import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CountyMasterModel } from 'src/Models/CountyMasterModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { AppConfig } from 'src/Services/app.config';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompletedNew, getIncompletedStepNew } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-admin-company-profile',
  templateUrl: './admin-company-profile.component.html',
  styleUrls: ['./admin-company-profile.component.css']
})
export class AdminCompanyProfileComponent implements OnInit {

  CompanyProfileInformationForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/

  ClientInformationId: number;
  listOfResetPasswordIDs = [];
  ResetPasswordList: any[] = [];
  selectedItemsResetPassword = [];
  dropdownResetPasswordFilter = [];
  dropdownSettingsResetPasswordFilter = {};
  isEnableCurrentlyResetingPassword: boolean = false

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateList: StateMasterModel[] = [];
  stateId: string = ' ';

  dropdownListMailingState = [];
  selectedItemsMailingState = [];
  dropdownSettingsMailingState = {};
  listOfMailingStateIds = [];
  mailingStateList: StateMasterModel[] = [];
  mailingStateId: string = ' ';

  dropdownListMailingCounty = [];
  selectedItemsMailingCounty = [];
  dropdownSettingsMailingCounty = {};
  listOfMailingCountyIds = [];
  mailingCountyList: CountyMasterModel[] = [];
  mailingCountyId: string = ' ';

  isCompanyProfileDetailsCompleted: boolean;
  isAddCompQueCompleted: boolean;
  isStateSelectionCompleted: boolean;
  isAttechedDetailsCompleted: boolean;
  isNotes: boolean;
  currentActiveTab: number = 1;
  url: string;
  tabId: number = 0;

  mainUrl: string;
  subUrl: string;

  companyName: string;
  dbacompanyName: string;
  companyId: number;
  priceQuoteId: number

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute, private datePipe: DatePipe, private globle: Globle, private UserService: UsersService,
    private router: Router, private PricingQuotesMaster: PricingQuotesService, private toastr: ToastrService, private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = sessionStorage.getItem('UserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Company Profile');
    this.companyId = +sessionStorage.getItem('CompanyId');
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.dbacompanyName = sessionStorage.getItem('DbaCompanyName') == 'null' ? null : sessionStorage.getItem('DbaCompanyName');

    this.CompanyProfileInformationForm = this._fb.group({
      clientInformationId: 0,
      companyName: ['', Validators.required],
      physicalMailingAddressWithCounty: ['', Validators.required],
      generalCompanyPhoneNumber: ['', Validators.required],
      generalCompanyEmail: ['', Validators.required],
      persontoReceiveInvoice: ['', Validators.required],
      website: [''],
      filerIdNumber: [''],
      fEIN: ['', Validators.required],
      needAssistWithResetPassword: 0,
      stateOfFormation: [''],
      dateOfFormation: ['', Validators.required],
      quoteName: [''],
      quoteExpiryDate: [''],
      clientCompanyId: this.companyId,
      priceQuoteId: 0,
      dba: [''],
      mailingCity: [''],
      mailingState: 0,
      mailingZipCode: [''],
      affiliates: [''],
      isQuote: false,
      mailingCounty: 0,
    })
    this.getState();

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.ClientInformationId = params['id'];
        }
      }
      if (params['companyId']) {
        if (params['companyId'] != null) {
          this.isEdit = true;
          this.companyId = params['companyId'];
        }
      }
    });
    this.getPriceQuoteListByCompanyId(this.companyId)
    this.mainUrl = '/admin/CompanyProfile/';
    if (this.ClientInformationId != null) {
      this.GetProgressStepDetailsById(this.ClientInformationId);
      this.subUrl = getIncompletedStepNew(this.globle.cApp.statusModel);
    }

    this.dropdownSettingsState = {
      singleSelection: true,
      text: "Filter by State",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No State(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsMailingState = {
      singleSelection: true,
      text: "Filter by State",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No State(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsMailingCounty = {
      singleSelection: true,
      text: "Filter by County",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No County(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

  }


  ngOnInit() {
    this.isSubmit = false;
  }

  Getuser(userID: number): void {
    this.blockDocument();
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.UserService.GetUserFindByUserID(this.tokenKey, userID).subscribe(
      data => {
        this.companyName = data['userMaster'][0].companyName;
        this.companyId = data['userMaster'][0].companyid;
        this.unblockDocument();
      },
      error => {
        this.unblockDocument();
      }
    );
  }

  GetClientInformationIdByUserId(UserId: number) {
    this.blockDocument()
    this.UserService.GetUserFindByUserID(this.tokenKey, UserId).subscribe(
      data => {
        this.companyName = data['userMaster'][0].companyName;
        this.companyId = data['userMaster'][0].companyId;
        this.PricingQuotesMaster.GetClientInformationIdByUserId(this.tokenKey, parseInt(this.userID.toString())).subscribe(
          result => {
            if (result['status'] == 'Success') {
              this.ClientInformationId = result['clientInformationId'];
              this.unblockDocument();
              if (this.ClientInformationId != 0) {
                this.GetClientInformationById(this.ClientInformationId);
                this.GetProgressStepDetailsById(this.ClientInformationId);
              }
              else {
                this.CompanyProfileInformationForm.patchValue({
                  companyName: this.companyName
                });
              }
            }
          });
      });
  }

  getState() {
    this.PricingQuotesMaster.GetStateMaster(this.tokenKey).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }

        this.mailingStateList = data['state'];
        this.dropdownListMailingState = [];
        if (this.mailingStateList.length > 0) {
          this.mailingStateList.forEach(x => {
            this.dropdownListMailingState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  getCounty(stateid, countyid) {
    this.PricingQuotesMaster.GetCountyMaster(this.tokenKey, stateid).subscribe(
      data => {
        this.mailingCountyList = data['county'];
        this.dropdownListMailingCounty = [];
        this.selectedItemsMailingCounty = [];
        this.listOfMailingCountyIds = [];
        this.mailingCountyId = '';

        if (this.mailingCountyList.length > 0) {
          this.mailingCountyList.forEach(x => {
            this.dropdownListMailingCounty.push({ "id": x.countyId, "itemName": x.countyName })
          });
        }

        if (countyid != 0 && countyid!=null) {
          if (this.dropdownListMailingCounty.length > 0) {
            let countyData = this.dropdownListMailingCounty.filter(x => x.id == countyid);
            if (countyData.length > 0) {
              this.listOfMailingCountyIds = [];
              this.listOfMailingCountyIds.push(countyData[0].id);
              this.mailingCountyId = countyData[0].id;
              this.selectedItemsMailingCounty.push({ "id": countyData[0].id, "itemName": countyData[0].itemName })
            }
          }
        }
      },
      error => { }
    );
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  GetClientInformationById(clientInformationId: number) {
    this.blockDocument()
    this.PricingQuotesMaster.GetClientInformationById(this.tokenKey, parseInt(clientInformationId.toString()), 0, true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.unblockDocument();
          this.getCounty(result['clientInformation'].mailingState, result['clientInformation'].mailingCounty);
          this.CompanyProfileInformationForm.patchValue({
            clientInformationId: result['clientInformation'].clientInformationId,
            companyName: result['clientInformation'].companyName,
            physicalMailingAddressWithCounty: result['clientInformation'].physicalMailingAddressWithCounty,
            generalCompanyPhoneNumber: result['clientInformation'].generalCompanyPhoneNumber,
            generalCompanyEmail: result['clientInformation'].generalCompanyEmail,
            persontoReceiveInvoice: result['clientInformation'].persontoReceiveInvoice,
            website: result['clientInformation'].website,
            filerIdNumber: result['clientInformation'].filerIdNumber,
            fEIN: result['clientInformation'].fein,
            stateOfFormation: result['clientInformation'].stateOfFormation,
            dateOfFormation: result['clientInformation'].dateOfFormation,
            quoteName: result['clientInformation'].quoteName,
            quoteExpiryDate: result['clientInformation'].quoteExpiryDate,
            clientCompanyId: result['clientInformation'].clientCompanyId,
            dba: result['clientInformation'].dba,
            affiliates: result['clientInformation'].affiliates,
            isQuote: result['clientInformation'][0].isQuote
          });

          if (result['clientInformation'].companyName == null || result['clientInformation'].companyName == '') {
            this.CompanyProfileInformationForm.patchValue({
              companyName: this.companyName
            });
          }

          if (result['clientInformation'].clientCompanyId == null || result['clientInformation'].clientCompanyId == '' || result['clientInformation'].clientCompanyId == "0") {
            this.CompanyProfileInformationForm.patchValue({
              clientCompanyId: this.companyId
            });
          }

          if (result['clientInformation'].dba == null || result['clientInformation'].dba == '' || result['clientInformation'].dba == undefined) {
            this.CompanyProfileInformationForm.patchValue({
              dba: this.dbacompanyName
            });
          }

          this.selectedItemsResetPassword = [];
          if (result['clientInformation'].needAssistWithResetPassword != null) {
            this.isEnableCurrentlyResetingPassword = result['clientInformation'].needAssistWithResetPassword;
            if (result['clientInformation'].needAssistWithResetPassword == true) {
              this.selectedItemsResetPassword.push({ "id": 1, "itemName": "Yes" })
            }
            else { this.selectedItemsResetPassword.push({ "id": 2, "itemName": "No" }) }
          }

          if (this.stateList.length > 0) {
            this.selectedItemsState = [];
            this.listOfStateIds = [];
            let data = this.stateList.filter(x => x.stateId == result['clientInformation'].stateOfFormation)[0];
            if (data != undefined) {
              this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name });
              this.listOfStateIds.push(data.stateId);
              this.stateId = result['clientInformation'].stateOfFormation;
            }

            this.selectedItemsMailingState = [];
            this.listOfMailingStateIds = [];
            let data1 = this.mailingStateList.filter(x => x.stateId == result['clientInformation'].mailingState)[0];
            if (data1 != undefined) {
              this.selectedItemsMailingState.push({ "id": data1.stateId, "itemName": data1.name });
              this.listOfMailingStateIds.push(data1.stateId);
              this.mailingStateId = result['clientInformation'].mailingState;
            }
          }
          else
            setTimeout(() => {
              this.selectedItemsState = [];
              this.listOfStateIds = [];
              let data = this.stateList.filter(x => x.stateId == result['clientInformation'].stateOfFormation)[0];
              if (data != undefined) {
                this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name });
                this.listOfStateIds.push(data.stateId);
                this.stateId = result['clientInformation'].stateOfFormation;
              }

              this.selectedItemsMailingState = [];
              this.listOfMailingStateIds = [];
              let data1 = this.mailingStateList.filter(x => x.stateId == result['clientInformation'].mailingState)[0];
              if (data1 != undefined) {
                this.selectedItemsMailingState.push({ "id": data1.stateId, "itemName": data1.name });
                this.listOfMailingStateIds.push(data1.stateId);
                this.mailingStateId = result['clientInformation'].mailingState;
              }
            }, 1000);


        }
      });
  }

  getPriceQuoteListByCompanyId(companyId: number) {
    this.blockDocument()
    this.PricingQuotesMaster.getPriceQuoteListByCompanyId(this.tokenKey, companyId, false).subscribe(
      result => {
        if (result['status'] == 'Success' && result['pricingQuotes'].length > 0) {
          this.unblockDocument();
          this.getCounty(result['pricingQuotes'][0].mailingState,result['pricingQuotes'][0].mailingCounty);
          this.CompanyProfileInformationForm.patchValue({
            clientInformationId: result['pricingQuotes'][0].clientInformationId,
            companyName: result['pricingQuotes'][0].companyName,
            physicalMailingAddressWithCounty: result['pricingQuotes'][0].physicalMailingAddressWithCounty,
            generalCompanyPhoneNumber: result['pricingQuotes'][0].generalCompanyPhoneNumber,
            generalCompanyEmail: result['pricingQuotes'][0].generalCompanyEmail,
            persontoReceiveInvoice: result['pricingQuotes'][0].persontoReceiveInvoice,
            website: result['pricingQuotes'][0].website,
            filerIdNumber: result['pricingQuotes'][0].filerIdNumber,
            fEIN: result['pricingQuotes'][0].fein,
            stateOfFormation: result['pricingQuotes'][0].stateOfFormation,
            dateOfFormation: result['pricingQuotes'][0].dateOfFormation,
            quoteName: result['pricingQuotes'][0].quoteName,
            quoteExpiryDate: result['pricingQuotes'][0].quoteExpiryDate,
            clientCompanyId: result['pricingQuotes'][0].clientCompanyId,
            priceQuoteId: result['pricingQuotes'][0].priceQuoteId,
            dba: result['pricingQuotes'][0].dba,
            mailingCity: result['pricingQuotes'][0].mailingCity,
            mailingZipCode: result['pricingQuotes'][0].mailingZipCode,
            affiliates: result['pricingQuotes'][0].affiliates,
            isQuote: result['pricingQuotes'][0].isQuote

          });

          if (result['pricingQuotes'][0].companyName == null || result['pricingQuotes'][0].companyName == '') {
            this.CompanyProfileInformationForm.patchValue({
              companyName: this.companyName
            });
          }

          if (result['pricingQuotes'][0].dba == null || result['pricingQuotes'][0].dba == '' || result['pricingQuotes'][0].dba == undefined) {
            this.CompanyProfileInformationForm.patchValue({
              dba: this.dbacompanyName
            });
          }

          if (result['pricingQuotes'][0].clientCompanyId == null || result['pricingQuotes'][0].clientCompanyId == '' || result['pricingQuotes'][0].clientCompanyId == "0") {
            this.CompanyProfileInformationForm.patchValue({
              clientCompanyId: this.companyId
            });
          }

          this.selectedItemsResetPassword = [];
          if (result['pricingQuotes'][0].needAssistWithResetPassword != null) {
            this.isEnableCurrentlyResetingPassword = result['pricingQuotes'][0].needAssistWithResetPassword;
            if (result['pricingQuotes'][0].needAssistWithResetPassword == true) {
              this.selectedItemsResetPassword.push({ "id": 1, "itemName": "Yes" })
            }
            else { this.selectedItemsResetPassword.push({ "id": 2, "itemName": "No" }) }
          }

          if (this.stateList.length > 0) {
            this.selectedItemsState = [];
            this.listOfStateIds = [];
            let data = this.stateList.filter(x => x.stateId == result['pricingQuotes'][0].stateOfFormation)[0];
            if (data != undefined) {
              this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name });
              this.listOfStateIds.push(data.stateId);
              this.stateId = result['pricingQuotes'][0].stateOfFormation;
            }

            this.selectedItemsMailingState = [];
            this.listOfMailingStateIds = [];
            let data1 = this.mailingStateList.filter(x => x.stateId == result['pricingQuotes'][0].mailingState)[0];
            if (data1 != undefined) {
              this.selectedItemsMailingState.push({ "id": data1.stateId, "itemName": data1.name });
              this.listOfMailingStateIds.push(data1.stateId);
              this.mailingStateId = result['pricingQuotes'][0].mailingState;
            }
          }
          else
            setTimeout(() => {
              this.selectedItemsState = [];
              this.listOfStateIds = [];
              let data = this.stateList.filter(x => x.stateId == result['pricingQuotes'][0].stateOfFormation)[0];
              if (data != undefined) {
                this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name });
                this.listOfStateIds.push(data.stateId);
                this.stateId = result['pricingQuotes'][0].stateOfFormation;
              }

              this.selectedItemsMailingState = [];
              this.listOfMailingStateIds = [];
              let data1 = this.mailingStateList.filter(x => x.stateId == result['pricingQuotes'][0].mailingState)[0];
              if (data1 != undefined) {
                this.selectedItemsMailingState.push({ "id": data1.stateId, "itemName": data1.name });
                this.listOfMailingStateIds.push(data1.stateId);
                this.mailingStateId = result['pricingQuotes'][0].mailingState;
              }
            }, 1000);

          this.ClientInformationId = result['pricingQuotes'][0].clientInformationId;
        } else {
          this.CompanyProfileInformationForm.patchValue({
            companyName: this.companyName,
            clientCompanyId: this.companyId,
            dba: this.dbacompanyName
          });
          this.unblockDocument();
        }
      });
  }
  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  save() {
    var isDetailsCompleted = false;
    this.isSubmit = true;
    if (this.CompanyProfileInformationForm.value.needAssistWithResetPassword == "")
      this.CompanyProfileInformationForm.value.needAssistWithResetPassword = null;

    let data = this.CompanyProfileInformationForm.value;
    if (!this.CompanyProfileInformationForm.valid || !data.companyName.trim() || !data.physicalMailingAddressWithCounty.trim()
      || !data.generalCompanyPhoneNumber.trim() || !data.generalCompanyEmail.trim()
      || !data.persontoReceiveInvoice.trim() || !data.fEIN.trim()) {
      isDetailsCompleted = false;
    }
    else {
      isDetailsCompleted = true;
    }

    if (this.listOfStateIds.length == 0) { isDetailsCompleted = false; }

    if (this.CompanyProfileInformationForm.invalid) {
      this.globle.cApp.statusModel.isCompanyProfileDetailsCompleted = false;
    }

    let value = {
      clientInformationId: data.clientInformationId,
      companyName: data.companyName != null ? data.companyName.trim() : data.companyName,
      physicalMailingAddressWithCounty: data.physicalMailingAddressWithCounty != null ? data.physicalMailingAddressWithCounty.trim() : data.physicalMailingAddressWithCounty,
      generalCompanyPhoneNumber: data.generalCompanyPhoneNumber != null ? data.generalCompanyPhoneNumber.trim() : data.generalCompanyPhoneNumber,
      generalCompanyEmail: data.generalCompanyEmail != null ? data.generalCompanyEmail.trim() : data.generalCompanyEmail,
      persontoReceiveInvoice: data.persontoReceiveInvoice != null ? data.persontoReceiveInvoice.trim() : data.persontoReceiveInvoice,
      website: data.website,
      filerIdNumber: data.filerIdNumber,
      fEIN: data.fEIN != null ? data.fEIN.trim() : data.fEIN,
      stateOfFormation: this.stateId,
      dateOfFormation: data.dateOfFormation,
      createdBy: this.userID,
      quoteName: data.quoteName != null ? data.quoteName.trim() : data.quoteName,
      isClient: false,
      quoteExpiryDate: data.quoteExpiryDate != null ? data.quoteExpiryDate : null,
      isFront: true,
      clientCompanyId: data.clientCompanyId,
      isCompProfileInfoCompleted: isDetailsCompleted,
      priceQuoteId: data.priceQuoteId,
      dba: data.dba != null ? data.dba.trim() : data.dba,
      mailingState: this.mailingStateId,
      mailingCity: data.mailingCity,
      mailingZipCode: data.mailingZipCode,
      affiliates: data.affiliates,
      isQuote: data.isQuote != null ? data.isQuote : false,
      mailingCounty: this.mailingCountyId
    }

    this.blockDocument();
    this.PricingQuotesMaster.CompanyProfileInformationAddUpdate(this.tokenKey, value).subscribe(
      result => {

        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isCompanyProfileDetailsCompleted = true;
          this.unblockDocument();
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (result['clientInformationId'] != 0) {
              let navigation = '/admin/CompanyProfile/add-comp-que/' + result['clientInformationId'] + '/' + this.companyId;
              this.router.navigate([navigation]);
            }
          }
        }
        else {
          this.unblockDocument();
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  onSelectTaxCompliance($event) {
    if ($event.id == 1) {
      this.isEnableCurrentlyResetingPassword = true;
      this.CompanyProfileInformationForm.patchValue({
        needAssistWithResetPassword: true
      });
    }
    else {
      this.isEnableCurrentlyResetingPassword = false;
      this.CompanyProfileInformationForm.patchValue({
        needAssistWithResetPassword: false
      });
    }
  }

  onDeSelectTaxComplianceAll($event) {
    this.selectedItemsResetPassword = [];
    this.isEnableCurrentlyResetingPassword = false;
    this.CompanyProfileInformationForm.patchValue({
      needAssistWithResetPassword: false
    });
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = $event.id;
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.stateId = '';
  }

  activeTab(url: string, tabId: number) {
    if (this.ClientInformationId != null) {
      if (checkStepIsCompletedNew(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.ClientInformationId + '/' + this.companyId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepNew(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.ClientInformationId + '/' + this.companyId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompletedNew(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepNew(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(clientInformationId: number) {
    this.PricingQuotesMaster.GetCompanyProfileProgressById(this.tokenKey, clientInformationId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStepNew(this.globle.cApp.statusModel);
          this.activeTab('company-info', 1);
        }
      }
    );
  }

  backtoquoteList() {
    let navigation = '/View/client-profile'
    this.router.navigate([navigation]);
  }

  onSelectMailingState($event) {
    this.listOfMailingStateIds = this.removeElemetninArray(this.listOfMailingStateIds, $event.id)
    this.listOfMailingStateIds.push($event.id);
    this.mailingStateId = $event.id;
    this.getCounty(this.mailingStateId,0);
  }

  onDeSelectMailingState($event) {
    if (this.listOfMailingStateIds != null) {
      if (this.listOfMailingStateIds.length > 0) {
        this.listOfMailingStateIds = this.removeElemetninArray(this.listOfMailingStateIds, $event.id)
      }
    }
    if (this.listOfMailingStateIds.length > 0) {
      this.mailingStateId = this.listOfMailingStateIds.join(',')
    }

    this.listOfMailingCountyIds = null;
    this.mailingCountyList = [];
    this.mailingCountyId = '';
    this.selectedItemsMailingCounty = null;

  }

  onDeSelectAllMailingState($event) {
    this.listOfMailingStateIds = [];
    this.mailingStateId = '';

    this.listOfMailingCountyIds = null;
    this.mailingCountyList = [];
    this.mailingCountyId = '';
    this.selectedItemsMailingCounty = null;
  }

  onSelectMailingCounty($event) {
    this.listOfMailingCountyIds = this.removeElemetninArray(this.listOfMailingCountyIds, $event.id)
    this.listOfMailingCountyIds.push($event.id);
    this.mailingCountyId = $event.id;
  }

  onDeSelectMailingCounty($event) {
    if (this.listOfMailingCountyIds != null) {
      if (this.listOfMailingCountyIds.length > 0) {
        this.listOfMailingCountyIds = this.removeElemetninArray(this.listOfMailingCountyIds, $event.id)
      }
    }
    if (this.listOfMailingCountyIds.length > 0) {
      this.mailingCountyId = this.listOfMailingCountyIds.join(',')
    }
  }

  onDeSelectAllMailingCounty($event) {
    this.listOfMailingCountyIds = [];
    this.mailingCountyId = '';
  }

  cancelClinetInfo() {
    sessionStorage.removeItem('ClientCompanyName');
    sessionStorage.removeItem('DbaCompanyName');
    if (this.SelectedTool == "PricingQuote") {
      let navigation = '/admin/PricingQuote/ClientInformation'
      this.router.navigate([navigation]);
    }
    else {
      let navigation = '/admin/Admin/companies'
      this.router.navigate([navigation]);
    }
  }
}
