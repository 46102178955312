export class CRRAContentModel {
    contentId: number;
    stateId: number;
    carrierTypeId: string;
    categoryId: string;
    subCategoryId: string;
    ruleNote: string;
    name: string;
    notes: string;
    tmiNotes: string;
    effectiveDate: string | Date;
}

export class CRRAContentGetModel {
    contentId: number;
    stateId: number;
    stateName: string;
    categoryName: string;
    carrierName: string;
    subCategoryName: string;
    ruleNote: string;
    name: string;
    notes: string
    tmiNotes: string;
    effectiveDate: string | Date;
    totalRecordCount: number;
    categoryId:number;
    fundID: any;
    fundCount:number;
    isFavourite:boolean;
}

export class FilterCRRAContentModel {
    pageNumber?: number;
    pageSize?: number;
    stateId?: string | number;
    carrierTypeId?: string | number;
    categoryId?: string | number;
    subCategoryId?: string | number;
    orderBy?: string;
    isCompare?: boolean;
    baseUrl?:string;
    userId:number;
    isFavourite:number;
    note:string;
    auditMonth?:string;
}

export class CRRAGetUpdateViewModel {
    contentId: number;
    stateId: number;
    stateName: string;
    categoryId:number;
    categoryName:string;
    stateList: any[];
    categoryList:any[];
}

export class CRRAContentHistoryGetModel{
    recordId: number;
    fieldName: string;
    oldValue: string;
    newValue: string;
    createdDate: string | Date;
    createdBy: number;
    userName: string;
    totalRecordCount:number;
}