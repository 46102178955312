import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarrierObligationFieldService {

  public Fields:Fields[] = [
    { id: 1, name: "Carrier Type" },
    { id: 2, name: "Category Type"},
    { id: 3, name: "Sub Category" },
    { id: 4, name: "Title" },
    { id: 5, name: "Details" },
    { id: 6, name: "Pending Activity" },
    { id: 7, name: "Due Date" },
    { id: 8, name: "Internal Notes" },
    { id: 9, name: "Key Terms" },
    { id: 10, name: "Related Forfeitures" },
    { id: 11, name: "CFR §" },   
    { id: 12, name: "Chapter" },   
    { id: 13, name: "Sub Chapter" },   
    { id: 14, name: "Part" },   
    { id: 15, name: "Sub Part" },   
    { id: 16, name: "CFR Section" },   
    { id: 17, name: "URL" },   
    { id: 18, name: "Publish Date" },   
    { id: 19, name: "Sort Order" },  
    { id: 20, name: "Last Audit" },    
  ];

  getFields() {
    return this.Fields;
  }
}

class Fields {
  id: number;
  name: string;
}