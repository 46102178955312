import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TreeNode } from 'primeng/api';

@Injectable()
export class NodeService {

    AppUrl: string = "";
    constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.setAPIUrl();
    }

    setAPIUrl() {
        if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
            this.AppUrl = sessionStorage.getItem("BaseURL") || '';
        }
    }

    getFiles() {
        return this._http.get<any>('assets/files.json')
            .toPromise()
            .then(res => <TreeNode[]>res.data);
    }
}