import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from 'src/Models/Common';
import { CarrierTariffsModel } from 'src/Models/CarrierTariffsModel';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { I360Service } from 'src/Services/i360Service';
import { Params, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage-carrier-tariffs',
  templateUrl: './manage-carrier-tariffs.component.html',
  styleUrls: ['./manage-carrier-tariffs.component.css']
})
export class ManageCarrierTariffsComponent implements OnInit {

  carrierTariffsModel = new CarrierTariffsModel();


  carrierTariffsForm: FormGroup;
  loginuserID: number = 0;

  CarrierTariffsModel: CarrierTariffsModel = {
    carrierId: 0,
    title: '',
    url: '',
    isActive: ''
  };
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;

  carrierId: number = 0;
  isMultipleClick: boolean = false;
  constructor(private activedRoute: ActivatedRoute, private _fb: FormBuilder, private router: Router, private checkAuth: CheckAuth, private titleService: Title,
    private i360service: I360Service,
    private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Carrier Tariff');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    this.carrierTariffsForm = this._fb.group({
      carrierId: 0,
      title: '',
      url: ['', [Validators.required, Validators.pattern(reg)]],
      isActive: true
    });
  }

  get url() { return this.carrierTariffsForm.get('url') };
  
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          
          this.carrierId = params['id'];
          this.GetCarrierTariffsData();
        }
      }
    });

  }

  GetCarrierTariffsData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.i360service.i360CarrierTariffsFindbyCarrierId(this.tokenKey, this.carrierId).subscribe(
      data => {
        if (data != null) {
          this.carrierTariffsModel = data["carrierData"];
          if (this.carrierTariffsModel != null) {
            this.carrierTariffsForm.setValue({
              carrierId: this.carrierTariffsModel.carrierId,
              title: this.carrierTariffsModel.title,
              url: this.carrierTariffsModel.url,
              isActive: this.carrierTariffsModel.isActive
            });
          }
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  saveCarrierTariffs(carrierTariffsForm) {
    
    event.preventDefault();  

    this.carrierTariffsModel = carrierTariffsForm.value;

    if (this.carrierTariffsModel.title == '' || typeof (this.carrierTariffsModel.title) == 'undefined' || this.carrierTariffsModel.title == null) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }

    if (this.carrierTariffsModel.url == '' || typeof (this.carrierTariffsModel.url) == 'undefined' || this.carrierTariffsModel.url == null) {
      this.showMessage('Please enter url', 'URL Required', 'error');
      return;
    }
    

    this.carrierTariffsModel.createdBy = this.loginuserID;
    this.carrierTariffsModel.modifiedBy = this.loginuserID;

    if (!this.carrierTariffsForm.valid) {
      
      return;
    }


    if (this.isMultipleClick) {      
      return;
    }
    this.isMultipleClick = true;
    
    this.i360service.i360CarrierTariffsAddUpdate(this.tokenKey, this.carrierTariffsModel).subscribe(
      data => {
        
        this.isMultipleClick = false;
        if (data['carrierId'] != 0) {
          if (data['carrierId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.carrierId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.carrierId = 0;
            this.cancelCarrierTariffs()
          }
        }
      },
      error => {
        this.isMultipleClick = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelCarrierTariffs() {
    this.router.navigate(['admin/inteserra360/carriertariffs']);
  }
}
