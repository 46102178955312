import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterFundModel } from 'src/Models/TRFAFundModel';
@Injectable({
  providedIn: 'root'
})
export class TrfaFundMasterService {
  AppUrl: string = "";
  filterFundModel = new FilterFundModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }


  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllFund(TokenKey: string, PageNumber: number, PageSize: number, filterText: string, stateId: string, fundTypeID: string, orderBy: string, statusId: string,): Observable<any> {

    this.filterFundModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      stateID: stateId,
      filterText: filterText,
      fundTypeID: fundTypeID,
      orderBy: orderBy,
      statusId: statusId
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRFAFundMaster/GetAllFund';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterFundModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  FundAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRFAFundMaster/FundAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetFundById(TokenKey: string, fundId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFAFundMaster/GetFundById/' + fundId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteFund(TokenKey: string, fundId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFAFundMaster/DeleteFund/' + fundId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetFund(TokenKey: string, stateId: string, isAdmin: boolean,isEdit:boolean): Observable<any> {
    this.setAPIUrl();
    this.filterFundModel = {
      pageNumber: 1,
      pageSize: 10,
      stateID: stateId,
      filterText: null,
      fundTypeID: null,
      orderBy: null,
      isAdmin: isAdmin,
      isEdit:isEdit
    }
    let data = 'api/TRFAFundMaster/GetFund';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.filterFundModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetFundByName(TokenKey: string, fundName: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFAFundMaster/GetFundByName/' + fundName;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

}
