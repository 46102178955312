import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ManageCRRAModel } from 'src/Models/ManageCRRAModel';
import { ManageCrraMasterService } from 'src/Services/manage-crra-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-crrahome',
  templateUrl: './crrahome.component.html',
  styleUrls: ['./crrahome.component.css']
})
export class CRRAHomeComponent implements OnInit {

  tokenKey: string = '';
  objManageCRRAModel = new ManageCRRAModel();
  
  constructor(private titleService: Title, private toastr: ToastrService, private _fb: FormBuilder, private manageCrraMaster: ManageCrraMasterService, private checkAuth: CheckAuth) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Home');
   }

  ngOnInit() {

    this.getHomeDetail();
  }

  getHomeDetail(){
    this.manageCrraMaster.GetManageCRRADetails(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        this.objManageCRRAModel = data.result;
      }
    });
  }
}
