import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpEvent, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const nextReq = req.clone({
            headers: req.headers.set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache')
                .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
                .set('If-Modified-Since', '0')
        });

        return next.handle(nextReq);
    }

    // intercept(httpRequest: HttpRequest<any>,
    //     nextRequest: HttpHandler): Observable<HttpEvent<any>> {
    //     const transformedRequest = httpRequest.clone({
    //         headers: httpRequest.headers.set('Cache-Control', 'no-cache')
    //             .set('Pragma', 'no-cache')
    //             .set('Expires', 'Thu, 01 Jan 1970 00:00:00 GMT')
    //             .set('If-Modified-Since', '0')
    //     });
    //     return nextRequest.handle(transformedRequest);
    // }

    // intercept(request: HttpRequest<any>, next: HttpHandler) {
    //     if (request.method === 'GET') {
    //         const customRequest = request.clone({
    //             headers: new HttpHeaders({
    //                 'Cache-Control': 'no-cache',
    //                 'Pragma': 'no-cache',
    //                 'Expires': 'Thu, 01 Jan 1970 00:00:00 GMT',
    //                 'If-Modified-Since': '0'
    //             })
    //         });
    //     }
    //     return next.handle(request);
    // }
}