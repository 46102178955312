import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-landing-page',
  templateUrl: './login-landing-page.component.html',
  styleUrls: ['./login-landing-page.component.css']
})
export class LoginLandingPageComponent implements OnInit {
  userName: string = '';
  lastLogin: any;
  constructor() { }

  ngOnInit(): void {
    this.userName = sessionStorage.getItem('FirstLastName');
    this.lastLogin = sessionStorage.getItem('LastLogin');
  }

}
