import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterCRRAContentModel } from 'src/Models/CRRAContentModel';

@Injectable({
  providedIn: 'root'
})
export class CrraContentMasterService {

  AppUrl: string = "";

  filterCRRAContentModel = new FilterCRRAContentModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }


  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllContent(TokenKey: string, PageNumber: number, PageSize: number, stateId: string, carrierTypeId: string, categoryId: string, subCategoryId: string, orderBy: string, auditMonth: string): Observable<any> {
    this.filterCRRAContentModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      stateId: stateId,
      carrierTypeId: carrierTypeId,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      orderBy: orderBy,
      isFavourite: 0,
      userId: 0,
      note: null,
      auditMonth: auditMonth
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRAContentMaster/GetAllContent';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterCRRAContentModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ContentAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRAContentMaster/ContentAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetContentById(TokenKey: string, ContentId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRAContentMaster/GetContentById/' + ContentId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteContent(TokenKey: string, ContentId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRAContentMaster/DeleteContent/' + ContentId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllCompareView(TokenKey: string, stateId: string, carrierTypeId: string, categoryId: string, subCategoryId: string): Observable<any> {
    this.filterCRRAContentModel = {
      // pageNumber: 0,
      // pageSize: 0,
      stateId: stateId,
      carrierTypeId: carrierTypeId,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      isFavourite: 0,
      userId: 0,
      note: null,
      auditMonth: null
      // isCompare: isCompare,
      // baseUrl: baseUrl
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRAContentMaster/GetAllCompareView';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterCRRAContentModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllCompareViewByUser(TokenKey: string, stateId: string, carrierTypeId: string, categoryId: string, subCategoryId: string, userId: number, Note: string, IsFavourite: number): Observable<any> {
    this.filterCRRAContentModel = {
      stateId: stateId,
      carrierTypeId: carrierTypeId,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      isFavourite: + IsFavourite,
      userId: userId,
      note: Note
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRAContentMaster/GetAllCompareViewByUser';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterCRRAContentModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCRRAContenthistoryById(TokenKey: string, PageNumber: number, PageSize: number, contentId: number, orderBy: string, FieldId: string) {
    this.setAPIUrl();
    let data = 'api/CRRAContentMaster/GetContenthistoryById/' + PageNumber + '/' + PageSize + '/' + contentId + '/' + orderBy + '/' + FieldId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}