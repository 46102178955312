import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { EBBClaimProcessMasterModel } from 'src/Models/EBBClaimProcessMasterModel';
import { InrollProductsCompanyModel } from 'src/Models/InrollProductsCompanyModel';
import { NLADClaimService } from 'src/Services/nladClaim.service';
import * as FileSaver from 'file-saver';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-claim-imports-lifeline-listing',
  templateUrl: './claim-imports-lifeline-listing.component.html',
  styleUrls: ['./claim-imports-lifeline-listing.component.css']
})
export class ClaimImportsLifelineListingComponent implements OnInit {

  listOfData: EBBClaimProcessMasterModel[]
  filterModel = new FilterModel();
  SelectedTool: string;
  productId: number = 1;
  providerId: number = 0;
  userId: number = 0;

  isAscending: boolean = false
  upDowCompanyNameCSS: string = '';
  upDowSourceFileNameCSS: string = '';
  upDowDestinationFileNameCSS: string = '';
  upDowMonthsCSS: string = '';
  upDowYearsCSS: string = '';
  upDowRecordsCountCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowErrorCountCSS: string = '';

  totalRecordsCount: number = -1;
  isloading: boolean = false;
  currentPage: number = 1;

  listOfInrollProductCompany: InrollProductsCompanyModel[];
  dropdownCompanies = [];
  selectedItemsCompanies = [];
  dropdownSettingCompanies = {};
  listOfCompany = [];
  monthText = [];

  tokenKey: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  deleteId: number = 0;
  openMenuIndex: number = -1;

  constructor(
    private router: Router,
    private titleService: Title,
    private nladService: NLADClaimService,
    private toastr: ToastrService,
    private i360Service: I360Service,
    private checkAuth: CheckAuth,
  ) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Lifeline Claim Import');
    this.productId = ToolEnum[this.SelectedTool];
    this.providerId = 0;
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.filterModel.providerId = this.providerId.toString();
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'CreatedDate Desc';
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.GetLifelineClaimProcessMasterPaged();
    this.GetInrollClientCompany();
    this.monthText.push({ 'id': 1, 'itemName': 'January' });
    this.monthText.push({ 'id': 2, 'itemName': 'February' });
    this.monthText.push({ 'id': 3, 'itemName': 'March' });
    this.monthText.push({ 'id': 4, 'itemName': 'April' });
    this.monthText.push({ 'id': 5, 'itemName': 'May' });
    this.monthText.push({ 'id': 6, 'itemName': 'June' });
    this.monthText.push({ 'id': 7, 'itemName': 'July' });
    this.monthText.push({ 'id': 8, 'itemName': 'August' });
    this.monthText.push({ 'id': 9, 'itemName': 'September' });
    this.monthText.push({ 'id': 10, 'itemName': 'October' });
    this.monthText.push({ 'id': 11, 'itemName': 'November' });
    this.monthText.push({ 'id': 12, 'itemName': 'December' });
  }

  GetLifelineClaimProcessMasterPaged() {
    this.isloading = true;
    this.listOfData = [];
    this.nladService.GetLifelineClaimProcessMasterPaged(this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfData = data.listOfData;
          if (this.listOfData != null) {
            if (this.listOfData.length > 0) {
              this.totalRecordsCount = this.listOfData[0].totalRecordCount;
            }
            else {
              this.totalRecordsCount = 0;
            }
          }
          else {
            this.totalRecordsCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetLifelineClaimProcessMasterPaged();
  }


  sort(sortBy: any) {
    this.upDowCompanyNameCSS = '';
    this.upDowSourceFileNameCSS = '';
    this.upDowDestinationFileNameCSS = '';
    this.upDowMonthsCSS = '';
    this.upDowYearsCSS = '';
    this.upDowRecordsCountCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowErrorCountCSS = '';

    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'CompanyName': this.upDowCompanyNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'sourceFileName': this.upDowSourceFileNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'destinationFileName': this.upDowDestinationFileNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'months': this.upDowMonthsCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'years': this.upDowYearsCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'recordsCount': this.upDowRecordsCountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'CreatedDate': this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'errorRecordsCount': this.upDowErrorCountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetLifelineClaimProcessMasterPaged();
  }

  GetInrollClientCompany() {
    this.nladService.GetLifelineInrollClientCompany().subscribe(
      data => {
        this.dropdownSettingCompanies = {
          singleSelection: true,
          text: 'Filter by Company',
          enableSearchFilter: true,
          classes: 'borderLessTextBoxGreen',
          badgeShowLimit: 1,
          noDataLabel: 'No company',
          showCheckbox: false,
          enableFilterSelectAll: false
        };
        
        if (data != null) {
          if (data.status == 'Success') {
            this.listOfInrollProductCompany = data.listofInrollCompany;
            if (this.listOfInrollProductCompany != null) {
              if (this.listOfInrollProductCompany.length > 0) {
                for (let iCounter = 0; iCounter < this.listOfInrollProductCompany.length; iCounter++) {
                  this.dropdownCompanies.push({ 'id': this.listOfInrollProductCompany[iCounter].companyId, 'itemName': this.listOfInrollProductCompany[iCounter].companyName });
                }
              }
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedCompanies($event) {
    
    this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event.id)
    this.listOfCompany.push($event.id);
    this.filterModel.pageNumber = '1';
    this.filterModel.providerId = $event.id;
    this.GetLifelineClaimProcessMasterPaged();
  }

  onDeSelectedCompanies($event) {
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event.id)
      }
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.providerId = $event.id;
    this.GetLifelineClaimProcessMasterPaged();
  }

  onSelectAllCompanies($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.providerId = $event[0].id;
    this.GetLifelineClaimProcessMasterPaged();
  }

  onDeSelectAllCompanies($event) {
    this.listOfCompany = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.providerId = '0';
    this.GetLifelineClaimProcessMasterPaged();
  }

  removeElemetninArrayById(array, element) {
    return array.filter(e => e.id !== element);
  }

  redirectToDetailsPage(id, companyName) {
    
    sessionStorage.setItem('ViewClaimMasterId', id);
    sessionStorage.setItem('ViewCompanyName', companyName);
    this.router.navigate(['/admin/inroll/view/lifeline-claim'])
  }

  setMonthsText(id) {
    if (id > 0)
      return this.monthText.filter(m => m.id == id)[0].itemName;
    return id;
  }

  downloadDocument(filePath, fileNames) {
    this.blockDocument();
    this.filterModel.docPath = filePath;
    this.filterModel.docType = 'EBBClaimFile';
    this.i360Service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
      fileData => {
        
        this.unblockDocument();
        if (fileNames == '') {
          this.toastr.error('Records not found.', 'Error');
        }
        else {
          if (fileData != null) {
            FileSaver.saveAs(fileData, fileNames);
          }
        }
      }, error => {
        this.unblockDocument();
        console.log(error);
      }
    );
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  setDeleteId(id) {
    this.deleteId = id;
  }

  LifelineClaimDataDelete(id) {
    if (this.deleteId > 0) {
      this.blockDocument()
      this.nladService.LifelineClaimDataDelete(this.deleteId).subscribe(
        data => {
          this.deleteId = 0;
          this.unblockDocument();
          if (data.id > 0) {
            this.GetLifelineClaimProcessMasterPaged();
            this.toastr.success('Data deleted successfully', 'Delete')
          }
        }, error => {
          this.unblockDocument();
          console.log(error);
        }
      );
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
