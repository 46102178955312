import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Filter911Model } from 'src/Models/filterModel';

@Injectable({
  providedIn: 'root'
})
export class TRFAServiceService {


  AppUrl: string = "";
  filter911Model = new Filter911Model();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }
  
  UploadDataonFTP(TokenKey: string,userId:string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFASourceMaster/DownloadData/'+userId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DownloadS3Files(fileName: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFA_911_DownloadHistory/DownloadTRFAS3Files/'+fileName;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteZipFile(FileName: string): Observable<any> {        
    this.setAPIUrl();        
    this.AppUrl = this.AppUrl + 'api/TRFA_911_DownloadHistory/DeleteTRFAZipFile';
    let input: FormData = new FormData();
    input.append('FileName', FileName);        
    return this._http.post<any>(this.AppUrl, input)
        .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
