import { Component, OnInit } from '@angular/core';
import { ClaimViewReportsModel, EBBClaimCountReportsModel } from 'src/Models/EBBClaimCountReportsModel';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { NLADClaimService } from 'src/Services/nladClaim.service';
import { InrollProductsCompanyModel } from 'src/Models/InrollProductsCompanyModel';
import { ClaimStateModel } from 'src/Models/ClaimStateModel';
import { Router } from '@angular/router';


@Component({
  selector: 'app-claim-report-module',
  templateUrl: './claim-report-module.component.html',
  styleUrls: ['./claim-report-module.component.css']
})
export class ClaimReportModuleComponent implements OnInit {

  filterModel = new FilterModel();
  listOfData: ClaimViewReportsModel[];
  tokenKey: string = '';
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false

  upDowCompanyNameCSS: string = '';
  upDowYearsCSS: string = '';
  upDowMonthsCSS: string = '';
  upDowTotalCountCSS: string = '';
  upDowTotalAmountCSS: string = '';
  upDowDeviceCountCSS: string = '';
  upDowDeviceAmountCSS: string = '';
  upDowStateCSS: string = '';

  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  isloading: boolean = false;
  claimMasterId:any;
  isRecord: boolean = false;

  constructor(private nladClaimService: NLADClaimService, private checkAuth: CheckAuth, private titleService: Title, private router: Router) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('View Report');
    this.claimMasterId = sessionStorage.getItem('ViewClaimMasterId');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
   
    this.ViewClaimReports();
  }

  ViewClaimReports() {  

  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.ViewClaimReports();
  }

  sort(sortBy: any) {
    this.upDowCompanyNameCSS = '';
    this.upDowYearsCSS = '';
    this.upDowMonthsCSS = '';
    this.upDowTotalCountCSS = '';
    this.upDowTotalAmountCSS = '';
    this.upDowDeviceCountCSS = '';
    this.upDowDeviceAmountCSS = '';
    this.upDowStateCSS = '';

    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';
    
    switch (sortBy) {
      case 'companyName': this.upDowCompanyNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'years': this.upDowYearsCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'months': this.upDowMonthsCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'totalCount': this.upDowTotalCountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'totalAmount': this.upDowTotalAmountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deviceCount': this.upDowDeviceCountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deviceAmount': this.upDowDeviceAmountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'state': this.upDowStateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.OrderBy = sortBy;
    this.ViewClaimReports();
  }

  removeElemetninArrayById(array, element) {
    return array.filter(e => e.id !== element);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
}
