import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { CRRADepositeModel } from 'src/Models/CRRADepositeModel';
import { CrraDepositeMasterService } from 'src/Services/crra-deposite-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { StateMasterService } from 'src/Services/state-master.service';

@Component({
  selector: 'app-deposite',
  templateUrl: './deposite.component.html',
  styleUrls: ['./deposite.component.css']
})
export class DepositeComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofDeposite: CRRADepositeModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  filterText: string;


  upDowStateNameCSS: string = '';
  upDowIXCDepCSS: string = '';
  upDowCLECDepCSS: string = '';
  upDowIXCPayCSS: string = '';
  upDowCLECPayCSS: string = '';
  upDowNoteCSS: string = '';

  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  isState911: boolean = false;
  payRateId: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];
  openMenuIndex: number = -1;

  module="CRRA";

  constructor(private router: Router, private crraDepositeMaster: CrraDepositeMasterService, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService,private stateService:StateMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofDeposite = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'StateName';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Deposit Rates');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdictions",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.getState();
    this.GetDeposite();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetDeposite();
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetDeposite();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetDeposite();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetDeposite();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.resetPageNoandSize();
    this.GetDeposite();
  }

  resetPageNoandSize()
  {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  GetDeposite() {
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.filterText == "null") this.filterText = " ";
    this.crraDepositeMaster.GetAllDeposite(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, this.filterText, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofDeposite = data['crradeposite'];
        if (this.listofDeposite != null) {
          if (this.listofDeposite.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofDeposite[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );

  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.filterText = $event.target.value;
    else
      this.filterText = 'null';

    this.GetDeposite();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "stateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowIXCDepCSS = '';
        this.upDowCLECDepCSS = '';
        this.upDowIXCPayCSS = '';
        this.upDowCLECPayCSS = '';
        this.upDowNoteCSS = '';
      }
      else if (sortBy === "IXCDep") {
        this.upDowStateNameCSS = '';
        this.upDowIXCDepCSS = 'fa fa-arrow-down';
        this.upDowCLECDepCSS = '';
        this.upDowIXCPayCSS = '';
        this.upDowCLECPayCSS = '';
        this.upDowNoteCSS = '';
      }
      else if (sortBy === "CLECDep") {
        this.upDowStateNameCSS = '';
        this.upDowIXCDepCSS = '';
        this.upDowCLECDepCSS = 'fa fa-arrow-down';
        this.upDowIXCPayCSS = '';
        this.upDowCLECPayCSS = '';
        this.upDowNoteCSS = '';
      }
      else if (sortBy === "IXCPay") {
        this.upDowStateNameCSS = '';
        this.upDowIXCDepCSS = '';
        this.upDowCLECDepCSS = '';
        this.upDowIXCPayCSS = 'fa fa-arrow-down';
        this.upDowCLECPayCSS = '';
        this.upDowNoteCSS = '';
      }
      else if (sortBy === "CLECPay") {
        this.upDowStateNameCSS = '';
        this.upDowIXCDepCSS = '';
        this.upDowCLECDepCSS = '';
        this.upDowIXCPayCSS = '';
        this.upDowCLECPayCSS = 'fa fa-arrow-down';
        this.upDowNoteCSS = '';
      }
      else if (sortBy === "Notes") {
        this.upDowStateNameCSS = '';
        this.upDowIXCDepCSS = '';
        this.upDowCLECDepCSS = '';
        this.upDowIXCPayCSS = '';
        this.upDowCLECPayCSS = '';
        this.upDowNoteCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "stateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-up';
        this.upDowIXCDepCSS = '';
        this.upDowCLECDepCSS = '';
        this.upDowIXCPayCSS = '';
        this.upDowCLECPayCSS = '';
        this.upDowNoteCSS = '';
      }
      else if (sortBy === "IXCDep") {
        this.upDowStateNameCSS = '';
        this.upDowIXCDepCSS = 'fa fa-arrow-up';
        this.upDowCLECDepCSS = '';
        this.upDowIXCPayCSS = '';
        this.upDowCLECPayCSS = '';
        this.upDowNoteCSS = '';
      }
      else if (sortBy === "CLECDep") {
        this.upDowStateNameCSS = '';
        this.upDowIXCDepCSS = '';
        this.upDowCLECDepCSS = 'fa fa-arrow-up';
        this.upDowIXCPayCSS = '';
        this.upDowCLECPayCSS = '';
        this.upDowNoteCSS = '';
      }
      else if (sortBy === "IXCPay") {
        this.upDowStateNameCSS = '';
        this.upDowIXCDepCSS = '';
        this.upDowCLECDepCSS = '';
        this.upDowIXCPayCSS = 'fa fa-arrow-up';
        this.upDowCLECPayCSS = '';
        this.upDowNoteCSS = '';
      }
      else if (sortBy === "CLECPay") {
        this.upDowStateNameCSS = '';
        this.upDowIXCDepCSS = '';
        this.upDowCLECDepCSS = '';
        this.upDowIXCPayCSS = '';
        this.upDowCLECPayCSS = 'fa fa-arrow-up';
        this.upDowNoteCSS = '';
      }
      else if (sortBy === "Notes") {
        this.upDowStateNameCSS = '';
        this.upDowIXCDepCSS = '';
        this.upDowCLECDepCSS = '';
        this.upDowIXCPayCSS = '';
        this.upDowCLECPayCSS = '';
        this.upDowNoteCSS = 'fa fa-arrow-up';
      }
    }
    this.GetDeposite();
  }

  addDeposite() {
    let navigation = '/admin/' + this.SelectedTool + '/deposite/manage';
    this.router.navigate([navigation]);
  }

  updateDeposite(payRateId) {
    let navigation = '/admin/' + this.SelectedTool + '/deposite/manage/' + payRateId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.payRateId = id;
  }

  deleteDeposite(payRateId) {
    this.isloading = true;
    this.crraDepositeMaster.DeleteDeposite(this.tokenKey, payRateId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
            this.showMessage("Deposite Deleted successfully", 'Success', 'success')
        }
        this.GetDeposite();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  onFilterSelectAll($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetDeposite();
  }

  onFilterDeSelectAll($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetDeposite();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
