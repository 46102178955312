import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { IPRModel } from 'src/Models/TrfaAlertsModel';
import { TrfaAlertService } from 'src/Services/alert.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-manage-rmd-alerts',
  templateUrl: './manage-rmd-alerts.component.html',
  styleUrls: ['./manage-rmd-alerts.component.css']
})
export class ManageRmdAlertsComponent implements OnInit {

  tokenKey: string = '';
  userId: number = 0;
  SelectedTool: string;
  productId: number = 1;
  multiSelectDropDownHeight: number = 200;


  IPRModel = new IPRModel()

  addUpdateRMDAlert = new FormGroup({
    updateId: new FormControl(''),
    updateDate: new FormControl(''),
    providerName: new FormControl(''),
    //description: new FormControl(''),
    isNotify: new FormControl(0),
  });

  isIprAlerts: boolean = true;
  updateId: number = 0;
  isDataLoad: boolean = false;
  isViewMode: boolean = false;

  isNotify: boolean = true;

  pageNumber: number;
  pageSize: number;
  Email: string = '';
  Name: string = '';
  orderBy: string;

  constructor(private activedRoute: ActivatedRoute, private checkAuth: CheckAuth, public trfaAlertServices: TrfaAlertService,
    private userServices: UsersService, private toastr: ToastrService, private router: Router, private titleService: Title) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum.RMD;
    this.titleService.setTitle('Manage RMD Alert');
  }


  ngOnInit() {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.orderBy = 'updateId ASC'
    this.activedRoute.params.subscribe((params: Params) => {

      if (params['id']) {
        if (params['id'] != null) {
          this.isIprAlerts = false;
          this.updateId = params['id'];
          this.userId = params['userid'];
          this.GetIPRAlertsByUsers();
        }
      }
      else {
        this.userId = 0;
      }
    });
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  GetIPRAlertsByUsers(): void {
    this.isDataLoad = true;
    this.trfaAlertServices.GetRMDAlertsByUsers(this.tokenKey, this.updateId, this.pageNumber, this.pageSize, this.userId, this.orderBy).subscribe(
      data => {
        if (data["rmdUser"] != null && data["status"] != "Failure") {

          this.IPRModel = data['rmdUser'][0];

          this.Email = this.IPRModel.email;
          this.Name = this.IPRModel.name;

          if (this.IPRModel != null) {
            let updateDate = new Date(this.IPRModel.updateDate);

            let dd1 = updateDate.getDate().toString();
            if (parseInt(dd1.toString()) < 10)
              dd1 = '0' + dd1.toString();

            let mm1 = (updateDate.getMonth() + 1).toString();
            if (parseInt(mm1.toString()) < 10)
              mm1 = '0' + mm1.toString();

            this.IPRModel.updateDate = mm1 + '/' + dd1 + '/' + updateDate.getFullYear();
          }

          this.addUpdateRMDAlert.setValue({
            updateId: this.IPRModel.updateId,
            updateDate: this.IPRModel.updateDate,
            providerName: this.IPRModel.providerName,
            //description: this.Alerts911Model.description,
            isNotify: this.IPRModel.isNotify
          });

          this.isDataLoad = false;
        }
        else {
          this.isDataLoad = false;
          this.showMessage(data["message"], 'Error', 'error');
        }
      }, error => {
        this.isDataLoad = false;
        this.showMessage(error, 'Error', 'error');
      })

  }


  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  isAlertDate(dateArray: any) {
    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      this.IPRModel.updateDate = mm1 + '/' + dd1 + '/' + dateArray.getFullYear();
    }
    else
      this.IPRModel.updateDate = null;
  }

  saveaddUpdateRMDAlert(addUpdateRMDAlert: any) {
    if (addUpdateRMDAlert.value['updateDate'] == '') {
      this.showMessage('Please select alert date', 'Error', 'error');
      return;
    }
    let currentDate = new Date();
    if (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) > addUpdateRMDAlert.value["updateDate"]) {
      this.showMessage('Previous alert date not allow', 'Error', 'error');
      return;
    }

    if (addUpdateRMDAlert.value['providerName'] == '' || typeof (addUpdateRMDAlert.value['providerName']) == 'undefined') {
      this.showMessage('Please enter providerName', 'Error', 'error');
      return;
    }
    if (addUpdateRMDAlert.value['providerName'] != '') {
      if (addUpdateRMDAlert.value['providerName'].toString().trim() == '') {
        this.showMessage('Please enter providerName', 'Error', 'error');
        return;
      }
    }

    // let planText = this.removeHTMLTags(addUpdateRMDAlert.value['description']);
    // if (planText === false) {
    //   this.showMessage('Please enter description', 'Error', 'error');
    //   return;
    // }
    // if (planText != '') {
    //   if (planText.trim() == '') {
    //     this.showMessage('Please enter description', 'Error', 'error');
    //     return;
    //   }
    // }

    if (typeof (addUpdateRMDAlert.value['updateId']) == 'undefined') {
      this.IPRModel.updateId = 0;
    }
    else {
      this.IPRModel.updateId = addUpdateRMDAlert.value['updateId'];
    }

    if (typeof (addUpdateRMDAlert.value['providerName']) == 'undefined') {
      this.IPRModel.providerName = '';
    }
    else {
      this.IPRModel.providerName = addUpdateRMDAlert.value['providerName'];
    }

    // if (typeof (addUpdateRMDAlert.value['description']) == 'undefined') {
    //   this.Alerts911Model.description = '';
    // }
    // else {
    //   this.Alerts911Model.description = addUpdateRMDAlert.value['description'];
    // }
    this.IPRModel.isNotify = this.isNotify;
    this.IPRModel.userId = this.userId;
    this.IPRModel.productId = this.productId;

    this.trfaAlertServices.addUpdateRMDAlerts(this.tokenKey, this.IPRModel).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.showMessage('RMD alerts Updated successfully', 'Success', 'success');
          this.router.navigate(['/admin/rmd/rmd-Alerts']);
        }
        else
          this.showMessage(data['status'], 'Error', 'error');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  removeHTMLTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  }

  setSendEmailNotificationChecked($event) {
    this.isNotify = $event.target.checked;
  }

}
