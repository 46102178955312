import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { InteserraResourcesModel } from 'src/Models/InteserraResourcesModel';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-inteserra-resources',
  templateUrl: './inteserra-resources.component.html',
  styleUrls: ['./inteserra-resources.component.css']
})
export class InteserraResourcesComponent implements OnInit {

  listOfInteserraResources: InteserraResourcesModel[];
  filterModel = new FilterModel();
  tokenKey: string = '';
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  videoURL: any;
  videoType: string;
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  @ViewChild("videoModel", { static: false }) videoModel: ElementRef;
  topicTitle: string = ''
  isEmbed: boolean = false;

  constructor(private i360Service: I360Service, private checkAuth: CheckAuth, private titleService: Title,
    private toastr: ToastrService, private appActivity: ApplicationActivityService,private sanitizer: DomSanitizer) {
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
      this._userID = sessionStorage.getItem('UserId');
      this.tokenKey = sessionStorage.getItem('tokenValue');
      this.filterModel.pageNumber = '1';
      this.filterModel.pageSize = '10';
      this.filterModel.orderBy = 'SortOrder';
      this.filterModel.userID = this._userID;
      this.filterModel.productId = ToolEnum.inteserraResources;
      this.titleService.setTitle('Inteserra Resources | Inteserra - i360');
     }

     tokenExpireCalculation(startDate: string, tokenExpiry: string) {
      this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
    }

  ngOnInit() {
    this.Geti360InteserraResources();
  }

  
  Geti360InteserraResources() {
    this.i360Service.i360InteserraResourcesGet(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.listOfInteserraResources = data['listOfInteserraResources'];
        if (this.listOfInteserraResources != null) {
          if (this.listOfInteserraResources.length > 0) {
            this.totalRecordCount = this.listOfInteserraResources[0].totalRecordCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
        else {
          this.totalRecordCount = 0;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  donwloadFile(data: InteserraResourcesModel) {
    this.topicTitle = '';
    this.isEmbed = data.isEmbed;
    if (data.isLocked)
      return;

    this.topicTitle = data.title;

    if (data.linktype == 1 || data.linktype == 0) {
        ApplicationActivityStore('View', 'Inteserra Resources', this.appActivity, data.resourcesId, ActionActivity['Inteserra Resources'], data.title);
        if(data.externalURL.includes('youtube'))
        {
          this.videoURL = data.externalURL;
          this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.externalURL);
          this.videoModel.nativeElement.click();
        }
        else{
          window.open(data.externalURL, '_blank');
        }
      // }
    }

    else if (data.linktype == 2) {
      const extension = data.docPath.split(".").pop();
      if (extension == 'ogg' || extension == 'ogv' || extension == 'mp4' || extension == 'webm') {
        ApplicationActivityStore('View', 'Inteserra Resources', this.appActivity, data.resourcesId, ActionActivity['Inteserra Resources'], data.title);
        this.videoType = 'video/' + extension;
        this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.docPath);
        this.videoModel.nativeElement.click();
        this.videoplayer.nativeElement.load();
        this.videoplayer.nativeElement.play();
      }

      else {
        ApplicationActivityStore('Download', 'Inteserra Resources', this.appActivity, data.resourcesId, ActionActivity['Inteserra Resources'], data.displayDocName);
        this.isShowprogressSpinner = true;
        this.blockedDocument = true;
        this.i360Service.DownloadDocument(this.tokenKey, { DocType: 'Inteserra Resources', DocPath: data.docPath }).subscribe(
          fileData => {
            this.isShowprogressSpinner = false;
            this.blockedDocument = false;
            if (fileData != null) {
              FileSaver.saveAs(fileData, data.displayDocName);
              this.toastr.success('File download successfully', 'File Downloaded')
            }
          }, error => {
            this.isShowprogressSpinner = false;
            this.blockedDocument = false;
            console.log(error);
          });
      }
      }
    }
    closeVideoPlayer() {
      this.videoplayer.nativeElement.pause();
      this.videoplayer.nativeElement.currentTime = 0;
    }

  toggleVideo(event: any) {
    var myVideo: any = this.videoplayer.nativeElement
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.Geti360InteserraResources();
  }

  // Disable Mouse Right Click
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    if (event != undefined)
      event.preventDefault();
  }

  // Disable Keyboard
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.returnValue = false;
    event.preventDefault();
  }

}
