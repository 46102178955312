import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompleted, getIncompletedStep } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-price-quote-notes',
  templateUrl: './price-quote-notes.component.html',
  styleUrls: ['./price-quote-notes.component.css']
})
export class PriceQuoteNotesComponent implements OnInit {
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;

  mainUrl: string;
  subUrl: string;

  priceQuoteId:number=0;
  isSubmit: boolean = false;
  isEdit: boolean = false;
  currentActiveTab: number = 1;
  notes:string;
  clientInformationId:number=0;
  companyId:number=0;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute, private globle: Globle,
    private router: Router, private PricingQuotesMaster: PricingQuotesService, private toastr: ToastrService,
    private titleService: Title, private userService: UsersService) {
      this.SelectedTool = sessionStorage.getItem('UserLoginTool');
      this.userID = sessionStorage.getItem('UserId');
      this.tokenKey = sessionStorage.getItem('tokenValue');
      this.titleService.setTitle('Company Profile');
      this.companyId=+sessionStorage.getItem('CompanyId');
      // this.companyName = sessionStorage.getItem('CompanyName');

      this.activedRoute.params.subscribe((params: Params) => {
        if (params['id']) {
          if (params['id'] != null) {
            this.clientInformationId = params['id'];
          }
        }
      });

      this.mainUrl = '/View/';
    if (this.clientInformationId != null) {
      this.GetProgressStepDetailsById(this.clientInformationId);
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isCompanyProfileDetailsCompleted = null;
      this.globle.cApp.statusModel.isAddCompQueCompleted = null;
      this.globle.cApp.statusModel.isStateSelectionCompleted = null;
      // this.globle.cApp.statusModel.isAttechedDetailsCompleted = null;
      this.globle.cApp.statusModel.isNotes=null;
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }

     }

  ngOnInit() {
    this.getPriceQuoteListByCompanyId(this.companyId)
  }

  getPriceQuoteListByCompanyId(companyId: number) {
    this.blockDocument();
    this.PricingQuotesMaster.getPriceQuoteListByCompanyId(this.tokenKey, companyId, false).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.unblockDocument();
          this.notes = result['pricingQuotes'][0].notes;
          // this.clientInformationId=result['clientInformation'].clientInformationId;
          this.priceQuoteId= result['pricingQuotes'][0].priceQuoteId;
        }
        else{
          this.unblockDocument();
        }
      });
  }

  save(clientForm: any) {
    this.isSubmit = true;
   
    let value = {
      clientInformationId: this.clientInformationId,
      priceQuoteId:this.priceQuoteId,
      notes:this.notes,
      createdBy:this.userID
    }

    this.blockDocument();
    this.PricingQuotesMaster.NotesAddUpdate(this.tokenKey, value).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isAddCompQueCompleted = true;
          this.unblockDocument();
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          }
          else {
            this.showMessage('Company Profile saved successfully', 'success', 'success');
            this.router.navigate(['/View/client-profile']);
          }
        }
        else {
          this.unblockDocument();
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  previousStep() {
    // let navigation = '/admin/' + this.SelectedTool + '/company-info/' + this.clientInformationId;
    let navigation = '/View/state-selection/' + this.clientInformationId;
    this.router.navigate([navigation]);
  }

  nextStep() {
    // let navigation = '/admin/' + this.SelectedTool + '/state-selection';
    let navigation = '/View/state-selection';
    this.router.navigate([navigation]);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  activeTab(url: string, tabId: number) {

    if (this.clientInformationId != null) {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.clientInformationId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.clientInformationId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(clientInformationId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsByClientInformationId(this.tokenKey, this.clientInformationId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
          this.activeTab('notes', 13);
        }
      });
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

}
