import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Console } from 'console';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { ToolEnum } from 'src/Models/Common';
import { CompanyModel } from 'src/Models/CompanyModel';
import { CompanyService } from 'src/Services/company.service';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { ProductsService } from 'src/Services/products.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';
import { AdminLayoutComponent } from 'src/app/Layout/admin-layout/admin-layout.component';

@Component({
  selector: 'app-managecompany',
  templateUrl: './managecompany.component.html',
  styleUrls: ['./managecompany.component.css']
})
export class ManageCompanyComponent implements OnInit {
  // Product List---------------
  selectedItemsProduct: any;
  companyProduct: any;
  productFilter: string = '';
  listOfProductID = [];
  selectedProducts = [];

  companyForm: FormGroup;
  loginuserID: number = 0;
  tokenKey: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  statusCode: number = 0;
  SelectedTool: string;
  companyId = 0
  companyModel: CompanyModel = {
    companyId: 0,
    companyName: '',
    sfAccount: '',
    isDownload: false,
    companyProducts: [],
    isActive: true,
    is_FTP_Access: false,
    isFTP911Access: false,
    dba: '',
    notes: '',
    clientCompanyId: 0,
    priceQuoteId: 0
  };

  isMultipleClick = false;
  isloadingstates = false;

  selectedAllWireless: boolean;
  selectedAllVoIP: boolean;
  selectedAlliPR: boolean;
  selectedAllAction: boolean;
  selectedAllBriefing: boolean;
  selectedAllBriefingCarriers: boolean;
  selectedAllTraining: boolean;
  selectedAllSpecialReport: boolean;
  selectedAllTaxExemptionForm: boolean;

  selectedAllWebinar: boolean;
  selectedAllInteserraResources: boolean;
  selectedAllWhitepapers: boolean;

  selectedWirelessCount = 0;
  selectedVoIPCount = 0;
  selectedIPRCount = 0;
  selectedActionCount = 0;
  selectedBriefingCount = 0;
  selectedBriefingCarriersCount = 0;
  devideRowCount = 0;
  selectedTrainingCount = 0;
  selectedState911Count = 0;
  selectedSpecialReportCount = 0;
  selectedTaxExemptionFormCount = 0;

  selectedWebinarCount = 0;
  selectedInteserraResourcesCount = 0;
  selectedWhitepapersCount = 0;
  selectedStateTRFACount = 0;
  selectedStateCRRACount = 0;

  isWirelessPrimaryContact: boolean = false;
  isIPRPrimaryContact: boolean = false;
  isVoIPPrimaryContact: boolean = false;
  isTractItPrimaryContact: boolean = false;
  isBriefingPrimaryContact: boolean = false;
  isTrainingPrimaryContact: boolean = false;
  stateList: any[] = [];
  selectedAll911State: boolean;
  isSpecialReportPrimaryContact: boolean = false;
  isWebinarPrimaryContact: boolean = false;
  isInteserraResourcesPrimaryContact: boolean = false;
  isWhitepapersPrimaryContact: boolean = false;
  selectedAllTRFA: boolean;
  selectedAllCRRA: boolean;
  public toolEnum = ToolEnum;

  isDownload: boolean = false;
  selecteddownload: boolean = false;
  isFTPAccess: boolean = false;
  isFTP911Access: boolean = false;
  selectedFTPAccess: boolean = false;
  selected911FTPAccess: boolean = false;
  exportCitiationList: any[] = [];
  PriceQuoteStateList: any[];
  isPriceQuoteStateList: boolean = false;
  tefCount: boolean = false;
  intResCount: boolean = false;
  typeOfCompanyList: any[] = [];

  dropdownSettingsCompanyType = {};
  dropdownListCompanyType = [];
  selectedItemsCompanyType = [];

  dropdownListCompany = [];

  dropdownSettingsParentCompany = {};
  selectedItemsParentCompany = [];

  dropdownSettingsPrimaryCompany = {};
  selectedItemsPrimaryCompany = [];

  isExist: string = "true";
  selectedParentOption: string;
  selectedChildOption: string;
  selectedAffiliateOption: string;
  isChild: boolean = false;
  isAffiliate: boolean = false;
  clientInformationId:number=0;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private companyService: CompanyService
    , private activedRoute: ActivatedRoute, private toastr: ToastrService
    , private router: Router, private titleService: Title, private productsService: ProductsService,
    private stateService: StateMasterService, private UserService: UsersService, private adminLayout: AdminLayoutComponent, private PricingQuotesMaster: PricingQuotesService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.titleService.setTitle('Manage Company');

    this.dropdownSettingsCompanyType = {
      singleSelection: true,
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No company type available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsParentCompany = {
      singleSelection: true,
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No company type available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsPrimaryCompany = {
      singleSelection: true,
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No company type available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.selectedParentOption = "3";
    this.selectedChildOption = "3";
    this.selectedAffiliateOption = "3";

    this.GetTypeOfCompany();
  }

  ngOnInit() {
    this.movetotop();
    this.activedRoute.params.subscribe((params: Params) => {

      if (params['clientInformationId']) {
        if (params['clientInformationId'] != null) {
          this.clientInformationId = params['clientInformationId'];
        }
      }

      if (params['id']) {
        if (params['id'] != null) {
          this.companyId = params['id'];

          this.GetPriceQuoteSubscriptionState(this.companyId);
          this.getCompany();
        }
      }
      else {
        this.companyId = 0;
        this.Getstates();
        this.GetProductData();
      }
    });

  }

  Getstates(): void {
    this.isloadingstates = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyService.GetStateFindByCompanyID(this.tokenKey, this.companyId).subscribe(
      data => {
        this.companyModel.wirelessStates = data.wirelessStates;
        this.companyModel.iprStates = data.iprStates;
        this.companyModel.voIPStates = data.voIPStates;
        this.companyModel.actionStates = data.actionStates;
        this.companyModel.briefingStates = data.briefingStates;
        this.companyModel.carrierCategory = data.carrierCategory;
        this.companyModel.training = data.training;
        this.companyModel.state911 = data.state911;
        this.companyModel.specialReport = data.specialReport;
        this.companyModel.taxExemptionForm = data.taxExemptionForm;

        this.companyModel.webinars = data.webinars;
        this.companyModel.inteserraResources = data.inteserraResources;
        this.companyModel.whitePapers = data.whitePapers;
        this.companyModel.trfa = data.trfa;
        this.companyModel.crra = data.crra;

        if (this.companyModel.taxExemptionForm.length != 0) {
          this.tefCount = true;
        }

        if (this.companyModel.inteserraResources.length != 0) {
          this.intResCount = true;
        }

        if (this.companyId == 0) {

          for (let index = 0; index < this.companyProduct.length; index++) {
            const element = this.companyProduct[index];
            if (element.productId == 23 || element.productId == 27 || element.productId == 40 || element.productId == 28 || element.productId == 29 || element.productId == 35 || element.productId == 37 || element.productId == 38 || element.productId == 39 || element.productId == 2) {
              if (element.productId == 23) {
                if (this.companyModel.training.find(x => x.isAllowallAccess == true) != null) {
                  element.licenceCount = 1000;
                  element.isSelected = true;

                  for (let xindex = 0; xindex < this.companyModel.training.length; xindex++) {
                    const xelement = this.companyModel.training[xindex];
                    if (xelement.isAllowallAccess) {
                      xelement.isSelected = true;
                    }
                  }

                }
              }
              else if (element.productId == 27) {
                if (this.companyModel.specialReport.find(x => x.isAllowallAccess == true) != null) {
                  element.licenceCount = 1000;
                  element.isSelected = true;
                  for (let xindex = 0; xindex < this.companyModel.specialReport.length; xindex++) {
                    const xelement = this.companyModel.specialReport[xindex];
                    if (xelement.isAllowallAccess) {
                      xelement.isSelected = true;
                    }
                  }
                }
              }
              else if (element.productId == 40) {
                if (this.companyModel.taxExemptionForm.find(x => x.isAllowallAccess == true) != null) {
                  element.licenceCount = 1000;
                  element.isSelected = true;
                  for (let xindex = 0; xindex < this.companyModel.taxExemptionForm.length; xindex++) {
                    const xelement = this.companyModel.taxExemptionForm[xindex];
                    if (xelement.isAllowallAccess) {
                      xelement.isSelected = true;
                    }
                  }
                }
              }
              else if (element.productId == 28) {
                if (this.companyModel.webinars.find(x => x.isAllowallAccess == true) != null) {
                  element.licenceCount = 1000;
                  element.isSelected = true;
                  for (let xindex = 0; xindex < this.companyModel.webinars.length; xindex++) {
                    const xelement = this.companyModel.webinars[xindex];
                    if (xelement.isAllowallAccess) {
                      xelement.isSelected = true;
                    }
                  }
                }
              }
              else if (element.productId == 35) {
                if (this.companyModel.inteserraResources.find(x => x.isAllowallAccess == true) != null) {
                  element.licenceCount = 1000;
                  element.isSelected = true;
                  for (let xindex = 0; xindex < this.companyModel.inteserraResources.length; xindex++) {
                    const xelement = this.companyModel.inteserraResources[xindex];
                    if (xelement.isAllowallAccess) {
                      xelement.isSelected = true;
                    }
                  }
                }
              }
              else if (element.productId == 29) {
                if (this.companyModel.whitePapers.find(x => x.isAllowallAccess == true) != null) {
                  element.licenceCount = 1000;
                  element.isSelected = true;

                  for (let xindex = 0; xindex < this.companyModel.whitePapers.length; xindex++) {
                    const xelement = this.companyModel.whitePapers[xindex];
                    if (xelement.isAllowallAccess) {
                      xelement.isSelected = true;
                    }
                  }
                }
              }

              else if (element.productId == 2) {
                if (this.companyModel.iprStates.find(x => x.isSelected == false) != null) {
                  element.licenceCount = 1000;
                  element.isSelected = true;

                  for (let xindex = 0; xindex < this.companyModel.iprStates.length; xindex++) {
                    const xelement = this.companyModel.iprStates[xindex];
                  
                      xelement.isSelected = true;
                      this.selectedAlliPR = true;
                    
                  }
                }
              } 
              else if (element.productId == 37 || element.productId == 38 || element.productId == 39) {
                element.licenceCount = 1000;
                element.isSelected = true;
              }
            }
          }

          this.calculateCheckedCount();
        }

        this.unblockDocument();
        this.isloadingstates = false;
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
        this.isloadingstates = false;
      }
    );
  }

  getCompany() {
    this.isloadingstates = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyService.getCompanyFindByCompanyId(this.tokenKey, this.companyId, this.loginuserID).subscribe(
      data => {

        this.companyModel = data.companyModel;
        this.selecteddownload = this.companyModel.isDownload;
        this.selectedFTPAccess = this.companyModel.is_FTP_Access;
        this.selected911FTPAccess = this.companyModel.isFTP911Access;

        this.isDownload = this.companyModel.isDownload;;
        this.isFTP911Access = this.companyModel.isFTP911Access;
        this.isFTPAccess = this.companyModel.is_FTP_Access;

        if (this.companyModel.taxExemptionForm.length != 0) {
          this.tefCount = true;
        }

        if (this.companyModel.inteserraResources.length != 0) {
          this.intResCount = true;
        }

        this.unblockDocument();
        const selectedWirelessStates = this.companyModel.wirelessStates.filter((item) => item.isSelected);
        if (selectedWirelessStates.length == this.companyModel.wirelessStates.length) {
          this.selectedAllWireless = true;
        }
        const selectedAlliPR = this.companyModel.iprStates.filter((item) => item.isSelected);
        if (selectedAlliPR.length == this.companyModel.iprStates.length) {
          this.selectedAlliPR = true;
        }

        const selectedVoIPStates = this.companyModel.voIPStates.filter((item) => item.isSelected);
        if (selectedVoIPStates.length == this.companyModel.voIPStates.length) {
          this.selectedAllVoIP = true;
        }

        const selectedActionStates = this.companyModel.actionStates.filter((item) => item.isSelected);
        if (selectedActionStates.length == this.companyModel.actionStates.length) {
          this.selectedAllAction = true;
        }

        const selectedAllBriefingStates = this.companyModel.briefingStates.filter((item) => item.isSelected);
        if (selectedAllBriefingStates.length == this.companyModel.briefingStates.length) {
          this.selectedAllBriefing = true;
        }
        const selectedAllBriefingCarriers = this.companyModel.carrierCategory.filter((item) => item.isSelected);
        if (selectedAllBriefingCarriers.length == this.companyModel.carrierCategory.length) {
          this.selectedAllBriefingCarriers = true;
        }

        const selectedAllTraining = this.companyModel.training.filter((item) => item.isSelected);
        if (selectedAllTraining.length == this.companyModel.training.length) {
          this.selectedAllTraining = true;
        }
        const selectedAll911State = this.companyModel.state911.filter((item) => item.isSelected);
        if (selectedAll911State.length == this.companyModel.state911.length) {
          this.selectedAll911State = true;
        }

        const selectedAllTRFAState = this.companyModel.trfa.filter((item) => item.isSelected);
        if (selectedAllTRFAState.length == this.companyModel.trfa.length) {
          this.selectedAllTRFA = true;
        }

        const selectedAllCRRAState = this.companyModel.crra.filter((item) => item.isSelected);
        if (selectedAllCRRAState.length == this.companyModel.crra.length) {
          this.selectedAllCRRA = true;
        }

        const selectedAllSpecialReport = this.companyModel.specialReport.filter((item) => item.isSelected);
        if (selectedAllSpecialReport.length == this.companyModel.specialReport.length) {
          this.selectedAllSpecialReport = true;
        }

        const selectedAllTaxExemptionForm = this.companyModel.taxExemptionForm.filter((item) => item.isSelected);
        if (selectedAllTaxExemptionForm.length == this.companyModel.taxExemptionForm.length) {
          this.selectedAllTaxExemptionForm = true;
        }

        const selectedAllWebinar = this.companyModel.webinars.filter((item) => item.isSelected);
        if (selectedAllWebinar.length == this.companyModel.webinars.length) {
          this.selectedAllWebinar = true;
        }

        const selectedAllInteserraResources = this.companyModel.inteserraResources.filter((item) => item.isSelected);
        if (selectedAllInteserraResources.length == this.companyModel.inteserraResources.length) {
          this.selectedAllInteserraResources = true;
        }

        const selectedAllWhitepapers = this.companyModel.whitePapers.filter((item) => item.isSelected);
        if (selectedAllWhitepapers.length == this.companyModel.whitePapers.length) {
          this.selectedAllWhitepapers = true;
        }

        this.selectedParentOption = this.companyModel.isParent;

        if (this.companyModel.isChild == "1")
          this.isChild = true;
        this.selectedChildOption = this.companyModel.isChild;

        if (this.companyModel.isAffiliate == "1")
          this.isAffiliate = true;
        this.selectedAffiliateOption = this.companyModel.isAffiliate;

        if (this.companyModel.typeOfCompany != null) {
          let SelectedCompanyData = this.dropdownListCompanyType.filter(a => a.id == this.companyModel.typeOfCompany);
          if (SelectedCompanyData != null && SelectedCompanyData.length > 0) {
            this.selectedItemsCompanyType.push(
              { 'id': SelectedCompanyData[0].id, 'itemName': SelectedCompanyData[0].itemName }
            );
          }

        }

        if (this.isChild) {
          let SelectedCompanyData = this.dropdownListCompany.filter(a => a.id == this.companyModel.parentCompanyId);
          if (SelectedCompanyData != null && SelectedCompanyData.length > 0) {
            this.selectedItemsParentCompany.push(
              { 'id': SelectedCompanyData[0].id, 'itemName': SelectedCompanyData[0].itemName }
            );
          }
        }

        if (this.isAffiliate) {
          let SelectedCompanyData = this.dropdownListCompany.filter(a => a.id == this.companyModel.primaryCompanyId);
          if (SelectedCompanyData != null && SelectedCompanyData.length > 0) {
            this.selectedItemsPrimaryCompany.push(
              { 'id': SelectedCompanyData[0].id, 'itemName': SelectedCompanyData[0].itemName }
            );
          }
        }

        this.calculateCheckedCount();
        this.isloadingstates = false;
        this.GetProductData();
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
        this.isloadingstates = false;
      }
    );

  }

  checkProductSelected(productId) {
    if (this.companyProduct) {
      return this.companyProduct.find(x => x.productId == productId && x.isSelected == true) != null;
    }
  }

  selectAll(productType) {
    switch (productType) {
      case ToolEnum.wireless:
        for (let i = 0; i < this.companyModel.wirelessStates.length; i++) {
          this.companyModel.wirelessStates[i].isSelected = this.selectedAllWireless;
        }
        break;
      case ToolEnum.ipr:
        for (let i = 0; i < this.companyModel.iprStates.length; i++) {
          this.companyModel.iprStates[i].isSelected = this.selectedAlliPR;
        }
        break;
      case ToolEnum.voip:
        for (let i = 0; i < this.companyModel.voIPStates.length; i++) {
          this.companyModel.voIPStates[i].isSelected = this.selectedAllVoIP;
        }
      case ToolEnum.actionitem:
        for (let i = 0; i < this.companyModel.actionStates.length; i++) {
          this.companyModel.actionStates[i].isSelected = this.selectedAllAction;
        }
        break;
      case ToolEnum.briefingServices:
        for (let i = 0; i < this.companyModel.briefingStates.length; i++) {
          this.companyModel.briefingStates[i].isSelected = this.selectedAllBriefing;
        }
        break;
      case ToolEnum.carrierTariffs:
        for (let i = 0; i < this.companyModel.carrierCategory.length; i++) {
          this.companyModel.carrierCategory[i].isSelected = this.selectedAllBriefingCarriers;
        }
        break;
      case ToolEnum.training:
        for (let i = 0; i < this.companyModel.training.length; i++) {
          this.companyModel.training[i].isSelected = this.selectedAllTraining;
        }
        break;
      case ToolEnum.state911:
        for (let i = 0; i < this.companyModel.state911.length; i++) {
          this.companyModel.state911[i].isSelected = this.selectedAll911State;
        }
        break;
      case ToolEnum.trfa:
        for (let i = 0; i < this.companyModel.trfa.length; i++) {
          this.companyModel.trfa[i].isSelected = this.selectedAllTRFA;
        }
        break;
      case ToolEnum.crra:
        for (let i = 0; i < this.companyModel.crra.length; i++) {
          this.companyModel.crra[i].isSelected = this.selectedAllCRRA;
        }
        break;
      case ToolEnum.specialReport:
        for (let i = 0; i < this.companyModel.specialReport.length; i++) {
          this.companyModel.specialReport[i].isSelected = this.selectedAllSpecialReport;
        }
        break;
      case ToolEnum.TaxExemptionForms:
        for (let i = 0; i < this.companyModel.taxExemptionForm.length; i++) {
          this.companyModel.taxExemptionForm[i].isSelected = this.selectedAllTaxExemptionForm;
        }
        break;
      case ToolEnum.webinar:
        for (let i = 0; i < this.companyModel.webinars.length; i++) {
          this.companyModel.webinars[i].isSelected = this.selectedAllWebinar;
        }
        break;
      case ToolEnum.inteserraResources:
        for (let i = 0; i < this.companyModel.inteserraResources.length; i++) {
          this.companyModel.inteserraResources[i].isSelected = this.selectedAllInteserraResources;
        }
        break;
      case ToolEnum.whitepapers:
        for (let i = 0; i < this.companyModel.whitePapers.length; i++) {
          this.companyModel.whitePapers[i].isSelected = this.selectedAllWhitepapers
        }
        break;
    }
    this.calculateCheckedCount();
  }

  checkIfAllSelected(productType) {

    switch (productType) {
      case ToolEnum.wireless:
        this.selectedAllWireless = this.companyModel.wirelessStates.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.ipr:
        this.selectedAlliPR = this.companyModel.iprStates.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.voip:
        this.selectedAllVoIP = this.companyModel.voIPStates.every(function (item: any) {
          return item.isSelected == true;
        });
      case ToolEnum.actionitem:
        this.selectedAllAction = this.companyModel.actionStates.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.briefingServices:
        this.selectedAllBriefing = this.companyModel.briefingStates.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.carrierTariffs:
        this.selectedAllBriefingCarriers = this.companyModel.carrierCategory.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.training:
        this.selectedAllTraining = this.companyModel.training.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.state911:
        this.selectedAll911State = this.companyModel.state911.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.trfa:
        this.selectedAllTRFA = this.companyModel.trfa.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.crra:
        this.selectedAllCRRA = this.companyModel.crra.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.specialReport:
        this.selectedAllSpecialReport = this.companyModel.specialReport.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.TaxExemptionForms:
        this.selectedAllTaxExemptionForm = this.companyModel.taxExemptionForm.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.webinar:
        this.selectedAllWebinar = this.companyModel.webinars.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.inteserraResources:
        this.selectedAllInteserraResources = this.companyModel.inteserraResources.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.whitepapers:
        this.selectedAllWhitepapers = this.companyModel.whitePapers.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
    }
    this.calculateCheckedCount();
  }


  calculateCheckedCount() {
    this.selectedWirelessCount = this.companyModel.wirelessStates.filter((item) => item.isSelected).length;
    this.selectedIPRCount = this.companyModel.iprStates.filter((item) => item.isSelected).length;
    this.selectedVoIPCount = this.companyModel.voIPStates.filter((item) => item.isSelected).length;
    this.selectedActionCount = this.companyModel.actionStates.filter((item) => item.isSelected).length;
    this.selectedBriefingCount = this.companyModel.briefingStates.filter((item) => item.isSelected).length;
    this.selectedBriefingCarriersCount = this.companyModel.carrierCategory.filter((item) => item.isSelected).length;
    this.selectedTrainingCount = this.companyModel.training.filter((item) => item.isSelected).length;
    this.selectedState911Count = this.companyModel.state911.filter((item) => item.isSelected).length;
    this.selectedStateTRFACount = this.companyModel.trfa.filter((item) => item.isSelected).length;
    this.selectedStateCRRACount = this.companyModel.crra.filter((item) => item.isSelected).length;
    this.selectedSpecialReportCount = this.companyModel.specialReport.filter((item) => item.isSelected).length;
    this.selectedTaxExemptionFormCount = this.companyModel.taxExemptionForm.filter((item) => item.isSelected).length;
    this.selectedWebinarCount = this.companyModel.webinars.filter((item) => item.isSelected).length;
    this.selectedInteserraResourcesCount = this.companyModel.inteserraResources.filter((item) => item.isSelected).length;
    this.selectedWhitepapersCount = this.companyModel.whitePapers.filter((item) => item.isSelected).length;
  }

  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  GetProductData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.productsService.GetProductList(this.tokenKey, this.loginuserID, false, true).subscribe(
      data => {
        if (data.product != null) {
          this.devideRowCount = data.product.length / 2;
          this.devideRowCount = this.devideRowCount.toString().indexOf(".") != -1 ? this.devideRowCount + 1 : this.devideRowCount;
          if (this.companyModel.companyProducts != null) {
            for (let index = 0; index < data.product.length; index++) {
              const element = data.product[index];

              const companyProduct = this.companyModel.companyProducts.find(x => x.productId == element.productId);
              if (companyProduct != null) {
                element.isSelected = true;
                element.isDemo = companyProduct.isDemo;
                element.isComp = companyProduct.isComp;
                element.licenceCount = companyProduct.licenceCount;
              }
              else {
                if (element.productId == 5 || element.productId == 11 || element.productId == 12 || element.productId == 2 || element.productId == 21 || element.productId == 33) {
                  if (this.companyId == 0) {
                    element.licenceCount = 1000;
                    element.isSelected = true;
                  }
                }
              }
            }
          }
          this.companyProduct = data.product;
        }
        else {
          this.showMessage(data['message'], 'Error', 'error');
          this.unblockDocument();
        }

      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }


  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  cancelcompany() {
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['/admin/' + toolName + '/companies']);
  }

  saveCompany(userForm: any) {
    event.preventDefault();
    if ((userForm.value['companyName'] == null || userForm.value['companyName'] == undefined ||
      userForm.value['companyName'].trim() == '')) {
      this.showMessage('Please enter company name.', 'Required', 'error');
      return;
    }
    if (this.companyProduct && this.companyProduct.filter(x => x.isSelected).length == 0) {
      this.showMessage('Please select at least one product(s)', 'Required', 'error');
      return;
    }
    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.wireless) && this.selectedWirelessCount == 0) {
      this.showMessage('Please select atleast one Jurisdiction of Wireless tool.', 'Required', 'error');
      return;
    }
    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.ipr) && this.selectedIPRCount == 0) {
      this.showMessage('Please select atleast one Jurisdiction of IPR tool.', 'Required', 'error');
      return;
    }
    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.voip) && this.selectedVoIPCount == 0) {
      this.showMessage('Please select atleast one Jurisdiction of VoIP tool.', 'Required', 'error');
      return;
    }
    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.actionitem) && this.selectedActionCount == 0) {
      this.showMessage('Please select atleast one Jurisdiction of Trac-It tool.', 'Required', 'error');
      return;
    }

    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.briefingServices) && this.selectedBriefingCount == 0) {
      this.showMessage('Please select atleast one Jurisdiction of Briefing Services tool.', 'Required', 'error');
      return;
    }

    if (this.companyProduct.find(x => x.isSelected && x.id === ToolEnum.briefingServices) && this.selectedBriefingCarriersCount == 0) {
      this.showMessage('Please select atleast one briefing type of Briefing Services tool.', 'Required', 'error');
      return;
    }

    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.training) && this.selectedTrainingCount == 0) {
      this.showMessage('Please select atleast one video of Training tool.', 'Required', 'error');
      return;
    }
    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.state911) && this.selectedState911Count == 0) {
      this.showMessage('Please select atleast one state of 911 tool.', 'Required', 'error');
      return;
    }
    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.trfa) && this.selectedStateTRFACount == 0) {
      this.showMessage('Please select atleast one state of TRFA tool.', 'Required', 'error');
      return;
    }

    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.crra) && this.selectedStateCRRACount == 0) {
      this.showMessage('Please select atleast one state of CRRA tool.', 'Required', 'error');
      return;
    }

    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.specialReport) && this.selectedSpecialReportCount == 0) {
      this.showMessage('Please select atleast one Special report tool.', 'Required', 'error');
      return;
    }
    if (this.companyModel.taxExemptionForm.length != 0) {
      if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.TaxExemptionForms) && this.selectedTaxExemptionFormCount == 0) {
        this.showMessage('Please select atleast one Tax Exemption Forms tool.', 'Required', 'error');
        return;
      }
    }
    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.webinar) && this.selectedWebinarCount == 0) {
      this.showMessage('Please select atleast one Webinar tool.', 'Required', 'error');
      return;
    }

    if (this.companyModel.inteserraResources.length != 0) {
      if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.inteserraResources) && this.selectedInteserraResourcesCount == 0) {
        this.showMessage('Please select atleast one Inteserra Resources tool.', 'Required', 'error');
        return;
      }
    }
    if (this.companyProduct.find(x => x.isSelected && x.productId === ToolEnum.whitepapers) && this.selectedWhitepapersCount == 0) {
      this.showMessage('Please select atleast one White papers tool.', 'Required', 'error');
      return;
    }

    if (this.companyProduct && this.companyProduct.filter(x => x.isSelected && (x.licenceCount == null || x.licenceCount == 0)).length > 0) {
      this.showMessage('Please enter licence value', 'Required', 'error');
      return;
    }

    this.companyModel.companyProducts = this.companyProduct.filter(x => x.isSelected);

   
    userForm.value['createdBy'] = this.loginuserID;
    userForm.value['modifiedBy'] = this.loginuserID;
    let msg = '';
    if (this.loginuserID == 0) {
      msg = 'added';
    }
    else {
      msg = 'updated';
    }

    if (this.isMultipleClick) {
      return;
    }
    this.companyModel.isDownload = this.isDownload;
    this.companyModel.is_FTP_Access = this.isFTPAccess;
    this.companyModel.isFTP911Access = this.isFTP911Access;

    this.companyModel.isParent = this.selectedParentOption;
    this.companyModel.isChild = this.selectedChildOption;
    this.companyModel.isAffiliate = this.selectedAffiliateOption;

    if (this.selectedItemsCompanyType == null || this.selectedItemsCompanyType.length == 0) {
      this.showMessage('Please select type of company', 'Required', 'error');
      return;
    }

    if (this.selectedItemsCompanyType != null && this.selectedItemsCompanyType != undefined)
      this.companyModel.typeOfCompany = +this.selectedItemsCompanyType[0].id;

    if (this.isChild) {
      if (this.selectedItemsParentCompany == null || this.selectedItemsParentCompany.length == 0) {
        this.showMessage('Please select parent company', 'Required', 'error');
        return;
      }
      if (this.selectedItemsParentCompany != null && this.selectedItemsParentCompany != undefined)
        this.companyModel.parentCompanyId = +this.selectedItemsParentCompany[0].id;
    }

    if (this.isAffiliate) {
      if (this.selectedItemsPrimaryCompany == null || this.selectedItemsPrimaryCompany.length == 0) {
        this.showMessage('Please select primary company', 'Required', 'error');
        return;
      }
      if (this.selectedItemsPrimaryCompany != null && this.selectedItemsPrimaryCompany != undefined)
        this.companyModel.primaryCompanyId = +this.selectedItemsPrimaryCompany[0].id;
    }

    this.isMultipleClick = true;
    this.blockDocument();
    this.companyService.addUpdateCompany(this.companyModel, this.tokenKey).subscribe(data => {
      this.isMultipleClick = false;
      if (data.status == 'Success') {
        this.showMessage('Company ' + msg + ' successfully', 'Company ' + msg, 'success');
        this.unblockDocument();
        let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
        this.router.navigate(['/admin/' + toolName + '/companies']);
      }
      else {
        if (data.message == 'duplicate') {
          this.showMessage('Duplicate Company not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else {
          this.showMessage(data.status, 'Error', 'error');
          this.unblockDocument();
        }
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
      this.isMultipleClick = false;
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getState() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.stateService.GetStateMaster(this.tokenKey, '911').subscribe(
      data => {
        this.stateList = data['state'];
        this.selectedState911Count = data['state'].length
      },
      error => { }
    );
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.isDownload = true;
    }
    else
      this.isDownload = false;
  }

  onCheckboxFTPAccessChange(e) {
    if (e.target.checked) {
      this.isFTPAccess = true;
    }
    else
      this.isFTPAccess = false;
  }

  onCheckbox911FTPAccessChange(e) {
    if (e.target.checked) {
      this.isFTP911Access = true;
    }
    else
      this.isFTP911Access = false;
  }

  SelectedProductCheck(item) {
    if (!item.isSelected) {
      item.isDemo = false;
      item.isComp = false;
      item.licenceCount = '';
    }
    else {

      if (this.companyId > 0) {

        if (item.productId == 23) {
          if (this.companyModel.training.find(x => x.isAllowallAccess == true) != null) {
            item.licenceCount = 1000;
            item.isSelected = true;

            for (let xindex = 0; xindex < this.companyModel.training.length; xindex++) {
              const xelement = this.companyModel.training[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }

          }
        }
        else if (item.productId == 27) {
          if (this.companyModel.specialReport.find(x => x.isAllowallAccess == true) != null) {
            item.licenceCount = 1000;
            item.isSelected = true;
            for (let xindex = 0; xindex < this.companyModel.specialReport.length; xindex++) {
              const xelement = this.companyModel.specialReport[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }
          }
        }
        else if (item.productId == 40) {
          if (this.companyModel.taxExemptionForm.find(x => x.isAllowallAccess == true) != null) {
            item.licenceCount = 1000;
            item.isSelected = true;
            for (let xindex = 0; xindex < this.companyModel.taxExemptionForm.length; xindex++) {
              const xelement = this.companyModel.taxExemptionForm[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }
          }
        }
        else if (item.productId == 28) {
          if (this.companyModel.webinars.find(x => x.isAllowallAccess == true) != null) {
            item.licenceCount = 1000;
            item.isSelected = true;
            for (let xindex = 0; xindex < this.companyModel.webinars.length; xindex++) {
              const xelement = this.companyModel.webinars[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }
          }
        }
        else if (item.productId == 35) {
          if (this.companyModel.inteserraResources.find(x => x.isAllowallAccess == true) != null) {
            item.licenceCount = 1000;
            item.isSelected = true;
            for (let xindex = 0; xindex < this.companyModel.inteserraResources.length; xindex++) {
              const xelement = this.companyModel.inteserraResources[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }
          }
        }
        else if (item.productId == 29) {
          if (this.companyModel.whitePapers.find(x => x.isAllowallAccess == true) != null) {
            item.licenceCount = 1000;
            item.isSelected = true;

            for (let xindex = 0; xindex < this.companyModel.whitePapers.length; xindex++) {
              const xelement = this.companyModel.whitePapers[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }

          }
        }
        else if (item.productId == 2) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 5) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 21) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 33) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 37) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 38) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 39) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }

        this.calculateCheckedCount();
      }
      else {
        if (item.productId == 23) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 27) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 40) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 28) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 29) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 2) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 5) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 21) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 33) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 37) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 38) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
        else if (item.productId == 39) {
          item.licenceCount = 1000;
          item.isSelected = true;
        }
      }

    }
  }
  updatePricingQuotes(clientInformationId,companyName,companyId) {
  sessionStorage.setItem('UserLoginTool','Admin');
  sessionStorage.setItem('ClientCompanyName',companyName);
  this.adminLayout.SelectedTool='Admin';
  let navigation = '/admin/CompanyProfile/company-info/'+clientInformationId+'/'+companyId;
  this.router.navigate([navigation]);
}

  

  GetPriceQuoteSubscriptionState(CompanyId) {
    this.PricingQuotesMaster.GetPriceQuoteSubscriptionState(this.tokenKey, CompanyId).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          if (result['stateList'] != null) {
            this.PriceQuoteStateList = result['stateList'];
            if (this.PriceQuoteStateList.length > 0) {
              this.isPriceQuoteStateList = true;
            }
          }
        }
      })
  }

  GetTypeOfCompany() {
    this.companyService.GetTypeOfCompany(this.tokenKey).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          if (result['companyTypeList'] != null) {
            this.typeOfCompanyList = result['companyTypeList'];
            for (let iCounter = 0; iCounter < this.typeOfCompanyList.length; iCounter++) {
              this.dropdownListCompanyType.push(
                { 'id': this.typeOfCompanyList[iCounter].companyTypeId, 'itemName': this.typeOfCompanyList[iCounter].name }
              );
            }
          }

          if (result['clientCompanies'] != null) {
            for (let iCounter = 0; iCounter < result['clientCompanies'].length; iCounter++) {
              this.dropdownListCompany.push(
                { 'id': result['clientCompanies'][iCounter].companyId, 'itemName': result['clientCompanies'][iCounter].companyName }
              );
            }
          }
        }
      })
  }

  ViewOnBoarding(clientInformationId) {
    sessionStorage.setItem('UserLoginTool', 'PricingQuote');
    this.adminLayout.SelectedTool = 'PricingQuote';
    let navigation = '/admin/PricingQuote/add-company-info/' + clientInformationId;
    this.router.navigate([navigation]);
  }

  onCompanyTypeSelect(companytypeId: number) {

  }

  NoteVisible(Option) {
    this.selectedParentOption = Option;
  }

  ChildCheck(Option) {
    this.selectedChildOption = Option;
    if (Option == "1")
      this.isChild = true;
    else
      this.isChild = false;
  }

  AffiliateCheck(Option) {
    this.selectedAffiliateOption = Option;
    if (Option == "1")
      this.isAffiliate = true;
    else
      this.isAffiliate = false;
  }

  onPrimaryCompanySelect(companyid: number) {

  }

  onParentCompanySelect(companyid: number) {

  }
  // ExportCitiationSubscriptionGetAll() {
  //   this.UserService.ExportCitiationSubscriptionGetAll(this.tokenKey).subscribe(
  //     data => {
  //       this.exportCitiationList = data['exportCitiation'];
  //       //this.userModel.exportCitiation = this.exportCitiationList;
  //     },
  //     error => { }
  //   );
  // }

  onCompanyTypeSelectAll(){
    this.selectedItemsCompanyType=[];
  }

  onPrimaryCompanySelectAll(){
    this.selectedItemsPrimaryCompany=[];
  }

  onParentCompanySelectAll(){
    this.selectedItemsParentCompany=[];
  }

}
