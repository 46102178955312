import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdditionalServicesFeeModel, OngoingComplianceModel } from 'src/Models/OngoingComplianceModel';
import { HtmlToPdf } from 'src/Services/htmlToPdf';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import * as FileSaver from 'file-saver';
import { checkStepIsCompleted, getIncompletedStep } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';
import {  MarketEntryDataModel } from 'src/Models/PriceCalculationModel';
import { UpdatedBaseRateModel } from 'src/Models/UpdatedBaseRateModel';

@Component({
  selector: 'app-pricing-quotes-proposal',
  templateUrl: './pricing-quotes-proposal.component.html',
  styleUrls: ['./pricing-quotes-proposal.component.css']
})
export class PricingQuotesProposalComponent implements OnInit {
  SelectedTool: string;
  userID: any;
  clientInformationId: number = 0;
  priceQuoteId: number = 0;
  tokenKey: string;
  isloading: boolean = false;

  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  stateCode: any;
  isRecord: boolean = false;
  quoteName: string;
  listofCompliance: OngoingComplianceModel[];
  listofMiscellaneous: OngoingComplianceModel[];
  listofAdditionalServices: AdditionalServicesFeeModel[];
  listofUpdatedAddOnServices: UpdatedBaseRateModel[];
  listofUpdatedAddOnOtherRegulatory: UpdatedBaseRateModel[];
  listofConsultingProjects: UpdatedBaseRateModel[];
  complianceTotal: number = 0;
  baseUrl: string;
  isConsultingProjects: boolean = false;
  consultingTotal:number=0;

  isRateDetailsCompleted: boolean;
  isQuoteDetailsCompleted: boolean;
  isAddOnServicesCompleted: boolean;
  isComplianceDetailsCompleted: boolean;
  isProposalDetailsCompleted: boolean;
  currentActiveTab: number = 1;

  url: string;
  tabId: number = 0;

  mainUrl: string;
  subUrl: string;

  listofMarketEntryData: MarketEntryDataModel[];
  listofMarketEntryData1: MarketEntryDataModel;
  listofMarketEntryData2: MarketEntryDataModel;

  sosServiceFees?: number = 0.00;
  sosCorpFeelingFees?: number = 0.00;
  sosLLCFeelingFees?: number = 0.00;
  registeredAgentFees?: number = 0.00;
  pucServiceFees?: number = 0.00;
  pscFeelingFees?: number = 0.00;
  localCounselFees?: number = 0.00;
  inteserraTotal?: number = 0.00;
  companyTotal?: number = 0.00;
  dbaFillinfFees?: number = 0.00;
  dbaComSpecFillingFees?: number = 0.00;
  Total?: number = 0.00;
  dbaServiceFees?: number = 0.00;
  dbaComSpecServiceFees?: number = 0.00;
  dbA_Inteserra_Fee?: number = 0.00;
  dbAFillingsFees?: number = 0.00;
  dbA_RA_Fees?: number = 0.00;
  companyName: string;
  
  advisoryQuotebtndisplay:boolean=false;
  solutionQuotebtndisplay:boolean=false;

  soS_Service_Fees_SelectedAll: boolean = false;
  dbA_Service_Fees_SelectedAll: boolean = false;
  soS_Corp_Filling_Fees_SelectedAll: boolean = false;
  soS_LLC_Filing_Fees_SelectedAll: boolean = false;
  dbA_Filing_Fees_SelectedAll: boolean = false;
  registered_Agent_Fees_SelectedAll: boolean = false;
  puC_Service_Fees_SelectedAll: boolean = false;
  psC_FilingsAndPublication_Fees_SelectedAll: boolean = false;
  local_Counsel_Fees_SelectedAll: boolean = false;

  soS_Service_Fees_NAisSelected: boolean = false;
  dbA_Service_Fees_NAisSelected: boolean = false;
  soS_Corp_Filling_Fees_NAisSelected: boolean = false;
  soS_LLC_Filing_Fees_NAisSelected: boolean = false;
  dbA_Filing_Fees_NAisSelected: boolean = false;
  registered_Agent_Fees_NAisSelected: boolean = false;
  puC_Service_Fees_NAisSelected: boolean = false;
  psC_FilingsAndPublication_Fees_NAisSelected: boolean = false;
  local_Counsel_Fees_NAisSelected: boolean = false;

  isMarketEntry:boolean=false;
  isMisc:boolean=false;
  isAddOn:boolean=false;
  isRegulatory:boolean=false;
  isAdditionalServices:boolean=false;

  isCompliance:boolean=false;
  solutionTotal:number=0;
  regulatoryTotal:number=0;
  miscTotal:number=0;
  additionalServicesTotal:number=0;
  marketEntryName : string;

  constructor(private router: Router, private PricingQuotesMaster: PricingQuotesService, private checkAuth: CheckAuth
    , private titleService: Title, private activedRoute: ActivatedRoute, private toastr: ToastrService, private htmlToPdf: HtmlToPdf,
    @Inject('BASE_URL') baseUrl: string, private globle: Globle) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.baseUrl = baseUrl;
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Price Quote - ' + this.companyName);


    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.priceQuoteId = params['id'];
          this.GetComplianceByClientInformationId(this.priceQuoteId);
          this.GetMarketEntryDataById(this.priceQuoteId);
          this.GetAdvisorysolutionbuttonDataById(this.priceQuoteId);
          this.GetClientInformationById(this.priceQuoteId);
        }
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.priceQuoteId != null) {
      this.GetProgressStepDetailsById(this.priceQuoteId);
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isRateDetailsCompleted = null
      this.globle.cApp.statusModel.isQuoteDetailsCompleted = null
      this.globle.cApp.statusModel.isAddOnServicesCompleted = null
      this.globle.cApp.statusModel.isComplianceDetailsCompleted = null
      this.globle.cApp.statusModel.isProposalDetailsCompleted = null
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
  }

  ngOnInit() {
    this.isloading = true;
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  previousStep() {
    this.router.navigate(['/admin/' + this.SelectedTool + '/compliance/' + this.priceQuoteId]);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  GetComplianceByClientInformationId(priceQuoteId) {
    this.blockDocument();
    this.PricingQuotesMaster.GetProposelByClientInformationId(this.tokenKey, priceQuoteId).subscribe(
      data => {
        if (data['status'] = 'Success') {
          // this.globle.cApp.statusModel.isProposalDetailsCompleted = true;
          this.unblockDocument();
          this.isloading = false;
          if (data['compliance'] != null && data['compliance'].length > 0) {
            this.listofCompliance = data['compliance'].filter(x => x.totalMonthlyCost != 0);
            if(this.listofCompliance.length>0)
            this.isCompliance=true
          }

          if(data['marketEntryData'] !=null && data['marketEntryData'].length>0){
            this.isMarketEntry=true;
          }
          else
          {
            this.isMarketEntry=false;
          }

          if (data['miscelleneous'] != null && data['miscelleneous'].length > 0) {
            this.listofMiscellaneous = data['miscelleneous'];
            for (let iCounter = 0; iCounter < this.listofMiscellaneous.length; iCounter++) {
              this.miscTotal = this.miscTotal + this.listofMiscellaneous[iCounter].totalMonthlyCost;
            }
            this.isMisc=true;
          }

          if (data['additionalServicesFees'] != null && data['additionalServicesFees'].length > 0) {
            this.listofAdditionalServices = data['additionalServicesFees'];
            for (let iCounter = 0; iCounter < this.listofAdditionalServices.length; iCounter++) {
              this.additionalServicesTotal = this.additionalServicesTotal + this.listofAdditionalServices[iCounter].additionalServicesAmount;
            }
            this.isAdditionalServices=true;
          }

          if (data['platFormOffering'] != null && data['platFormOffering'].length > 0) {
            this.listofUpdatedAddOnServices = data['platFormOffering'];
            for (let iCounter = 0; iCounter < this.listofUpdatedAddOnServices.length; iCounter++) {
                this.solutionTotal = this.solutionTotal + this.listofUpdatedAddOnServices[iCounter].totalAmount;
              }
            this.isAddOn=true;
          }

          if (data['regulatoryRequirement'] != null && data['regulatoryRequirement'].length > 0) {
            this.listofUpdatedAddOnOtherRegulatory = data['regulatoryRequirement'];
            for (let iCounter = 0; iCounter < this.listofUpdatedAddOnOtherRegulatory.length; iCounter++) {
              this.regulatoryTotal = this.regulatoryTotal + this.listofUpdatedAddOnOtherRegulatory[iCounter].monthlyRate;
            }
            this.isRegulatory=true;
          }

          if (data['updatedConsultingProjects'] != null && data['updatedConsultingProjects'].length > 0) {
            this.listofConsultingProjects = data['updatedConsultingProjects'];
            for (let iCounter = 0; iCounter < this.listofConsultingProjects.length; iCounter++) {
              this.consultingTotal = this.consultingTotal + this.listofConsultingProjects[iCounter].monthlyRate;
              //this.regulatoryTotal = this.regulatoryTotal + this.listofUpdatedAddOnOtherRegulatory[iCounter].annualRate;
            }
            this.isConsultingProjects=true;
          }

          // this.listofCompliance = data['compliance'];
          if (this.listofCompliance != null) {
            if (this.listofCompliance.length == 0) {
              this.isRecord = true;
            }
            else {
              if (this.listofCompliance[0].quoteName != null && this.listofCompliance[0].quoteName != "") {
                this.quoteName = " - " + this.listofCompliance[0].quoteName;
              }
              else {
                this.quoteName = "";
              }
              for (let iCounter = 0; iCounter < this.listofCompliance.length; iCounter++) {
                this.complianceTotal = this.complianceTotal + this.listofCompliance[iCounter].totalMonthlyCost;
              }
            }
          }
          else {
            this.isRecord = false;
          }
        }
        else {
          this.unblockDocument();
          this.isRecord = false;
        }
      }, error => { 
        this.unblockDocument();
        this.stateCode = error 
      }
    );
  }

  copy(el: HTMLDivElement) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(el.innerText).then(() => {
        this.toastr.success('Text copy successfully', 'Copied')
      }, (error) => {
      });
    }
  }

  activeTab(url: string, tabId: number) {

    if (this.priceQuoteId != null) {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsById(this.tokenKey, priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
          this.activeTab('proposal', 9);
        }
      });
  }

  GetMarketEntryDataById(priceQuoteId: number) {
    this.blockDocument();
    this.PricingQuotesMaster.GetMarketEntryDataById(this.tokenKey, priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.unblockDocument();
          this.listofMarketEntryData = result['compliance'];

          if (this.listofMarketEntryData.length > 0) {
            this.sosServiceFees = 0;
            this.sosCorpFeelingFees = 0;
            this.pucServiceFees = 0;
            this.pscFeelingFees = 0;
            this.registeredAgentFees = 0;
            this.localCounselFees = 0;
            this.dbaFillinfFees = 0;
            this.dbaServiceFees = 0;
            this.sosLLCFeelingFees = 0;
            this.dbA_Inteserra_Fee = 0.00;
            this.dbAFillingsFees = 0.00;
            this.dbA_RA_Fees = 0.00;

            var SOSServiceData = this.listofMarketEntryData.filter(x => x.soS_Service_Fees_isSelected == true).length;
            if (SOSServiceData != null) {
              if (SOSServiceData == this.listofMarketEntryData.length)
                this.soS_Service_Fees_SelectedAll = true;
              else
                this.soS_Service_Fees_SelectedAll = false;
            }

            var DBAServiceData = this.listofMarketEntryData.filter(x => x.dbA_Service_Fees_isSelected == true).length;
            if (DBAServiceData != null) {
              if (DBAServiceData == this.listofMarketEntryData.length)
                this.dbA_Service_Fees_SelectedAll = true;
              else
                this.dbA_Service_Fees_SelectedAll = false;
            }

            var SOSCorpData = this.listofMarketEntryData.filter(x => x.soS_Corp_Filling_Fees_isSelected == true).length;
            if (SOSCorpData != null) {
              if (SOSCorpData == this.listofMarketEntryData.length)
                this.soS_Corp_Filling_Fees_SelectedAll = true;
              else
                this.soS_Corp_Filling_Fees_SelectedAll = false;
            }

            var SOSLLCData = this.listofMarketEntryData.filter(x => x.soS_LLC_Filing_Fees_isSelected == true).length;
            if (SOSLLCData != null) {
              if (SOSLLCData == this.listofMarketEntryData.length)
                this.soS_LLC_Filing_Fees_SelectedAll = true;
              else
                this.soS_LLC_Filing_Fees_SelectedAll = false;
            }

            var DBAFillingFeesData = this.listofMarketEntryData.filter(x => x.dbA_Filing_Fees_isSelected == true).length;
            if (DBAFillingFeesData != null) {
              if (DBAFillingFeesData == this.listofMarketEntryData.length)
                this.dbA_Filing_Fees_SelectedAll = true;
              else
                this.dbA_Filing_Fees_SelectedAll = false;
            }

            var RegisterAgentData = this.listofMarketEntryData.filter(x => x.registered_Agent_Fees_isSelected == true).length;
            if (RegisterAgentData != null) {
              if (RegisterAgentData == this.listofMarketEntryData.length)
                this.registered_Agent_Fees_SelectedAll = true;
              else
                this.registered_Agent_Fees_SelectedAll = false;
            }

            var PUCServicesData = this.listofMarketEntryData.filter(x => x.puC_Service_Fees_isSelected == true).length;
            if (PUCServicesData != null) {
              if (PUCServicesData == this.listofMarketEntryData.length)
                this.puC_Service_Fees_SelectedAll = true;
              else
                this.puC_Service_Fees_SelectedAll = false;
            }

            var PSCFilingsAndPublicationData = this.listofMarketEntryData.filter(x => x.psC_FilingsAndPublication_Fees_isSelected == true).length;
            if (PSCFilingsAndPublicationData != null) {
              if (PSCFilingsAndPublicationData == this.listofMarketEntryData.length)
                this.psC_FilingsAndPublication_Fees_SelectedAll = true;
              else
                this.psC_FilingsAndPublication_Fees_SelectedAll = false;
            }

            var LocalCounselData = this.listofMarketEntryData.filter(x => x.local_Counsel_Fees_isSelected == true).length;
            if (LocalCounselData != null) {
              if (LocalCounselData == this.listofMarketEntryData.length)
                this.local_Counsel_Fees_SelectedAll = true;
              else
                this.local_Counsel_Fees_SelectedAll = false;
            }

            var SOSServiceNAData = this.listofMarketEntryData.filter(x => x.soS_Service_Fees_NAisSelected == true).length;
            if (SOSServiceNAData != null) {
              if (SOSServiceNAData > 0)
                this.soS_Service_Fees_NAisSelected = true;
              else
                this.soS_Service_Fees_NAisSelected = false;
            }

            var DBAServiceNAData = this.listofMarketEntryData.filter(x => x.dbA_Service_Fees_NAisSelected == true).length;
            if (DBAServiceNAData != null) {
              if (DBAServiceNAData > 0)
                this.dbA_Service_Fees_NAisSelected = true;
              else
                this.dbA_Service_Fees_NAisSelected = false;
            }

            var SOSCorpNAData = this.listofMarketEntryData.filter(x => x.soS_Corp_Filling_Fees_NAisSelected == true).length;
            if (SOSCorpNAData != null) {
              if (SOSCorpNAData > 0)
                this.soS_Corp_Filling_Fees_NAisSelected = true;
              else
                this.soS_Corp_Filling_Fees_NAisSelected = false;
            }

            var SOSLLCNAData = this.listofMarketEntryData.filter(x => x.soS_LLC_Filing_Fees_NAisSelected == true).length;
            if (SOSLLCNAData != null) {
              if (SOSLLCNAData > 0)
                this.soS_LLC_Filing_Fees_NAisSelected = true;
              else
                this.soS_LLC_Filing_Fees_NAisSelected = false;
            }

            var DBAFillingFeesNAData = this.listofMarketEntryData.filter(x => x.dbA_Filing_Fees_NAisSelected == true).length;
            if (DBAFillingFeesNAData != null) {
              if (DBAFillingFeesNAData > 0)
                this.dbA_Filing_Fees_NAisSelected = true;
              else
                this.dbA_Filing_Fees_NAisSelected = false;
            }

            var RegisterAgentNAData = this.listofMarketEntryData.filter(x => x.registered_Agent_Fees_NAisSelected == true).length;
            if (RegisterAgentNAData != null) {
              if (RegisterAgentNAData > 0)
                this.registered_Agent_Fees_NAisSelected = true;
              else
                this.registered_Agent_Fees_NAisSelected = false;
            }

            var PUCServicesNAData = this.listofMarketEntryData.filter(x => x.puC_Service_Fees_NAisSelected == true).length;
            if (PUCServicesNAData != null) {
              if (PUCServicesNAData > 0)
                this.puC_Service_Fees_NAisSelected = true;
              else
                this.puC_Service_Fees_NAisSelected = false;
            }

            var PSCFilingsAndPublicationNAData = this.listofMarketEntryData.filter(x => x.psC_FilingsAndPublication_Fees_NAisSelected == true).length;
            if (PSCFilingsAndPublicationNAData != null) {
              if (PSCFilingsAndPublicationNAData > 0)
                this.psC_FilingsAndPublication_Fees_NAisSelected = true;
              else
                this.psC_FilingsAndPublication_Fees_NAisSelected = false;
            }

            var LocalCounselNAData = this.listofMarketEntryData.filter(x => x.local_Counsel_Fees_NAisSelected == true).length;
            if (LocalCounselNAData != null) {
              if (LocalCounselNAData > 0)
                this.local_Counsel_Fees_NAisSelected = true;
              else
                this.local_Counsel_Fees_NAisSelected = false;
            }

            for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {

              this.Total = 0.00;

              if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Service_Fees;
        
              if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;
        
              if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;
        
              if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;
        
              if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
        
              if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;
        
              if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;
        
              if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Service_Fees
        
              if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;
        
              this.listofMarketEntryData[iCounter].total = +this.Total;
        
              if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
                  this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;
        
              if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
                this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;
        
              if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected  && !this.dbA_Service_Fees_NAisSelected)
                this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;
        
              if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
                this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;
        
              if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
                this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;
        
              if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
                this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;
        
              if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
                this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;
        
              if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
                this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;
        
              if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
                this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
            }
            this.dbaComSpecFillingFees = 0;
            this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
            this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
            this.inteserraTotal = 0; this.companyTotal = 0;
            this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees;
            this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
          }
        }
        else {
          this.unblockDocument();
        }
      },
      error => { 
      this.unblockDocument();
        this.stateCode = error 
      });
  }

  createMyPdf1() {
    this.blockDocument();
    var fCC499 = 0, fCCFRN = 0, rMD = 0, fCC214 = 0, cGSCA = 0;
    let value = {
      priceQuoteId: this.priceQuoteId,
      inteserraFee: this.sosServiceFees,
      inteserraPUCServiceFees: this.pucServiceFees,
      filingFee: this.pscFeelingFees,
      estimatedLocalAmount: this.localCounselFees,
      sOSInteserraFee: this.sosServiceFees,
      sOSCorpFees: this.sosCorpFeelingFees,
      sOSLLCFees: this.sosLLCFeelingFees,
      publicationFee: this.pscFeelingFees,
      dBAInteserraFee: this.dbA_Inteserra_Fee,
      dBAFilingFees: this.dbAFillingsFees,
      dBARAFees: this.dbA_RA_Fees,
      fCC499: fCC499,
      fCCFRN: fCCFRN,
      rMD: rMD,
      fCC214: fCC214,
      cGSCA: cGSCA,
      listofMarketEntryData: this.listofMarketEntryData,
      dbaServiceFees: this.dbaServiceFees,
      dbaFillinfFees: this.dbaFillinfFees,
      registeredAgentFees: this.registeredAgentFees
    }

    this.PricingQuotesMaster.GenerateProposal(value, this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.downloadFile('PriceQuoteFinalProposal.pdf', data['url'], data['fileName']);
          this.unblockDocument();
        }
      },
      error => {
        this.unblockDocument();
      }
    );
  }

  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        sessionStorage.setItem('fileloading', 'false');
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  Save() {
    let value = {
      listofMarketEntryData: this.listofMarketEntryData,
      priceQuoteId: this.priceQuoteId,
      IsProposalDetailsCompleted: true
    }
    this.blockDocument();
    this.PricingQuotesMaster.proposalStepAddUpdate(this.tokenKey, value).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isProposalDetailsCompleted = true;
          this.showMessage('Price quote saved successfully', 'Success', 'success');
          this.router.navigate(['/admin/' + this.SelectedTool + '/pricing-quotes']);
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  changevalue(event: any, item, fieldName: string) {
    if (this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId).length > 0) {
      if (fieldName == 'soS_Service_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].soS_Service_Fees = +event;
      }
      else if (fieldName == 'dbA_Service_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].dbA_Service_Fees = +event;
      }
      else if (fieldName == 'soS_Corp_Filling_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].soS_Corp_Filling_Fees = +event;
      }
      else if (fieldName == 'soS_LLC_Filing_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].soS_LLC_Filing_Fees = +event;
      }
      else if (fieldName == 'dbA_Filing_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].dbA_Filing_Fees = +event;
      }
      else if (fieldName == 'registered_Agent_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].registered_Agent_Fees = +event;
      }
      else if (fieldName == 'puC_Service_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].puC_Service_Fees = +event;
      }
      else if (fieldName == 'psC_FilingsAndPublication_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].psC_FilingsAndPublication_Fees = +event;
      }
      else if (fieldName == 'local_Counsel_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].local_Counsel_Fees = +event;
      }
      else if (fieldName == 'Notes') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].notes = event;
      }

      this.sosServiceFees = 0;
      this.sosCorpFeelingFees = 0;
      this.pucServiceFees = 0;
      this.pscFeelingFees = 0;
      this.registeredAgentFees = 0;
      this.localCounselFees = 0;
      this.dbaFillinfFees = 0;
      this.sosLLCFeelingFees = 0;
      this.dbaServiceFees = 0;
      this.dbA_Inteserra_Fee = 0;
      this.dbAFillingsFees = 0;
      this.dbA_RA_Fees = 0;
      for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {

        this.Total = 0.00;

        if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Service_Fees;
  
        if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;
  
        if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;
  
        if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;
  
        if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
  
        if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;
  
        if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;
  
        if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Service_Fees
  
        if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;       
  
        this.listofMarketEntryData[iCounter].total = +this.Total;
  
        if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
            this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;
  
        if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
          this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;
  
        if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected  && !this.dbA_Service_Fees_NAisSelected)
          this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;
  
        if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
          this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;
  
        if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
          this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;
  
        if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
          this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;
  
        if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
          this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;
  
        if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
          this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;
  
        if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
          this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
      }
      this.dbaComSpecFillingFees = 0;
      this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
      this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
      this.inteserraTotal = 0; this.companyTotal = 0;
      this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees;
      this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
    }
  }

  SelectedEntryCheck(detail) {

    this.sosServiceFees = 0;
    this.sosCorpFeelingFees = 0;
    this.pucServiceFees = 0;
    this.pscFeelingFees = 0;
    this.registeredAgentFees = 0;
    this.localCounselFees = 0;
    this.dbaFillinfFees = 0;
    this.sosLLCFeelingFees = 0;
    this.dbaServiceFees = 0;
    this.dbA_Inteserra_Fee = 0;
    this.dbAFillingsFees = 0;
    this.dbA_RA_Fees = 0;

    for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {

      this.Total = 0.00;

      if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

      if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

      if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;
      // }

      if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Service_Fees

      if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

      this.listofMarketEntryData[iCounter].total = +this.Total;

      if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
          this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
        this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected  && !this.dbA_Service_Fees_NAisSelected)
        this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

      if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
        this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
        this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;

      if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
        this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

      if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
        this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

      if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
        this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

      if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
        this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
    }

    var SOSServiceData = this.listofMarketEntryData.filter(x => x.soS_Service_Fees_isSelected == true).length;
    if (SOSServiceData != null) {
      if (SOSServiceData == this.listofMarketEntryData.length)
        this.soS_Service_Fees_SelectedAll = true;
      else
        this.soS_Service_Fees_SelectedAll = false;
    }

    var DBAServiceData = this.listofMarketEntryData.filter(x => x.dbA_Service_Fees_isSelected == true).length;
    if (DBAServiceData != null) {
      if (DBAServiceData == this.listofMarketEntryData.length)
        this.dbA_Service_Fees_SelectedAll = true;
      else
        this.dbA_Service_Fees_SelectedAll = false;
    }

    var SOSCorpData = this.listofMarketEntryData.filter(x => x.soS_Corp_Filling_Fees_isSelected == true).length;
    if (SOSCorpData != null) {
      if (SOSCorpData == this.listofMarketEntryData.length)
        this.soS_Corp_Filling_Fees_SelectedAll = true;
      else
        this.soS_Corp_Filling_Fees_SelectedAll = false;
    }

    var SOSLLCData = this.listofMarketEntryData.filter(x => x.soS_LLC_Filing_Fees_isSelected == true).length;
    if (SOSLLCData != null) {
      if (SOSLLCData == this.listofMarketEntryData.length)
        this.soS_LLC_Filing_Fees_SelectedAll = true;
      else
        this.soS_LLC_Filing_Fees_SelectedAll = false;
    }

    var DBAFillingFeesData = this.listofMarketEntryData.filter(x => x.dbA_Filing_Fees_isSelected == true).length;
    if (DBAFillingFeesData != null) {
      if (DBAFillingFeesData == this.listofMarketEntryData.length)
        this.dbA_Filing_Fees_SelectedAll = true;
      else
        this.dbA_Filing_Fees_SelectedAll = false;
    }

    var RegisterAgentData = this.listofMarketEntryData.filter(x => x.registered_Agent_Fees_isSelected == true).length;
    if (RegisterAgentData != null) {
      if (RegisterAgentData == this.listofMarketEntryData.length)
        this.registered_Agent_Fees_SelectedAll = true;
      else
        this.registered_Agent_Fees_SelectedAll = false;
    }

    var PUCServicesData = this.listofMarketEntryData.filter(x => x.puC_Service_Fees_isSelected == true).length;
    if (PUCServicesData != null) {
      if (PUCServicesData == this.listofMarketEntryData.length)
        this.puC_Service_Fees_SelectedAll = true;
      else
        this.puC_Service_Fees_SelectedAll = false;
    }

    var PSCFilingsAndPublicationData = this.listofMarketEntryData.filter(x => x.psC_FilingsAndPublication_Fees_isSelected == true).length;
    if (PSCFilingsAndPublicationData != null) {
      if (PSCFilingsAndPublicationData == this.listofMarketEntryData.length)
        this.psC_FilingsAndPublication_Fees_SelectedAll = true;
      else
        this.psC_FilingsAndPublication_Fees_SelectedAll = false;
    }

    var LocalCounselData = this.listofMarketEntryData.filter(x => x.local_Counsel_Fees_isSelected == true).length;
    if (LocalCounselData != null) {
      if (LocalCounselData == this.listofMarketEntryData.length)
        this.local_Counsel_Fees_SelectedAll = true;
      else
        this.local_Counsel_Fees_SelectedAll = false;
    }

    this.dbaComSpecFillingFees = 0;
    this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
    this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
    this.inteserraTotal = 0; this.companyTotal = 0;
    this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees;
    this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
  }

  selectedState_soS_Service_Fees(value, columnName) {
    this.sosServiceFees = 0;
    this.sosCorpFeelingFees = 0;
    this.pucServiceFees = 0;
    this.pscFeelingFees = 0;
    this.registeredAgentFees = 0;
    this.localCounselFees = 0;
    this.dbaFillinfFees = 0;
    this.sosLLCFeelingFees = 0;
    this.dbaServiceFees = 0;
    this.dbA_Inteserra_Fee = 0;
    this.dbAFillingsFees = 0;
    this.dbA_RA_Fees = 0;

    for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {

      this.Total = 0.00;
      if (columnName == 'SOS_Service_Fees')
        this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected = value;
      if (columnName == 'DBA_Service_Fees')
        this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected = value;
      if (columnName == 'SOS_Corp_Fees')
        this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected = value;
      if (columnName == 'SOS_LLC_Fees')
        this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected = value;
      if (columnName == 'DBA_Filing_Fees')
        this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected = value;
      if (columnName == 'Registered_Agent_Fees')
        this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected = value;
      if (columnName == 'PUC_Service_Fees')
        this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected = value;
      if (columnName == 'PSC_FilingsAndPublication_Fees')
        this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected = value;
      if (columnName == 'Local_Counsel_Fees')
        this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected = value;

      if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

      if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;
     
      if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

      if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Service_Fees

      if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;     

      this.listofMarketEntryData[iCounter].total = +this.Total;

      if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
          this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
        this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected  && !this.dbA_Service_Fees_NAisSelected)
        this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

      if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
        this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
        this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;

      if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
        this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

      if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
        this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

      if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
        this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

      if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
        this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
    }
    this.dbaComSpecFillingFees = 0;
    this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
    this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
    this.inteserraTotal = 0; this.companyTotal = 0;
    this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees;
    this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
  }

  selectedState_NA_Fees(value, columnName) {
    this.sosServiceFees = 0;
    this.sosCorpFeelingFees = 0;
    this.pucServiceFees = 0;
    this.pscFeelingFees = 0;
    this.registeredAgentFees = 0;
    this.localCounselFees = 0;
    this.dbaFillinfFees = 0;
    this.sosLLCFeelingFees = 0;
    this.dbaServiceFees = 0;
    this.dbA_Inteserra_Fee = 0;
    this.dbAFillingsFees = 0;
    this.dbA_RA_Fees = 0;

    for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {

      this.Total = 0.00;
      if (columnName == 'SOS_Service_Fees')
        this.soS_Service_Fees_NAisSelected = value;
      if (columnName == 'DBA_Service_Fees')
        this.dbA_Service_Fees_NAisSelected = value;
      if (columnName == 'SOS_Corp_Fees')
        this.soS_Corp_Filling_Fees_NAisSelected = value;
      if (columnName == 'SOS_LLC_Fees')
        this.soS_LLC_Filing_Fees_NAisSelected = value;
      if (columnName == 'DBA_Filing_Fees')
        this.dbA_Filing_Fees_NAisSelected = value;
      if (columnName == 'Registered_Agent_Fees')
        this.registered_Agent_Fees_NAisSelected = value;
      if (columnName == 'PUC_Service_Fees')
        this.puC_Service_Fees_NAisSelected = value;
      if (columnName == 'PSC_FilingsAndPublication_Fees')
        this.psC_FilingsAndPublication_Fees_NAisSelected = value;
      if (columnName == 'Local_Counsel_Fees')
        this.local_Counsel_Fees_NAisSelected = value;

      if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

      if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

      if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;
      // }

      if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Service_Fees

      if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

      this.listofMarketEntryData[iCounter].total = +this.Total;

      if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
          this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected  && !this.dbA_Filing_Fees_NAisSelected)
        this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected  && !this.dbA_Service_Fees_NAisSelected)
        this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

      if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
        this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
        this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;

      if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
        this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

      if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
        this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

      if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
        this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

      if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56 && this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
        this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
    }
    this.dbaComSpecFillingFees = 0;
    this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
    this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
    this.inteserraTotal = 0; this.companyTotal = 0;
    this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees;
    this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
  }

  
  GetAdvisorysolutionbuttonDataById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetAdvisorysoultionbuttondisplay(priceQuoteId,this.tokenKey,).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if(result.lstdata[0].advisoryServices>0){
            this.advisoryQuotebtndisplay=true;
          }
          else{
            this.advisoryQuotebtndisplay=false;
          }
          
          if(result.lstdata[0].solutions>0){
            this.solutionQuotebtndisplay=true;
          }
          else{
            this.solutionQuotebtndisplay=false;
          }
        }

      });
  }

  createAdvisoryPdf() {
    this.blockDocument();

    this.PricingQuotesMaster.GenerateAdvisoryServicesQuote(this.priceQuoteId,this.companyName,this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.downloadFile('AdvisoryProposal.pdf', data['url'], data['fileName']);
          this.unblockDocument();
        }
      },
      error => {
        this.unblockDocument();
      }
    );
  }

  createSolutionPdf() {
    this.blockDocument();

    this.PricingQuotesMaster.GenerateSolutionServicesQuote(this.priceQuoteId,this.companyName,this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.downloadFile('SolutionsProposal.pdf', data['url'], data['fileName']);
          this.unblockDocument();
        }
      },
      error => {
        this.unblockDocument();
      }
    );
  }

  createMatrketEntryPdf() {
    this.blockDocument();
    this.PricingQuotesMaster.GenerateMarketEntryPDF(this.priceQuoteId,this.companyName,this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.downloadFile('MarketEntry.pdf', data['url'], data['fileName']);
          this.unblockDocument();
        }
      },
      error => {
        this.unblockDocument();
      }
    );
  }

  GenerateMarketEntryReportPDF() {
    this.blockDocument();
    this.PricingQuotesMaster.GenerateMarketEntryReportPDF(this.priceQuoteId,this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.downloadFile('MarketEntryReport.pdf', data['url'], data['fileName']);
          this.unblockDocument();
        }
        else
        {
          this.unblockDocument();  
        }
      },
      error => {
        this.unblockDocument();
      }
    );
  }

  GetClientInformationById(priceQuoteId: number) {
    this.blockDocument();

    this.PricingQuotesMaster.GetClientInformationById(this.tokenKey, priceQuoteId,0, false).subscribe(
      result => {
        this.unblockDocument();
       
        if(result['clientInformation'].marketEntryProvider == 1){
          this.marketEntryName = " - CLEC";
        }
        else if(result['clientInformation'].marketEntryProvider == 2){
          this.marketEntryName = " - IXC";
        }
        else if(result['clientInformation'].marketEntryProvider == 3){
          this.marketEntryName = " - VOIP";
        }
        else if(result['clientInformation'].marketEntryProvider == 4){
          this.marketEntryName = " - Wireless";
        }
      });
  }


  ExportToExcelQuote() {
    this.isloading = false;
    let tableData = document.getElementById("exportQuotetool").innerHTML;
    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";
    let name = 'PriceQuoteExport';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "PriceQuoteExport.xls");
  }
}

