import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CheckAuth } from '../../../Services/utility.service';
import { AppConfig } from '../../../Services/app.config';
import { StateMasterModel } from '../../../Models/stateMasterModel';
import { UserModel } from '../../../Models/usersModel';
import { UsersService } from '../../../Services/users.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ProductsService } from '../../../Services/products.service';
import { AdminUserTypeModel } from 'src/Models/AdminUserTypeModel';
import { ClientCompanyModel } from 'src/Models/ClientCompanyModel';
import { AdminUserType } from '../../../Models/Common';

@Component({
  selector: 'app-inteserra-manage-users',
  templateUrl: './inteserra-manage-users.component.html',
  styleUrls: ['./inteserra-manage-users.component.css']
})
export class InteserraManageUsersComponent implements OnInit {
  userForm: FormGroup;
  loginuserID: number = 0;
  appModel: any;
  allstate: boolean = false;
  tokenKey: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  statusCode: number = 0;
  _userID: number = 0;
  userModel: UserModel = {
    userId: '',
    userName: '',
    password: '',
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isActive: '',
    createdDate: null,
    createdBy: null,
    modifiedDate: null,
    modifiedBy: null
  };
  // Product List---------------
  dropdownListProduct = [];
  selectedItemsProduct = [];
  dropdownSettingsProduct = {};
  productFilter: string = '';
  listOfProductID = [];
  // Product List---------------
  SelectedTool: string;

  selectedAll: any;
  isloadingstates: boolean = false;

  adminTypeList: AdminUserTypeModel[];
  dropdownListAdminType = [];
  selectedItemsAdminType = [];
  dropdownSettingsAdminType = {};
  listOfAdminTypeID = [];

  clientCompanyList: ClientCompanyModel[];
  dropdownListclientCompany = [];
  selectedItemsclientCompany = [];
  dropdownSettingclientCompany = {};
  listOfCompany = [];

  isControlEnable: boolean = true;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private UserService: UsersService
    , private activedRoute: ActivatedRoute, private toastr: ToastrService
    , private router: Router, private titleService: Title, private productsService: ProductsService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.userForm = this._fb.group({
      userid: 0,
      username: [''],
      password: [''],
      companyName: [''],
      firstname: [''],
      lastname: [''],
      email: [''],
      phone: [''],
      userTypeId: 2,
      isactive: 1,
      createdBy: this.loginuserID,
      modifiedBy: this.loginuserID,
      ListOfProducts: [''],
      adminTypeId: 0,
      clientCompanyId: [{}],
    })
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Manage Admin');
    this.GetAdminTypeData();
    this.GetCompanyList()
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.movetotop();
    this.GetProductData();
    this.activedRoute.params.subscribe((params: Params) => {
     
      if (params['id']) {
        if (params['id'] != null) {
          this._userID = params['id'];
          this.Getuser();
        }
      }
      else {
        this._userID = 0;
        this.SetCompanyDropdownSetting();
      }
    });
  }

  Getuser(): void {
    this.isloadingstates = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.UserService.GetUserFindByUserID(this.tokenKey, this._userID).subscribe(
      data => {
       
        this.userModel = data["userMaster"];
        this.userForm.setValue({
          'userid': this.userModel[0].userId,
          'username': this.userModel[0].userName,
          'password': this.userModel[0].password,
          'companyName': this.userModel[0].companyName,
          'firstname': this.userModel[0].firstName,
          'lastname': this.userModel[0].lastName,
          'email': this.userModel[0].email,
          'phone': this.userModel[0].phone,
          'userTypeId': 2,
          'isactive': this.userModel[0].isActive,
          'createdBy': this.loginuserID,
          'modifiedBy': this.loginuserID,
          'ListOfProducts': '',
          'adminTypeId': '',
          'clientCompanyId': [{}],
        });
        this.selectedItemsProduct = [];
        for (let iCounter = 0; iCounter < data["userMaster"][0].listOfProducts.length; iCounter++) {
          this.selectedItemsProduct.push({ "id": data["userMaster"][0].listOfProducts[iCounter].productId, "itemName": data["userMaster"][0].listOfProducts[iCounter].displayName });
          this.listOfProductID.push(data["userMaster"][0].listOfProducts[iCounter].productId);
        }

        this.selectedItemsAdminType = [];
        if (data["userMaster"][0].adminTypeId != null) {
          if (data["userMaster"][0].adminTypeId > 0) {
            this.selectedItemsAdminType.push({ "id": data["userMaster"][0].adminTypeId, "itemName": data["userMaster"][0].adminUserName })
            this.listOfAdminTypeID.push(data["userMaster"][0].adminTypeId);
            
            if (data["userMaster"][0].adminTypeId == AdminUserType.systemuser) {
              this.isControlEnable = false;
            }
            this.SetCompanyDropdownSetting();
          }
        }

        this.selectedItemsclientCompany = [];
        if (data["userMaster"][0].systemUserCompany != null) {
          if (data["userMaster"][0].systemUserCompany.length > 0) {
            for (let iCounter = 0; iCounter < data["userMaster"][0].systemUserCompany.length; iCounter++) {
              this.selectedItemsclientCompany.push({ "id": data["userMaster"][0].systemUserCompany[iCounter].companyId, "itemName": data["userMaster"][0].systemUserCompany[iCounter].companyName });
              this.listOfCompany.push(data["userMaster"][0].systemUserCompany[iCounter].companyId);
            }
          }
        }
        this.unblockDocument();

      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
        this.isloadingstates = false;
      }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectedProduct($event) {
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
        this.listOfProductID.push($event.id);
      }
      else {
        this.listOfProductID.push($event.id);
      }
    }
  }

  onDeSelectedProduct($event) {
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
      }
    }
  }

  onSelectAllProduct($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfProductID != null) {
        if (this.listOfProductID.length > 0) {
          this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event[iCounter].id)
          this.listOfProductID.push($event[iCounter].id);
        }
        else {
          this.listOfProductID.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllProduct($event) {
    this.listOfProductID = [];
  }

  saveuser(userForm: any) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    if ((userForm.value["username"] == null || userForm.value["username"] == undefined ||
      userForm.value["username"].trim() == "")) {
      this.showMessage('Please enter username.', 'Required', 'error');
      return;
    }
    if ((userForm.value["password"] == null || userForm.value["password"] == undefined ||
      userForm.value["password"].trim() == "")) {
      this.showMessage('Please enter password.', 'Required', 'error');
      return;
    }
    if (userForm.value["email"].trim() != '') {
      let result = this.isValidMailFormat(userForm.controls.email);
      if (result != null) {
        this.showMessage('Please provide a valid email', 'Invalid Email', 'error');
        return;
      }
    }

    if (this.listOfProductID.length == 0) {
      this.showMessage('Please select at least one tool(s)', 'Required', 'error');
      return;
    }

    if (this.listOfAdminTypeID.length == 0) {
      this.showMessage('Please select user type', 'User Type', 'error');
      return;
    }

    var SelectedProducts = [];
    if (this.selectedItemsProduct.length > 0) {
      for (let iCounter = 0; iCounter < this.selectedItemsProduct.length; iCounter++) {
        SelectedProducts.push({ "ProductId": this.selectedItemsProduct[iCounter].id });
      }

      userForm.value["ListOfProducts"] = SelectedProducts;
    }
    else {
      this.showMessage('Please select tool(s)', 'Tools', 'error');
      return;
    }

    this.blockDocument();
    userForm.value["userid"] = this._userID;
    userForm.value["userTypeId"] = 2;
    let msg = '';
    if (this._userID == 0) {
      msg = 'added';
    }
    else {
      msg = 'updated';
    }

    userForm.value['adminTypeId'] = this.listOfAdminTypeID.join(',');
    userForm.value['clientCompanyId'] = this.listOfCompany.join(',');


    this.UserService.addupdateuser(this.userForm.value, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        this.showMessage('User ' + msg + ' successfully', 'User ' + msg, 'success');
        this.unblockDocument();
        let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
        this.router.navigate(['/admin/' + toolName + '/users']);
      }
      else {
        if (data.message == 'duplicate') {
          this.showMessage('Duplicate user not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else if (data.message == 'duplicate email') {
          this.showMessage('Duplicate email id not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else {
          this.showMessage(data.message, 'Error', 'error');
          this.unblockDocument();
        }
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  isValidMailFormat(control: FormControl) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value != "" && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { "Please provide a valid email": true };
    }

    return null;
  }
  canceluser() {
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['/admin/' + toolName + '/users']);
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }

  isPasswordFormat(control: any) {
    let EMAIL_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    if (control != "" && (control.length > 5 && EMAIL_REGEXP.test(control))) {
      return { "Password pattern are valid": true };
    }
    return null;
  }

 
  // Product List---------------
  GetProductData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.productsService.GetProductList(this.tokenKey, this.loginuserID, true, false).subscribe(
      data => {
        if (data["product"] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data["product"].length; iCounter++) {
            if (data["product"][iCounter].displayName != 'i360 Alerts') {
              this.dropdownListProduct.push(
                { "id": data["product"][iCounter].productId, "itemName": data["product"][iCounter].displayName }
              );
            }

          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
          this.unblockDocument();
        }
        this.dropdownSettingsProduct = {
          singleSelection: false,
          text: "Select",
          enableSearchFilter: false,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No product available',
          showCheckbox: true,
          enableFilterSelectAll: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }
  // Product List---------------

  GetAdminTypeData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.UserService.GetAdminUserType(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.adminTypeList = data['listOfadmintype'];
          if (this.adminTypeList != null) {
            for (let iCounter = 0; iCounter < this.adminTypeList.length; iCounter++) {
              this.dropdownListAdminType.push(
                { "id": this.adminTypeList[iCounter].adminUserTypeID, "itemName": this.adminTypeList[iCounter].adminUserName }
              );
            }
          }

        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
          this.unblockDocument();
        }
        this.dropdownSettingsAdminType = {
          singleSelection: true,
          text: "Select",
          enableSearchFilter: false,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No user type available',
          showCheckbox: false,
          enableFilterSelectAll: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }

  EnableDisableCompanyDropdown($event) {
    this.listOfCompany = [];
    this.selectedItemsclientCompany = [];
    if ($event.id == AdminUserType.systemuser)
      this.isControlEnable = false;
    else
      this.isControlEnable = true;
    this.SetCompanyDropdownSetting();
  }

  onSelectedAdminType($event) {
    this.listOfAdminTypeID = [];
    if (this.listOfAdminTypeID != null) {
      if (this.listOfAdminTypeID.length > 0) {
        this.listOfAdminTypeID = this.removeElemetninArray(this.listOfAdminTypeID, $event.id)
        this.listOfAdminTypeID.push($event.id);
      }
      else {
        this.listOfAdminTypeID.push($event.id);
      }
      this.EnableDisableCompanyDropdown($event);
    }
  }

  onDeSelectedAdminType($event) {
    if (this.listOfAdminTypeID != null) {
      if (this.listOfAdminTypeID.length > 0) {
        this.listOfAdminTypeID = this.removeElemetninArray(this.listOfAdminTypeID, $event.id)
      }
    }
    this.EnableDisableCompanyDropdown($event);
  }

  onSelectAllAdminType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfAdminTypeID != null) {
        if (this.listOfAdminTypeID.length > 0) {
          this.listOfAdminTypeID = this.removeElemetninArray(this.listOfAdminTypeID, $event[iCounter].id)
          this.listOfAdminTypeID.push($event[iCounter].id);
        }
        else {
          this.listOfAdminTypeID.push($event[iCounter].id);
        }
      }
      this.EnableDisableCompanyDropdown($event);
    }
  }

  onDeSelectAllAdminType($event) {
    this.listOfAdminTypeID = [];
    this.EnableDisableCompanyDropdown($event);
  }

  GetCompanyList(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.UserService.GetClientCompanyList(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
         
          this.clientCompanyList = data['listOfCompany'];
          if (this.clientCompanyList != null) {
            for (let iCounter = 0; iCounter < this.clientCompanyList.length; iCounter++) {
              this.dropdownListclientCompany.push(
                { "id": this.clientCompanyList[iCounter].companyId, "itemName": this.clientCompanyList[iCounter].companyName }
              );
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
          this.unblockDocument();
        }

      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }

  SetCompanyDropdownSetting() {
    this.dropdownSettingclientCompany = {
      singleSelection: false,
      text: "Select",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No company available',
      showCheckbox: true,
      enableFilterSelectAll: false,
      disabled: this.isControlEnable,
    };
  }
  // Company   
  onSelectedCompany($event) {
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
        this.listOfCompany.push($event.id);
      }
      else {
        this.listOfCompany.push($event.id);
      }
    }
  }

  onDeSelectedCompany($event) {
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
      }
    }
  }

  onSelectAllCompany($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllCompany($event) {
    this.listOfCompany = [];
  }
}
