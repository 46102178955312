import { Component, OnInit } from '@angular/core';
import { NewsModel } from 'src/Models/NewsModel';
import { I360Service } from 'src/Services/i360Service';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ElementRef, Input, ViewChild } from '@angular/core';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { ActionActivity } from 'src/Models/Common';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  listOfNews: NewsModel[];
  filterModel = new FilterModel();
  tokenKey: string = '';
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false

  @Input() url = location.href;
  @ViewChild('myElement', { static: false }) myElement: ElementRef;

  constructor(private i360Service: I360Service, private checkAuth: CheckAuth, private titleService: Title,
    private appActivity: ApplicationActivityService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '9';
    this.filterModel.orderBy = 'PubDate DESC';
    this.filterModel.userID = this._userID;
    this.titleService.setTitle('News | Inteserra - i360');
    //this.setSocialMedia();

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.i360NewsFindByPaged();
  }

  i360NewsFindByPaged() {
    this.i360Service.i360NewsFindByPaged(this.tokenKey, this.filterModel).subscribe(
      data => {
        if (data != null) {
          this.listOfNews = data['listOfNews'];
          if (this.listOfNews != null) {
            if (this.listOfNews.length > 0) {
              this.totalRecordCount = this.listOfNews[0].totalRecordCount;
            }
            else
              this.totalRecordCount = 0;
          }
          else
            this.totalRecordCount = 0;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  pageChanged($event: any) {
    this.applicationActivity('View', 'News - Page '+ $event.page, ActionActivity.News);
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.i360NewsFindByPaged();
  }

  setSocialMedia() {
    // initialise facebook sdk after it loads if required
    if (!window['fbAsyncInit']) {
      window['fbAsyncInit'] = function () {
        window['FB'].init({
          appId: 'your-app-id',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v3.0'
        });
      };
    }

    // load facebook sdk if required
    const url = 'https://connect.facebook.net/en_US/sdk.js';
    if (!document.querySelector(`script[src='${url}']`)) {
      let script = document.createElement('script');
      script.src = url;
      document.body.appendChild(script);
    }
  }

  ngAfterViewInit(): void {
    // add linkedin share button script tag to element
    // render facebook button
    window['FB'] && window['FB'].XFBML.parse();
  }

  applicationActivity(actionName, pageName, datatype = 0, actionUrl = '', dataId = 0) {
    ApplicationActivityStore(actionName, pageName, this.appActivity, dataId, datatype, actionUrl)
  }

}
