export class CarrierCategoryAdminModel {
  carrierCatId: number;
  carrierCatName: string;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
  isReadOnly: boolean
  createdUser: string;
  modifiedUser: string;
  totalRowCount: number;
  isDeleteActive: boolean;
  isActive: boolean;
  visiblityType?: any;
  visiblityTypeName?: any;
  isSelected?: any;
  productId?: any;
  sortOrder:number
}
