import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CrraUpdatesMasterService } from 'src/Services/crra-updates-master.service';
import { CRRAUpdateGetModel } from 'src/Models/CRRAUpdateModel';

@Component({
  selector: 'app-crra-updates',
  templateUrl: './crra-updates.component.html',
  styleUrls: ['./crra-updates.component.css']
})
export class CrraUpdatesComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofUpdate: CRRAUpdateGetModel[]

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  UpdateNote: string;


  upDowNoteCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  isAscending: boolean = false; 
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

//State List
dropdownListState = [];
selectedItemsState = [];
dropdownSettingsState = {};
listOfStateIds = [];
stateId: string = ' ';
stateList: StateMasterModel[] = [];
module="CRRA";
stateIds:string=' ';
UpdateId:number = 0;
openMenuIndex: number = -1;

  constructor(private router: Router, private crraUpdateMaster: CrraUpdatesMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService,private stateService:StateMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofUpdate = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'UpdateDate desc';
    this.UpdateNote = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Updates');
    
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdictions",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
 
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.isloading = true;
    this.GetUpdate();
  }
  
  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetUpdate();
  }


  GetUpdate() {
    this.isloading = true;
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.UpdateNote == "null") this.UpdateNote = " ";
    this.crraUpdateMaster.GetAllUpdate(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofUpdate = data['crraupdate'];
        if (this.listofUpdate != null) {
          if (this.listofUpdate.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofUpdate[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.UpdateNote = $event.target.value;
    else
      this.UpdateNote = 'null';

    this.resetPageNoandSize();
    this.GetUpdate();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowUpdateDateCSS=''; 
      if (sortBy === "UpdateNote") {
        this.upDowNoteCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
        this.upDowUpdateDateCSS = '';
      }
      else if (sortBy === "StateName") {
        this.upDowNoteCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowUpdateDateCSS = '';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowNoteCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowUpdateDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "UpdateNote") {
        this.upDowNoteCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
        this.upDowUpdateDateCSS = '';
      }
      else if (sortBy === "StateName") {
        this.upDowNoteCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-up';
        this.upDowUpdateDateCSS = '';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowNoteCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowUpdateDateCSS = 'fa fa-arrow-up';
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  addUpdate() {
    let navigation = '/admin/' + this.SelectedTool + '/updates/manage';
    this.router.navigate([navigation]);
  }

  updateUpdate(UpdateId) {
    let navigation = '/admin/' + this.SelectedTool + '/updates/manage/' + UpdateId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.UpdateId = id;
  }

  deleteUpdate(UpdateId) {
    this.isloading = true;
    this.crraUpdateMaster.DeleteUpdate(this.tokenKey, UpdateId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
            this.showMessage("Update Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.GetUpdate();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey,this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  resetPageNoandSize()
  {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
