import { Component, OnInit, ViewChild } from '@angular/core';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';
import { FilterModel } from 'src/Models/filterModel';
import { Title } from '@angular/platform-browser';
import { BriefingMasterModel } from 'src/Models/BriefingMasterModel';
import { StateMasterService } from 'src/Services/state-master.service';
import { ToolEnum } from 'src/Models/Common';
import * as FileSaver from 'file-saver';
import { UserBriefingModel } from 'src/Models/UserBriefingModel';
import { ToastrService } from 'ngx-toastr';
import { UserOtherFavoriteModel } from 'src/Models/UserOtherFavoriteModel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { ActionActivity } from 'src/Models/Common';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.css']
})
export class FavoriteComponent implements OnInit {
  tokenKey: string = '';
  loginUser: number = 0;

  listOfBriefing: BriefingMasterModel[];
  userBriefingModel = new UserBriefingModel();
  briefingOrderBy: string = '';
  briefingIsAscending: boolean = false

  briefingUpDowTitleCSS: string = '';
  briefingUpDowStateCSS: string = '';
  briefingUpDowUpdateTypeCSS: string = '';
  briefingUpDowUpdateDateCSS: string = '';

  briefingIsShowprogressSpinner: boolean;
  briefingBlockedDocument: boolean;

  briefingFilterModel = new FilterModel();
  briefingCurrentPage: number = 1;
  briefingTotalRecordCount: number = -1;
  briefingKeywordFilter: string = '';
  briefingIsDataLoad: boolean = false;

  productId: any = 0;
  SelectedTool: string = 'inteserra360';

  listOfUserOtherFavorite: UserOtherFavoriteModel[];
  userOtherFavoriteModel = new UserOtherFavoriteModel();
  userOtherFavoriteOrderBy: string = '';
  userOtherFavoriteIsAscending: boolean = false

  userOtherFavoriteUpDowTitleCSS: string = '';
  userOtherFavoriteUpDowStateCSS: string = '';
  userOtherFavoriteUpDowUpdateTypeCSS: string = '';
  userOtherFavoriteUpDowUpdateDateCSS: string = '';

  userOtherFavoriteIsShowprogressSpinner: boolean;
  userOtherFavoriteBlockedDocument: boolean;

  userOtherFavoriteFilterModel = new FilterModel();
  userOtherFavoriteCurrentPage: number = 1;
  userOtherFavoriteTotalRecordCount: number = -1;
  userOtherFavoriteKeywordFilter: string = '';
  userOtherFavoriteIsDataLoad: boolean = false;

  otherFavoriteForm: FormGroup;
  isMultipleClick: boolean = false;
  isSubmitted = false;
  @ViewChild('modelCancelButton', { static: true }) modelCancelButton;

  constructor(private _fb: FormBuilder, private toastr: ToastrService, private stateMasterService: StateMasterService,
    private titleService: Title, private checkAuth: CheckAuth, private i360Service: I360Service, private appActivity: ApplicationActivityService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginUser = +sessionStorage.getItem('UserId');
    this.titleService.setTitle('My Library | Inteserra - i360');
    this.productId = ToolEnum[this.SelectedTool];
    this.briefingFilterModel.pageNumber = '1';
    this.briefingFilterModel.pageSize = '10';
    this.briefingFilterModel.orderBy = 'ISNULL(ModifiedTime,CreatedTime) DESC';
    this.briefingFilterModel.userID = this.loginUser.toString();

    this.userOtherFavoriteFilterModel.pageNumber = '1';
    this.userOtherFavoriteFilterModel.pageSize = '10';
    this.userOtherFavoriteFilterModel.orderBy = 'ISNULL(ModifiedTime,CreatedTime) DESC';
    this.userOtherFavoriteFilterModel.userID = this.loginUser.toString();

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    this.otherFavoriteForm = this._fb.group({
      userOtherFavoriteId: 0,
      title: [''],
      url: ['', [Validators.required, Validators.pattern(reg)]],
      userId: this.loginUser,
    });
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit(): void {    
    this.GetFavoriteBriefingData();
    this.Geti360UserOtherFavoriteByPaged();
  }

  GetFavoriteBriefingData() {
    this.briefingIsDataLoad = true;
    this.listOfBriefing = [];
    this.briefingIsShowprogressSpinner = true;
    this.briefingBlockedDocument = true;
    this.i360Service.i360FavoriteBriefingByPaged(this.tokenKey, this.briefingFilterModel).subscribe(
      data => {
        this.briefingIsShowprogressSpinner = false;
        this.briefingBlockedDocument = false;
        this.briefingIsDataLoad = false;
        if (data != null) {
          this.listOfBriefing = data['briefingData'];
          if (this.listOfBriefing != null) {
            if (this.listOfBriefing.length > 0) {
              this.briefingTotalRecordCount = this.listOfBriefing[0].totalRecordCount;
            }
            else {
              this.briefingTotalRecordCount = 0;
            }
          }
          else {
            this.briefingTotalRecordCount = 0;
          }
        }
      },
      error => {
        this.briefingIsDataLoad = false;
        this.briefingBlockedDocument = false;
        this.briefingIsShowprogressSpinner = false;
        console.log(error);
      }
    );
  }

  briefingSorting(sortBy) {
    this.briefingUpDowStateCSS = '';
    this.briefingUpDowUpdateDateCSS = '';
    this.briefingUpDowTitleCSS = '';

    if (!this.briefingIsAscending) {
      this.briefingIsAscending = true;
      this.briefingFilterModel.orderBy = sortBy + ' DESC';

      if (sortBy === "stateName") {
        this.briefingUpDowStateCSS = 'active-up';
      }
      else if (sortBy === "updateDate") {
        this.briefingUpDowUpdateDateCSS = 'active-up';
      }
      else if (sortBy === "title") {
        this.briefingUpDowTitleCSS = 'active-up';
      }
    }
    else {
      this.briefingIsAscending = false;
      this.briefingFilterModel.orderBy = sortBy;
      if (sortBy === "stateName") {
        this.briefingUpDowStateCSS = 'active';
      }
      else if (sortBy === "updateDate") {
        this.briefingUpDowUpdateDateCSS = 'active';
      }
      else if (sortBy === "title") {
        this.briefingUpDowTitleCSS = 'active';
      }
    }
    this.briefingOrderBy = sortBy;
    this.GetFavoriteBriefingData();
  }

  keywordFilters($event) {
    this.briefingFilterModel.pageNumber = '1';
    if ($event.target.value != "" && $event.target.value != null) 
    this.briefingFilterModel.keywordFilter = $event.target.value; 
    else this.briefingFilterModel.keywordFilter = null; 

    this.userOtherFavoriteFilterModel.pageNumber = '1';
    if ($event.target.value != "" && $event.target.value != null) 
    this.userOtherFavoriteFilterModel.keywordFilter = $event.target.value; 
    else this.userOtherFavoriteFilterModel.keywordFilter = null; 


    this.GetFavoriteBriefingData();
    this.Geti360UserOtherFavoriteByPaged();
  }

  briefingPageChanged($event: any) {
    ApplicationActivityStore('View', 'My Library Favorite Page'+ $event.page, this.appActivity, ToolEnum.library, ActionActivity['My Library'], '/MyLibrary');
    this.briefingFilterModel.pageNumber = $event.page;
    this.GetFavoriteBriefingData();
  }

  downloadBriefingDownload(briefingId: number, docType: string) {
    this.briefingIsShowprogressSpinner = true;
    this.briefingBlockedDocument = true;
    this.i360Service.i360BriefingMasterFindbyBriefingId(this.tokenKey, briefingId).subscribe(
      data => {
        this.briefingIsShowprogressSpinner = false;
        this.briefingBlockedDocument = false;
        if (data != null) {
          if (data['briefingData'] != null) {
            let docPath = '';
            let stateName = data['briefingData'].stateName;
            if (docType == 'BriefingDoc') {
              docPath = data['briefingData'].briefingDocPath;
            }
            else {
              docPath = data['briefingData'].supportedDocPath;
            }
            if (docPath != '') {
              this.briefingFilterModel.docPath = docPath;
              this.briefingFilterModel.stateName = stateName;
              this.briefingFilterModel.docType = docType;
              this.i360Service.DownloadDocument(this.tokenKey, this.briefingFilterModel).subscribe(
                fileData => {                  
                  if (fileData != null) {
                    FileSaver.saveAs(fileData, docPath);                     
                    ApplicationActivityStore('View', 'Download My Library -'+ docType, this.appActivity, ToolEnum.library, ActionActivity['My Library'], docPath);    
                  }
                }, error => {
                  console.log(error);
                }
              );
            }
          }
        }
      }, error => {
        console.log(error);
        this.briefingIsShowprogressSpinner = false;
        this.briefingBlockedDocument = false;
      }
    );
  }

  removeBriefingToUserFavorite(briefingId: number) {
    ApplicationActivityStore('Delete', 'My Library Favorite', this.appActivity, ToolEnum.library, ActionActivity['My Library'], '');
    this.userBriefingModel.briefingId = briefingId;
    this.userBriefingModel.userId = this.loginUser;

    this.i360Service.i360UserBriefingRemoveFromFavotire(this.tokenKey, this.userBriefingModel).subscribe(
      data => {
        if (data != null) {
          if (data['id'] > 0) {
            // this.showMessage('Briefing removed from your library.', 'Removed', 'success');
            this.toastr.success('Removed from My Library.');
            this.briefingFilterModel.pageNumber = '1';
            this.GetFavoriteBriefingData();
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  Geti360UserOtherFavoriteByPaged() {
    this.userOtherFavoriteIsDataLoad = true;
    this.listOfUserOtherFavorite = [];
    this.userOtherFavoriteIsShowprogressSpinner = true;
    this.userOtherFavoriteBlockedDocument = true;
    this.i360Service.i360UserOtherFavoriteByPaged(this.tokenKey, this.userOtherFavoriteFilterModel).subscribe(
      data => {
        this.userOtherFavoriteIsShowprogressSpinner = false;
        this.userOtherFavoriteBlockedDocument = false;
        this.userOtherFavoriteIsDataLoad = false;

        if (data != null) {
          this.listOfUserOtherFavorite = data['listOfFavorite'];
          if (this.listOfUserOtherFavorite != null) {
            if (this.listOfUserOtherFavorite.length > 0) {
              this.userOtherFavoriteTotalRecordCount = this.listOfUserOtherFavorite[0].totalRecordCount;
            }
            else {
              this.userOtherFavoriteTotalRecordCount = 0;
            }
          }
          else {
            this.userOtherFavoriteTotalRecordCount = 0;
          }
        }
      },
      error => {
        this.userOtherFavoriteIsDataLoad = false;
        this.userOtherFavoriteBlockedDocument = false;
        this.userOtherFavoriteIsShowprogressSpinner = false;
        console.log(error);
      }
    );
  }

  userOtherFavoritePageChanged($event: any) {
    ApplicationActivityStore('View', 'My Library Other Favorite Page ' + $event.page, this.appActivity, ToolEnum.library, ActionActivity['My Library'], ''); 
    this.userOtherFavoriteFilterModel.pageNumber = $event.page;
    this.Geti360UserOtherFavoriteByPaged();
  }

  removeUserOtherFavorite(userOtherFavoriteId: number) {
    ApplicationActivityStore('Delete', 'My Library Other Favorite', this.appActivity, ToolEnum.library, ActionActivity['My Library'], '');  
    this.i360Service.i360UserOtherFavoriteDelete(this.tokenKey, userOtherFavoriteId).subscribe(
      data => {
        if (data != null) {
          if (data['id'] > 0) {
            this.showMessage('Item removed from your library.', 'Removed', 'success');
            this.userOtherFavoriteFilterModel.pageNumber = '1';
            this.Geti360UserOtherFavoriteByPaged();
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  userOtherFavoriteSorting(sortBy) {
    this.userOtherFavoriteUpDowStateCSS = '';
    this.userOtherFavoriteUpDowUpdateDateCSS = '';
    this.userOtherFavoriteUpDowTitleCSS = '';

    if (!this.userOtherFavoriteIsAscending) {
      this.userOtherFavoriteIsAscending = true;
      this.userOtherFavoriteFilterModel.orderBy = sortBy + ' DESC';

      if (sortBy === "title") {
        this.userOtherFavoriteUpDowTitleCSS = 'active-up';
      }
    }
    else {
      this.userOtherFavoriteIsAscending = false;
      this.userOtherFavoriteFilterModel.orderBy = sortBy;
      if (sortBy === "stateName") {
        this.userOtherFavoriteUpDowStateCSS = 'active';
      }
      else if (sortBy === "updateDate") {
        this.userOtherFavoriteUpDowUpdateDateCSS = 'active';
      }
      else if (sortBy === "title") {
        this.userOtherFavoriteUpDowTitleCSS = 'active';
      }
    }
    this.userOtherFavoriteOrderBy = sortBy;
    this.Geti360UserOtherFavoriteByPaged();
  }

  saveUserOtherFavorite(otherFavoriteForm) {
    this.isSubmitted = true;    
    this.userOtherFavoriteModel = otherFavoriteForm.value;
    this.userOtherFavoriteModel.isActive = true;
    this.userOtherFavoriteModel.createBy = this.loginUser;
    this.userOtherFavoriteModel.modifiedBy = this.loginUser;
    if (this.userOtherFavoriteModel.title == '' || this.userOtherFavoriteModel.title == null || typeof (this.userOtherFavoriteModel.title) == 'undefined') {
      this.showMessage('Please enter title.', 'Error', 'error');
      return;
    }

    if (this.userOtherFavoriteModel.url == '' || this.userOtherFavoriteModel.url == null || typeof (this.userOtherFavoriteModel.url) == 'undefined') {
      this.showMessage('Please enter url.', 'Error', 'error');
      return;
    }

    if (!this.otherFavoriteForm.valid) {
      return;
    }

    if (this.isMultipleClick) {      
      return;
    }
    this.isMultipleClick = true;
    this.i360Service.i360UserOtherFavoriteAddUpdate(this.tokenKey, this.userOtherFavoriteModel).subscribe(
      data => {
        this.isMultipleClick = false;
        this.isSubmitted = false;
        if (data != null) {
          if (data['id'] > 0) {
            this.modelCancelButton.nativeElement.click();
            this.showMessage('Item added in your favorite list.', 'Add', 'success');
            this.userOtherFavoriteFilterModel.pageNumber = '1';
            this.Geti360UserOtherFavoriteByPaged();
          }
        }
      },
      error => {
        console.log(error);
        this.isMultipleClick = false;
      }
    );
  }

  clearForm() {
    ApplicationActivityStore('Add', 'My Library Other Favorite', this.appActivity, ToolEnum.library, ActionActivity['My Library'], ''); 
    this.otherFavoriteForm.setValue({
      userOtherFavoriteId: 0,
      title: [''],
      url: [''],
      userId: this.loginUser,
    })
  }

  get url() { return this.otherFavoriteForm.get('url') };

  // applicationActivity(actionName, pageName, datatype = 0, dataId = 0, url = '') {
  //   ApplicationActivityStore(actionName, pageName, this.appActivity, dataId, datatype, url)
  // }

}

