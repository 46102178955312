import { Component, DebugElement, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { SpecialReportSectionsModel } from 'src/Models/SpecialReportModel';
import { CheckAuth } from 'src/Services/utility.service';
import { SpecialReportService } from 'src/Services/special-reports.services';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SectionMasterService } from 'src/Services/section-master.service';
import { SectionsModel } from 'src/Models/SectionMasterModel';

@Component({
  selector: 'app-special-reports-section-master',
  templateUrl: './special-reports-section-master.component.html',
  styleUrls: ['./special-reports-section-master.component.css']
})
export class SpecialReportsSectionMasterComponent implements OnInit {
  filterModel = new FilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfGetSpecialReportSectionData: SectionsModel[];


  isUserSystemAdmin: boolean = true;
  tokenKey: string = '';
  SelectedTool: string;
  sectionDetails: any = [];


  isAscending: boolean = false
  upDowSectionnameCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';
  upDowSortOrderCSS: string = '';
  upDowProductCSS:string='';

  productId: number = 1;
  deletedId: number = 0;
  isloading: boolean;
  specialReportId:number=0;
  selectedItemsStatus = [];
  dropdownSettingsStatus = {};
  listOfStatus = [];
  statusId: string = ' ';
  moduleList: any[] = [{ itemName: 'Yes', id: '1' }, { itemName: 'No', id: '0' }];
  dropdownListProduct = [];
  dropdownSettingsProducts = {};
  listOfProductID: string[] = [];
  selectedItemsProduct = [];
  listOfProduct?: string;
  PageNumber: number;
  listOfSectionID: string[] = [];
  openMenuIndex: number = -1;


  constructor( private activedRoute: ActivatedRoute,private specialreportservice: SpecialReportService, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService,private sectionService:SectionMasterService) {
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
      this.SelectedTool = sessionStorage.getItem('UserLoginTool');
      this.tokenKey = sessionStorage.getItem('tokenValue');
      this.productId = ToolEnum[this.SelectedTool];
      this.titleService.setTitle('Section');
      this.filterModel.pageNumber = '1';
      this.filterModel.pageSize = '10';
      this.filterModel.orderBy = 'SortOrder';
     }

  ngOnInit() {
    this.dropdownSettingsProducts = {
      singleSelection: true,
      text: "Filter by Product",
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No Product available',
    };
    this.dropdownSettingsStatus = {
      singleSelection: true,
      text: "Filter by Active",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No record available',
      showCheckbox: false
    };
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {

          this.specialReportId = params['id'];
          this.GetSpecialReportSectionData();
        }
      }
    });
    this.GetSpecialReportSectionData();
    this.getAllProduct();

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  GetSpecialReportSectionData() {
    this.listOfGetSpecialReportSectionData = [];
    this.isloading = true;
    this.sectionService.i360SectionGet(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfGetSpecialReportSectionData = data['sectionData'];
          if (this.listOfGetSpecialReportSectionData != null) {
            if (this.listOfGetSpecialReportSectionData.length > 0) {
              this.totalRecordCount = this.listOfGetSpecialReportSectionData[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filterModel.whereCondition = $event.target.value;
    }
    else {
      this.filterModel.whereCondition = '';
    }
    this.GetSpecialReportSectionData();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetSpecialReportSectionData();
  }

  cleanCssClass() {
    this.upDowSectionnameCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';
    this.upDowProductCSS = '';

  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case "SectionName": this.upDowSectionnameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "isActive": this.upDowIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "CreatedDate": this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "UpdatedDate": this.upDowModifiedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "sortOrder": this.upDowSortOrderCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "productName": this.upDowProductCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;

      default: break;
    }
    this.GetSpecialReportSectionData();
  }


  addSections() {
    this.router.navigate(['admin/inteserra360/Manage/SectionsMaster/']);
  }

  editData(id) {
    this.router.navigate(['admin/inteserra360/Manage/SectionsMaster/' + id])
  }

  setDeleteData(ids) {
    this.deletedId = ids;
  }

  deleteRecord(deletedId) {
    this.sectionService.i360SectionDelete(this.tokenKey, deletedId).subscribe(
      data => {
        if(data['status']=='Failure'){
          this.showMessage(data['msg'], 'Warning', 'error');
        }
        else {
        this.deletedId = 0;
        if (data != null) {
          if (data['deletedId'] > 0) {
            this.GetSpecialReportSectionData();
            this.toastr.success('Record deleted successfully.', 'Delete')
          }
        }
        }
      },
      error => {
      }
    );
  }

  drop(event: CdkDragDrop<string[]>): void {
    var CurrentShortOrder, PreviousShortOrder, currentcustomProductDetailsId, previouscustomProductDetailsId
    PreviousShortOrder = this.listOfGetSpecialReportSectionData[event.previousIndex]['sortOrder']
    previouscustomProductDetailsId = this.listOfGetSpecialReportSectionData[event.previousIndex]['sectionId']

    CurrentShortOrder = this.listOfGetSpecialReportSectionData[event.currentIndex]['sortOrder']
    currentcustomProductDetailsId = this.listOfGetSpecialReportSectionData[event.currentIndex]['sectionId']

    this.specialreportservice.i360SRSectionUpdate(this.tokenKey, previouscustomProductDetailsId, currentcustomProductDetailsId).subscribe(
      data => {
        this.deletedId = 0;
        if (data != null) {
          this.sectionService.i360SectionGet(this.tokenKey, this.filterModel).subscribe(
            data => {
              this.isloading = false;
              if (data != null) {
                this.listOfGetSpecialReportSectionData = data['sectionData'];
                if (this.listOfGetSpecialReportSectionData != null) {
                  if (this.listOfGetSpecialReportSectionData.length > 0) {
                    this.totalRecordCount = this.listOfGetSpecialReportSectionData[0].totalRecordCount;
                  }
                  else {
                    this.totalRecordCount = 0;
                  }
                }
                else {
                  this.totalRecordCount = 0;
                }
              }
            },
            error => {
              this.isloading = false;
              console.log(error);
            }
          );
        }
      },
      error => {
      }
    );

  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  onSelectStatus($event) {
    this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
    this.listOfStatus.push($event.id);
    this.statusId = ' ';
    if (this.listOfStatus.length > 0) {
      this.statusId = $event.id;
      this.filterModel.statusId=this.statusId;
    }else{
      this.filterModel.statusId=null;
    }
    
    this.GetSpecialReportSectionData();
  }

  onDeSelectStatus($event) {
    if (this.listOfStatus != null) {
      if (this.listOfStatus.length > 0) {
        this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
      }
    }
    this.statusId='';
    if (this.listOfStatus.length > 0) {
      this.statusId = $event.id;
    }
    this.filterModel.statusId=null;
    this.GetSpecialReportSectionData();
  }

  onDeSelectAllStatus($event) {
    this.statusId='';
    this.listOfStatus = [];
    this.filterModel.statusId=null;
    this.GetSpecialReportSectionData();
  }
  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  getAllProduct() {
    this.sectionService.i360GetProductsForSections(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        for (let iCounter = 0; iCounter < data['productList'].length; iCounter++) {
          this.dropdownListProduct.push(
            { 'id': data['productList'][iCounter].menuName, 'itemName': data['productList'][iCounter].menuName }
          );
        }
        if (this.listOfSectionID != null && this.listOfSectionID != undefined) {
          for (let iCounter = 0; iCounter < this.listOfSectionID.length; iCounter++) {
            if (this.listOfSectionID[iCounter] != '') {
              let obj = data["productList"].filter(m => m.productId == this.listOfSectionID[iCounter]);
              if (obj != null) {
                this.selectedItemsProduct.push({ "id": obj[0].productName, "itemName": obj[0].productName });
              }
            }
          }
        }
      }
    });
  }

  onSelect($event) {
    this.PageNumber = 1;
    if (this.listOfProductID != null) {
      this.listOfProductID=[];
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
        this.listOfProductID.push($event.id);
      }
      else {
        this.listOfProductID.push($event.id);
      }
    }

    this.listOfProduct = this.listOfProductID.join(',');
    this.filterModel.section=this.listOfProduct;
    this.GetSpecialReportSectionData();
  }

  onDeSelectAll($event) {
    this.PageNumber = 1;
    this.listOfProductID = [];
    this.listOfProduct = '';
    this.filterModel.section=this.listOfProduct;
    this.GetSpecialReportSectionData();
  }

  onDeSelect($event) {
    this.PageNumber = 1;
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
      }
    }
    this.listOfProduct = this.listOfProductID.join(',');
    this.filterModel.section=this.listOfProduct;
    this.GetSpecialReportSectionData();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
