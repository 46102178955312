import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { CarrierFavoriteFilterModel, FilterObligationUpdateModel } from 'src/Models/ObligationUpdatesModel';

@Injectable({
  providedIn: 'root'
})
export class CarrierObligationService {

  AppUrl: string = "";
  filterObligationUpdateModel = new FilterObligationUpdateModel
  CarrierFavoriteFilterModel=new CarrierFavoriteFilterModel;

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  ObligationAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CarrierObligationMaster/ObligationAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ObligationUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CarrierObligationMaster/ObligationUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  ObligationUpdate_Internal(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CarrierObligationMaster/ObligationUpdate_Internal';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllobligation(TokenKey: string, PageNumber: number, PageSize: number,orderBy:string,search:string,carriertype:string,exportexcel:boolean,typeofwindows:number=0): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetAllobligation/' + PageNumber + '/' + PageSize+'/'+orderBy+'/'+search+'/'+carriertype+'/'+exportexcel+'/'+typeofwindows;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllobligation_Internal(TokenKey: string, PageNumber: number, PageSize: number,orderBy:string,search:string,carriertype:string,exportexcel:boolean,typeofwindows:number=0): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetAllobligation_Internal/' + PageNumber + '/' + PageSize+'/'+orderBy+'/'+search+'/'+carriertype+'/'+exportexcel+'/'+typeofwindows;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  Deleteobligation(TokenKey: string,obligationId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/Deleteobligation/' + obligationId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetObligationById(TokenKey: string,obligationId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetObligationById/' + obligationId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetObligationhistoryById(TokenKey: string, PageNumber: number, PageSize: number, obligationId: number, orderBy: string, FieldId: string) {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetObligationhistoryById/' + PageNumber + '/' + PageSize + '/' + obligationId + '/' + orderBy + '/' + FieldId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCategory(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetCategory';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  // Carrier Page Services

  CarrierAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CarrierTypeObligationMaster/CarrierAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCarrier(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierTypeObligationMaster/GetCarrier';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllCarrier(TokenKey: string, PageNumber: number, PageSize: number, filterText: string,orderBy:string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierTypeObligationMaster/GetAllCarrier/' + filterText + '/' + PageNumber + '/' + PageSize+'/'+orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCarrierById(TokenKey: string,carrierTypeId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierTypeObligationMaster/GetCarrierById/' + carrierTypeId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteCarrier(TokenKey: string, carrierTypeId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierTypeObligationMaster/DeleteCarrier/' + carrierTypeId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  // For Updates page Services
  GetAllUpdate(TokenKey: string, PageNumber: number, PageSize: number, carrierId: string, orderBy: string): Observable<any> {
    this.filterObligationUpdateModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      carrierId: carrierId,
      orderBy: orderBy,
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CarrierObligationMaster/GetAllUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterObligationUpdateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteUpdate(TokenKey: string, updateId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/DeleteUpdate/' + updateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetUpdateById(TokenKey: string, updateId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetUpdateById/' + updateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  UpdateAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CarrierObligationMaster/UpdateAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllUpdateByUser(TokenKey: string, PageNumber: number, PageSize: number,carrierId: string, fromDate: string, toDate: string, orderBy: string, userId:number,isFavorite:number,note:string): Observable<any> {
    this.filterObligationUpdateModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      carrierId: carrierId,
      orderBy: orderBy,
      fromDate: fromDate,
      toDate: toDate,
      userId:userId,
      isFavorite:isFavorite,
      Note:note
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CarrierObligationMaster/GetAllUpdateByUser';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterObligationUpdateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllObligationByUser(TokenKey: string, PageNumber: number, PageSize: number,orderBy:string,userId:number,category: string,note:string,carriertype:string,favorite:number){
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetAllObligationByUser/' + PageNumber + '/' + PageSize+'/'+orderBy+'/'+userId+'/'+category+'/'+note+'/'+carriertype+'/'+favorite;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetChecklistInternalUser(TokenKey: string, PageNumber: number, PageSize: number,orderBy:string,userId:number,category: string,note:string,carriertype:string,favorite:number,exportlst:boolean){
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetChecklistInternalUser/' + PageNumber + '/' + PageSize+'/'+orderBy+'/'+userId+'/'+category+'/'+note+'/'+carriertype+'/'+favorite+'/'+exportlst;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ExportChecklistInternalUser(TokenKey: string, PageNumber: number, PageSize: number,orderBy:string,userId:number,category: string,note:string,carriertype:string,favorite:number){
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/ExportChecklistInternalUser/' + PageNumber + '/' + PageSize+'/'+orderBy+'/'+userId+'/'+category+'/'+note+'/'+carriertype+'/'+favorite;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getCompanyIdByUserId(TokenKey: string, UserId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetCompanyIdByUserId/' + UserId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ClientCarrierFavouriteAdd(TokenKey: string, _userID: number, CarrierId: string, isFavourite: number): Observable<any> {
    this.setAPIUrl();
    this.CarrierFavoriteFilterModel.clientId = _userID;
    this.CarrierFavoriteFilterModel.carrierTypeId = CarrierId;
    this.CarrierFavoriteFilterModel.isFavourite = isFavourite;

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/CarrierObligationMaster/ClientCarrierFavouriteAddRemove', this.CarrierFavoriteFilterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetFavoriteCarrierByUser(TokenKey: any, clientId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetClientFavouriteCarriers/' + clientId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

}
