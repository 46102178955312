import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppConfig } from '../Services/app.config';
import { HttpModule, RequestOptions } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';

// 3rd Party Component
import { EditorModule } from 'primeng/editor';
import { ToastrModule } from 'ngx-toastr';
import { ProgressBarModule } from 'primeng/progressbar';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { OrderModule } from 'ngx-order-pipe';
import { TabViewModule } from 'primeng/tabview';
import { TabsModule } from 'ngx-bootstrap';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CKEditorModule } from 'ckeditor4-angular';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ChartModule } from 'primeng/chart';
import {DropdownModule} from 'primeng/dropdown';
import {TooltipModule} from 'primeng/tooltip';
// import { AngularEditorModule } from '@kolkov/angular-editor';
//import { FullCalendarModule } from 'ng-fullcalendar';


// Admin Component
import { AdminLoginComponent } from './Admin/admin-login/admin-login.component';
import { SiteLayoutComponent } from './Layout/site-layout/site-layout.component';
import { AdminLayoutComponent } from './Layout/admin-layout/admin-layout.component';
import { DashboardComponent } from './Admin/dashboard/dashboard.component';
import { UsersComponent } from './Admin/users/users.component';
import { ReportsComponent } from './Admin/reports/reports.component';
import { StatesComponent } from './Admin/states/states.component';
import { StateMatrixsetupComponent } from './Admin/state-matrixsetup/state-matrixsetup.component';


// Services
import { UtilityService, CheckAuth } from '../Services/utility.service';
import { TokenService } from '../Services/token.service';
import { ClientLoginComponent } from './Front/client-login/client-login.component';
import { FundDetailsComponent } from './Shared/fund-details/fund-details.component';
import { MatrixFundDetailsService } from 'src/Services/matrix-fund-details.service';
import { StateFundPreviewComponent } from './Admin/state-fund-preview/state-fund-preview.component';
import { ManageuserComponent } from './Admin/users/manageuser.component';
import { UpdatesComponent } from './Admin/updates/updates.component';
import { ManageupdateComponent } from './Admin/updates/manageupdate.component';
import { StateManangesetupComponent } from './Admin/state-matrixsetup/state-manangesetup.component';
import { InteserraUsersComponent } from './Admin/inteserra-users/inteserra-users.component';
import { InteserraManageUsersComponent } from './Admin/inteserra-users/inteserra-manage-users.component';
import { ProfileComponent } from './Admin/profile/profile.component';
import { HtmlToPdf } from '../Services/htmlToPdf';

import { BypassService } from '../Services/bypass.service';

import { UpdateImportComponent } from './Admin/IPR/update-import/update-import.component';
import { AccessDeniedComponent } from './Admin/access-denied/access-denied.component';
import { IPRUpdatesComponent } from './Admin/IPR/updates/updates.component';
import { ManageIPRUpdateComponent } from './Admin/IPR/updates/manageupdate.component';
import { HistoryComponent } from './Admin/IPR/history/history.component';
import { IPRLogComponent } from './Admin/IPR/log/iprlog.component';

import { FrontAccessDeniedComponent } from './Front/access-denied/access-denied.component';

import { DndModule } from 'ng2-dnd';
import { CacheInterceptor } from 'src/interceptors/cache-interceptor';
import { GtagModule } from 'angular-gtag';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { SearchPanelComponent } from './Shared/search-panel/search-panel.component';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { AddNewActionPopupComponent } from './Shared/add-new-action-popup/add-new-action-popup.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionItemDetailsComponent } from './Shared/action-item-details/action-item-details.component';
import { ActionStatusPopupComponent } from './Shared/action-status-popup/action-status-popup.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NotesPopupComponent } from './Shared/notes-popup/notes-popup.component';


import { ActionItemDetailsPopupComponent } from './Shared/action-item-details-popup/action-item-details-popup.component';
import { LandingPageComponent } from './Admin/action-item/landing-page/landing-page.component';
import { ActionItemTypeComponent } from './Admin/action-item/action-item-type/action-item-type.component';
import { ManegeActionTypeComponent } from './Admin/action-item/action-item-type/manege-action-type.component';
import { ActionItemListComponent } from './Admin/action-item/action-item-list/action-item-list.component';
import { ManageActionItemComponent } from './Admin/action-item/action-item-list/manage-action-item.component';
import { GeneralAlertsComponent } from './Admin/action-item/general-alerts/general-alerts.component';
import { ManageGeneralAlertsComponent } from './Admin/action-item/general-alerts/manage-general-alerts.component';

import { SafePipe } from 'src/Services/SafePipe';
import { SafeHtmlPipe } from 'src/Services/SafeHtmlPipe';
import { CarrierCategoryComponent } from './Admin/action-item/carrier-category/carrier-category.component';
import { ManageCarrierCategoryComponent } from './Admin/action-item/carrier-category/manage-carrier-category.component';

import { EditActionPopupComponent } from './Shared/edit-action-popup/edit-action-popup.component';
import { StateCommissionComponent } from './Admin/state-commission/state-commission.component';
import { ManageStateCommissionComponent } from './Admin/state-commission/manage-state-commission.component';
import { CarrierTariffsComponent } from './Admin/carrier-tariffs/carrier-tariffs.component';
import { ManageCarrierTariffsComponent } from './Admin/carrier-tariffs/manage-carrier-tariffs.component';
import { BriefingComponent } from './Admin/briefing/briefing.component';
import { ManageBriefingComponent } from './Admin/briefing/manage-briefing.component';
import { Data477Component } from './Admin/data477/data477.component';
import { Data477ManageComponent } from './Admin/data477/data477-manage.component';

import { LoginLandingPageComponent } from './Front/login-landing-page/login-landing-page.component';
import { BriefingServicesComponent } from './Front/briefing-services/briefing-services.component';
import { RedirectService } from 'src/Services/RedirectService';
import { RegistratonComponent } from './Front/registraton/registraton.component';
import { UserLibraryComponent } from './Front/user-library/user-library.component';

import { TreeModule } from 'primeng/tree';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { NodeService } from 'src/Services/NodeService';
import { ContextMenuModule } from 'primeng/contextmenu';
import { FrontIndexComponent } from './Front/front-index/front-index.component';
import { OwlModule } from 'ngx-owl-carousel';
import { FrontStateCommissionComponent } from './Front/front-state-commission/front-state-commission.component';
import { FrontCarrierTariffComponent } from './Front/front-carrier-tariff/front-carrier-tariff.component';
import { RedLightStatusComponent } from './Front/red-light-status/red-light-status.component';
import { EmailVerificationComponent } from './Front/email-verification/email-verification.component';
import { AuthGuard } from './AuthGard/AuthGuard';
import { ManageSubscriptionsComponent } from './Front/manage-subscriptions/manage-subscriptions.component';
import { CompanyDirectoryComponent } from './Front/company-directory/company-directory.component';
import { FrontData477Component } from './Front/front-data477/front-data477.component';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NewsComponent } from './Front/news/news.component';
import { WebinarLibraryComponent } from './Front/webinar-library/webinar-library.component';
import { WhitePapersComponent } from './Front/white-papers/white-papers.component';
import { FavoriteComponent } from './Front/favorite/favorite.component';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareModule, ShareDirective } from 'ngx-sharebuttons';
import { ShareIconsModule, } from 'ngx-sharebuttons/icons';
import { FontAwesomeModule, FaIconLibrary, } from '@fortawesome/angular-fontawesome';

// import social buttons module
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { ChangePasswordComponent } from './Front/change-password/change-password.component';
import { ForgotPasswordComponent } from './Front/forgot-password/forgot-password.component';
import { CompanyComponent } from './Admin/company/company.component';
import { ManageCompanyComponent } from './Admin/company/managecompany.component';
import { AssignUserComponent } from './Admin/company/assign-user/assign-user.component';
import { ComplianceReportComponent } from './Front/report/compliance-report/compliance-report.component';
import { MenuComponent } from './Admin/menu/menu.component';
import { ManageMenuComponent } from './Admin/menu/manage-menu/manage-menu.component';
import { WebinarLibraryComponent as AdminWebinarLibraryComponent } from './Admin/webinar-library/webinar-library.component';
import { WhitePapersComponent as AdminWhitePapersComponent } from './Admin/white-papers/white-papers.component';
import { ManageWebinarLibraryComponent } from './Admin/webinar-library/manage-webinar-library/manage-webinar-library.component';
import { ManageWhitePapersComponent } from './Admin/white-papers/manage-white-papers/manage-white-papers.component';
import { HomePageBannerComponent } from './Admin/home-page-banner/home-page-banner.component';
import { ManageHomePageBannerComponent } from './Admin/home-page-banner/manage-home-page-banner/manage-home-page-banner.component';
import { ApplicationActivityComponent } from './Admin/application-activity/application-activity.component';
import { AdminSeminarsComponent } from './Admin/seminars/seminars.component';
import { ManageSeminarsComponent } from './Admin/seminars/manage-seminars/manage-seminars.component';
import { FrontSeminarComponent } from './Front/front-seminar/front-seminar.component';
import { CreatePasswordComponent } from './Front/create-password/create-password.component';
import { SpecialBriefingReportComponent } from './Front/special-briefing-report/special-briefing-report.component';
import { RMDUpdatesComponent } from './Admin/RMD/updates/rmd-updates.component';
import { RMDLogComponent } from './Admin/RMD/log/rmdlog.component';
import { BriefingDetailsComponent } from './Front/briefing-services/briefing-details.component';
import { BriefingEmailVarificationComponent } from './Front/briefing-email-varification/briefing-email-varification.component';
import { MonthlySummaryOfDeliveredBriefingsByCompanyComponent } from './Admin/briefing-report/monthly-summary-of-delivered-briefings-by-company.component';
import { MonthlyBillingOfRegulatoryMonitoringBulletinsComponent } from './Admin/briefing-report/monthly-billing-of-regulatory-monitoring-bulletins.component';
import { CompanySubscriptionReportsComponent } from './Admin/briefing-report/company-subscription-reports.component';
import { MappedColumnListingComponent } from './Admin/claim-module/mapped-column-listing.component';
import { ColumnMappingComponent } from './Admin/claim-module/column-mapping.component';
import { ClaimImportsComponent } from './Admin/claim-module/claim-imports.component';
import { ClaimReportsComponent } from './Front/claim-reports/claim-reports.component';
import { AdminClaimReportsComponent } from './Admin/claim-reports/admin-claim-reports.component';
import { AdminClaimDetailsReportsComponentComponent } from './Admin/claim-reports/admin-claim-details-reports-component.component';
import { ClaimImportsListingComponent } from './Admin/claim-module/claim-imports-listing.component';
import { ViewClaimProcessDetailsComponent } from './Admin/claim-module/view-claim-process-details.component';
import { ClaimDetailReportsComponent } from './Front/claim-reports/claim-detail-reports.component';
import { AdminClaimFullReportsComponent } from './Admin/claim-reports/admin-claim-full-reports.component';
import { AdminClaimYearReportComponent } from './Admin/claim-reports/admin-claim-year-report.component';
import { TrainingComponent } from './Admin/training/training.component';
import { ManageTrainingComponent } from './Admin/training/manage-training.component';
import { FrontTrainingComponentComponent } from './Front/front-training-component/front-training-component.component';
import { StateMasterAddEditComponent } from './Admin/911/state-master-add-edit/state-master-add-edit.component';
import { CountyComponent } from './Admin/911/county/county/county.component';
import { CreateoreditCountyComponent } from './Admin/911/county/createoredit-county/createoredit-county.component';
import { CityComponent } from './Admin/911/city/city/city.component';
import { CreateoreditCityComponent } from './Admin/911/city/createoredit-city/createoredit-city.component';
import { RateMain911Component } from './Admin/911/rate-main911/rate-main911.component';
import { CreateoreditRateMain911Component } from './Admin/911/rate-main911/createoredit-rate-main911/createoredit-rate-main911.component';
import { DatePipe } from '@angular/common';
import { ListOfClaimReportsComponent } from './Front/claim-reports/list-of-claim-reports.component';
import { FrontClaimImportReportsComponent } from './Front/claim-reports/front-claim-import-reports.component';
import { FrontViewClaimProcessComponent } from './Front/claim-reports/front-view-claim-process.component';
import { YearlyClaimReportsComponent } from './Front/claim-reports/yearly-claim-reports.component';
import { YearlyClaimDetailReportsComponent } from './Front/claim-reports/yearly-claim-detail-reports.component';
import { SummaryReportsComponent } from './Front/claim-reports/summary-reports.component';
import { SummaryDetailReportsComponent } from './Front/claim-reports/summary-detail-reports.component';
import { RateLookupComponent } from './Front/911-rate-lookup/rate-lookup/rate-lookup.component';
import { FutureChangesComponent } from './Front/911-rate-lookup/future-changes/future-changes.component';
import { CategoryAddUpdateComponent } from './Admin/TRFA/Category/category-add-update/category-add-update.component';
import { CategoryComponent } from './Admin/TRFA/Category/category/category.component';
import { FundTypeComponent } from './Admin/TRFA/FundType/fund-type/fund-type.component';
import { FundTypeAddUpdateComponent } from './Admin/TRFA/FundType/fund-type-add-update/fund-type-add-update.component';
import { CarriersComponent } from './Admin/TRFA/Carriers/carriers/carriers.component';
import { CarriersAddUpdateComponent } from './Admin/TRFA/Carriers/carriers-add-update/carriers-add-update.component';
import { FundComponent } from './Admin/TRFA/Fund/fund/fund.component';
import { FundAddUpdateComponent } from './Admin/TRFA/Fund/fund-add-update/fund-add-update.component';
import { UpdateComponent } from './Admin/TRFA/Update/update/update.component';
import { UpdateAddUpdateComponent } from './Admin/TRFA/Update/update-add-update/update-add-update.component';
import { ContactsComponent } from './Admin/911/contacts/contacts.component';
import { ContentComponent } from './Admin/TRFA/Content/content/content.component';
import { ContentAddUpdateComponent } from './Admin/TRFA/Content/content-add-update/content-add-update.component';
import { UpdatedViewComponent } from './Admin/TRFA/Content/updated-view/updated-view.component';
import { CompareViewComponent } from './Admin/TRFA/Content/compare-view/compare-view.component';
import { ImportRateMainComponent } from './Admin/911/import-rate-main/import-rate-main.component';
import { LinkComponent } from './Admin/TRFA/Link/link/link.component';
import { LinkAddUpdateComponent } from './Admin/TRFA/Link/link-add-update/link-add-update.component';
import { TrfaContentViewComponent } from './Front/TRFA/trfa-content-view/trfa-content-view.component';
import { TrfaUpdateViewComponent } from './Front/TRFA/trfa-update-view/trfa-update-view.component';
import { TrfaHomeComponent } from './Front/TRFA/trfa-home/trfa-home.component';
import { TrfaAboutComponent } from './Front/TRFA/trfa-about/trfa-about.component';
import { SpecialReportsComponent } from './Admin/special-reports/special-reports.component';
import { ManageSpecialReportsComponent } from './Admin/special-reports/manage-special-reports.component';
import { ContentListComponent } from './Admin/TRFA/Content/content-list/content-list.component';
import { SourceComponent } from './Admin/TRFA/Source/source/source.component';
import { SourceAddUpdateComponent } from './Admin/TRFA/Source/source-add-update/source-add-update.component';
import { CustomProductComponent } from './Front/custom-product/custom-product.component';
import { CustomCompanyProductComponent } from './Admin/custom-company-product/custom-company-product.component';
import { ManageCustomCompanyProductComponent } from './Admin/custom-company-product/manage-custom-company-product/manage-custom-company-product.component';
import { ContactRateComponent } from './Admin/911/rate-main911/contact-rate/contact-rate.component';
import { CreateoreditcontactComponent } from './Admin/911/contacts/createoreditcontact/createoreditcontact.component';
import { FTPData911Component } from './Admin/911/ftp-data911/ftp-data911.component';
import { FtpComponent } from './ftp/ftp.component';
import { DownloadfilesComponent } from './Front/downloadfiles/downloadfiles.component';
import { TRFADownloadComponent } from './Admin/TRFA/trfadownload/trfadownload.component';
import { DownloadTRFAfilesComponent } from './Front/download-trfafiles/download-trfafiles.component';
import { MyFavoritesComponent } from './Front/911-rate-lookup/my-favorites/my-favorites.component';
import { MytrfaFavoritesComponent } from './Front/TRFA/mytrfa-favorites/mytrfa-favorites.component';
import { Update911Component } from './Admin/911/update911/update911.component';
import { Update911AddUpdateComponent } from './Admin/911/update911/update911-add-update/update911-add-update.component';
import { UpdateViewComponent } from './Front/911-rate-lookup/update-view/update-view.component';
import { BriefingTypeComponent } from './Admin/briefing/briefing-type/briefing-type.component';
import { BriefingTypeAddUpdateComponent } from './Admin/briefing/briefing-type/briefing-type-add-update/briefing-type-add-update.component';
import { BriefingSubTypeComponent } from './Admin/briefing/briefing-sub-type/briefing-sub-type.component';
import { BriefingSubtypeAddUpdateComponent } from './Admin/briefing/briefing-sub-type/briefing-subtype-add-update/briefing-subtype-add-update.component';
import { TracItAlertsComponent } from './Admin/action-item/trac-it-alerts/trac-it-alerts.component';
import { ManageTracItAlertsComponent } from './Admin/action-item/trac-it-alerts/manage-trac-it-alerts/manage-trac-it-alerts.component';
import { UserTracItAlertsComponent } from './Admin/action-item/trac-it-alerts/user-trac-it-alerts/user-trac-it-alerts.component';
import { AdminByPassClientLoginComponent } from './Front/admin-by-pass-client-login/admin-by-pass-client-login.component';
import { TrfaAlertsComponent } from './Admin/TRFA/trfa-alerts/trfa-alerts.component';
import { UserTrfaAlertsComponent } from './Admin/TRFA/trfa-alerts/user-trfa-alerts/user-trfa-alerts.component';
import { ManageTrfaAlertsComponent } from './Admin/TRFA/trfa-alerts/manage-trfa-alerts/manage-trfa-alerts.component';
import { Alerts911Component } from './Admin/911/alerts911/alerts911.component';
import { User911AlertsComponent } from './Admin/911/alerts911/user911-alerts/user911-alerts.component';
import { Manage911AlertsComponent } from './Admin/911/alerts911/manage911-alerts/manage911-alerts.component';
import { VoipAlertsComponent } from './Admin/voip-alerts/voip-alerts.component';
import { UserVoipAlertsComponent } from './Admin/voip-alerts/user-voip-alerts/user-voip-alerts.component';
import { ManageVoipAlertsComponent } from './Admin/voip-alerts/manage-voip-alerts/manage-voip-alerts.component';
import { WirelessAlertsComponent } from './Admin/wireless-alerts/wireless-alerts.component';
import { ManageWirelessAlertsComponent } from './Admin/wireless-alerts/manage-wireless-alerts/manage-wireless-alerts.component';
import { UserWirelessAlertsComponent } from './Admin/wireless-alerts/user-wireless-alerts/user-wireless-alerts.component';
import { BriefingAlertsComponent } from './Admin/briefing/briefing-alerts/briefing-alerts.component';
import { UserBriefingAlertsComponent } from './Admin/briefing/briefing-alerts/user-briefing-alerts/user-briefing-alerts.component';
import { ManageBriefingAlertsComponent } from './Admin/briefing/briefing-alerts/manage-briefing-alerts/manage-briefing-alerts.component';
import { IprAlertsComponent } from './Admin/IPR/ipr-alerts/ipr-alerts.component';
import { UserIprAlertsComponent } from './Admin/IPR/ipr-alerts/user-ipr-alerts/user-ipr-alerts.component';
import { ManageIprAlertsComponent } from './Admin/IPR/ipr-alerts/manage-ipr-alerts/manage-ipr-alerts.component';
import { RmdAlertsComponent } from './Admin/RMD/rmd-alerts/rmd-alerts.component';
import { UserRmdAlertsComponent } from './Admin/RMD/rmd-alerts/user-rmd-alerts/user-rmd-alerts.component';
import { ManageRmdAlertsComponent } from './Admin/RMD/rmd-alerts/manage-rmd-alerts/manage-rmd-alerts.component';
// import { Updates911Component } from './Front/911-rate-lookup/updates911/updates911.component';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { MappedColumnLifelineListingComponent } from './Admin/claim-module-lifeline/mapped-column-lifeline-listing.component';
import { ColumnMappingLifelineComponent } from './Admin/claim-module-lifeline/column-mapping-lifeline.component';
import { ClaimImportsLifelineComponent } from './Admin/claim-module-lifeline/claim-imports-lifeline.component';
import { AdminClaimMonthlyLifelineReportsComponent } from './Admin/claim-reports-lifeline/admin-claim-monthly-lifeline-reports.component';
import { AdminClaimDetailsLifelineReportsComponent } from './Admin/claim-reports-lifeline/admin-claim-details-lifeline-reports.component';
import { ClaimImportsLifelineListingComponent } from './Admin/claim-module-lifeline/claim-imports-lifeline-listing.component';
import { ViewClaimProcessDetailsLifelineComponent } from './Admin/claim-module-lifeline/view-claim-process-details-lifeline.component';
import { AdminClaimSummaryLifelineReportsComponent } from './Admin/claim-reports-lifeline/admin-claim-summary-lifeline-reports.component';
import { AdminClaimYearlyLifelineReportComponent } from './Admin/claim-reports-lifeline/admin-claim-yearly-lifeline-report.component';

import { ListOfLifelineClaimReportsComponent } from './Front/claim-reports-lifeline/list-of-lifeline-claim-reports.component';
import { FrontViewClaimProcessLifelineComponent } from './Front/claim-reports-lifeline/front-view-claim-process-lifeline.component';
import { YearlyClaimLifelineReportsComponent } from './Front/claim-reports-lifeline/yearly-claim-lifeline-reports.component';
import { YearlyClaimDetailLifelineReportsComponent } from './Front/claim-reports-lifeline/yearly-claim-detail-lifeline-reports.component';
import { SummaryLifelineReportsComponent } from './Front/claim-reports-lifeline/summary-lifeline-reports.component';
import { SummaryDetailLifelineReportsComponent } from './Front/claim-reports-lifeline/summary-detail-lifeline-reports.component';
import { ClaimDetailLifelineReportsComponent } from './Front/claim-reports-lifeline/claim-detail-lifeline-reports.component';
import { ClaimLifelineReportsComponent } from './Front/claim-reports-lifeline/claim-lifeline-reports.component';
import { FrontClaimImportLifelineReportsComponent } from './Front/claim-reports-lifeline/front-claim-import-lifeline-reports.component';
import { AuditReportComponent } from './Admin/TRFA/audit-report/audit-report.component';
import { ContentHistoryComponent } from './Admin/TRFA/Content/content-history/content-history.component';
import { TrsComponent } from './Admin/TRS/trs/trs.component';
import { TrsAddUpdateComponent } from './Admin/TRS/trs-add-update/trs-add-update.component';
import { TRSCustomerComponent } from './Front/trscustomer/trscustomer.component';
import { TRSFavoritesComponent } from './Front/trscustomer/trsfavorites/trsfavorites.component';
import { CrraCarriersComponent } from './Admin/CRRA/Carriers/crra-carriers/crra-carriers.component';
import { CrraCarriersAddUpdateComponent } from './Admin/CRRA/Carriers/crra-carriers-add-update/crra-carriers-add-update.component';
import { CrraCategoryComponent } from './Admin/CRRA/Category/crra-category/crra-category.component';
import { CrraCategoryAddUpdateComponent } from './Admin/CRRA/Category/crra-category-add-update/crra-category-add-update.component';
import { CrraSubCategoryComponent } from './Admin/CRRA/Sub-Category/crra-sub-category/crra-sub-category.component';
import { CrraSubCategoryAddUpdateComponent } from './Admin/CRRA/Sub-Category/crra-sub-category-add-update/crra-sub-category-add-update.component';
import { CrraUpdatesComponent } from './Admin/CRRA/Updates/crra-updates/crra-updates.component';
import { CrraUpdatesAddUpdateComponent } from './Admin/CRRA/Updates/crra-updates-add-update/crra-updates-add-update.component';
import { DepositeComponent } from './Admin/CRRA/Deposite/deposite/deposite.component';
import { AddUpdateDepositeComponent } from './Admin/CRRA/Deposite/add-update-deposite/add-update-deposite.component';
import { CrraContentsComponent } from './Admin/CRRA/Content/crra-contents/crra-contents.component';
import { CrraContentsAddUpdateComponent } from './Admin/CRRA/Content/crra-contents-add-update/crra-contents-add-update.component';
import { CrraCompareViewComponent } from './Admin/CRRA/Content/crra-compare-view/crra-compare-view.component';
import { CrraContentListComponent } from './Admin/CRRA/Content/crra-content-list/crra-content-list.component';
import { RulesComponent } from './Front/CRRA/rules/rules.component';
import { CRRAHomeComponent } from './Front/CRRA/crrahome/crrahome.component';
import { MyCRRAFavouriteComponent } from './Front/CRRA/my-crra-favourite/my-crra-favourite.component';
import { CrraLinkComponent } from './Admin/CRRA/Link/crra-link/crra-link.component';
import { CrraLinkAddUpdateComponent } from './Admin/CRRA/Link/crra-link-add-update/crra-link-add-update.component';
import { DepositRatesComponent } from './Front/CRRA/deposit-rates/deposit-rates.component';
import { CrraFrontupdatesComponent } from './Front/CRRA/crra-frontupdates/crra-frontupdates.component';
import { ManageCrraComponent } from './Admin/CRRA/Manage-CRRA/manage-crra/manage-crra.component';
import { CrraAlertsComponent } from './Admin/CRRA/CRRA-Alerts/crra-alerts/crra-alerts.component';
import { UserCrraAlertsComponent } from './Admin/CRRA/CRRA-Alerts/user-crra-alerts/user-crra-alerts.component';
import { ManageCrraAlertsComponent } from './Admin/CRRA/CRRA-Alerts/manage-crra-alerts/manage-crra-alerts.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CrraContentHistoryComponent } from './Admin/CRRA/crra-content-history/crra-content-history.component';
import { ManageTRSComponent } from './Admin/TRS/manage-trs/manage-trs.component';
import { ClaimReportModuleComponent } from './Admin/claim-reports/claim-report-module/claim-report-module.component';
import { BaseRateComponent } from './Admin/Pricing-Quoting/base-rate/base-rate.component';
import { CompanyProfileInformationComponent } from './Front/client-profile/company-profile-information/company-profile-information.component';
import { AdditionalCompanyQuestionComponent } from './Front/client-profile/additional-company-question/additional-company-question.component';
import { AttachmentsToDetermineServicesComponent } from './Front/client-profile/attachments-to-determine-services/attachments-to-determine-services.component';
import { StateSelectionComponent } from './Front/client-profile/state-selection/state-selection.component';
import { BaseRateTypeComponent } from './Admin/Pricing-Quoting/base-rate-type/base-rate-type.component';
import { AddUpdateBaseRateTypeComponent } from './Admin/Pricing-Quoting/base-rate-type/add-update-base-rate-type/add-update-base-rate-type.component';
import { AddUpdateBaseRateComponent } from './Admin/Pricing-Quoting/base-rate/add-update-base-rate/add-update-base-rate.component';
import { AdminCompnayProfileInformationComponent } from './Admin/Pricing-Quoting/client-profile/admin-compnay-profile-information/admin-compnay-profile-information.component';
import { AdminAdditionalCompanyQuestionsComponent } from './Admin/Pricing-Quoting/client-profile/admin-additional-company-questions/admin-additional-company-questions.component';
import { AdminStateSelectionComponent } from './Admin/Pricing-Quoting/client-profile/admin-state-selection/admin-state-selection.component';
import { AdminAttachmentsToDetermineServicesComponent } from './Admin/Pricing-Quoting/client-profile/admin-attachments-to-determine-services/admin-attachments-to-determine-services.component';
import { PricingQuotesComponent } from './Admin/Pricing-Quoting/pricing-quotes/pricing-quotes.component';
import { AdminManageBaserateComponent } from './Admin/Pricing-Quoting/pricing-quotes/admin-manage-baserate/admin-manage-baserate.component';
import { PriceQuotesCalcComponent } from './Admin/Pricing-Quoting/pricing-quotes/price-quotes-calc/price-quotes-calc.component';
import { AddOnServicesComponent } from './Admin/Pricing-Quoting/pricing-quotes/add-on-services/add-on-services.component';
import { PriceQuotesComplianceComponent } from './Admin/Pricing-Quoting/pricing-quotes/price-quotes-compliance/price-quotes-compliance.component';
import { PricingQuotesProposalComponent } from './Admin/Pricing-Quoting/pricing-quotes/pricing-quotes-proposal/pricing-quotes-proposal.component';
import { Globle } from './Shared/global';
import { PriceQuotesListComponent } from './Front/client-profile/price-quotes-list/price-quotes-list.component';
import { AdditionalCompanyInformationComponent } from './Front/OnboardingDataCollection/additional-company-information/additional-company-information.component';
import { SensitiveInformationComponent } from './Front/OnboardingDataCollection/sensitive-information/sensitive-information.component';
import { InteserraPortalCredentialsComponent } from './Front/OnboardingDataCollection/inteserra-portal-credentials/inteserra-portal-credentials.component';
import { ClientQuoteComponent } from './Front/client-quotation/client-quote/client-quote.component';
import { ClientQuoteCompanyProfileInfoComponent } from './Front/client-quotation/client-quote-company-profile-info/client-quote-company-profile-info.component';
import { ClientQuoteAdditionalCompanyQuestionComponent } from './Front/client-quotation/client-quote-additional-company-question/client-quote-additional-company-question.component';
import { ClientQuoteStateSelectionComponent } from './Front/client-quotation/client-quote-state-selection/client-quote-state-selection.component';
import { ClientQuoteAttechedServicesComponent } from './Front/client-quotation/client-quote-atteched-services/client-quote-atteched-services.component';
import { AdminSensitiveInformationComponent } from './Admin/OnboardingDataCollection/admin-sensitive-information/admin-sensitive-information.component';
import { AdminInteserraPortalCredentialComponent } from './Admin/OnboardingDataCollection/admin-inteserra-portal-credential/admin-inteserra-portal-credential.component';
import { AdminAdditionalCompanyInformationComponent } from './Admin/OnboardingDataCollection/admin-additional-company-information/admin-additional-company-information.component';
import { ClientQuoteListComponent } from './Front/client-quotation/client-quote-list/client-quote-list.component';
import { QuotesAndProposalComponent } from './Front/quotes-and-proposal/quotes-and-proposal.component';
import { UserAutoLoginComponent } from './Front/user-auto-login/user-auto-login.component';
import { DownloadQuotesAndProposalComponent } from './Front/download-quotes-and-proposal/download-quotes-and-proposal.component';
import { UserByPassClientLoginComponent } from './Front/user-by-pass-client-login/user-by-pass-client-login.component';
import { PriceQuoteAdminCompanySelectionComponent } from './Admin/Pricing-Quoting/price-quote-admin-company-selection/price-quote-admin-company-selection.component';
import { AdminPriceQuoteNotesComponent } from './Admin/Pricing-Quoting/client-profile/admin-price-quote-notes/admin-price-quote-notes.component';
import { PriceQuoteNotesComponent } from './Front/client-profile/price-quote-notes/price-quote-notes.component';
import { ClientQuoteNotesComponent } from './Front/client-quotation/client-quote-notes/client-quote-notes.component';
import { MyProfileComponent } from './Front/my-profile/my-profile.component';
import { SpecialReportsSectionMasterComponent } from './Admin/special-reports-section-master/special-reports-section-master.component';
import { ManageSpecialReportsSectionMasterComponent } from './Admin/special-reports-section-master/manage-special-reports-section-master/manage-special-reports-section-master.component';
import { NoUsageReportComponent } from './Admin/no-usage-report/no-usage-report.component';
import { ActionItemUpdatesComponent } from './Admin/action-item/action-item-updates/action-item-updates.component';
import { TaxExemptionFormComponent } from './Admin/tax-exemption-form/tax-exemption-form.component';
import { ManagetaxExemptionFormComponent } from './Admin/tax-exemption-form/managetax-exemption-form/managetax-exemption-form.component';
import { FrontTaxExemptionFormsComponent } from './Front/front-tax-exemption-forms/front-tax-exemption-forms.component';
import { AdminOnboardingStateSelectionComponent } from './Admin/OnboardingDataCollection/admin-onboarding-state-selection/admin-onboarding-state-selection.component';
import { OnboardingStateSelectionComponent } from './Front/OnboardingDataCollection/onboarding-state-selection/onboarding-state-selection.component';
import { DndDirectives } from './Front/dnd-directives';
import { AdminInteserraResourcesComponent } from './Admin/admin-inteserra-resources/admin-inteserra-resources.component';
import { ManageInteserraResourcesComponent } from './Admin/admin-inteserra-resources/manage-inteserra-resources/manage-inteserra-resources.component';
import { InteserraResourcesComponent } from './Front/inteserra-resources/inteserra-resources.component';
import { AdminCompanyProfileComponent } from './Admin/company/admin-company-profile/admin-company-profile.component';
import { AdminCompanyProfileAdditionalQueComponent } from './Admin/company/admin-company-profile-additional-que/admin-company-profile-additional-que.component';
import { AdminCompanyProfileStateSelectionComponent } from './Admin/company/admin-company-profile-state-selection/admin-company-profile-state-selection.component';

import { CarrierObligationComponent } from './Admin/carrier-obligation/carrier-obligation.component';
import { CarrierObligationAddUpdateComponent } from './Admin/carrier-obligation/carrier-obligation-add-update/carrier-obligation-add-update.component';
import { CarrierObligationHistoryComponent } from './Admin/carrier-obligation/carrier-obligation-history/carrier-obligation-history.component';
import { CarrierObligationUpdatesComponent } from './Admin/carrier-obligation-updates/carrier-obligation-updates/carrier-obligation-updates.component';
import { CarrierObligationUpdatesAddUpdateComponent } from './Admin/carrier-obligation-updates/carrier-obligation-updates-add-update/carrier-obligation-updates-add-update.component';
import { CarrierTypeCfoComponent } from './Admin/carrier-type-cfo/carrier-type-cfo.component';
import { CarrierTypeCfoAddUpdateComponent } from './Admin/carrier-type-cfo/carrier-type-cfo-add-update/carrier-type-cfo-add-update.component';
import { CfoFrontobligationComponent } from './Front/Carrier-obligation/cfo-frontobligation/cfo-frontobligation.component';
import { CfoFrontupdatesComponent } from './Front/Carrier-obligation/cfo-frontupdates/cfo-frontupdates.component';
import { ChecklistComponent } from './Front/Carrier-obligation/cfo-checklist/checklist.component';
import { CfoFavoritesComponent } from './Front/Carrier-obligation/cfo-favorites/cfo-favorites.component';
import { CategoryTypeCfoAddUpdateComponent } from './Admin/category-type-cfo/category-type-cfo-add-update/category-type-cfo-add-update.component';
import { CategoryTypeCfoComponent } from './Admin/category-type-cfo/category-type-cfo.component';
import { PriceQuoteEmailVerificationComponent } from './Front/price-quote-email-verification/price-quote-email-verification.component';
import { CarrierObligationAuditComponentV2 } from './Admin/carrier-obligation/carrier-obligation-audit-v2/carrier-obligation-audit-v2.component';
import { ClientQuoteAddOnServicesComponent } from './Front/client-quotation/client-quote-add-on-services-selection/client-quote-add-on-services.component';
import { ClientPriceQuotesComplianceComponent } from './Front/client-quotation/client-quotes-compliance/client-price-quotes-compliance.component';
import { DownloadfilesonboardingComponent } from './Front/downloadfilesonboarding/downloadfilesonboarding.component';
import { MiscellaneousfeesComponent } from './Admin/Pricing-Quoting/miscellaneousfees/miscellaneousfees.component';
import { ManageMiscellaneousfeesComponent } from './Admin/Pricing-Quoting/miscellaneousfees/manage-miscellaneousfees/manage-miscellaneousfees.component';
import { MarketEntryComponent } from './Admin/Pricing-Quoting/market-entry/market-entry.component';
import { ClientInformationListComponent } from './Admin/Pricing-Quoting/client-profile/client-information-list/client-information-list.component';
import { OnboardingListComponent } from './Admin/OnboardingDataCollection/onboarding-list/onboarding-list.component';
import { AdminAttachmentsComponentComponent } from './Admin/company/admin-attachments-component/admin-attachments-component.component';
import { FrontAttachmentsComponentComponent } from './Front/client-profile/front-attachments-component/front-attachments-component.component';
import { IndividualCompanySubscriptionReportsComponent } from './Admin/briefing-report/individual-company-subscription-reports.component';
import { JurisdictionReportsComponent } from './Admin/briefing-report/jurisdiction-reports.component';
import { BriefingTypeReportsComponent } from './Admin/briefing-report/briefing-type-reports.component';
import { ProcessingReportsComponent } from './Admin/briefing-report/processing-reports.component';
import { CarrierObligationAuditComponentV3 } from './Admin/carrier-obligation/carrier-obligation-audit-v3/carrier-obligation-audit-v3.component';
import { CarrierObligationAuditInternalComponent } from './Admin/carrier-obligation/carrier-obligation-audit-internal/carrier-obligation-audit-internal.component';
import { AuditMonthComponent } from './Admin/911/audit-month/audit-month.component';
import { BriefingEmailDetailsComponent } from './Admin/briefing/briefing-email-details/briefing-email-details.component';
import { EmailSummaryComponent } from './Admin/briefing-report/email-summary/email-summary.component';
import { VoipJurisdictionReportsComponent } from './Admin/voip-reports/voip-jurisdiction-reports.component';
import { VoIPUpdateCategoryTypeReportsComponent } from './Admin/voip-report/update-category-type-reports.component';
import { VoipTypeReportsComponent } from './Admin/voip-report/voip-type-reports.component';
import { AllActiveSubscribersReportsComponent } from './Admin/voip-report/all-active-subscribers-reports.component';
import { CompanySubscriptionReportComponent } from './Admin/voip-reports/company-subscription-report/company-subscription-report.component';
import { WirelessAllActiveCompanyReportComponent } from './Admin/wireless-reports/all-active-companies/all-active-companies-report.component';
import { WirelessAllActiveSubscribersReportsComponent } from './Admin/wireless-reports/all-active-subscribers/all-active-subscribers-reports.component';
import { WirelessUpdateTypeReportsComponent } from './Admin/wireless-reports/update-type/update-type-reports.component';
import { WirelessJurisdictionReportsComponent } from './Admin/wireless-reports/jurisdiction/jurisdiction-reports.component';
import { WirelessCategoryReportsComponent } from './Admin/wireless-reports/category/category-type-reports.component';


export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    SafePipe,
    SafeHtmlPipe,
    AppComponent,
    AdminLoginComponent,
    AdminLayoutComponent,
    SiteLayoutComponent,
    DashboardComponent,
    UsersComponent,
    ReportsComponent,
    StatesComponent,
    StateMatrixsetupComponent,
    ClientLoginComponent,
    FundDetailsComponent,
    StateFundPreviewComponent,
    ManageuserComponent,
    UpdatesComponent,
    ManageupdateComponent,
    StateManangesetupComponent,
    InteserraUsersComponent,
    InteserraManageUsersComponent,
    ProfileComponent,
    UpdateImportComponent,
    AccessDeniedComponent,
    IPRUpdatesComponent,
    ManageIPRUpdateComponent,
    HistoryComponent,
    FrontAccessDeniedComponent,
    SearchPanelComponent,
    AddNewActionPopupComponent,
    ActionItemDetailsComponent,
    ActionStatusPopupComponent,
    NotesPopupComponent,
    ActionItemDetailsPopupComponent,
    LandingPageComponent,
    ActionItemTypeComponent,
    ManegeActionTypeComponent,
    ActionItemListComponent,
    ManageActionItemComponent,
    GeneralAlertsComponent,
    ManageGeneralAlertsComponent,
    CarrierCategoryComponent,
    ManageCarrierCategoryComponent,
    EditActionPopupComponent,
    StateCommissionComponent,
    ManageStateCommissionComponent,
    CarrierTariffsComponent,
    ManageCarrierTariffsComponent,
    BriefingComponent,
    ManageBriefingComponent,
    Data477Component,
    Data477ManageComponent,
    LoginLandingPageComponent,
    BriefingServicesComponent,
    RegistratonComponent,
    UserLibraryComponent,
    FrontIndexComponent,
    FrontStateCommissionComponent,
    FrontCarrierTariffComponent,
    RedLightStatusComponent,
    EmailVerificationComponent,
    ManageSubscriptionsComponent,
    CompanyDirectoryComponent,
    FrontData477Component,
    NewsComponent,
    WebinarLibraryComponent,
    WhitePapersComponent,
    FavoriteComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    CompanyComponent,
    ManageCompanyComponent,
    AssignUserComponent,
    ComplianceReportComponent,
    MenuComponent,
    ManageMenuComponent,
    ManageWebinarLibraryComponent,
    ManageWhitePapersComponent,
    AdminWebinarLibraryComponent,
    AdminWhitePapersComponent,
    HomePageBannerComponent,
    ManageHomePageBannerComponent,
    ApplicationActivityComponent,
    AdminSeminarsComponent,
    ManageSeminarsComponent,
    FrontSeminarComponent,
    CreatePasswordComponent,
    SpecialBriefingReportComponent,
    RMDUpdatesComponent,
    RMDLogComponent,
    BriefingDetailsComponent,
    BriefingEmailVarificationComponent,
    MonthlySummaryOfDeliveredBriefingsByCompanyComponent,
    MonthlyBillingOfRegulatoryMonitoringBulletinsComponent,
    CompanySubscriptionReportsComponent,
    ColumnMappingComponent,
    MappedColumnListingComponent,
    ClaimImportsComponent,
    ClaimReportsComponent,
    AdminClaimReportsComponent,
    AdminClaimDetailsReportsComponentComponent,
    ClaimImportsListingComponent,
    ViewClaimProcessDetailsComponent,
    ClaimDetailReportsComponent,
    AdminClaimFullReportsComponent,
    AdminClaimYearReportComponent,
    TrainingComponent,
    ManageTrainingComponent,
    FrontTrainingComponentComponent,
    StateMasterAddEditComponent,
    CountyComponent,
    CreateoreditCountyComponent,
    CityComponent,
    CreateoreditCityComponent,
    RateMain911Component,
    CreateoreditRateMain911Component,
    ListOfClaimReportsComponent,
    FrontClaimImportReportsComponent,
    FrontViewClaimProcessComponent,
    YearlyClaimReportsComponent,
    YearlyClaimDetailReportsComponent,
    SummaryReportsComponent,
    SummaryDetailReportsComponent,
    RateLookupComponent,
    FutureChangesComponent,
    CategoryAddUpdateComponent,
    CategoryComponent,
    FundTypeComponent,
    FundTypeAddUpdateComponent,
    CarriersComponent,
    CarriersAddUpdateComponent,
    FundComponent,
    FundAddUpdateComponent,
    UpdateComponent,
    UpdateAddUpdateComponent,
    ContactsComponent,
    ContentComponent,
    ContentAddUpdateComponent,
    UpdatedViewComponent,
    CompareViewComponent,
    ImportRateMainComponent,
    LinkComponent,
    LinkAddUpdateComponent,
    TrfaContentViewComponent,
    TrfaUpdateViewComponent,
    TrfaHomeComponent,
    TrfaAboutComponent,
    SpecialReportsComponent,
    ManageSpecialReportsComponent,
    ContentListComponent,
    SourceComponent,
    SourceAddUpdateComponent,
    SpecialReportsComponent,
    ManageSpecialReportsComponent, CustomProductComponent, CustomCompanyProductComponent, ManageCustomCompanyProductComponent,
    ContactRateComponent, CreateoreditcontactComponent, FTPData911Component, FtpComponent, DownloadfilesComponent, TRFADownloadComponent,
    DownloadTRFAfilesComponent, MyFavoritesComponent, MytrfaFavoritesComponent, Update911Component, Update911AddUpdateComponent, UpdateViewComponent,
    AdminByPassClientLoginComponent, BriefingTypeComponent, BriefingTypeAddUpdateComponent, BriefingSubTypeComponent,
    BriefingSubtypeAddUpdateComponent, TracItAlertsComponent, ManageTracItAlertsComponent, UserTracItAlertsComponent,
    TrfaAlertsComponent, UserTrfaAlertsComponent, ManageTrfaAlertsComponent, Alerts911Component, User911AlertsComponent,
    Manage911AlertsComponent, VoipAlertsComponent, UserVoipAlertsComponent, ManageVoipAlertsComponent, WirelessAlertsComponent,
    ManageWirelessAlertsComponent, UserWirelessAlertsComponent, BriefingAlertsComponent, UserBriefingAlertsComponent, ManageBriefingAlertsComponent,
    IprAlertsComponent, UserIprAlertsComponent, ManageIprAlertsComponent, RmdAlertsComponent, UserRmdAlertsComponent, ManageRmdAlertsComponent,
    MappedColumnLifelineListingComponent, ColumnMappingLifelineComponent, ClaimImportsLifelineComponent, AdminClaimMonthlyLifelineReportsComponent,
    AdminClaimDetailsLifelineReportsComponent, ClaimImportsLifelineListingComponent, ViewClaimProcessDetailsLifelineComponent,
    AdminClaimSummaryLifelineReportsComponent, AdminClaimYearlyLifelineReportComponent, FrontViewClaimProcessLifelineComponent,
    YearlyClaimLifelineReportsComponent, YearlyClaimDetailLifelineReportsComponent, SummaryLifelineReportsComponent, SummaryDetailLifelineReportsComponent,
    ClaimDetailLifelineReportsComponent, ClaimLifelineReportsComponent, FrontClaimImportLifelineReportsComponent, ListOfLifelineClaimReportsComponent, AuditReportComponent, ContentHistoryComponent, CrraCarriersComponent, CrraCarriersAddUpdateComponent, CrraCategoryComponent, CrraCategoryAddUpdateComponent, CrraSubCategoryComponent, CrraSubCategoryAddUpdateComponent, DepositeComponent, AddUpdateDepositeComponent,
    CrraUpdatesComponent, CrraUpdatesAddUpdateComponent, CrraContentsComponent, CrraContentsAddUpdateComponent, CrraCompareViewComponent, CrraContentListComponent, CrraLinkComponent, CrraLinkAddUpdateComponent, RulesComponent, CRRAHomeComponent, MyCRRAFavouriteComponent, ManageCrraComponent, CrraAlertsComponent, UserCrraAlertsComponent, ManageCrraAlertsComponent, DepositRatesComponent, CrraFrontupdatesComponent
    ,TrsComponent, TrsAddUpdateComponent, TRSCustomerComponent, TRSFavoritesComponent, ManageTRSComponent, CrraContentHistoryComponent,IPRLogComponent, ClaimReportModuleComponent, MyProfileComponent, NoUsageReportComponent, BaseRateComponent, CompanyProfileInformationComponent, AdditionalCompanyQuestionComponent, AttachmentsToDetermineServicesComponent, StateSelectionComponent, BaseRateTypeComponent, AddUpdateBaseRateComponent, AddUpdateBaseRateTypeComponent, AdminCompnayProfileInformationComponent, 
    AdminAdditionalCompanyQuestionsComponent, AdminStateSelectionComponent, AdminAttachmentsToDetermineServicesComponent, PricingQuotesComponent, AdminManageBaserateComponent, PriceQuotesCalcComponent, AddOnServicesComponent, PriceQuotesComplianceComponent, PricingQuotesProposalComponent, PriceQuotesListComponent, AdditionalCompanyInformationComponent, SensitiveInformationComponent, InteserraPortalCredentialsComponent,
    ClientQuoteComponent, ClientQuoteCompanyProfileInfoComponent,ClientQuoteAdditionalCompanyQuestionComponent,ClientQuoteStateSelectionComponent,ClientQuoteAttechedServicesComponent
    , AdminSensitiveInformationComponent, AdminInteserraPortalCredentialComponent, AdminAdditionalCompanyInformationComponent, ClientQuoteListComponent, QuotesAndProposalComponent, UserAutoLoginComponent, DownloadQuotesAndProposalComponent, UserByPassClientLoginComponent, PriceQuoteAdminCompanySelectionComponent, AdminPriceQuoteNotesComponent, PriceQuoteNotesComponent, ClientQuoteNotesComponent, SpecialReportsSectionMasterComponent, ManageSpecialReportsSectionMasterComponent, ActionItemUpdatesComponent, TaxExemptionFormComponent, ManagetaxExemptionFormComponent, FrontTaxExemptionFormsComponent, AdminOnboardingStateSelectionComponent, OnboardingStateSelectionComponent
    ,DndDirectives, AdminInteserraResourcesComponent, ManageInteserraResourcesComponent, InteserraResourcesComponent, AdminCompanyProfileComponent, AdminCompanyProfileAdditionalQueComponent, AdminCompanyProfileStateSelectionComponent,
    CarrierObligationComponent, CarrierObligationAddUpdateComponent, CarrierObligationHistoryComponent, CarrierObligationUpdatesComponent, CarrierObligationUpdatesAddUpdateComponent, CarrierTypeCfoComponent, CarrierTypeCfoAddUpdateComponent, CfoFrontobligationComponent, CfoFrontupdatesComponent,CfoFavoritesComponent,ChecklistComponent,CategoryTypeCfoComponent,CategoryTypeCfoAddUpdateComponent, PriceQuoteEmailVerificationComponent,ClientQuoteAddOnServicesComponent,ClientPriceQuotesComplianceComponent,PriceQuoteEmailVerificationComponent,CarrierObligationAuditComponentV2,CarrierObligationAuditComponentV3,DownloadfilesonboardingComponent, MiscellaneousfeesComponent,ManageMiscellaneousfeesComponent, MarketEntryComponent, ClientInformationListComponent, OnboardingListComponent, AdminAttachmentsComponentComponent, FrontAttachmentsComponentComponent, BriefingEmailDetailsComponent, EmailSummaryComponent,
    IndividualCompanySubscriptionReportsComponent,
    JurisdictionReportsComponent,
    BriefingTypeReportsComponent,
    ProcessingReportsComponent,
    CarrierObligationAuditInternalComponent,
    AuditMonthComponent,VoipJurisdictionReportsComponent,VoIPUpdateCategoryTypeReportsComponent,
    VoipTypeReportsComponent,AllActiveSubscribersReportsComponent,CompanySubscriptionReportComponent,
    WirelessAllActiveCompanyReportComponent,WirelessAllActiveSubscribersReportsComponent,WirelessUpdateTypeReportsComponent,WirelessJurisdictionReportsComponent,WirelessCategoryReportsComponent

  ],
  imports: [
    // ShareModule,
    // ShareButtonsModule.withConfig({
    //   debug: true
    // }),
    // ShareIconsModule,
    // FontAwesomeModule,
    PdfViewerModule,
    JwSocialButtonsModule,
    RadioButtonModule,
    OverlayPanelModule,
    FullCalendarModule,
    DndModule.forRoot(),
    AngularMultiSelectModule,
    DropdownModule,
    TooltipModule,
    BrowserAnimationsModule,
    ProgressBarModule,
    HttpModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    AccordionModule,
    CheckboxModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    EditorModule,
    ProgressSpinnerModule,
    BlockUIModule,
    NgbModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    OrderModule,
    TabViewModule,
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    CKEditorModule,
    ColorPickerModule,
    TreeModule,
    ToastModule,
    ButtonModule,
    ContextMenuModule,
    OwlModule,
    ScrollToModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
    }),
    DragDropModule,
    ChartModule,
    GtagModule.forRoot({ trackingId: 'UA-39058819-4', trackPageviews: false, debug: false }),
    RouterModule.forRoot([
      { path: '', redirectTo: 'index', pathMatch: 'full' },
      // { path: 'index', component: FrontIndexComponent },
      //{ path: 'login', component: ClientLoginComponent },
      { path: 'admin', component: AdminLoginComponent },
      { path: 'admin/login', component: AdminLoginComponent },
      { path: 'ftp/:id', component: FtpComponent },
      //{ path: 'registraton', component: RegistratonComponent },
      //{ path: 'login/:username/:haskey', component: ByPassLoginComponent },
      {
        path: 'admin',
        component: AdminLayoutComponent,
        children: [
          { path: 'wireless/dashboard', component: DashboardComponent },
          // { path: 'wireless/subscribers', component: UsersComponent },
          // { path: 'wireless/subscriber/manage', component: ManageuserComponent },
          // { path: 'wireless/subscriber/manage/:id', component: ManageuserComponent },
          // { path: 'wireless/users', component: InteserraUsersComponent },
          // { path: 'wireless/user/manage', component: InteserraManageUsersComponent },
          // { path: 'wireless/user/manage/:id', component: InteserraManageUsersComponent },
          { path: 'wireless/updates', component: UpdatesComponent },
          { path: 'wireless/updates/manage', component: ManageupdateComponent },
          { path: 'wireless/updates/manage/:id', component: ManageupdateComponent },
          { path: 'wireless/states', component: StatesComponent },
          { path: 'wireless/state/setup/manage/:id', component: StateManangesetupComponent },
          { path: 'wireless/state/setup/add/:id', component: StateMatrixsetupComponent },
          { path: 'wireless/state/setup/edit/:id/:id2', component: StateMatrixsetupComponent },
          { path: 'wireless/state/matrix/preview', component: StateFundPreviewComponent },
          { path: 'wireless/user/profile', component: ProfileComponent },
          { path: 'wireless/access-denied', component: AccessDeniedComponent },
          // { path: 'wireless/companies', component: CompanyComponent },
          // { path: 'wireless/company/manage', component: ManageCompanyComponent },
          // { path: 'wireless/company/manage/:id', component: ManageCompanyComponent },
          // { path: 'wireless/company/assignuser/:id', component: AssignUserComponent },
          // { path: 'wireless/menus', component: MenuComponent },
          // { path: 'wireless/menu/edit/:id', component: ManageMenuComponent },
          // { path: 'wireless/applicationactivity', component: ApplicationActivityComponent },
          { path: 'Wireless/Wireless-Alerts', component: WirelessAlertsComponent },
          { path: 'Wireless/Wireless-Alerts/user/:id', component: UserWirelessAlertsComponent },
          { path: 'Wireless/Wirelss-Alerts/manage/:id/:userid', component: ManageWirelessAlertsComponent },
          { path: 'Wireless/report/allactivecompanies', component: WirelessAllActiveCompanyReportComponent },
          { path: 'Wireless/report/allactivesubsciber', component: WirelessAllActiveSubscribersReportsComponent },
          { path: 'Wireless/report/updatetype', component: WirelessUpdateTypeReportsComponent },
          { path: 'Wireless/report/jurisdiction', component: WirelessJurisdictionReportsComponent },
          { path: 'Wireless/report/category', component: WirelessCategoryReportsComponent },


          { path: 'ipr/dashboard', component: DashboardComponent },
          // { path: 'ipr/subscribers', component: UsersComponent },
          // { path: 'ipr/subscriber/manage', component: ManageuserComponent },
          // { path: 'ipr/subscriber/manage/:id', component: ManageuserComponent },
          // { path: 'ipr/users', component: InteserraUsersComponent },
          // { path: 'ipr/user/manage', component: InteserraManageUsersComponent },
          // { path: 'ipr/user/manage/:id', component: InteserraManageUsersComponent },
          { path: 'ipr/user/profile', component: ProfileComponent },
          { path: 'ipr/import-updates', component: UpdateImportComponent },
          { path: 'ipr/access-denied', component: AccessDeniedComponent },
          { path: 'ipr/updates', component: IPRUpdatesComponent },
          { path: 'ipr/updates/manage/:id', component: ManageIPRUpdateComponent },
          { path: 'ipr/updates/history', component: HistoryComponent },
          { path: 'ipr/updates/managehistory', component: IPRLogComponent },
          // { path: 'ipr/companies', component: CompanyComponent },
          // { path: 'ipr/company/manage', component: ManageCompanyComponent },
          // { path: 'ipr/company/manage/:id', component: ManageCompanyComponent },
          // { path: 'ipr/company/assignuser/:id', component: AssignUserComponent },
          // { path: 'ipr/menus', component: MenuComponent },
          // { path: 'ipr/menu/edit/:id', component: ManageMenuComponent },
          // { path: 'ipr/applicationactivity', component: ApplicationActivityComponent },
          { path: 'ipr/ipr-Alerts', component: IprAlertsComponent },
          { path: 'ipr/ipr-Alerts/user/:id', component: UserIprAlertsComponent },
          { path: 'ipr/ipr-Alerts/manage/:id/:userid', component: ManageIprAlertsComponent },

          { path: 'voip/dashboard', component: DashboardComponent },
          { path: 'voip/updates', component: UpdatesComponent },
          { path: 'voip/updates/manage', component: ManageupdateComponent },
          { path: 'voip/updates/manage/:id', component: ManageupdateComponent },
          { path: 'voip/states', component: StatesComponent },
          { path: 'voip/state/setup/manage/:id', component: StateManangesetupComponent },
          { path: 'voip/state/setup/add/:id', component: StateMatrixsetupComponent },
          { path: 'voip/state/setup/edit/:id/:id2', component: StateMatrixsetupComponent },
          { path: 'voip/state/matrix/preview', component: StateFundPreviewComponent },
          { path: 'voip/user/profile', component: ProfileComponent },
          { path: 'voip/access-denied', component: AccessDeniedComponent },
          // { path: 'voip/subscribers', component: UsersComponent },
          // { path: 'voip/subscriber/manage', component: ManageuserComponent },
          // { path: 'voip/subscriber/manage/:id', component: ManageuserComponent },
          // { path: 'voip/users', component: InteserraUsersComponent },
          // { path: 'voip/user/manage', component: InteserraManageUsersComponent },
          // { path: 'voip/user/manage/:id', component: InteserraManageUsersComponent },
          // { path: 'voip/companies', component: CompanyComponent },
          // { path: 'voip/company/manage', component: ManageCompanyComponent },
          // { path: 'voip/company/manage/:id', component: ManageCompanyComponent },
          // { path: 'voip/company/assignuser/:id', component: AssignUserComponent },
          // { path: 'voip/menus', component: MenuComponent },
          // { path: 'voip/menu/edit/:id', component: ManageMenuComponent },
          // { path: 'voip/applicationactivity', component: ApplicationActivityComponent },
          { path: 'voip/voip-Alerts', component: VoipAlertsComponent },
          { path: 'voip/voip-Alerts/user/:id', component: UserVoipAlertsComponent },
          { path: 'voip/voip-Alerts/manage/:id/:userid', component: ManageVoipAlertsComponent },
          { path: 'voip/voip-reports/jurisdiction', component: VoipJurisdictionReportsComponent },
          { path: 'voip/voip-report/voip-update-category', component: VoIPUpdateCategoryTypeReportsComponent },
          { path: 'voip/report/updatetype', component: VoipTypeReportsComponent },
          { path: 'voip/voip-reports/all-activate-subsciber', component: AllActiveSubscribersReportsComponent },
          { path: 'voip/company/subscription', component: CompanySubscriptionReportComponent },


          { path: 'trac-it/dashboard', component: DashboardComponent },
          // { path: 'trac-it/home', component: LandingPageComponent },          
          { path: 'trac-it/categories', component: ActionItemTypeComponent },
          { path: 'trac-it/category/manage', component: ManegeActionTypeComponent },
          { path: 'trac-it/category/manage/:id', component: ManegeActionTypeComponent },
          { path: 'trac-it/entries', component: ActionItemListComponent },
          { path: 'trac-it/manage', component: ManageActionItemComponent },
          { path: 'trac-it/manage/:id', component: ManageActionItemComponent },
          { path: 'trac-it/Trac-itAlerts', component: TracItAlertsComponent },
          { path: 'trac-it/updates', component: ActionItemUpdatesComponent },
          { path: 'trac-it/Trac-itAlerts/user/:id', component: UserTracItAlertsComponent },
          { path: 'trac-it/Trac-itAlerts/manage/:id/:userid', component: ManageTracItAlertsComponent },
          { path: 'trac-it/carriertypes', component: CarrierCategoryComponent },
          { path: 'trac-it/carriertype/manage', component: ManageCarrierCategoryComponent },
          { path: 'trac-it/carriertype/manage/:id', component: ManageCarrierCategoryComponent },
          { path: 'trac-it/access-denied', component: AccessDeniedComponent },
          // { path: 'trac-it/subscribers', component: UsersComponent },
          // { path: 'trac-it/subscriber/manage', component: ManageuserComponent },
          // { path: 'trac-it/subscriber/manage/:id', component: ManageuserComponent },
          // { path: 'trac-it/users', component: InteserraUsersComponent },
          // { path: 'trac-it/user/manage', component: InteserraManageUsersComponent },
          // { path: 'trac-it/user/manage/:id', component: InteserraManageUsersComponent },
          { path: 'trac-it/user/profile', component: ProfileComponent },
          // { path: 'trac-it/companies', component: CompanyComponent },
          // { path: 'trac-it/company/manage', component: ManageCompanyComponent },
          // { path: 'trac-it/company/manage/:id', component: ManageCompanyComponent },
          // { path: 'trac-it/company/assignuser/:id', component: AssignUserComponent },
          // { path: 'trac-it/menus', component: MenuComponent },
          // { path: 'trac-it/menu/edit/:id', component: ManageMenuComponent },
          // { path: 'trac-it/applicationactivity', component: ApplicationActivityComponent },

          { path: 'inteserra360/access-denied', component: AccessDeniedComponent },
          { path: 'inteserra360/dashboard', component: DashboardComponent },
          { path: 'inteserra360/statecommissions', component: StateCommissionComponent },
          { path: 'inteserra360/statecommission/manage', component: ManageStateCommissionComponent },
          { path: 'inteserra360/statecommission/manage/:id', component: ManageStateCommissionComponent },
          { path: 'inteserra360/carriertariffs', component: CarrierTariffsComponent },
          { path: 'inteserra360/carriertariff/manage', component: ManageCarrierTariffsComponent },
          { path: 'inteserra360/carriertariff/manage/:id', component: ManageCarrierTariffsComponent },
          { path: 'inteserra360/477data', component: Data477Component },
          { path: 'inteserra360/477data/manage', component: Data477ManageComponent },
          { path: 'inteserra360/477data/manage/:id', component: Data477ManageComponent },
          { path: 'inteserra360/WebinarLibrary', component: AdminWebinarLibraryComponent },
          { path: 'inteserra360/InteserraResources', component: AdminInteserraResourcesComponent },
          { path: 'inteserra360/WebinarLibrary/manage', component: ManageWebinarLibraryComponent },
          { path: 'inteserra360/WebinarLibrary/manage/:id', component: ManageWebinarLibraryComponent },
          { path: 'inteserra360/InteserraResources/manage', component: ManageInteserraResourcesComponent },
          { path: 'inteserra360/InteserraResources/manage/:id', component: ManageInteserraResourcesComponent },
          { path: 'inteserra360/WhitePaper', component: AdminWhitePapersComponent },
          { path: 'inteserra360/WhitePaper/manage', component: ManageWhitePapersComponent },
          { path: 'inteserra360/WhitePaper/manage/:id', component: ManageWhitePapersComponent },
          { path: 'inteserra360/Banner', component: HomePageBannerComponent },
          { path: 'inteserra360/Banner/manage', component: ManageHomePageBannerComponent },
          { path: 'inteserra360/Banner/manage/:id', component: ManageHomePageBannerComponent },
          { path: 'inteserra360/Seminar', component: AdminSeminarsComponent },
          { path: 'inteserra360/Seminar/manage', component: ManageSeminarsComponent },
          { path: 'inteserra360/Seminar/manage/:id', component: ManageSeminarsComponent },
          { path: 'inteserra360/i360Alerts', component: GeneralAlertsComponent },
          { path: 'inteserra360/i360Alerts/manage', component: ManageGeneralAlertsComponent },
          { path: 'inteserra360/i360Alerts/manage/:id', component: ManageGeneralAlertsComponent },
          { path: 'inteserra360/home', component: LandingPageComponent },
          { path: 'inteserra360/Training', component: TrainingComponent },
          { path: 'inteserra360/Manage/Training', component: ManageTrainingComponent },
          { path: 'inteserra360/Manage/Training/:id', component: ManageTrainingComponent },
          { path: 'inteserra360/SpecialReports', component: SpecialReportsComponent },
          { path: 'inteserra360/Manage/SpecialReports', component: ManageSpecialReportsComponent },
          { path: 'inteserra360/Manage/SpecialReports/:id', component: ManageSpecialReportsComponent },
          { path: 'inteserra360/custom-company-product/:id', component: CustomCompanyProductComponent },
          { path: 'inteserra360/Manage/custom-company-product/:cid', component: ManageCustomCompanyProductComponent },
          { path: 'inteserra360/Manage/custom-company-product/:cid/:id', component: ManageCustomCompanyProductComponent },
          { path: 'inteserra360/user/profile', component: ProfileComponent },
          { path: 'inteserra360/trs-customer-notice', component: TrsComponent },
          { path: 'inteserra360/trs-customer-notice/manage', component: TrsAddUpdateComponent },
          { path: 'inteserra360/trs-customer-notice/manage/:id', component: TrsAddUpdateComponent },
          { path: 'inteserra360/manage-trs', component: ManageTRSComponent },
          { path: 'inteserra360/SectionsMaster', component: SpecialReportsSectionMasterComponent },
          { path: 'inteserra360/Manage/SectionsMaster', component: ManageSpecialReportsSectionMasterComponent },
          { path: 'inteserra360/Manage/SectionsMaster/:id', component: ManageSpecialReportsSectionMasterComponent },
          { path: 'inteserra360/TaxExemptionForms', component: TaxExemptionFormComponent },
          { path: 'inteserra360/Manage/TaxExemptionForms', component: ManagetaxExemptionFormComponent },
          { path: 'inteserra360/Manage/TaxExemptionForms/:id', component: ManagetaxExemptionFormComponent },

          // { path: 'inteserra360/subscribers', component: UsersComponent },
          // { path: 'inteserra360/companies', component: CompanyComponent },
          // { path: 'inteserra360/company/manage', component: ManageCompanyComponent },
          // { path: 'inteserra360/company/manage/:id', component: ManageCompanyComponent },
          // { path: 'inteserra360/company/assignuser/:id', component: AssignUserComponent },
          // { path: 'inteserra360/menus', component: MenuComponent },
          // { path: 'inteserra360/menu/edit/:id', component: ManageMenuComponent },
          // { path: 'inteserra360/applicationactivity', component: ApplicationActivityComponent },

          { path: 'rmd/dashboard', component: DashboardComponent },
          { path: 'rmd/access-denied', component: AccessDeniedComponent },
          { path: 'rmd/updates', component: RMDUpdatesComponent },
          { path: 'rmd/updates/history', component: RMDLogComponent },
          // { path: 'rmd/subscribers', component: UsersComponent },
          // { path: 'rmd/subscriber/manage', component: ManageuserComponent },
          // { path: 'rmd/subscriber/manage/:id', component: ManageuserComponent },
          // { path: 'rmd/users', component: InteserraUsersComponent },
          // { path: 'rmd/user/manage', component: InteserraManageUsersComponent },
          // { path: 'rmd/user/manage/:id', component: InteserraManageUsersComponent },
          { path: 'rmd/user/profile', component: ProfileComponent },
          // { path: 'rmd/companies', component: CompanyComponent },
          // { path: 'rmd/company/manage', component: ManageCompanyComponent },
          // { path: 'rmd/company/manage/:id', component: ManageCompanyComponent },
          // { path: 'rmd/company/assignuser/:id', component: AssignUserComponent },
          // { path: 'rmd/menus', component: MenuComponent },
          // { path: 'rmd/menu/edit/:id', component: ManageMenuComponent },
          // { path: 'rmd/applicationactivity', component: ApplicationActivityComponent },
          { path: 'rmd/rmd-Alerts', component: RmdAlertsComponent },
          { path: 'rmd/rmd-Alerts/user/:id', component: UserRmdAlertsComponent },
          { path: 'rmd/rmd-Alerts/manage/:id/:userid', component: ManageRmdAlertsComponent },

          { path: 'briefingServices/dashboard', component: DashboardComponent },
          { path: 'briefingServices/list', component: BriefingComponent },
          { path: 'briefingServices/manage', component: ManageBriefingComponent },
          { path: 'briefingServices/manage/:id', component: ManageBriefingComponent },
          { path: 'briefingServices/access-denied', component: AccessDeniedComponent },
          { path: 'briefingServices/briefingreport/monthly/summary', component: MonthlySummaryOfDeliveredBriefingsByCompanyComponent },
          { path: 'briefingServices/briefingreport/monthly/billing', component: MonthlyBillingOfRegulatoryMonitoringBulletinsComponent },
          { path: 'briefingServices/briefingreport/company/subscription', component: CompanySubscriptionReportsComponent },
          { path: 'briefingServices/briefingreport/individualcompany/subscription', component: IndividualCompanySubscriptionReportsComponent },
          { path: 'briefingServices/briefingreport/jurisdiction', component: JurisdictionReportsComponent },
          { path: 'briefingServices/briefingreport/briefingType', component: BriefingTypeReportsComponent },
          { path: 'briefingServices/briefingreport/processing', component: ProcessingReportsComponent },
          { path: 'briefingServices/briefingType', component: BriefingTypeComponent },
          { path: 'briefingServices/briefingType/manage', component: BriefingTypeAddUpdateComponent },
          { path: 'briefingServices/briefingType/manage/:id', component: BriefingTypeAddUpdateComponent },
          { path: 'briefingServices/briefingSubType', component: BriefingSubTypeComponent },
          { path: 'briefingServices/briefingSubType/manage', component: BriefingSubtypeAddUpdateComponent },
          { path: 'briefingServices/briefingSubType/manage/:id', component: BriefingSubtypeAddUpdateComponent },
          { path: 'briefingServices/briefingEmailDetails/:id', component: BriefingEmailDetailsComponent },
          { path: 'briefingServices/emailSummary', component: EmailSummaryComponent },
          // { path: 'briefingServices/subscribers', component: UsersComponent },
          // { path: 'briefingServices/subscriber/manage', component: ManageuserComponent },
          // { path: 'briefingServices/subscriber/manage/:id', component: ManageuserComponent },
          // { path: 'briefingServices/users', component: InteserraUsersComponent },
          // { path: 'briefingServices/user/manage', component: InteserraManageUsersComponent },
          // { path: 'briefingServices/user/manage/:id', component: InteserraManageUsersComponent },
          { path: 'briefingServices/user/profile', component: ProfileComponent },
          // { path: 'briefingServices/companies', component: CompanyComponent },
          // { path: 'briefingServices/company/manage', component: ManageCompanyComponent },
          // { path: 'briefingServices/company/manage/:id', component: ManageCompanyComponent },
          // { path: 'briefingServices/company/assignuser/:id', component: AssignUserComponent },
          // { path: 'briefingServices/menus', component: MenuComponent },
          // { path: 'briefingServices/menu/edit/:id', component: ManageMenuComponent },
          // { path: 'briefingServices/applicationactivity', component: ApplicationActivityComponent },
          { path: 'briefingServices/briefing-Alerts', component: BriefingAlertsComponent },
          { path: 'briefingServices/briefing-Alerts/user/:id', component: UserBriefingAlertsComponent },
          { path: 'briefingServices/briefing-Alerts/manage/:id/:userid', component: ManageBriefingAlertsComponent },

          { path: 'inroll/dashboard', component: DashboardComponent },
          { path: 'inroll/access-denied', component: AccessDeniedComponent },

          { path: 'inroll/column/listing', component: MappedColumnListingComponent },
          { path: 'inroll/column/mapping', component: ColumnMappingComponent },
          { path: 'inroll/column/mapping/:id', component: ColumnMappingComponent },
          { path: 'inroll/claim/import', component: ClaimImportsComponent },
          { path: 'inroll/claim/reports', component: AdminClaimReportsComponent },
          { path: 'inroll/claim/details/reports', component: AdminClaimDetailsReportsComponentComponent },
          { path: 'inroll/claim/listing', component: ClaimImportsListingComponent },
          { path: 'inroll/view/claim', component: ViewClaimProcessDetailsComponent },
          { path: 'inroll/claim/totalcountreports', component: AdminClaimFullReportsComponent },
          { path: 'inroll/claim/yearreports', component: AdminClaimYearReportComponent },

          { path: 'inroll/column/lifeline-listing', component: MappedColumnLifelineListingComponent },
          { path: 'inroll/column/lifeline-mapping', component: ColumnMappingLifelineComponent },
          { path: 'inroll/column/lifeline-mapping/:id', component: ColumnMappingLifelineComponent },
          { path: 'inroll/claim/lifeline-import', component: ClaimImportsLifelineComponent },
          { path: 'inroll/claim/lifeline-monthly-reports', component: AdminClaimMonthlyLifelineReportsComponent },
          { path: 'inroll/claim/details/lifeline-reports', component: AdminClaimDetailsLifelineReportsComponent },
          { path: 'inroll/claim/lifeline-listing', component: ClaimImportsLifelineListingComponent },
          { path: 'inroll/view/lifeline-claim', component: ViewClaimProcessDetailsLifelineComponent },
          { path: 'inroll/claim/lifeline-claim-summary', component: AdminClaimSummaryLifelineReportsComponent },
          { path: 'inroll/claim/lifeline-yearly-reports', component: AdminClaimYearlyLifelineReportComponent },
          { path: 'inroll/view/claimReport/:id', component: ClaimReportModuleComponent },
          // { path: 'inroll/subscribers', component: UsersComponent },
          // { path: 'inroll/subscriber/manage', component: ManageuserComponent },
          // { path: 'inroll/subscriber/manage/:id', component: ManageuserComponent },
          // { path: 'inroll/users', component: InteserraUsersComponent },
          // { path: 'inroll/user/manage', component: InteserraManageUsersComponent },
          // { path: 'inroll/user/manage/:id', component: InteserraManageUsersComponent },
          { path: 'inroll/user/profile', component: ProfileComponent },
          // { path: 'inroll/companies', component: CompanyComponent },
          // { path: 'inroll/company/manage', component: ManageCompanyComponent },
          // { path: 'inroll/company/manage/:id', component: ManageCompanyComponent },
          // { path: 'inroll/company/assignuser/:id', component: AssignUserComponent },
          // { path: 'inroll/menus', component: MenuComponent },
          // { path: 'inroll/menu/edit/:id', component: ManageMenuComponent },
          // { path: 'inroll/applicationactivity', component: ApplicationActivityComponent },


          { path: '911/dashboard', component: DashboardComponent },
          { path: '911/access-denied', component: AccessDeniedComponent },
          { path: '911/states', component: StatesComponent },
          { path: '911/states/manage', component: StateMasterAddEditComponent },
          { path: '911/states/manage/:id', component: StateMasterAddEditComponent },
          { path: '911/county', component: CountyComponent },
          { path: '911/county/manage', component: CreateoreditCountyComponent },
          { path: '911/county/manage/:id', component: CreateoreditCountyComponent },
          { path: '911/city', component: CityComponent },
          { path: '911/city/manage', component: CreateoreditCityComponent },
          { path: '911/city/manage/:id', component: CreateoreditCityComponent },
          { path: '911/rates', component: RateMain911Component },
          { path: '911/rates/manage', component: CreateoreditRateMain911Component },
          { path: '911/rates/manage/:id', component: CreateoreditRateMain911Component },
          { path: '911/rates/contact/:id', component: ContactRateComponent },
          { path: '911/inactiveRates', component: RateMain911Component },
          { path: '911/contacts', component: ContactsComponent },
          { path: '911/importRates', component: ImportRateMainComponent },
          { path: '911/contacts/manage', component: CreateoreditcontactComponent },
          { path: '911/contacts/manage/:id', component: CreateoreditcontactComponent },
          { path: '911/ftPData', component: FTPData911Component },
          { path: '911/update', component: Update911Component },
          { path: '911/update/manage', component: Update911AddUpdateComponent },
          { path: '911/update/manage/:id', component: Update911AddUpdateComponent },
          // { path: '911/subscribers', component: UsersComponent },
          // { path: '911/subscriber/manage', component: ManageuserComponent },
          // { path: '911/subscriber/manage/:id', component: ManageuserComponent },
          // { path: '911/users', component: InteserraUsersComponent },
          // { path: '911/user/manage', component: InteserraManageUsersComponent },
          // { path: '911/user/manage/:id', component: InteserraManageUsersComponent },
          { path: '911/user/profile', component: ProfileComponent },
          // { path: '911/companies', component: CompanyComponent },
          // { path: '911/company/manage', component: ManageCompanyComponent },
          // { path: '911/company/manage/:id', component: ManageCompanyComponent },
          // { path: '911/company/assignuser/:id', component: AssignUserComponent },
          // { path: '911/menus', component: MenuComponent },
          // { path: '911/menu/edit/:id', component: ManageMenuComponent },
          // { path: '911/applicationactivity', component: ApplicationActivityComponent },
          { path: '911/911-Alerts', component: Alerts911Component },
          { path: '911/911-Alerts/user/:id', component: User911AlertsComponent },
          { path: '911/911-Alerts/manage/:id/:userid', component: Manage911AlertsComponent },
          { path: '911/audit-month', component: AuditMonthComponent },

          { path: 'trfa/dashboard', component: DashboardComponent },
          { path: 'trfa/access-denied', component: AccessDeniedComponent },
          { path: 'trfa/category', component: CategoryComponent },
          { path: 'trfa/category/manage', component: CategoryAddUpdateComponent },
          { path: 'trfa/category/manage/:id', component: CategoryAddUpdateComponent },
          { path: 'trfa/carrier', component: CarriersComponent },
          { path: 'trfa/carrier/manage', component: CarriersAddUpdateComponent },
          { path: 'trfa/carrier/manage/:id', component: CarriersAddUpdateComponent },
          { path: 'trfa/fundType', component: FundTypeComponent },
          { path: 'trfa/fundType/manage', component: FundTypeAddUpdateComponent },
          { path: 'trfa/fundType/manage/:id', component: FundTypeAddUpdateComponent },
          { path: 'trfa/states', component: StatesComponent },
          { path: 'trfa/states/manage', component: StateMasterAddEditComponent },
          { path: 'trfa/states/manage/:id', component: StateMasterAddEditComponent },
          { path: 'trfa/fund', component: FundComponent },
          { path: 'trfa/fund/manage', component: FundAddUpdateComponent },
          { path: 'trfa/fund/manage/:id', component: FundAddUpdateComponent },
          { path: 'trfa/update', component: UpdateComponent },
          { path: 'trfa/update/manage', component: UpdateAddUpdateComponent },
          { path: 'trfa/update/manage/:id', component: UpdateAddUpdateComponent },
          { path: 'trfa/content', component: ContentComponent },
          { path: 'trfa/content/manage', component: ContentAddUpdateComponent },
          { path: 'trfa/content/manage/:id', component: ContentAddUpdateComponent },
          // { path: 'trfa/updatedView', component: UpdatedViewComponent }, 
          { path: 'trfa/compareView', component: CompareViewComponent },
          { path: 'trfa/link', component: LinkComponent },
          { path: 'trfa/link/manage', component: LinkAddUpdateComponent },
          { path: 'trfa/link/manage/:id', component: LinkAddUpdateComponent },
          { path: 'trfa/source', component: SourceComponent },
          { path: 'trfa/source/manage', component: SourceAddUpdateComponent },
          { path: 'trfa/source/manage/:id', component: SourceAddUpdateComponent },
          { path: 'trfa/content-list', component: ContentListComponent },
          { path: 'trfa/audit-report', component: AuditReportComponent },
          { path: 'trfa/ftPData', component: TRFADownloadComponent },
          // { path: 'trfa/subscribers', component: UsersComponent },
          // { path: 'trfa/subscriber/manage', component: ManageuserComponent },
          // { path: 'trfa/subscriber/manage/:id', component: ManageuserComponent },
          // { path: 'trfa/users', component: InteserraUsersComponent },
          // { path: 'trfa/user/manage', component: InteserraManageUsersComponent },
          // { path: 'trfa/user/manage/:id', component: InteserraManageUsersComponent },
          { path: 'trfa/user/profile', component: ProfileComponent },
          // { path: 'trfa/companies', component: CompanyComponent },
          // { path: 'trfa/company/manage', component: ManageCompanyComponent },
          // { path: 'trfa/company/manage/:id', component: ManageCompanyComponent },
          // { path: 'trfa/company/assignuser/:id', component: AssignUserComponent },
          // { path: 'trfa/menus', component: MenuComponent },
          // { path: 'trfa/menu/edit/:id', component: ManageMenuComponent },
          // { path: 'trfa/applicationactivity', component: ApplicationActivityComponent },
          { path: 'trfa/content/history/:id', component: ContentHistoryComponent },

          { path: 'Admin/access-denied', component: AccessDeniedComponent },
          { path: 'Admin/dashboard', component: DashboardComponent },
          { path: 'Admin/companies', component: CompanyComponent },
          { path: 'Admin/company/manage', component: ManageCompanyComponent },
          { path: 'Admin/company/manage/:id', component: ManageCompanyComponent },
          { path: 'Admin/company/manage/:id/:clientInformationId', component: ManageCompanyComponent },
          { path: 'Admin/company/assignuser/:id', component: AssignUserComponent },
          { path: 'Admin/subscribers', component: UsersComponent },
          { path: 'Admin/subscriber/manage', component: ManageuserComponent },
          { path: 'Admin/subscriber/manage/:id', component: ManageuserComponent },
          { path: 'Admin/users', component: InteserraUsersComponent },
          { path: 'Admin/user/manage', component: InteserraManageUsersComponent },
          { path: 'Admin/user/manage/:id', component: InteserraManageUsersComponent },
          { path: 'Admin/user/profile', component: ProfileComponent },
          { path: 'Admin/menus', component: MenuComponent },
          { path: 'Admin/menu/edit/:id', component: ManageMenuComponent },
          { path: 'Admin/applicationactivity', component: ApplicationActivityComponent },
          { path: 'trfa/trfa-Alerts', component: TrfaAlertsComponent },
          { path: 'trfa/trfa-Alerts/user/:id', component: UserTrfaAlertsComponent },
          { path: 'trfa/trfa-Alerts/manage/:id/:userid', component: ManageTrfaAlertsComponent },

          { path: 'crra/dashboard', component: DashboardComponent },
          { path: 'crra/access-denied', component: AccessDeniedComponent },
          { path: 'crra/states', component: StatesComponent },
          { path: 'crra/states/manage', component: StateMasterAddEditComponent },
          { path: 'crra/states/manage/:id', component: StateMasterAddEditComponent },
          { path: 'crra/carrier', component: CrraCarriersComponent },
          { path: 'crra/carrier/manage', component: CrraCarriersAddUpdateComponent },
          { path: 'crra/carrier/manage/:id', component: CrraCarriersAddUpdateComponent },
          { path: 'crra/category', component: CrraCategoryComponent },
          { path: 'crra/category/manage', component: CrraCategoryAddUpdateComponent },
          { path: 'crra/category/manage/:id', component: CrraCategoryAddUpdateComponent },
          { path: 'crra/subCategory', component: CrraSubCategoryComponent },
          { path: 'crra/subCategory/manage', component: CrraSubCategoryAddUpdateComponent },
          { path: 'crra/subCategory/manage/:id', component: CrraSubCategoryAddUpdateComponent },
          { path: 'crra/updates', component: CrraUpdatesComponent },
          { path: 'crra/updates/manage', component: CrraUpdatesAddUpdateComponent },
          { path: 'crra/updates/manage/:id', component: CrraUpdatesAddUpdateComponent },
          { path: 'crra/deposite', component: DepositeComponent },
          { path: 'crra/deposite/manage', component: AddUpdateDepositeComponent },
          { path: 'crra/deposite/manage/:id', component: AddUpdateDepositeComponent },
          { path: 'crra/contents', component: CrraContentsComponent },
          { path: 'crra/contents/manage', component: CrraContentsAddUpdateComponent },
          { path: 'crra/contents/manage/:id', component: CrraContentsAddUpdateComponent },
          { path: 'crra/compareView', component: CrraCompareViewComponent },
          { path: 'crra/content-list', component: CrraContentListComponent },
          { path: 'crra/link', component: CrraLinkComponent },
          { path: 'crra/link/manage', component: CrraLinkAddUpdateComponent },
          { path: 'crra/link/manage/:id', component: CrraLinkAddUpdateComponent },
          { path: 'crra/home', component: ManageCrraComponent },
          { path: 'crra/crra-Alerts', component: CrraAlertsComponent },
          { path: 'crra/crra-Alerts/user/:id', component: UserCrraAlertsComponent },
          { path: 'crra/crra-Alerts/manage/:id/:userid', component: ManageCrraAlertsComponent },
          { path: 'crra/contents/history/manage/:id', component: CrraContentHistoryComponent },

          { path: 'view47/access-denied', component: AccessDeniedComponent },
          { path: 'view47/dashboard', component: DashboardComponent },
          { path: 'view47/content', component: CarrierObligationAuditComponentV2 },
          { path: 'view47/pending-list', component: CarrierObligationAuditComponentV3 },
          { path: 'view47/internal-notes', component: CarrierObligationAuditInternalComponent },
          { path: 'view47/manage', component: CarrierObligationAddUpdateComponent },
          { path: 'view47/manage/:id', component: CarrierObligationAddUpdateComponent },
          { path: 'view47/history/:id', component: CarrierObligationHistoryComponent },
          { path: 'view47/update', component: CarrierObligationUpdatesComponent },
          { path: 'view47/update/manage', component: CarrierObligationUpdatesAddUpdateComponent },
          { path: 'view47/update/manage/:id', component: CarrierObligationUpdatesAddUpdateComponent },
          { path: 'view47/carrier', component: CarrierTypeCfoComponent },
          { path: 'view47/carrier/manage', component: CarrierTypeCfoAddUpdateComponent },
          { path: 'view47/carrier/manage/:id', component: CarrierTypeCfoAddUpdateComponent },
          { path: 'view47/category', component: CategoryTypeCfoComponent },
          { path: 'view47/category/manage', component: CategoryTypeCfoAddUpdateComponent },
          { path: 'view47/category/manage/:id', component: CategoryTypeCfoAddUpdateComponent },
         
          { path: 'PricingQuote/dashboard', component: DashboardComponent },
          { path: 'PricingQuote/baserate', component: BaseRateComponent },
          { path: 'PricingQuote/baseRate/manage', component: AddUpdateBaseRateComponent },
          { path: 'PricingQuote/baseRate/manage/:id', component: AddUpdateBaseRateComponent },
          { path: 'PricingQuote/baserateType', component: BaseRateTypeComponent },
          { path: 'PricingQuote/baseRateType/manage', component: AddUpdateBaseRateTypeComponent },
          { path: 'PricingQuote/baseRateType/manage/:id', component: AddUpdateBaseRateTypeComponent },
          { path: 'PricingQuote/company-info', component: AdminCompnayProfileInformationComponent },
          { path: 'PricingQuote/company-info/:id', component: AdminCompnayProfileInformationComponent },
          { path: 'PricingQuote/add-comp-que', component: AdminAdditionalCompanyQuestionsComponent },
          { path: 'PricingQuote/add-comp-que/:id', component: AdminAdditionalCompanyQuestionsComponent },
          { path: 'PricingQuote/state-selection', component: AdminStateSelectionComponent },
          { path: 'PricingQuote/state-selection/:id', component: AdminStateSelectionComponent },
          { path: 'PricingQuote/attach-to-determine-services', component: AdminAttachmentsToDetermineServicesComponent },
          { path: 'PricingQuote/attach-to-determine-services/:id', component: AdminAttachmentsToDetermineServicesComponent },
          { path: 'PricingQuote/attach-to-determine-services/:id/:priceQuoteId', component: AdminAttachmentsToDetermineServicesComponent },
          { path: 'PricingQuote/pricing-quotes', component: PricingQuotesComponent },
          { path: 'PricingQuote/manage-baserate', component: AdminManageBaserateComponent },
          { path: 'PricingQuote/manage-baserate/:id', component: AdminManageBaserateComponent },
          { path: 'PricingQuote/pricing-quotes/:id', component: PriceQuotesCalcComponent },
          { path: 'PricingQuote/add-on-services/:id', component: AddOnServicesComponent},
          { path: 'PricingQuote/compliance/:id', component: PriceQuotesComplianceComponent},
          { path: 'PricingQuote/proposal/:id', component:PricingQuotesProposalComponent},
          { path: 'PricingQuote/add-company-info/:id', component: AdminAdditionalCompanyInformationComponent },
          { path: 'PricingQuote/add-company-info/:id/:priceQuoteId', component: AdminAdditionalCompanyInformationComponent },
          { path: 'PricingQuote/sensitive-info/:id', component: AdminSensitiveInformationComponent },
          { path: 'PricingQuote/sensitive-info/:id/:priceQuoteId', component: AdminSensitiveInformationComponent },
          { path: 'PricingQuote/portal-credential/:id', component: AdminInteserraPortalCredentialComponent },
          { path: 'PricingQuote/portal-credential/:id/:priceQuoteId', component: AdminInteserraPortalCredentialComponent },
          { path: 'PricingQuote/Company-Selection', component: PriceQuoteAdminCompanySelectionComponent },
          { path: 'PricingQuote/Company-Selection/:id/:companyid', component: PriceQuoteAdminCompanySelectionComponent },
          { path: 'PricingQuote/Company-Selection/:id', component: PriceQuoteAdminCompanySelectionComponent },
          { path: 'PricingQuote/notes', component: AdminPriceQuoteNotesComponent },
          { path: 'PricingQuote/notes/:id', component: AdminPriceQuoteNotesComponent },
          { path: 'Admin/NoUsageReport', component: NoUsageReportComponent },
          { path: 'PricingQuote/onboarding-state-selection', component: AdminOnboardingStateSelectionComponent },
          { path: 'PricingQuote/onboarding-state-selection/:id', component: AdminOnboardingStateSelectionComponent },
          { path: 'PricingQuote/onboarding-state-selection/:id/:priceQuoteId', component: AdminOnboardingStateSelectionComponent },
          { path: 'CompanyProfile/company-info', component: AdminCompanyProfileComponent },
          { path: 'CompanyProfile/company-info/:id/:companyId', component: AdminCompanyProfileComponent },
          { path: 'CompanyProfile/add-comp-que', component: AdminCompanyProfileAdditionalQueComponent },
          { path: 'CompanyProfile/add-comp-que/:id/:companyId', component: AdminCompanyProfileAdditionalQueComponent },
          { path: 'CompanyProfile/add-comp-que/:id', component: AdminCompanyProfileAdditionalQueComponent },
          { path: 'CompanyProfile/state-selection', component: AdminCompanyProfileStateSelectionComponent },
          { path: 'CompanyProfile/state-selection/:id/:companyId', component: AdminCompanyProfileStateSelectionComponent },
          { path: 'CompanyProfile/state-selection/:id', component: AdminCompanyProfileStateSelectionComponent },
          { path: 'PricingQuote/Miscellaneousfees', component: MiscellaneousfeesComponent },
          { path: 'PricingQuote/Manage/Miscellaneousfees', component: ManageMiscellaneousfeesComponent },
          { path: 'PricingQuote/Manage/Miscellaneousfees/:id', component: ManageMiscellaneousfeesComponent },
          { path: 'PricingQuote/market-entry', component: MarketEntryComponent },
          { path: 'PricingQuote/ClientInformation', component: ClientInformationListComponent },
          { path: 'PricingQuote/Onboarding', component: OnboardingListComponent },          { path: 'CompanyProfile/attachments', component: AdminAttachmentsComponentComponent },
          { path: 'CompanyProfile/attachments/:id/:companyId', component: AdminAttachmentsComponentComponent },
          { path: 'CompanyProfile/attachments/:id', component: AdminAttachmentsComponentComponent },

        ]
      },
      {
        path: '',
        component: SiteLayoutComponent,
        children: [
          { path: 'index', component: FrontIndexComponent },
          { path: 'registraton', component: RegistratonComponent },
          { path: 'login', component: ClientLoginComponent },
          //{ path: 'Home', component: LoginLandingPageComponent },
          { path: 'Access/Denied/:id', component: FrontAccessDeniedComponent },
          { path: 'View/BriefingServices', component: BriefingServicesComponent, canActivate: [AuthGuard] },
          { path: 'MyLibrary', component: FavoriteComponent, canActivate: [AuthGuard] },
          { path: 'Library/Exemption/Certificates', component: UserLibraryComponent, canActivate: [AuthGuard] },
          { path: 'Library/CPCN/Certificates', component: UserLibraryComponent, canActivate: [AuthGuard] },
          { path: 'Library/SecretaryofStatesAuthorization', component: UserLibraryComponent, canActivate: [AuthGuard] },
          { path: 'Library/Contracts', component: UserLibraryComponent, canActivate: [AuthGuard] },
          { path: 'StateCommission', component: FrontStateCommissionComponent, canActivate: [AuthGuard] },
          { path: 'CarrierTariffs', component: FrontCarrierTariffComponent, canActivate: [AuthGuard] },
          { path: 'RedLightStatus', component: RedLightStatusComponent, canActivate: [AuthGuard] },
          { path: 'Dashboard', component: FrontIndexComponent, canActivate: [AuthGuard] },
          { path: 'EmailVerification/:id', component: EmailVerificationComponent },
          { path: 'Manage/Subscriptions', component: ManageSubscriptionsComponent, canActivate: [AuthGuard] },
          { path: 'Company/Directory', component: CompanyDirectoryComponent, canActivate: [AuthGuard] },
          { path: '477Data', component: FrontData477Component, canActivate: [AuthGuard] },
          { path: 'News', component: NewsComponent },
          { path: 'WebinarLibrary', component: WebinarLibraryComponent, canActivate: [AuthGuard] },
          { path: 'InteserraResources', component: InteserraResourcesComponent, canActivate: [AuthGuard] },
          { path: 'WhitePapers', component: WhitePapersComponent, canActivate: [AuthGuard] },
          { path: 'ChangePassword/:id', component: ChangePasswordComponent },
          { path: 'ChangePassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
          { path: 'ForgotPassword', component: ForgotPasswordComponent },
          { path: 'Report/Compliance', component: ComplianceReportComponent, canActivate: [AuthGuard] },
          { path: 'Seminars', component: FrontSeminarComponent, canActivate: [AuthGuard] },
          { path: 'TaxExemptionForms', component: FrontTaxExemptionFormsComponent, canActivate: [AuthGuard] },

          { path: 'CreatePassword', component: CreatePasswordComponent },
          { path: 'SpecialBriefingReport', component: SpecialBriefingReportComponent, canActivate: [AuthGuard] },
          { path: 'login/:id', component: ClientLoginComponent },
          { path: 'View/BriefingDetails/:id', component: BriefingDetailsComponent, canActivate: [AuthGuard] },
          { path: 'Verify/BriefingEmail/:id', component: BriefingEmailVarificationComponent },

          { path: 'Claim/Reports/List', component: ListOfClaimReportsComponent, canActivate: [AuthGuard] },

          { path: 'Claim/Import', component: FrontClaimImportReportsComponent, canActivate: [AuthGuard] },
          { path: 'View/Claim', component: FrontViewClaimProcessComponent, canActivate: [AuthGuard] },
          { path: 'Claim/Reports', component: ClaimReportsComponent, canActivate: [AuthGuard] },
          { path: 'View/Details', component: ClaimDetailReportsComponent, canActivate: [AuthGuard] },
          { path: 'Claim/yearly-Reports', component: YearlyClaimReportsComponent, canActivate: [AuthGuard] },
          { path: 'View/yearly-Report-details', component: YearlyClaimDetailReportsComponent, canActivate: [AuthGuard] },
          { path: 'Claim/summary-Reports', component: SummaryReportsComponent, canActivate: [AuthGuard] },
          { path: 'View/summary-details', component: SummaryDetailReportsComponent, canActivate: [AuthGuard] },

          { path: 'Claim/LifelineReports/List', component: ListOfLifelineClaimReportsComponent, canActivate: [AuthGuard] },

          { path: 'Claim/Lifeline-Import', component: FrontClaimImportLifelineReportsComponent, canActivate: [AuthGuard] },
          { path: 'View/Lifeline-Claim', component: FrontViewClaimProcessLifelineComponent, canActivate: [AuthGuard] },
          { path: 'Claim/Lifeline-Reports', component: ClaimLifelineReportsComponent, canActivate: [AuthGuard] },
          { path: 'View/Lifeline-Details', component: ClaimDetailLifelineReportsComponent, canActivate: [AuthGuard] },
          { path: 'Claim/Lifeline-yearly-Reports', component: YearlyClaimLifelineReportsComponent, canActivate: [AuthGuard] },
          { path: 'View/Lifeline-yearly-Report-details', component: YearlyClaimDetailLifelineReportsComponent, canActivate: [AuthGuard] },
          { path: 'Claim/Lifeline-summary-Reports', component: SummaryLifelineReportsComponent, canActivate: [AuthGuard] },
          { path: 'View/Lifeline-summary-details', component: SummaryDetailLifelineReportsComponent, canActivate: [AuthGuard] },

          { path: 'Training', component: FrontTrainingComponentComponent, canActivate: [AuthGuard] },
          { path: 'View/rateLookup', component: RateLookupComponent, canActivate: [AuthGuard] },
          { path: 'View/rateLookup/:id', component: RateLookupComponent, canActivate: [AuthGuard] },
          { path: 'View/futureChanges', component: FutureChangesComponent, canActivate: [AuthGuard] },
          { path: 'View/contentView', component: TrfaContentViewComponent, canActivate: [AuthGuard] },
          { path: 'View/updateView', component: TrfaUpdateViewComponent, canActivate: [AuthGuard] },
          { path: 'View/updateView/:id', component: TrfaUpdateViewComponent },
          { path: 'View/contentView/:id', component: TrfaContentViewComponent, canActivate: [AuthGuard] },
          { path: 'View/home', component: TrfaHomeComponent, canActivate: [AuthGuard] },
          { path: 'View/about', component: TrfaAboutComponent, canActivate: [AuthGuard] },
          { path: 'Custom-Product/:id', component: CustomProductComponent, canActivate: [AuthGuard] },
          { path: 'View/downloadfiles/:id', component: DownloadfilesComponent },
          { path: 'View/downloadtrfafiles/:id', component: DownloadTRFAfilesComponent },
          { path: 'View/myfavorite', component: MyFavoritesComponent, canActivate: [AuthGuard] },
          { path: 'View/mytrfafavorite', component: MytrfaFavoritesComponent },
          { path: 'View/911Updates', component: Update911Component, canActivate: [AuthGuard] },
          { path: 'View/update', component: UpdateViewComponent, canActivate: [AuthGuard] },
          { path: 'View/update/:id', component: UpdateViewComponent },
          { path: 'adminclientlogin/:id/:id2', component: AdminByPassClientLoginComponent },
          { path: 'View/rulesView', component: RulesComponent, canActivate: [AuthGuard] },
          { path: 'View/crraHome', component: CRRAHomeComponent, canActivate: [AuthGuard] },
          { path: 'View/myFavourite', component: MyCRRAFavouriteComponent, canActivate: [AuthGuard] },
          { path: 'View/updates', component: CrraFrontupdatesComponent, canActivate: [AuthGuard] },
          { path: 'View/updates/:id', component: CrraFrontupdatesComponent },
          { path: 'View/deposit-rate', component: DepositRatesComponent, canActivate: [AuthGuard] },
          { path: 'TRSCustomer', component: TRSCustomerComponent, canActivate: [AuthGuard] },
          { path: 'View/trsfavorite', component: TRSFavoritesComponent, canActivate: [AuthGuard] },

          { path: 'View/company-info', component: CompanyProfileInformationComponent, canActivate: [AuthGuard] },
          { path: 'View/company-info/:id', component: CompanyProfileInformationComponent, canActivate: [AuthGuard] },
          { path: 'View/add-comp-que', component: AdditionalCompanyQuestionComponent, canActivate: [AuthGuard] },
          { path: 'View/add-comp-que/:id', component: AdditionalCompanyQuestionComponent, canActivate: [AuthGuard] },
          { path: 'View/attach-to-determine-services', component: AttachmentsToDetermineServicesComponent, canActivate: [AuthGuard] },
          { path: 'View/attach-to-determine-services/:id', component: AttachmentsToDetermineServicesComponent, canActivate: [AuthGuard] },
          { path: 'View/attach-to-determine-services/:id/:priceQuoteId', component: AttachmentsToDetermineServicesComponent, canActivate: [AuthGuard] },
          { path: 'View/state-selection', component: StateSelectionComponent, canActivate: [AuthGuard] },
          { path: 'View/state-selection/:id', component: StateSelectionComponent, canActivate: [AuthGuard] },
          { path: 'View/client-profile', component: PriceQuotesListComponent, canActivate: [AuthGuard] },
          { path: 'View/add-company-info/:id', component: AdditionalCompanyInformationComponent , canActivate: [AuthGuard] },
          { path: 'View/add-company-info/:id/:priceQuoteId', component: AdditionalCompanyInformationComponent , canActivate: [AuthGuard] },
          { path: 'View/sensitive-info/:id', component: SensitiveInformationComponent , canActivate: [AuthGuard] },
          { path: 'View/sensitive-info/:id/:priceQuoteId', component: SensitiveInformationComponent , canActivate: [AuthGuard] },
          { path: 'View/portal-credential/:id', component: InteserraPortalCredentialsComponent , canActivate: [AuthGuard] },
          { path: 'View/portal-credential/:id/:priceQuoteId', component: InteserraPortalCredentialsComponent , canActivate: [AuthGuard] },

          { path: 'View/client-quote', component: ClientQuoteComponent, canActivate: [AuthGuard] },
          { path: 'View/client-quote/:id', component: ClientQuoteComponent, canActivate: [AuthGuard] },
          { path: 'View/client-quote/:id/:companyId', component: ClientQuoteComponent, canActivate: [AuthGuard]},
          { path: 'View/client-quote-company-info', component: ClientQuoteCompanyProfileInfoComponent, canActivate: [AuthGuard] },
          { path: 'View/client-quote-company-info/:id', component: ClientQuoteCompanyProfileInfoComponent, canActivate: [AuthGuard] },
          { path: 'View/client-quote-add-comp-que/:id', component: ClientQuoteAdditionalCompanyQuestionComponent, canActivate: [AuthGuard] },
          { path: 'View/client-quote-state-selection/:id', component: ClientQuoteStateSelectionComponent, canActivate: [AuthGuard] },
          { path: 'View/client-quote-add-on-services/:id', component: ClientQuoteAddOnServicesComponent, canActivate: [AuthGuard] },
          { path: 'View/client-quote-compliance/:id', component: ClientPriceQuotesComplianceComponent, canActivate: [AuthGuard] },
          { path: 'View/client-quote-attach-determine-services/:id', component: ClientQuoteAttechedServicesComponent, canActivate: [AuthGuard] },
          { path: 'View/client-quote-list', component: ClientQuoteListComponent, canActivate: [AuthGuard] },
          { path: 'View/quotes-and-proposal', component: QuotesAndProposalComponent, canActivate: [AuthGuard] },
          { path: 'View/download-quotes-and-proposal/:id', component: DownloadQuotesAndProposalComponent},
          { path: 'View/download-quotes/:id', component: DownloadfilesonboardingComponent },

          { path: 'View/user-login', component: UserAutoLoginComponent, canActivate: [AuthGuard] },
          { path: 'userclientlogin/:id/:id2', component: UserByPassClientLoginComponent },
          { path: 'View/notes', component: PriceQuoteNotesComponent, canActivate: [AuthGuard] },
          { path: 'View/notes/:id', component: PriceQuoteNotesComponent, canActivate: [AuthGuard] },
          { path: 'View/client-notes', component: ClientQuoteNotesComponent, canActivate: [AuthGuard] },
          { path: 'View/client-notes/:id', component: ClientQuoteNotesComponent, canActivate: [AuthGuard] },
          { path: 'View/my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
          { path: 'View/onboarding-state-selection', component: OnboardingStateSelectionComponent, canActivate: [AuthGuard] },
          { path: 'View/onboarding-state-selection/:id', component: OnboardingStateSelectionComponent, canActivate: [AuthGuard] },
          { path: 'View/onboarding-state-selection/:id/:priceQuoteId', component: OnboardingStateSelectionComponent, canActivate: [AuthGuard] },

          { path: 'View/View47', component: CfoFrontobligationComponent, canActivate: [AuthGuard] },
          { path: 'View/View47-updates', component: CfoFrontupdatesComponent, canActivate: [AuthGuard] },
          { path: 'View/checklist', component: ChecklistComponent, canActivate: [AuthGuard] },
		      { path: 'View/View47-favorite', component: CfoFavoritesComponent, canActivate: [AuthGuard] },
          { path: 'Verify/PriceQuoteEmail/:id', component: PriceQuoteEmailVerificationComponent },
          { path: 'View/attachments', component: FrontAttachmentsComponentComponent },
          { path: 'View/attachments/:id', component: FrontAttachmentsComponentComponent },

        ]
      },
      { path: '**', redirectTo: 'login' }
    ]
      // , { enableTracing: true }
    ),
    // AngularEditorModule
  ],
  entryComponents: [
    AddNewActionPopupComponent,
    ActionItemDetailsComponent,
    ActionStatusPopupComponent,
    NotesPopupComponent,
    ActionItemDetailsPopupComponent,
    EditActionPopupComponent,
  ],
  providers: [
    CheckAuth,
    UtilityService,
    TokenService,
    AppConfig,
    RedirectService,
    MatrixFundDetailsService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    // { provide: RequestOptions, useClass: CacheInterceptor },
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: Window, useValue: window },
    SiteLayoutComponent,
    HtmlToPdf,
    BypassService,
    NgbActiveModal,
    NodeService,
    AuthGuard,
    DatePipe,
    Globle
    // FaIconLibrary,
    // ShareDirective
  ],
  exports: [
    DndModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

