import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TRFAContentGetModel } from 'src/Models/TRFAContentModel';
import { TrfaContentMasterService } from 'src/Services/trfa-content-master.service';
import { CheckAuth } from 'src/Services/utility.service';
import { TrfaMonthsService } from 'src/Services/trfa-months.service';
import { HtmlToPdf } from 'src/Services/htmlToPdf';
import * as FileSaver from 'file-saver';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TRFAFundModel } from 'src/Models/TRFAFundModel';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { StateMasterService } from 'src/Services/state-master.service';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.css']
})
export class AuditReportComponent implements OnInit {

  MonthList1: any[] = [];
  selectedItemsMonth1 = [];
  dropdownSettingsMonthFilter1 = {};
  dropdownMonthFilter1 = [];
  auditMonth1: any;
  listOfMonth1 = [];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  auditMonth: any;

  listofContent: TRFAContentGetModel[];
  isloading: boolean = false;
  tokenKey: string;
  isRecord: boolean = false;
  totalRecordCount: number = 0;

  isShowprogressSpinner: boolean = false;
  blockedDocument: boolean = false;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];
  //Fund
  fundID: string = ' ';
  dropdownListFund = [];
  selectedItemsFund = [];
  dropdownSettingsFund = {};
  listOffundIDs = [];
  fundList: TRFAFundModel[] = [];
  module = "TRFA";
  stateIds: string = ' ';
  UpdateId: number = 0;
  fltMonth:any="";


  constructor(private router: Router, private trfaContentMaster: TrfaContentMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService, private trfaMonthsService: TrfaMonthsService, private htmlToPdf: HtmlToPdf,
    private trfaFundMaster: TrfaFundMasterService, private stateService: StateMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofContent = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'StateName,FundName';
    this.titleService.setTitle('Audit Report');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsFund = {
      singleSelection: false,
      text: "Filter by Fund",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: true
    };
    this.tokenKey = sessionStorage.getItem('tokenValue');


    this.dropdownSettingsMonthFilter1 = {
      singleSelection: true,
      text: "Select Month",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Month(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.getMonths1();
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }


  getFund() {
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.trfaFundMaster.GetFund(this.tokenKey, this.stateId, true, true).subscribe(
      data => {
        this.dropdownListFund = []
        this.fundList = data['fund'];
        this.dropdownListFund = data['fund'];
        this.selectedItemsFund = [];
        if (this.fundList.length > 0) {
          let b = [];
          this.selectedItemsFund = [];
          this.fundList.forEach(x => {
            let a = this.listOffundIDs.filter(e => e == x.fundID);
            if (a.length > 0) {
              b.push(x.fundID);
              this.selectedItemsFund.push(x)
            }
          });
          this.listOffundIDs = b;
        }
        else {
          this.selectedItemsFund = [];
          this.listOffundIDs = [];
        }
      },
      error => { }
    );
  }

  getMonths1() {
    this.MonthList1 = this.trfaMonthsService.getMonths();
    this.dropdownMonthFilter1 = [];
    this.selectedItemsMonth1 = [];
    this.MonthList1.forEach(x => {
      this.dropdownMonthFilter1.push({ "id": x.id, "itemName": x.name })
    });
  }

  onSelectMonth1($event) {
    this.listOfMonth1 = [];
    this.listOfMonth1 = this.removeElemetninArray(this.listOfMonth1, $event.itemName)
    this.listOfMonth1.push($event.itemName);

    this.fltMonth = $event.itemName;
    this.getFund();
    this.GetContent();
  }

  onDeSelectMonth1($event) {
    this.fltMonth = "";
    if (this.listOfMonth1 != null) {
      if (this.listOfMonth1.length > 0) {
        this.listOfMonth1 = this.removeElemetninArray(this.listOfMonth1, $event.itemName)
        this.totalRecordCount = 0;
        this.listofContent = [];
        this.isRecord = false;
      }
    }
    this.GetContent();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  GetContent() {
    this.isloading = true;

    this.auditMonth = '';
    if (this.listOfMonth1.length > 0) {
      this.auditMonth = this.listOfMonth1.join(',')
    }

    this.fundID = '';
    if (this.listOffundIDs.length > 0) {
      this.fundID = this.listOffundIDs.join(',')
    }
    this.stateId = '';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }

    if (this.auditMonth == '' && this.fundID == '' && this.stateId == '') {
      this.isRecord = false;
      this.totalRecordCount = 0;
      this.isloading = false;
      this.listofContent = [];
      return;
    }

    this.trfaContentMaster.GetAllAuditReport(this.tokenKey, this.PageNumber, this.PageSize, this.OrderBy, this.auditMonth, this.stateId, this.fundID).subscribe(
      data => {
        this.isloading = false;
        this.listofContent = [];
        this.listofContent = data['content'];

        this.listofContent.forEach(element => {
          element['isEdit'] = false;
        });

        if (this.listofContent != null) {
          if (this.listofContent.length == 0) {
            this.isRecord = false;
            this.totalRecordCount = 0
          }
          else {
            this.isRecord = true;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { }
    );

  }
  onDeSelectMonthAll1($event) {
    this.listOfMonth1 = [];
    this.totalRecordCount = 0;
    this.listofContent = [];
    this.isRecord = false;
    this.GetContent();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetContent();
  }

  createMyPDF() {
    this.blockDocument();

    this.auditMonth = '';
    if (this.listOfMonth1.length > 0) {
      this.auditMonth = this.listOfMonth1.join(',')

      this.fundID = '';
      if (this.listOffundIDs.length > 0) {
        this.fundID = this.listOffundIDs.join(',')
      }
      this.stateId = '';
      if (this.listOfStateIds.length > 0) {
        this.stateId = this.listOfStateIds.join(',')
      }


      this.htmlToPdf.CreateAuditReportPDF(this.tokenKey, this.auditMonth, this.stateId, this.fundID, '0').
        subscribe(data => {
          if (data['status'] == 'Success') {
            this.downloadFile('AuditReport.pdf', data['url'], data['fileName']);
            this.unblockDocument();
          }
        },
          error => {
            this.unblockDocument();
          }
        );
    }
    else {
      this.unblockDocument();
    }
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  createMyCSV() {
    this.blockDocument();

    this.auditMonth = '';
    if (this.listOfMonth1.length > 0) {
      this.auditMonth = this.listOfMonth1.join(',')
    }
    this.fundID = '';
    if (this.listOffundIDs.length > 0) {
      this.fundID = this.listOffundIDs.join(',')
    }
    this.stateId = '';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }

    if (this.auditMonth == '' && this.fundID == '' && this.stateId == '') {
      if (this.listofContent.length == 0) {
        this.showMessage('Data not found', 'Warning', 'warning')
      }
      this.unblockDocument();
      return;
    }


    this.htmlToPdf.CreateAuditReportCSV(this.tokenKey, this.auditMonth, this.stateId, this.fundID, '0').
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.downloadCSVFile('AuditReport.csv', data['url'], data['fileName']);
          this.unblockDocument();
        }
        else if (data['status'] == 'Failure') {
          this.showMessage(data['message'], 'Warning', 'warning')
          this.unblockDocument();
        }
      },
        error => {
          this.unblockDocument();
        }
      );

  }

  public downloadCSVFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        this.htmlToPdf.DeleteCSV(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.listOfStateIds.length > 0) {
      this.getFund();
    }
    else {
      this.listOffundIDs = [];
      this.fundList = [];
      this.selectedItemsFund = [];
      this.dropdownListFund = [];
    }

    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllState($event) {
    this.listOffundIDs = [];
    this.fundList = [];
    this.selectedItemsFund = [];
    this.dropdownListFund = [];
    this.listOfStateIds = [];
    // this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  onSelectFund($event) {
    this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
    this.listOffundIDs.push($event.id);
    // this.stateId = ' ';
    // if (this.listOffundIDs.length > 0) {
    //   this.stateId = this.listOffundIDs.join(',')
    // }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectFund($event) {
    if (this.listOffundIDs != null) {
      if (this.listOffundIDs.length > 0) {
        this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
      }
    }
    if (this.listOffundIDs.length > 0) {
      this.stateId = this.listOffundIDs.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllFund($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllFund($event) {
    this.listOffundIDs = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  onFilterSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
    this.getFund();
  }

  onFilterDeSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    this.stateId=''
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.listOffundIDs = [];
    this.fundList = [];
    this.selectedItemsFund = [];
    this.dropdownListFund = [];
    this.listOfStateIds = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  moveToAuditpage(stateName:any,fundName:any){
    sessionStorage.setItem("trfastateName",stateName);
    sessionStorage.setItem("trfafundName",fundName);
    sessionStorage.setItem("trfaMonth",this.fltMonth);
    this.router.navigate(['/admin/trfa/content-list']);
  }
}
