export class ReminderModel {
    isSetReminder?: any;
    reminderOption?: any;
    reminederDate?: any;
    hours?: any;
    minute?: any;
    ampm?: any;
    recurEveryDay?: any;
    weekName?: any;
    monthsName?: any;
    userId?: any;
    libraryId?: any;
    libraryReminderId?: any;
}