import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';
import { MenuItemModel } from 'src/Models/MenuItemModel';
import { MenuMasterModel } from 'src/Models/MenuMasterModel';
import { MenuService } from 'src/Services/menu.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  tokenKey: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  menuItems = new Array<MenuItemModel>();
  dropdownListMenu = new Array<MenuMasterModel>();
  selectedMenu: any;
  loginuserID: any;
  dropdownSettingsMenu = {};
  isloadingmenu = false;
  SelectedTool = '';
  openMenuIndex: number = -1;
  
  constructor(private checkAuth: CheckAuth, private titleService: Title,
    private router: Router, private menuService: MenuService,
    private toastrService: ToastrService) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Menus');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  ngOnInit() {
    this.movetotop();
    this.dropdownSettingsMenu = {
      singleSelection: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No menu available',
      primaryKey: 'menuGroupId',
      labelKey: 'menuGroupName',
      text: 'Select menu group',
    };
    this.getMenuGroup();
    this.getMenuItem();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  getMenuGroup() {
    this.menuService.i360MenuGroupAdminGet(this.tokenKey).subscribe(data => {
      if (data) {
        if (data.status == "Success") {
          this.dropdownListMenu = data.listOfMenu;
        }
        else {
          this.toastrService.error("Error", "error");
        }
      }
    })
  }

  getMenuItem(menuGroupId = 0) {
    this.isloadingmenu = true;
    this.menuService.i360MenuItems(this.tokenKey, menuGroupId).subscribe(data => {
      this.isloadingmenu = false;
      if (data) {
        if (data.status == "Success") {
          this.menuItems = data.listOfMenu;
        }
        else {
          this.toastrService.error("Error", "error");
        }
      }
    }, error => {
      this.isloadingmenu = false;
    })
  }

  onMenuSelect(selectedMenu) {
    this.menuItems = [];
    if (this.selectedMenu.length > 0 && selectedMenu.length != 0) {
      this.getMenuItem(this.selectedMenu[0].menuGroupId);
    }
    else
      this.getMenuItem();
  }

  editMenu(menuId) {
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['/admin/' + toolName + '/menu/edit/' + menuId]);
  }

  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
