export class Update911Model {
    updateID: number;
    note: string;
    fundID: number;
    updateDate: string|Date;
}
export class  Update911GetModel {
    updateID: number;
    note: string;
    fundID: number;
    updateDate: string|Date;
    stateName: string;
    shortName:string;
    fundName: string;
    fundTypeName: number;
    totalRecordCount: number;
    IsFavorite:number;
    stateId:number;
    userId:number;
    rate:string;
    fee:string;
    rateId:number;
}

export class FilterUpdate911Model {
    pageNumber?: number;
    pageSize?: number;
    orderBy?: string;
    fundID?: string | number;
    stateID?: string | number;
    fundTypeID?: string | number;
    filterText?: string;
    isAdmin?: boolean;
    statusId?: string;
    isEdit?:boolean;
}

export class Update911FundModel {
    fundID: number;
    Id: number;
    fundTypeID: number;
    stateID: number;
    fundName: string;
    itemName: string;
}

export class FilterRate911Model {
    stateId?: string | number;
    isActive?: number;
    scopeId?: string | number;
}

export class GetRate{
    stateId?: string | number;
    rateId?: string | number;
    isActive?: number;
    rate:string;
    fee:string;
    taxName:string;
}

export class FilterUpdateModel {
    pageNumber?: number;
    pageSize?: number;
    orderBy?: string;
    rateId?: string | number;
    stateId?: string | number;
    userId?: string | number;
    isFavorite?:number;
}


export class FilterRate911V2Model {
    stateId?: number;
    countyId?: number;
    cityId?: number;
    scopeId?: number;
}