import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TRFAContentGetModel, TRFAContentModel, TRFAGetUpdateViewModel } from 'src/Models/TRFAContentModel';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { TrfaContentMasterService } from 'src/Services/trfa-content-master.service';
import { TrfaFundTypeMasterService } from 'src/Services/trfa-fund-type--master.service';
import { TRFAFundTypeModel } from 'src/Models/TRFAFundTypeModel';
import { TrfaCarrierMasterService } from 'src/Services/trfa-carrier--master.service';
import { TrfaCategoryMasterService } from 'src/Services/trfa-category-master.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HtmlToPdf } from 'src/Services/htmlToPdf';
import * as FileSaver from 'file-saver';
import { TRFAFundModel } from 'src/Models/TRFAFundModel';

@Component({
  selector: 'app-compare-view',
  templateUrl: './compare-view.component.html',
  styleUrls: ['./compare-view.component.css']
})
export class CompareViewComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofContent: TRFAContentGetModel[]

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;


  upDowRuleTextCSS: string = '';
  upDowfundNameCSS: string = '';
  upDowSubcategoryCSS: string = '';
  upDowEffectiveDateCSS: string = '';
  upDowRuleCitationCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];
  //Fund
  fundID: string = ' ';
  dropdownListCarrier = [];
  selectedItemsCarrier = [];
  dropdownSettingsCarrier = {};
  listOfCarrierIDs = [];
  carrierList: any[] = [];
  module = "TRFA";
  FundTypeId: string = ' ';
  dropdownListFundType = [];
  selectedItemsFundType = [];
  dropdownSettingsFundType = {};
  listOfFundTypeIds = [];
  fundTypeList: TRFAFundTypeModel[] = [];
  carrierId: string = ' ';
  categoryList: any[] = [];
  dropdownDateCategoryFilter = [];
  selectedItemsCategory = [];
  listOfCategoryIDs = [];
  dropdownSettingsDateCategoryFilter = {};
  categoryId: string = ' ';
  stateLinkData: TRFAGetUpdateViewModel;
  isupdatesloading: boolean = false;
  baseUrl: string;
  isShowprogressSpinner: boolean = false;
  blockedDocument: boolean = false;

  dropdownListFund = [];
  fundList: TRFAFundModel[] = [];
  selectedItemsFund = [];
  dropdownSettingsFund = {};
  listOffundIDs = [];
  fundsId: string = ' ';

  @ViewChild('deletecloseBtn', { static: false }) deletecloseBtn: ElementRef;

  constructor(private router: Router, private trfaContentMaster: TrfaContentMasterService, private trfaCarrierMasterService: TrfaCarrierMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private modalService: NgbModal, private trfaFundTypeMaster: TrfaFundTypeMasterService, private categoryMasterService: TrfaCategoryMasterService,
     private toastr: ToastrService, private stateService: StateMasterService, private trfaFundMaster: TrfaFundMasterService,
     private htmlToPdf: HtmlToPdf,
     @Inject('BASE_URL') baseUrl: string) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofContent = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'RuleText DESC';
    this.Note = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Compare View');
    this.baseUrl = baseUrl;
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsCarrier = {
      singleSelection: false,
      text: "Filter by Carrier",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Carrier(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsFundType = {
      singleSelection: false,
      text: "Filter by Fund Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No FundType(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsDateCategoryFilter = {
      singleSelection: false,
      text: "Filter by Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsFund = {
      singleSelection: false,
      text: "Filter by Fund",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: true
    };
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getCarrier();
    this.getState();
    this.getFund();
    this.getFundType();
    this.getCategory();
    //this.isloading = true;
    this.GetContent();
  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }
  getFundType() {
    this.trfaFundTypeMaster.GetFundType(this.tokenKey, true).subscribe(
      data => {
        this.fundTypeList = data['fundType'];
        this.dropdownListFundType = [];
        if (this.fundTypeList.length > 0) {
          this.fundTypeList.forEach(x => {
            this.dropdownListFundType.push({ "id": x.fundTypeID, "itemName": x.fundTypeName })
          });
        }
      },
      error => { }
    );
  }
  getCarrier() {
    this.trfaCarrierMasterService.GetCarrier(this.tokenKey).subscribe(
      data => {
        this.dropdownListCarrier = []
        this.carrierList = data['carrier'];
        if (this.carrierList.length > 0) {
          this.carrierList.forEach(x => {
            this.dropdownListCarrier.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }
  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetContent();
  }


  GetContent() {
    //
    this.fundID = ' ';
    if (this.listOfStateIds.length == 0 && this.listOfFundTypeIds.length == 0 && this.listOfCarrierIDs.length == 0 && this.listOfCategoryIDs.length == 0) {
      this.listofContent=[];
      return;
    }
    this.isloading = true;
    if (this.listOfCarrierIDs.length > 0) {
      this.fundID = this.listOfCarrierIDs.join(',')
    }
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.FundTypeId = ' ';
    if (this.listOfFundTypeIds.length > 0) {
      this.FundTypeId = this.listOfFundTypeIds.join(',')
    }
    this.carrierId = ' ';
    if (this.listOfCarrierIDs.length > 0) {
      this.carrierId = this.listOfCarrierIDs.join(',')
    }
    this.categoryId = ' ';
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    this.fundsId = ' ';
    if (this.listOffundIDs.length > 0) {
      this.fundsId = this.listOffundIDs.join(',')
    }
    
    if (this.Note == "null") this.Note = " ";


    this.trfaContentMaster.GetAllUpdatedView(this.tokenKey, this.stateId, this.FundTypeId, this.carrierId, this.categoryId,this.fundsId,this.baseUrl, true).subscribe(
      data => {
        this.isloading = true;
        this.listofContent = data['content'];
        if (this.listofContent != null) {
          if (this.listofContent.length == 0) {
            this.isRecord = true;
            this.isloading = false;
          }
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
            this.isloading = false;
          }
        }
        else
          this.isRecord = true;
        this.isloading = false;
      },
      error => { this.stateCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else
      this.Note = 'null';

    this.GetContent();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowEffectiveDateCSS = '';
      this.upDowRuleCitationCSS = '';
      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-down';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = '';
      }
      else if (sortBy === "FundID") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = 'fa fa-arrow-down';
        this.upDowSubcategoryCSS = '';
      }
      else if (sortBy === "RuleCitation") {
        this.upDowRuleTextCSS = '';
        this.upDowRuleCitationCSS = 'fa fa-arrow-down';
        this.upDowSubcategoryCSS = '';
      }
      else if (sortBy === "Subcategory") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-up';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = '';
      }
      else if (sortBy === "FundID") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = 'fa fa-arrow-up';
        this.upDowSubcategoryCSS = '';
      }
      else if (sortBy === "RuleCitation") {
        this.upDowRuleTextCSS = '';
        this.upDowRuleCitationCSS = 'fa fa-arrow-up';
        this.upDowSubcategoryCSS = '';
      }
      else if (sortBy === "Subcategory") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-up';
      }
    }
    this.GetContent();
  }

  addContent() {
    let navigation = '/admin/' + this.SelectedTool + '/content/manage';
    this.router.navigate([navigation]);
  }

  ContentContent(ContentId) {
    let navigation = '/admin/' + this.SelectedTool + '/content/manage/' + ContentId;
    this.router.navigate([navigation]);
  }

  deleteContent(ContentId) {
    this.isloading = true;
    this.trfaContentMaster.DeleteContent(this.tokenKey, ContentId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          // if (data['message'] != "")
          //   this.showMessage(data['message'], 'Warning', 'warning')
          // else
          this.showMessage("Deleted successfully", 'Success', 'success')
        }
        this.GetContent();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    // this.listOfStateIds=[];
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.GetContent();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.GetContent();
  }

  onSelectAll($event) {
    // this.listOfStateIds=[]; 
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.GetContent();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.getFund(); 
    this.GetContent();
  }


  onSelectCarrier($event) {
    // this.listOfCarrierIDs=[];
    this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
    this.listOfCarrierIDs.push($event.id);
    // this.stateId = ' ';
    // if (this.listOfCarrierIDs.length > 0) {
    //   this.stateId = this.listOfCarrierIDs.join(',')
    // }
    this.GetContent();
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIDs != null) {
      if (this.listOfCarrierIDs.length > 0) {
        this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
      }
    }
    if (this.listOfCarrierIDs.length > 0) {
      this.carrierId = this.listOfCarrierIDs.join(',')
    }
    this.GetContent();
  }

  onSelectAllCarrier($event) {
    // this.listOfCarrierIDs=[];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIDs = [];
    this.GetContent();
  }

  // replaceCommaLines(data) {

  //   let dataToArray = data.split(',').map(item => item.trim());
  //   let list = data.split(",").join("<br />")
  //   // return dataToArray.join("\n");
  //   return list
  // }

  onSelectFundType($event) {
    // this.listOfFundTypeIds=[];
    this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event.id)
    this.listOfFundTypeIds.push($event.id);
    // this.stateId = ' ';
    // if (this.listOfFundTypeIds.length > 0) {
    //   this.stateId = this.listOfFundTypeIds.join(',')
    // }
    this.GetContent();
  }

  onDeSelectFundType($event) {
    if (this.listOfFundTypeIds != null) {
      if (this.listOfFundTypeIds.length > 0) {
        this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event.id)
      }
    }
    if (this.listOfFundTypeIds.length > 0) {
      this.fundID = this.listOfFundTypeIds.join(',')
    }
    this.GetContent();
  }

  onSelectAllFundType($event) {
    // this.listOfFundTypeIds=[];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfFundTypeIds != null) {
        if (this.listOfFundTypeIds.length > 0) {
          this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event[iCounter].id)
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
        else {
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onDeSelectAllFundType($event) {
    this.listOfFundTypeIds = [];
    this.GetContent();
  }
  getCategory() {
    this.categoryMasterService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryList = result['category'];
          this.dropdownDateCategoryFilter = [];
          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilter.push({ "id": x.categoryID, "itemName": x.categoryName })
          });
          // if (this.categoryList.length > 0 && this.isEdit) {
          //   this.selectedItemsCategory=[]
          //  this.contentData.categoryID.map((x)=>{
          //     let data = this.categoryList.filter(x => x.categoryID == x.categoryID)[0];
          //     if(data) this.selectedItemsCategory.push({ "id": data.categoryID, "itemName": data.categoryName })
          //   })
          // }
        }
      },
      error => { }
    );
  }
  onSelectCategory($event) {
    // this.listOfCategoryIDs=[]
    this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
    this.listOfCategoryIDs.push($event.id);
    // this.stateId = ' ';
    // if (this.listOfCarrierIDs.length > 0) {
    //   this.stateId = this.listOfCarrierIDs.join(',')
    // }
    // this.GetUpdate();
    this.GetContent();
  }

  onDeSelectCategory($event) {
    if (this.listOfCategoryIDs != null) {
      if (this.listOfCategoryIDs.length > 0) {
        this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
      }
    }
    this.GetContent();
  }

  onSelectAllCategory($event) {
    // this.listOfCategoryIDs=[]
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onDeSelectAllCategory($event) {
    this.listOfCategoryIDs = [];
    this.GetContent();
    // this.GetUpdate();
  }
  // replaceCommaLines(data) {

  //   // let dataToArray = data.split(',').map(item => item.trim());
  //   // let list = data.split(",").join("<br />")
  //   // return dataToArray.join("\n");
  //   return data.replace(/,/g, ', ')
  // }

  replaceCommaLines(data) {
    let str='';
    let dataToArray = data.split(',').map(item => item.trim());
    // let list = data.split(",").join("<br />")
    dataToArray.forEach((element,index) => {
      
        if((dataToArray.length -1)==index){
          str=str+element;
        }
        else{
          if(((index+1)%3)==0){
            str=str+element+"<br />";                  
          }
          else{ str=str+element+',';}
        }      
      }); 
          
    return str
  } 
      

  openModel(content, item) {
    this.stateLinkData = null;
    this.isupdatesloading = true;
    this.stateLinkData = item;
    this.modalService.open(content, { centered: true });
    setTimeout(() => {
      this.isupdatesloading = false;
    }, 2000);
  }
  citationUrl(item) {
    if (item.url) {
      window.open(item.url, '_blank')
    }
  }

  createMyPdf() {
    this.blockDocument();

    let mainURL = this.baseUrl;
    let url = '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> '
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';
    
    var html = url + document.getElementById('divPrint').innerHTML;
    this.htmlToPdf.CreateAdminPDF(this.tokenKey, html, '0').
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.downloadFile('FundsDetails.pdf', data['url'], data['fileName']);
          this.unblockDocument();
        }
      },
        error => {
          this.unblockDocument();
        }
      );

  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        // ApplicationActivityStore('Download', 'TRFA File download ' + filename, this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/contentView');
        sessionStorage.setItem('fileloading', 'false');
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  contentGetAllPrintData() {
    this.print();
  }

  print() {
    let mainURL = this.baseUrl;
    let url = '<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';
    let printContents, popupWin;
    printContents = url + document.getElementById('divPrint').innerHTML;
    popupWin = window.open('', 'PrintWindow', 'width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>        
      </head>
      <body onload="window.print();window.close()">${printContents}</body>
    </html>`
    );
    popupWin.document.close();

  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    else
    {
      this.stateId='';
    }
    this.GetContent();
  }

  onFilterSelectAllFundType($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfFundTypeIds != null) {
        if (this.listOfFundTypeIds.length > 0) {
          this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event[iCounter].id)
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
        else {
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onFilterDeSelectAllFundType($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfFundTypeIds != null) {
        if (this.listOfFundTypeIds.length > 0) {
          this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event[iCounter].id)
        }
      }
    }
    this.GetContent();
  }

  onFilterSelectAllCarrier($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onFilterDeSelectAllCarrier($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
        }
      }
    }
    this.GetContent();
  }

  onFilterSelectAllCategory($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onFilterDeSelectAllCategory($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
        }
      }
    }
    this.GetContent();
  }

  print1() {
    this.blockDocument();
      
    let mainURL = this.baseUrl;
    let url = '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> '
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';
    
    var html = url + document.getElementById('divPrint').innerHTML;
    this.htmlToPdf.CreateAdminPDF(this.tokenKey, html, '0').
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.PrintPreviewPDF(data['url'],data['fileName']);
          this.deletecloseBtn.nativeElement.click();
        }
      },
        error => {
          this.unblockDocument();
        }
      );

    
  }

  PrintPreviewPDF(PDFPath:string,orignalFileName:any)
  {
    this.htmlToPdf.downloadPdf(PDFPath).subscribe(
      (res) => {
        this.blockDocument();
        // FileSaver.saveAs(res, filename);
        const blobUrl = window.URL.createObjectURL((res));
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
    this.unblockDocument();
  }

  getFund() {
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.trfaFundMaster.GetFund(this.tokenKey, this.stateId, true,true).subscribe(
      data => {
        this.dropdownListFund = []
        this.fundList = data['fund'];
        this.dropdownListFund = data['fund'];
        this.selectedItemsFund = [];
        if (this.fundList.length > 0) {
          let b = [];
          this.selectedItemsFund = [];
          this.fundList.forEach(x => {
            let a = this.listOffundIDs.filter(e => e == x.fundID);
            if (a.length > 0) {
              b.push(x.fundID);
              this.selectedItemsFund.push(x)
            }
            // this.selectedItemsFund.push(x)
          });
          this.listOffundIDs = b;
        }
        else {
          this.selectedItemsFund = [];
          this.listOffundIDs = [];
        }



        // if (this.fundList.length > 0) {
        //   this.fundList.forEach(x => {
        //     this.dropdownListFund.push({ "id": x.fundID, "itemName": x.fundName })
        //   });
        // }
      },
      error => { }
    );
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  onSelectFund($event) {
    this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
    this.listOffundIDs.push($event.id);
    // this.stateId = ' ';
    // if (this.listOffundIDs.length > 0) {
    //   this.stateId = this.listOffundIDs.join(',')
    // }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectFund($event) {
    if (this.listOffundIDs != null) {
      if (this.listOffundIDs.length > 0) {
        this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
      }
    }
    if (this.listOffundIDs.length > 0) {
      this.stateId = this.listOffundIDs.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllFund($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllFund($event) {
    this.listOffundIDs = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }
  
}




