import { Component, OnInit } from '@angular/core';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';
import { FilterModel } from 'src/Models/filterModel';
import { Title } from '@angular/platform-browser';
import { BriefingMasterModel } from 'src/Models/BriefingMasterModel';
import { StateMasterService } from 'src/Services/state-master.service';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import * as FileSaver from 'file-saver';
import { UserBriefingModel } from 'src/Models/UserBriefingModel';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RedirectService } from 'src/Services/RedirectService';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';

@Component({
  selector: 'app-briefing-services',
  templateUrl: './briefing-services.component.html',
  styleUrls: ['./briefing-services.component.css']
})
export class BriefingServicesComponent implements OnInit {
  listOfBriefing: BriefingMasterModel[];
  userBriefingModel = new UserBriefingModel();

  tokenKey: string = '';
  loginUser: any;

  isAscending: boolean = false
  upDowStateCSS: string = '';
  upDowUpdateTypeCSS: string = '';
  upDowUpdateDateCSS: string = '';

  isShowprogressSpinner: boolean;
  blockedDocument: boolean;

  rangeOfupdatesDates: any;

  // States
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];

  // Briefing Types
  dropdownListBriefingTypes = [];
  selectedItemsBriefingTypes = [];
  dropdownSettingsBriefingTypes = {};
  listOfBriefingTypesIds = [];

  // Briefing Sub Types
  dropdownListBriefingSubTypes = [];
  selectedItemsBriefingSubTypes = [];
  dropdownSettingsBriefingSubTypes = {};
  listOfBriefingSubTypesIds = [];

  filterModel = new FilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';

  productId: any = 0;
  SelectedTool: string = 'inteserra360';
  isDataLoad: boolean = false;
  userHasTracIt: boolean = false;

  listOfBriefingState = [];

  PageNumber: number = 1;
  PageSize: number;
  carrierCatName: string;
  OrderBy: string;
  IsActive: number = 1;


  constructor(private toastr: ToastrService, private stateMasterService: StateMasterService, private titleService: Title, private appActivity: ApplicationActivityService,
    private checkAuth: CheckAuth, private i360Service: I360Service, private router: Router, private redirectService: RedirectService) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginUser = sessionStorage.getItem('UserId');
    this.titleService.setTitle('Briefing Services | Inteserra - i360');
    if (sessionStorage.getItem('userHasTracIt') == 'true')
      this.userHasTracIt = true;
    this.productId = ToolEnum[this.SelectedTool];
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'updateDate DESC';
    this.filterModel.userID = this.loginUser;

    this.OrderBy = 'updateDate';
    this.upDowUpdateDateCSS = 'active-up'
    this.isAscending = true;
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit(): void {
    this.GetBriefingData();
    this.GetStateData();
    this.GetBulletinTypeData();
    this.GetBulletinSubTypeData();
  }

  GetBriefingData() {
    this.isDataLoad = true;
    this.listOfBriefing = [];
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.i360Service.i360FrontBriefingViewByPaged(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isDataLoad = false;
        if (data != null) {
          this.listOfBriefing = data['briefingData'];
          if (this.listOfBriefing != null) {
            if (this.listOfBriefing.length > 0) {
              this.totalRecordCount = this.listOfBriefing[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isDataLoad = false;
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        console.log(error);
      }
    );
  }

  sort(sortBy) {

    this.upDowStateCSS = '';
    this.upDowUpdateDateCSS = '';

    if (!this.isAscending) {
      this.isAscending = true;
      this.filterModel.orderBy = sortBy + ' DESC';

      if (sortBy === "stateName") {
        this.upDowStateCSS = 'active-up';
      }
      else if (sortBy === "updateDate") {
        this.upDowUpdateDateCSS = 'active-up';
      }
    }
    else {
      this.isAscending = false;
      this.filterModel.orderBy = sortBy;
      if (sortBy === "stateName") {
        this.upDowStateCSS = 'active';
      }
      else if (sortBy === "updateDate") {
        this.upDowUpdateDateCSS = 'active';
      }
    }
    this.OrderBy = sortBy;
    this.GetBriefingData();
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) { this.filterModel.keywordFilter = $event.target.value; }
    else { this.filterModel.keywordFilter = null; }

    this.GetBriefingData();
  }

  pageChanged($event: any) {
    this.applicationActivity('View', 'Briefing - Page ' + $event.page, ActionActivity['Briefing Services']);
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.GetBriefingData();
  }

  isUpdatesDatesFilter(value: any) {
    if (value != null) {
      if (value.length == 2) {
        this.filterModel.pageNumber = '1';
        this.currentPage = 1;
        const d = new Date(value[0]);
        const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

        const e = new Date(value[1]);
        const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');

        this.filterModel.fromDate = a;
        this.filterModel.toDate = f;
        this.GetBriefingData();
      }
    }
  }

  pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.filterModel.stateID = null;
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  GetStateData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.stateMasterService.getState(this.tokenKey, this.productId).subscribe(
      data => {
        if (data["state"] != null) {
          // Set All Item          
          for (let iCounter = 0; iCounter < data["state"].length; iCounter++) {
            this.dropdownListState.push(
              { "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name }
            );
          }
        }
        this.dropdownSettingsState = {
          singleSelection: false,
          text: "Select Jurisdiction(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No Jurisdiction available',
          showCheckbox: true
        };
      }, error => {
        console.log(error);
      })
  }

  clearUpdateDate() {
    this.rangeOfupdatesDates = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.fromDate = '';
    this.filterModel.toDate = '';
    this.GetBriefingData();
  }

  GetBulletinSubTypeData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.i360Service.i360BulletinSubTypeGet(this.tokenKey).subscribe(
      data => {
        if (data["listOfBulletinSubType"] != null) {
          for (let iCounter = 0; iCounter < data["listOfBulletinSubType"].length; iCounter++) {
            this.dropdownListBriefingSubTypes.push({ "id": data["listOfBulletinSubType"][iCounter].bulletinSubTypeId, "itemName": data["listOfBulletinSubType"][iCounter].bulletinSubType });
          }
        }

        this.dropdownSettingsBriefingSubTypes = {
          singleSelection: false,
          text: "Briefing Sub Type",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No types available',
          showCheckbox: true
        };
      }, error => {
        console.log(error);
      })
  }

  onSelectBriefingSubTypes($event) {
    this.listOfBriefingSubTypesIds = this.removeElemetninArray(this.listOfBriefingSubTypesIds, $event.id)
    this.listOfBriefingSubTypesIds.push($event.id);
    this.filterModel.briefingSubtypesId = this.listOfBriefingSubTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onDeSelectBriefingSubTypes($event) {
    if (this.listOfBriefingSubTypesIds != null) {
      if (this.listOfBriefingSubTypesIds.length > 0) {
        this.listOfBriefingSubTypesIds = this.removeElemetninArray(this.listOfBriefingSubTypesIds, $event.id)
      }
    }
    this.filterModel.briefingSubtypesId = this.listOfBriefingSubTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onSelectAllBriefingSubTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingSubTypesIds != null) {
        if (this.listOfBriefingSubTypesIds.length > 0) {
          this.listOfBriefingSubTypesIds = this.removeElemetninArray(this.listOfBriefingSubTypesIds, $event[iCounter].id)
          this.listOfBriefingSubTypesIds.push($event[iCounter].id);
        }
        else {
          this.listOfBriefingSubTypesIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.briefingSubtypesId = this.listOfBriefingSubTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onDeSelectAllBriefingSubTypes($event) {
    this.listOfBriefingSubTypesIds = [];
    this.filterModel.briefingSubtypesId = null;
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  GetBulletinTypeData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.i360Service.i360BulletinTypeGet(this.tokenKey).subscribe(
      data => {
        if (data["listOfBulletinType"] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data["listOfBulletinType"].length; iCounter++) {
            this.dropdownListBriefingTypes.push(
              { "id": data["listOfBulletinType"][iCounter].carrierCatId, "itemName": data["listOfBulletinType"][iCounter].carrierCatName }
            );
          }
        }

        this.dropdownSettingsBriefingTypes = {
          singleSelection: false,
          text: "Briefing Types",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No types available',
          showCheckbox: true,
          maxWidth: 150
        };
      }, error => {
        console.log(error);
      })
  }

  onSelectBriefingTypes($event) {
    this.listOfBriefingTypesIds = this.removeElemetninArray(this.listOfBriefingTypesIds, $event.id)
    this.listOfBriefingTypesIds.push($event.id);
    this.filterModel.briefingTypesId = this.listOfBriefingTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onDeSelectBriefingTypes($event) {
    if (this.listOfBriefingTypesIds != null) {
      if (this.listOfBriefingTypesIds.length > 0) {
        this.listOfBriefingTypesIds = this.removeElemetninArray(this.listOfBriefingTypesIds, $event.id)
      }
    }
    this.filterModel.briefingTypesId = this.listOfBriefingTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onSelectAllBriefingTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingTypesIds != null) {
        if (this.listOfBriefingTypesIds.length > 0) {
          this.listOfBriefingTypesIds = this.removeElemetninArray(this.listOfBriefingTypesIds, $event[iCounter].id)
          this.listOfBriefingTypesIds.push($event[iCounter].id);
        }
        else {
          this.listOfBriefingTypesIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.briefingTypesId = this.listOfBriefingTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onDeSelectAllBriefingTypes($event) {
    this.listOfBriefingTypesIds = [];
    this.filterModel.briefingTypesId = null;
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  downloadBriefingDownload(briefingId: number, docType: string) {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.i360Service.i360FrontBriefingMasterFindbyBriefingId(this.tokenKey, briefingId).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;

        if (data != null) {
          if (data['briefingData'] != null) {
            let title = data['briefingData'].title;
            let docPath = '';
            let stateName = data['briefingData'].stateName;
            if (docType == 'BriefingDoc') {
              docPath = data['briefingData'].briefingDocPath;
            }
            else {
              docPath = data['briefingData'].supportedDocPath;
            }
            if (docPath != '') {
              this.filterModel.docPath = docPath;
              this.filterModel.stateName = stateName;
              this.filterModel.docType = docType;
              this.i360Service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
                fileData => {
                  if (fileData != null) {
                    FileSaver.saveAs(fileData, docPath);
                    this.applicationActivity('Download', 'Briefing - ' + docType, ActionActivity['Briefing Services'], title, briefingId);
                  }
                }, error => {
                  console.log(error);
                }
              );
            }
          }
        }
      }, error => {
        console.log(error);
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
      }
    );
  }

  addToUserFavorite(briefingId: number) {
    this.userBriefingModel.briefingId = briefingId;
    this.userBriefingModel.userId = this.loginUser;
    this.userBriefingModel.createdBy = this.loginUser;
    this.userBriefingModel.modifiedBy = this.loginUser;
    this.applicationActivity('View', 'Add to My Library', ActionActivity['Briefing Services'], '', briefingId);
    this.i360Service.i360UserBriefingAdd(this.tokenKey, this.userBriefingModel).subscribe(
      data => {
        if (data != null) {
          this.GetBriefingData();
          if (data['userBriefingId'] == -1) {
            this.showMessage('Briefing already in your favorite list.', 'Duplicate', 'error')
          }
          else if (data['userBriefingId'] > 0) {
            // this.showMessage('Briefing added in your favorite list.', 'Added', 'success')
            this.toastr.success('Added to My Library.');
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  removeToUserFavorite(briefingId: number) {
    this.userBriefingModel.briefingId = briefingId;
    this.userBriefingModel.userId = this.loginUser;
    this.applicationActivity('View', 'Remove from My Library', ActionActivity['Briefing Services'], '', briefingId);
    this.i360Service.i360UserBriefingRemoveFromFavotire(this.tokenKey, this.userBriefingModel).subscribe(
      data => {
        if (data != null) {
          if (data['id'] > 0) {
            // this.showMessage('Briefing remove from your favorite list.', 'Removed', 'success');
            this.toastr.success('Removed from My Library.');
            this.filterModel.pageNumber = '1';
            this.GetBriefingData();
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  autoLoginInIRIS(url, briefingId) {

    if (this.userHasTracIt) {
      this.i360Service.GenerateByPassAuthenticationKey(this.tokenKey).subscribe(
        data => {

          if (data != null) {
            if (data['status'] == 'Success') {
              let encyHasKey = data['encyHasKey'];
              if (data['encyHasKey'] != null) {
                let productName = ToolEnum[ToolEnum.actionitem].toString();
                let userName = sessionStorage.getItem('UserName');
                let param = { username: userName, password: encyHasKey, productname: productName.toLowerCase() };
                this.redirectService.post(param, url);
              }
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    }
    else {
      if (sessionStorage.getItem('isLogin') == 'false') {
        let url = '/login';
        this.router.navigate([url]);
      }
      else {
        let productName = ToolEnum[ToolEnum.actionitem].toString();
        sessionStorage.setItem('productId', ToolEnum.briefingServices.toString());
        let url = '/Access/Denied/' + productName;
        this.router.navigate([url]);
      }

    }
    this.applicationActivity('View', 'Briefing - Track-It', ActionActivity['Briefing Services'], url, briefingId);
  }

  norightsMsg() {
    this.showMessage('You do not have access. Please contact your company administrator ', 'Permission', 'error')
  }

  applicationActivity(actionName, pageName, datatype = 0, actionUrl = '', dataId = 0) {
    ApplicationActivityStore(actionName, pageName, this.appActivity, dataId, datatype, actionUrl)
  }

  i360BriefingStateFindByBriefingId(BriefingId: any) {
    this.listOfBriefingState = [];
    this.i360Service.i360BriefingStateFindByBriefingId(this.tokenKey, BriefingId).subscribe(
      data => {
        if (data != null) {
          this.listOfBriefingState = data['listOfBriefingStates'];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  redirectAccessDenide() {
    sessionStorage.setItem('productId', ToolEnum.actionitem.toString());
    let url = '/Access/Denied/Trac-It';
    this.router.navigate([url]);
  }

  onFilterSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onFilterDeSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onFilterSelectAllBriefingTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingTypesIds != null) {
        if (this.listOfBriefingTypesIds.length > 0) {
          this.listOfBriefingTypesIds = this.removeElemetninArray(this.listOfBriefingTypesIds, $event[iCounter].id)
          this.listOfBriefingTypesIds.push($event[iCounter].id);
        }
        else {
          this.listOfBriefingTypesIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.briefingTypesId = this.listOfBriefingTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onFilterDeSelectAllBriefingTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingTypesIds != null) {
        if (this.listOfBriefingTypesIds.length > 0) {
          this.listOfBriefingTypesIds = this.removeElemetninArray(this.listOfBriefingTypesIds, $event[iCounter].id)
        }
      }
    }
    this.filterModel.briefingTypesId = this.listOfBriefingTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onFilterSelectAllBriefingSubTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingSubTypesIds != null) {
        if (this.listOfBriefingSubTypesIds.length > 0) {
          this.listOfBriefingSubTypesIds = this.removeElemetninArray(this.listOfBriefingSubTypesIds, $event[iCounter].id)
          this.listOfBriefingSubTypesIds.push($event[iCounter].id);
        }
        else {
          this.listOfBriefingSubTypesIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.briefingSubtypesId = this.listOfBriefingSubTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  onFilterDeSelectAllBriefingSubTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingSubTypesIds != null) {
        if (this.listOfBriefingSubTypesIds.length > 0) {
          this.listOfBriefingSubTypesIds = this.removeElemetninArray(this.listOfBriefingSubTypesIds, $event[iCounter].id)
        }
      }
    }
    this.filterModel.briefingSubtypesId = this.listOfBriefingSubTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

}
