import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientInformationModel } from 'src/Models/ClientInformationModel';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { CrraUpdatesMasterService } from 'src/Services/crra-updates-master.service';
import { FavoriteService } from 'src/Services/favorite.service';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';

@Component({
  selector: 'app-client-quote-list',
  templateUrl: './client-quote-list.component.html',
  styleUrls: ['./client-quote-list.component.css']
})
export class ClientQuoteListComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  userID: any;
  listofPricingQuotes: ClientInformationModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  CompanyName: string;

  upDownCompanyNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDownQuoteNameCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDownQuoteSentDateCSS: string = '';
  upDownQuoteStatusCSS: string = '';
  upDownMSAStatusCSS: string = '';
  isAscending: boolean = true;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  OrderByName: string = '';
  blockedDocument = false;
  ClientInformationId: number;
  isShowprogressSpinner = false;
  OrderBy: string;
  // IsClient: boolean = false;
  priceQuoteId: number = 0;

  constructor(private appActivity: ApplicationActivityService,private router: Router, private checkAuth: CheckAuth, private titleService: Title, private activedRoute: ActivatedRoute,
    private toastr: ToastrService, private pricingQuotesMaster: PricingQuotesService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = sessionStorage.getItem('UserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Quote Request');
    this.PageNumber = 1;
    this.PageSize = 10;
    this.OrderBy = 'CreatedDate desc';
    sessionStorage.removeItem('ClientCompanyId1');
    sessionStorage.removeItem('ClientCompanyName');
    sessionStorage.removeItem('ClientCompanyId');
    sessionStorage.removeItem('isVendor');
  }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getPriceQuoteListByUserId();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  getPriceQuoteListByUserId() {
    ApplicationActivityStore('View', 'Pricing Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], '/View/quotes-and-proposal');

    this.blockDocument();
    this.pricingQuotesMaster.getPriceQuoteListByUserId(this.tokenKey, parseInt(this.userID.toString()), this.PageNumber, this.PageSize, this.OrderBy, true).subscribe(
      result => {
        this.isloading = false;
        this.listofPricingQuotes = result['pricingQuotes'];
        this.unblockDocument();
        if (this.listofPricingQuotes != null) {
          if (this.listofPricingQuotes.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofPricingQuotes[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  addPricingQuotes() {
    let navigation = '/View/client-quote';
    this.router.navigate([navigation]);
  }

  updatePricingQuotes(item) {
    sessionStorage.setItem('ClientCompanyId',item.clientCompanyId)
    sessionStorage.setItem('PriceQuoteId',item.priceQuoteId);
    // if (item.isClient == true) {
      let navigation = '/View/client-quote/' + item.priceQuoteId + '/' + item.clientCompanyId;
      this.router.navigate([navigation]);
    // }
    // else {
    //   let navigation = '/View/company-info/' + item.clientInformationId;
    //   this.router.navigate([navigation]);
    // }
  }

  backtoquoteList(ClientInformationId: number) {
    let navigation = '/View/client-quote'
    this.router.navigate([navigation]);
    if (ClientInformationId != null) {
      sessionStorage.setItem('clientInfoId', ClientInformationId.toString());
    }
  }

  OnboardingDataCollection(ClientInformationId: number,companyName,priceQuoteId:number) {
    sessionStorage.setItem('ClientCompanyName',companyName);
    let navigation = '/View/add-company-info/' + ClientInformationId+'/' + priceQuoteId;
    sessionStorage.setItem('isVendor', 'true');
    this.router.navigate([navigation]);

  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDownCompanyNameCSS = '';
      this.upDownQuoteNameCSS = '';
      this.upDowCreatedDateCSS = '';
      this.upDowUpdateDateCSS = '';
      this.upDownQuoteSentDateCSS = '';
      this.upDownQuoteStatusCSS = '';
      this.upDownMSAStatusCSS = '';

      if (sortBy === "CompanyName") {
        this.upDownCompanyNameCSS = 'active-up';
      }
      else if (sortBy === "QuoteName") {
        this.upDownQuoteNameCSS = 'active-up';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowCreatedDateCSS = 'active-up';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowUpdateDateCSS = 'active-up';
      }
      else if (sortBy === "SentDate") {
        this.upDownQuoteSentDateCSS = 'active-up';
      }
      else if (sortBy === "IsQuoteApproved") {
        this.upDownQuoteStatusCSS = 'active-up';
      }
      else if (sortBy === "msaStatus") {
        this.upDownMSAStatusCSS = 'active-up';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;
      this.upDownCompanyNameCSS = '';
      this.upDownQuoteNameCSS = '';
      this.upDowCreatedDateCSS = '';
      this.upDowUpdateDateCSS = '';

      if (sortBy === "CompanyName") {
        this.upDownCompanyNameCSS = 'active';
      }
      else if (sortBy === "QuoteName") {
        this.upDownQuoteNameCSS = 'active';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowCreatedDateCSS = 'active';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowUpdateDateCSS = 'active';
      }
      else if (sortBy === "SentDate") {
        this.upDownQuoteSentDateCSS = 'active';
      }
      else if (sortBy === "IsQuoteApproved") {
        this.upDownQuoteStatusCSS = 'active';
      }
      else if (sortBy === "msaStatus") {
        this.upDownMSAStatusCSS = 'active';
      }
    }
    this.OrderByName = sortBy;
    this.getPriceQuoteListByUserId();
  }

  getPageLength() {
    return Math.min((this.PageNumber * this.PageSize), this.totalRecordCount);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.getPriceQuoteListByUserId();
  }

  downloadQuoteAndProposal(id) {
    if (id != undefined && id > 0) {
      window.open('/#/View/download-quotes-and-proposal/' + id, "_blank");
    }
  }
  
}
