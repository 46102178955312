import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNewActionModel } from 'src/Models/AddNewActionModel';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersService } from '../../../Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';
import { UserModel } from 'src/Models/usersModel';
import { ActionItemService } from '../../../Services/actionitem.service';
import { ToastrService } from 'ngx-toastr';
import { MatrixService } from 'src/Services/matrix.service';

@Component({
  selector: 'app-add-new-action-popup',
  templateUrl: './add-new-action-popup.component.html',
  styleUrls: ['./add-new-action-popup.component.css']
})

export class AddNewActionPopupComponent implements OnInit {
  @Input() IsActionFlag: any;
  @Input() title: string;
  // @Input() body: string;
  // @Output() delete = new EventEmitter<any>();
  addNewActionModel = new AddNewActionModel();
  userModel: UserModel[];

  loginUser: number = 0;
  message: string;
  isCheckVisibleToAllCompanyUsers: boolean;
  tokenKey: string = '';
  companyId: number = 0;

  dropdownUserSettings = {};
  selectedItemsUser = [];
  dropdownListUser = [];

  dropdownCarrierCategoriesSettings = {};
  selectedItemsCarrierCategories = [];
  dropdownListCarrierCategories = [];

  dropdownStateSettings = {};
  selectedItemsState = [];
  dropdownListState = [];
  productId: number;

  listOfAssignToUser = [];

  addUpdateActionItem = new FormGroup({
    assignToUser: new FormControl(''),
    actionItemId: new FormControl(''),
    deadLineDate: new FormControl(''),
    subject: new FormControl(''),
    description: new FormControl(''),
    visibleToAllCompanyUsers: new FormControl(''),
    state: new FormControl(''),
    carrierCategories: new FormControl(''),
  });


  // todayDateString: string = '';
  // bsInlineValue = new Date();

  isOneClickOnButton: boolean = false;

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  constructor(private matrix: MatrixService, private toastr: ToastrService, private actionItemServices: ActionItemService, private router: Router, public activeModal: NgbActiveModal, private usersService: UsersService, private checkAuth: CheckAuth) {
    this.title = 'Add New Trac-It';
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    // this.setCurrentDateToCalender();

  }

  // setCurrentDateToCalender() {
  //   let todayDate = new Date();
  //   let dd1 = todayDate.getDate().toString();
  //   if (parseInt(dd1.toString()) < 10)
  //     dd1 = '0' + dd1.toString();

  //   let mm1 = (todayDate.getMonth() + 1).toString();
  //   if (parseInt(mm1.toString()) < 10)
  //     mm1 = '0' + mm1.toString();

  //     this.todayDateString = mm1 + '/' + dd1 + '/' + todayDate.getFullYear();

  // }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyId = +sessionStorage.getItem("CompanyId");
    this.loginUser = +sessionStorage.getItem('UserId');
    this.productId = +sessionStorage.getItem('productId');
    this.GetUsersFindByCompanyId(this.companyId);
    this.GetCarrierCategories();
    this.GetStates(this.loginUser, this.productId);
  }

  confirmYes(webinar: any) {

    // this.webinarService.deleteWebinar(webinar.webinarId).subscribe(event => {
    //   if (event instanceof HttpResponse) {
    //     this.message = 'Webinar deleted successfully.';
    //   }
    // });
    // this.router.navigate(['/mywebinars']);
  }

  saveAddUpdateActionItem(addUpdateActionItem: any, isButtonDisable: boolean) {


    if (addUpdateActionItem.value['deadLineDate'] == undefined || addUpdateActionItem.value['deadLineDate'] == '') {
      this.showMessage('Please select deadline date', 'Error', 'error');
      return;
    }
    // let currentDate = new Date();
    // if (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) > addUpdateActionItem.value["deadLineDate"]) {
    //   this.showMessage('Previous deadline date not allow', 'Error', 'error');
    //   return;
    // }
    if (addUpdateActionItem.value['subject'] == undefined || addUpdateActionItem.value['subject'] == '') {
      this.showMessage('Please enter subject', 'Error', 'error');
      return;
    }
    if (addUpdateActionItem.value['description'] == undefined || addUpdateActionItem.value['description'] == '') {
      this.showMessage('Please enter description', 'Error', 'error');
      return;
    }
    if (addUpdateActionItem.value['state'].length == 0) {
      this.showMessage('Please select state', 'Error', 'error');
      return;
    }
    if (addUpdateActionItem.value['carrierCategories'].length == 0) {
      this.showMessage('Please select carrier type summary', 'Error', 'error');
      return;
    }

    this.addNewActionModel.state = '';
    let state = addUpdateActionItem.value['state']
    let saprator = '';
    if (state != null) {
      for (let iCounter = 0; iCounter < state.length; iCounter++) {
        this.addNewActionModel.state += saprator + state[iCounter].id;
        saprator = ',';
      }
    }

    this.addNewActionModel.carrierCategories = '';
    let carrierCategories = addUpdateActionItem.value['carrierCategories']
    saprator = '';
    if (state != null) {
      for (let iCounter = 0; iCounter < carrierCategories.length; iCounter++) {
        this.addNewActionModel.carrierCategories += saprator + carrierCategories[iCounter].id;
        saprator = ',';
      }
    }

    if (addUpdateActionItem.value['actionItemId'] != 'undefined' && addUpdateActionItem.value['actionItemId'] != undefined)
      this.addNewActionModel.actionItemId = addUpdateActionItem.value['actionItemId'];
    else
      this.addNewActionModel.actionItemId = 0;

    // if (addUpdateActionItem.value['assignToUser'].length > 0)
    //   this.addNewActionModel.assignToUser = addUpdateActionItem.value['assignToUser'][0].id;
    // else
    //   this.addNewActionModel.assignToUser = '0';

    if (this.addNewActionModel.assignToUser == null)
      this.addNewActionModel.assignToUser = '0';

    if (addUpdateActionItem.value['visibleToAllCompanyUsers'] != 'undefined' && addUpdateActionItem.value['visibleToAllCompanyUsers'] != undefined)
      this.addNewActionModel.visibleToAllCompanyUsers = addUpdateActionItem.value['visibleToAllCompanyUsers'];
    else
      this.addNewActionModel.visibleToAllCompanyUsers = false;

    if (typeof (addUpdateActionItem.value['description']) != undefined)
      this.addNewActionModel.description = addUpdateActionItem.value['description'];
    else
      this.addNewActionModel.description = null;

    this.addNewActionModel.subject = addUpdateActionItem.value['subject'];
    this.addNewActionModel.createdBy = +sessionStorage.getItem('UserId');
    this.addNewActionModel.companyId = this.companyId;
    this.addNewActionModel.actionItemId = 0;


    event.preventDefault();


    if (isButtonDisable) {      
      return;
    }

    this.isOneClickOnButton = true;

    this.actionItemServices.AddUpdateNewActionItem(this.tokenKey, this.addNewActionModel).subscribe(
      data => {
        this.isOneClickOnButton = false;
        if (data['status'] == 'Success') {
          this.showMessage('Entry saved successfully', 'Success', 'success');
          this.activeModal.close('Yes');
        }
        else {
          this.showMessage(data['status'], 'Error', 'error');       
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
        this.isOneClickOnButton = false;
      }
    );
  }

  viewAllCompanyUser($event) {
    this.isCheckVisibleToAllCompanyUsers = $event.target.checked;
    this.selectedItemsUser = [];
    this.UserDropDownSettings();
  }

  GetUsersFindByCompanyId(companyId: any) {
    this.usersService.GetUsersFindByCompanyIdRemoveLoginUser(this.tokenKey, companyId, this.productId, this.loginUser).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.userModel = data['companyUsers'];
          if (this.userModel != null) {
            if (this.userModel.length > 0) {
              for (let iCounter = 0; iCounter < this.userModel.length; iCounter++) {
                this.dropdownListUser.push({ "id": parseInt(this.userModel[iCounter].userId), "itemName": this.userModel[iCounter].userName });
              }
            }
          }
        }
        this.UserDropDownSettings();

      });
  }

  UserDropDownSettings() {
    this.dropdownUserSettings = {
      singleSelection: true,
      text: "Select User",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No user available',
      showCheckbox: false,
      disabled: !this.isCheckVisibleToAllCompanyUsers
    };
  }
  onSelectedUser($event) {
    this.addNewActionModel.assignToUser = $event['id'];
  }

  onDeSelectedUser($event) {
    this.addNewActionModel.assignToUser = null;
  }

  onDeSelectAllUser($event) {
    this.addNewActionModel.assignToUser = null;
  }

  GetCarrierCategories() {
    this.actionItemServices.GetCarrierCategories(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          let carrierCategories = data['companyModel'];
          if (carrierCategories != null) {
            if (carrierCategories.length > 0) {
              for (let iCounter = 0; iCounter < carrierCategories.length; iCounter++) {
                this.dropdownListCarrierCategories.push({ "id": parseInt(carrierCategories[iCounter].carrierCatId), "itemName": carrierCategories[iCounter].carrierCatName });
              }
            }
          }
        }

        this.dropdownCarrierCategoriesSettings = {
          singleSelection: false,
          text: "Select Carrier Type Summary",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No carrier type summary available',
          showCheckbox: true
        };
      });
  }

  onSelectedCarrierCategories($event) {
  }

  onDeSelectedCarrierCategories($event) {

  }

  GetStates(userId: any, productId: any) {
    this.matrix.getState(this.tokenKey, userId, productId).subscribe(
      data => {
        if (data["state"] != null) {
          this.selectedItemsState = [];
          for (let iCounter = 0; iCounter < data["state"].length; iCounter++) {
            this.dropdownListState.push(
              { "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name }
            );

            // if (data["state"][iCounter].favourite == "1")
            //   this.selectedItemsState.push({ "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name });
          }
        }

        this.dropdownStateSettings = {
          singleSelection: false,
          text: "Select State(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No state available',
          showCheckbox: true,
          enableFilterSelectAll: false,
          enableCheckAll: true
        };
      }, error => {

      })
  }

  onSelectedState($event) {

  }

  onDeSelectedState($event) {

  }

  isDeadlineDate(dateArray: any) {
    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      this.addNewActionModel.deadLineDate = mm1 + '-' + dd1 + '-' + dateArray.getFullYear();
    }
    else
      this.addNewActionModel.deadLineDate = null;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
}
