import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { IprUpdateImportService } from '../../../../Services/ipr-update-import.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-import',
  templateUrl: './update-import.component.html',  
  styles: [`
        .popover {color: #000;max-width: 100%;}
        .fileupload {height:auto !important;}
        .info {margin-top:25px;}
        .importerror{margin-top: 50px;border: 1px solid #00bfd6;padding: 12px;}
        .importerror b{color:#949494;}
    `]
})
export class UpdateImportComponent implements OnInit {
  fileToUpload: File = null;
  updateForm: FormGroup;
  loginuserID: number = 0;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: string = '';
  SelectedTool: string;
  ImportDisable: string = '';
  ImportError: string = '';
  constructor(private _service: IprUpdateImportService, private _fb: FormBuilder, private toastr: ToastrService, private router: Router) {
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.updateForm = this._fb.group({
      createdBy: this.loginuserID,
      fupdImportUpdates: null
    });
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }
  ngOnInit() {
  }
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
  }

  SaveIPRUpdates(updateForm: any, files) {
    this.ImportError = '';
    updateForm.value["createdBy"] = this.loginuserID;
    if (files.length === 0) {
      this.showMessage('Please select file to import', 'Import Updates', 'error');
      return;
    }

    if (!this.ValidateCSVExtension(files[0].name)) {
      this.showMessage('Please upload .CSV file only', 'Import Updates', 'error');
      return;
    }
    this.ImportDisable = 'disabled';
    this._service.IPRImportUpdates(this.updateForm, <File>files[0], this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        this.showMessage('Updates imported successfully', 'Import Updates', 'success');
        this.unblockDocument();
        this.ImportDisable = '';
        this.router.navigate(['/admin/' + this.SelectedTool + '/updates']);
      }
      else if (data.status == 'Failure') {
        this.ImportError = data.message;        
        this.unblockDocument();
        this.ImportDisable = '';
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
      this.ImportDisable = '';
    });
  }
  cancelupdates() {
    this.router.navigate(['/admin/' + this.SelectedTool + '/updates']);
  }
  ValidateCSVExtension(FileName) {
    var allowedFiles = [".csv"];
    var regex = new RegExp("([a-zA-Z0-9\s_() \\.\-:])+(" + allowedFiles.join('|') + ")$");    
    if (!regex.test(FileName.toLowerCase())) {
      return false;
    }
    return true;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
}
