import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IpService } from 'src/Services/ip.service';

@Component({
  selector: 'app-ftp',
  templateUrl: './ftp.component.html',
  styleUrls: ['./ftp.component.css']
})
export class FtpComponent implements OnInit {
  Router:string;
  baseUrl: string;
  isShowprogressSpinner:boolean=true;
  parameterVal:string='';
  constructor(private activedRoute: ActivatedRoute,private router: Router,
    @Inject('BASE_URL') baseUrl: string, private ipService: IpService) { 
      this.baseUrl = baseUrl;
    }
  
  
  ngOnInit() {

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.parameterVal=params['id'];
        }
      }
    });
    let mainURL = this.baseUrl;
    //this.Router=mainURL+'#'+this.router.url;
    // if(mainURL.includes('localhost'))
    // {
      window.open("https://staging.inteserra360.com/ftp_data/"+this.parameterVal+".zip", "_self");
    //}
    // else
    // {
    //   window.open(mainURL+"ftp_data/"+this.parameterVal+".zip", "_self");
    // }
    let ipAddress = '127.0.0.1';
    this.ipService.getIPAddress().subscribe((res: any) => {
      if (res) {
        if (res._body != null) {
          let obj = JSON.parse(res._body)
          if (obj != null) {
            ipAddress = obj.ip;
          }
        }
      }
    });
    this.Router=ipAddress;

    

    this.isShowprogressSpinner=false
  }
}
