import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DropDown911Model } from 'src/Models/CityMaster911Model';
import { CountyMaster911Model } from 'src/Models/CountyMaster911Model';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TRFAUpdateGetModel } from 'src/Models/TRFAUpdateModel';
import { GetRate } from 'src/Models/Update911Model';
import { CityMasterService } from 'src/Services/city-master.service';
import { CountyMasterService } from 'src/Services/county-master.service';
import { Main911Service } from 'src/Services/main911.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { Updates911Service } from 'src/Services/updates911.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-update911-add-update',
  templateUrl: './update911-add-update.component.html',
  styleUrls: ['./update911-add-update.component.css']
})
export class Update911AddUpdateComponent implements OnInit {
  listOfRateIds: GetRate[];
  PageNumber: number = 1;
  PageSize: number = 10;
  isRecord: boolean = false;
  update911MasterForm: FormGroup;
  userID: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  RateList: GetRate[] = [];
  stateList: StateMasterModel[] = [];
  selectedState = [];
  fundList: TRFAUpdateGetModel[] = [];
  selectedFund = [];
  isEdit: boolean = false;
  getStateId: string = '';

  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};
  dropdownSettingsDateFundFilter = {};
  dropdownSettingsRate = {};
  dropdownSettingsFee = {};
  dropdownDateFundFilter = [];
  selectedRate = [];
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  module = "TRFA";
  //State List
  dropdownListState = [];
  dropdownListRate = [];
  dropdownListRatetemp = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  listOffundIDs = [];
  stateId: string = ' ';
  isActive: number;
  scopeId: string = '';
  rateId: string = '';
  getRateId: string = '';
  //county
  selectedItemsCounty = [];
  dropdownDateCountyFilter = [];
  dropdownSettingsDateCountyFilter = {};
  listOfCountyIds = [];
  countyId: string = ' ';

  listOfCityIds = [];
  selectedCity = [];
  dropdownDateCityFilter = [];
  countyList: CountyMaster911Model[] = [];
  cityList: DropDown911Model[] = [];
  dropdownSettingsDateCityFilter = {};

  cityId: string = ' ';

  scopeList = [];
  selectedScope = [];
  

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private stateService: StateMasterService,
    private datePipe: DatePipe, private updates911: Updates911Service, private main911Service: Main911Service,
    private toastr: ToastrService, private fundService: TrfaFundMasterService,private cityService: CityMasterService,private countyService: CountyMasterService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetUpdateById(params['id']);
        }
      }
    });

    this.getState();
    this.getAllScope();
    //this.stateId='';
    //this.GetRateByStateId();

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.getStateId = '';
    this.dropdownSettingsState = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsRate = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsDateCountyFilter = {
      singleSelection: true,
      text: "Filter by County",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No County(s) available',
      showCheckbox: false
    };
    this.dropdownSettingsDateCityFilter = {
      singleSelection: true,
      text: "Filter by City",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No City(s) available',
      showCheckbox: false
    };

    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Scope(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.update911MasterForm = this._fb.group({
      updateID: 0,
      note: [''],
      rate: [''],
      fee: [''],
      updateDate: ['', Validators.required],
      rateId: [''],
    })

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetUpdateById(params['id']);
        }
      }
    });
  }

  GetUpdateById(updateID) {
    this.updates911.GetUpdateById(this.tokenKey, parseInt(updateID.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.update911MasterForm.patchValue({
            updateID: result['update'].updateID,
            rateId: result['update'].rateId,
            rate: result['update'].rate,
            fee: result['update'].fee,
            note: result['update'].note,
            stateId: result['update'].stateID,
            countyId: result['update'].countyId,
            cityId: result['update'].cityId,
            scopeId: result['update'].scopeId,
            updateDate: result['update'].updateDate
          });

          this.GetCountyByStateId(result['update'].stateID);
          this.GetCityByCountyId(result['update'].countyId);

          this.selectedState = [];
          if (this.stateList.length > 0) {
            let data = this.stateList.filter(x => x.stateId == result['update'].stateID)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name });
            this.stateId = this.selectedState[0].id.toString();
          }
          else {
            this.getStateId = result['update'].stateID;
            this.stateId = this.getStateId;
          }

          setTimeout(() => {
          this.selectedScope = [];
          if (this.scopeList.length > 0) {
            let data = this.scopeList.filter(x => x.id == result['update'].scopeId)[0];
            this.selectedScope.push({ "id": data.id, "itemName": data.itemName });
            this.scopeId = this.selectedScope[0].id.toString();            
          }
          else {
            this.scopeId = result['update'].scopeId;
          }
        }, 500);

          setTimeout(() => {
            this.selectedItemsCounty = [];
            if (this.countyList.length > 0) {
              let data = this.countyList.filter(x => x.countyId == result['update'].countyId)[0];
              this.selectedItemsCounty.push({ "id": data.countyId, "itemName": data.countyName });
              this.countyId = this.selectedItemsCounty[0].id.toString();
            }
            else {
              this.countyId = result['update'].countyId;
            }

            setTimeout(() => {
              this.selectedCity = [];

              if (this.cityList.length > 0) {
                let data = this.cityList.filter(x => x.id == result['update'].cityId)[0];
                this.selectedCity.push({ "id": data.id, "itemName": data.itemName });
                this.cityId = this.selectedCity[0].id.toString();
              }
              else {
                this.cityId = result['update'].cityId;
              }

            }, 1000);

          }, 1500);
         
          this.GetRebindRateByStateId(result['update'].rateId);
        }
      });
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
          this.selectedState = [];
          if (this.getStateId != '') {
            let data = this.stateList.filter(x => x.stateId == this.getStateId)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
        }
      },
      error => { }
    );
  }

  onSelectState($event) {
    this.listOfStateIds = [];
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';

    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }

    this.GetCountyByStateId(this.stateId);
    //this.getFund();
    this.selectedRate = [];
    this.GetRateByStateId();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.listOfStateIds.length == 0) {
      this.dropdownListRate = [];
      this.selectedRate = [];
      this.RateList = [];


      this.dropdownDateCountyFilter = [];
      this.selectedItemsCounty = [];
      this.listOfCountyIds = [];
      this.selectedCity = [];
      this.dropdownDateCityFilter = [];
      this.listOfCityIds = [];

    }
    else {
    {
      this.GetCountyByStateId(this.stateId);
      //this.getFund();
      this.GetRateByStateId();
    }
    // this.update911MasterForm.patchValue({ rate: '', fee: '' });
    }
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetCountyByStateId(this.stateId);
    //this.getFund();
    this.GetRateByStateId();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.dropdownListRate = [];
    this.selectedRate = [];
    this.RateList = [];
  }
  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  cancelUpdate() {
    let navigation = '/admin/' + this.SelectedTool + '/update';
    this.router.navigate([navigation]);
  }

  // GetRateByStateId() {
  //   this.updates911.GetRateByStateId(this.tokenKey, this.stateId, this.isActive, this.scopeId).subscribe(
  //     data => {

  //       if (data['status'] == 'Success') {
  //         this.RateList = data['rate'];
  //         this.dropdownListRatetemp = data['rate'];

  //         this.dropdownListRate = [];

  //         data['rate'].forEach(x => {
  //           this.dropdownListRate.push(
  //             {
  //               "id": x.rateId,
  //               "itemName": x.taxName,
  //             })
  //         });
  //       }
  //     },
  //     error => { }
  //   );
  // }

  GetRateByStateId() {
    this.updates911.GetRateByStateIdV2(this.tokenKey,Number(this.stateId),Number(this.countyId), Number(this.cityId),  Number(this.scopeId)).subscribe(
      data => {

        if (data['status'] == 'Success') {
          this.RateList = data['rate'];
          this.dropdownListRatetemp = data['rate'];

          this.dropdownListRate = [];

          data['rate'].forEach(x => {
            this.dropdownListRate.push(
              {
                "id": x.rateId,
                "itemName": x.taxName,
              })
          });
        }
      },
      error => { }
    );
  }

  GetRebindRateByStateId(rateId) {
    this.updates911.GetRateByStateId(this.tokenKey, this.stateId, this.isActive, this.scopeId).subscribe(
      data => {

        if (data['status'] == 'Success') {
          this.RateList = data['rate'];
          this.dropdownListRatetemp = data['rate'];

          this.dropdownListRate = [];

          data['rate'].forEach(x => {
            this.dropdownListRate.push(
              {
                "id": x.rateId,
                "itemName": x.taxName,
              })
          });
          this.selectedRate = [];

          if (this.RateList.length > 0) {
            let data = this.RateList.filter(x => x.rateId == rateId)[0];
            this.selectedRate.push({ "id": data.rateId, "itemName": data.taxName })
          }
          else
            setTimeout(() => {
              let data = this.RateList.filter(x => x.rateId == rateId)[0];
              this.selectedRate.push({ "id": data.rateId, "itemName": data.taxName });
            }, 1500);
        }
      },
      error => { }
    );
  }

  onDeSelectDateFilterAll($event) {
    this.selectedRate = [];
    this.dropdownListRate = [];
  }

  onSelectRate(event) {
   
  }

  ondeSelectRate(event) {
    
  }
  
  onDeSelectAllRate($event) {

  }
  
  save() {
    this.isSubmit = true;
    let data = this.update911MasterForm.value;
    if (!this.update911MasterForm.valid || this.selectedRate.length == 0)
      return
    data["createdBy"] = this.userID;

    let countyId=0;

    if (this.selectedItemsCounty.length > 0) {
      countyId = this.selectedItemsCounty[0].id;
    }

    let cityId=0;

    if (this.selectedCity.length > 0) {
      cityId = this.selectedCity[0].id;
    }

    let scopeId=0;

    if (this.selectedScope.length > 0) {
      scopeId = this.selectedScope[0].id;
    }
  
    let value = {
      updateID: data.updateID,
      rateId: this.selectedRate[0].id,
      rate: data.rate,
      fee: data.fee,
      updateDate: this.datePipe.transform(data.updateDate, 'MM-dd-yyyy hh:mm:ss'),
      note: data.note.trim(),
      stateId: this.selectedState[0].id,
      countyId:  countyId,
      cityId: cityId,
      scopeId: scopeId
    }
    this.updates911.UpdateAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.updateID > 0)
              this.showMessage('Update updated successfully', 'Success', 'success');
            else
              this.showMessage('Update saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/update']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }


  
  onSelectCounty($event) {

    this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
    this.listOfCountyIds.push($event.id);
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);

    this.GetRateByStateId();
  }

  onDeSelectCounty($event) {

    if (this.listOfCountyIds != null) {
      if (this.listOfCountyIds.length > 0) {
        this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    if (this.listOfCountyIds.length == 0) {
      this.listOfCityIds = [];
      this.selectedCity = [];
      this.dropdownDateCityFilter = [];
    }
    else
      this.GetCityByCountyId(this.countyId);

      this.GetRateByStateId();
  }

  onSelectAllCounty($event) {

    this.isShowprogressSpinner = true;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);
  }

  onDeSelectAllCounty($event) {
    this.isShowprogressSpinner = true;
    this.listOfCountyIds = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
  }


  
  onFilterSelectAllCounty($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);
  }

  onFilterDeSelectAllCounty($event){

    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
        }
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',');
      this.GetCityByCountyId(this.countyId);
    }
    else{
      this.dropdownDateCityFilter=[];
    }
  }


  
  GetCountyByStateId(stateId) {
    this.countyService.GetCountyByStateId(this.tokenKey, stateId,true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyList = result['county'];
          this.dropdownDateCountyFilter = [];
          if (this.countyList.length > 0) {
            let b = [];
            this.selectedItemsCounty = [];
            this.countyList.map(x => {
              let a = this.listOfCountyIds.filter(e => e == x.countyId);
              if (a.length > 0) {
                b.push(x.countyId);
                this.selectedItemsCounty.push({ "id": x.countyId, "itemName": x.countyName })
              }
              this.dropdownDateCountyFilter.push({ "id": x.countyId, "itemName": x.countyName })
            });
          }
          else {
            this.selectedItemsCounty = [];
          }
        }
      });
  }
  

  GetCityByCountyId(countyId) {

    this.cityService.GetCityByCountyIdMultiple(this.tokenKey, countyId,true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.cityList = result['city'];
          this.dropdownDateCityFilter = [];
          this.dropdownDateCityFilter = this.cityList;
          this.selectedCity = [];
          if (this.cityList.length > 0) {
            let b = [];
            this.selectedCity = [];

            this.listOfCityIds.map(x => {
              let a = this.cityList.filter(e => e.id == x);
              if (a.length > 0) {
                b.push(x);
                this.selectedCity.push(a[0])
              }
            });
            this.listOfCityIds = b;
          }
          else {
            this.selectedCity = [];
            this.listOfCityIds = [];
          }
        }
        this.isShowprogressSpinner = false;
      });
  }


  onSelectCity($event) {
    this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
    this.listOfCityIds.push($event.id);
    this.cityId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }

    this.GetRateByStateId();
  }

  onDeSelectCity($event) {

    if (this.listOfCityIds != null) {
      if (this.listOfCityIds.length > 0) {
        this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
      }
    }
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }

    this.GetRateByStateId();
  }

  onSelectAllCity($event) {

    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
          this.listOfCityIds.push($event[iCounter].id);
        }
        else {
          this.listOfCityIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllCity($event) {
    this.listOfCityIds = [];
   
  }

  onFilterSelectAllCity($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
          this.listOfCityIds.push($event[iCounter].id);
        }
        else {
          this.listOfCityIds.push($event[iCounter].id);
        }
      }
    }
  }

  onFilterDeSelectAllCity($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
        }
      }
    }
  }

  getAllScope() {
    this.main911Service.GetAllScope(this.tokenKey).subscribe(
      data => {
        let scopeList = data['scope'];
        if (scopeList.length > 0) {
          scopeList.map(x => {
            this.scopeList.push({ "id": x.scopeID, "itemName": x.scopeName })
          });
        }
      },
      error => { }
    );
  }

  onChangeScope(event) {
    //scopeID: event.id
    this.scopeId=event.id;

    this.GetRateByStateId();

  }
  onDeSelectFilterScopeAll($event) {
    this.scopeId='';

    this.selectedScope = [];
    this.GetRateByStateId();
  }
  
}
