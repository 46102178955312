import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ApplicationActivityModel } from 'src/Models/applicationActivityModel';
import { ClientCompanyModel } from 'src/Models/ClientCompanyModel';
import { AdminUserType, ApplicationActivityProductList } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { CheckAuth } from 'src/Services/utility.service';
import { UpdatesService } from 'src/Services/updatesService';
import * as FileSaver from 'file-saver';
import { I360Service } from 'src/Services/i360Service';

@Component({
  selector: 'app-application-activity',
  templateUrl: './application-activity.component.html',
  styleUrls: ['./application-activity.component.css']
})
export class ApplicationActivityComponent implements OnInit {
  filterModel = new FilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfApplicationActivity: ApplicationActivityModel[];

  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false
  upDownNameCSS: string = '';
  upDowURLCSS: string = '';
  upDownPageNameCSS: string = '';
  upDownActionNameCSS: string = '';
  upDownActionUrlCSS: string = '';
  upDownIpAddressCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDownCompanyNameCSS: string = '';
  upDowIsDemoCSS: string = '';
  upDowIsCompCSS: string = '';

  isloading: boolean;
  dropdownSettingsActionName = {};
  dropdownListActionName = [];
  selecteddropdownListActionName = [];

  dropdownSettingsActionTypeName = {};
  dropdownListActionTypeName = [];
  selecteddropdownListActionTypeName = [];

  dropdownSettingsProduct = {};
  dropdownListProduct = [];
  selecteddropdownListProduct = [];

  clientCompanyList: ClientCompanyModel[];
  dropdownListclientCompany = [];
  selectedItemsclientCompany = [];
  dropdownSettingclientCompany = {};
  listOfCompany = [];

  loginUserId: number = 0;
  systemUserCondition: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  rangeOfupdatesDates: any;

  constructor(private applicationActivityService: ApplicationActivityService, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService, private updatesService: UpdatesService, private i360Service: I360Service,
  ) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Application Activity');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'CreatedDate DESC';
    this.filterModel.keywordFilter = '';
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.GetApplicationActivityLibrariesData();

    this.dropdownListActionName.push({ id: 'Add', itemName: 'Add' });
    this.dropdownListActionName.push({ id: 'Edit', itemName: 'Edit' });
    this.dropdownListActionName.push({ id: 'Delete', itemName: 'Delete' });
    this.dropdownListActionName.push({ id: 'Download', itemName: 'Download' });
    this.dropdownListActionName.push({ id: 'Rename', itemName: 'Rename' });
    this.dropdownListActionName.push({ id: 'Reminder', itemName: 'Reminder' });
    this.dropdownListActionName.push({ id: 'View', itemName: 'View' });
    this.dropdownListActionName.push({ id: 'DeniedEntry', itemName: 'Denied Entry' });
    this.dropdownListActionName.push({ id: 'Mark Favorite', itemName: 'Mark Favorite' });
    this.dropdownListActionName.push({ id: 'Remove Favorite', itemName: 'Remove Favorite' });
    this.dropdownListActionName.push({ id: 'Click Link', itemName: 'Click Link' });

    this.dropdownSettingsActionName = {
      singleSelection: false,
      text: "Select Action(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No action available',
      showCheckbox: true
    };

    this.dropdownListProduct = ApplicationActivityProductList.sort((a, b) => a.itemName.localeCompare(b.itemName));;

    this.dropdownSettingsProduct = {
      singleSelection: false,
      text: "Select Product(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Product available',
      showCheckbox: true
    };

    this.dropdownSettingclientCompany = {
      singleSelection: false,
      text: "Filter by Company(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No company available',
      showCheckbox: true,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };

    this.loginUserId = +sessionStorage.getItem('AdminUserId');
    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.systemUserCondition = 'null'
    else
      this.systemUserCondition = '  (CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.loginUserId + '))';
  }

  GetApplicationActivityLibrariesData() {
    this.listOfApplicationActivity = [];
    this.isloading = true;
    this.applicationActivityService.ApplicationActivityGet(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfApplicationActivity = data['listOfData'];
          if (this.listOfApplicationActivity != null) {
            if (this.listOfApplicationActivity.length > 0) {
              this.totalRecordCount = this.listOfApplicationActivity[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters() {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetApplicationActivityLibrariesData();
  }

  onDeselectActionName() {
    this.filterModel.actionName = ''
    this.selecteddropdownListActionName = [];
    this.GetApplicationActivityLibrariesData();
  }

  onDeselectProduct() {
    this.filterModel.DataType = ''
    this.selecteddropdownListProduct = [];
    this.GetApplicationActivityLibrariesData();
  }

  onactionNameChange() {
    if (this.selecteddropdownListActionName && this.selecteddropdownListActionName.length > 0) {
      let actionName = '';
      this.selecteddropdownListActionName.forEach((element, index) => {
        actionName += `'${element.id}'` + (index != this.selecteddropdownListActionName.length - 1 ? ',' : '');
      });
      this.filterModel.actionName = actionName;

    }
    else
      this.filterModel.actionName = '';
    this.keywordFilters();
  }

  onProductChange() {
    if (this.selecteddropdownListProduct && this.selecteddropdownListProduct.length > 0)
    {
      this.filterModel.DataType = this.selecteddropdownListProduct.map(x => x.id).join(",");
      this.GetClientCompanyByProductId(this.filterModel.DataType)
    }
    else
      this.filterModel.DataType = '';
    this.keywordFilters();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetApplicationActivityLibrariesData();
  }

  cleanCssClass() {
    this.upDownNameCSS = '';
    this.upDownPageNameCSS = '';
    this.upDownActionNameCSS = '';
    this.upDownActionUrlCSS = '';
    this.upDownIpAddressCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDownCompanyNameCSS = '';
    this.upDowIsDemoCSS = '';
    this.upDowIsCompCSS = '';
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case "FullName": this.upDownNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "PageName": this.upDownPageNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "ActionName": this.upDownActionNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "ActionUrl": this.upDownActionUrlCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "IpAddress": this.upDownIpAddressCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "CreatedDate": this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "CompanyName": this.upDownCompanyNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "isDemo": this.upDowIsDemoCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "isComp": this.upDowIsCompCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetApplicationActivityLibrariesData();
  }

  GetClientCompany() {
    this.clientCompanyList = [];
    this.updatesService.GetClientCompany(this.tokenKey, 0, this.systemUserCondition).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.clientCompanyList = data['clientCompanay'];

          if (this.clientCompanyList != null) {
            if (this.clientCompanyList.length > 0) {
              for (let iCounter = 0; iCounter < this.clientCompanyList.length; iCounter++) {
                this.dropdownListclientCompany.push(
                  { "id": this.clientCompanyList[iCounter].companyId, "itemName": this.clientCompanyList[iCounter].companyName }
                );
              }
            }
          }

          if (this.listOfCompany != null && this.listOfCompany != undefined) {
            for (let iCounter = 0; iCounter < this.listOfCompany.length; iCounter++) {
              if (this.listOfCompany[iCounter] != '') {
                let obj = data["clientCompanay"].filter(m => m.companyId == this.listOfCompany[iCounter]);
                if (obj != null) {
                  this.selectedItemsclientCompany.push({ "id": obj[0].companyId, "itemName": obj[0].companyName });
                }
              }
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  GetClientCompanyByProductId(ProductId) {
    this.clientCompanyList = [];
    this.updatesService.GetClientCompanyByProductId(this.tokenKey,this.filterModel).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.clientCompanyList = data['clientCompanay'];

          if (this.clientCompanyList != null) {
            if (this.clientCompanyList.length > 0) {
              this.dropdownListclientCompany=[];
              for (let iCounter = 0; iCounter < this.clientCompanyList.length; iCounter++) {
                this.dropdownListclientCompany.push(
                  { "id": this.clientCompanyList[iCounter].companyId, "itemName": this.clientCompanyList[iCounter].companyName }
                );
              }
            }
          }

          if (this.listOfCompany != null && this.listOfCompany != undefined) {
            for (let iCounter = 0; iCounter < this.listOfCompany.length; iCounter++) {
              if (this.listOfCompany[iCounter] != '') {
                let obj = data["clientCompanay"].filter(m => m.companyId == this.listOfCompany[iCounter]);
                if (obj != null) {
                  this.selectedItemsclientCompany.push({ "id": obj[0].companyId, "itemName": obj[0].companyName });
                }
              }
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedCompany($event) {
    this.currentPage = 1;
    let companyId = $event.id;
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
        this.listOfCompany.push($event.id);
      }
      else {
        this.listOfCompany.push($event.id);
      }
    }
    this.filterModel.listOfCompanyId = this.listOfCompany.join(',');
    this.GetApplicationActivityLibrariesData();
  }

  onDeSelectedCompany($event) {
    this.currentPage = 1;
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
      }
    }
    this.filterModel.listOfCompanyId = this.listOfCompany.join(',');
    this.GetApplicationActivityLibrariesData();
  }

  onSelectAllCompany($event) {
    this.currentPage = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.listOfCompanyId = this.listOfCompany.join(',');
    this.GetApplicationActivityLibrariesData();
  }

  onDeSelectAllCompany($event) {
    this.currentPage = 1;
    this.listOfCompany = [];
    this.filterModel.listOfCompanyId = null;
    this.GetApplicationActivityLibrariesData();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  getExportData() {
    this.isloading = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.applicationActivityService.ApplicationActivityExports(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isloading = false;
        if (data != null) {
          if (data.status != "Data not found") {
            this.filterModel.docPath = data.responseFullPath;
            this.filterModel.docType = "Application Activity Exports";
            this.i360Service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
              fileData => {
                if (fileData != null) {
                  FileSaver.saveAs(fileData, "ActivityExports.csv");
                }
              }, error => {
                console.log(error);
              }
            );
          }
          else {
            this.toastr.error(data.status, 'Error');
          }
        }
      },
      error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isloading = false;
        console.log(error);
      }
    );
  }

  isUpdatesDatesFilter(value: any) {
    if (value != null) {
      if (value.length == 2) {
        this.filterModel.pageNumber = '1';
        this.currentPage = 1;
        const d = new Date(value[0]);
        const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

        const e = new Date(value[1]);
        const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');

        this.filterModel.fromDate = a;
        this.filterModel.toDate = f;
        this.GetApplicationActivityLibrariesData();
      }
    }
  }

  pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  clearUpdateDate() {
    this.rangeOfupdatesDates = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.fromDate = '';
    this.filterModel.toDate = '';
    this.GetApplicationActivityLibrariesData();
  }


  onFilterSelectAllAction($event) {
    if (this.selecteddropdownListActionName && this.selecteddropdownListActionName.length > 0) {
      let actionName = '';
      this.selecteddropdownListActionName.forEach((element, index) => {
        actionName += `'${element.id}'` + (index != this.selecteddropdownListActionName.length - 1 ? ',' : '');
      });
      this.filterModel.actionName = actionName;

    }
    else
      this.filterModel.actionName = '';
    this.keywordFilters();
  }

  onFilterDeSelectAllAction($event) {
    if (this.selecteddropdownListActionName && this.selecteddropdownListActionName.length > 0) {
      let actionName = '';
      this.selecteddropdownListActionName.forEach((element, index) => {
        actionName += `'${element.id}'` + (index != this.selecteddropdownListActionName.length - 1 ? ',' : '');
      });
      this.filterModel.actionName = actionName;

    }
    else
      this.filterModel.actionName = '';
    this.keywordFilters();
  }

  onFilterSelectAllProduct($event) {
    if (this.selecteddropdownListProduct && this.selecteddropdownListProduct.length > 0)
      this.filterModel.DataType = this.selecteddropdownListProduct.map(x => x.id).join(",");
    else
      this.filterModel.DataType = '';
    this.keywordFilters();
  }

  onFilterDeSelectAllProduct($event) {
    if (this.selecteddropdownListProduct && this.selecteddropdownListProduct.length > 0)
      this.filterModel.DataType = this.selecteddropdownListProduct.map(x => x.id).join(",");
    else
      this.filterModel.DataType = '';
    this.keywordFilters();
  }

}
