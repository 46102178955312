import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute,Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FilterModel } from 'src/Models/filterModel';
import { TracAlertsModel } from 'src/Models/TracAlertsModel';
import { ActionItemService } from 'src/Services/actionitem.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-user-trac-it-alerts',
  templateUrl: './user-trac-it-alerts.component.html',
  styleUrls: ['./user-trac-it-alerts.component.css']
})
export class UserTracItAlertsComponent implements OnInit {

  tokenKey: string = '';
  isloading: boolean = false;
  filterModel = new FilterModel();
  TracAlertsModel1: TracAlertsModel[]=[];
  totalRecordCount1: number = -1;
  actionId:number;
  userId:number = 0;
  keywordFilter: string=" ";
  pageNumber:number;
  pageSize:number;
  subject: string = '';
  orderBy:string;
  upDownEmailCSS: string = '';
  upDownNameCSS: string = '';
  isAscending: boolean;
  openMenuIndex: number = -1;

  constructor(private activedRoute: ActivatedRoute,private toastr: ToastrService, private router: Router, private checkAuth: CheckAuth, private actionItemServices: ActionItemService,private titleService: Title) { 
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.titleService.setTitle('Trac-It Alert Users');
  }

  ngOnInit() {
    this.TracAlertsModel1=[];
    this.keywordFilter="";
    this.pageNumber = 1;
    this.pageSize = 10;
    this.orderBy = 'ActionId ASC';    
    this.activedRoute.params.subscribe((params: Params) => {

      if (params['id']) {
        if (params['id'] != null) {
          this.actionId = params['id'];
          this.getUserList();
        }
      }
      else {
        this.actionId = 0;
      }
    });
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  keywordFilters($event) {
    this.keywordFilter = $event.target.value;
    if ($event.target.value != "" && $event.target.value != null) {
      this.keywordFilter = $event.target.value;
    }
    else { this.keywordFilter = ""; }

    this.getUserList();
  }

  getUserList() {
    this.isloading = true;
    if (this.keywordFilter == "null" || this.keywordFilter == "")
    { this.keywordFilter = "null";}
    this.actionItemServices.GetTracItAlertsByIds(this.tokenKey,this.actionId,this.pageNumber,this.pageSize,this.keywordFilter,this.userId,this.orderBy).subscribe(data => {
      if (data['status'] == 'Success') {
        this.isloading = false;
        this.TracAlertsModel1 = data['generalAlert'].tractItAlerts;        
        if (this.TracAlertsModel1 != null) {
          if (this.TracAlertsModel1.length > 0) {
            this.totalRecordCount1 = +this.TracAlertsModel1[0].totalRowCount;
          }
          else {
            this.totalRecordCount1 = 0;
          }
        }
        this.subject = this.TracAlertsModel1[0].subject;
      }
      else {
        this.showMessage(data['status'], 'Error', 'error');
      }

    },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  redirectToEditView(actionId:any,userId:any) {
    this.router.navigate(['/admin/trac-it/Trac-itAlerts/manage/' + actionId + '/' + userId]);
  }
  
  pageChanged($event: any) {
    this.pageNumber = parseInt($event.page);
    this.getUserList();
  }

  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';
      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.getUserList();
  }

  setDescCSS(sortBy) {
    if (sortBy === "email") {
      this.upDownEmailCSS = 'fa fa-arrow-down';
      this.upDownNameCSS = '';
    }
    else if (sortBy === "name") {
      this.upDownEmailCSS = '';
      this.upDownNameCSS = 'fa fa-arrow-down';
    }
  }

  setAscCSS(sortBy) {
    if (sortBy === "email") {
      this.upDownEmailCSS = 'fa fa-arrow-up';
      this.upDownNameCSS = '';
    }
    else if (sortBy === "name") {
      this.upDownEmailCSS = '';
      this.upDownNameCSS = 'fa fa-arrow-up';
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }
}
