import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/Services/app.config';
import { StateMasterService } from 'src/Services/state-master.service';
import { CrraCarrierMasterService } from 'src/Services/crra-carrier-master.service';
import { CheckAuth } from 'src/Services/utility.service';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';

@Component({
  selector: 'app-carrier-type-cfo-add-update',
  templateUrl: './carrier-type-cfo-add-update.component.html',
  styleUrls: ['./carrier-type-cfo-add-update.component.css']
})
export class CarrierTypeCfoAddUpdateComponent implements OnInit {

  carrierMasterForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private carrierObligationService: CarrierObligationService, private toastr: ToastrService) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    if(this.SelectedTool == 'view 47'){
      this.SelectedTool = 'view47';
    }
    this.isSubmit = false;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.carrierMasterForm = this._fb.group({
      carrierTypeId: 0,
      carrierName: ['', Validators.required],
      sortOrder: ['', Validators.required],
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetCarrierById(params['id']);
        }
      }
    });
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  cancelCarrierMaster() {
    let navigation = '/admin/' + this.SelectedTool + '/carrier';
    this.router.navigate([navigation]);
  }

  GetCarrierById(carrierTypeId: number) {
    this.carrierObligationService.GetCarrierById(this.tokenKey, parseInt(carrierTypeId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierMasterForm.patchValue({
            carrierTypeId: result['obligationcarrier'].carrierTypeId,
            carrierName: result['obligationcarrier'].carrierName,
            sortOrder: result['obligationcarrier'].sortOrder
          });
        }
      });
  }

  save() {
    this.isSubmit = true;
    let data = this.carrierMasterForm.value;
    if (!this.carrierMasterForm.valid || !data.carrierName.trim())
      return
    let value = {
      carrierTypeId: data.carrierTypeId,
      carrierName: data.carrierName.trim(),
      sortOrder: data.sortOrder
    }
    this.carrierObligationService.CarrierAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {

            if (data.carrierTypeId > 0)
              this.showMessage('carrier updated successfully', 'Success', 'success');
            else
              this.showMessage('carrier saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/carrier']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
}
