import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AppConfig } from '../../../../Services/app.config';
import { CheckAuth } from '../../../../Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { FieldModel } from '../../../../Models/fieldModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { IPRUpdatesModel } from '../../../../Models/IPRUpdatesModel';
import { IprUpdatesService } from '../../../../Services/iprupdatesService';
import { ToolEnum } from 'src/Models/Common';

@Component({
  selector: 'app-manageupdate',
  templateUrl: './manageupdate.component.html',
  styleUrls: ['./manageupdate.component.css']
})
export class ManageIPRUpdateComponent implements OnInit {
  updateForm: FormGroup;
  userID: number = 0;
  appModel: any;
  tokenKey: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  statusCode: number = 0;
  stateFilter: string = '';
  fieldModel: FieldModel[] = [];
  listOfActiveStatesCount: number = 0;
  listOfDeActiveStatesCount: number = 0;
  listOfNotRegisteredStatesCount: number = 0;

  iPRUpdatesModel: IPRUpdatesModel = {
    carrierId: 0,
    businessName: '',
    businessAddress: '',
    previousName: '',
    regContactName: '',
    regContactTitle: '',
    regContantAddress: '',
    regContactTelephone: '',
    regContactEmailId: '',
    rccContactName: '',
    rccContactTitle: '',
    rccContactAddress: '',
    rccContactTelephone: '',
    rccContactEmail: '',
    rccContactDepartment: '',
    listOfActiveStates: [],
    listOfDeActiveStates: [],
    listOfNotRegisteredStates: []
  };
  selectedActiveStatesAll: any;
  selectedDeActiveStatesAll: any;
  carrierId: number = 0;
  isloading: boolean = false;
  isfieldenable: boolean = false;
  SelectedTool: string;
  productId : number;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private toastr: ToastrService, private iprupdatesService: IprUpdatesService
    , private router: Router, private titleService: Title
    , private activedRoute: ActivatedRoute) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.updateForm = this._fb.group({
      carrierId: 0,
      businessName: [''],
      businessAddress: [''],
      previousName: [''],
      regContactName: [''],
      regContactTitle: [''],
      regContantAddress: [''],
      regContactTelephone: [''],
      regContactEmailId: [''],
      rccContactName: [''],
      rccContactTitle: [''],
      rccContactAddress: [''],
      rccContactTelephone: [''],
      rccContactEmail: [''],
      rccContactDepartment: [''],
      isActive: [''],
      ActiveStates: [''],
      DeActiveStates: [''],
      NotRegisteredStates:[''],
      createdBy: this.userID,
      modifiedBy: this.userID
    })
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Update');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.movetotop();
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.carrierId = params['id'];
          this.GetIPRUpdates();
        }
      }      
    });
  }
  GetIPRUpdates(): void {
    this.isloading = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.iprupdatesService.GetIPRUpdatesFindByCarrierID(this.tokenKey, this.carrierId, this.productId).subscribe(
      data => {
        this.iPRUpdatesModel = data["updates"];
        this.updateForm.setValue({
          'carrierId': this.iPRUpdatesModel[0].carrierId,
          'businessName': this.iPRUpdatesModel[0].businessName,
          'businessAddress': this.iPRUpdatesModel[0].businessAddress,
          'previousName': this.iPRUpdatesModel[0].previousName,
          'regContactName': this.iPRUpdatesModel[0].regContactName,
          'regContactTitle': this.iPRUpdatesModel[0].regContactTitle,
          'regContantAddress': this.iPRUpdatesModel[0].regContantAddress,
          'regContactTelephone': this.iPRUpdatesModel[0].regContactTelephone,
          'regContactEmailId': this.iPRUpdatesModel[0].regContactEmailId,
          'rccContactName': this.iPRUpdatesModel[0].rccContactName,
          'rccContactTitle': this.iPRUpdatesModel[0].rccContactTitle,
          'rccContactAddress': this.iPRUpdatesModel[0].rccContactAddress,
          'rccContactTelephone': this.iPRUpdatesModel[0].rccContactTelephone,
          'rccContactEmail': this.iPRUpdatesModel[0].rccContactEmail,
          'rccContactDepartment': this.iPRUpdatesModel[0].rccContactDepartment,
          'isActive': this.iPRUpdatesModel[0].isActive,
          'ActiveStates': '',
          'DeActiveStates': '',
          'NotRegisteredStates': '',
          'createdBy': this.userID,
          'modifiedBy': this.userID
        });
        this.iPRUpdatesModel.listOfActiveStates = data["updates"][0].listOfActiveStates;
        this.iPRUpdatesModel.listOfDeActiveStates = data["updates"][0].listOfDeActiveStates;
        this.iPRUpdatesModel.listOfNotRegisteredStates = data["updates"][0].listOfNotRegisteredStates;

        let objNotRegisteredState = this.iPRUpdatesModel.listOfNotRegisteredStates.filter((item) => item.isSelected == true);
        if (objNotRegisteredState.length > 0) {
          for (let obj of objNotRegisteredState) {
            this.iPRUpdatesModel.listOfDeActiveStates.filter(item => item.stateId == obj.stateId)[0].isActive = 'false';
          }
        }
        this.unblockDocument();
        this.CalculateState();
        this.isloading = false;
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
        this.isloading = false;
      }
    );
  }
  saveupdates(updateForm: any) {
    if (updateForm.value["businessName"] == null || updateForm.value["businessName"] == undefined || updateForm.value["businessName"] == "") {
      this.showMessage('Please select Business Name.', 'Required', 'error');
      return;
    }
    if ((updateForm.value["businessAddress"] == null || updateForm.value["businessAddress"] == undefined || updateForm.value["businessAddress"] == "")) {
      this.showMessage('Please enter Business Address.', 'Required', 'error');
      return;
    }
    if ((updateForm.value["rccContactEmail"] != null || updateForm.value["rccContactEmail"] != "")) {
      if (!this.isValidMailFormat(updateForm.value["rccContactEmail"])) {
        this.showMessage('Please provide a valid Rural Call Completion Contact Email', 'Required', 'error'); 
        return;
      }
    }
    if ((updateForm.value["regContactEmailId"] != null || updateForm.value["regContactEmailId"] != "")) {
      if (!this.isValidMailFormat(updateForm.value["regContactEmailId"])) {
        this.showMessage('Please provide a valid Regulatory Contact Email', 'Required', 'error');
        return;
      }
    }
    this.blockDocument();
    updateForm.value["carrierId"] = this.carrierId;
    let msg = 'Updated';
    updateForm.value["ActiveStates"] = this.iPRUpdatesModel.listOfActiveStates.filter(i => i.isSelected == true).map(i => i.shortName).join(',');
    updateForm.value["DeActiveStates"] = this.iPRUpdatesModel.listOfDeActiveStates.filter(i => i.isSelected == true).map(i => i.shortName).join(',');
    updateForm.value["NotRegisteredStates"] = this.iPRUpdatesModel.listOfNotRegisteredStates.filter(i => i.isSelected == true).map(i => i.stateId).join(',');
    
    this.iprupdatesService.addupdateupdates(this.updateForm.value, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        this.showMessage(msg + ' successfully', msg, 'success');
        this.unblockDocument();
        this.router.navigate(['/admin/' + this.SelectedTool + '/updates']);
      }
      else {
        if (data.message == 'duplicate') {
          this.showMessage('Duplicate Business Name not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else {
          this.showMessage(data.status, 'Error', 'error');
          this.unblockDocument();
        }
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }
  UpdateactiveState(state: any) {
    let id = state.stateId;
    if (state.isSelected == true)
    {
      let objDeActiveState = this.iPRUpdatesModel.listOfDeActiveStates.filter((item) => item.stateId == id);
      if (objDeActiveState.length == 1) {
        objDeActiveState[0].isSelected = false;
      }

      let objNotRegisteredState = this.iPRUpdatesModel.listOfNotRegisteredStates.filter((item) => item.stateId == id);
      if (objNotRegisteredState.length == 1) {
        objNotRegisteredState[0].isSelected = false;
      }
    } else {
      let objDeActiveState = this.iPRUpdatesModel.listOfDeActiveStates.filter((item) => item.stateId == id);
      if (objDeActiveState.length == 1) {
        objDeActiveState[0].isSelected = true;
      }
    }
    this.CalculateState();
  }
  UpdatedeactiveState(state: any) {
    let id = state.stateId;
    if (state.isSelected == true) {
      let objNotRegisteredState = this.iPRUpdatesModel.listOfNotRegisteredStates.filter((item) => item.stateId == id && item.isSelected == true);
      if (objNotRegisteredState.length == 1) {        
        objNotRegisteredState[0].isSelected = false;
      }
      let objActiveState = this.iPRUpdatesModel.listOfActiveStates.filter((item) => item.stateId == id);
      if (objActiveState.length == 1) {
        objActiveState[0].isSelected = false;
      }
    }
    this.CalculateState();
  }
  UpdateNotRegisteredState(state: any) {
    let id = state.stateId;
    if (state.isSelected == true) {
      let objActiveState = this.iPRUpdatesModel.listOfActiveStates.filter((item) => item.stateId == id);
      if (objActiveState.length == 1) {
        objActiveState[0].isSelected = false;
      }
      let objDeActiveState = this.iPRUpdatesModel.listOfDeActiveStates.filter((item) => item.stateId == id);
      if (objDeActiveState.length == 1) {
        objDeActiveState[0].isSelected = false;
      }
    }
    this.CalculateState();
  }
  CalculateState() {
    this.listOfActiveStatesCount = this.iPRUpdatesModel.listOfActiveStates.filter((item) => item.isSelected === true).length;
    this.listOfDeActiveStatesCount = this.iPRUpdatesModel.listOfDeActiveStates.filter((item) => item.isSelected === true).length;
    this.listOfNotRegisteredStatesCount = this.iPRUpdatesModel.listOfNotRegisteredStates.filter((item) => item.isSelected === true).length;
  }
  isValidMailFormat(email: string) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (email != "" && (email.length <= 5 || !EMAIL_REGEXP.test(email))) {
      return false;
    }

    return true;
  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }
  cancelupdates() {
    this.router.navigate(['/admin/' + this.SelectedTool + '/updates']);
  }
}
