import { Component, OnInit } from '@angular/core';
import { BriefingMasterModel } from 'src/Models/BriefingMasterModel';
import { I360Service } from 'src/Services/i360Service';
import { Title } from '@angular/platform-browser';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EmailLoginService } from 'src/Services/emaillogin.service';
import { FilterModel } from 'src/Models/filterModel';
import { MenuService } from 'src/Services/menu.service';
import * as FileSaver from 'file-saver';
import { ActionActivity, AdminUserType, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { UserBriefingModel } from 'src/Models/UserBriefingModel';

@Component({
  selector: 'app-briefing-details',
  templateUrl: './briefing-details.component.html',
  styleUrls: ['./briefing-details.component.css']
})
export class BriefingDetailsComponent implements OnInit {
  listOfBriefing: BriefingMasterModel[];
  userBriefingModel = new UserBriefingModel();

  briefingId: number = 0;
  tokenKey: string = '';
  quaryValues: string = '';
  encyKeyfilterModel = new FilterModel();
  filterModel = new FilterModel();
  selectdUserDocument: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  emailUserId: any = 0;
  listOfBriefingState = [];
  loginUser: any = 0;
  constructor(
    private i360Service: I360Service,
    private menuServices: MenuService,
    private toastr: ToastrService,
    private titleService: Title,
    private checkAuth: CheckAuth,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private emailLoginService: EmailLoginService,
    private appActivity: ApplicationActivityService,
  ) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    if (sessionStorage.getItem('emailBriefingId') != '' && sessionStorage.getItem('emailBriefingId') != null && typeof(sessionStorage.getItem('emailBriefingId')) != undefined) {
      this.briefingId = +sessionStorage.getItem('emailBriefingId');
      sessionStorage.removeItem('emailBriefingId');
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'updateDate DESC';
    this.loginUser = sessionStorage.getItem('UserId');
    this.titleService.setTitle('Briefing Details');
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.quaryValues = params['id'];
          this.encyKeyfilterModel.emailKey = params['id'];
          this.IsValidBriefingEmailKey();
        }
      }
    });
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  IsValidBriefingEmailKey() {
    this.emailLoginService.isValidBriefingEmailKey(this.encyKeyfilterModel).subscribe(
      data => {
        this.briefingId = data.briefingId;
        this.emailUserId = data.userId;
        this.filterModel.userID = this.emailUserId;

        let IsAdmin = false;
        if (localStorage.getItem('clientTypeId') != null) {
          IsAdmin = localStorage.getItem('clientTypeId') == '2' ? true : false;
        }
        if (this.emailUserId.toString() !== this.loginUser.toString() && !IsAdmin)
          this.router.navigate(['/Access/Denied/BriefingEmailServices']);
        else
          this.GetBriefingData();
      },
      error => {
        console.log(error);
      }
    );
  }

  GetBriefingData() {
    this.filterModel.whereCondition = "(briefingId = " + this.briefingId + ") ";
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.menuServices.i360BriefingMasterFindbyBriefingId(this.tokenKey, this.filterModel).subscribe(
      data => {
   
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        if (data != null) {
          this.listOfBriefing = data['briefingData'];
          if (this.listOfBriefing != null) {
            if (this.listOfBriefing.length > 0) {
              //this.listOfBriefing[0].displayBriefingDocName = 'https://api.inteserra360.com//BriefingService/BriefingDocument/bbf74c01-ab24-47a3-86ea-155b70eaa801.pdf';
              this.selectdUserDocument = 'https://docs.google.com/gview?url=' + this.listOfBriefing[0].displayDocumentURL + '&embedded=true';
            }
          }
        }
      },
      error => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        console.log(error);
      }
    );
  }

  downloadBriefingDownload(briefingId: number, docType: string) {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.i360Service.i360FrontBriefingMasterFindbyBriefingId(this.tokenKey, briefingId).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
  
        if (data != null) {          
          let title = data['briefingData'].title;
          if (data['briefingData'] != null) {
            let docPath = '';
            let stateName = data['briefingData'].stateName;
            if (docType == 'BriefingDoc') {
              docPath = data['briefingData'].briefingDocPath;
            }
            else {
              docPath = data['briefingData'].supportedDocPath;
            }
            if (docPath != '') {
              this.filterModel.docPath = docPath;
              this.filterModel.stateName = stateName;
              this.filterModel.docType = docType;
              this.i360Service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
                fileData => {
                  if (fileData != null) {
                    FileSaver.saveAs(fileData, docPath);
                    this.applicationActivity('Download', 'Briefing Email - ' + docType, ActionActivity['Briefing Services'], title, briefingId);
                  }
                }, error => {
                  console.log(error);
                }
              );
            }
          }
        }
      }, error => {
        console.log(error);
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
      }
    );
  }

  applicationActivity(actionName, pageName, datatype = 0, actionUrl = '', dataId = 0) {
    ApplicationActivityStore(actionName, pageName, this.appActivity, dataId, datatype, actionUrl)
  }

  addToUserFavorite(briefingId: number) {
    this.userBriefingModel.briefingId = briefingId;
    this.userBriefingModel.userId = this.emailUserId;
    this.userBriefingModel.createdBy = this.emailUserId;
    this.userBriefingModel.modifiedBy = this.emailUserId;
    this.applicationActivity('View', 'Add to My Library', ActionActivity['Briefing Services'], '', briefingId);
    this.i360Service.i360UserBriefingAdd(this.tokenKey, this.userBriefingModel).subscribe(
      data => {
        if (data != null) {
          this.GetBriefingData();
          if (data['userBriefingId'] == -1) {
            this.showMessage('Briefing already in your favorite list.', 'Duplicate', 'error')
          }
          else if (data['userBriefingId'] > 0) {
            // this.showMessage('Briefing added in your favorite list.', 'Added', 'success')
            this.toastr.success('Added to My Library.');
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  removeToUserFavorite(briefingId: number) {
    this.userBriefingModel.briefingId = briefingId;
    this.userBriefingModel.userId = this.emailUserId;
    this.applicationActivity('View', 'Remove from My Library', ActionActivity['Briefing Services'], '', briefingId);
    this.i360Service.i360UserBriefingRemoveFromFavotire(this.tokenKey, this.userBriefingModel).subscribe(
      data => {
        if (data != null) {
          if (data['id'] > 0) {
            // this.showMessage('Briefing remove from your favorite list.', 'Removed', 'success');
            this.toastr.success('Removed from My Library.');
            this.filterModel.pageNumber = '1';
            this.GetBriefingData();
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  i360BriefingStateFindByBriefingId(BriefingId: any) {
    this.listOfBriefingState = [];
    this.i360Service.i360BriefingStateFindByBriefingId(this.tokenKey, BriefingId).subscribe(
      data => {
        if (data != null) {
          this.listOfBriefingState = data['listOfBriefingStates'];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  norightsMsg() {
    this.showMessage('You do not have access. Please contact your company administrator ', 'Permission', 'error')
  }
}
