export class CRRADepositeModel {
    payRateId: number;
    ixcDep: string;
    clecDep: string;   
    ixcPay: string;
    clecPay: string;   
    rate: boolean;
    notes: string;
    stateId:number;
    filterText:string;
    totalRecordCount: number;
}

export class FilterDepositeModel {
    pageNumber?: number;
    pageSize?: number;
    orderBy?: string;    
    stateId?: string | number;    
    filterText?: string;
    userId?: number;
    isFavorite?:number;
  }