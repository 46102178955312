import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../../Services/app.config';
import { UsersService } from '../../../Services/users.service';
import { CheckAuth } from '../../../Services/utility.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from '../../../Models/usersModel';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  userModel = new UserModel();
  appModel: any;
  tokenKey: string = '';
  loginForm: FormGroup;

  

  constructor(private toastr: ToastrService, private _fb: FormBuilder, private UserService: UsersService, private checkAuth: CheckAuth, private router: Router
    , private titleService: Title) {
    sessionStorage.removeItem('tokenValue');
    sessionStorage.removeItem('appStartTime');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.titleService.setTitle('Admin Login');
  }

  ngOnInit() {
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.loginForm = this._fb.group({
      userid: 0,
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    })
  }

  CheckLogin() {

    if (this.loginForm.value["username"] == "") {
      this.showMessage('Please enter username', 'Required', 'error');
      return;
    }
    if (this.loginForm.value["password"] == "") {
      this.showMessage('Please enter password', 'Required', 'error');
      return;
    }
    if (!this.loginForm.valid) {
      return;
    }

    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.UserService.checkAdminUserLogin(this.tokenKey, this.loginForm.value.username, this.loginForm.value.password).subscribe(
      data => {
        if (data["status"] == "Success") {
          this.userModel = data["usersModel"];
          if (this.userModel != null) {

            if (data["usersModel"].listOfProducts.length > 0) {
              sessionStorage.setItem('AdminUserId', this.userModel.userId);
              sessionStorage.setItem('AdminUserType', this.userModel.userTypeId);
              let Tool: string = data["usersModel"].listOfProducts[0].productName.toLowerCase();

              let FirstLastName = this.userModel.firstName + ' ' + this.userModel.lastName;
              if (FirstLastName.trim() != '')
                sessionStorage.setItem('AdminFirstLastName', FirstLastName);
              else
                sessionStorage.setItem('AdminFirstLastName', this.userModel.userName);

              sessionStorage.setItem('AdminCompanyName', this.userModel.companyName);

              sessionStorage.setItem('adminTypeId', this.userModel.adminTypeId.toString());
              debugger
              sessionStorage.setItem('UserLoginTool', Tool);
              let UserAccessibleTools: string = ''
              
              for (let iCounter = 0; iCounter < data["usersModel"].listOfProducts.length; iCounter++) {
                UserAccessibleTools += data["usersModel"].listOfProducts[iCounter].productName + ',';
              }
              sessionStorage.setItem('UserAccessibleTools', UserAccessibleTools.substring(0, UserAccessibleTools.length - 1).toLowerCase());
              let redirectTool = Tool == 'actionitem' ? 'trac-it' : Tool;              
              this.router.navigate(['admin/' + redirectTool + '/dashboard']);
            }
            else {
              this.showMessage("You don't have access to any Tool. Please contact administrator.", 'Error', 'error');
            }
          }
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
      },
      errorCode => {
        this.showMessage(errorCode, 'Error', 'error');
      }
    );

  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
}
