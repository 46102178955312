import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TRFAUpdateGetModel, TRFAUpdateModel } from 'src/Models/TRFAUpdateModel';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TRFAFundGetModel, TRFAFundModel } from 'src/Models/TRFAFundModel';
import { TrfaUpdateMasterService } from 'src/Services/trfa-update--master.service';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofUpdate: TRFAUpdateGetModel[]

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;


  upDowNoteCSS: string = '';
  upDowfundNameCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDowFundTypeCSS: string = '';
  isAscending: boolean = false; 
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

//State List
dropdownListState = [];
selectedItemsState = [];
dropdownSettingsState = {};
listOfStateIds = [];
stateId: string = ' ';
stateList: StateMasterModel[] = [];
//Fund
fundID: string = ' ';
dropdownListFund = [];
selectedItemsFund = [];
dropdownSettingsFund = {};
listOffundIDs = [];
fundList:TRFAFundModel[]=[];
module="TRFA";
stateIds:string=' ';
UpdateId:number = 0;
openMenuIndex: number = -1;

  constructor(private router: Router, private trfaUpdateMaster: TrfaUpdateMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService,private stateService:StateMasterService,private trfaFundMaster:TrfaFundMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofUpdate = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'UpdateDate desc';
    this.Note = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Updates');
    
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsFund = {
      singleSelection: false,
      text: "Filter by Fund",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: true
    };
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.getFund();
    this.isloading = true;
    this.GetUpdate();
  }
  
  getFund() {
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.trfaFundMaster.GetFund(this.tokenKey,this.stateId,true,true).subscribe(
      data => {
        this.dropdownListFund=[]
        this.fundList = data['fund'];
        this.dropdownListFund =data['fund'];
        this.selectedItemsFund = [];
        if (this.fundList.length > 0) {
          let b = [];
          this.selectedItemsFund = [];
          this.fundList.forEach(x => {
            let a = this.listOffundIDs.filter(e => e == x.fundID);
            if (a.length > 0) {
              b.push(x.fundID);
              this.selectedItemsFund.push(x)
            }
          });
          this.listOffundIDs = b;
        }
        else {
          this.selectedItemsFund = [];
          this.listOffundIDs = [];
        }
      },
      error => { }
    );
  }
  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetUpdate();
  }


  GetUpdate() {
    this.isloading = true;
    this.fundID = ' ';
    if (this.listOffundIDs.length > 0) {
      this.fundID = this.listOffundIDs.join(',')
    }
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.Note == "null") this.Note = " ";
    this.trfaUpdateMaster.GetAllUpdate(this.tokenKey, this.PageNumber, this.PageSize, this.fundID,this.stateId, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofUpdate = data['update'];
        if (this.listofUpdate != null) {
          if (this.listofUpdate.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofUpdate[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else
      this.Note = 'null';

    this.resetPageNoandSize();
    this.GetUpdate();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowUpdateDateCSS=''; 
      this.upDowFundTypeCSS=''; 
      if (sortBy === "Note") {
        this.upDowNoteCSS = 'fa fa-arrow-down';
        this.upDowfundNameCSS = '';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "FundName") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "FundTypeName") {
        this.upDowNoteCSS = '';
        this.upDowFundTypeCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "StateName") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowUpdateDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "Note") {
        this.upDowNoteCSS = 'fa fa-arrow-up';
        this.upDowfundNameCSS = '';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "FundName") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "FundTypeName") {
        this.upDowNoteCSS = '';
        this.upDowFundTypeCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "StateName") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowUpdateDateCSS = 'fa fa-arrow-up';
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  addUpdate() {
    let navigation = '/admin/' + this.SelectedTool + '/update/manage';
    this.router.navigate([navigation]);
  }

  updateUpdate(UpdateId) {
    let navigation = '/admin/' + this.SelectedTool + '/update/manage/' + UpdateId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.UpdateId = id;
  }

  deleteUpdate(UpdateId) {
    this.isloading = true;
    this.trfaUpdateMaster.DeleteUpdate(this.tokenKey, UpdateId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          // if (data['message'] != "")
          //   this.showMessage(data['message'], 'Warning', 'warning')
          // else
            this.showMessage("Update Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.GetUpdate();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }


  onSelectFund($event) {
    this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
    this.listOffundIDs.push($event.id);
    // this.stateId = ' ';
    // if (this.listOffundIDs.length > 0) {
    //   this.stateId = this.listOffundIDs.join(',')
    // }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectFund($event) {
    if (this.listOffundIDs != null) {
      if (this.listOffundIDs.length > 0) {
        this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
      }
    }
    if (this.listOffundIDs.length > 0) {
      this.stateId = this.listOffundIDs.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAllFund($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectAllFund($event) {
    this.listOffundIDs = [];
    this.resetPageNoandSize();
    this.GetUpdate();
  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey,this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  resetPageNoandSize()
  {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
