import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { UserListFTPAccessModel } from 'src/Models/UserListFTPAccessModel';
import { Main911Service } from 'src/Services/main911.service';


@Component({
  selector: 'app-ftp-data911',
  templateUrl: './ftp-data911.component.html',
  styleUrls: ['./ftp-data911.component.css']
})
export class FTPData911Component implements OnInit {
  tokenKey: any;
  isShowprogressSpinner:boolean=false;
  isloading:boolean=false;
  SubscriberUserList: UserListFTPAccessModel[];
  PageNumber: number = 1;
  PageSize: number = 30;
  orderBy:string='CompanyName';
  openMenuIndex: number = -1;
  public toolEnum = ToolEnum;

  constructor(private toastr: ToastrService,private main911Service: Main911Service,private titleService:Title) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('911 FTP Data');
   }

  ngOnInit() {
    var AdminIDData= sessionStorage.getItem('AdminUserId');
    this.GetSubscriberUserListForDownload();
  }

  uploadDataonFTP()
  {
    this.isShowprogressSpinner=true;
    this.main911Service.UploadDataonFTP(this.tokenKey,null).subscribe(
      result => {
        this.isShowprogressSpinner=false;
        this.showMessage('Data Upload and Email Send Successfully', 'Success', 'success');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  sendMail(userId)
  {
    this.isShowprogressSpinner=true;
    this.main911Service.UploadDataonFTP(this.tokenKey,userId).subscribe(
      result => {
        this.isShowprogressSpinner=false;
        this.showMessage('Data Upload and Email Send Successfully', 'Success', 'success');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  GetSubscriberUserListForDownload(){
    this.isloading = true;
    this.main911Service.GetSubscriberUserList(this.tokenKey, this.PageNumber, this.PageSize, this.orderBy,ToolEnum.state911.toString()).subscribe(
      data => {
        this.isloading = false;
        this.SubscriberUserList = data['main'];
      },
      error => { }
    );
  }

 showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
