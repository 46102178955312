import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { RouterExtService } from 'src/Services/RouterExtService';
import { CRRAContentGetModel } from 'src/Models/CRRAContentModel';
import { CRRASubCategorysModel } from 'src/Models/CRRASubCategoryModel';
import { CrraContentMasterService } from 'src/Services/crra-content-master.service';
import { CrraCategoryMasterService } from 'src/Services/crra-category-master.service';
import { CrraCarrierMasterService } from 'src/Services/crra-carrier-master.service';
import { CrraSubCategoryMasterService } from 'src/Services/crra-sub-category-master.service';


@Component({
  selector: 'app-crra-contents',
  templateUrl: './crra-contents.component.html',
  styleUrls: ['./crra-contents.component.css']
})
export class CrraContentsComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofContent: CRRAContentGetModel[]

  PageNumber: number;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  filterText: string;

  auditMonth: any;
  listOfMonth1 = [];

  upDowStateNameCSS: string = '';
  upDowEffectiveDateCSS: string = '';
  upDowRuleCiteCSS: string = '';
  upDowRuleHeadingCSS: string = '';

  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];

  //Subcategory

  dropdownListSubCategory = [];
  dropdownSettingsSubCategory = {};
  subCategoryList: CRRASubCategorysModel[] = [];
  selectedItemsSubCategory = [];
  listOfSubCategoryIDs = [];
  subCategoryId: string = ' ';

  //Category
  categoryListMain: any[] = [];
  dropdownDateCategoryFilterMain: any[] = [];
  dropdownSettingsDateCategoryFilterMain = {};
  selectedItemsCategoryMain = [];
  listOfCategoryIDsMain = [];
  categoryId: string = '';

  module = "CRRA";
  stateIds: string = ' ';
  ContentId: number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private crraContentMaster: CrraContentMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService, private stateService: StateMasterService, private crraCarrierMaster: CrraCarrierMasterService,
    private crraCategoryMasterService: CrraCategoryMasterService, private crraSubCategoryMasterService: CrraSubCategoryMasterService,
    private routerService: RouterExtService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofContent = [];

    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'StateName';
    this.filterText = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Contents');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdictions",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsSubCategory = {
      singleSelection: false,
      text: "Filter by SubCategory",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No SubCategory(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateCategoryFilterMain = {
      singleSelection: false,
      text: "Filter by Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.getCategory();
    this.PageNumber = 1;
    if (this.routerService.getPreviousUrl().includes('admin/crra/contents/manage') || this.routerService.getPreviousUrl().includes('admin/crra/contents/history')) {
      this.setSaveFilter();
    }
    else {
      this.clearSession();
      this.GetContent();
    }
  }

  getCategory() {
    this.crraCategoryMasterService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {

          this.categoryListMain = result['crracategory'];
          this.dropdownDateCategoryFilterMain = [];
          this.categoryListMain.forEach(x => {
            this.dropdownDateCategoryFilterMain.push({ "id": x.categoryId, "itemName": x.categoryName })
          });

          if (this.listOfCategoryIDsMain != null && this.listOfCategoryIDsMain != undefined) {
            for (let iCounter = 0; iCounter < this.listOfCategoryIDsMain.length; iCounter++) {
              if (this.listOfCategoryIDsMain[iCounter] != '') {
                let obj = result["crracategory"].filter(m => m.categoryId == this.listOfCategoryIDsMain[iCounter]);
                if (obj != null) {
                  this.selectedItemsCategoryMain.push({ "id": obj[0].categoryId, "itemName": obj[0].categoryName });
                }
              }
            }
          }

        }
      },
      error => { }
    );
  }

  GetSubCategoryByCategoryId(categoryId) {

    this.crraSubCategoryMasterService.GetSubCategoryByCategoryId(this.tokenKey, categoryId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.subCategoryList = result['crrasubcategory'];
          this.dropdownListSubCategory = [];
          if (this.subCategoryList.length > 0) {
            let b = [];
            this.selectedItemsSubCategory = [];
            this.subCategoryList.forEach(x => {
              let a = this.listOfSubCategoryIDs.filter(e => e == x.subCategoryId);
              if (a.length > 0) {
                b.push(x.subCategoryId);
                this.selectedItemsSubCategory.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
              }
              this.dropdownListSubCategory.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
            });
            this.listOfSubCategoryIDs = b;
            if (this.listOfSubCategoryIDs.length > 0) {
              this.subCategoryId = this.listOfSubCategoryIDs.join(',')
              this.GetContent();
            }
          }
          else {
            this.selectedItemsSubCategory = [];
            this.listOfSubCategoryIDs = [];
          }
        }
      }
    );
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetContent();
  }


  GetContent() {
    this.isloading = true;

    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }

    this.categoryId = ' ';
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }

    this.subCategoryId = ' ';
    if (this.listOfSubCategoryIDs.length > 0) {
      this.subCategoryId = this.listOfSubCategoryIDs.join(',')
    }

    if (this.filterText == "null") this.filterText = " ";
    this.crraContentMaster.GetAllContent(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, ' ', this.categoryId, this.subCategoryId, this.OrderBy, null).subscribe(
      data => {
        this.isloading = false;
        this.listofContent = data['crracontent'];
        if (this.listofContent != null) {
          if (this.listofContent.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.filterText = $event.target.value;
    else
      this.filterText = 'null';
    this.resetPageNoandSize();
    this.GetContent();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDowStateNameCSS = '';
      this.upDowEffectiveDateCSS = '';
      this.upDowRuleCiteCSS = '';
      this.upDowRuleHeadingCSS = '';

      if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-down';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "Name") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = 'fa fa-arrow-down';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "RuleNote") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = 'fa fa-arrow-down';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-up';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-up';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "Name") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = 'fa fa-arrow-up';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "RuleNote") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = 'fa fa-arrow-up';
      }
    }

    this.resetPageNoandSize();
    this.GetContent();

  }

  addContent() {
    let navigation = '/admin/' + this.SelectedTool + '/contents/manage';
    this.router.navigate([navigation]);
  }

  ContentContent(ContentId) {
    this.saveSession();
    let navigation = '/admin/' + this.SelectedTool + '/contents/manage/' + ContentId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.ContentId = id;
  }

  deleteContent(ContentId) {
    this.isloading = true;
    this.crraContentMaster.DeleteContent(this.tokenKey, ContentId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.showMessage("Content Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.GetContent();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.resetPageNoandSize();
    this.GetContent();
  }


  onSelectSubCategory($event) {
    this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event.id)
    this.listOfSubCategoryIDs.push($event.id);
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectSubCategory($event) {
    if (this.listOfSubCategoryIDs != null) {
      if (this.listOfSubCategoryIDs.length > 0) {
        this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event.id)
      }
    }
    if (this.listOfSubCategoryIDs.length > 0) {
      this.subCategoryId = this.listOfSubCategoryIDs.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllSubCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllSubCategory($event) {
    this.listOfSubCategoryIDs = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  replaceCommaLine(data) {
    if (data != null) {
      if (data.includes(',')) {
        let dataToArray = data.split(',').map(item => item.trim());
        let list = data.split(",").join("<br />")
        return list
      }
      else {
        return data;
      }
    }
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }

        if (this.listOfStateIds != null && this.listOfStateIds != undefined) {
          for (let iCounter = 0; iCounter < this.listOfStateIds.length; iCounter++) {
            if (this.listOfStateIds[iCounter] != '') {
              let obj = data["state"].filter(m => m.stateId == this.listOfStateIds[iCounter]);
              if (obj != null) {
                this.selectedItemsState.push({ "id": obj[0].stateId, "itemName": obj[0].name });
              }
            }
          }
        }

      },
      error => { }
    );
  }

  onSelectCategoryMain($event) {
    this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event.id)
    this.listOfCategoryIDsMain.push($event.id);
    this.categoryId = ' ';
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }
    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onDeSelectCategoryMain($event) {
    this.categoryId=' '
    if (this.listOfCategoryIDsMain != null) {
      if (this.listOfCategoryIDsMain.length > 0) {
        this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event.id)
      }
    }
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }
    if (this.listOfCategoryIDsMain.length == 0) {
      this.dropdownListSubCategory = [];
      this.selectedItemsSubCategory = [];
      this.listOfSubCategoryIDs = [];
    }
    else
      this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onSelectAllCategoryMain($event) {
    
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }
    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onDeSelectAllCategoryMain($event) {
    this.categoryId=' '
    this.listOfCategoryIDsMain = [];
    this.listOfSubCategoryIDs = [];
    this.selectedItemsSubCategory = [];
    this.dropdownListSubCategory = [];
    this.selectedItemsCategoryMain = []
    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  clear() {
    this.stateId = '';
    this.subCategoryId = '';
    this.categoryId = '';

    this.selectedItemsState = [];
    this.selectedItemsSubCategory = [];
    this.selectedItemsCategoryMain = [];

    this.listOfStateIds = [];
    this.listOfSubCategoryIDs = [];
    this.listOfCategoryIDsMain = [];

    this.GetContent();
  }

  onFilterSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllCategory($event) {
    this.categoryId = ' ';
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }
    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onFilterDeSelectAllCategory($event) {
    this.categoryId = ' ';
    if ($event.length > 0) {
      for (let iCounter = 0; iCounter < $event.length; iCounter++) {
        if (this.listOfCategoryIDsMain != null) {
          if (this.listOfCategoryIDsMain.length > 0) {
            this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          }
        }
      }
    } else {
      this.dropdownListSubCategory = [];
      this.listOfSubCategoryIDs = [];
      this.selectedItemsSubCategory = [];
      this.listOfCategoryIDsMain = [];
      this.selectedItemsCategoryMain = [];
    }
    if (this.listOfStateIds.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
      this.GetSubCategoryByCategoryId(this.categoryId);
    }
    else {
      this.dropdownListSubCategory = [];
      this.listOfSubCategoryIDs = [];
      this.selectedItemsSubCategory = [];
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllSubCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllSubCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  ViewHistory(ContentId) {
    this.saveSession();
    let navigation = '/admin/' + this.SelectedTool + '/contents/history/manage/' + ContentId;
    this.router.navigate([navigation]);
  }

  saveSession() {
    sessionStorage.setItem('ContentSaveFilterPageNo', this.PageNumber.toString());
    sessionStorage.setItem('ContentSaveFilterOrderBy', this.OrderBy);
    sessionStorage.setItem('ContentSaveFilterIsAscending', this.isAscending ? 'true' : 'false');

    sessionStorage.setItem('ContentSaveFilterSubCategoryId', JSON.stringify(this.listOfSubCategoryIDs));
    sessionStorage.setItem('ContentSaveFilterStateId', JSON.stringify(this.listOfStateIds));
    sessionStorage.setItem('ContentSaveFilterCategoryId', JSON.stringify(this.listOfCategoryIDsMain));
  }

  clearSession() {
    sessionStorage.removeItem('ContentSaveFilterPageNo');
    sessionStorage.removeItem('ContentSaveFilterOrderBy');
    sessionStorage.removeItem('ContentSaveFilterIsAscending');
    sessionStorage.removeItem('ContentSaveFilterSubCategoryId');
    sessionStorage.removeItem('ContentSaveFilterStateId');
    sessionStorage.removeItem('ContentSaveFilterCategoryId');
  }

  setSaveFilter() {
    if (sessionStorage.getItem('ContentSaveFilterPageNo') != null && sessionStorage.getItem('ContentSaveFilterPageNo') != '' && sessionStorage.getItem('ContentSaveFilterPageNo') != 'undefined') {
      this.PageNumber = parseInt(sessionStorage.getItem('ContentSaveFilterPageNo'));
    }
    if (sessionStorage.getItem('ContentSaveFilterOrderBy') != null && sessionStorage.getItem('ContentSaveFilterOrderBy') != '' && sessionStorage.getItem('ContentSaveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('ContentSaveFilterOrderBy');
    }
    if (sessionStorage.getItem('ContentSaveFilterIsAscending') != null && sessionStorage.getItem('ContentSaveFilterIsAscending') != '' && sessionStorage.getItem('ContentSaveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('ContentSaveFilterIsAscending') == 'false' ? false : true;
      this.ContentSessionSort(this.OrderBy);
    }

    if (sessionStorage.getItem('ContentSaveFilterStateId') != null && sessionStorage.getItem('ContentSaveFilterStateId') != '' && sessionStorage.getItem('ContentSaveFilterStateId') != 'undefined') {
      this.listOfStateIds = JSON.parse(sessionStorage.getItem('ContentSaveFilterStateId'));
    }
    if (sessionStorage.getItem('ContentSaveFilterCategoryId') != null && sessionStorage.getItem('ContentSaveFilterCategoryId') != '' && sessionStorage.getItem('ContentSaveFilterCategoryId') != 'undefined') {
      this.listOfCategoryIDsMain = JSON.parse(sessionStorage.getItem('ContentSaveFilterCategoryId'));
    }

    if (sessionStorage.getItem('ContentSaveFilterSubCategoryId') != null && sessionStorage.getItem('ContentSaveFilterSubCategoryId') != '' && sessionStorage.getItem('ContentSaveFilterSubCategoryId') != 'undefined') {
      this.listOfSubCategoryIDs = JSON.parse(sessionStorage.getItem('ContentSaveFilterSubCategoryId'));
      this.categoryId = ' ';
      if (this.listOfCategoryIDsMain.length > 0) {
        this.categoryId = this.listOfCategoryIDsMain.join(',')
      }
      this.GetSubCategoryByCategoryId(this.categoryId);
    }

    this.GetContent();
    this.clearSession();
  }

  ContentSessionSort(sortBy: string) {

    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy;

      this.upDowStateNameCSS = '';
      this.upDowEffectiveDateCSS = '';
      this.upDowRuleCiteCSS = '';
      this.upDowRuleHeadingCSS = '';

      if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-down';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "Name") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = 'fa fa-arrow-down';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "RuleNote") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = 'fa fa-arrow-down';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-up';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-up';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "Name") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = 'fa fa-arrow-up';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "RuleNote") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = 'fa fa-arrow-up';
      }
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
