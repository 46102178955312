import { Component, OnInit } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { I360Service } from 'src/Services/i360Service';
import { Title } from '@angular/platform-browser';
import { CheckAuth } from 'src/Services/utility.service';
import { UserFolderModel, UserLibraryModel } from 'src/Models/UserLibraryModel';
import { ViewChild } from '@angular/core';
import { FilterModel } from 'src/Models/filterModel';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReminderModel } from 'src/Models/ReminderModel';
import { LibraryReminderModel } from 'src/Models/LibraryReminderModel';
import { ContextMenu } from 'primeng/contextmenu';
import { UserPermissionModal } from 'src/Models/userPermissionModel';
import { UserPemissionService } from 'src/Services/userpemission.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';

@Component({
  selector: 'app-user-library',
  templateUrl: './user-library.component.html',
  styleUrls: ['./user-library.component.css']
})
export class UserLibraryComponent implements OnInit {
  filterModel = new FilterModel();
  userLibraryModel = new UserLibraryModel();
  listOfChild: UserLibraryModel[];
  listOfFilesFolders: TreeNode[];

  rightPanelContextItems: MenuItem[];
  selectedFile: TreeNode;

  lvl2SelectedFile: TreeNode;
  lvl3SelectedFile: TreeNode;

  companyId: number = 0;
  tokenKey: string = '';
  loginUser: number = 0;
  productId: number = 17;
  parentId: number = -1;
  currentNodeId: any = '';
  tempParentID: number = 0;
  folderName: string = '';
  FolderTitleName: string = 'Explorer';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  childFilterModel = new FilterModel();
  selectedNode: TreeNode;
  isDragDrop: boolean = false;
  isFileChoose: boolean = false;
  selectAllState:boolean=false;

  @ViewChild('fileUploadDoc', { static: true }) fileUploadDoc;
  @ViewChild('deleteConfirmation', { static: true }) deleteConfirmation;
  @ViewChild('modelCancelButton', { static: true }) modelCancelButton;
  @ViewChild('openAddFolderModelDialog', { static: true }) openAddFolderModelDialog;
  @ViewChild('reminderConfirmation', { static: true }) reminderConfirmation;
  @ViewChild('closeReminderConfirmation', { static: true }) closeReminderConfirmation;
  @ViewChild('addUpdateCloseReminder', { static: true }) addUpdateCloseReminder;
  @ViewChild('fileUploadRename', { static: true }) fileUploadRename;
  @ViewChild("cmMenu", { static: true }) cmMenu: ContextMenu;


  isErrorOnDoc: boolean;
  isClicktoFolderOpen = false;
  deleteId: number = 0;
  reminderType: string = 'oneTime';
  selectedlevel: string = '';

  dropdownListReminderOption = [];
  selectedItemsReminderOption = [];
  dropdownSettingsReminderOption = {};
  listOfReminderOptionID = [];

  bsDateValue: any;
  isSetReminder: boolean = false;
  stateDivVisible:boolean = true;

  headingCss = {
    'background': 'none',
  };

  reminderForm: FormGroup;
  reminderModel = new ReminderModel();
  currentNode: TreeNode;
  currentLabel: string = '';

  totalRecordCount: number = -1;
  isDataLoad: boolean = false;
  currentPage: number = 1;

  upDownNameCSS: string = '';
  upDownFileTypeCSS: string = '';
  upDownCreatedTimeCSS: string = '';
  upDownModifiedTimeCSS: string = '';
  upDownfileSizeCSS: string = '';
  isAscending: boolean = false;
  pageTitle: string = '';
  totalFileCount: number = 0;
  folderSize: number = 0;
  OrderBy: string = '';
  keyword: string = '';
  libraryId: number = 0;
  libraryReminderId: number = 0;
  libraryReminderModel = new LibraryReminderModel();
  isRenameClick: boolean = false;
  items: MenuItem[];
  renameFolderFlag = false;
  deleteFolderFlag = false;
  userPermission = new UserPermissionModal();

  pageTitleSub: string = '';
  files: any[] = [];
  stateList: StateMasterModel[] = [];


  userFolderModel = new UserFolderModel();

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private titleService: Title,
    private i360Service: I360Service, private toastr: ToastrService, private userPermissionService: UserPemissionService,
    private appActivity: ApplicationActivityService, private PricingQuotesMaster: PricingQuotesService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginUser = +sessionStorage.getItem('UserId');
    this.productId = +sessionStorage.getItem('productId');
    this.companyId = +sessionStorage.getItem('CompanyId');
    this.userLibraryModel.modifiedBy = this.loginUser;
    this.userLibraryModel.createdBy = this.loginUser;
    this.userLibraryModel.productId = this.productId;
    this.userLibraryModel.userId = this.loginUser;
    this.userLibraryModel.companyId = this.companyId;
    this.setReminderModelPopupValues();
    this.reminderForm = this._fb.group({
      isSetReminder: [''],
      reminderOption: [''],
      reminederDate: this.bsDateValue,
      libraryId: 0,
      libraryReminderId: 0,
      name: ['']
    });
    this.childFilterModel.pageNumber = '1';
    this.childFilterModel.pageSize = '10';
    this.childFilterModel.orderBy = 'ISNULL(ModifiedTime,CreatedTime) desc';
    this.pageTitle = sessionStorage.getItem('displayName');
    if (this.pageTitle == 'Secretary of States Authorization') {
      this.pageTitleSub = 'States Authorization';
      this.pageTitle = this.pageTitle.replace(this.pageTitleSub, '');
    }
    else {
      let arr = this.pageTitle.split(' ');
      if(arr.length==2){
        this.pageTitleSub = arr[1];
      }
      else{
      this.pageTitleSub = arr[1] +' '+ arr[2];
      }
      this.pageTitle = this.pageTitle.replace(this.pageTitleSub, '');      
    }

    this.titleService.setTitle(sessionStorage.getItem('displayName') + ' | Inteserra - i360');
  }

  setReminderModelPopupValues() {
    this.dropdownListReminderOption = [
      { 'itemName': 'Before 2 months', 'id': 1 },
      { 'itemName': 'Before 1 month', 'id': 2 },
      { 'itemName': 'Before 2 weeks', 'id': 3 },
      { 'itemName': 'Before 1 day', 'id': 4 }
    ];

    this.setDropDownSetting();
    this.bsDateValue = new Date();
  }

  pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.GetUserLibraryFindParentId();
    this.getUserPermissionForProduct();
  }

  getUserPermissionForProduct() {
    this.userPermissionService.i360UserPermissionByUserIdProductId(this.tokenKey, this.loginUser, this.productId)
      .subscribe(data => {
        if (data) {
          if (data.status = "Success")
            this.userPermission = data.userPermission;
          const renameper = { label: 'Rename', icon: 'pi pi-fw pi-pencil', command: (event) => this.renameFolderEvent() };
          const deleteper = { label: 'Delete', icon: 'pi pi-fw pi-trash', command: (event) => this.deleteFolderEvent() };
          this.rightPanelContextItems = [];
          if (this.userPermission.addEdit) {
            this.rightPanelContextItems.push(renameper);
          }
          if (this.userPermission.delete) {
            this.rightPanelContextItems.push(deleteper);
          }
        }
      })
  }

  renameFolderEvent() {
    if (!this.selectedNode)
      return;
    this.openAddFolderModelDialog.nativeElement.click();
    this.folderName = this.selectedNode.label;
    this.renameFolderFlag = true;
    this.applicationActivity('Rename', this.pageTitle + this.pageTitleSub + ' - Folder', this.productId, '', this.productId);
  }

  deleteFolderEvent() {
    if (!this.selectedNode)
      return;
    this.deleteConfirmation.nativeElement.click();
    this.deleteFolderFlag = true;
    this.applicationActivity('Delete', this.pageTitle + this.pageTitleSub + ' - Folder', this.productId, '', this.productId);
  }

  public nodeMenu(event: MouseEvent, node: TreeNode) {
    event.stopPropagation();
    console.log(node);
    this.selectedNode = node;
    this.cmMenu.show(event);
    return false;

  }

  GetUserLibraryFindParentId() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.i360Service.i360UserLibraryFindParentId(this.tokenKey, this.productId, this.loginUser, this.parentId)
      .subscribe(files => {
        this.listOfFilesFolders = files['listTreeModel'];
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
      },
      error => { 

        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
      }
    );
  }

  i360UserLibraryFindChild() {
    this.isDataLoad = true;
    this.currentNodeId = this.childFilterModel.level;
    this.isClicktoFolderOpen = this.childFilterModel.isClicktoFolderOpen;
    this.listOfChild = [];
    if (this.childFilterModel.isClicktoFolderOpen)
      this.userLibraryModel.parentId = this.childFilterModel.parentId;

    this.i360Service.i360UserLibraryFindChild(this.tokenKey, this.childFilterModel)
      .subscribe(data => {
        this.isDataLoad = false;
        this.listOfChild = data['listOfChild'];
        if (this.listOfChild != null) {
          if (this.listOfChild.length > 0) {
            this.totalRecordCount = this.listOfChild[0].totalRecordCount;
            this.totalFileCount = this.listOfChild[0].totalRecordCount;
            this.folderSize = this.listOfChild[0].folderSize;
          }
          else {
            this.totalRecordCount = 0
            this.totalFileCount = 0;
            this.folderSize = 0;
          }
        }
        else {
          this.totalRecordCount = 0
          this.totalFileCount = 0;
          this.folderSize = 0;
        }

        this.lvl2SelectedFile = null;
        this.lvl3SelectedFile = null;

        // if (this.lvl3SelectedFile != null) {
        //   this.selectedFile = this.lvl3SelectedFile;
        // }
        // else if (this.lvl2SelectedFile != null) {
        //   this.selectedFile = this.lvl2SelectedFile;
        // }
      },
        error => {
          this.isDataLoad = false;
          console.log(error);
        }
      );
  }
  expandAll() {
    this.listOfFilesFolders.forEach(node => {
      this.expandRecursive(node, true);
    });
  }


  private expandParentRecursive(node: TreeNode) {
    if ((node != null || node != undefined) && (node.parent != null || node.parent != undefined)) {
      node.parent.expanded = true;
      this.expandParentRecursive(node.parent);
    }
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {

        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  nodeSelect(event) {

    this.selectedlevel = event.node.level;
    this.collapseMiddleLevel(event);
    this.currentNode = event.node;
    event.node.expanded = true;
    this.currentNodeId = event.node.key;
    this.userLibraryModel.level = event.node.key == -1 ? 0 : event.node.key;
    this.userLibraryModel.parentId = event.node.data;
    this.tempParentID = event.node.data;
    this.currentLabel = event.node.label;
    this.childFilterModel.userID = this.loginUser.toString();
    this.childFilterModel.parentId = event.node.data.toString();
    this.childFilterModel.productId = this.productId;
    this.childFilterModel.level = event.node.key;
    this.childFilterModel.isClicktoFolderOpen = false;
    this.childFilterModel.pageNumber = '1';
    this.i360UserLibraryFindChild();
  }

  onNodeExpand(event) {
    this.collapseMiddleLevel(event);
    event.node.expanded = true;
  }

  collapseMiddleLevel(event) {
    if (event.node.key == 0) {
      this.listOfFilesFolders.forEach(node => {
        if (node.children) {
          node.children.forEach(node1 => {
            if (node1.key == "0")
              node1.expanded = false;
          });
        }
      });
    }
  }

  nodeUnselect(event) {
    //this.showMessage('detail:' + event.node.label, 'success', 'success');
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  getDeletedId($event, myId, level) {
    this.deleteId = myId;
    this.applicationActivity('Delete', this.pageTitle + this.pageTitleSub + ' - File', this.productId, '', this.productId);
  }

  opneDeleteConirmationModel() {

    this.deleteConfirmation.nativeElement.click();
  }

  i360UserLibraryDelete(deleteId) {
    if (!this.deleteFolderFlag) {
      this.i360Service.i360UserLibraryDelete(this.tokenKey, deleteId).subscribe(
        data => {
          if (data != null) {
            if (data['deleteId'] > 0) {
              this.showMessage('Item Deleted Successfully', 'Deleted', 'success');
              this.deleteId = 0;
              this.GetUserLibraryFindParentId();
              this.childFilterModel.productId = this.userLibraryModel.productId;
              this.childFilterModel.userID = this.loginUser.toString();
              this.childFilterModel.parentId = this.tempParentID.toString();
              this.childFilterModel.isClicktoFolderOpen = false;
              this.childFilterModel.level = this.currentNodeId;
              this.i360UserLibraryFindChild();
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    }
    else {
      this.deleteFolder();
    }
  }

  i360UserLibraryDeleteCancel() {
    this.deleteId = 0;
    this.GetUserLibraryFindParentId();
    this.childFilterModel.productId = this.userLibraryModel.productId;
    this.childFilterModel.userID = this.loginUser.toString();
    this.childFilterModel.parentId = this.tempParentID.toString();
    this.childFilterModel.isClicktoFolderOpen = false;
    this.childFilterModel.level = this.currentNodeId;
    this.i360UserLibraryFindChild();
  }

  openAddFolderModel(id) {
    this.stateDivVisible = false;
    this.applicationActivity('Rename', this.pageTitle + this.pageTitleSub + ' - File', this.productId, '', id);
    this.isRenameClick = true;
    this.clearFolderFileName(this.isRenameClick);
    this.deleteId = id;
    this.openAddFolderModelDialog.nativeElement.click();
    this.i360Service.i360UserLibraryFindByLibraryId(this.tokenKey, this.deleteId).subscribe(
      data => {
        if (data != null) {
          if (data['libraryData'] != null) {
            this.folderName = data['libraryData'].name;
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  unselectFile() {
    this.selectedFile = null;
  }

  saveUserLibrary(isFileUpload) {

    if (this.selectedlevel == "5" && !isFileUpload && !this.isRenameClick) {
      this.showMessage('You have selected an invalid Folder! Select Only 5 Level Folder!', 'Error', 'error');
      return;
    }

    let formData = new FormData();

    // if (this.folderName == '')
    if (this.files.length > 0)
      formData.append('fileUploadDoc', this.files[0]);
    else if (this.fileUploadDoc.nativeElement.files.length > 0)
      formData.append('fileUploadDoc', this.fileUploadDoc.nativeElement.files[0]);
    else
      formData.append('fileUploadDoc', null);

    if (this.files.length > 0)
      formData.append('fileUploadRename', this.files[0]);
    else if (this.fileUploadRename.nativeElement.files.length > 0)
      formData.append('fileUploadRename', this.fileUploadRename.nativeElement.files[0]);
    else
      formData.append('fileUploadRename', null);

    formData.append('companyId', this.userLibraryModel.companyId);
    formData.append('createdBy', this.userLibraryModel.createdBy);
    formData.append('fileName', this.userLibraryModel.fileName);
    formData.append('fileType', this.userLibraryModel.fileType);
    formData.append('isFolder', this.userLibraryModel.isFolder);
    formData.append('modifiedBy', this.userLibraryModel.modifiedBy);
    formData.append('level', this.userLibraryModel.level);
    formData.append('libraryId', this.userLibraryModel.libraryId);
    formData.append('name', this.userLibraryModel.name);
    formData.append('parentId', this.userLibraryModel.parentId);
    formData.append('productId', this.userLibraryModel.productId);
    formData.append('userId', this.userLibraryModel.userId);
    if (isFileUpload) {
      this.i360Service.i360UserLibraryAdd(this.tokenKey, formData).subscribe(
        data => {
          if (data != null) {
            if (data['libraryId'] > 0) {
              this.fileUploadDoc.nativeElement.value = '';
              this.fileUploadRename.nativeElement.value = '';
              if (isFileUpload) {
                this.reminderModel.libraryId = data['libraryId'];
                this.i360Service.i360UserLibraryReminderAddUpdate(this.tokenKey, this.reminderModel).subscribe(
                  reminderData => {
                    this.showMessage('File Uploaded Successfully', 'Upload', 'success')
                    this.closeReminderConfirmation.nativeElement.click();
                  },
                  error => {
                    console.log(error);
                  }
                );
              }
              else {
                if (this.deleteId == 0)
                  this.showMessage('Folder Created Successfully', 'Created', 'success')
                else
                  this.showMessage('Folder/File Rename Successfully', 'Rename', 'success')
              }

              this.userLibraryModel.name = '';
              this.userLibraryModel.fileName = '';
              this.userLibraryModel.fileType = '';
              this.deleteId = 0;
              if (this.folderName != '') {
                this.modelCancelButton.nativeElement.click();
              }
              this.folderName = '';
              this.GetUserLibraryFindParentId();
              this.childFilterModel.userID = this.loginUser.toString();
              this.childFilterModel.parentId = this.userLibraryModel.parentId.toString();
              this.childFilterModel.productId = this.userLibraryModel.productId;
              this.childFilterModel.level = this.currentNodeId;
              this.childFilterModel.isClicktoFolderOpen = true;
              this.i360UserLibraryFindChild();
              this.isRenameClick = false;
              this.clearFolderFileName(this.isRenameClick);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    } 
    else {

      if (this.selectedlevel == "5" && !isFileUpload && !this.isRenameClick) {
        this.showMessage('You have selected an invalid Folder! Select Only 5 Level Folder!', 'Error', 'error');
        return;
      }

      this.userFolderModel.companyId = this.userLibraryModel.companyId;
      this.userFolderModel.createdBy = this.userLibraryModel.createdBy;
      this.userFolderModel.isFolder = this.userLibraryModel.isFolder;
      this.userFolderModel.modifiedBy = this.userLibraryModel.modifiedBy;
      this.userFolderModel.level = this.userLibraryModel.level;
      this.userFolderModel.libraryId = this.userLibraryModel.libraryId;
      this.userFolderModel.name = this.userLibraryModel.name;
      this.userFolderModel.productId = this.userLibraryModel.productId;
      this.userFolderModel.userId = this.userLibraryModel.userId;
      this.userFolderModel.stateListData=this.stateList;
      this.userFolderModel.parentId=this.userLibraryModel.parentId
      
      this.i360Service.i360UserFolderAdd(this.tokenKey, this.userFolderModel).subscribe(
        data => {
          if (data != null) {
            if (data['libraryId'] > 0) {
              this.userLibraryModel.name = '';
              this.userLibraryModel.fileName = '';
              this.userLibraryModel.fileType = '';
              this.deleteId = 0;
              // if (this.folderName != '') {
              //   this.modelCancelButton.nativeElement.click();
              // }
              this.modelCancelButton.nativeElement.click();
              this.folderName = '';
              this.GetUserLibraryFindParentId();
              this.childFilterModel.userID = this.loginUser.toString();
              this.childFilterModel.parentId = this.userLibraryModel.parentId.toString();
              this.childFilterModel.productId = this.userLibraryModel.productId;
              this.childFilterModel.level = this.currentNodeId;
              this.childFilterModel.isClicktoFolderOpen = true;
              this.i360UserLibraryFindChild();
              this.isRenameClick = false;
              this.clearFolderFileName(this.isRenameClick);
                if (this.deleteId == 0)
                  this.showMessage('Folder Created Successfully', 'Created', 'success')
                else
                  this.showMessage('Folder/File Rename Successfully', 'Rename', 'success')
            }
          }
        },
        error => {
          console.log(error);
        }
      );
      
    }
  }

  fileEvent(event): any {
    //*-- this function gets content of uploaded file and validation -- * /    
    var file = event.target.files[0];

    //this.deleteId = 0;
    //this.folderName = '';

    //this.uploadOwnDocumentModel.fileName = file.name;    
    if (file != null) {
      let size = Math.round(file.size / 1024);
      if (size <= 21000) {
        //   ["gif", "jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx", "JPG", "JPEG", "PNG", "GIF", "PDF", "DOC", "DOCX", "XLS", "XLSX"];
        var allowedExtensions = ["doc", "DOC", "docx", "DOCX", "pdf", "PDF", "jpg", "JPG", "jpeg", "JPEG", "png", "PNG",];
        let fileType = file.name.split('.').pop();

        if (!this.isInArray(allowedExtensions, fileType)) {
          this.showMessage('You have selected an invalid file type.System supports following file formats: PDF, DOC, DOCX, JPG, JPEG, PNG.', 'Error', 'error');
          this.isErrorOnDoc = true
          window.scrollTo(0, 0);
        }
        else {
          let fileType = file.name.split('.').pop();
          this.userLibraryModel.fileType = fileType.toLowerCase();
          this.userLibraryModel.fileName = file.name;
          this.userLibraryModel.isFolder = false;
          this.userLibraryModel.libraryId = this.deleteId;
          this.isErrorOnDoc = false;
          //this.saveUserLibrary(true);
        } this.files.push(file);
      }
      else {
        this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
        this.isErrorOnDoc = true;
        window.scrollTo(0, 0);
      }
    }
  }
  /*- checks if word exists in array -*/
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  saveFolder() {
    
    if (this.folderName.trim() == '' || this.folderName == undefined || this.folderName == null) {
      if(this.stateList.filter(x=>x.isSelected==true).length==0)
      {
      this.showMessage('Please enter folder name or Select any of the State.', 'Error', 'error');
      return;
      }
    }
    this.userLibraryModel.name = this.folderName;
    this.userLibraryModel.isFolder = true;
    this.userLibraryModel.fileName = '';
    this.userLibraryModel.fileType = '';
    this.userLibraryModel.libraryId = this.deleteId;
    if (this.currentNodeId == -1)
      this.userLibraryModel.level = 0
    else
      this.userLibraryModel.level += 1;

    if (this.renameFolderFlag)
      this.updateFolderName();
    else
      this.saveUserLibrary(false);
  }

  updateFolderName() {
    if (this.folderName.trim() == '' || this.folderName == undefined || this.folderName == null) {
      this.showMessage('Please enter folder name.', 'Error', 'error');
      return;
    }

    this.userLibraryModel.name = this.folderName;
    this.userLibraryModel.libraryId = parseInt(this.selectedNode.data);
    this.userLibraryModel.createdBy = this.loginUser;
    this.i360Service.i360UserLibraryFolderUpdate(this.tokenKey, this.userLibraryModel).subscribe(
      data => {
        if (data.status == "Success") {
          this.showMessage('Folder rename Successfully', 'Rename', 'success')
          this.selectedNode = undefined;
          this.renameFolderFlag = false;
          this.GetUserLibraryFindParentId();
          this.modelCancelButton.nativeElement.click();
        }
        else {
          this.showMessage(data.message, 'Error', 'error')
        }
      });
  }

  deleteFolder() {
    this.i360Service.i360UserLibraryFolderDelete(this.tokenKey, parseInt(this.selectedNode.data)).subscribe(
      data => {
        if (data.status == "Success") {
          this.showMessage('Folder deleted Successfully', 'Delete', 'success')
          this.selectedNode = undefined;
          this.deleteFolderFlag = false;
          this.GetUserLibraryFindParentId();
          this.modelCancelButton.nativeElement.click();
        }
        else if (data.status == "FolderNotEmpty") {
          this.showMessage('You can not delete the folder as it not empty.', 'Error', 'error')
        }
        else {
          this.showMessage(data.message, 'Error', 'error')
        }
      });
  }

  DownloadFileForLibrary(userId, fileName, name) {
    this.applicationActivity('Download', this.pageTitle + this.pageTitleSub + ' - File', this.productId, fileName, this.productId);
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.filterModel.userID = userId;
    this.filterModel.docPath = fileName;
    this.i360Service.DownloadFileForLibrary(this.tokenKey, this.filterModel).subscribe(
      fileData => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        if (fileData != null) {
          FileSaver.saveAs(fileData, name);
          this.showMessage('File download successfully', 'File Downloaded', 'success');
        }
      }, error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        console.log(error);
      }
    );
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectedReminderOption($event) {
    this.listOfReminderOptionID = [];
    if (this.listOfReminderOptionID != null) {
      if (this.listOfReminderOptionID.length > 0) {
        this.listOfReminderOptionID = this.removeElemetninArray(this.listOfReminderOptionID, $event.itemName)
        this.listOfReminderOptionID.push($event.itemName);
      }
      else {
        this.listOfReminderOptionID.push($event.itemName);
      }
    }
  }

  onSelectAllReminderOption($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfReminderOptionID != null) {
        if (this.listOfReminderOptionID.length > 0) {
          this.listOfReminderOptionID = this.removeElemetninArray(this.listOfReminderOptionID, $event[iCounter].itemName)
          this.listOfReminderOptionID.push($event[iCounter].itemName);
        }
        else {
          this.listOfReminderOptionID.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllReminderOption($event) {
    this.listOfReminderOptionID = [];
  }

  onDeSelectedReminderOption($event) {
    if (this.listOfReminderOptionID != null) {
      if (this.listOfReminderOptionID.length > 0) {
        this.listOfReminderOptionID = this.removeElemetninArray(this.listOfReminderOptionID, $event.itemName)
      }
    }
  }

  onValueChange(dt: Date) {
    let dd = dt.getDate().toString();
    if (parseInt(dd.toString()) < 10)
      dd = '0' + dd.toString();

    let mm = (dt.getMonth() + 1).toString();
    if (parseInt(mm.toString()) < 10)
      mm = '0' + mm.toString();

    let hh = (dt.getHours()).toString();
    if (parseInt(hh.toString()) < 10)
      hh = '0' + hh.toString();

    let min = (dt.getMinutes()).toString();
    if (parseInt(min.toString()) < 10)
      min = '0' + min.toString();

    return dt.getFullYear() + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':00';
  }

  saveReminder(reminderForm: any) {
    this.reminderModel = reminderForm.value;

    if (this.isErrorOnDoc) {
      return;
    }

    if (reminderForm.value['name'] == null || typeof (reminderForm.value['name']) == 'undefined') {
      this.toastr.error('Please enter file name', 'Error');
      return;
    }

    if (reminderForm.value['name'].length > 0 && reminderForm.value['name'][0].trim() == '') {
      this.toastr.error('Please enter file name', 'Error');
      return;
    }

    if (this.files.length == 0) {
      if (this.fileUploadDoc.nativeElement.files.length == 0) {
        this.toastr.error('Please select file', 'Error');
        return;
      }
    }
    if (this.isSetReminder) {
      if (this.listOfReminderOptionID.length == 0) {
        this.toastr.error('Please select reminder option.', 'Error');
        return;
      }
      this.reminderModel.userId = this.loginUser;
      this.reminderModel.reminderOption = this.listOfReminderOptionID.join(',');
      this.reminderModel.monthsName = '';
      this.reminderModel.weekName = '';
      this.reminderModel.isSetReminder = this.isSetReminder;

      let reminderDate = new Date(reminderForm.controls.reminederDate.value);
      let dt = new Date(reminderDate.getFullYear(), reminderDate.getMonth(), reminderDate.getDate(), 0, 0, 0, 0);
      this.reminderModel.reminederDate = this.onValueChange(dt);
    }
    else
      this.reminderModel.isSetReminder = this.isSetReminder;

    this.userLibraryModel.name = reminderForm.value['name'];

    this.saveUserLibrary(true);
  }

  setToEnableDropdown(libraryId, libraryReminderId, isAdd) {
    this.applicationActivity('Reminder', this.pageTitle + this.pageTitleSub + ' - File', this.productId, '', libraryReminderId);
    this.libraryId = libraryId;
    this.libraryReminderId = libraryReminderId;
    this.isSetReminder = true;
    this.setDropDownSetting();

    if (libraryReminderId > 0) {
      this.i360Service.i360LibraryReminderFindByLibraryReminderId(this.tokenKey, libraryReminderId).subscribe(
        reminderData => {
          this.libraryReminderModel = reminderData['libraryReminder'];
          if (this.libraryReminderModel != null) {
            this.reminderForm.setValue({
              isSetReminder: this.isSetReminder,
              reminderOption: [],
              reminederDate: '',
              libraryId: this.libraryReminderModel.libraryId,
              libraryReminderId: this.libraryReminderModel.libraryReminderId,
              name: this.libraryReminderModel.name,
            });
            let listOfOption = this.dropdownListReminderOption.filter(m => m.itemName == this.libraryReminderModel.reminderType);
            this.selectedItemsReminderOption = [];
            this.listOfReminderOptionID = [];
            if (listOfOption != null) {
              if (listOfOption.length > 0) {
                this.selectedItemsReminderOption.push({ 'itemName': listOfOption[0].itemName, 'id': listOfOption[0].id })
                this.listOfReminderOptionID.push(listOfOption[0].itemName);
              }
            }
            this.bsDateValue = new Date(this.libraryReminderModel.reminderDate);
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  updateReminder(reminderForm) {
    if (this.listOfReminderOptionID.length == 0) {
      this.toastr.error('Please select reminder option.', 'Error');
      return;
    }

    this.reminderModel.userId = this.loginUser;
    this.reminderModel.reminderOption = this.listOfReminderOptionID.join(',');
    this.reminderModel.monthsName = '';
    this.reminderModel.weekName = '';
    this.reminderModel.isSetReminder = this.isSetReminder;
    this.reminderModel.libraryId = this.libraryId;
    this.reminderModel.libraryReminderId = this.libraryReminderId;

    let reminderDate = new Date(reminderForm.controls.reminederDate.value);
    let dt = new Date(reminderDate.getFullYear(), reminderDate.getMonth(), reminderDate.getDate(), 0, 0, 0, 0);
    this.reminderModel.reminederDate = this.onValueChange(dt);

    this.i360Service.i360UserLibraryReminderAddUpdate(this.tokenKey, this.reminderModel).subscribe(
      reminderData => {
        if (reminderData != null) {
          if (reminderData['libraryId'] > 0) {
            this.showMessage('Reminder uploaded successfully', 'Update', 'success')
            this.libraryId = 0;
            this.libraryReminderId = 0;
            this.addUpdateCloseReminder.nativeElement.click();
            this.selectedItemsReminderOption = [];
            this.bsDateValue = new Date();
            if (this.reminderModel.libraryReminderId == 0) {
              this.i360UserLibraryFindChild();
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  resetReminder() {
    this.selectedItemsReminderOption = [];
    this.bsDateValue = new Date();
    this.libraryId = 0;
    this.libraryReminderId = 0;
  }

  formatDate(date) {
    this.isSetReminder = false;
    var d = new Date(date);
    var hh = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    var dd = "AM";
    var h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h == 0) {
      h = 12;
    }
    this.reminderForm.setValue({
      isSetReminder: this.isSetReminder,
      reminderOption: [''],
      reminederDate: this.bsDateValue,
      libraryId: 0,
      libraryReminderId: 0,
      name: ['']
    });
    this.setDropDownSetting();
  }

  enableDisable($event) {
    this.setDropDownSetting();
  }

  setDropDownSetting() {
    this.dropdownSettingsReminderOption = {
      singleSelection: true,
      text: 'Select Option',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No option available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      disabled: this.isSetReminder ? false : true
    };
  }

  pageChanged($event: any) {
    this.childFilterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.i360UserLibraryFindChild();
  }

  sort(sortBy) {

    this.upDownNameCSS = '';
    this.upDownFileTypeCSS = '';
    this.upDownCreatedTimeCSS = '';
    this.upDownModifiedTimeCSS = '';
    this.upDownfileSizeCSS = '';

    if (!this.isAscending) {
      this.isAscending = true;
      this.childFilterModel.orderBy = sortBy + ' DESC';

      if (sortBy === "name") {
        this.upDownNameCSS = 'active-up';
      }
      else if (sortBy === "fileType") {
        this.upDownFileTypeCSS = 'active-up';
      }
      else if (sortBy === "fileSize") {
        this.upDownfileSizeCSS = 'active-up';
      }
      else if (sortBy === "createdTime") {
        this.upDownCreatedTimeCSS = 'active-up';
      }
      else if (sortBy === "modifiedTime") {
        this.upDownModifiedTimeCSS = 'active-up';
      }
    }
    else {
      this.isAscending = false;
      this.childFilterModel.orderBy = sortBy;
      if (sortBy === "name") {
        this.upDownNameCSS = 'active';
      }
      else if (sortBy === "fileType") {
        this.upDownFileTypeCSS = 'active';
      }
      else if (sortBy === "fileSize") {
        this.upDownfileSizeCSS = 'active';
      }
      else if (sortBy === "createdTime") {
        this.upDownCreatedTimeCSS = 'active';
      }
      else if (sortBy === "modifiedTime") {
        this.upDownModifiedTimeCSS = 'active';
      }
    }
    this.OrderBy = sortBy;
    this.i360UserLibraryFindChild();
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 MB';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  keywordFilters($event) {
    this.childFilterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.childFilterModel.keywordFilter = $event.target.value;
    }
    else {
      this.childFilterModel.keywordFilter = null;
    }
    this.i360UserLibraryFindChild();
  }

  clearKeywords() {
    this.keyword = '';
    this.childFilterModel.keywordFilter = null;
    this.childFilterModel.pageNumber = '1';
    this.i360UserLibraryFindChild();
  }
  addFile(bsDateValue) {
    this.files = [];
    this.fileUploadDoc.nativeElement.value = '';
    this.formatDate(bsDateValue);
    this.applicationActivity('Add', this.pageTitle + this.pageTitleSub + ' - File', this.productId, '', this.productId);
  }
  addFolder(isIdClear) {
    this.getState();
    this.stateDivVisible=true;
    if (this.deleteId == 0 && !this.selectedNode)
      this.applicationActivity('Add', this.pageTitle + this.pageTitleSub + ' - Folder', this.productId, '', this.productId);
    this.clearFolderFileName(isIdClear);


  }
  clearFolderFileName(isIdClear) {
    this.isRenameClick = false
    this.renameFolderFlag = false;
    this.selectAllState=false;
    this.folderName = '';
    if (!isIdClear)
      this.deleteId = 0;
  }

  applicationActivity(actionName, pageName, datatype = 0, actionUrl = '', dataId = 0) {
    ApplicationActivityStore(actionName, pageName, this.appActivity, dataId, datatype, actionUrl)
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  prepareFilesList(files: Array<any>) {
    this.files = [];
    for (const item of files) {
      if (item != undefined) {
        item.progress = 0;

        if (item != null) {
          let size = Math.round(item.size / 1024);
          if (size <= 21000) {
            //   ["gif", "jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx", "JPG", "JPEG", "PNG", "GIF", "PDF", "DOC", "DOCX", "XLS", "XLSX"];
            var allowedExtensions = ["doc", "DOC", "docx", "DOCX", "pdf", "PDF", "jpg", "JPG", "jpeg", "JPEG", "png", "PNG",];
            let fileType = item.name.split('.').pop();

            if (!this.isInArray(allowedExtensions, fileType)) {
              this.showMessage('You have selected an invalid file type.System supports following file formats: PDF, DOC, DOCX, JPG, JPEG, PNG.', 'Error', 'error');
              this.isErrorOnDoc = true
              window.scrollTo(0, 0);
            }
            else {
              let fileType = item.name.split('.').pop();
              this.userLibraryModel.fileType = fileType.toLowerCase();
              this.userLibraryModel.fileName = item.name;
              this.userLibraryModel.isFolder = false;
              this.userLibraryModel.libraryId = this.deleteId;
              this.isErrorOnDoc = false;
              //this.saveUserLibrary(true);
            }
          }
          else {
            this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
            this.isErrorOnDoc = true;
            window.scrollTo(0, 0);
          }
        }
        this.files.push(item);
      }
    }
    this.uploadFilesSimulator(0);
  }
  // prepareFilesList(files: Array<any>) {
  //   for (const item of files) {
  //     item.progress = 0;
  //     this.files.push(item);
  //   }
  //   this.uploadFilesSimulator(0);
  // }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  getState() {
    this.PricingQuotesMaster.GetStateMaster(this.tokenKey).subscribe(
      data => {
        this.stateList = data['state'];
        console.log(this.stateList);

      },
      error => { }
    );
  }

  SelectedStatesCheck(){
    if(this.stateList.length>0)
    {
      for (let iCounter = 0; iCounter < this.stateList.length; iCounter++) {
        if (this.selectAllState == true)
        {
          this.stateList[iCounter].isSelected=true;
        }
        else
         {
          this.stateList[iCounter].isSelected=false;
         }
      }
    }
  }
}
