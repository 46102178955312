import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TRFAUpdateGetModel, TRFAUpdateModel } from 'src/Models/TRFAUpdateModel';
import { StateMasterService } from 'src/Services/state-master.service';
import { TRFAFundModel } from 'src/Models/TRFAFundModel';
import { TrfaUpdateMasterService } from 'src/Services/trfa-update--master.service';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { FilterModel } from 'src/Models/filterModel';
import { I360Service } from 'src/Services/i360Service';
import * as FileSaver from 'file-saver';
import { ApplicationActivityStore } from "src/common/commonFunction";
import { ActionActivity,EnumFavorites,ToolEnum } from 'src/Models/Common';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { FavoriteFilterModel } from 'src/Models/FavoriteFilterModel';
import { FavoriteService } from 'src/Services/favorite.service';
import { Main911Service } from 'src/Services/main911.service';
import { Trfa911FTPUploadHistoryModel } from 'src/Models/TRFA_911_FTPUploadHistoryModel';

@Component({
  selector: 'app-trfa-update-view',
  templateUrl: './trfa-update-view.component.html',
  styleUrls: ['./trfa-update-view.component.css']
})
export class TrfaUpdateViewComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofUpdate: TRFAUpdateGetModel[]

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;


  upDowNoteCSS: string = '';
  upDowfundNameCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDowFundTypeCSS: string = '';
  isAscending: boolean = true;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: any = [];
  //Fund
  fundID: string = ' ';
  dropdownListFund = [];
  selectedItemsFund = [];
  dropdownSettingsFund = {};
  listOffundIDs = [];
  fundList: TRFAFundModel[] = [];
  module = "TRFA";
  stateIds: string = ' ';
  OrderByName: string = 'StateName';
  selectedPageSize = [];
  pageSizeOptions: any[] = [];
  dropdownSettingsDatePageSizeFilter = {};
  dropdownDateSelectedColumnFilter = [];
  selectedColumn = [];
  listOfColumnId = [];
  dropdownSettingsDateselectedColumnFilter = {};
  rangeOfupdatesDates: any;
  fromDate: string = '';
  toDate: string = '';
  listofMain911PrintData: any[] = [];
  filterModel = new FilterModel();
  blockedDocument = false;
  isShowprogressSpinner = false;
  stateSubscription: any;
  _userID: string = "";
   isColumnExist:boolean =true;
   quaryValues:string="";

    SelectedStateFav: number = 0;
    favouriteStateModel: FavoriteFilterModel[] = [];
    ProductId:number = 0;
    ListHistoryModel:Trfa911FTPUploadHistoryModel[];

    displayFavorite:number=0;

  constructor(private appActivity: ApplicationActivityService,private router: Router, private trfaUpdateMaster: TrfaUpdateMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private i360service: I360Service, private toastr: ToastrService, private stateService: StateMasterService, private trfaFundMaster: TrfaFundMasterService
    ,private favorite: FavoriteService,
    private activedRoute: ActivatedRoute,
    private main911Service: Main911Service) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofUpdate = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'UpdateDate desc';
    this.Note = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('States');
    this.getColumn();
    this._userID = sessionStorage.getItem('UserId');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsFund = {
      singleSelection: false,
      text: "Filter by Fund",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsDateselectedColumnFilter = {
      singleSelection: false,
      text: "Show Column",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Column(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsDatePageSizeFilter = {
      singleSelection: true,
      text: "Filter by Page",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Page(s) available',
      showCheckbox: false,
      escapeToClose: false,
    };
    this.pageSizeOptions = [
      { "id": 1, "itemName": 10 },
      { "id": 2, "itemName": 50 },
      { "id": 3, "itemName": 100 },
      { "id": 4, "itemName": 200 },
    ];
    this.selectedPageSize.push({ "id": 1, "itemName": 10 })
    sessionStorage.setItem('tokenValue', localStorage.getItem('tokenValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    //this.getState();
    this.getFund();
    this.getSubscriptionState();
    this.isloading = true;
    
    this.OrderByName="";

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {

        if (params['id'] != null) {

          this.quaryValues = params['id'];
          
          this.GetTRFANotificationHistory();
        }
        else {
          this.GetUpdate();
        }
      }
      else
      {this.GetUpdate();}
    });
  }

  GetTRFANotificationHistory()
  {

    this.blockDocument();
    this.main911Service.GetTRFA911NotificationHistory(this.quaryValues).subscribe(
      data => {
        this.isloading = false;
        this.listofUpdate = data['update'];
        if(data.status == 'Failure' && this.listofUpdate.length>0)
        {
          this.showMessage('No any update found', 'Warning', 'warning');
          this.router.navigate(['/Access/Denied/View Updates']);
        }
        else
        {
          if(this.listofUpdate.length>0)
          {
          this.blockedDocument = false;
          sessionStorage.setItem('trfa911UpdatehistoryUId', this.listofUpdate[0].userId.toString());
          if (data.status == 'Success' && (typeof (localStorage.getItem('UserName')) != 'undefined' && localStorage.getItem('UserName') != null && localStorage.getItem('UserName') != '')) {
            if(this.listofUpdate[0].userId.toString()===localStorage.getItem('UserId'))
            {
              setTimeout(() => {
                    this.isloading = false;
                    
                      if (this.listofUpdate.length == 0)
                        this.isRecord = true;
                      else {
                        this.isRecord = false;
                        this.totalRecordCount = this.listofUpdate.length;
                        data['update'].map((x) => {
                          let data = this.fundList.filter(y => y.fundID == x.fundID)[0];
                          this.selectedItemsFund.push({ "id": data.fundID, "itemName": data.fundName });
                          this.listOffundIDs.push(data.fundID)
                          let stateData=this.stateList.filter(y=>y.stateId==x.stateId)[0]
                          this.selectedItemsState.push({ "id": stateData.stateId, "itemName": stateData.name});
                          this.listOfStateIds.push(stateData.stateId)
                        })
                      }
              }, 2000);
              this.unblockDocument();
            }
            else
            this.router.navigate(['/Access/Denied/View Updates']);
          }
          else if (data.status == 'Success')
            this.router.navigate(['login/' + this.quaryValues]);
          else
            this.router.navigate(['login']);
        }
        else{
          this.blockedDocument = false;
          this.showMessage('Data Not Found', 'Warning', 'warning');
          this.router.navigate(['/Access/Denied/View Updates']);
          this.unblockDocument();
        }
        }
      },
      error => {
       this.blockedDocument = false;
        console.log(error);
        this.router.navigate(['login']);
        this.unblockDocument();
      }
     );
  }

  getFund() {
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.trfaFundMaster.GetFund(this.tokenKey, this.stateId,false,false).subscribe(
      data => {
        this.dropdownListFund = []
        this.fundList = data['fund'];
        this.dropdownListFund = data['fund'];
        this.selectedItemsFund = [];
        if (this.fundList.length > 0) {
          let b = [];
          this.selectedItemsFund = [];
          this.fundList.forEach(x => {
            let a = this.listOffundIDs.filter(e => e == x.fundID);
            if (a.length > 0) {
              b.push(x.fundID); 
              this.selectedItemsFund.push(x)
            }
          });
          this.listOffundIDs = b;
        }
        else {
          this.selectedItemsFund = [];
          this.listOffundIDs = [];
        }
      },
      error => { }
    );
  }
  pageChanged($event: any) {    
    this.PageNumber = parseInt($event.page);
    ApplicationActivityStore('View', 'TRFA Updates'+$event.page,this.appActivity,ToolEnum.trfa, ActionActivity.TRFA,'/View/updateView');
    this.GetUpdate();
  }


  GetUpdate() {
    this.isloading = true;
    this.fundID = ' ';
    if (this.listOffundIDs.length > 0) {
      this.fundID = this.listOffundIDs.join(',')
    }
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.Note == "null") this.Note = " ";
    
    let listofstatename = "";
      this.listOfStateIds.forEach((value, y) => {
        const statemodal = this.stateList.filter(x => x.stateId == value);
        if(this.listOfStateIds.length-1==y)
        {         
         listofstatename = listofstatename + statemodal[0].name + '<br />';
        }
        else{
          listofstatename = listofstatename + statemodal[0].name + ',' + '<br />';
        }
      });

      if (listofstatename != "") {
        listofstatename = listofstatename.trim();
        ApplicationActivityStore('View', 'TRFA Updates-', this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/updateView',listofstatename);
      }
      else {
        ApplicationActivityStore('View', 'TRFA Updates', this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/updateView', null);
      }


      this.trfaUpdateMaster.GetAllUpdateByUser(this.tokenKey, this.PageNumber, this.PageSize, this.fundID, this.stateId, this.fromDate, this.toDate, this.OrderBy,false,parseInt(this._userID),this.displayFavorite).subscribe(
      data => {
        this.isloading = false;
        this.listofUpdate = data['update'];
        if (this.listofUpdate != null) {
          if (this.listofUpdate.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofUpdate[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else
      this.Note = 'null';
      this.resetPageNoandSize();
    this.GetUpdate();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowNoteCSS = '';
      this.upDowfundNameCSS = '';
      this.upDowStateNameCSS = '';
      this.upDowFundTypeCSS = '';
      this.upDowUpdateDateCSS = '';
      if (sortBy === "Note") {
        this.upDowNoteCSS = 'active-up';
      }
      else if (sortBy === "fundName") {
        this.upDowfundNameCSS = 'active-up';
      }
      else if (sortBy === "FundTypeName") {
        this.upDowFundTypeCSS = 'active-up';
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active-up';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowUpdateDateCSS = 'active-up';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;
      this.upDowNoteCSS = '';
      this.upDowfundNameCSS = '';
      this.upDowStateNameCSS = '';
      this.upDowFundTypeCSS = '';
      this.upDowUpdateDateCSS = '';
      if (sortBy === "Note") {
        this.upDowNoteCSS = 'active';
      }
      else if (sortBy === "fundName") {
        this.upDowfundNameCSS = 'active';
      }
      else if (sortBy === "FundTypeName") {
        this.upDowFundTypeCSS = 'active';
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowUpdateDateCSS = 'active';
      }
    }
    this.OrderByName = sortBy;
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  addUpdate() {
    let navigation = '/admin/' + this.SelectedTool + '/update/manage';
    this.router.navigate([navigation]);
  }

  updateUpdate(UpdateId) {
    let navigation = '/admin/' + this.SelectedTool + '/update/manage/' + UpdateId;
    this.router.navigate([navigation]);
  }

  deleteUpdate(UpdateId) {
    this.isloading = true;
    this.trfaUpdateMaster.DeleteUpdate(this.tokenKey, UpdateId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          // if (data['message'] != "")
          //   this.showMessage(data['message'], 'Warning', 'warning')
          // else
          this.showMessage("Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.GetUpdate();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }


  onSelectFund($event) {
    this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
    this.listOffundIDs.push($event.id);
    // this.stateId = ' ';
    // if (this.listOffundIDs.length > 0) {
    //   this.stateId = this.listOffundIDs.join(',')
    // }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectFund($event) {
    if (this.listOffundIDs != null) {
      if (this.listOffundIDs.length > 0) {
        this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
      }
    }
    if (this.listOffundIDs.length > 0) {
      this.stateId = this.listOffundIDs.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAllFund($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectAllFund($event) {
    this.listOffundIDs = [];
    this.resetPageNoandSize();
    this.GetUpdate();
  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        console.log(this.stateList)
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }
  getColumn() {

    this.dropdownDateSelectedColumnFilter = [
      { "id": 1, "itemName": 'Jurisdiction' },
      { "id": 2, "itemName": 'Fund' },
      { "id": 3, "itemName": 'Fund Type' },
      { "id": 4, "itemName": 'Note' },
      { "id": 5, "itemName": 'Update Date' },
    ]
    this.selectedColumn = [{ "id": 1, "itemName": 'Jurisdiction' },
    { "id": 2, "itemName": 'Fund' },
    { "id": 3, "itemName": 'Fund Type' },
    { "id": 4, "itemName": 'Note' },
    { "id": 5, "itemName": 'Update Date' },
    ]
  }
  isCheckColum(id) {
    let data = this.selectedColumn.filter((x => x.id == id))[0];
    if (data != null)
      return true
    else return false
  }
  onSelectColumn($event) {
    this.isColumnExist=true
    this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event.id)
    this.listOfColumnId.push($event.id);
    if (this.listOfColumnId.length > 0) {
    }
  }

  onDeSelectColumn($event) {

    if (this.listOfColumnId != null) {
      if (this.listOfColumnId.length > 0) {
        this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event.id)
      }
    }
    if (this.selectedColumn.length > 0) {
      this.isColumnExist=true
    }
    else{
      this.isColumnExist=false
    }
  }

  onSelectAllColumn($event) {
    this.isColumnExist=true
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfColumnId != null) {
        if (this.listOfColumnId.length > 0) {
          this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event[iCounter].id)
          this.listOfColumnId.push($event[iCounter].id);
        }
        else {
          this.listOfColumnId.push($event[iCounter].id);
        }
      }
    }
  }
  onDeSelectAllColumn($event) {
    this.isColumnExist=false
    this.selectedColumn = [];
    this.listOfColumnId = [];
  }
  validExport(id) {
    // if(this.exportCitiationList.length>0){
    //  let data= this.exportCitiationList.find((x)=>x.exportCitiationId==id);
    //  if(data !=null)return true;
    //  else return false
    // }
    //else return false
    return true
  }
  getPageLength() {
    // if (this.PageSize < this.totalRecordCount) return this.PageSize
    // else return this.totalRecordCount
    return Math.min((this.PageNumber * this.PageSize),this.totalRecordCount);
  }
  onChangePageSize(event) {
    if (this.selectedPageSize.length > 0) this.PageSize = this.selectedPageSize[0].itemName;
    else {
      this.selectedPageSize = [];
      this.PageSize = 10;
      this.selectedPageSize.push({ "id": 1, "itemName": 10 })
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }
  onDeSelectDateFilterPageSizeAll($event) {
    this.resetPageNoandSize();
    this.GetUpdate();
  }
  clearUpdateDate() {
    this.rangeOfupdatesDates = [];
    this.fromDate = '';
    this.toDate = '';
    this.resetPageNoandSize();
    this.GetUpdate();
  }
  isUpdatesDatesFilter(value: any) {
    if (value != null) {
      if (value.length == 2) {
        const d = new Date(value[0]);
        const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

        const e = new Date(value[1]);
        const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');

        this.fromDate = a;
        this.toDate = f;
        this.resetPageNoandSize();
        this.GetUpdate();
      }
    }
  }
  pad(s) {
    return (s < 10) ? '0' + s : s;
  }
  print() {
    ApplicationActivityStore('Download', 'TRFA Updates Print',this.appActivity,ToolEnum.trfa, ActionActivity.TRFA,'/View/updateView');
    ApplicationActivityStore('Download', 'TRFA Print',this.appActivity,ToolEnum.trfa, ActionActivity.TRFA,'/View/updateView');
    let printContents, popupWin;
    printContents = document.getElementById('printareaDiv').innerHTML;
    popupWin = window.open('', 'PrintWindow', 'width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
    popupWin.document.open();
    popupWin.document.write(`
  <html>
    <head>
      <style>
      body{  width: 99%;}
        label { font-weight: 400;
                font-size: 13px;
                padding: 2px;
                margin-bottom: 5px;
              }
        table, td, th {
               border: 1px solid silver;
                }
                table td {
               font-size: 13px;
               word-wrap:break-word;
                }

                 table th {
               font-size: 13px;
                }
          table {
                border-collapse: collapse;
                width: 98%;
                table-layout:fixed;
                }
            th {
                height: 26px;
                }
      </style>
    </head>
<body onload="window.print();window.close()">${printContents}</body>
  </html>`
    );
    popupWin.document.close();

  }
  updateGetAllPrintData() {
    this.isloading = true;
    this.fundID = ' ';
    if (this.listOffundIDs.length > 0) {
      this.fundID = this.listOffundIDs.join(',')
    }
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.Note == "null") this.Note = " ";
    this.stateId = this.listOfStateIds.join(',')
    this.trfaUpdateMaster.GetAllUpdateByUser(this.tokenKey, 1, 1000000000, this.fundID, this.stateId, this.fromDate, this.toDate, this.OrderBy,false,parseInt(this._userID),0).subscribe(
      data => {
        this.isloading = false;
        this.listofMain911PrintData = data['update'];
        if (this.listofMain911PrintData != null) {
          if (this.listofMain911PrintData.length == 0) {
            this.isRecord = true;
          }
          else {
            this.isRecord = false;
          }
        }
        else {
          this.isRecord = true;
        }
        setTimeout(() => {
          this.print();
        }, 1000);
      },
      error => { }
    );
  }
  ExportUpdates() {
    this.isloading = true;
    this.blockedDocument = true;
    this.isShowprogressSpinner = true;
    this.fundID = ' ';
    if (this.listOffundIDs.length > 0) {
      this.fundID = this.listOffundIDs.join(',')
    }
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.Note == "null") this.Note = " ";

    this.trfaUpdateMaster.ExportUpdates(this.tokenKey, this.PageNumber, this.PageSize, this.fundID, this.stateId, this.fromDate, this.toDate, this.OrderBy).subscribe(
      data => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.isloading = false;
        if (data != null) {

          if (data.status == 'Success') {
            this.filterModel.docPath = data.responseNewFilename;
            this.filterModel.docType = 'TRFAUpdate Exports';
            this.i360service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
              fileData => {

                if (fileData != null) {
                  FileSaver.saveAs(fileData, 'Updates.csv');
                }
              }, error => {
                console.log(error);
              }
            );
          }
          else {
            this.toastr.error(data.status, 'Error');
          }
        }
      },
      error => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.isloading = false;
      }
    );

  }

  getSubscriptionState() {

    this.stateSubscription = null;
    this.stateService.i360ManageSubscriptionClientId(this.tokenKey, this._userID, 0,true).subscribe(
      data => {
        this.stateSubscription = {
          wirelessStates: data.wirelessStates,
          wirelessStatesCount: data.wirelessStates ? data.wirelessStates.length : 0,
          iprStates: data.iprStates,
          iprStatesCount: data.iprStates ? data.iprStates.length : 0,
          voIPStates: data.voIPStates,
          voIPStatesCount: data.voIPStates ? data.voIPStates.length : 0,
          actionStates: data.actionStates,
          actionStatesCount: data.actionStates ? data.actionStates.length : 0,
          productAccess: data.productAccess,
          briefingStates: data.briefingStates,
          briefingStatesCount: data.briefingStates ? data.briefingStates.length : 0,
          state911: data.state911,
          state911Count: data.state911 ? data.state911.length : 0,
          carrierCategory: data.carrierCategory,
          carrierCategoryCount: data.carrierCategory ? data.carrierCategory.filter(m => m.isSelected == true).length : 0,
          trfa: data.trfa
        };
        this.stateList = data.trfa;
        this.dropdownListState = [];
        if(this.stateList!=null)
        {
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      }

      },
      error => { console.log(error); }
    );
  }

  redirectToFundDetails(fundID: any)
  {
    sessionStorage.setItem('fundID', fundID);
    ApplicationActivityStore('View', 'TRFA Updates - View Fund History', this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/contentView', null);
    this.router.navigate(['/View/contentView/' + fundID]);
  } 

  resetPageNoandSize()
  {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  AddToFavourite(item, IsFavourite: number) {
    let stateId = item.stateId;
    if (IsFavourite == 0) {
      this.SelectedStateFav = stateId;
      this.favouriteStateModel = item;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavourite(stateId, IsFavourite, item);
    }
  }

  SaveFavourite(stateId: string, IsFavourite: number, item)
  {
    this.blockDocument();
    let stateName = item.stateName;
    document.getElementById("btnClose").click();
    this.favorite.ClientStateFavouriteAdd(this.tokenKey, parseInt(this._userID), stateId, IsFavourite, ToolEnum.trfa).subscribe(data => 
    {
      if (data["status"] == "Success") 
      {
        this.GetUpdate();
        if (IsFavourite == 1) {
          ApplicationActivityStore('Mark Favorite', 'TRFA Updates', this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/updateView', stateName);
          this.showMessage('Selected jurisdiction added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          ApplicationActivityStore('Remove Favorite', 'TRFA Updates', this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/updateView', stateName);
          this.showMessage('Selected jurisdiction removed successfully from favorite list.', 'Favorite', 'success');
        }        
      }
      else 
      {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
      }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
  }

  GetFavourite(displayFav:number)
  {
    this.displayFavorite=displayFav;
    this.GetUpdate();
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  onFilterSelectAll($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    // this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAllstate($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    // this.getFund();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }
}
