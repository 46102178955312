import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';
import { UserModel } from 'src/Models/usersModel';
import { ActionItemService } from 'src/Services/actionitem.service';
import { ActionItemSearchModel } from 'src/Models/ActionItemSearchModel';
import { ActionItemUpdateStatusModel } from 'src/Models/ActionItemUpdateStatusModel';
import { ToastrService } from 'ngx-toastr';
import { ActionItemSearchStatusHistoryModel } from 'src/Models/ActionItemSearchStatusHistoryModel';
import { Router } from '@angular/router';
import { ActionStatusList } from '../../../Models/Common';

@Component({
  selector: 'app-action-status-popup',
  templateUrl: './action-status-popup.component.html',
  styleUrls: ['./action-status-popup.component.css']
})
export class ActionStatusPopupComponent implements OnInit {
  tokenKey: string = '';
  userModel: UserModel[];
  actionItemUpdateStatusModel = new ActionItemUpdateStatusModel();

  dropdownUserSettings = {};
  selectedItemsUser = [];
  dropdownListUser = [];

  dropdownStatusSettings = {};
  selectedItemsStatus = [];
  dropdownListStatus = [];
  newStatus: number = -1;

  companyId: number = 0;
  userId: number = 0;
  actionId: number = 0;
  updateId: number = 0;
  productId: number = 0;
  notes: string = '';

  isHideAssignToUser: boolean = false;
  isHideRadioOption: boolean = false;
  isShowHideExrtaChargeAndNote: boolean = false;

  isOneClickOnButton: boolean = false;

  updateActionStatus = new FormGroup({
    assignToUser: new FormControl(''),
    status: new FormControl(''),
    handleType: new FormControl(''),
    notes: new FormControl(''),
    actionID: new FormControl(''),
    updateID: new FormControl(''),
    isInformationOnly: new FormControl(false),
  });

  actionItemSearchModel = new ActionItemSearchModel()
  actionItemSearchStatusHistoryModel: ActionItemSearchStatusHistoryModel[];
  isActionItemSearchStatusHistoryData: boolean = false;

  stateArray: any = [];
  carrierCategoryArray: any = [];
  isAccordianShow: boolean = true;

  previousAssingId: number;
  emaliAutoLoginUserId: number = 0;
  assignToId: number = 0;
  isNoteVisible: boolean = false;

  previousStatus: number = -1;


  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  constructor(private router: Router, private toastr: ToastrService, private actionItemServices: ActionItemService, private checkAuth: CheckAuth, private usersService: UsersService, public activeModal: NgbActiveModal) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
  }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyId = +sessionStorage.getItem("CompanyId");
    this.userId = +sessionStorage.getItem('UserId');
    this.productId = +sessionStorage.getItem('productId');
    this.updateId = +sessionStorage.getItem('updateId');
    this.actionId = +sessionStorage.getItem('actionId');
    this.emaliAutoLoginUserId = +sessionStorage.getItem('emailUserId');

    sessionStorage.removeItem('productId');
    sessionStorage.removeItem('updateId');
    sessionStorage.removeItem('actionId');
    sessionStorage.removeItem('emailUserId');

    this.ActionItemSearchByIds(this.userId, this.updateId, this.actionId);
    this.GetActionItemSearchStatusHistory(this.userId, this.updateId, this.actionId);
  }

  GetActionItemSearchStatusHistory(userId: any, updateId: any, actionId: any) {
    this.actionItemServices.GetActionItemSearchStatusHistory(this.tokenKey, userId, updateId, actionId).subscribe(
      data => {

        if (data['status'] == 'Success') {
          this.actionItemSearchStatusHistoryModel = data['actionItemSearchHistoryModel'];
          if (this.actionItemSearchStatusHistoryModel != null) {
            if (this.actionItemSearchStatusHistoryModel.length == 0) {
              this.isActionItemSearchStatusHistoryData = true;
            }
            else
              this.isActionItemSearchStatusHistoryData = false;

          }
        }
      });
  }

  ActionItemSearchByIds(userId: any, updateId: any, actionId: any) {
    this.actionItemServices.ActionItemSearchByIds(this.tokenKey, userId, updateId, actionId).subscribe(
      data => {

        if (data['status'] == 'Success') {
          this.actionItemSearchModel = data['actionItemSearchModel'];

          if (this.actionItemSearchModel != null) {
            if (this.actionItemSearchModel.handleType == '2')
              this.isNoteVisible = true;
            else
              this.isNoteVisible = false;
          }
          else
            this.isNoteVisible = false;


          if (this.actionItemSearchModel != null) {
            this.isHideAssignToUser = this.actionItemSearchModel.isHideAssignToUser.toString() == 'true' ? true : false;
            this.isHideRadioOption = this.actionItemSearchModel.isHideRadioOption.toString() == 'true' ? true : false;
            // if (this.actionItemSearchModel.actionCharges > 0)
            //   this.isShowHideExrtaChargeAndNote = true;
            // else
            //   this.isShowHideExrtaChargeAndNote = false;
          }

          this.GetUsersFindByCompanyId(this.companyId);
          this.GetActionStatus();
          if (this.actionItemSearchModel.stateName != 'null' && typeof (this.actionItemSearchModel.stateName) != 'undefined') {
            this.stateArray = this.actionItemSearchModel.stateName.split(',');
          }
          if (this.actionItemSearchModel.carrierNames != 'null' && typeof (this.actionItemSearchModel.carrierNames) != 'undefined') {
            this.carrierCategoryArray = this.actionItemSearchModel.carrierNames.split(',');
          }
        }
      });
  }

  GetUsersFindByCompanyId(companyId: any) {
    this.usersService.GetUsersFindByCompanyId(this.tokenKey, companyId, this.productId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.userModel = data['companyUsers'];
          if (this.userModel != null) {
            if (this.userModel.length > 0) {
              for (let iCounter = 0; iCounter < this.userModel.length; iCounter++) {
                this.dropdownListUser.push({ "id": parseInt(this.userModel[iCounter].userId), "itemName": this.userModel[iCounter].userName });
              }
            }
          }
        }


        if (this.emaliAutoLoginUserId > 0) {
          for (let iCounter = 0; iCounter < this.userModel.length; iCounter++) {
            if (this.emaliAutoLoginUserId == parseInt(this.userModel[iCounter].userId)) {
              this.selectedItemsUser.push({ "id": parseInt(this.userModel[iCounter].userId), "itemName": this.userModel[iCounter].userName });
              this.previousAssingId = parseInt(this.userModel[iCounter].userId);
            }
          }
        }
        else {
          if (this.actionItemSearchModel.assignToId != null && this.actionItemSearchModel.assignToId != '') {
            for (let iCounter = 0; iCounter < this.userModel.length; iCounter++) {
              if (this.userModel[iCounter].userId == this.actionItemSearchModel.assignToId) {
                this.selectedItemsUser.push({ "id": parseInt(this.userModel[iCounter].userId), "itemName": this.userModel[iCounter].userName });
                this.previousAssingId = parseInt(this.userModel[iCounter].userId);
              }
            }
          }
        }

        this.dropdownUserSettings = {
          singleSelection: true,
          text: "Select User",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No user available',
          showCheckbox: false
        };
      });
  }



  onSelectedUser($event) {
    this.assignToId = $event.id;
  }

  onDeSelectedUser($event) {
    this.assignToId = 0;
  }

  onDeSelectedAllUser($event) {
    this.assignToId = 0;
  }

  GetActionStatus() {
    this.actionItemServices.GetActionStatus(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          let actionStatus = data['companyModel'];
          if (actionStatus != null) {
            if (actionStatus.length > 0) {
              for (let iCounter = 0; iCounter < actionStatus.length; iCounter++) {
                if (parseInt(actionStatus[iCounter].statusId) != ActionStatusList.informativeonly)
                  this.dropdownListStatus.push({ "id": parseInt(actionStatus[iCounter].statusId), "itemName": actionStatus[iCounter].statusName });
              }
            }
          }

          if (this.actionItemSearchModel.statusName != null && this.actionItemSearchModel.statusName != '') {
            for (let iCounter = 0; iCounter < actionStatus.length; iCounter++) {
              if (actionStatus[iCounter].statusName == this.actionItemSearchModel.statusName) {
                this.selectedItemsStatus.push({ "id": parseInt(actionStatus[iCounter].statusId), "itemName": actionStatus[iCounter].statusName });
                this.previousStatus = parseInt(actionStatus[iCounter].statusId);
                this.newStatus = parseInt(actionStatus[iCounter].statusId);
              }
            }
          }
        }



        this.dropdownStatusSettings = {
          singleSelection: true,
          text: "Select Status",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No status available',
          showCheckbox: false
        };
      });
  }
  onSelectedStatus($event) {
    this.newStatus = $event.id
  }

  onDeSelectedStatus($event) {
    this.newStatus = -1
  }

  onDeSelectAllStatus($event) {
    this.newStatus = -1
  }

  UpdateActionItem(updateActionStatus: any, isButtonDisable: boolean) {

    event.preventDefault();

    if (updateActionStatus.value['updateID'] != undefined)
      this.actionItemUpdateStatusModel.updateId = updateActionStatus.value['updateID'];
    else
      this.actionItemUpdateStatusModel.updateId = 0;

    if (updateActionStatus.value['actionID'] != undefined)
      this.actionItemUpdateStatusModel.actionId = updateActionStatus.value['actionID'];
    else
      this.actionItemUpdateStatusModel.actionId = 0;

    if (updateActionStatus.value['handleType'] != undefined && !this.isHideRadioOption)
      this.actionItemUpdateStatusModel.handleType = updateActionStatus.value['handleType'];
    else
      this.actionItemUpdateStatusModel.handleType = 0;

    //this.actionItemUpdateStatusModel = updateActionStatus.value;

    this.actionItemUpdateStatusModel.assignToId = this.assignToId;
    // for (let iCounter = 0; iCounter < updateActionStatus.controls["assignToUser"].value.length; iCounter++) {
    //   this.actionItemUpdateStatusModel.assignToId = this.assignToId;
    // }
    if (this.newStatus == -1)
      this.newStatus = ActionStatusList.pending;

    this.actionItemUpdateStatusModel.statusId = this.newStatus;

    // Set when status is pending then set to ackowledged 
    
    if (this.newStatus == ActionStatusList.pending && this.previousStatus != ActionStatusList.acknowledged &&
      !this.isHideRadioOption && updateActionStatus.value['handleType'] != undefined && updateActionStatus.value['handleType'] != '0') {
      this.actionItemUpdateStatusModel.statusId = ActionStatusList.acknowledged;
    }

    // for (let iCounter = 0; iCounter < updateActionStatus.controls["status"].value.length; iCounter++) {
    //   this.actionItemUpdateStatusModel.statusId = updateActionStatus.controls["status"].value[iCounter].id;
    // }

    this.actionItemUpdateStatusModel.userID = this.userId;

    if (updateActionStatus.value['notes'] != undefined)
      this.actionItemUpdateStatusModel.note = updateActionStatus.value['notes'];
    else
      this.actionItemUpdateStatusModel.note = '';

    this.actionItemUpdateStatusModel.productId = this.productId;
    this.actionItemUpdateStatusModel.previousAssingId = this.previousAssingId;
    this.actionItemUpdateStatusModel.previousStatus = this.previousStatus;

    if (isButtonDisable) {      
      return;
    }

    this.isOneClickOnButton = true;

    this.actionItemServices.ActionItemUpdateStatus(this.tokenKey, this.actionItemUpdateStatusModel).subscribe(
      data => {
        this.isOneClickOnButton = false;
        if (data['status'] == 'Success') {
          this.showMessage('Trac-It status upteded successfully', 'Success', 'success');
          this.activeModal.close('Yes');
        }
        else {
          this.showMessage(data['status'], 'Error', 'error');
          this.isOneClickOnButton = false;
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
        this.isOneClickOnButton = false;
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  NoteVisible(isChecked: boolean) {
    this.isNoteVisible = isChecked;
  }
  // ShowHideExrtaChargeAndNote(selectedValue: any) {

  // }
}
