import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { I360Service } from 'src/Services/i360Service';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CustomProductModel } from 'src/Models/CustomProductModel';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { ActionActivity } from 'src/Models/Common';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-custom-product',
  templateUrl: './custom-product.component.html',
  styleUrls: ['./custom-product.component.css']
})
export class CustomProductComponent implements OnInit {

  CustomProduct: CustomProductModel;
  customProductDetails: any = [];
  filterModel = new FilterModel();
  tokenKey: string = '';
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  videoURL: any;
  videoType: string;
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  @ViewChild("videoModel", { static: false }) videoModel: ElementRef;
  topicTitle: string = ''
  groupArr: any[];
  isEmbed: boolean = false;


  constructor(private activedRoute: ActivatedRoute, private i360Service: I360Service, private checkAuth: CheckAuth, private titleService: Title,
    private toastr: ToastrService, private appActivity: ApplicationActivityService, private sanitizer: DomSanitizer) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'ISNULL(ModifiedDate,CreatedDate) DESC';
    this.filterModel.userID = this._userID;
    this.titleService.setTitle('Custom Product | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.CustomProduct = new CustomProductModel();
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.filterModel.customProductId = params['id'];
          this.Geti360CustomProduct();
        }
      }
    });
  }

  Geti360CustomProduct() {
    this.i360Service.i360CustomProductDetailsGet(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.CustomProduct = data['customProduct'];
        this.customProductDetails = data['customProduct'].customProductDetails;

        this.groupArr = this.customProductDetails.reduce((r, { section }) => {
          if (!r.some(o => o.section == section)) {
            r.push({ section, groupItem: this.customProductDetails.filter(v => v.section == section) });
          }
          return r;
        }, []);

        if (this.customProductDetails != null) {
          if (this.customProductDetails.length > 0) {
            this.totalRecordCount = this.customProductDetails[0].totalRecordCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
        else {
          this.totalRecordCount = 0;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  donwloadFile(data: any) {
    this.topicTitle = '';
    this.isEmbed = data.isEmbed;

    if (data.isLocked)
      return;

    this.topicTitle = data.title;

    if (data.linkType == 1 || data.linkType == 0) {
      ApplicationActivityStore('View', 'Customer Resources', this.appActivity, data.webinarLibraryId, ActionActivity['Manage Customer Resources'], data.title);

      if (data.externalURL.includes('youtube')) {
        this.videoURL = data.externalURL;
        this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.externalURL);
        this.videoModel.nativeElement.click();
      }
      else {
        window.open(data.externalURL, '_blank');
      }
      // this.videoURL = data.externalURL;
      // this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.externalURL);
      // this.videoModel.nativeElement.click();
      // }
    }

    else if (data.linkType == 2) {
      const extension = data.docPath.split(".").pop();
      if (extension == 'ogg' || extension == 'ogv' || extension == 'mp4' || extension == 'webm') {
        ApplicationActivityStore('View', 'Customer Resources', this.appActivity, data.webinarLibraryId, ActionActivity['Manage Customer Resources'], data.title);
        this.videoType = 'video/' + extension;
        this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.docPath);
        this.videoModel.nativeElement.click();
        this.videoplayer.nativeElement.load();
        this.videoplayer.nativeElement.play();
      }

      else {
        ApplicationActivityStore('Download', 'Customer Resources', this.appActivity, data.webinarLibraryId, ActionActivity['Manage Customer Resources'], data.displayDocName);
        this.isShowprogressSpinner = true;
        this.blockedDocument = true;
        this.i360Service.DownloadDocument(this.tokenKey, { DocType: 'Customer Resources', DocPath: data.displayDocName }).subscribe(
          fileData => {
            this.isShowprogressSpinner = false;
            this.blockedDocument = false;
            if (fileData != null) {
              FileSaver.saveAs(fileData, data.displayDocName);
              this.toastr.success('File download successfully', 'File Downloaded')
            }
          }, error => {
            this.isShowprogressSpinner = false;
            this.blockedDocument = false;
            console.log(error);
          });
      }
    }
  }

  closeVideoPlayer() {
    this.videoplayer.nativeElement.pause();
    this.videoplayer.nativeElement.currentTime = 0;
  }

  toggleVideo(event: any) {
    var myVideo: any = this.videoplayer.nativeElement
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.Geti360CustomProduct();
  }

  // Disable Mouse Right Click
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    if (event != undefined)
      event.preventDefault();
  }

  // Disable Keyboard
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.returnValue = false;
    event.preventDefault();
  }

}
