import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { FavoriteFilterModel } from 'src/Models/FavoriteFilterModel';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  AppUrl: string = "";
  FavoriteFilterModel=new FavoriteFilterModel();
  
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  ClientStateFavouriteAdd(TokenKey: string, _userID: number, stateID: string, isFavourite: number,ModuleId:number): Observable<any> {
    this.setAPIUrl();
    this.FavoriteFilterModel.userId = _userID;
    this.FavoriteFilterModel.stateID = stateID;
    this.FavoriteFilterModel.isFavourite = isFavourite;
    this.FavoriteFilterModel.ModuleId = ModuleId

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/Favorites/ClientStateFavouriteAdd', this.FavoriteFilterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetFavoriteStateByUser(TokenKey: any, UserID: any,ModuleId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Favorites/GetFavoriteStateByUser/' + UserID + '/' + ModuleId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  
  private handleError(error: Response | any) { 
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
