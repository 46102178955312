import { Injectable} from '@angular/core';
import { TokenService } from '../Services/token.service';

@Injectable()
export class UtilityService {
  isSession(): Promise<boolean> {
    if (typeof (Storage) !== 'undefined') {
      if (sessionStorage.getItem['User']) {
        return Promise.resolve(true);
      }
    }
    return Promise.resolve(false);
  }
}

@Injectable()
export class CheckAuth {
  moonLanding: Date;
  currentTime: Date;

  constructor(private tokenService: TokenService) {

  }

  GetToken(): void {        
    this.tokenService.getAuthentication().subscribe(t => {            
      this.moonLanding = new Date();            
      sessionStorage.setItem('tokenValue', t["tokenDetail"].token);
      sessionStorage.setItem('tokenExpiryValue', t["tokenDetail"].tokenExpiry);
      sessionStorage.setItem('appStartTime', this.moonLanding.toString());

      localStorage.setItem('tokenValue', t["tokenDetail"].token);
      localStorage.setItem('tokenExpiryValue', t["tokenDetail"].tokenExpiry);
      localStorage.setItem('appStartTime', this.moonLanding.toString());
    }
    );
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {        
    if (startDate != null) {      
      this.currentTime = new Date();
      let time = this.currentTime.getTime() - (new Date(startDate).getTime());
      time = time / 1000;
      time = time / 60;
      time = Math.round(Math.abs(time));
      if (parseInt(time.toString()) > (parseInt(tokenExpiry) - 2)) {
        this.GetToken();
      }
    }
    else {
      this.GetToken();
    }
  }
}
