import { Injectable } from '@angular/core';
import { StepMasterModel } from 'src/Models/StepMasterModel';

@Injectable()
export class Globle {
    /**
     *
     */
    constructor() {
        this.cApp = new StepMasterModel();
    }
    // cAppHistory: SubscriberApplicationHistory;
    cApp: StepMasterModel;
}
