import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { CustomProductModel } from 'src/Models/CustomProductModel';
import { CheckAuth } from 'src/Services/utility.service';
import { CustomCompanyProductService } from 'src/Services/custom-company-product.services';

@Component({
  selector: 'app-custom-company-product',
  templateUrl: './custom-company-product.component.html',
  styleUrls: ['./custom-company-product.component.css']
})
export class CustomCompanyProductComponent implements OnInit {
  filterModel = new FilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfGetCustomProductData: CustomProductModel[];

  isUserSystemAdmin: boolean = true;
  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false
  upDowTitleCSS: string = '';
  upDowURLCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';

  deletedId: number = 0;
  isloading: boolean;
  openMenuIndex: number = -1;

  constructor(private activedRoute: ActivatedRoute,private customCompanyProductService: CustomCompanyProductService, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Company Custom Product');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'ISNULL(ModifiedDate,CreatedDate) DESC';
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.filterModel.companyId = params['id'];
          this.GetCustomCompanyProductData();
        }
      }     
    });    
  }

  GetCustomCompanyProductData() {
    this.listOfGetCustomProductData = [];
    this.isloading = true;
    this.customCompanyProductService.i360CustomProductGet(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        
        if (data != null) {
          this.listOfGetCustomProductData = data['customProductData'];
          if (this.listOfGetCustomProductData != null) {
            if (this.listOfGetCustomProductData.length > 0) {
              this.totalRecordCount = this.listOfGetCustomProductData[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filterModel.whereCondition = $event.target.value;
    }
    else {
      this.filterModel.whereCondition = '';
    }
    this.GetCustomCompanyProductData();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetCustomCompanyProductData();
  }

  cleanCssClass() {
    this.upDowTitleCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case "title": this.upDowTitleCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "isActive": this.upDowIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "CreatedDate": this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "ModifiedDate": this.upDowModifiedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetCustomCompanyProductData();
  }

  addCustomCompanyProducts() {
    this.router.navigate(['admin/inteserra360/Manage/custom-company-product/'+this.filterModel.companyId]);
  }

  editData(id) {
    this.router.navigate(['admin/inteserra360/Manage/custom-company-product/'+this.filterModel.companyId+'/' + id])
  }

  setDeleteData(ids) {
    this.deletedId = ids;
  }

  deleteRecord(deletedId) {
    this.customCompanyProductService.i360CustomProductDelete(this.tokenKey, deletedId).subscribe(
      data => {
        this.deletedId = 0;
        if (data != null) {
          if (data['deletedId'] > 0) {
            this.GetCustomCompanyProductData();
            this.toastr.success('Record deleted successfully.', 'Delete')
          }
        }
      },
      error => {
      }
    );
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }


}
