import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class IpService {

  constructor(private http: Http) { }
  public getIPAddress() {
    return this.http.get("https://jsonip.com")
      .pipe(catchError(this.handleError));;
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
