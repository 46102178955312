export class GeneralAlertsModel {
    generalAlertsId: number;
    alertDate: string;
    subject: string;
    message: string;
    productId: string;
    isGeneralAlerts: boolean;
    wirelessStateOp: string;
    wirelessStates: string;
    voipStateOp: string;
    voipStates: string;
    iprStateOp: string;
    iprStates: string;
    iprProviderOp: string;
    iprProviders: string;
    actionStateOp: string;
    actionStates: string;
    actionTypeOp: string;
    actionTypes: string;
    actionCategoryOp: string;
    actionCategories: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    emailStatus: string;
    createdUser: string;
    modifiedUser: string;
    totalRowCount: string;
}