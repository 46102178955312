import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MenuMasterModel } from 'src/Models/MenuMasterModel';
import { MenuService } from 'src/Services/menu.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-manage-menu',
  templateUrl: './manage-menu.component.html',
  styleUrls: ['./manage-menu.component.css']
})
export class ManageMenuComponent implements OnInit {
  isMultipleClick = false;
  menuMaster = new MenuMasterModel();
  menuId = 0;
  loginuserID = 0;
  tokenKey: string;
  isShowprogressSpinner = false;
  blockedDocument = false;
  SelectedTool: string;
  constructor(private checkAuth: CheckAuth, private titleService: Title,
    private activedRoute: ActivatedRoute, private router: Router,
    private menuService: MenuService,
    private toastrService: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Manage Menu');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

  }

  ngOnInit() {
    this.movetotop();
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.menuId = params['id'];
          this.getMenu();
        }
      }
    });
  }

  cancelmenu() {
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['/admin/' + toolName + '/menus']);
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  getMenu() {
    this.menuService.i360MenuGetBydId(this.tokenKey, this.menuId).subscribe(data => {
      if (data) {
        if (data.status == "Success") {
          this.menuMaster = data.menu;
        }
        else {
          this.toastrService.error("Error", "error");
        }
      }
    })
  }

  saveMenu(menuform) {
    if ((this.menuMaster.menuName == null || this.menuMaster.menuName.trim() == '')) {
      this.toastrService.error('Please enter name.', 'Required');
      return;
    }
    if ((this.menuMaster.shortDiscription == null || this.menuMaster.shortDiscription.trim() == '')) {
      this.toastrService.error('Please enter short discription.', 'Required');
      return;
    }
    this.menuMaster.modifiedBy = this.loginuserID;
    this.menuService.i360MenuMasterUpdate(this.tokenKey, this.menuMaster).subscribe(data => {
      if (data.status == "Success") {
        this.toastrService.success("Menu updated successfully", "success");
        this.unblockDocument();
        this.cancelmenu();
      }
      else {
        this.toastrService.error("Error", "error");
      }
    })
  }

  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
}
