import { Component, HostListener, OnInit } from '@angular/core';
import { CarrierCategoryAdminModel } from 'src/Models/CarrierCategoryAdminModel';
import { FilterModel } from 'src/Models/filterModel';
import { CarrierCategoryService } from 'src/Services/carriercategory.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-carrier-category',
  templateUrl: './carrier-category.component.html',
  styleUrls: ['./carrier-category.component.css']
})
export class CarrierCategoryComponent implements OnInit {
  carrierCategoryList: CarrierCategoryAdminModel[];
  filterParameter = new FilterModel();

  tokenKey: string = '';
  loginuserID: number = 0;
  SelectedTool: string = '';
  totalRecordCount: number = -1;
  isloading: boolean = false;

  upDowSortCSS: string='';
  upDowCarrierCatNameCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowIsVisiblityTypeNameCSS: string = '';
  isAscending: boolean;
  deleteRrecordId: number = 0;
  openMenuIndex: number = -1;

  constructor(private checkAuth: CheckAuth, private toastr: ToastrService, private titleService: Title, private router: Router, private carrierService: CarrierCategoryService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    this.filterParameter.pageNumber = '1';
    this.filterParameter.pageSize = '10';
    this.filterParameter.whereCondition = '';
    this.filterParameter.orderBy = 'SortOrder';
    this.titleService.setTitle('Carrier Types');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.GetCarrierCategoryByPage();
  }

  GetCarrierCategoryByPage() {
    this.carrierCategoryList = [];
    this.isloading = true;
    this.carrierService.GetCarrierCategoryByPage(this.tokenKey, this.filterParameter).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.carrierCategoryList = data['carrierCategoryList'];
          if (this.carrierCategoryList.length > 0) {
            this.totalRecordCount = data['carrierCategoryList'][0]['totalRowCount'];
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  pageChanged($event: any) {
    this.filterParameter.pageNumber = parseInt($event.page).toString();
    this.GetCarrierCategoryByPage();
  }

  sort(sortBy: any) {
    this.upDowSortCSS = '';
    this.upDowCarrierCatNameCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowIsVisiblityTypeNameCSS = '';

    if (!this.isAscending) {
      this.isAscending = true;
      this.filterParameter.orderBy = sortBy + ' DESC';

      if (sortBy === "CarrierCatName") {
        this.upDowCarrierCatNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "sortOrder") {
        this.upDowSortCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "visiblityTypeName") {
        this.upDowIsVisiblityTypeNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "ModifiedDate") {
        this.upDowModifiedDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "IsActive") {
        this.upDowIsActiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.filterParameter.orderBy = sortBy;

      if (sortBy === "CarrierCatName") {
        this.upDowCarrierCatNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "sortOrder") {
        this.upDowSortCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "visiblityTypeName") {
        this.upDowIsVisiblityTypeNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "ModifiedDate") {
        this.upDowModifiedDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "IsActive") {
        this.upDowIsActiveCSS = 'fa fa-arrow-up';
      }
    }
    this.GetCarrierCategoryByPage();
  }

  SetDeleteRecordsId(CarrierCatId: any) {
    this.deleteRrecordId = CarrierCatId;
  }

  DeleteCarrierCategory() {
    this.carrierService.CarrierCategoryAdminDelete(this.tokenKey, this.deleteRrecordId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.showMessage('Record deleted successfully', 'Sucess', 'success');
          this.GetCarrierCategoryByPage();
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  editCarrierCategory(CarrierCatId: any) {
    this.router.navigate(['/admin/trac-it/carriertype/manage/' + CarrierCatId])
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
