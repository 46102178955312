import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})
export class AccessDeniedComponent implements OnInit {
  AccessDeniedTool: string = 'selected'
  constructor() {
    
    try {
      this.AccessDeniedTool = sessionStorage.getItem('AccessDeniedTool');
      if (this.AccessDeniedTool.toLocaleLowerCase() == "ipr")
        this.AccessDeniedTool = "iPR";
      else if (this.AccessDeniedTool.toLocaleLowerCase() == "voip")
        this.AccessDeniedTool = "VoIP";
    }
    catch (error) {
    }
  }

  ngOnInit() {
  }

}
