import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterContentModel } from 'src/Models/TRFAContentModel';

@Injectable({
  providedIn: 'root'
})
export class TrfaContentMasterService {
  AppUrl: string = "";
  filterContentModel = new FilterContentModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }


  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllContent(TokenKey: string, PageNumber: number, PageSize: number, stateID: string, fundID: string, carrierTypeID: string, categoryID: string, SourceTypeID: string, orderBy: string, auditMonth: string): Observable<any> {
    // GetAllContent(TokenKey: string, PageNumber: number, PageSize: number, stateID: string,categoryID:string,carrierTypeID:string,orderBy:string): Observable<any> {
    this.filterContentModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      stateID: stateID,
      fundID: fundID,
      carrierTypeID: carrierTypeID,
      categoryID: categoryID,
      orderBy: orderBy,
      SourceTypeID: SourceTypeID,
      auditMonth: auditMonth
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRFAContentMaster/GetAllContent';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterContentModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ContentAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRFAContentMaster/ContentAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  
  GetContentById(TokenKey: string, ContentId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFAContentMaster/GetContentById/' + ContentId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteContent(TokenKey: string, ContentId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFAContentMaster/DeleteContent/' + ContentId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetAllUpdatedView(TokenKey: string, stateID: string, fundID: string, carrierTypeID: string, categoryID: string,fundsId:string, baseUrl: string, isCompare: boolean): Observable<any> {
    // GetAllContent(TokenKey: string, PageNumber: number, PageSize: number, stateID: string,categoryID:string,carrierTypeID:string,orderBy:string): Observable<any> {
    this.filterContentModel = {
      pageNumber: 0,
      pageSize: 0,
      stateID: stateID,
      fundID: fundID,
      carrierTypeID: carrierTypeID,
      categoryID: categoryID,
      isCompare: isCompare,
      baseUrl: baseUrl,
      fundsId:fundsId
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRFAContentMaster/GetAllUpdatedView';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterContentModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

      GetAllAuditReport(TokenKey: string, PageNumber: number, PageSize: number,orderBy:string,auditMonth:string,StateId:string,FundId :string): Observable<any> {
          this.filterContentModel={
            pageNumber:PageNumber,
            pageSize:PageSize,
            orderBy:orderBy,
            auditMonth:auditMonth,
            stateID:StateId,
            fundID:FundId
          }
          this.setAPIUrl();
          let URL = this.AppUrl + 'api/TRFAContentMaster/GetAllAuditReport';
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
          return this._http.post<any>(URL,this.filterContentModel, httpOptions)
            .pipe(catchError(this.handleError));
        }
  GetAllContentByUser(TokenKey: string, PageNumber: number, PageSize: number, stateID: string, fundTypeID: string, fundID: string, carrierTypeID: string, categoryID: string, orderBy: string, note: string, baseUrl: string, userId: number, isFavorite: number): Observable<any> {
    // GetAllContent(TokenKey: string, PageNumber: number, PageSize: number, stateID: string,categoryID:string,carrierTypeID:string,orderBy:string): Observable<any> {
    this.filterContentModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      stateID: stateID,
      fundTypeID: fundTypeID,
      fundID: fundID,
      carrierTypeID: carrierTypeID,
      categoryID: categoryID,
      orderBy: orderBy,
      note: note,
      baseUrl: baseUrl,
      userId: userId,
      isFavorite: isFavorite
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRFAContentMaster/GetAllContentByUser';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterContentModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetContenthistoryById(TokenKey: string, PageNumber: number, PageSize: number, contentId: number, orderBy: string, FieldId: string) {
    this.setAPIUrl();
    let data = 'api/TRFAContentMaster/GetContenthistoryById/' + PageNumber + '/' + PageSize + '/' + contentId + '/' + orderBy + '/' + FieldId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}