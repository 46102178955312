export class TRFAUpdateModel {
    updateID: number;
    note: string;
    fundID: number;
    updateDate: string|Date;
}
export class TRFAUpdateGetModel {
    updateID: number;
    note: string;
    fundID: number;
    updateDate: string|Date;
    stateName: string;
    shortName:string;
    fundName: string;
    fundTypeName: number;
    totalRecordCount: number;
    userId:number;
}

export class FilterUpdateModel {
    pageNumber?: number;
    pageSize?: number;
    orderBy?: string;
    fundID?: string | number;
    stateID?: string | number;
    fromDate?: string;
    toDate?: string;
    isAdmin?: boolean;
    userId?:number;
    isFavorite?:number;
}
