import { Component, OnInit } from '@angular/core';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { NLADClaimService } from 'src/Services/nladClaim.service';
import { InrollProductsCompanyModel } from 'src/Models/InrollProductsCompanyModel';
import { ClaimStateModel } from 'src/Models/ClaimStateModel';
import { EBBClaimProcessDetailsModel } from 'src/Models/EBBClaimProcessDetailsModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-front-view-claim-process',
  templateUrl: './front-view-claim-process.component.html',
  styleUrls: ['./front-view-claim-process.component.css']
})
export class FrontViewClaimProcessComponent implements OnInit {
  filterModel = new FilterModel();
  listOfData: EBBClaimProcessDetailsModel[];
  tokenKey: string = '';
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false

  upDowSubscriberIDCSS: string = '';
  upDowRateCSS: string = '';
  upDowReasonCodeCSS: string = '';
  upDowDeviceBenefitCSS: string = '';
  upDowSPINCSS: string = '';
  upDowSACCSS: string = '';
  upDowLastNameCSS: string = '';
  upDowFirstNameCSS: string = '';
  upDowStreetAddressCSS: string = '';
  upDowCityCSS: string = '';
  upDowStateCSS: string = '';
  upDowZipCSS: string = '';
  upDowPhoneNumberCSS: string = '';
  upDowETCGeneralCSS: string = '';
  upDowServiceTypeCSS: string = '';
  upDowTribalBenefitFlagCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowYearsCSS: string = '';
  upDowMonthsCSS: string = '';

  dropdownMonths = [];
  selectedItemsMonths = [];
  dropdownSettingMonths = {};
  listOfMonths = [];

  dropdownYears = [];
  selectedItemsYears = [];
  dropdownSettingYears = {};
  listOfYears = [];
  monthText = [];

  listOfInrollProductCompany: InrollProductsCompanyModel[];
  dropdownCompanies = [];
  selectedItemsCompanies = [];
  dropdownSettingCompanies = {};
  listOfCompany = [];

  listOfStateData: ClaimStateModel[];
  dropdownStates = [];
  selectedItemsStates = [];
  dropdownSettingStates = {};
  listOfStates = [];

  companyName: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  isloading = false;

  constructor(private nladClaimService: NLADClaimService, private checkAuth: CheckAuth, private titleService: Title, private router: Router) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Detail Claims- ACP | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'Id';
    this.filterModel.userID = this._userID;
    this.filterModel.productId = 0;
    this.filterModel.months = '0';
    this.filterModel.years = '0';
    this.filterModel.state = '';
    if (sessionStorage.getItem('ViewClaimMasterId') != null)
      this.filterModel.claimMasterId = sessionStorage.getItem('ViewClaimMasterId');
    if (sessionStorage.getItem('ViewCompanyName') != null)
      this.companyName = sessionStorage.getItem('ViewCompanyName');
    this.EBBClaimDetailsReports();
    // this.GetMonths();
    // this.GetYears();
    //this.GetInrollClientCompany();
    this.GetClaimState();

    this.monthText.push({ 'id': 1, 'itemName': 'January' });
    this.monthText.push({ 'id': 2, 'itemName': 'February' });
    this.monthText.push({ 'id': 3, 'itemName': 'March' });
    this.monthText.push({ 'id': 4, 'itemName': 'April' });
    this.monthText.push({ 'id': 5, 'itemName': 'May' });
    this.monthText.push({ 'id': 6, 'itemName': 'June' });
    this.monthText.push({ 'id': 7, 'itemName': 'July' });
    this.monthText.push({ 'id': 8, 'itemName': 'August' });
    this.monthText.push({ 'id': 9, 'itemName': 'September' });
    this.monthText.push({ 'id': 10, 'itemName': 'October' });
    this.monthText.push({ 'id': 11, 'itemName': 'November' });
    this.monthText.push({ 'id': 12, 'itemName': 'December' });
  }

  EBBClaimDetailsReports() {

    this.blockDocument();
    this.listOfData = [];
    this.isloading = true;
    this.nladClaimService.ViewClaimProcessDetailsPaged(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        this.unblockDocument();
        if (data != null) {
          this.listOfData = data['listOfData'];
          if (this.listOfData != null) {
            if (this.listOfData.length > 0) {
              this.totalRecordCount = this.listOfData[0].totalRecords;
            }
            else
              this.totalRecordCount = 0;
          }
          else
            this.totalRecordCount = 0;
        }
      },
      error => {
        this.isloading = false;
        this.unblockDocument();
        console.log(error);
      }
    );
  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.EBBClaimDetailsReports();
  }

  sort(sortBy: any) {
    this.upDowSubscriberIDCSS = '';
    this.upDowRateCSS = '';
    this.upDowReasonCodeCSS = '';
    this.upDowDeviceBenefitCSS = '';
    this.upDowSPINCSS = '';
    this.upDowSACCSS = '';
    this.upDowLastNameCSS = '';
    this.upDowFirstNameCSS = '';
    this.upDowStreetAddressCSS = '';
    this.upDowCityCSS = '';
    this.upDowStateCSS = '';
    this.upDowZipCSS = '';
    this.upDowPhoneNumberCSS = '';
    this.upDowETCGeneralCSS = '';
    this.upDowServiceTypeCSS = '';
    this.upDowTribalBenefitFlagCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowYearsCSS = '';
    this.upDowMonthsCSS = '';

    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'subscriberID': this.upDowSubscriberIDCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'rate': this.upDowRateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'reasonCode': this.upDowReasonCodeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'deviceBenefit': this.upDowDeviceBenefitCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'spin': this.upDowSPINCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'sac': this.upDowSACCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'lastName': this.upDowLastNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'firstName': this.upDowFirstNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'streetAddress': this.upDowStreetAddressCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'city': this.upDowCityCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'state': this.upDowStateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'zip': this.upDowZipCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'phoneNumber': this.upDowPhoneNumberCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'etcGeneral': this.upDowETCGeneralCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'serviceType': this.upDowServiceTypeCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'tribalBenefitFlag': this.upDowTribalBenefitFlagCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'createdDate': this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'years': this.upDowYearsCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'months': this.upDowMonthsCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.OrderBy = sortBy;
    this.EBBClaimDetailsReports();
  }

  setMonthsText(id) {
    return this.monthText.filter(m => m.id == id)[0].itemName;
  }

  removeElemetninArrayById(array, element) {
    return array.filter(e => e.id !== element);
  }

  GetClaimState() {
    this.nladClaimService.GetClaimState(this.tokenKey).subscribe(
      data => {
        if (data != null) {
          this.listOfStateData = data['listOfData'];
          if (this.listOfStateData != null) {
            if (this.listOfStateData.length > 0) {

              for (let iCounter = 0; iCounter < this.listOfStateData.length; iCounter++) {
                this.dropdownStates.push({ 'id': this.listOfStateData[iCounter].shortName, 'itemName': this.listOfStateData[iCounter].name });
              }
            }
          }
          this.dropdownSettingStates = {
            singleSelection: true,
            text: 'Select State',
            enableSearchFilter: true,
            classes: 'borderLessTextBoxGreen',
            badgeShowLimit: 1,
            noDataLabel: 'No State',
            showCheckbox: false,
            enableFilterSelectAll: false
          };
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedStates($event) {

    this.listOfStates = this.removeElemetninArrayById(this.listOfStates, $event.id)
    this.listOfStates.push($event.id);
    this.filterModel.pageNumber = '1';
    this.filterModel.state = $event.id;
    this.EBBClaimDetailsReports();
  }

  onDeSelectedStates($event) {
    if (this.listOfStates != null) {
      if (this.listOfStates.length > 0) {
        this.listOfStates = this.removeElemetninArrayById(this.listOfStates, $event.id)
      }
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.state = $event.id;
    this.EBBClaimDetailsReports();
  }

  onSelectAllStates($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStates != null) {
        if (this.listOfStates.length > 0) {
          this.listOfStates = this.removeElemetninArrayById(this.listOfStates, $event[iCounter].id)
          this.listOfStates.push($event[iCounter].id);
        }
        else {
          this.listOfStates.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.state = $event[0].id;
    this.EBBClaimDetailsReports();
  }

  onDeSelectAllStates($event) {
    this.listOfStates = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.state = '';
    this.EBBClaimDetailsReports();
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  redirectToListPage() {
    this.router.navigate(['/Claim/Import']);
  }
}
