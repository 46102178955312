import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNewActionModel } from 'src/Models/AddNewActionModel';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersService } from '../../../Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';
import { UserModel } from 'src/Models/usersModel';
import { ActionItemService } from '../../../Services/actionitem.service';
import { ToastrService } from 'ngx-toastr';
import { MatrixService } from 'src/Services/matrix.service';
import { ActionGetByIdModel } from 'src/Models/ActionGetByIdModel';

@Component({
  selector: 'app-edit-action-popup',
  templateUrl: './edit-action-popup.component.html',
  styleUrls: ['./edit-action-popup.component.css']
})
export class EditActionPopupComponent implements OnInit {
  @Input() IsActionFlag: any;
  @Input() title: string;
  // @Input() body: string;
  // @Output() delete = new EventEmitter<any>();
  addNewActionModel = new AddNewActionModel();
  userModel: UserModel[];

  loginUser: number = 0;
  message: string;
  isCheckVisibleToAllCompanyUsers: boolean;
  tokenKey: string = '';
  companyId: number = 0;

  dropdownUserSettings = {};
  selectedItemsUser = [];
  dropdownListUser = [];

  dropdownCarrierCategoriesSettings = {};
  selectedItemsCarrierCategories = [];
  dropdownListCarrierCategories = [];
  listofcarriercategories: number[] = [];

  dropdownStateSettings = {};
  selectedItemsState = [];
  dropdownListState = [];
  listofState: number[] = [];
  productId: number;

  listOfAssignToUser = [];

  addUpdateActionItem = new FormGroup({
    assignToUser: new FormControl(''),
    actionItemId: new FormControl(''),
    deadLineDate: new FormControl(''),
    subject: new FormControl(''),
    description: new FormControl(''),
    visibleToAllCompanyUsers: new FormControl(''),
    state: new FormControl(''),
    carrierCategories: new FormControl(''),
  });

  EditActionId: number = 0;
  editModel = new ActionGetByIdModel();
  isOneClickOnButton: boolean = false;

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  constructor(private matrix: MatrixService, private toastr: ToastrService, private actionItemServices: ActionItemService, private router: Router, public activeModal: NgbActiveModal, private usersService: UsersService, private checkAuth: CheckAuth) {
    this.title = 'Edit Trac-It';
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    // this.setCurrentDateToCalender();

  }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyId = +sessionStorage.getItem("CompanyId");
    this.loginUser = +sessionStorage.getItem('UserId');
    this.productId = +sessionStorage.getItem('productId');
    this.EditActionId = +sessionStorage.getItem('EditActionId');
    this.ActionGetById();

  }

  viewAllCompanyUser($event) {
    this.isCheckVisibleToAllCompanyUsers = $event.target.checked;
    this.selectedItemsUser = [];
    this.UserDropDownSettings();
  }

  pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  ActionGetById() {
    this.actionItemServices.ActionGetById(this.tokenKey, this.EditActionId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.editModel = data['model'];
          if (this.editModel != null) {
            const dd = new Date(this.editModel.deadlineDate);
            var dDate = [this.pad(dd.getMonth() + 1), this.pad(dd.getDate()), dd.getFullYear()].join('/');

            this.addUpdateActionItem.setValue({
              assignToUser: [],
              actionItemId: this.editModel.actionId,
              deadLineDate: dDate,
              subject: this.editModel.subject,
              description: this.editModel.description,
              visibleToAllCompanyUsers: this.editModel.isForAllUsers,
              state: [],
              carrierCategories: [],
            });

            this.isCheckVisibleToAllCompanyUsers = this.editModel.isForAllUsers;
            this.addNewActionModel.assignToUser = this.editModel.assignToId.toString();
            this.UserDropDownSettings();
            // this.addNewActionModel.actionCharges = '0';
            // this.addNewActionModel.actionItemId = this.editModel.actionId;
            // this.addNewActionModel.actionNotes = '';
            // this.addNewActionModel.actionType = '';
            // this.addNewActionModel.assignToUser = this.editModel.assignToId.toString();
            // this.addNewActionModel.carrierCategories = '';
            // this.addNewActionModel.companyId = 0;
            // this.addNewActionModel.createdBy = this.loginUser;            
            // this.addNewActionModel.deadLineDate = dDate;
            // this.addNewActionModel.description = this.editModel.description;
            // this.addNewActionModel.visibleToAllCompanyUsers = this.editModel.isForAllUsers;

            this.GetUsersFindByCompanyId(this.companyId);
            this.GetCarrierCategories();
            this.GetStates(this.loginUser, this.productId);
          }

        }
        else {
          this.showMessage(data['status'], 'Error', 'error');
        }
      }
    );
  }

  saveAddUpdateActionItem(addUpdateActionItem: any, isButtonDisable: boolean) {

    event.preventDefault();

    if (addUpdateActionItem.value['deadLineDate'] == undefined || addUpdateActionItem.value['deadLineDate'] == '') {
      this.showMessage('Please select deadline date', 'Error', 'error');
      return;
    }
    let currentDate = new Date();
    if (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) > addUpdateActionItem.value["deadLineDate"]) {
      this.showMessage('Previous deadline date not allow', 'Error', 'error');
      return;
    }
    if (addUpdateActionItem.value['subject'] == undefined || addUpdateActionItem.value['subject'] == '') {
      this.showMessage('Please enter subject', 'Error', 'error');
      return;
    }
    if (addUpdateActionItem.value['description'] == undefined || addUpdateActionItem.value['description'] == '') {
      this.showMessage('Please enter description', 'Error', 'error');
      return;
    }
    if (this.listofState.length == 0) {
      this.showMessage('Please select state', 'Error', 'error');
      return;
    }
    if (this.listofcarriercategories.length == 0) {
      this.showMessage('Please select carrier type summary', 'Error', 'error');
      return;
    }

    this.addNewActionModel.state = this.listofState.join(',');
    this.addNewActionModel.carrierCategories = this.listofcarriercategories.join(',');

    if (addUpdateActionItem.value['actionItemId'] != 'undefined' && addUpdateActionItem.value['actionItemId'] != undefined)
      this.addNewActionModel.actionItemId = addUpdateActionItem.value['actionItemId'];
    else
      this.addNewActionModel.actionItemId = 0;

    if (this.addNewActionModel.assignToUser == null)
      this.addNewActionModel.assignToUser = '0';

    if (addUpdateActionItem.value['visibleToAllCompanyUsers'] != 'undefined' && addUpdateActionItem.value['visibleToAllCompanyUsers'] != undefined)
      this.addNewActionModel.visibleToAllCompanyUsers = addUpdateActionItem.value['visibleToAllCompanyUsers'];
    else
      this.addNewActionModel.visibleToAllCompanyUsers = false;

    if (typeof (addUpdateActionItem.value['description']) != undefined)
      this.addNewActionModel.description = addUpdateActionItem.value['description'];
    else
      this.addNewActionModel.description = null;

    this.addNewActionModel.subject = addUpdateActionItem.value['subject'];
    this.addNewActionModel.createdBy = +sessionStorage.getItem('UserId');
    this.addNewActionModel.companyId = this.companyId;
    this.addNewActionModel.actionItemId = this.EditActionId;
    this.addNewActionModel.productId = this.productId.toString();

    if (isButtonDisable) {      
      return;
    }

    this.isOneClickOnButton = true;

    this.actionItemServices.EditActionItem(this.tokenKey, this.addNewActionModel).subscribe(
      data => {
        this.isOneClickOnButton = false;
        if (data['status'] == 'Success') {
          this.showMessage('Entry updates successfully', 'Success', 'success');
          this.activeModal.close('Yes');
        }
        else {
          this.showMessage(data['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
        this.isOneClickOnButton = false;
      }
    );
  }

  GetUsersFindByCompanyId(companyId: any) {
    this.usersService.GetUsersFindByCompanyIdRemoveLoginUser(this.tokenKey, companyId, this.productId, this.loginUser).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.userModel = data['companyUsers'];
          if (this.userModel != null) {
            if (this.userModel.length > 0) {
              for (let iCounter = 0; iCounter < this.userModel.length; iCounter++) {
                this.dropdownListUser.push({ "id": parseInt(this.userModel[iCounter].userId), "itemName": this.userModel[iCounter].userName });
              }

              if (this.editModel != null) {
                if (this.editModel.assignToId > 0) {
                  let model = this.userModel.filter(m => +m.userId == this.editModel.assignToId);
                  this.selectedItemsUser.push({ "id": parseInt(model[0].userId), "itemName": model[0].userName });
                  this.addNewActionModel.previousAssingId = model[0].userId;
                }
              }
            }
          }
        }
        this.UserDropDownSettings();

      });
  }

  UserDropDownSettings() {
    this.dropdownUserSettings = {
      singleSelection: true,
      text: "Select User",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No user available',
      showCheckbox: false,
      disabled: !this.isCheckVisibleToAllCompanyUsers
    };
  }
  onSelectedUser($event) {
    this.addNewActionModel.assignToUser = $event['id'];
  }

  onDeSelectedUser($event) {
    this.addNewActionModel.assignToUser = null;
  }

  onDeSelectAllUser($event) {
    this.addNewActionModel.assignToUser = null;
  }

  GetCarrierCategories() {
    this.actionItemServices.GetCarrierCategories(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.listofcarriercategories = [];
          let carrierCategories = data['companyModel'];
          if (carrierCategories != null) {
            if (carrierCategories.length > 0) {
              for (let iCounter = 0; iCounter < carrierCategories.length; iCounter++) {
                this.dropdownListCarrierCategories.push({ "id": parseInt(carrierCategories[iCounter].carrierCatId), "itemName": carrierCategories[iCounter].carrierCatName });
              }

              if (this.editModel != null) {
                if (this.editModel.listOfCarrierCategory != null) {
                  if (this.editModel.listOfCarrierCategory.length > 0) {
                    for (let iCounter = 0; iCounter < this.editModel.listOfCarrierCategory.length; iCounter++) {
                      this.selectedItemsCarrierCategories.push({ "id": this.editModel.listOfCarrierCategory[iCounter].carrierCatId, "itemName": this.editModel.listOfCarrierCategory[iCounter].carrierName });
                      this.listofcarriercategories.push(this.editModel.listOfCarrierCategory[iCounter].carrierCatId);
                    }
                  }
                }
              }
            }
          }
        }

        this.dropdownCarrierCategoriesSettings = {
          singleSelection: false,
          text: "Select Carrier Type Summary",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No carrier type summary available',
          showCheckbox: true
        };
      });
  }

  onSelectedCarrierCategories($event) {
    if (this.listofcarriercategories != null) {
      if (this.listofcarriercategories.length > 0) {
        this.listofcarriercategories = this.removeElemetninArray(this.listofcarriercategories, $event.id)
        this.listofcarriercategories.push($event.id);
      }
      else {
        this.listofcarriercategories.push($event.id);
      }
    }
  }

  onDeSelectedCarrierCategories($event) {
    if (this.listofcarriercategories != null) {
      if (this.listofcarriercategories.length > 0) {
        this.listofcarriercategories = this.removeElemetninArray(this.listofcarriercategories, $event.id)
      }
    }
  }

  onSelectAllCarrierCategories($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listofcarriercategories != null) {
        if (this.listofcarriercategories.length > 0) {
          this.listofcarriercategories = this.removeElemetninArray(this.listofcarriercategories, $event[iCounter].id)
          this.listofcarriercategories.push($event[iCounter].id);
        }
        else {
          this.listofcarriercategories.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllCarrierCategories($event) {
    this.listofcarriercategories = [];
  }

  GetStates(userId: any, productId: any) {
    this.matrix.getState(this.tokenKey, userId, productId).subscribe(
      data => {
        if (data["state"] != null) {
          this.selectedItemsState = [];
          for (let iCounter = 0; iCounter < data["state"].length; iCounter++) {
            this.dropdownListState.push(
              { "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name }
            );
          }

          if (this.editModel != null) {
            if (this.editModel.listOfState != null) {
              if (this.editModel.listOfState.length > 0) {
                for (let iCounter = 0; iCounter < this.editModel.listOfState.length; iCounter++) {
                  this.selectedItemsState.push({ "id": this.editModel.listOfState[iCounter].stateId, "itemName": this.editModel.listOfState[iCounter].stateName });
                  this.listofState.push(this.editModel.listOfState[iCounter].stateId);
                }
              }
            }
          }
        }

        this.dropdownStateSettings = {
          singleSelection: false,
          text: "Select State(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No state available',
          showCheckbox: true,
          enableFilterSelectAll: false,
          enableCheckAll: true
        };
      }, error => {

      })
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectedState($event) {
    if (this.listofState != null) {
      if (this.listofState.length > 0) {
        this.listofState = this.removeElemetninArray(this.listofState, $event.id)
        this.listofState.push($event.id);
      }
      else {
        this.listofState.push($event.id);
      }
    }
  }

  onDeSelectedState($event) {
    if (this.listofState != null) {
      if (this.listofState.length > 0) {
        this.listofState = this.removeElemetninArray(this.listofState, $event.id)
      }
    }
  }

  onSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listofState != null) {
        if (this.listofState.length > 0) {
          this.listofState = this.removeElemetninArray(this.listofState, $event[iCounter].id)
          this.listofState.push($event[iCounter].id);
        }
        else {
          this.listofState.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllState($event) {
    this.listofState = [];
  }

  isDeadlineDate(dateArray: any) {
    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      this.addNewActionModel.deadLineDate = mm1 + '-' + dd1 + '-' + dateArray.getFullYear();
    }
    else
      this.addNewActionModel.deadLineDate = null;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
}
