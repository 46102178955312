import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/Services/app.config';
import { CrraCategoryMasterService } from 'src/Services/crra-category-master.service';
import { CrraSubCategoryMasterService } from 'src/Services/crra-sub-category-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-crra-sub-category-add-update',
  templateUrl: './crra-sub-category-add-update.component.html',
  styleUrls: ['./crra-sub-category-add-update.component.css']
})
export class CrraSubCategoryAddUpdateComponent implements OnInit {

  subcategoryMasterForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/

  listOfCategoryIDs = [];
  categoryList: any[] = [];
  selectedItemsCategory = [];
  dropdownDateCategoryFilter = [];
  dropdownSettingsDateCategoryFilter = {};


  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute,
    private router: Router, private crraCategoryMaster: CrraCategoryMasterService, private crrasubCategoryMaster: CrraSubCategoryMasterService, private toastr: ToastrService) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.isSubmit = false;
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.dropdownSettingsDateCategoryFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Category(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };


    this.subcategoryMasterForm = this._fb.group({
      subCategoryId: 0,
      categoryId: ['', Validators.required],
      subcategoryName: ['', Validators.required],
      order: ['', Validators.required],
    })
    this.getCategory();
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetSubCategoryById(params['id']);
        }
      }
    });
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  cancelcategoryMaster() {
    let navigation = '/admin/' + this.SelectedTool + '/subCategory';
    this.router.navigate([navigation]);
  }

  getCategory() {
    this.crraCategoryMaster.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryList = result['crracategory'];
          this.dropdownDateCategoryFilter = [];
          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilter.push({ "id": x.categoryId, "itemName": x.categoryName })
          });
        }
      },
      error => { }
    );
  }

  onDeSelectDateFilterCategoryAll($event) {
    this.selectedItemsCategory = [];
  }

  onChangeCategory(event) {
    this.subcategoryMasterForm.patchValue({
      categoryId: event.id

    });
  }

  GetSubCategoryById(subCategoryId: number) {
    this.crrasubCategoryMaster.GetSubCategoryById(this.tokenKey, subCategoryId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.subcategoryMasterForm.patchValue({
            subCategoryId: result['crrasubcategory'].subCategoryId,
            subcategoryName: result['crrasubcategory'].subCategoryName,
            order: result['crrasubcategory'].order,
            categoryId: result['crrasubcategory'].categoryId
          });

          if (this.categoryList.length > 0) {
            let data = this.categoryList.filter(x => x.categoryId == result['crrasubcategory'].categoryId)[0];
            this.selectedItemsCategory.push({ "id": data.categoryId, "itemName": data.categoryName })         
          }
          else
            setTimeout(() => {
              let data = this.categoryList.filter(x => x.categoryId == result['crrasubcategory'].categoryId)[0];
              this.selectedItemsCategory.push({ "id": data.categoryId, "itemName": data.categoryName })            
            }, 800);
        }
      });
  }

  save() {
    this.isSubmit = true;
    let data = this.subcategoryMasterForm.value;
    if (!this.subcategoryMasterForm.valid || !data.subcategoryName.trim() || this.selectedItemsCategory.length == 0)
      return
    let value = {
      subcategoryId: data.subCategoryId,
      subcategoryName: data.subcategoryName.trim(),
      order: data.order,
      categoryId: data.categoryId
    }
    this.crrasubCategoryMaster.SubCategoryAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {

            if (data.categoryId > 0)
              this.showMessage('category updated successfully', 'Success', 'success');
            else
              this.showMessage('category saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/subCategory']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
}
