import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { FavoriteFilterModel } from 'src/Models/FavoriteFilterModel';
import { FilterModel } from 'src/Models/filterModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TRSNoticeModel } from 'src/Models/TRSNoticeModel';
import { Update911GetModel } from 'src/Models/Update911Model';
import { FavoriteService } from 'src/Services/favorite.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrsMasterService } from 'src/Services/trs-master.service';
import { CheckAuth } from 'src/Services/utility.service';
import * as FileSaver from 'file-saver';
import { ApplicationActivityStore } from "src/common/commonFunction";
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { Title } from '@angular/platform-browser';
import { ManageTRSModel } from 'src/Models/ManageTRSModel';

@Component({
  selector: 'app-trscustomer',
  templateUrl: './trscustomer.component.html',
  styleUrls: ['./trscustomer.component.css']
})
export class TRSCustomerComponent implements OnInit {

  listofUpdate: Update911GetModel[];
  isColumnExist:boolean =true;

  tokenKey: string;
  dropdownSettingsDateFilter = {};
  pageSizeOptions:any[]=[];
  selectedPageSize = [];
  SelectedTool: string;
  ProductId:number = 0;
  _userID:any;
  upDowNameCSS: string = '';
  isAscending: boolean = false;
  OrderByName: string = '';

  PageNumber: number = 1;
  PageSize: number = 10;
  OrderBy: string;

  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateList: StateMasterModel[] = [];
  stateId: string = ' ';

  productId: number = 1;
  trsId: number = 0;
  isloading: boolean;
  module = "Inteserra360";

  stateCode: any;
  filterModel = new FilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfTRSNotice: TRSNoticeModel[];

  upDowStateNameCSS: string = '';
  upDowDirecCSS = '';
  upDowBillDocCSS = '';
  upDowURLCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';

  SelectedStateFav: number = 0;
  favouriteStateModel: FavoriteFilterModel[] = [];
  blockedDocument = false;
  isShowprogressSpinner = false;
  displayFavorite:number=0;
  objManageTRSModel = new ManageTRSModel();

  constructor(private checkAuth: CheckAuth,private stateService: StateMasterService,private router: Router,private trsNoticeService: TrsMasterService,
    private toastr: ToastrService,private favorite: FavoriteService,private appActivity: ApplicationActivityService, private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.ProductId = ToolEnum.TRSCustomerNotice;
    this._userID = sessionStorage.getItem('UserId');
    this.upDowNameCSS = 'active'
    this.isAscending = false;
    this.OrderBy = 'SortOrder desc,StateName';
    this.isColumnExist=false;
    this.titleService.setTitle('TRS Customer Notice');
   }

  ngOnInit() {
    this.getHomeDetail();

    this.dropdownSettingsDateFilter = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No State(s) available',
      showCheckbox: true
    };
    this.getSubscriptionState();
    this.PageNumber = 1;
    this.PageSize = 10;
    this.OrderBy = 'SortOrder desc,StateName';

    this.GetTRSNotice();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  getHomeDetail(){
    this.trsNoticeService.GetManageTRSDetails(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        this.objManageTRSModel = data.result;
      }
    });
  }

  getSubscriptionState() {
    
    this.stateService.getState(this.tokenKey,this.ProductId).subscribe(
      data => {
        this.stateList = data.state;
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name ,"shortName":x.shortName})
          });
        }
      },
      error => { console.log(error); }
    );
  }

  GetTRSNotice() {
    this.isloading = true;

    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }

    this.listOfTRSNotice = [];
    this.trsNoticeService.GetAllTRSNoticeFront(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, this.OrderBy,this._userID,this.displayFavorite).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfTRSNotice = data['trsnotice'];
          if (this.listOfTRSNotice != null) {
            if (this.listOfTRSNotice.length > 0) {
              this.totalRecordCount = this.listOfTRSNotice[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filterModel.keywordFilter = $event.target.value;
    }
    else {
      this.filterModel.keywordFilter = '';
    }
    this.GetTRSNotice();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.PageNumber=$event.page;
    this.GetTRSNotice();
  }

  sort(sortBy: any) {

    this.upDowStateNameCSS = '';
    this.upDowDirecCSS = '';
    this.upDowBillDocCSS = '';
    this.upDowURLCSS = '';
    this.OrderByName=sortBy;

    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active-up';
      }
      else if (sortBy === "directoryDocName") {
        this.upDowDirecCSS = 'active-up';
      }
      else if (sortBy === "billInsertDocName") {
        this.upDowBillDocCSS = 'active-up';
      }
      else if (sortBy === "url") {
        this.upDowURLCSS = 'active-up';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active';
      }
      else if (sortBy === "directoryDocName") {
        this.upDowDirecCSS = 'active';
      }
      else if (sortBy === "billInsertDocName") {
        this.upDowBillDocCSS = 'active';
      }
      else if (sortBy === "url") {
        this.upDowURLCSS = 'active';
      }
    }
    this.GetTRSNotice();
  }

  AddToFavourite(item, IsFavourite: number) {
    let stateId = item.stateId;
    if (IsFavourite == 0) {
      this.SelectedStateFav = stateId;
      this.favouriteStateModel = item;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavourite(stateId, IsFavourite, item);
    }
  }

  SaveFavourite(stateId: string, IsFavourite: number, item)
  {
    this.blockDocument();
    document.getElementById("btnClose").click();
    this.favorite.ClientStateFavouriteAdd(this.tokenKey, parseInt(this._userID), stateId, IsFavourite, ToolEnum.TRSCustomerNotice).subscribe(data => 
    {
      if (data["status"] == "Success") 
      {
        this.GetTRSNotice();
        if (IsFavourite == 1) {
          this.showMessage('Selected jurisdiction added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          this.showMessage('Selected jurisdiction removed successfully from favorite list.', 'Favorite', 'success');
        }        
      }
      else 
      {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
      }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }

    
  }

  downloadDocument(fileData,docPath)
  {
    FileSaver.saveAs(fileData, docPath);
    ApplicationActivityStore('Download', 'TRS Document Download', this.appActivity, ToolEnum.TRSCustomerNotice, ActionActivity['TRS Customer Notice'], '/TRSCustomer');
  }
  
  GetFavourite(displayFav:number)
  {
    this.displayFavorite=displayFav;
    this.GetTRSNotice();
  }

  getPageLength() {
    // if (this.PageSize < this.totalRecordCount) return this.PageSize
    // else return this.totalRecordCount
    return Math.min((this.PageNumber * this.PageSize),this.totalRecordCount);
  }

  onSelectState($event) {
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetTRSNotice();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetTRSNotice();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetTRSNotice();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.GetTRSNotice();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }
  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetTRSNotice();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetTRSNotice();
  }
}
