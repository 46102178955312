import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PricingQuotingBaserateService {

  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }


  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllBaseRate(TokenKey: string, PageNumber: number, PageSize: number, filterText: string,orderBy:string,baseRateType:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/BaseRate/GetAllBaseRate/' + filterText + '/' + PageNumber + '/' + PageSize+'/'+orderBy+'/'+baseRateType;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  BaseRateAddUpdate(TokenKey: string, addNewBaseRateModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/BaseRate/BaseRateAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewBaseRateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetBaseRateById(TokenKey: string,baseRateId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/BaseRate/GetBaseRateById/' + baseRateId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteBaseRate(TokenKey: string,baseRateId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/BaseRate/DeleteBaseRate/' + baseRateId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }


  GetAllBaseRateType(TokenKey: string, PageNumber: number, PageSize: number, filterText: string,orderBy:string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/BaseRateType/GetAllBaseRateType/' + filterText + '/' + PageNumber + '/' + PageSize+'/'+orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  BaseRateTypeAddUpdate(TokenKey: string, addNewBaseRateModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/BaseRateType/BaseRateTypeAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewBaseRateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetBaseRateTypeById(TokenKey: string,baseRateId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/BaseRateType/GetBaseRateTypeById/' + baseRateId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteBaseRateType(TokenKey: string,baseRateTypeId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/BaseRateType/DeleteBaseRateType/' + baseRateTypeId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  BaseRateTypeAll(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/BaseRateType/BaseRateTypeAll';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
