import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PriceQuotePortalCredentialModel } from 'src/Models/Price_Quote_PortalCredentialModel';
import { AppConfig } from 'src/Services/app.config';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompletedPhase3, getIncompletedStepPhase3 } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-inteserra-portal-credentials',
  templateUrl: './inteserra-portal-credentials.component.html',
  styleUrls: ['./inteserra-portal-credentials.component.css']
})
export class InteserraPortalCredentialsComponent implements OnInit {

  CompanyProfileInformationForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;

  ClientInformationId: number;

  mainUrl: string;
  subUrl: string;
  currentActiveTab: number = 1;

  isServiceType: boolean = false;
  isRemovingUser: boolean = false;
  isREMSApprover: boolean = false;
  isTaxPortalCredential: boolean = false;

  person1SelectedItems: any;
  person2SelectedItems: any;
  person3SelectedItems: any;

  ClientRequestTypeIDs = [];
  ClientRequestTypeList: any[] = [];
  SelectedClientRequestType = [];
  dropdownSettingsClientRequestTypeFilter = {};

  ServiceTypeIDs = [];
  ServiceTypeList: any[] = [];
  SelectedServiceType = [];
  dropdownSettingsServiceTypeFilter = {};

  ServicesToRemoveIDs = [];
  ServicesToRemoveList: any[] = [];
  SelectedServicesToRemove = [];
  dropdownSettingsServicesToRemoveFilter = {};

  PersonStillEmployedIDs = [];
  PersonStillEmployedList: any[] = [];
  SelectedPersonStillEmployedType = [];
  dropdownSettingsPersonStillEmployedFilter = {};

  PersonnelAuthorityIDs = [];
  PersonnelAuthorityList: any[] = [];
  SelectedPersonnelAuthorityType = [];
  dropdownSettingsPersonnelAuthorityFilter = {};

  Approver2AuthorityIDs = [];
  Approver2AuthorityList: any[] = [];
  SelectedApprover2AuthorityType = [];
  dropdownSettingsApprover2AuthorityFilter = {};

  Approver3AuthorityIDs = [];
  Approver3AuthorityList: any[] = [];
  SelectedApprover3AuthorityType = [];
  dropdownSettingsApprover3AuthorityFilter = {};
  PortalCredentialForm: FormGroup;
  // PortalCredentialModel: Price_Quote_PortalCredentialModel;
  PortalCredentialModel: PriceQuotePortalCredentialModel = {
    inteserraPortalCredentialId: 0,
    clientInformationId: 0,
    allSubsidiaryCompanies: null,
    clientRequestType: null,
    serviceType: null,
    personnelFirstName: null,
    personnelLastName: null,
    personnelTitle: null,
    personnelAuthorityId: 0,
    personnelPhoneNo: null,
    personnelEmail: null,
    personnelAmountLimit: null,
    personnel2FirstName: null,
    personnel2LastName: null,
    approver2Title: null,
    approver2AuthorityId: 0,
    approver2PhoneNo: null,
    approver2Email: null,
    approver2AmountLimit: null,
    approver3FirstName: null,
    approver3LastName: null,
    approver3Title: null,
    approver3AuthorityId: 0,
    approverPhoneNo: null,
    approver3Email: null,
    approver3AmountLimit: null,
    listOfPersonnelWhoNoLongerNeedsCredentials: null,
    listOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal: null,
    serviceToRemoveContactFrom: null,
    isCompanyEmployed: null,
    createdDate: '',
    createdBy: 0,
    updatedDate: '',
    updatedBy: 0
  }

  isPersonnel1TaxPortal: boolean = false;
  isPersonnel1RegulatoryReporting: boolean = false;
  isPersonnel2TaxPortal: boolean = false;
  isPersonnel2RegulatoryReporting: boolean = false;
  isPersonnel3TaxPortal: boolean = false;
  isPersonnel3RegulatoryReporting: boolean = false;

  isTaxSelected: boolean = false;
  isRegulatoryReporting: boolean = false;
  IsVendor: boolean = false;
  clientcompanyName:string;
  priceQuoteId:number=0;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute,
    private globle: Globle, private router: Router, private PricingQuotesMaster: PricingQuotesService, private toastr: ToastrService,
    private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = sessionStorage.getItem('UserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.clientcompanyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Onboarding Information - '+this.clientcompanyName);

    var vendor = sessionStorage.getItem('isVendor');
    if (vendor == 'true') {
      this.IsVendor = true;
    }
    else {
      this.IsVendor = false;
    }

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.ClientInformationId = params['id'];
        }
      }
      if (params['priceQuoteId']) {
        if (params['priceQuoteId'] != null) {
          this.priceQuoteId = params['priceQuoteId'];
        }
      }
    });

    this.mainUrl = '/View/';
    if (this.ClientInformationId != null) {
      this.GetProgressStepDetailsById(this.ClientInformationId);
      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isAdditionalCompanyInformation = null;
      this.globle.cApp.statusModel.isSensitiveInformation = null;
      this.globle.cApp.statusModel.isInteserraPortalCredential = null;
      this.globle.cApp.statusModel.isAttechedDetailsCompleted = null;
      this.globle.cApp.statusModel.isStateSelectionCompleted=null;
      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
    }

    if (this.IsVendor == false) {
      this.PortalCredentialForm = this._fb.group({
        inteserraPortalCredentialId: 0,
        clientInformationId: this.ClientInformationId,
        allSubsidiaryCompanies: ['', Validators.required],
        clientRequestType: ['', Validators.required],
        serviceType: ['', Validators.required],
        personnelFirstName: ['', Validators.required],
        personnelLastName: ['', Validators.required],
        personnelTitle: ['', Validators.required],
        personnelAuthorityId: [0, Validators.required],
        personnelPhoneNo: ['', Validators.required],
        personnelEmail: ['', Validators.required],
        personnelAmountLimit: ['', Validators.required],
        personnel2FirstName: ['', Validators.required],
        personnel2LastName: ['', Validators.required],
        approver2Title: ['', Validators.required],
        approver2AuthorityId: [0, Validators.required],
        approver2PhoneNo: ['', Validators.required],
        approver2Email: ['', Validators.required],
        approver2AmountLimit: ['', Validators.required],
        approver3FirstName: ['', Validators.required],
        approver3LastName: ['', Validators.required],
        approver3Title: ['', Validators.required],
        approver3AuthorityId: [0, Validators.required],
        approverPhoneNo: ['', Validators.required],
        approver3Email: ['', Validators.required],
        approver3AmountLimit: ['', Validators.required],
        listOfPersonnelWhoNoLongerNeedsCredentials: ['', Validators.required],
        listOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal: ['', Validators.required],
        serviceToRemoveContactFrom: ['', Validators.required],
        isCompanyEmployed: ['', Validators.required],
        createdDate: [''],
        createdBy: [''],
        updatedDate: [''],
        updatedBy: [''],
      })
    }
    else {
      this.PortalCredentialForm = this._fb.group({
        inteserraPortalCredentialId: 0,
        clientInformationId: this.ClientInformationId,
        allSubsidiaryCompanies: [''],
        clientRequestType: [''],
        serviceType: [''],
        personnelFirstName: [''],
        personnelLastName: [''],
        personnelTitle: [''],
        personnelAuthorityId: [0],
        personnelPhoneNo: [''],
        personnelEmail: [''],
        personnelAmountLimit: [''],
        personnel2FirstName: [''],
        personnel2LastName: [''],
        approver2Title: [''],
        approver2AuthorityId: [0],
        approver2PhoneNo: [''],
        approver2Email: [''],
        approver2AmountLimit: [''],
        approver3FirstName: [''],
        approver3LastName: [''],
        approver3Title: [''],
        approver3AuthorityId: [0],
        approverPhoneNo: [''],
        approver3Email: [''],
        approver3AmountLimit: [''],
        listOfPersonnelWhoNoLongerNeedsCredentials: [''],
        listOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal: [''],
        serviceToRemoveContactFrom: [''],
        isCompanyEmployed: [''],
        createdDate: [''],
        createdBy: [''],
        updatedDate: [''],
        updatedBy: [''],
      })
    }

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.ClientInformationId = params['id'];
          this.GetPortalCredentialById(this.ClientInformationId);
          this.GetTaxRegulatorySelection(this.ClientInformationId);
        }
      }
    });

  }

  ngOnInit() {
    this.dropdownSettingsClientRequestTypeFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsServiceTypeFilter = {
      singleSelection: false,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: true,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsServicesToRemoveFilter = {
      singleSelection: false,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: true,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsPersonStillEmployedFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsPersonnelAuthorityFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsApprover2AuthorityFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsApprover3AuthorityFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.BindDropdown();
  }

  GetTaxRegulatorySelection(clientInformationId: number) {
    this.blockDocument();
    this.PricingQuotesMaster.GetTaxRegulatorySelection(this.tokenKey, parseInt(clientInformationId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.unblockDocument();
          if (result['services'] != null) {
            let data = result['services'].filter(x => x.baseRate == 'Tax')[0];
            if (data != null && data != undefined) {
              this.isTaxSelected = true;
            }

            let data1 = result['services'].filter(x => x.baseRate == 'Regulatory Reporting')[0];
            if (data1 != null && data1 != undefined) {
              this.isRegulatoryReporting = true;
            }
          }
        }
      }
    );
  }

  GetPortalCredentialById(ClientInformationId) {
    this.blockDocument()
    this.PricingQuotesMaster.GetPortalCredentialById(this.tokenKey, parseInt(ClientInformationId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.unblockDocument();
          if (result['clientInformation'] != null) {
            this.PortalCredentialModel = result['clientInformation'];

            this.PortalCredentialForm.patchValue({
              clientInformationId: this.ClientInformationId,
              allSubsidiaryCompanies: result['clientInformation'].allSubsidiaryCompanies,
              inteserraPortalCredentialId: result['clientInformation'].inteserraPortalCredentialId,
              personnelFirstName: result['clientInformation'].personnelFirstName,
              personnelLastName: result['clientInformation'].personnelLastName,
              personnelTitle: result['clientInformation'].personnelTitle,
              personnelPhoneNo: result['clientInformation'].personnelPhoneNo,
              personnelEmail: result['clientInformation'].personnelEmail,
              personnelAmountLimit: result['clientInformation'].personnelAmountLimit,
              personnel2FirstName: result['clientInformation'].personnel2FirstName,
              personnel2LastName: result['clientInformation'].personnel2LastName,
              approver2Title: result['clientInformation'].approver2Title,
              approver2PhoneNo: result['clientInformation'].approver2PhoneNo,
              approver2Email: result['clientInformation'].approver2Email,
              approver2AmountLimit: result['clientInformation'].approver2AmountLimit,
              approver3FirstName: result['clientInformation'].approver3FirstName,
              approver3LastName: result['clientInformation'].approver3LastName,
              approver3Title: result['clientInformation'].approver3Title,
              approverPhoneNo: result['clientInformation'].approverPhoneNo,
              approver3Email: result['clientInformation'].approver3Email,
              approver3AmountLimit: result['clientInformation'].approver3AmountLimit,
              listOfPersonnelWhoNoLongerNeedsCredentials: result['clientInformation'].listOfPersonnelWhoNoLongerNeedsCredentials,
              listOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal: result['clientInformation'].listOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal,
            });

            // this.isPersonnel1TaxPortal = result['clientInformation'].isPersonnel1TaxPortal;
            // this.isPersonnel2TaxPortal = result['clientInformation'].isPersonnel2TaxPortal;
            // this.isPersonnel3TaxPortal = result['clientInformation'].isPersonnel3TaxPortal;
            this.isPersonnel1RegulatoryReporting = result['clientInformation'].isPersonnel1RegulatoryReporting;
            this.isPersonnel2RegulatoryReporting = result['clientInformation'].isPersonnel2RegulatoryReporting;
            this.isPersonnel3RegulatoryReporting = result['clientInformation'].isPersonnel3RegulatoryReporting;
            if (result['clientInformation'].persolnal1Access != null)
              this.person1SelectedItems = result['clientInformation'].persolnal1Access.toString();
            if (result['clientInformation'].persolnal2Access != null)
              this.person2SelectedItems = result['clientInformation'].persolnal2Access.toString();
            if (result['clientInformation'].persolnal3Access != null)
              this.person3SelectedItems = result['clientInformation'].persolnal3Access.toString();

            this.SelectedPersonStillEmployedType = [];
            if (result['clientInformation'].isCompanyEmployed != null) {
              if (result['clientInformation'].isCompanyEmployed == true) {
                this.SelectedPersonStillEmployedType.push({ "id": 1, "itemName": "Yes" })
                this.PersonStillEmployedIDs.push(1);
              }
              else { this.SelectedPersonStillEmployedType.push({ "id": 2, "itemName": "No" }) }
              this.PersonStillEmployedIDs.push(2);
            }

            this.SelectedClientRequestType = [];
            this.ClientRequestTypeIDs = [];
            if (result['clientInformation'].clientRequestType != null) {
              if (result['clientInformation'].clientRequestType.split(',').length > 0) {
                for (let index = 0; index < result['clientInformation'].clientRequestType.split(',').length; index++) {
                  let data = this.ClientRequestTypeList.filter(x => x.id == result['clientInformation'].clientRequestType.split(',')[index])[0];
                  if (data != undefined) {
                    if (data.id == 1 || data.id == 2)
                    {
                      this.isServiceType = true;
                      this.isREMSApprover= true;
                    }
                    if (data.id == 3)
                      this.isRemovingUser = true;

                    this.SelectedClientRequestType.push({ "id": data.id, "itemName": data.itemName });
                    this.ClientRequestTypeIDs.push(data.id);
                  }
                }
              }
            }

            this.SelectedServiceType = [];
            this.ServiceTypeIDs = [];
            if (result['clientInformation'].serviceType != null) {
              if (result['clientInformation'].serviceType.split(',').length > 0) {
                for (let index = 0; index < result['clientInformation'].serviceType.split(',').length; index++) {
                  let data = this.ServiceTypeList.filter(x => x.id == result['clientInformation'].serviceType.split(',')[index])[0];
                  if (data != undefined) {
                    if (data.id == 1)
                      this.isREMSApprover = true;
                    if (data.id == 2)
                      this.isTaxPortalCredential = true;

                    this.SelectedServiceType.push({ "id": data.id, "itemName": data.itemName });
                    this.ServiceTypeIDs.push(data.id);
                  }
                }
              }
            }

            this.SelectedServicesToRemove = [];
            this.ServicesToRemoveIDs = [];
            if (result['clientInformation'].serviceToRemoveContactFrom != null) {
              if (result['clientInformation'].serviceToRemoveContactFrom.split(',').length > 0) {
                for (let index = 0; index < result['clientInformation'].serviceToRemoveContactFrom.split(',').length; index++) {
                  let data = this.ServicesToRemoveList.filter(x => x.id == result['clientInformation'].serviceToRemoveContactFrom.split(',')[index])[0];
                  if (data != undefined) {
                    this.SelectedServicesToRemove.push({ "id": data.id, "itemName": data.itemName });
                    this.ServicesToRemoveIDs.push(data.id);
                  }
                }
              }
            }

            if (this.isREMSApprover) {
              this.SelectedPersonnelAuthorityType = [];
              this.PersonnelAuthorityIDs = [];
              if (result['clientInformation'].personnelAuthorityId != null) {
                let data = this.PersonnelAuthorityList.filter(x => x.id == result['clientInformation'].personnelAuthorityId)[0];
                if (data != undefined) {
                  this.SelectedPersonnelAuthorityType.push({ "id": data.id, "itemName": data.itemName });
                  this.PersonnelAuthorityIDs.push(data.id);
                }
              }

              this.SelectedApprover2AuthorityType = [];
              this.Approver2AuthorityIDs = [];
              if (result['clientInformation'].approver2AuthorityId != null) {
                let data = this.Approver2AuthorityList.filter(x => x.id == result['clientInformation'].approver2AuthorityId)[0];
                if (data != undefined) {
                  this.SelectedApprover2AuthorityType.push({ "id": data.id, "itemName": data.itemName });
                  this.Approver2AuthorityIDs.push(data.id);
                }
              }

              this.SelectedApprover3AuthorityType = [];
              this.Approver3AuthorityIDs = [];
              if (result['clientInformation'].approver3AuthorityId != null) {
                let data = this.Approver3AuthorityList.filter(x => x.id == result['clientInformation'].approver3AuthorityId)[0];
                if (data != undefined) {
                  this.SelectedApprover3AuthorityType.push({ "id": data.id, "itemName": data.itemName });
                  this.Approver3AuthorityIDs.push(data.id);
                }
              }
            }
          }
        }
      });
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  BindDropdown() {
    this.ClientRequestTypeList = [];
    this.ClientRequestTypeList.push({ "id": 1, "itemName": "New Client - Need Access" })
    // this.ClientRequestTypeList.push({ "id": 2, "itemName": "Existing Client - Adding User Access/Approver Level" })
    // this.ClientRequestTypeList.push({ "id": 3, "itemName": "Existing Client - Removing User Access" })

    this.ServiceTypeList = [];
    this.ServiceTypeList.push({ "id": 1, "itemName": "Regulatory Compliance Reporting System(REMS)" })
    this.ServiceTypeList.push({ "id": 2, "itemName": "Transactional Tax Compliance Portal" })

    this.ServicesToRemoveList = [];
    this.ServicesToRemoveList.push({ "id": 1, "itemName": "Regulatory Compliance Reporting System(REMS)" })
    this.ServicesToRemoveList.push({ "id": 2, "itemName": "Transactional Tax Compliance Portal" })

    this.PersonStillEmployedList = [];
    this.PersonStillEmployedList.push({ "id": 1, "itemName": "Yes" })
    this.PersonStillEmployedList.push({ "id": 2, "itemName": "No" })

    this.PersonnelAuthorityList = [];
    this.PersonnelAuthorityList.push({ "id": 1, "itemName": "Officer" })
    this.PersonnelAuthorityList.push({ "id": 2, "itemName": "Non-officer" })
    this.PersonnelAuthorityList.push({ "id": 3, "itemName": "Review Only" })

    this.Approver2AuthorityList = [];
    this.Approver2AuthorityList.push({ "id": 1, "itemName": "Officer" })
    this.Approver2AuthorityList.push({ "id": 2, "itemName": "Non-officer" })
    this.Approver2AuthorityList.push({ "id": 3, "itemName": "Review Only" })

    this.Approver3AuthorityList = [];
    this.Approver3AuthorityList.push({ "id": 1, "itemName": "Officer" })
    this.Approver3AuthorityList.push({ "id": 2, "itemName": "Non-officer" })
    this.Approver3AuthorityList.push({ "id": 3, "itemName": "Review Only" })
  }

  onSelectClientRequestType($event) {
    if ($event.id == 1 || $event.id == 2)
    {
      this.isServiceType = true;
      this.isREMSApprover= true;
    }

    if ($event.id == 3)
      this.isRemovingUser = true;

    this.ClientRequestTypeIDs.push($event.id);
  }

  onDeClientRequestType($event) {
    // if ($event.id == 3)
    //   this.isRemovingUser = false;

    // this.ClientRequestTypeIDs = this.removeElemetninArray(this.ClientRequestTypeIDs, $event.id)
    // if (this.ClientRequestTypeIDs.length > 0) {
    //   this.isServiceType = false;
    //   for (let index = 0; index < this.ClientRequestTypeIDs.length; index++) {
    //     if (this.ClientRequestTypeIDs[index] == 1 || this.ClientRequestTypeIDs[index] == 2)
    //       this.isServiceType = true;
    //   }
    // }
    // else
    //   this.isServiceType = false;
    this.ClientRequestTypeIDs = [];
    this.isServiceType = false;
    this.isRemovingUser = false;
    this.isTaxPortalCredential = false;
    this.isREMSApprover = false;
    this.SelectedServiceType = [];
  }
  onSelectClientRequestTypeAll($event) {
    this.isRemovingUser = true;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.ClientRequestTypeIDs != null) {
        if (this.ClientRequestTypeIDs.length > 0) {
          this.ClientRequestTypeIDs = this.removeElemetninArray(this.ClientRequestTypeIDs, $event[iCounter].id)
          this.ClientRequestTypeIDs.push($event[iCounter].id);
        }
        else {
          this.ClientRequestTypeIDs.push($event[iCounter].id);
        }
      }
      this.isServiceType = true;
      this.isREMSApprover= true;
    }
  }

  onDeSelectClientRequestTypeAll($event) {
    this.ClientRequestTypeIDs = [];
    this.isServiceType = false;
    this.isRemovingUser = false;
    this.isTaxPortalCredential = false;
    this.isREMSApprover = false;
    this.SelectedServiceType = [];
    this.SelectedClientRequestType = [];
  }

  onSelectServiceType($event) {
    if ($event.id == 1)
      this.isREMSApprover = true;
    else
      this.isTaxPortalCredential = true;
    this.ServiceTypeIDs.push($event.id);
  }

  onDeClientServiceType($event) {
    if ($event.id == 1)
      this.isREMSApprover = false;
    else
      this.isTaxPortalCredential = false;
    this.ServiceTypeIDs = this.removeElemetninArray(this.ServiceTypeIDs, $event.id)
  }

  onSelectServiceTypeAll($event) {
    this.isREMSApprover = true;
    this.isTaxPortalCredential = true;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.ServiceTypeIDs != null) {
        if (this.ClientRequestTypeIDs.length > 0) {
          this.ServiceTypeIDs = this.removeElemetninArray(this.ServiceTypeIDs, $event[iCounter].id)
          this.ServiceTypeIDs.push($event[iCounter].id);
        }
        else {
          this.ServiceTypeIDs.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectServiceTypeAll($event) {
    this.ServiceTypeIDs = [];
    this.isREMSApprover = false;
    this.isTaxPortalCredential = false;
  }

  onSelectServicesToRemove($event) {
    this.ServicesToRemoveIDs.push($event.id);
  }

  onDeServicesToRemove($event) {
    this.ServicesToRemoveIDs = this.removeElemetninArray(this.ServicesToRemoveIDs, $event.id)
  }

  onDeSelectServicesToRemoveAll($event) {
    this.ServicesToRemoveIDs = [];
  }

  onSelectServicesToRemoveAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.ServicesToRemoveIDs != null) {
        if (this.ServicesToRemoveIDs.length > 0) {
          this.ServicesToRemoveIDs = this.removeElemetninArray(this.ServicesToRemoveIDs, $event[iCounter].id)
          this.ServicesToRemoveIDs.push($event[iCounter].id);
        }
        else {
          this.ServicesToRemoveIDs.push($event[iCounter].id);
        }
      }
    }
  }

  onSelectPersonStillEmployed($event) {
    this.PersonStillEmployedIDs.push($event.id);
  }

  onDePersonStillEmployed($event) {
    this.PersonStillEmployedIDs = this.removeElemetninArray(this.PersonStillEmployedIDs, $event.id)
  }

  onDeSelectPersonStillEmployedAll($event) {
    this.PersonStillEmployedIDs = [];
  }

  onSelectPersonnelAuthority($event) {
    this.PersonnelAuthorityIDs.push($event.id);
  }

  onDePersonnelAuthority($event) {
    this.PersonnelAuthorityIDs = this.removeElemetninArray(this.PersonnelAuthorityIDs, $event.id)
  }

  onDeSelectPersonnelAuthorityAll($event) {
    this.PersonnelAuthorityIDs = [];
  }

  onSelectApprover2Authority($event) {
    this.Approver2AuthorityIDs.push($event.id);
  }

  onDeApprover2Authority($event) {
    this.Approver2AuthorityIDs = this.removeElemetninArray(this.Approver2AuthorityIDs, $event.id)
  }

  onDeSelectApprover2AuthorityAll($event) {
    this.Approver2AuthorityIDs = [];
  }

  onSelectApprover3Authority($event) {
    this.Approver3AuthorityIDs.push($event.id);
  }

  onDeApprover3Authority($event) {
    this.Approver3AuthorityIDs = this.removeElemetninArray(this.Approver3AuthorityIDs, $event.id)
  }

  onDeSelectApprover3AuthorityAll($event) {
    this.Approver3AuthorityIDs = [];
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  save(clientForm: any) {
    this.isSubmit = true;

    this.PortalCredentialModel.createdBy = this.userID;
    // this.PortalCredentialModel.ClientInformationId = this.ClientInformationId;
    // this.PortalCredentialModel.IsFront = true;

    let formData = new FormData();
    formData.append('CreatedBy', this.userID || 0);
    formData.append('ClientInformationId', this.ClientInformationId.toString() || '');
    formData.append('IsFront', 'true');


    let data = clientForm.value;

    var Personnel1Selection, Personnel2Selection, Personnel3Selection

    if (this.person1SelectedItems != null) {
      Personnel1Selection = this.person1SelectedItems;
    } else {
      Personnel1Selection = 4;
    }

    if (this.person2SelectedItems != null) {
      Personnel2Selection = this.person2SelectedItems;
    } else {
      Personnel2Selection = 4;
    }

    if (this.person3SelectedItems != null) {
      Personnel3Selection = this.person3SelectedItems;
    } else {
      Personnel3Selection = 4;
    }



    formData.append('InteserraPortalCredentialId', data.inteserraPortalCredentialId || 0);

    if (this.IsVendor == false) {
      if (data.allSubsidiaryCompanies == null || data.allSubsidiaryCompanies == '' || data.allSubsidiaryCompanies == undefined)
        return;
    }
    formData.append('AllSubsidiaryCompanies', data.allSubsidiaryCompanies || '');

    if (this.IsVendor == false) {
      if (this.SelectedClientRequestType.length == 0) {
        this.showMessage("Please Select Client Request Type", 'Error', 'error');
        return;
      }
    }

    if (this.ClientRequestTypeIDs.length > 0) {
      formData.append('ClientRequestType', this.ClientRequestTypeIDs.join(','));
      // if (this.isServiceType) {
      //   if (this.SelectedServiceType.length == 0)
      //   {
      //     this.showMessage("Please Select Service Type", 'Error', 'error');
      //     return;
      //   }
      // }
    }
    else {
      this.showMessage("Please Select Client Request Type", 'Error', 'error');
    }

    // if (this.isServiceType) {
    //   if (this.IsVendor == false) {
    //     if (this.ServiceTypeIDs.length == 0) {
    //       this.showMessage("Please Select Service Type", 'Error', 'error');
    //       return;
    //     }
    //   }
    // }

    if (this.ServiceTypeIDs.length > 0) {
      // this.PortalCredentialModel.ServiceType = this.ServiceTypeIDs.join(',');
      formData.append('ServiceType', this.ServiceTypeIDs.join(','));
    }
    else {
      formData.append('ServiceType', null);
      // this.PortalCredentialModel.ServiceType = null;
    }

    if (this.isREMSApprover) {
      if (this.IsVendor == false && Personnel1Selection != 4) {
        if (this.PersonnelAuthorityIDs.length == 0)
          return;
      }
    }

    if (this.PersonnelAuthorityIDs.length > 0) {
      // this.PortalCredentialModel.PersonnelAuthorityId = this.PersonnelAuthorityIDs[0];
      formData.append('PersonnelAuthorityId', this.PersonnelAuthorityIDs[0]);
    }
    else {
      formData.append('PersonnelAuthorityId', null);
      // this.PortalCredentialModel.PersonnelAuthorityId = null;
    }

    if (this.isREMSApprover) {
      if (this.IsVendor == false && Personnel2Selection != 4) {
        if (this.Approver2AuthorityIDs.length == 0)
          return
      }
    }

    if (this.Approver2AuthorityIDs.length > 0) {
      // this.PortalCredentialModel.Approver2AuthorityId = this.Approver2AuthorityIDs[0];
      formData.append('Approver2AuthorityId', this.Approver2AuthorityIDs[0]);
    }
    else {
      this.PortalCredentialModel.approver2AuthorityId = null;
      formData.append('Approver2AuthorityId', null);
    }

    if (this.isREMSApprover) {
      if (this.IsVendor == false && Personnel3Selection != 4) {
        if (this.Approver3AuthorityIDs.length == 0)
          return;
      }
    }

    if (this.Approver3AuthorityIDs.length > 0) {
      // this.PortalCredentialModel.Approver3AuthorityId = this.Approver3AuthorityIDs[0];
      formData.append('Approver3AuthorityId', this.Approver3AuthorityIDs[0]);
    }
    else {
      // this.PortalCredentialModel.Approver3AuthorityId = null;
      formData.append('Approver3AuthorityId', null);
    }

    if (this.isRemovingUser) {
      if (this.IsVendor == false) {
        if (this.ServicesToRemoveIDs.length == 0)
          return;
      }
    }

    if (this.ServicesToRemoveIDs.length > 0) {
      // this.PortalCredentialModel.ServiceToRemoveContactFrom = this.ServicesToRemoveIDs.join(',');
      formData.append('ServiceToRemoveContactFrom', this.ServicesToRemoveIDs.join(','));
    }
    else {
      formData.append('ServiceToRemoveContactFrom', null);
      // this.PortalCredentialModel.ServiceToRemoveContactFrom = null;
    }

    if (this.isRemovingUser) {
      if (this.IsVendor == false) {
        if (this.PersonStillEmployedIDs.length == 0)
          return;
      }
    }

    if (this.PersonStillEmployedIDs.length > 0) {
      // this.PortalCredentialModel.IsCompanyEmployed = this.PersonStillEmployedIDs[0]['id'] == 1 ? true : false;
      formData.append('IsCompanyEmployed', this.PersonStillEmployedIDs[0]['id'] == 1 ? 'true' : 'false');
    }
    else {
      // this.PortalCredentialModel.IsCompanyEmployed = null;
      formData.append('IsCompanyEmployed', null);
    }

    if (!this.isRemovingUser) {
      this.PortalCredentialModel.isCompanyEmployed = null;
      this.PortalCredentialModel.serviceToRemoveContactFrom = null;
      this.PortalCredentialModel.listOfPersonnelWhoNoLongerNeedsCredentials = null;
    }

    if (this.isRemovingUser) {
      if (this.IsVendor == false) {
        if (data.listOfPersonnelWhoNoLongerNeedsCredentials == null || data.listOfPersonnelWhoNoLongerNeedsCredentials == '' || data.listOfPersonnelWhoNoLongerNeedsCredentials == undefined)
          return;
      }
    }

    if (this.isREMSApprover) {
      if (this, this.IsVendor == false) {
        if ((data.personnelFirstName == null || data.personnelFirstName == '' || data.personnelFirstName == undefined) && Personnel1Selection != 4)
          return;

        if ((data.personnelLastName == null || data.personnelLastName == '' || data.personnelLastName == undefined) && Personnel1Selection != 4)
          return;

        if ((data.personnelTitle == null || data.personnelTitle == '' || data.personnelTitle == undefined) && Personnel1Selection != 4)
          return;

        if ((data.personnelPhoneNo == null || data.personnelPhoneNo == '' || data.personnelPhoneNo == undefined) && Personnel1Selection != 4)
          return;

        if ((data.personnelEmail == null || data.personnelEmail == '' || data.personnelEmail == undefined) && Personnel1Selection != 4)
          return;

        if ((data.personnelAmountLimit == null || data.personnelAmountLimit == '' || data.personnelAmountLimit == undefined) && Personnel1Selection != 4)
          return;

        if ((data.personnel2FirstName == null || data.personnel2FirstName == '' || data.personnel2FirstName == undefined) && Personnel2Selection != 4)
          return;

        if ((data.personnel2LastName == null || data.personnel2LastName == '' || data.personnel2LastName == undefined) && Personnel2Selection != 4)
          return;

        if ((data.approver2Title == null || data.approver2Title == '' || data.approver2Title == undefined) && Personnel2Selection != 4)
          return;

        if ((data.approver2PhoneNo == null || data.approver2PhoneNo == '' || data.approver2PhoneNo == undefined) && Personnel2Selection != 4)
          return;

        if ((data.approver2Email == null || data.approver2Email == '' || data.approver2Email == undefined) && Personnel2Selection != 4)
          return;

        if ((data.approver2AmountLimit == null || data.approver2AmountLimit == '' || data.approver2AmountLimit == undefined) && Personnel2Selection != 4)
          return;

        if ((data.approver3FirstName == null || data.approver3FirstName == '' || data.approver3FirstName == undefined) && Personnel3Selection != 4)
          return;

        if ((data.approver3LastName == null || data.approver3LastName == '' || data.approver3LastName == undefined) && Personnel3Selection != 4)
          return;

        if ((data.approver3Title == null || data.approver3Title == '' || data.approver3Title == undefined) && Personnel3Selection != 4)
          return;

        if ((data.approverPhoneNo == null || data.approverPhoneNo == '' || data.approverPhoneNo == undefined) && Personnel3Selection != 4)
          return;

        if ((data.approver3Email == null || data.approver3Email == '' || data.approver3Email == undefined) && Personnel3Selection != 4)
          return;

        if ((data.approver3AmountLimit == null || data.approver3AmountLimit == '' || data.approver3AmountLimit == undefined) && Personnel3Selection != 4)
          return;
      }

      formData.append('ListOfPersonnelWhoNoLongerNeedsCredentials', data.listOfPersonnelWhoNoLongerNeedsCredentials || '');
      formData.append('PersonnelFirstName', data.personnelFirstName || '');
      formData.append('PersonnelLastName', data.personnelLastName || '');
      formData.append('PersonnelTitle', data.personnelTitle || '');
      formData.append('PersonnelPhoneNo', data.personnelPhoneNo || '');
      formData.append('PersonnelEmail', data.personnelEmail || '');
      formData.append('PersonnelAmountLimit', data.personnelAmountLimit || '');
      formData.append('Personnel2FirstName', data.personnel2FirstName || '');
      formData.append('Personnel2LastName', data.personnel2LastName || '');
      formData.append('Approver2Title', data.approver2Title || '');
      formData.append('Approver2PhoneNo', data.approver2PhoneNo || '');
      formData.append('Approver2Email', data.approver2Email || '');
      formData.append('Approver2AmountLimit', data.approver2AmountLimit || '');
      formData.append('Approver3FirstName', data.approver3FirstName || '');
      formData.append('Approver3LastName', data.approver3LastName || '');
      formData.append('Approver3Title', data.approver3Title || '');
      formData.append('ApproverPhoneNo', data.approverPhoneNo || '');
      formData.append('Approver3Email', data.approver3Email || '');
      formData.append('Approver3AmountLimit', data.approver3AmountLimit || '');

      formData.append('isPersonnel1TaxPortal', this.isPersonnel1TaxPortal == true ? 'true' : 'false');
      formData.append('isPersonnel1RegulatoryReporting', this.isPersonnel1RegulatoryReporting == true ? 'true' : 'false');
      formData.append('isPersonnel2TaxPortal', this.isPersonnel2TaxPortal == true ? 'true' : 'false');
      formData.append('isPersonnel2RegulatoryReporting', this.isPersonnel2RegulatoryReporting == true ? 'true' : 'false');
      formData.append('isPersonnel3TaxPortal', this.isPersonnel3TaxPortal == true ? 'true' : 'false');
      formData.append('isPersonnel3RegulatoryReporting', this.isPersonnel3RegulatoryReporting == true ? 'true' : 'false');

      if (this.person1SelectedItems != undefined) {
        formData.append('persolnal1Access', this.person1SelectedItems.toString());
      } else { formData.append('persolnal1Access', null); }

      if (this.person2SelectedItems != undefined) {
        formData.append('persolnal2Access', this.person2SelectedItems.toString());
      } else {
        formData.append('persolnal2Access', null);
      }

      if (this.person3SelectedItems != undefined) {
        formData.append('persolnal3Access', this.person3SelectedItems.toString());
      } else {
        formData.append('persolnal3Access', null);
      }
    }

    // if (!this.isREMSApprover) {
    //   this.PortalCredentialModel.PersonnelFirstName = null;
    //   this.PortalCredentialModel.PersonnelLastName = null;
    //   this.PortalCredentialModel.PersonnelTitle = null;
    //   this.PortalCredentialModel.PersonnelAuthorityId = null;
    //   this.PortalCredentialModel.PersonnelPhoneNo = null;
    //   this.PortalCredentialModel.PersonnelEmail = null;
    //   this.PortalCredentialModel.PersonnelAmountLimit = null;
    //   this.PortalCredentialModel.Personnel2FirstName = null;
    //   this.PortalCredentialModel.Personnel2LastName = null;
    //   this.PortalCredentialModel.Approver2Title = null;
    //   this.PortalCredentialModel.Approver2AuthorityId = null;
    //   this.PortalCredentialModel.Approver2PhoneNo = null;
    //   this.PortalCredentialModel.Approver2Email = null;
    //   this.PortalCredentialModel.Approver2AmountLimit = null;
    //   this.PortalCredentialModel.Approver3FirstName = null;
    //   this.PortalCredentialModel.Approver3LastName = null;
    //   this.PortalCredentialModel.Approver3Title = null;
    //   this.PortalCredentialModel.Approver3AuthorityId = null;
    //   this.PortalCredentialModel.ApproverPhoneNo = null;
    //   this.PortalCredentialModel.Approver3Email = null;
    //   this.PortalCredentialModel.Approver3AmountLimit = null;
    // }

    if (!this.isTaxPortalCredential) {
      this.PortalCredentialModel.listOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal = null;
    }
    else {
      if (this.IsVendor == false) {
        // if (data.listOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal == null || data.listOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal == '' || data.listOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal == undefined)
        //   return;
      }
      formData.append('ListOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal', data.listOutAllPersonnelWhoWillNeedCredentialsToTheTransactionalTaxCompliancePortal);
    }

    this.blockDocument();
    this.PricingQuotesMaster.InteserraPortalCredentialAddUpdate(this.tokenKey, formData).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isInteserraPortalCredential = true;
          this.unblockDocument();
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (result['clientInformationId'] != 0) {
              // this.showMessage('Additional Data saved successfully', 'success', 'success');
              // let navigation = '/View/client-profile';
              // var vendordata = sessionStorage.getItem('isVendor');
              // if (vendordata != null && vendordata != "" && vendordata != undefined) {
              //   if (vendordata == "true") {
              //     let navigation = '/View/client-quote-list';
              //     this.router.navigate([navigation]);
              //   }
              //   else {
              let navigation = '/View/attach-to-determine-services/' + this.ClientInformationId+'/'+this.priceQuoteId;
              this.router.navigate([navigation]);
              //   }
              // }
              // this.router.navigate([navigation]);
            }
          }
        }
        else {
          this.unblockDocument();
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );

  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  activeTab(url: string, tabId: number) {

    if (this.ClientInformationId != null) {
      if (checkStepIsCompletedPhase3(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.ClientInformationId+'/'+this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.ClientInformationId+'/'+this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompletedPhase3(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(clientInformationId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsByClientInformationId(this.tokenKey, clientInformationId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
          this.activeTab('portal-credential', 12);
        }
      }
    );
  }

  previousStep() {
    let navigation = '/View/sensitive-info/' + this.ClientInformationId+'/'+this.priceQuoteId;
    this.router.navigate([navigation]);
  }
}
