import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { WhitePapersModel } from 'src/Models/WhitePapersModel';
import { CheckAuth } from 'src/Services/utility.service';
import { WhitePaperService } from 'src/Services/white-paper.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-white-papers',
  templateUrl: './white-papers.component.html',
  styleUrls: ['./white-papers.component.css']
})
export class WhitePapersComponent implements OnInit {

  filterModel = new FilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfWhitePapers: WhitePapersModel[];

  isUserSystemAdmin: boolean = true;
  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false
  upDowTitleCSS: string = '';
  upDowURLCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowIsIsAllowallAccessCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';
  upDowSortOrderCSS: string = '';
  upDowSectionCSS:string='';

  productId: number = 1;
  deletedId: number = 0;
  isloading: boolean;
  specialReportId:number=0;
  selectedItemsStatus = [];
  selectedItemsFree = [];
  dropdownSettingsStatus = {};
  dropdownSettingsFree = {};
  listOfStatus = [];
  statusId: string = ' ';
  listOfFree = [];
  freeId: string = ' ';
  moduleList: any[] = [{ itemName: 'Yes', id: '1' }, { itemName: 'No', id: '0' }];
  dropdownListSection = [];
  dropdownSettingsSections = {};
  selectedItemsSections = [];
  listOfSectionID: string[] = [];
  selectedItemsSection = [];
  listOfSection?: string;
  PageNumber: number;
  openMenuIndex: number = -1;

  constructor(private activedRoute: ActivatedRoute,private whitePaper: WhitePaperService, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('White Papers');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'SortOrder';
    // this.filterModel.orderBy = 'ISNULL(ModifiedDate,CreatedDate) DESC';
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsSections = {
      singleSelection: true,
      text: "Filter by Sections",
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No Section available',
    };
    this.dropdownSettingsStatus = {
      singleSelection: true,
      text: "Filter by Active",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No record available',
      showCheckbox: false
    };
    this.dropdownSettingsFree = {
      singleSelection: true,
      text: "Filter by Free",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No record available',
      showCheckbox: false
    };
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {

          this.specialReportId = params['id'];
          this.GetWhitePapersData();
        }
      }
    });
    this.GetWhitePapersData();
    this.getAllSections();

  }

  GetWhitePapersData() {
    this.listOfWhitePapers = [];
    this.isloading = true;
    this.whitePaper.i360WhitePapersGet(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfWhitePapers = data['whitePapersData'];
          if (this.listOfWhitePapers != null) {
            if (this.listOfWhitePapers.length > 0) {
              this.totalRecordCount = this.listOfWhitePapers[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filterModel.whereCondition = $event.target.value;
    }
    else {
      this.filterModel.whereCondition = '';
    }
    this.GetWhitePapersData();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetWhitePapersData();
  }

  cleanCssClass() {
    this.upDowTitleCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowIsIsAllowallAccessCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';
    this.upDowSectionCSS = '';

  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case "title": this.upDowTitleCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "isActive": this.upDowIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "isAllowallAccess": this.upDowIsIsAllowallAccessCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;      
      case "CreatedDate": this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "ModifiedDate": this.upDowModifiedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "sortOrder": this.upDowSortOrderCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "sectionName": this.upDowSectionCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;

      default: break;
    }
    this.GetWhitePapersData();
  }
 
  addWhitePaper() {
    this.router.navigate(['admin/inteserra360/WhitePaper/manage']);
  }

  editData(id) {
    this.router.navigate(['admin/inteserra360/WhitePaper/manage/' + id])
  }

  setDeleteData(ids) {
    this.deletedId = ids;
  }

  deleteRecord(deletedId) {
    this.whitePaper.i360WhitePapersDelete(this.tokenKey, deletedId).subscribe(
      data => {
        this.deletedId = 0;
        if (data != null) {
          if (data['deletedId'] > 0) {
            this.GetWhitePapersData();
            this.toastr.success('Record deleted successfully.', 'Delete')
          }
        }
      },
      error => {
      }
    );
  }

  drop(event: CdkDragDrop<string[]>): void {
    var CurrentShortOrder, PreviousShortOrder, currentcustomProductDetailsId, previouscustomProductDetailsId
    PreviousShortOrder = this.listOfWhitePapers[event.previousIndex]['sortOrder']
    previouscustomProductDetailsId = this.listOfWhitePapers[event.previousIndex]['whitePaperId']

    CurrentShortOrder = this.listOfWhitePapers[event.currentIndex]['sortOrder']
    currentcustomProductDetailsId = this.listOfWhitePapers[event.currentIndex]['whitePaperId']

    this.whitePaper.i360WhitePaperUpdate(this.tokenKey, previouscustomProductDetailsId, currentcustomProductDetailsId).subscribe(
      data => {
        this.deletedId = 0;
        if (data != null) {
          this.whitePaper.i360WhitePapersGet(this.tokenKey, this.filterModel).subscribe(
            data => {
              this.isloading = false;
              if (data != null) {
                this.listOfWhitePapers = data['whitePapersData'];
                if (this.listOfWhitePapers != null) {
                  if (this.listOfWhitePapers.length > 0) {
                    this.totalRecordCount = this.listOfWhitePapers[0].totalRecordCount;
                  }
                  else {
                    this.totalRecordCount = 0;
                  }
                }
                else {
                  this.totalRecordCount = 0;
                }
              }
            },
            error => {
              this.isloading = false;
              console.log(error);
            }
          );
        }
      },
      error => {
      }
    );

  }

  getAllSections() {
    this.whitePaper.i360WhitepaperSectionListWithIds(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        for (let iCounter = 0; iCounter < data['sectionList'].length; iCounter++) {
          this.dropdownListSection.push(
            { 'id': data['sectionList'][iCounter].sectionId, 'itemName': data['sectionList'][iCounter].sectionName }
          );
        }
        if (this.listOfSectionID != null && this.listOfSectionID != undefined) {
          for (let iCounter = 0; iCounter < this.listOfSectionID.length; iCounter++) {
            if (this.listOfSectionID[iCounter] != '') {
              let obj = data["sectionList"].filter(m => m.productId == this.listOfSectionID[iCounter]);
              if (obj != null) {
                this.selectedItemsSection.push({ "id": obj[0].sectionId, "itemName": obj[0].sectionName });
              }
            }
          }
        }
        
      }
    });
  }

  onSelectStatus($event) {
    this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
    this.listOfStatus.push($event.id);
    this.statusId = ' ';
    if (this.listOfStatus.length > 0) {
      this.statusId = $event.id;
      this.filterModel.statusId=this.statusId;
    }else{
      this.filterModel.statusId=null;
    }
    
    this.GetWhitePapersData();
  }

  onDeSelectStatus($event) {
    if (this.listOfStatus != null) {
      if (this.listOfStatus.length > 0) {
        this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
      }
    }
    this.statusId='';
    if (this.listOfStatus.length > 0) {
      this.statusId = $event.id;
    }
    this.filterModel.statusId=null;
    this.GetWhitePapersData();
  }

  onDeSelectAllStatus($event) {
    this.statusId='';
    this.listOfStatus = [];
    this.filterModel.statusId=null;
    this.GetWhitePapersData();
  }

  onSelectFree($event) {
    this.listOfFree = this.removeElemetninArray(this.listOfFree, $event.id)
    this.listOfFree.push($event.id);
    this.freeId = ' ';
    if (this.listOfFree.length > 0) {
      this.freeId = $event.id;
      this.filterModel.freeId=this.freeId;
    }
    else{
      this.filterModel.freeId=null;
    }
    this.GetWhitePapersData();
  }

  onDeSelectFree($event) {
    if (this.listOfFree != null) {
      if (this.listOfFree.length > 0) {
        this.listOfFree = this.removeElemetninArray(this.listOfFree, $event.id)
      }
    }
    this.freeId='';
    if (this.listOfFree.length > 0) {
      this.freeId = $event.id;
    }
    this.filterModel.freeId=null;
    this.GetWhitePapersData();
  }

  onDeSelectAllFree($event) {
    this.freeId='';
    this.listOfFree = [];
    this.filterModel.freeId=null;
    this.GetWhitePapersData();  
  }

  

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelect($event) {
    this.PageNumber = 1;
    if (this.listOfSectionID != null) {
      this.listOfSectionID=[];
      if (this.listOfSectionID.length > 0) {
        this.listOfSectionID = this.removeElemetninArray(this.listOfSectionID, $event.id)
        this.listOfSectionID.push($event.id);
      }
      else {
        this.listOfSectionID.push($event.id);
      }
    }

    this.listOfSection = this.listOfSectionID.join(',');
    this.filterModel.section=this.listOfSection;
    this.GetWhitePapersData();
  }

  onSelectAll($event) {
    this.PageNumber = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSectionID != null) {
        if (this.listOfSectionID.length > 0) {
          this.listOfSectionID = this.removeElemetninArray(this.listOfSectionID, $event[iCounter].id)
          this.listOfSectionID.push($event[iCounter].id);
        }
        else {
          this.listOfSectionID.push($event[iCounter].id);
        }
      }
    }
    this.listOfSection = this.listOfSectionID.join(',');
    this.filterModel.section=this.listOfSection;
    this.GetWhitePapersData();
  }

  onDeSelectAll($event) {
    this.PageNumber = 1;
    this.listOfSectionID = [];
    this.listOfSection = '';
    this.filterModel.section=this.listOfSection;
    this.GetWhitePapersData();
  }

  onDeSelect($event) {
    this.PageNumber = 1;
    if (this.listOfSectionID != null) {
      if (this.listOfSectionID.length > 0) {
        this.listOfSectionID = this.removeElemetninArray(this.listOfSectionID, $event.id)
      }
    }
    this.listOfSection = this.listOfSectionID.join(',');
    this.filterModel.section=this.listOfSection;
    this.GetWhitePapersData();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
