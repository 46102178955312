import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EmailLoginService } from 'src/Services/emaillogin.service';
import { FilterModel } from 'src/Models/filterModel';

@Component({
  selector: 'app-price-quote-email-verification',
  templateUrl: './price-quote-email-verification.component.html',
  styleUrls: ['./price-quote-email-verification.component.css']
})
export class PriceQuoteEmailVerificationComponent implements OnInit {
  quaryValues: string = '';
  filterModel = new FilterModel();
  blockedDocument: boolean = false;

  constructor(private activedRoute: ActivatedRoute,
    private router: Router,
    private emailLoginService: EmailLoginService) { }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {

        if (params['id'] != null) {

          this.quaryValues = params['id'];
          this.filterModel.emailKey = params['id'];
          this.IsValidPriceQuoteEmailKey();
        }
        else {
          this.router.navigate(['login/' + this.quaryValues]);
        }
      }
      else {
        this.router.navigate(['login/' + this.quaryValues]);
      }
    });
  }

  IsValidPriceQuoteEmailKey() {
    this.blockedDocument = true;
    this.emailLoginService.isValidPriceQuoteEmailKey(this.filterModel).subscribe(
      data => {
        this.blockedDocument = false;
        sessionStorage.setItem('emailPriceQuoteUId', data.userId);
        sessionStorage.setItem('emailPriceQuoteId',data.priceQuoteId);
        if (data.status == 'Success' && (typeof (localStorage.getItem('UserName')) != 'undefined' && localStorage.getItem('UserName') != null && localStorage.getItem('UserName') != '')) {
            window.open('/#/View/download-quotes-and-proposal/' + data.priceQuoteId, "_blank");
        }
        else if (data.status == 'Success'){
          this.router.navigate(['login/' + this.quaryValues]);
        }
        else if(data.status == 'Expired' && (typeof (localStorage.getItem('UserName')) != 'undefined' && localStorage.getItem('UserName') != null && localStorage.getItem('UserName') != '')){
          var Urldata='Access/Denied/'+decodeURI('pricequote');

          this.router.navigate([Urldata]);
        }
        else{
          this.router.navigate(['login']);
        }
      },
      error => {
        this.blockedDocument = false;
        console.log(error);
        this.router.navigate(['login']);
      }
    );
  }
}
