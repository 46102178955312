import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { TaxExemptionFormModel,TaxExemptionFormSectionsModel } from 'src/Models/TaxExemptionFormsModel';
import { CheckAuth } from 'src/Services/utility.service';
import { TaxExemptionFormsServiceService } from 'src/Services/tax-exemption-forms-service.service';
import { FilterModel } from 'src/Models/filterModel';

@Component({
  selector: 'app-managetax-exemption-form',
  templateUrl: './managetax-exemption-form.component.html',
  styleUrls: ['./managetax-exemption-form.component.css']
})
export class ManagetaxExemptionFormComponent implements OnInit {

  taxExemptionFormModel = new TaxExemptionFormModel();
  loginuserID: number = 0;
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;

  taxExemptionFormId: number = 0;
  isMultipleClick: boolean = false;
  file: any;
  @ViewChild('fileUpload', { static: true }) fileUpload;
  isShowprogressSpinner = false;
  blockedDocument = false;
  @ViewChild('FreeModelButton', { static: true }) FreeModelButton: ElementRef;
  popupmsg: string = '';
  issubmitted: boolean = false;
  dropdownOptionSettings = {}
  dropdownOptionItems = [];
  listOfOptionSelected = [];
  isSelectExternalURL: boolean = false;
  isSelectFileUpload: boolean = false;
  isErrorOnUploadDoc: boolean = false;
  selectedFiles: File;
  selectedOptionItems = [];
  listOfGettaxExemptionFormSectionData: TaxExemptionFormSectionsModel[];
  totalRecordCount: number = -1;
  isloading: boolean;
  filterModel = new FilterModel();
  dropdownListSection = [];
  selectedItemsSections = [];
  dropdownSettingsSections = {};
  selectedSectionItems = [];

  constructor(private activedRoute: ActivatedRoute, private _fb: FormBuilder, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title,
    private tefService: TaxExemptionFormsServiceService,
    private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Tax Exemption Forms');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');

    this.dropdownOptionSettings = {
      singleSelection: true,
      text: 'Select',
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 3,
      noDataLabel: 'No record available',
      showCheckbox: false
    };
   this.getAllSections();

  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.FillselectoptionDropdown();
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {

          this.taxExemptionFormId = params['id'];
          this.GetTaxExemptionFormData();
        }
      }
      else {
        this.taxExemptionFormModel.isActive = true;
      }
    });
    this.dropdownSettingsSections = {
      singleSelection: true,
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No Section available',
    };

  } 

  GetTaxExemptionFormData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tefService.i360TaxExemptionFormFindbyTaxExemptionFormId(this.tokenKey, this.taxExemptionFormId).subscribe(
      data => {
        if (data != null) {
          this.taxExemptionFormModel = data["taxExemptionFormData"];

          if (this.taxExemptionFormModel.linktype != 0) {
            this.selectedOptionItems = this.dropdownOptionItems.filter(x => x.id == this.taxExemptionFormModel.linktype);
            this.listOfOptionSelected.push(this.taxExemptionFormModel.linktype);
            if (this.taxExemptionFormModel.linktype == 1) {
              document.getElementById('inputfile').style.display = 'none'
              document.getElementById('inputUrl').style.display = 'block'
            }
            else {
              document.getElementById('inputfile').style.display = 'block'
              document.getElementById('inputUrl').style.display = 'none'
            }
          }
          if(this.taxExemptionFormModel.sectionId!=null && this.taxExemptionFormModel.sectionId!=0)
          { 
            if(this.dropdownListSection!=null && this.dropdownListSection.length>0)
            {
              this.selectedItemsSections = this.dropdownListSection.filter(x => x.id == this.taxExemptionFormModel.sectionId); 
            }
            else{
            setTimeout(() => {
          this.selectedItemsSections = this.dropdownListSection.filter(x => x.id == this.taxExemptionFormModel.sectionId); 
          }, 800);
         }
          }
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  getAllSections() {
    this.tefService.i360TaxExemptionFormsSectionListWithIds(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        for (let iCounter = 0; iCounter < data['sectionList'].length; iCounter++) {
          this.dropdownListSection.push(
            { 'id': data['sectionList'][iCounter].sectionId, 'itemName': data['sectionList'][iCounter].sectionName }
          );
        }
      }
    });
  }


  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  saveTaxExemptionForm(tEFForm) {
    this.issubmitted = true;

    if (this.listOfOptionSelected.length == 0) {
      //this.showMessage('Please Link Type', 'Link Type Required', 'error');
      return;
    }

    if (this.listOfOptionSelected[0] == 2) {
      if (this.fileUpload.nativeElement.files.length == 0 && !this.taxExemptionFormModel.displayDocName) {
        this.showMessage('Please select file', 'File Required', 'error');
        return;
      }
    } else {
      if (tEFForm.form.invalid) {
        return false;
      }
    }

    event.preventDefault();

    if (this.taxExemptionFormModel.title == '' || typeof (this.taxExemptionFormModel.title) == 'undefined' || this.taxExemptionFormModel.title == null) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }

    if (this.taxExemptionFormModel.sortOrder == '' || typeof (this.taxExemptionFormModel.sortOrder) == 'undefined' || this.taxExemptionFormModel.sortOrder == null) {
      // this.showMessage('Please enter SortOrder', 'SortOrder Required', 'error');
      if (this.taxExemptionFormModel.sortOrder != 0) {
        return;
      }
    }

    if (this.selectedItemsSections == null || this.selectedItemsSections.length == 0){
    this.showMessage('Please enter Section', 'Section Required', 'error');
    return;
    }

    this.taxExemptionFormModel.createdBy = this.loginuserID;
    this.taxExemptionFormModel.modifiedBy = this.loginuserID;
    let formData = new FormData();
    formData.append('taxExemptionFormId', this.taxExemptionFormModel.taxExemptionFormId);
    formData.append('title', this.taxExemptionFormModel.title);
    formData.append('displayDocName', this.taxExemptionFormModel.displayDocName);
    formData.append('docPath', this.taxExemptionFormModel.docPath);
    formData.append('fileUpload', this.fileUpload.nativeElement.files[0]);
    formData.append('createdBy', this.taxExemptionFormModel.createdBy);
    formData.append('modifiedBy', this.taxExemptionFormModel.modifiedBy);
    formData.append('isActive', this.taxExemptionFormModel.isActive);
    formData.append('isAllowallAccess', this.taxExemptionFormModel.isAllowallAccess);
    if (this.taxExemptionFormModel.externalURL != null && this.taxExemptionFormModel.externalURL != 'null') {
      formData.append('externalURL', this.taxExemptionFormModel.externalURL);
    }
    formData.append('linktype', this.listOfOptionSelected[0]);
    formData.append('sortOrder', this.taxExemptionFormModel.sortOrder);

    formData.append('sectionId', this.selectedItemsSections[0].id);

    if (this.isMultipleClick) {
      return;
    }
    this.isMultipleClick = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.tefService.i360TaxExemptionFormsAddUpdate(this.tokenKey, formData).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        if (data['taxExemptionFormId'] != 0) {
          if (data['taxExemptionFormId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.taxExemptionFormId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.taxExemptionFormId = 0;
            this.cancelTEF()
          }
        }
      },
      error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelTEF() {
    this.router.navigate(['admin/inteserra360/TaxExemptionForms']);
  }

  IfFreeProduct(event) {
    if (this.taxExemptionFormId > 0) {
      this.FreeModelButton.nativeElement.click();
      this.popupmsg = event ? 'Are you sure you want to allow access to this item to all companies and subscribers having access to Tax Exemption Form?' : 'Are you sure you want to remove access to this item from all companies and subscribers having access to this Tax Exemption Form?';
      console.log(this.taxExemptionFormId);
      console.log(event);
    }
  }

  Cancelpopup() {
   // this.GetSpecialReportData();
  }

  
  FillselectoptionDropdown() {
    this.dropdownOptionItems.push({ "id": '1', "itemName": "External URL" });
    this.dropdownOptionItems.push({ "id": '2', "itemName": "File Upload" });
  }

  onSelectOption($event) {
    this.listOfOptionSelected = [];
    this.listOfOptionSelected.push($event.id);
    if ($event.id == 1) {
      document.getElementById('inputfile').style.display = 'none'
      document.getElementById('inputUrl').style.display = 'block'
    } else if ($event.id == 2) {
      document.getElementById('inputfile').style.display = 'block'
      document.getElementById('inputUrl').style.display = 'none'
    } else {
      document.getElementById('inputfile').style.display = 'none'
      document.getElementById('inputUrl').style.display = 'none'
    }
  }

  onDeselectOption() {
    document.getElementById('inputfile').style.display = 'none'
    document.getElementById('inputUrl').style.display = 'none'
    this.listOfOptionSelected = [];
  }

  onDeselectAllOption() {
    document.getElementById('inputfile').style.display = 'none'
    document.getElementById('inputUrl').style.display = 'none'
    this.listOfOptionSelected = [];
  }

  fileEvent(event): any {
    var file = event.target.files[0];
    let size = Math.round(file.size / 1024);
    if (size <= 21000) {
      var allowedExtensions = ["doc", "DOC", "docx", "DOCX", "pdf", "PDF"];
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF, DOC, DOCX.', 'Error', 'error');
        this.isErrorOnUploadDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isErrorOnUploadDoc = false;
        this.selectedFiles = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnUploadDoc = true;
      window.scrollTo(0, 0);
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  onSelectedSection($event) {
    // this.selectedItemsSections = this.removeElemetninArray(this.selectedItemsSections, $event.id)
    // this.selectedItemsSections.push($event.id);
  }
  onDeSelectedSection($event) {
    if (this.selectedItemsSections != null) {
      if (this.selectedItemsSections.length > 0) {
        this.selectedItemsSections = this.removeElemetninArray(this.selectedItemsSections, $event.id)
      }
    }
  }
}
