export class WhitePapersModel {
    whitePaperId?: any;
    externalURL?: any;
    displayDocName: string;
    docPath: string;
    title?: any;
    isActive?: any;
    createdDate?: any;
    createdBy?: any;
    modifiedDate?: any;
    modifiedBy?: any;
    totalRecordCount?: any;
    isAllowallAccess?: any;
    isSelected?: any;
    productId?: any;
    linktype?: any;
    isEmbed?: any;
    isLocked?: any;
    sortOrder?:any;
    sectionId?: any
    sectionName?:any
}
export class WhitepaperSectionsModel{
    
    sectionId?: any
    sectionName?: any
    sortOrder?: any 
    isActive?: any 
    createdDate?: any 
    createdBy?: any 
    updatedDate?: any
    updatedBy?: any 
    totalRecordCount?: any

}