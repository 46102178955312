import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})
export class FrontAccessDeniedComponent implements OnInit {
  AccessDeniedTool: string = 'selected'
  constructor(private activedRoute: ActivatedRoute, private appActivity: ApplicationActivityService) {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.AccessDeniedTool = decodeURI(params['id']);
          this.AccessDeniedTool = this.AccessDeniedTool.replace(/-/g, "")
          var Urldata='Access/Denied/'+decodeURI(params['id']);
          if (this.AccessDeniedTool.toLocaleLowerCase() == "briefingsubscriptionservices")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.briefingServices, ActionActivity['Briefing Services'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "voippro")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.voip, ActionActivity.VoIP,Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "tracit")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.actionitem, ActionActivity['Trac It'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "wirelesspro")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.wireless, ActionActivity.Wireless,Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "crra")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.crra, ActionActivity.CRRA,Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "trfa")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.trfa, ActionActivity.TRFA,Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "telecomregulatoryfees911")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.state911, ActionActivity.State911,Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "localrateinformation")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.commissionWebsites, ActionActivity['Local Rate Information'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "ratesmanagementsystem")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.ratesManagementSystem, ActionActivity['Rates Management System'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "inrollclaimsacp")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.inrollACP, ActionActivity['Inroll ACP'],Urldata);         
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "ipr")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.ipr, ActionActivity.IPR,Urldata);
          // else if (this.AccessDeniedTool.toLocaleLowerCase() == "robocallmitigationdatabase")
          //   ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.state911, ActionActivity.State911);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "trscustomernotice")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.TRSCustomerNotice, ActionActivity['TRS Customer Notice'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "webinarlibrary")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.webinar, ActionActivity['Webinar Libary'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "specialreports")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.specialReport, ActionActivity['Special Report'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "training")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.training, ActionActivity.Training,Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "whitepapers")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.whitepapers, ActionActivity['White Papers'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "inteserraresources")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.InteserraResources, ActionActivity['Inteserra Resources'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "taxdocuments")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum['Tax Documents'], ActionActivity['Tax Documents'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "myfiles")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.MyFiles, ActionActivity['My Files'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "tariffsnet")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.tarrifsnet, ActionActivity['Tariffs.net'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "secretaryofstatesauthorization")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.secretaryofStatesAuthorization, ActionActivity['Secretary of States Authorization'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "cpcncertificates")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ActionActivity['CPCN Certificates'], ActionActivity['CPCN Certificates'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "exemptioncertificates")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.exemptionCertificates, ActionActivity['Exemption Certificates'],Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "companyprofile")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.PricingQuote, ActionActivity.PricingQuote,Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "quotesandproposals")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.QuotesAndProposals, ActionActivity.QuotesAndProposals,Urldata);
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "carrierfccobligation")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.View47, ActionActivity['View 47'],Urldata);
            else if (this.AccessDeniedTool.toLocaleLowerCase() == "pricequote")
            ApplicationActivityStore('DeniedEntry', 'Access Denied', this.appActivity, ToolEnum.PricingQuote, ActionActivity['Price Quote'],Urldata);


            if (this.AccessDeniedTool.toLocaleLowerCase() == 'tariffsnet')
              this.AccessDeniedTool = "Tariffs.Net";
          if (this.AccessDeniedTool.toLocaleLowerCase() == "ipr")
            this.AccessDeniedTool = "iPR";
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "voip")
            this.AccessDeniedTool = "VoIP";
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "actionitem")
            this.AccessDeniedTool = "Trac-It";
          else if (this.AccessDeniedTool.toLocaleLowerCase() == "briefingemailservices")
            this.AccessDeniedTool = "Briefing Email Services";


        }
      }
    });


  }

  ngOnInit() {
  }

}
