import { Component, HostListener, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserListFTPAccessModel } from 'src/Models/UserListFTPAccessModel';
import { Main911Service } from 'src/Services/main911.service';
import { TRFAServiceService } from 'src/Services/trfaservice.service';
import { ToolEnum } from 'src/Models/Common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-trfadownload',
  templateUrl: './trfadownload.component.html',
  styleUrls: ['./trfadownload.component.css']
})
export class TRFADownloadComponent implements OnInit {
  tokenKey: any;
  isShowprogressSpinner:boolean=false;
  isloading:boolean=false;
  SubscriberUserList: UserListFTPAccessModel[];
  PageNumber: number = 1;
  PageSize: number = 30;
  openMenuIndex: number = -1;
  orderBy:string='CompanyName';

  constructor(private toastr: ToastrService,private trfaService: TRFAServiceService,
    private main911Service: Main911Service,private titleService:Title) { 
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('TRFA FTP Data');
  }

  ngOnInit() {
    this.GetSubscriberUserListForDownload();
  }

  uploadDataonFTP()
  {
    this.isShowprogressSpinner=true;
    this.trfaService.UploadDataonFTP(this.tokenKey,null).subscribe(
      result => {
        this.isShowprogressSpinner=false;
        this.showMessage('Data Upload and Email Send Successfully', 'Success', 'success');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  GetSubscriberUserListForDownload(){
    this.isloading = true;
    this.main911Service.GetSubscriberUserList(this.tokenKey, this.PageNumber, this.PageSize, this.orderBy,ToolEnum.trfa.toString()).subscribe(
      data => {
        this.isloading = false;
        this.SubscriberUserList = data['main'];
      },
      error => { }
    );
  }

  sendMail(userId)
  {
    this.isShowprogressSpinner=true;
    this.trfaService.UploadDataonFTP(this.tokenKey,userId).subscribe(
      result => {
        this.isShowprogressSpinner=false;
        this.showMessage('Data Upload and Email Send Successfully', 'Success', 'success');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
