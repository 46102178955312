import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterObligationUpdateModel } from 'src/Models/ObligationUpdatesModel';

@Injectable({
  providedIn: 'root'
})
export class CategoryObligationService {

  AppUrl: string = "";
  filterObligationUpdateModel = new FilterObligationUpdateModel

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  CategoryAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CategoryTypeObligationMaster/CategoryAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllCategory(TokenKey: string, PageNumber: number, PageSize: number, filterText: string,orderBy:string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CategoryTypeObligationMaster/GetAllCategory/' + filterText + '/' + PageNumber + '/' + PageSize+'/'+orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCategoryById(TokenKey: string,CategoryTypeId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CategoryTypeObligationMaster/GetCategoryById/' + CategoryTypeId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteCategory(TokenKey: string, CategoryTypeId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CategoryTypeObligationMaster/DeleteCategory/' + CategoryTypeId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetView47DashboardCount(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CarrierObligationMaster/GetDashboardCount';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
