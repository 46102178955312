export class BriefingMasterModel {
    briefingId?: any;
    stateId?: any;
    updateDate?: any;
    title?: any;
    description?: any;
    displayBriefingDocName?: any;
    briefingDocPath?: any;
    displaySupportedDocName?: any;
    supportedDocPath?: any;
    isActive?: any;
    createdBy?: any;
    createdTime?: any;
    modifiedBy?: any;
    modifiedTime?: any;
    stateName?: any;
    shortName?: any;
    totalRecordCount?: any;
    bulletinTypeId?: string;
    bulletinSubTypeId?: string;
    listOfSelectdBulletinType?: any;
    listOfSelectdBulletinSubType?: any;
    briefingCategoryId?: any;
    price?: any;
    isPaid?: any;
    backupTitle?: any;
    notes: string;
    filedAt: string;
    stateMonitoringId: any;
    companyId?: any;
    companyUsersIds?: any;
    companyEmailStatus?: any;
    isPushActionItem?: any;
    sourceNumber: string;
    briefingUserlist?: any;
    stateCount?: any;
    isInFavorite?: any;
    isAvalibleForDownload?: any;
    executiveSummary?: string;
    fullBriefingDocumentURL?: string;
    fullSupportedDocumentURL?: string;
    displayDocumentURL?: string;
    isRecordsDuplicates?: any;
    duplicatesId?: any;
    sourceDate?: any;
    acquiredDate?: any;
    emailStatus?:string;
    failureReason?:string;
    totalEmailCount?:number;
    emailSentCount?:number;
    emailFailedCount?:number;
}

export class BriefingTypeModel {
    carrierCatId?:any;
    carrierCatName?:any;
    sortOrder?:any;
    totalRecordCount?: any;
}

export class BriefingSubTypeModel {
    bulletinSubTypeId?:any;
    bulletinSubType?:any;
    shortName?:any;
    sortOrder?:any;
    totalRecordCount?: any;
}