
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http, Response, ResponseContentType } from '@angular/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HtmlToPdf {
    AppUrl: string = "";
    constructor(private _http: Http, private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.setAPIUrl();
    }

    setAPIUrl() {
        if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
            this.AppUrl = sessionStorage.getItem("BaseURL") || '';
        }
    }

    CreatePDF(TokenKey: string, HTMLContent: string, Orientation: any, productId: number): Observable<any> {
        this.setAPIUrl();
        this.AppUrl = this.AppUrl + 'api/MyPdf/GetPDF';
        let input: FormData = new FormData();
        input.append('HTMLContent', HTMLContent);
        input.append('Orientation', Orientation);
        input.append('Token', TokenKey);
        input.append('productId', productId.toString());
        return this.http.post<any>(this.AppUrl, input)
            .pipe(catchError(this.handleError));
    }

    CreateAdminPDF(TokenKey: string, HTMLContent: string, Orientation: any): Observable<any> {
        this.setAPIUrl();
        this.AppUrl = this.AppUrl + 'api/MyPdf/GetAdminPDF';
        let input: FormData = new FormData();
        input.append('HTMLContent', HTMLContent);
        input.append('Orientation', Orientation);
        input.append('Token', TokenKey);
        return this.http.post<any>(this.AppUrl, input)
            .pipe(catchError(this.handleError));
    }

    GetPriceQuoteProposalPDF(TokenKey: string, HTMLContent: string, Orientation: any): Observable<any> {
        this.setAPIUrl();
        this.AppUrl = this.AppUrl + 'api/MyPdf/GetPriceQuoteProposalPDF';
        let input: FormData = new FormData();
        input.append('HTMLContent', HTMLContent);
        input.append('Orientation', Orientation);
        input.append('Token', TokenKey);
        return this.http.post<any>(this.AppUrl, input)
            .pipe(catchError(this.handleError));
    }


    CreateAuditReportPDF(TokenKey: string, auditMonth: string,StateId:string,FundId:string, Orientation: any): Observable<any> {        
        this.setAPIUrl();
        this.AppUrl = this.AppUrl + 'api/MyPdf/CreateAuditReportPDF';
        let input: FormData = new FormData();
        input.append('AuditMonth', auditMonth);
        input.append('StateId', StateId);
        input.append('FundId', FundId);
        input.append('Orientation', Orientation);
        input.append('Token', TokenKey);
        return this.http.post<any>(this.AppUrl, input)
            .pipe(catchError(this.handleError));
    }

    CreateAuditReportCSV(TokenKey: string, auditMonth: string,StateId:string,FundId:string, Orientation: any): Observable<any> {        
        this.setAPIUrl();
        this.AppUrl = this.AppUrl + 'api/MyPdf/CreateAuditReportCSV';
        let input: FormData = new FormData();
        input.append('AuditMonth', auditMonth);
        input.append('StateId', StateId);
        input.append('FundId', FundId);
        input.append('Orientation', Orientation);
        input.append('Token', TokenKey);
        return this.http.post<any>(this.AppUrl, input)
            .pipe(catchError(this.handleError));
    }
    

    DeletePDF(TokenKey: string, FileName: string): Observable<any> {        
        this.setAPIUrl();        
        this.AppUrl = this.AppUrl + 'api/MyPdf/DeletePDFFile';
        let input: FormData = new FormData();
        input.append('FileName', FileName);
        input.append('Token', TokenKey);
        return this.http.post<any>(this.AppUrl, input)
            .pipe(catchError(this.handleError));
    }

    DeleteCSV(TokenKey: string, FileName: string): Observable<any> {        
        this.setAPIUrl();        
        this.AppUrl = this.AppUrl + 'api/MyPdf/DeleteCSVFile';
        let input: FormData = new FormData();
        input.append('FileName', FileName);        
        input.append('Token', TokenKey);
        return this.http.post<any>(this.AppUrl, input)
            .pipe(catchError(this.handleError));
    }


    public downloadPdf(url): Observable<any> {
        return this._http.get(url, { responseType: ResponseContentType.Blob }).pipe(map(
            (res) => {
                return new Blob([res.blob()], { type: 'application/pdf' });
            }));
    }

    private handleError(error: Response | any) {
        console.error(error.message || error);
        return observableThrowError(error.status)
    }

    CreateDepositePDF(TokenKey: string, stateId: string, filterText: string,userId:any,isFavorite:any,Orientation: any): Observable<any> {
        this.setAPIUrl();
        this.AppUrl = this.AppUrl + 'api/MyPdf/CreateDepositePDF';
        let input: FormData = new FormData();
        input.append('stateId', stateId);   
        input.append('filterText', filterText); 
        input.append('userId', userId);
        input.append('isFavorite', isFavorite);
        input.append('Orientation', Orientation);
        input.append('Token', TokenKey);
        return this.http.post<any>(this.AppUrl, input)
            .pipe(catchError(this.handleError));
    }


}