export class Data477Model {
    dataId?: any;
    title?: any;
    url?: any;
    isActive?: any;
    createdBy?: any;
    createdTime?: any;
    modifiedBy?: any;
    modifiedTime?: any;
    totalRecordCount?: any;
}