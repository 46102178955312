import { Component, OnInit } from '@angular/core';
import { ActionItemSearchModel } from 'src/Models/ActionItemSearchModel';
import { ActionItemService } from 'src/Services/actionitem.service';
import { CheckAuth } from 'src/Services/utility.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-action-item-details-popup',
  templateUrl: './action-item-details-popup.component.html',
  styleUrls: ['./action-item-details-popup.component.css']
})
export class ActionItemDetailsPopupComponent implements OnInit {

  stateArray: any = [];
  carrierCategoryArray: any = [];
  actionItemSearchModel = new ActionItemSearchModel()

  tokenKey: string = '';
  companyId: number = 0;
  userId: number = 0;
  actionId: number = 0;
  updateId: number = 0;
  productId: number = 0;  

  isAccordianShow: boolean = false;
  
  constructor(private actionItemServices: ActionItemService, private checkAuth: CheckAuth, public activeModal: NgbActiveModal) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyId = +sessionStorage.getItem("CompanyId");
    this.userId = +sessionStorage.getItem('UserId');
    this.productId = +sessionStorage.getItem('productId');
    this.updateId = +sessionStorage.getItem('updateId');
    this.actionId = +sessionStorage.getItem('actionId');

    sessionStorage.removeItem('productId');
    sessionStorage.removeItem('updateId');
    sessionStorage.removeItem('actionId');

    this.ActionItemSearchByIds(this.userId, this.updateId, this.actionId);
  }


  ActionItemSearchByIds(userId: any, updateId: any, actionId: any) {
    this.actionItemServices.ActionItemSearchByIds(this.tokenKey, userId, updateId, actionId).subscribe(
      data => {
        
        if (data['status'] == 'Success') {
          this.actionItemSearchModel = data['actionItemSearchModel'];

          if (this.actionItemSearchModel.stateName != 'null' && typeof (this.actionItemSearchModel.stateName) != 'undefined') {            
            this.stateArray = this.actionItemSearchModel.stateName.split(',');
          }
          if (this.actionItemSearchModel.carrierNames != 'null' && typeof (this.actionItemSearchModel.carrierNames) != 'undefined') {            
            this.carrierCategoryArray = this.actionItemSearchModel.carrierNames.split(',');
          }          
        }
      });
  }
}
