import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TRFAContentGetModel, TRFAContentModel } from 'src/Models/TRFAContentModel';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TRFAFundGetModel, TRFAFundModel } from 'src/Models/TRFAFundModel';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { TrfaContentMasterService } from 'src/Services/trfa-content-master.service';
import { TRFASourceModel } from 'src/Models/TRFASourceModel';
import { TrfaSourceMasterService } from 'src/Services/trfa-source-master.service';
import { TrfaCategoryMasterService } from 'src/Services/trfa-category-master.service';
import { RouterExtService } from 'src/Services/RouterExtService';
import { DatePipe } from '@angular/common';
import { TrfaCarrierMasterService } from 'src/Services/trfa-carrier--master.service';


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofContent: TRFAContentGetModel[]
  templistofContent: TRFAContentGetModel[]

  PageNumber: number;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;

  auditMonth: any;
  listOfMonth1 = [];

  upDowRuleTextCSS: string = '';
  upDowfundNameCSS: string = '';
  upDowSubcategoryCSS: string = '';
  upDowEffectiveDateCSS: string = '';
  upDowRuleCitationCSS: string = '';
  upDowLastAuditCSS: string = '';
  upDowCategoryCSS: string = '';
  upDowSourceCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];

  //Fund
  fundID: string = ' ';
  dropdownListFund = [];
  selectedItemsFund = [];
  dropdownSettingsFund = {};
  listOffundIDs = [];
  fundList: TRFAFundModel[] = [];

  //Source
  SourceList: TRFASourceModel[] = [];
  selectedItemsSource = [];
  dropdownListSource = [];
  dropdownSettingsSource = {};
  listOfsource = [];
  sourceTypeId: string = '';

  categoryListMain: any[] = [];
  dropdownDateCategoryFilterMain: any[] = [];
  dropdownSettingsDateCategoryFilterMain = {};
  selectedItemsCategoryMain = [];
  listOfCategoryIDsMain = [];
  categoryId: string = '';

  module = "TRFA";
  stateIds: string = ' ';
  ContentId:number = 0;
  openMenuIndex: number = -1;
  isRowEdited = false;

  carrierList: any[] = [];
  dropdownDateCarrierFilter: any[] = [];
  dropdownSettingsDateCarrierFilter = {};
  selectedItemsCarrier = [];
  listOfCarrierIDs = [];
  listOfCarrierName = [];

  categoryList: any[] = [];
  dropdownDateCategoryFilter: any[] = [];
  dropdownSettingsDateCategoryFilter = {};
  selectedItemsCategory = [];
  listOfCategoryIDs = [];
  categorysId: string = '';
  listOfCategoryName = [];
  userID: any;
  ckeConfig:any;

  constructor(private router: Router, private trfaContentMaster: TrfaContentMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService, private stateService: StateMasterService, private trfaFundMaster: TrfaFundMasterService,
    private trfaSourceMaster: TrfaSourceMasterService, private categoryMasterService: TrfaCategoryMasterService,
    private routerService: RouterExtService,private datePipe: DatePipe,private carrierMasterService: TrfaCarrierMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofContent = [];
    
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'StateName,FundName';
    this.Note = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Contents');
    this.userID = +sessionStorage.getItem('AdminUserId');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsFund = {
      singleSelection: false,
      text: "Filter by Fund",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateCarrierFilter = {
      singleSelection: false,
      text: "Select carriers",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Carrier(s) available',
      showCheckbox: true
    };

    // this.dropdownSettingsSource = {
    //   singleSelection: false,
    //   text: "Filter by Source",
    //   enableSearchFilter: true,
    //   classes: "borderLessTextBoxGreen",
    //   badgeShowLimit: 1,
    //   noDataLabel: 'No Source(s) available',
    //   showCheckbox: true
    // };

    this.dropdownSettingsDateCategoryFilterMain = {
      singleSelection: false,
      text: "Filter by Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateCategoryFilter = {
      singleSelection: false,
      text: "Filter by Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };

    this.ckeConfig = {
      versionCheck:false,
      toolbar: [
        { items:
          [
          'Undo','Redo','Link','Unlink','Anchor',
          'Image','Table','SpecialChar','Maximize','Source','Bold','Italic','Underline','Strike','RemoveFormat',
          'NumberedList','BulletedList','Outdent','Indent','Styles','Format','Font'
          ]
        }],
    }

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.getFund();
    this.getCategory();
    this.getCarrier();
    this.PageNumber = 1;
    if (this.routerService.getPreviousUrl().includes('admin/trfa/content/manage') || this.routerService.getPreviousUrl().includes('admin/trfa/content/history')) {
      this.setSaveFilter();
    }
    else {
      this.clearSession();
      this.OrderBy = 'CategorySort';
      this.GetContent();
    }      
    // this.getSource();    
    
    
  }

  

  getCarrier() {
    this.carrierMasterService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierList = result['carrier'];
          this.dropdownDateCarrierFilter = [];
          this.carrierList.forEach(x => {
            this.dropdownDateCarrierFilter.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }
  
  getCategory() {
    this.categoryMasterService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {

          this.categoryListMain = result['category'];
          this.dropdownDateCategoryFilterMain = [];
          this.categoryListMain.forEach(x => {
            this.dropdownDateCategoryFilterMain.push({ "id": x.categoryID, "itemName": x.categoryName })
          });

          this.dropdownDateCategoryFilter = [];
          this.categoryListMain.forEach(x => {
            this.dropdownDateCategoryFilter.push({ "id": x.categoryID, "itemName": x.categoryName })
          });


          if (this.listOfCategoryIDsMain != null && this.listOfCategoryIDsMain != undefined) {
            for (let iCounter = 0; iCounter < this.listOfCategoryIDsMain.length; iCounter++) {
              if (this.listOfCategoryIDsMain[iCounter] != '') {
                let obj = result["category"].filter(m => m.categoryID == this.listOfCategoryIDsMain[iCounter]);
                if (obj != null) {
                  this.selectedItemsCategoryMain.push({ "id": obj[0].categoryID, "itemName": obj[0].categoryName });
                }
              }
            }
          }

        }
      },
      error => { }
    );
  }
  getFund() {
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.trfaFundMaster.GetFund(this.tokenKey, this.stateId, true,false).subscribe(
      data => {
        this.dropdownListFund = []
        this.fundList = data['fund'];
        this.dropdownListFund = data['fund'];
        this.selectedItemsFund = [];
        if (this.fundList.length > 0) {
          let b = [];
          this.selectedItemsFund = [];
          this.fundList.forEach(x => {
            let a = this.listOffundIDs.filter(e => e == x.fundID);
            if (a.length > 0) {
              b.push(x.fundID);
              this.selectedItemsFund.push(x)
            }
            // this.selectedItemsFund.push(x)
          });
          this.listOffundIDs = b;
        }
        else {
          this.selectedItemsFund = [];
          this.listOffundIDs = [];
        }



        // if (this.fundList.length > 0) {
        //   this.fundList.forEach(x => {
        //     this.dropdownListFund.push({ "id": x.fundID, "itemName": x.fundName })
        //   });
        // }
      },
      error => { }
    );
  }
  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetContent();
  }


  GetContent() {
    this.isloading = true;
    this.fundID = ' ';
    if (this.listOffundIDs.length > 0) {
      this.fundID = this.listOffundIDs.join(',')
    }
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    // this.sourceTypeId = ' ';
    // if (this.listOfsource.length > 0) {
    //   this.sourceTypeId = this.listOfsource.join(',')
    // }

    this.categoryId = ' ';
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }

    this.auditMonth= '';
    if (this.listOfMonth1.length > 0) {
      this.auditMonth = this.listOfMonth1.join(',')
    }   

    if (this.Note == "null") this.Note = " ";
    this.trfaContentMaster.GetAllContent(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, this.fundID, " ",this.categoryId, "", this.OrderBy,this.auditMonth).subscribe(
      data => {
        this.isloading = false;
        this.listofContent = data['content'];

        
        if (this.listofContent != null) {
          if (this.listofContent.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
          }

          this.listofContent.forEach(element => {
            element['isEdit'] = false;
          });
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else
      this.Note = 'null';
    this.resetPageNoandSize();
    this.GetContent();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowEffectiveDateCSS = '';
      this.upDowLastAuditCSS = '';
      this.upDowRuleCitationCSS = '';
      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-down';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "FundID") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = 'fa fa-arrow-down';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "RuleCitation") {
        this.upDowRuleTextCSS = '';
        this.upDowRuleCitationCSS = 'fa fa-arrow-down';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "Subcategory") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = 'fa fa-arrow-down';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-down';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "lastAudit") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowLastAuditCSS = 'fa fa-arrow-down';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "CategoryName") {
        this.upDowRuleCitationCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowRuleTextCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowLastAuditCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "SourceName") {
        this.upDowRuleCitationCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowRuleTextCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowLastAuditCSS = '';
        this.upDowCategoryCSS = '';
        this.upDowSourceCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-up';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "FundID") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = 'fa fa-arrow-up';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "RuleCitation") {
        this.upDowRuleTextCSS = '';
        this.upDowRuleCitationCSS = 'fa fa-arrow-up';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "Subcategory") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = 'fa fa-arrow-up';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-up';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "lastAudit") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowLastAuditCSS = 'fa fa-arrow-up';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "CategoryName") {
        this.upDowRuleCitationCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowRuleTextCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowLastAuditCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "SourceName") {
        this.upDowRuleCitationCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowRuleTextCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowLastAuditCSS = '';
        this.upDowCategoryCSS = '';
        this.upDowSourceCSS = 'fa fa-arrow-up';
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  addContent() {
    let navigation = '/admin/' + this.SelectedTool + '/content/manage';
    this.router.navigate([navigation]);
  }

  ContentContent(ContentId) {
    this.saveSession();
    // let navigation = '/admin/' + this.SelectedTool + '/content/manage/' + ContentId;
    // this.router.navigate([navigation]);
    window.open('/#/admin/' + this.SelectedTool + '/content/manage/' + ContentId,"_blank");
  }


  GetContentById(Content) {

    this.listofContent.forEach(element => {
      element['isEdit'] = false;
    });

    this.templistofContent = JSON.parse(JSON.stringify(this.listofContent));
    Content.isEdit = true;
    Content.effectiveDate = new Date(Content.effectiveDate);
    if(Content.lastAudit!=null)
    {
    Content.lastAudit = new Date(Content.lastAudit);
    }

    this.isRowEdited = true;
    this.trfaContentMaster.GetContentById(this.tokenKey, parseInt(Content.contentID.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (this.carrierList.length > 0) {
            this.selectedItemsCarrier = []
            this.listOfCarrierIDs = [];
            this.listOfCarrierName=[];
            for (let iCounter = 0; iCounter < result['content'].carrierTypeID.length; iCounter++) {
              let data = this.carrierList.filter(x => x.carrierTypeId == result['content'].carrierTypeID[iCounter].carrierTypeId)[0];
              this.selectedItemsCarrier.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
              this.listOfCarrierIDs.push(data.carrierTypeId)
              this.listOfCarrierName.push(data.carrierName)
            }
          }

          if (this.categoryListMain.length > 0) {
            this.selectedItemsCategory = [];
            this.listOfCategoryIDs = [];
            this.listOfCategoryName=[];
            result['content'].categoryID.map((x) => {
              let data = this.categoryListMain.filter(y => y.categoryID == x.categoryID)[0];
              this.selectedItemsCategory.push({ "id": data.categoryID, "itemName": data.categoryName })
              this.listOfCategoryIDs.push(data.categoryID)
              this.listOfCategoryName.push(data.categoryName);
            })
          }
        }
      });
  }

  setDeleteData(id) {
    this.ContentId = id;
  }

  deleteContent(ContentId) {
    this.isloading = true;
    this.trfaContentMaster.DeleteContent(this.tokenKey, ContentId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          // if (data['message'] != "")
          //   this.showMessage(data['message'], 'Warning', 'warning')
          // else
          this.showMessage("Content Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.GetContent();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }


  onSelectFund($event) {
    this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
    this.listOffundIDs.push($event.id);
    // this.stateId = ' ';
    // if (this.listOffundIDs.length > 0) {
    //   this.stateId = this.listOffundIDs.join(',')
    // }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectFund($event) {
    if (this.listOffundIDs != null) {
      if (this.listOffundIDs.length > 0) {
        this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event.id)
      }
    }
    if (this.listOffundIDs.length > 0) {
      this.stateId = this.listOffundIDs.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllFund($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllFund($event) {
    this.listOffundIDs = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  replaceCommaLine(data) {

    let dataToArray = data.split(',').map(item => item.trim());
    let list = data.split(",").join("<br />")
    // return dataToArray.join("\n");
    return list
  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        console.log(this.stateList)
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }

        if (this.listOfStateIds != null && this.listOfStateIds != undefined) {
          for (let iCounter = 0; iCounter < this.listOfStateIds.length; iCounter++) {
            if (this.listOfStateIds[iCounter] != '') {
              let obj = data["state"].filter(m => m.stateId == this.listOfStateIds[iCounter]);
              if (obj != null) {
                this.selectedItemsState.push({ "id": obj[0].stateId, "itemName": obj[0].name });
              }
            }
          }
        }

      },
      error => { }
    );
  }

  getSource() {
    this.trfaSourceMaster.GetSource(this.tokenKey).subscribe(
      data => {
        this.SourceList = data['source'];
        this.dropdownListSource = [];
        console.log(this.SourceList)
        if (this.SourceList.length > 0) {
          this.SourceList.forEach(x => {
            this.dropdownListSource.push({ "id": x.sourceTypeId, "itemName": x.sourceName })
          });
        }
      },
      error => { }
    );
  }

  onSelectSource($event) {
    this.listOfsource = this.removeElemetninArray(this.listOfsource, $event.id)
    this.listOfsource.push($event.id);
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectSource($event) {
    if (this.listOfsource != null) {
      if (this.listOfsource.length > 0) {
        this.listOfsource = this.removeElemetninArray(this.listOfsource, $event.id)
      }
    }
    if (this.listOfsource.length > 0) {
      this.sourceTypeId = this.listOfsource.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllSource($event) {
    this.listOfsource = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllSource($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfsource != null) {
        if (this.listOfsource.length > 0) {
          this.listOfsource = this.removeElemetninArray(this.listOfsource, $event[iCounter].id)
          this.listOfsource.push($event[iCounter].id);
        }
        else {
          this.listOfsource.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }
  onSelectCategoryMain($event) {
    this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event.id)
    this.listOfCategoryIDsMain.push($event.id);
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectCategoryMain($event) {
    if (this.listOfCategoryIDsMain != null) {
      if (this.listOfCategoryIDsMain.length > 0) {
        this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event.id)
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllCategoryMain($event) {
    this.listOfCategoryIDsMain = []
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllCategoryMain($event) {

    this.listOfCategoryIDsMain = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  clear(){
    this.stateId = '';
    this.fundID = ''; 
    this.categoryId = '';

    this.selectedItemsState = [];
    this.selectedItemsFund = [];
    this.selectedItemsCategoryMain = [];
    
    this.listOfStateIds = [];
    this.listOffundIDs = [];
    this.listOfCategoryIDsMain = [];

    this.GetContent(); 
  }
  
  saveSession() {
    sessionStorage.setItem('ContentSaveFilterPageNo', this.PageNumber.toString());
    sessionStorage.setItem('ContentSaveFilterOrderBy', this.OrderBy);
    sessionStorage.setItem('ContentSaveFilterIsAscending', this.isAscending ? 'true' : 'false');
    // var ContentSaveFilterFundId='';
    // if (this.listOffundIDs.length > 0) {
    //   ContentSaveFilterFundId=this.listOffundIDs.join(',');
    // }
    sessionStorage.setItem('ContentSaveFilterFundId', JSON.stringify(this.listOffundIDs));
    // var ContentSaveFilterStateId='';
    // if (this.listOfStateIds.length > 0) {
    //   ContentSaveFilterStateId=this.listOfStateIds.join(',');
    // }
    sessionStorage.setItem('ContentSaveFilterStateId', JSON.stringify(this.listOfStateIds));

    // var ContentSaveFilterCategoryId='';
    // if (this.listOfCategoryIDsMain.length > 0) {
    //   ContentSaveFilterCategoryId=this.listOfCategoryIDsMain.join(',');
    // }
    sessionStorage.setItem('ContentSaveFilterCategoryId', JSON.stringify(this.listOfCategoryIDsMain));
  }

  clearSession() {
    sessionStorage.removeItem('ContentSaveFilterPageNo');
    sessionStorage.removeItem('ContentSaveFilterOrderBy');
    sessionStorage.removeItem('ContentSaveFilterIsAscending');
    sessionStorage.removeItem('ContentSaveFilterFundId');
    sessionStorage.removeItem('ContentSaveFilterStateId');
    sessionStorage.removeItem('ContentSaveFilterCategoryId');
  }

  setSaveFilter() {
    if (sessionStorage.getItem('ContentSaveFilterPageNo') != null && sessionStorage.getItem('ContentSaveFilterPageNo') != '' && sessionStorage.getItem('ContentSaveFilterPageNo') != 'undefined') {
      this.PageNumber = parseInt(sessionStorage.getItem('ContentSaveFilterPageNo'));
    }
    if (sessionStorage.getItem('ContentSaveFilterOrderBy') != null && sessionStorage.getItem('ContentSaveFilterOrderBy') != '' && sessionStorage.getItem('ContentSaveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('ContentSaveFilterOrderBy');
    }
    if (sessionStorage.getItem('ContentSaveFilterIsAscending') != null && sessionStorage.getItem('ContentSaveFilterIsAscending') != '' && sessionStorage.getItem('ContentSaveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('ContentSaveFilterIsAscending') == 'false' ? false : true;
      if (this.isAscending) {
        //this.setDescCSS(this.OrderBy.replace('DESC', '').trim());
        this.ContentSessionSort(this.OrderBy);
      } else {
        //this.setAscCSS(this.OrderBy.replace('DESC', '').trim());
        this.ContentSessionSort(this.OrderBy);
      }
    }
    if (sessionStorage.getItem('ContentSaveFilterFundId') != null && sessionStorage.getItem('ContentSaveFilterFundId') != '' && sessionStorage.getItem('ContentSaveFilterFundId') != 'undefined') {
      this.listOffundIDs = JSON.parse(sessionStorage.getItem('ContentSaveFilterFundId'));
    }
    if (sessionStorage.getItem('ContentSaveFilterStateId') != null && sessionStorage.getItem('ContentSaveFilterStateId') != '' && sessionStorage.getItem('ContentSaveFilterStateId') != 'undefined') {
      this.listOfStateIds = JSON.parse(sessionStorage.getItem('ContentSaveFilterStateId'));
    }
    if (sessionStorage.getItem('ContentSaveFilterCategoryId') != null && sessionStorage.getItem('ContentSaveFilterCategoryId') != '' && sessionStorage.getItem('ContentSaveFilterCategoryId') != 'undefined') {
      this.listOfCategoryIDsMain = JSON.parse(sessionStorage.getItem('ContentSaveFilterCategoryId'));
    }
     this.GetContent();
    this.clearSession();
  }

  ContentSessionSort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy;
      this.upDowEffectiveDateCSS = '';
      this.upDowLastAuditCSS = '';
      this.upDowRuleCitationCSS = '';
      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-down';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "FundID") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = 'fa fa-arrow-down';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "RuleCitation") {
        this.upDowRuleTextCSS = '';
        this.upDowRuleCitationCSS = 'fa fa-arrow-down';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "Subcategory") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = 'fa fa-arrow-down';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-down';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "lastAudit") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowLastAuditCSS = 'fa fa-arrow-down';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "CategoryName") {
        this.upDowRuleCitationCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowRuleTextCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowLastAuditCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "SourceName") {
        this.upDowRuleCitationCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowRuleTextCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowLastAuditCSS = '';
        this.upDowCategoryCSS = '';
        this.upDowSourceCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-up';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "FundID") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = 'fa fa-arrow-up';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "RuleCitation") {
        this.upDowRuleTextCSS = '';
        this.upDowRuleCitationCSS = 'fa fa-arrow-up';
        this.upDowSubcategoryCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "Subcategory") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowSubcategoryCSS = 'fa fa-arrow-up';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-up';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "lastAudit") {
        this.upDowRuleTextCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowLastAuditCSS = 'fa fa-arrow-up';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = '';
      }
      else if (sortBy === "CategoryName") {
        this.upDowRuleCitationCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowRuleTextCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowLastAuditCSS = '';
        this.upDowSourceCSS = '';
        this.upDowCategoryCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "SourceName") {
        this.upDowRuleCitationCSS = '';
        this.upDowSubcategoryCSS = '';
        this.upDowRuleTextCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowLastAuditCSS = '';
        this.upDowCategoryCSS = '';
        this.upDowSourceCSS = 'fa fa-arrow-up';
      }
    }
  }

  PreviousContentData(ContentId){
    this.saveSession();
    let navigation = '/admin/' + this.SelectedTool + '/content/history/' + ContentId;
    this.router.navigate([navigation]);
  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
          this.listOffundIDs.push($event[iCounter].id);
        }
        else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllFund($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(this.listOffundIDs, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllCategory($event){
    this.listOfCategoryIDsMain = []
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllCategory($event){
    this.listOfCategoryIDsMain = []
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

  cancelEdit(data) {
    data.isEdit = false;
    this.isRowEdited = false;
    this.selectedItemsCarrier = [];
    this.selectedItemsCategory = [];
    this.selectedItemsCategoryMain = [];
    this.selectedItemsSource = [];
    this.selectedItemsFund = [];
    this.selectedItemsState= [];

    this.listofContent = this.templistofContent;
  }

  saveContent(item) {
    this.saveSession();
    let userId= this.userID;
    let carrierId = this.listOfCarrierIDs.join(',');
    let carrierName=this.listOfCarrierName.join(',');
    let categoryId = this.listOfCategoryIDs.join(',');
    let CategoryName=this.listOfCategoryName.join(',');
    // let editlistOffundID = this.EditlistOffundIDs.join(',');
    // let fundName = this.EditlistOfFundName.join(',');
    // let sourceID = this.EditlistOfsource.join(',');
    // let sourceName = this.EditlistOfsourceName.join(',');
    // let MonthList = this.listOfMonth.join(',');

    
      if (carrierId == "") {
        this.showMessage("Please select carrier", 'warning', 'error');
        return;
      }
      if (categoryId == "") {
        this.showMessage("Please select category", 'warning', 'error');
        return;
      }
      if (item.ruleText == "") {
        this.showMessage("Please Enter ruleText", 'warning', 'error');
        return;
      }
      if (item.effectiveDate == "") {
        this.showMessage("Please select effectivedate", 'warning', 'error');
        return;
      }
      // if (editlistOffundID == "") {
      //   this.showMessage("Please select fund", 'warning', 'warning');
      // }
   

      if (item.internalNote == null) {
        item.internalNote = "";
      }
      if (item.ruleCitation == null) {
        item.ruleCitation = "";
      }
      if (item.subcategory == null) {
        item.subcategory = "";
      }

      let value = {
        contentID: item.contentID,
        // fundID: editlistOffundID,
        effectiveDate: this.datePipe.transform(item.effectiveDate, 'yyyy-MM-dd hh:mm:ss'),
        ruleText: item.ruleText.trim(),
        ruleCitation: item.ruleCitation == "" ? item.ruleCitation : item.ruleCitation.trim(),
        internalNote: item.internalNote == "" ? item.internalNote : item.internalNote.trim(),
        subcategory: item.subcategory == "" ? item.subcategory : item.subcategory.trim(),
        carrierTypeID: carrierId,
        categoryID: categoryId,
        lastAudit: this.datePipe.transform(item.lastAudit, 'yyyy-MM-dd hh:mm:ss'),
        // auditMonth: MonthList == '' ? null : MonthList,
        // sourceTypeId: sourceID == "" ? "" : sourceID,
        userId:userId,
        carrierTypeName:carrierName,
        CategoryTypeName:CategoryName
        // sourceTypeName: sourceName == '' ? null : sourceName,
        // fundName: fundName
      }

      let formData = new FormData();
    formData.append('contentID', item.contentID);
    formData.append('fundID', item.fundID);
    formData.append('effectiveDate', this.datePipe.transform(item.effectiveDate, 'yyyy-MM-dd hh:mm:ss'));
    formData.append('ruleText', item.ruleText==null?null:item.ruleText.trim());
    formData.append('ruleCitation', item.ruleCitation == "" ? item.ruleCitation : item.ruleCitation.trim());
    formData.append('internalNote', item.internalNote == "" ? item.internalNote : item.internalNote.trim(),);
    formData.append('subcategory', item.subcategory == "" ? item.subcategory : item.subcategory.trim());
    formData.append('carrierTypeID', carrierId);
    formData.append('categoryID', categoryId);
    formData.append('lastAudit', this.datePipe.transform(item.lastAudit, 'yyyy-MM-dd hh:mm:ss'));
    // formData.append('auditMonth', MonthList);
    // formData.append('sourceTypeId', sourceID == "" ? "" : sourceID);
    

      this.trfaContentMaster.ContentAddUpdate(this.tokenKey, formData).subscribe(
        result => {
          if (result['status'] == 'Success') {
            if (result['message'] != "") {
              this.showMessage(result['message'], 'Warning', 'warning')
            } else {
              if (item.contentID > 0) {
                this.showMessage('Content Update successfully', 'Success', 'success');
                //this.resetPageNoandSize();
                this.GetContent();
              }
              else
                this.showMessage('Content saved successfully', 'Success', 'success');
              this.router.navigate(['/admin/' + this.SelectedTool + '/content']);
            }
          }
          else {
            this.showMessage(result['status'], 'Error', 'error');
          }
        },
        error => {
          this.showMessage(error, 'Error', 'error');
        }
      );
      item.isEdit = false;
      this.isRowEdited = false;
  }

  onSelectCarrier($event) {
    this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
    this.listOfCarrierName= this.removeElemetninArray(this.listOfCarrierName, $event.itemName)
    this.listOfCarrierIDs.push($event.id);
    this.listOfCarrierName.push($event.itemName);
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIDs != null) {
      if (this.listOfCarrierIDs.length > 0) {
        this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
      }
    }

    if (this.listOfCarrierName != null) {
      if (this.listOfCarrierName.length > 0) {
        this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event.itemName)
      }
    }
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCarrierName != null) {
        if (this.listOfCarrierName.length > 0) {
          this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event[iCounter].itemName)
          this.listOfCarrierName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCarrierName.push($event[iCounter].itemName);
        }
      }    
    }
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIDs = [];
    this.listOfCarrierName=[];
  }

  onSelectCategory($event) {
    // this.listOfCategoryIDs=[]
    this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
    this.listOfCategoryIDs.push($event.id);
    // this.GetContent();
  }
  
  onDeSelectCategory($event) {
    if (this.listOfCategoryIDs != null) {
      if (this.listOfCategoryIDs.length > 0) {
        this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
      }
    }
    // this.GetContent();
  }
  
  onSelectAllCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    // this.GetContent();
  }
  
  onDeSelectAllCategory($event) {
    this.listOfCategoryIDs = [];
    // this.GetContent();
  }
}
