import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApplicationActivityModel } from 'src/Models/applicationActivityModel';
import { FilterModel } from 'src/Models/filterModel';
@Injectable({
  providedIn: 'root'
})
export class ApplicationActivityService {

  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  ApplicationActivityAdd(TokenKey: string, data: ApplicationActivityModel): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/ApplicationActivity/ApplicationActivityAdd';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ApplicationActivityGet(TokenKey: string, data: FilterModel): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/ApplicationActivity/ApplicationActivityGet';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  NoUsageDataGet(TokenKey: string, data: FilterModel): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/ApplicationActivity/NoUsageDataGet';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ApplicationActivityExports(TokenKey: string, data: FilterModel): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/ApplicationActivity/ApplicationActivityExports';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  NoUsageDataExports(TokenKey: string, data: FilterModel): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/ApplicationActivity/NoUsageDataExports';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, data, httpOptions)
      .pipe(catchError(this.handleError));
  }


  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
