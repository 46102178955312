import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserPermissionModal } from 'src/Models/userPermissionModel';

@Injectable({
  providedIn: 'root'
})
export class UserPemissionService {

  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }
  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  i360UserPermissionByUserId(TokenKey: any, userId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/UserPermission/i360UserPermissionByUserId/' + userId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360UserPermissionByUserIdProductId(TokenKey: any, userId: number, productId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/UserPermission/i360UserPermissionByUserIdProductId/' + userId + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360UserPermissionInsert(TokenKey: any, modal: UserPermissionModal[]): Observable<any> {
    this.setAPIUrl();
    let data = 'api/UserPermission/i360UserPermissionInsert';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, modal, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

}
