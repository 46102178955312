import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { ColumnMappedByCompanyListModel } from 'src/Models/GetColumnMappedByCompanyListPaged';
import { InrollProductsCompanyModel } from 'src/Models/InrollProductsCompanyModel';
import { NLADClaimService } from 'src/Services/nladClaim.service';

@Component({
  selector: 'app-mapped-column-lifeline-listing',
  templateUrl: './mapped-column-lifeline-listing.component.html',
  styleUrls: ['./mapped-column-lifeline-listing.component.css']
})
export class MappedColumnLifelineListingComponent implements OnInit {
  listOfData: ColumnMappedByCompanyListModel[]
  filterModel = new FilterModel();
  SelectedTool: string;
  productId: number = 1;
  providerId: number = 0;
  userId: number = 0;

  isAscending: boolean = false
  upDowFileNameCSS: string = '';
  upDowCompanyNameCSS: string = '';
  upDowCreatedDateCSS: string = '';


  totalRecordsCount: number = -1;
  isloading: boolean = false;
  currentPage: number = 1;

  listOfInrollProductCompany: InrollProductsCompanyModel[];
  dropdownCompanies = [];
  selectedItemsCompanies = [];
  dropdownSettingCompanies = {};
  listOfCompany = [];

  deleteProviderId = 0;
  openMenuIndex: number = -1;

  constructor(
    private router: Router,
    private titleService: Title,
    private nladService: NLADClaimService,
    private toastr: ToastrService,
  ) {
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Lifeline Claim Files Columns Mappings');
    this.productId = ToolEnum[this.SelectedTool];
    this.providerId = 0;
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.filterModel.providerId = this.providerId.toString();
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'CompanyName';
  }
  ngOnInit() {
    this.GetLifelineColumnMappedByCompanyListPaged();
    this.GetInrollClientCompany();
  }

  GetLifelineColumnMappedByCompanyListPaged() {
    this.isloading = true;
    this.listOfData = [];
    this.nladService.GetLifelineColumnMappedByCompanyListPaged(this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfData = data.listOfData;
          if (this.listOfData != null) {
            if (this.listOfData.length > 0) {
              this.totalRecordsCount = this.listOfData[0].totalRecordCount;
            }
            else {
              this.totalRecordsCount = 0;
            }
          }
          else {
            this.totalRecordsCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetLifelineColumnMappedByCompanyListPaged();
  }


  sort(sortBy: any) {
    this.upDowFileNameCSS = '';
    this.upDowCompanyNameCSS = '';
    this.upDowCreatedDateCSS = '';

    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'fileName': this.upDowFileNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'CompanyName': this.upDowCompanyNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'CreatedDate': this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetLifelineColumnMappedByCompanyListPaged();
  }

  GetInrollClientCompany() {
    this.nladService.GetLifelineInrollClientCompany().subscribe(
      data => {
        this.dropdownSettingCompanies = {
          singleSelection: true,
          text: 'Filter by Company',
          enableSearchFilter: true,
          classes: 'borderLessTextBoxGreen',
          badgeShowLimit: 1,
          noDataLabel: 'No company',
          showCheckbox: false,
          enableFilterSelectAll: false
        };

        if (data != null) {
          if (data.status == 'Success') {
            this.listOfInrollProductCompany = data.listofInrollCompany;
            if (this.listOfInrollProductCompany != null) {
              if (this.listOfInrollProductCompany.length > 0) {
                for (let iCounter = 0; iCounter < this.listOfInrollProductCompany.length; iCounter++) {
                  this.dropdownCompanies.push({ 'id': this.listOfInrollProductCompany[iCounter].companyId, 'itemName': this.listOfInrollProductCompany[iCounter].companyName });
                }
              }
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedCompanies($event) {
    
    this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event.id)
    this.listOfCompany.push($event.id);
    this.filterModel.pageNumber = '1';
    this.filterModel.providerId = $event.id;
    this.GetLifelineColumnMappedByCompanyListPaged();
  }

  onDeSelectedCompanies($event) {
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event.id)
      }
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.providerId = $event.id;
    this.GetLifelineColumnMappedByCompanyListPaged();
  }

  onSelectAllCompanies($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.providerId = $event[0].id;
    this.GetLifelineColumnMappedByCompanyListPaged();
  }

  onDeSelectAllCompanies($event) {
    this.listOfCompany = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.providerId = '0';
    this.GetLifelineColumnMappedByCompanyListPaged();
  }

  removeElemetninArrayById(array, element) {
    return array.filter(e => e.id !== element);
  }

  redirectToColumnMappingPage(providerId) {
    this.router.navigate(['/admin/inroll/column/lifeline-mapping/' + providerId]);
  }

  mappedLifelineColumnDelete(providerId) {
    this.nladService.MappedLifelineColumnDelete(providerId).subscribe(
      data => {
        if (data != null) {
          if (data.id > 0) {
            this.toastr.success('Record Delete Successfully', 'Success');
            this.GetLifelineColumnMappedByCompanyListPaged();
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  setDeleteId(providerId) {
    this.deleteProviderId = providerId
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
