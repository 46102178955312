export class Inteserrai360DashboardCountModel {
    id: number = 0;
    stateCommissionCount: number = 0;
    carrierCount: number = 0;
    briefingCount: number = 0;
    dataCount: number = 0;
    webinarCount = 0;
    whitePapersCount = 0;
    bannerCount = 0;
    seminarCount = 0;
    generalAlertsCount = 0;
    trainingCount = 0;
    specialReportsCount = 0;
    specialReportsSectionsCount = 0;
    taxExemptionFormCount=0;
    trsCount = 0;
    inteserraResourcesCount:number=0
}