import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { BriefingModel } from 'src/Models/TrfaAlertsModel';
import { TrfaAlertService } from 'src/Services/alert.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';


@Component({
  selector: 'app-manage-briefing-alerts',
  templateUrl: './manage-briefing-alerts.component.html',
  styleUrls: ['./manage-briefing-alerts.component.css']
})
export class ManageBriefingAlertsComponent implements OnInit {
  tokenKey: string = '';
  userId: number = 0;
  SelectedTool: string;
  productId: number = 1;
  multiSelectDropDownHeight: number = 200;


  BriefingModel = new BriefingModel()

  addUpdateBriefingAlert = new FormGroup({
    briefingId: new FormControl(''),
    updateDate: new FormControl(''),
    title: new FormControl(''),
    description: new FormControl(''),
    isSendMail: new FormControl(0),
  });

  isbriefingAlerts: boolean = true;
  briefingId: number = 0;
  isDataLoad: boolean = false;
  isViewMode: boolean = false;

  isSendMail: boolean = true;

  pageNumber:number;
  pageSize:number;
  Email:string = '';
  Name:string = '';
  orderBy:string;
  Title:string ='';

  constructor(private activedRoute: ActivatedRoute, private checkAuth: CheckAuth, public trfaAlertServices:TrfaAlertService,
    private userServices: UsersService, private toastr: ToastrService, private router: Router,private titleService: Title) {
      this.tokenKey = sessionStorage.getItem('tokenValue');
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
      this.SelectedTool = sessionStorage.getItem('UserLoginTool');
      this.productId = ToolEnum.briefingServices;
    }


  ngOnInit() {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.orderBy = 'briefingId ASC'
    this.activedRoute.params.subscribe((params: Params) => {

      if (params['id']) {
        if (params['id'] != null) {
          this.isbriefingAlerts = false;
          this.briefingId = params['id'];
          this.userId = params['userid'];
          this.GetBriefingAlertsByUsers();
        }
      }
      else {
        this.userId = 0;
      }
    });
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  
  GetBriefingAlertsByUsers(): void {
    this.isDataLoad = true;
    this.trfaAlertServices.GetBriefingAlertsByUsers(this.tokenKey, this.briefingId,this.pageNumber,this.pageSize,this.userId,this.orderBy).subscribe(
      data => {
        if (data["briefinguser"] != null && data["status"] != "Failure") {
          this.BriefingModel = data['briefinguser'][0];
          this.Email=this.BriefingModel.email;
          this.Name=this.BriefingModel.name;

          if (this.BriefingModel != null) { 
            let updateDate = new Date(this.BriefingModel.updateDate);

            let dd1 = updateDate.getDate().toString();
            if (parseInt(dd1.toString()) < 10)
              dd1 = '0' + dd1.toString();

             let mm1 = (updateDate.getMonth() + 1).toString();
            if (parseInt(mm1.toString()) < 10)
              mm1 = '0' + mm1.toString();

            this.BriefingModel.updateDate = mm1 + '/' + dd1 + '/' + updateDate.getFullYear();
          }

          this.addUpdateBriefingAlert.setValue({
            briefingId: this.BriefingModel.briefingId,
            updateDate: this.BriefingModel.updateDate,
            title: this.BriefingModel.title,
            description: this.BriefingModel.description,
            isSendMail: this.BriefingModel.isSendMail
          });

          this.isDataLoad = false;
        }
        else {
          this.isDataLoad = false;
          this.showMessage(data["message"], 'Error', 'error');
        }
      }, error => {
        this.isDataLoad = false;
        this.showMessage(error, 'Error', 'error');
      })

  }


  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  isAlertDate(dateArray: any) {
    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      this.BriefingModel.updateDate = mm1 + '/' + dd1 + '/' + dateArray.getFullYear();
    }
    else
      this.BriefingModel.updateDate = null;
  }

  saveAddUpdateBriefingAlert(addUpdateBriefingAlert: any) {
    if (addUpdateBriefingAlert.value['updateDate'] == '') {
      this.showMessage('Please select alert date', 'Error', 'error');
      return;
    }
    let currentDate = new Date();
    if (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) > addUpdateBriefingAlert.value["updateDate"]) {
      this.showMessage('Previous alert date not allow', 'Error', 'error');
      return;
    }

    if (addUpdateBriefingAlert.value['title'] == '' || typeof (addUpdateBriefingAlert.value['title']) == 'undefined') {
      this.showMessage('Please enter title', 'Error', 'error');
      return;
    }
    if (addUpdateBriefingAlert.value['title'] != '') {
      if (addUpdateBriefingAlert.value['title'].toString().trim() == '') {
        this.showMessage('Please enter title', 'Error', 'error');
        return;
      }
    }

    if (addUpdateBriefingAlert.value['description'] == '' || typeof (addUpdateBriefingAlert.value['description']) == 'undefined') {
      this.showMessage('Please enter description', 'Error', 'error');
      return;
    }
    if (addUpdateBriefingAlert.value['description'] != '') {
      if (addUpdateBriefingAlert.value['description'].toString().trim() == '') {
        this.showMessage('Please enter description', 'Error', 'error');
        return;
      }
    }

    if (typeof (addUpdateBriefingAlert.value['briefingId']) == 'undefined') {
      this.BriefingModel.briefingId = 0;
    }
    else {
      this.BriefingModel.briefingId = addUpdateBriefingAlert.value['briefingId'];
    }

    if (typeof (addUpdateBriefingAlert.value['title']) == 'undefined') {
      this.BriefingModel.title = '';
    }
    else {
      this.BriefingModel.title = addUpdateBriefingAlert.value['title'];
    }

    if (typeof (addUpdateBriefingAlert.value['description']) == 'undefined') {
      this.BriefingModel.description = '';
    }
    else {
      this.BriefingModel.description = addUpdateBriefingAlert.value['description'];
    }

    this.BriefingModel.isSendMail = this.isSendMail;
    this.BriefingModel.userId = this.userId;
    this.BriefingModel.productId=this.productId;
   
    this.trfaAlertServices.addUpdateBriefingAlerts(this.tokenKey, this.BriefingModel).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.showMessage('Briefing alerts Updated successfully', 'Success', 'success');
          this.router.navigate(['/admin/briefingServices/briefing-Alerts']);
        }
        else
          this.showMessage(data['status'], 'Error', 'error');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }
  
  removeHTMLTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  }

  setSendEmailNotificationChecked($event) {
    this.isSendMail = $event.target.checked;
  }
}
