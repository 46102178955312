import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActionStateModel } from 'src/Models/ActionStateModel';
import { FilterModel } from 'src/Models/filterModel';
import { TracAlertsModel } from 'src/Models/TracAlertsModel';
import { ActionItemService } from 'src/Services/actionitem.service';

@Component({
  selector: 'app-trac-it-alerts',
  templateUrl: './trac-it-alerts.component.html',
  styleUrls: ['./trac-it-alerts.component.css']
})
export class TracItAlertsComponent implements OnInit {

  tokenKey: string = '';
  isloading: boolean = false;
  filteModel = new FilterModel();
  TracAlertsModel: TracAlertsModel[];
  totalRecordCount: number = -1;
  isAscending: boolean;
  actionId:number=0;

  upDownAlertDateCSS: string = '';
  upDownStateNameCSS: string = '';
  upDownSubjectCSS: string = '';
  upDownStatusCSS: string = '';
  upDownCreatedDateCSS: string = '';
  upDownModifiedDateCSS: string = '';
  Id: number = 0;
  IsSendEmail:boolean
  actionStateList: ActionStateModel[];
  openMenuIndex: number = -1;

  constructor(private toastr: ToastrService, private router: Router, private actionItemServices: ActionItemService,private titleService: Title) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Trac-It Alerts');
  }

  ngOnInit() {
    this.filteModel.pageNumber = '1';
    this.filteModel.pageSize = '10';
    this.filteModel.keywordFilter = '';
    this.filteModel.orderBy = 'ActionId ASC';
    this.filteModel.actionId=0;
    this.filteModel.userID = '0';
    this.GetTracAlertsFindByPages();
  }

  GetTracAlertsFindByPages() {
    this.isloading = true;
    if (this.filteModel.keywordFilter == "null") this.filteModel.keywordFilter = " ";
    this.actionItemServices.GetTracAlertsFindByPages(this.tokenKey, this.filteModel).subscribe(data => {
      if (data['status'] == 'Success') {
        this.isloading = false;
        this.TracAlertsModel = data['tracItAlertList'];
        if (this.TracAlertsModel != null) {
          if (this.TracAlertsModel.length > 0) {
            this.totalRecordCount = +this.TracAlertsModel[0].totalRowCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      }
      else {
        this.showMessage(data['status'], 'Error', 'error');
      }

    },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  GetModelActionState(actionId: any) {
    this.actionStateList = [];
    this.actionItemServices.GetActionStateByActionId(this.tokenKey, actionId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.actionStateList = data['actionState'];
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  keywordFilters($event) {
    this.filteModel.keywordFilter = $event.target.value;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filteModel.keywordFilter = $event.target.value;
    }
    else { this.filteModel.keywordFilter = ''; }

    this.GetTracAlertsFindByPages();
  }

  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.filteModel.orderBy = sortBy + ' DESC';
      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.filteModel.orderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.GetTracAlertsFindByPages();
  }

  setDescCSS(sortBy) {
    if (sortBy === "deadlineDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-down';
      this.upDownSubjectCSS = '';
      this.upDownStateNameCSS='';
    }
    else if (sortBy === "subject") {
      this.upDownAlertDateCSS = '';
      this.upDownStateNameCSS='';
      this.upDownSubjectCSS = 'fa fa-arrow-down';
    }
    else if (sortBy === "StateName") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = '';
      this.upDownStateNameCSS = 'fa fa-arrow-down';
    }
  }

  setAscCSS(sortBy) {
    if (sortBy === "deadlineDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-up';
      this.upDownSubjectCSS = '';
      this.upDownStateNameCSS ='';
    }
    else if (sortBy === "subject") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = 'fa fa-arrow-up';
      this.upDownStateNameCSS ='';
    }
    else if (sortBy === "StateName") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = '';
      this.upDownStateNameCSS = 'fa fa-arrow-up';
    }
  }

  pageChanged($event) {
    this.filteModel.pageNumber = parseInt($event.page).toString();
    this.GetTracAlertsFindByPages();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  redirectToEditView(actionId:any) {
    this.router.navigate(['/admin/trac-it/Trac-itAlerts/user/' + actionId]);
  }

  SetCancelMailId(actionId: any) {
    this.Id = actionId;
  }

  CancelMail() {
    this.actionItemServices.notsentmail(this.tokenKey, this.Id).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.showMessage('', 'Success', 'success');
          this.GetTracAlertsFindByPages();
        }
        else
          this.showMessage(data['status'], 'Error', 'error');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
