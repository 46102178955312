import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CityMaster911Model } from 'src/Models/CityMaster911Model';
import { ContactsRate911Model } from 'src/Models/ContactsRate911Model';
import { CountyMaster911Model } from 'src/Models/CountyMaster911Model';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CityMasterService } from 'src/Services/city-master.service';
import { CountyMasterService } from 'src/Services/county-master.service';
import { Main911Service } from 'src/Services/main911.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-createoreditcontact',
  templateUrl: './createoreditcontact.component.html',
  styleUrls: ['./createoreditcontact.component.css']
})
export class CreateoreditcontactComponent implements OnInit {

  contactForm: FormGroup;
  userID: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  tokenKey: any;
  AppUrl: string = "";
  isSubmit: boolean = false;
  stateList: StateMasterModel[] = [];
  selectedState = [];
  selectedItemsCounty = [];
  countyList: CountyMaster911Model[] = [];
  isEdit: boolean = false;
  cityList: CityMaster911Model[] = [];
  selectedCity = [];
  scopeList = [];
  selectedScope = [];
  contactsRate911Model: ContactsRate911Model;
  isloading: boolean = false;
  isRecord: boolean = false;
  dropdownDateFilter = [];
  dropdownDateCountyFilter = [];
  dropdownDateCityFilter = [];
  countyId: number = 0;
  cityId: number = 0;
  listOfCityIds = [];
  citysId: string = ' ';
  isLocationStep: boolean = true;
  listOfCountyIds = [];
  countysId: string = ' ';
  cityListForRemove = [];
  cityListForRemoveNew = [];
  dropdownSettingsStateFilter = {};
  dropdownSettingsDateFilter = {};
  dropdownSettingsCountyFilter = {};
  dropdownSettingsCityFilter = {};
  rateC: any;
  IsStateSelect: boolean = true;
  IsCitySelect: boolean = true;
  IsCountySelect: boolean = true;

  pattern = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute,
    private router: Router, private countyService: CountyMasterService, private stateService: StateMasterService,
    private toastr: ToastrService, private cityService: CityMasterService, private main911Service: Main911Service,
    private datePipe: DatePipe, private readonly changeDetectorRef: ChangeDetectorRef) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.getState();
    this.GetAllStateScope();
    this.setAPIUrl();
  }

  ngOnInit() {
    this.dropdownSettingsStateFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      disabled: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsCountyFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No County(s) available',
      showCheckbox: false,
      disabled: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsCityFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No City(s) available',
      showCheckbox: false,
      disabled: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No FundJurisd level(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.contactForm = this._fb.group({
      ID: 0,
      stateId: ['', Validators.required],
      countyId: [''],
      cityId: [''],
      fundJurisdLevel: ['', Validators.required],
      authorityBoardName: [''],
      contactTitle: [''],
      contactFirstName: ['', Validators.required],
      contactLastName: ['', Validators.required],
      contactOrganizationName: [''],
      contactStreetAddress: ['',],
      contactCity: [''],
      contactStateName: [''],
      contactZipAddress: [''],
      contactPOBox: [''],
      contactPhone: ['', Validators.required],
      contactEmail: ['', Validators.compose([Validators.pattern(this.pattern)])],
      contactFax: [''],
      rateConfirmed: [''],
      lastContactDate: [''],
      confirmationDate: [''],
      remittanceInfo: [''],
      websiteAddress: [''],
      notes: [''],
      notSourceTypees: [''],
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetContactById(params['id']);
        }
      }
    });
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.SelectedTool).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownDateFilter = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownDateFilter.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }
  GetAllStateScope() {
    this.main911Service.GetAllStateScope(this.tokenKey).subscribe(
      data => {
        let scopeList = data['scope'];
        if (scopeList.length > 0) {
          scopeList.forEach(x => {
            this.scopeList.push({ "id": x.scopeID, "itemName": x.scopeName })
          });
        }
      },
      error => { }
    );
  }
  GetCountyByStateId(stateId, isNewCounty) {
    this.countyService.GetCountyByStateId(this.tokenKey, stateId, true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyList = result['county'];
          this.dropdownDateCountyFilter = [];
          this.selectedItemsCounty = [];
          if (!isNewCounty) {
            this.dropdownDateCityFilter = [];
            this.selectedCity = [];
          }
          this.countyList.forEach(x => {
            this.dropdownDateCountyFilter.push({ "id": x.countyId, "itemName": x.countyName })
          });
          if (!this.isEdit && isNewCounty && this.countyId != 0) {
            if (this.countyList.length > 0) {
              let data = this.countyList.filter(x => x.countyId == this.countyId)[0];
              if (data) {

                this.selectedItemsCounty.push({ "id": data.countyId, "itemName": data.countyName })
                this.contactForm.patchValue({
                  countyId: data.countyId
                });
              }
            }
          }
        }
      });
  }

  GetCityByCountyId(countyId, isNewCity) {
    setTimeout(() => {
    this.cityService.GetCityByCountyIdMultiple(this.tokenKey, countyId, true).subscribe(
        result => {
          if (result['status'] == 'Success') {
            this.cityList = result['city'];
            if (this.cityList.length > 0 && this.selectedCity.length > 0 && this.listOfCityIds.length > 0) {
              this.selectedCity = this.removeElemetninArray(this.selectedCity, this.selectedCity[0])
              result['city'].forEach(x => {
                this.dropdownDateCityFilter.push({ "id": x.id, "itemName": x.itemName })
              })
            }
            else {
              this.dropdownDateCityFilter = [];
              this.selectedCity = [];
              this.listOfCityIds = [];
              this.cityList = result['city'];
              result['city'].forEach(x => {
                this.dropdownDateCityFilter.push({ "id": x.id, "itemName": x.itemName })
              })
            }
          }
        });
      }, 500);
     }

  onChangeState(event) {
    this.contactForm.patchValue({
      stateId: event.id
    });
    if (event.id) this.GetCountyByStateId(event.id, false)
  }
  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }
  onDeSelectDateFilterAll($event) {
    this.selectedState = [];
    this.selectedItemsCounty = [];
    this.selectedCity = [];
    this.dropdownDateCountyFilter = [];
    this.dropdownDateCityFilter = [];
  }
  onDeSelectDateFilterCountyAll($event) {
    this.selectedItemsCounty = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.contactForm.value.countyID='';
  }
  onSelectCounty($event) {
    var countyID = $event.id.toString();
    this.contactForm.patchValue({
      countyId: $event.id
    });
    this.GetCityByCountyId(countyID, false);
  }
  onDeSelectCounty($event) {
    if (this.listOfCountyIds.length == 0) {
      this.listOfCityIds = [];
      this.selectedCity = [];
      this.dropdownDateCityFilter = [];
      this.cityList = [];
    }
    else
      var CountyID = $event.id.toString()
    this.GetCityByCountyIdforRemoveCity(CountyID, false);
    this.contactForm.value.countyId='';
    this.selectedItemsCounty = [];
    this.contactForm.value.cityId='';
    this.selectedCity = [];
    this.listOfCityIds=[];
    this.cityList =[];
    this.dropdownDateCityFilter = [];
  }

  onDeSelectCountyFilterAll($event) {
    this.selectedItemsCounty = [];
    this.contactForm.value.countyId='';
    this.contactForm.value.cityId='';
    this.selectedCity = [];
    this.listOfCityIds=[];
    this.cityList=[];
    this.dropdownDateCityFilter = [];
  }

  onDeSelectCityFilterAll($event) {
    this.selectedCity = [];
    this.listOfCityIds=[];
    this.contactForm.value.cityId='';
  }

  onSelectCity($event) {
    this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
    this.listOfCityIds.push($event.id);
    this.citysId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.citysId = this.listOfCityIds.join(',')
    }
    this.contactForm.patchValue({
      cityId: $event.id
    });
  }
  onDeSelectCity($event) {
    if (this.listOfCityIds != null) {
      if (this.listOfCityIds.length > 0) {
        this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
      }
    }
    if (this.listOfCityIds.length > 0) {
      this.citysId = this.listOfCityIds.join(',')
    }
    this.contactForm.patchValue({
      cityId: this.citysId
    });
    this.contactForm.value.cityId='';
  }
  onDeSelectDateFilterCityAll($event) {
    this.selectedCity = [];
    this.contactForm.value.cityId='';
  }
  onChangeScope(event) {
    if (event.itemName == 'State') {
      this.IsStateSelect = true;
      this.IsCountySelect = false;
      this.IsCitySelect = false;
    }
    else if (event.itemName == 'County') {
      this.IsStateSelect = false;
      this.IsCountySelect = true;
      this.IsCitySelect = false;
    }
    else {
      this.IsStateSelect = false;
      this.IsCountySelect = false;
      this.IsCitySelect = true;
    }

    this.contactForm.patchValue({
      fundJurisdLevel: event.itemName
    });
  }
  onDeSelectFilterScopeAll($event) {
    this.selectedScope = [];
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
  cancel() {
    if (this.isLocationStep) {
      let navigation = '/admin/' + this.SelectedTool + '/contacts';
      this.router.navigate([navigation]);
    }
    else {
      this.isLocationStep = true;
    }
  }

  GetCityByCountyIdforRemoveCity(countyId, isNewCity) {
    this.cityService.GetCityByCountyId(this.tokenKey, countyId).subscribe(
      result => {
        if (result['status'] == 'Success') {

          if (this.cityList.length > 0 && this.selectedCity.length > 0 && this.listOfCityIds.length > 0) {
            this.cityListForRemove = [];
            this.cityListForRemoveNew = [];
            this.cityListForRemove = result['city'];

            this.cityListForRemove.forEach(x => {
              this.cityListForRemoveNew.push({ "id": x.cityId, "itemName": x.cityName })
            });
            for (let iCounter = 0; iCounter < this.cityListForRemoveNew.length; iCounter++) {
              let index: number = -1;

              index = this.cityList.findIndex(y => y.cityId == this.cityListForRemoveNew[iCounter].id)
              if (index !== -1) {
                this.cityList.splice(index, 1);
              }

              index = this.selectedCity.findIndex(y => y.id == this.cityListForRemoveNew[iCounter].id);
              if (index !== -1) {
                this.selectedCity.splice(index, 1);
              }

              index = this.dropdownDateCityFilter.findIndex(y => y.id == this.cityListForRemoveNew[iCounter].id);
              if (index !== -1) {
                this.dropdownDateCityFilter.splice(index, 1);
              }
              this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, this.cityListForRemoveNew[iCounter].id)
            }
          }
          this.citysId = ' ';
          if (this.listOfCityIds.length > 0) {
            this.citysId = this.listOfCityIds.join(',')
          }
        }
      });
  }
  save() {
    this.isSubmit = true;
    let formData = new FormData();
    let data = this.contactForm.value;
    if (this.IsCitySelect) {
      if (!this.isEdit && (!this.contactForm.valid || this.selectedItemsCounty.length == 0 || this.selectedState.length == 0 || this.selectedScope.length == 0 || this.selectedCity.length == 0))
        return
      if (this.isEdit && (!this.contactForm.valid || this.selectedItemsCounty.length == 0 || this.selectedState.length == 0 || this.selectedScope.length == 0 || this.selectedCity.length == 0))
        return
    }
    else if (this.IsCountySelect) {
      if (!this.isEdit && (!this.contactForm.valid || this.selectedItemsCounty.length == 0 || this.selectedState.length == 0 || this.selectedScope.length == 0))
        return
      if (this.isEdit && (!this.contactForm.valid || this.selectedItemsCounty.length == 0 || this.selectedState.length == 0 || this.selectedScope.length == 0))
        return
    }
    else {
      if (!this.isEdit && (!this.contactForm.valid || this.selectedState.length == 0 || this.selectedScope.length == 0))
        return
      if (this.isEdit && (!this.contactForm.valid || this.selectedState.length == 0 || this.selectedScope.length == 0))
        return
    }

    formData.append('ID', data.ID);
    formData.append('state', data.stateId);
    formData.append('county', data.countyId);
    formData.append('city', data.cityId);
    formData.append('fundJurisdLevel', data.fundJurisdLevel);
    formData.append('authorityBoardName', data.authorityBoardName);
    formData.append('contactTitle', data.contactTitle);
    formData.append('contactFirstName', data.contactFirstName);
    formData.append('contactLastName', data.contactLastName);
    formData.append('contactOrganizationName', data.contactOrganizationName);
    formData.append('contactStreetAddress', data.contactStreetAddress);
    formData.append('contactCity', data.contactCity);
    formData.append('contactStateName', data.contactStateName);
    formData.append('contactZipAddress', data.contactZipAddress);
    formData.append('contactPOBox', data.contactPOBox);
    formData.append('contactPhone', data.contactPhone);
    formData.append('contactEmail', data.contactEmail);
    formData.append('contactFax', data.contactFax);
    formData.append('rateConfirmed', data.rateConfirmed);
    formData.append('lastContactDate', this.datePipe.transform(data.lastContactDate, 'MM-dd-yyyy'));
    formData.append('confirmationDate', this.datePipe.transform(data.confirmationDate, 'MM-dd-yyyy'));
    formData.append('remittanceInfo', data.remittanceInfo);
    formData.append('websiteAddress', data.websiteAddress);
    formData.append('notes', data.notes);
    formData.append('notSourceTypees', data.notSourceTypees);
    this.main911Service.CreateOrUpdateContact(this.tokenKey, formData).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            if (result['message'] == "Invalid") this.showMessage('Invalid Data', 'Error', 'error')
            else {
              if (data.ID > 0) {
                this.isloading = true;
                this.showMessage('Contact updated successfully', 'Success', 'success');
              }
              else {
                this.showMessage('Contact saved successfully', 'Success', 'success');
              }
              this.router.navigate(['/admin/' + this.SelectedTool + '/contacts']);
            }
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }
  GetContactById(ID) {
    this.main911Service.GetContactById(this.tokenKey, parseInt(ID)).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.GetCountyByStateIdEdit(result['main'].state,result['main'].county, false);
          this.GetCityByCountyIdEdit(result['main'].county,result['main'].city, false);

          if (result['main'].fundJurisdLevel == 'State') {
            this.IsStateSelect = true;
            this.IsCountySelect = false;
            this.IsCitySelect = false;
          }
          else if (result['main'].fundJurisdLevel == 'County') {
            this.IsStateSelect = false;
            this.IsCountySelect = true;
            this.IsCitySelect = false;
          }
          else {
            this.IsStateSelect = false;
            this.IsCountySelect = false;
            this.IsCitySelect = true;
          }

          this.contactForm.patchValue({
            ID: result['main'].id,
            stateId: result['main'].state,
            cityId: result['main'].city,
            countyId: result['main'].county,
            fundJurisdLevel: result['main'].fundJurisdLevel,
            authorityBoardName: result['main'].authorityBoardName != null ? result['main'].authorityBoardName : "",
            contactTitle: result['main'].contactTitle != null ? result['main'].contactTitle : "",
            contactFirstName: result['main'].contactFirstName != null ? result['main'].contactFirstName : "",
            contactLastName: result['main'].contactLastName != null ? result['main'].contactLastName : "",
            contactOrganizationName: result['main'].contactOrganizationName != null ? result['main'].contactOrganizationName : "",
            contactStreetAddress: result['main'].contactStreetAddress != null ? result['main'].contactStreetAddress : "",
            contactCity: result['main'].contactCity != null ? result['main'].contactCity : "",
            contactStateName: result['main'].contactStateName != null ? result['main'].contactStateName : "",
            contactZipAddress: result['main'].contactZipAddress != null ? result['main'].contactZipAddress : "",
            contactPOBox: result['main'].contactPOBox != null ? result['main'].contactPOBox : "",
            contactPhone: result['main'].contactPhone,
            contactEmail: result['main'].contactEmail != null ? result['main'].contactEmail : "",
            contactFax: result['main'].contactFax != null ? result['main'].contactFax : "",
            rateConfirmed: result['main'].rateConfirmed != null ? result['main'].rateConfirmed : "",
            lastContactDate: result['main'].lastContactDate,
            confirmationDate: result['main'].confirmationDate,
            remittanceInfo: result['main'].remittanceInfo != null ? result['main'].remittanceInfo : "",
            websiteAddress: result['main'].websiteAddress != null ? result['main'].websiteAddress : "",
            notes: result['main'].notes != null ? result['main'].notes : "",
            notSourceTypees: result['main'].notSourceTypees != null ? result['main'].notSourceTypees : "",
          });
          if (this.dropdownDateFilter.length > 0) {
            let data = this.dropdownDateFilter.filter(x => x.id == result['main'].state);
            if (data.length > 0)
              this.selectedState.push({ "id": data[0].id, "itemName": data[0].itemName });
          }
          else {
            setTimeout(() => {
              let data = this.dropdownDateFilter.filter(x => x.id == result['main'].state);
              this.selectedState.push({ "id": data[0].id, "itemName": data[0].itemName });
            }, 500);
          }
          if (this.scopeList.length > 0) {
            let scope = this.scopeList.filter(x => x.itemName == result['main'].fundJurisdLevel);
            if (scope.length > 0) {
              this.selectedScope.push({ "id": scope[0].id, "itemName": scope[0].itemName });
            }
          }
          else {
            setTimeout(() => {
              let scope = this.scopeList.filter(x => x.itemName == result['main'].fundJurisdLevel);
              this.selectedScope.push({ "id": scope[0].id, "itemName": scope[0].itemName });
            }, 500);
          }
        }
      });
  }

  GetCountyByStateIdEdit(stateId,countyId, isNewCounty) {
    this.countyService.GetCountyByStateId(this.tokenKey, stateId, true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyList = result['county'];
          this.dropdownDateCountyFilter = [];
          this.selectedItemsCounty = [];
          if (!isNewCounty) {
            this.dropdownDateCityFilter = [];
            this.selectedCity = [];
          }
          this.countyList.forEach(x => {
            this.dropdownDateCountyFilter.push({ "id": x.countyId, "itemName": x.countyName })
          });


          let county = this.dropdownDateCountyFilter.filter(x => x.id == countyId);
            if (county.length > 0) {
              this.selectedItemsCounty.push({ "id": county[0].id, "itemName": county[0].itemName });
            }
        }
      });
  }

  GetCityByCountyIdEdit(countyId,cityId, isNewCity) {
    setTimeout(() => {
          this.cityService.GetCityByCountyIdMultiple(this.tokenKey, countyId, true).subscribe(
        result => {
          if (result['status'] == 'Success') {
            this.cityList = result['city'];
            if (this.cityList.length > 0 && this.selectedCity.length > 0 && this.listOfCityIds.length > 0) {
              this.selectedCity = this.removeElemetninArray(this.selectedCity, this.selectedCity[0])
              result['city'].forEach(x => {
                this.dropdownDateCityFilter.push({ "id": x.id, "itemName": x.itemName })
              })
            }
            else {
              this.dropdownDateCityFilter = [];
              this.selectedCity = [];
              this.listOfCityIds = [];
              this.cityList = result['city'];
              result['city'].forEach(x => {
                this.dropdownDateCityFilter.push({ "id": x.id, "itemName": x.itemName })
              })
            }
            let city = this.dropdownDateCityFilter.filter(x => x.id == cityId);
              if (city.length > 0) {
                this.selectedCity.push({ "id": city[0].id, "itemName": city[0].itemName });
              }
          }
        });
      }, 500);
     }
    
}
