import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterUpdateModel } from 'src/Models/TRFAUpdateModel';

@Injectable({
  providedIn: 'root'
})
export class TrfaUpdateMasterService {
  AppUrl: string = "";
  filterUpdateModel = new FilterUpdateModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }


  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllUpdate(TokenKey: string, PageNumber: number, PageSize: number, fundID: string, stateID: string, orderBy: string): Observable<any> {
    this.filterUpdateModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      fundID: fundID,
      stateID: stateID,
      orderBy: orderBy,
      fromDate: '',
      toDate: ''
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRFAUpdateMaster/GetAllUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterUpdateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  UpdateAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRFAUpdateMaster/UpdateAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetUpdateById(TokenKey: string, updateId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFAUpdateMaster/GetUpdateById/' + updateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteUpdate(TokenKey: string, updateId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFAUpdateMaster/DeleteUpdate/' + updateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetAllUpdateByUser(TokenKey: string, PageNumber: number, PageSize: number, fundID: string, stateID: string, fromDate: string, toDate: string, orderBy: string, isAdmin: boolean,userId:number,isFavorite:number): Observable<any> {
    this.filterUpdateModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      fundID: fundID,
      stateID: stateID,
      orderBy: orderBy,
      fromDate: fromDate,
      toDate: toDate,
      isAdmin: isAdmin,
      userId:userId,
      isFavorite:isFavorite,
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRFAUpdateMaster/GetAllUpdateByUser';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterUpdateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  ExportUpdates(TokenKey: string, PageNumber: number, PageSize: number, fundID: string, stateID: string, fromDate: string, toDate: string, orderBy: string): Observable<any> {
    this.filterUpdateModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      fundID: fundID,
      stateID: stateID,
      orderBy: orderBy,
      fromDate: fromDate,
      toDate: toDate
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRFAUpdateMaster/ExportUpdates';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterUpdateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetTRFANotificationHistory(TokenKey: string, paramvalue:string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFAUpdateMaster/GetTRFANotificationHistory/' + paramvalue;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}