export class ApplicationActivityModel {
  applicationActivityId?: number;
  pageName: string;
  pageUrl: string;
  actionName: string;
  ipAddress?: string;
  userId?: number;
  dataId?: number;
  dataType: number;
  actionUrl: string;
  fullName: string;
  totalRecordCount: number;
  companyName: string;
  stateName: string;
  productName:string;
}