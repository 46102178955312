
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AdminByPassClientLoginService {
    AppUrl: string = "";
    constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.setAPIUrl();
    }

    setAPIUrl() {
        if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
            this.AppUrl = sessionStorage.getItem("BaseURL") || '';
        }
    }

    AddNewUserForAdmin(adminUserId: any, userId: any): Observable<any> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/AdminByPassClientLogin/AddNewUserForAdmin/' + adminUserId + '/' + userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    AddNewUserFromFront(adminUserId: any, userId: any): Observable<any> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/AdminByPassClientLogin/AddNewUserFromFront/' + adminUserId + '/' + userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    GetAdminByPassUserLoginData(TokenKey: string, userId: string): Observable<any> {
        this.setAPIUrl();
        let data = 'api/AdminByPassClientLogin/GetAdminByPassUserLoginData/' + userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(this.AppUrl + data, data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    UserAccessProvidetoNewUser(TokenKey: string, OldUserId: string,NewUserId:number): Observable<any> {
        this.setAPIUrl();
        let data = 'api/AdminByPassClientLogin/UserAccessProvidetoNewUser/' + OldUserId+'/'+NewUserId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(this.AppUrl + data, data, httpOptions)
            .pipe(catchError(this.handleError));
    }
    
    ForgotPasswordDecryptEmailKey(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        
        let data = 'api/Menu/ForgotPasswordDecryptEmailKey/';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    private handleError(error: Response | any) {
        console.error(error.message || error);
        return observableThrowError(error.status)
    }
}
