import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MenuGroupModel } from 'src/Models/MenuGroupModel';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    menuModel: MenuGroupModel[];
    constructor(private router: Router) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        
        let routerURL = window.location.hash.replace('#', ''); //this.router.url; //window.location.hash.replace('#', '');
        if (routerURL.indexOf('Denied') == -1) {
            let isRedirect = true;
            let isLock = false;
            let url = '';

            this.menuModel = JSON.parse(sessionStorage.getItem('UserManu'));

            
            if (routerURL.indexOf('admin') == -1 && routerURL.indexOf('Denied') == -1) {
                if (this.menuModel != null) {
                    if (this.menuModel.length > 0) {
                        for (let i = 0; i < this.menuModel.length; i++) {
                            if (this.menuModel[i].childMenu != null) {
                                if (this.menuModel[i].childMenu.length > 0) {
                                    for (let j = 0; j < this.menuModel[i].childMenu.length; j++) {
                                        if (routerURL == this.menuModel[i].childMenu[j].externalURL) {
                                            
                                            let obj = this.menuModel[i].childMenu[j];
                                            if (obj != null) {
                                                url = obj.externalURL;
                                                //sessionStorage.setItem('productId', obj.productId.toString());
                                                if (obj.isLocked || url == null) {
                                                    isLock = true;
                                                    if (obj.displayName.indexOf('Tarrifs.') > -1) {
                                                        obj.displayName = 'TarrifsNet';
                                                    }
                                                    let para = encodeURI(obj.displayName);
                                                    url = '/Access/Denied/' + para;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else if (routerURL.indexOf('Denied') > -1) {
                isLock = true;
            }
            
            if ((sessionStorage.getItem('isLocked') == 'true' || sessionStorage.getItem('isLocked') == null) && (sessionStorage.getItem('UserId') == '' ||
                sessionStorage.getItem('UserId') == undefined ||
                sessionStorage.getItem('UserId') == null || sessionStorage.getItem('UserId') == '0')) {
                    if (localStorage.getItem('UserId') != null) {
                                            // logged in so return true
                                            return true;
                                       }
                sessionStorage.removeItem('isLocked');
                this.router.navigate(['/login']);
            }
            else{
                                return true;
                           }
            sessionStorage.removeItem('isLocked');
            if (isLock)
            {
                return false;
            }                
            else
                return true;
        }
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}
