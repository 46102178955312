import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { CheckAuth } from 'src/Services/utility.service';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';
import { CarrierObligationFieldService } from 'src/Services/carrier-obligation-field.service';
import { ObligationHistoryGetModel } from 'src/Models/ObligationGetLinkModel';

@Component({
  selector: 'app-carrier-obligation-history',
  templateUrl: './carrier-obligation-history.component.html',
  styleUrls: ['./carrier-obligation-history.component.css']
})
export class CarrierObligationHistoryComponent implements OnInit {

  tokenKey: string = '';
  stateCode: any;
  isloading: boolean = false;
  totalRecordCount: number = -1;
  isAscending: boolean;

  upDownCreatedByCSS: string = '';
  upDownDateCSS: string = '';
  upDownFieldCSS: string = '';
  upDownOldCSS: string = '';
  upDownNewCSS: string = '';

  productId: number;
  OrderBy: string;
  PageNumber: number;
  PageSize: number;
  listofObligation: ObligationHistoryGetModel[]
  isRecord: boolean = false;
  obligationId: number;

  FieldList: any[] = [];
  selectedField = [];
  dropdownSettingsField = {};
  dropdownFieldData = [];
  listOfField = [];
  FieldName: any;
  SelectedTool: string;
  FieldId: any;

  constructor(private toastr: ToastrService, private checkAuth: CheckAuth, private router: Router, private activedRoute: ActivatedRoute,
    private carrierObligationService: CarrierObligationService, private CarrierObligationFieldService: CarrierObligationFieldService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.productId = ToolEnum.crra;
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.PageNumber = 1;
    this.PageSize = 10;
    this.OrderBy = 'createdDate desc';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    if(this.SelectedTool == 'view 47'){
      this.SelectedTool = 'view47';
    }
    
    this.dropdownSettingsField = {
      singleSelection: false,
      text: "Filter by Field",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Filed(s) available',
      showCheckbox: true
    };

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.obligationId = params['id'];
          this.getField();
          this.GetObligationhistoryById();
        }
      }
    });
  }

  getField() {
    this.FieldList = this.CarrierObligationFieldService.getFields();
    this.dropdownFieldData = [];
    this.selectedField = [];
    this.FieldList.forEach(x => {
      this.dropdownFieldData.push({ "id": x.id, "itemName": x.name })
    });
  }

  onSelectField($event) {
    this.listOfField = this.removeElemetninArray(this.listOfField, $event.id)
    this.listOfField.push($event.id);
    this.GetObligationhistoryById();
  }

  onDeSelectField($event) {
    if (this.listOfField != null) {
      if (this.listOfField.length > 0) {
        this.listOfField = this.removeElemetninArray(this.listOfField, $event.id)
      }
    }
    this.GetObligationhistoryById();
  }

  onSelectAllField($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfField != null) {
        if (this.listOfField.length > 0) {
          this.listOfField = this.removeElemetninArray(this.listOfField, $event[iCounter].id)
          this.listOfField.push($event[iCounter].id);
        }
        else {
          this.listOfField.push($event[iCounter].id);
        }
      }
    }
    this.GetObligationhistoryById();

  }

  onDeSelectAllField($event) {
    this.listOfField = [];
    this.GetObligationhistoryById();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  GetObligationhistoryById() {
    this.isloading = true;

    this.FieldId = '';
    if (this.listOfField.length > 0) {
      this.FieldId = this.listOfField.join(',');
    }
    else {
      this.FieldId = null;
    }
    
    this.carrierObligationService.GetObligationhistoryById(this.tokenKey, this.PageNumber, this.PageSize, this.obligationId, this.OrderBy, this.FieldId).subscribe(
      data => {
        this.isloading = false;
        this.listofObligation = data['obligationhistory'];
        if (this.listofObligation != null) {
          if (this.listofObligation.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofObligation[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }


  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "createdBy") {
        this.upDownCreatedByCSS = 'fa fa-arrow-down';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "createdDate") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = 'fa fa-arrow-down';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "fieldName") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = 'fa fa-arrow-down';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "oldValue") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = 'fa fa-arrow-down';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "newValue") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = 'fa fa-arrow-down';
      }

    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy + ' ASC';

      if (sortBy === "createdBy") {
        this.upDownCreatedByCSS = 'fa fa-arrow-up';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "createdDate") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = 'fa fa-arrow-up';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "fieldName") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = 'fa fa-arrow-up';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "oldValue") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = 'fa fa-arrow-up';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "newValue") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = 'fa fa-arrow-up';
      }
    }
    this.GetObligationhistoryById();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetObligationhistoryById();
  }

  BackToObligation() {
    let rurl=sessionStorage.getItem("callbackurl");
    let navigation = '/admin/' + this.SelectedTool + '/'+rurl;

    this.router.navigate([navigation]);
  }
}

