import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { FilterModel } from 'src/Models/filterModel';
import { SeminarsModel, SeminarsSectionsModel } from 'src/Models/SeminarsModel';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { SeminarService } from 'src/Services/seminar.service';
import { CheckAuth } from 'src/Services/utility.service';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityStore } from 'src/common/commonFunction';

@Component({
  selector: 'app-front-seminar',
  templateUrl: './front-seminar.component.html',
  styleUrls: ['./front-seminar.component.css']
})
export class FrontSeminarComponent implements OnInit {

  listOfSeminars: SeminarsModel[];
  filterModel = new FilterModel();
  tokenKey: string = '';
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  listOfSpecialReportSection: SeminarsSectionsModel[];
  groupArr: any[];
  seminarSectionDetails: any = [];



  constructor(private seminarService: SeminarService, private checkAuth: CheckAuth, private titleService: Title, private toastr: ToastrService, private appActivity: ApplicationActivityService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'SortOrder';
    this.filterModel.userID = this._userID;
    this.titleService.setTitle('Seminars | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.i360SeminarsGet();    
  }

  i360SeminarsGet() {
    this.seminarService.i360SeminarsGetFront(this.tokenKey).subscribe(
      data => {
        this.listOfSeminars = data.listOfData;

        this.groupArr = this.listOfSeminars.reduce((r, { sectionName }) => {
          if (!r.some(o => o.sectionName == sectionName)) {
            r.push({ sectionName, groupItem: this.listOfSeminars.filter(v => v.sectionName == sectionName) });
          }
          return r;
        }, []);


        if (this.listOfSeminars != null) {
          if (this.listOfSeminars.length > 0) {
            this.totalRecordCount = this.listOfSeminars[0].totalRecordCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
        else {
          this.totalRecordCount = 0;
        }
      },
      error => {
        console.log(error);
      }
    );
  }
 

}
