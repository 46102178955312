import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterSubCategotyModel } from 'src/Models/CRRASubCategoryModel';

@Injectable({
  providedIn: 'root'
})
export class CrraSubCategoryMasterService {

  AppUrl: string = "";
  filterSubCategoryModel=new FilterSubCategotyModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }


  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllSubCategory(TokenKey: string, PageNumber: number, PageSize: number, filterText: string,categoryId:string,orderBy:string): Observable<any> {
    this.filterSubCategoryModel={
      pageNumber:PageNumber,
      pageSize:PageSize,   
      filterText:filterText,   
      categoryId:categoryId,
      orderBy:orderBy
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRASubCategoryMaster/GetAllSubCategory';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL,this.filterSubCategoryModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  SubCategoryAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRASubCategoryMaster/SubCategoryAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetSubCategoryById(TokenKey: string,subcategoryId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRASubCategoryMaster/GetSubCategoryById/' + subcategoryId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteSubCategory(TokenKey: string,subcategoryId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRASubCategoryMaster/DeleteSubCategory/' + subcategoryId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetSubCategory(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRASubCategoryMaster/GetSubCategory';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetSubCategoryByCategoryId(TokenKey: string, categoryId: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRASubCategoryMaster/GetSubCategoryByCategoryId/' + categoryId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

}
