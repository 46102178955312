import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { HomePageBannerModel } from 'src/Models/HomePageBannerModel';
import { HomepagebannerService } from 'src/Services/homepagebanner.service';
import { CheckAuth } from 'src/Services/utility.service';
@Component({
  selector: 'app-manage-home-page-banner',
  templateUrl: './manage-home-page-banner.component.html',
  styleUrls: ['./manage-home-page-banner.component.css']
})
export class ManageHomePageBannerComponent implements OnInit {
  ckeConfig:any;
  homePageBannerModel = new HomePageBannerModel();
  loginuserID: number = 0;
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;

  bannerId: number = 0;
  isMultipleClick: boolean = false;
  file: any;
  isShowprogressSpinner = false;
  blockedDocument = false;
  constructor(private activedRoute: ActivatedRoute, private _fb: FormBuilder, private router: Router, private checkAuth: CheckAuth, private titleService: Title,
    private bannerService: HomepagebannerService,
    private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Banner');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.ckeConfig = {
      versionCheck:false,
      toolbar: [
        { items:
          [
          'Undo','Redo','Link','Unlink','Anchor',
          'Image','Table','SpecialChar','Maximize','Source','Bold','Italic','Underline','Strike','RemoveFormat',
          'NumberedList','BulletedList','Outdent','Indent','Styles','Format','Font'
          ]
        }],
   
      
    }
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {

          this.bannerId = params['id'];
          this.GetHomePageBannerData();
        }
      }
      else {
        this.homePageBannerModel.isActive = true;
        this.homePageBannerModel.leftDescription='';
        this.homePageBannerModel.rightDescription='';
      }
    });

  }

  GetHomePageBannerData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.bannerService.i360HomePageBannerFindbyBannerId(this.tokenKey, this.bannerId).subscribe(
      data => {
        if (data != null) {
          this.homePageBannerModel = data["banner"];
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  saveHomePageBanner(HomePageBannerForm) {

    event.preventDefault();

    if (this.homePageBannerModel.title == '' || typeof (this.homePageBannerModel.title) == 'undefined' || this.homePageBannerModel.title == null) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }
    if (!this.homePageBannerModel.leftDescription) {
      this.showMessage('Please enter left description', 'Left description Required', 'error');
      return;
    }
    if (!this.homePageBannerModel.rightDescription) {
      this.showMessage('Please enter right description', 'Right description Required', 'error');
      return;
    }

    if (this.isMultipleClick) {      
      return;
    }
    this.isMultipleClick = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.homePageBannerModel.createdBy = this.loginuserID;
    this.bannerService.i360HomePageBannerAddUpdate(this.tokenKey, this.homePageBannerModel).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        if (data['homePageBannerId'] != 0) {
          if (data['homePageBannerId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.bannerId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.bannerId = 0;
            this.cancelHomePageBanner()
          }
        }
      },
      error => {
        this.isMultipleClick = false;
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelHomePageBanner() {
    this.router.navigate(['admin/inteserra360/Banner']);
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
}
