import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientInformationModel } from 'src/Models/ClientInformationModel';
import { OngoingComplianceModel } from 'src/Models/OngoingComplianceModel';
import { AppConfig } from 'src/Services/app.config';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { Globle } from 'src/app/Shared/global';
import * as FileSaver from 'file-saver';
import { MarketEntryDataModel } from 'src/Models/PriceCalculationModel';
import { EmailLoginService } from 'src/Services/emaillogin.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { HtmlToPdf } from 'src/Services/htmlToPdf';
import { FilterModel } from 'src/Models/filterModel';

declare const PDFObject: any;
@Component({
  selector: 'app-download-quotes-and-proposal',
  templateUrl: './download-quotes-and-proposal.component.html',
  styleUrls: ['./download-quotes-and-proposal.component.css']
})
export class DownloadQuotesAndProposalComponent implements OnInit {
  isSubmit: boolean = false;
  userID: any;
  ClientInformationId: number;
  tokenKey: any;

  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  stateCode: any;
  isRecord1: boolean = false;
  quoteName: string;
  listofCompliance: OngoingComplianceModel[];
  listofMiscellaneous: OngoingComplianceModel[];
  complianceTotal: number = 0;
  baseUrl: string;
  appModel: any;
  SelectedTool: string;

  isQuoteApproved: number = 0;
  dropdownListQuoteStatus = [];
  selectedItemsQuoteStatus = [];
  dropdownSettingsQuoteStatus = {};
  listOfQuoteStatusIds = [];
  quoteStatusId: number = 0;
  isQuoteSeen:boolean = false;


  listofPricingQuotes: ClientInformationModel[];

  listofMarketEntryData: MarketEntryDataModel[];

  sosServiceFees?: number = 0.00;
  sosCorpFeelingFees?: number = 0.00;
  sosLLCFeelingFees?: number = 0.00;
  registeredAgentFees?: number = 0.00;
  pucServiceFees?: number = 0.00;
  pscFeelingFees?: number = 0.00;
  localCounselFees?: number = 0.00;
  inteserraTotal?: number = 0.00;
  companyTotal?: number = 0.00;
  dbaFillinfFees?: number = 0.00;
  dbaComSpecFillingFees?: number = 0.00;
  Total?: number = 0.00;
  priceQuoteId: number = 0;
  dbaServiceFees?: number = 0.00;
  dbaComSpecServiceFees?: number = 0.00;
  dbA_Inteserra_Fee?: number = 0.00;
  dbAFillingsFees?: number = 0.00;
  dbA_RA_Fees?: number = 0.00;
  selectdUserDocument: string = '';
  pdfURl: string = '';
  pdfURlAdvisory:string='';
  pdfURlMarketentry:string='';
  pdfURlSolution:string='';
  pdfURlFinal:string='';
  pdfOrgionalName: string = '';
  pdfOrgionalNameAdvisory:string='';
  pdfOrgionalNameSolution:string='';
  pdfOrgionalNameMarketentry:string='';
  pdfOrgionalNameFinal:string='';

  isMarketEntry: boolean = false;
  advisoryQuotebtndisplay: boolean = false;
  solutionQuotebtndisplay: boolean = false;

  btnClickName: number = 1;
  FinalQuoteLink: any = '';
  FinalMarketEntryQuoteLink: any = '';
  FinalAdvisoryServicesQuoteLink: any = '';
  FinalMarketEntryReport:any='';
  FinalSolutionsQuoteLink: any = '';
  isCompliance: boolean = false;

  quaryValues: string = '';
  encyKeyfilterModel = new FilterModel();
  emailUserId: any = 0;
  loginUser: any = 0;
  emailPriceQuoteId: number = 0;
  quoteStatus : number = 0;
  rejectReason: string;
  TitleMsg: string;
  quoteName1: string;


  constructor(private router: Router, private PricingQuotesMaster: PricingQuotesService, private checkAuth: CheckAuth
    , private titleService: Title, private emailLoginService: EmailLoginService, private activedRoute: ActivatedRoute, private toastr: ToastrService, private htmlToPdf: HtmlToPdf,
    @Inject('BASE_URL') baseUrl: string, private globle: Globle,private appActivity: ApplicationActivityService) {
    this.userID = sessionStorage.getItem('UserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.baseUrl = baseUrl;
    this.titleService.setTitle('Price Quote');

    this.dropdownSettingsQuoteStatus = {
      singleSelection: true,
      text: "Please Select Quote Status",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Quote Status available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
  }

  ngOnInit() {
    this.isSubmit = false;
    this.activedRoute.params.subscribe((params: Params) => {

      if (params['id']) {
        if (params['id'] != null) {
          debugger;
          const value = params['id'];
          if (this.isNumber(value)) {
            this.ClientInformationId = params['id'];
            this.priceQuoteId = params['id'];
            this.GetAdvisorysolutionbuttonDataById(this.priceQuoteId);
            this.GetMarketEntryDataById(this.ClientInformationId);
            this.GetComplianceByClientInformationId(this.ClientInformationId);
          } else {
            this.quaryValues = params['id'];
            this.encyKeyfilterModel.emailKey = params['id'];
            this.IsValidPriceQuoteEmailKey();
          }

          this.emailPriceQuoteId = +sessionStorage.getItem('emailPriceQuoteId');
          
        }
        this.GetQuoteStatusData(this.priceQuoteId);
        if(!this.isQuoteSeen || this.isQuoteSeen==null)
        this.priceQuoteSeen();
      }
    });

    // this.GetClientInformationIdByUserId(this.userID);
    ApplicationActivityStore('View', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], '/View/download-quotes-and-proposal/'+this.priceQuoteId);
  }

  //   GetClientInformationIdByUserId(UserId: number) {
  //   this.blockDocument()
  //   this.PricingQuotesMaster.GetClientInformationIdByUserId(this.tokenKey, parseInt(this.userID.toString())).subscribe(
  //     result => {
  //       if (result['status'] == 'Success') {
  //         this.ClientInformationId = result['clientInformationId'];
  //         this.unblockDocument();
  //         if (this.ClientInformationId != 0) {
  //           this.GetComplianceByClientInformationId(this.ClientInformationId);
  //         }
  //       }
  //     });
  // }

  IsValidPriceQuoteEmailKey() {
    this.emailLoginService.isValidPriceQuoteEmailKey(this.encyKeyfilterModel).subscribe(
      data => {
        this.priceQuoteId = data.priceQuoteId;
        this.emailUserId = data.userId;
        this.ClientInformationId = data.priceQuoteId;
        let IsAdmin = false;
        if (localStorage.getItem('clientTypeId') != null) {
          IsAdmin = localStorage.getItem('clientTypeId') == '2' ? true : false;
        }
        if (this.emailUserId.toString() !== this.userID.toString() && !IsAdmin)
          this.router.navigate(['/Access/Denied/PriceQuote']);
        else {
        this.GetQuoteStatusData(this.priceQuoteId);
          this.GetAdvisorysolutionbuttonDataById(this.priceQuoteId);
          this.GetMarketEntryDataById(this.ClientInformationId);
          this.GetComplianceByClientInformationId(this.ClientInformationId);
        }

      },
      error => {
        console.log(error);
      }
    );
  }

  GetQuoteStatusData(priceQuoteId: number) {
    this.PricingQuotesMaster.GetQuoteStatus(this.tokenKey, priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.isQuoteApproved = result['quoteStatusData'].isQuoteApproved,
            this.isQuoteApproved = result['quoteStatusData'].isQuoteApproved;
            this.isQuoteSeen = result['quoteStatusData'].isQuoteSeen;
            this.quoteName1 = result['quoteStatusData'].quoteName;
        }
      });
  }

  GetComplianceByClientInformationId(ClientInformationId) {
    this.PricingQuotesMaster.GetProposelByClientInformationId(this.tokenKey, ClientInformationId).subscribe(
      data => {
        if (data['status'] = 'Success') {
          // this.globle.cApp.statusModel.isProposalDetailsCompleted = true;
          this.unblockDocument();
          if (data['compliance'] != null && data['compliance'].length > 0) {
            this.listofCompliance = data['compliance'].filter(x => x.totalMonthlyCost != 0);
            if (this.listofCompliance.length > 0)
              this.isCompliance = true;
          }

          if (data['marketEntryData'] != null && data['marketEntryData'].length > 0) {
            this.isMarketEntry = true;
          }
          else {
            this.isMarketEntry = false;
          }

          if (data['miscelleneous'] != null && data['miscelleneous'].length > 0) {
            this.listofMiscellaneous = data['miscelleneous'].filter(x => x.totalMonthlyCost != 0);
          }


          if (this.listofCompliance != null) {
            if (this.listofCompliance.length == 0) {
              this.isRecord1 = true;
            }
            else {

              if (this.listofCompliance[0].quoteName != null && this.listofCompliance[0].quoteName != "") {
                this.quoteName = " - " + this.listofCompliance[0].quoteName;
              }
              else {
                this.quoteName = "";
              }
              for (let iCounter = 0; iCounter < this.listofCompliance.length; iCounter++) {
                this.complianceTotal = this.complianceTotal + this.listofCompliance[iCounter].totalMonthlyCost;
              }
            }
          }
          else {
            this.isRecord1 = false;
          }

          if (this.listofMiscellaneous != null) {
            if (this.listofMiscellaneous.length == 0) {
              this.isRecord1 = true;
            }
            else {
              this.isRecord1 = false;
            }
          }
        }
        else {
          this.unblockDocument();
          this.isRecord1 = false;
        }

        if (this.isCompliance == true) {
          this.createMyPdf1();
          this.isRecord1 = false;
          return;
        }
        else if (this.isMarketEntry == true) {
          this.createMatrketEntryPdf();
          this.isRecord1 = false;
          return;
        }
        else if (this.advisoryQuotebtndisplay == true) {
          this.createAdvisoryPdf();
          this.isRecord1 = false;
          return;
        }
        else if (this.solutionQuotebtndisplay == true) {
          this.createSolutionPdf();
          this.isRecord1 = false;
          return;
        }
        else {
          return;
        }

      }, error => { this.stateCode = error }
    );
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  createMyPdf() {
    this.blockDocument();

    let mainURL = this.baseUrl;
    let url = '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> '
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';

    var html = '';
    if (this.listofMarketEntryData.length > 0) {
      html = url + '</br><b>EXHIBIT A' + this.quoteName + '</b></br></br>' +
        document.getElementById('divExibitA').innerHTML
        + '</br><b>MARKET ENTRY</b></br>' +
        document.getElementById('divExibitB').innerHTML;
    } else {
      html = url + '</br><b>EXHIBIT A' + this.quoteName + '</b></br></br>' +
        document.getElementById('divExibitA').innerHTML;
    }
    //+ '</br><b>EXHIBIT A-2 PHASE I - MARKET ENTRY FCC/NATIONWIDE</b></br>' +
    // document.getElementById('divExibitC').innerHTML;
    this.htmlToPdf.GetPriceQuoteProposalPDF(this.tokenKey, html, '0').
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.downloadFile('Final Proposal.pdf', data['url'], data['fileName']);
          this.unblockDocument();
        }
      },
        error => {
          this.unblockDocument();
        }
      );
  }

  createMyPdf1() {
    ApplicationActivityStore('View', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], 'Final Quote :'+this.priceQuoteId);
    this.blockDocument();

    var fCC499 = 0, fCCFRN = 0, rMD = 0, fCC214 = 0, cGSCA = 0;

    // let FF477 = this.listofCompliance.filter(y => y.stateId == 1)[0];
    // if (FF477 != null) {
    //   FF477.annualRate = + this.VoiceOnlyPrice + this.VoiceWithBroadband;
    // }
    // if (this.listofMarketEntryData != null && this.listofMarketEntryData.length > 0) {
    //   // cGSCA = this.listofMarketEntryData.filter(x => x.stateId == 101)[0].total;
    //   // fCC214 = this.listofMarketEntryData.filter(x => x.stateId == 102)[0].total;
    //   fCC499 = this.listofMarketEntryData.filter(x => x.stateId == 103)[0].total;
    //   fCCFRN = this.listofMarketEntryData.filter(x => x.stateId == 104)[0].total;
    //   rMD = this.listofMarketEntryData.filter(x => x.stateId == 105)[0].total;
    // }

    let value = {
      priceQuoteId: this.priceQuoteId,
      inteserraFee: this.sosServiceFees,
      inteserraPUCServiceFees: this.pucServiceFees,
      filingFee: this.pscFeelingFees,
      estimatedLocalAmount: this.localCounselFees,
      sOSInteserraFee: this.sosServiceFees,
      sOSCorpFees: this.sosCorpFeelingFees,
      sOSLLCFees: this.sosLLCFeelingFees,
      publicationFee: this.pscFeelingFees,
      dBAInteserraFee: this.dbA_Inteserra_Fee,
      dBAFilingFees: this.dbAFillingsFees,
      dBARAFees: this.dbA_RA_Fees,
      fCC499: fCC499,
      fCCFRN: fCCFRN,
      rMD: rMD,
      fCC214: fCC214,
      cGSCA: cGSCA,
      listofMarketEntryData: this.listofMarketEntryData,
      dbaServiceFees: this.dbaServiceFees,
      dbaFillinfFees: this.dbaFillinfFees,
      registeredAgentFees: this.registeredAgentFees
    }

    this.PricingQuotesMaster.GenerateProposal(value, this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          // this.downloadFile('PriceQuoteFinalProposal.pdf', data['url'], data['fileName']);
          this.pdfURlFinal = data['url'];
          this.pdfURl = this.pdfURlFinal;
          this.pdfOrgionalNameFinal = data['fileName'];
          this.pdfOrgionalName = this.pdfOrgionalNameFinal;
          this.handleRenderPdf(data['url'])
          this.unblockDocument();
          this.FinalQuoteLink = this.selectdUserDocument;
          //alert("First time final quote"+this.FinalQuoteLink);
        }
      },
      error => {
        this.unblockDocument();
      }
    );
  }

  handleRenderPdf(data) {
    this.selectdUserDocument = data;
    //this.selectdUserDocument = 'https://docs.google.com/gview?url=' + data + '&embedded=true';
    // this.selectdUserDocument ="https://docs.google.com/gview?url=https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx&embedded=true"
    // const pdfObject = PDFObject.embed(data, '#pdfContainer');
  }

  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        // ApplicationActivityStore('Download', 'TRFA File download ' + filename, this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/contentView');
        sessionStorage.setItem('fileloading', 'false');
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  public downloadFile1(no:number) {
    if(no==1){
      ApplicationActivityStore('Download', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], 'Final Quote :'+this.priceQuoteId);
    }
    else if(no==2){
      ApplicationActivityStore('Download', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], 'Market Entry Quote :'+this.priceQuoteId);
    }
    else if(no==3){
      ApplicationActivityStore('Download', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], 'Advisory Services Quote :'+this.priceQuoteId);
    }
    else if(no==4){
      ApplicationActivityStore('Download', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], 'Solution Quote :'+this.priceQuoteId);
    }
    else if(no==5){
      ApplicationActivityStore('Download', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], 'Market Entry Report :'+this.priceQuoteId);
    }
    
    this.blockDocument();
    this.htmlToPdf.downloadPdf(this.pdfURl).subscribe(
      (res) => {
        FileSaver.saveAs(res, this.pdfOrgionalName);
        // ApplicationActivityStore('Download', 'TRFA File download ' + filename, this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/contentView');
        sessionStorage.setItem('fileloading', 'false');
        // this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
        //   data => {
        this.unblockDocument();
        //   },
        // );
      }
    );
  }


  GetMarketEntryDataById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetMarketEntryDataById(this.tokenKey, priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.listofMarketEntryData = result['compliance'];
          if (this.listofMarketEntryData.length > 0) {
            // document.getElementById('exibita').style.display = 'none';

            this.sosServiceFees = 0;
            this.sosCorpFeelingFees = 0;
            this.pucServiceFees = 0;
            this.pscFeelingFees = 0;
            this.registeredAgentFees = 0;
            this.localCounselFees = 0;
            this.dbaFillinfFees = 0;
            this.sosLLCFeelingFees = 0;
            this.dbaServiceFees = 0;
            this.sosLLCFeelingFees = 0;
            this.dbaServiceFees = 0;
            this.dbA_Inteserra_Fee = 0.00;
            this.dbAFillingsFees = 0.00;
            this.dbA_RA_Fees = 0.00;

            for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {

              this.Total = 0.00;
              this.Total = +this.listofMarketEntryData[iCounter].soS_Service_Fees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees + +this.listofMarketEntryData[iCounter].puC_Service_Fees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;
              if (this.listofMarketEntryData[iCounter].marketEntryId == 52 || this.listofMarketEntryData[iCounter].marketEntryId == 53 || this.listofMarketEntryData[iCounter].marketEntryId == 54 || this.listofMarketEntryData[iCounter].marketEntryId == 55 || this.listofMarketEntryData[iCounter].marketEntryId == 56)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;
              this.listofMarketEntryData[iCounter].total = +this.Total;

              this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;
              this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;
              this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;
              this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;
              this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;
              this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;
              if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56)
                this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;
              if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56)
                this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;
              if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56)
                this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
              // if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56)
              //   this.dbA_Inteserra_Fee = this.dbA_Inteserra_Fee + +this.listofMarketEntryData[iCounter].dbA_Inteserra_Fee;
              // if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56)
              //   this.dbAFillingsFees = this.dbAFillingsFees + +this.listofMarketEntryData[iCounter].dbaFillingFees;
              // if (this.listofMarketEntryData[iCounter].marketEntryId != 52 && this.listofMarketEntryData[iCounter].marketEntryId != 53 && this.listofMarketEntryData[iCounter].marketEntryId != 54 && this.listofMarketEntryData[iCounter].marketEntryId != 55 && this.listofMarketEntryData[iCounter].marketEntryId != 56)
              // this.dbA_RA_Fees = this.dbA_RA_Fees + +this.listofMarketEntryData[iCounter].dbA_RA_Fees;
            }
            this.dbaComSpecFillingFees = 0;
            this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
            this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
            this.inteserraTotal = 0; this.companyTotal = 0;
            this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees;
            this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
          }
          else {
            // document.getElementById('exibita').style.display = 'block';
            // document.getElementById('exibitTabB').style.display = 'none';
          }
        }

      });
  }

  GetAllCommonQuote(){
    

    this.btnClickName=1;
    this.GetMarketEntryDataById(this.priceQuoteId);
    this.blockDocument();
    if (this.FinalQuoteLink == '' || this.FinalQuoteLink == null || this.FinalQuoteLink == undefined) {
      this.GetComplianceByClientInformationId(this.priceQuoteId);
    }
    else {
      this.selectdUserDocument = this.FinalQuoteLink;
      this.pdfURl = this.pdfURlFinal;
      this.pdfOrgionalName = this.pdfOrgionalNameFinal;
      this.unblockDocument();
      //alert("Old Final Quote"+this.selectdUserDocument)
    }
  }
  GetAdvisorysolutionbuttonDataById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetAdvisorysoultionbuttondisplay(priceQuoteId, this.tokenKey,).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result.lstdata[0].advisoryServices > 0) {
            this.advisoryQuotebtndisplay = true;
          }
          else {
            this.advisoryQuotebtndisplay = false;
          }

          if (result.lstdata[0].solutions > 0) {
            this.solutionQuotebtndisplay = true;
          }
          else {
            this.solutionQuotebtndisplay = false;
          }
        }

      });
  }

  createAdvisoryPdf() {
    ApplicationActivityStore('View', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], 'Advisory Services :'+this.priceQuoteId);
    this.btnClickName=3;
    this.blockDocument();
    if (this.FinalAdvisoryServicesQuoteLink == null || this.FinalAdvisoryServicesQuoteLink == '' || this.FinalAdvisoryServicesQuoteLink == undefined) {
      this.PricingQuotesMaster.GenerateAdvisoryServicesQuote(this.priceQuoteId, "", this.tokenKey).subscribe(
        data => {
          if (data['status'] == 'Success') {
            this.pdfURlAdvisory=data['url'];
            this.pdfURl = this.pdfURlAdvisory;
            this.pdfOrgionalNameAdvisory = data['fileName'];
            this.pdfOrgionalName = this.pdfOrgionalNameAdvisory;
            this.handleRenderPdf(data['url'])
            //this.downloadFile('AdvisoryProposal.pdf', data['url'], data['fileName']);
            this.FinalAdvisoryServicesQuoteLink = this.selectdUserDocument;
            this.unblockDocument();
            //alert("First time advisory" + this.FinalAdvisoryServicesQuoteLink)
          }
        },
        error => {
          this.unblockDocument();
        }
      );
    }
    else {
      this.selectdUserDocument = this.FinalAdvisoryServicesQuoteLink;
      this.pdfURl = this.pdfURlAdvisory;
      this.pdfOrgionalName = this.pdfOrgionalNameAdvisory;
      this.unblockDocument();
      //alert("old advisory"+this.selectdUserDocument)
    }
  }

  createSolutionPdf() {
    ApplicationActivityStore('View', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], 'Solution Quote :'+this.priceQuoteId);
    this.btnClickName=4;
    this.blockDocument();
    if(this.FinalSolutionsQuoteLink == null || this.FinalSolutionsQuoteLink == '' || this.FinalSolutionsQuoteLink == undefined){
       this.PricingQuotesMaster.GenerateSolutionServicesQuote(this.priceQuoteId,"",this.tokenKey).subscribe(
         data => {
           if (data['status'] == 'Success') {
             this.pdfURlSolution = data['url'];
             this.pdfURl = this.pdfURlSolution;
             this.pdfOrgionalNameSolution = data['fileName'];
             this.pdfOrgionalName = this.pdfOrgionalNameSolution;
             //this.downloadFile('SolutionsProposal.pdf', data['url'], data['fileName']);
             this.handleRenderPdf(data['url']);
             this.FinalSolutionsQuoteLink = this.selectdUserDocument;
             this.unblockDocument();
             //alert("First time solution"+this.FinalSolutionsQuoteLink)
           }
         },
         error => {
           this.unblockDocument();
         }
       );
    }
    else {
      this.selectdUserDocument = this.FinalSolutionsQuoteLink;
      this.pdfURl = this.pdfURlSolution;
      this.pdfOrgionalName = this.pdfOrgionalNameSolution;
      this.unblockDocument();
      //alert("Old solution"+this.selectdUserDocument)
    }
  }

  createMatrketEntryPdf() {
    ApplicationActivityStore('View', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], 'Market Entry Quote :'+this.priceQuoteId);
    this.btnClickName=2;
    this.blockDocument();
    if (this.FinalMarketEntryQuoteLink == null || this.FinalMarketEntryQuoteLink == '' || this.FinalMarketEntryQuoteLink == undefined) {
      this.PricingQuotesMaster.GenerateMarketEntryPDF(this.priceQuoteId, "", this.tokenKey).subscribe(
        data => {
          if (data['status'] == 'Success') {
            this.pdfURlMarketentry = data['url'];
            this.pdfURl=this.pdfURlMarketentry;
            this.pdfOrgionalNameMarketentry = data['fileName'];
            this.pdfOrgionalName = this.pdfOrgionalNameMarketentry;
            this.handleRenderPdf(data['url']);
            //this.downloadFile('MarketEntry.pdf', data['url'], data['fileName']);
            this.FinalMarketEntryQuoteLink = this.selectdUserDocument;
            this.unblockDocument();
            //alert("First time market entry"+this.FinalMarketEntryQuoteLink)
          }
        },
        error => {
          this.unblockDocument();
        }
      );
   }
   else
   {
     this.selectdUserDocument = this.FinalMarketEntryQuoteLink;
     this.pdfURl=this.pdfURlMarketentry;
     this.pdfOrgionalName = this.pdfOrgionalNameMarketentry;
     this.unblockDocument();
    //alert("old market entry"+this.selectdUserDocument)
   }
  }

  GenerateMarketEntryReportPDF() {
    ApplicationActivityStore('View', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], 'Market Entry Report :'+this.priceQuoteId);
    this.btnClickName=5;
    this.blockDocument();
    if(this.FinalMarketEntryReport == null || this.FinalMarketEntryReport == '' || this.FinalMarketEntryReport == undefined){
      this.PricingQuotesMaster.GenerateMarketEntryReportPDF(this.priceQuoteId,this.tokenKey).subscribe(
        data => {
          if (data['status'] == 'Success') {
            this.pdfURl = data['url'];
            this.pdfOrgionalName = data['fileName'];
            this.handleRenderPdf(data['url']);
            this.FinalMarketEntryReport = this.selectdUserDocument;
            this.unblockDocument();
          }
        },
        error => {
          this.unblockDocument();
        }
      );
   }
   else
   {
     this.selectdUserDocument = this.FinalMarketEntryReport;
     this.unblockDocument();
   }
  }




  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  isNumber(value: any): boolean {
    const regex = /^\d+$/;
    return regex.test(value);
  }

  priceQuoteSeen() {
    this.blockDocument();
    this.PricingQuotesMaster.UpdateQuoteMarkAsSeen(this.tokenKey, this.emailPriceQuoteId).subscribe(
      data => {
        this.unblockDocument();
        if (data['status'] == 'Success') {
         
        }
        this.unblockDocument();
      },
      error => { }
    );

  }

  onStatusChange(priceQuoteId, isQuoteApproved) {
    this.isSubmit = false;
    this.quoteStatusId = isQuoteApproved;
    this.rejectReason = "";
    if (isQuoteApproved == 2) {
      this.TitleMsg = "Approve"
    }
    else {
      this.TitleMsg = "Reject";
    }
    this.priceQuoteId = priceQuoteId;
    // var PriceQuoteData1 = this.dropdownListQuoteStatus.filter(x => x.id == isQuoteApproved)[0];
    // if (PriceQuoteData1 != null) {
    //   this.selectedItemsQuoteStatus = [];
    //   this.quoteStatusId = PriceQuoteData1.id;
    //   this.selectedItemsQuoteStatus.push({ "id": PriceQuoteData1.id, "itemName": PriceQuoteData1.itemName })
    // }
    document.getElementById("btnopenApprovedpopup").click();
    return;
  }

  onStatusReject(priceQuoteId, isQuoteApproved) {
    this.isSubmit = false;
    this.quoteStatusId = isQuoteApproved;
    this.priceQuoteId = priceQuoteId;
    var PriceQuoteData1 = this.dropdownListQuoteStatus.filter(x => x.id == isQuoteApproved)[0];
    if (PriceQuoteData1 != null) {
      this.selectedItemsQuoteStatus = [];
      this.quoteStatusId = PriceQuoteData1.id;
      this.selectedItemsQuoteStatus.push({ "id": PriceQuoteData1.id, "itemName": PriceQuoteData1.itemName })
    }
    document.getElementById("btnopenRejectpopup").click();
    return;
  }

  priceQuoteApproved() {
    this.isSubmit = true;

    this.isQuoteApproved = this.quoteStatusId;
    if (this.quoteStatusId == 3) {
      if (this.rejectReason == "" || this.rejectReason == null) {
        return;
      }
    }

    ApplicationActivityStore('Click Link', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], this.quoteName1 +" - "+this.TitleMsg);
    document.getElementById("closebutton").click();

    this.blockDocument();
    this.PricingQuotesMaster.PriceQuoteApproved(this.tokenKey, this.priceQuoteId, this.quoteStatusId, this.rejectReason).subscribe(
      data => {
        this.unblockDocument();
        if (data['status'] == 'Success') {
          this.isSubmit = false;
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else {
            // if (this.Sent == 'Sent')
            this.showMessage("Quote Status update successfully", 'Success', 'success')
            // else
            //   this.showMessage("Marked as Denied this Quote successfully", 'Success', 'success')
          }
        }

      },
      
      error => { this.stateCode = error }
    );
  }
}
