import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WhitePaperService {

  AppUrl: string = "";
  constructor(private _http: Http, private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  i360WhitePapersAddUpdate(TokenKey: string, WhitePapersModel: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/WhitePapers/i360WhitePapersAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, WhitePapersModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360WhitePapersGet(TokenKey: string, filterModel: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/WhitePapers/i360WhitePapersGet';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360WhitePapersFindbyWhitePaperId(TokenKey: string, WhitePapersId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/WhitePapers/i360WhitePapersFindbyWhitePaperId';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, WhitePapersId, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360WhitePapersDelete(TokenKey: string, WhitePapersId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/WhitePapers/i360WhitePapersDelete';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, WhitePapersId, httpOptions)
      .pipe(catchError(this.handleError));
  }
  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  i360WhitepaperSectionListWithIds(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/WhitePapers/i360WhitepaperSectionListWithIds';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.get<any>(this.AppUrl + data, httpOptions).pipe(catchError(this.handleError));
  }

  i360WhitePaperUpdate(TokenKey: string, currentIndexid: string, previousIndexid: string): Observable<any[]> {
    this.setAPIUrl();
    let data = 'api/WhitePapers/i360WhitepaperDetailUpdate/' + currentIndexid + '/' + previousIndexid;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
