import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TRSNoticeModel } from 'src/Models/TRSNoticeModel';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrsMasterService } from 'src/Services/trs-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-trs-add-update',
  templateUrl: './trs-add-update.component.html',
  styleUrls: ['./trs-add-update.component.css']
})
export class TrsAddUpdateComponent implements OnInit {

  @ViewChild('direcDOC', { static: true }) direcDOC;
  @ViewChild('billDoc', { static: true }) billDoc;

  productId: number;
  tokenKey: string = '';
  SelectedTool: string;
  userId: number = 0;
  module = "i360";

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateList: StateMasterModel[] = [];

  trsNoticeModel = new TRSNoticeModel();
  trsMasterForm: FormGroup;

  TRSNoticeModel: TRSNoticeModel = {
    trsId: 0,
    stateId: '',
    directoryDocName: '',
    directoryDocPath: '',
    billInsertDocName: '',
    billInsertDocPath: '',
    url: '',
    createdBy: '',
    createdDate: '',
    modifiedBy: '',
    modifiedDate: '',
    totalRecordCount: '',
    directoryPageLabel: '',
    billInsertLabel: ''
  };

  isSubmit: boolean = false;
  isErrorOnDirecDOC: boolean;
  isErrorOnBillDoc: boolean;
  selectedFiles: File;
  selectedFiles1: File;
  isDirectoryFile: boolean = false;
  isBillFile: boolean = false;
  // isDirectory: boolean = false;
  // isBill: boolean = false;


  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private titleService: Title, private router: Router, private toastr: ToastrService,
    private stateMasterService: StateMasterService, private activedRoute: ActivatedRoute, private trsMasterService: TrsMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage TRS Customer Notice');
    this.userId = +sessionStorage.getItem('AdminUserId');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  get url() { return this.trsMasterForm.get('url') };

  ngOnInit() {

    this.isSubmit = false;
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.trsMasterForm = this._fb.group({
      trsId: 0,
      stateId: [0],
      directoryDocPath: [''],
      billInsertDocPath: [''],
      url: ['', [Validators.required, Validators.pattern(reg)]],
      billInsertLabel: [''],
      directoryPageLabel: [''],
      directoryDocName: [''],
      billInsertDocName: [''],
    })

    this.dropdownSettingsState = {
      singleSelection: true,
      text: "Select Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.GetStateData();
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.GetTRSById(params['id']);
        }
      }
    });


  }

  GetStateData() {
    this.stateMasterService.getState(this.tokenKey, this.productId).subscribe(
      data => {
        this.stateList = data['state'];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  onChangeState(event) {
    this.trsMasterForm.patchValue({
      stateId: event.id
    });
  }

  onDeSelectAllState($event) {
    this.selectedItemsState = [];
  }

  GetTRSById(trsId: number) {
    this.trsMasterService.GetTRSNoticeById(this.tokenKey, parseInt(trsId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.trsNoticeModel = result['trsnotice'];
          if (this.trsNoticeModel != null) {
            if (this.trsNoticeModel.billInsertDocName != null)
              this.isBillFile = true

            if (this.trsNoticeModel.directoryDocName != null)
              this.isDirectoryFile = true

            this.trsMasterForm.setValue({
              trsId: this.trsNoticeModel.trsId,
              stateId: this.trsNoticeModel.stateId,
              directoryDocPath: this.trsNoticeModel.directoryDocPath,
              billInsertDocPath: this.trsNoticeModel.billInsertDocPath,
              directoryPageLabel: this.trsNoticeModel.directoryPageLabel,
              billInsertLabel: this.trsNoticeModel.billInsertLabel,
              url: this.trsNoticeModel.url,
              directoryDocName: this.trsNoticeModel.directoryDocName,
              billInsertDocName: this.trsNoticeModel.billInsertDocName,
            });
            if (this.stateList.length > 0) {
              let data = this.stateList.filter(x => x.stateId == this.trsNoticeModel.stateId)[0];
              this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name })
            }
            else
              setTimeout(() => {
                let data = this.stateList.filter(x => x.stateId == this.trsNoticeModel.stateId)[0];
                this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name })
              }, 500);
          }
        }
      }
    );
  }

  saveTRSNotice(trsMasterForm) {
    this.trsNoticeModel = trsMasterForm.value;

    event.preventDefault();

    if (this.selectedItemsState.length == 0) {
      this.showMessage('Please select jurisdiction', 'jurisdiction Required', 'error');
      return;
    }

    // if (this.direcDOC.nativeElement.files.length == 0 && this.trsNoticeModel.trsId == 0) {
    //   this.showMessage('Please select directory page document', 'Directory Page Document Required', 'error');
    //   return;
    // }

    // if (this.billDoc.nativeElement.files.length == 0 && this.trsNoticeModel.trsId == 0) {
    //   this.showMessage('Please select bill insert document', 'Bill Insert Document Required', 'error');
    //   return;
    // }

    if (this.isErrorOnDirecDOC) {
      this.showMessage('File not valid in directory page document', 'Invalid File', 'error');
      return;
    }

    if (this.isErrorOnBillDoc) {
      this.showMessage('File not valid in bill insert document', 'Invalid File', 'error');
      return;
    }

    if (this.trsNoticeModel.url == '' || this.trsNoticeModel.url == null) {
      this.trsNoticeModel.url = '';
    }
    else if (this.trsNoticeModel.url.length != 0 && this.url.invalid) {
      this.showMessage('Please enter valid url', '', 'error');
      return;
    }

    if (this.isDirectoryFile) {
      if (this.trsNoticeModel.directoryPageLabel == '' || this.trsNoticeModel.directoryPageLabel == null || this.trsNoticeModel.directoryPageLabel.trim() == '') {
        this.showMessage('Please enter Directory Page Label', '', 'error');
        return;
      }
    }

    if (this.isBillFile) {
      if (this.trsNoticeModel.billInsertLabel == '' || this.trsNoticeModel.billInsertLabel == null || this.trsNoticeModel.billInsertLabel.trim() == '') {
        this.showMessage('Please enter Bill Insert Label', '', 'error');
        return;
      }
    }

    this.trsNoticeModel.createdBy = this.userId;
    this.trsNoticeModel.modifiedBy = this.userId;

    let formData = new FormData();
    formData.append('trsId', this.trsNoticeModel.trsId);
    formData.append('stateId', this.trsNoticeModel.stateId);
    formData.append('direcDOC', this.direcDOC.nativeElement.files[0]);
    formData.append('billDoc', this.billDoc.nativeElement.files[0]);
    formData.append('url', this.trsNoticeModel.url);
    formData.append('createdBy', this.trsNoticeModel.createdBy);
    formData.append('modifiedBy', this.trsNoticeModel.modifiedBy);
    if (this.trsNoticeModel.directoryPageLabel != null)
      formData.append('directoryPageLabel', this.trsNoticeModel.directoryPageLabel);
    if (this.trsNoticeModel.billInsertLabel != null)
      formData.append('billInsertLabel', this.trsNoticeModel.billInsertLabel);

    this.trsMasterService.TRSNoticeAddUpdate(this.tokenKey, formData).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] == "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          }
          else {
            if (this.trsNoticeModel.trsId > 0)
              this.showMessage('TRS customer notice updated successfully', 'Success', 'success');
            else
              this.showMessage('TRS customer notice saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/trs-customer-notice']);
          }
        }
        else if (result['status'] == 'Failure') {
          this.showMessage(result['message'], 'Warning', 'warning')
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  cancelTRSNotice() {
    let navigation = '/admin/' + this.SelectedTool + '/trs-customer-notice';
    this.router.navigate([navigation]);
  }

  fileEvent(event): any {
    this.isDirectoryFile = false;
    var file = event.target.files[0];
    if (file == undefined) {
      this.trsMasterForm.patchValue({
        directoryPageLabel: ''
      });
    }
    let size = Math.round(file.size);
    if (size <= file.size) {
      var allowedExtensions = ["pdf", "PDF"];
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.', 'Error', 'error');
        this.isErrorOnDirecDOC = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isDirectoryFile = true;
        this.isErrorOnDirecDOC = false;
        this.selectedFiles = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnDirecDOC = true;
      window.scrollTo(0, 0);
    }
  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  fileEvent1(event): any {
    this.isBillFile = false;
    var file1 = event.target.files[0];
    if (file1 == undefined) {
      this.trsMasterForm.patchValue({
        billInsertLabel: ''
      });
    }
    let size1 = Math.round(file1.size);
    if (size1 <= file1.size) {
      var allowedExtensions1 = ["pdf", "PDF"];
      let fileType1 = file1.name.split('.').pop();

      if (!this.isInArray1(allowedExtensions1, fileType1)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.', 'Error', 'error');
        this.isErrorOnBillDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isBillFile = true;
        this.isErrorOnBillDoc = false;
        this.selectedFiles1 = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnBillDoc = true;
      window.scrollTo(0, 0);
    }
  }
  isInArray1(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
}

