import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientInformationModel } from 'src/Models/ClientInformationModel';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CompanyDirectoryModel } from 'src/Models/CompanyDirectoryModel';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { I360Service } from 'src/Services/i360Service';
import { CompanyService } from 'src/Services/company.service';
import { CompanyProductModel } from 'src/Models/CompanyProductModel';
import { ProductsService } from 'src/Services/products.service';
import { CompanyModel } from 'src/Models/CompanyModel';


@Component({
  selector: 'app-price-quotes-list',
  templateUrl: './price-quotes-list.component.html',
  styleUrls: ['./price-quotes-list.component.css']
})
export class PriceQuotesListComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  userID: any;
  listofPricingQuotes: ClientInformationModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  CompanyName: string;

  upDownCompanyNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDownQuoteNameCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowCreatedByCSS: string = '';
  upDowUpdateByCSS: string = '';
  upDownQuoteSentDateCSS: string = '';
  upDownQuoteStatusCSS: string = '';

  isAscending: boolean = true;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  OrderByName: string = '';
  blockedDocument = false;
  ClientInformationId: number;
  isShowprogressSpinner = false;
  OrderBy: string;
  IsClient: boolean = false;
  isInteserraUser: boolean = false;
  companyId:number;

  filterModel = new FilterModel();
  listOfCompany: CompanyDirectoryModel[];
  _userID: any;
  companyProduct: any;

  currentPage: number = 1;
  keywordFilter: string = '';

  upDowUserNameCSS: string = '';
  upDowNameCSS: string = '';
  upDowCompanyNameCSS: string = '';
  upDowEmailCSS: string = '';
  upDowUserTypeCSS: string = '';
  upDowIsActiveCSS: string = '';
  totalRecordCount1: number = 0;

  PriceQuoteStateList:any[]
  isPriceQuoteStateList:boolean=false;
  isCompanyProductList:boolean=false;

  listOfProducts:CompanyProductModel[];
  //loginuserID: number = 0;
  companyModel: CompanyModel = {
    companyId: 0,
    companyName: '',
    sfAccount: '',
    isDownload: false,
    companyProducts: [],
    isActive: true,
    is_FTP_Access: false,
    isFTP911Access: false,
    dba:'',
    notes:'',
    clientCompanyId:0,
    priceQuoteId:0
  };


  constructor(private productsService: ProductsService,private i360Service: I360Service,private router: Router, private checkAuth: CheckAuth, private titleService: Title, private activedRoute: ActivatedRoute, private companyService: CompanyService,
    private toastr: ToastrService, private pricingQuotesMaster: PricingQuotesService,private appActivity: ApplicationActivityService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = sessionStorage.getItem('UserId');
    this._userID = sessionStorage.getItem('UserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Company Profile');
    this.PageNumber = 1;
    this.PageSize = 10;
    this.OrderBy = 'CreatedDate desc';
    sessionStorage.removeItem('ClientCompanyId1');
    sessionStorage.removeItem('ClientCompanyName');
    sessionStorage.removeItem('ClientCompanyId');
    sessionStorage.removeItem('isVendor');
    this.companyId=+sessionStorage.getItem('CompanyId');
    
  }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    // this.getCompanyIdByUserId();
    this.getPriceQuoteListByCompanyId(this.companyId);
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'ISNULL(ModifiedDate,CreatedDate) DESC';    
    this.filterModel.userID = this._userID;
    this.GetPriceQuoteSubscriptionState(this.companyId);
    setTimeout(() => {
      this.i360CompanyDirectoryFindByUserId();
    }, 400);
    this.getCompanyProductsAccessList();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  getCompanyIdByUserId() {
    this.pricingQuotesMaster.getCompanyIdByUserId(this.tokenKey, parseInt(this.userID.toString())).subscribe(
      result => {
        this.isloading = false;
        this.companyId = result['companyId'];
      }
    );
  }

  getPriceQuoteListByCompanyId(companyId:number) {
    this.blockDocument();
    this.pricingQuotesMaster.getPriceQuoteListByCompanyId(this.tokenKey, companyId,true).subscribe(
      result => {
        this.isloading = false;
        this.listofPricingQuotes = result['pricingQuotes'];
        this.unblockDocument();
        if (this.listofPricingQuotes != null) {

          if (this.companyId == 19) {
            this.isInteserraUser = true;
          }
          else {
            this.isInteserraUser = false;
          }

          if (this.listofPricingQuotes.length == 0)
          {
            this.isRecord = true;
          }
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofPricingQuotes[0].totalRecordCount;
          }
        }
        else
        {
          let navigation = '/View/company-info';
          this.router.navigate([navigation]);
        }
      },
      error => { this.stateCode = error }
    );
  }

  addPricingQuotes() {
    let navigation = '/View/company-info';
    this.router.navigate([navigation]);
  }

  updatePricingQuotes(ClientInformationId: number, IsClient: boolean) {
    if (IsClient == true) {
      let navigation = '/View/client-quote-company-info/' + ClientInformationId;
      this.router.navigate([navigation]);
    }
    else {
      let navigation = '/View/company-info/' + ClientInformationId;
      this.router.navigate([navigation]);
    }
  }

  OnboardingDataCollection(ClientInformationId: number) {
    let navigation = '/View/add-company-info/' + ClientInformationId;
    sessionStorage.setItem('isVendor','false');
    this.router.navigate([navigation]);
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDownCompanyNameCSS = '';
      this.upDownQuoteNameCSS = '';
      this.upDowCreatedDateCSS = '';
      this.upDowCreatedByCSS = '';
      this.upDowUpdateDateCSS = '';
      this.upDowUpdateByCSS = '';
      this.upDowUserNameCSS = '';
      this.upDowNameCSS = '';
      this.upDowCompanyNameCSS = '';
      this.upDowEmailCSS = '';
      this.upDowUserTypeCSS = '';
      this.upDowIsActiveCSS = '';

      if (sortBy === "CompanyName") {
        this.upDownCompanyNameCSS = 'active-up';
      }
      else if (sortBy === "QuoteName") {
        this.upDownQuoteNameCSS = 'active-up';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowCreatedDateCSS = 'active-up';
      }
      else if (sortBy === "CreatedByName") {
        this.upDowCreatedByCSS = 'active-up';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowUpdateDateCSS = 'active-up';
      }
      else if (sortBy === "UpdatedByName") {
        this.upDowUpdateByCSS = 'active-up';
      }
      else if (sortBy === "SentDate") {
        this.upDownQuoteSentDateCSS = 'active-up';
      }
      else if (sortBy === "IsQuoteApproved") {
        this.upDownQuoteStatusCSS = 'active-up';
      }
      else if (sortBy === "username") {
        this.upDowUserNameCSS = 'active-up';
      }
      else if (sortBy === "name") {
        this.upDowNameCSS = 'active-up';
      }
      else if (sortBy === "companyName") {
        this.upDowCompanyNameCSS = 'active-up';
      }
      else if (sortBy === "email") {
        this.upDowEmailCSS = 'active-up';
      }
      else if (sortBy === "userType") {
        this.upDowUserTypeCSS = 'active-up';
      }
      else if (sortBy === "isActive") {
        this.upDowIsActiveCSS = 'active-up';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;
      this.upDownCompanyNameCSS = '';
      this.upDownQuoteNameCSS = '';
      this.upDowCreatedDateCSS = '';
      this.upDowCreatedByCSS = '';
      this.upDowUpdateDateCSS = '';
      this.upDowUpdateByCSS = '';
      this.upDowUserNameCSS = '';
      this.upDowNameCSS = '';
      this.upDowCompanyNameCSS = '';
      this.upDowEmailCSS = '';
      this.upDowUserTypeCSS = '';
      this.upDowIsActiveCSS = '';


      if (sortBy === "CompanyName") {
        this.upDownCompanyNameCSS = 'active';
      }
      else if (sortBy === "QuoteName") {
        this.upDownQuoteNameCSS = 'active';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowCreatedDateCSS = 'active';
      }
      else if (sortBy === "CreatedByName") {
        this.upDowCreatedByCSS = 'active';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowUpdateDateCSS = 'active';
      }
      else if (sortBy === "UpdatedByName") {
        this.upDowUpdateByCSS = 'active';
      }
      else if (sortBy === "SentDate") {
        this.upDownQuoteSentDateCSS = 'active';
      }
      else if (sortBy === "IsQuoteApproved") {
        this.upDownQuoteStatusCSS = 'active';
      }
      else if (sortBy === "username") {
        this.upDowUserNameCSS = 'active';
      }
      else if (sortBy === "name") {
        this.upDowNameCSS = 'active';
      }
      else if (sortBy === "companyName") {
        this.upDowCompanyNameCSS = 'active';
      }
      else if (sortBy === "email") {
        this.upDowEmailCSS = 'active';
      }
      else if (sortBy === "userType") {
        this.upDowUserTypeCSS = 'active';
      }
      else if (sortBy === "isActive") {
        this.upDowIsActiveCSS = 'active';
      }
    }
    this.OrderByName = sortBy;
    this.getPriceQuoteListByCompanyId(this.companyId);
    this.i360CompanyDirectoryFindByUserId();
  }

  getPageLength() {
    return Math.min((this.PageNumber * this.PageSize), this.totalRecordCount);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.getPriceQuoteListByCompanyId(this.companyId);
  }

  pageChangedDirectory($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.i360CompanyDirectoryFindByUserId();
  }

  i360CompanyDirectoryFindByUserId() {
    this.i360Service.i360CompanyDirectoryFindByUserId(this.tokenKey, this.filterModel).subscribe(
      data => {
        if (data != null) {
          this.listOfCompany = data['listOfCompany'];
          if (this.listOfCompany != null) {
            if (this.listOfCompany.length > 0) {
              this.totalRecordCount1 = this.listOfCompany[0].totalRecordCount;
            }
            else
              this.totalRecordCount1 = 0;
          }
          else
            this.totalRecordCount1 = 0;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) { this.filterModel.keywordFilter = $event.target.value; }
    else { this.filterModel.keywordFilter = null; }

    this.i360CompanyDirectoryFindByUserId();
  }

  GetPriceQuoteSubscriptionState(CompanyId){
    this.pricingQuotesMaster.GetStateSelectionForProfile(this.tokenKey, CompanyId).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          if (result['stateList'] != null) {
             this.PriceQuoteStateList = result['stateList'];
             if(this.PriceQuoteStateList.length>0)
             {
              this.isPriceQuoteStateList=true;
             }
          }
        }
      })
  }

  getCompanyProductsAccessList(){
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.pricingQuotesMaster.GetCompanyProductAcessList(this.tokenKey, this.companyId).subscribe(
      data => { 
        this.listOfProducts=data.companyProductsList;
        if(this.listOfProducts.length>0)
             {
              this.isCompanyProductList=true;
             }
      }
    )
  }

  addCompanyProfile(){
    let navigation = '/View/company-info';
    this.router.navigate([navigation]);
  }

}
