import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { TrfaAlertsModel } from 'src/Models/TrfaAlertsModel';
import { TrfaAlertService } from 'src/Services/alert.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-manage-trfa-alerts',
  templateUrl: './manage-trfa-alerts.component.html',
  styleUrls: ['./manage-trfa-alerts.component.css']
})
export class ManageTrfaAlertsComponent implements OnInit {

  tokenKey: string = '';
  userId: number = 0;
  SelectedTool: string;
  productId: number = 1;
  multiSelectDropDownHeight: number = 200;


  TrfaAlertsModel = new TrfaAlertsModel()

  addUpdateTrfaAlert = new FormGroup({
    updateId: new FormControl(''),
    updateDate: new FormControl(''),
    note: new FormControl(''),
    //description: new FormControl(''),
    isNotify: new FormControl(0),
  });

  isTrfaAlerts: boolean = true;
  updateId: number = 0;
  isDataLoad: boolean = false;
  isViewMode: boolean = false;

  isNotify: boolean ;

  pageNumber:number;
  pageSize:number;
  Email:string = '';
  Name:string = '';
  orderBy:string;
  fundName:string;


  constructor(private activedRoute: ActivatedRoute, private checkAuth: CheckAuth, public trfaAlertServices:TrfaAlertService,
    private userServices: UsersService, private toastr: ToastrService, private router: Router,private titleService: Title) {
      this.tokenKey = sessionStorage.getItem('tokenValue');
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
      this.SelectedTool = sessionStorage.getItem('UserLoginTool');
      this.productId = ToolEnum.trfa;
      this.titleService.setTitle('Manage TRFA Alert');
    }

  ngOnInit() {
    this.pageNumber = 1;
    this.pageSize = 10;
    this.orderBy = 'updateId ASC'
    this.activedRoute.params.subscribe((params: Params) => {

      if (params['id']) {
        if (params['id'] != null) {
          this.isTrfaAlerts = false;
          this.updateId = params['id'];
          this.userId = params['userid'];
          this.GetTrfaAlertsByUsers();
        }
      }
      else {
        this.userId = 0;
      }
    });
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  
  GetTrfaAlertsByUsers(): void {
    this.isDataLoad = true;
    this.trfaAlertServices.GetTrfaAlertsByUser(this.tokenKey, this.updateId,this.pageNumber,this.pageSize,this.userId,this.orderBy).subscribe(
      data => {
        if (data["trfaAlertuser"] != null && data["status"] != "Failure") {

          this.TrfaAlertsModel = data['trfaAlertuser'][0];

          this.Email=this.TrfaAlertsModel.email;
          this.Name=this.TrfaAlertsModel.name;
          this.fundName=this.TrfaAlertsModel.fundName;

          if (this.TrfaAlertsModel != null) { 
            let updateDate = new Date(this.TrfaAlertsModel.updateDate);

            let dd1 = updateDate.getDate().toString();
            if (parseInt(dd1.toString()) < 10)
              dd1 = '0' + dd1.toString();

             let mm1 = (updateDate.getMonth() + 1).toString();
            if (parseInt(mm1.toString()) < 10)
              mm1 = '0' + mm1.toString();

            this.TrfaAlertsModel.updateDate = mm1 + '/' + dd1 + '/' + updateDate.getFullYear();
          }

          this.addUpdateTrfaAlert.setValue({
            updateId: this.TrfaAlertsModel.updateId,
            updateDate: this.TrfaAlertsModel.updateDate,
            note: this.TrfaAlertsModel.note,
            //description: this.TrfaAlertsModel.description,
            isNotify: this.TrfaAlertsModel.isNotify
          });

          this.isDataLoad = false;
        }
        else {
          this.isDataLoad = false;
          this.showMessage(data["message"], 'Error', 'error');
        }
      }, error => {
        this.isDataLoad = false;
        this.showMessage(error, 'Error', 'error');
      })

  }


  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  isAlertDate(dateArray: any) {
    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      this.TrfaAlertsModel.updateDate = mm1 + '/' + dd1 + '/' + dateArray.getFullYear();
    }
    else
      this.TrfaAlertsModel.updateDate = null;
  }

  saveAddUpdateTrfaAlert(addUpdateTrfaAlert: any) {
    if (addUpdateTrfaAlert.value['updateDate'] == '') {
      this.showMessage('Please select alert date', 'Error', 'error');
      return;
    }
    let currentDate = new Date();
    if (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) > addUpdateTrfaAlert.value["updateDate"]) {
      this.showMessage('Previous alert date not allow', 'Error', 'error');
      return;
    }

    if (addUpdateTrfaAlert.value['note'] == '' || typeof (addUpdateTrfaAlert.value['note']) == 'undefined') {
      this.showMessage('Please enter note', 'Error', 'error');
      return;
    }
    if (addUpdateTrfaAlert.value['note'] != '') {
      if (addUpdateTrfaAlert.value['note'].toString().trim() == '') {
        this.showMessage('Please enter note', 'Error', 'error');
        return;
      }
    }

    if (typeof (addUpdateTrfaAlert.value['updateId']) == 'undefined') {
      this.TrfaAlertsModel.updateId = 0;
    }
    else {
      this.TrfaAlertsModel.updateId = addUpdateTrfaAlert.value['updateId'];
    }

    if (typeof (addUpdateTrfaAlert.value['note']) == 'undefined') {
      this.TrfaAlertsModel.note = '';
    }
    else {
      this.TrfaAlertsModel.note = addUpdateTrfaAlert.value['note'];
    }
    this.TrfaAlertsModel.isNotify = this.isNotify;
    this.TrfaAlertsModel.userId = this.userId;
    this.TrfaAlertsModel.updateDate =addUpdateTrfaAlert.value["updateDate"]
    this.TrfaAlertsModel.productId=this.productId;
    
    this.trfaAlertServices.AddUpdateTrfaAlerts(this.tokenKey, this.TrfaAlertsModel).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.showMessage('Trfa alerts Updated successfully', 'Success', 'success');
          this.router.navigate(['/admin/trfa/trfa-Alerts']);
        }
        else
          this.showMessage(data['status'], 'Error', 'error');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }
  
  removeHTMLTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  }

  setSendEmailNotificationChecked($event) {
    this.isNotify = $event.target.checked;
  }
}