export class ActionItemUpdateStatusModel {
    userID: number;
    updateId: number;
    actionId: number;
    statusId: number;
    assignToId: number;
    handleType: number;
    note: string;
    productId?: number = 0;
    previousAssingId?: number = 0;
    previousStatus?: number = 0;
}
