export class InteserraResourcesModel {
    resourcesId?: any
    externalURL?: any
    displayDocName: string;
    docPath: string
    title?: any
    isActive?: any
    createdDate?: any
    createdBy?: any
    modifiedDate?: any
    modifiedBy?: any
    totalRecordCount?: any
    isAllowallAccess?: any
    isSelected?: any
    productId?: any
    isLocked?: any
    isEmbed?: any
    linktype?:any
    sortOrder?:any;
}