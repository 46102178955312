import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { IPRModel } from 'src/Models/TrfaAlertsModel';
import { TrfaAlertService } from 'src/Services/alert.service';

@Component({
  selector: 'app-rmd-alerts',
  templateUrl: './rmd-alerts.component.html',
  styleUrls: ['./rmd-alerts.component.css']
})
export class RmdAlertsComponent implements OnInit {

  tokenKey: string = '';
  isloading: boolean = false;
  filteModel = new FilterModel();;
  IPRModel: IPRModel[];
  totalRecordCount: number = -1;
  isAscending: boolean;
  updateId: number = 0;

  upDownAlertDateCSS: string = '';
  upDownTitleCSS: string = '';
  upDownStatusCSS: string = '';
  upDownCreatedDateCSS: string = '';
  upDownModifiedDateCSS: string = '';
  Id: number = 0;
  IsSendEmail: boolean
  productId:number;
  SelectedTool: string;
  openMenuIndex: number = -1;

   constructor(private toastr: ToastrService, private router: Router,private trfaAlertServices:TrfaAlertService,private titleService:Title) { 
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId=ToolEnum.RMD;
    this.titleService.setTitle('RMD Alerts');
  }


  ngOnInit() {
    this.filteModel.pageNumber = '1';
    this.filteModel.pageSize = '10';
    this.filteModel.keywordFilter = '';
    this.filteModel.productId = this.productId;
    this.filteModel.orderBy = 'updateId ASC';
    this.filteModel.updateId=0;
    this.GetRMDAlertsFindByPages();
  }


  GetRMDAlertsFindByPages() {
    this.isloading = true;
    if (this.filteModel.keywordFilter == "null") this.filteModel.keywordFilter = " ";
    this.trfaAlertServices.GetRMDAlertsFindByPages(this.tokenKey, this.filteModel).subscribe(data => {
      if (data['status'] == 'Success') {
        this.isloading = false;
        this.IPRModel = data['rmdAlertList'];
        if (this.IPRModel != null) {
          if (this.IPRModel.length > 0) {
            this.totalRecordCount = +this.IPRModel[0].totalRowCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      }
      else {
        this.showMessage(data['status'], 'Error', 'error');
      }

    },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }


  keywordFilters($event) {
    this.filteModel.keywordFilter = $event.target.value;
    //this.filteModel.pageNumber = '1';
    if ($event.target.value != "" && $event.target.value != null) {
      this.filteModel.keywordFilter = $event.target.value;
    }
    else { this.filteModel.keywordFilter = ''; }

    this.GetRMDAlertsFindByPages();
  }

  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.filteModel.orderBy = sortBy + ' DESC';
      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.filteModel.orderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.GetRMDAlertsFindByPages();
  }

  setDescCSS(sortBy) {
    if (sortBy === "updateDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-down';
      this.upDownTitleCSS = '';
    }
    else if (sortBy === "providerName") {
      this.upDownAlertDateCSS = '';
      this.upDownTitleCSS = 'fa fa-arrow-down';
    }
  }

  setAscCSS(sortBy) {
    if (sortBy === "updateDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-up';
      this.upDownTitleCSS = '';
    }
    else if (sortBy === "providerName") {
      this.upDownAlertDateCSS = '';
      this.upDownTitleCSS = 'fa fa-arrow-up';
    }   
  }

  pageChanged($event) {
    this.filteModel.pageNumber = parseInt($event.page).toString();
    this.GetRMDAlertsFindByPages();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  redirectToEditView(updateId: any) {
    this.router.navigate(['/admin/rmd/rmd-Alerts/user/' + updateId]);
  }

  SetCancelMailId(updateId: any) {
    this.Id = updateId;
  }

  CancelMail()
  {
    this.trfaAlertServices.notsentAlertmail(this.tokenKey, this.Id,this.productId).subscribe(
      data => {
      if (data['status'] == 'Success') {
        this.showMessage('', 'Success', 'success');
        this.GetRMDAlertsFindByPages();
      }
      else
        this.showMessage(data['status'], 'Error', 'error');
    },
    error => {
      this.showMessage(error, 'Error', 'error');
    }
  );
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
