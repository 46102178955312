export class ActionItemSearchModel {
    deadlineDate: Date;
    title: string;
    colorCode: string;
    description: string;
    neworChanged: string;
    stateName: string;
    actionTypeName: string;
    carrierNames: string;
    statusName: string;
    actionId: string;
    updateId: string;
    actionItemTypeId: string;
    createdBy: string;
    createdDate: string;
    modifiedBy: string;
    modifiedDate: string;
    actionLinkId: string;
    assignToId: string;
    actionCreatedBy: string;
    actionCreatedDate: string;
    handleType: string;
    actionModifiedBy: string;
    actionModifiedDate: string;
    status: string;
    statusColorCode: string;
    isHideAssignToUser: string;
    isHideRadioOption: string;
    actionCharges: number = 0;
    actionNotes: string = '';
    isInformationOnly: any;
    isAllVisible: any;
}
