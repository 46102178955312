export class CRRAUpdateGetModel {
    updateId: number;
    note: string;
    updateDate: string|Date;
    stateName: string;
    shortName:string;
    totalRecordCount: number;
    isFavorite:boolean;
    userId:number;
}

export class FilterCRRAUpdateModel {
    pageNumber?: number;
    pageSize?: number;
    orderBy?: string;
    stateID?: string | number;
    fromDate?: string;
    toDate?: string;
    userId?:number;
    isFavorite?:number;
}