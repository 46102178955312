import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Filter911Model } from 'src/Models/filterModel';

@Injectable({
  providedIn: 'root'
})
export class CityMasterService {
  AppUrl: string = "";
  filter911Model=new Filter911Model();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  GetAllCity(TokenKey: string, PageNumber: number, PageSize: number, filterText: string, stateId: string, countyId: string,orderBy:string): Observable<any> {
    
    this.setAPIUrl();
    this.filter911Model={
      pageNumber:PageNumber,
      pageSize:PageSize,
      stateID:stateId,
      filterText:filterText,
      countyID:countyId,
      orderBy:orderBy,
      isFutureChanges:false,
      isActive:false
    }
    let URL = this.AppUrl + 'api/cityMaster/GetAllCity';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filter911Model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CreateOrUpdateCity(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/cityMaster/CreateOrUpdateCity';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCityById(TokenKey: string, cityId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/cityMaster/GetCityById/' + cityId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetCityByCountyId(TokenKey: string, countyId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/cityMaster/GetCityByCountyId/' + countyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteCity(TokenKey: string, cityId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/cityMaster/DeleteCity/' + cityId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetCityByCountyIdMultiple(TokenKey: string, countyId: string,IsAdmin : boolean): Observable<any> {
    
    this.setAPIUrl();
    let URL = this.AppUrl +'api/cityMaster/GetCityByCountyIdMultiple';

    let model={
      countyId:countyId,
      isAdmin:IsAdmin
    }
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL,JSON.stringify(model), httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCityByStateId(TokenKey: string, stateId: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/cityMaster/GetCityByStateId/' + stateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
