import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { AppConfig } from 'src/Services/app.config';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';
import { CheckAuth } from 'src/Services/utility.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';

@Component({
  selector: 'app-cfo-favorites',
  templateUrl: './cfo-favorites.component.html',
  styleUrls: ['./cfo-favorites.component.css']
})
export class CfoFavoritesComponent implements OnInit {
  appModel: any;
  isloading: boolean = false;
  tokenKey: string = '';
  favoritekey: string = '';
  favouriteCarrierTypeModel:any = [];
  
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  carrierForm: FormGroup;
  baseUrl: string;

  dropdownListCarriertype:any = [];
  selectedItemsCarriertype:any = [];
  dropdownSettingsCarriertype = {};
  CarriertypeFilter: string = '';


  listOfCarriertypeID: string[] = [];
  ClientSelectedTool: string;
  ValidationMessage: string;
  DeSelectedFavoriteId: number;
  FavoriteType: string;
  IsDeselected: number = 0;
  isCarriertypeRecordsExists: boolean = true;
  productId: number;
  module = '911';
  dropdownSettingsDateFilter = {};
  CarriertypeId: any;
  _userID: any;
  ProductId: number = 0;
  CarriertypeSubscription: any;
  CarriertypeList: StateMasterModel[] = [];
  TRFACarriertypeId: string;
  CarriertypefilterData: any;
  listOfCarriertypeIds = [];
  selectedCarriertype = [];
  CarriertypeIdList: string = '';
  SelectedCarriertypeFav: number = 0;
  INTERNALINTESERRAUSERS:boolean=false;

  constructor(@Inject('BASE_URL') baseUrl: string
  , private favorite: CarrierObligationService, private toastr: ToastrService
  , private titleService: Title, private checkAuth: CheckAuth
  , private router: Router, private activedRoute: ActivatedRoute,private appActivity: ApplicationActivityService) {

    let Inteseraemailid= localStorage.getItem('useremail');

    if (Inteseraemailid != '' && Inteseraemailid != null) {
      if (Inteseraemailid.indexOf('@inteserra.com') !== -1) {
        this.INTERNALINTESERRAUSERS = true;
      }
      else {
        this.INTERNALINTESERRAUSERS = false;
      }
    }
    else {
      this.INTERNALINTESERRAUSERS = false;
    }

    this.baseUrl = baseUrl;
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = +sessionStorage.getItem('UserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
  
    this.ClientSelectedTool = sessionStorage.getItem('ClientLoginTool');
    this.titleService.setTitle('My Favorites');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.favouriteCarrierTypeModel = [];
    this.dropdownSettingsDateFilter = {
      singleSelection: false,
      text: "Select Carrier Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Carrier Type available',
      showCheckbox: true
    };

    this.GetClientFavorites();
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
	
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  
  // Carrier ---------------------------
  
  OnSelectCarriertype($event) {
    this.IsDeselected = 0;
    this.SaveFavoriteCarrier($event['id'], 1);
  }
  
  OnDeSelectCarriertype($event) {
    this.IsDeselected = 0;
    this.SaveFavoriteCarrier($event['id'], 0);
  }

  onSelectAllCarriertypeList($event) {
    let carrierId='';
    if ($event) {
      $event.forEach(x => {
        carrierId += String(x.id) + ',';
      });
      carrierId = carrierId.slice(0,-1);
    }
    this.IsDeselected = 0;
    this.SaveFavoriteCarrier(carrierId, 1);
  }

  onDeSelectAllCarriertypeList($event) {
    if (this.favouriteCarrierTypeModel != null) {
      let carrierId='';
      this.favouriteCarrierTypeModel.forEach(x => {
        carrierId += String(x.carrierTypeId) + ',';
      });
      carrierId = carrierId.slice(0,-1);
      this.IsDeselected = 0;
      this.SaveFavoriteCarrier(carrierId, 0);
    }
  }
  
  AddToFavourite(carrier, IsFavourite: number) {
    let carrierId = carrier.carrierTypeId;
    if (IsFavourite == 0) {
      this.SelectedCarriertypeFav = carrierId;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavoriteCarrier(carrierId, IsFavourite);
    }
  }
  
  SaveFavoriteCarrier(carrierId: string, IsFavourite: number) {
    this.favorite.ClientCarrierFavouriteAdd(this.tokenKey, this._userID, carrierId, IsFavourite).subscribe(data => {
      if (data["status"] == "Success") {
        this.GetClientFavorites();
        if (IsFavourite == 1) {
          let CarrierTypename='';

          if(this.dropdownListCarriertype.length>0){
            for(let i=0;i<this.dropdownListCarriertype.length;i++){
              let ArrcarrierId=carrierId.split(',');
              if(ArrcarrierId.length>0){
                for(let j=0;j<ArrcarrierId.length;j++){
                  if(this.dropdownListCarriertype[i].id==ArrcarrierId[j]){
                    CarrierTypename+=this.dropdownListCarriertype[i].itemName+" ,";
                  }    
                }
              }
            }
            CarrierTypename=CarrierTypename.slice(0,-1);
            ApplicationActivityStore('Mark Favorite', 'View 47 Favorite', this.appActivity, ToolEnum.View47, ActionActivity['View 47'], CarrierTypename);
          }
          

          this.showMessage('Selected Carrier Type added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          let CarrierTypename='';

          if(this.dropdownListCarriertype.length>0){
            for(let i=0;i<this.dropdownListCarriertype.length;i++){
              let ArrcarrierId=carrierId.split(',');
              if(ArrcarrierId.length>0){
                for(let j=0;j<ArrcarrierId.length;j++){
                  if(this.dropdownListCarriertype[i].id==ArrcarrierId[j]){
                    CarrierTypename+=this.dropdownListCarriertype[i].itemName+" ,";
                  }    
                }
              }
            }
            CarrierTypename=CarrierTypename.slice(0,-1);
            ApplicationActivityStore('Remove Favorite', 'View 47 Favorite', this.appActivity, ToolEnum.View47, ActionActivity['View 47'], CarrierTypename);
          }
          this.showMessage('Selected Carrier Type removed successfully from favorite list.', 'Favorite', 'success');
        }
     
      }
      else {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
   
  }
  
  GetClientFavorites(): void {
    
    ApplicationActivityStore('View', 'View 47 Favorite', this.appActivity, ToolEnum.View47, ActionActivity['View 47'], '/View/View47-favorite');

    this.favorite.GetFavoriteCarrierByUser(this.tokenKey, this._userID).subscribe(
      data => {
        if (data["main"] != null) {
          this.favouriteCarrierTypeModel = data["main"];
          this.dropdownListCarriertype = [];
          this.listOfCarriertypeID=[];
          this.selectedItemsCarriertype=[];

          if (this.favouriteCarrierTypeModel.length > 0) {
            this.favouriteCarrierTypeModel.forEach(x => {
              this.dropdownListCarriertype.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
              this.listOfCarriertypeID.push(x.carrierId);
              if (x.isActive == true) {
                this.selectedItemsCarriertype.push({ "id": x.carrierTypeId, "itemName": x.carrierName });
              }
            });

            if (this.listOfCarriertypeID.length > 0) {
              this.CarriertypeIdList = this.listOfCarriertypeID.join(',')
            }
          }

          if (this.favouriteCarrierTypeModel == null)
            this.isCarriertypeRecordsExists = false;
          else
            this.isCarriertypeRecordsExists = true;
           
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
          this.unblockDocument();
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      }
    )
  }

  clear(){
    this.listOfCarriertypeID = [];
    this.selectedItemsCarriertype = [];
    this.dropdownListCarriertype = [];
    this.CarriertypeIdList = '';
  }
}