import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TRFAContentGetModel } from 'src/Models/TRFAContentModel';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrfaCarrierMasterService } from 'src/Services/trfa-carrier--master.service';
import { TrfaCategoryMasterService } from 'src/Services/trfa-category-master.service';
import { TrfaContentMasterService } from 'src/Services/trfa-content-master.service';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { CheckAuth } from 'src/Services/utility.service';
// import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TrfaSourceMasterService } from 'src/Services/trfa-source-master.service';
import { TrfaMonthsService } from 'src/Services/trfa-months.service';
@Component({
  selector: 'app-content-add-update',
  templateUrl: './content-add-update.component.html',
  styleUrls: ['./content-add-update.component.css']
})
export class ContentAddUpdateComponent implements OnInit {

  ContentMasterForm: FormGroup;
  userID: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  stateList: StateMasterModel[] = [];
  selectedState = [];
  fundList: TRFAContentGetModel[] = [];
  categoryList: any[] = [];
  carrierList: any[] = [];
  MonthList: any[] = [];
  selectedItemsMonth = [];
  ckeConfig:any;

  selectedFund = [];
  sourceList: any[] = [];

  isEdit: boolean = false;

  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};
  dropdownSettingsDateFundFilter = {};
  dropdownSettingsDateCategoryFilter = {};
  dropdownSettingsMonthFilter = {};
  dropdownSettingsDateCarrierFilter = {};
  dropdownSettingsSourceFilter = {};

  dropdownDateFundFilter = [];
  dropdownDateFundFilterTemp = [];
  dropdownDateCategoryFilter = [];
  dropdownDateCarrierFilter = [];
  dropdownMonthFilter = [];
  dropdownSourceFilter = [];

  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  module = "TRFA";
  selectedItemsCarrier = [];
  listOfCarrierIDs = [];
  listOfCarrierName = [];
  selectedItemsCategory = [];
  listOfCategoryIDs = [];
  listOfCategoryName = [];
  selectedItemsSource = [];

  listOfSource = [];
  listOfSourceName = [];
  listOfMonth = [];
  contentData: any;
  stateId: string = ' ';
  auditDate: any;
  auditMonth: any;

  isErrorOnInternalDoc: boolean;
  selectedInternalDoc: File;
  isInternalDocFile: boolean = false;
  isInternalDocInfo: boolean = false;
  allowedExtensions: any = ["pdf", "PDF", "doc", "docx", "DOC", "DOCX", "txt"];

  filePath1: string = null;

  file: any;
  @ViewChild('internalDocInfo', { static: true }) internalDocInfo;

  // config: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '15rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ],
  //   toolbarHiddenButtons: [
  //     [
  //       'undo',
  //       'redo',
  //       'subscript',
  //       'superscript',
  //       'indent',
  //       'outdent',
  //       'fontName'
  //     ],
  //     [
  //       'fontSize',
  //       'textColor',
  //       'backgroundColor',
  //       'customClasses',
  //       'unlink',
  //       'insertImage',
  //       'insertVideo',
  //       'insertHorizontalRule',
  //       'removeFormat',
  //     ]
  //   ]
  // }
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private stateService: StateMasterService,
    private datePipe: DatePipe,
    private categoryMasterService: TrfaCategoryMasterService,
    private carrierMasterService: TrfaCarrierMasterService,
    private toastr: ToastrService, private ContentService: TrfaContentMasterService,
    private fundService: TrfaFundMasterService, private sourceMasterService: TrfaSourceMasterService,
    private trfaMonthsService: TrfaMonthsService) {

   
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.ckeConfig = {
      versionCheck:false,
      toolbar: [
        { items:
          [
          'Undo','Redo','Link','Unlink','Anchor',
          'Image','Table','SpecialChar','Maximize','Source','Bold','Italic','Underline','Strike','RemoveFormat',
          'NumberedList','BulletedList','Outdent','Indent','Styles','Format','Font'
          ]
        }],
    }

    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsDateFundFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsDateCategoryFilter = {
      singleSelection: false,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsDateCarrierFilter = {
      singleSelection: false,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Carrier(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsMonthFilter = {
      singleSelection: false,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Month(s) available',
      showCheckbox: true
    };
    this.ContentMasterForm = this._fb.group({
      contentID: 0,
      fundID: [''],
      ruleText: ['', Validators.required],
      effectiveDate: ['', Validators.required],
      ruleCitation: [''],
      internalNote: [''],
      subcategory: [''],
      carrierTypeID: [''],
      categoryID: [''],
      auditDate: [''],
      auditMonth: [''],
      copyOfInternalDocPath:[''],
      copyOfInternalDocName:[''],
      copyOfInternalDocFullPath:[],
      fundName: ['']
    })
    this.tokenKey = sessionStorage.getItem('tokenValue');
    //this.getFund();
    this.getCarrier();
    this.getCategory();
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.getMonths();
    this.getSource();
    
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.getFund(true);
          this.GetContentById(params['id']);
        }
        else
        {
          this.getFund(false);
        }
      }
      else{ this.getFund(false);}
    });
  }

  getMonths() {
    this.MonthList = this.trfaMonthsService.getMonths();
    this.dropdownMonthFilter = [];
    this.MonthList.forEach(x => {
      this.dropdownMonthFilter.push({ "id": x.id, "itemName": x.name })
    });
  }

  GetContentById(ContentID) {
    this.ContentService.GetContentById(this.tokenKey, parseInt(ContentID.toString())).subscribe(
      result => {

        if (result['status'] == 'Success') {

          this.auditMonth = [];
          this.listOfSource = [];

          if (result['content'].lastAudit != null) { this.auditDate = new Date(result['content'].lastAudit) }
          if (result['content'].auditMonth != null) { this.auditMonth = new Date(result['content'].auditMonth) }

          this.contentData = result['content'];

          this.ClearContentForm();


          this.ContentMasterForm.patchValue({
            contentID: result['content'].contentID,
            fundID: result['content'].fundID,
            effectiveDate: result['content'].effectiveDate,
            ruleText: result['content'].ruleText,
            ruleCitation: result['content'].ruleCitation,
            internalNote: result['content'].internalNote,
            subcategory: result['content'].subcategory,
            carrierTypeID: result['content'].carrierTypeID,
            categoryID: result['content'].categoryID,
            auditDate: this.auditDate,
            auditMonth: this.auditMonth,
            sourceTypeId: result['content'].sourceTypeId,
            copyOfInternalDocPath: result['content'].copyOfInternalDocPath,
            copyOfInternalDocName: result['content'].copyOfInternalDocName,
            copyOfInternalDocFullPath:result['content'].copyOfInternalDocFullPath,
            fundName: result['content'].fundName
          });

          this.filePath1 = result['content'].copyOfInternalDocFullPath;
          if (this.fundList.length > 0) {
            this.selectedFund = [];
            let data = this.fundList.filter(x => x.fundID == result['content'].fundID)[0];
            //this.selectedFund.push({ "id": data.fundID, "itemName": data.fundName })
            this.selectedFund.push(data)
          }
          else
            setTimeout(() => {
              this.selectedFund = [];
              let data = this.fundList.filter(x => x.fundID == result['content'].fundID)[0];
              //this.selectedFund.push({ "id": data.fundID, "itemName": data.fundName })
              this.selectedFund.push(data)
            }, 800);

          if (this.categoryList.length > 0) {
            this.selectedItemsCategory = [];
            this.listOfCategoryIDs = [];
            this.listOfCategoryName=[];
            result['content'].categoryID.map((x) => {
              let data = this.categoryList.filter(y => y.categoryID == x.categoryID)[0];
              this.selectedItemsCategory.push({ "id": data.categoryID, "itemName": data.categoryName })
              this.listOfCategoryIDs.push(data.categoryID)
              this.listOfCategoryName.push(data.categoryName)
            })
          }
          else
            setTimeout(() => {
              this.selectedItemsCategory = [];
              this.listOfCategoryIDs = [];
              this.listOfCategoryName=[];
              result['content'].categoryID.map((x) => {
                let data = this.categoryList.filter(y => y.categoryID == x.categoryID)[0];
                this.selectedItemsCategory.push({ "id": data.categoryID, "itemName": data.categoryName });
                this.listOfCategoryIDs.push(data.categoryID)
                this.listOfCategoryName.push(data.categoryName);
              })
            }, 2000);


          if (this.carrierList.length > 0) {
            this.selectedItemsCarrier = []
            this.listOfCarrierIDs = []
            this.listOfCarrierName=[]
            for (let iCounter = 0; iCounter < result['content'].carrierTypeID.length; iCounter++) {

              let data = this.carrierList.filter(x => x.carrierTypeId == result['content'].carrierTypeID[iCounter].carrierTypeId)[0];
              this.selectedItemsCarrier.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
              this.listOfCarrierIDs.push(data.carrierTypeId)
              this.listOfCarrierName.push(data.carrierName)

            }
            // result['content'].carrierTypeID.map((x)=>{
            //   let data = this.carrierList.filter(x => x.carrierTypeId == x.carrierTypeId)[0];
            //    this.selectedItemsCarrier.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
            // })
          }
          else
            setTimeout(() => {
              if (result['content'].carrierTypeID.length > 0) {
                this.selectedItemsCarrier = []
                this.listOfCarrierIDs = []
                this.listOfCarrierName=[];
                for (let iCounter = 0; iCounter < result['content'].carrierTypeID.length; iCounter++) {

                  let data = this.carrierList.filter(x => x.carrierTypeId == result['content'].carrierTypeID[iCounter].carrierTypeId)[0];
                  this.selectedItemsCarrier.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
                  this.listOfCarrierIDs.push(data.carrierTypeId)
                  this.listOfCarrierName.push(data.carrierName)
                }
                // result['content'].carrierTypeID.map((x)=>{
                // });
              }
            }, 1500);

          if (this.sourceList.length > 0) {
            this.selectedItemsSource = []
            this.listOfSource = []
            this.listOfSourceName=[];
            for (let iCounter = 0; iCounter < result['content'].sourceTypeId.length; iCounter++) {

              let data = this.sourceList.filter(x => x.sourceTypeId == result['content'].sourceTypeId[iCounter].sourceTypeId)[0];
              this.selectedItemsSource.push({ "id": data.sourceTypeId, "itemName": data.sourceName })
              this.listOfSource.push(data.sourceTypeId)
              this.listOfSourceName.push(data.sourceName)
            }
          }
          else
            setTimeout(() => {
              if (result['content'].sourceTypeId.length > 0) {
                this.selectedItemsCarrier = []
                this.listOfSource = []
                this.listOfSourceName=[];
                for (let iCounter = 0; iCounter < result['content'].sourceTypeId.length; iCounter++) {

                  let data = this.sourceList.filter(x => x.sourceTypeId == result['content'].sourceTypeId[iCounter].sourceTypeId)[0];
                  this.selectedItemsSource.push({ "id": data.sourceTypeId, "itemName": data.sourceName })
                  this.listOfSource.push(data.sourceTypeId)
                  this.listOfSourceName.push(data.sourceName)
                }
              }
            }, 1000);


          if (this.MonthList.length > 0) {
            let auditMonthList = [];
            if(result['content'].auditMonth!=null)
            {
              if (result['content'].auditMonth.includes(',')) {
                auditMonthList = result['content'].auditMonth.split(',');

                this.listOfMonth=[];
              this.selectedItemsMonth=[];
              
              for (let iCounter = 0; iCounter < auditMonthList.length; iCounter++) {
                let data = this.MonthList.filter(x => x.name == auditMonthList[iCounter])[0];
                if (data != undefined) {
                  this.selectedItemsMonth.push({ "id": data.id, "itemName": data.name })
                  this.listOfMonth.push(data.name)
                }
              }
              }
              else if (result['content'].auditMonth !== "") {
                auditMonthList = result['content'].auditMonth;

                this.listOfMonth=[];
              this.selectedItemsMonth=[];
              // for (let iCounter = 0; iCounter < auditMonthList.length; iCounter++) {
                let data = this.MonthList.filter(x => x.name == auditMonthList)[0];
                if (data != undefined) {
                  this.selectedItemsMonth.push({ "id": data.id, "itemName": data.name })
                  this.listOfMonth.push(data.name)
                // }
              }
              }
            }

            // if (auditMonthList.length > 0) {
            //   this.listOfMonth=[];
            //   this.selectedItemsMonth=[];
            //   for (let iCounter = 0; iCounter < auditMonthList.length; iCounter++) {
            //     let data = this.MonthList.filter(x => x.name == auditMonthList[iCounter])[0];
            //     if (data != undefined) {
            //       this.selectedItemsMonth.push({ "id": data.id, "itemName": data.name })
            //       this.listOfMonth.push(data.name)
            //     }
            //   }
            // }
            
            // let data = this.MonthList.filter(x => x.name == result['content'].auditMonth)[0];
            // if (data != undefined) {
            //   this.selectedItemsMonth.push({ "id": data.id, "itemName": data.name })
            // }
          }
        }
      });
  }

  ClearContentForm(){
    this.ContentMasterForm = this._fb.group({
      contentID: 0,
      fundID: [''],
      ruleText: ['', Validators.required],
      effectiveDate: ['', Validators.required],
      ruleCitation: [''],
      internalNote: [''],
      subcategory: [''],
      carrierTypeID: [''],
      categoryID: [''],
      auditDate: [''],
      auditMonth: [''],
      fundName: [''],
    })
  }


  getFund(isEdit:boolean) {
    this.fundService.GetFund(this.tokenKey, this.stateId,true,false).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.fundList = result['fund'];
          this.dropdownDateFundFilterTemp = result['fund'];

          this.dropdownDateFundFilterTemp.forEach(x => {
            this.dropdownDateFundFilter.push(
              { 
                "id": x.fundID, 
                // "itemName": this.TextColorChange.transform(x.fundName)+' >> '+x.shortName +' >> '+x.fundTypeName,
                "itemName": x.fundName+'  >>  '+x.shortName +'  >>  '+x.fundTypeName,
                "shortName":x.shortName,
                "fundTypeName":x.fundTypeName,
                "fundName":x.fundName })
          });

        }
      },
      error => { }
    );
  }
  getCategory() {
    this.categoryMasterService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryList = result['category'];
          this.dropdownDateCategoryFilter = [];
          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilter.push({ "id": x.categoryID, "itemName": x.categoryName })
          });
          // if (this.categoryList.length > 0 && this.isEdit) {
          //   this.selectedItemsCategory=[]
          //  this.contentData.categoryID.map((x)=>{
          //     let data = this.categoryList.filter(x => x.categoryID == x.categoryID)[0];
          //     if(data) this.selectedItemsCategory.push({ "id": data.categoryID, "itemName": data.categoryName })
          //   })
          // }
        }
      },
      error => { }
    );
  }
  getCarrier() {
    this.carrierMasterService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierList = result['carrier'];
          this.dropdownDateCarrierFilter = [];
          this.carrierList.forEach(x => {
            this.dropdownDateCarrierFilter.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
          // if (this.carrierList.length > 0 && this.isEdit) {
          //   
          //   this.selectedItemsCarrier=[];
          //  this.contentData.carrierTypeID.map((x)=>{
          //     let data = this.carrierList.filter(x => x.carrierTypeId == x.carrierTypeId)[0];
          //     if(data) this.selectedItemsCarrier.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
          //   })
          // }
        }
      },
      error => { }
    );
  }

  cancelContent() {
    let navigation = '/admin/' + this.SelectedTool + '/content';
    this.router.navigate([navigation]);
  }

  save() {
    this.isSubmit = true;
    let data = this.ContentMasterForm.value;
    // || !data.note.trim()
    if (!this.ContentMasterForm.valid || this.selectedFund.length == 0 || this.selectedItemsCategory.length == 0 || this.selectedItemsCategory.length == 0)
      return
    let userId= this.userID;
    let carrierId = this.listOfCarrierIDs.join(',');
    let carrierName=this.listOfCarrierName.join(',');
    let categoryId = this.listOfCategoryIDs.join(',');
    let CategoryName=this.listOfCategoryName.join(',');
    let sourceId = this.listOfSource.join(',');
    let sourceName = this.listOfSourceName.join(',');
    let MonthList = this.listOfMonth.join(',');

    let value = {
      contentID: data.contentID,
      fundID: data.fundID,
      effectiveDate: this.datePipe.transform(data.effectiveDate, 'yyyy-MM-dd hh:mm:ss'),
      ruleText: data.ruleText==null?null:data.ruleText.trim(),
      ruleCitation: data.ruleCitation==null?null:data.ruleCitation.trim(),
      internalNote: data.internalNote==null?null:data.internalNote.trim(),
      subcategory: data.subcategory==null?null:data.subcategory.trim(),
      carrierTypeID: carrierId,
      categoryID: categoryId,
      lastAudit: this.datePipe.transform(data.auditDate, 'yyyy-MM-dd hh:mm:ss'),
      auditMonth: MonthList==''?null:MonthList,
      sourceTypeId: sourceId,
      userId:userId,
      carrierTypeName:carrierName,
      CategoryTypeName:CategoryName,
      sourceTypeName:sourceName==''?null:sourceName,
      fundName:data.fundName
    }

    let formData = new FormData();
    formData.append('contentID', data.contentID);
    formData.append('fundID', data.fundID);
    formData.append('effectiveDate', this.datePipe.transform(data.effectiveDate, 'yyyy-MM-dd hh:mm:ss'));
    formData.append('ruleText', data.ruleText==null?null:data.ruleText.trim());
    formData.append('ruleCitation', data.ruleCitation==null?null:data.ruleCitation.trim());
    formData.append('internalNote', data.internalNote);
    formData.append('subcategory', data.subcategory==null?null:data.subcategory.trim());
    formData.append('carrierTypeID', carrierId);
    formData.append('categoryID', categoryId);
    formData.append('lastAudit', this.datePipe.transform(data.auditDate, 'yyyy-MM-dd hh:mm:ss'));
    formData.append('auditMonth', MonthList);
    formData.append('sourceTypeId', sourceId);
    formData.append('userId', userId);
    formData.append('carrierTypeName', carrierName);
    formData.append('CategoryTypeName', CategoryName);
    formData.append('sourceTypeName', sourceName);
    formData.append('internalDocInfo', this.internalDocInfo.nativeElement.files[0]);
    formData.append('copyOfInternalDocPath', data.copyOfInternalDocPath);
    formData.append('copyOfInternalDocName', data.copyOfInternalDocName);
        
    this.ContentService.ContentAddUpdate(this.tokenKey, formData).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.contentID > 0)
              this.showMessage('Content Update successfully', 'Success', 'success');
            else
              this.showMessage('Content saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/content']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }


  onDeSelectDateFilterAll($event) {
    this.selectedState = [];
    this.dropdownDateFundFilter = [];
  }

  onDeSelectDateFilterFundAll($event) {
    this.selectedFund = [];
  }

  onChangeFund(event) {
    this.ContentMasterForm.patchValue({
      fundID: event.id,
      fundName: event.fundName
    });
  }

  onSelectCarrier($event) {
    this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
    this.listOfCarrierName= this.removeElemetninArray(this.listOfCarrierName, $event.itemName)
    this.listOfCarrierIDs.push($event.id);
    this.listOfCarrierName.push($event.itemName);
    // this.stateId = ' ';
    // if (this.listOfCarrierIDs.length > 0) {
    //   this.stateId = this.listOfCarrierIDs.join(',')
    // }
    // this.GetUpdate();
  }

  onDeSelectCarrier($event) {

    if (this.listOfCarrierIDs != null) {
      if (this.listOfCarrierIDs.length > 0) {
        this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
      }
    }

    if (this.listOfCarrierName != null) {
      if (this.listOfCarrierName.length > 0) {
        this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event.itemName)
      }
    }
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCarrierName != null) {
        if (this.listOfCarrierName.length > 0) {
          this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event[iCounter].itemName)
          this.listOfCarrierName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCarrierName.push($event[iCounter].itemName);
        }
      }
    }
    // this.GetUpdate();
  }

  onDeSelectAllCarrier($event) {

    this.listOfCarrierIDs = [];
    this.listOfCarrierName=[];
    // this.GetUpdate();
  }
  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectCategory($event) {
    //this.listOfCategoryIDs = []
    this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
    this.listOfCategoryIDs.push($event.id);

    this.listOfCategoryName=this.removeElemetninArray(this.listOfCategoryName, $event.itemName)
    this.listOfCategoryName.push($event.itemName);
    // this.stateId = ' ';
    // if (this.listOfCarrierIDs.length > 0) {
    //   this.stateId = this.listOfCarrierIDs.join(',')
    // }
    // this.GetUpdate();
  }

  onDeSelectCategory($event) {
    if (this.listOfCategoryIDs != null) {
      if (this.listOfCategoryIDs.length > 0) {
        this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
      }
    }

    if (this.listOfCategoryName != null) {
      if (this.listOfCategoryName.length > 0) {
        this.listOfCategoryName = this.removeElemetninArray(this.listOfCategoryName, $event.itemName)
      }
    }
    // if (this.listOfCarrierIDs.length > 0) {
    //   this.stateId = this.listOfCarrierIDs.join(',')
    // }
    // this.GetUpdate();
  }

  onSelectAllCategory($event) {
    this.listOfCategoryIDs = [];
    this.listOfCategoryName=[];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCategoryName != null) {
        if (this.listOfCategoryName.length > 0) {
          this.listOfCategoryName = this.removeElemetninArray(this.listOfCategoryName, $event[iCounter].itemName)
          this.listOfCategoryName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCategoryName.push($event[iCounter].itemName);
        }
      }
    }
    // this.GetUpdate();
  }

  onDeSelectAllCategory($event) {
    this.listOfCategoryIDs = [];
    this.listOfCategoryName=[];

    // this.GetUpdate();
  }
  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  getSource() {
    this.sourceMasterService.GetSource(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.sourceList = result['source'];
          this.dropdownSourceFilter = [];
          this.sourceList.forEach(x => {
            this.dropdownSourceFilter.push({ "id": x.sourceTypeId, "itemName": x.sourceName })
          });
        }
      },
      error => { }
    );
  }

  onSelectSource($event) {
    this.listOfSource = this.removeElemetninArray(this.listOfSource, $event.id)
    this.listOfSource.push($event.id);
    this.listOfSourceName = this.removeElemetninArray(this.listOfSourceName, $event.itemName)
    this.listOfSourceName.push($event.itemName);
  }

  onDeSelectSource($event) {

    if (this.listOfSource != null) {
      if (this.listOfSource.length > 0) {
        this.listOfSource = this.removeElemetninArray(this.listOfSource, $event.id)
      }
    }
    if (this.listOfSourceName != null) {
      if (this.listOfSourceName.length > 0) {
        this.listOfSourceName = this.removeElemetninArray(this.listOfSourceName, $event.itemName)
      }
    }
    this.selectedItemsSource

  }

  onSelectAllSource($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSource != null) {
        if (this.listOfSource.length > 0) {
          this.listOfSource = this.removeElemetninArray(this.listOfSource, $event[iCounter].id)
          this.listOfSource.push($event[iCounter].id);
        }
        else {
          this.listOfSource.push($event[iCounter].id);
        }
      }

      if (this.listOfSourceName != null) {
        if (this.listOfSourceName.length > 0) {
          this.listOfSourceName = this.removeElemetninArray(this.listOfSourceName, $event[iCounter].itemName)
          this.listOfSourceName.push($event[iCounter].itemName);
        }
        else {
          this.listOfSourceName.push($event[iCounter].itemName);
        }
      }
    }

  }

  onDeSelectAllSource($event) {
    this.listOfSource = [];
    this.listOfSourceName=[];
  }

  onDeSelectMonthAll($event) {
    this.selectedItemsMonth = [];
    this.listOfMonth = [];
  }

  onSelectMonth($event) {
    this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event.itemName)
    this.listOfMonth.push($event.itemName);
    // this.ContentMasterForm.patchValue({
    //   auditMonth: event.itemName
    // });
  }

  onDeSelectMonth($event) {
    if (this.listOfMonth != null) {
      if (this.listOfMonth.length > 0) {
        this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event.itemName)
      }
    }
  }

  onSelectAllMonth($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonth != null) {
        if (this.listOfMonth.length > 0) {
          this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event[iCounter].itemName)
          this.listOfMonth.push($event[iCounter].itemName);
        }
        else {
          this.listOfMonth.push($event[iCounter].itemName);
        }
      }
    }
  }

  articlesInfoEvent(event): any {
    this.isInternalDocFile = false;
    this.isInternalDocInfo = false;
    var file = event.target.files[0];

    let size = Math.round(file.size);
    if (size <= file.size) {
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(this.allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.', 'Error', 'error');
        this.isErrorOnInternalDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isInternalDocFile = true;
        this.isInternalDocInfo = true;
        this.isErrorOnInternalDoc = false;
        this.selectedInternalDoc = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnInternalDoc = true;
      window.scrollTo(0, 0);
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

}

