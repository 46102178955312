import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { HomePageBannerModel } from 'src/Models/HomePageBannerModel';
import { HomepagebannerService } from 'src/Services/homepagebanner.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-home-page-banner',
  templateUrl: './home-page-banner.component.html',
  styleUrls: ['./home-page-banner.component.css']
})
export class HomePageBannerComponent implements OnInit {

  filterModel = new FilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfHomePageBanner: HomePageBannerModel[];

  isUserSystemAdmin: boolean = true;
  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false
  upDowTitleCSS: string = '';
  upDowURLCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';

  productId: number = 1;
  deletedId: number = 0;
  isloading: boolean;
  openMenuIndex: number = -1;

  constructor(private bannerService: HomepagebannerService, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Banner');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'ISNULL(ModifiedDate,CreatedDate) DESC';
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.GetHomePageBannerData();
  }

  GetHomePageBannerData() {
    this.listOfHomePageBanner = [];
    this.isloading = true;
    this.bannerService.i360HomePageBannerGet(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfHomePageBanner = data['homePageBannerData'];
          if (this.listOfHomePageBanner != null) {
            if (this.listOfHomePageBanner.length > 0) {
              this.totalRecordCount = this.listOfHomePageBanner[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filterModel.whereCondition = $event.target.value;
    }
    else {
      this.filterModel.whereCondition = '';
    }
    this.GetHomePageBannerData();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetHomePageBannerData();
  }

  cleanCssClass() {
    this.upDowTitleCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case "title": this.upDowTitleCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "isActive": this.upDowIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "CreatedDate": this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "ModifiedDate": this.upDowModifiedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetHomePageBannerData();
  }

  addHomePageBanner() {
    this.router.navigate(['admin/inteserra360/Banner/manage']);
  }

  editData(id) {
    this.router.navigate(['admin/inteserra360/Banner/manage/' + id])
  }

  setDeleteData(ids) {
    this.deletedId = ids;
  }

  deleteRecord(deletedId) {
    this.bannerService.i360HomePageBannerDelete(this.tokenKey, deletedId).subscribe(
      data => {
        this.deletedId = 0;
        if (data != null) {
          if (data['deletedId'] > 0) {
            this.GetHomePageBannerData();
            this.toastr.success('Record deleted successfully.', 'Delete')
          }
        }
      },
      error => {
      }
    );
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
