export class TRFAContentModel {
  contentID: number;
  fundID: number;
  ruleText: string;
  effectiveDate: string | Date;
  ruleSite: string;
  draftRevisions: string;
  ruleHeading: string;
  carrierTypeID: string;
  categoryID: string;
}
export class TRFAContentGetModel {
  contentID: number;
  fundID: number;
  ruleText: string;
  effectiveDate: string | Date;
  ruleSite: string;
  draftRevisions: string;
  ruleHeading: string;
  fundName: string;
  fundTypeName: string;
  stateName: string;
  totalRecordCount: number;
  lastAudit: string | Date;
  auditMonth: string;
  sourceName: string;
  ruleTextupdated: string;
  isExpanded: boolean;
  fundCount: number;
}

export class TRFAContentGetModel_v2 {
  contentID: number;
  fundID: number;
  ruleText: string;
  effectiveDate: string | Date;
  fundName: string;
  fundTypeName: string;
  lastAudit: string | Date;
  auditMonth: string;
  sourceName: string;
  ruleTextupdated: string;
  carrierName:string;
  categoryName:any;
  effectiveDates:any;
  internalNote:any;
  isFavorite:any;
  note:any;
  ruleCitation:any;
  totalRecordCount:any;
  stateID:any;
  stateName:any;
  subcategory:any;
}


export class FilterContentModel {
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  stateID?: string | number;
  categoryID?: string | number;
  fundTypeID?: string | number;
  fundID?: string | number;
  carrierTypeID?: string | number;
  isCompare?: boolean;
  SourceTypeID?: string | number;
  auditMonth?: string
  note?: string
  baseUrl?: string
  userId?: number
  isFavorite?: number
  fundsId?:string|number;
}

export class TRFAGetUpdateViewModel {
  contentID: number;
  fundID: number;
  stateName: string;
  fundName: string;
  stateID: number;
  linkList: any[];
  fundList: any[];
}

export class TRFAContentHistoryGetModel {
  recordId: number;
  fieldName: string;
  oldValue: string;
  newValue: string;
  createdDate: string | Date;
  createdBy: number;
  userName: string;
  totalRecordCount:number;
}