import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';
import { ToolEnum } from 'src/Models/Common';
import { TabsetComponent } from 'ngx-bootstrap';

@Component({
  selector: 'app-manage-subscriptions',
  templateUrl: './manage-subscriptions.component.html',
  styleUrls: ['./manage-subscriptions.component.css'],  
})
export class ManageSubscriptionsComponent implements OnInit {
  selectedWirelessCount = 0;
  selectedVoIPCount = 0;
  selectedIPRCount = 0;
  selectedActionCount = 0;
  selectedBriefingCount = 0;

  selectedProducts = [];
  tokenKey: string = '';
  _userID: any;
  stateSubscription: any;
  isGeneralAlertSubscription: boolean = false;
  @ViewChild('statesTabs', { static: false }) statesTabs: TabsetComponent;
  lockedProduct: any;
  unLockedProduct: any;

  constructor(private stateServices: StateMasterService, private checkAuth: CheckAuth, private titleService: Title,) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Manage Subscriptions | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.getSubscriptionState();
  }

  getSubscriptionState() {
    
    this.stateSubscription = null;
    this.stateServices.i360ManageSubscriptionClientId(this.tokenKey, this._userID, 0,false).subscribe(
      data => {
        this.stateSubscription = {
          wirelessStates: data.wirelessStates,
          wirelessStatesCount: data.wirelessStates ? data.wirelessStates.length : 0,
          iprStates: data.iprStates,
          iprStatesCount: data.iprStates ? data.iprStates.length : 0,
          voIPStates: data.voIPStates,
          voIPStatesCount: data.voIPStates ? data.voIPStates.length : 0,
          actionStates: data.actionStates,
          actionStatesCount: data.actionStates ? data.actionStates.length : 0,
          productAccess: data.productAccess,
          briefingStates: data.briefingStates,
          briefingStatesCount: data.briefingStates ? data.briefingStates.length : 0,
          state911: data.state911,
          state911Count: data.state911 ? data.state911.length : 0,
          carrierCategory: data.carrierCategory,
          carrierCategoryCount: data.carrierCategory ? data.carrierCategory.filter(m => m.isSelected == true).length : 0,
        };


        if (data.productAccess.length > 0) {
          this.lockedProduct = data.productAccess.filter(m => m.isLocked == true && m.productId != 11 && m.productId != 12);
          this.unLockedProduct = data.productAccess.filter(m => m.isLocked == false);
          let sub = data.productAccess.filter(m => m.productId !== ToolEnum.actionitem && m.productId !== ToolEnum.ipr && m.productId !== ToolEnum.voip && m.productId !== ToolEnum.wireless)
          if (sub.length > 0) {
            this.isGeneralAlertSubscription = true;
          }
          else {
            if (this.statesTabs != null && typeof (this.statesTabs) != 'undefined') {
              this.statesTabs.tabs[0].active = true;
            }
            this.isGeneralAlertSubscription = false;
          }
        }
      },
      error => { console.log(error); }
    );
  }
}
