import { Component, OnInit } from '@angular/core';
import { CheckAuth } from 'src/Services/utility.service';
import { CountyMasterService } from 'src/Services/county-master.service';
import { CountyMaster911Model } from 'src/Models/CountyMaster911Model';
import { Router } from '@angular/router';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CityMasterService } from 'src/Services/city-master.service';
import { DropDown911Model } from 'src/Models/CityMaster911Model';
import { Main911Service } from 'src/Services/main911.service';
import { Main911Model } from 'src/Models/Main911Model';
import { DatePipe } from '@angular/common';
import { I360Service } from 'src/Services/i360Service';
import { Filter911Model, FilterModel } from 'src/Models/filterModel';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/Services/users.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { ActionActivity, EnumFavorites, ToolEnum } from 'src/Models/Common';
import { FavoriteFilterModel } from 'src/Models/FavoriteFilterModel';
import { FavoriteService } from 'src/Services/favorite.service';

@Component({
  selector: 'app-future-changes',
  templateUrl: './future-changes.component.html',
  styleUrls: ['./future-changes.component.css']
})
export class FutureChangesComponent implements OnInit {

  
  tokenKey: string;
  listofMain911: Main911Model[];
  PageNumber: number = 1;
  PageSize: number = 30;
  isRecord: boolean = false;
  isloading: boolean = false;
  totalRecordCount: number = 0;
  stateId: string = ' ';
  filterText: string = ' ';
  SelectedTool: string;
  stateList: StateMasterModel[] = [];
  countyId: string = ' ';
  countyList: CountyMaster911Model[] = [];
  selectedCounty = [];
  orderBy: string = 'StateName,CountyName,CityName';
  isAscending: boolean = false;
  upDowNameCSS: string = '';
  upDowScopeNameCSS: string = '';
  upDowCountyNameCSS: string = '';
  upDowFeeCSS: string = '';
  upDowEffectiveDateCSS: string = '';
  upDowEndDateCSS: string = '';
  upDowWhatTaxedCSS: string = '';
  upDowNotesCSS: string = '';
  upDowActiveCSS: string = '';
  upDowRateNameCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowCityNameCSS: string = '';
  upDowCitationNameCSS: string = '';
  upDowAuditDateCSS: string = '';
  upDowAuditMonthCSS: string = '';

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  listofstatename=[];
  //county
  dropdownDateCountyFilter = [];
  selectedItemsCounty = [];
  dropdownSettingsDateFilter = {};
  listOfColumnId = [];
  cityId: string = ' ';
  selectedState = [];
  // dropdownDateCountyFilter = [];
  selectedCity = [];
  cityList: DropDown911Model[] = [];
  dropdownDateCityFilter = [];
  todayDate: any;
  filterModel = new FilterModel();
  blockedDocument = false;
  isShowprogressSpinner = false;
  dropdownSettingsDateCountyFilter = {};
  dropdownSettingsDateCityFilter = {};
  OrderByName: string = '';
  dropdownDateSelectedColumnFilter = [];
  selectedColumn = [];
  dropdownSettingsDateselectedColumnFilter = {};
  isShow: boolean = false;
  pageSizeOptions:any[]=[];
  dropdownSettingsDatePageSizeFilter = {};
  selectedPageSize = [];
  listOfCountyIds=[];

    //State List
    // dropdownListState = [];
    // selectedItemsState = [];
    // dropdownSettingsState = {};
    isColumnExist:boolean =true;
    listOfCityIds = [];
    listofMain911PrintData: any[]=[];
    filter911Model=new Filter911Model();
    AppUrl:string="";
    isShowprogressSpinners:boolean=true;
    module='911';
    _userID:any;
    exportCitiationList:any[]=[];

  SelectedStateFav: number = 0;
  favouriteStateModel: FavoriteFilterModel[] = [];
  ProductId:number = 0;
  displayFavorite:number=0;

  constructor(private appActivity: ApplicationActivityService,private countyService: CountyMasterService, private stateService: StateMasterService, private router: Router, private checkAuth: CheckAuth, private cityService: CityMasterService, private main911Service: Main911Service, private datePipe: DatePipe, private i360service: I360Service, private toastr: ToastrService,private userService: UsersService,private favorite: FavoriteService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.ProductId = ToolEnum.state911;
    this._userID = sessionStorage.getItem('UserId');
    this.upDowNameCSS = 'active'
    this.isAscending = false;
    this.OrderByName = 'StateName,CountyName,CityName';
    // this.upDowNameCSS = 'active-up'
    // this.isAscending = true;
    this.ExportCitiationSubscriptionGet();
    this.getColumn();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.todayDate = this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    this.dropdownSettingsDateselectedColumnFilter = {
      singleSelection: false,
      text: "Show Column",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Column(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsDateFilter = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsDateCountyFilter = {
      singleSelection: false,
      text: "Filter by County",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No County(s) available',
      showCheckbox: true,
      lazyLoading:true,
    };
    this.dropdownSettingsDateCityFilter = {
      singleSelection: false,
      text: "Filter by City",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No City(s) available',
      showCheckbox: true,
      lazyLoading:true,
      enableFilterSelectAll:false
    };
    this.dropdownSettingsDatePageSizeFilter = {
      singleSelection: true,
      text: "Filter by Page",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Page(s) available',
      showCheckbox: false,
      escapeToClose:false,
    };
    this.pageSizeOptions=[
      { "id":1, "itemName": 10},
      { "id":2, "itemName": 50},
      { "id":3, "itemName": 100},
      { "id":4, "itemName": 200},
    ];
    this.selectedPageSize.push({ "id":1, "itemName": 10})
    this.tokenKey = sessionStorage.getItem('tokenValue');
    // this.getState();
    this.getSubscriptionState();
    this.MainGetAll();
  }

  keywordFilters($event) {
    if ($event.target.value != "" && $event.target.value != null)
      this.filterText = $event.target.value;
    else
      this.filterText = ' ';
    this.MainGetAll();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    ApplicationActivityStore('View', '911 Future Changes-'+$event.page,this.appActivity,ToolEnum.state911, ActionActivity.State911,'/View/futureChanges','');
    this.MainGetAll();
  }

  MainGetAll() {
    
    this.isloading = true;
    this.stateId = ' ';
    this.countyId = ' ';
    // if (this.selectedCounty.length > 0) {
    //   this.countyId = this.selectedCounty[0].id
    // }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.cityId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.cityId =  this.listOfCityIds.join(',')
    }
    if (this.selectedState.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
      let listofstatename = "";
      this.listOfStateIds.forEach((value, y) => {
        const statemodal = this.stateList.filter(x => x.stateId == value);
        if(this.listOfStateIds.length-1==y)
        {         
         listofstatename = listofstatename + statemodal[0].name + '<br />';
        }
        else{
          listofstatename = listofstatename + statemodal[0].name + ',' + '<br />';
        }
      });

      if (listofstatename != "") {
        listofstatename = listofstatename.trim();
        ApplicationActivityStore('View', '911 Future Changes-' ,this.appActivity, ToolEnum.state911, ActionActivity.State911, '/View/rateLookup',listofstatename);
      }

      this.main911Service.Get911FutureChanges(this.tokenKey, this.PageNumber, this.PageSize, this._userID,this.stateId, this.countyId, this.cityId, this.orderBy,this.displayFavorite).subscribe(
        data => {
          this.isloading = false;
          this.listofMain911 = data['main'];
          if (this.listofMain911 != null) {
            if (this.listofMain911.length == 0){
              this.totalRecordCount=0;
              this.isRecord = true;
            }
            else {
              this.isRecord = false;
              this.totalRecordCount = this.listofMain911[0].totalRecordCount;
              this.listofMain911.forEach((x) => {
                if (new Date(this.datePipe.transform(new Date(x.endDate), 'MM-dd-yyyy')) >= new Date(this.todayDate)) x['isActive'] = true;
                else x['isActive'] = false;
  
              })
  
  
            }
          }
          else{
            this.totalRecordCount=0;
            this.isRecord = true;
          }
        },
        error => { }
      );
    }
    else{
      this.listofMain911=[];
      this.totalRecordCount=0;
      this.isRecord = true;
    }
    this.setAPIUrl();
  }

  delete911Main(rateID) {
    this.isloading = true;
    this.main911Service.Delete911Main(this.tokenKey, rateID).subscribe(
      data => {
        this.isloading = false;
        this.MainGetAll();
      },
      error => { }
    );
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey,this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  onSelectState($event) {
    this.resetPageNoandSize();
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetCountyByStateId(this.stateId);
    this.MainGetAll();
    // this.MainGetAll();
  }

  onDeSelectState($event) {
    this.resetPageNoandSize();
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.listOfStateIds.length == 0) {
      this.dropdownDateCountyFilter = [];
      this.selectedItemsCounty = [];
      this.listOfCountyIds = [];
      this.selectedCity = [];
      this.dropdownDateCityFilter = [];
      this.listOfCityIds = [];
      this.selectedCity = [];
      this.dropdownDateCityFilter = [];
    }
    else
      this.GetCountyByStateId(this.stateId);
      this.MainGetAll();
    // this.MainGetAll();
  }

  onSelectAll($event) {
    this.resetPageNoandSize();
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetCountyByStateId(this.stateId);
    this.MainGetAll();
    // this.MainGetAll();
  }

  onDeSelectAllState($event) {
    this.resetPageNoandSize();
    this.listOfStateIds = [];
    this.listOfCountyIds = [];
    this.selectedItemsCounty = [];
    this.dropdownDateCountyFilter = [];
    this.listOfCityIds = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.selectedState=[]
    this.MainGetAll();
    // this.MainGetAll();
  }

  onSelectCounty($event) {
    this.resetPageNoandSize();
    this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
    this.listOfCountyIds.push($event.id);
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);
    this.MainGetAll();
  }

  onDeSelectCounty($event) {
    this.resetPageNoandSize();
    if (this.listOfCountyIds != null) {
      if (this.listOfCountyIds.length > 0) {
        this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
      }
    }
    this.countyId=' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    if (this.listOfCountyIds.length == 0) {
      this.listOfCityIds = [];
      this.selectedCity = [];
      this.dropdownDateCityFilter = [];
    }
    else
    this.GetCityByCountyId(this.countyId);
    this.MainGetAll();
  }

  onSelectAllCounty($event) {
    this.resetPageNoandSize();
    this.isShowprogressSpinner=true;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.countyId=' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);
 
    this.MainGetAll();
  }

  onDeSelectAllCounty($event) {
    this.resetPageNoandSize();
    this.isShowprogressSpinner=true;
    this.listOfCountyIds = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.MainGetAll();
    setTimeout(() => {
      this.isShowprogressSpinner=false;
    }, 2000);
  }


  onSelectCity($event) {
    this.resetPageNoandSize();
    this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
    this.listOfCityIds.push($event.id);
    this.cityId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }
   
    this.MainGetAll();
  }

  onDeSelectCity($event) {
    this.resetPageNoandSize();
    if (this.listOfCityIds != null) {
      if (this.listOfCityIds.length > 0) {
        this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
      }
    }
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }
    this.MainGetAll();
  }

  onSelectAllCity($event) {
    this.resetPageNoandSize();
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
          this.listOfCityIds.push($event[iCounter].id);
        }
        else {
          this.listOfCityIds.push($event[iCounter].id);
        }
      }
    }
   
    this.MainGetAll();
  }

  onDeSelectAllCity($event) {
    this.resetPageNoandSize();
    this.listOfCityIds = [];
    this.MainGetAll();
  }

  onChangeState(event) {
    this.resetPageNoandSize();
    this.dropdownDateCityFilter = [];
    this.dropdownDateCountyFilter = [];
    this.MainGetAll();
    if (event.id) this.GetCountyByStateId(event.id)
  }

  onDeSelectDateFilterAll($event) {
    this.resetPageNoandSize();
    this.selectedState = [];
    this.selectedCounty = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.dropdownDateCountyFilter = [];
    this.MainGetAll();
  }

  onDeSelectDateFilterCountyAll($event) {
    this.resetPageNoandSize();
    this.selectedCounty = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.MainGetAll();
  }

  onChangeCounty(event) {
    this.resetPageNoandSize();
    this.MainGetAll();
    if (event.id) this.GetCityByCountyId(event.id)
  }
  onChangeCity(event) {
    this.resetPageNoandSize();
    this.MainGetAll();
  }
  onDeSelectDateFilterCityAll($event) {
    this.resetPageNoandSize();
    this.selectedCity = [];
    this.MainGetAll();
  }
  GetCountyByStateId(stateId) {
    
    this.countyService.GetCountyByStateId(this.tokenKey, stateId,false).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyList = result['county'];
          this.dropdownDateCountyFilter = [];
          if (this.countyList.length > 0) {
            let b = [];
            this.selectedItemsCounty = [];
            this.countyList.forEach(x => {
              let a = this.listOfCountyIds.filter(e => e == x.countyId);
              if (a.length > 0) {
                b.push(x.countyId);
                this.selectedItemsCounty.push({ "id": x.countyId, "itemName": x.countyName,"stateName":x.stateName  })
              }
              this.dropdownDateCountyFilter.push({ "id": x.countyId, "itemName": x.countyName,"stateName":x.stateName  })
            });
            this.listOfCountyIds = b;
            if(this.listOfCountyIds.length>0){
              this.countyId = this.listOfCountyIds.join(',')
              this.GetCityByCountyId(this.countyId)
            }else{
              this.listOfCityIds = [];
              this.selectedCity = [];
              this.dropdownDateCityFilter = [];
            }
          }
          else {
            this.selectedItemsCounty = [];
            this.listOfCountyIds = [];
          }
        }
      });
  }
  GetCityByCountyId(countyId) {
    
    //const arr = countyId.split(',');
    
    this.cityService.GetCityByCountyIdMultiple(this.tokenKey, countyId,false).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.cityList = result['city'];
          this.dropdownDateCityFilter = [];
          this.dropdownDateCityFilter=this.cityList
          this.selectedCity = [];
          if (this.cityList.length > 0) {
            let b = [];
            this.selectedCity = [];
            this.listOfCityIds.forEach(x => {
              let a = this.cityList.filter(e => e.id == x);
              if (a.length > 0) {
                b.push(x);
                this.selectedCity.push(a[0])
              }
            });
            this.listOfCityIds = b;
          }
          else {
            this.selectedCity = [];
            this.listOfCityIds = [];
          }
        }
        this.isShowprogressSpinner=false;
        if(this.listOfCountyIds.length==0) {
          this.listOfCountyIds = [];
          this.selectedCity = [];
          this.dropdownDateCityFilter = [];
        }
      });
  }


  sort(sortBy: string) {
    this.upDowNameCSS = '';
    this.upDowStateNameCSS = '';
    this.upDowCountyNameCSS = '';
    this.upDowCityNameCSS = '';
    this.upDowScopeNameCSS = '';
    this.upDowFeeCSS = '';
    this.upDowRateNameCSS = '';
    this.upDowWhatTaxedCSS = '';
    this.upDowEffectiveDateCSS = '';
    this.upDowCitationNameCSS = '';
    this.upDowAuditDateCSS = '';
    this.upDowAuditMonthCSS = '';
    this.resetPageNoandSize();
    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';

      if (sortBy === "TaxName") {
        this.upDowNameCSS = 'active-up';
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active-up';
      }
      else if (sortBy === "CountyName") {
        this.upDowCountyNameCSS = 'active-up';
      }
      else if (sortBy === "CityName") {
        this.upDowCityNameCSS = 'active-up';
      }
      else if (sortBy === "ScopeID") {
        this.upDowScopeNameCSS = 'active-up';
      }
      else if (sortBy === "Rate") {
        this.upDowRateNameCSS = 'active-up';
      }
      else if (sortBy === "Fee") {
        this.upDowFeeCSS = 'active-up';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowEffectiveDateCSS = 'active-up';
      }
      else if (sortBy === "WhatTaxed") {
        this.upDowWhatTaxedCSS = 'active-up';
      }
      else if (sortBy === "citationName") {
        this.upDowCitationNameCSS = 'active-up';
      }
      else if (sortBy === "AuditDate") {
        this.upDowAuditDateCSS = 'active-up';
      }
      else if (sortBy === "AuditMonth") {
        this.upDowAuditMonthCSS = 'active-up';
      }
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      if (sortBy === "TaxName") {
        this.upDowNameCSS = 'active';
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active';
      }
      else if (sortBy === "CountyName") {
        this.upDowCountyNameCSS = 'active';
      }
      else if (sortBy === "CityName") {
        this.upDowCityNameCSS = 'active';
      }
      else if (sortBy === "ScopeID") {
        this.upDowScopeNameCSS = 'active';
      }
      else if (sortBy === "Rate") {
        this.upDowRateNameCSS = 'active';
      }
      else if (sortBy === "Fee") {
        this.upDowFeeCSS = 'active';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowEffectiveDateCSS = 'active';
      }
      else if (sortBy === "EndDate") {
        this.upDowEndDateCSS = 'active';
      }
      else if (sortBy === "WhatTaxed") {
        this.upDowWhatTaxedCSS = 'active';
      }
      else if (sortBy === "Notes") {
        this.upDowNotesCSS = 'active';
      }
      else if (sortBy === "citationName") {
        this.upDowCitationNameCSS = 'active';
      }
      else if (sortBy === "AuditDate") {
        this.upDowAuditDateCSS = 'active';
      }
      else if (sortBy === "AuditMonth") {
        this.upDowAuditMonthCSS = 'active';
      }
    }
    this.OrderByName = sortBy;
    this.MainGetAll();
  }

  addRate() {
    let navigation = '/admin/' + this.SelectedTool + '/rates/manage';
    this.router.navigate([navigation]);
  }

  updateRate(rateID) {
    let navigation = '/admin/' + this.SelectedTool + '/rates/manage/' + rateID;
    this.router.navigate([navigation]);
  }
  Export911RateLookup() {
    
    this.stateId = ' ';
    if (this.selectedState.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    else {
    this.showMessage('No rows to export!', 'Warning', 'warning')
  return
  }
  this.isloading = true;
    this.blockedDocument = true;
    this.isShowprogressSpinner = true;
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.cityId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.cityId =  this.listOfCityIds.join(',')
    }
    if (this.filterText == "null") this.filterText = " ";
    this.main911Service.Export911RateLookup(this.tokenKey, this.stateId, this.countyId, this.cityId, this.orderBy,true,this.filterText).subscribe(
      data => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.isloading = false;
        if (data != null) {

          if (data.status == 'Success') {
            this.filterModel.docPath = data.responseNewFilename;
            this.filterModel.docType = '911RateLookup Exports';
            this.i360service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
              fileData => {

                if (fileData != null) {
                  FileSaver.saveAs(fileData, '911RateLookupExports.csv');
                }
              }, error => {
                console.log(error);
              }
            );
          }
          else {
            this.toastr.error(data.status, 'Error');
          }
        }
      },
      error => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.isloading = false;
      }
    );
    
  }
  getColumn() {
    this.dropdownDateSelectedColumnFilter = [
      { "id": 1, "itemName": 'Jurisdiction' },
      { "id": 2, "itemName": 'County' },
      { "id": 3, "itemName": 'City' },
      { "id": 4, "itemName": 'Name' },
      { "id": 5, "itemName": 'Scope' },
      { "id": 6, "itemName": 'Rate' },
      { "id": 7, "itemName": 'Fee' },
      { "id": 8, "itemName": 'Who or What is Taxed' },
      { "id": 9, "itemName": 'Effective Date' },
      // { "id": 10, "itemName": 'Audit Month' },
      // { "id": 11, "itemName": 'Audit Date' },
      { "id": 10, "itemName": 'Citation Name' },
    ]
    this.selectedColumn=[{ "id": 1, "itemName": 'Jurisdiction' },
    { "id": 2, "itemName": 'County' },
    { "id": 3, "itemName": 'City' },
    { "id": 4, "itemName": 'Name' },
    { "id": 5, "itemName": 'Scope' },
    { "id": 6, "itemName": 'Rate' },
    { "id": 7, "itemName": 'Fee' },
    { "id": 8, "itemName": 'Who or What is Taxed' },
    { "id": 9, "itemName": 'Effective Date' },
    // { "id": 10, "itemName": 'Audit Month' },
    // { "id": 11, "itemName": 'Audit Date' },
    { "id": 10, "itemName": 'Citation Name' },
  ]
  }
  isCheckColum(id) {
    let data = this.selectedColumn.filter((x => x.id == id))[0];
    if (data != null)
      return true
    else return false
  }
  onSelectColumn($event) {
    this.isColumnExist=true
    this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event.id)
    this.listOfColumnId.push($event.id);
    if (this.listOfColumnId.length > 0) {
    }
  }

  onDeSelectColumn($event) {
    
    if (this.listOfColumnId != null) {
      if (this.listOfColumnId.length > 0) {
        this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event.id)
      }
    }
    if (this.selectedColumn.length > 0) {
            this.isColumnExist=true
          }
          else{
            this.isColumnExist=false
           }
  }

  onSelectAllColumn($event) {
    this.isColumnExist=true
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfColumnId != null) {
        if (this.listOfColumnId.length > 0) {
          this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event[iCounter].id)
          this.listOfColumnId.push($event[iCounter].id);
        }
        else {
          this.listOfColumnId.push($event[iCounter].id);
        }
      }
    }
  }
  onDeSelectAllColumn($event) {
    this.isColumnExist=false;
    this.selectedColumn=[];
    this.listOfColumnId = [];
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
  onChangePageSize(event) {
    if(this.selectedPageSize.length>0) this.PageSize=this.selectedPageSize[0].itemName;
    else{
      this.selectedPageSize=[];
      this.PageSize=30;
      this.selectedPageSize.push({ "id":1, "itemName": 30})
    }
    this.MainGetAll();
  }
  onDeSelectDateFilterPageSizeAll($event) {
    this.MainGetAll();
  }
    print(){ 
     
      let printContents, popupWin;
      printContents = document.getElementById('printareaDiv').innerHTML;
      popupWin = window.open('','PrintWindow','width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
      popupWin.document.open();
      popupWin.document.write(`
    <html>
      <head>
        <style>
        body{  width: 99%;}
          label { font-weight: 400;
                  font-size: 13px;
                  padding: 2px;
                  margin-bottom: 5px;
                }
          table, td, th {
                 border: 1px solid silver;
                  }
                  table td {
                 font-size: 13px;
                 word-wrap:break-word;
                  }

                   table th {
                 font-size: 13px;
                  }
            table {
                  border-collapse: collapse;
                  width: 98%;
                  table-layout:fixed;
                  }
              th {
                  height: 26px;
                  }
        </style>
      </head>
  <body onload="window.print();window.close()">${printContents}</body>
    </html>`
      );
      popupWin.document.close();

  }
  MainGetAllPrintData() {
    
    this.isloading = true;
    this.stateId = ' ';
    this.countyId = ' ';
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.cityId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.cityId =  this.listOfCityIds.join(',')
    }
    if (this.selectedState.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
      this.main911Service.RateLookupGetAll(this.tokenKey, 1, 1000000000,this._userID, this.stateId, this.countyId, this.cityId, this.orderBy,true,0,'').subscribe(
        data => {
          this.isloading = false;
          this.listofMain911PrintData = data['main'];
          if (this.listofMain911PrintData != null) {
            if (this.listofMain911PrintData.length == 0){
              this.isRecord = true;
            }
            else {
              this.isRecord = false;
            }
          }
          else{
            this.isRecord = true;
          }
          setTimeout(() => {
            this.print();
          }, 1000);
        },
        error => { }
      );
    }
    else{
      this.listofMain911PrintData=[];
      this.isRecord = true;
    }
  }
  getPageLength(){
    // if(this.PageSize<this.totalRecordCount)return this.PageSize
    // else return this.totalRecordCount
    return Math.min((this.PageNumber * this.PageSize),this.totalRecordCount);
   }
   setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }
  citationUrl(item){
    this.setAPIUrl()
if(item.citationName){
 if(item.citationDocName) window.open(this.AppUrl+'\CitationDocument\\'+item.citationDocName, '_blank')
 else if(item.citationURL) window.open(item.citationURL, '_blank')
}
  }
  getSubscriptionState() {
    
    this.stateService.i360ManageSubscriptionClientId(this.tokenKey, this._userID, 0,false).subscribe(
      data => {
        this.stateList = data.state911;
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name ,"shortName":x.shortName})
          });
        }
      },
      error => { console.log(error); }
    );
  }
  ExportCitiationSubscriptionGet() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.userService.ExportCitiationSubscriptionGetByUser(this.tokenKey,parseInt(this._userID),this.ProductId).subscribe(
      data => {
        
         this.exportCitiationList = data['exportCitiation'];
       
      },
      error => { }
    );
  }
   validExport(id){
    if(this.exportCitiationList.length>0){
     let data= this.exportCitiationList.find((x)=>x.exportCitiationId==id);
     if(data !=null)return true;
     else return false
    }
    else return false
  }
  isFeeAndRateValue(value){
   return Number(value).toFixed(2);
  }

  resetPageNoandSize()
  {
    this.PageNumber = 1;
    this.PageSize = 30;
  }

  clear() {
    this.stateId = '';
    this.countyId = ' ';
    this.cityId = ' ';
    
    this.listOfStateIds=[];
    this.resetPageNoandSize();
    this.listOfCountyIds = [];
    this.selectedItemsCounty = [];
    this.dropdownDateCountyFilter = [];
    this.listOfCityIds = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.selectedState = []
    this.MainGetAll();
  }

  AddToFavourite(item, IsFavourite: number) {
    let stateId = item.stateId;
    if (IsFavourite == 0) {
      this.SelectedStateFav = stateId;
      this.favouriteStateModel = item;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavourite(stateId, IsFavourite, item);
    }
  }

  SaveFavourite(stateId: string, IsFavourite: number, item)
  {
    this.blockDocument();
    document.getElementById("btnClose").click();
    this.favorite.ClientStateFavouriteAdd(this.tokenKey, this._userID, stateId, IsFavourite, ToolEnum.state911).subscribe(data => 
    {
      if (data["status"] == "Success") 
      {
        this.MainGetAll();
        if (IsFavourite == 1) {
          this.showMessage('Selected Jurisdiction added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          this.showMessage('Selected Jurisdiction removed successfully from favorite list.', 'Favorite', 'success');
        }        
      }
      else 
      {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
      }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
  }

  GetFavourite(displayFav:number)
  {
    this.displayFavorite=displayFav;
    this.MainGetAll();
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  onFilterSelectAll($event){
    this.resetPageNoandSize();
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetCountyByStateId(this.stateId);
    this.MainGetAll();
  }

  onFilterDeSelectAll($event){
    this.resetPageNoandSize();
    this.listOfStateIds = [];
    this.listOfCountyIds = [];
    this.selectedItemsCounty = [];
    this.dropdownDateCountyFilter = [];
    this.listOfCityIds = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.selectedState=[]
    this.MainGetAll();
  }
}
