import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientCompanyModel } from 'src/Models/ClientCompanyModel';
import { ProductModel } from 'src/Models/ProductModel';
import { UserModel } from 'src/Models/usersModel';
import { RouterExtService } from 'src/Services/RouterExtService';
import { StateMasterService } from 'src/Services/state-master.service';
import { UpdatesService } from 'src/Services/updatesService';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-user-auto-login',
  templateUrl: './user-auto-login.component.html',
  styleUrls: ['./user-auto-login.component.css']
})
export class UserAutoLoginComponent implements OnInit {

  userID: string;
  tokenKey: string = '';
  systemUserCondition: string = '';

  listOfUser: UserModel[];
  statusCode: any;
  PageNumber: number;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  totalRecordCount: number;
  isloading: boolean = false;
  keywordFilter: string = '';

  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;

  clientCompanyList: ClientCompanyModel[];
  dropdownListclientCompany = [];
  selectedItemsclientCompany = [];
  dropdownSettingclientCompany = {};
  listOfCompany = [];

  listOfProduct?: string;

  upDowNoteCSS: string = '';
  upDowfundNameCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDowFundTypeCSS: string = '';
  isAscending: boolean = true;
  isRecord: boolean = false;
  isSearchVisible: boolean = false;
  baseUrl:string='';

  constructor(@Inject(Window) private win: Window, private router: Router, private checkAuth: CheckAuth
    , private userService: UsersService, private titleService: Title, private stateServices: StateMasterService
    , private routerService: RouterExtService, private toastr: ToastrService, private updatesService: UpdatesService,
    @Inject('BASE_URL') baseUrl: string) {
    this.userID = sessionStorage.getItem('UserId');

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.systemUserCondition = null;
    this.titleService.setTitle('INTERNAL - Subscriber Login');
    this.baseUrl = baseUrl;
    this.dropdownSettingclientCompany = {
      singleSelection: true,
      text: "Filter by Company(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No company available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };

    this.listOfUser = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'UserId DESC';
    this.totalRecordCount = -1;
  }

  ngOnInit() {
    this.GetClientCompany();
  }

  GetClientCompany() {
    this.clientCompanyList = [];
    this.blockDocument();
    this.updatesService.GetClientCompany(this.tokenKey, 0, this.systemUserCondition).subscribe(
      data => {
        this.unblockDocument();
        if (data['status'] == 'Success') {
          this.clientCompanyList = data['clientCompanay'];

          if (this.clientCompanyList != null) {
            if (this.clientCompanyList.length > 0) {
              for (let iCounter = 0; iCounter < this.clientCompanyList.length; iCounter++) {
                this.dropdownListclientCompany.push(
                  { "id": this.clientCompanyList[iCounter].companyId, "itemName": this.clientCompanyList[iCounter].companyName }
                );
              }
            }
          }

          if (this.listOfCompany != null && this.listOfCompany != undefined) {
            for (let iCounter = 0; iCounter < this.listOfCompany.length; iCounter++) {
              if (this.listOfCompany[iCounter] != '') {
                let obj = data["clientCompanay"].filter(m => m.companyId == this.listOfCompany[iCounter]);
                if (obj != null) {
                  this.selectedItemsclientCompany.push({ "id": obj[0].companyId, "itemName": obj[0].companyName });
                }
              }
            }
          }
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  onSelectedCompany($event) {
    this.PageNumber = 1;
    let companyId = $event.id;
    this.listOfCompany = [];
    this.listOfCompany.push($event.id);
    // if (this.listOfCompany != null) {
    //   if (this.listOfCompany.length > 0) {
    //     this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
    //     this.listOfCompany.push($event.id);
    //   }
    //   else {
    //     this.listOfCompany.push($event.id);
    //   }
    // }
    this.isSearchVisible = true;
    this.GetListOfUser();
  }

  onDeSelectedCompany($event) {
    this.PageNumber = 1;
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
      }
    }
    this.isSearchVisible = false;
    this.keywordFilter = '';
    this.GetListOfUser();
  }

  onSelectAllCompany($event) {
    this.PageNumber = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
    this.isSearchVisible = true;
    this.GetListOfUser();
  }

  onDeSelectAllCompany($event) {
    this.PageNumber = 1;
    this.listOfCompany = [];
    this.isSearchVisible = false;
    this.keywordFilter = '';
    this.GetListOfUser();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  GetListOfUser() {
    this.isloading = true;
    // this.isRecord=false;
    let selectedcompany = this.listOfCompany.join(',');
    if (selectedcompany != '') {
      this.userService.GetUsers(this.tokenKey, this.PageNumber, this.PageSize, '', this.OrderBy, this.keywordFilter, this.userID, '', selectedcompany, null,null).subscribe(
        data => {
          this.isloading = false;
          this.listOfUser = data['usersModel'];
          if (this.listOfUser != null) {
            if (this.listOfUser.length > 0) {
              this.isRecord = true;
              this.totalRecordCount = data['usersModel'][0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
        },
        error => {
          this.statusCode = error
          this.isloading = false;
        }
      );
    } else {
      this.isRecord = false;
      this.listOfUser = null;
      this.isloading = false;
      this.totalRecordCount = 0;
    }
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  showi360LoginButton(listOfProducts: ProductModel[]) {
    if (listOfProducts && listOfProducts.length > 0) {
      return (listOfProducts.find(x => x.productId == 1) != null ||
        listOfProducts.find(x => x.productId == 4) != null ||
        listOfProducts.find(x => x.productId == 3) != null ||
        listOfProducts.find(x => x.productId == 7) != null || listOfProducts.find(x => x.productId == 8) != null ||
        listOfProducts.find(x => x.productId == 9) != null || listOfProducts.find(x => x.productId == 14) != null ||
        listOfProducts.find(x => x.productId == 22) != null || listOfProducts.find(x => x.productId == 23) != null ||
        listOfProducts.find(x => x.productId == 24) != null)
    }
    return false;
  }

  redirectToClientLogini360(userId, userLoginUrl) {
    const url = 'userclientlogin/' + this.userID + '/' + userId;
    userLoginUrl += userLoginUrl.indexOf("#") > -1 ? url : "#/" + url;
    this.win.open(userLoginUrl,"_blank");
    // this.router.navigate(['/Custom-Product/' + custommenu.customProductId]);
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowNoteCSS = '';
      this.upDowfundNameCSS = '';
      this.upDowStateNameCSS = '';
      this.upDowFundTypeCSS = '';
      this.upDowUpdateDateCSS = '';
      if (sortBy === "Note") {
        this.upDowNoteCSS = 'active-up';
      }
      else if (sortBy === "fundName") {
        this.upDowfundNameCSS = 'active-up';
      }
      else if (sortBy === "FundTypeName") {
        this.upDowFundTypeCSS = 'active-up';
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active-up';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowUpdateDateCSS = 'active-up';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;
      this.upDowNoteCSS = '';
      this.upDowfundNameCSS = '';
      this.upDowStateNameCSS = '';
      this.upDowFundTypeCSS = '';
      this.upDowUpdateDateCSS = '';
      if (sortBy === "Note") {
        this.upDowNoteCSS = 'active';
      }
      else if (sortBy === "fundName") {
        this.upDowfundNameCSS = 'active';
      }
      else if (sortBy === "FundTypeName") {
        this.upDowFundTypeCSS = 'active';
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowUpdateDateCSS = 'active';
      }
    }
    this.OrderBy = sortBy;
    this.GetListOfUser();
  }

  getPageLength() {
    // if (this.PageSize < this.totalRecordCount) return this.PageSize
    // else return this.totalRecordCount
    return Math.min((this.PageNumber * this.PageSize), this.totalRecordCount);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetListOfUser();
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null) { this.keywordFilter = $event.target.value; }
    else { this.keywordFilter = 'null'; }

    this.GetListOfUser();
  }

}
