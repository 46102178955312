import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { url } from 'inspector';
import { dragDropServiceFactory } from 'ng2-dnd';
import { ToastrService } from 'ngx-toastr';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-carrier-obligation-add-update',
  templateUrl: './carrier-obligation-add-update.component.html',
  styleUrls: ['./carrier-obligation-add-update.component.css']
})
export class CarrierObligationAddUpdateComponent implements OnInit {

  ObligationMasterForm: FormGroup;
  userId: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  ckeConfig: any;
  isEdit: boolean = false;

  //CarrierType
  dropdownSettingsCarrierFilter = {};
  dropdownCarrierFilter = [];
  selectedCarrierItems = [];
  listOfCarrierTypeIDs = [];
  carrierTypeList: any = [];
  listOfCarrierTypeName = [];

  //CategoryType
  dropdownSettingsCategoryFilter = {};
  dropdownCategoryFilter = [];
  selectedCategoryItems = [];
  listOfCategoryTypeIDs = [];
  categoryTypeList: any = [];
  listOfCategoryTypeName = [];
  companyId:number;
  isInteserraUser: boolean = false;
  isDuplicate: boolean = false;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute,
    private toastr: ToastrService, private router: Router, private carrierObligationService: CarrierObligationService,private datePipe: DatePipe,private titleService: Title) {
      this.isDuplicate = sessionStorage.getItem('isDuplicate') == 'true' ? true : false;
    if (sessionStorage.getItem('isDuplicate') != null)
      sessionStorage.removeItem('isDuplicate');

    this.titleService.setTitle('View 47');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.ckeConfig = {
      height: '100px', // Set the height here
      versionCheck:false,
      toolbar: [
        {
          items:
            [
              'Undo', 'Redo', 'Link', 'Unlink', 'Anchor',
              'Image', 'Table', 'SpecialChar', 'Maximize', 'Source', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat',
              'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Styles', 'Format', 'Font'
            ]
        }],
    }
    
    this.dropdownSettingsCarrierFilter = {
      singleSelection: false,
      text: "Select Carrier Type(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 5,
      noDataLabel: 'No Carrier Type(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsCategoryFilter = {
      singleSelection: false,
      text: "Select Category Type(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Category Type(s) available',
      showCheckbox: true
    };

    this.ObligationMasterForm = this._fb.group({
      obligationId: 0,
      carrierTypeId: [0],
      categoryTypeId: [0],
      title: ['', Validators.required],
      details: ['', Validators.required],
      pendingactivity: [''],
      dueDate: [''],
      internalnotes: [''],
      keyterms: [''],      
      relatedforfeitures: [''],
      auditornotes:[''],
      cfr: ['', Validators.required],
      url: [''],
      ch: [''],
      subch: [''],
      part: [''],
      subpart: [''],
      sortorder:[''],
      publishdate: ['', Validators.required],
      checklist:[''],
      lastaudit:['']
    })

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    if(this.SelectedTool == 'view 47'){
      this.SelectedTool = 'view47';
    }
    
    this.getcompanyId();
    this.getCarrierType();
    this.getCategoryType();

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          if (this.isEdit = true) {
            this.dropdownSettingsCategoryFilter = {
              singleSelection: false,
              text: "Select Category Type",
              enableSearchFilter: true,
              classes: "borderLessTextBoxGreen",
              badgeShowLimit: 2,
              noDataLabel: 'No Category Type(s) available',
              showCheckbox: true
              
            };
          }
          this.GetObligationById(params['id']);
        }
      }
    });

  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  getcompanyId(){
    this.carrierObligationService.getCompanyIdByUserId(this.tokenKey,this.userId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.companyId = result['companyId'];
          if(this.companyId == 19){
            this.isInteserraUser=true;
          }
          else{
            this.isInteserraUser=false;
          }
        }
      },
      error => { }
    );
  }

  getCarrierType() {
    this.carrierObligationService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierTypeList = result['carrier'];
          this.dropdownCarrierFilter = [];
          this.carrierTypeList.forEach(x => {
            this.dropdownCarrierFilter.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCarrierType($event) {
    this.listOfCarrierTypeIDs = this.removeElemetninArray(this.listOfCarrierTypeIDs, $event.id)
    this.listOfCarrierTypeName = this.removeElemetninArray(this.listOfCarrierTypeName, $event.itemName)
    this.listOfCarrierTypeIDs.push($event.id);
    this.listOfCarrierTypeName.push($event.itemName);
  }

  onDeSelectCarrierType($event) {

    if (this.listOfCarrierTypeIDs != null) {
      if (this.listOfCarrierTypeIDs.length > 0) {
        this.listOfCarrierTypeIDs = this.removeElemetninArray(this.listOfCarrierTypeIDs, $event.id)
      }
    }

    if (this.listOfCarrierTypeName != null) {
      if (this.listOfCarrierTypeName.length > 0) {
        this.listOfCarrierTypeName = this.removeElemetninArray(this.listOfCarrierTypeName, $event.itemName)
      }
    }
  }

  onSelectAllCarrierType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierTypeIDs != null) {
        if (this.listOfCarrierTypeIDs.length > 0) {
          this.listOfCarrierTypeIDs = this.removeElemetninArray(this.listOfCarrierTypeIDs, $event[iCounter].id)
          this.listOfCarrierTypeIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierTypeIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCarrierTypeName != null) {
        if (this.listOfCarrierTypeName.length > 0) {
          this.listOfCarrierTypeName = this.removeElemetninArray(this.listOfCarrierTypeName, $event[iCounter].itemName)
          this.listOfCarrierTypeName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCarrierTypeName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllCarrierType($event) {
    this.listOfCarrierTypeIDs = [];
    this.listOfCarrierTypeName = [];
  }

  getCategoryType() {
    this.carrierObligationService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryTypeList = result['category'];
          this.dropdownCategoryFilter = [];
          this.categoryTypeList.forEach(x => {
            this.dropdownCategoryFilter.push({ "id": x.categoryTypeId, "itemName": x.categoryName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCategoryType($event) {
    this.listOfCategoryTypeIDs = this.removeElemetninArray(this.listOfCategoryTypeIDs, $event.id)
    this.listOfCategoryTypeName = this.removeElemetninArray(this.listOfCategoryTypeName, $event.itemName)
    this.listOfCategoryTypeIDs.push($event.id);
    this.listOfCategoryTypeName.push($event.itemName);
  }

  onDeSelectCategoryType($event) {

    if (this.listOfCategoryTypeIDs != null) {
      if (this.listOfCategoryTypeIDs.length > 0) {
        this.listOfCategoryTypeIDs = this.removeElemetninArray(this.listOfCategoryTypeIDs, $event.id)
      }
    }

    if (this.listOfCategoryTypeName != null) {
      if (this.listOfCategoryTypeName.length > 0) {
        this.listOfCategoryTypeName = this.removeElemetninArray(this.listOfCategoryTypeName, $event.itemName)
      }
    }
  }

  onSelectAllCategoryType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryTypeIDs != null) {
        if (this.listOfCategoryTypeIDs.length > 0) {
          this.listOfCategoryTypeIDs = this.removeElemetninArray(this.listOfCategoryTypeIDs, $event[iCounter].id)
          this.listOfCategoryTypeIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryTypeIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCategoryTypeName != null) {
        if (this.listOfCategoryTypeName.length > 0) {
          this.listOfCategoryTypeName = this.removeElemetninArray(this.listOfCategoryTypeName, $event[iCounter].itemName)
          this.listOfCategoryTypeName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCategoryTypeName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllCategoryType($event) {
    this.listOfCategoryTypeIDs = [];
    this.listOfCategoryTypeName = [];
  }

  GetObligationById(ObligationId) {
    this.carrierObligationService.GetObligationById(this.tokenKey, parseInt(ObligationId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.ObligationMasterForm.patchValue({
            obligationId: result['obligation'].obligationId,
            carrierTypeId: result['obligation'].carrierTypeId,
            categoryTypeId: result['obligation'].categoryTypeId,
            title: result['obligation'].title,
            details: result['obligation'].details,
            pendingactivity: result['obligation'].pendingactivity,
            dueDate: result['obligation'].dueDate,
            internalnotes: result['obligation'].internalnotes,
            keyterms: result['obligation'].keyterms,
            relatedforfeitures: result['obligation'].relatedforfeitures,
            auditornotes:result['obligation'].auditornotes,
            cfr: result['obligation'].cfr,
            url: result['obligation'].url,
            ch: result['obligation'].ch,
            subch: result['obligation'].subCh,
            part: result['obligation'].part,
            subpart: result['obligation'].subPart,
            sortorder:result['obligation'].sortOrder,
            publishdate:result['obligation'].publishdate,
            checklist:result['obligation'].checklist,
            lastaudit:result['obligation'].lastAudit,
          });

          if (this.carrierTypeList.length > 0) {
            this.selectedCarrierItems = []
            this.listOfCarrierTypeIDs = []
            this.listOfCarrierTypeName = []
            for (let iCounter = 0; iCounter < result['obligation'].carrierTypeId.length; iCounter++) {
              let data = this.carrierTypeList.filter(x => x.carrierTypeId == result['obligation'].carrierTypeId[iCounter].carrierTypeId)[0];
              this.selectedCarrierItems.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
              this.listOfCarrierTypeIDs.push(data.carrierTypeId)
              this.listOfCarrierTypeName.push(data.carrierName)
            }
          }
          else
            setTimeout(() => {
              if (result['obligation'].carrierTypeId.length > 0) {
                this.selectedCarrierItems = []
                this.listOfCarrierTypeIDs = []
                this.listOfCarrierTypeName = [];
                for (let iCounter = 0; iCounter < result['obligation'].carrierTypeId.length; iCounter++) {
                  let data = this.carrierTypeList.filter(x => x.carrierTypeId == result['obligation'].carrierTypeId[iCounter].carrierTypeId)[0];
                  this.selectedCarrierItems.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
                  this.listOfCarrierTypeIDs.push(data.carrierTypeId)
                  this.listOfCarrierTypeName.push(data.carrierName)
                }
              }
            }, 500);

          // Category
          if (this.categoryTypeList.length > 0) {
            this.selectedCategoryItems = []
            this.listOfCategoryTypeIDs = []
            this.listOfCategoryTypeName = []
            for (let iCounter = 0; iCounter < result['obligation'].categoryTypeId.length; iCounter++) {
              let data = this.categoryTypeList.filter(x => x.categoryTypeId == result['obligation'].categoryTypeId[iCounter].categoryTypeId)[0];
              this.selectedCategoryItems.push({ "id": data.categoryTypeId, "itemName": data.categoryName })
              this.listOfCategoryTypeIDs.push(data.categoryTypeId)
              this.listOfCategoryTypeName.push(data.categoryName)
            }
          }
          else
            setTimeout(() => {
              if (result['obligation'].categoryTypeId.length > 0) {
                this.selectedCategoryItems = []
                this.listOfCategoryTypeIDs = []
                this.listOfCategoryTypeName = [];
                for (let iCounter = 0; iCounter < result['obligation'].categoryTypeId.length; iCounter++) {
                  let data = this.categoryTypeList.filter(x => x.categoryTypeId == result['obligation'].categoryTypeId[iCounter].categoryTypeId)[0];
                  this.selectedCategoryItems.push({ "id": data.categoryTypeId, "itemName": data.categoryName })
                  this.listOfCategoryTypeIDs.push(data.categoryTypeId)
                  this.listOfCategoryTypeName.push(data.categoryName)
                }
              }
            }, 1000);
        }
      }
    );
  }

  save() {
    this.isSubmit = true;
    let data = this.ObligationMasterForm.value;
    if (!this.ObligationMasterForm.valid || !data.title.trim() || !data.details.trim() || this.selectedCarrierItems.length == 0 || this.selectedCategoryItems.length == 0 || !data.cfr.trim() || !data.publishdate.trim())
      return
    data["createdBy"] = this.userId;
    let userId = this.userId;
    let carrierId = this.listOfCarrierTypeIDs.join(',');
    let carrierName = this.listOfCarrierTypeName.join(',');
    let categoryId = this.listOfCategoryTypeIDs.join(',');
    let categoryName = this.listOfCategoryTypeName.join(',');

    let boolChk=false;

    if(data.checklist!='' && data.checklist!=null){
      if(data.checklist==true){
        boolChk=true;
      }
      else{
        boolChk=false;
      }
      
    }
    else{
      boolChk=false;
    }

    data.obligationId = this.isDuplicate ? 0 : data.obligationId;

    let value = {
      obligationId: data.obligationId,
      carrierTypeId: carrierId,
      categoryTypeId: categoryId,
      subCarriertype: "",
      title: data.title,
      details: data.details,
      pendingactivity: data.pendingactivity,
      dueDate: data.dueDate == null ? null : data.dueDate.trim(),
      internalnotes: data.internalnotes == null ? null : data.internalnotes.trim(),
      keyterms: data.keyterms == null ? null : data.keyterms.trim(),
      relatedforfeitures: data.relatedforfeitures == null ? null : data.relatedforfeitures.trim(),
      auditornotes: data.auditornotes == null ? null : data.auditornotes.trim(),
      cfr: data.cfr == null ? null : data.cfr.trim(),
      url: data.url == null ? null : data.url,
      ch: data.ch == null ? null : data.ch.trim(),
      subch: data.subch == null ? null : data.subch.trim(),
      part: data.part == null ? null : data.part.trim(),
      subpart: data.subpart == null ? null : data.subpart.trim(),
      cfrsection: null,
      sortorder:data.sortorder == null ? null : data.sortorder.trim(),
      publishdate: data.publishdate == null ? null : data.publishdate.trim(),
      carrierTypeName: carrierName,
      categoryTypeName: categoryName,
      userId: userId,
      checklist:boolChk,
      lastaudit: this.datePipe.transform(data.lastaudit, 'yyyy-MM-dd hh:mm:ss'),  
    }

    this.carrierObligationService.ObligationAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.obligationId > 0)
              this.showMessage('Obligation updated successfully', 'Success', 'success');
            else
              this.showMessage('Obligation saved successfully', 'Success', 'success');

              let rurl=sessionStorage.getItem("callbackurl");

              if(rurl!='' && rurl!=null && rurl!=undefined){
                this.router.navigate(['/admin/' + this.SelectedTool + '/'+rurl]);
              }
              else{
                this.router.navigate(['/admin/' + this.SelectedTool + '/content']);
              }
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  cancelObligation() {
    let rurl=sessionStorage.getItem("callbackurl");
    let navigation = '/admin/' + this.SelectedTool + '/'+rurl;
    
    this.router.navigate([navigation]);
  }
}
