export class ObligationUpdateGetModel {
    updateId: number;
    note: string;
    updateDate: string|Date;
    carrierName: string;
    carrierId:number;
    shortName:string;
    totalRecordCount: number;
    isFavorite:boolean;
    userId:number;
    Carrierinfo: any;
    cfr:string;
}

export class FilterObligationUpdateModel {
    pageNumber?: number;
    pageSize?: number;
    orderBy?: string;
    carrierId?: string | number;
    userId?:number;
    isFavorite?:number;
    fromDate?: string;
    toDate?: string;
    Note?:string;
}


export class CarrierFavoriteFilterModel {
    carrierTypeId?: string;
    clientId?:number;
    isFavourite?:number;
}