import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MenuMasterModel } from 'src/Models/MenuMasterModel';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  AppUrl: string = "";
  constructor(private _http: Http, private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  i360MenuGroupAdminGet(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Menu/i360MenuGroupAdminGet/';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360MenuItems(TokenKey: string, menuGroupId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Menu/i360MenuItems/' + menuGroupId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360MenuGetBydId(TokenKey: string, menuId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Menu/i360MenuGetBydId/' + menuId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360MenuMasterUpdate(TokenKey: string, menuMaster: MenuMasterModel): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Menu/i360MenuMasterUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, menuMaster, httpOptions)
      .pipe(catchError(this.handleError));
  }
  
  i360BriefingMasterFindbyBriefingId(TokenKey: string, filterModel: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Menu/i360BriefingMasterFindbyBriefingId';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
        .pipe(catchError(this.handleError));
}

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
