import { Component, OnInit } from '@angular/core';
import { ActionItemService } from '../../../../Services/actionitem.service';
import { LandingPageModel } from '../../../../Models/landingPageModel';
import { CheckAuth } from 'src/Services/utility.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  ckeConfig:any;
  tokenKey: string = '';
  objLandingPageModel = new LandingPageModel();
  editorData = ''
  loginuserID: number;
  description = '';
  constructor(private titleService: Title, private toastr: ToastrService, private _fb: FormBuilder, private actionItemService: ActionItemService, private checkAuth: CheckAuth) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.titleService.setTitle('IRIS Home');
  }
  ngOnInit() {
    this.ckeConfig = {
      versionCheck:false,
      toolbar: [
        { items:
          [
          'Undo','Redo','Link','Unlink','Anchor',
          'Image','Table','SpecialChar','Maximize','Source','Bold','Italic','Underline','Strike','RemoveFormat',
          'NumberedList','BulletedList','Outdent','Indent','Styles','Format','Font'
          ]
        }],
    }
    this.GetLandingPageDetails();
  }

  GetLandingPageDetails() {
    this.actionItemService.GetLandingPageDetails(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        this.objLandingPageModel = data.result;
      }
    });

  }

  saveLandingPageDetails() {

    if (!this.objLandingPageModel.title) {
      this.showMessage('Please enter title.', 'Required', 'error');
      return;
    }
    if (!this.objLandingPageModel.description) {
      this.showMessage('Please enter description.', 'Required', 'error');
      return;
    }
    else {
      this.objLandingPageModel.userid = this.loginuserID;
      this.actionItemService.SaveLandingPageDetails(this.tokenKey, this.objLandingPageModel).subscribe(data => {
        if (data['status'] !== 'Failure') {
          this.showMessage('Details Saved Successfully.', 'Success', 'success');
        }
        else {
          this.showMessage('Issue In Saving Your Details.', 'Error', 'error');
        }
      });
    }
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

}
