import { Component, OnInit, Inject } from '@angular/core';
import { StateMasterModel } from '../../../../Models/stateMasterModel';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { AppConfig } from '../../../../Services/app.config';
import { CheckAuth } from '../../../../Services/utility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { EnumFavorites, ToolEnum } from 'src/Models/Common';
import { FavoriteService } from 'src/Services/favorite.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { FavoriteFilterModel } from 'src/Models/FavoriteFilterModel';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-my-favorites',
  templateUrl: './my-favorites.component.html',
  styleUrls: ['./my-favorites.component.css']
})
export class MyFavoritesComponent implements OnInit {
  appModel: any;
  isloading: boolean = false;
  tokenKey: string = '';
  favoritekey: string = '';
  favouriteStateModel: FavoriteFilterModel[] ;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  carrierForm: FormGroup;
  baseUrl: string;

  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  stateFilter: string = '';


  listOfstateID: string[] = [];
  ClientSelectedTool: string;
  ValidationMessage: string;
  DeSelectedFavoriteId: number;
  FavoriteType: string;
  IsDeselected: number = 0;
  isStateRecordsExists: boolean = true;
  productId: number;
  module = '911';
  dropdownSettingsDateFilter = {};
  stateId: any;
  _userID: any;
  ProductId: number = 0;
  stateSubscription: any;
  stateList: StateMasterModel[] = [];
  TRFAstateId: string;
  statefilterData: any;
  listOfStateIds = [];
  selectedState = [];
  stateIdList: string = '';
  SelectedStateFav: number = 0;
  display:string='';


  constructor(@Inject('BASE_URL') baseUrl: string
    , private stateService: StateMasterService
    , private favorite: FavoriteService, private toastr: ToastrService
    , private titleService: Title, private checkAuth: CheckAuth
    , private router: Router, private activedRoute: ActivatedRoute) {
    this.baseUrl = baseUrl;
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = +sessionStorage.getItem('UserId');
    this.appModel = AppConfig.settings.ConnectionStrings;

    this.carrierForm = new FormGroup({
      stateId: new FormControl(),
      carrierId: new FormControl(),
    });
    this.ClientSelectedTool = sessionStorage.getItem('ClientLoginTool');
    this.ProductId = ToolEnum.state911;
    this.titleService.setTitle('My Favorites');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.dropdownSettingsDateFilter = {
      singleSelection: false,
      text: "Select Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    //this.GetsdClientFavorites();
    this.GetClientFavorites();
  }


  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  // State ---------------------------
  OnSelectState($event) {
    this.IsDeselected = 0;
    this.SaveFavoriteState($event['id'], 1);
  }
  OnDeSelectState($event) {
    this.IsDeselected = 0;
    this.SaveFavoriteState($event['id'], 0);
  }

  onSelectAllStateList($event) {
    let stateId: any;
    if ($event) {
      $event.forEach(x => {
        stateId += String(x.id) + ',';
      });
      stateId += "-99";
    }
    this.IsDeselected = 0;
    this.SaveFavoriteState(stateId, 1);
  }

  onDeSelectAllStateList($event) {
    if (this.favouriteStateModel != null) {
      let stateId: any;
      this.favouriteStateModel.forEach(x => {
        stateId += String(x.stateID) + ',';
      });
      stateId += "-99";
      this.IsDeselected = 0;
      this.SaveFavoriteState(stateId, 0);
    }
  }

  AddToFavourite(state, IsFavourite: number) {
    let stateId = state.stateID;
    if (IsFavourite == 0) {
      this.SelectedStateFav = stateId;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavoriteState(stateId, IsFavourite);
    }
  }

  SaveFavoriteState(stateId: string, IsFavourite: number) {
    // document.getElementById("btnClose").click();
    this.favorite.ClientStateFavouriteAdd(this.tokenKey, this._userID, stateId, IsFavourite, ToolEnum.state911).subscribe(data => {
      if (data["status"] == "Success") {
        this.GetClientFavorites();
        if (IsFavourite == 1) {
          this.showMessage('Selected Jurisdiction added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          this.showMessage('Selected Jurisdiction removed successfully from favorite list.', 'Favorite', 'success');
        }
        
      }
      else {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
   
  }

  // GetsdClientFavorites(): void {    
  //   this.favorite.GetFavoriteStateByUser(this.tokenKey, this._userID, ToolEnum.state911).subscribe(
  //     data => {        
  //       if (data["main"] != null) {          
         
  //         this.dropdownListState = [];
  //         if (data["main"].length > 0) {
  //           data["main"].forEach(x => {
  //             this.dropdownListState.push({ "id": x.stateID, "itemName": x.name })
  //             this.listOfStateIds.push(x.stateID);
  //             if (x.isActive == true) {
  //               this.selectedItemsState.push({ "id": x.stateID, "itemName": x.name });
  //             }
  //           });

  //           if (this.listOfStateIds.length > 0) {
  //             this.stateIdList = this.listOfStateIds.join(',')
  //           }

  //           this.dropdownSettingsDateFilter = {
  //             singleSelection: false,
  //             text: "Select State",
  //             enableSearchFilter: true,
  //             classes: "borderLessTextBoxGreen",
  //             badgeShowLimit: 1,
  //             noDataLabel: 'No State(s) available',
  //             showCheckbox: true
  //           };          

  //         }            
  //       }
  //       else {
  //         this.showMessage(data["message"], 'Error', 'error');
  //         this.unblockDocument();
  //       }
  //     }, error => {
  //       this.showMessage(error, 'Error', 'error');
  //       this.unblockDocument();
  //     }
  //   )
    
  // }


  GetClientFavorites(): void {
    
    this.favorite.GetFavoriteStateByUser(this.tokenKey, this._userID, ToolEnum.state911).subscribe(
      data => {
        
        if (data["main"] != null) {          
          this.favouriteStateModel = data["main"];
          this.dropdownListState = [];
          this.listOfStateIds=[];
          this.selectedItemsState=[];

          if (this.favouriteStateModel.length > 0) {
            this.favouriteStateModel.forEach(x => {
              this.dropdownListState.push({ "id": x.stateID, "itemName": x.name })
              this.listOfStateIds.push(x.stateID);
              if (x.isActive == true) {
                this.selectedItemsState.push({ "id": x.stateID, "itemName": x.name });
              }
            });

            if (this.listOfStateIds.length > 0) {
              this.stateIdList = this.listOfStateIds.join(',')
            }
          }
          if (this.favouriteStateModel == null)
            this.isStateRecordsExists = false;
          else
            this.isStateRecordsExists = true;           
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
          this.unblockDocument();
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      }
    )   
  }
  
}
