import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UpdateAuditmonth } from 'src/Models/AuditMonthModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { AuditMonthService } from 'src/Services/audit-month.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-audit-month',
  templateUrl: './audit-month.component.html',
  styleUrls: ['./audit-month.component.css']
})
export class AuditMonthComponent implements OnInit {

  SelectedTool: string;
  tokenKey: any;
  updateAuditmonthModel : UpdateAuditmonth;
  stateList: StateMasterModel[] = [];
  dropdownDateFilter = [];
  module = '911';
  rate911auditmonthForm: FormGroup;
  selectedState = [];
  dropdownSettingsStateFilter = {};
  
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, 
    private router: Router, private stateService: StateMasterService, private datePipe: DatePipe,
    private auditMonthUpdateservice : AuditMonthService, private toastr: ToastrService, private titleService: Title
  ) {
    this.updateAuditmonthModel = {
      selectedStateId: 0,
      auditMonth: '',
    };
    this.titleService.setTitle('Audit Month Bulk Update');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
  }

  ngOnInit() {
    this.rate911auditmonthForm = this._fb.group({
      stateId: [0],
      auditMonth: ['']
    });
    this.dropdownSettingsStateFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      disabled: false,
      enableFilterSelectAll: false
    };
  }
  
  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownDateFilter = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownDateFilter.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  cancel() {
      let navigation = '/admin/' + this.SelectedTool + '/dashboard';
      this.router.navigate([navigation]);
  }

  onChangeState(event) {
    this.selectedState = event ? [event] : [];
  }

  onDeSelectDateFilterAll($event) {
    this.selectedState = [];
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  UpldateAuditMonth() {
    const auditMonthValue = this.rate911auditmonthForm.controls["auditMonth"].value;
    if (this.rate911auditmonthForm.invalid || !this.selectedState || this.selectedState.length === 0 || auditMonthValue == null || auditMonthValue == '' || auditMonthValue == undefined) {
      if(!this.selectedState || this.selectedState.length === 0){
        this.showMessage("Please Select State", 'Warning', 'error');
      }
      if(auditMonthValue == null || auditMonthValue == '' || auditMonthValue == undefined){
        this.showMessage("Please Select Audit Month", 'Warning', 'error');
      }
      return;
    } else {
      this.updateAuditmonthModel.selectedStateId = Number(this.selectedState[0].id);
      this.updateAuditmonthModel.auditMonth = this.datePipe.transform(this.rate911auditmonthForm.controls["auditMonth"].value, 'yyyy-MM');
      this.auditMonthUpdateservice.auditMonthUpdate(this.tokenKey, this.updateAuditmonthModel).subscribe(
        result => {
          if (result['status'] == 'Success') {
            if(result['message'] !=""){
              this.showMessage(result.message, 'Success', 'success');
            }else{
              this.showMessage(result.message, 'Warning', 'error');
            }
            this.resetForm();
            // setTimeout(() => {
            //   this.router.navigate(['/admin/'+this.SelectedTool+'/rates']);
            // }, 1000);
          }
          else {
            this.showMessage(result['status'], 'Error', 'error');
          }
        },
        error => {
          this.showMessage(error, 'Error', 'error');
        }
      );
    }
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  resetForm(){
    this.selectedState = [];
    this.rate911auditmonthForm.reset({
      stateId: 0,
      auditMonth: '' 
    });
  }
}
