import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { StateSelectionModel } from 'src/Models/StateSelectionModel';
import { AddOnServicesModel, UpdatedBaseRateModel } from 'src/Models/UpdatedBaseRateModel';
import { PriceCalculationModel } from 'src/Models/PriceCalculationModel';
import { AddUpdateOngoingComplianceModel } from 'src/Models/OngoingComplianceModel';

@Injectable({
  providedIn: 'root'
})
export class PricingQuotesService {

  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllPricingQuotes(TokenKey: string, PageNumber: number, PageSize: number, filterText: string, orderBy: string, displayQuoteStatusIds: string, quoteSentStatus: string, msAStatusIdsSearch: string, quoteStatusIdsSearch: string): Observable<any> {

    this.setAPIUrl();
    let data = 'api/ClientInformation/GetAllPricingQuotes/' + filterText + '/' + PageNumber + '/' + PageSize + '/' + displayQuoteStatusIds + '/' + quoteSentStatus + '/' + msAStatusIdsSearch + '/' + quoteStatusIdsSearch + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllPricingQuotesExport(TokenKey: string, PageNumber: number, PageSize: number, filterText: string, orderBy: string, displayQuoteStatusIds: string, quoteSentStatus: string, msAStatusIdsSearch: string, quoteStatusIdsSearch: string): Observable<any> {

    this.setAPIUrl();
    let data = 'api/ClientInformation/GetAllPricingQuotesExport/' + filterText + '/' + PageNumber + '/' + PageSize + '/' + displayQuoteStatusIds + '/' + quoteSentStatus + '/' + msAStatusIdsSearch + '/' + quoteStatusIdsSearch + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetClientInformationList(TokenKey: string, PageNumber: number, PageSize: number, filterText: string, orderBy: string): Observable<any> {

    this.setAPIUrl();
    let data = 'api/ClientInformation/GetClientInformationList/' + filterText + '/' + PageNumber + '/' + PageSize + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetOnboardingList(TokenKey: string, PageNumber: number, PageSize: number, filterText: string, orderBy: string): Observable<any> {

    this.setAPIUrl();
    let data = 'api/ClientInformation/GetOnboardingList/' + filterText + '/' + PageNumber + '/' + PageSize + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  // DeletePricingQuotes(TokenKey: string, ClientInformationId: number): Observable<any> {
  //   this.setAPIUrl();
  //   let data = 'api/ClientInformation/DeleteClientInformation/' + ClientInformationId;
  //   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  //   return this._http.get<any>(this.AppUrl + data, httpOptions)
  //     .pipe(catchError(this.handleError));
  // }

  DeletePricingQuotes(TokenKey: string, PriceQuoteId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/DeletePriceQuote/' + PriceQuoteId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CompanyProfileInformationAddUpdate(TokenKey: string, addNewClientInformationModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/ClientInformation/CompanyProfileInformationAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewClientInformationModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetClientInformationById(TokenKey: string, priceQuoteId: number, ClientCompanyId: number, isFront: boolean): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetClientInformationById/' + priceQuoteId + '/' + ClientCompanyId + '/' + isFront;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetClientInformationByClientInformationId(TokenKey: string, clientInformationId: number, isFront: boolean): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetClientInformationByClientInformationId/' + clientInformationId + '/' + isFront;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetClientInformationByCompanyId(TokenKey: string, companyId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetClientInformationByCompanyId/' + companyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }


  GetTaxRegulatorySelection(TokenKey: string, ClientInformationId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/AdditionalDataCollection/GetTaxRegulatorySelection/' + ClientInformationId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  AdditionalCompanyQuestionsAddUpdate(TokenKey: string, addNewClientInformationModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/ClientInformation/AdditionalCompanyQuestionsAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewClientInformationModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  NotesAddUpdate(TokenKey: string, addNewClientInformationModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/ClientInformation/NotesAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewClientInformationModel, httpOptions)
      .pipe(catchError(this.handleError));
  }


  GetStateSelection(TokenKey: string, priceQuoteId: number, isFront: boolean): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetStateSelection/' + priceQuoteId + '/' + isFront;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  addUpdateSelectedStateData(selectedStateData: StateSelectionModel, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/ClientInformation/SelectedStateDataAddUpdate', selectedStateData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ClientInformationAttachmentAddUpdate(TokenKey: string, ClientInformationAttachmentModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/ClientInformation/ClientInformationAttachmentAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
    return this._http.post<any>(URL, ClientInformationAttachmentModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetClientInformationAttachmentById(TokenKey: string, ClientInformationId: number, isSubscriber: boolean): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetClientInformationAttachmentById/' + ClientInformationId + '/' + isSubscriber;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetUpdatedBaseRate(TokenKey: string, PriceQuoteId: number, BaseRateType: number, orderBy: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetUpdatedBaseRateById/' + PriceQuoteId + '/' + BaseRateType + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  UpdatedBaseRateAddUpdate(UpdatedBaseRateData: UpdatedBaseRateModel[], TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/PricingQuotes/UpdatedBaseRateAddUpdate', UpdatedBaseRateData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetSelectedStateCount(TokenKey: string, PriceQuoteId: number, BaseRateType: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetStateCountByClientInformationId/' + PriceQuoteId + '/' + BaseRateType;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetClientInformationIdByUserId(TokenKey: string, userId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetClientInformationIdByUserId/' + userId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  PriceQuoteAddUpdate(priceQuoteData: PriceCalculationModel, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/PricingQuotes/PriceQuoteAddUpdate', priceQuoteData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetStateMaster(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetAllState';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCountyMaster(TokenKey: string, stateId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetAllCounty/' + stateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAddOnServices(TokenKey: string, priceQuoteId: number, BaseRateType: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetAddOnServices/' + priceQuoteId + '/' + BaseRateType;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  UpdatedAddOnServicesAddUpdate(UpdatedAddOnServiceData: AddOnServicesModel, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/PricingQuotes/UpdatedAddOnServicesAddUpdate', UpdatedAddOnServiceData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetPriceQuoteServices(TokenKey: string, BaseRateTypeId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetPriceQuoteServices/' + BaseRateTypeId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetComplianceByClientInformationId(TokenKey: string, priceQuoteId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetComplianceByClientInformationId/' + priceQuoteId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ComlianceAddUpdate(listofCompliance: AddUpdateOngoingComplianceModel, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/PricingQuotes/ComlianceAddUpdate', listofCompliance, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetProposelByClientInformationId(TokenKey: string, priceQuoteId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetProposelByClientInformationId/' + priceQuoteId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetProgressStepDetailsById(TokenKey: string, PriceQuoteId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetProgressStepDetailsById/' + PriceQuoteId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  PriceQuoteSent(TokenKey: string, PriceQuoteId: number, isSent: boolean): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/PriceQuoteSent/' + PriceQuoteId + '/' + isSent;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  PriceQuoteDisplay(TokenKey: string, PriceQuoteId: number, isDisplay: boolean): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/PriceQuoteDisplay/' + PriceQuoteId + '/' + isDisplay;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  proposalStepAddUpdate(TokenKey: string, objPropsalData: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/PricingQuotes/proposalStepAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, objPropsalData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  MarketEntryDataAddUpdate(TokenKey: string, objPropsalData: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/PricingQuotes/MarketEntryDataAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, objPropsalData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  MarketEntryDefaultDataAddUpdate(TokenKey: string, objPropsalData: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/PricingQuotes/MarketEntryDefaultDataAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, objPropsalData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getPriceQuoteListByUserId(TokenKey: string, UserId: number, PageNumber: number, PageSize: number, orderBy: string, IsClient: boolean): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetAllPricingQuotesByUserId/' + UserId + '/' + PageNumber + '/' + PageSize + '/' + orderBy + '/' + IsClient;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  AdditionalCompanyInformationAddUpdate(TokenKey: string, addNewClientInformationModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/AdditionalDataCollection/AdditionalCompanyInformationAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewClientInformationModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAdditionalInformationById(TokenKey: string, ClientInformationId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/AdditionalDataCollection/GetAdditionalCompanyInformationById/' + ClientInformationId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  SensitiveInformationAddUpdate(TokenKey: string, sensitiveInformationModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/AdditionalDataCollection/SensitiveInformationAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, sensitiveInformationModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetSensitiveInformationById(TokenKey: string, ClientInformationId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/AdditionalDataCollection/GetSensitiveInformationById/' + ClientInformationId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  InteserraPortalCredentialAddUpdate(TokenKey: string, PortalCredentialModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/AdditionalDataCollection/PortalCredentialAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, PortalCredentialModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetPortalCredentialById(TokenKey: string, ClientInformationId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/AdditionalDataCollection/GetPortalCredentialById/' + ClientInformationId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetState(TokenKey: string, module: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/AdditionalDataCollection/GetState/' + module;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  PriceQuoteApproved(TokenKey: string, PriceQuoteId: number, IsQuoteApproved: number, rejectReason: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/PriceQuoteApproved/' + PriceQuoteId + '/' + IsQuoteApproved + '/' + rejectReason;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  MSAStatusApproved(TokenKey: string, PriceQuoteId: number, IsQuoteApproved: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/MSAStatusApproved/' + PriceQuoteId + '/' + IsQuoteApproved;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getCompanyIdByUserId(TokenKey: string, UserId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetCompanyIdByUserId/' + UserId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CreateDuplicateRecord(TokenKey: string, PriceQuoteId: number, AdminId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/CreateDuplicateRecord/' + PriceQuoteId + '/' + AdminId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getPriceQuoteListByCompanyId(TokenKey: string, CompanyId: number, isList: boolean): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/getPriceQuoteListByCompanyId/' + CompanyId + '/' + isList;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetProgressStepDetailsByClientInformationId(TokenKey: string, ClientInformationId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetProgressStepDetailsByClientInformationId/' + ClientInformationId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCompanyProfileProgressById(TokenKey: string, ClientInformationId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetCompanyProfileProgressById/' + ClientInformationId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }


  getAllQuoteByUserId(TokenKey: string, UserId: number, PageNumber: number, PageSize: number, orderBy: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetAllQuoteByUserId/' + UserId + '/' + PageNumber + '/' + PageSize + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  SaveAttachmentRequiredField(TokenKey: string, clientInformationId: number, isRequired: boolean, fieldName: string, priceQuoteId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/SaveAttachmentRequiredField/' + clientInformationId + '/' + isRequired + '/' + fieldName + '/' + priceQuoteId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetMarketEntryDataById(TokenKey: string, priceQuoteId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/getMarketEntrydataById/' + priceQuoteId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetMarketEntryByTypeId(TokenKey: string, marketEntryTypeId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetMarketEntryByTypeId/' + marketEntryTypeId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GenerateProposal(priceQuoteData: any, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/MyPdf/GenerateProposal', priceQuoteData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetPriceQuoteSubscriptionState(TokenKey: string, CompanyId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetPriceQuoteSubscriptionStates/' + CompanyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetPriceQuoteSubscriptionStateByClientInformationId(TokenKey: string, ClientInformationId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetPriceQuoteSubscriptionStateByClientInformationId/' + ClientInformationId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetStateSelectionForProfile(TokenKey: string, CompanyId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/ClientInformation/GetStateSelectionForProfile/' + CompanyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCompanyProductAcessList(TokenKey: string, companyId): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/ClientInformation/CompanyAccessProductsListByCompanyId/' + companyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  GetAdvisorysoultionbuttondisplay(PriceQuoteId: any, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + 'api/PricingQuotes/GetAdvisorysoultionbuttondisplay?PriceQuoteId=' + PriceQuoteId, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GenerateAdvisoryServicesQuote(PriceQuoteId: any, companyName: any, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + 'api/MyPdf/GenerateAdvisoryServicesQuote?PriceQuoteId=' + PriceQuoteId + '&companyName=' + companyName, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GenerateSolutionServicesQuote(PriceQuoteId: any, companyName: any, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + 'api/MyPdf/GenerateSolutionServicesQuote?PriceQuoteId=' + PriceQuoteId + '&companyName=' + companyName, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GenerateMarketEntryPDF(PriceQuoteId: any, companyName: any, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + 'api/MyPdf/GenerateMarketEntryPDF?PriceQuoteId=' + PriceQuoteId + '&companyName=' + companyName, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GenerateOnboardingDetails(ClientInformationId: any, PriceQuoteId: any, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + 'api/MyPdf/GenerateOnboardingDetails?ClientInformationId=' + ClientInformationId + '&PriceQuoteId=' + PriceQuoteId, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GenerateMarketEntryReportPDF(PriceQuoteId: any, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + 'api/MyPdf/GenerateMarketEntryReportPDF?PriceQuoteId=' + PriceQuoteId, httpOptions)
      .pipe(catchError(this.handleError));
  }

  UpdateQuoteMarkAsSeen(TokenKey: string, priceQuoteId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/UpdateQuoteMarkAsSeen/' + priceQuoteId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetQuoteStatus(TokenKey: string, priceQuoteId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetQuoteStatus/' + priceQuoteId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  MiscellaneousfeesAddUpdate(TokenKey: string, trainingModel: any): Observable<any> {
    debugger
    this.setAPIUrl();
    let data = 'api/PricingQuotes/MiscellaneousfeesAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, trainingModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllMiscellaneousFees(TokenKey: string, PageNumber: number, PageSize: number, filterText: string, orderBy: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetAllMiscellaneousFees/' + filterText + '/' + PageNumber + '/' + PageSize + '/' + orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetMiscFeesById(TokenKey: string, miscId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetMiscFeesById/' + miscId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getAllPricingQuotes(TokenKey: string, PriceQuoteId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/PricingQuotes/GetExportQuote/' + PriceQuoteId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
