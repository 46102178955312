import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActionItemService } from 'src/Services/actionitem.service';
import { CheckAuth } from 'src/Services/utility.service';
import { ActionItemSearchModel } from 'src/Models/ActionItemSearchModel';
import { FormControl, FormGroup } from '@angular/forms';
import { ActionItemSaveNoteModel } from 'src/Models/ActionItemSaveNoteModel';
import { ActionItemSearchNotesModel } from 'src/Models/ActionItemSearchNotesModel';

@Component({
  selector: 'app-notes-popup',
  templateUrl: './notes-popup.component.html',
  styleUrls: ['./notes-popup.component.css']
})
export class NotesPopupComponent implements OnInit {
  tokenKey: string = '';
  actionItemSearchModel = new ActionItemSearchModel()
  stateArray: any = [];
  carrierCategoryArray: any = [];

  companyId: number = 0;
  userId: number = 0;
  actionId: number = 0;
  updateId: number = 0;
  productId: number = 0;
  actionNoteId: number = 0;
  notes: string = '';

  isNotesData: boolean = false;
  deletedId: number = 0;
  addUpdateNotesStatus = new FormGroup({
    notes: new FormControl(''),
    actionID: new FormControl(''),
    updateID: new FormControl(''),
    actionNoteID: new FormControl(''),
  });

  notesModel = new ActionItemSaveNoteModel();
  listOfNotes: ActionItemSearchNotesModel[];

  isAscending: boolean = true;
  orderBy: string = 'Desc';
  upDowNoteCSS: string = '';
  upDowDateCSS: string = 'active-up';
  sortColumnName: string = 'createdDate';

  isAccordianShow: boolean = true;

  isOneClickOnButton: boolean = false;
  constructor(private toastr: ToastrService, private actionItemServices: ActionItemService, private checkAuth: CheckAuth, public activeModal: NgbActiveModal) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
  }


  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyId = +sessionStorage.getItem("CompanyId");
    this.userId = +sessionStorage.getItem('UserId');
    this.productId = +sessionStorage.getItem('productId');
    this.updateId = +sessionStorage.getItem('updateId');
    this.actionId = +sessionStorage.getItem('actionId');

    sessionStorage.removeItem('productId');
    sessionStorage.removeItem('updateId');
    sessionStorage.removeItem('actionId');

    this.ActionItemSearchByIds(this.userId, this.updateId, this.actionId);
    this.GetActionItemSearchNotes(this.userId, this.updateId, this.actionId, this.sortColumnName + this.orderBy);
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ActionItemSearchByIds(userId: any, updateId: any, actionId: any) {
    this.actionItemServices.ActionItemSearchByIds(this.tokenKey, userId, updateId, actionId).subscribe(
      data => {

        if (data['status'] == 'Success') {
          this.actionItemSearchModel = data['actionItemSearchModel'];
          if (this.actionItemSearchModel.stateName != 'null' && typeof (this.actionItemSearchModel.stateName) != 'undefined') {
            this.stateArray = this.actionItemSearchModel.stateName.split(',');
          }
          if (this.actionItemSearchModel.carrierNames != 'null' && typeof (this.actionItemSearchModel.carrierNames) != 'undefined') {
            this.carrierCategoryArray = this.actionItemSearchModel.carrierNames.split(',');
          }
        }
      });
  }

  addUpdateNotes(addUpdateNotesStatus: any, isButtonDisable: boolean) {
    this.notesModel = addUpdateNotesStatus.value;
    this.notesModel.userID = this.userId;
    event.preventDefault();



    if (isButtonDisable) {      
      return;
    }

    this.isOneClickOnButton = true;
    
    this.actionItemServices.ActionItemSaveNote(this.tokenKey, this.notesModel).subscribe(
      data => {
        this.isOneClickOnButton = false;
        if (data['status'] == 'Success') {
          if (this.notesModel.actionNoteId == 0) {
            this.showMessage('Note saved successfully', 'Success', 'success');
          }
          else {
            this.showMessage('Note upteded successfully', 'Success', 'success');
          }
          this.activeModal.close('Yes');
        }
        else {
          this.showMessage(data['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
        this.isOneClickOnButton = false;
      }
    );
  }

  GetActionItemSearchNotes(userId: any, updateId: any, actionId: any, sortColumnName: any) {
    this.actionItemServices.GetActionItemSearchNotes(this.tokenKey, userId, updateId, actionId, sortColumnName).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.listOfNotes = data['actionItemSaveNoteList'];
          if (this.listOfNotes != null) {
            if (this.listOfNotes.length == 0) {
              this.isNotesData = true;
            }
            else {
              this.isNotesData = false;
            }
          }
        }
        else {
          this.showMessage(data['status'], 'Error', 'error');
        }
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  EditNotes(notes: string, actionNoteId: number) {

    this.notesModel.actionNoteId = actionNoteId;
    this.notes = notes;
    this.addUpdateNotesStatus.setValue
      ({
        notes: notes,
        actionID: this.actionId,
        updateID: this.updateId,
        actionNoteID: actionNoteId
      });
  }

  setDeletedNotedId(actionNoteId: number) {
    this.deletedId = actionNoteId;
  }

  DeleteNotes(actionNoteId: number) {

    this.actionItemServices.ActionNotesDeleteByIds(this.tokenKey, actionNoteId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.showMessage('Note deleted successfully', 'Success', 'success');
          this.GetActionItemSearchNotes(this.userId, this.updateId, this.actionId, this.sortColumnName);
        }
        else {
          this.showMessage(data['status'], 'Error', 'error');
        }
      }
    );
  }

  sort(colName: any) {

    if (this.sortColumnName != colName) {
      this.isAscending = false;
    }

    if (this.sortColumnName === colName) {
      this.isAscending = !this.isAscending;
    }

    if (this.isAscending) {
      //this.isAscending = true;
      this.orderBy = ' DESC';
      if (colName === "createdDate") {
        this.upDowDateCSS = 'active-up';
        this.upDowNoteCSS = '';
      }
      else if (colName === "note") {
        this.upDowDateCSS = '';
        this.upDowNoteCSS = 'active-up';
      }
    }
    else {
      //this.isAscending = false;
      this.orderBy = '';
      if (colName === "createdDate") {
        this.upDowDateCSS = 'active';
        this.upDowNoteCSS = '';
      }
      else if (colName === "note") {
        this.upDowDateCSS = '';
        this.upDowNoteCSS = 'active';
      }
    }
    this.sortColumnName = colName;
    this.GetActionItemSearchNotes(this.userId, this.updateId, this.actionId, this.sortColumnName + this.orderBy);
  }

}
