import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SiteLayoutComponent } from 'src/app/Layout/site-layout/site-layout.component';
import { ToolEnum } from 'src/Models/Common';
import { CustomMenuGroupModel, MenuGroupModel } from 'src/Models/MenuGroupModel';
import { UserModel } from 'src/Models/usersModel';
import { AdminByPassClientLoginService } from 'src/Services/adminByPassClientLogin.service';
import { I360Service } from 'src/Services/i360Service';

@Component({
  selector: 'app-admin-by-pass-client-login',
  templateUrl: './admin-by-pass-client-login.component.html',
  styleUrls: ['./admin-by-pass-client-login.component.css']
})
export class AdminByPassClientLoginComponent implements OnInit {

  userId: any;
  clientId: string = '';
  adminUserId: string = '';
  tokenKey: string = '';
  userModel = new UserModel();
  menuModel = new MenuGroupModel();
  custommenuModel= new CustomMenuGroupModel();
  quaryValues: string = '';

  constructor(private siteLaout: SiteLayoutComponent, private i360Service: I360Service,private adminByPassService: AdminByPassClientLoginService,
    private activedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {      
      if (params['id'] && params['id2']) {        
        if (params['id'] != null) {          
          this.userId = params['id'];
          this.clientId = params['id2'];
          if (this.userId != '' && this.clientId != null) {
            this.AddNewUserForAdmin(this.userId, this.clientId);
          }
          else {
            this.router.navigate(['/Login']);
          }
        }
      }
      else {
        this.router.navigate(['/Login']);
      }
    });
  }

  AddNewUserForAdmin(adminUserId: any, clientId: any) {
    this.adminByPassService.AddNewUserForAdmin(adminUserId, clientId).subscribe(
      data => {
        if (data["status"] == "Success") {          
          let newId = data["newId"];
          this.tokenKey = data['token'];

          if (newId > 0) {
            this.CheckLogin(newId);
          }
          else {
            this.showMessage("You don't have access to any Tool. Please contact administrator.", 'Error', 'error');
          }
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
      },
      errorCode => {
        this.showMessage(errorCode, 'Error', 'error');
      }
    );
  }

  CheckLogin(newId) {
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.adminByPassService.GetAdminByPassUserLoginData(this.tokenKey, newId).subscribe(
      data => {
        if (data["status"] == "Success") {          
          this.userModel = data["usersModel"];
          if (this.userModel != null) {

            let FirstLastName = this.userModel.firstName + ' ' + this.userModel.lastName;
                if (FirstLastName.trim() != '') {
                  sessionStorage.setItem('FirstLastName', FirstLastName);
                  localStorage.setItem('FirstLastName', FirstLastName);
                }
                else {
                  sessionStorage.setItem('FirstLastName', this.userModel.userName);
                  localStorage.setItem('FirstLastName', this.userModel.userName);
                }

            if (data["usersModel"].listOfProducts.length > 0) {
              sessionStorage.setItem('UserId', this.userModel.userId);
              localStorage.setItem('UserId', this.userModel.userId);
              if (this.userModel.createPassword == true) {
                this.router.navigate(['CreatePassword']);
              }
              else {
                sessionStorage.setItem('UserName', this.userModel.userName);
                localStorage.setItem('UserName', this.userModel.userName);
                sessionStorage.setItem('LastLogin', this.userModel.lastLogin);
                localStorage.setItem('LastLogin', this.userModel.lastLogin);
                sessionStorage.setItem('clientTypeId', this.userModel.userTypeId);
                localStorage.setItem('clientTypeId', this.userModel.userTypeId);
                sessionStorage.setItem('isUserByPass', 'Login');
                localStorage.setItem('isUserByPass', 'Login');
                let FirstLastName = this.userModel.firstName + ' ' + this.userModel.lastName;
                if (FirstLastName.trim() != '') {
                  sessionStorage.setItem('FirstLastName', FirstLastName);
                  localStorage.setItem('FirstLastName', FirstLastName);
                }
                else {
                  sessionStorage.setItem('FirstLastName', this.userModel.userName);
                  localStorage.setItem('FirstLastName', this.userModel.userName);
                }

                let Tool: string = data["usersModel"].listOfProducts[0].productName.toLowerCase();

                sessionStorage.setItem('userHasTracIt', 'false');
                localStorage.setItem('userHasTracIt', 'false');
                let UserAccessibleTools: string = ''

                for (let iCounter = 0; iCounter < data["usersModel"].listOfProducts.length; iCounter++) {
                  UserAccessibleTools += data["usersModel"].listOfProducts[iCounter].productName + ',';

                  if (ToolEnum.actionitem == data["usersModel"].listOfProducts[iCounter].productId) {
                    sessionStorage.setItem('userHasTracIt', 'true');
                    localStorage.setItem('userHasTracIt', 'true');
                  }
                }
                sessionStorage.setItem('ClientAccessibleTools', UserAccessibleTools.substring(0, UserAccessibleTools.length - 1).toLowerCase());
                localStorage.setItem('ClientAccessibleTools', UserAccessibleTools.substring(0, UserAccessibleTools.length - 1).toLowerCase());
                sessionStorage.setItem('CompanyId', this.userModel.companyId);
                localStorage.setItem('CompanyId', this.userModel.companyId);
                sessionStorage.setItem('IsICalSubscription', this.userModel.isICalSubscription);
                localStorage.setItem('IsICalSubscription', this.userModel.isICalSubscription);
                sessionStorage.setItem('EncyUserId', this.userModel.encyUserId);
                localStorage.setItem('EncyUserId', this.userModel.encyUserId);
                sessionStorage.setItem('CompanyName', this.userModel.companyName);
                localStorage.setItem('CompanyName', this.userModel.companyName);
                sessionStorage.setItem('isLogin', 'true');
                localStorage.setItem('isLogin', 'true');
                this.siteLaout.userName = sessionStorage.getItem('FirstLastName');
                this.siteLaout.lastLogin = sessionStorage.getItem('LastLogin');
                sessionStorage.setItem('DbaCompanyName', this.userModel.dba);
                localStorage.setItem('DbaCompanyName', this.userModel.dba);
              
                this.i360Service.i360MenuGroupGet(this.tokenKey, this.userModel.userId).subscribe(
                  menudata => {
                    if (!this.userModel.email || this.userModel.email.indexOf('@inteserra.com') == -1) {
                      menudata['listOfMenu'].forEach(element => {
                        if (element.menuGroupId == 7) {
                          element.childMenu = element.childMenu.filter(x => x.menuId != 44);
                        }
                      });
                    }
                    this.siteLaout.menuModel = menudata['listOfMenu'];
                    this.menuModel = menudata['listOfMenu'];
                    this.siteLaout.userID = +this.userModel.userId;
                    sessionStorage.setItem('UserManu', JSON.stringify(this.menuModel));
                    localStorage.setItem('UserManu', JSON.stringify(this.menuModel));
           
                    if (this.quaryValues != '' && this.quaryValues != null) {
                      let uId = sessionStorage.getItem('emailBriefingUId')!=null?sessionStorage.getItem('emailBriefingUId').toString():null;
                      let trfa911uId = sessionStorage.getItem('trfa911FileDownloadUId')!=null?sessionStorage.getItem('trfa911FileDownloadUId').toString():null;
                      let trfauId = sessionStorage.getItem('trfaFileDownloadUId')!=null?sessionStorage.getItem('trfaFileDownloadUId').toString():null;
                      let trfa911updateuId = sessionStorage.getItem('trfa911UpdatehistoryUId')!=null?sessionStorage.getItem('trfa911UpdatehistoryUId').toString():null;
                      
                      if (uId === this.userModel.userId.toString())
                        this.router.navigate(['/View/BriefingDetails/' + this.quaryValues]);
                      else if(trfa911uId===this.userModel.userId.toString())
                      this.router.navigate(['/View/downloadfiles/' + this.quaryValues]);
                      else if(trfa911uId!=null)
                      this.router.navigate(['/Access/Denied/download files']);
                      else if(trfauId===this.userModel.userId.toString())
                      this.router.navigate(['/View/downloadtrfafiles/' + this.quaryValues]);
                      else if(trfauId!=null)
                      this.router.navigate(['/Access/Denied/download files']);
                      else if(trfa911updateuId===this.userModel.userId.toString())
                      this.router.navigate(['/View/updateView/' + this.quaryValues]);
                      else if(trfa911updateuId!=null)
                      this.router.navigate(['/Access/Denied/View Updates']);
                      else
                        this.router.navigate(['/Access/Denied/BriefingEmailServices']);
                    }
                    else
                      this.router.navigate(['Dashboard']);
                  },
                  error => {
                    console.log(error);
                  }
                );

                this.i360Service.i360CustomProductGet(this.tokenKey, this.userModel.userId).subscribe(
                  data => {
                    
                    this.siteLaout.custommenuModel = data['listOfCustomProduct']; 
                    this.custommenuModel = data['listOfCustomProduct'];                     
                    sessionStorage.setItem('UsercustomManu', JSON.stringify(this.custommenuModel));
                    localStorage.setItem('UsercustomManu', JSON.stringify(this.custommenuModel));
                    
                  },
                  error => {
                    console.log(error);
                  }
                );


              }
            }
            else {
              this.showMessage("You don't have access to any Tool. Please contact administrator.", 'Error', 'error');
            }
          }
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }



      },
      errorCode => {
        this.showMessage(errorCode, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

}
