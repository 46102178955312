import { Injectable }  from '@angular/core';
import { Http, Response } from '@angular/http';
import { environment } from '../environments/environment';
import { IAppConfig } from 'src/Models/app-config.model';

@Injectable()
export class AppConfig {

  static settings: IAppConfig;

  constructor(private http: Http) { }

  load() {        
    const jsonFile = `${environment.clientAppBaseURL}/assets/config/config.${environment.name}.json`;    
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: Response) => {
        AppConfig.settings = <any>response.json();
        resolve();
      }).catch((response: any) => {
        reject('Could not load file');
      });
    });
  }
}
