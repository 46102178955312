import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';

@Component({
  selector: 'app-list-of-claim-reports',
  templateUrl: './list-of-claim-reports.component.html',
  styleUrls: ['./list-of-claim-reports.component.css']
})
export class ListOfClaimReportsComponent implements OnInit {

  constructor( private appActivity: ApplicationActivityService,private router: Router,private titleService: Title) {
    this.titleService.setTitle('ACP Claims Reports | Inteserra - i360');
   }

  ngOnInit() {
    
  }

  redirectToImportReports() {
    ApplicationActivityStore('View', 'Claims Imported-ACP',this.appActivity,0, ActionActivity['Inroll ACP'],'/Claim/Import');
    this.router.navigate(['Claim/Import']);
  }

  redirectToMonthlyReports() {
    ApplicationActivityStore('View', 'Monthly Claims-ACP',this.appActivity,0, ActionActivity['Inroll ACP'],'/Claim/Reports');
    this.router.navigate(['Claim/Reports']);
  }

  redirectToYearlyReports() {
    ApplicationActivityStore('View', 'Yearly Claims-ACP',this.appActivity,0, ActionActivity['Inroll ACP'],'/Claim/yearly-Reports');
    this.router.navigate(['Claim/yearly-Reports']);
  }

  redirectToSummaryReports() {
    ApplicationActivityStore('View', 'Claims Summary-ACP',this.appActivity,0, ActionActivity['Inroll ACP'],'/Claim/summary-Reports');
    this.router.navigate(['Claim/summary-Reports']);
  }

}
