import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConfig } from 'src/Services/app.config';
import { UsersService } from '../../../Services/users.service';
import { CheckAuth } from '../../../Services/utility.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from '../../../Models/usersModel';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  userModel = new UserModel();
  appModel: any;
  tokenKey: string = '';
  loginForm: FormGroup;
  multipleEmail: boolean = false;
  userName: string = null;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;

  constructor(
    private router: Router,
    private checkAuth: CheckAuth,
    private _fb: FormBuilder,
    private toastr: ToastrService,
    private usersService: UsersService
    , private titleService: Title
  ) {
    sessionStorage.removeItem('tokenValue');
    sessionStorage.removeItem('appStartTime');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    sessionStorage.removeItem('isAdminOpen');
    sessionStorage.removeItem('AdminUserId');
    sessionStorage.removeItem('StateSetupManageRedirection');
    this.titleService.setTitle('Forgot Password | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.loginForm = this._fb.group({
      userid: 0,
      email: ['', [Validators.required]],
      userName: ['', [Validators.required]],
    })
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  CheckUser(loginForm: any) {

    this.tokenKey = sessionStorage.getItem('tokenValue');

    if (loginForm.value["email"] == "") {
      this.showMessage('Please enter email', 'Error', 'error');
      return;
    }

    if(this.multipleEmail){
      if (loginForm.value["userName"] == "") {
        this.showMessage('Please enter user name', 'Error', 'error');
        return;
      }
    }

    if (loginForm.value['email'].trim() != '') {
      let result = this.isValidMailFormat(loginForm.controls.email);
      if (result != null) {
        this.showMessage('Please provide a valid email', 'Invalid Email', 'error');
        return;
      }
    }

    if (loginForm.value['userName'].trim() != '') {
      this.userName = loginForm.value['userName'].trim();
    }
    this.blockDocument();
    this.usersService.CheckUserByEmailIdCount(this.tokenKey, loginForm.value['email'].trim()).subscribe(
      data => {
        this.unblockDocument();
        if (data["status"] == "Success") {
          if (data["emailCount"] == 1 || this.multipleEmail) {
            this.blockDocument();
            this.usersService.CheckUserByEmailId(this.tokenKey, loginForm.value['email'].trim(), this.userName).subscribe(
              data => {
                this.unblockDocument();
                if (data["status"] == "Success") {
                  this.showMessage('Please check you inbox, Email sent successfully on ' + loginForm.value['email'].trim(), 'Success', 'success');
                  this.loginForm.setValue({
                    userid: 0,
                    email: [''],
                  })

                }
                else {
                  this.showMessage(data["status"], 'Error', 'error');
                }
              },
              error => {
                this.unblockDocument();
                this.showMessage(error, 'Error', 'error');
              }
            );
          }
          else {
            if(data["emailCount"] == 0)
              {
                this.showMessage(data["status"], 'Error', 'error');
              }
              else{
                this.multipleEmail = true;
                this.showMessage('Multiple email found please enter user name!', 'Warning', 'warning');
              }
          }
        } else {
          this.showMessage(data["status"], 'Error', 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      });
  }

  isValidMailFormat(control: FormControl) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value != '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { 'Please provide a valid email': true };
    }

    return null;
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
}
