import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { I360Service } from 'src/Services/i360Service';
import { MenuGroupModel } from 'src/Models/MenuGroupModel';
import { CheckAuth } from '../../../Services/utility.service';
import { Router } from '@angular/router';
import { UserModel } from '../../../Models/usersModel';
import { FrontSolutionTracItModel } from 'src/Models/FrontSolutionTracItModel';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { RedirectService } from 'src/Services/RedirectService';
import { NewsModel } from 'src/Models/NewsModel';
import { FilterModel } from 'src/Models/filterModel';
import { HomePageBannerModel } from 'src/Models/HomePageBannerModel';
import { HomepagebannerService } from 'src/Services/homepagebanner.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';

@Component({
  selector: 'app-front-index',
  templateUrl: './front-index.component.html',
  styleUrls: ['./front-index.component.css']
})
export class FrontIndexComponent implements OnInit {
  tokenKey: string = '';
  menuModel = new MenuGroupModel();
  userModel = new UserModel();
  listOfTracIt: FrontSolutionTracItModel[];
  listOfFileCount: FrontSolutionTracItModel[];
  userId: any;
  welcomeMessage: string = 'Welcome to i360';
  userHasTracIt: boolean = false;

  listOfNews: NewsModel[];
  filterModel = new FilterModel();
  totalRecordCount: any;
  listOfBanner: HomePageBannerModel[];
  listOfBannercount: number = 0;

  constructor(private redirectService: RedirectService,
    private config: NgbCarouselConfig,
    private checkAuth: CheckAuth,
    private router: Router,
    private titleService: Title,
    private bannerService: HomepagebannerService,
    private i360Service: I360Service,
    private appActivity: ApplicationActivityService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    if (sessionStorage.getItem('userHasTracIt') == 'true')
      this.userHasTracIt = true;
    if (sessionStorage.getItem('UserId') != null && typeof (sessionStorage.getItem('UserId')) != 'undefined' && sessionStorage.getItem('UserId') != '0') {
      this.userId = sessionStorage.getItem('UserId');
    }
    else
      this.userId = sessionStorage.getItem('EncyUserId');

    config.interval = 2000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;

    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '9';
    this.filterModel.orderBy = 'PubDate DESC';
    this.filterModel.userID = this.userId;
    this.titleService.setTitle('Inteserra - i360');
  }


  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.GetListOfTracIt();
    this.GetLibraryFileCount();
    this.i360NewsFindByPaged();
    this.i360HomePageBanner();

  }

  ngAfterViewInit(): void {

  }

  GetListOfTracIt() {
    let uId = this.userHasTracIt ? this.userId : 0;
    this.i360Service.i360FrontSolutionTracIt('', uId).subscribe(
      data => {
        if (data['listOfTracIt'] != null) {
          this.listOfTracIt = data['listOfTracIt'];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  GetLibraryFileCount() {

    this.i360Service.i360UserLibraryFileCount('', this.userId).subscribe(
      data => {
        if (data['listOfFileCount'] != null) {
          this.listOfFileCount = data['listOfFileCount'];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  autoLoginInIRIS(url, briefingId) {
    this.applicationActivity('View', 'Home - Track-It', ActionActivity['Briefing Services'], url, briefingId);
    if (this.userHasTracIt) {
      this.i360Service.GenerateByPassAuthenticationKey(this.tokenKey).subscribe(
        data => {

          if (data != null) {
            if (data['status'] == 'Success') {
              let encyHasKey = data['encyHasKey'];
              if (data['encyHasKey'] != null) {
                let productName = ToolEnum[ToolEnum.actionitem].toString();
                let userName = sessionStorage.getItem('UserName');
                let param = { username: userName, password: encyHasKey, productname: productName.toLowerCase() };
                this.redirectService.post(param, url);
              }
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    }
    else {
      if (sessionStorage.getItem('isLogin') == 'false') {
        let url = '/login';
        this.router.navigate([url]);
      }
      else {
        let productName = ToolEnum[ToolEnum.actionitem].toString();
        sessionStorage.setItem('productId', ToolEnum.briefingServices.toString());
        let url = '/Access/Denied/' + productName;
        this.router.navigate([url]);
      }

    }
  }
  navigateToProduct(url, productId, isLocked, productName) {
    if (isLocked) {
      let para = encodeURI(productName);
      url = '/Access/Denied/' + para;
    }
    this.applicationActivity('View', 'Home - ' + productName, productId);
    sessionStorage.removeItem('displayName');
    sessionStorage.setItem('productId', productId);
    sessionStorage.setItem('displayName', productName);
    this.router.navigate([url]);
  }

  i360NewsFindByPaged() {
    this.i360Service.i360Top9NewsFindByPaged('', this.filterModel).subscribe(
      data => {
        if (data != null) {
          this.listOfNews = data['listOfNews'];
          if (this.listOfNews != null) {
            if (this.listOfNews.length > 0) {
              this.totalRecordCount = this.listOfNews[0].totalRecordCount;
            }
            else
              this.totalRecordCount = 0;
          }
          else
            this.totalRecordCount = 0;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  i360HomePageBanner() {
    this.bannerService.i360HomePageBannerGetFront('').subscribe(
      data => {
        if (data != null) {
          
          this.listOfBannercount = data['listOfBanner'].length;

          if (data['listOfBanner'].length > 0) {
            this.listOfBanner = data['listOfBanner'];
            setTimeout(() => {
              for (let index = 1; index < 500; index++) {
                const el = document.getElementById("ngb-slide-" + index);
                if (el) {
                  el.click();
                  break;
                }
                index = index + this.listOfBanner.length - 1;
              }

            }, 2000);
          }

        }
      },
      error => {
        console.log(error);
      }
    );
  }
  applicationActivity(actionName, pageName, datatype = 0, actionUrl = '', dataId = 0) {
    ApplicationActivityStore(actionName, pageName, this.appActivity, dataId, datatype, actionUrl)
  }

  rediretTonews() {
    this.router.navigate(['/News']);
    this.applicationActivity('View', 'Home - News', ActionActivity.News)
  }

  rediretToLibrary() {
    this.router.navigate(['/MyLibrary']);
    this.applicationActivity('View', 'Home - Library', ActionActivity['My Library'])
  }
}
