export class CarrierTariffsModel {
    carrierId?: any;    
    title?: string;
    url?: string;
    isActive?: any;
    createdBy?: any;
    createdTime?: any;
    modifiedBy?: any;
    modifiedTime?: any;
    totalRecordCount?: any;
}