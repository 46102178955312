import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { StateMasterService } from 'src/Services/state-master.service';
import { Main911Service } from 'src/Services/main911.service';
import { ContactsRate911Model } from 'src/Models/ContactsRate911Model';
import { CountyMasterService } from 'src/Services/county-master.service';
import { CityMasterService } from 'src/Services/city-master.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  stateCode: any;
  tokenKey: string;
  listofContacts: ContactsRate911Model[]

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  FundName: string;


  upDowFundNameCSS: string = '';
  upDowContactTableNameCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowCountyNameCSS = '';
  upDowCityNameCSS = '';
  upDowFirstNameCSS = '';
  upDowLastNameCSS = '';
  upDowLastDateCSS = '';
  upDowConfiDateCSS = '';
  upDowEmailCSS = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: any[] = [];

  //County List
  dropdownListCounty = [];
  selectedItemsConuty = [];
  dropdownSettingsCounty = {};
  listOfCountyIds = [];
  countyId: string = ' ';
  countyList: any[] = [];

  //ContactTable
  ContactTableId: string = ' ';
  dropdownListContactTable = [];
  selectedItemsContactTable = [];
  dropdownSettingsContactTable = {};
  listofContactsTypeIds = [];
  ContactTableList: any[] = [];
  listofContactsTable: any[] = [];
  filterText: string = ' ';

  module = '911';
  contactId: number = 0;

  //For ContactColumn Dropdown
  listOfColumnIds = [];
  ColumnId: any;
  selectedItemsColumn = [];
  dropdownSettingsColumn = {};
  ColumnList: any[] = [];
  dropdownListColumn = [];
  selectedItem: string = '';

  //City List
  dropdownListCity = [];
  selectedItemsCity = [];
  dropdownSettingsCity = {};
  listOfCityIds = [];
  cityId: string = ' ';
  cityList: any[] = [];

  //Scope List
  dropdownListScope = [];
  selectedItemsScope = [];
  dropdownSettingsScope = {};
  listOfScopeIds = [];
  scopeId: string = ' ';
  scopeList: any[] = [];

  fromDate?: string;
  toDate?: string;
  fromDate1?: string;
  toDate1?: string;
  rangeOfLastConfDates: any;
  rangeOfConfDates: any;
  openMenuIndex: number = -1;

  constructor(private countyService: CountyMasterService, private router: Router, private main911Master: Main911Service, private checkAuth: CheckAuth
    , private titleService: Title, private main911Service: Main911Service, private cityService: CityMasterService, private toastr: ToastrService, private stateService: StateMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofContacts = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'StateName';
    this.FundName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Contacts');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsCounty = {
      singleSelection: false,
      text: "Filter by County",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No County(s) available',
      showCheckbox: true,
    };
    this.dropdownSettingsCity = {
      singleSelection: false,
      text: "Filter by City",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No City(s) available',
      showCheckbox: true,
    };
    this.dropdownSettingsScope = {
      singleSelection: false,
      text: "Filter by Fund Jurisdiction Level",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund Jurisdiction Level(s) available',
      showCheckbox: true,
    };
    this.dropdownSettingsContactTable = {
      singleSelection: true,
      text: "Filter by ContactTable",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No ContactTable(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsColumn = {
      singleSelection: true,
      text: "Filter by Column",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Column(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    }

    $(function () {
      $('.wrapper1').on('scroll', function (e) {
        $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft());
      });
      $('.wrapper2').on('scroll', function (e) {
        $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft());
      });
      $('.div1').width($('table').width());
      $('.div2').width($('table').width());
    });

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.GetAllContactColumn();
    this.getState();
    this.getScope();
    this.isloading = true;
    this.ContactsGetAll();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.ContactsGetAll();
  }

  getScope() {
    this.main911Service.GetAllStateScope(this.tokenKey).subscribe(
      data => {
        this.scopeList = data['scope'];
        this.dropdownListScope = [];
        if (this.scopeList.length > 0) {
          this.scopeList.forEach(x => {
            this.dropdownListScope.push({ "id": x.scopeID, "itemName": x.scopeName })
          });
        }
      },
      error => { }
    );
  }

  onSelectScope($event) {
    this.listOfScopeIds = this.removeElemetninArray(this.listOfScopeIds, $event.id)
    this.listOfScopeIds.push($event.id);
    this.scopeId = ' ';
    if (this.listOfScopeIds.length > 0) {
      this.scopeId = this.listOfScopeIds.join(',')
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onDeSelectScope($event) {

    if (this.listOfScopeIds != null) {
      if (this.listOfScopeIds.length > 0) {
        this.listOfScopeIds = this.removeElemetninArray(this.listOfScopeIds, $event.id)
      }
    }
    if (this.listOfScopeIds.length > 0) {
      this.scopeId = this.listOfScopeIds.join(',')
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();

  }

  onSelectAllScope($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfScopeIds != null) {
        if (this.listOfScopeIds.length > 0) {
          this.listOfScopeIds = this.removeElemetninArray(this.listOfScopeIds, $event[iCounter].id)
          this.listOfScopeIds.push($event[iCounter].id);
        }
        else {
          this.listOfScopeIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfScopeIds.length > 0) {
      this.scopeId = this.listOfScopeIds.join(',')
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onDeSelectAllScope($event) {
    this.listOfScopeIds = [];
    this.selectedItemsScope = [];
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  GetCountyByStateId(stateId) {

    this.countyService.GetCountyByStateId(this.tokenKey, stateId, true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyList = result['county'];
          this.dropdownListCounty = [];
          if (this.countyList.length > 0) {
            let b = [];
            this.selectedItemsConuty = [];
            this.countyList.forEach(x => {
              this.dropdownListCounty.push({ "id": x.countyId, "itemName": x.countyName })
            });
            this.listOfCountyIds = b;
            if (this.listOfCountyIds.length > 0) {
              this.countyId = this.listOfCountyIds.join(',')
            }
          }
          else {
            this.selectedItemsConuty = [];
            this.listOfCountyIds = [];
          }
        }
      });
  }

  GetCityByCountyId(countyId) {
    this.cityService.GetCityByCountyIdMultiple(this.tokenKey, countyId, true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.cityList = result['city'];
          this.dropdownListCity = [];
          this.dropdownListCity = this.cityList
          this.selectedItemsCity = [];
          if (this.cityList.length > 0) {
            let b = [];
            this.selectedItemsCity = [];
            this.listOfCityIds.forEach(x => {
              let a = this.cityList.filter(e => e.id == x);
              if (a.length > 0) {
                b.push(x);
                this.selectedItemsCity.push(a[0])
              }
            });
            this.listOfCityIds = b;
          }
          else {
            this.selectedItemsCity = [];
            this.listOfCityIds = [];
          }
        }
        if (this.listOfCountyIds.length == 0) {
          this.listOfCountyIds = [];
          this.selectedItemsCity = [];
          this.dropdownListCity = [];
        }
      }
    );
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetCountyByStateId(this.stateId);
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onDeSelectState($event) {

    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.listOfStateIds.length == 0) {
      this.dropdownListCounty = [];
      this.selectedItemsConuty = [];
      this.listOfCountyIds = [];
      this.dropdownListCity = [];
      this.selectedItemsCity = [];
      this.listOfCityIds = [];
    }
    else
      this.GetCountyByStateId(this.stateId);
    this.resetPageNoandSize();
    this.ContactsGetAll();

  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetCountyByStateId(this.stateId);
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onDeSelectAllState($event) {

    this.listOfStateIds = [];
    this.listOfCountyIds = [];
    this.listOfCityIds = [];
    this.selectedItemsConuty = [];
    this.dropdownListCounty = [];
    this.selectedItemsState = [];
    this.dropdownListCity = [];
    this.selectedItemsCity = [];


    this.resetPageNoandSize();
    this.ContactsGetAll();

  }

  onSelectCounty($event) {
    this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
    this.listOfCountyIds.push($event.id);
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);
    this.resetPageNoandSize();
    this.ContactsGetAll();

  }

  onDeSelectCounty($event) {

    if (this.listOfCountyIds != null) {
      if (this.listOfCountyIds.length > 0) {
        this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    if (this.listOfCountyIds.length == 0) {
      this.listOfCityIds = [];
      this.selectedItemsCity = [];
      this.dropdownListCity = [];
    }
    else
      this.GetCityByCountyId(this.countyId);
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onSelectAllCounty($event) {

    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);
    this.resetPageNoandSize();
    this.ContactsGetAll();

  }

  onDeSelectAllCounty($event) {
    this.listOfCountyIds = [];
    this.selectedItemsCity = [];
    this.dropdownListCity = [];
    this.resetPageNoandSize();
    this.ContactsGetAll();

  }

  onSelectCity($event) {
    this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
    this.listOfCityIds.push($event.id);
    this.cityId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onDeSelectCity($event) {

    if (this.listOfCityIds != null) {
      if (this.listOfCityIds.length > 0) {
        this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
      }
    }
    this.cityId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onSelectAllCity($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
          this.listOfCityIds.push($event[iCounter].id);
        }
        else {
          this.listOfCityIds.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onDeSelectAllCity($event) {
    this.listOfCityIds = [];
    this.resetPageNoandSize();
    this.ContactsGetAll();

  }

  GetAllContactColumn() {
    this.main911Master.GetAllContactColumn(this.tokenKey).subscribe(
      data => {
        this.ColumnList = data['result'];
        this.dropdownListColumn = [];
        if (this.ColumnList.length > 0) {
          this.ColumnList.forEach(x => {
            this.dropdownListColumn.push({ "id": x.id, "itemName": x.columnName })
          });
        }
      },
      error => { }
    );
  }

  ContactsGetAll() {
    this.isloading = true;
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',');
    }
    this.countyId = " ";
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.cityId = " ";
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }
    this.scopeId = " ";
    if (this.listOfScopeIds.length > 0) {
      this.scopeId = this.listOfScopeIds.join(',')
    }
    if (this.filterText == "null") { this.filterText = " "; }

    this.main911Master.ContactsGetAll(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, this.countyId, this.cityId, this.scopeId, this.OrderBy, this.filterText, this.selectedItem, this.fromDate, this.toDate, this.fromDate1, this.toDate1).subscribe(
      data => {
        this.listofContacts = data['main'];
        if (this.listofContacts != null) {
          if (this.listofContacts.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContacts[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
    this.isloading = false;
  }



  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.filterText = $event.target.value;
    else
      this.filterText = 'null';
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "cityName") {
        this.upDowCityNameCSS = 'fa fa-arrow-down';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactFirstName") {
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = 'fa fa-arrow-down';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactLastName") {
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = 'fa fa-arrow-down';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "LastContactDate") {
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = 'fa fa-arrow-down';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ConfirmationDate") {
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = 'fa fa-arrow-down';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactEmail") {
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = 'fa fa-arrow-down';
      }

    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "cityName") {
        this.upDowCityNameCSS = 'fa fa-arrow-up';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactFirstName") {
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = 'fa fa-arrow-up';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactLastName") {
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = 'fa fa-arrow-up';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "LastContactDate") {
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = 'fa fa-arrow-up';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ConfirmationDate") {
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = 'fa fa-arrow-up';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactEmail") {
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = 'fa fa-arrow-down';
      }
    }
    this.ContactsGetAll();
  }

  addFund() {
    let navigation = '/admin/' + this.SelectedTool + '/fund/manage';
    this.router.navigate([navigation]);
  }

  updateFund(FundId) {
    let navigation = '/admin/' + this.SelectedTool + '/fund/manage/' + FundId;
    this.router.navigate([navigation]);
  }



  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  onSelectContactTable($event) {

    this.listofContactsTypeIds = [];
    this.listofContactsTypeIds = this.removeElemetninArray(this.listofContactsTypeIds, $event.id)
    this.listofContactsTypeIds.push($event.id);
    this.ContactTableId = ' ';
    if (this.listofContactsTypeIds.length > 0) {
      this.ContactTableId = this.listofContactsTypeIds.join(',')
    }
  }

  onDeSelectContactTable($event) {

    if (this.listofContactsTypeIds != null) {
      if (this.listofContactsTypeIds.length > 0) {
        this.listofContactsTypeIds = this.removeElemetninArray(this.listofContactsTypeIds, $event.id)
      }
    }
    if (this.listofContactsTypeIds.length > 0) {
      this.ContactTableId = this.listofContactsTypeIds.join(',')
    }
  }

  onSelectAllContactTable($event) {

    this.listofContactsTypeIds = [];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listofContactsTypeIds != null) {
        if (this.listofContactsTypeIds.length > 0) {
          this.listofContactsTypeIds = this.removeElemetninArray(this.listofContactsTypeIds, $event[iCounter].id)
          this.listofContactsTypeIds.push($event[iCounter].id);
        }
        else {
          this.listofContactsTypeIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllContactTable($event) {
    this.listofContactsTypeIds = [];
  }
  GetContactsTable() {
    this.main911Master.GetContactsTable(this.tokenKey).subscribe(
      data => {
        this.listofContactsTable = data['main'];
        if (this.listofContactsTable.length > 0) {
          this.listofContactsTable.forEach(x => {
            this.dropdownListContactTable.push({ "id": x.id, "itemName": x.tableName })
          });
        }
      },
      error => { this.stateCode = error }
    );
  }
  ContactsStateGetAll() {
    this.main911Master.ContactsStateGetAll(this.tokenKey).subscribe(
      data => {
        this.stateList = data['main'];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.id, "itemName": x.stateName })
          });
        }

      },
      error => { this.stateCode = error }
    );
  }

  addContact() {
    let navigation = '/admin/' + this.SelectedTool + '/contacts/manage';
    this.router.navigate([navigation]);
  }

  updateContact(id) {
    if (id != undefined && id > 0) {
      window.open('/#/admin/' + this.SelectedTool + '/contacts/manage/' + id, "_blank");
    }
  }

  setDeleteData(id) {
    this.contactId = id;
  }

  deleteContact(deletedId) {
    this.isloading = true;
    this.main911Master.DeleteContact(this.tokenKey, deletedId).subscribe(
      data => {
        this.ContactsGetAll();
        this.toastr.success('Contacts deleted successfully.', 'Delete')
      },
      error => { }
    );
  }

  onSelectColumn($event) {
    this.selectedItem = $event.itemName;
    this.ColumnId = $event.id;
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }
  onDeSelectColumn($event) {
    this.selectedItem = '';
    this.ColumnId = 0;
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }
  onDeSelectAllColumn($event) {
    this.selectedItem = '';
    this.selectedItemsColumn = [];
    this.ContactsGetAll();
  }

  isLastConfDatesFilter(value: any) {
    if (value != null) {
      if (value.length == 2) {
        this.PageNumber = 1;
        const d = new Date(value[0]);
        const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

        const e = new Date(value[1]);
        const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');

        this.fromDate = a;
        this.toDate = f;
        this.ContactsGetAll();
      }
    }
  }

  pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  clearLastDate() {
    this.rangeOfLastConfDates = [];
    this.resetPageNoandSize();
    this.fromDate = '';
    this.toDate = '';
    this.ContactsGetAll();
  }

  isConfDatesFilter(value: any) {
    if (value != null) {
      if (value.length == 2) {
        this.PageNumber = 1;
        const d = new Date(value[0]);
        const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

        const e = new Date(value[1]);
        const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');

        this.fromDate1 = a;
        this.toDate1 = f;
        this.ContactsGetAll();
      }
    }
  }

  clearConfDate() {
    this.rangeOfConfDates = [];
    this.resetPageNoandSize();
    this.fromDate1 = '';
    this.toDate1 = '';
    this.ContactsGetAll();
  }

  onFilterSelectAllState($event) {

    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
      this.GetCountyByStateId(this.stateId);
    }
    
    this.resetPageNoandSize();
    this.ContactsGetAll();
   
  }

  onFilterDeSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetCountyByStateId(this.stateId);
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onFilterSelectAllCounty($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onFilterDeSelectAllCounty($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
        }
      }
    }
    if($event.length==0)
    {
      this.listOfCountyIds=[];
      this.selectedItemsConuty=[];
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
      this.GetCityByCountyId(this.countyId);
    }
    else{
      this.dropdownListCity=[];
      this.selectedItemsCity = [];
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onFilterSelectAllCity($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
          this.listOfCityIds.push($event[iCounter].id);
        }
        else {
          this.listOfCityIds.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onFilterDeSelectAllCity($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onFilterSelectAllScope($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfScopeIds != null) {
        if (this.listOfScopeIds.length > 0) {
          this.listOfScopeIds = this.removeElemetninArray(this.listOfScopeIds, $event[iCounter].id)
          this.listOfScopeIds.push($event[iCounter].id);
        }
        else {
          this.listOfScopeIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfScopeIds.length > 0) {
      this.scopeId = this.listOfScopeIds.join(',')
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  onFilterDeSelectAllScope($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfScopeIds != null) {
        if (this.listOfScopeIds.length > 0) {
          this.listOfScopeIds = this.removeElemetninArray(this.listOfScopeIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfScopeIds.length > 0) {
      this.scopeId = this.listOfScopeIds.join(',')
    }
    this.resetPageNoandSize();
    this.ContactsGetAll();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
