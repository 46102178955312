import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { MiscellaneousFeeModel, MiscellaneousFeesComplianceModel } from 'src/Models/OngoingComplianceModel';
import { ToolEnum } from 'src/Models/Common';

@Component({
  selector: 'app-manage-miscellaneousfees',
  templateUrl: './manage-miscellaneousfees.component.html',
  styleUrls: ['./manage-miscellaneousfees.component.css']
})
export class ManageMiscellaneousfeesComponent implements OnInit {
  miscFeesModel = new MiscellaneousFeesComplianceModel();
  miscFeesDetails: any = [];
  loginuserID: number = 0;
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;
  AddButtontitle: string;
  AddUpdateButtontitle: string;
  subtitle: string;

  miscellaneousId: number = 0;
  isMultipleClick: boolean = false;
  isShowprogressSpinner = false;


  constructor( private _fb: FormBuilder, private activedRoute: ActivatedRoute,  private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService, private priceQuoteService: PricingQuotesService) { 
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
      this.SelectedTool = sessionStorage.getItem('UserLoginTool');
      this.tokenKey = sessionStorage.getItem('tokenValue');
      this.productId = ToolEnum[this.SelectedTool];
      this.titleService.setTitle('Manage Miscellaneousfees');
      this.loginuserID = +sessionStorage.getItem('AdminUserId');
    }

    tokenExpireCalculation(startDate: string, tokenExpiry: string) {
      this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
    }
  

  ngOnInit() {

    this.AddUpdateButtontitle = "Save";
    this.activedRoute.params.subscribe((params: Params) => {
     
      if (params['id']) {
        if (params['id'] != null) {

          this.miscellaneousId = params['id'];
          this.AddUpdateButtontitle = "Update";

          this.GetMiscFeesId();
        }
      }
      else {
        this.miscFeesModel.isActive = true;
      }
    });
  }

  cancelMiscFeesSection() {
    this.router.navigate(['admin/PricingQuote/Miscellaneousfees']);
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  GetMiscFeesId() {
    debugger
    this.priceQuoteService.GetMiscFeesById(this.tokenKey, this.miscellaneousId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.miscFeesModel = result["miscFeesData"];
        }
      });
  }

  saveMiscFees() {
    debugger
    event.preventDefault();

    if (this.miscFeesModel.miscellaneousTitle == '' || typeof (this.miscFeesModel.miscellaneousTitle) == 'undefined' || this.miscFeesModel.miscellaneousTitle == null) {
      this.showMessage('Please enter Title', 'Title Required', 'error');
      return;
    }

    //this.specialReportSectionModel.companyId = this.companyId;
    this.miscFeesModel.miscellaneousId = this.miscellaneousId;
    this.miscFeesModel.createdBy = this.loginuserID;
    this.miscFeesModel.updatedBy = this.loginuserID;

    let formData = new FormData();
    formData.append('miscellaneousId', this.miscFeesModel.miscellaneousId);
    formData.append('miscellaneousTitle', this.miscFeesModel.miscellaneousTitle);
    formData.append('miscellaneousNote', this.miscFeesModel.miscellaneousNote);
    formData.append('miscellaneousAmount', this.miscFeesModel.miscellaneousAmount);
    formData.append('createdBy', this.miscFeesModel.createdBy);
    formData.append('updatedBy', this.miscFeesModel.updatedBy);
    formData.append('isActive', this.miscFeesModel.isActive);


    if (this.isMultipleClick) {
      return;
    }
    this.isMultipleClick = true;
    this.isShowprogressSpinner = true;
    this.priceQuoteService.MiscellaneousfeesAddUpdate(this.tokenKey, formData).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.isMultipleClick = false;
        if(data['status']=='Sucess'){
          this.showMessage('Record saved successfully', 'Save', 'success');
        }
       
        if (this.miscellaneousId == 0)
        this.showMessage('Record saved successfully', 'Save', 'success');
        else
        this.showMessage('Record updated successfully', 'Update', 'success');
       this.miscellaneousId = 0;
        this.cancelMiscFeesSection();
        
      },
      
    );
  }

}
