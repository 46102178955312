import { CarrierCategoryModel } from './ActionCarrierModel';
import { ActionStateModel } from './ActionStateModel';

export class ActionGetByIdModel {
    actionId: number = 0;
    deadlineDate: Date;
    subject: string;
    description: string;
    actionItemTypeId: number;
    assignToId: number;
    isForAllUsers: boolean;
    listOfCarrierCategory: CarrierCategoryModel[];
    listOfState: ActionStateModel[];
}