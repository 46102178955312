import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StateMasterService } from '../../../../Services/state-master.service';
import { CheckAuth } from '../../../../Services/utility.service';
import { ToolEnum, AdminUserType } from '../../../../Models/Common';
import { ActionItemService } from '../../../../Services/actionitem.service';
import { FormGroup, FormControl } from '@angular/forms';
import { AddNewActionModel } from 'src/Models/AddNewActionModel';
import { Title } from '@angular/platform-browser';
import { ClientCompanyModel } from 'src/Models/ClientCompanyModel';
import { UserModel } from 'src/Models/usersModel';
import { UpdatesService } from '../../../../Services/updatesService';
import { UsersService } from '../../../../Services/users.service';
import { Params, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { updateCategoryModel } from 'src/Models/updateCategoryModel';

@Component({
  selector: 'app-manage-action-item',
  templateUrl: './manage-action-item.component.html',
  styleUrls: ['./manage-action-item.component.css']
})
export class ManageActionItemComponent implements OnInit {
  tokenKey: string = '';
  userID: number = 0;
  SelectedTool: string;
  productId: number = 1;

  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfState = [];

  dropdownListTypes = [];
  selectedItemsTypes = [];
  dropdownSettingsTypes = {};
  listOfTypes = [];

  dropdownListCategory = [];
  selectedItemsCategory = [];
  dropdownSettingsCategory = {};
  listOfCategory = [];

  search = '';
  searchTickers = [];
  timeout = null;
  show = false;
  selectedVal: number = 0;

  updateCategoryModel: updateCategoryModel[] = [];

  dropdownListUpdateCategory = [];
  selectedItemsUpdateCategory = [];
  dropdownSettingsUpdateCategory = {};
  listOfUpdateCategory = [];

  addUpdateActionItem = new FormGroup({
    actionItemId: new FormControl(''),
    deadLineDate: new FormControl(''),
    state: new FormControl(''),
    description: new FormControl(''),
    types: new FormControl(''),
    carrierCategories: new FormControl(''),
    subject: new FormControl(''),
    isNeworChanged: new FormControl(''),
    clientCompanyId: new FormControl(''),
    companyUserId: new FormControl(''),
    isUpdateRequired: new FormControl(0),
    actionCharges: new FormControl(''),
    actionNotes: new FormControl(''),
    isSendEmailNotification: new FormControl(0),
    isInformationOnly: new FormControl(0),
    internalNotes: new FormControl(''),
    recurrance: new FormControl(''),
    recurranceId: new FormControl(0),
    search: new FormControl(''),
    alertText: new FormControl(''),
    updateCategories: new FormControl('')
  });

  addNewActionModel = new AddNewActionModel();

  clientCompanyList: ClientCompanyModel[];
  dropdownListclientCompany = [];
  selectedItemsclientCompany = [];
  dropdownSettingclientCompany = {};
  listOfCompany = [];

  selectedItemsBriefing = [];
  dropdownSettingBriefing = {};
  listOfBriefing = [];
  dropdownListBriefing = [];

  userModel: UserModel[];
  dropdownListclientCompanyUser = [];
  selectedItemsclientCompanyUser = [];
  dropdownSettingclientCompanyUser = {};
  listOfUsers = [];

  actionId: number = 0;

  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  isEnableDisable: boolean = false;
  isClone: boolean = false;
  actionChargesValue: any = 0;
  actionNotesValue: string = '';
  isSendEmailNotificationChecked: boolean = true;
  systemUserCondition: string = '';
  isThisUpdateRequiresActionToPerform: boolean = true;

  isUpdateRequiredChecked: boolean = false;

  selectedItemsRecurrance = [];
  dropdownListRecurrance = [];
  dropdownSingleSettings = {};
  updatevalueset: any;
  updateSingleRecord: boolean = true;
  selectedItems: any;
  isNewRecord: any;

  constructor(private router: Router, private activedRoute: ActivatedRoute, private updatesService: UpdatesService, private titleService: Title,
    private actionItemServices: ActionItemService, private checkAuth: CheckAuth, private userServices: UsersService,
    private toastr: ToastrService, private stateMasterService: StateMasterService) {

    this.userID = +sessionStorage.getItem('AdminUserId');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.tokenKey = sessionStorage.getItem('tokenValue');
    let ActionItemCloneId = +sessionStorage.getItem('ActionItemClone');
    if (ActionItemCloneId > 0) {
      this.isClone = true;
    }
    else {
      this.isClone = false;
    }

    if (this.isClone) {
      this.titleService.setTitle('Clone Trac-It Entry');
    }
    else
      this.titleService.setTitle('Manage Trac-It Entry');

    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.systemUserCondition = 'null'
    else
      this.systemUserCondition = '  (CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.userID + '))';
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      this.GetUpdateCategoryData();
      this.GetAllRecurrance();
      this.BindBrifingData();
      this.dropdownSettingBriefing = {
        singleSelection: true,
        text: "Select Briefing(s)",
        enableSearchFilter: true,
        classes: "borderLessTextBoxGreen",
        badgeShowLimit: 3,
        noDataLabel: 'No Briefings available',
        showCheckbox: false
      };

      this.selectedItems = '1';
      this.isNewRecord = true;
      if (params['id']) {
        this.isNewRecord = false;
        if (params['id'] != null) {
          this.actionId = params['id'];
          this.GetActionSearchById();
          this.dropdownSingleSettings = {
            singleSelection: true,
            text: "Select",
            enableSearchFilter: true,
            classes: "borderLessTextBoxGreen",
            badgeShowLimit: 3,
            noDataLabel: 'No record available',
            showCheckbox: false,
            disabled: true
          };
        }
      }
      else {
        this.actionId = 0;
        this.setDropDownSetting();
        this.GetStateData();
        this.GetCarrierCategories();
        this.ActionTypes();
        this.GetClientCompany();

        this.dropdownSingleSettings = {
          singleSelection: true,
          text: "Select",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No record available',
          showCheckbox: false
        };
      }
    });
    this.selectedItemsRecurrance = this.dropdownListRecurrance.filter(x => x.id == 'None');
  }

  GetUpdateCategoryData(): void {

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.updatesService.getUpdateCategory(this.tokenKey).subscribe(
      data => {

        if (data["updateCategory"] != null) {
          let UpdateCategoryData = data["updateCategory"];
          if (UpdateCategoryData != null) {
            if (UpdateCategoryData.length > 0) {
              for (let iCounter = 0; iCounter < UpdateCategoryData.length; iCounter++) {
                this.dropdownListUpdateCategory.push({ "id": parseInt(UpdateCategoryData[iCounter].updateCategoryId), "itemName": UpdateCategoryData[iCounter].name });
              }
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
          this.unblockDocument();
        }

      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  GetActionSearchById() {
    this.actionItemServices.GetActionSearchById(this.tokenKey, this.actionId).subscribe(
      data => {

        if (data['status'] == 'Success') {
          if (data['actionModel'] != null) {

            if (data['actionModel'].actionModel != null) {
              let deadLineDate = new Date(data['actionModel'].actionModel.deadLineDate);

              let dd1 = deadLineDate.getDate().toString();
              if (parseInt(dd1.toString()) < 10)
                dd1 = '0' + dd1.toString();

              let mm1 = (deadLineDate.getMonth() + 1).toString();
              if (parseInt(mm1.toString()) < 10)
                mm1 = '0' + mm1.toString();

              let dtdeadLineDate = mm1 + '-' + dd1 + '-' + deadLineDate.getFullYear();

              this.addUpdateActionItem.setValue({
                'actionItemId': data['actionModel'].actionModel.actionId,
                'clientCompanyId': [],
                'companyUserId': [],
                'deadLineDate': dtdeadLineDate,
                'state': [],
                'types': [],
                'carrierCategories': [],
                'subject': data['actionModel'].actionModel.subject,
                'isNeworChanged': data['actionModel'].actionModel.isNeworChanged,
                'description': data['actionModel'].actionModel.description,
                'isUpdateRequired': data['actionModel'].actionModel.isAction,
                'actionCharges': data['actionModel'].actionModel.actionCharges,
                'actionNotes': data['actionModel'].actionModel.actionNotes,
                'isSendEmailNotification': this.isSendEmailNotificationChecked,
                'isInformationOnly': data['actionModel'].actionModel.isInformationOnly,
                'internalNotes': data['actionModel'].actionModel.internalNotes,
                'recurrance': data['actionModel'].actionModel.recurrance,
                'recurranceId': data['actionModel'].actionModel.recurranceId,
                'search': '',
                'alertText': data['actionModel'].actionModel.alertText,
                'updateCategories':[]
              });

              if (data['actionModel'].actionModel.isInformationOnly)
                this.isThisUpdateRequiresActionToPerform = false;
              else
                this.isThisUpdateRequiresActionToPerform = true;
              this.isEnableDisable = data['actionModel'].actionModel.isAction;
              this.selectedItemsRecurrance = this.dropdownListRecurrance.filter(x => x.id == data['actionModel'].actionModel.recurrance);

            }

            this.selectedItemsTypes = [];
            this.listOfTypes = [];
            if (data['actionModel'].actionModel.actionItemTypeId > 0) {
              this.selectedItemsTypes.push({ "id": data['actionModel'].actionModel.actionItemTypeId, "itemName": data['actionModel'].actionModel.actionItemTypeName });
              this.listOfTypes.push(data['actionModel'].actionModel.actionItemTypeId);
            }

            this.selectedItemsclientCompany = [];
            this.listOfCompany = [];
            if (data['actionModel'].actionModel.companyId > 0) {
              this.selectedItemsclientCompany.push({ "id": data['actionModel'].actionModel.companyId, "itemName": data['actionModel'].actionModel.companyName });
              this.listOfCompany.push(data['actionModel'].actionModel.companyId);
              this.GetCompanyUser(data['actionModel'].actionModel.companyId);
            }

            this.selectedItemsclientCompanyUser = [];
            this.listOfUsers = [];
            if (data['actionModel'].companyUserUpdateModel.length > 0) {
              this.selectedItemsclientCompanyUser = [];
              for (let iCounter = 0; iCounter < data['actionModel'].companyUserUpdateModel.length; iCounter++) {
                this.selectedItemsclientCompanyUser.push({ "id": data['actionModel'].companyUserUpdateModel[iCounter].userId, "itemName": data['actionModel'].companyUserUpdateModel[iCounter].userName });
                this.listOfUsers.push(data['actionModel'].companyUserUpdateModel[iCounter].userId);
              }
            }

            if (data['actionModel'].actionStateModel.length > 0) {
              this.selectedItemsState = [];
              this.listOfState = [];
              for (let iCounter = 0; iCounter < data['actionModel'].actionStateModel.length; iCounter++) {
                this.selectedItemsState.push({ "id": data['actionModel'].actionStateModel[iCounter].stateId, "itemName": data['actionModel'].actionStateModel[iCounter].stateName });
                this.listOfState.push(data['actionModel'].actionStateModel[iCounter].stateId);
              }
            }

            if (data['actionModel'].actionCarrierModel.length > 0) {
              this.selectedItemsCategory = [];
              this.listOfCategory = [];
              for (let iCounter = 0; iCounter < data['actionModel'].actionCarrierModel.length; iCounter++) {
                this.selectedItemsCategory.push({ "id": data['actionModel'].actionCarrierModel[iCounter].carrierCatId, "itemName": data['actionModel'].actionCarrierModel[iCounter].carrierName });
                this.listOfCategory.push(data['actionModel'].actionCarrierModel[iCounter].carrierCatId);
              }
            }

            if (data['actionModel'].actionModel.updateCategoryId != null) {
              this.selectedItemsUpdateCategory = [];
              this.listOfUpdateCategory = [];
              this.listOfUpdateCategory=data['actionModel'].actionModel.updateCategoryId;
              if (this.dropdownListUpdateCategory.length > 0) {
                var Updatedata = this.dropdownListUpdateCategory.filter(a => a.id == data['actionModel'].actionModel.updateCategoryId);
                if (Updatedata.length > 0 && Updatedata != null) {
                  this.selectedItemsUpdateCategory.push({ "id": Updatedata[0].id, "itemName": Updatedata[0].itemName });
                }
              }
              else {
                setTimeout(() => {
                  var Updatedata = this.dropdownListUpdateCategory.filter(a => a.updateCategoryId == data['actionModel'].actionModel.updateCategoryId);
                  if (Updatedata.length > 0 && Updatedata != null) {
                    this.selectedItemsUpdateCategory.push({ "id": Updatedata[0].id, "itemName": Updatedata[0].itemName });
                  }
                }, 800);
              }

            }

            this.GetBrifingsDataandSelect(data['actionModel'].actionModel.briefing);
          }

          this.setDropDownSetting();
          this.GetStateData();
          this.GetCarrierCategories();
          this.ActionTypes();
          this.GetClientCompany();
        }
      });
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  setDropDownSetting() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: 'Select Jurisdiction(s)',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction available',
      showCheckbox: true,
      enableFilterSelectAll: true
    };

    this.dropdownSettingsTypes = {
      singleSelection: true,
      text: 'Select Category(s)',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No category(s) available',
      showCheckbox: true,
      enableFilterSelectAll: true
    };

    this.dropdownSettingsCategory = {
      singleSelection: false,
      text: 'Select Carrier Type(s)',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No carrier type(s) available',
      showCheckbox: true,
      enableFilterSelectAll: true
    };

    this.dropdownSettingsUpdateCategory = {
      singleSelection: true,
      text: 'Select Update Category(s)',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No Update category(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingclientCompany = {
      singleSelection: true,
      text: "Select Company",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No data available for selected company',
      showCheckbox: false
    };

    this.dropdownSettingclientCompanyUser = {
      singleSelection: false,
      text: "Select User",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No data available for selected user',
      showCheckbox: true
    };
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  GetStateData(): void {
    this.stateMasterService.getState(this.tokenKey, this.productId).subscribe(
      data => {
        if (data["state"] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data["state"].length; iCounter++) {
            this.dropdownListState.push({ "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name });
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');

        }
        this.dropdownSettingsState = {
          singleSelection: false,
          text: "Select Jurisdiction(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No Jurisdiction available',
          showCheckbox: true
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  GetCarrierCategories() {
    this.actionItemServices.GetCarrierCategories(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          let carrierCategories = data['companyModel'];
          if (carrierCategories != null) {
            if (carrierCategories.length > 0) {
              for (let iCounter = 0; iCounter < carrierCategories.length; iCounter++) {
                if (carrierCategories[iCounter].isActive)
                  this.dropdownListCategory.push({ "id": parseInt(carrierCategories[iCounter].carrierCatId), "itemName": carrierCategories[iCounter].carrierCatName });
              }
            }
          }
        }

        this.dropdownSettingsCategory = {
          singleSelection: false,
          text: "Select Carrier Type(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No carrier type(s) available',
          showCheckbox: true
        };
      });
  }

  ActionTypes(): void {
    this.dropdownListTypes = [];
    this.actionItemServices.GetActionItemsType(this.tokenKey).subscribe(
      data => {
        if (data["actionType"] != null && data["status"] != "Failure") {
          for (let iCounter = 0; iCounter < data["actionType"].length; iCounter++) {
            // Remove Company Specific
            if (data["actionType"][iCounter].actionItemTypeId != 5) {
              this.dropdownListTypes.push(
                { "id": data["actionType"][iCounter].actionItemTypeId, "itemName": data["actionType"][iCounter].typeName }
              );
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
        this.dropdownSettingsTypes = {
          singleSelection: true,
          text: "Select Category(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No  category(s) Available',
          showCheckbox: false,
          enableFilterSelectAll: false,
          enableCheckAll: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  isDeadlineDate(dateArray: any) {
    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      this.addNewActionModel.deadLineDate = mm1 + '-' + dd1 + '-' + dateArray.getFullYear();
    }
    else
      this.addNewActionModel.deadLineDate = null;
  }

  GetClientCompany() {
    this.clientCompanyList = [];
    this.updatesService.GetClientCompany(this.tokenKey, this.productId, this.systemUserCondition).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.clientCompanyList = data['clientCompanay'];

          if (this.clientCompanyList != null) {
            if (this.clientCompanyList.length > 0) {
              for (let iCounter = 0; iCounter < this.clientCompanyList.length; iCounter++) {
                this.dropdownListclientCompany.push(
                  { "id": this.clientCompanyList[iCounter].companyId, "itemName": this.clientCompanyList[iCounter].companyName }
                );
              }
            }
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  onSelectedCompany($event) {

    this.listOfCompany = [];
    let companyId = $event.id;
    this.selectedItemsclientCompanyUser = [];
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
        this.listOfCompany.push($event.id);
      }
      else {
        this.listOfCompany.push($event.id);
      }
    }
    this.GetCompanyUser(companyId);
  }

  onDeSelectedCompany($event) {
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
      }
    }
  }

  onSelectAllCompany($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllCompany($event) {
    this.dropdownListclientCompanyUser = [];
    this.selectedItemsclientCompanyUser = [];
    this.listOfUsers = [];
    this.listOfCompany = [];
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  // Users 
  onSelectedUsers($event) {

    if (this.listOfUsers != null) {
      if (this.listOfUsers.length > 0) {
        this.listOfUsers = this.removeElemetninArray(this.listOfUsers, $event.id)
        this.listOfUsers.push($event.id);
      }
      else {
        this.listOfUsers.push($event.id);
      }
    }
  }

  onDeSelectedUsers($event) {
    if (this.listOfUsers != null) {
      if (this.listOfUsers.length > 0) {
        this.listOfUsers = this.removeElemetninArray(this.listOfUsers, $event.id)
      }
    }
  }

  onSelectAllUsers($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfUsers != null) {
        if (this.listOfUsers.length > 0) {
          this.listOfUsers = this.removeElemetninArray(this.listOfUsers, $event[iCounter].id)
          this.listOfUsers.push($event[iCounter].id);
        }
        else {
          this.listOfUsers.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllUsers($event) {
    this.listOfUsers = [];
  }

  // Action Type
  onSelectedActionType($event) {
    this.listOfTypes = [];
    if (this.listOfTypes != null) {
      if (this.listOfTypes.length > 0) {
        this.listOfTypes = this.removeElemetninArray(this.listOfTypes, $event.id)
        this.listOfTypes.push($event.id);
      }
      else {
        this.listOfTypes.push($event.id);
      }
    }
  }

  onDeSelectedActionType($event) {
    if (this.listOfTypes != null) {
      if (this.listOfTypes.length > 0) {
        this.listOfTypes = this.removeElemetninArray(this.listOfTypes, $event.id)
      }
    }
  }

  onDeSelectAllActionType($event) {
    this.listOfTypes = [];
  }

  // State 
  onSelectedState($event) {
    if (this.listOfState != null) {
      if (this.listOfState.length > 0) {
        this.listOfState = this.removeElemetninArray(this.listOfState, $event.id)
        this.listOfState.push($event.id);
      }
      else {
        this.listOfState.push($event.id);
      }
    }
  }

  onDeSelectedState($event) {
    if (this.listOfState != null) {
      if (this.listOfState.length > 0) {
        this.listOfState = this.removeElemetninArray(this.listOfState, $event.id)
      }
    }
  }

  onSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfState != null) {
        if (this.listOfState.length > 0) {
          this.listOfState = this.removeElemetninArray(this.listOfState, $event[iCounter].id)
          this.listOfState.push($event[iCounter].id);
        }
        else {
          this.listOfState.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllState($event) {
    this.listOfState = [];
  }

  // Category
  onSelectedCategory($event) {
    if (this.listOfCategory != null) {
      if (this.listOfCategory.length > 0) {
        this.listOfCategory = this.removeElemetninArray(this.listOfCategory, $event.id)
        this.listOfCategory.push($event.id);
      }
      else {
        this.listOfCategory.push($event.id);
      }
    }
  }

  onDeSelectedCategory($event) {
    if (this.listOfCategory != null) {
      if (this.listOfCategory.length > 0) {
        this.listOfCategory = this.removeElemetninArray(this.listOfCategory, $event.id)
      }
    }
  }

  onSelectAllCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategory != null) {
        if (this.listOfCategory.length > 0) {
          this.listOfCategory = this.removeElemetninArray(this.listOfCategory, $event[iCounter].id)
          this.listOfCategory.push($event[iCounter].id);
        }
        else {
          this.listOfCategory.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllCategory($event) {
    this.listOfCategory = [];
  }

  onSelectedUpdateCategory($event) {
    if (this.listOfUpdateCategory != null) {
      if (this.listOfUpdateCategory.length > 0) {
        this.listOfUpdateCategory = [];
        this.listOfUpdateCategory.push($event.id);
      }
      else {
        this.listOfUpdateCategory.push($event.id);
      }
    }
  }

  onDeSelectedUpdateCategory($event) {
    if (this.listOfUpdateCategory != null) {
      if (this.listOfUpdateCategory.length > 0) {
        this.listOfUpdateCategory = this.removeElemetninArray(this.listOfUpdateCategory, $event.id)
      }
    }
  }

  onDeSelectAllUpdateCategory($event) {
    this.listOfUpdateCategory = [];
  }

  GetCompanyUser(companyId: any) {
    this.dropdownListclientCompanyUser = [];
    this.userServices.GetUsersFindByCompanyId(this.tokenKey, companyId, this.productId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          if (data['companyUsers'] != null) {
            if (data['companyUsers'].length > 0) {
              this.userModel = data['companyUsers'];

              for (let iCounter = 0; iCounter < this.userModel.length; iCounter++) {
                if (this.userModel[iCounter].adminUserId != -1) {
                  this.dropdownListclientCompanyUser.push(
                    { "id": this.userModel[iCounter].userId, "itemName": this.userModel[iCounter].userName }
                  );
                }
              }

              this.dropdownSettingclientCompanyUser = {
                singleSelection: false,
                text: "Select User",
                enableSearchFilter: true,
                classes: "borderLessTextBoxGreen",
                badgeShowLimit: 2,
                noDataLabel: 'No data available for selected user',
                showCheckbox: true
              };

            }
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  setAddUpdateActionItem(addUpdateFormControl: any) {
    this.updatevalueset = addUpdateFormControl;
  }

  UpdateData(updateVal: any) {
    this.updateSingleRecord = updateVal;
    this.saveAddUpdateActionItem(this.updatevalueset, true);
    this.updatevalueset = '';
  }

  saveAddUpdateActionItem(addUpdateFormControl: any, isclickfrompopup: boolean) {

    if (this.selectedItemsRecurrance[0].id != "None" && !isclickfrompopup) {
      document.getElementById("btnopenEditpopup").click();
      return;
    }

    if (this.listOfCompany.length == 0) {
      this.addNewActionModel.companyId = 0;
    }
    else {
      this.addNewActionModel.companyId = this.listOfCompany[0];
    }

    if (this.listOfCompany.length == 0 && this.listOfUsers.length > 0) {
      this.showMessage('Please select company.', 'Required', 'error');
      return;
    }

    if (this.listOfUsers.length == 0) {
      this.addNewActionModel.assignToUser = '0'
    }
    else {
      this.addNewActionModel.assignToUser = this.listOfUsers.join(',');
    }

    if (typeof (addUpdateFormControl.value['deadLineDate']) == 'undefined' || addUpdateFormControl.value['deadLineDate'] == '') {
      this.showMessage('Please select deadline date', 'Error', 'error');
      return;
    }

    if (this.listOfState.length == 0) {
      this.showMessage('Please select Jurisdiction', 'Error', 'error');
      return;
    }

    if (this.listOfTypes.length == 0) {
      this.showMessage('Please select category', 'Error', 'error');
      return;
    }

    if (this.listOfCategory.length == 0) {
      this.showMessage('Please select carrier types', 'Error', 'error');
      return;
    }

    if (this.listOfUpdateCategory.length == 0) {
      this.showMessage('Please select Update Category', 'Error', 'error');
      return;
    }

    if (addUpdateFormControl.value['subject'] == '' || typeof (addUpdateFormControl.value['subject']) == 'undefined') {
      this.showMessage('Please enter subject', 'Error', 'error');
      return;
    }

    if (addUpdateFormControl.value['description'] == '' || typeof (addUpdateFormControl.value['description']) == 'undefined') {
      this.showMessage('Please enter description', 'Error', 'error');
      return;
    }

    if (this.listOfState.length == 0) {
      this.addNewActionModel.state = '0';
    }
    else {
      this.addNewActionModel.state = this.listOfState.join(',');
    }

    if (this.listOfCategory.length == 0) {
      this.addNewActionModel.carrierCategories = '0';
    }
    else {
      this.addNewActionModel.carrierCategories = this.listOfCategory.join(',');
    }

    if (this.listOfUpdateCategory.length == 0) {
      this.addNewActionModel.updateCategoryId = 0;
    }
    else {
      this.addNewActionModel.updateCategoryId = this.listOfUpdateCategory[0];
    }

    if (this.listOfTypes.length == 0) {
      this.addNewActionModel.actionType = '0'
    }
    else {
      this.addNewActionModel.actionType = this.listOfTypes.join(',');
    }
    if (addUpdateFormControl.value["isUpdateRequired"] == false || typeof (addUpdateFormControl.value["isUpdateRequired"]) == 'undefined') {
      this.addNewActionModel.actionCharges = '0';
      this.addNewActionModel.actionNotes = '';
      this.addNewActionModel.isAction = false;
    }
    else {
      this.addNewActionModel.actionCharges = addUpdateFormControl.value['actionCharges'] == '' ? 0 : addUpdateFormControl.value['actionCharges'];
      this.addNewActionModel.actionNotes = addUpdateFormControl.value['actionNotes'];
      this.addNewActionModel.isAction = true;
    }

    if (addUpdateFormControl.value['actionItemId'] != 'undefined' && addUpdateFormControl.value['actionItemId'] != undefined) {
      let ActionItemCloneId = +sessionStorage.getItem('ActionItemClone');
      if (ActionItemCloneId > 0)
        this.addNewActionModel.actionItemId = 0;
      else
        this.addNewActionModel.actionItemId = addUpdateFormControl.value['actionItemId'];
    }
    else
      this.addNewActionModel.actionItemId = 0;

    if (typeof (addUpdateFormControl.value['description']) != undefined)
      this.addNewActionModel.description = addUpdateFormControl.value['description'];
    else
      this.addNewActionModel.description = null;

    if (typeof (addUpdateFormControl.value['internalNotes']) != undefined)
      this.addNewActionModel.internalNotes = addUpdateFormControl.value['internalNotes'];
    else
      this.addNewActionModel.internalNotes = null;

    if (typeof (addUpdateFormControl.value['subject']) != undefined)
      this.addNewActionModel.subject = addUpdateFormControl.value['subject'];
    else
      this.addNewActionModel.subject = null;

    if (typeof (addUpdateFormControl.value['alertText']) != undefined)
      this.addNewActionModel.alertText = addUpdateFormControl.value['alertText'];
    else
      this.addNewActionModel.alertText = null;

    this.addNewActionModel.createdBy = +sessionStorage.getItem('AdminUserId');

    if (addUpdateFormControl.value['isNeworChanged'] == '')
      this.addNewActionModel.isNeworChanged = false;
    else
      this.addNewActionModel.isNeworChanged = addUpdateFormControl.value['isNeworChanged']

    this.addNewActionModel.isSendEmailNotification = this.isSendEmailNotificationChecked;

    if (addUpdateFormControl.value['isInformationOnly'] == '')
      this.addNewActionModel.isInformationOnly = false;
    else {
      this.addNewActionModel.isInformationOnly = addUpdateFormControl.value['isInformationOnly']
    }

    if (this.addNewActionModel.isInformationOnly == true) {
      this.addNewActionModel.actionCharges = '0';
      this.addNewActionModel.actionNotes = '';
      this.addNewActionModel.isAction = false;
    }

    if (this.selectedItemsRecurrance.length > 0)
      this.addNewActionModel.recurrance = this.selectedItemsRecurrance[0].id;

    this.addNewActionModel.recurranceId = addUpdateFormControl.value['recurranceId'];

    if (this.listOfBriefing.length == 0) {
      this.addNewActionModel.briefing = '0';
    }
    else {
      this.addNewActionModel.briefing = this.listOfBriefing.join(',');
    }

    if (this.selectedItems == "1") {
      this.addNewActionModel.updateSingleRecord = true;
    }
    else {
      this.addNewActionModel.updateSingleRecord = false;
    }

    this.blockDocument();
    this.actionItemServices.AdminAddUpdateActionItem(this.tokenKey, this.addNewActionModel).subscribe(
      data => {
        this.unblockDocument();
        if (data['status'] == 'Success') {
          let ActionItemClone = sessionStorage.getItem('ActionItemClone');
          if (data['actionId'] > 0)
            if (this.actionId == 0)
              this.showMessage('Calendar item saved successfully', 'Saved', 'success');
            else if (+ActionItemClone > 0) {
              this.showMessage('Calendar item clone successfully', 'Clone', 'success');
            }
            else {
              this.showMessage('Calendar item updated successfully', 'Updated', 'success');
            }

          this.router.navigate(['/admin/trac-it/entries']);
        }
        sessionStorage.removeItem('ActionItemClone');
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  setEnableDisable($event) {

    this.isEnableDisable = $event;
    if (!$event) {
      this.actionChargesValue = 0;
      this.actionNotesValue = '';
    }
  }

  isNumberKey(evt) {

    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  redirectToBack() {
    sessionStorage.removeItem('ActionItemClone');
    this.router.navigate(['/admin/trac-it/entries']);
  }

  setSendEmailNotificationChecked($event) {
    this.isSendEmailNotificationChecked = $event.target.checked;
  }

  setEnableDisableIsRequires($event) {

    this.isThisUpdateRequiresActionToPerform = !$event.currentTarget.checked;
    if ($event.currentTarget.checked == true) {
      this.isUpdateRequiredChecked = false;
      this.isEnableDisable = false;
      this.actionChargesValue = 0;
      this.actionNotesValue = '';
    }
  }

  GetAllRecurrance() {
    this.dropdownListRecurrance.push({ "id": 'None', "itemName": "None" });
    this.dropdownListRecurrance.push({ "id": 'Annual', "itemName": "Annual" });
    this.dropdownListRecurrance.push({ "id": 'Quarterly', "itemName": "Quarterly" });
    this.dropdownListRecurrance.push({ "id": 'Every 2 months', "itemName": "Every 2 months" });
    this.dropdownListRecurrance.push({ "id": 'Every 1 month', "itemName": "Every 1 month" });
    this.dropdownListRecurrance.push({ "id": 'Every 2 weeks', "itemName": "Every 2 weeks" });
  }

  // Briefing 
  onSelectedBriefing($event) {
    this.listOfBriefing = [];
    this.listOfBriefing.push($event.id);
  }

  onDeSelectedBriefing($event) {
    if (this.listOfBriefing != null) {
      if (this.listOfBriefing.length > 0) {
        this.listOfBriefing = this.removeElemetninArray(this.listOfBriefing, $event.id)
      }
    }
  }

  GetBrifingsDataandSelect(id: any) {
    this.actionItemServices.GetBrifingsData(this.tokenKey, id).subscribe(
      data => {
        if (data['status'] == 'Success') {
          if (data['briefingData'] != null) {
            if (data['briefingData'].length > 0) {
              this.searchTickers = data['briefingData'];
              this.search = data['briefingData'][0].title;
              this.listOfBriefing = [];
              this.listOfBriefing.push(data['briefingData'][0].briefingId);
              this.selectedVal = id
            }
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  searchFunc($event) {
    if ($event.target.value == "") {
      this.show = false;
      this.searchTickers = [];
    }
    else {
      this.actionItemServices.GetBrifingsDDLData(this.tokenKey, $event.target.value).subscribe(
        data => {
          if (data['status'] == 'Success') {
            if (data['briefingData'] != null) {
              if (data['briefingData'].length > 0) {
                this.show = true
                this.searchTickers = data['briefingData'];
              }
              else { this.searchTickers = [] }
            }
          }
        },
        error => {
          this.showMessage(error, 'Error', 'error');
        }
      );
    }
  }

  hide() {
    this.show = false
  }
  clear() {
    this.show = false
  }
  Show() {
    this.show = this.show = true ? !this.show : this.show
  }

  getStockImage(symbol) {
    var a = "";
  }

  onSelectedBriefing1(val: any, strval: string) {
    if (this.listOfBriefing[0] == val) {
      this.search = "";
      this.listOfBriefing = [];
      this.selectedVal = 0;
    } else {
      this.search = strval;
      this.listOfBriefing = [];
      this.listOfBriefing.push(val);
      this.selectedVal = val;
    }
  }

  BindBrifingData() {
    this.actionItemServices.GetBrifingsDDLData(this.tokenKey, null).subscribe(
      data => {
        if (data['status'] == 'Success') {
          if (data['briefingData'] != null) {
            if (data['briefingData'].length > 0) {
              this.show = true
              this.searchTickers = data['briefingData'];
            }
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }
}
