import { Component, HostListener, OnInit } from '@angular/core';
import { CheckAuth } from 'src/Services/utility.service';
import { CountyMasterService } from 'src/Services/county-master.service';
import { CountyMaster911Model } from 'src/Models/CountyMaster911Model';
import { Router } from '@angular/router';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CityMasterService } from 'src/Services/city-master.service';
import { CityMaster911Model } from 'src/Models/CityMaster911Model';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})
export class CityComponent implements OnInit {
  tokenKey: string;
  listofCity: CityMaster911Model[];
  PageNumber: number = 1;
  PageSize: number = 10;
  isRecord: boolean = false;
  isloading: boolean = false;
  totalRecordCount: number = 0;
  stateId: string = ' ';
  filterText: string = ' ';
  SelectedTool: string;
  stateList: StateMasterModel[] = [];
  countyId: string = ' ';
  countyList: CountyMaster911Model[] = [];
  selectedCounty;
  orderBy: string = 'SortOrder';
  isAscending: boolean = false;
  upDowNameCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowCountyNameCSS: string = '';
  upDowActiveCSS: string = '';
  upDowSortCSS:string = '';

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  //county
  selectedItemsConuty = [];
  selectedItemsCounty = [];
  dropdownSettingsCounty = {};
  listOfCountyIds = [];
  module='911';
  cityId:number=0;
  openMenuIndex: number = -1;

  constructor(private countyService: CountyMasterService, private toastr: ToastrService, private stateService: StateMasterService, private router: Router, private checkAuth: CheckAuth, private cityService: CityMasterService,private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('City');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsCounty = {
      singleSelection: false,
      text: "Filter by County",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No County(s) available',
      showCheckbox: true
    };
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.GetAllCity();
  }

  keywordFilters($event) {
    if ($event.target.value != "" && $event.target.value != null)
      this.filterText = $event.target.value;
    else
      this.filterText = ' ';
    this.GetAllCity();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetAllCity();
  }

  GetAllCity() {
    this.isloading = true;
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.cityService.GetAllCity(this.tokenKey, this.PageNumber, this.PageSize, this.filterText, this.stateId, this.countyId, this.orderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofCity = data['city'];
        if (this.listofCity != null) {
          if (this.listofCity.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofCity[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { }
    );
  }

  addCity() {
    let navigation = '/admin/' + this.SelectedTool + '/city/manage';
    this.router.navigate([navigation]);
  }

  updateCity(cityId) {
    let navigation = '/admin/' + this.SelectedTool + '/city/manage/' + cityId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.cityId = id;
  }

  deleteCity(cityId) {
    this.isloading = true;
    this.cityService.DeleteCity(this.tokenKey, cityId).subscribe(
      data => {
        this.GetAllCity();
        this.toastr.success('City deleted successfully.', 'Delete')
      },
      error => { }
    );
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey,this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {          
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name });
        });
        }
      },
      error => { }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetCountyByStateId(this.stateId);
    this.GetAllCity();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.listOfStateIds.length == 0) {
      this.selectedItemsConuty = [];
      this.selectedItemsCounty = [];
      this.listOfCountyIds = [];
    }
    else
      this.GetCountyByStateId(this.stateId);
      this.PageNumber = 1;
      this.PageSize = 10;
    this.GetAllCity();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetCountyByStateId(this.stateId);
    this.GetAllCity();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.listOfCountyIds = [];
    this.selectedItemsCounty = [];
    this.selectedItemsConuty = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCity();
  }

  onSelectCounty($event) {
    this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
    this.listOfCountyIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.stateId = this.listOfCountyIds.join(',')
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCity();
  }

  onDeSelectCounty($event) {
    if (this.listOfCountyIds != null) {
      if (this.listOfCountyIds.length > 0) {
        this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
      }
    }
    if (this.listOfCountyIds.length > 0) {
      this.stateId = this.listOfCountyIds.join(',')
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCity();
  }

  onSelectAllCounty($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCity();
  }

  onDeSelectAllCounty($event) {
    this.listOfCountyIds = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCity();
  }

  GetCountyByStateId(stateId) {
    this.countyService.GetCountyByStateId(this.tokenKey, stateId,true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyList = result['county'];
          this.selectedItemsConuty = [];
          if (this.countyList.length > 0) {
            let b = [];
            this.selectedItemsCounty = [];
            this.countyList.forEach(x => {
              let a = this.listOfCountyIds.filter(e => e == x.countyId);
              if (a.length > 0) {
                b.push(x.countyId);
                this.selectedItemsCounty.push({ "id": x.countyId, "itemName": x.countyName })
              }
              this.selectedItemsConuty.push({ "id": x.countyId, "itemName": x.countyName })
            });
            this.listOfCountyIds = b;
          }
          else {
            this.selectedItemsCounty = [];
            this.listOfCountyIds = [];
          }
        }
      });
  }


  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';

      if (sortBy === "CityName") {
        this.upDowNameCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
        this.upDowCountyNameCSS = '';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "StateId") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowCountyNameCSS = '';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "CountyId") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowCountyNameCSS = 'fa fa-arrow-down';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "sortOrder") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowCountyNameCSS = '';
        this.upDowSortCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowCountyNameCSS = '';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      if (sortBy === "CityName") {
        this.upDowNameCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
        this.upDowCountyNameCSS = '';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "StateId") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-up';
        this.upDowCountyNameCSS = '';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "CountyId") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowCountyNameCSS = 'fa fa-arrow-down';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "sortOrder") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowCountyNameCSS = '';
        this.upDowSortCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowCountyNameCSS = '';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-up';
      }
    }
    this.GetAllCity();
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetCountyByStateId(this.stateId);
    this.GetAllCity();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
      this.GetCountyByStateId(this.stateId);
    }
    else{
      this.selectedItemsConuty=[];
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    
    this.GetAllCity();
  }

  onFilterSelectAllCounty($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCity();
  }

  onFilterDeSelectAllCounty($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
        }
      }
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCity();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
