import { Component, OnInit, ViewChild } from "@angular/core";
import { CheckAuth } from "src/Services/utility.service";
import { CountyMasterService } from "src/Services/county-master.service";
import { Router } from "@angular/router";
import { StateMasterService } from "src/Services/state-master.service";
import { CityMasterService } from "src/Services/city-master.service";
import { Main911Service } from "src/Services/main911.service";
import { Main911Model } from "src/Models/Main911Model";
import { DatePipe } from "@angular/common";
import { I360Service } from "src/Services/i360Service";
import { FilterModel } from "src/Models/filterModel";
import * as FileSaver from "file-saver";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Location911Service } from "src/Services/location911.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";

@Component({
  selector: "app-import-rate-main",
  templateUrl: "./import-rate-main.component.html",
  styleUrls: ["./import-rate-main.component.css"],
})
export class ImportRateMainComponent implements OnInit {
  @ViewChild("fileInput", { static: true }) fileInput;
  exportDoc: any;
  tokenKey: string;
  listofMain911: Main911Model[];
  PageNumber: number = 1;
  PageSize: number = 10;
  isRecord: boolean = false;
  isloading: boolean = false;
  totalRecordCount: number = 0;
  stateId: string = " ";
  filterText: string = " ";
  SelectedTool: string;
  isErrorOnBriefingDoc: boolean;
  isErrorOnSupportedDoc: boolean;
  duplicateRate: number;
  failedRate: number;
  successRate: number;
  totalRate: number;
  isSuccess: boolean = false;
  responseFailedFilename: any = "";
  filterModel = new FilterModel();
  AppUrl:string="";
  progress:number=0;
  isShowprogressSpinner = false;
  constructor(
    private countyService: CountyMasterService,
    private locationService: Location911Service,
    private stateService: StateMasterService,
    private router: Router,
    private checkAuth: CheckAuth,
    private cityService: CityMasterService,
    private main911Service: Main911Service,
    private datePipe: DatePipe,
    private i360service: I360Service,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    this.tokenExpireCalculation(
      sessionStorage.getItem("appStartTime"),
      sessionStorage.getItem("tokenExpiryValue")
    );
    this.SelectedTool = sessionStorage.getItem("UserLoginTool");
    this.tokenKey = sessionStorage.getItem("tokenValue");
    this.setAPIUrl();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {}
  uploadFile() {
    this.isSuccess = false;
    if (!this.exportDoc) {
      this.showMessage("File is required", "Warning", "warning");
      return;
    }
    this.isShowprogressSpinner=true;
   
    let formData = new FormData();
    formData.append("uploadFile", this.exportDoc);
    this.main911Service.UploadExcel(this.tokenKey, formData).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
             if (event.body["status"] == "Success") {
          this.isSuccess = true;
          this.isShowprogressSpinner=false;
          this.duplicateRate = event.body["duplicateRate"];
          this.failedRate = event.body["failedRate"];
          this.successRate = event.body["successRate"];
          this.totalRate = event.body["totalRate"];
          if(event.body["responseFailedFilename"] !="")this.responseFailedFilename = event.body["responseFailedFilename"];
        } else {
          this.showMessage(event.body["status"], "Error", "error");
        }
            setTimeout(() => {
            }, 1500);
        }
      },
      (error) => {
        this.showMessage(error, "Error", "error");
        this.isShowprogressSpinner=false;
      }
    );
    this.setAPIUrl();
  }
  fileEvent(event): any {
    this.exportDoc = null;
    this.progress=0;
    this.isSuccess = false;
    //*-- this function gets content of uploaded file and validation -- * /
    var file = event.target.files[0];
    let size = Math.round(file.size / 1024);
    if (size <= 21000) {
      var allowedExtensions = ["xls", "xlsx", "csv"];
      let fileType = file.name.split(".").pop();

      if (!this.isInArray(allowedExtensions, fileType)) {
        this.showMessage(
          "You have selected an invalid file type.System supports following file formats: xls,xlsx,csv",
          "Error",
          "error"
        );
        this.isErrorOnBriefingDoc = true;

        window.scrollTo(0, 0);
      } else {
        this.isErrorOnBriefingDoc = false;
        this.exportDoc = file;
      }
    } else {
      this.showMessage(
        "You have selected a larger file. supports file upto 20MB.",
        "Error",
        "error"
      );
      this.isErrorOnBriefingDoc = true;
      window.scrollTo(0, 0);
    }
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == "success") {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    } else if (msgType == "error") {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    } else if (msgType == "warning") {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  exportFailRate(file){
    if (file) {
      if(file=="RateImportSample"){
        this.filterModel.docPath =this.AppUrl+'\ExcelImportDocument\\'+'RateImportSample.csv';
        this.filterModel.docType = 'RateImportSample';
        FileSaver.saveAs(this.AppUrl+'\ExcelImportDocument\\'+'RateImportSample.csv', 'RateImportSample.csv');
      }
      else{
        this.isShowprogressSpinner=true;
        this.filterModel.docPath = this.responseFailedFilename;
        this.filterModel.docType = 'RateImportToFailed';
        this.i360service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
          fileData => {
            if (fileData != null) {
              if(file=="RateImportSample") FileSaver.saveAs(fileData, 'RateImportSample.csv');
             else  FileSaver.saveAs(fileData, 'ExcelImportToFailed.csv');
            }
            this.isShowprogressSpinner=false;
          }, error => {
            this.isShowprogressSpinner=false;
            console.log(error);
          }
        );
      }
    }
  }
  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }}
}

                                   