import { Inject, Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class NLADClaimService {
    AppUrl: string = "";
    constructor(private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.setAPIUrl();
    }

    private handleError(error: Response | any) {
        console.error(error.message || error);
        return observableThrowError(error.status)
    }

    setAPIUrl() {
        if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
            this.AppUrl = sessionStorage.getItem("BaseURL") || '';
        }
    }

    GetNLADFileFormat(): Observable<any> {
        this.setAPIUrl();
        return this._httpClient.get(this.AppUrl + `api/NLADClaim/GetNLADFileFormat`)
            .pipe(map(response => {
                return response;
            }));
    }

    UploadExcelFile(formData: FormData): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/UploadExcelFile`, formData, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetColumnArray(model: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetColumnArray`, model, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    SaveColumnMapping(model: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/SaveColumnMapping`, model, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetInrollClientCompany(): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetInrollClientCompany`, {}, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetColumnMappingList(filterModel: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetColumnMappingList`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    EBBClaimImport(formData: FormData): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/EBBClaimImport`, formData, httpOptions)
            .pipe(timeout(180000),map(response => {
                return response;
            }),catchError(err => {
                if (err.name === 'TimeoutError') {
                    return '0';  
                }
              })
            );
    }

    EBBClaimDestinationFileImport(formData: FormData): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/EBBClaimDestinationFileImport`, formData, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    EBBDestinationDataImport(formData: FormData): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/EBBDestinationDataImport`, formData, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    EBBClaimImportFinal(formData: FormData): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/EBBClaimImportFinal`, formData, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    EBBClaimOtherImport(formData: FormData): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/EBBClaimOtherImport`, formData, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    EBBClaimCountReports(tokenKey, filterModel: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/EBBClaimCountReports`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetClaimYears(tokenKey, provider: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetClaimYears`, provider, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetClaimState(tokenKey): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetClaimState`, {}, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetClaimProcessDetailsReports(tokenKey, filterModel: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetClaimProcessDetailsReports`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetClaimProcessMasterPaged(filterModel: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetClaimProcessMasterPaged`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetColumnMappedByCompanyListPaged(filterModel: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetColumnMappedByCompanyListPaged`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    ViewClaimProcessDetailsPaged(tokenKey, filterModel: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/ViewClaimProcessDetailsPaged`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetNLADFileFormatFindByProviderId(providerId): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetNLADFileFormatFindByProviderId/` + providerId, { 'providerId': providerId }, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetColumnArrayFindByProvideId(providerId): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetColumnArrayFindByProvideId/` + providerId, { 'providerId': providerId }, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    MappedColumnDelete(providerId): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/MappedColumnDelete/` + providerId, { 'providerId': providerId }, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    ClaimDataDelete(id: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/ClaimDataDelete/` + id, { 'ClaimMasterId': id }, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    EBBClaimTotalCountReports(tokenKey, filterModel: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/EBBClaimTotalCountReports`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    EBBClaimYearCountReports(tokenKey, filterModel: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/EBBClaimYearCountReports`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetLifelineClaimProcessMasterPaged(filterModel: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetLifelineClaimProcessMasterPaged`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    LifelineClaimDataDelete(id: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/LifelineClaimDataDelete/` + id, { 'ClaimMasterId': id }, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetLifelineColumnMappedByCompanyListPaged(filterModel: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetLifelineColumnMappedByCompanyListPaged`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    MappedLifelineColumnDelete(providerId): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/MappedLifelineColumnDelete/` + providerId, { 'providerId': providerId }, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    LifelineClaimYearCountReports(tokenKey, filterModel: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/LifelineClaimYearCountReports`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    LifelineMonthlyClaimCountReports(tokenKey, filterModel: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/LifelineMonthlyClaimCountReports`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    LifelineClaimSummaryReports(tokenKey, filterModel: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/LifelineClaimSummaryReports`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetLifelineClaimProcessDetailsReports(tokenKey, filterModel: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetLifelineClaimProcessDetailsReports`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    ViewLifelineClaimProcessDetailsPaged(tokenKey, filterModel: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/ViewLifelineClaimProcessDetailsPaged`, filterModel, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }


    LifelineUploadExcelFile(formData: FormData): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/LifelineUploadExcelFile`, formData, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetLifelineColumnArray(model: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetLifelineColumnArray`, model, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    SaveLifelineColumnMapping(model: any): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/SaveLifelineColumnMapping`, model, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    LifelineClaimImport(formData: FormData): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/LifelineClaimImport`, formData, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }    

    GetLifelineNLADFileFormat(): Observable<any> {
        this.setAPIUrl();
        return this._httpClient.get(this.AppUrl + `api/NLADClaim/GetLifelineNLADFileFormat`)
            .pipe(map(response => {
                return response;
            }));
    }

    GetLifelineNLADFileFormatFindByProviderId(providerId): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetLifelineNLADFileFormatFindByProviderId/` + providerId, { 'providerId': providerId }, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetLifelineColumnArrayFindByProvideId(providerId): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetLifelineColumnArrayFindByProvideId/` + providerId, { 'providerId': providerId }, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetLifelineClaimYears(tokenKey, provider: any): Observable<any> {
        this.setAPIUrl();
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': tokenKey }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetLifelineClaimYears`, provider, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    GetLifelineInrollClientCompany(): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json" }) };
        return this._httpClient.post(this.AppUrl + `api/NLADClaim/GetLifelineInrollClientCompany`, {}, httpOptions)
            .pipe(map(response => {
                return response;
            }));
    }

    // ViewClaimReportsById(TokenKey: string, claimMasterId: number): Observable<any> {
    //     this.setAPIUrl();
    //     let data = 'api/NLADClaim/ViewClaimReportsById/' + claimMasterId;
    //     const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    //     return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
    //         .pipe(catchError(this.handleError));
    // }

}
