import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConfig } from 'src/Services/app.config';
import { UsersService } from '../../../Services/users.service';
import { CheckAuth } from '../../../Services/utility.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from '../../../Models/usersModel';
import { Title } from '@angular/platform-browser';
import { AdminByPassClientLoginService } from 'src/Services/adminByPassClientLogin.service';
import { FilterModel } from 'src/Models/filterModel';
import { ChangePasswordModel } from 'src/Models/ChangePasswordModel';
import { SiteLayoutComponent } from 'src/app/Layout/site-layout/site-layout.component';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  userModel = new UserModel();
  appModel: any;
  tokenKey: string = '';
  loginForm: FormGroup;
  emailKey: any = '';
  filterModel = new FilterModel();
  userId: number = 0;
  changePasswordModel = new ChangePasswordModel();

  constructor(
    private appActivity: ApplicationActivityService,
    private adminByPassService: AdminByPassClientLoginService,
    private activedRoute: ActivatedRoute,
    private router: Router,
    private checkAuth: CheckAuth,
    private _fb: FormBuilder,
    private toastr: ToastrService,
    private usersService: UsersService
    , private titleService: Title,
    private siteLayoutComponent: SiteLayoutComponent
  ) {
    sessionStorage.removeItem('tokenValue');
    sessionStorage.removeItem('appStartTime');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    sessionStorage.removeItem('isAdminOpen');
    sessionStorage.removeItem('AdminUserId');
    sessionStorage.removeItem('StateSetupManageRedirection');
    this.titleService.setTitle('Change Password | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.loginForm = this._fb.group({
      userid: 0,
      newpassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    })
   
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {

        if (params['id'] != null) {

          this.emailKey = params['id'];
          if (this.emailKey != '') {
            this.ForgotPasswordDecryptEmailKey(this.emailKey);
          }
          else {
            this.router.navigate(['/Login']);
          }
        }
      }
      else if (sessionStorage.getItem('isLogin') == 'true') {
        this.userId = +sessionStorage.getItem('UserId');
      }
      else {
        this.router.navigate(['/Login']);
      }
    });
  }

  ForgotPasswordDecryptEmailKey(emailKey: any) {

    this.filterModel.emailKey = emailKey;
    this.adminByPassService.ForgotPasswordDecryptEmailKey('null', this.filterModel).subscribe(
      data => {

        if (data["status"] == "Success") {
          this.userId = data["userId"];
          this.tokenKey = data['token'];
          sessionStorage.setItem('tokenValue', this.tokenKey);

          if (this.userId > 0) {
            // this.loginForm.setValue({
            //   userid: this.userId,
            //   newpassword: [''],
            //   confirmPassword: ['']
            // });
          }
          else {
            this.showMessage("Email key is invalid. Please contact administrator.", 'Error', 'error');
          }
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
      },
      errorCode => {
        this.showMessage(errorCode, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  updatePassword(loginForm) {

    this.changePasswordModel = loginForm.value;    
    if (loginForm.value["newpassword"] == "") {
      this.showMessage('Please enter new password', 'Error', 'error');
      return;
    }
    if (loginForm.value["confirmPassword"] == "") {
      this.showMessage('Please enter confirm password', 'Error', 'error');
      return;
    }

    if (loginForm.value["confirmPassword"] != loginForm.value["newpassword"]) {
      this.showMessage('Confirm password not match', 'Error', 'error');
      return;
    }

    this.usersService.ChangePassword('null', this.changePasswordModel).subscribe(
      data => {
        if (data['status'] == 'Success') {
          if (data['id'] > 0) {
            this.showMessage('Password changed successfully', 'Success', 'success');

            ApplicationActivityStore('Add', 'Change Password',this.appActivity,0, ActionActivity['Change Password'],'/ChangePassword');
            ApplicationActivityStore('Add', 'Logout',this.appActivity,0, ActionActivity.Logout,'/ChangePassword');
            this.siteLayoutComponent.Logout();
            this.router.navigate(['/login']);
          }
        }
      },
      error => {

      }
    );
  }
}
