import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { SeminarsModel } from 'src/Models/SeminarsModel';
import { SeminarService } from 'src/Services/seminar.service';
import { CheckAuth } from 'src/Services/utility.service';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import Base64UploadAdapter from '../../../@ckeditor/Base64Upload';
@Component({
  selector: 'app-manage-seminars',
  templateUrl: './manage-seminars.component.html',
  styleUrls: ['./manage-seminars.component.css']
})
export class ManageSeminarsComponent implements OnInit {

  // public editor = ClassicEditor;
  // editorConfig = {
  //   extraPlugins: [Base64UploadAdapter],
  //   image: {
  //     resizeUnit: 'px'
  //   },
  // }
  ckeConfig:any;
  seminarsModel = new SeminarsModel();
  loginuserID: number = 0;
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;

  SeminarId: number = 0;
  isMultipleClick: boolean = false;
  file: any;
  isShowprogressSpinner = false;
  blockedDocument = false;
  dropdownListSection = [];
  dropdownSettingsSections = {};
  selectedItemsSections = [];


  @ViewChild('fileUpload', { static: true }) fileUpload;

  constructor(private activedRoute: ActivatedRoute, private _fb: FormBuilder, private router: Router, private checkAuth: CheckAuth, private titleService: Title,
    private Seminar: SeminarService,
    private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Seminar');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    setTimeout(() => {
    this.getAllSections();
      
    }, 200);

  }

  // Getter for easy access


  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.ckeConfig = {
      versionCheck:false,
      toolbar: [
        { items:
          [
          'Undo','Redo','Link','Unlink','Anchor',
          'Image','Table','SpecialChar','Maximize','Source','Bold','Italic','Underline','Strike','RemoveFormat',
          'NumberedList','BulletedList','Outdent','Indent','Styles','Format','Font'
          ]
        }],
   
      
    }
    
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {

          this.SeminarId = params['id'];
          this.GetSeminarsData();
        }
      }
      else
        this.seminarsModel.isActive = true;
    });
    this.dropdownSettingsSections = {
      singleSelection: true,
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No Section available',
    };

  }

  GetSeminarsData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.Seminar.i360SeminarsFindbySeminarId(this.tokenKey, this.SeminarId).subscribe(
      data => {
        if (data != null) {
          this.seminarsModel = data.singleData;

          if(this.seminarsModel.sectionId!=null && this.seminarsModel.sectionId!=0)
          {
            if(this.dropdownListSection!=null && this.dropdownListSection.length>0)
            {
              this.selectedItemsSections = this.dropdownListSection.filter(x => x.id == this.seminarsModel.sectionId); 
            }
            else{
            setTimeout(() => {
          this.selectedItemsSections = this.dropdownListSection.filter(x => x.id == this.seminarsModel.sectionId); 
        }, 800);
      }
          }
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  saveSeminars(SeminarsForm) {

    event.preventDefault();

    if (!this.seminarsModel.title) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }
    if (!this.seminarsModel.description) {
      this.showMessage('Please enter description', 'Description Required', 'error');
      return;
    }
    if (!this.seminarsModel.sortOrder) {
      if(this.seminarsModel.sortOrder!=0)
      {
      this.showMessage('Please enter sort order', 'Sort Order Required', 'error');
      return;
      }
    }

    this.seminarsModel.createdBy = this.loginuserID;
    this.seminarsModel.modifiedBy = this.loginuserID;
    if (this.isMultipleClick) {      
      return;
    }
    if (this.selectedItemsSections != null) {
      if (this.selectedItemsSections.length > 0) {
      this.seminarsModel.sectionId=this.selectedItemsSections[0]['id'];
      }
      else{
        this.showMessage('Please enter Section', 'Sort Order Required', 'error');
      return;
      }
    }
    else{
      this.showMessage('Please enter Section', 'Sort Order Required', 'error');
      return;
    }

    this.isMultipleClick = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.Seminar.i360SeminarsAddUpdate(this.tokenKey, this.seminarsModel).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        if (data['seminarsId'] != 0) {
          if (data['seminarsId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.SeminarId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.SeminarId = 0;
            this.cancelSeminars()
          }
        }
      },
      error => {
        this.isMultipleClick = false;
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelSeminars() {
    this.router.navigate(['admin/inteserra360/Seminar']);
  }

  getAllSections() {
    this.Seminar.i360SeminarsSectionListWithIds(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        for (let iCounter = 0; iCounter < data['sectionList'].length; iCounter++) {
          this.dropdownListSection.push(
            { 'id': data['sectionList'][iCounter].sectionId, 'itemName': data['sectionList'][iCounter].sectionName }
          );
        }
      }
    });
  }

  onSelectedSection($event) {
    this.selectedItemsSections = [];
    // this.selectedItemsSections.push($event.id);
     this.selectedItemsSections.push({ "id": $event.id, "itemName": $event.itemName }) 
  }
  onDeSelectedSection($event) {
    if (this.selectedItemsSections != null) {
      if (this.selectedItemsSections.length > 0) {
        this.selectedItemsSections = this.removeElemetninArray(this.selectedItemsSections, $event.id)
      }
    }
  }
}
