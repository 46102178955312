import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientInformationModel } from 'src/Models/ClientInformationModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-onboarding-list',
  templateUrl: './onboarding-list.component.html',
  styleUrls: ['./onboarding-list.component.css']
})
export class OnboardingListComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofPricingQuotes: ClientInformationModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  CompanyName: string;
  isSubmit: boolean = false;

  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  clientInformationId: number = 0;
  Sent: string;
  DisplayQuote: string;
  isChecked: boolean = false;
  isCheckedDisplayQuote: boolean = false;
  blockedDocument: boolean;
  Sent1: string;
  duplicateClientInformationId: number = 0;

  priceQuoteId: number = 0;
  AdminId: number = 0;

  upDownCompanyNameCSS: string = '';
  upDownQuoteRequestedByCSS: string = '';
  upDownProposelDateCSS: string = '';
  upDownProposelModifiedDateCSS: string = '';
  openMenuIndex: number = -1;
  
  constructor(private router: Router,private checkAuth: CheckAuth
    , private titleService: Title,private toastr: ToastrService,private PricingQuotesMaster: PricingQuotesService) {
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
      this.listofPricingQuotes = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'CreatedDate desc';
    this.upDownProposelDateCSS = 'fa fa-arrow-down'
    this.isAscending = true;
    this.CompanyName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Onboarding');
    sessionStorage.removeItem('ClientCompanyName');
    sessionStorage.removeItem('AdminClientCompanyId');
     }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetOnboardingList();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetOnboardingList();
  }

  GetOnboardingList() {
    this.isloading = true;
    this.PricingQuotesMaster.GetOnboardingList(this.tokenKey, this.PageNumber, this.PageSize, this.CompanyName, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofPricingQuotes = data['pricingQuotes'];
        if (this.listofPricingQuotes != null) {
          if (this.listofPricingQuotes.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofPricingQuotes[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );

  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "CompanyName") {
        this.upDownCompanyNameCSS = 'fa fa-arrow-down';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedByName") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteRequestedByCSS = 'fa fa-arrow-down';
        this.upDownProposelDateCSS = '';
        this.upDownProposelModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = 'fa fa-arrow-down';
        this.upDownProposelModifiedDateCSS = '';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelModifiedDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "CompanyName") {
        this.upDownCompanyNameCSS = 'fa fa-arrow-up';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedByName") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteRequestedByCSS = 'fa fa-arrow-up';
        this.upDownProposelDateCSS = '';
        this.upDownProposelModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = 'fa fa-arrow-up';
        this.upDownProposelModifiedDateCSS = '';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDownCompanyNameCSS = '';
        this.upDownQuoteRequestedByCSS = '';
        this.upDownProposelDateCSS = '';
        this.upDownProposelModifiedDateCSS = 'fa fa-arrow-up';
      }

    }
    this.GetOnboardingList();
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.CompanyName = $event.target.value;
    else
      this.CompanyName = 'null';

    this.GetOnboardingList();
  }
  updatePricingQuotes(clientInformationId,companyName,companyId) {
  sessionStorage.setItem('UserLoginTool','PricingQuote');
  sessionStorage.setItem('ClientCompanyName',companyName);
  let navigation = '/admin/PricingQuote/add-company-info/'+clientInformationId+'/'+companyId;
  this.router.navigate([navigation]);
}

toggleMenu(index: number) {
  this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
}

@HostListener('document:click', ['$event'])
onDocumentClick(event: MouseEvent) {
  const target = event.target as HTMLElement;
  if (!target.closest('.kebab-menu')) {
    this.openMenuIndex = null;
  }
}

}
