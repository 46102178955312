export class AddNewActionModel {
    actionItemId: number;
    assignToUser: string;
    deadLineDate: string;
    subject: string;
    description: string;
    state: string;
    carrierCategories: string;
    visibleToAllCompanyUsers: boolean;
    createdBy: number;
    companyId: number;
    isNeworChanged: any
    actionType: string;
    isAction: boolean;
    actionCharges: string
    actionNotes: string;
    previousAssingId: string;
    productId: string;
    isSendEmailNotification: any
    isInformationOnly: any
    internalNotes:any   
    recurrance: string;
    recurranceId:number;
    briefing:string;
    updateSingleRecord:boolean;
    alertText:string;
    updateCategoryId:number;
}