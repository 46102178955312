import { Component, HostListener, OnInit } from '@angular/core';
import { InteserraResourcesModel } from 'src/Models/InteserraResourcesModel';
import { FilterModel } from 'src/Models/filterModel';
import { InteserraResourcesService } from 'src/Services/inteserra-resources.service';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from 'src/Models/Common';

@Component({
  selector: 'app-admin-inteserra-resources',
  templateUrl: './admin-inteserra-resources.component.html',
  styleUrls: ['./admin-inteserra-resources.component.css']
})
export class AdminInteserraResourcesComponent implements OnInit {

  filterModel = new FilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfInteserraResources: InteserraResourcesModel[];

  isUserSystemAdmin: boolean = true;
  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false
  upDowTitleCSS: string = '';
  upDowURLCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowIsIsAllowallAccessCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';
  upDowSortOrderCSS: string = '';

  productId: number = 1;
  deletedId: number = 0;
  isloading: boolean;
  openMenuIndex: number = -1;

  constructor(private InteserraResourcesService: InteserraResourcesService, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Inteserra Resources');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'SortOrder';
     }

     tokenExpireCalculation(startDate: string, tokenExpiry: string) {
      this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
    }

  ngOnInit() {
    this.GetInteserraResourcesData();
  }

  GetInteserraResourcesData() {
    this.listOfInteserraResources = [];
    this.isloading = true;
    this.InteserraResourcesService.i360InteserraResourcesGet(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfInteserraResources = data['inteserraResourcesGet'];
          if (this.listOfInteserraResources != null) {
            if (this.listOfInteserraResources.length > 0) {
              this.totalRecordCount = this.listOfInteserraResources[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filterModel.whereCondition = $event.target.value;
    }
    else {
      this.filterModel.whereCondition = '';
    }
    this.GetInteserraResourcesData();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetInteserraResourcesData();
  }

  cleanCssClass() {
    this.upDowTitleCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowIsIsAllowallAccessCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case "title": this.upDowTitleCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "isActive": this.upDowIsActiveCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "isAllowallAccess": this.upDowIsIsAllowallAccessCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;      
      case "CreatedDate": this.upDowCreatedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "ModifiedDate": this.upDowModifiedDateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "sortOrder": this.upDowSortOrderCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetInteserraResourcesData();
  }
 
  addInteserraResources() {
    this.router.navigate(['admin/inteserra360/InteserraResources/manage']);
  }

  editData(id) {
    this.router.navigate(['admin/inteserra360/InteserraResources/manage/' + id])
  }

  setDeleteData(ids) {
    this.deletedId = ids;
  }

  deleteRecord(deletedId) {
    this.InteserraResourcesService.i360InteserraResourcesDelete(this.tokenKey, deletedId).subscribe(
      data => {
        this.deletedId = 0;
        if (data != null) {
          if (data['deletedId'] > 0) {
            this.GetInteserraResourcesData();
            this.toastr.success('Record deleted successfully.', 'Delete')
          }
        }
      },
      error => {
      }
    );
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
