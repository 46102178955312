import { Component, OnInit } from '@angular/core';
import { CompanyDirectoryModel } from 'src/Models/CompanyDirectoryModel';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { I360Service } from 'src/Services/i360Service';
import { ApplicationActivityService } from 'src/Services/application-activity.service';

@Component({
  selector: 'app-company-directory',
  templateUrl: './company-directory.component.html',
  styleUrls: ['./company-directory.component.css']
})
export class CompanyDirectoryComponent implements OnInit {
  filterModel = new FilterModel();
  listOfCompany: CompanyDirectoryModel[];
  tokenKey: string = '';
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false

  upDowUserNameCSS: string = '';
  upDowNameCSS: string = '';
  upDowCompanyNameCSS: string = '';
  upDowEmailCSS: string = '';
  upDowUserTypeCSS: string = '';
  upDowIsActiveCSS: string = '';

  constructor(private i360Service: I360Service, private checkAuth: CheckAuth, private titleService: Title,private appActivity: ApplicationActivityService,) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Company Directory | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'ISNULL(ModifiedDate,CreatedDate) DESC';    
    this.filterModel.userID = this._userID;
    this.i360CompanyDirectoryFindByUserId();
  }

  i360CompanyDirectoryFindByUserId() {
    this.i360Service.i360CompanyDirectoryFindByUserId(this.tokenKey, this.filterModel).subscribe(
      data => {
        if (data != null) {
          this.listOfCompany = data['listOfCompany'];
          if (this.listOfCompany != null) {
            if (this.listOfCompany.length > 0) {
              this.totalRecordCount = this.listOfCompany[0].totalRecordCount;
            }
            else
              this.totalRecordCount = 0;
          }
          else
            this.totalRecordCount = 0;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  sort(sortBy) {
    this.upDowUserNameCSS = '';
    this.upDowNameCSS = '';
    this.upDowCompanyNameCSS = '';
    this.upDowEmailCSS = '';
    this.upDowUserTypeCSS = '';
    this.upDowIsActiveCSS = '';

    if (!this.isAscending) {
      this.isAscending = true;
      this.filterModel.orderBy = sortBy + ' DESC';

      if (sortBy === "username") {
        this.upDowUserNameCSS = 'active-up';
      }
      else if (sortBy === "name") {
        this.upDowNameCSS = 'active-up';
      }
      else if (sortBy === "companyName") {
        this.upDowCompanyNameCSS = 'active-up';
      }
      else if (sortBy === "email") {
        this.upDowEmailCSS = 'active-up';
      }
      else if (sortBy === "userType") {
        this.upDowUserTypeCSS = 'active-up';
      }
      else if (sortBy === "isActive") {
        this.upDowIsActiveCSS = 'active-up';
      }
    }
    else {
      this.isAscending = false;
      this.filterModel.orderBy = sortBy;
      if (sortBy === "username") {
        this.upDowUserNameCSS = 'active';
      }
      else if (sortBy === "name") {
        this.upDowNameCSS = 'active';
      }
      else if (sortBy === "companyName") {
        this.upDowCompanyNameCSS = 'active';
      }
      else if (sortBy === "email") {
        this.upDowEmailCSS = 'active';
      }
      else if (sortBy === "userType") {
        this.upDowUserTypeCSS = 'active';
      }
      else if (sortBy === "isActive") {
        this.upDowIsActiveCSS = 'active';
      }
    }
    this.OrderBy = sortBy;
    this.i360CompanyDirectoryFindByUserId();
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) { this.filterModel.keywordFilter = $event.target.value; }
    else { this.filterModel.keywordFilter = null; }

    this.i360CompanyDirectoryFindByUserId();
  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.i360CompanyDirectoryFindByUserId();
  }
}
