import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CheckAuth } from '../../../../Services/utility.service';
import { ToolEnum } from '../../../../Models/Common';
import { Title } from '@angular/platform-browser';
import { Params, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CarrierCategoryService } from 'src/Services/carriercategory.service';
import { CarrierCategoryAdminModel } from 'src/Models/CarrierCategoryAdminModel';
import { error } from 'protractor';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-manage-carrier-category',
  templateUrl: './manage-carrier-category.component.html',
  styleUrls: ['./manage-carrier-category.component.css']
})
export class ManageCarrierCategoryComponent implements OnInit {
  carrierCategoryModel = new CarrierCategoryAdminModel();
  CarrierCatId: number = 0;

  tokenKey: string = '';
  loginuserID: number = 0;
  SelectedTool: string = '';

  addUpdateCarrierCategory = new FormGroup({
    carrierCatId: new FormControl('0'),
    carrierCatName: new FormControl(''),
    isActive: new FormControl(0),
    selectedItemsProduct: new FormControl(0),
    sortOrder: new FormControl(),
  });

  // Product List---------------
  dropdownListProduct = [];
  selectedItemsProduct = [];
  dropdownSettingsProduct = {};
  productFilter: string = '';
  listOfProductID: string[] = [];
  // Product List---------------

  constructor(private checkAuth: CheckAuth, private toastr: ToastrService, private titleService: Title,
    private router: Router, private activedRoute: ActivatedRoute, private carrierService: CarrierCategoryService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Manage Carrier Type');
    this.carrierCategoryModel.isActive = true;
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.CarrierCatId = params['id'];
          this.GetCarrierCategorySearchById();
        }
      }
      else {
        this.CarrierCatId = 0;
        this.GetProductData();
      }
    });

  }

  GetCarrierCategorySearchById() {
    this.carrierService.GetCarrierCategorySearchById(this.tokenKey, this.CarrierCatId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.carrierCategoryModel = data['carrierModel'];
          if (this.carrierCategoryModel != null) {
            this.listOfProductID.push(this.carrierCategoryModel.visiblityType);
          }
          this.GetProductData();
        }
        else
          this.showMessage(data['status'], 'Error', 'error')
      },
      error => {
        this.showMessage(error, 'Error', 'error')
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  SaveCarrierCategory(addUpdateCarrierCategory: any) {
    if (addUpdateCarrierCategory.value['carrierCatName'] == '' || typeof (addUpdateCarrierCategory.value['carrierCatName']) == 'undefined') {
      this.showMessage('Please enter carrier type name', 'Error', 'error');
      return;
    }
    if (addUpdateCarrierCategory.value['sortOrder'] == null) {
      this.showMessage('Please enter sortOrder', 'Error', 'error');
      return;
    }
    if (this.listOfProductID.length == 0) {
      this.showMessage('Please select product', 'Error', 'error');
      return;
    }

    this.carrierCategoryModel.isActive = addUpdateCarrierCategory.value['isActive'];
    this.carrierCategoryModel.createdBy = this.loginuserID.toString();
    this.carrierCategoryModel.modifiedBy = this.loginuserID.toString();
    this.carrierCategoryModel.carrierCatId = addUpdateCarrierCategory.value['carrierCatId'];
    this.carrierCategoryModel.carrierCatName = addUpdateCarrierCategory.value['carrierCatName'];
    this.carrierCategoryModel.isDeleteActive = false;
    this.carrierCategoryModel.isReadOnly = false;
    this.carrierCategoryModel.totalRowCount = 0;
    this.carrierCategoryModel.visiblityType = this.listOfProductID.join(',');
    this.carrierCategoryModel.sortOrder = addUpdateCarrierCategory.value['sortOrder'];

    this.carrierService.CarrierCategoryAddUpdate(this.tokenKey, this.carrierCategoryModel).subscribe(
      data => {
        if (data['status'] == 'Success') {
          if (this.carrierCategoryModel.carrierCatId > 0)
            this.showMessage('Carrier type updated successfully', 'Success', 'success');
          else
            this.showMessage('Carrier type added successfully', 'Success', 'success');
          this.router.navigate(['admin/trac-it/carriertypes']);
        }
        else {
          if (data['id'] == -1) {
            this.showMessage('Carrier type already exists.', 'Error', 'error');
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );

  }

  GetProductData(): void {
    // Set All Item
    this.dropdownListProduct.push({ 'id': 0, 'itemName': 'Both' });
    this.dropdownListProduct.push({ 'id': 2, 'itemName': 'i360' });
    this.dropdownListProduct.push({ 'id': 1, 'itemName': 'Trac It' });
    
    if (this.listOfProductID != null && this.listOfProductID != undefined) {
      for (let iCounter = 0; iCounter < this.listOfProductID.length; iCounter++) {
        let obj = this.dropdownListProduct.filter(m => m.id == this.listOfProductID[iCounter]);
        if (obj != null) {
          this.selectedItemsProduct.push({ "id": obj[0].id, "itemName": obj[0].itemName });
        }
      }
    }

    this.dropdownSettingsProduct = {
      singleSelection: true,
      text: 'Select by Product(s)',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No product available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };

  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelect($event) {
    this.listOfProductID = [];
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
        this.listOfProductID.push($event.id);
      }
      else {
        this.listOfProductID.push($event.id);
      }
    }
  }

  onSelectAll($event) {
    this.listOfProductID = [];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfProductID != null) {
        if (this.listOfProductID.length > 0) {
          this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event[iCounter].id)
          this.listOfProductID.push($event[iCounter].id);
        }
        else {
          this.listOfProductID.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAll($event) {
    this.listOfProductID = [];
  }

  onDeSelect($event) {
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
      }
    }
  }

}
