export class SectionsModel{
    sectionId?: any
    sectionName?: any
    productName?:any
    sortOrder?: any 
    isActive?: any 
    createdDate?: any 
    createdBy?: any 
    updatedDate?: any
    updatedBy?: any 
    productId?:any
    menuName?:any
    totalRecordCount?: any
}