import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterModel,CarrierFilterModel } from 'src/Models/filterModel';
import { ActionCalendarSearch } from 'src/Models/actionCalendarSearchModel';
import { AddNewActionModel } from 'src/Models/AddNewActionModel';
import { LandingPageModel } from '../Models/landingPageModel';

@Injectable({
    providedIn: 'root'
})

export class ActionItemService {
    AppUrl: string = "";
    carrierFilterModel = new CarrierFilterModel();
    filterModel = new FilterModel();
    constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.setAPIUrl();
    }

    setAPIUrl() {
        if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
            this.AppUrl = sessionStorage.getItem("BaseURL") || '';
        }
    }

    getCalendarData(TokenKey: string, searchModel: ActionCalendarSearch): Observable<any> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/GetCalendarData';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(URL, searchModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    getListViewData(TokenKey: string, orderBy: any, searchModel: ActionCalendarSearch): Observable<any> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/GetListViewData?orderBy=' + orderBy;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(URL, searchModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    populateActionItems(TokenKey: string): Observable<any> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/GetActionItems';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(URL, httpOptions)
            .pipe(catchError(this.handleError));
    }

    populateCarrierCategory(TokenKey: string): Observable<any[]> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/GetCarrierCategories';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(URL, httpOptions)
            .pipe(catchError(this.handleError));
    }

    populateActionStatus(TokenKey: string): Observable<any[]> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/GetActionStatus';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(URL, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetCarrierCategories(TokenKey: string): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetCarrierCategories';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    AddUpdateNewActionItem(TokenKey: string, addNewActionModel: AddNewActionModel): Observable<any> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/AddUpdateNewActionItem';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(URL, addNewActionModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetActionStatus(TokenKey: string): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionStatus';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    ActionItemSearchByIds(TokenKey: string, userId: string, updateId: string, actionId: string): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/ActionItemSearchByIds/' + userId + '/' + updateId + '/' + actionId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    ActionItemUpdateStatus(TokenKey: string, actionItemUpdateStatusModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/ActionItemUpdateStatus';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, actionItemUpdateStatusModel, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionItemSearchStatusHistory(TokenKey: string, userId: string, updateId: string, actionId: string): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionItemSearchStatusHistory/' + userId + '/' + updateId + '/' + actionId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    ActionItemSaveNote(TokenKey: string, notesModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/ActionItemSaveNote';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, notesModel, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionItemSearchNotes(TokenKey: string, userId: string, updateId: string, actionId: string, orderBy: string): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionItemSearchNotes/' + userId + '/' + updateId + '/' + actionId + '/' + orderBy;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    ActionNotesDeleteByIds(TokenKey: string, actionNoteId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/ActionNotesDeleteByIds/' + actionNoteId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionItemDashboardCounts(TokenKey: string, userId: any, fromDate: any, toDate: any, isFavorite: any, isMyTracIt: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionItemDashboardCounts';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, { 'userId': userId, 'fromDate': fromDate, 'toDate': toDate, 'isFavorite': isFavorite, 'isMyTracIt': isMyTracIt }, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionItemDashboardNotes(TokenKey: string, userId: any, fromDate: any, toDate: any, isFavorite: any, isMyTracIt: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionItemDashboardNotes';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, { 'userId': userId, 'fromDate': fromDate, 'toDate': toDate, 'isFavorite': isFavorite, 'isMyTracIt': isMyTracIt }, httpOptions).pipe(catchError(this.handleError));
    }

    ActionItemStateFavouriteAdd(TokenKey: string, userId: number, stateIds: string, isFavourite: number,): Observable<any> {
        this.setAPIUrl();
        this.carrierFilterModel.userID = userId;
        this.carrierFilterModel.keywordFilter = stateIds;
        this.carrierFilterModel.Status = isFavourite;

        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(this.AppUrl + 'api/ActionItem/ActionItemStateFavouriteAdd', this.carrierFilterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetActionItemTypesFavoriteData(TokenKey: string, userId: number): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionItemTypesFavoriteData/' + userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    ActionItemTypeFavouriteAdd(TokenKey: string, userId: number, stateIds: string, isFavourite: number): Observable<any> {
        this.setAPIUrl();
        this.carrierFilterModel.userID = userId;
        this.carrierFilterModel.keywordFilter = stateIds;
        this.carrierFilterModel.Status = isFavourite;

        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(this.AppUrl + 'api/ActionItem/ActionItemTypeFavouriteAdd', this.carrierFilterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetCarrierCategoryFavoriteData(TokenKey: string, userId: number): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetCarrierCategoryFavoriteData/' + userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    CarrierCategoryFavouriteAdd(TokenKey: string, userId: number, stateIds: string, isFavourite: number): Observable<any> {
        this.setAPIUrl();
        this.carrierFilterModel.userID = userId;
        this.carrierFilterModel.keywordFilter = stateIds;
        this.carrierFilterModel.Status = isFavourite;

        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(this.AppUrl + 'api/ActionItem/CarrierCategoryFavouriteAdd', this.carrierFilterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetDashboardActionItemsReuiredAttention(TokenKey: string, orderBy: string, searchModel: ActionCalendarSearch): Observable<any> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/GetDashboardActionItemsReuiredAttention?orderBy=' + orderBy;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(URL, searchModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetLandingPageDetails(TokenKey: string): Observable<any> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/GetLandingPageDetails';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(URL, httpOptions)
            .pipe(catchError(this.handleError));
    }

    SaveLandingPageDetails(TokenKey: string, postModel: LandingPageModel): Observable<any> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/SaveUpdateLandingPageDetails';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(URL, postModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetClientFavorite(TokenKey: string, userId: any, productId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetClientFavorite/' + userId + '/' + productId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionTypesByPages(TokenKey: string, model: FilterModel): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionTypesByPages';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, model, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionItemTypeById(TokenKey: string, actionTypeId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionItemTypeById/' + actionTypeId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    AddUpdateActionItemType(TokenKey: string, actionTypeModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/AddUpdateActionItemType';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, actionTypeModel, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionItemsType(TokenKey: string): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionItemsType';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    AdminAddUpdateActionItem(TokenKey: string, addNewActionModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/AdminAddUpdateActionItem';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, addNewActionModel, httpOptions).pipe(catchError(this.handleError));
    }

    AdminActionItemFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/AdminActionItemFindByPages';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionSearchById(TokenKey: string, actionId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionSearchById/' + actionId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionStateByActionId(TokenKey: string, actionId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionStateByActionId/' + actionId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionCarrierByActionId(TokenKey: string, actionId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionCarrierByActionId/' + actionId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    DeleteActionItemAdmin(TokenKey: string, actionId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/DeleteActionItemAdmin/' + actionId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionItemProvider(TokenKey: string): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionItemProvider';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    AddUpdateGeneralAlerts(TokenKey: string, generalAlertsModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/AddUpdateGeneralAlerts';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, generalAlertsModel, httpOptions).pipe(catchError(this.handleError));
    }

    GetGeneralAlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetGeneralAlertsFindByPages';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
    }

    GetGeneralAlertsByIds(TokenKey: string, generalAlertsId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetGeneralAlertsByIds/' + generalAlertsId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    GetAdminActionItemDashboardCounts(TokenKey: string, sysFilterModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetAdminActionItemDashboardCounts';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, sysFilterModel, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionItemSearchListForUpdateCount(TokenKey: string, userId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetActionItemSearchListForUpdateCount/' + userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    GetActionItemSearchListForUpdate(TokenKey: string, orderBy: string, searchModel: ActionCalendarSearch): Observable<any> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/GetActionItemSearchListForUpdate?orderBy=' + orderBy;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(URL, searchModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    DeleteActionTypes(TokenKey: string, actionTypeId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/DeleteActionTypes/' + actionTypeId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    DeleteAction(TokenKey: string, actionId: any, userId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/DeleteAction/' + actionId + '/' + userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    ActionGetById(TokenKey: string, actionId: any): Observable<any> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/ActionGetById/' + actionId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(URL, httpOptions)
            .pipe(catchError(this.handleError));
    }

    EditActionItem(TokenKey: string, addNewActionModel: AddNewActionModel): Observable<any> {
        this.setAPIUrl();
        let URL = this.AppUrl + 'api/ActionItem/EditActionItem';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(URL, addNewActionModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    private handleError(error: Response | any) {
        console.error(error.message || error);
        return observableThrowError(error.status)
    }

    GetBrifingsData(TokenKey: string,id:any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetBrifingsData/'+id;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    GetBrifingsDDLData(TokenKey: string,searchval:any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetBrifingsDDLData/' + searchval;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    GetTracAlertsFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetTracAlertsFindByPages';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
    }

    GetTracUpdateFindByPages(TokenKey: string, filterModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetTracUpdateFindByPages';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
    }


    GetTracItAlertsByIds(TokenKey: string,actionId: any,pageNumber:any,pageSize:any,filterText:any,userId,orderBy:string): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetTracItAlertsByIds/' + actionId + '/' + pageNumber + '/' + pageSize + '/' + filterText + '/' + userId + '/' + orderBy;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    AddUpdateTracItAlerts(TokenKey: string, addUpdateTracItAlert: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/AddUpdateTracItAlerts';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, addUpdateTracItAlert, httpOptions).pipe(catchError(this.handleError));
    }

    GetTracItAlertsByUser(TokenKey: string, actionId: any,pageNumber:any,pageSize:any,userId:any,orderBy:string): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/GetTracItAlertsByUser/' + actionId + '/' + pageNumber + '/' + pageSize + '/' + userId + '/' + orderBy;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }

    notsentmail(TokenKey: string, actionId: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/notsentmail/' + actionId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
    }
    
      ExportTracItEntries(TokenKey: string, filterModel: any): Observable<any[]> {
        this.setAPIUrl();
        let fullAPIPath = this.AppUrl + 'api/ActionItem/ExportTracItEntries';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._http.post<any>(fullAPIPath, filterModel, httpOptions).pipe(catchError(this.handleError));
    }
}
