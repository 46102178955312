import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { I360Service } from 'src/Services/i360Service';
import { FilterModel } from 'src/Models/filterModel';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {
  message: string = '';
  filterModel = new FilterModel();

  constructor(private toastr: ToastrService, private activedRoute: ActivatedRoute, private titleService: Title, private router: Router, private i360Services: I360Service) {
    
    this.titleService.setTitle('Email Verification | Inteserra - i360');
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        sessionStorage.setItem('UserId', '0');
        if (params['id'] != null) {
          this.filterModel.emailKey = params['id'];
          this.VerifyEmailAddress();
        }
      }
      else {
        this.filterModel.emailKey = '';
      }
    });
  }

  ngOnInit() {
  }

  VerifyEmailAddress() {
    this.i360Services.i360VerifyEmailAddress('', this.filterModel).subscribe(
      data => {
        if (data['isVerify'] > 0) {
          this.message = 'Your email verification completed successfully.';
          sessionStorage.setItem('tokenValue', data['tokenKey']);
        }
        else if (data['isVerify'] > -1) {
          this.message = data['status'];
        }
        else
          this.message = data['status'];
        //this.showMessage(this.message, 'Required', 'error');
      },
      error => {
        this.showMessage(error, 'Required', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

}
