import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';
import { CarrierTariffsModel } from 'src/Models/CarrierTariffsModel';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';

@Component({
  selector: 'app-front-carrier-tariff',
  templateUrl: './front-carrier-tariff.component.html',
  styleUrls: ['./front-carrier-tariff.component.css']
})
export class FrontCarrierTariffComponent implements OnInit {
  tokenKey: string = '';
  loginUser: number = 0;
  listOfCarrier: CarrierTariffsModel[];

  constructor(private i360Service: I360Service, private titleService: Title, private checkAuth: CheckAuth, private appActivity: ApplicationActivityService) { }

  ngOnInit() {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginUser = +sessionStorage.getItem('UserId');
    this.titleService.setTitle('Carrier Tariffs | Inteserra - i360');
    this.GetCarrierTariffs();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  GetCarrierTariffs() {
    this.i360Service.i360CarrierTariffsForFront(this.tokenKey).subscribe(
      data => {
        if (data != null) {
          this.listOfCarrier = data['listOfData'];
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  applicationActivity(actionName, pageName, datatype = 0, actionUrl = '', dataId = 0) {
    ApplicationActivityStore(actionName, pageName, this.appActivity, dataId, datatype, actionUrl)
  }
}
