export class StepMasterModel {

    constructor() {
        this.statusModel = new StatusModel();
        
    }
    statusModel: StatusModel;
}    

export class StatusModel {
    progressMasterId:number;
    clientInformationId:number;
    isCompanyProfileDetailsCompleted?:boolean;
    isAddCompQueCompleted?:boolean;
    isStateSelectionCompleted?:boolean;
    isAttechedDetailsCompleted?:boolean;
    isRateDetailsCompleted?:boolean;
    isQuoteDetailsCompleted?:boolean;
    isAddOnServicesCompleted?:boolean;
    isComplianceDetailsCompleted?:boolean;
    isProposalDetailsCompleted?:boolean;  
    isAdditionalCompanyInformation?:boolean;
    isSensitiveInformation?:boolean;
    isInteserraPortalCredential?:boolean;
    isNotes?:boolean;
    isOnboardingStateSelection?:boolean;
}