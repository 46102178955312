export class FavoriteFilterModel{
    pageNumber?: number;
    pageSize?: number;  
    orderby?:string; 
    stateID?: string | number;    
    userId?: string | number;
    filterText?: string;
    isFavourite?:number;
    isActive?:boolean;
    ModuleId?:number;
    name?:string;
}