import { Component, HostListener, OnInit } from '@angular/core';
import { FilterModel } from 'src/Models/filterModel';
import { ActionItemService } from 'src/Services/actionitem.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { CheckAuth } from 'src/Services/utility.service';
import { UsersService } from 'src/Services/users.service';
import { ToolEnum } from 'src/Models/Common';
import { GeneralAlertsModel } from 'src/Models/GeneralAlertsModel';
import { ProductsService } from 'src/Services/products.service';
import { RouterExtService } from 'src/Services/RouterExtService';

@Component({
  selector: 'app-general-alerts',
  templateUrl: './general-alerts.component.html',
  styleUrls: ['./general-alerts.component.css']
})
export class GeneralAlertsComponent implements OnInit {
  tokenKey: string = '';
  userID: number = 0;
  SelectedTool: string;
  productId: number = 1;

  filteModel = new FilterModel();

  upDownAlertDateCSS: string = '';
  upDownSubjectCSS: string = '';
  upDownProductsCSS: string = '';
  upDownStatusCSS: string = '';
  upDownCreatedDateCSS: string = '';
  upDownModifiedDateCSS: string = '';
  isAscending: boolean;

  isloading: boolean = false;
  totalRecordCount: number = -1;
  generalAlertsModel: GeneralAlertsModel[];

  // Product List---------------
  dropdownListProduct = [];
  selectedItemsProduct = [];
  dropdownSettingsProduct = {};
  productFilter: string = '';
  listOfProductID: string[] = [];
  // Product List---------------
  openMenuIndex: number = -1;


  constructor(private productsService: ProductsService, private actionItemServices: ActionItemService, private router: Router,
    private toastr: ToastrService, private titleService: Title, private checkAuth: CheckAuth, private userServices: UsersService
    , private routerService: RouterExtService) {
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');    
    this.productId = 4;
    this.titleService.setTitle('i360 Alerts List');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.filteModel.pageNumber = '1';
    this.filteModel.pageSize = '10';
    this.filteModel.whereCondition = '';
    this.filteModel.listOfProduct = '';
    this.filteModel.orderBy = ' createdDate Desc ';
    this.isAscending = false;
    if (this.routerService.getPreviousUrl().includes("admin/inteserra360/i360Alerts/manage")) {
      this.setSaveFilter();
    }
    else {
      this.clearSession();
    }

    this.GetGeneralAlertsFindByPages();
    this.GetProductData();
    this.dropdownSettingsProduct = {
      singleSelection: false,
      text: 'Filter by Product(s)',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No product available',
      showCheckbox: true,
      enableFilterSelectAll: false
    };
  }

  GetProductData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.productsService.GetProductList(this.tokenKey, this.userID, false, true).subscribe(
      data => {
        if (data['product'] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data['product'].length; iCounter++) {
            this.dropdownListProduct.push(
              { 'id': data['product'][iCounter].productId, 'itemName': data['product'][iCounter].displayName }
            );
          }

          if (this.listOfProductID != null && this.listOfProductID != undefined) {
            for (let iCounter = 0; iCounter < this.listOfProductID.length; iCounter++) {
              if (this.listOfProductID[iCounter] != '') {
                let obj = data["product"].filter(m => m.productId == this.listOfProductID[iCounter]);
                if (obj != null) {
                  this.selectedItemsProduct.push({ "id": obj[0].productId, "itemName": obj[0].displayName });
                }
              }
            }
          }
        }
        else {
          this.showMessage(data['message'], 'Error', 'error');
        }
        this.dropdownSettingsProduct = {
          singleSelection: false,
          text: 'Filter by Product(s)',
          enableSearchFilter: false,
          classes: 'borderLessTextBoxGreen',
          badgeShowLimit: 1,
          noDataLabel: 'No product available',
          showCheckbox: true,
          enableFilterSelectAll: false,
          enableCheckAll: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  GetGeneralAlertsFindByPages() {
    this.isloading = true;
    this.actionItemServices.GetGeneralAlertsFindByPages(this.tokenKey, this.filteModel).subscribe(data => {
      if (data['status'] == 'Success') {
        this.isloading = false;
        this.generalAlertsModel = data['generalAlertList'];
        if (this.generalAlertsModel != null) {
          if (this.generalAlertsModel.length > 0) {
            this.totalRecordCount = +this.generalAlertsModel[0].totalRowCount;
          }
        }
      }
      else {
        this.showMessage(data['status'], 'Error', 'error');
      }

    },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelect($event) {
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
        this.listOfProductID.push($event.id);
      }
      else {
        this.listOfProductID.push($event.id);
      }
    }

    this.filteModel.listOfProduct = this.listOfProductID.join(',');
    this.GetGeneralAlertsFindByPages();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfProductID != null) {
        if (this.listOfProductID.length > 0) {
          this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event[iCounter].id)
          this.listOfProductID.push($event[iCounter].id);
        }
        else {
          this.listOfProductID.push($event[iCounter].id);
        }
      }
    }
    this.filteModel.listOfProduct = this.listOfProductID.join(',');
    this.GetGeneralAlertsFindByPages();
  }

  onDeSelectAll($event) {
    this.listOfProductID = [];
    this.filteModel.listOfProduct = '';
    this.GetGeneralAlertsFindByPages();
  }

  onDeSelect($event) {
    if (this.listOfProductID != null) {
      if (this.listOfProductID.length > 0) {
        this.listOfProductID = this.removeElemetninArray(this.listOfProductID, $event.id)
      }
    }
    this.filteModel.listOfProduct = this.listOfProductID.join(',');
    this.GetGeneralAlertsFindByPages();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  pageChanged($event) {
    this.filteModel.pageNumber = parseInt($event.page).toString();
    this.GetGeneralAlertsFindByPages();
  }

  keywordFilters($event) {
    this.filteModel.keywordFilter = $event.target.value;
    this.filteModel.pageNumber = '1';
    if ($event.target.value != "" && $event.target.value != null) {
      this.filteModel.whereCondition = " subject like '%" + $event.target.value + "%'";
    }
    else { this.filteModel.whereCondition = ''; }

    this.GetGeneralAlertsFindByPages();
  }

  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.filteModel.orderBy = sortBy + ' DESC';
      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.filteModel.orderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.GetGeneralAlertsFindByPages();
  }

  redirectToEditView(generalAlertsId: any, isViewMode: any) {
    this.saveSession(); // To Store All filtered and other data to restore once back.
    sessionStorage.setItem('isViewMode', isViewMode);
    this.router.navigate(['/admin/inteserra360/i360Alerts/manage/' + generalAlertsId]);
  }


  saveSession() {
    sessionStorage.setItem('saveFilterIsAscending', this.isAscending ? 'true' : 'false');
    sessionStorage.setItem('saveFilterProductID', JSON.stringify(this.listOfProductID));
    sessionStorage.setItem('saveFilteModel', JSON.stringify(this.filteModel));
  }

  clearSession() {
    sessionStorage.removeItem('saveFilterProductID');
    sessionStorage.removeItem('saveFilterIsAscending');
    sessionStorage.removeItem('saveFilteModel');
  }

  setSaveFilter() {
    if (sessionStorage.getItem('saveFilterProductID') != null && sessionStorage.getItem('saveFilterProductID') != '' && sessionStorage.getItem('saveFilterProductID') != 'undefined') {
      this.listOfProductID = JSON.parse(sessionStorage.getItem('saveFilterProductID'));
      this.filteModel.listOfProduct = this.listOfProductID.join(',');
    }
    if (sessionStorage.getItem('saveFilteModel') != null && sessionStorage.getItem('saveFilteModel') != '' && sessionStorage.getItem('saveFilteModel') != 'undefined') {
      this.filteModel = JSON.parse(sessionStorage.getItem('saveFilteModel'));
    }
    if (sessionStorage.getItem('saveFilterIsAscending') != null && sessionStorage.getItem('saveFilterIsAscending') != '' && sessionStorage.getItem('saveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('saveFilterIsAscending') == 'false' ? false : true;
      if (this.isAscending) {
        this.setDescCSS(this.filteModel.orderBy.replace('DESC', '').trim());
      } else {
        this.setAscCSS(this.filteModel.orderBy.replace('DESC', '').trim());
      }
    }
    this.clearSession();
  }

  setDescCSS(sortBy) {
    if (sortBy === "alertDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-down';
      this.upDownSubjectCSS = '';
      this.upDownProductsCSS = '';
      this.upDownStatusCSS = '';
      this.upDownCreatedDateCSS = '';
      this.upDownModifiedDateCSS = '';
    }
    else if (sortBy === "subject") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = 'fa fa-arrow-down';
      this.upDownProductsCSS = '';
      this.upDownStatusCSS = '';
      this.upDownCreatedDateCSS = '';
      this.upDownModifiedDateCSS = '';
    }
    else if (sortBy === "productId") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = '';
      this.upDownProductsCSS = 'fa fa-arrow-down';
      this.upDownStatusCSS = '';
      this.upDownCreatedDateCSS = '';
      this.upDownModifiedDateCSS = '';
    }
    else if (sortBy === "EmailStatus") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = '';
      this.upDownProductsCSS = '';
      this.upDownStatusCSS = 'fa fa-arrow-down';
      this.upDownCreatedDateCSS = '';
      this.upDownModifiedDateCSS = '';
    }
    else if (sortBy === "createdDate") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = '';
      this.upDownProductsCSS = '';
      this.upDownStatusCSS = '';
      this.upDownCreatedDateCSS = 'fa fa-arrow-down';
      this.upDownModifiedDateCSS = '';
    }
    else if (sortBy === "modifiedDate") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = '';
      this.upDownProductsCSS = '';
      this.upDownStatusCSS = '';
      this.upDownCreatedDateCSS = '';
      this.upDownModifiedDateCSS = 'fa fa-arrow-down';
    }

  }

  setAscCSS(sortBy) {
    if (sortBy === "alertDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-up';
      this.upDownSubjectCSS = '';
      this.upDownProductsCSS = '';
      this.upDownStatusCSS = '';
      this.upDownCreatedDateCSS = '';
      this.upDownModifiedDateCSS = '';
    }
    else if (sortBy === "subject") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = 'fa fa-arrow-up';
      this.upDownProductsCSS = '';
      this.upDownStatusCSS = '';
      this.upDownCreatedDateCSS = '';
      this.upDownModifiedDateCSS = '';
    }
    else if (sortBy === "productId") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = '';
      this.upDownProductsCSS = 'fa fa-arrow-up';
      this.upDownStatusCSS = '';
      this.upDownCreatedDateCSS = '';
      this.upDownModifiedDateCSS = '';
    }
    else if (sortBy === "EmailStatus") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = '';
      this.upDownProductsCSS = '';
      this.upDownStatusCSS = 'fa fa-arrow-up';
      this.upDownCreatedDateCSS = '';
      this.upDownModifiedDateCSS = '';
    }
    else if (sortBy === "createdDate") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = '';
      this.upDownProductsCSS = '';
      this.upDownStatusCSS = '';
      this.upDownCreatedDateCSS = 'fa fa-arrow-up';
      this.upDownModifiedDateCSS = '';
    }
    else if (sortBy === "modifiedDate") {
      this.upDownAlertDateCSS = '';
      this.upDownSubjectCSS = '';
      this.upDownProductsCSS = '';
      this.upDownStatusCSS = '';
      this.upDownCreatedDateCSS = '';
      this.upDownModifiedDateCSS = 'fa fa-arrow-up';
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
