import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientCompanyModel } from 'src/Models/ClientCompanyModel';
import { AdminUserType } from 'src/Models/Common';
import { CompanyService } from 'src/Services/company.service';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { ProductsService } from 'src/Services/products.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-price-quote-admin-company-selection',
  templateUrl: './price-quote-admin-company-selection.component.html',
  styleUrls: ['./price-quote-admin-company-selection.component.css']
})
export class PriceQuoteAdminCompanySelectionComponent implements OnInit {

  tokenKey: string = '';
  systemUserCondition: string = '';
  loginUserId: number = 0;

  clientCompanyList: ClientCompanyModel[];
  dropdownListclientCompany = [];
  selectedItemsclientCompany = [];
  dropdownSettingclientCompany = {};
  listOfCompany = [];
  companyId: number = 0;
  companyName: string = '';
  clientCompanyId: string = '';
  clientCompanyName: string = '';
  dbaCompanyName: string = '';
  clientInformationId: any;
  isAddButton: boolean = false;
  isSearchValue: boolean = true;
  itemSearchValue: string;
  isSaveSuccess: boolean = false;

  companyCreationMessage: string = "";
  newCompanyName: string = "";

  prompt = 'Press <enter> to add "';

  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  priceQuoteId:number
  
  
  constructor(private toastr: ToastrService, private router: Router, private UserService: UsersService, private checkAuth: CheckAuth,
    private PricingQuotesMaster: PricingQuotesService, private productsService: ProductsService, private companyService: CompanyService,
    private titleService: Title,private activedRoute: ActivatedRoute) {
    sessionStorage.removeItem('AdminPriceQuoteId')
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.clientCompanyId = sessionStorage.getItem('AdminClientCompanyId');
    this.loginUserId = Number(sessionStorage.getItem('AdminUserId'));
    this.priceQuoteId = Number(sessionStorage.getItem('AdminPriceQuoteId'));
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Company Profile - '+this.companyName);

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.priceQuoteId = params['id'];
        }
      }
    });
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['companyid']) {
        if (params['companyid'] != null) {
          this.clientCompanyId = params['companyid'];
        }
      }
    });

    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.systemUserCondition = 'null'
    else
      this.systemUserCondition = '  (CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.loginUserId + '))';
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.dropdownSettingclientCompany = {
      singleSelection: true,
      text: "Select Company",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No company available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      enableCheckAll: false
    };
    this.selectedItemsclientCompany = [];
    this.GetClientCompany();

  }

  GetClientCompany() {
    this.clientCompanyList = [];
    this.blockDocument();
    this.UserService.GetCompanyListWithIds(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          
          this.clientCompanyList = data['companyModel'];

          if (this.clientCompanyList != null) {
            if (this.clientCompanyList.length > 0) {
              for (let iCounter = 0; iCounter < this.clientCompanyList.length; iCounter++) {
                this.dropdownListclientCompany.push(
                  { "id": this.clientCompanyList[iCounter].companyId, "itemName": this.clientCompanyList[iCounter].companyName }
                );
              }
            }
          }
          this.unblockDocument();
          if (this.priceQuoteId != null && this.priceQuoteId!=undefined && this.priceQuoteId!=0) {
            this.GetClientInformationById(this.priceQuoteId);
          }
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );

  
   
  }

  onChangeSelectedCompany(event) {
    if (this.selectedItemsclientCompany.length > 0) {
      this.companyId = event.id;
      this.companyName = event.itemName;
      this.clientCompanyId= event.id;
      this.clientCompanyName = event.itemName;
      let obj = this.clientCompanyList.filter(m => m.companyId.toString() == this.clientCompanyId);
      if (obj != null && obj.length>0) {
        this.dbaCompanyName=obj[0].dba;
        sessionStorage.setItem('DbaCompanyName',obj[0].dba);
      }
      sessionStorage.setItem('ClientCompanyId', this.companyId.toString());
      sessionStorage.setItem('ClientCompanyName', this.companyName);
    }
  }

  onDeSelectAllCompany($event) {
    this.selectedItemsclientCompany = [];
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  GetClientInformationById(priceQuoteId: number) {
    this.blockDocument();
    this.PricingQuotesMaster.GetClientInformationById(this.tokenKey, priceQuoteId,0, false).subscribe(
      result => {
        if (result['status'] == 'Success' && result['clientInformation']) {
          
          this.clientCompanyId = result['clientInformation'].clientCompanyId,
            this.clientCompanyName = result['clientInformation'].clientCompanyName;
            this.dbaCompanyName = result['clientInformation'].dba;
            sessionStorage.setItem('ClientCompanyName',this.clientCompanyName);
            sessionStorage.setItem('ClientCompanyId',this.clientCompanyId);
            this.companyName=result['clientInformation'].clientCompanyName

            setTimeout(() => {
            if (this.clientCompanyId != null && this.clientCompanyId != undefined) {
              if (this.clientCompanyId != '') {
                let obj = this.clientCompanyList.filter(m => m.companyId.toString() == this.clientCompanyId);
                if (obj != null && obj.length>0) {
                  this.selectedItemsclientCompany.push({ "id": obj[0].companyId, "itemName": obj[0].companyName });
                  sessionStorage.setItem('DbaCompanyName',obj[0].dba);
                  sessionStorage.setItem('ClientCompanyName',obj[0].companyName);
                  this.companyName=obj[0].companyName;
                }
              }
            }else {setTimeout(() => {
              if (this.clientCompanyId != null && this.clientCompanyId != undefined) {
                if (this.clientCompanyId != '') {
                  let obj = this.clientCompanyList.filter(m => m.companyId.toString() == this.clientCompanyId);
                  if (obj != null&& obj.length>0) {
                    sessionStorage.setItem('DbaCompanyName',obj[0].dba);
                    this.selectedItemsclientCompany.push({ "id": obj[0].companyId, "itemName": obj[0].companyName });
                    sessionStorage.setItem('ClientCompanyName',obj[0].companyName);
                    this.companyName=obj[0].companyName;
                  }
                }
              }
             }, 800);}
            }, 800);

            if (this.isSaveSuccess) {
              if (this.itemSearchValue != '' && this.itemSearchValue != null) {
                let obj = this.clientCompanyList.filter(m => m.companyName == this.itemSearchValue);
                if (obj != null&& obj.length>0) {
                  this.selectedItemsclientCompany.push({ "id": obj[0].companyId, "itemName": obj[0].companyName });
                  sessionStorage.setItem('ClientCompanyId', obj[0].companyId.toString());
                  sessionStorage.setItem('ClientCompanyName', obj[0].companyName);
                  sessionStorage.setItem('DbaCompanyName', obj[0].dba);
                  this.companyName=obj[0].companyName;
                }
              }
            }
            this.unblockDocument();
        }
      });
  }

  save() {

    if (this.selectedItemsclientCompany.length == 0 || this.selectedItemsclientCompany.length == null) {
      this.showMessage('Please select company', 'Error', 'error');
      return;
    }

    sessionStorage.setItem('ClientCompanyId',this.clientCompanyId);
      let navigation = '/admin/PricingQuote/company-info/' + this.priceQuoteId;
      this.router.navigate([navigation]);
  }

  onKeydownEnter($event) {
    this.itemSearchValue = $event.target.value;
    var data = this.dropdownListclientCompany.filter(a => a.itemName.toLowerCase().includes(this.itemSearchValue.toLowerCase()));
    if (data.length == 0) {
      this.newCompanyName = '"' + this.itemSearchValue + '"';
      document.getElementById("btnopenEditpopup").click();
    }
    return;
  }

  onKeyup($event) {
    var name = $event.target.value;

    var data = this.dropdownListclientCompany.filter(a => a.itemName.toLowerCase().includes(name.toLowerCase()));
    if (data.length == 0) {
      this.companyCreationMessage = "";
      this.companyCreationMessage = this.prompt + name + '" Company';
      this.isAddButton = false;
      this.isSearchValue = false;
    }
    else {
      this.isSearchValue = true;
    }
  }

  AddCompany() {
    this.companyCreationMessage = "";
    this.isSearchValue = true;

    this.blockDocument();
    if (this.itemSearchValue == null || this.itemSearchValue == "") {
      this.showMessage("Please Enter New Company Name those you want to add", 'Error', 'error');
      return;
    }

    this.companyService.AddCompanyFromFront(this.itemSearchValue, this.loginUserId, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        this.isSaveSuccess = true;
        this.dropdownListclientCompany = this.removeElemetninArray(this.dropdownListclientCompany, this.itemSearchValue);
        this.selectedItemsclientCompany = [];
        this.GetClientCompany();
        this.unblockDocument();
      }
      else {
        if (data.message == 'duplicate') {

          this.showMessage('Duplicate Company not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else {
          this.showMessage(data.status, 'Error', 'error');
          this.unblockDocument();
        }
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }

  AddCompanyCancel() {
    this.companyCreationMessage = "";
    this.isSearchValue = true;
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  previousStep() {
    let navigation = '/admin/PricingQuote/pricing-quotes';
    this.router.navigate([navigation]);
    sessionStorage.removeItem('AdminPriceQuoteId');
  }

}
