import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ComplianceReportModel, ReportFilterModel } from 'src/Models/ReportModel';
import { ReportService } from 'src/Services/report.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-compliance-report',
  templateUrl: './compliance-report.component.html',
  styleUrls: ['./compliance-report.component.css']
})
export class ComplianceReportComponent implements OnInit {
  complianceReport = new ComplianceReportModel();
  tokenKey: string = '';
  loginUser: number = 0;
  reportFilter = new ReportFilterModel();
  data: any;
  pieOptions = {};
  username: any;
  constructor(private reportService: ReportService, private checkAuth: CheckAuth, private toastr: ToastrService) {
    this.checkAuth.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginUser = +sessionStorage.getItem('UserId');
    this.username = sessionStorage.getItem('UserName');

  }

  ngOnInit() {
    this.reportFilter.userName = this.username// this.loginUser //mhope;
    this.getReport();
    this.pieOptions = {
      legend: {
        position: 'right'
      }
    };
  }

  getReport() {
    this.reportService.ComplianceReport(this.tokenKey, this.reportFilter).subscribe(data => {
      if (data.status == "Success") {
        this.complianceReport = data.model;
        this.data = {
          labels: ['Total Reports', 'Total Reports Filed', 'Total Reports Filed by Client', 'Total Reports Not Sent', 'Total Reports Not Received', 'Total Reports with No Remittance'],
          datasets: [
            {
              data: [this.complianceReport.total_Reports,
              this.complianceReport.total_Reports_Filed,
              this.complianceReport.total_Reports_Filed_by_Client,
              this.complianceReport.total_Reports_Not_Sent,
              this.complianceReport.total_Reports_Not_Received,
              this.complianceReport.total_Reports_with_No_Remittance],
              backgroundColor: [
                '#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC'

              ],
              hoverBackgroundColor: [
                '#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC'
              ]
            }]
        };
      }
      else {
        this.toastr.error("Error", "Error");
      }
    })
  }

  showChart() {
    if (this.complianceReport.total_Reports != 0 || this.complianceReport.total_Reports_Filed != 0
      || this.complianceReport.total_Reports_Filed_by_Client != 0
      || this.complianceReport.total_Reports_Not_Received != 0
      || this.complianceReport.total_Reports_Not_Sent != 0
      || this.complianceReport.total_Reports_with_No_Remittance != 0)
      return true;
    else
      return false;
  }

}
