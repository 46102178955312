import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { CRRADepositeModel } from 'src/Models/CRRADepositeModel';
import { CRRAUpdateGetModel } from 'src/Models/CRRAUpdateModel';
import { FavoriteFilterModel } from 'src/Models/FavoriteFilterModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { CompanyService } from 'src/Services/company.service';
import { CrraDepositeMasterService } from 'src/Services/crra-deposite-master.service';
import { CrraUpdatesMasterService } from 'src/Services/crra-updates-master.service';
import { FavoriteService } from 'src/Services/favorite.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';
import * as FileSaver from 'file-saver';
import { HtmlToPdf } from 'src/Services/htmlToPdf';
import { UsersService } from 'src/Services/users.service';

@Component({
  selector: 'app-deposit-rates',
  templateUrl: './deposit-rates.component.html',
  styleUrls: ['./deposit-rates.component.css']
})
export class DepositRatesComponent implements OnInit {

  stateCode: any;
  userId: any
  ProductId: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];
  stateSubscription: any;
  statefilterData: any;

  tokenKey: string;
  SearchItem: string = '';

  PageNumber: number = 1;
  PageSize: number;
  filterText: string;
  OrderBy: string;
  OrderByName: string = 'StateName';

  rangeOfupdatesDates: any;

  upDowStateNameCSS: string = '';
  upDowIXCDepCSS: string = '';
  upDowCLECDepCSS: string = '';
  upDowIXCPayCSS: string = '';
  upDowCLECPayCSS: string = '';
  upDowRateCSS: string = '';
  upDowNoteCSS: string = '';

  isAscending: boolean = true;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  dropdownDateSelectedColumnFilter = [];
  selectedColumn = [];
  listOfColumnId = [];
  dropdownSettingsDateselectedColumnFilter = {};

  SelectedStateFav: number = 0;
  favouriteStateModel: FavoriteFilterModel[] = [];
  displayFavorite: number = 0;
  blockedDocument = false;

  isShowprogressSpinner = false;
  listofDeposite: CRRADepositeModel[];
  isColumnExist: boolean = true;
  module = "CRRA";
  canDownload: boolean = false;
  _userID: string = "";
  baseUrl: string;
  @ViewChild('deletecloseBtn', { static: false }) deletecloseBtn: ElementRef;
  CRRAexportCitiationList: any[] = [];

  constructor(private appActivity: ApplicationActivityService,
    private router: Router,
    private checkAuth: CheckAuth,
    private titleService: Title,
    private stateService: StateMasterService, private toastr: ToastrService, private favorite: FavoriteService,
    private crradepositMaster: CrraDepositeMasterService, private companyService: CompanyService, private htmlToPdf: HtmlToPdf,private userService: UsersService) {

    this.tokenExpireCalculation(
      sessionStorage.getItem("appStartTime"),
      sessionStorage.getItem("tokenExpiryValue")
    );

    this.userId = sessionStorage.getItem('UserId');
    this.ProductId = ToolEnum.crra;
    this.titleService.setTitle('Deposit Rates');
    this.tokenKey = sessionStorage.getItem("tokenValue");
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Jurisdiction(s) available",
      showCheckbox: true,
    };


    this.getColumn();
    this.getState();
    this.getCanDownload();
    this.PageNumber = 1;
    this.PageSize = 10;
    this.OrderByName = "";
    this.OrderBy = "StateName";
    this.GetDeposit();
    this.CRRAExportCitiationSubscriptionGet();
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.filterText = $event.target.value;
    else
      this.filterText = 'null';

    this.GetDeposit();
  }


  getState() {
    this.stateSubscription = null;
    this.stateService.i360ManageSubscriptionClientId(this.tokenKey, this.userId, 0, false).subscribe(
      data => {
        this.stateSubscription = {
          wirelessStates: data.wirelessStates,
          wirelessStatesCount: data.wirelessStates ? data.wirelessStates.length : 0,
          iprStates: data.iprStates,
          iprStatesCount: data.iprStates ? data.iprStates.length : 0,
          voIPStates: data.voIPStates,
          voIPStatesCount: data.voIPStates ? data.voIPStates.length : 0,
          actionStates: data.actionStates,
          actionStatesCount: data.actionStates ? data.actionStates.length : 0,
          productAccess: data.productAccess,
          briefingStates: data.briefingStates,
          briefingStatesCount: data.briefingStates ? data.briefingStates.length : 0,
          state911: data.state911,
          state911Count: data.state911 ? data.state911.length : 0,
          carrierCategory: data.carrierCategory,
          carrierCategoryCount: data.carrierCategory ? data.carrierCategory.filter(m => m.isSelected == true).length : 0,
          trfa: data.trfa,
          crra: data.crra
        };
        this.stateList = data.crra;
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { console.log(error); }
    );
  }

  CRRAExportCitiationSubscriptionGet() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.userService.ExportCitiationSubscriptionGetByUser(this.tokenKey, parseInt(this.userId),this.ProductId).subscribe(
      data => {

        this.CRRAexportCitiationList = data['exportCitiation'];

      },
      error => { }
    );
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetDeposit();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetDeposit();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetDeposit();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.resetPageNoandSize();
    this.GetDeposit();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  GetDeposit() {
    this.isloading = true;

    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }

    if (this.filterText == "null") this.filterText = " ";

    let listofstatename = "";

    this.listOfStateIds.forEach((value, y) => {
      const statemodal = this.stateList.filter(x => x.stateId == value);
      if (this.listOfStateIds.length - 1 == y) {
        listofstatename = listofstatename + statemodal[0].name + '<br />';
      }
      else {
        listofstatename = listofstatename + statemodal[0].name + ',' + '<br />';
      }
    });

    if (listofstatename != "") {
      listofstatename = listofstatename.trim();
      ApplicationActivityStore('View', 'CRRA Deposit-', this.appActivity, ToolEnum.crra, ActionActivity.CRRA, '/View/deposit-view', listofstatename);
    }

    this.crradepositMaster.GetAllDepositByUser(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, this.filterText, parseInt(this.userId), this.OrderBy, this.displayFavorite).subscribe(
      data => {
        this.isloading = false;
        this.listofDeposite = data['crraDeposite'];
        if (this.listofDeposite != null) {
          if (this.listofDeposite.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofDeposite[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  sort(sortBy: string) {
    if (!this.isAscending) {

      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDowNoteCSS = '';
      this.upDowStateNameCSS = '';

      if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active-up';
      }

      else if (sortBy === "IXCDep") {
        this.upDowIXCDepCSS = 'active-up';
      }

      else if (sortBy === "CLECDep") {
        this.upDowCLECDepCSS = 'active-up';
      }

      else if (sortBy === "IXCPay") {
        this.upDowIXCPayCSS = 'active-up';
      }

      else if (sortBy === "CLECPay") {
        this.upDowCLECPayCSS = 'active-up';
      }

      else if (sortBy === "Rate") {
        this.upDowRateCSS = 'active-up';
      }

      else if (sortBy === "Notes") {
        this.upDowNoteCSS = 'active-up';
      }


    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      this.upDowNoteCSS = '';
      this.upDowStateNameCSS = '';

      if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active';
      }

      else if (sortBy === "IXCDep") {
        this.upDowIXCDepCSS = 'active';
      }

      else if (sortBy === "CLECDep") {
        this.upDowCLECDepCSS = 'active';
      }

      else if (sortBy === "IXCPay") {
        this.upDowIXCPayCSS = 'active';
      }

      else if (sortBy === "CLECPay") {
        this.upDowCLECPayCSS = 'active';
      }

      else if (sortBy === "Rate") {
        this.upDowRateCSS = 'active';
      }

      else if (sortBy === "Notes") {
        this.upDowNoteCSS = 'active';
      }


    }
    this.OrderByName = sortBy;
    this.resetPageNoandSize();
    this.GetDeposit();
  }

  getColumn() {

    this.dropdownDateSelectedColumnFilter = [
      { "id": 1, "itemName": 'Jurisdiction' },
      { "id": 2, "itemName": 'IXCDep' },
      { "id": 3, "itemName": 'CLECDep' },
      { "id": 4, "itemName": 'IXCPay' },
      { "id": 5, "itemName": 'CLECPay' },
      { "id": 6, "itemName": 'Rate Set Annually' },
      { "id": 7, "itemName": 'Note' }
    ]

    this.selectedColumn = [
      { "id": 1, "itemName": 'Jurisdiction' },
      { "id": 2, "itemName": 'IXCDep' },
      { "id": 3, "itemName": 'CLECDep' },
      { "id": 4, "itemName": 'IXCPay' },
      { "id": 5, "itemName": 'CLECPay' },
      { "id": 6, "itemName": 'Rate Set Annually' },
      { "id": 7, "itemName": 'Note' }
    ]
  }

  isCheckColum(id) {
    let data = this.selectedColumn.filter((x => x.id == id))[0];
    if (data != null)
      return true
    else return false
  }

  getPageLength() {
    // if (this.PageSize < this.totalRecordCount) return this.PageSize
    // else return this.totalRecordCount
    return Math.min((this.PageNumber * this.PageSize), this.totalRecordCount);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    ApplicationActivityStore('View', 'CRRA Deposit' + $event.page, this.appActivity, ToolEnum.crra, ActionActivity.CRRA, '/View/deposit-rate');
    this.GetDeposit();
  }

  onFilterSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetDeposit();
  }

  onFilterDeSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetDeposit();
  }

  AddToFavourite(item, IsFavourite: number) {
    let stateId = item.stateId;
    if (IsFavourite == 0) {
      this.SelectedStateFav = stateId;
      this.favouriteStateModel = item;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavourite(stateId, IsFavourite, item);
    }
  }

  GetFavourite(displayFav: number) {
    this.displayFavorite = displayFav;
    this.GetDeposit();
  }

  SaveFavourite(stateId: string, IsFavourite: number, item) {
    this.blockDocument();
    //document.getElementById("btnClose").click();
    this.favorite.ClientStateFavouriteAdd(this.tokenKey, parseInt(this.userId), stateId, IsFavourite, ToolEnum.crra).subscribe(data => {
      if (data["status"] == "Success") {
        this.GetDeposit();
        if (IsFavourite == 1) {
          this.showMessage('Selected jurisdiction added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          this.showMessage('Selected jurisdiction removed successfully from favorite list.', 'Favorite', 'success');
        }
      }
      else {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  clear() {
    this.filterText="";
    this.SearchItem = "";
    this.stateId = '';
    this.selectedItemsState = [];
    this.listOfStateIds = [];
    this.GetDeposit();
  }

  getCanDownload() {
    this.companyService.GetCompanybyUserId(this.tokenKey, this.userId).subscribe(
      data => {
        if (data != null) {
          if (data['result'] > 0) {
            this.canDownload = true;
          }
          else
            this.canDownload = false;
        }
      },
      error => { }
    );
  }

  validExport(id) {
    if (this.CRRAexportCitiationList.length > 0) {
      let data = this.CRRAexportCitiationList.find((x) => x.exportCitiationId == id);
      if (data != null) return true;
      else return false
    }
    else return false
    // return true;
  }

  createMyPdf() {
    this.blockDocument();

    this.stateId = '';
    if (this.stateList.length > 0) {
      this.stateId = this.listOfStateIds.join(',')

      if (this.filterText == "null" || this.filterText == undefined) this.filterText = " ";

      this.htmlToPdf.CreateDepositePDF(this.tokenKey, this.stateId, this.filterText,parseInt(this.userId),this.displayFavorite ,'0').
        subscribe(data => {
          if (data['status'] == 'Success') {
            this.downloadFile('DepositeRate.pdf', data['url'], data['fileName']);
            this.unblockDocument();
          }
        },
          error => {
            this.unblockDocument();
          }
        );
    }
    else {
      this.unblockDocument();
    }
  }

  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        sessionStorage.setItem('fileloading', 'false');
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  print() {
    this.blockDocument();

    this.stateId = '';
    if (this.stateList.length > 0) {
      this.stateId = this.listOfStateIds.join(',')

      if (this.filterText == "null" || this.filterText == undefined) this.filterText = " ";

      this.htmlToPdf.CreateDepositePDF(this.tokenKey, this.stateId, this.filterText,parseInt(this.userId),this.displayFavorite ,'0').
        subscribe(data => {
          if (data['status'] == 'Success') {
            this.PrintPreviewPDF(data['url'],data['fileName']);
            this.unblockDocument();
          }
        },
          error => {
            this.unblockDocument();
          }
        );
    }
    else {
      this.unblockDocument();
    }
  }

  PrintPreviewPDF(PDFPath:string,orignalFileName:any)
  {
    this.htmlToPdf.downloadPdf(PDFPath).subscribe(
      (res) => {
        this.blockDocument();
        // FileSaver.saveAs(res, filename);
        const blobUrl = window.URL.createObjectURL((res));
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
    this.unblockDocument();
  }

}