import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { FilterModel } from 'src/Models/filterModel';
import { RMDScheduleLogHistoryModel } from 'src/Models/RMDScheduleLogHistoryModel';
import { RMDUpdatesModel } from 'src/Models/RMDUpdatesModel';
import { RmdupdatesService } from 'src/Services/rmdupdates.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-rmd-updates',
  templateUrl: './rmd-updates.component.html',
  styleUrls: ['./rmd-updates.component.css']
})
export class RMDUpdatesComponent implements OnInit {

  filterModel = new FilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  rmdUpdatesModel: RMDUpdatesModel[];
  carrier: RMDUpdatesModel;

  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false
  upDownBussinessNameCSS: string = '';
  upDownPrevious_dba_namesCSS: string = '';
  upDownOther_dba_namesCSS: string = '';
  upDownForeign_voice_providerCSS: string = '';
  upDownImplementationCSS: string = '';
  upDownSys_updated_onCSS: string = '';
  upDownUpdateCountCSS: string = '';
  upDownFrn_strCSS: string = '';


  isloading: boolean;
  iscarrierloading: boolean;
  dropdownSettingsActionName = {};
  dropdownListActionName = [];
  selecteddropdownListActionName = [];

  dropdownSettingsProduct = {};
  dropdownListProduct = [];
  selecteddropdownListProduct = [];
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  lastModifiedDetails = '';
  ishistoryloading = false;
  historyModel: RMDScheduleLogHistoryModel[];
  totalHistoryCount = -1;

  constructor(private rmdUpdatesService: RmdupdatesService, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('RMD Updates');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'Sys_updated_on DESC';
    this.upDownSys_updated_onCSS = 'fa fa-arrow-up';
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.movetotop();
    this.GetRMDUpdatesFilter();
  }

  GetRMDUpdatesFilter() {
    this.rmdUpdatesModel = [];
    this.isloading = true;
    this.rmdUpdatesService.GetUpdatesFilterByPage(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.rmdUpdatesModel = data['updates'];
          if (this.rmdUpdatesModel != null) {
            if (this.rmdUpdatesModel.length > 0) {
              this.totalRecordCount = this.rmdUpdatesModel[0].totalRecordCount;
              this.lastModifiedDetails = 'Last updated on ' + this.rmdUpdatesModel[0].lastModifiedDate;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters(event) {
    this.filterModel.pageNumber = '1';
    if (event.target.value != "" && event.target.value != null)
      this.filterModel.keywordFilter = event.target.value;
    else
      this.filterModel.keywordFilter = '';

    this.currentPage = 1;
    this.GetRMDUpdatesFilter();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetRMDUpdatesFilter();
  }

  cleanCssClass() {
    this.upDownBussinessNameCSS = '';
    this.upDownPrevious_dba_namesCSS = '';
    this.upDownOther_dba_namesCSS = '';
    this.upDownForeign_voice_providerCSS = '';
    this.upDownImplementationCSS = '';
    this.upDownSys_updated_onCSS = '';
    this.upDownUpdateCountCSS = '';
    this.upDownFrn_strCSS = '';
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case "Business_name": this.upDownBussinessNameCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "Previous_dba_names": this.upDownPrevious_dba_namesCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "Other_dba_names": this.upDownOther_dba_namesCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "Foreign_voice_provider": this.upDownForeign_voice_providerCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "Implementation": this.upDownImplementationCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "Sys_updated_on": this.upDownSys_updated_onCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "UpdateCount": this.upDownUpdateCountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "Frn_str": this.upDownFrn_strCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetRMDUpdatesFilter();
  }

  ActiveInactiveUpdates(updateId: any, isactive: any) {
    this.blockDocument();
    this.rmdUpdatesService.ActiveInactiveRMDUpdates(this.tokenKey, updateId, isactive)
      .subscribe(data => {
        if (data["status"] == "Success") {
          if (isactive) {
            this.showMessage('Activated successfully', 'Active', 'success');
          }
          else {
            this.showMessage('Inactivated successfully', 'Inactive', 'success');
          }
          this.GetRMDUpdatesFilter();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
        this.unblockDocument();
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }

  getCarrierUpdateHistory(id: number) {
    this.ishistoryloading = true;
    this.rmdUpdatesService.GetUpdateHistoryByCarrierID(this.tokenKey, id).subscribe(
      data => {
        if (data["status"] == "Success") {
          this.historyModel = data["updates"];
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
        this.ishistoryloading = false;
      },
      error => {
        this.ishistoryloading = false;
      },
    );
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }

  GetRMDUpdateById(carrierId) {
    this.carrier = undefined;
    this.iscarrierloading = true;
    this.rmdUpdatesService.GetRMDUpdatesFindByCarrierID(this.tokenKey, carrierId).subscribe(
      data => {
        this.iscarrierloading = false;
        if (data != null) {
          this.carrier = data['updates'];
        }
      },
      error => {
        this.iscarrierloading = false;
        console.log(error);
      }
    );

  }

}
