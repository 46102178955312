import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-front-data477',
  templateUrl: './front-data477.component.html',
  styleUrls: ['./front-data477.component.css']
})
export class FrontData477Component implements OnInit {

  constructor(private titleService: Title) { 
    this.titleService.setTitle('477 Data | Inteserra - i360');
  }

  ngOnInit() {
  }

}
