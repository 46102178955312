import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterCRRAUpdateModel } from 'src/Models/CRRAUpdateModel';

@Injectable({
  providedIn: 'root'
})
export class CrraUpdatesMasterService {

  AppUrl: string = "";
  filterUpdateModel = new FilterCRRAUpdateModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }


  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllUpdate(TokenKey: string, PageNumber: number, PageSize: number, stateID: string, orderBy: string): Observable<any> {
    this.filterUpdateModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      stateID: stateID,
      orderBy: orderBy,
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRAUpdateMaster/GetAllUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterUpdateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  UpdateAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRAUpdateMaster/UpdateAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetUpdateById(TokenKey: string, updateId: number): Observable<any> {
    this.setAPIUrl(); 
    let data = 'api/CRRAUpdateMaster/GetUpdateById/' + updateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteUpdate(TokenKey: string, updateId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRAUpdateMaster/DeleteUpdate/' + updateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllUpdateByUser(TokenKey: string, PageNumber: number, PageSize: number,stateID: string, fromDate: string, toDate: string, orderBy: string, userId:number,isFavorite:number): Observable<any> {
    this.filterUpdateModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      stateID: stateID,
      orderBy: orderBy,
      fromDate: fromDate,
      toDate: toDate,
      userId:userId,
      isFavorite:isFavorite,
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRAUpdateMaster/GetAllUpdateByUser';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterUpdateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  // ExportUpdates(TokenKey: string, PageNumber: number, PageSize: number, stateID: string, orderBy: string): Observable<any> {
  //   this.filterUpdateModel = {
  //     pageNumber: PageNumber,
  //     pageSize: PageSize,
  //     stateID: stateID,
  //     orderBy: orderBy,
  //   }
  //   this.setAPIUrl();
  //   let URL = this.AppUrl + 'api/CRRUpdateMaster/ExportUpdates';
  //   const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  //   return this._http.post<any>(URL, this.filterUpdateModel, httpOptions)
  //     .pipe(catchError(this.handleError));
  // }

  GetCRRANotificationHistory(userlink: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFA_911_DownloadHistory/GetCRRANotificationHistory/'+userlink;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}