import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { NLADClaimService } from 'src/Services/nladClaim.service';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { InrollProductsCompanyModel } from 'src/Models/InrollProductsCompanyModel';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { I360Service } from 'src/Services/i360Service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-claim-imports',
  templateUrl: './claim-imports.component.html',
  styleUrls: ['./claim-imports.component.css']
})
export class ClaimImportsComponent implements OnInit {
  SelectedTool: string;
  productId: number = 1;
  providerId: number = 0;
  userId: number = 0;

  @ViewChild('uploadSourceFile', { static: false }) uploadSourceFile: ElementRef;
  @ViewChild('uploadDestinationFile', { static: false }) uploadDestinationFile: ElementRef;
  sourceFile: File;
  destinationFile: File;
  file: File;
  isErrorOnSourceFileDoc: boolean = true;
  isErrorOnDestinationFileDoc: boolean = true;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;

  listOfInrollProductCompany: InrollProductsCompanyModel[];

  dropdownCompanies = [];
  selectedItemsCompanies = [];
  dropdownSettingCompanies = {};
  listOfCompany = [];

  dropdownMonths = [];
  selectedItemsMonths = [];
  dropdownSettingMonths = {};
  listOfMonths = [];

  years: any = '';
  tokenKey: string = '';
  errorMessage: string = '';

  constructor(
    private router: Router,
    private titleService: Title,
    private nladService: NLADClaimService,
    private toastr: ToastrService,
    private checkAuth: CheckAuth,
    private i360service: I360Service,
  ) {
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('ACP Claim Import');
    this.productId = ToolEnum[this.SelectedTool];
    this.providerId = 0;
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.GetInrollClientCompany();
    this.GetMonths();
  }

  GetInrollClientCompany() {
    this.nladService.GetInrollClientCompany().subscribe(
      data => {
        this.dropdownSettingCompanies = {
          singleSelection: true,
          text: 'Select Inroll Company',
          enableSearchFilter: true,
          classes: 'borderLessTextBoxGreen',
          badgeShowLimit: 1,
          noDataLabel: 'No inroll company',
          showCheckbox: false,
          enableFilterSelectAll: false
        };

        if (data != null) {
          if (data.status == 'Success') {
            this.listOfInrollProductCompany = data.listofInrollCompany;
            if (this.listOfInrollProductCompany != null) {
              if (this.listOfInrollProductCompany.length > 0) {
                for (let iCounter = 0; iCounter < this.listOfInrollProductCompany.length; iCounter++) {
                  this.dropdownCompanies.push({ 'id': this.listOfInrollProductCompany[iCounter].companyId, 'itemName': this.listOfInrollProductCompany[iCounter].companyName });
                }
              }
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedCompanies($event) {
    this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event.id)
    this.listOfCompany.push($event.id);
  }

  onDeSelectedCompanies($event) {
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event.id)
      }
    }
  }

  onSelectAllCompanies($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllCompanies($event) {
    this.listOfCompany = [];
  }

  removeElemetninArrayById(array, element) {
    return array.filter(e => e.id !== element);
  }

  onFileSelect(file, type) {
    if (type == 'Destination')
      this.destinationFile = file.target.files[0];
    else
      this.sourceFile = file.target.files[0];

    this.file = file.target.files[0];
    let size = Math.round(this.file.size / 1024);
    if (size <= 500000) {
      var allowedExtensions = ["csv", "CSV"];
      let fileType = this.file.name.split('.').pop();

      if (!this.isInArray(allowedExtensions, fileType)) {
        this.toastr.error('You have selected an invalid ' + type + ' file type.System supports following file formats: csv.', 'Error');
        if (type == 'Destination')
          this.isErrorOnDestinationFileDoc = true;
        else
          this.isErrorOnSourceFileDoc = true;

        window.scrollTo(0, 0);
      }
      else {
        if (type == 'Destination')
          this.isErrorOnDestinationFileDoc = false;
        else
          this.isErrorOnSourceFileDoc = false;
      }
    }
    else {
      this.toastr.error('You have selected a larger ' + type + ' file. supports file upto 500MB.', 'Error');
      if (type == 'Destination')
        this.isErrorOnDestinationFileDoc = true;
      else
        this.isErrorOnSourceFileDoc = true;
      window.scrollTo(0, 0);
    }
  }

  /*- checks if word exists in array -*/
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  onFileUpload() {
    this.errorMessage = '';

    if (this.listOfMonths.length == 0) {
      this.toastr.error('Please select month', 'Error');
      return;
    }

    if (this.years.length < 4) {
      this.toastr.error('Please enter year', 'Error');
      return;
    }

    if (this.isErrorOnSourceFileDoc) {
      this.toastr.error('Please select the proper source file', 'Error');
      return;
    }

    if (this.isErrorOnDestinationFileDoc) {
      this.toastr.error('Please select the proper USAC Claim file', 'Error');
      return;
    }

    this.blockDocument();
    let formData = new FormData();
    if (this.file) {
      this.providerId = parseInt(this.listOfCompany.join(','));
      formData.append("uploadSourceFile", this.sourceFile);
      formData.append("uploadDestinationFile", this.destinationFile);
      formData.append("UserId", this.userId.toString());
      formData.append("providerId", this.providerId.toString());
      formData.append("Months", this.listOfMonths.join(','));
      formData.append("Years", this.years);
    }
    this.nladService.EBBClaimImport(formData).subscribe(
      fdata => {
        
        if (fdata != null) {
          if(fdata=='0')
          {
            this.toastr.warning('File upload process is running in background. Please reload page after some time.', 'Information');
            this.router.navigate(['/admin/inroll/claim/listing']);
          }
          else{
          if (fdata.result == 'Success' && fdata.destinationResponse == '' && fdata.sourceResponse == '') {
            this.clear();            
            const downloadobj = new FilterModel();
            if (fdata.matchDataExportPath != null) {
              
              downloadobj.docPath = fdata.matchDataExportPath;
              downloadobj.docType = "EBBClaimFile";
              this.i360service.DownloadDocument(this.tokenKey, downloadobj).subscribe(
                fileData => {
                  this.unblockDocument();
                  if (fileData != null) {
                    if (fdata.errorDataExportPath != null)
                      this.router.navigate(['/admin/inroll/claim/listing']);
                    FileSaver.saveAs(fileData, 'EBBClaim.csv');
                  }
                }, error => {
                  this.unblockDocument();
                  console.log(error);
                }
              );
            }
            if (fdata.errorDataExportPath != null) {
              downloadobj.docPath = fdata.errorDataExportPath;
              downloadobj.docType = "EBBClaimFile";
              this.i360service.DownloadDocument(this.tokenKey, downloadobj).subscribe(
                fileData => {
                  this.unblockDocument();
                  if (fileData != null) {
                    this.router.navigate(['/admin/inroll/claim/listing']);
                    FileSaver.saveAs(fileData, 'EBBClaimError.csv');
                  }
                }, error => {
                  this.unblockDocument();
                  console.log(error);
                }
              );
            }

          }
          else if (fdata.sourceResponse != null && fdata.sourceResponse != '') {
            this.unblockDocument();
            this.errorMessage = fdata.sourceResponse;
            this.toastr.error(fdata.sourceResponse, 'Column Missing in Source File');
          }
          else if (fdata.destinationResponse != null && fdata.destinationResponse != '') {
            this.unblockDocument();
            this.errorMessage = fdata.destinationResponse;
            this.toastr.error(fdata.destinationResponse, 'Column Missing in USAC File');
          }
          else if (fdata.result != 'Success') {
            this.unblockDocument();
            this.errorMessage = fdata.result;
            this.toastr.error(fdata.result, 'Error');
          }
        }
        }
      },
      error => {
        this.unblockDocument();
        console.log(error);
      }
    );
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  GetMonths() {
    this.dropdownSettingMonths = {
      singleSelection: true,
      text: 'Select Month',
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No Month',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.dropdownMonths.push({ 'id': 1, 'itemName': 'January' });
    this.dropdownMonths.push({ 'id': 2, 'itemName': 'February' });
    this.dropdownMonths.push({ 'id': 3, 'itemName': 'March' });
    this.dropdownMonths.push({ 'id': 4, 'itemName': 'April' });
    this.dropdownMonths.push({ 'id': 5, 'itemName': 'May' });
    this.dropdownMonths.push({ 'id': 6, 'itemName': 'June' });
    this.dropdownMonths.push({ 'id': 7, 'itemName': 'July' });
    this.dropdownMonths.push({ 'id': 8, 'itemName': 'August' });
    this.dropdownMonths.push({ 'id': 9, 'itemName': 'September' });
    this.dropdownMonths.push({ 'id': 10, 'itemName': 'October' });
    this.dropdownMonths.push({ 'id': 11, 'itemName': 'November' });
    this.dropdownMonths.push({ 'id': 12, 'itemName': 'December' });
  }

  onSelectedMonths($event) {
    this.listOfMonths = [];
    this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event.id)
    this.listOfMonths.push($event.id);
  }

  onDeSelectedMonths($event) {
    this.listOfMonths = [];
    if (this.listOfMonths != null) {
      if (this.listOfMonths.length > 0) {
        this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event.id)
      }
    }
  }

  onSelectAllMonths($event) {
    this.listOfMonths = [];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonths != null) {
        if (this.listOfMonths.length > 0) {
          this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event[iCounter].id)
          this.listOfMonths.push($event[iCounter].id);
        }
        else {
          this.listOfMonths.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllMonths($event) {
    this.listOfMonths = [];
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clear() {
    this.uploadSourceFile.nativeElement.value = "";
    this.uploadDestinationFile.nativeElement.value = "";
    this.years = '';
    this.selectedItemsMonths = [];
    this.listOfMonths = [];
    this.selectedItemsCompanies = [];
    this.listOfCompany = [];
  }
}
