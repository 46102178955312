import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TRFASourcesModel } from 'src/Models/TRFASourceModel';
import { TrfaSourceMasterService } from 'src/Services/trfa-source-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.css']
})
export class SourceComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofSource: TRFASourcesModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  SourceName: string;


  upDowSourceNameCSS: string = '';
  upDowSortOrderCSS: string = '';
  upDowActiveCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  SourceTypeId: number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private trfaSourceMaster: TrfaSourceMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofSource = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'sortOrder';
    this.SourceName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Source');
    
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetSource();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetSource();
  }

  GetSource() { 
     
      if (this.SourceName == "null") this.SourceName = " ";
      this.trfaSourceMaster.GetAllSource(this.tokenKey, this.PageNumber, this.PageSize, this.SourceName, this.OrderBy).subscribe(
        data => {
          this.isloading = false;
          this.listofSource = data['source'];
          if (this.listofSource != null) {
            if (this.listofSource.length == 0)
              this.isRecord = true;
            else {
              this.isRecord = false;
              this.totalRecordCount = this.listofSource[0].totalRecordCount;
            }
          }
          else
            this.isRecord = true;
        },
        error => { this.stateCode = error }
      );
    
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.SourceName = $event.target.value;
    else
      this.SourceName = 'null';

    this.GetSource();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "SourceName") {
        this.upDowSourceNameCSS = 'fa fa-arrow-down';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowSourceNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowSourceNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "SourceName") {
        this.upDowSourceNameCSS = 'fa fa-arrow-up';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowSourceNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-up';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowSourceNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-up';
      }
    }
    this.GetSource();
  }

  addSource() {
    let navigation = '/admin/' + this.SelectedTool + '/source/manage';
    this.router.navigate([navigation]);
  }

  updateSource(SourceTypeId) {
    let navigation = '/admin/' + this.SelectedTool + '/source/manage/' + SourceTypeId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.SourceTypeId = id;
  }

  deleteSource(SourceTypeId) {
    this.isloading = true;
    this.trfaSourceMaster.DeleteSource(this.tokenKey, SourceTypeId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
            this.showMessage("Source Deleted successfully", 'Success', 'success')
        }
        this.GetSource();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
