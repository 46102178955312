import { Component, OnInit } from '@angular/core';
import { EBBClaimCountReportsModel } from 'src/Models/EBBClaimCountReportsModel';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { NLADClaimService } from 'src/Services/nladClaim.service';
import { InrollProductsCompanyModel } from 'src/Models/InrollProductsCompanyModel';
import { ClaimStateModel } from 'src/Models/ClaimStateModel';
import { Router } from '@angular/router';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';

@Component({
  selector: 'app-summary-lifeline-reports',
  templateUrl: './summary-lifeline-reports.component.html',
  styleUrls: ['./summary-lifeline-reports.component.css']
})
export class SummaryLifelineReportsComponent implements OnInit {

  filterModel = new FilterModel();
  listOfData: EBBClaimCountReportsModel[];
  tokenKey: string = '';
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false

  upDowCompanyNameCSS: string = '';
  upDowYearsCSS: string = '';
  upDowMonthsCSS: string = '';
  upDowTotalCountCSS: string = '';
  upDowTotalAmountCSS: string = ''; 
  upDowStateCSS: string = '';

  dropdownMonths = [];
  selectedItemsMonths = [];
  dropdownSettingMonths = {};
  listOfMonths = [];

  dropdownYears = [];
  selectedItemsYears = [];
  dropdownSettingYears = {};
  listOfYears = [];
  monthText = [];

  listOfInrollProductCompany: InrollProductsCompanyModel[];
  dropdownCompanies = [];
  selectedItemsCompanies = [];
  dropdownSettingCompanies = {};
  listOfCompany = [];

  listOfStateData: ClaimStateModel[];
  dropdownStates = [];
  selectedItemsStates = [];
  dropdownSettingStates = {};
  listOfStates = [];

  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  isloading: boolean = false;

  constructor(private appActivity: ApplicationActivityService,private nladClaimService: NLADClaimService, private checkAuth: CheckAuth, private titleService: Title, private router: Router) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Claims Summary-Lifeline | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'CompanyName';
    this.filterModel.userID = this._userID;
    this.filterModel.productId = 0;
    this.filterModel.months = '0';
    this.filterModel.years = '0';
    this.filterModel.state = '0';

    if (sessionStorage.getItem('CompanyId') != null && sessionStorage.getItem('CompanyId') != '')
    this.filterModel.providerId = sessionStorage.getItem('CompanyId');

    this.LifelineClaimSummaryReports();
    this.GetMonths();
    this.GetYears();

    this.GetClaimState();

    sessionStorage.removeItem('FrontDetailReportsYear');
    sessionStorage.removeItem('FrontDetailReportsMonths');
    sessionStorage.removeItem('FrontDetailReportsProviderId');
    sessionStorage.removeItem('FrontDetailReportsCompanyName');
    sessionStorage.removeItem('FrontDetailReportsState');
    sessionStorage.removeItem('FrontReportFlag');

    this.monthText.push({ 'id': 1, 'itemName': 'January' });
    this.monthText.push({ 'id': 2, 'itemName': 'February' });
    this.monthText.push({ 'id': 3, 'itemName': 'March' });
    this.monthText.push({ 'id': 4, 'itemName': 'April' });
    this.monthText.push({ 'id': 5, 'itemName': 'May' });
    this.monthText.push({ 'id': 6, 'itemName': 'June' });
    this.monthText.push({ 'id': 7, 'itemName': 'July' });
    this.monthText.push({ 'id': 8, 'itemName': 'August' });
    this.monthText.push({ 'id': 9, 'itemName': 'September' });
    this.monthText.push({ 'id': 10, 'itemName': 'October' });
    this.monthText.push({ 'id': 11, 'itemName': 'November' });
    this.monthText.push({ 'id': 12, 'itemName': 'December' });
  }

  LifelineClaimSummaryReports() {
    this.blockDocument()
    this.listOfData = [];
    this.isloading = true;
    this.nladClaimService.LifelineClaimSummaryReports(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        this.unblockDocument();
        if (data != null) {
          this.listOfData = data['listOfData'];
          if (this.listOfData != null) {
            if (this.listOfData.length > 0) {
              this.totalRecordCount = this.listOfData[0].totalRecords;
            }
            else
              this.totalRecordCount = 0;
          }
          else
            this.totalRecordCount = 0;
        }
      },
      error => {
        this.isloading = false;
        this.unblockDocument();
        console.log(error);
      }
    );
  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.LifelineClaimSummaryReports();
  }

  sort(sortBy: any) {
    this.upDowCompanyNameCSS = '';
    this.upDowYearsCSS = '';
    this.upDowMonthsCSS = '';
    this.upDowTotalCountCSS = '';
    this.upDowTotalAmountCSS = '';    
    this.upDowStateCSS = '';

    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';
    
    switch (sortBy) {  
      case 'years': this.upDowYearsCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'months': this.upDowMonthsCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'totalCount': this.upDowTotalCountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case 'totalAmount': this.upDowTotalAmountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;     
      case 'state': this.upDowStateCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.OrderBy = sortBy;
    this.LifelineClaimSummaryReports();
  }

  GetMonths() {
    this.dropdownSettingMonths = {
      singleSelection: false,
      text: 'Filter by Month',
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No Month',
      showCheckbox: true,
      enableFilterSelectAll: false
    };
    this.dropdownMonths.push({ 'id': 1, 'itemName': 'January' });
    this.dropdownMonths.push({ 'id': 2, 'itemName': 'February' });
    this.dropdownMonths.push({ 'id': 3, 'itemName': 'March' });
    this.dropdownMonths.push({ 'id': 4, 'itemName': 'April' });
    this.dropdownMonths.push({ 'id': 5, 'itemName': 'May' });
    this.dropdownMonths.push({ 'id': 6, 'itemName': 'June' });
    this.dropdownMonths.push({ 'id': 7, 'itemName': 'July' });
    this.dropdownMonths.push({ 'id': 8, 'itemName': 'August' });
    this.dropdownMonths.push({ 'id': 9, 'itemName': 'September' });
    this.dropdownMonths.push({ 'id': 10, 'itemName': 'October' });
    this.dropdownMonths.push({ 'id': 11, 'itemName': 'November' });
    this.dropdownMonths.push({ 'id': 12, 'itemName': 'December' });
  }

  removeElemetninArrayById(array, element) {
    return array.filter(e => e.id !== element);
  }

  onSelectedMonths($event) {
    
    if (this.listOfMonths.length > 0) {
      this.listOfMonths = this.listOfMonths.filter(m => m != $event.id);
    }

    this.listOfMonths.push($event.id);
    this.filterModel.pageNumber = '1'
    if (this.listOfMonths.length == 0)
      this.filterModel.months = '0';
    else
      this.filterModel.months = this.listOfMonths.join(',')
    this.LifelineClaimSummaryReports();
  }

  onDeSelectedMonths($event) {
    
    if (this.listOfMonths != null) {
      if (this.listOfMonths.length > 0) {
        this.listOfMonths = this.listOfMonths.filter(m => m != $event.id);
      }
    }
    this.filterModel.pageNumber = '1'
    if (this.listOfMonths.length == 0)
      this.filterModel.months = '0';
    else
      this.filterModel.months = this.listOfMonths.join(',')
    this.LifelineClaimSummaryReports();
  }

  onSelectAllMonths($event) {
    
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonths != null) {
        if (this.listOfMonths.length > 0) {
          this.listOfMonths = this.listOfMonths.filter(m => m != $event[iCounter].id);
          this.listOfMonths.push($event[iCounter].id);
        }
        else {
          this.listOfMonths.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.pageNumber = '1'
    if (this.listOfMonths.length == 0)
      this.filterModel.months = '0';
    else
      this.filterModel.months = this.listOfMonths.join(',')
    this.LifelineClaimSummaryReports();
  }

  onDeSelectAllMonths($event) {
    this.listOfMonths = [];
    this.filterModel.pageNumber = '1'
    this.filterModel.months = '0'
    this.LifelineClaimSummaryReports();
  }

  GetYears() {
    this.nladClaimService.GetLifelineClaimYears(this.tokenKey, this.filterModel).subscribe(
      data => {
        if (data != null) {
          let dropdownYears = data['listOfData'];
          if (dropdownYears != null) {
            if (dropdownYears.length > 0) {
              
              for (let iCounter = 0; iCounter < dropdownYears.length; iCounter++) {
                this.dropdownYears.push({ 'id': dropdownYears[iCounter], 'itemName': dropdownYears[iCounter] });
              }
            }
          }
          this.dropdownSettingYears = {
            singleSelection: false,
            text: 'Filter by Year',
            enableSearchFilter: true,
            classes: 'borderLessTextBoxGreen',
            badgeShowLimit: 1,
            noDataLabel: 'No Year',
            showCheckbox: true,
            enableFilterSelectAll: false
          };
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedYears($event) {
    if (this.listOfYears.length > 0) {
      this.listOfYears = this.listOfYears.filter(m => m != $event.id);
    }
    this.listOfYears.push($event.id);
    this.filterModel.pageNumber = '1'
    if (this.listOfYears.length > 0)
      this.filterModel.years = this.listOfYears.join(',')
    else
      this.filterModel.years = '0';
    this.LifelineClaimSummaryReports();
  }

  onDeSelectedYears($event) {
    if (this.listOfYears != null) {
      if (this.listOfYears.length > 0) {
        this.listOfYears = this.listOfYears.filter(m => m != $event.id);
      }
    }
    this.filterModel.pageNumber = '1'
    if (this.listOfYears.length > 0)
      this.filterModel.years = this.listOfYears.join(',')
    else
      this.filterModel.years = '0';
    this.LifelineClaimSummaryReports();
  }

  onSelectAllYears($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfYears != null) {
        if (this.listOfYears.length > 0) {
          this.listOfYears = this.listOfYears.filter(m => m != $event[iCounter].id);
          this.listOfYears.push($event[iCounter].id);
        }
        else {
          this.listOfYears.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.pageNumber = '1'
    if (this.listOfYears.length > 0)
      this.filterModel.years = this.listOfYears.join(',')
    else
      this.filterModel.years = '0';
    this.LifelineClaimSummaryReports();
  }

  onDeSelectAllYears($event) {
    this.listOfYears = [];
    this.filterModel.pageNumber = '1'
    this.filterModel.years = '0'
    this.LifelineClaimSummaryReports();
  }

  setMonthsText(id) {
    return this.monthText.filter(m => m.id == id)[0].itemName;
  }


  GetClaimState() {
    this.nladClaimService.GetClaimState(this.tokenKey).subscribe(
      data => {
        if (data != null) {
          this.listOfStateData = data['listOfData'];
          if (this.listOfStateData != null) {
            if (this.listOfStateData.length > 0) {
              
              for (let iCounter = 0; iCounter < this.listOfStateData.length; iCounter++) {
                this.dropdownStates.push({ 'id': this.listOfStateData[iCounter].shortName, 'itemName': this.listOfStateData[iCounter].name });
              }
            }
          }
          this.dropdownSettingStates = {
            singleSelection: false,
            text: 'Filter by State',
            enableSearchFilter: true,
            classes: 'borderLessTextBoxGreen',
            badgeShowLimit: 1,
            noDataLabel: 'No State',
            showCheckbox: true,
            enableFilterSelectAll: false
          };
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedStates($event) {
    
    if (this.listOfStates.length > 0) {
      this.listOfStates = this.listOfStates.filter(m => m != $event.id)
    }
    this.listOfStates.push($event.id);
    this.filterModel.pageNumber = '1';
    if (this.listOfStates.length > 0)
      this.filterModel.state = this.listOfStates.join(',');
    else
      this.filterModel.state = '0';
    this.LifelineClaimSummaryReports();
  }

  onDeSelectedStates($event) {
    
    if (this.listOfStates != null) {
      if (this.listOfStates.length > 0) {
        this.listOfStates = this.listOfStates.filter(m => m != $event.id)
      }
    }
    this.filterModel.pageNumber = '1';
    if (this.listOfStates.length > 0)
      this.filterModel.state = this.listOfStates.join(',');
    else
      this.filterModel.state = '0';
    this.LifelineClaimSummaryReports();
  }

  onSelectAllStates($event) {
    
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStates != null) {
        if (this.listOfStates.length > 0) {
          this.listOfStates = this.listOfStates.filter(m => m != $event[iCounter].id)
          this.listOfStates.push($event[iCounter].id);
        }
        else {
          this.listOfStates.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.pageNumber = '1';
    if (this.listOfStates.length > 0)
      this.filterModel.state = this.listOfStates.join(',');
    else
      this.filterModel.state = '0';
    this.LifelineClaimSummaryReports();
  }

  onDeSelectAllStates($event) {
    this.listOfStates = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.state = '';
    this.LifelineClaimSummaryReports();
  }


  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  redirectToDetailsPage(months: any,years: any,state: any,ProviderId: any,companyName: any) {
    state=state==null?"0":state;
    months=months==null?"0":months;
    years=years==null?"0":years;

    sessionStorage.setItem('FrontDetailReportsYear', years);
    sessionStorage.setItem('FrontDetailReportsMonths', months);
    sessionStorage.setItem('FrontDetailReportsProviderId', ProviderId);
    sessionStorage.setItem('FrontDetailReportsCompanyName', companyName);
    sessionStorage.setItem('FrontDetailReportsState', state);
    sessionStorage.setItem('FrontReportFlag', 'Summary');

    ApplicationActivityStore('View', 'Detail Summary-Lifeline',this.appActivity,0, ActionActivity['Inroll Lifeline'],'/View/Lifeline-summary-details');
    
    this.router.navigate(['/View/Lifeline-summary-details'])
  }


  redirectToListPage() {
    this.router.navigate(['/Claim/LifelineReports/List']);
  }

}
