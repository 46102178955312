import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-briefing-subtype-add-update',
  templateUrl: './briefing-subtype-add-update.component.html',
  styleUrls: ['./briefing-subtype-add-update.component.css']
})
export class BriefingSubtypeAddUpdateComponent implements OnInit {

  userID: any;
  BriefSubtypeMasterForm: FormGroup;
  SelectedTool: string;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit:boolean = false;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;

  constructor(private i360service: I360Service,private _fb: FormBuilder, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
   }

   tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() { 
    this.isSubmit = false;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.BriefSubtypeMasterForm = this._fb.group({
      BulletinSubTypeId: 0,
      BulletinSubType: ['', Validators.required],
      shortName: ['', Validators.required],
      sortOrder: ['',Validators.required],
      isActive:[false],
      isArchive:[false]
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetBriefSubTypeById(params['id']);
        }
      }
    });
  }

  GetBriefSubTypeById(BulletinSubTypeId: number) {
    this.i360service.GetBriefSubTypeById(this.tokenKey, parseInt(BulletinSubTypeId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.BriefSubtypeMasterForm.patchValue({
            BulletinSubTypeId: result['briefing'].bulletinSubTypeId,
            BulletinSubType: result['briefing'].bulletinSubType,
            shortName:result['briefing'].shortName,
            isActive : result['briefing'].isActive,
            sortOrder: result['briefing'].sortOrder,
            isArchive : result['briefing'].isArchive            
          });
        }
      });
  }

  save() {
    this.isSubmit = true;
    let data = this.BriefSubtypeMasterForm.value;
    if (!this.BriefSubtypeMasterForm.valid||!data.BulletinSubType.trim() || !data.shortName.trim())
      return
    let value={
      BulletinSubTypeId:data.BulletinSubTypeId,
      BulletinSubType:data.BulletinSubType.trim(),
      shortName:data.shortName.trim(),
      sortOrder:data.sortOrder,
      isActive: data.isActive,
      isArchive:data.isArchive,
      createdBy:this.userID,
    }
    this.i360service.BriefingSubTypeAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if(result['message'] !=""){
            this.showMessage(result['message'], 'Warning', 'warning')
          }else{

            if (data.BulletinSubTypeId > 0)
              this.showMessage('Briefing Sub Type updated successfully', 'Success', 'success');
            else
              this.showMessage('Briefing Sub Type saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/'+this.SelectedTool+'/briefingSubType']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  cancelBriefSubType() {
    let navigation = '/admin/' + this.SelectedTool + '/briefingSubType';
    this.router.navigate([navigation]);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
}
