import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OngoingComplianceModel } from 'src/Models/OngoingComplianceModel';
import { HtmlToPdf } from 'src/Services/htmlToPdf';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { Globle } from 'src/app/Shared/global';
import * as FileSaver from 'file-saver';
import { AppConfig } from 'src/Services/app.config';
import { ClientInformationModel } from 'src/Models/ClientInformationModel';
import { FilterModel } from 'src/Models/filterModel';
import { EmailLoginService } from 'src/Services/emaillogin.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityService } from 'src/Services/application-activity.service';

@Component({
  selector: 'app-quotes-and-proposal',
  templateUrl: './quotes-and-proposal.component.html',
  styleUrls: ['./quotes-and-proposal.component.css']
})
export class QuotesAndProposalComponent implements OnInit {

  isSubmit: boolean = false;
  userID: any;
  ClientInformationId: number;
  tokenKey: any;

  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  stateCode: any;
  isRecord1: boolean = false;
  quoteName: string;
  listofCompliance: OngoingComplianceModel[];
  complianceTotal: number = 0;
  baseUrl: string;
  appModel: any;
  SelectedTool: string;

  isQuoteApproved: number = 0;
  dropdownListQuoteStatus = [];
  selectedItemsQuoteStatus = [];
  dropdownSettingsQuoteStatus = {};
  listOfQuoteStatusIds = [];
  quoteStatusId: number = 0;
  QuotePreparedBy: string;


  listofPricingQuotes: ClientInformationModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  CompanyName: string;

  upDownCompanyNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDownQuoteNameCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowCreatedByCSS: string = '';
  upDowUpdateByCSS: string = '';
  upDownQuoteSentDateCSS: string = '';
  upDownQuoteStatusCSS: string = '';
  upDownMSAStatusCSS: string = '';
  upDownQuoteprebyCSS: string = ''

  isAscending: boolean = true;
  isRecord: boolean = false;
  isloading: boolean = false;
  totalRecordCount: number = 0;
  OrderByName: string = '';
  OrderBy: string;
  IsClient: boolean = false;
  isInteserraUser: boolean = false;
  companyId: number;
  rejectReason: string;
  priceQuoteId: number;
  TitleMsg: string;
  quaryValues: string = '';
  encyKeyfilterModel = new FilterModel();
  filterModel = new FilterModel();
  priceQuoteId1: number = 0;
  emailUserId: any = 0;
  loginUser: any = 0;
  emailPriceQuoteId: number = 0;
  isSelected: boolean = false;

  constructor(private appActivity: ApplicationActivityService,private router: Router, private PricingQuotesMaster: PricingQuotesService, private checkAuth: CheckAuth
    , private titleService: Title, private activedRoute: ActivatedRoute,private emailLoginService: EmailLoginService, private toastr: ToastrService, private htmlToPdf: HtmlToPdf,
    @Inject('BASE_URL') baseUrl: string, private globle: Globle) {
    this.userID = sessionStorage.getItem('UserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Quotes and Onboarding');

    this.baseUrl = baseUrl;

    this.PageNumber = 1;
    this.PageSize = 10;
    this.OrderBy = 'CreatedDate desc';

    this.dropdownSettingsQuoteStatus = {
      singleSelection: true,
      text: "Please Select Quote Status",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Quote Status available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.quaryValues = params['id'];
          this.encyKeyfilterModel.emailKey = params['id'];
          this.IsValidPriceQuoteEmailKey();
        }
      }
    });
    this.isSubmit = false;
    this.BindDropdown();
    this.getCompanyIdByUserId();
    // this.GetClientInformationIdByUserId(this.userID);
    this.emailPriceQuoteId = +sessionStorage.getItem('emailPriceQuoteId');
    if (this.emailPriceQuoteId != 0) {
      document.getElementById("btndisplayPriceQuotePopUp").click();
    }

  }

  IsValidPriceQuoteEmailKey() {
    this.emailLoginService.isValidPriceQuoteEmailKey(this.encyKeyfilterModel).subscribe(
      data => {
        this.priceQuoteId1 = data.priceQuoteId;
        this.emailUserId = data.userId;
        this.filterModel.userID = this.emailUserId;

        let IsAdmin = false;
        if (localStorage.getItem('clientTypeId') != null) {
          IsAdmin = localStorage.getItem('clientTypeId') == '2' ? true : false;
        }
        if (this.emailUserId.toString() !== this.loginUser.toString() && !IsAdmin)
          this.router.navigate(['/Access/Denied/BriefingEmailServices']);
        else
          this.getCompanyIdByUserId();
      },
      error => {
        console.log(error);
      }
    );
  }

  BindDropdown() {
    this.dropdownListQuoteStatus = [];
    this.dropdownListQuoteStatus.push({ "id": 1, "itemName": "Pending" })
    this.dropdownListQuoteStatus.push({ "id": 2, "itemName": "Approved" })
    this.dropdownListQuoteStatus.push({ "id": 3, "itemName": "Rejected" })
  }

  // GetClientInformationIdByUserId(UserId: number) {
  //   this.blockDocument()
  //   this.PricingQuotesMaster.GetClientInformationIdByUserId(this.tokenKey, parseInt(this.userID.toString())).subscribe(
  //     result => {
  //       if (result['status'] == 'Success') {
  //         this.ClientInformationId = result['clientInformationId'];
  //         this.unblockDocument();
  //         if (this.ClientInformationId != 0) {
  //           this.GetComplianceByClientInformationId(this.ClientInformationId);
  //           this.GetClientInformationById(this.ClientInformationId);
  //         }
  //       }
  //     });
  // }

  // GetComplianceByClientInformationId() {
  //   this.blockDocument();
  //   this.PricingQuotesMaster.GetProposelByClientInformationId(this.tokenKey, this.ClientInformationId).subscribe(
  //     data => {
  //       if (data['status'] = 'Success') {
  //         // this.globle.cApp.statusModel.isProposalDetailsCompleted = true;
  //         this.unblockDocument();
  //         this.listofCompliance = data['compliance'];
  //         if (this.listofCompliance != null) {
  //           if (this.listofCompliance.length == 0) {
  //             this.isRecord1 = true;
  //           }
  //           else {
  //             if(this.listofCompliance[0].quoteName!=null && this.listofCompliance[0].quoteName!="")
  //             {
  //             this.quoteName=" - "+this.listofCompliance[0].quoteName;
  //             }
  //             else{
  //               this.quoteName="";
  //             }
  //             for (let iCounter = 0; iCounter < this.listofCompliance.length; iCounter++) {
  //               this.complianceTotal = this.complianceTotal + this.listofCompliance[iCounter].totalMonthlyCost;
  //             }
  //           }
  //         }
  //         else {
  //           this.isRecord1 = false;
  //         }
  //       }
  //       else {
  //         this.unblockDocument();
  //         this.isRecord1 = false;
  //       }
  //     }, error => { this.stateCode = error }
  //   );
  // }

  downloadQuoteAndProposal(id) {
    if (id != undefined && id > 0) {
      window.open('/#/View/download-quotes-and-proposal/' + id, "_blank");
    }
  }

  GetComplianceByClientInfoId(clientInformationId) {
    
    this.blockDocument();
    this.PricingQuotesMaster.GetProposelByClientInformationId(this.tokenKey, clientInformationId).subscribe(
      data => {
        if (data['status'] = 'Success') {
          // this.globle.cApp.statusModel.isProposalDetailsCompleted = true;
          this.unblockDocument();

          this.isRecord1 = true;
          this.listofCompliance = [];
          this.quoteName = "";
          this.complianceTotal = 0;

          this.listofCompliance = data['compliance'];
          if (this.listofCompliance != null) {
            if (this.listofCompliance.length == 0) {
              this.isRecord1 = true;
            }
            else {
              if (this.listofCompliance[0].quoteName != null && this.listofCompliance[0].quoteName != "") {
                this.quoteName = " - " + this.listofCompliance[0].quoteName;
              }
              else {
                this.quoteName = "";
              }
              for (let iCounter = 0; iCounter < this.listofCompliance.length; iCounter++) {
                this.complianceTotal = this.complianceTotal + this.listofCompliance[iCounter].totalMonthlyCost;
              }

              this.blockDocument();

              let mainURL = this.baseUrl;
              let url = '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> '
              url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
              url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
              url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
              url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
              url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
              url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
              url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
              url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
              url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
              url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';

              var html = url + '</br><b>EXHIBIT A' + this.quoteName + '</b></br></br>' +
                document.getElementById('divExibitA').innerHTML;
              // + '</br><b>EXHIBIT A-1 PHASE I - MARKET ENTRY FCC/CA ONLY</b></br>' +
              // document.getElementById('divExibitB').innerHTML + '</br><b>EXHIBIT A-2 PHASE I - MARKET ENTRY FCC/NATIONWIDE</b></br>' +
              // document.getElementById('divExibitC').innerHTML;
              this.htmlToPdf.GetPriceQuoteProposalPDF(this.tokenKey, html, '0').
                subscribe(data => {
                  if (data['status'] == 'Success') {
                    this.downloadFile('Final Proposal.pdf', data['url'], data['fileName']);
                    this.unblockDocument();
                  }
                },
                  error => {
                    this.unblockDocument();
                  }
                );
            }
          }
          else {
            this.isRecord1 = false;
          }
        }
        else {
          this.unblockDocument();
          this.isRecord1 = false;
        }
      }, error => { this.stateCode = error }
    );
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  createMyPdf() {
    this.blockDocument();

    let mainURL = this.baseUrl;
    let url = '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> '
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';

    var html = url + '</br><b>EXHIBIT A' + this.quoteName + '</b></br></br>' +
      document.getElementById('divExibitA').innerHTML;
    // + '</br><b>EXHIBIT A-1 PHASE I - MARKET ENTRY FCC/CA ONLY</b></br>' +
    // document.getElementById('divExibitB').innerHTML + '</br><b>EXHIBIT A-2 PHASE I - MARKET ENTRY FCC/NATIONWIDE</b></br>' +
    // document.getElementById('divExibitC').innerHTML;
    this.htmlToPdf.GetPriceQuoteProposalPDF(this.tokenKey, html, '0').
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.downloadFile('Final Proposal.pdf', data['url'], data['fileName']);
          this.unblockDocument();
        }
      },
        error => {
          this.unblockDocument();
        }
      );
  }

  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        // ApplicationActivityStore('Download', 'TRFA File download ' + filename, this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/contentView');
        sessionStorage.setItem('fileloading', 'false');
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  onCheckboxChange1(isQuoteApproved, clientInformationId) {
    this.isSubmit = false;
    this.ClientInformationId = clientInformationId;
    var PriceQuoteData1 = this.dropdownListQuoteStatus.filter(x => x.id == isQuoteApproved)[0];
    if (PriceQuoteData1 != null) {
      this.selectedItemsQuoteStatus = [];
      this.quoteStatusId = PriceQuoteData1.id;
      this.selectedItemsQuoteStatus.push({ "id": PriceQuoteData1.id, "itemName": PriceQuoteData1.itemName })
    }
    document.getElementById("btnopenApprovedpopup").click();
    return;
  }

  onStatusChange(priceQuoteId, isQuoteApproved) {
    this.isSubmit = false;
    this.quoteStatusId = isQuoteApproved;
    this.rejectReason = "";
    if (isQuoteApproved == 2) {
      this.TitleMsg = "Approve"
    }
    else {
      this.TitleMsg = "Reject";
    }
    this.priceQuoteId = priceQuoteId;
    // var PriceQuoteData1 = this.dropdownListQuoteStatus.filter(x => x.id == isQuoteApproved)[0];
    // if (PriceQuoteData1 != null) {
    //   this.selectedItemsQuoteStatus = [];
    //   this.quoteStatusId = PriceQuoteData1.id;
    //   this.selectedItemsQuoteStatus.push({ "id": PriceQuoteData1.id, "itemName": PriceQuoteData1.itemName })
    // }
    document.getElementById("btnopenApprovedpopup").click();
    return;
  }

  onStatusReject(priceQuoteId, isQuoteApproved) {
    this.isSubmit = false;
    this.quoteStatusId = isQuoteApproved;
    this.priceQuoteId = priceQuoteId;
    var PriceQuoteData1 = this.dropdownListQuoteStatus.filter(x => x.id == isQuoteApproved)[0];
    if (PriceQuoteData1 != null) {
      this.selectedItemsQuoteStatus = [];
      this.quoteStatusId = PriceQuoteData1.id;
      this.selectedItemsQuoteStatus.push({ "id": PriceQuoteData1.id, "itemName": PriceQuoteData1.itemName })
    }
    document.getElementById("btnopenRejectpopup").click();
    return;
  }

  onSelectQuoteStatus($event) {
    this.quoteStatusId = $event.id;
    if ($event.id == 3) {

    }
  }

  onDeSelectQuoteStatus($event) {
    this.quoteStatusId = 0;
  }

  onDeSelectAllQuoteStatus($event) {
    this.quoteStatusId = 0;
  }

  priceQuoteApproved() {
    this.isSubmit = true;

   
    this.isQuoteApproved = this.quoteStatusId;
    if (this.quoteStatusId == 3) {
      if (this.rejectReason == "" || this.rejectReason == null) {
        return;
      }
    }

    let quoteName="";
    let prlst = this.listofPricingQuotes.filter(x => x.priceQuoteId == this.priceQuoteId);
    if (prlst.length>0) {
      quoteName = prlst[0].quoteName;
    }

    ApplicationActivityStore('Click Link', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], quoteName +" - "+this.TitleMsg);
    
    document.getElementById("closebutton").click();

    this.blockDocument();
    this.PricingQuotesMaster.PriceQuoteApproved(this.tokenKey, this.priceQuoteId, this.quoteStatusId, this.rejectReason).subscribe(
      data => {
        this.unblockDocument();
        if (data['status'] == 'Success') {
          this.isSubmit = false;
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else {
            // if (this.Sent == 'Sent')
            this.showMessage("Quote Status update successfully", 'Success', 'success')
            this.getPriceQuoteListByUserId();
            // else
            //   this.showMessage("Marked as Denied this Quote successfully", 'Success', 'success')
          }
        }

      },
      error => { this.stateCode = error }
    );
  }

  priceQuoteApprovedNo() {
    // var PriceQuoteData1 = this.listofPricingQuotes.filter(x => x.clientInformationId == this.clientInformationId)[0];
    // if (PriceQuoteData1 != null) {
    //   PriceQuoteData1.isQuoteApproved = 1;
    // }
  }

  priceQuoteReject() {
    this.isSubmit = true;
    this.quoteStatusId = 3;

    if (this.rejectReason == "" || this.rejectReason == null) {
      return;
    }

    this.isQuoteApproved = this.quoteStatusId;

    this.quoteStatusId = 2;
    document.getElementById("closebutton").click();

    this.blockDocument();
    this.PricingQuotesMaster.PriceQuoteApproved(this.tokenKey, this.priceQuoteId, this.quoteStatusId, "").subscribe(
      data => {
        this.unblockDocument();
        if (data['status'] == 'Success') {
          this.isSubmit = false;
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else {
            // if (this.Sent == 'Sent')
            this.showMessage("Quote Status update successfully", 'Success', 'success')
            this.getPriceQuoteListByUserId();
            // else
            //   this.showMessage("Marked as Denied this Quote successfully", 'Success', 'success')
          }
        }

      },
      error => { this.stateCode = error }
    );
  }


  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  GetClientInformationById(clientInformationId: number) {
    this.PricingQuotesMaster.GetClientInformationById(this.tokenKey, parseInt(clientInformationId.toString()), 0, false).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.isQuoteApproved = result['clientInformation'].isQuoteApproved,
            this.isQuoteApproved = result['clientInformation'].isQuoteApproved;
        }
      });
  }

  getCompanyIdByUserId() {
    this.PricingQuotesMaster.getCompanyIdByUserId(this.tokenKey, parseInt(this.userID.toString())).subscribe(
      result => {
        this.isloading = false;
        this.companyId = result['companyId'];
        if (this.companyId == 19) {
          this.isInteserraUser = true;
        }
        else {
          this.isInteserraUser = false;
        }
        this.getPriceQuoteListByUserId();
      }
    );
  }

  getPriceQuoteListByUserId() {
    ApplicationActivityStore('View', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], '/View/quotes-and-proposal');
    this.blockDocument();
    this.PricingQuotesMaster.getAllQuoteByUserId(this.tokenKey, parseInt(this.userID.toString()), this.PageNumber, this.PageSize, this.OrderBy).subscribe(
      result => {
        this.isloading = false;
        this.listofPricingQuotes = result['pricingQuotes'];
        this.unblockDocument();
        if (this.listofPricingQuotes != null) {

          // if (this.companyId == 19) {
          //   this.isInteserraUser = true;
          // }
          // else {
          //   this.isInteserraUser = false;
          // }

          if (this.listofPricingQuotes.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofPricingQuotes[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDownCompanyNameCSS = '';
      this.upDownQuoteNameCSS = '';
      this.upDowCreatedDateCSS = '';
      this.upDowCreatedByCSS = '';
      this.upDowUpdateDateCSS = '';
      this.upDowUpdateByCSS = '';
      this.upDownMSAStatusCSS = '';

      if (sortBy === "CompanyName") {
        this.upDownCompanyNameCSS = 'active-up';
      }
      else if (sortBy === "QuoteName") {
        this.upDownQuoteNameCSS = 'active-up';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowCreatedDateCSS = 'active-up';
      }
      else if (sortBy === "CreatedByName") {
        this.upDowCreatedByCSS = 'active-up';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowUpdateDateCSS = 'active-up';
      }
      else if (sortBy === "UpdatedByName") {
        this.upDowUpdateByCSS = 'active-up';
      }
      else if (sortBy === "SentDate") {
        this.upDownQuoteSentDateCSS = 'active-up';
      }
      else if (sortBy === "IsQuoteApproved") {
        this.upDownQuoteStatusCSS = 'active-up';
      }
      else if (sortBy == "QuotePreparedBy") {
        this.upDownQuoteprebyCSS = 'active-up';
      }
      else if (sortBy === "msaStatus") {
        this.upDownMSAStatusCSS = 'active-up';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;
      this.upDownCompanyNameCSS = '';
      this.upDownQuoteNameCSS = '';
      this.upDowCreatedDateCSS = '';
      this.upDowCreatedByCSS = '';
      this.upDowUpdateDateCSS = '';
      this.upDowUpdateByCSS = '';
      this.upDownMSAStatusCSS = '';

      if (sortBy === "CompanyName") {
        this.upDownCompanyNameCSS = 'active';
      }
      else if (sortBy === "QuoteName") {
        this.upDownQuoteNameCSS = 'active';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowCreatedDateCSS = 'active';
      }
      else if (sortBy === "CreatedByName") {
        this.upDowCreatedByCSS = 'active';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowUpdateDateCSS = 'active';
      }
      else if (sortBy === "UpdatedByName") {
        this.upDowUpdateByCSS = 'active';
      }
      else if (sortBy === "SentDate") {
        this.upDownQuoteSentDateCSS = 'active';
      }
      else if (sortBy === "IsQuoteApproved") {
        this.upDownQuoteStatusCSS = 'active';
      }
      else if (sortBy === "QuotePreparedBy") {
        this.upDownQuoteprebyCSS = 'active';
      }
      else if (sortBy === "msaStatus") {
        this.upDownMSAStatusCSS = 'active';
      }
    }
    this.OrderByName = sortBy;
    this.getPriceQuoteListByUserId();
  }

  getPageLength() {
    return Math.min((this.PageNumber * this.PageSize), this.totalRecordCount);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.getPriceQuoteListByUserId();
  }

  OnboardingDataCollection(ClientInformationId: number,companyName: string,priceQuoteId : number) {
    ApplicationActivityStore('Edit', 'Price Quote', this.appActivity, ToolEnum.PricingQuote, ActionActivity['PricingQuote'], '/View/add-company-info/' + ClientInformationId+'/'+priceQuoteId);

    sessionStorage.setItem('ClientCompanyName',sessionStorage.getItem('CompanyName'));
    let navigation = '/View/add-company-info/' + ClientInformationId+'/'+priceQuoteId;
    sessionStorage.setItem('isVendor', 'false');
    this.router.navigate([navigation]);
  }


  downloadOnboardingdetails(clientInformationId, priceQuoteId) {
    this.blockDocument();

    this.PricingQuotesMaster.GenerateOnboardingDetails(clientInformationId, priceQuoteId, this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.downloadFile('OnboardingDetails.pdf', data['url'], data['fileName']);
          this.unblockDocument();
        }
        else if (data['status'] == 'datanotavailable') {
          this.showMessage(data['message'], 'Data not available. Please fill in the data first.', 'warning')
        }
      },
      error => {
        this.unblockDocument();
      }
    );
  }


  // priceQuoteSeen() {
  //   this.blockDocument();
  //   this.PricingQuotesMaster.UpdateQuoteMarkAsSeen(this.tokenKey, this.emailPriceQuoteId).subscribe(
  //     data => {
  //       this.unblockDocument();
  //       if (data['status'] == 'Success') {
  //         document.getElementById("closePopUPbutton").click();
  //       }

  //     },
  //     error => { }
  //   );

  //   this.emailPriceQuoteId = 0;
  //   sessionStorage.setItem('emailPriceQuoteId', '');
  // }


  // priceQuoteCancel() {
  //   this.emailPriceQuoteId = 0;
  //   sessionStorage.setItem('emailPriceQuoteId', '');
  //   this.getPriceQuoteListByUserId();
  // }
}
