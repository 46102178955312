import { ApplicationActivityModel } from "src/Models/applicationActivityModel";

export function ApplicationActivityStore(actionName, pageName, appActivity, dataId = 0, dataType = 0, actionUrl = '',stateName='') {
  const modal = new ApplicationActivityModel();
  modal.actionName = actionName;
  modal.pageName = pageName
  modal.userId = +sessionStorage.getItem('UserId') || 0;
  modal.dataId = dataId;
  modal.pageUrl = document.location.href;
  modal.ipAddress = sessionStorage.getItem('ipAddress');
  modal.dataType = dataType;
  modal.actionUrl = actionUrl;
  modal.stateName = stateName;
  appActivity.ApplicationActivityAdd(sessionStorage.getItem('tokenValue'), modal).subscribe(res => {
  })
}

const StringIsNumber = value => isNaN(Number(value)) === false;
export function EnumToArray(enumme) {
  const returnArray = [];
  let index = 1;
  Object.keys(enumme).forEach((element) => {
    if (!StringIsNumber(element)) {
      returnArray.push({ id: index, itemName: element })
      index++;
    }

  });
  console.log(returnArray);

  return returnArray;
}

export const IpAddressAllow = [
  '120.72.91.106',
  '120.72.91.107',
  '120.72.91.108',
  '120.72.91.109',
  '120.72.91.110',
  '120.72.95.90',
  '120.72.95.91',
  '120.72.95.92',
  '120.72.95.93',
  '120.72.95.94',
  '122.170.105.210',
  '122.170.105.215',
  '103.94.192.143',
  '74.203.128.70'
]

export function CheckIpAddresAllow(ipAddress: string) {
  return IpAddressAllow.findIndex(x => x == ipAddress) > -1;
}