
export class OngoingComplianceModel {
    serviceName: string;
    serviceId: number;
    notes: string;
    subTitle: string;
    numberOfstate: number;
    costPerState: number;
    totalMonthlyCost: number;
    complianceId: number;
    clientInformationId: number;
    createdBy: number;
    createdDate: Date | string;
    updatedBy: number;
    updatedDate: Date | string;
    quoteName:string;
    priceQuoteId:number;
    stateShortName:string;
}

export class MiscellaneousFeeModel {
    miscComplianeId:number;
    miscellaneousId: any;
    miscellaneousTitle: string;
    miscellaneousNote: string;
    miscellaneousAmount: number;
    clientInformationId: number;
    createdBy: number;
    createdDate: Date | string;
    updatedBy: number;
    updatedDate: Date | string;
    priceQuoteId:number;
    isSelected:boolean;
}

export class AdditionalServicesFeeModel {
    additionalServicesComplianeId:number;
    additionalServicesTitle: string;
    additionalServicesNote: string;
    additionalServicesAmount: number;
    clientInformationId: number;
    createdBy: number;
    createdDate: Date | string;
    updatedBy: number;
    updatedDate: Date | string;
    priceQuoteId:number;
    isSelected:boolean;
}

export class AddUpdateOngoingComplianceModel{
    OngoingCompliance:OngoingComplianceModel[];
    MiscellaneousFee:MiscellaneousFeeModel[];
    AdditionalServicesFee:AdditionalServicesFeeModel[];
    priceQuoteId:number;
}

export class MiscellaneousFeesComplianceModel {
    miscComplianeId:number;
    miscellaneousId: any;
    miscellaneousTitle: string;
    miscellaneousNote: string;
    miscellaneousAmount: any;
    clientInformationId: number;
    createdBy: any;
    createdDate: Date | string;
    updatedBy: any;
    updatedDate: Date | string;
    priceQuoteId:number;
    isActive :any;
    totalRecordCount:number;
}