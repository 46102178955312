import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TabHeadingDirective } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/Services/app.config';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { PricingQuotingBaserateService } from 'src/Services/pricing-quoting-baserate.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-add-update-base-rate',
  templateUrl: './add-update-base-rate.component.html',
  styleUrls: ['./add-update-base-rate.component.css']
})
export class AddUpdateBaseRateComponent implements OnInit {


  baseRateMasterForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/

  listOfBaseRateTypeIDs = [];
  BaseRateTypeList: any[] = [];
  selectedItemsBaseRateType = [];
  dropdownBaseRateTypeFilter = [];
  dropdownSettingsBaseRateTypeFilter = {};

  listOfServicesIDs = [];
  ServicesList: any[] = [];
  selectedItemsServices = [];
  dropdownServicesFilter = [];
  dropdownSettingsServicesFilter = {};

  IsAddOn: boolean = false;
  isMonthlyEnableDisable: boolean = true;
  isAnnualEnableDisable: boolean = true;
  MonthlyLabel: string = 'Monthly Rate';
  AnnualLabel: string = 'Annual Rate';
  isSelected: boolean = false;
  isSelectedForState: boolean = false;
  isAdditionalServices: boolean = false;
  isAdditionalServicesSelected: boolean = false;
  isActiveServices: boolean = false;
  isActiveServicesSelected: boolean = false;
  isControlEnable: boolean = false;
 
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute, private PricingQuotesMaster: PricingQuotesService,
    private router: Router, private baseRateMaster: PricingQuotingBaserateService, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  ngOnInit() {
    this.isSubmit = false;
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.BaseRateTypeAll();

    this.baseRateMasterForm = this._fb.group({
      baseRateId: 0,
      baseRate: [''],
      rateType: ['', Validators.required],
      monthlyRate: ['', Validators.required],
      annualRate: ['', Validators.required],
      serviceId: [''],
      isSelected: false,
      sortOrder:['', Validators.required]
    })

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          if(this.isEdit){
            this.isControlEnable=true;
            this.baseRateMasterForm.get('baseRate').disable()
          }
          else{
            this.isControlEnable=false;
          }
          this.GetBaseRateById(params['id']);
        }
       
      }
    });
    this.SetDropDownFilterSetting();
    this.SetDropDownSeviceSetting();
  }

  SetDropDownFilterSetting(){
    this.dropdownSettingsBaseRateTypeFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No BaseRateType(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      disabled: this.isControlEnable,
      selectionLimit:1
    };
  }

  SetDropDownSeviceSetting(){
    this.dropdownSettingsServicesFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Service(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false,
      disabled: this.isControlEnable,
    };
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  get disabled() {
    return this.baseRateMasterForm.get('rateType').disable
  }

  onDeSelectBaseRateTypeAll($event) {
    this.selectedItemsBaseRateType = [];
    this.IsAddOn = false;
    this.isMonthlyEnableDisable = true;
    this.isAnnualEnableDisable = true;
    this.MonthlyLabel = 'Monthly Rate';
    this.AnnualLabel = 'Annual Rate';
    this.isAdditionalServices = false;
    this.isActiveServices = false;
    this.isSelectedForState = false;
  }

  onSelectBaseRateType(event) {
    this.isMonthlyEnableDisable = true;
    this.isAnnualEnableDisable = true;
    this.MonthlyLabel = 'Monthly Rate';
    this.AnnualLabel = 'Annual Rate';

    if (event.id == 1)
    {
      this.isAdditionalServices = true;
      this.isActiveServices = true;
    }
    else
    {
      this.isAdditionalServices = false;
      this.isActiveServices = false;
    }

    if (event.id == 2 || event.id == 3) {
      this.GetPriceQuoteServices(event.id, 0);
      this.IsAddOn = true;
      this.MonthlyLabel = 'One Time';
      this.AnnualLabel = 'Annual';
    }
    else this.IsAddOn = false;

    if (event.id == 6) {
      this.isSelectedForState = true;
    }
    else
      this.isSelectedForState = false;

    this.baseRateMasterForm.patchValue({
      rateType: event.id
    });
  }

  onDeSelectBaseRateType(event) {
    this.isMonthlyEnableDisable = true;
    this.isAnnualEnableDisable = true;
    this.MonthlyLabel = 'Monthly Rate';
    this.AnnualLabel = 'Annual Rate';
    this.IsAddOn = false;
    this.isAdditionalServices = false;
    this.isActiveServices = false;
    this.isSelectedForState = false;
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  cancelBaseRate() {
    let navigation = '/admin/' + this.SelectedTool + '/baserate';
    this.router.navigate([navigation]);
  }

  GetBaseRateById(baseRateId: number) {
    this.baseRateMaster.GetBaseRateById(this.tokenKey, parseInt(baseRateId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.baseRateMasterForm.patchValue({
            baseRateId: result['baserate'].baseRateId,
            baseRate: result['baserate'].baseRate,
            rateType: result['baserate'].rateType,
            monthlyRate: result['baserate'].monthlyRate,
            annualRate: result['baserate'].annualRate,
            serviceId: result['baserate'].serviceId,
            sortOrder:result['baserate'].sortOrder
          });

          this.isSelected = result['baserate'].isSelectedForState;
          this.isAdditionalServicesSelected = result['baserate'].isAdditionalServicesSelected;
          this.isActiveServicesSelected = result['baserate'].isOnlyActiveService;
          this.selectedItemsBaseRateType = [];

          if (result['baserate'].rateType == 1)
          {
            this.isAdditionalServices = true;
            this.isActiveServices = true;
          }
          else
          {
            this.isAdditionalServices = false;
            this.isActiveServices = true;
          }

          if (result['baserate'].rateType == 2 || result['baserate'].rateType == 3) {
            this.IsAddOn = true;
            this.GetPriceQuoteServices(result['baserate'].rateType, result['baserate'].serviceId);
          }

          if (result['baserate'].rateType == 6) {
            this.isSelectedForState = true;
          }

          if (this.BaseRateTypeList.length > 0) {
            let data = this.BaseRateTypeList.filter(x => x.baseRateTypeId == result['baserate'].rateType)[0];
            this.selectedItemsBaseRateType.push({ "id": data.baseRateTypeId, "itemName": data.baseRateTypeName })
          }
          else
            setTimeout(() => {
              let data = this.BaseRateTypeList.filter(x => x.baseRateTypeId == result['baserate'].rateType)[0];
              this.selectedItemsBaseRateType.push({ "id": data.baseRateTypeId, "itemName": data.baseRateTypeName })
            }, 2000);
        }
      });
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  SelectedProductCheck(isSelected) {
  }

  BaseRateTypeAll() {
    this.baseRateMaster.BaseRateTypeAll(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.BaseRateTypeList = result['baseRateType'];
          this.dropdownBaseRateTypeFilter = [];
          this.BaseRateTypeList.forEach(x => {
            this.dropdownBaseRateTypeFilter.push({ "id": x.baseRateTypeId, "itemName": x.baseRateTypeName })
          });
        }
      },
      error => { }
    );
  }

  save() {
    this.isSubmit = true;
    let data = this.baseRateMasterForm.value;

    if (data.monthlyRate != 0) {
      if (data.monthlyRate == null || data.monthlyRate == "")
        return;
    }

    if (data.annualRate != 0) {
      if (data.annualRate == null || data.annualRate == "")
        return;
    }

    if (data.sortOrder == '' || data.sortOrder==null) {
        return;
    }

    if (data.annualRate == '')
      data.annualRate = 0;

    if (data.monthlyRate == '')
      data.monthlyRate = 0;

    if (this.selectedItemsBaseRateType.length == 0)
      return

    if (data.rateType == 2 || data.rateType == 3) {
      if (this.selectedItemsServices.length == 0)
        return
      else
        data.baseRate = this.selectedItemsServices[0].itemName;
    }
    else {
      if (data.baseRate != null) {
        if (!data.baseRate.trim())
          return;
        else
          data.baseRate = data.baseRate.trim();
      }
      data.serviceId = 0;
    }

    let value = {
      baseRateId: data.baseRateId,
      baseRate: data.baseRate,
      rateType: data.rateType,
      monthlyRate: data.monthlyRate,
      annualRate: data.annualRate,
      createdBy: this.userID,
      serviceId: data.serviceId,
      isSelectedForState: this.isSelected,
      isAdditionalServicesSelected: this.isAdditionalServicesSelected,
      isOnlyActiveService : this.isActiveServicesSelected,
      sortOrder:data.sortOrder
    }

    this.baseRateMaster.BaseRateAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {

            if (data.baseRateId > 0)
              this.showMessage('Base rate updated successfully', 'Success', 'success');
            else
              this.showMessage('Base rate saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/baserate']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }


  GetPriceQuoteServices(baseRateTypeId: number, serviceId: number) {
    this.PricingQuotesMaster.GetPriceQuoteServices(this.tokenKey, parseInt(baseRateTypeId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.selectedItemsServices = [];
          this.ServicesList = result['services'];
          this.dropdownServicesFilter = [];
          this.ServicesList.forEach(x => {
            this.dropdownServicesFilter.push({ "id": x.serviceId, "itemName": x.serviceName })
          });

          if (serviceId != 0) {
            let data = this.ServicesList.filter(x => x.serviceId == serviceId)[0];
            this.selectedItemsServices.push({ "id": data.serviceId, "itemName": data.serviceName })
            if (data != null) {
              this.MonthlyLabel = 'One Time';
              this.AnnualLabel = 'Annual';
              this.isMonthlyEnableDisable = data.onetime;
              this.isAnnualEnableDisable = data.annual;
            }
          }
        }
      },
      error => { });
  }

  onDeSelectServicesAll($event) {
    this.ServicesList = [];
    this.dropdownServicesFilter = [];
    this.selectedItemsServices = [];
  }

  onChangeServices(event) {
    let data = this.ServicesList.filter(x => x.serviceId == event.id)[0];
    if (data != null) {
      this.isMonthlyEnableDisable = data.onetime;
      this.isAnnualEnableDisable = data.annual;
    }
    this.baseRateMasterForm.patchValue({
      serviceId: event.id
    });
  }
}
