import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CRRAUpdateGetModel } from 'src/Models/CRRAUpdateModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CrraUpdatesMasterService } from 'src/Services/crra-updates-master.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-crra-updates-add-update',
  templateUrl: './crra-updates-add-update.component.html',
  styleUrls: ['./crra-updates-add-update.component.css']
})
export class CrraUpdatesAddUpdateComponent implements OnInit {

  updateMasterForm: FormGroup;
  userId: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  stateList: StateMasterModel[] = [];
  selectedState = [];
  isEdit: boolean = false;
  getStateId: string = '';

  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};

  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  module = "CRRA";
  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private stateService: StateMasterService,
    private datePipe: DatePipe,
    private toastr: ToastrService, private crraupdateService: CrraUpdatesMasterService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
        }
      }
    });

    this.getState();
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.getStateId = '';
    this.dropdownSettingsState = {
      singleSelection: true,
      text: "Select Jurisdictions",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.updateMasterForm = this._fb.group({
      updateId: 0,
      updateNote: [''],
      updateDate: ['', Validators.required],
      stateId: [''],
    })

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetUpdateById(params['id']);
        }
      }
    });
  }

  GetUpdateById(updateId) {
    this.crraupdateService.GetUpdateById(this.tokenKey, parseInt(updateId.toString())).subscribe(
      result => {

        if (result['status'] == 'Success') {
          this.updateMasterForm.patchValue({
            updateId: result['crraupdate'].updateId,
            stateId: result['crraupdate'].stateId,
            updateNote: result['crraupdate'].updateNote,
            updateDate: result['crraupdate'].updateDate
          });

          if (this.stateList.length > 0) {
            let data = this.stateList.filter(x => x.stateId == result['crraupdate'].stateId)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
          else {
            this.getStateId = result['crraupdate'].stateId;
          }
        }
      });
  }

  cancelUpdate() {
    let navigation = '/admin/' + this.SelectedTool + '/updates';
    this.router.navigate([navigation]);
  }

  save() {
    this.isSubmit = true;
    let data = this.updateMasterForm.value;
    if (!this.updateMasterForm.valid)
      return
    data["createdBy"] = this.userId;
    let value = {
      updateId: data.updateId,
      updateDate: this.datePipe.transform(data.updateDate, 'MM-dd-yyyy hh:mm:ss'),
      UpdateNote: data.updateNote.trim(),
      stateId: data.stateId
    }
    this.crraupdateService.UpdateAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.updateId > 0)
              this.showMessage('Update updated successfully', 'Success', 'success');
            else
              this.showMessage('Update saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/updates']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
          if (this.getStateId != '') {
            let data = this.stateList.filter(x => x.stateId == this.getStateId)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
        }
      },
      error => { }
    );
  }
  onDeSelectDateFilterStateAll($event) {
    this.selectedState = [];
  }

  onChangeFund(event) {
    this.updateMasterForm.patchValue({
      stateId: event.id
    });
  }
}
