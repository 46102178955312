import { Component, OnInit, ViewChild, ViewChildren, ElementRef, HostListener } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EditorModule } from 'primeng/editor';
import { MatrixFundService } from '../../../Services/matrixfund.service';
import { CheckAuth } from '../../../Services/utility.service';
import { MatrixfundModel } from '../../../Models/matrixfundModel';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../../../Services/app.config';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { MatrixModel } from '../../../Models/matrixModel';
import { AssessmentcategoryModel } from '../../../Models/assessmentcategoryModel';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from '../../../Models/Common';

@Component({
  selector: 'app-state-manangesetup',
  templateUrl: './state-manangesetup.component.html',
  styleUrls: ['./state-manangesetup.component.css']
})
export class StateManangesetupComponent implements OnInit {
  userID: number = 0;
  stateid: number = 0;
  statename: string = '';
  isloading: boolean = false;
  //isloadingsave: boolean = false;
  tokenKey: string = '';
  matrixfundmodel: MatrixfundModel[] = [];
  totalPageSize: number = 0;
  pageSize: number = 0;
  pageIndex: number = 0;
  orderBy: string = 'null';
  keywordFilter: string = '';
  whereCondition: string = 'null';
  isAscending: boolean;
  statusCode: number = 0;
  appModel: any;
  upDowTitleCSS: string = '';
  upDowAssementCatNameCSS: string = '';
  upDowCreatedByCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedByCSS: string = '';
  upDowModifiedDateCSS: string = '';
  matrixModel: MatrixModel = {
    matrixId: 0,
    stateId: 0,
    stateName: '',
    createdDate: null,
    createdBy: null,
    createdByName: null,
    modifiedDate: null,
    modifiedBy: null,
    modifiedByName: null
  };
  totalRecordsCount: number = -1;
  SelectedTool: string;
  productId: number = 1;
  //assessmentcategoryModel: assessmentcategoryModel[] = [];
  @ViewChildren('rdfundfield') rdfundfield;
  isRecords: boolean = false;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;

  matrixid: number = 0;
  matrixFundId: number = 0;
  assementCatId: number = 0;
  fundFieldId: number = 0;
  deletematrixfundId: number = 0;
  openMenuIndex: number = -1;

  @ViewChild('deletecloseBtn', {static: false}) deletecloseBtn: ElementRef;

  constructor(private _fb: FormBuilder, private MatrixFundService: MatrixFundService
    , private checkAuth: CheckAuth, private toastr: ToastrService
    , private activedRoute: ActivatedRoute, private router: Router
    , private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('State Configurations');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.fundFieldId = 1;
    this.pageSize = this.appModel.PageSize;
    this.totalPageSize = 1;
    this.pageIndex = 1;
    this.keywordFilter = 'null';
    this.whereCondition = 'null';
    this.orderBy = 'DisplayOrder';
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.stateid = params['id'];
          this.isloading = true;
          this.GetMatrixbystateid();
        }
      }
    });
  }

  //Fund start
  GetMatrixbystateid(): void {
    //this.isloading = true; //moved to page init
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.MatrixFundService.GetMatrixByStateId(this.tokenKey, this.stateid.toString(), this.userID.toString(), this.productId.toString()).subscribe(
      data => {
        this.isloading = false;
        if (data["status"] == "Success") {
          this.matrixModel.matrixId = data["matrix"][0].matrixId;
          this.matrixModel.stateId = data["matrix"][0].stateId;
          this.matrixModel.stateName = data["matrix"][0].stateName;
          this.matrixModel.createdDate = data["matrix"][0].createdDate;
          this.matrixModel.createdBy = data["matrix"][0].createdBy;
          this.matrixModel.createdByName = data["matrix"][0].createdByName;
          this.matrixModel.modifiedDate = data["matrix"][0].modifiedDate;
          this.matrixModel.modifiedBy = data["matrix"][0].modifiedBy;
          this.matrixModel.modifiedByName = data["matrix"][0].modifiedByName;
          this.matrixid = this.matrixModel.matrixId;
          this.statename = this.matrixModel.stateName;
          this.whereCondition = '(MatrixId = ' + this.matrixid + ')';
          this.GetMatrixFund();
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }
  GetMatrixFund(): void {
    //this.isloading = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.MatrixFundService.GetMatrixFundFilterByPage(this.tokenKey, this.pageIndex.toString(), this.pageSize.toString(), this.whereCondition, this.orderBy, this.keywordFilter, this.productId.toString()).subscribe(
      data => {
        this.isloading = false;
        if (data["status"] == "Success") {
          this.matrixfundmodel = data["matrixFund"];
          if (this.matrixfundmodel.length > 0) {
            this.isRecords = true;
            this.totalPageSize = parseInt(this.matrixfundmodel[0].totalRecordCount);
            this.totalRecordsCount = this.matrixfundmodel.length;
          }
          else {
            this.totalRecordsCount = 0;
            this.isRecords = false;
          }
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }

  onDropSuccess() {
    this.blockDocument();
    for (let iCounter = 0; iCounter < this.matrixfundmodel.length; iCounter++) {
      this.matrixfundmodel[iCounter].modifiedBy = this.userID.toString();
    }
    this.MatrixFundService.updatematrixfundDisplayOrder(this.tokenKey, this.matrixfundmodel)
      .subscribe(data => {
        if (data["status"] == "Success") {
          this.showMessage('Rearranged successfully', 'Rearranged', 'success');
         // this.GetMatrixFund();
          this.unblockDocument();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
          this.unblockDocument();
        }
      });
  }

  onDragSuccess(){
  }

  allowDragDropFunction(a) {
    if (sessionStorage.getItem('UserLoginTool') == 'voip' && (a === 9 || a === 15))
      return false;
    else if (sessionStorage.getItem('UserLoginTool') == 'wireless' && (a === 7 || a === 8 || a === 1))
      return false;
    else
      return true;
  }

  setDeletematrixfundId(matrixfundId: any) {
    this.deletematrixfundId = matrixfundId;
  }

  DeleteFund() {
    this.blockDocument();
    this.MatrixFundService.deletefund(this.tokenKey, this.deletematrixfundId)
      .subscribe(data => {
        if (data["status"] == "Success") {
          this.showMessage('Deleted successfully', 'Deleted', 'success');
          this.deletecloseBtn.nativeElement.click();
          this.GetMatrixFund();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
        this.unblockDocument();
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }
  ActiveInactiveFund(matrixfundId: any, isactive: any) {
    this.blockDocument();
    this.MatrixFundService.activeinactivefund(this.tokenKey, matrixfundId, isactive)
      .subscribe(data => {
        if (data["status"] == "Success") {
          if (isactive) {
            this.showMessage('Activated successfully', 'Active', 'success');
          }
          else {
            this.showMessage('Inactivated successfully', 'Inactive', 'success');
          }
          this.GetMatrixFund();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
        this.unblockDocument();
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }
  redirectToStatePreview(stateid) {
    //sessionStorage.setItem('singleStatePreview', stateid);
    sessionStorage.setItem('StateSetupManageRedirection', stateid);
    sessionStorage.setItem('isAdminOpen', 'true');
    let navigation = '/admin/' + this.SelectedTool + '/state/matrix/preview';
    this.router.navigate([navigation]);
  }
  //Fund end

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';

      if (sortBy === "Title") {
        this.upDowTitleCSS = 'fa fa-arrow-down';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "AssementCatName") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = 'fa fa-arrow-down';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedBy") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = 'fa fa-arrow-down';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = 'fa fa-arrow-down';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "ModifiedBy") {
        this.upDowTitleCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = 'fa fa-arrow-down';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "ModifiedDate") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      if (sortBy === "Title") {
        this.upDowTitleCSS = 'fa fa-arrow-up';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "AssementCatName") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = 'fa fa-arrow-up';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedBy") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = 'fa fa-arrow-up';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = 'fa fa-arrow-up';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "ModifiedBy") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = 'fa fa-arrow-up';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "ModifiedDate") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = 'fa fa-arrow-up';
      }
    }
    this.GetMatrixFund();
  }
  pageChanged($event) {
    this.pageIndex = parseInt($event.page);
    this.GetMatrixFund();
  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }
  keywordFilters($event) {
    this.pageIndex = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.keywordFilter = $event.target.value;
    else
      this.keywordFilter = 'null';

    this.GetMatrixFund();
  }
  redirecttoeditsetup(matrixFundId) {
    let navigation = '/admin/' + this.SelectedTool + '/state/setup/edit/' + this.stateid + '/' + matrixFundId;
    this.router.navigate([navigation]);
  }
  redirecttoaddsetup() {
    let navigation = '/admin/' + this.SelectedTool + '/state/setup/add/' + this.stateid;
    this.router.navigate([navigation]);
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
