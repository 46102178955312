import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { SpecialReportModel, SpecialReportSectionsModel } from 'src/Models/SpecialReportModel';
import { CheckAuth } from 'src/Services/utility.service';
import { SpecialReportService } from 'src/Services/special-reports.services';
import { FormBuilder } from '@angular/forms';
import { SectionMasterService } from 'src/Services/section-master.service';
import { SectionsModel } from 'src/Models/SectionMasterModel';

@Component({
  selector: 'app-manage-special-reports-section-master',
  templateUrl: './manage-special-reports-section-master.component.html',
  styleUrls: ['./manage-special-reports-section-master.component.css']
})
export class ManageSpecialReportsSectionMasterComponent implements OnInit {
  specialReportSectionModel = new SectionsModel();
  specialReportsSectionsDetails: any = [];
  loginuserID: number = 0;
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;
  AddButtontitle: string;
  AddUpdateButtontitle: string;
  subtitle: string;
  sortOrder: string;
  companyId: number = 0;
  section: string;

  specialReportSectionId: number = 0;
  isMultipleClick: boolean = false;
  isShowprogressSpinner = false;
  dropdownListProduct = [];
  dropdownSettingsProducts = {};
  selectedItemsProducts = [];




  constructor(private _fb: FormBuilder, private activedRoute: ActivatedRoute, private specialreportservice: SpecialReportService, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService, private sectionService: SectionMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Special Section Report');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.dropdownSettingsProducts = {
      singleSelection: true,
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No Product available',
    };

    this.subtitle = '';

    this.AddButtontitle = "Add";
    this.AddUpdateButtontitle = "Save";
    this.activedRoute.params.subscribe((params: Params) => {
      this.companyId = params['cid'];

      if (params['id']) {
        if (params['id'] != null) {

          this.specialReportSectionId = params['id'];
          this.AddUpdateButtontitle = "Update";

          this.GetSpecialReportSectionData();
        }
      }
      else {
        this.specialReportSectionModel.isActive = true;
      }
    });
    this.getAllProduct();
  }

  GetSpecialReportSectionData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.sectionService.i360SectionFindbySectionId(this.tokenKey, this.specialReportSectionId).subscribe(
      data => {
        if (data != null) {
          this.specialReportSectionModel = data["sectionData"];

          if (this.specialReportSectionModel.productName != null) {
            if (this.dropdownListProduct != null && this.dropdownListProduct.length > 0) {
              this.selectedItemsProducts = this.dropdownListProduct.filter(x => x.id == this.specialReportSectionModel.productName);
            }
            else {
              setTimeout(() => {
                this.selectedItemsProducts = this.dropdownListProduct.filter(x => x.id == this.specialReportSectionModel.productName);
              }, 800);
            }
          }

        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  getAllProduct() {
    this.sectionService.i360GetProductsForSections(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        for (let iCounter = 0; iCounter < data['productList'].length; iCounter++) {
          this.dropdownListProduct.push(
            { 'id': data['productList'][iCounter].menuName, 'itemName': data['productList'][iCounter].menuName }
          );
        }
      }
    });
  }


  savespecialReportSections() {
    event.preventDefault();

    if (this.specialReportSectionModel.sectionName == '' || typeof (this.specialReportSectionModel.sectionName) == 'undefined' || this.specialReportSectionModel.sectionName == null) {
      this.showMessage('Please enter Section Name', 'Section Name Required', 'error');
      return;
    }

    if (this.specialReportSectionModel.sortOrder == '' || typeof (this.specialReportSectionModel.sortOrder) == 'undefined' || this.specialReportSectionModel.sortOrder == null) {
      this.showMessage('Please enter Sort Order', 'Sort Order Required', 'error');
      return;
    }
    if (this.selectedItemsProducts == null || this.selectedItemsProducts.length == 0) {
      this.showMessage('Please enter Product', 'Product Required', 'error');
      return;
    }

    //this.specialReportSectionModel.companyId = this.companyId;
    this.specialReportSectionModel.sectionId = this.specialReportSectionId;
    this.specialReportSectionModel.createdBy = this.loginuserID;
    this.specialReportSectionModel.updatedBy = this.loginuserID;

    let formData = new FormData();
    formData.append('sectionid', this.specialReportSectionModel.sectionId);
    formData.append('sectionName', this.specialReportSectionModel.sectionName);
    formData.append('createdBy', this.specialReportSectionModel.createdBy);
    formData.append('modifiedBy', this.specialReportSectionModel.updatedBy);
    formData.append('isActive', this.specialReportSectionModel.isActive);
    formData.append('sortOrder', this.specialReportSectionModel.sortOrder);
    formData.append('productName', this.selectedItemsProducts[0].itemName);



    // this.specialReportSectionModel.customProductDetails = this.customProductDetails;

    if (this.isMultipleClick) {
      return;
    }
    this.isMultipleClick = true;
    this.isShowprogressSpinner = true;
    this.sectionService.i360SectionsAddUpdate(this.tokenKey, formData).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.isMultipleClick = false;
        if(data['status']=='Failure')
        {
          this.showMessage(data['msg'], 'Warning', 'error');
        }
        else{
        if (data['sectionId'] != 0) {
          if (data['sectionId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.specialReportSectionId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.specialReportSectionId = 0;
            this.cancelSpecialReportSection();
          }
        }
      }
      },
      error => {
        this.isShowprogressSpinner = false;
        this.isMultipleClick = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelSpecialReportSection() {
    this.router.navigate(['admin/inteserra360/SectionsMaster']);
  }
  onSelectedSection($event) {
    // this.selectedItemsSections = this.removeElemetninArray(this.selectedItemsSections, $event.id)
    // this.selectedItemsSections.push($event.id);
  }
  onDeSelectedProduct($event) {
    if (this.selectedItemsProducts != null) {
      if (this.selectedItemsProducts.length > 0) {
        this.selectedItemsProducts = this.removeElemetninArray(this.selectedItemsProducts, $event.id)
      }
    }
  }
  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

}
