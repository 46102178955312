import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { NLADFileFormatModel } from 'src/Models/NLADFileFormatModel';
import { Title } from "@angular/platform-browser";
import { NLADClaimService } from 'src/Services/nladClaim.service';
import { ToastrService } from 'ngx-toastr';
import { ColumnMappingModel } from 'src/Models/ColumnMappingModel';
import { ToolEnum } from 'src/Models/Common';
import { InrollProductsCompanyModel } from 'src/Models/InrollProductsCompanyModel';
import { ColumnArraryModel } from 'src/Models/ColumnArraryModel';

@Component({
  selector: 'app-column-mapping-lifeline',
  templateUrl: './column-mapping-lifeline.component.html',
  styleUrls: ['./column-mapping-lifeline.component.css']
})
export class ColumnMappingLifelineComponent implements OnInit {
  nladFileFormatModel: NLADFileFormatModel[];
  mappingData: NLADFileFormatModel[] = [];

  @ViewChild('uploadedDoc', { static: false }) uploadedDoc: ElementRef;
  file: File;
  isErrorOnDoc: boolean = true;
  errorMsg: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;

  columnList: ColumnArraryModel[];
  columnMappingModel = new ColumnMappingModel();
  SelectedTool: string;
  productId: number = 1;
  providerId: number = 0;
  userId: number = 0;

  listOfInrollProductCompany: InrollProductsCompanyModel[];

  dropdownCompanies = [];
  selectedItemsCompanies = [];
  dropdownSettingCompanies = {};
  listOfCompany = [];
  docName: string = '';
  isDisableDropDown: boolean = false;

  constructor(
    private router: Router,
    private titleService: Title,
    private nladService: NLADClaimService,
    private toastr: ToastrService,
    private activedRoute: ActivatedRoute,
  ) {
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Manage Lifeline Claim Files Columns Mappings');
    this.productId = ToolEnum[this.SelectedTool];
    this.providerId = 0;
    this.userId = +sessionStorage.getItem('AdminUserId');


  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.providerId = params['id'];
          this.GetLifelineNLADFileFormatFindByProviderId(this.providerId);
          this.GetInrollClientCompany();
          this.isDisableDropDown = true;
        }
        else {
          this.GetLifelineNLADFileFormat();
          this.GetInrollClientCompany();
        }
      }
      else {
        this.GetLifelineNLADFileFormat();
        this.GetInrollClientCompany();
      }
    });
  }

  GetLifelineNLADFileFormat() {
    this.nladFileFormatModel = [];
    this.nladService.GetLifelineNLADFileFormat().subscribe(
      data => {
        this.nladFileFormatModel = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  onFileSelect(file) {
    this.file = file.target.files[0];
    this.docName = this.file.name;
    let size = Math.round(this.file.size / 1024);
    if (size <= 500000) {
      var allowedExtensions = ["csv", "CSV"];
      let fileType = this.file.name.split('.').pop();

      if (!this.isInArray(allowedExtensions, fileType)) {
       this.toastr.error('You have selected an invalid file type.System supports following file formats: csv.', 'Error');
        this.isErrorOnDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isErrorOnDoc = false;
      }
    }
    else {
      this.toastr.error('You have selected a larger file. supports file upto 500MB.', 'Error');
      this.isErrorOnDoc = true;
      window.scrollTo(0, 0);
    }
  }

  /*- checks if word exists in array -*/
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  onFileUpload() {
    
    this.errorMsg = '';
    if (this.isErrorOnDoc) {
      this.toastr.error('Please select source file', 'Error');
      return;
    }

    if (this.providerId > 0) {
      this.GetLifelineNLADFileFormat();
      this.providerId = 0;
    }

    this.blockDocument();
    let formData = new FormData();
    if (this.file) {
      formData.append("uploadedFile", this.file);
      formData.append("UserId", this.userId.toString());
    }

    this.nladService.LifelineUploadExcelFile(formData).subscribe(
      fdata => {
        
        if (fdata != null) {
          if (fdata.result == 'Success') {
            this.columnMappingModel = fdata;
            this.nladService.GetLifelineColumnArray(this.columnMappingModel).subscribe(
              data => {
                

                this.unblockDocument();
                this.uploadedDoc.nativeElement.value = "";
                if (data != null) {
                  if (data.result == 'Success') {
                    this.columnList = data.list;
                    this.columnMappingModel = data;
                    if (this.nladFileFormatModel != null) {
                      if (this.nladFileFormatModel.length > 0) {
                        this.providerId = this.listOfCompany[0];
                        for (let iCounter = 0; iCounter < this.nladFileFormatModel.length; iCounter++) {
                          let seprator = '';
                          this.nladFileFormatModel[iCounter].listOfSourceColumnName = '';
                          for (let jCounter = 0; jCounter < this.columnList.length; jCounter++) {
                            this.nladFileFormatModel[iCounter].listOfSourceColumnName += seprator + this.columnList[jCounter].columnName;
                            seprator = ',';
                          }
                          this.nladFileFormatModel[iCounter].fileName = data.docName;
                          this.nladFileFormatModel[iCounter].filePath = data.docPath;
                          this.nladFileFormatModel[iCounter].providerId = this.providerId;
                          this.nladFileFormatModel[iCounter].userId = this.userId;
                        }
                        this.nladFileFormatModel = this.filterDisplayOrder(this.nladFileFormatModel);
                      }
                    }
                  }
                  else {
                    this.errorMsg = data.result;
                    this.toastr.error(data.result, 'Error');
                  }
                }
              },
              error => {
                this.unblockDocument();
                console.log(error);
              }
            );
          }
        }
      },
      error => {
        this.unblockDocument();
        console.log(error);
      }
    );
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  setSourceColumnValue($event, id) {
    
    let key = parseInt($event.currentTarget.value);
    if (this.mappingData != null && key != -1) {
      let conrol1 = this.mappingData.filter(m => m.selectSourceId == key);
      if (conrol1.length > 0) {
        let tmp = <HTMLInputElement>document.getElementById(conrol1[0].controlId);
        if (tmp != null) {
          tmp.value = '-1';
        }
      }
      let conrol2 = this.mappingData.filter(m => m.id == id);
      if (conrol2.length > 0 && conrol1.length == 0) {
        let tmp = (<HTMLInputElement>document.getElementById(conrol2[0].controlId));
        if (tmp != null) {
          tmp.value = '-1';
        }
      }
      this.mappingData = this.removeElemetninArray(this.mappingData, key);
      this.mappingData = this.removeElemetninArrayById(this.mappingData, id);
      let model = this.nladFileFormatModel.filter(m => m.id === id);
      let sourceCol = this.columnList.filter(m => m.id == key)
      model[0].selectSourceColumnName = sourceCol[0].columnName;
      model[0].selectSourceId = sourceCol[0].id;
      model[0].controlId = $event.currentTarget.id;
      this.mappingData.push(model[0]);
    }
    else {
      this.mappingData = this.removeElemetninArrayById(this.mappingData, id);
    }
  }

  removeElemetninArray(array, element) {
    
    return array.filter(e => parseInt(e.selectSourceId) !== parseInt(element));
  }

  removeElemetninArrayById(array, element) {
    return array.filter(e => e.id !== element);
  }

  saveMappingDate() {
    
    let count = this.mappingData.filter(m => m.selectSourceColumnName != '' && m.selectSourceColumnName != null && typeof (m.selectSourceColumnName) != 'undefined')
    if (count.length < this.nladFileFormatModel.length) {
      this.toastr.error('Please mapped the columns properly and duplicate column not allowed.', 'Error');
      return;
    }

    this.nladService.SaveLifelineColumnMapping(this.mappingData).subscribe(
      data => {
        if (data != null) {
          if (data.status == "Success") {
            this.toastr.success('Column Mapped Successfully.', 'Success');
            this.router.navigate(['/admin/inroll/column/lifeline-listing']);
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  GetInrollClientCompany() {
    this.nladService.GetLifelineInrollClientCompany().subscribe(
      data => {
        this.dropdownSettingCompanies = {
          singleSelection: true,
          text: 'Select Inroll Company',
          enableSearchFilter: true,
          classes: 'borderLessTextBoxGreen',
          badgeShowLimit: 1,
          noDataLabel: 'No inroll company',
          showCheckbox: false,
          enableFilterSelectAll: false,
          disabled: this.isDisableDropDown
        };

        if (data != null) {
          if (data.status == 'Success') {
            this.listOfInrollProductCompany = data.listofInrollCompany;
            if (this.listOfInrollProductCompany != null) {
              if (this.listOfInrollProductCompany.length > 0) {
                for (let iCounter = 0; iCounter < this.listOfInrollProductCompany.length; iCounter++) {
                  this.dropdownCompanies.push({ 'id': this.listOfInrollProductCompany[iCounter].companyId, 'itemName': this.listOfInrollProductCompany[iCounter].companyName });
                  
                  if (this.listOfInrollProductCompany[iCounter].companyId == this.providerId) {
                    this.selectedItemsCompanies.push({ 'id': this.listOfInrollProductCompany[iCounter].companyId, 'itemName': this.listOfInrollProductCompany[iCounter].companyName });
                    this.listOfCompany.push(this.listOfInrollProductCompany[iCounter].companyId)
                  }
                }
              }
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedCompanies($event) {
    this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event.id)
    this.listOfCompany.push($event.id);
  }

  onDeSelectedCompanies($event) {
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event.id)
      }
    }
  }

  onSelectAllCompanies($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllCompanies($event) {
    this.listOfCompany = [];
  }

  filterDisplayOrder(array) {
    
    return array.sort((one, two) => (one.displayOrder > two.displayOrder ? 1 : -1));
  }

  GetLifelineNLADFileFormatFindByProviderId(providerId) {
    
    this.nladFileFormatModel = [];
    this.nladService.GetLifelineNLADFileFormatFindByProviderId(providerId).subscribe(
      data => {
        this.nladFileFormatModel = data.listOfData;
        this.mappingData = this.nladFileFormatModel;
        for (let iCounter = 0; iCounter < this.nladFileFormatModel.length; iCounter++) {
          this.nladFileFormatModel[iCounter].userId = this.userId;
        }

        this.GetLifelineColumnArrayFindByProvideId(this.providerId);
      },
      error => {
        console.log(error);
      }
    );
  }

  GetLifelineColumnArrayFindByProvideId(providerId) {
    
    this.nladService.GetLifelineColumnArrayFindByProvideId(providerId).subscribe(
      data => {
        
        let listOfData = data.listOfData;
        this.docName = data.docName;
        if (listOfData != null) {
          if (listOfData.length > 0) {
            this.columnList = [];
            for (let iCounter = 0; iCounter < listOfData.length; iCounter++) {
              let columnModel = new ColumnArraryModel();
              columnModel.id = listOfData[iCounter].id;
              columnModel.columnName = listOfData[iCounter].columnName;
              columnModel.docPath = null;
              columnModel.docName = null;
              columnModel.isSelected = listOfData[iCounter].isSelected;
              this.columnList.push(columnModel);
            }
            this.nladFileFormatModel = this.filterDisplayOrder(this.nladFileFormatModel);
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }
}
