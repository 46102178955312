
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterModel,CarrierFilterModel } from 'src/Models/filterModel';

@Injectable({
  providedIn: 'root'
})
export class IprUpdatesService {
  AppUrl: string = "";
  filterModel = new FilterModel();
  carrierFilterModel = new CarrierFilterModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }
  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  GetUpdatesFilterByPage(TokenKey: string, PageNumber: string, PageSize: string, WhereCondition: string, OrderBy: string, keywordFilter: string): Observable<any> {
    this.setAPIUrl();
    this.filterModel.pageNumber = PageNumber;
    this.filterModel.pageSize = PageSize;
    this.filterModel.whereCondition = WhereCondition;
    this.filterModel.orderBy = OrderBy;
    this.filterModel.keywordFilter = keywordFilter;

    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetIPRUpdatesFilter';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, this.filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetIPRUpdatesFindByCarrierID(TokenKey: string, carrierID: number, productId: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetIPRUpdatesFindByCarrierID/' + carrierID + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }
  ActiveInactiveIPRUpdates(TokenKey: string, updateId: string, isactive: string): Observable<any[]> {
    let data = '/' + updateId;
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/ActiveInActiveUpdate' + data + '/' + isactive;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any[]>(fullAPIPath, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetIPRUpdateHistoryByCarrierID(TokenKey: string, updateId: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetIPRUpdateHistoryByCarrierID/' + updateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }
  addupdateupdates(user, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/IPRUpdates/AddUpdateIPRUpdates', user, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetCarriers(TokenKey: string, FromDate: string, ToDate: string, UpdateType: string, OrderBy: string, keywordFilter: string, userID: number, filterFavourite: number, carrierFilter: string): Observable<any> {
    this.setAPIUrl();
    this.carrierFilterModel.fromDate = FromDate;
    this.carrierFilterModel.todate = ToDate;
    this.carrierFilterModel.updateStatus = UpdateType;
    this.carrierFilterModel.orderBy = OrderBy;
    this.carrierFilterModel.keywordFilter = keywordFilter;
    this.carrierFilterModel.userID = userID;
    this.carrierFilterModel.filterFavourite = filterFavourite;
    this.carrierFilterModel.carrierFilter = carrierFilter;

    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetIPRCarriersFilter';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, this.carrierFilterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetIPRUpdates(TokenKey: string, FromDate: string, ToDate: string, UpdateType: string, OrderBy: string, keywordFilter: string, userID: number, filterFavourite: number, carrierFilter: string): Observable<any> {
    this.setAPIUrl();
    this.carrierFilterModel.fromDate = FromDate;
    this.carrierFilterModel.todate = ToDate;
    this.carrierFilterModel.updateStatus = UpdateType;
    this.carrierFilterModel.orderBy = OrderBy;
    this.carrierFilterModel.keywordFilter = keywordFilter;
    this.carrierFilterModel.userID = userID;
    this.carrierFilterModel.filterFavourite = filterFavourite;
    this.carrierFilterModel.carrierFilter = carrierFilter;

    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetIPRUpdatesList';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, this.carrierFilterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  ClientStateFavouriteAdd(TokenKey: string, userId: number, stateIds: string, isFavourite: number): Observable<any> {
    this.setAPIUrl();
    this.carrierFilterModel.userID = userId;
    this.carrierFilterModel.keywordFilter = stateIds;
    this.carrierFilterModel.Status = isFavourite;

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/IPRUpdates/ClientStateFavouriteAdd', this.carrierFilterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  ClientCarrierFavouriteAdd(TokenKey: string, userId: number, carrierId: number, isFavourite: number): Observable<any> {
    this.setAPIUrl();
    this.carrierFilterModel.userID = userId;
    this.carrierFilterModel.ID = carrierId;
    this.carrierFilterModel.Status = isFavourite;

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/IPRUpdates/ClientCarrierFavouriteAdd', this.carrierFilterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetIPRUpdatesUnread(TokenKey: string, userID: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetIPRUpdatesUnreadList/' + userID;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }
  addNotification(userID: number, updateId: string, TokenKey: string): Observable<any> {
    this.setAPIUrl();
    this.carrierFilterModel.userID = userID;
    this.carrierFilterModel.updateStatus = updateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/IPRUpdates/AddNotification', this.carrierFilterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetCarrierMergeSuggestionList(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetCarrierMergeSuggestionList'
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }
  GetAllCarriersForMerge(TokenKey: string, CarrierID: number): Observable<any> {
    this.setAPIUrl();
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetAllCarriersForMerge/' + CarrierID;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }
  MergeCarrier(userID: number, oldCarrierID: number, newCarrierID: number, TokenKey: string): Observable<any> {
    this.setAPIUrl();
    this.carrierFilterModel.userID = userID;
    this.carrierFilterModel.ID = oldCarrierID;
    this.carrierFilterModel.newCarrierID = newCarrierID;

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/IPRUpdates/MergeCarrier', this.carrierFilterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  DiscardCarrierMerge(oldCarrierID: number, newCarrierID: number, TokenKey: string): Observable<any> {
    this.setAPIUrl();
    this.carrierFilterModel.ID = oldCarrierID;
    this.carrierFilterModel.newCarrierID = newCarrierID;

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/IPRUpdates/DiscardCarrierMerge', this.carrierFilterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetStatesByUserID(TokenKey: any, UserID: any, productId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/IPRUpdates/GetStatesByUserID/' + UserID + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetClientFavorites(TokenKey: any, UserID: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/IPRUpdates/UserFavoriteStatesCarriers/' + UserID;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetMatrixStatesByUserID(TokenKey: any, UserID: any, productId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/IPRUpdates/GetMatrixStatesByUserID/' + UserID + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  RemoveMatrixFavoriteByUserID(TokenKey: any, UserID: any, productId: number, DeSelectedFavoriteId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/IPRUpdates/RemoveMatrixFavoriteByUserID/' + UserID + '/' + productId + '/' + DeSelectedFavoriteId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetRecordsCountForAllPage(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/IPRUpdates/GetRecordsCountForAllPage';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetScheduleLogFilterByPage(TokenKey: string, filterModel: FilterModel): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetScheduleLogFilterByPage';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetScheduleLogHistory(TokenKey: string, filterModel: FilterModel) {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + `api/IPRUpdates/GetScheduleLogHistory`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
