
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { Response } from '@angular/http';
import { catchError } from 'rxjs/operators';
import { TokenDetailModel } from '../Models/tokenDetailModel';
import { UserTokenModel } from '../Models/UserTokenModel'
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../Services/app.config';


@Injectable()
export class TokenService {
  apiURL: string = "";
  userToken = new UserTokenModel();
  appModel: any;
  
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.appModel = AppConfig.settings.ConnectionStrings;
    sessionStorage.setItem('BaseURL', this.appModel.BaseURL);    
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.apiURL = sessionStorage.getItem("BaseURL") || '';
      this.apiURL = this.apiURL + 'api';
    }
   }

   setTokenCredential() {
    this.userToken.ClientID = this.appModel.ClientID;
    this.userToken.ClientSecret = this.appModel.ClientSecret;
  }

  getAuthentication(): Observable<TokenDetailModel> {    
    this.setTokenCredential();
    let fullAPIPath = this.apiURL + '/Token/Authentication';
    let formData: FormData = new FormData();
    formData.append("ClientID", this.userToken.ClientID);
    formData.append("ClientSecret", this.userToken.ClientSecret);
    return this.http.post<TokenDetailModel>(fullAPIPath, formData)
      .pipe(catchError(this.handleError));
  }

  private extractData(res: Response) {
    let body = res.json();    
    return body;
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
