import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from 'src/Models/Common';
import { Data477Model } from 'src/Models/Data477Model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { I360Service } from 'src/Services/i360Service';
import { Params, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-data477-manage',
  templateUrl: './data477-manage.component.html',
  styleUrls: ['./data477-manage.component.css']
})
export class Data477ManageComponent implements OnInit {
  data477Model = new Data477Model();

  data477Form: FormGroup;
  loginuserID: number = 0;

  Data477Model: Data477Model = {
    dataId: 0,
    title: '',
    url: '',
    isActive: ''
  };
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;

  dataId: number = 0;
  isMultipleClick: boolean = false;

  constructor(private activedRoute: ActivatedRoute, private _fb: FormBuilder, private router: Router, private checkAuth: CheckAuth, private titleService: Title,
    private i360service: I360Service,
    private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage 477 Data');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');

    const reg = '[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?';

    this.data477Form = this._fb.group({
      dataId: 0,
      title: '',
      url: ['', [Validators.required, Validators.pattern(reg)]],

      isActive: true
    });
  }

  // Getter for easy access
  get url() { return this.data477Form.get('url') };

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          
          this.dataId = params['id'];
          this.Get477Data();
        }
      }
    });

  }

  Get477Data(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.i360service.i360477DataFindbyDataId(this.tokenKey, this.dataId).subscribe(
      data => {
        if (data != null) {
          this.data477Model = data["data477"];
          if (this.data477Model != null) {
            this.data477Form.setValue({
              dataId: this.data477Model.dataId,
              title: this.data477Model.title,
              url: this.data477Model.url,
              isActive: this.data477Model.isActive
            });
          }
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  save477Data(data477Form) {
    
    event.preventDefault();

    this.data477Model = data477Form.value;

    if (this.data477Model.title == '' || typeof (this.data477Model.title) == 'undefined' || this.data477Model.title == null) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }

    if (this.data477Model.url == '' || typeof (this.data477Model.url) == 'undefined' || this.data477Model.url == null) {
      this.showMessage('Please enter url', 'URL Required', 'error');
      return;
    }

    this.data477Model.createdBy = this.loginuserID;
    this.data477Model.modifiedBy = this.loginuserID;

    if (!this.data477Form.valid) {

      return;
    }

    if (this.isMultipleClick) {      
      return;
    }
    this.isMultipleClick = true;

    this.i360service.i360477DataAddUpdate(this.tokenKey, this.data477Model).subscribe(
      data => {
       
        this.isMultipleClick = false;
        if (data['dataId'] != 0) {
          if (data['dataId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.dataId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.dataId = 0;
            this.cancel477Data()
          }
        }
      },
      error => {
        this.isMultipleClick = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancel477Data() {
    this.router.navigate(['admin/inteserra360/477data']);
  }
}
