
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoginPasswordModel } from 'src/Models/LoginPasswordModel';
import { FilterModel } from 'src/Models/filterModel';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  AppUrl: string = "";
  loginModel = new LoginPasswordModel();
  filterModel = new FilterModel();

  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }
  checkUserLogin(TokenKey: string, username: string, password: string): Observable<any> {
    this.setAPIUrl();
    this.loginModel.username = username;
    this.loginModel.password = password;
    let data = 'api/Users/CheckUserLogin';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.loginModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  checkAdminUserLogin(TokenKey: string, username: string, password: string): Observable<any> {
    this.setAPIUrl();
    this.loginModel.username = username;
    this.loginModel.password = password;
    let data = 'api/Users/CheckAdminUserLogin';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.loginModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetUserFindByUserID(TokenKey: string, UserID: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Users/GetUserFindByUserID/' + UserID;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }
  GetUserFindByUserIDMyProfile(TokenKey: string, UserID: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Users/GetUserFindByUserIDMyProfile/' + UserID;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  
  GetStateFindByUserID(TokenKey: string, UserID: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Users/GetStateFindByUserID/' + UserID;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }
  addupdateuser(user, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/Users/AddUpdateUser', user, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetUsers(TokenKey: string, PageNumber: any, PageSize: any, WhereCondition: any, OrderBy: any, keywordFilter: string, loginUser: any, listOfProduct: string, selectedcompany: string, ProductUnassingForUser: string, statusId: string): Observable<any> {
    this.setAPIUrl();
    this.filterModel.pageNumber = PageNumber;
    this.filterModel.pageSize = PageSize;
    this.filterModel.whereCondition = WhereCondition;
    this.filterModel.orderBy = OrderBy;
    this.filterModel.keywordFilter = keywordFilter;
    this.filterModel.userID = loginUser;
    this.filterModel.listOfProduct = listOfProduct == "" ? "null" : listOfProduct;
    this.filterModel.listOfCompanyId = selectedcompany == "" ? "null" : selectedcompany;
    this.filterModel.productUnassingForUser = ProductUnassingForUser == "" ? "null" : ProductUnassingForUser;
    this.filterModel.statusId = statusId == "" ? "null" : statusId;

    let data = 'api/Users/GetUsers';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  updatepassword(user, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/Users/UpdatePassword', user, httpOptions)
      .pipe(catchError(this.handleError));
  }

  checkUserbyusernamewithhash(TokenKey: string, username: string, hash: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/CheckUsernamewithhash/' + username + '/' + hash;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  UserLogout(TokenKey: string, UserID: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/UserLogout/' + UserID;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCompanyList(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Users/GetClientCompanyList';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  GetCompanyListWithIds(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Users/GetClientCompanyListWithIds';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  GetUsersFindByCompanyId(TokenKey: string, companyId: any, productId: any): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Users/GetUsersFindByCompanyId/' + companyId + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  GetUsersFindByCompanyIdRemoveLoginUser(TokenKey: string, companyId: any, productId: any, loginUser: any): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Users/GetUsersFindByCompanyIdRemoveLoginUser/' + companyId + '/' + productId + '/' + loginUser;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  GetAdminUserType(TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + 'api/Users/GetAdminUserType', httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetClientCompanyList(TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + 'api/Users/GetCompanyList', httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360OnlineRegisterAccountActiveEmail(TokenKey: string, userId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/i360OnlineRegisterAccountActiveEmail/' + userId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360CheckUserLogin(TokenKey: string, username: string, password: string): Observable<any> {
    this.setAPIUrl();
    this.loginModel.username = username;
    this.loginModel.password = password;
    let data = 'api/Users/i360CheckUserLogin';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.loginModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CheckUserByEmailId(TokenKey: string, email: string,userName:string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/CheckUserByEmailId/' + email+'/'+userName;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CheckUserByEmailIdCount(TokenKey: string, email: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/CheckUserByEmailIdCount/' + email;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ChangePassword(TokenKey: string, changePasswordModel: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Menu/ChangePassword';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, changePasswordModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  DeleteUser(TokenKey: string, userId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/DeleteUser/' + userId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  ExportUsers(TokenKey: string, PageNumber: any, PageSize: any, WhereCondition: any, OrderBy: any, keywordFilter: string, loginUser: any, listOfProduct: string, selectedcompany: string, ProductUnassingForUser: string,statusId: string): Observable<any> {
    this.setAPIUrl();
    this.filterModel.pageNumber = PageNumber;
    this.filterModel.pageSize = PageSize;
    this.filterModel.whereCondition = WhereCondition;
    this.filterModel.orderBy = OrderBy;
    this.filterModel.keywordFilter = keywordFilter;
    this.filterModel.userID = loginUser;
    this.filterModel.listOfProduct = listOfProduct == "" ? "null" : listOfProduct;
    this.filterModel.listOfCompanyId = selectedcompany == "" ? "null" : selectedcompany;
    this.filterModel.productUnassingForUser = ProductUnassingForUser == "" ? "null" : ProductUnassingForUser;
    this.filterModel.statusId = statusId == "" ? "null" : statusId;

    let data = 'api/Users/ExportUsers';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  ExportCitiationSubscriptionGetAll(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/ExportCitiationSubscriptionGetAll';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  ExportCitiationSubscriptionGetByUser(TokenKey: string,userId:number,ProductId:number): Observable<any> {
    
    this.setAPIUrl();
    let data = 'api/Users/ExportCitiationSubscriptionGetByUser/'+userId+'/'+ProductId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  TRFAExportCitiationSubscriptionGetAll(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/TRFAExportCitiationSubscriptionGetAll';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CRRAExportCitiationSubscriptionGetAll(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/CRRAExportCitiationSubscriptionGetAll';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetUserPortalCredentialDetailsByUserId(TokenKey: string,userId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/GetUserPortalCredentialDetailsByUserId/'+userId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetUsersByCompanyId(TokenKey: string, companyId: any): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Users/GetUsersByCompanyId/' + companyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
  
  GetAllDepartment(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Users/GetAllDepartmentList';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }
  addupdateuserFromFront(user, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/Users/AddUpdateUserFromFront', user, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetRecordsCountForAllPage(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Users/GetRecordsCountForAllPage';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
