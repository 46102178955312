import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ActionItemService } from 'src/Services/actionitem.service';
import { CheckAuth } from 'src/Services/utility.service';
import { ActionItemTypeModel } from 'src/Models/ActionItemTypeModel';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-manege-action-type',
  templateUrl: './manege-action-type.component.html',
  styleUrls: ['./manege-action-type.component.css']
})
export class ManegeActionTypeComponent implements OnInit {
  tokenKey: string = '';
  loginuserID: number;
  SelectedTool: string;
  actionTypeId: number = 0;

  actionTypeModel = new ActionItemTypeModel();
  actionTypeForm: FormGroup;

  constructor(private titleService: Title, private toastr: ToastrService, private _fb: FormBuilder, private activedRoute: ActivatedRoute, private router: Router,
    private actionItemService: ActionItemService, private checkAuth: CheckAuth) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    this.actionTypeForm = this._fb.group({
      actionItemTypeId: 0,
      typeName: [''],
      colorCode: [''],
      sortOrder:['']
    });
    this.titleService.setTitle('Manage Category');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.actionTypeId = params['id'];
          this.GetActionTypesByPages();
        }
      }
      else {
        this.actionTypeId = 0;
      }
    });
  }

  GetActionTypesByPages() {
    this.actionItemService.GetActionItemTypeById(this.tokenKey, this.actionTypeId).subscribe(
      data => {
        if (data['status'] == 'Success') {

          this.actionTypeModel = data['actionTypes'];
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  saveActionTypes(actionTypesForm: any) {

    this.actionTypeModel = actionTypesForm.value;
    this.actionTypeModel.createdBy = this.loginuserID;

    if (this.actionTypeModel.typeName == '' || this.actionTypeModel.typeName == null) {
      this.showMessage('Please enter category name', 'Error', 'error');
      return;
    }
    if (this.actionTypeModel.sortOrder == null) {
      this.showMessage('Please enter category name', 'Error', 'error');
      return;
    }
    if (this.actionTypeModel.colorCode == '' || this.actionTypeModel.colorCode == null || this.actionTypeModel.colorCode == 'undefined') {
      this.showMessage('Please select color', 'Error', 'error');
      return;
    }

    this.actionItemService.AddUpdateActionItemType(this.tokenKey, this.actionTypeModel).subscribe(
      data => {
        if (data['status'] == 'Success') {
          if (this.actionTypeModel.actionItemTypeId > 0)
            this.showMessage('Category updated successfully', 'Success', 'success');
          else
            this.showMessage('Category saved successfully', 'Success', 'success');
          this.router.navigate(['/admin/trac-it/categories']);
        }
        else {
          this.showMessage(data['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  canceluser() {
    this.router.navigate(['/admin/trac-it/categories']);
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

}
