import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CRRAContentGetModel } from 'src/Models/CRRAContentModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CrraCarrierMasterService } from 'src/Services/crra-carrier-master.service';
import { CrraCategoryMasterService } from 'src/Services/crra-category-master.service';
import { CrraContentMasterService } from 'src/Services/crra-content-master.service';
import { CrraSubCategoryMasterService } from 'src/Services/crra-sub-category-master.service';
import { RouterExtService } from 'src/Services/RouterExtService';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrfaMonthsService } from 'src/Services/trfa-months.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-crra-content-list',
  templateUrl: './crra-content-list.component.html',
  styleUrls: ['./crra-content-list.component.css']
})
export class CrraContentListComponent implements OnInit {

  ckeConfig: any;
  stateCode: any;
  tokenKey: string;
  listofContent: CRRAContentGetModel[];
  templistofContent: CRRAContentGetModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;
  userId: any;

  upDowRuleTextCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowRuleCiteCSS: string = '';
  upDowRuleHeadingCSS: string = '';
  upDowEffectiveDateCSS: string = '';

  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];

  //Carrier
  carrierList: any[] = [];
  dropdownDateCarrierFilter: any[] = [];
  dropdownSettingsDateCarrierFilter = {};
  selectedItemsCarrier = [];
  listOfCarrierIDs = [];
  listOfCarrierName = [];

  //Category
  categoryList: any[] = [];
  dropdownDateCategoryFilter: any[] = [];
  dropdownSettingsDateCategoryFilter = {};
  selectedItemsCategory = [];
  listOfCategoryIDs = [];
  listOfCategoryName = [];

  categoryListMain: any[] = [];
  dropdownDateCategoryFilterMain: any[] = [];
  dropdownSettingsDateCategoryFilterMain = {};
  selectedItemsCategoryMain = [];
  listOfCategoryIDsMain = [];
  categoryId: string = '';

  //SubCategory
  subCategoryList: any[] = [];
  dropdownDateSubCategoryFilter: any[] = [];
  dropdownSettingsDateSubCategoryFilter = {};
  selectedItemsSubCategory = [];
  listOfSubCategoryIDs = [];
  listOfSubCategoryName = [];

  dropdownSettingsDateSubCategoryFilterMain = {};
  subCategoryListMain: any[] = [];
  dropdownDateSubCategoryFilterMain: any[] = [];
  selectedItemsSubCategoryMain = [];
  listOfSubCategoryIDsMain = [];
  subCategoryId: string = '';

  isRowEdited = false;
  changedata: any;
  isSaveData: boolean = false;
  ContentId: number = 0;
  module = "CRRA";
  stateIds: string = ' ';
  contentData: any;

  MonthList: any[] = [];
  selectedItemsMonth = [];
  dropdownSettingsMonthFilter = {};
  dropdownMonthFilter = [];
  auditMonth: any;
  listOfMonth = [];

  MonthList1: any[] = [];
  selectedItemsMonth1 = [];
  dropdownSettingsMonthFilter1 = {};
  dropdownMonthFilter1 = [];
  listOfMonth1 = [];
  openMenuIndex: number = -1;

  constructor(private router: Router, private checkAuth: CheckAuth, private titleService: Title, private toastr: ToastrService,
    private stateService: StateMasterService, private datePipe: DatePipe, private routerService: RouterExtService,
    private crraCarrierMasterService: CrraCarrierMasterService, private crraCategoryMasterService: CrraCategoryMasterService,
    private crraContentMaster: CrraContentMasterService, private crraSubCategoryMasterService: CrraSubCategoryMasterService, private trfaMonthsService: TrfaMonthsService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofContent = [];
    this.templistofContent = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'StateName';
    this.Note = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Audit Window');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.ckeConfig = {
      versionCheck:false,
      toolbar: [
        {
          items:
            [
              'Undo', 'Redo', 'Link', 'Unlink', 'Anchor',
              'Image', 'Table', 'SpecialChar', 'Maximize', 'Source', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat',
              'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Styles', 'Format', 'Font'
            ]
        }],
    }

    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateCategoryFilter = {
      singleSelection: true,
      text: "Select category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Category(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsDateCategoryFilterMain = {
      singleSelection: false,
      text: "Filter by Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateCarrierFilter = {
      singleSelection: false,
      text: "Select carriers",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Carrier(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateSubCategoryFilter = {
      singleSelection: false,
      text: "Select Sub category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Sub Category(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateSubCategoryFilterMain = {
      singleSelection: false,
      text: "Filter by Sub Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Sub Category(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsMonthFilter = {
      singleSelection: false,
      text: "Select Month",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Month(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsMonthFilter1 = {
      singleSelection: true,
      text: "Filter by Month",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Month(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };


    $(function () {
      $('.wrapper1').on('scroll', function (e) {
        $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft());
      });
      $('.wrapper2').on('scroll', function (e) {
        $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft());
      });
      $('.div1').width($('table').width());
      $('.div2').width($('table').width());
    });

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.getState();
    this.getCarrier();
    this.getCategory();
    this.GetContent();
    this.getMonths1()
    this.isloading = true;
  }


  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetContent();
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ id: x.stateId, itemName: x.name });
          });
        }
      },
      error => { }
    );
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  getCarrier() {
    this.crraCarrierMasterService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierList = result['crracarrier'];
          this.dropdownDateCarrierFilter = [];
          this.carrierList.forEach(x => {
            this.dropdownDateCarrierFilter.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCarrier($event) {
    this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
    this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event.itemName)
    this.listOfCarrierIDs.push($event.id);
    this.listOfCarrierName.push($event.itemName);
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIDs != null) {
      if (this.listOfCarrierIDs.length > 0) {
        this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
      }
    }
    if (this.listOfCarrierName != null) {
      if (this.listOfCarrierName.length > 0) {
        this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event.itemName)
      }
    }
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCarrierName != null) {
        if (this.listOfCarrierName.length > 0) {
          this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event[iCounter].itemName)
          this.listOfCarrierName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCarrierName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIDs = [];
    this.listOfCarrierName = [];
  }

  getCategory() {
    this.crraCategoryMasterService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryList = result['crracategory'];
          this.dropdownDateCategoryFilter = [];

          this.categoryListMain = result['crracategory'];
          this.dropdownDateCategoryFilterMain = [];
          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilter.push({ "id": x.categoryId, "itemName": x.categoryName })
          });

          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilterMain.push({ "id": x.categoryId, "itemName": x.categoryName })
          });
        }
      },
      error => { }
    );
  }

  GetSubCategoryByCategoryId(categoryId) {

    this.crraSubCategoryMasterService.GetSubCategoryByCategoryId(this.tokenKey, categoryId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.subCategoryList = result['crrasubcategory'];
          this.dropdownDateSubCategoryFilter = [];

          this.subCategoryListMain = result['crrasubcategory'];
          this.dropdownDateSubCategoryFilterMain = [];

          this.subCategoryList.forEach(x => {
            this.dropdownDateSubCategoryFilter.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
          });

          this.subCategoryList.forEach(x => {
            this.dropdownDateSubCategoryFilterMain.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
          });
        }
      }
    );
  }

  // For Edit dropdown

  onSelectCategory($event) {
    this.listOfSubCategoryName=[];
    this.listOfSubCategoryIDs=[];
    this.listOfCategoryIDs=[];
    this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
    this.selectedItemsSubCategory = [];
    this.listOfCategoryName = [];
    this.listOfCategoryIDs.push($event.id);
    this.listOfCategoryName.push($event.itemName);
    this.categoryId = ' ';
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = $event.id;
    }
    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
  }

  onDeSelectCategory($event) {
    this.categoryId=' ';
    this.listOfSubCategoryName=[];
    this.listOfSubCategoryIDs=[];
    if (this.listOfCategoryIDs != null) {
      if (this.listOfCategoryIDs.length > 0) {
        this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
      }
    }

    if (this.listOfCategoryName != null) {
      if (this.listOfCategoryName.length > 0) {
        this.listOfCategoryName = this.removeElemetninArray(this.listOfCategoryName, $event.itemName)
      }
    }

    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    if (this.listOfCategoryIDs.length == 0) {
      this.dropdownDateSubCategoryFilter = [];
      this.selectedItemsSubCategory = [];
      this.listOfSubCategoryIDs = [];
      this.listOfCategoryName =[];
    }
    else
      this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
  }

  onSelectAllCategory($event) {
    this.listOfSubCategoryName=[];
    this.listOfSubCategoryIDs=[];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }

        if (this.listOfCategoryName.length > 0) {
          this.listOfCategoryName = this.removeElemetninArray(this.listOfCategoryName, $event[iCounter].id)
          this.listOfCategoryName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCategoryName.push($event[iCounter].itemName);
        }
      }
    }
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
  }

  onDeSelectAllCategory($event) {
    this.listOfCategoryIDs = [];
    this.listOfSubCategoryIDs = [];
    this.selectedItemsSubCategory = [];
    this.dropdownDateSubCategoryFilter = [];
    this.selectedItemsCategory = []
    this.listOfCategoryName=[];
    this.listOfSubCategoryName=[];
    this.categoryId=' ';
    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
  }

  // For Filter dropdown
  onSelectCategoryMain($event) {
    this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event.id)
    this.listOfCategoryIDsMain.push($event.id);
    this.categoryId = ' ';
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }
    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onDeSelectCategoryMain($event) {
    this.categoryId = ' ';
    if (this.listOfCategoryIDsMain != null) {
      if (this.listOfCategoryIDsMain.length > 0) {
        this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event.id)
      }
    }
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }
    if (this.listOfCategoryIDsMain.length == 0) {
      this.dropdownDateSubCategoryFilterMain = [];
      this.selectedItemsSubCategoryMain = [];
      this.listOfSubCategoryIDsMain = [];
    }
    else
      this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onSelectAllCategoryMain($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }

    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onDeSelectAllCategoryMain($event) {
    this.categoryId = ' ';
    this.listOfCategoryIDsMain = [];
    this.listOfSubCategoryIDsMain = [];
    this.selectedItemsSubCategoryMain = [];
    this.dropdownDateSubCategoryFilterMain = [];
    this.selectedItemsCategoryMain = []
    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  // For Filter Dropdown
  onSelectSubCategoryMain($event) {
    this.listOfSubCategoryIDsMain = this.removeElemetninArray(this.listOfSubCategoryIDsMain, $event.id)
    this.listOfSubCategoryIDsMain.push($event.id);
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectSubCategoryMain($event) {
    if (this.listOfSubCategoryIDsMain != null) {
      if (this.listOfSubCategoryIDsMain.length > 0) {
        this.listOfSubCategoryIDsMain = this.removeElemetninArray(this.listOfSubCategoryIDsMain, $event.id)
      }
    }
    if (this.listOfSubCategoryIDsMain.length > 0) {
      this.subCategoryId = this.listOfSubCategoryIDsMain.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllSubCategoryMain($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDsMain != null) {
        if (this.listOfSubCategoryIDsMain.length > 0) {
          this.listOfSubCategoryIDsMain = this.removeElemetninArray(this.listOfSubCategoryIDsMain, $event[iCounter].id)
          this.listOfSubCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfSubCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectAllSubCategoryMain($event) {
    this.listOfSubCategoryIDsMain = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  // For Edit Dropdown

  onSelectSubCategory($event) {
    this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event.id)
    this.listOfSubCategoryName = this.removeElemetninArray(this.listOfSubCategoryName, $event.itemName)
    this.listOfSubCategoryIDs.push($event.id);
    this.listOfSubCategoryName.push($event.itemName);
  }

  onDeSelectSubCategory($event) {
    if (this.listOfSubCategoryIDs != null) {
      if (this.listOfSubCategoryIDs.length > 0) {
        this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event.id)
      }
    }

    if (this.listOfSubCategoryName != null) {
      if (this.listOfSubCategoryName.length > 0) {
        this.listOfSubCategoryName = this.removeElemetninArray(this.listOfSubCategoryName, $event.itemName)
      }
    }
  }

  onSelectAllSubCategory($event) {
    this.listOfSubCategoryIDs = []
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfSubCategoryName != null) {
        if (this.listOfSubCategoryName.length > 0) {
          this.listOfSubCategoryName = this.removeElemetninArray(this.listOfSubCategoryName, $event[iCounter].itemName)
          this.listOfSubCategoryName.push($event[iCounter].itemName);
        }
        else {
          this.listOfSubCategoryName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllSubCategory($event) {
    this.listOfSubCategoryIDs = [];
    this.listOfSubCategoryName=[];
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDowStateNameCSS = '';
      this.upDowEffectiveDateCSS = '';
      this.upDowRuleCiteCSS = '';
      this.upDowRuleHeadingCSS = '';

      if (sortBy === "stateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-down';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "Name") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = 'fa fa-arrow-down';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "RuleNote") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = 'fa fa-arrow-down';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "stateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-up';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = 'fa fa-arrow-up';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "Name") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = 'fa fa-arrow-up';
        this.upDowRuleHeadingCSS = '';
      }
      else if (sortBy === "RuleNote") {
        this.upDowStateNameCSS = '';
        this.upDowEffectiveDateCSS = '';
        this.upDowRuleCiteCSS = '';
        this.upDowRuleHeadingCSS = 'fa fa-arrow-up';
      }
    }

    this.resetPageNoandSize();
    this.GetContent();
  }

  setDeleteData(id) {
    this.ContentId = id;
  }

  deleteContent(ContentId) {
    this.isloading = true;
    this.crraContentMaster.DeleteContent(this.tokenKey, ContentId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.showMessage("Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.GetContent();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  replaceCommaLine(data) {
    if (data != null) {
      if (data.includes(',')) {
        let dataToArray = data.split(',').map(item => item.trim());
        let list = data.split(",").join("<br />")
        return list
      }
      else {
        return data;
      }
    }
  }

  GetContentById(Content) {
    this.listofContent.forEach(element => {
      element['isEdit'] = false;
    });
    this.templistofContent = JSON.parse(JSON.stringify(this.listofContent));

    Content.isEdit = true;
    Content.effectiveDate = new Date(Content.effectiveDate);
    if (Content.lastAudit != null){
      Content.lastAudit = new Date(Content.lastAudit);
    }
    else{
      Content.lastAudit = this.datePipe.transform(new Date(), 'MM/dd/yyyy')
    }
      

    if (this.MonthList.length == 0) {
      this.getMonths();
    }


    this.isRowEdited = true;
    this.crraContentMaster.GetContentById(this.tokenKey, parseInt(Content.contentId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.listOfStateIds = [];
          this.listOfStateIds.push(result['crracontent'].stateId);

          let categoryId = this.listOfCategoryIDs.join(',');

          this.subCategoryList = result['crracontent'].subcategoryData;
          this.dropdownDateSubCategoryFilter = [];
          this.selectedItemsSubCategory = [];

          this.subCategoryList.forEach(x => {
            this.dropdownDateSubCategoryFilter.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
          });


          let subCategory = this.dropdownDateSubCategoryFilter.filter(x => x.id == this.subCategoryId);
          if (subCategory.length > 0) {
            this.selectedItemsSubCategory.push({ "id": subCategory[0].id, "itemName": subCategory[0].itemName });
          }


          if (this.carrierList.length > 0) {
            this.selectedItemsCarrier = []
            this.listOfCarrierIDs = []
            this.listOfCarrierName = [];
            for (let iCounter = 0; iCounter < result['crracontent'].carrierTypeId.length; iCounter++) {
              let data = this.carrierList.filter(x => x.carrierTypeId == result['crracontent'].carrierTypeId[iCounter].carrierTypeId)[0];
              this.selectedItemsCarrier.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
              this.listOfCarrierIDs.push(data.carrierTypeId)
              this.listOfCarrierName.push(data.carrierName);
            }
          }

          if (this.categoryList.length > 0) {
            this.selectedItemsCategory = [];
            this.listOfCategoryIDs = [];
            this.listOfCategoryName=[];
            result['crracontent'].categoryId.map((x) => {
              let data = this.categoryList.filter(y => y.categoryId == x.categoryId)[0];
              this.selectedItemsCategory.push({ "id": data.categoryId, "itemName": data.categoryName })
              this.listOfCategoryIDs.push(data.categoryId)
              this.listOfCategoryName.push(data.categoryName);
            })
          }

          if (this.subCategoryList.length > 0) {
            this.selectedItemsSubCategory = [];
            this.listOfSubCategoryIDs = [];
            this.listOfSubCategoryName=[];
            result['crracontent'].subCategoryId.map((x) => {
              let data = this.subCategoryList.filter(y => y.subCategoryId == x.subCategoryId)[0];
              if(data!=undefined)
              {
              this.selectedItemsSubCategory.push({ "id": data.subCategoryId, "itemName": data.subCategoryName })
              this.listOfSubCategoryIDs.push(data.subCategoryId)
              this.listOfSubCategoryName.push(data.subCategoryName)
              }
            })
          }

          if (this.MonthList.length > 0) {
            let auditMonthList = [];
            if (result['crracontent'].auditMonth.includes(',')) {
              auditMonthList = result['crracontent'].auditMonth.split(',');
            }
            else if (result['crracontent'].auditMonth !== "") {
              auditMonthList.push(result['crracontent'].auditMonth);
            }

            if (auditMonthList.length > 0) {
              this.listOfMonth = [];
              this.selectedItemsMonth = [];

              for (let iCounter = 0; iCounter < auditMonthList.length; iCounter++) {

                let data = this.MonthList.filter(x => x.name == auditMonthList[iCounter])[0];
                if (data != undefined) {
                  this.selectedItemsMonth.push({ "id": data.id, "itemName": data.name })
                  this.listOfMonth.push(data.name)
                }
              }
            }
          }

        }
      });
  }

  cancelEdit(data) {
    data.isEdit = false;
    this.isRowEdited = false;
    this.listofContent = this.templistofContent;
  }

  saveContent(item) {
    this.saveSession();
    let stateId = this.listOfStateIds.join(',');
    let carrierId = this.listOfCarrierIDs.join(',');
    let categoryId = this.listOfCategoryIDs.join(',');
    let subCategoryId = this.listOfSubCategoryIDs.join(',');
    let MonthList = this.listOfMonth.join(',');
    let carrierName = this.listOfCarrierName.join(',');
    let CategoryName = this.listOfCategoryName.join(',');
    let subCategoryName = this.listOfSubCategoryName.join(',');
    let userId = this.userId;

    if (carrierId == "" || categoryId == "" || subCategoryId == "" || item.notes == "" || item.effectiveDate == "") {
      if (carrierId == "") {
        this.showMessage("Please select carrier", 'warning', 'warning');
      }
      if (categoryId == "") {
        this.showMessage("Please select category", 'warning', 'warning');
      }
      if (subCategoryId == "") {
        this.showMessage("Please select sub category", 'warning', 'warning');
      }
      if (item.notes == "") {
        this.showMessage("Please Enter ruleText", 'warning', 'warning');
      }
      if (item.effectiveDate == "") {
        this.showMessage("Please select effectivedate", 'warning', 'warning');
      }

    }
    else {

      this.listOfMonth = [];

      if (item.tmiNotes == null) {
        item.tmiNotes = "";
      }
      if (item.name == null) {
        item.name = "";
      }
      if (item.ruleNote == null) {
        item.ruleNote = ""
      }

      if (item.notes == null) {
        item.notes = ""
      }

      let value = {
        contentId: item.contentId,
        stateId: stateId,
        carrierTypeId: carrierId,
        categoryId: categoryId,
        subCategoryId: subCategoryId,
        notes: item.notes == "" ? item.notes : item.notes.trim(),
        name: item.name == "" ? item.name : item.name.trim(),
        ruleNote: item.ruleNote == "" ? item.ruleNote : item.ruleNote.trim(),
        effectiveDate: this.datePipe.transform(item.effectiveDate, 'yyyy-MM-dd hh:mm:ss'),
        tmiNotes: item.tmiNotes == "" ? item.tmiNotes : item.tmiNotes.trim(),
        lastAudit: this.datePipe.transform(item.lastAudit, 'yyyy-MM-dd hh:mm:ss'),
        auditMonth: MonthList,
        stateName: item.stateName,
        carrierTypeName: carrierName,
        CategoryTypeName: CategoryName,
        subCategoryTypeName: subCategoryName,
        userId: userId
      }
      this.crraContentMaster.ContentAddUpdate(this.tokenKey, value).subscribe(
        result => {
          if (result['status'] == 'Success') {
            if (result['message'] != "") {
              this.showMessage(result['message'], 'Warning', 'warning')
            } else {
              if (item.contentId > 0) {
                this.showMessage('Content Update successfully', 'Success', 'success');
                this.GetContent();
              }
              else
                this.showMessage('Content saved successfully', 'Success', 'success');
              this.router.navigate(['/admin/' + this.SelectedTool + '/content-list']);
            }
          }
          else {
            this.showMessage(result['status'], 'Error', 'error');
          }
        },
        error => {
          this.showMessage(error, 'Error', 'error');
        }
      );
      item.isEdit = false;
      this.isRowEdited = false;

    }
  }

  GetContent() {
    this.isloading = true;
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }

    this.categoryId = ' ';
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }

    this.subCategoryId = ' ';
    if (this.listOfSubCategoryIDsMain.length > 0) {
      this.subCategoryId = this.listOfSubCategoryIDsMain.join(',')
    }

    this.auditMonth = '';
    if (this.listOfMonth1.length > 0) {
      this.auditMonth = this.listOfMonth1.join(',')
    }

    if (this.Note == "null") this.Note = " ";
    this.crraContentMaster.GetAllContent(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, " ", this.categoryId, this.subCategoryId, this.OrderBy, this.auditMonth).subscribe(
      data => {
        this.isloading = false;
        this.listofContent = data['crracontent'];

        this.listofContent.forEach(element => {
          element['isEdit'] = false;
        });

        if (this.listofContent != null) {
          if (this.listofContent.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }


  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  saveSession() {
    sessionStorage.setItem('ContentSaveFilterPageNo', this.PageNumber.toString());
    sessionStorage.setItem('ContentSaveFilterOrderBy', this.OrderBy);
    sessionStorage.setItem('ContentSaveFilterIsAscending', this.isAscending ? 'true' : 'false');
    sessionStorage.setItem('ContentSaveFilterStateId', JSON.stringify(this.listOfStateIds));
    sessionStorage.setItem('ContentSaveFilterCategoryId', JSON.stringify(this.listOfCategoryIDsMain));
    sessionStorage.setItem('ContentSaveFilterlistOfMonth', JSON.stringify(this.listOfMonth1));
  }

  clearSession() {
    sessionStorage.removeItem('ContentSaveFilterPageNo');
    sessionStorage.removeItem('ContentSaveFilterOrderBy');
    sessionStorage.removeItem('ContentSaveFilterIsAscending');
    sessionStorage.removeItem('ContentSaveFilterStateId');
    sessionStorage.removeItem('ContentSaveFilterCategoryId');
    sessionStorage.removeItem('ContentSaveFilterlistOfMonth');
  }

  setSaveFilter() {
    if (sessionStorage.getItem('ContentSaveFilterPageNo') != null && sessionStorage.getItem('ContentSaveFilterPageNo') != '' && sessionStorage.getItem('ContentSaveFilterPageNo') != 'undefined') {
      this.PageNumber = +sessionStorage.getItem('ContentSaveFilterPageNo');
    }
    if (sessionStorage.getItem('ContentSaveFilterOrderBy') != null && sessionStorage.getItem('ContentSaveFilterOrderBy') != '' && sessionStorage.getItem('ContentSaveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('ContentSaveFilterOrderBy');
    }
    if (sessionStorage.getItem('ContentSaveFilterIsAscending') != null && sessionStorage.getItem('ContentSaveFilterIsAscending') != '' && sessionStorage.getItem('ContentSaveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('ContentSaveFilterIsAscending') == 'false' ? false : true;
      if (this.isAscending) {
        this.ContentSessionSort(this.OrderBy.replace('DESC', '').trim());
      } else {
        this.ContentSessionSort(this.OrderBy.replace('DESC', '').trim());
      }
    }

    if (sessionStorage.getItem('ContentSaveFilterStateId') != null && sessionStorage.getItem('ContentSaveFilterStateId') != '' && sessionStorage.getItem('ContentSaveFilterStateId') != 'undefined') {
      this.listOfStateIds = JSON.parse(sessionStorage.getItem('ContentSaveFilterStateId'));
    }
    if (sessionStorage.getItem('ContentSaveFilterCategoryId') != null && sessionStorage.getItem('ContentSaveFilterCategoryId') != '' && sessionStorage.getItem('ContentSaveFilterCategoryId') != 'undefined') {
      this.listOfCategoryIDsMain = JSON.parse(sessionStorage.getItem('ContentSaveFilterCategoryId'));
    }

    if (sessionStorage.getItem('ContentSaveFilterlistOfMonth') != null && sessionStorage.getItem('ContentSaveFilterlistOfMonth') != '' && sessionStorage.getItem('ContentSaveFilterlistOfMonth') != 'undefined') {
      this.listOfMonth1 = JSON.parse(sessionStorage.getItem('ContentSaveFilterlistOfMonth'));
    }

    this.clearSession();
  }

  ContentSessionSort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "stateName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
      }

    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "stateName") {
        this.upDowRuleTextCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
      }

    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllCategory($event) {
    this.categoryId = ' ';
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }

    this.resetPageNoandSize();
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onFilterDeSelectAllCategory($event) {
    this.categoryId = ' ';
    if ($event.length > 0) {
      for (let iCounter = 0; iCounter < $event.length; iCounter++) {
        if (this.listOfCategoryIDsMain != null) {
          if (this.listOfCategoryIDsMain.length > 0) {
            this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          }
        }
      }
    } else {
      this.dropdownDateSubCategoryFilterMain = [];
      this.listOfCategoryIDsMain = [];
      this.selectedItemsCategoryMain = [];
      this.selectedItemsSubCategoryMain = [];
    }

    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
      this.GetSubCategoryByCategoryId(this.categoryId);
    }
    else {
      this.dropdownDateSubCategoryFilterMain = [];
      this.listOfCategoryIDsMain = [];
      this.selectedItemsSubCategoryMain = [];
    }
    this.GetContent();
  }

  onFilterSelectAllSubCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDsMain != null) {
        if (this.listOfSubCategoryIDsMain.length > 0) {
          this.listOfSubCategoryIDsMain = this.removeElemetninArray(this.listOfSubCategoryIDsMain, $event[iCounter].id)
          this.listOfSubCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfSubCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllSubCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDsMain != null) {
        if (this.listOfSubCategoryIDsMain.length > 0) {
          this.listOfSubCategoryIDsMain = this.removeElemetninArray(this.listOfSubCategoryIDsMain, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  getMonths() {
    this.MonthList = this.trfaMonthsService.getMonths();
    this.dropdownMonthFilter = [];
    this.selectedItemsMonth = [];
    this.MonthList.forEach(x => {
      this.dropdownMonthFilter.push({ "id": x.id, "itemName": x.name })
    });
  }

  onDeSelectMonthAll($event) {
    this.selectedItemsMonth = [];
    this.listOfMonth = [];
  }

  onSelectMonth($event) {
    this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event.itemName)
    this.listOfMonth.push($event.itemName);
  }

  onDeSelectMonth($event) {
    if (this.listOfMonth != null) {
      if (this.listOfMonth.length > 0) {
        this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event.itemName)
      }
    }
  }

  onSelectAllMonth($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonth != null) {
        if (this.listOfMonth.length > 0) {
          this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event[iCounter].itemName)
          this.listOfMonth.push($event[iCounter].itemName);
        }
        else {
          this.listOfMonth.push($event[iCounter].itemName);
        }
      }
    }
  }

  getMonths1() {
    this.MonthList1 = this.trfaMonthsService.getMonths();
    this.dropdownMonthFilter1 = [];
    this.selectedItemsMonth1 = [];
    this.MonthList1.forEach(x => {
      this.dropdownMonthFilter1.push({ "id": x.id, "itemName": x.name })
    });
  }

  onSelectMonth1($event) {
    this.listOfMonth1=[];
    this.listOfMonth1.push($event.itemName);
    this.resetPageNoandSize();
    this.GetContent();
  }

  onDeSelectMonth1($event) {
    this.listOfMonth1=[];
    this.resetPageNoandSize();
    this.GetContent();
  }

  onSelectAllMonth1($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonth1 != null) {
        if (this.listOfMonth1.length > 0) {
          this.listOfMonth1 = this.removeElemetninArray(this.listOfMonth1, $event[iCounter].itemName)
          this.listOfMonth1.push($event[iCounter].itemName);
        }
        else {
          this.listOfMonth1.push($event[iCounter].itemName);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();

  }

  onDeSelectMonthAll1($event) {
    this.listOfMonth1 = [];
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterSelectAllMonth($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonth1 != null) {
        if (this.listOfMonth1.length > 0) {
          this.listOfMonth1 = this.removeElemetninArray(this.listOfMonth1, $event[iCounter].itemName)
          this.listOfMonth1.push($event[iCounter].itemName);
        }
        else {
          this.listOfMonth1.push($event[iCounter].itemName);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  onFilterDeSelectAllMonth($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonth1 != null) {
        if (this.listOfMonth1.length > 0) {
          this.listOfMonth1 = this.removeElemetninArray(this.listOfMonth1, $event[iCounter].itemName)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetContent();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }
}


