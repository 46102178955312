import { Component, OnInit, Input } from '@angular/core';
import { ActionItemSearchModel } from 'src/Models/ActionItemSearchModel';

@Component({
  selector: 'app-action-item-details',
  templateUrl: './action-item-details.component.html',
  styleUrls: ['./action-item-details.component.css']
})
export class ActionItemDetailsComponent implements OnInit {
  @Input() actionItem = new ActionItemSearchModel();
  @Input() stateArray: any = [];
  @Input() carrierCategoryArray: any = [];  
  @Input() isAccordianShow: any = [];
  displayState: string;
  constructor() {   
    
  }

  ngOnInit() {    
    
  }

}
