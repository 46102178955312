export class TracAlertsModel {
    actionId:number;
    deadlineDate: string;      
    subject: string;  
    description: string;
    name:string;    
    email:string;
    userId:number; 
    isSendMail:boolean;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    createdUser: string;
    modifiedUser: string;
    totalRowCount: string;   
    productId:number;
    stateName:string;
    actionStateCount:number;    
    alertText:string;
}