export class ContactsRate911Model {
    Id?: number;
    rateID?: number;
    state?: number;
    county?: number;
    city?: number;
    fundJurisdLevel?: string;
    authorityBoardName?: string;
    contactTitle?: string;
    contactFirstName?: string;
    contactLastName?: string;
    contactOrganizationName?: string;
    contactStreetAddress?: string;
    contactCity?: string;
    contactStateName?: string;
    contactZipAddress?: string;
    contactPOBox?: string;
    contactPhone?: string;
    contactEmail?: string;
    contactFax?: string;
    rateConfirmed?: string;
    lastContactDate?: string;
    confirmationDate?: string;
    remittanceInfo?: string;
    websiteAddress?: string;
    notes?: string;
    notSourceTypees?: string;
    countyName?: string;
    stateName?: string;
    cityName?: string;
    totalRecordCount: number;
}

export class FilterContactsRate911Model {
    rateId?: number;
    pageNumber?: number;
    pageSize?: number;
    countyId: string;
    filterText: string;
    orderBy: string;
    module:string;
}