import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientInformationModel } from 'src/Models/ClientInformationModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompletedNew, getIncompletedStepNew } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-additional-company-question',
  templateUrl: './additional-company-question.component.html',
  styleUrls: ['./additional-company-question.component.css']
})
export class AdditionalCompanyQuestionComponent implements OnInit {

  CompanyProfileInformationForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;
  baseRateCount: number;
  baseRateList: any;
  clientInformationId: number = 0;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/

  listOfBillingProviderIDs = [];
  BillingProviderList: any[] = [];
  selectedItemsBillingProvider = [];
  dropdownBillingProviderFilter = [];
  dropdownSettingsBillingProviderFilter = {};

  listOfTaxRatingProviderIDs = [];
  TaxRatingProviderList: any[] = [];
  selectedItemsTaxRatingProvider = [];
  dropdownTaxRatingProviderFilter = [];
  dropdownSettingsTaxRatingProviderFilter = {};

  listOfTaxComplianceIDs = [];
  TaxComplianceList: any[] = [];
  selectedItemsTaxCompliance = [];
  dropdownTaxComplianceFilter = [];
  dropdownSettingsTaxComplianceFilter = {};

  listOfTaxPermitIDs = [];
  TaxPermitList: any[] = [];
  selectedItemsTaxPermit = [];
  dropdownTaxPermitFilter = [];
  dropdownSettingsTaxPermitFilter = {};

  isEnableBillingProvider: boolean = false;
  isEnableTaxRatingProvider: boolean = false;
  isEnableCurrentlyFillingForYou: boolean = false

  clientInfoModel: ClientInformationModel = {
    clientInformationId: 0,
    haveABillingProvider: 0,
    billingProvider: '',
    haveATaxRatingProvider: 0,
    taxRatingProvider: '',
    currentlyFillingTaxandcompliance: 0,
    whoFillingForYou: '',
    baseRateList: [],
    etcTypeProvider:0
  };

  isCompanyProfileDetailsCompleted: boolean;
  isAddCompQueCompleted: boolean;
  isStateSelectionCompleted: boolean;
  isAttechedDetailsCompleted: boolean;
  isNotes: boolean;
  currentActiveTab: number = 1;
  url: string;
  tabId: number = 0;

  mainUrl: string;
  subUrl: string;

  dropdownVoipProviderFilter = [];
  selectedItemsVoipProvider = [];
  dropdownSettingsVoipProviderFilter = {};

  IsvoipTypeProvider: boolean = false;
  voipTypeProvider: any;
  IsSelectedVoip: boolean = false;

  dropdownWirelessProviderFilter = [];
  selectedItemsWirelessProvider = [];
  dropdownSettingsWirelessProviderFilter = {};

  IsWirelessTypeProvider: boolean = false;
  WirelessTypeProvider: any;
  IsSelectedWireless: boolean = false;

  companyName: string;
  companyId: number;
  priceQuoteId: number;
  otherValue:string;
  IsSelectedOther:boolean=false;
  dropdownETCProviderFilter = [];
  selectedItemsETCProvider = [];
  dropdownSettingsETCProviderFilter = {};

  IsETCTypeProvider: boolean = false;
  etcTypeProvider: any;
  IsSelectedETC: boolean = false;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute, private globle: Globle,
    private router: Router, private PricingQuotesMaster: PricingQuotesService, private toastr: ToastrService, private titleService: Title) {
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Company Profile');
    this.companyId = +sessionStorage.getItem('CompanyId');
    this.companyName = sessionStorage.getItem('CompanyName');


    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.clientInformationId = params['id'];
        }
      }
    });

    this.mainUrl = '/View/';
    if (this.clientInformationId != null) {
      this.GetProgressStepDetailsById(this.clientInformationId);
      this.subUrl = getIncompletedStepNew(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isCompanyProfileDetailsCompleted = null;
      this.globle.cApp.statusModel.isAddCompQueCompleted = null;
      this.globle.cApp.statusModel.isStateSelectionCompleted = null;
      this.globle.cApp.statusModel.isAttechedDetailsCompleted = null;
      this.globle.cApp.statusModel.isNotes = null;
      this.subUrl = getIncompletedStepNew(this.globle.cApp.statusModel);
    }
  }

  ngOnInit() {

    $(function () {
      $('#tooltip').click(function () {
        var el = $('.tooltiptext');
        el.css('visibility') == 'visible' ?
          el.css('visibility', 'hidden') :
          el.css('visibility', 'visible');
      });
    });

    this.dropdownSettingsBillingProviderFilter = {
      singleSelection: true,
      text: "Select",
      //enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsTaxRatingProviderFilter = {
      singleSelection: true,
      text: "Select",
      //enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsTaxComplianceFilter = {
      singleSelection: true,
      text: "Select",
      //enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsTaxPermitFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsVoipProviderFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsWirelessProviderFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsETCProviderFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.BindDropdown();


    this.CompanyProfileInformationForm = this._fb.group({
      clientInformationId: this.clientInformationId,
      haveABillingProvider: [false, Validators.required],
      billingProvider: ['', Validators.required],
      haveATaxRatingProvider: [false, Validators.required],
      taxRatingProvider: ['', Validators.required],
      currentlyFillingTaxandcompliance: [false, Validators.required],
      whoFillingForYou: ['', Validators.required],
    })

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetClientInformationById(params['id']);
        }
      }
    });
    //this.getPriceQuoteListByCompanyId(this.companyId)
  }


  GetClientInformationById(clientInformationId: number) {
    this.blockDocument();
    this.PricingQuotesMaster.GetClientInformationById(this.tokenKey, parseInt(clientInformationId.toString()),this.companyId, true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.unblockDocument();
          this.clientInfoModel = result['clientInformation'];
          this.CompanyProfileInformationForm.patchValue({
            clientInformationId: result['clientInformation'].clientInformationId,
            billingProvider: result['clientInformation'].billingProvider,
            taxRatingProvider: result['clientInformation'].taxRatingProvider,
            whoFillingForYou: result['clientInformation'].whoFillingForYou,
          });
          this.otherValue=result['clientInformation'].otherTypeValue;

          this.selectedItemsBillingProvider = [];
          if (result['clientInformation'].haveABillingProvider != null) {
            this.isEnableBillingProvider = result['clientInformation'].haveABillingProvider;
            if (result['clientInformation'].haveABillingProvider == true) {
              this.selectedItemsBillingProvider.push({ "id": 1, "itemName": "Yes" })
            }
            else { this.selectedItemsBillingProvider.push({ "id": 2, "itemName": "No" }) }
          }

          this.selectedItemsTaxRatingProvider = [];
          if (result['clientInformation'].haveATaxRatingProvider != null) {
            this.isEnableTaxRatingProvider = result['clientInformation'].haveATaxRatingProvider;
            if (result['clientInformation'].haveATaxRatingProvider == true) {
              this.selectedItemsTaxRatingProvider.push({ "id": 1, "itemName": "Yes" })
            }
            else { this.selectedItemsTaxRatingProvider.push({ "id": 2, "itemName": "No" }) }
          }

          this.selectedItemsTaxCompliance = [];
          if (result['clientInformation'].currentlyFillingTaxandcompliance != null) {
            this.isEnableCurrentlyFillingForYou = result['clientInformation'].currentlyFillingTaxandcompliance;
            if (result['clientInformation'].currentlyFillingTaxandcompliance == true) {
              this.selectedItemsTaxCompliance.push({ "id": 1, "itemName": "Yes" })
            }
            else { this.selectedItemsTaxCompliance.push({ "id": 2, "itemName": "No" }) }
          }

          // this.selectedItemsTaxPermit = [];
          // if (result['clientInformation'].needYouTaxPermits != null) {
          //   let data = this.dropdownTaxPermitFilter.filter(x => x.id == result['clientInformation'].needYouTaxPermits)[0];
          //   if (data != null) {
          //     this.selectedItemsTaxPermit.push({ "id": data.id, "itemName": data.itemName })
          //   }
          // }

          this.selectedItemsVoipProvider = [];
          if (result['clientInformation'].voipTypeProvider != null) {
            let data = this.dropdownVoipProviderFilter.filter(x => x.id == result['clientInformation'].voipTypeProvider)[0];
            if (data != null) {
              this.selectedItemsVoipProvider.push({ "id": data.id, "itemName": data.itemName })
            }
          }

          this.selectedItemsWirelessProvider = [];
          if (result['clientInformation'].wirelessTypeProvider != null) {
            let data = this.dropdownWirelessProviderFilter.filter(x => x.id == result['clientInformation'].wirelessTypeProvider)[0];
            if (data != null) {
              this.selectedItemsWirelessProvider.push({ "id": data.id, "itemName": data.itemName })
            }
          }

          this.selectedItemsETCProvider = [];
          if (result['clientInformation'].etcTypeProvider != null) {
            let data = this.dropdownETCProviderFilter.filter(x => x.id == result['clientInformation'].etcTypeProvider)[0];
            if (data != null) {
              this.selectedItemsETCProvider.push({ "id": data.id, "itemName": data.itemName })
            }
          }

          this.otherValue=result['clientInformation'].otherTypeValue;

          if (result['clientInformation'].baseRateList != null) {
            this.baseRateCount = result['clientInformation'].baseRateList.length;
            this.baseRateList = result['clientInformation'].baseRateList;
            // const el = document.getElementById('VoipTypes');
            // const elWireless = document.getElementById('WirelessType');
            for (let i = 0; i < this.baseRateList.length; i++) {
              if (this.baseRateList[i].baseRate == "VOIP") {
                if (this.baseRateList[i].isSelected == true) {
                  this.IsSelectedVoip = true;
                  // el.style.display = 'block';
                }
                else {
                  this.IsSelectedVoip = false;
                  // el.style.display = 'none';
                }
              }

              if (this.baseRateList[i].baseRate == "Wireless") {
                if (this.baseRateList[i].isSelected == true) {
                  this.IsSelectedWireless = true;
                  // elWireless.style.display = 'block';
                }
                else {
                  this.IsSelectedWireless = false;
                  // elWireless.style.display = 'none';
                }
              }

              if (this.baseRateList[i].baseRate == "ETC") {
                if (this.baseRateList[i].isSelected == true) {
                  this.IsSelectedETC = true;
                }
                else {
                  this.IsSelectedETC = false;
                }
              }

              
              if (this.baseRateList[i].baseRate == "OTHER") {
                if (this.baseRateList[i].isActiveServiceSelected) {
                  this.IsSelectedOther = true;
                  // elWireless.style.display = 'block';
                }
                else {
                  this.IsSelectedOther = false;
                  // elWireless.style.display = 'none';
                }
              }
            }
          }
        }
      });
  }

  getPriceQuoteListByCompanyId(companyId: number) {
    this.blockDocument();
    this.PricingQuotesMaster.getPriceQuoteListByCompanyId(this.tokenKey, companyId, false).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.unblockDocument();
          this.clientInfoModel = result['pricingQuotes'][0];
          if(result['pricingQuotes'].length>0)
          {
          this.CompanyProfileInformationForm.patchValue({
            clientInformationId: result['pricingQuotes'][0].clientInformationId,
            billingProvider: result['pricingQuotes'][0].billingProvider,
            taxRatingProvider: result['pricingQuotes'][0].taxRatingProvider,
            whoFillingForYou: result['pricingQuotes'][0].whoFillingForYou,
          });
        }
          this.otherValue=result['pricingQuotes'][0].otherTypeValue;
          this.priceQuoteId = result['pricingQuotes'][0].priceQuoteId;

          this.selectedItemsBillingProvider = [];
          if (result['pricingQuotes'][0].haveABillingProvider != null) {
            this.isEnableBillingProvider = result['pricingQuotes'][0].haveABillingProvider;
            if (result['pricingQuotes'][0].haveABillingProvider == true) {
              this.selectedItemsBillingProvider.push({ "id": 1, "itemName": "Yes" })
            }
            else { this.selectedItemsBillingProvider.push({ "id": 2, "itemName": "No" }) }
          }

          this.selectedItemsTaxRatingProvider = [];
          if (result['pricingQuotes'][0].haveATaxRatingProvider != null) {
            this.isEnableTaxRatingProvider = result['pricingQuotes'][0].haveATaxRatingProvider;
            if (result['pricingQuotes'][0].haveATaxRatingProvider == true) {
              this.selectedItemsTaxRatingProvider.push({ "id": 1, "itemName": "Yes" })
            }
            else { this.selectedItemsTaxRatingProvider.push({ "id": 2, "itemName": "No" }) }
          }

          this.selectedItemsTaxCompliance = [];
          if (result['pricingQuotes'][0].currentlyFillingTaxandcompliance != null) {
            this.isEnableCurrentlyFillingForYou = result['pricingQuotes'][0].currentlyFillingTaxandcompliance;
            if (result['pricingQuotes'][0].currentlyFillingTaxandcompliance == true) {
              this.selectedItemsTaxCompliance.push({ "id": 1, "itemName": "Yes" })
            }
            else { this.selectedItemsTaxCompliance.push({ "id": 2, "itemName": "No" }) }
          }

          // this.selectedItemsTaxPermit = [];
          // if (result['pricingQuotes'][0].needYouTaxPermits != null) {
          //   let data = this.dropdownTaxPermitFilter.filter(x => x.id == result['pricingQuotes'][0].needYouTaxPermits)[0];
          //   if (data != null) {
          //     this.selectedItemsTaxPermit.push({ "id": data.id, "itemName": data.itemName })
          //   }
          // }

          this.selectedItemsVoipProvider = [];
          if (result['pricingQuotes'][0].voipTypeProvider != null) {
            let data = this.dropdownVoipProviderFilter.filter(x => x.id == result['pricingQuotes'][0].voipTypeProvider)[0];
            if (data != null) {
              this.selectedItemsVoipProvider.push({ "id": data.id, "itemName": data.itemName })
            }
          }

          this.selectedItemsWirelessProvider = [];
          if (result['pricingQuotes'][0].wirelessTypeProvider != null) {
            let data = this.dropdownWirelessProviderFilter.filter(x => x.id == result['pricingQuotes'][0].wirelessTypeProvider)[0];
            if (data != null) {
              this.selectedItemsWirelessProvider.push({ "id": data.id, "itemName": data.itemName })
            }
          }

          this.selectedItemsETCProvider = [];
          if (result['pricingQuotes'][0].etcTypeProvider != null) {
            let data = this.dropdownETCProviderFilter.filter(x => x.id == result['pricingQuotes'][0].etcTypeProvider)[0];
            if (data != null) {
              this.selectedItemsETCProvider.push({ "id": data.id, "itemName": data.itemName })
            }
          }

          if (result['pricingQuotes'][0].baseRateList != null) {
            this.baseRateCount = result['pricingQuotes'][0].baseRateList.length;
            this.baseRateList = result['pricingQuotes'][0].baseRateList;
            // const el = document.getElementById('VoipTypes');
            // const elWireless = document.getElementById('WirelessType');
            for (let i = 0; i < this.baseRateList.length; i++) {
              if (this.baseRateList[i].baseRate == "VOIP") {
                if (this.baseRateList[i].isSelected == true || this.baseRateList[i].isActiveServiceSelected) {
                  this.IsSelectedVoip = true;
                  // el.style.display = 'block';
                }
                else {
                  this.IsSelectedVoip = false;
                  // el.style.display = 'none';
                }
              }

              if (this.baseRateList[i].baseRate == "Wireless") {
                if (this.baseRateList[i].isSelected == true || this.baseRateList[i].isActiveServiceSelected) {
                  this.IsSelectedWireless = true;
                  // elWireless.style.display = 'block';
                }
                else {
                  this.IsSelectedWireless = false;
                  // elWireless.style.display = 'none';
                }
              }

              if (this.baseRateList[i].baseRate == "ETC") {
                if (this.baseRateList[i].isSelected == true ) {
                  this.IsSelectedETC = true;
                  // elWireless.style.display = 'block';
                }
                else {
                  this.IsSelectedETC = false;
                  // elWireless.style.display = 'none';
                }
              }

              if (this.baseRateList[i].baseRate == "OTHER") {
                if (this.baseRateList[i].isActiveServiceSelected) {
                  this.IsSelectedOther = true;
                  // elWireless.style.display = 'block';
                }
                else {
                  this.IsSelectedOther = false;
                  // elWireless.style.display = 'none';
                }
              }
            }
          }
        }
      });
  }

  save(clientForm: any) {
    var isbillingprovider = false, istaxRatingProvider = false, isTaxandCompliance = false;

    this.isSubmit = true;
    let data = clientForm.value;
    if (!clientForm.valid)
      return;

    if (this.isEnableBillingProvider && !data.billingProvider.trim()) {
      return;
    }

    if (this.isEnableTaxRatingProvider && !data.taxRatingProvider.trim()) {
      return;
    }

    if (this.isEnableCurrentlyFillingForYou && !data.whoFillingForYou.trim()) {
      return;
    }

    if (this.CompanyProfileInformationForm.invalid) {
      this.globle.cApp.statusModel.isAddCompQueCompleted = false;
      // sessionStorage.setItem('isAddCompQueCompleted', 'false');
    }

    if (this.isEnableBillingProvider && (data.billingProvider == null || data.billingProvider == '' || data.billingProvider == undefined))
      return;

    if (this.isEnableTaxRatingProvider && (data.taxRatingProvider == null || data.taxRatingProvider == '' || data.taxRatingProvider == undefined))
      return;

    if (this.isEnableCurrentlyFillingForYou && (data.whoFillingForYou == null || data.whoFillingForYou == '' || data.whoFillingForYou == undefined))
      return;

    if (this.selectedItemsBillingProvider.length > 0) {
      isbillingprovider = this.selectedItemsBillingProvider[0]['id'] == 1 ? true : false
    }
    else
      return;

    if (this.selectedItemsTaxRatingProvider.length > 0) {
      istaxRatingProvider = this.selectedItemsTaxRatingProvider[0]['id'] == 1 ? true : false
    }
    else
      return;


    if (this.selectedItemsTaxCompliance.length > 0) {
      isTaxandCompliance = this.selectedItemsTaxCompliance[0]['id'] == 1 ? true : false
    }
    else
      return;


    // if (this.selectedItemsTaxPermit.length > 0) {
    //   // isTaxPermit = this.selectedItemsTaxPermit[0]['id'] == 1 ? true : false
    // }
    // else
    //   return;


    if (this.baseRateList.filter(x => x.isSelected == true).length == 0 && this.baseRateList.filter(x => x.isActiveServiceSelected == true).length == 0) {
      this.showMessage('Please select Type of Service Provided', 'Error', 'error');
      return;
    }

    if (this.baseRateList.filter(x => x.isActiveServiceSelected == true).length == 0) {
      this.showMessage('Please select at least one Active Service', 'Error', 'error');
      return;
    }

    if (this.IsSelectedVoip == true) {
      if (this.selectedItemsVoipProvider.length == 0) {
        return;
      }
      else {
        this.voipTypeProvider = this.selectedItemsVoipProvider[0]['id'];
      }
    }

    if (this.IsSelectedWireless == true) {
      if (this.selectedItemsWirelessProvider.length == 0) {
        return;
      }
      else {
        this.WirelessTypeProvider = this.selectedItemsWirelessProvider[0]['id'];
      }
    }

    if (this.IsSelectedETC == true) {
      if (this.selectedItemsETCProvider.length == 0) {
        return;
      }
      else {
        this.etcTypeProvider = this.selectedItemsETCProvider[0]['id'];
      }
    }

    let value = {
      clientInformationId: data.clientInformationId,
      haveABillingProvider: isbillingprovider,
      billingProvider: data.billingProvider != null ? data.billingProvider.trim() : data.billingProvider,
      haveATaxRatingProvider: istaxRatingProvider,
      taxRatingProvider: data.taxRatingProvider != null ? data.taxRatingProvider.trim() : data.taxRatingProvider,
      currentlyFillingTaxandcompliance: isTaxandCompliance,
      whoFillingForYou: data.whoFillingForYou != null ? data.whoFillingForYou.trim() : data.whoFillingForYou,
      // needYouTaxPermits: this.selectedItemsTaxPermit[0]['id'],
      voipTypeProvider: this.voipTypeProvider,
      baseRateList: this.baseRateList,
      createdBy: this.userID,
      isFront: true,
      wirelessTypeProvider: this.WirelessTypeProvider,
      priceQuoteId: this.priceQuoteId,
      isAdditionalCompQueCompleted: true,
      etcTypeProvider: this.etcTypeProvider,
      otherTypeValue:this.otherValue
    }
    this.blockDocument();
    this.PricingQuotesMaster.AdditionalCompanyQuestionsAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isAddCompQueCompleted = true;
          this.unblockDocument();
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (result['clientInformationId'] != 0) {
              // let navigation = '/admin/' + this.SelectedTool + '/state-selection/' + result['clientInformationId'];
              let navigation = '/View/state-selection/' + this.clientInformationId;
              this.router.navigate([navigation]);
            }
          }
        }
        else {
          this.unblockDocument();
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  previousStep() {
    // let navigation = '/admin/' + this.SelectedTool + '/company-info/' + this.clientInformationId;
    let navigation = '/View/company-info/' + this.clientInformationId;
    this.router.navigate([navigation]);
  }

  nextStep() {
    // let navigation = '/admin/' + this.SelectedTool + '/state-selection';
    let navigation = '/View/state-selection';
    this.router.navigate([navigation]);
  }

  SelectedProductCheck(item) {
    var data = item;

    if(item.isActiveServiceSelected==null)
      {
        item.isActiveServiceSelected=false;
      }
      
    if (item.baseRate == "VOIP") {
      
      // const el = document.getElementById('VoipTypes');
      if (item.isSelected == false && item.isActiveServiceSelected==false) {
        this.selectedItemsVoipProvider = [];
        this.voipTypeProvider = null;
        this.IsSelectedVoip = false;
        // el.style.display = 'none';
      }
      else {
        this.IsSelectedVoip = true;
        // el.style.display = 'block';
      }

      if (this.IsSelectedVoip == false) {
        const el = document.getElementById('lblVoipRequired');
        if (el != undefined) {
          el.style.display = 'none';
        }
      }
      else {
        const el = document.getElementById('lblVoipRequired');
        if (el != undefined) {
          el.style.display = 'block';
        }
  
        if (this.clientInfoModel != null && this.clientInfoModel != undefined) {
          this.selectedItemsVoipProvider = [];
          if (this.clientInfoModel.voipTypeProvider != null) {
            let data = this.dropdownVoipProviderFilter.filter(x => x.id == this.clientInfoModel.voipTypeProvider)[0];
            if (data != null) {
              this.selectedItemsVoipProvider.push({ "id": data.id, "itemName": data.itemName })
            }
          }
        }
      }

    }

    if (item.baseRate == "OTHER") {
      if (item.isActiveServiceSelected==false) {
        this.IsSelectedOther = false;
        this.otherValue='';
      }
      else {
        this.IsSelectedOther = true;
      }
    }

    if (item.baseRate == "Wireless") {
      // const elWireless = document.getElementById('WirelessType');
      if (item.isSelected == false  && item.isActiveServiceSelected==false) {
        this.selectedItemsWirelessProvider = [];
        this.WirelessTypeProvider = null;
        this.IsSelectedWireless = false;
        // elWireless.style.display = 'none';
      }
      else {
        this.IsSelectedWireless = true;
        // elWireless.style.display = 'block';
      }

      if (this.IsSelectedWireless == false) {
        const el = document.getElementById('lblWirelessRequired');
        if (el != undefined) {
          el.style.display = 'none';
        }
      }
      else {
        const el = document.getElementById('lblWirelessRequired');
        if (el != undefined) {
          el.style.display = 'block';
        }
  
        if (this.clientInfoModel != null && this.clientInfoModel != undefined) {
          this.selectedItemsWirelessProvider = [];
          if (this.clientInfoModel.wirelessTypeProvider != null) {
            let data = this.dropdownWirelessProviderFilter.filter(x => x.id == this.clientInfoModel.wirelessTypeProvider)[0];
            if (data != null) {
              this.selectedItemsWirelessProvider.push({ "id": data.id, "itemName": data.itemName })
            }
          }
        }
      }
    }  

    if (item.baseRate == "ETC") {
      if (item.isSelected == false) {
        this.selectedItemsETCProvider = [];
        this.etcTypeProvider = 0;
        this.IsSelectedETC = false;
      }
      else {
        this.IsSelectedETC = true;
      }


      if (this.IsSelectedETC == false) {
        const el = document.getElementById('lblETCRequired');
        if (el != undefined) {
          el.style.display = 'none';
        }
      }
      else {
        const el = document.getElementById('lblETCRequired');
        if (el != undefined) {
          el.style.display = 'block';
        }
  
        if(this.clientInfoModel!=null && this.clientInfoModel!=undefined)
          {
            this.selectedItemsETCProvider = [];
            if (this.clientInfoModel.etcTypeProvider != null) {
              let data = this.dropdownETCProviderFilter.filter(x => x.id == this.clientInfoModel.etcTypeProvider)[0];
              if (data != null) {
                this.selectedItemsETCProvider.push({ "id": data.id, "itemName": data.itemName })
              }
            }
          }
      }
    }
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  BindDropdown() {
    this.dropdownBillingProviderFilter = [];
    this.dropdownBillingProviderFilter.push({ "id": 1, "itemName": "Yes" })
    this.dropdownBillingProviderFilter.push({ "id": 2, "itemName": "No" })
    this.dropdownTaxRatingProviderFilter = [];
    this.dropdownTaxRatingProviderFilter.push({ "id": 1, "itemName": "Yes" })
    this.dropdownTaxRatingProviderFilter.push({ "id": 2, "itemName": "No" })
    this.dropdownTaxComplianceFilter = [];
    this.dropdownTaxComplianceFilter.push({ "id": 1, "itemName": "Yes" })
    this.dropdownTaxComplianceFilter.push({ "id": 2, "itemName": "No" })
    this.dropdownTaxPermitFilter = [];
    this.dropdownTaxPermitFilter.push({ "id": 1, "itemName": "Yes" })
    this.dropdownTaxPermitFilter.push({ "id": 2, "itemName": "No" })
    this.dropdownTaxPermitFilter.push({ "id": 3, "itemName": "Not Sure" })
    this.dropdownVoipProviderFilter = [];
    this.dropdownVoipProviderFilter.push({ "id": 1, "itemName": "Interconnected VoIP - Nomadic" })
    this.dropdownVoipProviderFilter.push({ "id": 2, "itemName": "Interconnected VoIP - Fixed" })
    this.dropdownVoipProviderFilter.push({ "id": 3, "itemName": "Non-Interconnected VoIP" })

    this.dropdownWirelessProviderFilter = [];
    this.dropdownWirelessProviderFilter.push({ "id": 1, "itemName": "PrePaid" })
    this.dropdownWirelessProviderFilter.push({ "id": 2, "itemName": "PostPaid" })
    this.dropdownETCProviderFilter = [];
    this.dropdownETCProviderFilter.push({ "id": 1, "itemName": "ETC Law Firm" })
    this.dropdownETCProviderFilter.push({ "id": 2, "itemName": "ETC Low Income" })
    this.dropdownETCProviderFilter.push({ "id": 3, "itemName": "ETC High Cost" })
  }

  onSelectBillingProvider($event) {
    if ($event.id == 1) {
      this.isEnableBillingProvider = true;
      this.CompanyProfileInformationForm.patchValue({
        haveABillingProvider: true
      });
    } else {
      this.isEnableBillingProvider = false;
      this.CompanyProfileInformationForm.patchValue({
        haveABillingProvider: false
      });
    }
  }

  onDeSelectBillingProviderAll($event) {
    this.selectedItemsBillingProvider = [];
    this.isEnableBillingProvider = false;
    this.CompanyProfileInformationForm.patchValue({
      haveABillingProvider: false
    });
  }

  onSelectTaxRatingProvider($event) {
    if ($event.id == 1) {
      this.isEnableTaxRatingProvider = true;
      this.CompanyProfileInformationForm.patchValue({
        haveATaxRatingProvider: true
      });
    }
    else {
      this.isEnableTaxRatingProvider = false;
      this.CompanyProfileInformationForm.patchValue({
        haveATaxRatingProvider: false
      });
    }
  }

  onDeSelectTaxRatingProviderAll($event) {
    this.selectedItemsTaxRatingProvider = [];
    this.isEnableTaxRatingProvider = false;
    this.CompanyProfileInformationForm.patchValue({
      haveATaxRatingProvider: false
    });
  }

  onSelectTaxCompliance($event) {
    if ($event.id == 1) {
      this.isEnableCurrentlyFillingForYou = true;
      this.CompanyProfileInformationForm.patchValue({
        currentlyFillingTaxandcompliance: true
      });
    }
    else {
      this.isEnableCurrentlyFillingForYou = false;
      this.CompanyProfileInformationForm.patchValue({
        currentlyFillingTaxandcompliance: false
      });
    }
  }

  onDeSelectTaxComplianceAll($event) {
    this.selectedItemsTaxCompliance = [];
    this.isEnableCurrentlyFillingForYou = false;
    this.CompanyProfileInformationForm.patchValue({
      currentlyFillingTaxandcompliance: false
    });
  }

  onSelectTaxPermit($event) {
    if ($event.id == 1) {
      this.CompanyProfileInformationForm.patchValue({
        needYouTaxPermits: true
      });
    }
    else {
      this.CompanyProfileInformationForm.patchValue({
        needYouTaxPermits: false
      });
    }
  }

  onDeSelectTaxPermitAll($event) {
    this.selectedItemsTaxPermit = [];
    this.CompanyProfileInformationForm.patchValue({
      needYouTaxPermits: false
    });
  }

  onSelectVoipProvider($event) {
    // this.selectedItemsVoipProvider
  }

  onDeSelectVoipProvider($event) {
    this.selectedItemsVoipProvider = [];
  }

  onDeSelectVoipProviderAll($event) {
    this.selectedItemsVoipProvider = [];
  }

  onSelectWirelessProvider($event) {
    // this.selectedItemsWirelessProvider
  }

  onDeSelectWirelessProvider($event) {
    this.selectedItemsWirelessProvider = [];
  }

  onDeSelectWirelessProviderAll($event) {
    this.selectedItemsWirelessProvider = [];
  }

  onDeSelectETCProvider($event) {
    this.selectedItemsETCProvider = [];
  }

  onDeSelectETCProviderAll($event) {
    this.selectedItemsETCProvider = [];
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  activeTab(url: string, tabId: number) {

    if (this.clientInformationId != null) {
      if (checkStepIsCompletedNew(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.clientInformationId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepNew(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.clientInformationId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompletedNew(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepNew(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(clientInformationId: number) {
    this.PricingQuotesMaster.GetCompanyProfileProgressById(this.tokenKey, this.clientInformationId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStepNew(this.globle.cApp.statusModel);
          this.activeTab('add-comp-que', 2);
        }
      });
  }
}
