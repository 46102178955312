import { Component, HostListener, OnInit } from '@angular/core';
import { BriefingMasterModel } from 'src/Models/BriefingMasterModel';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from 'src/Models/Common';
import { BriefingMasterFilterModel } from 'src/Models/filterModel';
import { I360Service } from 'src/Services/i360Service';
import { StateMasterService } from 'src/Services/state-master.service';
import { ActionItemService } from 'src/Services/actionitem.service';
import * as FileSaver from 'file-saver';
@Component({
  selector: 'app-briefing',
  templateUrl: './briefing.component.html',
  styleUrls: ['./briefing.component.css']
})
export class BriefingComponent implements OnInit {
  filterModel = new BriefingMasterFilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfBriefing: BriefingMasterModel[];

  isUserSystemAdmin: boolean = true;
  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false
  upDowStateNameCSS: string = '';
  upDowTitleCSS: string = '';
  upDowDescriptionCSS: string = '';
  upDowBriefingDocumentCSS: string = '';
  upDowSupportedDocumentCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDowShortNameCSS: string = '';
  upDownEmailStatusCSS: string = '';

  upDowSourceDateCSS: string = '';
  upDowAcquiredDateCSS: string = '';
  upDowBackupTitleCSS: string = '';


  productId: number = 1;
  briefingId: number = 0;
  isloading: boolean;
  rangeOfupdatesDates: any;
  rangeOfsourceDates: any;
  rangeOfacquiredDates: any;

  // States
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];

  // Briefing Types
  dropdownListBriefingTypes = [];
  selectedItemsBriefingTypes = [];
  dropdownSettingsBriefingTypes = {};
  listOfBriefingTypesIds = [];

  // Briefing Sub Types
  dropdownListBriefingSubTypes = [];
  selectedItemsBriefingSubTypes = [];
  dropdownSettingsBriefingSubTypes = {};
  listOfBriefingSubTypesIds = [];

  //category dropdown
  dropdownSettingsTypes = {};
  dropdownListBriefingCategory = [];
  selectedBriefingCategory = [];
  listOfBriefingCategory = [];

  listOfBriefingState: any;
  blockedDocument = false;
  isShowprogressSpinner = false;
  loginuserID: number = 0;
  duplicateBriefingId: number = 0;

  openMenuIndex: number = -1

  constructor(
    private stateMasterService: StateMasterService, private i360service: I360Service, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService, private actionItemServices: ActionItemService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Briefing Services');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'ISNULL(ModifiedTime,CreatedTime) DESC';
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.GetBriefingData();
    this.GetStateData();
    this.GetBulletinTypeData();
    this.GetBulletinSubTypeData();
    this.BriefingCategories();
  }

  GetBriefingData() {
    this.isloading = true;
    this.listOfBriefing = [];
    this.i360service.i360BriefingMasterGet(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfBriefing = data['briefingData'];
          if (this.listOfBriefing != null) {
            if (this.listOfBriefing.length > 0) {
              this.totalRecordCount = this.listOfBriefing[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filterModel.whereCondition = $event.target.value;
    }
    else {
      this.filterModel.whereCondition = '';
    }
    this.GetBriefingData();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetBriefingData();
  }

  sort(sortBy: any) {
    this.upDowUpdateDateCSS = '';
    this.upDowStateNameCSS = '';
    this.upDowTitleCSS = '';
    this.upDownEmailStatusCSS='';
    this.upDowDescriptionCSS = '';
    this.upDowBriefingDocumentCSS = '';
    this.upDowSupportedDocumentCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';
    this.upDowShortNameCSS = '';
    this.upDowSourceDateCSS = '';
    this.upDowAcquiredDateCSS = '';
    this.upDowBackupTitleCSS='';

    if (!this.isAscending) {
      this.isAscending = true;
      this.filterModel.orderBy = sortBy + ' DESC';

      if (sortBy === "updateDate") {
        this.upDowUpdateDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "statename") {
        this.upDowStateNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "shortName") {
        this.upDowShortNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "title") {
        this.upDowTitleCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "emailStatus") {
        this.upDownEmailStatusCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "description") {
        this.upDowDescriptionCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "displaybriefingdocname") {
        this.upDowBriefingDocumentCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "displaysupporteddocname") {
        this.upDowSupportedDocumentCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "sourceDate") {
        this.upDowSourceDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "acquiredDate") {
        this.upDowAcquiredDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "backupTitle") {
        this.upDowBackupTitleCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "isActive") {
        this.upDowIsActiveCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "createdTime") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "modifiedTime") {
        this.upDowModifiedDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.filterModel.orderBy = sortBy;
      if (sortBy === "updateDate") {
        this.upDowUpdateDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "statename") {
        this.upDowStateNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "shortName") {
        this.upDowShortNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "title") {
        this.upDowTitleCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "emailStatus") {
        this.upDownEmailStatusCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "description") {
        this.upDowDescriptionCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "displaybriefingdocname") {
        this.upDowBriefingDocumentCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "displaysupporteddocname") {
        this.upDowSupportedDocumentCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "sourceDate") {
        this.upDowSourceDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "acquiredDate") {
        this.upDowAcquiredDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "backupTitle") {
        this.upDowBackupTitleCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "isActive") {
        this.upDowIsActiveCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "createdTime") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "modifiedTime") {
        this.upDowModifiedDateCSS = 'fa fa-arrow-up';
      }
    }
    this.GetBriefingData();
  }

  addData() {
    this.router.navigate(['admin/briefingServices/manage']);
  }

  editData(ids) {
    this.router.navigate(['admin/briefingServices/manage/' + ids])
  }

  sendEmail(id) {
    sessionStorage.setItem("fromSummary","0");
     window.open('/#/admin/' + this.SelectedTool + '/briefingEmailDetails/' + id,"_blank");
    // this.blockedDocument = this.isShowprogressSpinner = true;

    // this.i360service.BriefingInstantEmailSendById(this.tokenKey, id).subscribe(
    //   data => {
    //     this.blockedDocument = this.isShowprogressSpinner = false;
    //     if (data != null) {
    //       if (data.status == 'Success') {
    //         this.toastr.success('Mail send successfully.', 'Success')
    //       }
    //       else {
    //         this.toastr.error(data.message, 'Error')
    //       }
    //     }
    //   },
    //   error => {
    //     this.blockedDocument = this.isShowprogressSpinner = false;
    //   }
    // );
  }

  setDeleteData(ids) {
    this.briefingId = ids;
  }

  deleteRecord(briefingId) {
    this.i360service.i360BriefingMasterDelete(this.tokenKey, briefingId).subscribe(
      data => {
        this.briefingId = 0;
        if (data != null) {
          if (data['deletedId'] > 0) {
            this.GetBriefingData();
            this.toastr.success('Record deleted successfully.', 'Delete')
          }
        }
      },
      error => {

      }
    );
  }

  isUpdatesDatesFilter(value: any) {
    if (value != null) {
      if (value.length == 2) {
        this.filterModel.pageNumber = '1';
        this.currentPage = 1;
        const d = new Date(value[0]);
        const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

        const e = new Date(value[1]);
        const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');

        this.filterModel.fromDate = a;
        this.filterModel.toDate = f;
        this.GetBriefingData();
      }
    }
  }

  pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  clearUpdateDate() {
    this.rangeOfupdatesDates = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.fromDate = '';
    this.filterModel.toDate = '';
    this.GetBriefingData();
  }

  clearsourceDate() {
    this.rangeOfsourceDates = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.sourceFromDate = '';
    this.filterModel.sourceToDate = '';
    this.GetBriefingData();
  }

  clearacquiredDate() {
    this.rangeOfacquiredDates = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.acquiredFromDate = '';
    this.filterModel.acquiredToDate = '';
    this.GetBriefingData();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.filterModel.stateID = null;
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  GetStateData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.stateMasterService.getState(this.tokenKey, this.productId).subscribe(
      data => {
        if (data["state"] != null) {
          // Set All Item          
          for (let iCounter = 0; iCounter < data["state"].length; iCounter++) {
            this.dropdownListState.push(
              { "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name }
            );
          }
        }
        this.dropdownSettingsState = {
          singleSelection: false,
          text: "Select Jurisdiction(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No Jurisdiction available',
          showCheckbox: true
        };
      }, error => {
        console.log(error);
      })
  }

  GetBulletinTypeData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.i360service.i360BulletinTypeGet(this.tokenKey).subscribe(
      data => {
        if (data["listOfBulletinType"] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data["listOfBulletinType"].length; iCounter++) {
            this.dropdownListBriefingTypes.push(
              { "id": data["listOfBulletinType"][iCounter].carrierCatId, "itemName": data["listOfBulletinType"][iCounter].carrierCatName }
            );
          }
        }

        this.dropdownSettingsBriefingTypes = {
          singleSelection: false,
          text: "Select Briefing Types",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No briefing types available',
          showCheckbox: true
        };
      }, error => {
        console.log(error);
      })
  }

  onSelectBriefingTypes($event) {
    this.listOfBriefingTypesIds = this.removeElemetninArray(this.listOfBriefingTypesIds, $event.id)
    this.listOfBriefingTypesIds.push($event.id);
    this.filterModel.briefingTypesId = this.listOfBriefingTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onDeSelectBriefingTypes($event) {
    if (this.listOfBriefingTypesIds != null) {
      if (this.listOfBriefingTypesIds.length > 0) {
        this.listOfBriefingTypesIds = this.removeElemetninArray(this.listOfBriefingTypesIds, $event.id)
      }
    }
    this.filterModel.briefingTypesId = this.listOfBriefingTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onSelectAllBriefingTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingTypesIds != null) {
        if (this.listOfBriefingTypesIds.length > 0) {
          this.listOfBriefingTypesIds = this.removeElemetninArray(this.listOfBriefingTypesIds, $event[iCounter].id)
          this.listOfBriefingTypesIds.push($event[iCounter].id);
        }
        else {
          this.listOfBriefingTypesIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.briefingTypesId = this.listOfBriefingTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onDeSelectAllBriefingTypes($event) {
    this.listOfBriefingTypesIds = [];
    this.filterModel.briefingTypesId = null;
    this.filterModel.pageNumber = '1';
    this.GetBriefingData();
  }

  GetBulletinSubTypeData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.i360service.i360BulletinSubTypeGet(this.tokenKey).subscribe(
      data => {
        if (data["listOfBulletinSubType"] != null) {
          for (let iCounter = 0; iCounter < data["listOfBulletinSubType"].length; iCounter++) {
            this.dropdownListBriefingSubTypes.push({ "id": data["listOfBulletinSubType"][iCounter].bulletinSubTypeId, "itemName": data["listOfBulletinSubType"][iCounter].bulletinSubType });
          }
        }

        this.dropdownSettingsBriefingSubTypes = {
          singleSelection: false,
          text: "Select Briefing Sub Types",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No briefing sub types available',
          showCheckbox: true
        };
      }, error => {
        console.log(error);
      })
  }

  onSelectBriefingSubTypes($event) {
    this.listOfBriefingSubTypesIds = this.removeElemetninArray(this.listOfBriefingSubTypesIds, $event.id)
    this.listOfBriefingSubTypesIds.push($event.id);
    this.filterModel.briefingSubtypesId = this.listOfBriefingSubTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onDeSelectBriefingSubTypes($event) {
    if (this.listOfBriefingSubTypesIds != null) {
      if (this.listOfBriefingSubTypesIds.length > 0) {
        this.listOfBriefingSubTypesIds = this.removeElemetninArray(this.listOfBriefingSubTypesIds, $event.id)
      }
    }
    this.filterModel.briefingSubtypesId = this.listOfBriefingSubTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onSelectAllBriefingSubTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingSubTypesIds != null) {
        if (this.listOfBriefingSubTypesIds.length > 0) {
          this.listOfBriefingSubTypesIds = this.removeElemetninArray(this.listOfBriefingSubTypesIds, $event[iCounter].id)
          this.listOfBriefingSubTypesIds.push($event[iCounter].id);
        }
        else {
          this.listOfBriefingSubTypesIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.briefingSubtypesId = this.listOfBriefingSubTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onDeSelectAllBriefingSubTypes($event) {
    this.listOfBriefingSubTypesIds = [];
    this.filterModel.briefingSubtypesId = null;
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  i360BriefingStateFindByBriefingId(BriefingId: any) {
    this.listOfBriefingState = [];
    this.i360service.i360BriefingStateFindByBriefingId(this.tokenKey, BriefingId).subscribe(
      data => {
        if (data != null) {
          this.listOfBriefingState = data['listOfBriefingStates'];
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  BriefingCategories(): void {
    this.dropdownListBriefingCategory = [];
    this.actionItemServices.GetActionItemsType(this.tokenKey).subscribe(
      data => {
        if (data["actionType"] != null && data["status"] != "Failure") {

          for (let iCounter = 0; iCounter < data["actionType"].length; iCounter++) {
            // Remove Company Specific
            if (data["actionType"][iCounter].actionItemTypeId != 5) {
              this.dropdownListBriefingCategory.push(
                { "id": data["actionType"][iCounter].actionItemTypeId, "itemName": data["actionType"][iCounter].typeName }
              );
            }
          }
        }
        else {
          this.toastr.success(data["message"], 'error')
        }
        this.dropdownSettingsTypes = {
          singleSelection: true,
          text: "Select Category(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No  category(s) Available',
          showCheckbox: false,
          enableFilterSelectAll: false,
          enableCheckAll: false
        };
      }, error => {
        this.toastr.error(error, 'error')
      })
  }

  // Action Type
  onSelectedBriefingCategory($event) {
    this.filterModel.briefingCategoryId = $event.id;
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onDeSelectedBriefingCategory($event) {
    this.filterModel.briefingCategoryId = 0;
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onDeSelectAllBriefingCategory($event) {
    this.listOfBriefingCategory = [];
    this.filterModel.briefingCategoryId = 0;
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  exportExcelMailData(briefingId) {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.i360service.ExportExcelMailData(this.tokenKey, briefingId).subscribe(
      res => {
        if (res != null) {
          if (res.status == "success") {
            const downloadobj = new BriefingMasterFilterModel();
            downloadobj.docPath = res.path;
            downloadobj.docType = "exportEmail";
            this.i360service.DownloadDocument(this.tokenKey, downloadobj).subscribe(
              fileData => {
                if (fileData != null) {
                  FileSaver.saveAs(fileData, res.path);
                }
              }, error => {
                console.log(error);
              }
            );
          }
          else {
            this.toastr.error(res.message, 'error')
          }
        }
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
      }, error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        console.log(error);
      }
    );
  }

  GetBriefingEmailDataByBriefingId(briefingId) {
    this.router.navigate(['admin/briefingServices/briefingEmailDetails/' + briefingId])
    
  }
  
  setDuplicateId(duplicateBriefingId) {
    this.duplicateBriefingId = duplicateBriefingId;
  }

  duplicateBriefingData(briefingId) {
    sessionStorage.setItem('isDuplicate', 'true');
    this.router.navigate(['admin/briefingServices/manage/' + briefingId])
  }

  ExportBriefingReports() {
    this.isloading = true;
    this.blockedDocument = true;
    this.i360service.ExportBriefingReports(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        this.blockedDocument = false;

        if (data != null) {
          if (data.status == 'Success') {
            this.filterModel.docPath = data.responseNewFilename;
            this.filterModel.docType = 'Briefing Exports';
            this.i360service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
              fileData => {

                if (fileData != null) {
                  FileSaver.saveAs(fileData, 'BriefingExports.csv');
                }
              }, error => {
                console.log(error);
              }
            );
          }
        }
      },
      error => {
        this.blockedDocument = false;
        this.isloading = false;
        console.log(error);
      }
    );
  }

  onFilterSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onFilterDeSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onFilterSelectAllBriefingTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingTypesIds != null) {
        if (this.listOfBriefingTypesIds.length > 0) {
          this.listOfBriefingTypesIds = this.removeElemetninArray(this.listOfBriefingTypesIds, $event[iCounter].id)
          this.listOfBriefingTypesIds.push($event[iCounter].id);
        }
        else {
          this.listOfBriefingTypesIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.briefingTypesId = this.listOfBriefingTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onFilterDeSelectAllBriefingTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingTypesIds != null) {
        if (this.listOfBriefingTypesIds.length > 0) {
          this.listOfBriefingTypesIds = this.removeElemetninArray(this.listOfBriefingTypesIds, $event[iCounter].id)
        }
      }
    }
    this.filterModel.briefingTypesId = this.listOfBriefingTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onFilterSelectAllBriefingSubTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingSubTypesIds != null) {
        if (this.listOfBriefingSubTypesIds.length > 0) {
          this.listOfBriefingSubTypesIds = this.removeElemetninArray(this.listOfBriefingSubTypesIds, $event[iCounter].id)
          this.listOfBriefingSubTypesIds.push($event[iCounter].id);
        }
        else {
          this.listOfBriefingSubTypesIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.briefingSubtypesId = this.listOfBriefingSubTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }

  onFilterDeSelectAllBriefingSubTypes($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingSubTypesIds != null) {
        if (this.listOfBriefingSubTypesIds.length > 0) {
          this.listOfBriefingSubTypesIds = this.removeElemetninArray(this.listOfBriefingSubTypesIds, $event[iCounter].id)
        }
      }
    }
    this.filterModel.briefingSubtypesId = this.listOfBriefingSubTypesIds.join(',');
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    this.GetBriefingData();
  }


  isSourceDatesFilter(value: any) {
    if (value != null) {
      if (value.length == 2) {
        this.filterModel.pageNumber = '1';
        this.currentPage = 1;
        const d = new Date(value[0]);
        const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

        const e = new Date(value[1]);
        const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');

        this.filterModel.sourceFromDate = a;
        this.filterModel.sourceToDate = f;
        this.GetBriefingData();
      }
    }
  }


  isAcquiredDatesFilter(value: any) {
    if (value != null) {
      if (value.length == 2) {
        this.filterModel.pageNumber = '1';
        this.currentPage = 1;
        const d = new Date(value[0]);
        const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

        const e = new Date(value[1]);
        const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');

        this.filterModel.acquiredFromDate = a;
        this.filterModel.acquiredToDate = f;
        this.GetBriefingData();
      }
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

  viewBriefingDocument(item) {
    if (item.displayBriefingDocName != '') {
      window.open(item.displayBriefingDocName, '_blank');
    }
  }

  viewSourceDocument(item) {
    if (item.displaySupportedDocName != '') {
      window.open(item.displaySupportedDocName, '_blank');
    }
  }
}
