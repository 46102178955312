import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PricingQuotingBaserateService } from 'src/Services/pricing-quoting-baserate.service';
import { CheckAuth } from 'src/Services/utility.service';
import { BaseRateModel } from 'src/Models/BaseRateModel';

@Component({
  selector: 'app-base-rate',
  templateUrl: './base-rate.component.html',
  styleUrls: ['./base-rate.component.css']
})
export class BaseRateComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofBaseRate: BaseRateModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  BaseRate: string;


  upDowBaseRateCSS: string = '';
  upDowMonthlyRateCSS: string = '';
  upDowAnnualRateCSS: string = '';
  upDowLastUpdatedDateCSS: string = '';
  upDowSortOrderCSS: string = '';
  upDowActiveCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  baseRateId:number = 0;
  baseRateType:number = 0;

  listOfBaseRateTypeIDs = [];
  BaseRateTypeList: any[] = [];
  selectedItemsBaseRateType = [];
  dropdownBaseRateTypeFilter = [];
  dropdownSettingsBaseRateTypeFilter = {};
  openMenuIndex: number = -1;

  constructor(private router: Router, private BaseRateMaster: PricingQuotingBaserateService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService,private baseRateMaster: PricingQuotingBaserateService) {
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofBaseRate = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'SortOrder,BaseRateId';
    this.BaseRate = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Base Rate');
     }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetBaseRate();

    this.dropdownSettingsBaseRateTypeFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No BaseRateType(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.BaseRateTypeAll();

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  
  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetBaseRate();
  }

  GetBaseRate() { 
      if (this.BaseRate == "null") this.BaseRate = " ";
      this.BaseRateMaster.GetAllBaseRate(this.tokenKey, this.PageNumber, this.PageSize, this.BaseRate, this.OrderBy,this.baseRateType).subscribe(
        data => {
          this.isloading = false;
          this.listofBaseRate = data['baseRate'];
          if (this.listofBaseRate != null) {
            if (this.listofBaseRate.length == 0)
              this.isRecord = true;
            else {
              this.isRecord = false;
              this.totalRecordCount = this.listofBaseRate[0].totalRecordCount;
            }
          }
          else
            this.isRecord = true;
        },
        error => { this.stateCode = error }
      );
    
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.BaseRate = $event.target.value;
    else
      this.BaseRate = 'null';

    this.GetBaseRate();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "BaseRate") {
        this.upDowBaseRateCSS = 'fa fa-arrow-down';
        this.upDowMonthlyRateCSS = '';
        this.upDowAnnualRateCSS = '';
        this.upDowLastUpdatedDateCSS='';
        this.upDowSortOrderCSS='';
      }
      else if (sortBy === "MonthlyRate") {
        this.upDowBaseRateCSS = '';
        this.upDowMonthlyRateCSS = 'fa fa-arrow-down';
        this.upDowAnnualRateCSS = '';
        this.upDowLastUpdatedDateCSS='';
        this.upDowSortOrderCSS='';
      }
      else if (sortBy === "AnnualRate") {
        this.upDowBaseRateCSS = '';
        this.upDowAnnualRateCSS = 'fa fa-arrow-down';
        this.upDowMonthlyRateCSS = '';
        this.upDowLastUpdatedDateCSS='';
        this.upDowSortOrderCSS='';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowBaseRateCSS = '';
        this.upDowMonthlyRateCSS = '';
        this.upDowAnnualRateCSS = '';
        this.upDowSortOrderCSS='';
        this.upDowLastUpdatedDateCSS='fa fa-arrow-down';
      }
      else if (sortBy === "SortOrder") {
        this.upDowBaseRateCSS = '';
        this.upDowMonthlyRateCSS = '';
        this.upDowAnnualRateCSS = '';
        this.upDowSortOrderCSS='fa fa-arrow-down';
        this.upDowLastUpdatedDateCSS='';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "BaseRate") {
        this.upDowBaseRateCSS = 'fa fa-arrow-up';
        this.upDowMonthlyRateCSS = '';
        this.upDowAnnualRateCSS = '';
        this.upDowLastUpdatedDateCSS='';
        this.upDowSortOrderCSS='';
      }
      else if (sortBy === "MonthlyRate") {
        this.upDowBaseRateCSS = '';
        this.upDowMonthlyRateCSS = 'fa fa-arrow-up';
        this.upDowAnnualRateCSS = '';
        this.upDowLastUpdatedDateCSS='';
        this.upDowSortOrderCSS='';
      }
      else if (sortBy === "AnnualRate") {
        this.upDowBaseRateCSS = '';
        this.upDowAnnualRateCSS = 'fa fa-arrow-up';
        this.upDowMonthlyRateCSS = '';
        this.upDowLastUpdatedDateCSS='';
        this.upDowSortOrderCSS='';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowBaseRateCSS = '';
        this.upDowMonthlyRateCSS = '';
        this.upDowAnnualRateCSS = '';
        this.upDowSortOrderCSS='';
        this.upDowLastUpdatedDateCSS='fa fa-arrow-up';
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowBaseRateCSS = '';
        this.upDowMonthlyRateCSS = '';
        this.upDowAnnualRateCSS = '';
        this.upDowSortOrderCSS='fa fa-arrow-up';
        this.upDowLastUpdatedDateCSS='';
      }
    }
    this.GetBaseRate();
  }

  addBaseRate() {
    let navigation = '/admin/' + this.SelectedTool + '/baseRate/manage';
    this.router.navigate([navigation]);
  }

  updateBaseRate(baseRateId) {
    let navigation = '/admin/' + this.SelectedTool + '/baseRate/manage/' + baseRateId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.baseRateId = id;
  }


  deleteBaseRate(baseRateId) {
    this.isloading = true;
    this.BaseRateMaster.DeleteBaseRate(this.tokenKey, baseRateId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
            this.showMessage("Base Rate Deleted successfully", 'Success', 'success')
        }
        this.GetBaseRate();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  
  BaseRateTypeAll() {
    this.baseRateMaster.BaseRateTypeAll(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.BaseRateTypeList = result['baseRateType'];
          this.dropdownBaseRateTypeFilter = [];
          this.BaseRateTypeList.forEach(x => {
            this.dropdownBaseRateTypeFilter.push({ "id": x.baseRateTypeId, "itemName": x.baseRateTypeName })
          });
        }
      },
      error => { }
    );
  }

  onDeSelectBaseRateTypeAll($event) {
    this.baseRateType=0;
    this.GetBaseRate();
  }

  onSelectBaseRateType(event) {
    this.baseRateType=event.id;
    this.GetBaseRate();
  }

  onDeSelectBaseRateType(event) {
    this.baseRateType=0;
    this.GetBaseRate();
  }
  
  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
