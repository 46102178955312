import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientInformationAttachmentModel } from 'src/Models/ClientInformationAttachmentModel';
import { StatusModel } from 'src/Models/StepMasterModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompleted, checkStepIsCompletedPhase3, getIncompletedStepPhase3 } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-admin-attachments-to-determine-services',
  templateUrl: './admin-attachments-to-determine-services.component.html',
  styleUrls: ['./admin-attachments-to-determine-services.component.css']
})
export class AdminAttachmentsToDetermineServicesComponent implements OnInit {

  SelectedTool: string;
  tokenKey: any;
  isEdit: boolean = false;
  clientInformationId: number;
  priceQuoteId: number;
  userID: any;

  isShowprogressSpinner: boolean;
  blockedDocument: boolean;

  isErrorOnProductCatalogDOC: boolean;
  SelectedProductCatalog: File;
  isProductCatalogtoryFile: boolean = false;
  isProductCatalogtory: boolean = false;

  isErrorOnTaxLiabilityDoc: boolean;
  selectedTaxLiability: File;
  isTaxLiabilityFile: boolean = false;
  isTaxLiability: boolean = false;

  isErrorOnBilledRevenueDOC: boolean;
  SelectedBilledRevenue: File;
  isBilledRevenueFile: boolean = false;
  isBilledRevenue: boolean = false;

  isErrorOnTaxPermitDoc: boolean;
  selectedTaxPermit: File;
  isTaxPermitFile: boolean = false;
  isTaxPermit: boolean = false;
  allowedExtensions: any = ["pdf", "PDF", "doc", "docx", "DOC", "DOCX", "txt"];

  file: any;
  @ViewChild('productCatalog', { static: true }) productCatalog;
  @ViewChild('taxLiability', { static: true }) taxLiability;
  @ViewChild('billedRevenue', { static: true }) billedRevenue;
  @ViewChild('taxPermit', { static: true }) taxPermit;

  clientMasterForm: FormGroup;

  clientInformationAttachmentModel = new ClientInformationAttachmentModel();

  ClientInformationAttachmentModel: ClientInformationAttachmentModel = {
    clientInfoAttachmentId: 0,
    clientInformationId: 0,
    productCatalogDisplayName: '',
    productCatalogDocName: null,
    taxLiabilityDisplayName: '',
    taxLiabilityDocName: null,
    billedRevenueDisplayName: '',
    billedRevenueDocName: null,
    taxPermitDisplayName: '',
    taxPermitDocName: null,
    createdBy: 0,
    createdDate: '',
    updatedBy: 0,
    updatedDate: '',
    productCatalogDocPath: null,
    taxLiabilityDocPath: null,
    taxPermitDocPath: null,
    billedRevenueDocPath: null,
    isProductCatalogRequired: false,
    isTaxLiabilityRequired: false,
    isBilledRevenueRequired: false,
    isTaxPermitRequired: false
  }

  isCompanyProfileDetailsCompleted: boolean;
  isAddCompQueCompleted: boolean;
  isStateSelectionCompleted: boolean;
  isAttechedDetailsCompleted: boolean;
  currentActiveTab: number = 1;
  url: string;
  tabId: number = 0;

  mainUrl: string;
  subUrl: string;

  isChecked: boolean = false;
  required: string;
  fieldName:string;
  companyName:string;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute, private globle: Globle,
    private router: Router, private PricingQuotesMaster: PricingQuotesService, private toastr: ToastrService, private titleService: Title) {
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Onboarding Information - '+this.companyName);

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.clientInformationId = params['id'];
        }
      }

      if (params['priceQuoteId']) {
        if (params['priceQuoteId'] != null) {
          this.priceQuoteId = params['priceQuoteId'];
        }
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.clientInformationId != null) {
      this.GetProgressStepDetailsById(this.clientInformationId);
      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isAdditionalCompanyInformation= null;
      this.globle.cApp.statusModel.isSensitiveInformation= null;
      this.globle.cApp.statusModel.isInteserraPortalCredential= null;
      this.globle.cApp.statusModel.isAttechedDetailsCompleted = null;
      this.globle.cApp.statusModel.isStateSelectionCompleted=null;
      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
    }

    this.clientMasterForm = this._fb.group({
      clientInfoAttachmentId: 0,
      clientInformationId: this.clientInformationId,
      productCatalogDisplayName: [''],
      productCatalogDocName: [''],
      taxLiabilityDisplayName: [''],
      taxLiabilityDocName: [''],
      billedRevenueDisplayName: [''],
      billedRevenueDocName: [''],
      taxPermitDisplayName: [''],
      taxPermitDocName: [''],
      createdBy: 0,
      productCatalogDocPath: [''],
      taxLiabilityDocPath: [''],
      billedRevenueDocPath: [''],
      taxPermitDocPath: [''],
      priceQuoteId: this.priceQuoteId,
      isProductCatalogRequired: false,
      isTaxLiabilityRequired: false,
      isBilledRevenueRequired: false,
      isTaxPermitRequired: false
    })

  }

  ngOnInit() {
    if (this.clientInformationId != 0) {
      this.clientInformationAttachmentModel.priceQuoteId = this.clientInformationId;
      this.GetClientInformationAttachmentById(this.clientInformationId);
    }
  }

  GetClientInformationAttachmentById(priceQuoteId: number) {
    this.blockDocument();
    this.PricingQuotesMaster.GetClientInformationAttachmentById(this.tokenKey, priceQuoteId, true).subscribe(
      result => {

        if (result['status'] == 'Success') {
          if (result['clientInformation'] != null) {
            this.clientInformationAttachmentModel = result['clientInformation'];
          }
        }
        if (result['clientInformation'] != null) {
          this.clientMasterForm.setValue({
            clientInformationId: this.clientInformationAttachmentModel.clientInformationId,
            productCatalogDocName: this.clientInformationAttachmentModel.productCatalogDocName,
            productCatalogDisplayName: this.clientInformationAttachmentModel.productCatalogDisplayName,
            taxLiabilityDocName: this.clientInformationAttachmentModel.taxLiabilityDocName,
            taxLiabilityDisplayName: this.clientInformationAttachmentModel.taxLiabilityDisplayName,
            billedRevenueDocName: this.clientInformationAttachmentModel.billedRevenueDocName,
            billedRevenueDisplayName: this.clientInformationAttachmentModel.billedRevenueDisplayName,
            taxPermitDocName: this.clientInformationAttachmentModel.taxPermitDocName,
            taxPermitDisplayName: this.clientInformationAttachmentModel.taxPermitDisplayName,
            clientInfoAttachmentId: this.clientInformationAttachmentModel.clientInfoAttachmentId,
            createdBy: this.clientInformationAttachmentModel.createdBy,
            productCatalogDocPath: this.clientInformationAttachmentModel.productCatalogDocPath,
            taxLiabilityDocPath: this.clientInformationAttachmentModel.taxLiabilityDocPath,
            taxPermitDocPath: this.clientInformationAttachmentModel.taxLiabilityDocPath,
            billedRevenueDocPath: this.clientInformationAttachmentModel.billedRevenueDocPath,
            priceQuoteId: this.clientInformationAttachmentModel.priceQuoteId,
            isProductCatalogRequired: this.clientInformationAttachmentModel.isProductCatalogRequired,
            isTaxLiabilityRequired: this.clientInformationAttachmentModel.isTaxLiabilityRequired,
            isBilledRevenueRequired: this.clientInformationAttachmentModel.isBilledRevenueRequired,
            isTaxPermitRequired: this.clientInformationAttachmentModel.isTaxPermitRequired
          });

          this.clientInformationId=this.clientInformationAttachmentModel.clientInformationId
        }
        else {
          this.clientInformationAttachmentModel.clientInfoAttachmentId = 0;
          this.clientInformationAttachmentModel.clientInformationId = this.clientInformationId,
            this.clientInformationAttachmentModel.productCatalogDisplayName = '',
            this.clientInformationAttachmentModel.productCatalogDocName = null,
            this.clientInformationAttachmentModel.taxLiabilityDisplayName = '',
            this.clientInformationAttachmentModel.taxLiabilityDocName = null,
            this.clientInformationAttachmentModel.billedRevenueDisplayName = '',
            this.clientInformationAttachmentModel.billedRevenueDocName = null,
            this.clientInformationAttachmentModel.taxPermitDisplayName = '',
            this.clientInformationAttachmentModel.taxPermitDocName = null,
            this.clientInformationAttachmentModel.createdBy = 0,
            this.clientInformationAttachmentModel.createdDate = '',
            this.clientInformationAttachmentModel.updatedBy = 0,
            this.clientInformationAttachmentModel.updatedDate = '',
            this.clientInformationAttachmentModel.productCatalogDocPath = null,
            this.clientInformationAttachmentModel.taxLiabilityDocPath = null,
            this.clientInformationAttachmentModel.taxPermitDocPath = null,
            this.clientInformationAttachmentModel.billedRevenueDocPath = null,
            this.clientInformationAttachmentModel.priceQuoteId = this.priceQuoteId,
            this.clientInformationAttachmentModel.isProductCatalogRequired = 0,
            this.clientInformationAttachmentModel.isTaxLiabilityRequired = 0,
            this.clientInformationAttachmentModel.isBilledRevenueRequired = 0,
            this.clientInformationAttachmentModel.isTaxPermitRequired = 0
        }
        this.unblockDocument();
      });
  }

  save(clientForm) {

    this.clientInformationAttachmentModel = clientForm.value;

    if (!clientForm.valid)
      return

    if (this.clientMasterForm.invalid) {
      this.globle.cApp.statusModel.isAttechedDetailsCompleted = false;
      return;
    }

    if (this.isErrorOnProductCatalogDOC) {
      this.showMessage('File not valid in Product Catalog document', 'Invalid File', 'error');
      return;
    }

    if (this.isErrorOnTaxLiabilityDoc) {
      this.showMessage('File not valid in Tax Liability document', 'Invalid File', 'error');
      return;
    }

    if (this.isErrorOnBilledRevenueDOC) {
      this.showMessage('File not valid in Billed Revenue document', 'Invalid File', 'error');
      return;
    }

    if (this.isErrorOnTaxPermitDoc) {
      this.showMessage('File not valid in Tax Permit document', 'Invalid File', 'error');
      return;
    }

    this.clientInformationAttachmentModel.createdBy = this.userID;
    this.clientInformationAttachmentModel.updatedBy = this.userID;

    this.clientInformationAttachmentModel.clientInformationId = this.clientInformationId;
    this.clientInformationAttachmentModel.priceQuoteId = this.priceQuoteId
    let formData = new FormData();
    formData.append('clientInformationId', this.clientInformationAttachmentModel.clientInformationId || 0);
    formData.append('productCatalog', this.productCatalog.nativeElement.files[0] || '');
    formData.append('billedRevenue', this.billedRevenue.nativeElement.files[0] || '');
    formData.append('taxLiability', this.taxLiability.nativeElement.files[0] || '');
    formData.append('taxPermit', this.taxPermit.nativeElement.files[0] || '');
    formData.append('createdBy', this.clientInformationAttachmentModel.createdBy || 0);
    formData.append('updatedBy', this.clientInformationAttachmentModel.updatedBy || 0);
    formData.append('clientInfoAttachmentId', this.clientInformationAttachmentModel.clientInfoAttachmentId || 0);
    formData.append('productCatalogDisplayName', this.clientInformationAttachmentModel.productCatalogDisplayName || '');
    formData.append('productCatalogDocName', this.clientInformationAttachmentModel.productCatalogDocName || '');
    formData.append('taxLiabilityDisplayName', this.clientInformationAttachmentModel.taxLiabilityDisplayName || '');
    formData.append('taxLiabilityDocName', this.clientInformationAttachmentModel.taxLiabilityDocName || '');
    formData.append('billedRevenueDisplayName', this.clientInformationAttachmentModel.billedRevenueDisplayName || '');
    formData.append('billedRevenueDocName', this.clientInformationAttachmentModel.billedRevenueDocName || '');
    formData.append('taxPermitDisplayName', this.clientInformationAttachmentModel.taxPermitDisplayName || '');
    formData.append('taxPermitDocName', this.clientInformationAttachmentModel.taxPermitDocName || '');
    formData.append('priceQuoteId', this.clientInformationAttachmentModel.priceQuoteId || 0);

    this.blockDocument();
    this.PricingQuotesMaster.ClientInformationAttachmentAddUpdate(this.tokenKey, formData).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isAttechedDetailsCompleted = true;
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {

            if (result.priceQuoteId > 0)
              this.showMessage('Additional Data updated successfully', 'success', 'success');
            let navigation = '/admin/' + this.SelectedTool + '/onboarding-state-selection/'+this.clientInformationId+'/'+this.priceQuoteId;
            this.router.navigate([navigation]);
          }
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  productCatalogEvent(event): any {
    this.isProductCatalogtoryFile = false;
    this.isProductCatalogtory = false;
    var file = event.target.files[0];

    let size = Math.round(file.size);
    if (size <= file.size) {
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(this.allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.', 'Error', 'error');
        this.isErrorOnProductCatalogDOC = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isProductCatalogtoryFile = true;
        this.isProductCatalogtory = true;
        this.isErrorOnProductCatalogDOC = false;
        this.SelectedProductCatalog = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnProductCatalogDOC = true;
      window.scrollTo(0, 0);
    }
  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  taxLiabilityEvent(event): any {
    this.isTaxLiabilityFile = false;

    var file1 = event.target.files[0];

    let size1 = Math.round(file1.size);
    if (size1 <= file1.size) {
      let fileType1 = file1.name.split('.').pop();

      if (!this.isInArray1(this.allowedExtensions, fileType1)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF,TEXT,DOC,DOCX.', 'Error', 'error');
        this.isErrorOnTaxLiabilityDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isTaxLiabilityFile = true;
        this.isErrorOnTaxLiabilityDoc = false;
        this.selectedTaxLiability = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnTaxLiabilityDoc = true;
      window.scrollTo(0, 0);
    }
  }
  isInArray1(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  billedRevenueEvent(event): any {
    this.isBilledRevenueFile = false;
    this.isBilledRevenue = false;
    var file = event.target.files[0];

    let size = Math.round(file.size);
    if (size <= file.size) {
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(this.allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.', 'Error', 'error');
        this.isErrorOnBilledRevenueDOC = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isBilledRevenueFile = true;
        this.isBilledRevenue = true;
        this.isErrorOnBilledRevenueDOC = false;
        this.SelectedBilledRevenue = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnBilledRevenueDOC = true;
      window.scrollTo(0, 0);
    }
  }

  taxRevenueEvent(event): any {
    this.isTaxPermitFile = false;
    this.isTaxPermit = false;
    var file = event.target.files[0];

    let size = Math.round(file.size);
    if (size <= file.size) {
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(this.allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.', 'Error', 'error');
        this.isErrorOnTaxPermitDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isTaxPermitFile = true;
        this.isTaxPermit = true;
        this.isErrorOnTaxPermitDoc = false;
        this.selectedTaxPermit = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnTaxPermitDoc = true;
      window.scrollTo(0, 0);
    }
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  previousStep() {
    let navigation = '/admin/' + this.SelectedTool + '/portal-credential/' + this.clientInformationId+'/'+this.priceQuoteId;;
    this.router.navigate([navigation]);
  }

  activeTab(url: string, tabId: number) {

    if (this.clientInformationId != null) {
      if (checkStepIsCompletedPhase3(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.clientInformationId+'/'+this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.clientInformationId+'/'+this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompletedPhase3(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsByClientInformationId(this.tokenKey, priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
          this.activeTab('attach-to-determine-services', 4);
        }
      });
  }

  onCheckboxChange(event, item) {
    this.isChecked = event.target.checked;
    if (this.isChecked)
      this.required = "required";
    else
      this.required = "not required";

    this.fieldName=item;

    document.getElementById("btnopenEditpopup").click();
    return;
  }

  requiredFieldCancel() {
    
    if (this.fieldName != null) {
      if(this.fieldName=="Product Catalog")
      {
        this.clientInformationAttachmentModel.isProductCatalogRequired = !this.isChecked;
      }
      else if(this.fieldName=="Tax Liability")
      {
        this.clientInformationAttachmentModel.isTaxLiabilityRequired = !this.isChecked;
      }
      else if(this.fieldName=="Billed Revenue")
      {
        this.clientInformationAttachmentModel.isBilledRevenueRequired = !this.isChecked;
      }
      else if(this.fieldName=="Tax Permit")
      {
        this.clientInformationAttachmentModel.isTaxPermitRequired = !this.isChecked;
      }
    }
  }

  requiredFieldSave(){
    this.PricingQuotesMaster.SaveAttachmentRequiredField(this.tokenKey, this.clientInformationId, this.isChecked,this.fieldName,this.priceQuoteId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          if (data['message'] == "0")
            this.showMessage(data['message'], 'Warning', 'warning')
          else {
            if (this.isChecked)
              this.showMessage(this.fieldName + ' field is set as "Required" successfully', 'Success', 'success')
            else
              this.showMessage(this.fieldName + ' field is set as "Not Required" successfully', 'Success', 'success')
          }
        }
      },
      error => {  }
    );
  
  }

}
