import { Component, OnInit } from '@angular/core';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { NLADClaimService } from 'src/Services/nladClaim.service';
import { InrollProductsCompanyModel } from 'src/Models/InrollProductsCompanyModel';
import { ClaimStateModel } from 'src/Models/ClaimStateModel';
import { EBBClaimProcessDetailsModel } from 'src/Models/EBBClaimProcessDetailsModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-claim-detail-reports',
  templateUrl: './claim-detail-reports.component.html',
  styleUrls: ['./claim-detail-reports.component.css']
})
export class ClaimDetailReportsComponent implements OnInit {
  filterModel = new FilterModel();
  listOfData: EBBClaimProcessDetailsModel[];
  tokenKey: string = '';
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false

  upDowSubscriberIDCSS: string = '';
  upDowRateCSS: string = '';
  upDowReasonCodeCSS: string = '';
  upDowDeviceBenefitCSS: string = '';
  upDowSPINCSS: string = '';
  upDowSACCSS: string = '';
  upDowLastNameCSS: string = '';
  upDowFirstNameCSS: string = '';
  upDowStreetAddressCSS: string = '';
  upDowCityCSS: string = '';
  upDowStateCSS: string = '';
  upDowZipCSS: string = '';
  upDowPhoneNumberCSS: string = '';
  upDowETCGeneralCSS: string = '';
  upDowServiceTypeCSS: string = '';
  upDowTribalBenefitFlagCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowYearsCSS: string = '';
  upDowMonthsCSS: string = '';

  dropdownMonths = [];
  selectedItemsMonths = [];
  dropdownSettingMonths = {};
  listOfMonths = [];

  dropdownYears = [];
  selectedItemsYears = [];
  dropdownSettingYears = {};
  listOfYears = [];
  monthText = [];

  listOfInrollProductCompany: InrollProductsCompanyModel[];
  dropdownCompanies = [];
  selectedItemsCompanies = [];
  dropdownSettingCompanies = {};
  listOfCompany = [];

  listOfStateData: ClaimStateModel[];
  dropdownStates = [];
  selectedItemsStates = [];
  dropdownSettingStates = {};
  listOfStates = [];

  companyName: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  isloading: boolean = false;

  constructor(private nladClaimService: NLADClaimService, private checkAuth: CheckAuth, private titleService: Title, private router: Router,) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Detail Claims- ACP | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'Id';
    this.filterModel.userID = this._userID;
    this.filterModel.productId = 0;
    this.filterModel.months = '0';
    this.filterModel.years = '0';
    this.filterModel.state = '';
    this.filterModel.stateName = '0';
    // if (sessionStorage.getItem('FrontDetailReportsYear') != null)
    //   this.filterModel.years = sessionStorage.getItem('FrontDetailReportsYear');
    // if (sessionStorage.getItem('FrontDetailReportsMonths') != null)
    //   this.filterModel.months = sessionStorage.getItem('FrontDetailReportsMonths');
    // if (sessionStorage.getItem('FrontDetailReportsProviderId') != null)
    //   this.filterModel.providerId = sessionStorage.getItem('FrontDetailReportsProviderId');
    // if (sessionStorage.getItem('FrontDetailReportsCompanyName') != null)
    //   this.companyName = sessionStorage.getItem('FrontDetailReportsCompanyName');

    if (sessionStorage.getItem('FrontDetailReportsYear') != null)
      this.filterModel.years = sessionStorage.getItem('FrontDetailReportsYear');
    if (sessionStorage.getItem('FrontDetailReportsMonths') != null)
      this.filterModel.months = sessionStorage.getItem('FrontDetailReportsMonths');
    if (sessionStorage.getItem('FrontDetailReportsProviderId') != null)
      this.filterModel.providerId = sessionStorage.getItem('FrontDetailReportsProviderId');
    if (sessionStorage.getItem('FrontDetailReportsCompanyName') != null)
      this.companyName = sessionStorage.getItem('FrontDetailReportsCompanyName');
    if (sessionStorage.getItem('FrontDetailReportsState') != null)
      this.filterModel.stateName = sessionStorage.getItem('FrontDetailReportsState');

    // if (sessionStorage.getItem('ReportFlag') == 'Summary')
    //   this.isSummaryReport = true;
    // else if (sessionStorage.getItem('ReportFlag') == 'Monthly')
    //   this.isMonthlyCountReport = true;
    // else if (sessionStorage.getItem('ReportFlag') == 'Yearly')
    //   this.isYearlyCountReport = true;

    this.EBBClaimDetailsReports();
    // this.GetMonths();
    // this.GetYears();
    //this.GetInrollClientCompany();
    this.GetClaimState();
    
    this.monthText.push({ 'id': 1, 'itemName': 'January' });
    this.monthText.push({ 'id': 2, 'itemName': 'February' });
    this.monthText.push({ 'id': 3, 'itemName': 'March' });
    this.monthText.push({ 'id': 4, 'itemName': 'April' });
    this.monthText.push({ 'id': 5, 'itemName': 'May' });
    this.monthText.push({ 'id': 6, 'itemName': 'June' });
    this.monthText.push({ 'id': 7, 'itemName': 'July' });
    this.monthText.push({ 'id': 8, 'itemName': 'August' });
    this.monthText.push({ 'id': 9, 'itemName': 'September' });
    this.monthText.push({ 'id': 10, 'itemName': 'October' });
    this.monthText.push({ 'id': 11, 'itemName': 'November' });
    this.monthText.push({ 'id': 12, 'itemName': 'December' });
  }

  EBBClaimDetailsReports() {
    this.blockDocument();
    this.listOfData = [];
    this.isloading = true;
    this.nladClaimService.GetClaimProcessDetailsReports(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        this.unblockDocument();
        if (data != null) {
          this.listOfData = data['listOfData'];
          if (this.listOfData != null) {
            if (this.listOfData.length > 0) {
              this.totalRecordCount = this.listOfData[0].totalRecords;
            }
            else
              this.totalRecordCount = 0;
          }
          else
            this.totalRecordCount = 0;
        }
      },
      error => {
        this.isloading = false;
        this.unblockDocument();
        console.log(error);
      }
    );
  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.EBBClaimDetailsReports();
  }

  sort(sortBy: any) {
    this.upDowSubscriberIDCSS = '';
    this.upDowRateCSS = '';
    this.upDowReasonCodeCSS = '';
    this.upDowDeviceBenefitCSS = '';
    this.upDowSPINCSS = '';
    this.upDowSACCSS = '';
    this.upDowLastNameCSS = '';
    this.upDowFirstNameCSS = '';
    this.upDowStreetAddressCSS = '';
    this.upDowCityCSS = '';
    this.upDowStateCSS = '';
    this.upDowZipCSS = '';
    this.upDowPhoneNumberCSS = '';
    this.upDowETCGeneralCSS = '';
    this.upDowServiceTypeCSS = '';
    this.upDowTribalBenefitFlagCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowYearsCSS = '';
    this.upDowMonthsCSS = '';

    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'subscriberID': this.upDowSubscriberIDCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'rate': this.upDowRateCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'reasonCode': this.upDowReasonCodeCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'deviceBenefit': this.upDowDeviceBenefitCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'spin': this.upDowSPINCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'sac': this.upDowSACCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'lastName': this.upDowLastNameCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'firstName': this.upDowFirstNameCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'streetAddress': this.upDowStreetAddressCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'city': this.upDowCityCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'state': this.upDowStateCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'zip': this.upDowZipCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'phoneNumber': this.upDowPhoneNumberCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'etcGeneral': this.upDowETCGeneralCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'serviceType': this.upDowServiceTypeCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'tribalBenefitFlag': this.upDowTribalBenefitFlagCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'createdDate': this.upDowCreatedDateCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'years': this.upDowYearsCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'months': this.upDowMonthsCSS = this.isAscending ? 'active' : 'active-up'; break;
      default: break;
    }
    this.OrderBy = sortBy;
    this.EBBClaimDetailsReports();
  }

  // GetMonths() {
  //   this.dropdownSettingMonths = {
  //     singleSelection: true,
  //     text: 'Select Month(s)',
  //     enableSearchFilter: false,
  //     classes: 'borderLessTextBoxGreen',
  //     badgeShowLimit: 1,
  //     noDataLabel: 'No Month',
  //     showCheckbox: false,
  //     enableFilterSelectAll: false
  //   };
  //   this.dropdownMonths.push({ 'id': 1, 'itemName': 'January' });
  //   this.dropdownMonths.push({ 'id': 2, 'itemName': 'February' });
  //   this.dropdownMonths.push({ 'id': 3, 'itemName': 'March' });
  //   this.dropdownMonths.push({ 'id': 4, 'itemName': 'April' });
  //   this.dropdownMonths.push({ 'id': 5, 'itemName': 'May' });
  //   this.dropdownMonths.push({ 'id': 6, 'itemName': 'June' });
  //   this.dropdownMonths.push({ 'id': 7, 'itemName': 'July' });
  //   this.dropdownMonths.push({ 'id': 8, 'itemName': 'August' });
  //   this.dropdownMonths.push({ 'id': 9, 'itemName': 'September' });
  //   this.dropdownMonths.push({ 'id': 10, 'itemName': 'October' });
  //   this.dropdownMonths.push({ 'id': 11, 'itemName': 'November' });
  //   this.dropdownMonths.push({ 'id': 12, 'itemName': 'December' });
  // }

  // onSelectedMonths($event) {
  //   this.listOfMonths = []
  //   this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event.id)
  //   this.listOfMonths.push($event.id);
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.months = this.listOfMonths.join(',')
  //   this.EBBClaimDetailsReports();
  // }

  // onDeSelectedMonths($event) {
  //   this.listOfMonths = []
  //   if (this.listOfMonths != null) {
  //     if (this.listOfMonths.length > 0) {
  //       this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event.id)
  //     }
  //   }
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.months = this.listOfMonths.join(',')
  //   this.EBBClaimDetailsReports();
  // }

  // onSelectAllMonths($event) {
  //   this.listOfMonths = []
  //   for (let iCounter = 0; iCounter < $event.length; iCounter++) {
  //     if (this.listOfMonths != null) {
  //       if (this.listOfMonths.length > 0) {
  //         this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event[iCounter].id)
  //         this.listOfMonths.push($event[iCounter].id);
  //       }
  //       else {
  //         this.listOfMonths.push($event[iCounter].id);
  //       }
  //     }
  //   }
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.months = this.listOfMonths.join(',')
  //   this.EBBClaimDetailsReports();
  // }

  // onDeSelectAllMonths($event) {
  //   this.listOfMonths = [];
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.months = '0'
  //   this.EBBClaimDetailsReports();
  // }

  // GetYears() {
  //   this.nladClaimService.GetClaimYears(this.tokenKey, this.filterModel).subscribe(
  //     data => {
  //       if (data != null) {
  //         let listOfYears = data['listOfData'];
  //         if (listOfYears != null) {
  //           if (listOfYears.length > 0) {

  //             for (let iCounter = 0; iCounter < listOfYears.length; iCounter++) {
  //               this.listOfYears.push({ 'id': listOfYears[iCounter], 'itemName': listOfYears[iCounter] });
  //             }
  //           }
  //         }
  //         this.dropdownSettingYears = {
  //           singleSelection: true,
  //           text: 'Select Year(s)',
  //           enableSearchFilter: false,
  //           classes: 'borderLessTextBoxGreen',
  //           badgeShowLimit: 1,
  //           noDataLabel: 'No Year',
  //           showCheckbox: false,
  //           enableFilterSelectAll: false
  //         };
  //       }
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  // onSelectedYears($event) {
  //   this.listOfYears = []
  //   this.listOfYears = this.removeElemetninArrayById(this.listOfYears, $event.id)
  //   this.listOfYears.push($event.id);
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.years = this.listOfYears.join(',')
  //   this.EBBClaimDetailsReports();
  // }

  // onDeSelectedYears($event) {
  //   this.listOfYears = []
  //   if (this.listOfYears != null) {
  //     if (this.listOfYears.length > 0) {
  //       this.listOfYears = this.removeElemetninArrayById(this.listOfYears, $event.id)
  //     }
  //   }
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.years = this.listOfYears.join(',')
  //   this.EBBClaimDetailsReports();
  // }

  // onSelectAllYears($event) {
  //   this.listOfYears = []
  //   for (let iCounter = 0; iCounter < $event.length; iCounter++) {
  //     if (this.listOfYears != null) {
  //       if (this.listOfYears.length > 0) {
  //         this.listOfYears = this.removeElemetninArrayById(this.listOfYears, $event[iCounter].id)
  //         this.listOfYears.push($event[iCounter].id);
  //       }
  //       else {
  //         this.listOfYears.push($event[iCounter].id);
  //       }
  //     }
  //   }
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.years = this.listOfYears.join(',')
  //   this.EBBClaimDetailsReports();
  // }

  // onDeSelectAllYears($event) {
  //   this.listOfYears = [];
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.years = '0'
  //   this.EBBClaimDetailsReports();
  // }

  // GetInrollClientCompany() {
  //   this.nladClaimService.GetInrollClientCompany().subscribe(
  //     data => {
  //       this.dropdownSettingCompanies = {
  //         singleSelection: true,
  //         text: 'Filter by Company',
  //         enableSearchFilter: false,
  //         classes: 'borderLessTextBoxGreen',
  //         badgeShowLimit: 1,
  //         noDataLabel: 'No company',
  //         showCheckbox: false,
  //         enableFilterSelectAll: false
  //       };

  //       if (data != null) {
  //         if (data.status == 'Success') {
  //           this.listOfInrollProductCompany = data.listofInrollCompany;
  //           if (this.listOfInrollProductCompany != null) {
  //             if (this.listOfInrollProductCompany.length > 0) {
  //               for (let iCounter = 0; iCounter < this.listOfInrollProductCompany.length; iCounter++) {
  //                 this.dropdownCompanies.push({ 'id': this.listOfInrollProductCompany[iCounter].companyId, 'itemName': this.listOfInrollProductCompany[iCounter].companyName });
  //               }
  //             }
  //           }
  //         }
  //       }
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  // onSelectedCompanies($event) {

  //   this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event.id)
  //   this.listOfCompany.push($event.id);
  //   this.filterModel.pageNumber = '1';
  //   this.filterModel.providerId = $event.id;
  //   this.EBBClaimDetailsReports();
  // }

  // onDeSelectedCompanies($event) {
  //   if (this.listOfCompany != null) {
  //     if (this.listOfCompany.length > 0) {
  //       this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event.id)
  //     }
  //   }
  //   this.filterModel.pageNumber = '1';
  //   this.filterModel.providerId = $event.id;
  //   this.EBBClaimDetailsReports();
  // }

  // onSelectAllCompanies($event) {
  //   for (let iCounter = 0; iCounter < $event.length; iCounter++) {
  //     if (this.listOfCompany != null) {
  //       if (this.listOfCompany.length > 0) {
  //         this.listOfCompany = this.removeElemetninArrayById(this.listOfCompany, $event[iCounter].id)
  //         this.listOfCompany.push($event[iCounter].id);
  //       }
  //       else {
  //         this.listOfCompany.push($event[iCounter].id);
  //       }
  //     }
  //   }
  //   this.filterModel.pageNumber = '1';
  //   this.filterModel.providerId = $event[0].id;
  //   this.EBBClaimDetailsReports();
  // }

  // onDeSelectAllCompanies($event) {
  //   this.listOfCompany = [];
  //   this.filterModel.pageNumber = '1';
  //   this.filterModel.providerId = '0';
  //   this.EBBClaimDetailsReports();
  // }

  setMonthsText(id) {
    return this.monthText.filter(m => m.id == id)[0].itemName;
  }

  removeElemetninArrayById(array, element) {
    return array.filter(e => e.id !== element);
  }

  GetClaimState() {
    this.nladClaimService.GetClaimState(this.tokenKey).subscribe(
      data => {
        if (data != null) {

          this.listOfStateData = data['listOfData'];
          if (this.listOfStateData != null) {
            if (this.listOfStateData.length > 0) {

              for (let iCounter = 0; iCounter < this.listOfStateData.length; iCounter++) {
                this.dropdownStates.push({ 'id': this.listOfStateData[iCounter].shortName, 'itemName': this.listOfStateData[iCounter].name });
              }
            }
          }
          this.dropdownSettingStates = {
            singleSelection: true,
            text: 'Select State',
            enableSearchFilter: true,
            classes: 'borderLessTextBoxGreen',
            badgeShowLimit: 1,
            noDataLabel: 'No State',
            showCheckbox: false,
            enableFilterSelectAll: false
          };

          if(this.filterModel.stateName!="0")
          {
          let stateName = this.dropdownStates.filter(m => m.id == this.filterModel.stateName);         
          this.selectedItemsStates=stateName;
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedStates($event) {
    this.listOfStates = this.removeElemetninArrayById(this.listOfStates, $event.id)
    this.listOfStates.push($event.id);
    this.filterModel.pageNumber = '1';
    this.filterModel.state = $event.id;
    this.filterModel.stateName = $event.id;
    this.EBBClaimDetailsReports();
  }

  onDeSelectedStates($event) {
    if (this.listOfStates != null) {
      if (this.listOfStates.length > 0) {
        this.listOfStates = this.removeElemetninArrayById(this.listOfStates, $event.id)
      }
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.state = $event.id;
    this.EBBClaimDetailsReports();
  }

  onSelectAllStates($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStates != null) {
        if (this.listOfStates.length > 0) {
          this.listOfStates = this.removeElemetninArrayById(this.listOfStates, $event[iCounter].id)
          this.listOfStates.push($event[iCounter].id);
        }
        else {
          this.listOfStates.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.state = $event[0].id;
    this.EBBClaimDetailsReports();
  }

  onDeSelectAllStates($event) {
    this.listOfStates = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.state = '';
    this.EBBClaimDetailsReports();
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  redirectToListPage() {
    if (sessionStorage.getItem('FrontDetailReportsYear') != null)
      sessionStorage.removeItem('FrontDetailReportsYear');
    if (sessionStorage.getItem('FrontDetailReportsMonths') != null)
      sessionStorage.removeItem('FrontDetailReportsMonths');
    if (sessionStorage.getItem('FrontDetailReportsProviderId') != null)
      sessionStorage.removeItem('FrontDetailReportsProviderId');
    if (sessionStorage.getItem('FrontDetailReportsCompanyName') != null)
      sessionStorage.removeItem('FrontDetailReportsCompanyName');
    this.router.navigate(['/Claim/Reports']);
  }
}
