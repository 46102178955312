import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CrraDepositeMasterService } from 'src/Services/crra-deposite-master.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-add-update-deposite',
  templateUrl: './add-update-deposite.component.html',
  styleUrls: ['./add-update-deposite.component.css']
})
export class AddUpdateDepositeComponent implements OnInit {

  depositeMasterForm: FormGroup;
  userId: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  stateList: StateMasterModel[] = [];
  selectedState = [];
  isEdit: boolean = false;
  getStateId: string = '';

  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};

  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  module = "CRRA";
  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  rate: boolean ;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute,private router: Router,
    private stateService: StateMasterService,private datePipe: DatePipe,private toastr: ToastrService, 
    private crradepositeService: CrraDepositeMasterService) {

    this.tokenKey = sessionStorage.getItem('tokenValue');  
    this.getState();
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.getStateId = '';

    this.dropdownSettingsState = {
      singleSelection: true,
      text: "Select Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.depositeMasterForm = this._fb.group({
      payRateId: 0,
      stateId: ['', Validators.required],
      ixcDep: [''],
      clecDep: [''],
      ixcPay: [''],
      clecPay: [''],
      rate: [''],
      notes: [''],
    })

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetDepositeById(params['id']);
        }
      }
    });
  }

  GetDepositeById(payRateId) {
    this.crradepositeService.GetDepositeById(this.tokenKey, parseInt(payRateId.toString())).subscribe(
      result => {

        if (result['status'] == 'Success') {
          this.depositeMasterForm.patchValue({
            payRateId: result['crradeposite'].payRateId,
            stateId: result['crradeposite'].stateId,
            ixcDep: result['crradeposite'].ixcDep,
            clecDep: result['crradeposite'].clecDep,
            ixcPay: result['crradeposite'].ixcPay,
            clecPay: result['crradeposite'].clecPay,
            rate: result['crradeposite'].rate,
            notes: result['crradeposite'].notes
            
          });

          if (this.stateList.length > 0) {
            let data = this.stateList.filter(x => x.stateId == result['crradeposite'].stateId)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
          else {
            this.getStateId = result['crradeposite'].stateId;
          }
        }
      });
  }

  cancelDeposite() {
    let navigation = '/admin/' + this.SelectedTool + '/deposite';
    this.router.navigate([navigation]);
  }

  save() {
    this.isSubmit = true;
    let data = this.depositeMasterForm.value;
    if (!this.depositeMasterForm.valid)
      return
    data["createdBy"] = this.userId;
    let value = {
      payRateId: data.payRateId,
      stateId: data.stateId,
      ixcDep: data.ixcDep.trim(),
      clecDep: data.clecDep.trim(),
      ixcPay: data.ixcPay.trim(),
      clecPay: data.clecPay.trim(),
      rate: this.rate,
      notes: data.notes.trim()
    }
    this.crradepositeService.AddUpdateDeposite(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.payRateId > 0)
              this.showMessage('Deposite updated successfully', 'Success', 'success');
            else
              this.showMessage('Deposite saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/deposite']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
          if (this.getStateId != '') {
            let data = this.stateList.filter(x => x.stateId == this.getStateId)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
        }
      },
      error => { }
    );
  }
  onDeSelectDateFilterStateAll($event) {
    this.selectedState = [];
  }

  onChangeFund(event) {
    this.depositeMasterForm.patchValue({
      stateId: event.id
    });
  }

  setRate($event) {
    this.rate = $event.target.checked;
  }
}

