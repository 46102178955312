
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IprUpdateImportService {

  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }
  IPRImportUpdates(iprUpdates, fupdImportUpdates: File, TokenKey: string): Observable<any> {
    let formData = new FormData();
    formData.append('file', fupdImportUpdates, fupdImportUpdates.name);
    formData.append('createdBy', iprUpdates.value["createdBy"]);    
    const httpOptions = { headers: new HttpHeaders({ 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/IPRUpdates/IPRImportUpdates', formData,  httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
