
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MatrixFundService {
  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }
  GetMatrixByStateId(TokenKey: string, StateId: string, userID: string, ProductId: string): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/MatrixFund/GetMatrixByStateId/' + StateId + '/' + userID + '/' + ProductId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetMatrixFundFilterByPage(TokenKey: string, PageNumber: string, PageSize: string, WhereCondition: string, OrderBy: string, keywordFilter: string, productId: string): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/MatrixFund/GetMatrixFundFilterByPage/' + PageNumber + '/' + PageSize + '/' + WhereCondition + '/' + OrderBy + '/' + keywordFilter + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetAssessmentCategoryAll(TokenKey: string, productId: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/MatrixFund/GetMatrixFundFilterByPage/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions)
      .pipe(catchError(this.handleError));
  }
  addupdatematrixfund(matrixfund, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/MatrixFund/AddUpdateMatrixFund', matrixfund, httpOptions)
      .pipe(catchError(this.handleError));
  }
  updatematrixfundDisplayOrder(TokenKey: string, matrixfund): Observable<number> {
    let fullAPIPath = this.AppUrl + 'api/MatrixFund/UpdateMatrixFundDisplayOrder';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<number>(fullAPIPath, matrixfund, httpOptions)
      .pipe(catchError(this.handleError));
  }
  deletefund(TokenKey: string, matrixfundId: number): Observable<any[]> {
    let data = '/' + matrixfundId;
    let fullAPIPath = this.AppUrl + 'api/MatrixFund/DeleteFund' + data;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any[]>(fullAPIPath, httpOptions)
      .pipe(catchError(this.handleError));
  }
  activeinactivefund(TokenKey: string, matrixfundId: string, isactive: string): Observable<any[]> {
    let data = '/' + matrixfundId;
    let fullAPIPath = this.AppUrl + 'api/MatrixFund/ActiveInActiveFund' + data + '/' + isactive;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any[]>(fullAPIPath, httpOptions)
      .pipe(catchError(this.handleError));
  }
  addupdateotherrequirment(user, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/MatrixFund/AddUpdateOtherRequirements', user, httpOptions)
      .pipe(catchError(this.handleError));
  }
  updateotherrequirmentDisplayOrder(TokenKey: string, matrixfund): Observable<number> {
    let fullAPIPath = this.AppUrl + 'api/MatrixFund/UpdateOtherRequirementsDisplayOrder';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<number>(fullAPIPath, matrixfund, httpOptions)
      .pipe(catchError(this.handleError));
  }
  deleteotherreq(TokenKey: string, deleteotherreqId: number): Observable<any[]> {
    let data = '/' + deleteotherreqId;
    let fullAPIPath = this.AppUrl + 'api/MatrixFund/DeleteOtherRequirements' + data;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any[]>(fullAPIPath, httpOptions)
      .pipe(catchError(this.handleError));
  }
  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
