import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'src/Services/report.service';
import { CheckAuth } from 'src/Services/utility.service';
import { UpdatesService } from 'src/Services/updatesService';
import * as FileSaver from 'file-saver';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { WirelessFilterModel } from 'src/Models/ReportModel';
import { WirelessJurisdictionModel } from 'src/Models/WirelessJurisdictionModel';

@Component({
  selector: 'app-wireless-jurisdiction-reports',
  templateUrl: './jurisdiction-reports.component.html',
  styleUrls: ['./jurisdiction-reports.component.css']
})
export class WirelessJurisdictionReportsComponent implements OnInit {
  
  tokenKey: string = '';
  filterModel = new WirelessFilterModel();
  isloading = false;

  rangeDates: Date[] = [];
  fromDateFilter: string = '';
  toDateFilter: string = '';

  documentForm = new FormGroup({
    rangeDates: new FormControl(),
  });

  listOfData: WirelessJurisdictionModel[];

  basicData: any;
  basicOptions: any;

  stateNameArray:any=[];
  titleCountArray:any=[];

  constructor(private checkAuth: CheckAuth, private titleService: Title,
    private reportService: ReportService, private updatesService: UpdatesService,
    private toastrService: ToastrService) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Updates Counts By Jurisdiction');
  }

  ngOnInit() {
    this.movetotop();
    this.setCurrentMonthDateRange();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  getWirelessByJurisdictionReport() {
    this.listOfData = [];
    this.isloading = true;
    this.reportService.WirelessByJurisdictionReport(this.tokenKey, this.filterModel).subscribe(data => {
      this.isloading = false;
      if (data) {
        if (data.status == "Success") {
          this.stateNameArray = [];
          this.titleCountArray = [];
          this.listOfData = data.wirelessJurisdictionReports;
          if (this.listOfData.length > 0) {
            for (let i = 0; i < this.listOfData.length; i++) {
              this.stateNameArray.push(this.listOfData[i].stateName);
              this.titleCountArray.push(this.listOfData[i].titleCount);
            }
          }
          else {
            this.stateNameArray = [];
            this.titleCountArray = [];
          }

          this.basicData = {
            labels: this.stateNameArray,
            datasets: [
              {
                label: 'Wireless Updates Count',
                data: this.titleCountArray,
                borderColor: '#000958',
                backgroundColor: '#000958',
                borderWidth: 1
              }
            ]
          };

          this.basicOptions = {
            plugins: {
              legend: {
                labels: {
                  color: '#000'
                }
              }
            }
          };

        }
        else {
          this.toastrService.error("Error", "error");
        }
      }
    }, error => {
      this.isloading = false;
    })
  }


  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }

  exportToExcel() {
    if (this.listOfData.length > 0) {
      var uri = 'data:application/vnd.ms-excel;base64,'
        , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
        , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
        , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
      let html = '<table>';
     
      html += document.getElementById("reportHeader").innerHTML + " " + document.getElementById("reportBody").innerHTML;
      html += "</table>";
      var ctx = { worksheet: 'Worksheet', table: html }
      let dt = this.transformDate(new Date());
      FileSaver.saveAs((uri + base64(format(template, ctx))), "WirelessJurisdictionReport_" + dt + ".xls");
    }
    else {
      this.toastrService.error("No record found", "error");
    }
  }

  transformDate(array) {
    if (array != null) {

      let dd = array.getDate().toString();
      if (parseInt(dd.toString()) < 10)
        dd = '0' + dd.toString();

      let mm = (array.getMonth() + 1).toString();
      if (parseInt(mm.toString()) < 10)
        mm = '0' + mm.toString();

      let hrs = array.getHours();
      if (parseInt(hrs.toString()) < 10)
        hrs = '0' + hrs.toString();

      let min = array.getMinutes()
      if (parseInt(min.toString()) < 10)
        min = '0' + min.toString();

      let sec = array.getSeconds()
      if (parseInt(sec.toString()) < 10)
        sec = '0' + sec.toString();

      return array.getFullYear() + '_' + mm + '_' + dd + '_' + hrs + '_' + min + '_' + sec;
    }
  }

  isDateFilter(dateArray: any) {
    
    this.rangeDates = dateArray;

    if (this.rangeDates != null) {
      if (this.rangeDates.length > 1) {
        let dd1 = this.rangeDates[0].getDate().toString();
        if (parseInt(dd1.toString()) < 10)
          dd1 = '0' + dd1.toString();

        let mm1 = (this.rangeDates[0].getMonth() + 1).toString();
        if (parseInt(mm1.toString()) < 10)
          mm1 = '0' + mm1.toString();

        let dd2 = this.rangeDates[1].getDate().toString();
        if (parseInt(dd2.toString()) < 10)
          dd2 = '0' + dd2.toString();

        let mm2 = (this.rangeDates[1].getMonth() + 1).toString();
        if (parseInt(mm2.toString()) < 10)
          mm2 = '0' + mm2.toString();

        this.fromDateFilter = this.rangeDates[0].getFullYear() + mm1 + dd1;
        this.toDateFilter = this.rangeDates[1].getFullYear() + mm2 + dd2;
      }
      if (this.fromDateFilter === null || this.fromDateFilter === '') {
        this.fromDateFilter = "null";
        this.toDateFilter = "null";
      }
    }
    else {
      this.fromDateFilter = "null";
      this.toDateFilter = "null";
    }
    if (this.fromDateFilter != 'null' && this.toDateFilter != 'null') {
      this.filterModel.fromDate = this.fromDateFilter;
      this.filterModel.toDate = this.toDateFilter;
      this.getWirelessByJurisdictionReport();
    }

  }

  dateFilterClear() {
    this.rangeDates = [];
    this.fromDateFilter = null;
    this.toDateFilter = null;
    this.filterModel.fromDate = null;
    this.filterModel.toDate = null;    
    this.getWirelessByJurisdictionReport();
  }

  setCurrentMonthDateRange() {
    const startOfMonth = moment().startOf('month').toDate();
    const endOfMonth = moment().endOf('month').toDate();
    this.rangeDates = [startOfMonth, endOfMonth];
  }
}
