import { Component, OnInit, EventEmitter, Output, Inject, Input } from '@angular/core';
import { ActionItemTypeModel } from '../../../Models/ActionItemTypeModel';
import { CarrierCategoryModel } from '../../../Models/carrierCategoryModel';
import { ActionItemService } from '../../../Services/actionitem.service';
import { ActionCalendarSearch } from 'src/Models/actionCalendarSearchModel';
import { getDate, getMonth } from 'ngx-bootstrap/chronos/utils/date-getters';
import { MatrixService } from 'src/Services/matrix.service';
import { ToolEnum } from '../../../Models/Common';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { setMonth } from 'ngx-bootstrap/chronos/utils/date-setters';
import { ActionStatus } from '../../../Models/actionStatusModel';
import { setISODayOfWeek } from 'ngx-bootstrap/chronos/units/day-of-week';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../../../Services/app.config';
import { FavoriteModel } from '../../../Models/FavoriteModel';

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.css']
})
export class SearchPanelComponent implements OnInit {
  @Output('parentFun') parentFun: EventEmitter<any> = new EventEmitter();
  @Output('showdescription') showdescription: EventEmitter<any> = new EventEmitter();
  @Output('downloadFile') downloadFile: EventEmitter<any> = new EventEmitter();

  listOfStateId: string = '';
  listOfState: StateMasterModel[];
  actionItemsList: ActionItemTypeModel[];
  searchModal = new ActionCalendarSearch();
  selectedActionTypeIds: any[];
  selectedCareerIds: any[];
  selectedStatus: any[];
  loginUser: any;
  chkselectAll: boolean = false;
  productId: number = 1;
  carrierCategoryModel: CarrierCategoryModel[];
  tokenKey: string = null;
  isSidePanelActive: boolean = true;
  selectedStateCounter: number;
  isStateRecord: boolean = false;
  selectAllID: string[];
  listOfID: any[];
  ClientSelectedTool: string;
  CurrentTime = new Date();
  EndTime = new Date();
  bsRangeValue: Date[];
  isListShow = false;
  actionStatus: any[] = [];
  month: number;
  n: number = 3;
  baseUrl: string
  appModel: any;
  isICalSubscription: boolean = false;

  //#region Dashboard 
  @Input('dashboardSummaryType') dashboardSummaryType = 0;
  @Input('dashboardCarrierCategories') dashboardCarrierCategories = 0;
  @Input('dashboardActionStatus') dashboardActionStatus = 0;
  //#endregion

  FilterFavourite: boolean = false;
  favouriteModel = new FavoriteModel();
  httpAppUrl: string = "";
  webCalAppUrl: string = "";
  emailDeadlineDate: string = '';

  dateTrack: number = 0;
  emailDateTrack: number = 0;

  isActionTypeSelectAllChecked: boolean = true;
  isCarrierCategorySelectAllChecked: boolean = true;
  isActionStatusSelectAllChecked: boolean = true;

  MyTracIt: boolean = false;

  constructor(private toastr: ToastrService,
    private actionServices: ActionItemService, private matrix: MatrixService) {
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.listOfStateId = 'null';
    this.selectedStateCounter = 0;
    this.listOfID = [];
    this.ClientSelectedTool = sessionStorage.getItem('ClientLoginTool');
    this.productId = ToolEnum[this.ClientSelectedTool];
    this.loginUser = sessionStorage.getItem('UserId');
    this.searchModal.userId = +(sessionStorage.getItem('UserId'));
    this.searchModal.isNeworChanged = false;
    this.searchModal.actionTypeIds = '';
    this.searchModal.carrierCatIds = '';
    this.searchModal.stateIds = '';
    this.searchModal.statusIds = '';

    // if ((sessionStorage.getItem('dashboardFromDate') != '' && sessionStorage.getItem('dashboardFromDate') != null) && (sessionStorage.getItem('dashboardToDate') != '' && sessionStorage.getItem('dashboardToDate') != null)) {
    //   this.searchModal.dateFrom = sessionStorage.getItem('dashboardFromDate');
    //   this.searchModal.toDate = sessionStorage.getItem('dashboardToDate');
    //   let CurrentTime = new Date(this.searchModal.dateFrom);
    //   let EndTime = new Date(this.searchModal.toDate);
    //   this.bsRangeValue = [CurrentTime, EndTime];
    //   sessionStorage.removeItem('dashboardFromDate');
    //   sessionStorage.removeItem('dashboardToDate');
    // }
    // else if (sessionStorage.getItem('dashboardFromDate') == null) {
    //   //this.searchModal.dateFrom = this.CurrentTime.getFullYear() + '-' + (this.CurrentTime.getMonth() + 1) + '-' + this.CurrentTime.getDate();
    //   //this.searchModal.toDate = this.CurrentTime.getFullYear() + '-' + (this.CurrentTime.getMonth() + 2) + '-' + this.CurrentTime.getDate();
    //   this.EndTime.setDate(this.EndTime.getDate() + 30);
    //   this.bsRangeValue = [this.CurrentTime, this.EndTime];
    // }

    this.setEmailAutoLoginParameter();
    this.isICalSubscription = sessionStorage.getItem('IsICalSubscription') == 'true' ? true : false;
  }


  ngOnInit() {

    this.GetRightPanelStateList();
    this.setAPIUrl();
    //this.parentFun.emit(this.searchModal);

  }

  setEmailAutoLoginParameter() {

    if (sessionStorage.getItem('emailDeadlineDate') != '' && sessionStorage.getItem('emailDeadlineDate') != null) {
      
      let dddt = new Date(sessionStorage.getItem('emailDeadlineDate'));
      let dd1 = dddt.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dddt.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      this.emailDeadlineDate = mm1 + '/' + dd1 + '/' + dddt.getFullYear();
      let CurrentTime = new Date(sessionStorage.getItem('emailDeadlineDate'));
      let EndTime = new Date(sessionStorage.getItem('emailDeadlineDate'));
      this.searchModal.dateFrom = this.emailDeadlineDate;
      this.searchModal.toDate = this.emailDeadlineDate;
      this.bsRangeValue = [CurrentTime, EndTime];
    }
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.httpAppUrl = sessionStorage.getItem("BaseURL") || '';
      this.httpAppUrl += 'ActionItemSync/ImportCalendar/' + sessionStorage.getItem('EncyUserId');
      this.webCalAppUrl = this.httpAppUrl.replace("https://", "webcal://");
      this.webCalAppUrl = this.httpAppUrl.replace("http://", "webcal://");
    }
  }
  populateActionItems() {
    this.actionServices.populateActionItems(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        this.actionItemsList = data.result.objActionItemsTypeModel;
        this.carrierCategoryModel = data.result.objCarrierCategoryModel;
        this.actionStatus = data.result.objActionStatusModel;

        this.selectedActionTypeIds = [];
        this.selectedCareerIds = [];
        this.selectedStatus = [];

        let seprator = '';
        for (let index = 0; index < this.actionItemsList.length; index++) {
          const element = this.actionItemsList[index].actionItemTypeId;
          // Set Cheked/Unchecked Value When Click on Dashboard          
          if (this.dashboardSummaryType > 0) {
            if (this.dashboardSummaryType == this.actionItemsList[index].actionItemTypeId) {
              this.actionItemsList[index].isDefaultChecked = true;
              this.searchModal.actionTypeIds = this.actionItemsList[index].actionItemTypeId.toString();
            }
            else {
              this.actionItemsList[index].isDefaultChecked = false;
            }
          }
          else {
            this.actionItemsList[index].isDefaultChecked = true;
            this.searchModal.actionTypeIds += seprator + this.actionItemsList[index].actionItemTypeId;
            seprator = ',';
            this.selectedActionTypeIds.push(element);
          }
        }

        seprator = '';
        for (let index = 0; index < this.carrierCategoryModel.length; index++) {
          const element = this.carrierCategoryModel[index].carrierCatId;
          // Set Cheked/Unchecked Value When Click on Dashboard      
          if (this.dashboardCarrierCategories > 0) {
            if (this.dashboardCarrierCategories == this.carrierCategoryModel[index].carrierCatId) {
              this.carrierCategoryModel[index].isDefaultChecked = true;
              this.searchModal.carrierCatIds = this.carrierCategoryModel[index].carrierCatId.toString();
            }
            else {
              this.carrierCategoryModel[index].isDefaultChecked = false;
            }
          }
          else {
            this.carrierCategoryModel[index].isDefaultChecked = true;
            this.searchModal.carrierCatIds += seprator + this.carrierCategoryModel[index].carrierCatId;
            seprator = ',';
            this.selectedCareerIds.push(element);
          }
        }

        seprator = '';
        for (let index = 0; index < this.actionStatus.length; index++) {
          const element = this.actionStatus[index].statusId;
          // Set Cheked/Unchecked Value When Click on Dashboard    
          if (this.dashboardActionStatus > 0) {
            if (this.dashboardActionStatus == this.actionStatus[index].statusId) {
              this.actionStatus[index].isDefaultChecked = true;
              this.searchModal.statusIds = this.actionStatus[index].statusId.toString();
            }
            else {
              this.actionStatus[index].isDefaultChecked = false;
            }
          }
          else {
            //this.actionStatus[index].isDefaultChecked = true;
            
            if (this.actionStatus[index].isDefaultChecked) {
              this.searchModal.statusIds += seprator + this.actionStatus[index].statusId;
              seprator = ',';
              this.selectedStatus.push(element);
            }
          }
        }


        // const actionIDString = this.selectedActionTypeIds.join(',');
        // const careerIDString = this.selectedCareerIds.join(',');
        // const actionStaIDString = this.selectedStatus.join(',');
        // this.searchModal.actionTypeIds = actionIDString;
        // this.searchModal.carrierCatIds = careerIDString;
        // this.searchModal.statusIds = actionStaIDString;

        // this.searchModal.dateFrom = this.CurrentTime.getFullYear() + '-' + (this.CurrentTime.getMonth() + 1) + '-' + this.CurrentTime.getDate();
        // this.searchModal.toDate = this.CurrentTime.getFullYear() + '-' + (this.CurrentTime.getMonth() + 2) + '-' + this.CurrentTime.getDate();
        
        this.FilterFavourite = sessionStorage.getItem('dashboardIsFavorite') == 'true' ? true : false;
        this.MyTracIt = sessionStorage.getItem('dashboardIsMyTracIt') == 'true' ? true : false;
        if (this.FilterFavourite)
          this.loadMyFavourite();

        // if (this.MyTracIt)
        //   this.loadMyTracIt();

        this.searchModal.emailDeadlineDate = this.emailDeadlineDate;
        this.searchModal.isFavorite = this.FilterFavourite;
        this.searchModal.isMyTracIt = this.MyTracIt;
        this.parentFun.emit(this.searchModal);
      }

      if (this.MyTracIt) {
        this.searchModal.isMyTracIt = this.MyTracIt;
      }
    });
  }

  selectAllActionType($event: any) {
    this.selectedActionTypeIds = [];

    for (let iCounter = 0; iCounter < this.actionItemsList.length; iCounter++) {
      this.actionItemsList[iCounter].isDefaultChecked = $event.currentTarget.checked;
      this.selectedActionTypeIds.push(this.actionItemsList[iCounter].actionItemTypeId);
    }

    if (!$event.currentTarget.checked) {
      this.selectedActionTypeIds = [];
    }

    const str = this.selectedActionTypeIds.join(',');
    this.searchModal.actionTypeIds = str;
    this.searchModal.emailDeadlineDate = this.emailDeadlineDate;
    this.searchModal.isFavorite = this.FilterFavourite;
    this.searchModal.isMyTracIt = this.MyTracIt;
    this.searchModal.dateFrom = null;
    this.searchModal.toDate = null;
    this.parentFun.emit(this.searchModal);

  }

  onClick($event, a: any) {
    
    this.FilterFavourite = false;
    this.MyTracIt = false;
    if (this.searchModal.actionTypeIds != null && typeof (this.searchModal.actionTypeIds) != undefined && this.searchModal.actionTypeIds != '') {
      let array = this.searchModal.actionTypeIds.split(',');
      for (let iCounter = 0; iCounter < array.length; iCounter++) {
        if (this.selectedActionTypeIds.length > 0)
          this.selectedActionTypeIds = this.removeElemetninArray(this.selectedActionTypeIds, parseInt(array[iCounter]));
        this.selectedActionTypeIds.push(parseInt(array[iCounter]));
      }
    }

    const found = this.selectedActionTypeIds.find(element => element === a);
    if (found !== undefined) {
      const index: number = this.selectedActionTypeIds.indexOf(a);
      if (index !== -1) {
        this.selectedActionTypeIds.splice(index, 1);
      }
    } else {
      if ($event.currentTarget.checked)
        this.selectedActionTypeIds.push(a);
    }

    for (let iCounter = 0; iCounter < this.actionItemsList.length; iCounter++) {
      if (a == this.actionItemsList[iCounter].actionItemTypeId) {
        this.actionItemsList[iCounter].isDefaultChecked = $event.currentTarget.checked;
      }
    }

    if (this.selectedActionTypeIds.length == this.actionItemsList.length)
      this.isActionTypeSelectAllChecked = true;
    else
      this.isActionTypeSelectAllChecked = false;

    const str = this.selectedActionTypeIds.join(',');
    this.searchModal.actionTypeIds = str;
    this.searchModal.emailDeadlineDate = this.emailDeadlineDate;
    this.searchModal.isFavorite = this.FilterFavourite;
    this.searchModal.isMyTracIt = this.MyTracIt;
    this.searchModal.dateFrom = null;
    this.searchModal.toDate = null;
    this.parentFun.emit(this.searchModal);
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  selectAllCarrierCategory($event) {
    this.selectedCareerIds = [];

    for (let iCounter = 0; iCounter < this.carrierCategoryModel.length; iCounter++) {
      this.carrierCategoryModel[iCounter].isDefaultChecked = $event.currentTarget.checked;
      this.selectedCareerIds.push(this.carrierCategoryModel[iCounter].carrierCatId);
    }

    if (!$event.currentTarget.checked) {
      this.selectedCareerIds = [];
    }

   
    const str = this.selectedCareerIds.join(',');
    this.searchModal.carrierCatIds = str;
    this.searchModal.dateFrom = null;
    this.searchModal.toDate = null;
    this.searchModal.emailDeadlineDate = this.emailDeadlineDate;
    this.searchModal.isFavorite = this.FilterFavourite;
    this.searchModal.isMyTracIt = this.MyTracIt;
    this.parentFun.emit(this.searchModal);
  }


  onCareerCatChange($event, a: any) {
    this.FilterFavourite = false;
    this.MyTracIt = false;
    if (this.searchModal.carrierCatIds != null && typeof (this.searchModal.carrierCatIds) != undefined && this.searchModal.carrierCatIds != '') {
      let array = this.searchModal.carrierCatIds.split(',');
      for (let iCounter = 0; iCounter < array.length; iCounter++) {
        if (this.selectedCareerIds.length > 0)
          this.selectedCareerIds = this.removeElemetninArray(this.selectedCareerIds, parseInt(array[iCounter]));
        this.selectedCareerIds.push(parseInt(array[iCounter]));
      }
    }

    const found = this.selectedCareerIds.find(element => element === a);
    if (found !== undefined) {
      const index: number = this.selectedCareerIds.indexOf(a);
      if (index !== -1) {
        this.selectedCareerIds.splice(index, 1);
      }
    } else {
      if ($event.currentTarget.checked)
        this.selectedCareerIds.push(a);
    }

    for (let iCounter = 0; iCounter < this.carrierCategoryModel.length; iCounter++) {
      if (a == this.carrierCategoryModel[iCounter].carrierCatId) {
        this.carrierCategoryModel[iCounter].isDefaultChecked = $event.currentTarget.checked;
      }
    }

    if (this.carrierCategoryModel.length == this.selectedCareerIds.length)
      this.isCarrierCategorySelectAllChecked = true;
    else
      this.isCarrierCategorySelectAllChecked = false;

    const str = this.selectedCareerIds.join(',');
    this.searchModal.carrierCatIds = str;
    this.searchModal.dateFrom = null;
    this.searchModal.toDate = null;
    this.searchModal.emailDeadlineDate = this.emailDeadlineDate;
    this.searchModal.isFavorite = this.FilterFavourite;
    this.searchModal.isMyTracIt = this.MyTracIt;
    this.parentFun.emit(this.searchModal);
  }

  onDescriptionChange($event) {

    this.showdescription.emit($event);
  }

  selectAllActionStatus($event) {
    this.selectedStatus = [];

    for (let iCounter = 0; iCounter < this.actionStatus.length; iCounter++) {
      this.actionStatus[iCounter].isDefaultChecked = $event.currentTarget.checked;
      this.selectedStatus.push(this.actionStatus[iCounter].statusId);
    }

    if (!$event.currentTarget.checked) {
      this.selectedStatus = [];
    }

    const str = this.selectedStatus.join(',');
    this.searchModal.statusIds = str;
    this.searchModal.emailDeadlineDate = this.emailDeadlineDate;
    this.searchModal.isFavorite = this.FilterFavourite;
    this.searchModal.isMyTracIt = this.MyTracIt;
    this.searchModal.dateFrom = null;
    this.searchModal.toDate = null;
    this.parentFun.emit(this.searchModal);
  }

  onStatusChange($event, a: any) {
  
    if (this.searchModal.statusIds != null && typeof (this.searchModal.statusIds) != undefined && this.searchModal.statusIds != '') {
      let array = this.searchModal.statusIds.split(',');
      for (let iCounter = 0; iCounter < array.length; iCounter++) {
        if (this.selectedStatus.length > 0)
          this.selectedStatus = this.removeElemetninArray(this.selectedStatus, parseInt(array[iCounter]));
        this.selectedStatus.push(parseInt(array[iCounter]));
      }
    }
    const found = this.selectedStatus.find(element => element === a);
    if (found !== undefined) {
      const index: number = this.selectedStatus.indexOf(a);
      if (index !== -1) {
        this.selectedStatus.splice(index, 1);
      }

    } else {
      if ($event.currentTarget.checked)
        this.selectedStatus.push(a);
    }

    for (let iCounter = 0; iCounter < this.actionStatus.length; iCounter++) {
      if (a == this.actionStatus[iCounter].carrierCatId) {
        this.actionStatus[iCounter].isDefaultChecked = $event.currentTarget.checked;
      }
    }

    if (this.actionStatus.length == this.selectedStatus.length)
      this.isActionStatusSelectAllChecked = true;
    else
      this.isActionStatusSelectAllChecked = false;


    const str = this.selectedStatus.join(',');
    this.searchModal.statusIds = str;
    this.searchModal.emailDeadlineDate = this.emailDeadlineDate;
    this.searchModal.isFavorite = this.FilterFavourite;
    this.searchModal.isMyTracIt = this.MyTracIt;
    this.searchModal.dateFrom = null;
    this.searchModal.toDate = null;
    this.parentFun.emit(this.searchModal);
  }

  // Side Panel Events
  ShowHideSidePanel() {
    this.isSidePanelActive = !this.isSidePanelActive;
  }

  GetRightPanelStateList() {
    this.isStateRecord = false;
    this.selectAllID = [];
    this.listOfID = [];

    this.matrix.getState(this.tokenKey, this.loginUser, this.productId).subscribe(
      data => {
        
        this.isStateRecord = true;
        this.selectedStateCounter = 0;
        this.listOfState = data['state'];
        if (this.listOfState != null) {
          let seprator = '';
          if (this.listOfState.length > 0) {

            // const IsAnychecked = this.listOfState.filter(m => m.isChecked.toString() === 'true');
            // if (Array.isArray(IsAnychecked)) {
            //   if (IsAnychecked.length > 0) {
            //     this.listOfStateId = '';
            //   } else {
            //   }
            // }

            this.listOfStateId = '';

            for (let iCounter = 0; iCounter < this.listOfState.length; iCounter++) {
              //if (this.listOfState[iCounter].isChecked) {
              this.selectedStateCounter = this.selectedStateCounter + 1;
              this.listOfID.push(this.listOfState[iCounter].stateId);
              this.listOfStateId += seprator + this.listOfState[iCounter].stateId;
              seprator = ',';

              //}
              this.selectAllID.push(this.listOfState[iCounter].stateId)
              if (this.listOfID.length === this.selectAllID.length) {
                this.chkselectAll = true;
              } else {
                this.chkselectAll = false;
              }
            }

            const str = this.listOfID.join(',');
            this.searchModal.stateIds = str;
            this.searchModal.emailDeadlineDate = this.emailDeadlineDate;
            this.searchModal.isFavorite = this.FilterFavourite;
            this.searchModal.isMyTracIt = this.MyTracIt;
            this.searchModal.dateFrom = null;
            this.searchModal.toDate = null;
            this.parentFun.emit(this.searchModal);

            this.populateActionItems();
          } else {
            this.listOfStateId = 'null';
            this.populateActionItems();
          }
        }
        // this.GetMatrix();
        // this.unblockDocument();
        //this.parentFun.emit(this.searchModal);
      },
      error => {
        // this.statusCode = error;
        this.populateActionItems();
      }
    );
  }

  selectAllCheckBox(event: any) {
    this.FilterFavourite = false;
    this.MyTracIt = false;
    this.listOfStateId = '';
    // this.listOfState = []
    this.listOfID = [];
    const allState = Object.assign([], this.listOfState);

    if (!event.currentTarget.checked) {
      // this.selectAllID = [];
      // Select checkbox
      if (allState.length > 0) {
        for (var jCounter = 0; jCounter < allState.length; jCounter++) {
          this.listOfState[jCounter].isChecked = false;
        }
      }
    } else {
      this.listOfID = this.selectAllID;
      // Select checkbox
      if (allState.length > 0) {
        for (var jCounter = 0; jCounter < allState.length; jCounter++) {
          this.listOfState[jCounter].isChecked = true;
        }
      }
    }
    this.selectedStateCounter = this.listOfID.length;
    const str = this.listOfID.join(',');
    this.searchModal.stateIds = str;
    this.searchModal.emailDeadlineDate = this.emailDeadlineDate;
    this.searchModal.isFavorite = this.FilterFavourite;
    this.searchModal.isMyTracIt = this.MyTracIt;
    this.searchModal.dateFrom = null;
    this.searchModal.toDate = null;
    this.parentFun.emit(this.searchModal);

  }

  checkIfSelected(stateId: any, event: any) {
    this.FilterFavourite = false;
    this.MyTracIt = false;
    if (!event.currentTarget.checked) {
      this.listOfID = this.listOfID.filter(m => m !== stateId);
    } else {
      this.listOfID.push(stateId);
    }
    this.selectedStateCounter = this.listOfID.length;
    const str = this.listOfID.join(',');
    this.searchModal.stateIds = str;
    this.searchModal.emailDeadlineDate = this.emailDeadlineDate;
    this.searchModal.isFavorite = this.FilterFavourite;
    this.searchModal.isMyTracIt = this.MyTracIt;
    this.searchModal.dateFrom = null;
    this.searchModal.toDate = null;
    this.parentFun.emit(this.searchModal);
  }

  // onValueChange(value: Date): void {

  //   function pad(s) { return (s < 10) ? '0' + s : s; }
  //   const d = new Date(value[0]);
  //   const a = [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/');

  //   const e = new Date(value[1]);
  //   const f = [pad(e.getMonth() + 1), pad(e.getDate()), e.getFullYear()].join('/');

  //   this.searchModal.dateFrom = a;
  //   this.searchModal.toDate = f;

  //   const timeDifference = e.getTime() - d.getTime();
  //   const daysDifference = timeDifference / (1000 * 3600 * 24);
  //   if (daysDifference <= 90) {  
  //     if (this.searchModal.actionTypeIds != '' && this.searchModal.carrierCatIds != '' && this.searchModal.stateIds != '' && this.searchModal.statusIds != '') {        
  //       if (sessionStorage.getItem('emailDeadlineDate') != '' && sessionStorage.getItem('emailDeadlineDate') != null && this.emailDateTrack == 0) {  
  //         this.emailDateTrack++;
  //         this.searchModal.emailDeadlineDate = '';
  //         this.emailDeadlineDate = '';
  //         this.setEmailAutoLoginParameter();
  //         sessionStorage.removeItem('emailDeadlineDate');
  //       }
  //       else if (sessionStorage.getItem('dashboardFromDate') == null && this.dateTrack == 0 && this.emailDateTrack == 1) {  
  //         this.emailDeadlineDate = '';
  //         this.dateTrack++;
  //         let CurrentTime = new Date();

  //         var firstDay = new Date(CurrentTime.getFullYear(), CurrentTime.getMonth(), 1);
  //         var lastDay = new Date(CurrentTime.getFullYear(), CurrentTime.getMonth(), this.daysInMonth(CurrentTime.getMonth() + 1,
  //           CurrentTime.getFullYear()));       

  //         function pad(s) { return (s < 10) ? '0' + s : s; }
  //         const dd = new Date(firstDay);
  //         const aa = [pad(dd.getMonth() + 1), pad(dd.getDate()), dd.getFullYear()].join('/');

  //         const ee = new Date(lastDay);
  //         const ff = [pad(ee.getMonth() + 1), pad(ee.getDate()), ee.getFullYear()].join('/');

  //         this.searchModal.dateFrom = aa;
  //         this.searchModal.toDate = ff;

  //         this.bsRangeValue = [firstDay, lastDay];
  //       }
  //     }
  //     this.parentFun.emit(this.searchModal);
  //   }
  //   else {
  //     setTimeout(() => this.toastr.error('Please select dates in the range of three months only.', 'Error!'));
  //   }
  // }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  showHideCalendar(value: boolean) {
    this.isListShow = value;
  }

  pdfDocument() {
    this.downloadFile.emit();
  }

  loadMyFavourite() {
    let seprator = '';
    let strActionTypeIds = '';
    let strCarrierCatId = '';
    let strStateId = '';

    
    let isDashbaordFavoriteClick = sessionStorage.getItem('dashboardIsFavorite') == 'true' ? true : false;
    if (!isDashbaordFavoriteClick) {
      this.FilterFavourite = !this.FilterFavourite;
      this.selectedActionTypeIds = [];
      this.selectedCareerIds = [];
      this.listOfID = [];
    }


    sessionStorage.removeItem('dashboardIsFavorite');
    if (this.FilterFavourite) {
      this.actionServices.GetClientFavorite(this.tokenKey, this.loginUser, this.productId).subscribe(
        data => {
          
          if (data['status'] == 'Success') {
            this.favouriteModel = data['favoriteList'];

            if (this.favouriteModel != null) {
              //#region Set Favorute Action Item
              if (this.favouriteModel.actionItemTypesFavorite.length > 0) {
                // Set isDefaultChecked = false;
                for (let iCounter = 0; iCounter < this.actionItemsList.length; iCounter++) {
                  this.actionItemsList[iCounter].isDefaultChecked = false;
                }

                for (let jCounter = 0; jCounter < this.favouriteModel.actionItemTypesFavorite.length; jCounter++) {
                  strActionTypeIds += seprator + this.favouriteModel.actionItemTypesFavorite[jCounter].actionItemTypeId;
                  seprator = ',';
                  let favModel = this.actionItemsList.filter(m => m.actionItemTypeId == this.favouriteModel.actionItemTypesFavorite[jCounter].actionItemTypeId)
                  if (favModel != null) {
                    if (favModel.length > 0) {
                      const index: number = this.actionItemsList.indexOf(favModel[0])
                      if (index !== -1) {
                        this.actionItemsList[index].isDefaultChecked = true;
                        this.selectedActionTypeIds = this.removeElemetninArray(this.selectedActionTypeIds, favModel[0].actionItemTypeId);
                        this.selectedActionTypeIds.push(favModel[0].actionItemTypeId);
                      }
                      else
                        this.actionItemsList[index].isDefaultChecked = false;
                    }
                  }
                }
              }
              else {
                // Set isDefaultChecked = false;
                for (let iCounter = 0; iCounter < this.actionItemsList.length; iCounter++) {
                  this.actionItemsList[iCounter].isDefaultChecked = false;
                }
              }

              if (this.selectedActionTypeIds.length == this.actionItemsList.length)
                this.isActionTypeSelectAllChecked = true;
              else
                this.isActionTypeSelectAllChecked = false;

              //#endregion

              //#region Set Favorute Carrier Category
              seprator = '';
              if (this.favouriteModel.carrierCategoryFavorite.length > 0) {
                // Set isDefaultChecked = false;
                for (let iCounter = 0; iCounter < this.carrierCategoryModel.length; iCounter++) {
                  this.carrierCategoryModel[iCounter].isDefaultChecked = false;
                }

                for (let jCounter = 0; jCounter < this.favouriteModel.carrierCategoryFavorite.length; jCounter++) {
                  strCarrierCatId += seprator + this.favouriteModel.carrierCategoryFavorite[jCounter].carrierCatId;
                  seprator = ',';
                  let favModel = this.carrierCategoryModel.filter(m => m.carrierCatId == this.favouriteModel.carrierCategoryFavorite[jCounter].carrierCatId)
                  if (favModel != null) {
                    if (favModel.length > 0) {
                      const index: number = this.carrierCategoryModel.indexOf(favModel[0])
                      if (index !== -1) {
                        this.carrierCategoryModel[index].isDefaultChecked = true;
                        this.selectedCareerIds = this.removeElemetninArray(this.selectedCareerIds, favModel[0].carrierCatId);
                        this.selectedCareerIds.push(favModel[0].carrierCatId);
                      }
                      else
                        this.carrierCategoryModel[index].isDefaultChecked = false;
                    }
                  }
                }

              }
              else {
                // Set isDefaultChecked = false;
                for (let iCounter = 0; iCounter < this.carrierCategoryModel.length; iCounter++) {
                  this.carrierCategoryModel[iCounter].isDefaultChecked = false;
                }
              }
            }

            if (this.carrierCategoryModel.length == this.selectedCareerIds.length)
              this.isCarrierCategorySelectAllChecked = true;
            else
              this.isCarrierCategorySelectAllChecked = false;
            //#endregion

            //#region Set Favorite State
            seprator = '';
            if (this.favouriteModel.stateFavorite.length > 0) {
              // Set isDefaultChecked = false;
              for (let iCounter = 0; iCounter < this.listOfState.length; iCounter++) {
                this.listOfState[iCounter].isChecked = false;
              }

              for (let jCounter = 0; jCounter < this.favouriteModel.stateFavorite.length; jCounter++) {
                strStateId += seprator + this.favouriteModel.stateFavorite[jCounter].stateId;
                seprator = ',';
                let favModel = this.listOfState.filter(m => m.stateId == this.favouriteModel.stateFavorite[jCounter].stateId)
                if (favModel != null) {
                  if (favModel.length > 0) {
                    const index: number = this.listOfState.indexOf(favModel[0])
                    if (index !== -1) {
                      this.listOfState[index].isChecked = true;
                      this.listOfID = this.removeElemetninArray(this.listOfID, favModel[0].stateId);
                      this.listOfID.push(favModel[0].stateId);
                    }
                    else
                      this.listOfState[index].isChecked = false;
                  }
                }
              }

              if (this.favouriteModel.stateFavorite.length == this.listOfState.length) {
                this.chkselectAll = true;
              }
              this.selectedStateCounter = this.favouriteModel.stateFavorite.length;
            }
            else {
              if (this.favouriteModel.stateFavorite.length == 0) {
                this.chkselectAll = false;
                this.selectedStateCounter = 0;
              }
              // Set isDefaultChecked = false;
              for (let iCounter = 0; iCounter < this.listOfState.length; iCounter++) {
                this.listOfState[iCounter].isChecked = false;
              }
            }
            //#endregion

            this.searchModal.actionTypeIds = strActionTypeIds;
            this.searchModal.carrierCatIds = strCarrierCatId;
            this.searchModal.isFavorite = this.FilterFavourite;
            this.searchModal.isMyTracIt = this.MyTracIt;
            this.searchModal.stateIds = strStateId;
            this.searchModal.dateFrom = null;
            this.searchModal.toDate = null;
            this.parentFun.emit(this.searchModal);
          }
        }
      );
    }
    else {

      // Reset ActionItemsList Set isDefaultChecked = true;
      for (let iCounter = 0; iCounter < this.actionItemsList.length; iCounter++) {
        strActionTypeIds += seprator + this.actionItemsList[iCounter].actionItemTypeId;
        seprator = ',';
        this.actionItemsList[iCounter].isDefaultChecked = true;
        this.selectedActionTypeIds = this.removeElemetninArray(this.selectedActionTypeIds, this.actionItemsList[iCounter].actionItemTypeId);
        this.selectedActionTypeIds.push(this.actionItemsList[iCounter].actionItemTypeId);
      }
      this.isActionTypeSelectAllChecked = true;
      this.isCarrierCategorySelectAllChecked = true;
      this.isActionStatusSelectAllChecked = true;
      // Reset CarrierCategory Set isDefaultChecked = true;
      seprator = '';
      for (let iCounter = 0; iCounter < this.carrierCategoryModel.length; iCounter++) {
        strCarrierCatId += seprator + this.carrierCategoryModel[iCounter].carrierCatId;
        seprator = ',';
        this.carrierCategoryModel[iCounter].isDefaultChecked = true;
        this.selectedCareerIds = this.removeElemetninArray(this.selectedCareerIds, this.carrierCategoryModel[iCounter].carrierCatId);
        this.selectedCareerIds.push(this.carrierCategoryModel[iCounter].carrierCatId);
      }

      // Reset State Set isChecked = true;
      seprator = '';
      for (let iCounter = 0; iCounter < this.listOfState.length; iCounter++) {
        strStateId += seprator + this.listOfState[iCounter].stateId;
        seprator = ',';
        this.listOfState[iCounter].isChecked = true;
        this.listOfID = this.removeElemetninArray(this.listOfID, this.listOfState[iCounter].stateId);
        this.listOfID.push(this.listOfState[iCounter].stateId);
        this.selectedStateCounter = this.listOfState.length;
      }

      this.chkselectAll = true;


      this.searchModal.actionTypeIds = strActionTypeIds;
      this.searchModal.carrierCatIds = strCarrierCatId;
      this.searchModal.stateIds = strStateId;
      this.searchModal.isFavorite = this.FilterFavourite;
      this.searchModal.isMyTracIt = this.MyTracIt;
      this.searchModal.dateFrom = null;
      this.searchModal.toDate = null;
      this.parentFun.emit(this.searchModal);
    }
  }

  copyInputMessage(inputElement) {

    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, inputElement.setSelectionRange(0, inputElement.value.length));
  }

  HandledByCRSChange($event) {
    
    this.searchModal.isNeworChanged = $event.currentTarget.checked;
    this.searchModal.isFavorite = this.FilterFavourite;
    this.searchModal.isMyTracIt = this.MyTracIt;
    this.parentFun.emit(this.searchModal);
  }

  opneICalFile(iCalUrl) {
    window.open(iCalUrl);
  }

  loadMyTracIt() {
    
    this.MyTracIt = !this.MyTracIt;
    this.searchModal.isMyTracIt = this.MyTracIt;
    sessionStorage.removeItem('dashboardIsMyTracIt');
    this.searchModal.dateFrom = null;
    this.searchModal.toDate = null;
    this.parentFun.emit(this.searchModal);
  }

}
