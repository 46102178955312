import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CityMaster911Model } from 'src/Models/CityMaster911Model';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { AppConfig } from 'src/Services/app.config';
import { CityMasterService } from 'src/Services/city-master.service';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompletedPhase3, getIncompletedStepPhase3 } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-admin-sensitive-information',
  templateUrl: './admin-sensitive-information.component.html',
  styleUrls: ['./admin-sensitive-information.component.css']
})
export class AdminSensitiveInformationComponent implements OnInit {

  SensitiveInformationForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;

  ClientInformationId: number;

  mainUrl: string;
  subUrl: string;
  currentActiveTab: number = 1;

  isErrorOnOwnerLicenceDoc: boolean;
  selectedOwnerLicenceInfo: File;
  isOwnerLicenceInfoFile: boolean = false;
  isOwnerLicenceInfo: boolean = false;

  isErrorOnGovAuthorityLoginCredDoc: boolean;
  selectedGovAuthorityLoginCredInfo: File;
  isGovAuthorityLoginCredInfoFile: boolean = false;
  isGovAuthorityLoginCredInfo: boolean = false;
  allowedExtensions: any = ["pdf", "PDF", "doc", "docx", "DOC", "DOCX", "txt", "TXT"];

  isErrorOnArticlesInfoDoc: boolean;
  selectedArticlesInfo: File;
  isArticlesInfoFile: boolean = false;
  isArticlesInfo: boolean = false;

  isErrorOnFEINLetterDoc: boolean;
  selectedFEINLetter: File;
  isFEINLetterFile: boolean = false;
  isFEINLetter: boolean = false;

  file: any;
  @ViewChild('ownerDriverLicenseInfo', { static: true }) ownerDriverLicenseInfo: ElementRef;
  @ViewChild('govAuthorityLoginCredentialInfo', { static: true }) govAuthorityLoginCredentialInfo: ElementRef;
  @ViewChild('fileInfo', { static: true }) fileInfo: ElementRef;
  @ViewChild('articlesInfo', { static: true }) articlesInfo;
  @ViewChild('fEINLetter', { static: true }) fEINLetter;


  isTaxPermit: boolean = false;
  filePath1: string = null;
  copyArticalfilePath: string = null;
  govAuthFile: string = null;
  copyFEINLetterPath:string=null;

  stateList: StateMasterModel[] = [];
  stateId: number = 0;
  selectedState = [];
  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};
  dropdownSettingsStateFilter = {};

  dropdownDateCityFilter = [];
  cityList: CityMaster911Model[] = [];
  selectedCity = [];
  cityName: string = "";
  cityId: number = 0;
  dropdownSettingsCityFilter = {};
  listOfCityIds = [];

  module = 'i360';
  companyName:string;
  priceQuoteId:number=0;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute,
    private globle: Globle, private router: Router, private PricingQuotesMaster: PricingQuotesService,
    private toastr: ToastrService, private cityService: CityMasterService,private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = sessionStorage.getItem('UserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Onboarding Information - '+this.companyName);

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.getState();
          this.ClientInformationId = params['id'];
          this.GetClientInformationById(this.ClientInformationId);
          this.GetSensitiveInformation(this.ClientInformationId);
        }
      }

      if (params['priceQuoteId']) {
        if (params['priceQuoteId'] != null) {
          this.priceQuoteId = params['priceQuoteId'];
        }
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.ClientInformationId != null) {
      this.GetProgressStepDetailsById(this.ClientInformationId);
      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isAdditionalCompanyInformation = null;
      this.globle.cApp.statusModel.isSensitiveInformation = null;
      this.globle.cApp.statusModel.isInteserraPortalCredential = null;
      this.globle.cApp.statusModel.isStateSelectionCompleted=null;
      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
    }

  }

  ngOnInit() {
    this.dropdownSettingsStateFilter = {
      singleSelection: true,
      text: "Select State",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      disabled: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsCityFilter = {
      singleSelection: true,
      text: "Select City",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No City(s) available',
      showCheckbox: false,
      disabled: false,
      enableFilterSelectAll: false
    };

    this.SensitiveInformationForm = this._fb.group({
      clientInformationId: this.ClientInformationId,
      sensitiveInformationId: 0,
      ownerName: ['', Validators.required],
      ownerTitle: ['', Validators.required],
      ownerSSN: ['', Validators.required],
      ownerHomeAddress: ['', Validators.required],
      ownerHomeAddress2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      ownerHomePhone: ['', Validators.required],
      ownerDetails: ['', Validators.required],
      ownerDriverLicense: ['', Validators.required],
      ownerDriverLicensePath: [''],
      ownerDriverLicenseDocName: [''],
      ownerDriverLicenseFullPath: [''],
      govAuthorityLoginCredential: ['', Validators.required],
      govAuthorityLoginCredentialPath: [''],
      govAuthorityLoginCredentialDocName: [''],
      govAuthorityLoginCredentialFullPath: [''],
      copyOfTheirArticlesOfIncorporationPath:[''],
      copyOfTheirArticlesOfIncorporationDocName:[''],
      copyFEINLetterPath:[''],
      copyFEINLetterDocName:[''],
      copyOfTheirArticlesOfIncorporationFullPath:[''],
      copyFEINLetterFullPath:[''],
    })
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  getState() {
    this.PricingQuotesMaster.GetState(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownDateFilter = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownDateFilter.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  onChangeState(event) {
    this.SensitiveInformationForm.patchValue({
      state: event.id
    });
    if (event.id)
      this.GetCityByStateId(event.id)
  }

  onDeSelectDateFilterAll($event) {
    this.selectedState = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
  }

  onDeSelectDateFilterCountyAll($event) {
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
  }

  GetCityByStateId(stateId) {
    this.cityService.GetCityByStateId(this.tokenKey, stateId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.cityList = result['city'];
          this.dropdownDateCityFilter = [];
          this.selectedCity = [];
          this.cityList.forEach(x => {
            this.dropdownDateCityFilter.push({ "id": x.cityId, "itemName": x.cityName })
          });
        }
      });
  }

  onChangeCity(event) {
    this.SensitiveInformationForm.patchValue({
      city: event.id
    });
  }
  onDeSelectDateFilterCityAll($event) {
    this.selectedCity = [];
  }

  GetClientInformationById(clientInformationId: number) {
    this.blockDocument();
    this.PricingQuotesMaster.GetAdditionalInformationById(this.tokenKey, parseInt(clientInformationId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.unblockDocument();
          let clientModel = result['clientInformation'];
          if (result['clientInformation'].needYouTaxPermits == 1) {
            this.isTaxPermit = true;
            document.getElementById('inputfile').style.display = 'block'
          }
          else {
            this.isTaxPermit = false;
            document.getElementById('inputfile').style.display = 'none'
          }
        }
      }
    );
  }

  GetSensitiveInformation(clientInformationId: number) {
    this.blockDocument()
    this.PricingQuotesMaster.GetSensitiveInformationById(this.tokenKey, parseInt(clientInformationId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.unblockDocument();
          if (result['clientInformation'] != null) {
            this.SensitiveInformationForm.patchValue({
              clientInformationId: this.ClientInformationId,
              sensitiveInformationId: result['clientInformation'].sensitiveInformationId,
              ownerName: result['clientInformation'].ownerName,
              ownerTitle: result['clientInformation'].ownerTitle,
              ownerSSN: result['clientInformation'].ownerSSN,
              ownerHomeAddress: result['clientInformation'].ownerHomeAddress,
              ownerHomeAddress2: result['clientInformation'].ownerHomeAddress2,
              city: result['clientInformation'].city,
              state: result['clientInformation'].state,
              zipCode: result['clientInformation'].zipCode,
              ownerHomePhone: result['clientInformation'].ownerHomePhone,
              ownerDetails: result['clientInformation'].ownerDetails,
              ownerDriverLicense: result['clientInformation'].ownerDriverLicense,
              ownerDriverLicensePath: result['clientInformation'].ownerDriverLicensePath,
              ownerDriverLicenseDocName: result['clientInformation'].ownerDriverLicenseDocName,
              ownerDriverLicenseFullPath: result['clientInformation'].ownerDriverLicenseFullPath,
              govAuthorityLoginCredential: result['clientInformation'].govAuthorityLoginCredential,
              govAuthorityLoginCredentialPath: result['clientInformation'].govAuthorityLoginCredentialPath,
              govAuthorityLoginCredentialDocName: result['clientInformation'].govAuthorityLoginCredentialDocName,
              govAuthorityLoginCredentialFullPath: result['clientInformation'].govAuthorityLoginCredentialFullPath,
              copyOfTheirArticlesOfIncorporation: result['clientInformation'].copyOfTheirArticlesOfIncorporation,
              copyOfTheirArticlesOfIncorporationPath: result['clientInformation'].copyOfTheirArticlesOfIncorporationPath,
              copyOfTheirArticlesOfIncorporationDocName: result['clientInformation'].copyOfTheirArticlesOfIncorporationDocName,
              copyOfTheirArticlesOfIncorporationFullPath: result['clientInformation'].copyOfTheirArticlesOfIncorporationFullPath,
              copyFEINLetterPath: result['clientInformation'].copyFEINLetterPath,
              copyFEINLetterDocName: result['clientInformation'].copyFEINLetterDocName,
              copyFEINLetterFullPath: result['clientInformation'].copyFEINLetterFullPath,
            });

            this.filePath1 = result['clientInformation'].ownerDriverLicenseFullPath;
            this.copyArticalfilePath = result['clientInformation'].copyOfTheirArticlesOfIncorporationFullPath;
            this.copyFEINLetterPath= result['clientInformation'].copyFEINLetterFullPath;

            this.govAuthFile = result['clientInformation'].govAuthorityLoginCredentialFullPath;

            if (this.stateList.length > 0) {
              let data = this.stateList.filter(x => x.stateId == result['clientInformation'].state)[0];
              if (data != null && data != undefined) {
                this.selectedState.push({ "id": data.stateId, "itemName": data.name })
              }
            }
            else {
              setTimeout(() => {
                let data = this.stateList.filter(x => x.stateId == result['clientInformation'].state)[0];
                if (data != null && data != undefined) {
                  this.selectedState.push({ "id": data.stateId, "itemName": data.name })
                }
              }, 500);
            }

            this.GetCityByStateId(result['clientInformation'].state)

            if (this.cityList.length > 0) {
              let data = this.cityList.filter(x => x.cityId == result['clientInformation'].city)[0];
              if (data != null && data != undefined) {
                this.selectedCity.push({ "id": data.cityId, "itemName": data.cityName })
              }
            }
            else
              setTimeout(() => {
                let data = this.cityList.filter(x => x.cityId == result['clientInformation'].city)[0];
                if (data != null && data != undefined) {
                  this.selectedCity.push({ "id": data.cityId, "itemName": data.cityName })
                }
              }, 800);

          }
        }
      });
  }


  save(clientForm: any) {

    this.isSubmit = true;
    let data = clientForm.value;

    let formData = new FormData();

    if (this.isTaxPermit == true) {

      if (this.selectedState.length == 0 || this.selectedCity.length == 0)
        return

      if (this.govAuthorityLoginCredentialInfo.nativeElement.files.length == 0 && (data.govAuthorityLoginCredentialPath == '' || data.govAuthorityLoginCredentialPath == null)) {
        this.showMessage(('Please Upload Government Authority Login Credentials Document'), 'error', 'error');
        return;
      }

      if (!data.ownerName.trim() || !data.ownerTitle.trim() || data.ownerSSN == null || !data.ownerHomeAddress.trim() || data.zipCode == null || data.ownerHomePhone == null  || !data.ownerDetails.trim())
        return;

      if (this.ownerDriverLicenseInfo.nativeElement.files.length == 0 && (data.ownerDriverLicensePath == '' || data.ownerDriverLicensePath == null)) {
        this.showMessage(('Please Upload Owner/Officer Drivers License Document'), 'error', 'error');
        return;
      }


      formData.append('SensitiveInformationId', data.sensitiveInformationId || 0);
      formData.append('ClientInformationId', data.clientInformationId || 0);
      formData.append('OwnerName', data.ownerName || '');
      formData.append('OwnerTitle', data.ownerTitle || '');
      formData.append('OwnerSSN', data.ownerSSN || '');
      formData.append('OwnerHomeAddress', data.ownerHomeAddress || '');
      formData.append('OwnerHomeAddress2', data.ownerHomeAddress2 || '');
      formData.append('City', data.city || '');
      formData.append('State', data.state || '');
      formData.append('ZipCode', data.zipCode || '');
      formData.append('OwnerHomePhone', data.ownerHomePhone || '');
      formData.append('OwnerDetails', data.ownerDetails || '');
      formData.append('ownerDriverLicenseInfo', this.ownerDriverLicenseInfo.nativeElement.files[0] || '');
      formData.append('ownerDriverLicensePath', data.ownerDriverLicensePath || '');
      formData.append('ownerDriverLicenseDocName', data.ownerDriverLicenseDocName || '');
      formData.append('govAuthorityLoginCredentialInfo', this.govAuthorityLoginCredentialInfo.nativeElement.files[0] || '');
      formData.append('govAuthorityLoginCredentialPath', data.govAuthorityLoginCredentialPath || '');
      formData.append('govAuthorityLoginCredentialDocName', data.govAuthorityLoginCredentialDocName || '');
      formData.append('articlesInfo', this.articlesInfo.nativeElement.files[0] || '');
      formData.append('copyOfTheirArticlesOfIncorporationPath', data.copyOfTheirArticlesOfIncorporationPath || '');
      formData.append('copyOfTheirArticlesOfIncorporationDocName', data.copyOfTheirArticlesOfIncorporationDocName || '');
      formData.append('fEINLetter', this.fEINLetter.nativeElement.files[0] || '');
      formData.append('copyFEINLetterPath', data.copyFEINLetterPath || '');
      formData.append('copyFEINLetterDocName', data.copyFEINLetterDocName || '');
      formData.append('CreatedBy', this.userID || 0);
    }
    else {
      formData.append('SensitiveInformationId', data.sensitiveInformationId || 0);
      formData.append('ClientInformationId', data.clientInformationId || 0);
      formData.append('govAuthorityLoginCredentialInfo', this.govAuthorityLoginCredentialInfo.nativeElement.files[0] || '');
      formData.append('govAuthorityLoginCredentialPath', data.govAuthorityLoginCredentialPath || '');
      formData.append('govAuthorityLoginCredentialDocName', data.govAuthorityLoginCredentialDocName || '');
      formData.append('articlesInfo', this.articlesInfo.nativeElement.files[0] || '');
      formData.append('copyOfTheirArticlesOfIncorporationPath', data.copyOfTheirArticlesOfIncorporationPath || '');
      formData.append('copyOfTheirArticlesOfIncorporationDocName', data.copyOfTheirArticlesOfIncorporationDocName || '');
      formData.append('fEINLetter', this.fEINLetter.nativeElement.files[0] || '');
      formData.append('copyFEINLetterPath', data.copyFEINLetterPath || '');
      formData.append('copyFEINLetterDocName', data.copyFEINLetterDocName || '');
      formData.append('CreatedBy', this.userID || 0);
    }
   
    this.blockDocument();
    this.PricingQuotesMaster.SensitiveInformationAddUpdate(this.tokenKey, formData).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isSensitiveInformation = true;
          this.unblockDocument();
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (result['clientInformationId'] != 0) {
              let navigation = '/admin/' + this.SelectedTool + '/portal-credential/' + result['clientInformationId']+'/'+this.priceQuoteId;
              this.router.navigate([navigation]);
            }
          }
        }
        else {
          this.unblockDocument();
          this.showMessage(result['status'], result['message'], 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  previousStep() {
    let navigation = '/admin/' + this.SelectedTool + '/add-company-info/' + this.ClientInformationId + '/' + this.priceQuoteId;
    this.router.navigate([navigation]);
  }

  ownerDriverLicenseInfoEvent(event): any {
    this.isOwnerLicenceInfoFile = false;
    this.isOwnerLicenceInfo = false;
    var file = event.target.files[0];

    let size = Math.round(file.size);
    if (size <= file.size) {
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(this.allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.,DOC.,DOCX', 'Error', 'error');
        this.isErrorOnOwnerLicenceDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isOwnerLicenceInfoFile = true;
        this.isOwnerLicenceInfo = true;
        this.isErrorOnOwnerLicenceDoc = false;
        this.selectedOwnerLicenceInfo = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnOwnerLicenceDoc = true;
      window.scrollTo(0, 0);
    }
  }

  govAuthorityLoginCredEvent(event): any {
    this.isGovAuthorityLoginCredInfoFile = false;
    this.isGovAuthorityLoginCredInfo = false;
    var file = event.target.files[0];

    let size = Math.round(file.size);
    if (size <= file.size) {
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(this.allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.,DOC.,DOCX', 'Error', 'error');
        this.isErrorOnGovAuthorityLoginCredDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isGovAuthorityLoginCredInfoFile = true;
        this.isGovAuthorityLoginCredInfo = true;
        this.isErrorOnGovAuthorityLoginCredDoc = false;
        this.selectedGovAuthorityLoginCredInfo = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnGovAuthorityLoginCredDoc = true;
      window.scrollTo(0, 0);
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  activeTab(url: string, tabId: number) {

    if (this.ClientInformationId != null) {
      if (checkStepIsCompletedPhase3(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.ClientInformationId+'/'+this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.ClientInformationId+'/'+this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompletedPhase3(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(clientInformationId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsByClientInformationId(this.tokenKey, clientInformationId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
          this.activeTab('sensitive-info', 11);
        }
      }
    );
  }

  articlesInfoEvent(event): any {
    this.isArticlesInfoFile = false;
    this.isArticlesInfo = false;
    var file = event.target.files[0];

    let size = Math.round(file.size);
    if (size <= file.size) {
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(this.allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.', 'Error', 'error');
        this.isErrorOnArticlesInfoDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isArticlesInfoFile = true;
        this.isArticlesInfo = true;
        this.isErrorOnArticlesInfoDoc = false;
        this.selectedArticlesInfo = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnArticlesInfoDoc = true;
      window.scrollTo(0, 0);
    }
  }

  FEINLetterEvent(event): any {
    this.isFEINLetterFile = false;
    this.isFEINLetter = false;
    var file = event.target.files[0];

    let size = Math.round(file.size);
    if (size <= file.size) {
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(this.allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.', 'Error', 'error');
        this.isErrorOnFEINLetterDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isFEINLetterFile = true;
        this.isFEINLetter = true;
        this.isErrorOnFEINLetterDoc = false;
        this.selectedFEINLetter = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnFEINLetterDoc = true;
      window.scrollTo(0, 0);
    }
  }
}
