import { Component, OnInit } from '@angular/core';
import { GeneralAlertsModel } from 'src/Models/GeneralAlertsModel';
import { FormGroup, FormControl } from '@angular/forms';
import { StateMasterService } from 'src/Services/state-master.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from 'src/Models/Common';
import { CheckAuth } from 'src/Services/utility.service';
import { ActionItemService } from 'src/Services/actionitem.service';
import { UsersService } from 'src/Services/users.service';

@Component({
  selector: 'app-manage-general-alerts',
  templateUrl: './manage-general-alerts.component.html',
  styleUrls: ['./manage-general-alerts.component.css']
})
export class ManageGeneralAlertsComponent implements OnInit {
  tokenKey: string = '';
  userID: number = 0;
  SelectedTool: string;
  productId: number = 1;
  multiSelectDropDownHeight: number = 200;


  generalAlertsModel = new GeneralAlertsModel()

  addUpdateGeneralAlert = new FormGroup({
    generalAlertsId: new FormControl(''),
    alertDate: new FormControl(''),
    subject: new FormControl(''),
    message: new FormControl(''),
    wirelessStateOp: new FormControl(''),
    wirelessState: new FormControl(''),
    voipStateOp: new FormControl(''),
    voipState: new FormControl(''),
    iprStateOp: new FormControl(''),
    iprState: new FormControl(''),
    iprProviders: new FormControl(''),
    actionStateOp: new FormControl(''),
    actionStates: new FormControl(''),
    actionTypes: new FormControl(''),
    actionCategories: new FormControl(''),
  });

  isGeneralAlerts: boolean = true;

  wirelessDropdownListState = [];
  wirelessSelectedItemsState = [];
  wirelessDropdownStateSettings = {};
  wirelessListOfState = [];
  isWirelessControlEnable: boolean = true;

  voipDropdownListState = [];
  voipSelectedItemsState = [];
  voipDropdownStateSettings = {};
  voipListOfState = [];
  isVoIPControlEnable: boolean = true;

  iprDropdownListState = [];
  iprSelectedItemsState = [];
  iprDropdownStateSettings = {};
  iprListOfState = [];
  isIPRControlEnable: boolean = true;

  iprDropdownListForProvider = [];
  iprSelectedItemsForProvider = [];
  iprDropdownSettingsForProvider = {};
  iprListOfProvider = [];

  actionItemDropdownListState = [];
  actionItemSelectedItemsState = [];
  actionItemDropdownStateSettings = {};
  actionItemListOfState = [];
  isActionItemControlEnable: boolean = true;

  actionTypesDropdownList = [];
  actionTypesSelectedItems = [];
  actionTypesDropdownSettings = {};
  actionTypesListOfItem = [];

  actionCategoriesDropdownList = [];
  actionCategoriesSelectedItems = [];
  actionCategoriesDropdownSettings = {};
  actionCategoriesListOfItem = [];

  generalAlertsId: number = 0;
  isDataLoad: boolean = false;
  isViewMode: boolean = false;

  constructor(private activedRoute: ActivatedRoute, private actionItemServices: ActionItemService, private stateMasterService: StateMasterService, private router: Router,
    private toastr: ToastrService, private titleService: Title, private checkAuth: CheckAuth, private userServices: UsersService) {
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = 4;
    this.isViewMode = sessionStorage.getItem('isViewMode') == 'true' ? true : false;
    if (this.isViewMode)
      this.titleService.setTitle('View i360 Alert');
    else
      this.titleService.setTitle('Manage i360 Alert');
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {

      if (params['id']) {
        if (params['id'] != null) {
          this.isGeneralAlerts = false;
          this.isWirelessControlEnable = false;
          this.isVoIPControlEnable = false;
          this.isIPRControlEnable = false;
          this.isActionItemControlEnable = false;
          this.generalAlertsId = params['id'];
          this.GetGeneralAlertsByIds();
        }
      }
      else {
        this.generalAlertsId = 0;
      }
    });

    this.setWirelessDropDownSetting();
    this.setVoIPDropDownSetting();
    this.setIPRDropDownSetting();
    this.setActionItemDropDownSetting();

    this.GetIPRProvider();
    this.GetStateData();
    this.ActionTypes();
    this.GetCarrierCategories();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  GetGeneralAlertsByIds(): void {
    this.isDataLoad = true;
    this.actionItemServices.GetGeneralAlertsByIds(this.tokenKey, this.generalAlertsId).subscribe(
      data => {
        if (data["generalAlert"] != null && data["status"] != "Failure") {

          this.generalAlertsModel = data['generalAlert'].generalAlerts;

          if (this.generalAlertsModel != null) {
            let alertDate = new Date(this.generalAlertsModel.alertDate);

            let dd1 = alertDate.getDate().toString();
            if (parseInt(dd1.toString()) < 10)
              dd1 = '0' + dd1.toString();

            let mm1 = (alertDate.getMonth() + 1).toString();
            if (parseInt(mm1.toString()) < 10)
              mm1 = '0' + mm1.toString();

            this.generalAlertsModel.alertDate = mm1 + '/' + dd1 + '/' + alertDate.getFullYear();
          }


          let productModel = data['generalAlert'].productModel;

          if (productModel != null) {
            if (productModel.length > 0) {

              let wireless = productModel.filter(m => m.productId == ToolEnum.wireless);
              if (wireless != null) {
                if (wireless.length > 0) {
                  this.isWirelessControlEnable = false;
                }
                else
                  this.isWirelessControlEnable = true;
              }
              else
                this.isWirelessControlEnable = true;

              let voip = productModel.filter(m => m.productId == ToolEnum.voip);
              if (voip != null) {
                if (voip.length > 0) {
                  this.isVoIPControlEnable = false;
                }
                else
                  this.isVoIPControlEnable = true;
              }
              else
                this.isVoIPControlEnable = true;

              let ipr = productModel.filter(m => m.productId == ToolEnum.ipr);
              if (ipr != null) {
                if (ipr.length > 0 || data["generalAlert"].iprProvider.length > 0) {
                  this.isIPRControlEnable = false;
                }
                else
                  this.isIPRControlEnable = true;
              }
              else
                this.isIPRControlEnable = true;

              let action = productModel.filter(m => m.productId == ToolEnum.actionitem);
              if (action != null) {
                if (action.length > 0) {
                  this.isActionItemControlEnable = false;
                }
                else
                  this.isActionItemControlEnable = true;
              }
              else
                this.isActionItemControlEnable = true;

              let generalalert = productModel.filter(m => m.productId == ToolEnum.generalalert);
              if (generalalert != null) {
                if (generalalert.length > 0) {
                  this.isGeneralAlerts = false;
                }
                else
                  this.isGeneralAlerts = true;
              }
              else
                this.isGeneralAlerts = true;
            }
          }

          // Wireless State - Set Dropdown Selected values
          if (data["generalAlert"].wirelessStates != null) {
            this.wirelessListOfState = [];
            let wirelessStates = data["generalAlert"].wirelessStates;
            if (wirelessStates.length > 0) {
              for (let iCounter = 0; iCounter < wirelessStates.length; iCounter++) {
                this.wirelessSelectedItemsState.push(
                  { "id": wirelessStates[iCounter].stateId, "itemName": wirelessStates[iCounter].name }
                );
                this.wirelessListOfState.push(wirelessStates[iCounter].stateId);
              }
            }
          }

          // VOIP State - Set Dropdown Selected values
          if (data["generalAlert"].voipStates != null) {
            this.voipListOfState = [];
            let voipStates = data["generalAlert"].voipStates
            if (voipStates.length > 0) {
              for (let iCounter = 0; iCounter < voipStates.length; iCounter++) {
                this.voipSelectedItemsState.push(
                  { "id": voipStates[iCounter].stateId, "itemName": voipStates[iCounter].name }
                );
                this.voipListOfState.push(voipStates[iCounter].stateId);
              }
            }
          }

          // IPR State - Set Dropdown Selected values
          if (data["generalAlert"].iprStates != null) {
            let iprStates = data["generalAlert"].iprStates;
            this.iprListOfState = [];
            if (iprStates.length > 0) {
              for (let iCounter = 0; iCounter < iprStates.length; iCounter++) {
                this.iprSelectedItemsState.push(
                  { "id": iprStates[iCounter].stateId, "itemName": iprStates[iCounter].name }
                );
                this.iprListOfState.push(iprStates[iCounter].stateId);
              }
            }
          }

          // IPR Provider - Set Dropdown Selected values
          if (data["generalAlert"].iprProvider != null) {
            this.iprListOfProvider = [];
            let iprProvider = data["generalAlert"].iprProvider;
            if (iprProvider.length > 0) {
              for (let iCounter = 0; iCounter < iprProvider.length; iCounter++) {
                this.iprSelectedItemsForProvider.push(
                  { "id": iprProvider[iCounter].carrierId, "itemName": iprProvider[iCounter].businessName }
                );
                this.iprListOfProvider.push(iprProvider[iCounter].carrierId);
              }
            }
          }

          // Ation State - Set Dropdown Selected values
          if (data["generalAlert"].actionStates != null) {
            this.actionItemListOfState = [];
            let actionStates = data["generalAlert"].actionStates;
            if (actionStates.length > 0) {
              for (let iCounter = 0; iCounter < actionStates.length; iCounter++) {
                this.actionItemSelectedItemsState.push(
                  { "id": actionStates[iCounter].stateId, "itemName": actionStates[iCounter].name }
                );
                this.actionItemListOfState.push(actionStates[iCounter].stateId);
              }
            }
          }

          // Ation Type - Set Dropdown Selected values
          if (data["generalAlert"].actionType != null) {
            this.actionTypesListOfItem = [];
            let actionType = data["generalAlert"].actionType;
            if (actionType.length > 0) {
              for (let iCounter = 0; iCounter < actionType.length; iCounter++) {
                this.actionTypesSelectedItems.push(
                  { "id": actionType[iCounter].actionItemTypeId, "itemName": actionType[iCounter].typeName }
                );
                this.actionTypesListOfItem.push(actionType[iCounter].actionItemTypeId);
              }
            }
          }

          // Ation Carrier Category - Set Dropdown Selected values
          if (data["generalAlert"].carrierCategory != null) {
            let carrierCategory = data["generalAlert"].carrierCategory;
            this.actionCategoriesListOfItem = [];
            if (carrierCategory.length > 0) {
              for (let iCounter = 0; iCounter < carrierCategory.length; iCounter++) {
                this.actionCategoriesSelectedItems.push(
                  { "id": carrierCategory[iCounter].carrierCatId, "itemName": carrierCategory[iCounter].carrierCatName }
                );
                this.actionCategoriesListOfItem.push(carrierCategory[iCounter].carrierCatId);
              }
            }
          }


          this.addUpdateGeneralAlert.setValue({
            generalAlertsId: this.generalAlertsModel.generalAlertsId,
            alertDate: this.generalAlertsModel.alertDate,
            subject: this.generalAlertsModel.subject,
            message: this.generalAlertsModel.message,
            wirelessState: this.wirelessSelectedItemsState,
            wirelessStateOp: this.generalAlertsModel.wirelessStateOp,
            voipStateOp: this.generalAlertsModel.voipStateOp,
            voipState: this.voipSelectedItemsState,
            iprStateOp: this.generalAlertsModel.iprStateOp,
            iprState: this.iprSelectedItemsState,
            iprProviders: this.iprSelectedItemsForProvider,
            actionStateOp: this.generalAlertsModel.actionStateOp,
            actionStates: this.actionItemSelectedItemsState,
            actionTypes: this.actionTypesSelectedItems,
            actionCategories: this.actionCategoriesSelectedItems
          });

          this.isDataLoad = false;
        }
        else {
          this.isDataLoad = false;
          this.showMessage(data["message"], 'Error', 'error');
        }
      }, error => {
        this.isDataLoad = false;
        this.showMessage(error, 'Error', 'error');
      })
  }

  GetIPRProvider(): void {
    this.iprDropdownListForProvider = [];
    this.actionItemServices.GetActionItemProvider(this.tokenKey).subscribe(
      data => {
        if (data["list"] != null && data["status"] != "Failure") {
          for (let iCounter = 0; iCounter < data["list"].length; iCounter++) {
            this.iprDropdownListForProvider.push(
              { "id": data["list"][iCounter].carrierId, "itemName": data["list"][iCounter].businessName }
            );
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  setWirelessDropDownSetting() {
    this.wirelessDropdownStateSettings = {
      singleSelection: false,
      text: "Select State(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No state available',
      showCheckbox: true,
      disabled: this.isWirelessControlEnable,
      maxHeight: this.multiSelectDropDownHeight
    };
  }

  setVoIPDropDownSetting() {
    this.voipDropdownStateSettings = {
      singleSelection: false,
      text: "Select State(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No state available',
      showCheckbox: true,
      disabled: this.isVoIPControlEnable,
      maxHeight: this.multiSelectDropDownHeight
    };
  }

  setIPRDropDownSetting() {
    this.iprDropdownStateSettings = {
      singleSelection: false,
      text: "Select State(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No state available',
      showCheckbox: true,
      disabled: this.isIPRControlEnable,
      maxHeight: this.multiSelectDropDownHeight
    };

    this.iprDropdownSettingsForProvider = {
      singleSelection: false,
      text: "Select Provider(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No provider available',
      showCheckbox: true,
      disabled: this.isIPRControlEnable,
      maxHeight: this.multiSelectDropDownHeight
    };
  }

  setActionItemDropDownSetting() {
    this.actionItemDropdownStateSettings = {
      singleSelection: false,
      text: "Select State(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No state available',
      showCheckbox: true,
      disabled: this.isActionItemControlEnable,
      maxHeight: this.multiSelectDropDownHeight
    };

    this.actionTypesDropdownSettings = {
      singleSelection: false,
      text: "Select Categories",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No categories available',
      showCheckbox: true,
      disabled: this.isActionItemControlEnable,
      maxHeight: this.multiSelectDropDownHeight
    };

    this.actionCategoriesDropdownSettings = {
      singleSelection: false,
      text: "Select Carrier Type(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No carriers type available',
      showCheckbox: true,
      disabled: this.isActionItemControlEnable,
      maxHeight: this.multiSelectDropDownHeight
    };
  }



  isAlertDate(dateArray: any) {
    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      this.generalAlertsModel.alertDate = mm1 + '/' + dd1 + '/' + dateArray.getFullYear();
    }
    else
      this.generalAlertsModel.alertDate = null;
  }

  isGeneralAlertsChecked($event) {
    this.isGeneralAlerts = !$event.currentTarget.checked;
  }

  isWirelessChecked($event: any) {
    if ($event.currentTarget.checked == false) {
      this.generalAlertsModel.wirelessStateOp = undefined;
    }
    this.wirelessSelectedItemsState = [];
    this.wirelessListOfState = [];
    this.isWirelessControlEnable = !$event.currentTarget.checked;
    this.setWirelessDropDownSetting();

  }

  isVoIPChecked($event: any) {
    if ($event.currentTarget.checked == false) {
      this.generalAlertsModel.voipStateOp = undefined;
    }
    this.voipSelectedItemsState = [];
    this.voipListOfState = [];
    this.isVoIPControlEnable = !$event.currentTarget.checked;
    this.setVoIPDropDownSetting();
  }

  isIPRChecked($event: any) {
    if ($event.currentTarget.checked == false) {
      this.generalAlertsModel.iprStateOp = undefined;
      this.generalAlertsModel.iprProviderOp = undefined;
    }
    this.iprSelectedItemsState = [];
    this.iprSelectedItemsForProvider = [];
    this.iprListOfState = [];
    this.iprListOfProvider = [];
    this.isIPRControlEnable = !$event.currentTarget.checked;
    this.setIPRDropDownSetting();
  }

  isActionItemChecked($event: any) {
    if ($event.currentTarget.checked == false) {
      this.generalAlertsModel.actionStateOp = undefined;
      this.generalAlertsModel.actionTypeOp = undefined;
      this.generalAlertsModel.actionCategoryOp = undefined;
    }
    this.actionItemSelectedItemsState = [];
    this.actionTypesSelectedItems = [];
    this.actionCategoriesSelectedItems = [];
    this.actionItemListOfState = [];
    this.actionTypesListOfItem = [];
    this.actionCategoriesListOfItem = [];
    this.isActionItemControlEnable = !$event.currentTarget.checked;
    this.setActionItemDropDownSetting();
  }

  GetStateData() {
    this.userServices.GetStateFindByUserID(this.tokenKey, 0).subscribe(
      data => {
        if (data["status"] == "Success") {
          // Set All Item
          if (data['wirelessStates'] != null) {
            let wirelessStates = data['wirelessStates'];
            for (let iCounter = 0; iCounter < wirelessStates.length; iCounter++) {
              this.wirelessDropdownListState.push({ "id": wirelessStates[iCounter].stateId, "itemName": wirelessStates[iCounter].name });
            }
          }
          if (data['iprStates'] != null) {
            let iprStates = data['iprStates'];
            for (let iCounter = 0; iCounter < iprStates.length; iCounter++) {
              this.iprDropdownListState.push({ "id": iprStates[iCounter].stateId, "itemName": iprStates[iCounter].name });
            }
          }

          if (data['voIPStates'] != null) {
            let voIPStates = data['voIPStates'];
            for (let iCounter = 0; iCounter < voIPStates.length; iCounter++) {
              this.voipDropdownListState.push({ "id": voIPStates[iCounter].stateId, "itemName": voIPStates[iCounter].name });
            }
          }

          if (data['actionStates'] != null) {
            let actionStates = data['actionStates'];
            for (let iCounter = 0; iCounter < actionStates.length; iCounter++) {
              this.actionItemDropdownListState.push({ "id": actionStates[iCounter].stateId, "itemName": actionStates[iCounter].name });
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }

      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  ActionTypes(): void {
    this.actionTypesDropdownList = [];
    this.actionItemServices.GetActionItemsType(this.tokenKey).subscribe(
      data => {
        if (data["actionType"] != null && data["status"] != "Failure") {
          for (let iCounter = 0; iCounter < data["actionType"].length; iCounter++) {
            this.actionTypesDropdownList.push(
              { "id": data["actionType"][iCounter].actionItemTypeId, "itemName": data["actionType"][iCounter].typeName }
            );
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  GetCarrierCategories() {
    this.actionItemServices.GetCarrierCategories(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          let carrierCategories = data['companyModel'];
          if (carrierCategories != null) {
            if (carrierCategories.length > 0) {
              for (let iCounter = 0; iCounter < carrierCategories.length; iCounter++) {
                this.actionCategoriesDropdownList.push({ "id": parseInt(carrierCategories[iCounter].carrierCatId), "itemName": carrierCategories[iCounter].carrierCatName });
              }
            }
          }
        }
      });
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  // =====================================================================
  // WIRELESS
  // =====================================================================

  onWirelessSelectedState($event) {
    if (this.wirelessListOfState != null) {
      if (this.wirelessListOfState.length > 0) {
        this.wirelessListOfState = this.removeElemetninArray(this.wirelessListOfState, $event.id)
        this.wirelessListOfState.push($event.id);
      }
      else {
        this.wirelessListOfState.push($event.id);
      }
    }
  }

  onDeWirelessSelectedState($event) {
    if (this.wirelessListOfState != null) {
      if (this.wirelessListOfState.length > 0) {
        this.wirelessListOfState = this.removeElemetninArray(this.wirelessListOfState, $event.id)
      }
    }
  }

  onWirelessSelectStateAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.wirelessListOfState != null) {
        if (this.wirelessListOfState.length > 0) {
          this.wirelessListOfState = this.removeElemetninArray(this.wirelessListOfState, $event[iCounter].id)
          this.wirelessListOfState.push($event[iCounter].id);
        }
        else {
          this.wirelessListOfState.push($event[iCounter].id);
        }
      }
    }
  }

  onDeWirelessSelectStateAll($event) {
    this.wirelessListOfState = [];
  }

  // =====================================================================
  // VoIP
  // =====================================================================
  onVoIpSelectedState($event) {
    if (this.voipListOfState != null) {
      if (this.voipListOfState.length > 0) {
        this.voipListOfState = this.removeElemetninArray(this.voipListOfState, $event.id)
        this.voipListOfState.push($event.id);
      }
      else {
        this.voipListOfState.push($event.id);
      }
    }
  }

  onDeVoIpSelectedState($event) {
    if (this.voipListOfState != null) {
      if (this.voipListOfState.length > 0) {
        this.voipListOfState = this.removeElemetninArray(this.voipListOfState, $event.id)
      }
    }
  }

  onVoIpSelectStateAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.voipListOfState != null) {
        if (this.voipListOfState.length > 0) {
          this.voipListOfState = this.removeElemetninArray(this.voipListOfState, $event[iCounter].id)
          this.voipListOfState.push($event[iCounter].id);
        }
        else {
          this.voipListOfState.push($event[iCounter].id);
        }
      }
    }
  }

  onDeVoIpSelectStateAll($event) {
    this.voipListOfState = [];
  }

  // =====================================================================
  // IPR - STATE
  // =====================================================================

  onIPRSelectedState($event) {
    if (this.iprListOfState != null) {
      if (this.iprListOfState.length > 0) {
        this.iprListOfState = this.removeElemetninArray(this.iprListOfState, $event.id)
        this.iprListOfState.push($event.id);
      }
      else {
        this.iprListOfState.push($event.id);
      }
    }
  }

  onIPRDeSelectedState($event) {
    if (this.iprListOfState != null) {
      if (this.iprListOfState.length > 0) {
        this.iprListOfState = this.removeElemetninArray(this.iprListOfState, $event.id)
      }
    }
  }

  onIPRSelectStateAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.iprListOfState != null) {
        if (this.iprListOfState.length > 0) {
          this.iprListOfState = this.removeElemetninArray(this.iprListOfState, $event[iCounter].id)
          this.iprListOfState.push($event[iCounter].id);
        }
        else {
          this.iprListOfState.push($event[iCounter].id);
        }
      }
    }
  }

  onIPRDeSelectStateAll($event) {
    this.iprListOfState = [];
  }

  // =====================================================================
  // IPR - PROVIDER
  // =====================================================================

  onIPRSelectedProvider($event) {
    if (this.iprListOfProvider != null) {
      if (this.iprListOfProvider.length > 0) {
        this.iprListOfProvider = this.removeElemetninArray(this.iprListOfProvider, $event.id)
        this.iprListOfProvider.push($event.id);
      }
      else {
        this.iprListOfProvider.push($event.id);
      }
    }
  }

  onIPRDeSelectedProvider($event) {
    if (this.iprListOfProvider != null) {
      if (this.iprListOfProvider.length > 0) {
        this.iprListOfProvider = this.removeElemetninArray(this.iprListOfProvider, $event.id)
      }
    }
  }

  onIPRSelectAllProvider($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.iprListOfProvider != null) {
        if (this.iprListOfProvider.length > 0) {
          this.iprListOfProvider = this.removeElemetninArray(this.iprListOfProvider, $event[iCounter].id)
          this.iprListOfProvider.push($event[iCounter].id);
        }
        else {
          this.iprListOfProvider.push($event[iCounter].id);
        }
      }
    }
  }

  onIPRDeSelectAllProvider($event) {
    this.iprListOfProvider = [];
  }

  // =====================================================================
  // ACTION ITEM - STATE
  // =====================================================================

  onActionItemSelectedState($event) {
    if (this.actionItemListOfState != null) {
      if (this.actionItemListOfState.length > 0) {
        this.actionItemListOfState = this.removeElemetninArray(this.actionItemListOfState, $event.id)
        this.actionItemListOfState.push($event.id);
      }
      else {
        this.actionItemListOfState.push($event.id);
      }
    }
  }

  onDeActionItemSelectedState($event) {
    if (this.actionItemListOfState != null) {
      if (this.actionItemListOfState.length > 0) {
        this.actionItemListOfState = this.removeElemetninArray(this.actionItemListOfState, $event.id)
      }
    }
  }

  onActionItemSelectStateAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.actionItemListOfState != null) {
        if (this.actionItemListOfState.length > 0) {
          this.actionItemListOfState = this.removeElemetninArray(this.actionItemListOfState, $event[iCounter].id)
          this.actionItemListOfState.push($event[iCounter].id);
        }
        else {
          this.actionItemListOfState.push($event[iCounter].id);
        }
      }
    }
  }

  onDeActionItemSelectStateAll($event) {
    this.actionItemListOfState = [];
  }

  // =====================================================================
  // ACTION ITEM - TYPES
  // =====================================================================

  onActionTypesSelected($event) {
    if (this.actionTypesListOfItem != null) {
      if (this.actionTypesListOfItem.length > 0) {
        this.actionTypesListOfItem = this.removeElemetninArray(this.actionTypesListOfItem, $event.id)
        this.actionTypesListOfItem.push($event.id);
      }
      else {
        this.actionTypesListOfItem.push($event.id);
      }
    }
  }

  onDeActionTypesSelected($event) {
    if (this.actionTypesListOfItem != null) {
      if (this.actionTypesListOfItem.length > 0) {
        this.actionTypesListOfItem = this.removeElemetninArray(this.actionTypesListOfItem, $event.id)
      }
    }
  }

  onActionTypesSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.actionTypesListOfItem != null) {
        if (this.actionTypesListOfItem.length > 0) {
          this.actionTypesListOfItem = this.removeElemetninArray(this.actionTypesListOfItem, $event[iCounter].id)
          this.actionTypesListOfItem.push($event[iCounter].id);
        }
        else {
          this.actionTypesListOfItem.push($event[iCounter].id);
        }
      }
    }
  }

  onDeActionTypesSelectAll($event) {
    this.actionTypesListOfItem = [];
  }

  // =====================================================================
  // ACTION ITEM - ACTION CATEGORIES
  // =====================================================================

  onActionCategoriesSelected($event) {
    if (this.actionCategoriesListOfItem != null) {
      if (this.actionCategoriesListOfItem.length > 0) {
        this.actionCategoriesListOfItem = this.removeElemetninArray(this.actionCategoriesListOfItem, $event.id)
        this.actionCategoriesListOfItem.push($event.id);
      }
      else {
        this.actionCategoriesListOfItem.push($event.id);
      }
    }
  }

  onDeActionCategoriesSelected($event) {
    if (this.actionCategoriesListOfItem != null) {
      if (this.actionCategoriesListOfItem.length > 0) {
        this.actionCategoriesListOfItem = this.removeElemetninArray(this.actionCategoriesListOfItem, $event.id)
      }
    }
  }

  onActionCategoriesSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.actionCategoriesListOfItem != null) {
        if (this.actionCategoriesListOfItem.length > 0) {
          this.actionCategoriesListOfItem = this.removeElemetninArray(this.actionCategoriesListOfItem, $event[iCounter].id)
          this.actionCategoriesListOfItem.push($event[iCounter].id);
        }
        else {
          this.actionCategoriesListOfItem.push($event[iCounter].id);
        }
      }
    }
  }

  onDeActionCategoriesSelectAll($event) {
    this.actionCategoriesListOfItem = [];
  }

  saveAddUpdateGeneralAlert(addUpdateGeneralAlert: any) {

    if (addUpdateGeneralAlert.value['alertDate'] == '') {
      this.showMessage('Please select alert date', 'Error', 'error');
      return;
    }
    let currentDate = new Date();
    if (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) > addUpdateGeneralAlert.value["alertDate"]) {
      this.showMessage('Previous alert date not allowed', 'Error', 'error');
      return;
    }

    if (addUpdateGeneralAlert.value['subject'] == '' || typeof (addUpdateGeneralAlert.value['subject']) == 'undefined') {
      this.showMessage('Please enter subject', 'Error', 'error');
      return;
    }
    if (addUpdateGeneralAlert.value['subject'] != '') {
      if (addUpdateGeneralAlert.value['subject'].toString().trim() == '') {
        this.showMessage('Please enter subject', 'Error', 'error');
        return;
      }
    }

    let planText = this.removeHTMLTags(addUpdateGeneralAlert.value['message']);
    if (planText === false) {
      this.showMessage('Please enter message', 'Error', 'error');
      return;
    }
    if (planText != '') {
      if (planText.trim() == '') {
        this.showMessage('Please enter message', 'Error', 'error');
        return;
      }
    }

    if (this.isGeneralAlerts && this.isWirelessControlEnable && this.isVoIPControlEnable && this.isIPRControlEnable && this.isActionItemControlEnable) {
      this.showMessage('Please select at least one alert criteria', 'Error', 'error');
      return;
    }

    // Vaidation For Wireless 
    if (!this.isWirelessControlEnable) {
      if (typeof (addUpdateGeneralAlert.value['wirelessStateOp']) == 'undefined' || addUpdateGeneralAlert.value['wirelessStateOp'] == '0') {
        this.showMessage('Please select at least wireless state option', 'Error', 'error');
        return;
      }
      if (this.wirelessListOfState.length == 0) {
        this.showMessage('Please select at least one wireless state', 'Error', 'error');
        return;
      }
    }

    // Vaidation For VoIP     
    if (!this.isVoIPControlEnable) {
      if (typeof (addUpdateGeneralAlert.value['voipStateOp']) == 'undefined' || addUpdateGeneralAlert.value['voipStateOp'] == '0') {
        this.showMessage('Please select at least one voip state option', 'Error', 'error');
        return;
      }
      if (this.voipListOfState.length == 0) {
        this.showMessage('Please select at least one voip state', 'Error', 'error');
        return;
      }
    }

    // Vaidation For IPR     
    if (!this.isIPRControlEnable) {
      if (this.iprListOfState.length == 0 && this.iprListOfProvider.length == 0) {
        this.showMessage('Please select at least one ipr state/provider', 'Error', 'error');
        return;
      }
      if ((typeof (addUpdateGeneralAlert.value['iprStateOp']) == 'undefined' || addUpdateGeneralAlert.value['iprStateOp'] == '0') && this.iprListOfState.length > 0) {
        this.showMessage('Please select at least one ipr subscribe/favorite state option', 'Error', 'error');
        return;
      }
    }

    // Vaidation For ACTION TYPE     

    if (!this.isActionItemControlEnable) {
      if (this.actionItemListOfState.length == 0 && this.actionTypesListOfItem.length == 0 && this.actionCategoriesListOfItem.length == 0) {
        this.showMessage('Please select at least one trac-it state/categories/carrier type dropdown item', 'Error', 'error');
        return;
      }
      if ((typeof (addUpdateGeneralAlert.value['actionStateOp']) == 'undefined' || addUpdateGeneralAlert.value['actionStateOp'] == '0' || addUpdateGeneralAlert.value['actionStateOp'] == null) && this.actionItemListOfState.length > 0) {
        this.showMessage('Please select at least one trac-it state option', 'Error', 'error');
        return;
      }
    }

    if (typeof (addUpdateGeneralAlert.value['generalAlertsId']) == 'undefined') {
      this.generalAlertsModel.generalAlertsId = 0;
    }
    else {
      this.generalAlertsModel.generalAlertsId = addUpdateGeneralAlert.value['generalAlertsId'];
    }

    if (typeof (addUpdateGeneralAlert.value['subject']) == 'undefined') {
      this.generalAlertsModel.subject = '';
    }
    else {
      this.generalAlertsModel.subject = addUpdateGeneralAlert.value['subject'];
    }

    if (typeof (addUpdateGeneralAlert.value['message']) == 'undefined') {
      this.generalAlertsModel.message = '';
    }
    else {
      this.generalAlertsModel.message = addUpdateGeneralAlert.value['message'];
    }

    this.generalAlertsModel.isGeneralAlerts = !this.isGeneralAlerts;
    this.generalAlertsModel.productId = this.setProduct();
    this.generalAlertsModel.createdBy = this.userID;
    this.generalAlertsModel.modifiedBy = this.userID;

    // WIRELESS
    if (typeof (addUpdateGeneralAlert.value['wirelessStateOp']) == 'undefined') {
      this.generalAlertsModel.wirelessStateOp = '0';
    }
    else {
      this.generalAlertsModel.wirelessStateOp = addUpdateGeneralAlert.value['wirelessStateOp'];
    }

    if (this.wirelessListOfState.length == 0) {
      this.generalAlertsModel.wirelessStates = '0';
    }
    else {
      this.generalAlertsModel.wirelessStates = this.wirelessListOfState.join(',');
    }

    // VoIP
    if (typeof (addUpdateGeneralAlert.value['voipStateOp']) == 'undefined') {
      this.generalAlertsModel.voipStateOp = '0';
    }
    else {
      this.generalAlertsModel.voipStateOp = addUpdateGeneralAlert.value['voipStateOp'];
    }

    if (this.voipListOfState.length == 0) {
      this.generalAlertsModel.voipStates = '0';
    }
    else {
      this.generalAlertsModel.voipStates = this.voipListOfState.join(',');
    }

    // IPR - State
    if (typeof (addUpdateGeneralAlert.value['iprStateOp']) == 'undefined') {
      this.generalAlertsModel.iprStateOp = '0';
    }
    else {
      this.generalAlertsModel.iprStateOp = addUpdateGeneralAlert.value['iprStateOp'];
    }

    if (this.iprListOfState.length == 0) {
      this.generalAlertsModel.iprStates = '0';
    }
    else {
      this.generalAlertsModel.iprStates = this.iprListOfState.join(',');
    }

    // IPR - Provider
    if (typeof (addUpdateGeneralAlert.value['iprProviderOp']) == 'undefined') {
      this.generalAlertsModel.iprProviderOp = '0';
    }
    else {
      this.generalAlertsModel.iprProviderOp = addUpdateGeneralAlert.value['iprProviderOp'];
    }

    if (this.iprListOfProvider.length == 0) {
      this.generalAlertsModel.iprProviders = '0';
    }
    else {
      this.generalAlertsModel.iprProviders = this.iprListOfProvider.join(',');
    }

    // Action Item - State
    if (typeof (addUpdateGeneralAlert.value['actionStateOp']) == 'undefined') {
      this.generalAlertsModel.actionStateOp = '0';
    }
    else {
      this.generalAlertsModel.actionStateOp = addUpdateGeneralAlert.value['actionStateOp'];
    }

    if (this.actionItemListOfState.length == 0) {
      this.generalAlertsModel.actionStates = '0';
    }
    else {
      this.generalAlertsModel.actionStates = this.actionItemListOfState.join(',');
    }

    if (typeof (addUpdateGeneralAlert.value['actionTypes']) == 'undefined') {
      this.generalAlertsModel.actionTypes = '0';
    }
    else {
      this.generalAlertsModel.actionTypes = this.actionTypesListOfItem.join(',');
    }

    if (typeof (addUpdateGeneralAlert.value['actionCategories']) == 'undefined') {
      this.generalAlertsModel.actionCategories = '0';
    }
    else {
      this.generalAlertsModel.actionCategories = this.actionCategoriesListOfItem.join(',');
    }

    this.actionItemServices.AddUpdateGeneralAlerts(this.tokenKey, this.generalAlertsModel).subscribe(
      data => {
        if (data['status'] == 'Success') {          
          this.showMessage('General alerts successfully saved', 'Success', 'success');
          this.router.navigate(['/admin/inteserra360/i360Alerts']);
        }
        else
          this.showMessage(data['status'], 'Error', 'error');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  setProduct() {
    let prodId = '';
    let sparator = '';
    if (!this.isGeneralAlerts) {
      prodId = ToolEnum.generalalert.toString();
      sparator = ',';
    }


    if (!this.isWirelessControlEnable) {
      if (prodId != '')
        prodId += sparator + ToolEnum.wireless.toString();
      else {
        prodId += sparator + ToolEnum.wireless.toString();
        sparator = ',';
      }
    }

    if (!this.isVoIPControlEnable) {
      if (prodId != '') {
        prodId += sparator + ToolEnum.voip.toString();
      }
      else {
        prodId += sparator + ToolEnum.voip.toString();
        sparator = ',';
      }
    }

    if (!this.isIPRControlEnable) {
      if (prodId != '') {
        prodId += sparator + ToolEnum.ipr.toString();
      }
      else {
        prodId += sparator + ToolEnum.ipr.toString();
        sparator = ',';
      }
    }

    if (!this.isActionItemControlEnable) {
      if (prodId != '') {
        prodId += sparator + ToolEnum.actionitem.toString();
      }
      else {
        prodId += sparator + ToolEnum.actionitem.toString();
        sparator = ',';
      }
    }

    return prodId;
  }

  removeHTMLTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  }

}
