import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { BriefingModel } from 'src/Models/TrfaAlertsModel';
import { TrfaAlertService } from 'src/Services/alert.service';

@Component({
  selector: 'app-briefing-alerts',
  templateUrl: './briefing-alerts.component.html',
  styleUrls: ['./briefing-alerts.component.css']
})
export class BriefingAlertsComponent implements OnInit {

  tokenKey: string = '';
  isloading: boolean = false;
  filteModel = new FilterModel();
  BriefingModel: BriefingModel[];
  totalRecordCount: number = -1;
  isAscending: boolean;
  briefingId: number = 0;

  upDownAlertDateCSS: string = '';
  upDownTitleCSS: string = '';
  upDownStatusCSS: string = '';
  upDownCreatedDateCSS: string = '';
  upDownModifiedDateCSS: string = '';
  Id: number = 0;
  IsSendEmail: boolean
  productId:number;

   constructor(private toastr: ToastrService, private router: Router,private trfaAlertServices:TrfaAlertService) { 
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.productId=ToolEnum.briefingServices
  }


  ngOnInit() {
    this.filteModel.pageNumber = '1';
    this.filteModel.pageSize = '10';
    this.filteModel.keywordFilter = '';
    this.filteModel.productId = this.productId;
    this.filteModel.orderBy = 'briefingId';
    this.filteModel.briefingId='0';
    this.GetBriefingAlertsFindByPages();
  }


  GetBriefingAlertsFindByPages() {
    this.isloading = true;
    if (this.filteModel.keywordFilter == "null") this.filteModel.keywordFilter = " ";
    this.trfaAlertServices.GetBriefingAlertsFindByPages(this.tokenKey, this.filteModel).subscribe(data => {
      if (data['status'] == 'Success') {
        this.isloading = false;
        this.BriefingModel = data['briefingAlertList'];
        if (this.BriefingModel != null) {
          if (this.BriefingModel.length > 0) {
            this.totalRecordCount = +this.BriefingModel[0].totalRowCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      }
      else {
        this.showMessage(data['status'], 'Error', 'error');
      }

    },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }


  keywordFilters($event) {
    this.filteModel.keywordFilter = $event.target.value;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filteModel.keywordFilter = $event.target.value;
    }
    else { this.filteModel.keywordFilter = ''; }

    this.GetBriefingAlertsFindByPages();
  }

  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.filteModel.orderBy = sortBy + ' DESC';
      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.filteModel.orderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.GetBriefingAlertsFindByPages();
  }

  setDescCSS(sortBy) {
    if (sortBy === "updateDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-down';
      this.upDownTitleCSS = '';
    }
    else if (sortBy === "title") {
      this.upDownAlertDateCSS = '';
      this.upDownTitleCSS = 'fa fa-arrow-down';
    }
  }

  setAscCSS(sortBy) {
    if (sortBy === "updateDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-up';
      this.upDownTitleCSS = '';
    }
    else if (sortBy === "title") {
      this.upDownAlertDateCSS = '';
      this.upDownTitleCSS = 'fa fa-arrow-up';
    }   
  }

  pageChanged($event) {
    this.filteModel.pageNumber = parseInt($event.page).toString();
    this.GetBriefingAlertsFindByPages();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  redirectToEditView(briefingId: any) {
    this.router.navigate(['/admin/briefingServices/briefing-Alerts/user/' + briefingId]);
  }

  SetCancelMailId(briefingId: any) {
    this.Id = briefingId;
  }

  CancelMail()
  {
    this.trfaAlertServices.notsentAlertmail(this.tokenKey, this.Id,this.productId).subscribe(
      data => {
      if (data['status'] == 'Success') {
        this.showMessage('', 'Success', 'success');
        this.GetBriefingAlertsFindByPages();
      }
      else
        this.showMessage(data['status'], 'Error', 'error');
    },
    error => {
      this.showMessage(error, 'Error', 'error');
    }
  );
  }
}