import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TRFAFundTypeModel } from 'src/Models/TRFAFundTypeModel';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { TrfaFundTypeMasterService } from 'src/Services/trfa-fund-type--master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-fund-add-update',
  templateUrl: './fund-add-update.component.html',
  styleUrls: ['./fund-add-update.component.css']
})
export class FundAddUpdateComponent implements OnInit {

  fundMasterForm: FormGroup;
  userID: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  stateList: StateMasterModel[] = [];
  selectedState = [];
  fundTypeList: TRFAFundTypeModel[] = [];
  selectedFundType = [];
  isEdit: boolean = false;

  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};
  dropdownSettingsDateFundTypeFilter = {};

  dropdownDateFundTypeFilter = [];
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  module="TRFA";
  moduleList: any[] = [ {name: 'Active', key: 'Active'}, {name: 'Archive', key: 'Archive'},{name: 'Draft', key: 'Draft'}];
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private FundTypeService: TrfaFundTypeMasterService, private stateService: StateMasterService,
    private toastr: ToastrService, private fundService: TrfaFundMasterService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.getFundType();
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsDateFundTypeFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No FundType(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.fundMasterForm = this._fb.group({
      fundID: 0,
      fundName: ['',Validators.required],
      stateID: [''],
      fundTypeID: [''],
      selectedModule: ['', Validators.required],
      sortOrder: ['',Validators.required],
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetfundById(params['id']);
        }
      }
    });
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey,this.module).subscribe(
      data => {
        this.stateList = data['state'];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownDateFilter.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  GetfundById(fundId) {
    this.fundService.GetFundById(this.tokenKey, parseInt(fundId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.fundMasterForm.patchValue({
            fundID: result['fund'].fundID,
            fundTypeID: result['fund'].fundTypeID,
            fundName: result['fund'].fundName,
            stateID: result['fund'].stateID,
            selectedModule: result['fund'].status,
            sortOrder: result['fund'].sortOrder
          });
          if (this.stateList.length > 0) {
            let data = this.stateList.filter(x => x.stateId == result['fund'].stateID)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
          else
            setTimeout(() => {
              let data = this.stateList.filter(x => x.stateId == result['fund'].stateID)[0];
              this.selectedState.push({ "id": data.stateId, "itemName": data.name })
            }, 500);
          if (this.fundTypeList.length > 0) {
            let data = this.fundTypeList.filter(x => x.fundTypeID == result['fund'].fundTypeID)[0];
            this.selectedFundType.push({ "id": data.fundTypeID, "itemName": data.fundTypeName })
          }
          else
            setTimeout(() => {
              let data = this.fundTypeList.filter(x => x.fundTypeID == result['fund'].fundTypeID)[0];
              this.selectedFundType.push({ "id": data.fundTypeID, "itemName": data.fundTypeName })
            }, 800);
        }
      });
  }
  getFundType() {
    this.FundTypeService.GetFundType(this.tokenKey,true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.fundTypeList = result['fundType'];
          this.fundTypeList.forEach(x => {
            this.dropdownDateFundTypeFilter.push({ "id": x.fundTypeID, "itemName": x.fundTypeName })
          });
        }
      },
      error => { }
    );
  }
  cancelfund() {
    let navigation = '/admin/' + this.SelectedTool + '/fund';
    this.router.navigate([navigation]);
  }

  save() {
    this.isSubmit = true;
    let data = this.fundMasterForm.value;
    if (!this.fundMasterForm.valid || this.selectedState.length == 0 || this.selectedFundType.length == 0 || !data.fundName.trim())
      return
    data["createdBy"] = this.userID;
    let value={
      fundID:data.fundID,
      fundTypeID:data.fundTypeID,
      stateId:data.stateID,
      fundName:data.fundName.trim(),
      status:data.selectedModule,
      sortOrder:data.sortOrder
    }
    this.fundService.FundAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.fundID > 0)
              this.showMessage('Fund updated successfully', 'Success', 'success');
            else
              this.showMessage('Fund saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/fund']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  onChangeState(event) {
    this.fundMasterForm.patchValue({
      stateID: event.id
    });
  }

  onDeSelectDateFilterAll($event) {
    this.selectedState = [];
    //this.dropdownDateFundTypeFilter = [];
  }

  onDeSelectDateFilterFundTypeAll($event) {
    this.selectedFundType = [];
  }

  onChangeFundType(event) {
    this.fundMasterForm.patchValue({
      fundTypeID: event.id
    });
  }


}
