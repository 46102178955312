import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { FavoriteFilterModel } from 'src/Models/FavoriteFilterModel';
import { ObligationUpdateGetModel } from 'src/Models/ObligationUpdatesModel';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';
import { FavoriteService } from 'src/Services/favorite.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-cfo-frontupdates',
  templateUrl: './cfo-frontupdates.component.html',
  styleUrls: ['./cfo-frontupdates.component.css']
})
export class CfoFrontupdatesComponent implements OnInit {

  stateCode: any;
  _userID: string = "";
  ProductId: number = 0;

  dropdownListCarrier = [];
  selectedItemsCarrier = [];
  dropdownSettingsCarrier = {};
  listOfCarrierIds = [];
  carrierId: string = ' ';
  carrierTypeList: any[] = [];
 
  tokenKey: string;
  SearchItem: string = '';
  fromDate: string = '';
  toDate: string = '';

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;

  rangeOfupdatesDates: any;

  upDowNoteCSS: string = '';
  upDowCarrierNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDowcfrCSS: string = '';
  isAscending: boolean = true;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  OrderByName: string = 'UpdateDate';

  dropdownDateSelectedColumnFilter = [];
  selectedColumn = [];
  listOfColumnId = [];
  dropdownSettingsDateselectedColumnFilter = {};

  SelectedCarriertypeFav: number = 0;
  favouriteStateModel: FavoriteFilterModel[] = [];
  displayFavorite: number = 0;
  blockedDocument = false;

  isShowprogressSpinner = false;
  listofUpdate: ObligationUpdateGetModel[]

  isColumnExist: boolean = true;
  listofMain911PrintData: any[] = [];
  quaryValues: string = "";
  lstUpdate:any;
  INTERNALINTESERRAUSERS:boolean=false;
  
  constructor(private appActivity: ApplicationActivityService,
    private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private activedRoute: ActivatedRoute,
    private toastr: ToastrService, private favorite: FavoriteService,
    private carrierObligationService: CarrierObligationService) {

    let Inteseraemailid= localStorage.getItem('useremail');

    if (Inteseraemailid != '' && Inteseraemailid != null) {
      if (Inteseraemailid.indexOf('@inteserra.com') !== -1) {
        this.INTERNALINTESERRAUSERS = true;
      }
      else {
        this.INTERNALINTESERRAUSERS = false;
      }
    }
    else {
      this.INTERNALINTESERRAUSERS = false;
    }


    this.tokenExpireCalculation(
      sessionStorage.getItem("appStartTime"),
      sessionStorage.getItem("tokenExpiryValue")
    );
    this._userID = sessionStorage.getItem('UserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    if (this.SelectedTool == 'view 47') {
      this.SelectedTool = 'view47'
    }

    sessionStorage.setItem('tokenValue', localStorage.getItem('tokenValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.ProductId = ToolEnum.crra;
    this.titleService.setTitle('Updates');
    this.tokenKey = sessionStorage.getItem("tokenValue");
    this.OrderBy = 'UpdateDate desc';
    this.upDowUpdateDateCSS = 'active-up';
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsCarrier = {
      singleSelection: false,
      text: "Filter by Carrier(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Carrier Type(s) available",
      showCheckbox: true,
    };

    this.dropdownSettingsDateselectedColumnFilter = {
      singleSelection: false,
      text: "Show Column",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Column(s) available',
      showCheckbox: true
    };
    this.getCarrier();
    this.getColumn();
    this.OrderByName = "";
    // this.activedRoute.params.subscribe((params: Params) => {
    //   if (params['id']) {

    //     if (params['id'] != null) {

    //       this.quaryValues = params['id'];
    //     }
    //     else {
    //       this.GetUpdate();
    //     }
    //   }
    //   else { this.GetUpdate(); }
    // });
    this.GetUpdate();
  }

  isUpdatesDatesFilter(value: any) {
    if (value != null) {
      if (value.length == 2) {
        const d = new Date(value[0]);
        const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

        const e = new Date(value[1]);
        const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');

        this.fromDate = a;
        this.toDate = f;
        this.resetPageNoandSize();
        this.GetUpdate();
      }
    }
  }
  pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  getCarrier() {
    this.carrierObligationService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierTypeList = result['carrier'];
          this.dropdownListCarrier = [];
          this.carrierTypeList.forEach(x => {
            this.dropdownListCarrier.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  GetUpdate() {
    this.isloading = true;

    this.carrierId = ' ';
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    if (this.Note == "null" || this.Note == "undefined") this.Note = null;
    ApplicationActivityStore('View', 'View 47 Updates', this.appActivity, ToolEnum.View47, ActionActivity['View 47'], '/View/updates');
    this.carrierObligationService.GetAllUpdateByUser(this.tokenKey, this.PageNumber, this.PageSize, this.carrierId, this.fromDate, this.toDate, this.OrderBy, parseInt(this._userID),this.displayFavorite,this.Note).subscribe(
      data => {
        this.isloading = false;
          this.listofUpdate = data['obligationupdate'];

          if (this.listofUpdate != null) {
            if (this.listofUpdate.length == 0)
              this.isRecord = true;
            else {
              this.isRecord = false;
              this.totalRecordCount = this.listofUpdate[0].totalRecordCount;
            }
          }
          else
            this.isRecord = true;

      },
      error => { this.stateCode = error }
    );
  }

  clearUpdateDate() {
    this.rangeOfupdatesDates = [];
    this.fromDate = '';
    this.toDate = '';
    this.selectedItemsCarrier = [];
    this.listOfCarrierIds=[];
    this.Note=undefined;
    this.SearchItem="";
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowNoteCSS = '';
      this.upDowCarrierNameCSS = '';
      this.upDowUpdateDateCSS = '';
      this.upDowcfrCSS='';

      if (sortBy === "UpdateNote") {
        this.upDowNoteCSS = 'active-up';
      }
      else if (sortBy === "carrierName") {
        this.upDowCarrierNameCSS = 'active-up';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowUpdateDateCSS = 'active-up';
      }
      else if (sortBy === "cfr") {
        this.upDowcfrCSS = 'active-up';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;
      this.upDowNoteCSS = '';
      this.upDowCarrierNameCSS = '';
      this.upDowUpdateDateCSS = '';
      this.upDowcfrCSS='';

      if (sortBy === "UpdateNote") {
        this.upDowNoteCSS = 'active';
      }
      else if (sortBy === "carrierName") {
        this.upDowCarrierNameCSS = 'active';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowUpdateDateCSS = 'active';
      }
      else if (sortBy === "cfr") {
        this.upDowcfrCSS = 'active';
      }
    }
    this.OrderByName = sortBy;
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  getColumn() {

    this.dropdownDateSelectedColumnFilter = [
      { "id": 1, "itemName": 'Carrier Type' },
      { "id": 2, "itemName": 'UpdateNote' },
      { "id": 3, "itemName": 'Update Date' },
    ]
    this.selectedColumn = [{ "id": 1, "itemName": 'Carrier Type' },
    { "id": 2, "itemName": 'UpdateNote' },
    { "id": 3, "itemName": 'Update Date' },
    ]
  }

  isCheckColum(id) {
    let data = this.selectedColumn.filter((x => x.id == id))[0];
    if (data != null)
      return true
    else return false
  }

  AddToFavourite(carrier, IsFavourite: number) {
    let carrierId = carrier.carrierId;
    if (IsFavourite == 0) {
      this.SelectedCarriertypeFav = carrierId;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavoriteCarrier(carrierId, IsFavourite);
    }
  }

  onSelectCarrier($event) {
    this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event.id)
    this.listOfCarrierIds.push($event.id);
    this.carrierId = ' ';
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIds != null) {
      if (this.listOfCarrierIds.length > 0) {
        this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event.id)
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
          this.listOfCarrierIds.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIds = [];
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    ApplicationActivityStore('View', 'View 47 Updates' + $event.page, this.appActivity, ToolEnum.View47, ActionActivity['View 47'], '/View/updates');
    this.GetUpdate();
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else
      this.Note = 'null';
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectColumn($event) {
    this.isColumnExist = true
    this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event.id)
    this.listOfColumnId.push($event.id);
    if (this.listOfColumnId.length > 0) {
    }
  }

  onDeSelectColumn($event) {

    if (this.listOfColumnId != null) {
      if (this.listOfColumnId.length > 0) {
        this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event.id)
      }
    }
    if (this.selectedColumn.length > 0) {
      this.isColumnExist = true
    }
    else {
      this.isColumnExist = false
    }
  }

  onSelectAllColumn($event) {
    this.isColumnExist = true
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfColumnId != null) {
        if (this.listOfColumnId.length > 0) {
          this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event[iCounter].id)
          this.listOfColumnId.push($event[iCounter].id);
        }
        else {
          this.listOfColumnId.push($event[iCounter].id);
        }
      }
    }
  }
  onDeSelectAllColumn($event) {
    this.isColumnExist = false
    this.selectedColumn = [];
    this.listOfColumnId = [];
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  validExport(id) {
    // if(this.exportCitiationList.length>0){
    //  let data= this.exportCitiationList.find((x)=>x.exportCitiationId==id);
    //  if(data !=null)return true;
    //  else return false
    // }
    //else return false
    return false
  }

  // updateGetAllPrintData() {
  //   this.isloading = true;
  //   this.carrierId = ' ';
  //   if (this.listOfCarrierIds.length > 0) {
  //     this.carrierId = this.listOfCarrierIds.join(',')
  //   }
  //   if (this.Note == "null") this.Note = " ";
  //   this.carrierId = this.listOfCarrierIds.join(',')
  //   this.carrierObligationService.GetAllUpdateByUser(this.tokenKey, 1, 1000000000, this.carrierId, this.fromDate, this.toDate, this.OrderBy, parseInt(this._userID), 0).subscribe(
  //     data => {
  //       this.isloading = false;
  //       this.listofMain911PrintData = data['update'];
  //       if (this.listofMain911PrintData != null) {
  //         if (this.listofMain911PrintData.length == 0) {
  //           this.isRecord = true;
  //         }
  //         else {
  //           this.isRecord = false;
  //         }
  //       }
  //       else {
  //         this.isRecord = true;
  //       }
  //       setTimeout(() => {
  //         this.print();
  //       }, 1000);
  //     },
  //     error => { }
  //   );
  // }

  //   print() {
  //     ApplicationActivityStore('Download', 'CRRA Updates Print', this.appActivity, ToolEnum.crra, ActionActivity.CRRA, '/View/updates');
  //     let printContents, popupWin;
  //     printContents = document.getElementById('printareaDiv').innerHTML;
  //     popupWin = window.open('', 'PrintWindow', 'width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
  //     popupWin.document.open();
  //     popupWin.document.write(`
  //   <html>
  //     <head>
  //       <style>
  //       body{  width: 99%;}
  //         label { font-weight: 400;
  //                 font-size: 13px;
  //                 padding: 2px;
  //                 margin-bottom: 5px;
  //               }
  //         table, td, th {
  //                border: 1px solid silver;
  //                 }
  //                 table td {
  //                font-size: 13px;
  //                word-wrap:break-word;
  //                 }

  //                  table th {
  //                font-size: 13px;
  //                 }
  //           table {
  //                 border-collapse: collapse;
  //                 width: 98%;
  //                 table-layout:fixed;
  //                 }
  //             th {
  //                 height: 26px;
  //                 }
  //       </style>
  //     </head>
  // <body onload="window.print();window.close()">${printContents}</body>
  //   </html>`
  //     );
  //     popupWin.document.close();

  //   }

  getPageLength() {
    return Math.min((this.PageNumber * this.PageSize), this.totalRecordCount);
  }

  onFilterSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
          this.listOfCarrierIds.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  replaceCommaLines(data) {
    let str = '';
    let dataToArray = data.split(',').map(item => item.trim());
    dataToArray.forEach((element, index) => {

      if ((dataToArray.length - 1) == index) {
        str = str + element;
      }
      else {
        if (((index + 1) % 3) == 0) {
          str = str + element + "<br />";
        }
        else { str = str + element + ','; }
      }
    });

    return str
  }


  SaveFavoriteCarrier(carrierId: string, IsFavourite: number) {
    this.carrierObligationService.ClientCarrierFavouriteAdd(this.tokenKey, Number(this._userID), carrierId, IsFavourite).subscribe(data => {
      if (data["status"] == "Success") {
        this.GetUpdate();
        if (IsFavourite == 1) {
          this.showMessage('Selected Carrier Type added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          this.showMessage('Selected Carrier Type removed successfully from favorite list.', 'Favorite', 'success');
        }
     
      }
      else {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
   
  }

  GetFavourite(displayFav:number){
    this.displayFavorite=displayFav;
    this.GetUpdate();
  }
}

