import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from 'src/Models/Common';
import { TRSNoticeModel } from 'src/Models/TRSNoticeModel';
import { TrsMasterService } from 'src/Services/trs-master.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';

@Component({
  selector: 'app-trs',
  templateUrl: './trs.component.html',
  styleUrls: ['./trs.component.css']
})
export class TrsComponent implements OnInit {

  stateCode: any;
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfTRSNotice: TRSNoticeModel[];

  isUserSystemAdmin: boolean = true;
  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false

  upDowStateNameCSS: string = '';
  upDowDirecCSS = '';
  upDowBillDocCSS = '';
  upDowURLCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';

  productId: number;
  trsId: number = 0;
  isloading: boolean;
  module = "Inteserra360";

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];

  PageNumber: number = 1;
  PageSize: number;
  OrderBy: string;
  isRecord: boolean = false;
  openMenuIndex: number = -1;

  constructor(private trsNoticeService: TrsMasterService, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService, private stateService: StateMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('TRS Customer Notice');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdictions",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.getState();
    this.PageNumber = 1;
    this.PageSize = 10;
    this.OrderBy = 'SortOrder desc,StateName';

    this.GetTRSNotice();
  }

  getState() {

    this.stateService.getState(this.tokenKey, this.productId).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetTRSNotice();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetTRSNotice();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetTRSNotice();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetTRSNotice();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.resetPageNoandSize();
    this.GetTRSNotice();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }


  GetTRSNotice() {
    this.isloading = true;

    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }

    this.listOfTRSNotice = [];
    this.trsNoticeService.GetAllTRSNotice(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfTRSNotice = data['trsnotice'];
          if (this.listOfTRSNotice != null) {
            if (this.listOfTRSNotice.length == 0)
              this.isRecord = true;
            else {
              this.isRecord = false;
              this.totalRecordCount = this.listOfTRSNotice[0].totalRecordCount;
            }
          }
          else {
            this.isRecord = true;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  sort(sortBy: any) {

    this.upDowStateNameCSS = '';
    this.upDowDirecCSS = '';
    this.upDowBillDocCSS = '';
    this.upDowURLCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';

    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "stateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "directoryDocName") {
        this.upDowDirecCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "billInsertDocName") {
        this.upDowBillDocCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "url") {
        this.upDowURLCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "createdDate") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "modifiedDate") {
        this.upDowModifiedDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "stateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "directoryDocName") {
        this.upDowDirecCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "billInsertDocName") {
        this.upDowBillDocCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "url") {
        this.upDowURLCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "createdDate") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "modifiedDate") {
        this.upDowModifiedDateCSS = 'fa fa-arrow-up';
      }
    }
    this.GetTRSNotice();
  }

  addTRSNotice() {
    this.router.navigate(['admin/inteserra360/trs-customer-notice/manage']);
  }

  editTRSNotice(trsId) {
    this.router.navigate(['admin/inteserra360/trs-customer-notice/manage/' + trsId])
  }

  setDeleteData(ids) {
    this.trsId = ids;
  }

  deleteRecord(trsId) {
    this.trsNoticeService.DeleteTRSNotice(this.tokenKey, trsId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.showMessage("TRS customer notice Deleted successfully", 'Success', 'success')
        }
        this.GetTRSNotice();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  onFilterSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetTRSNotice();
  }

  onFilterDeSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
      if (this.listOfStateIds.length > 0) {
        this.stateId = this.listOfStateIds.join(',')
      }
      this.resetPageNoandSize();
      this.GetTRSNotice();
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
