import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MarketEntryDataModel } from 'src/Models/PriceCalculationModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';

@Component({
  selector: 'app-market-entry',
  templateUrl: './market-entry.component.html',
  styleUrls: ['./market-entry.component.css']
})
export class MarketEntryComponent implements OnInit {
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  
  listofMarketEntryData: MarketEntryDataModel[];

  dropdownMarketEntryFilter = [];
  dropdownSettingsMarketEntryFilter = {};
  selectedItemsMarketEntry = [];

  isTypeSelected:boolean=false;
  marketEntryProvider:number=0;
  MarketEntryTypeId:number=0;
  SelectedTool: string;

  constructor(private PricingQuotesMaster: PricingQuotesService,private toastr: ToastrService,private router: Router) { 
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.dropdownSettingsMarketEntryFilter = {
      singleSelection: true,
      text: "Please Select Market Entry Type",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  ngOnInit() {

    this.BindDropdown();
  }

  BindDropdown()
  {
    this.dropdownMarketEntryFilter = [];
    this.dropdownMarketEntryFilter.push({ "id": 1, "itemName": "Market Entry - CLEC" })
    this.dropdownMarketEntryFilter.push({ "id": 2, "itemName": "Market Entry - IXC" })
    this.dropdownMarketEntryFilter.push({ "id": 3, "itemName": "Market Entry - VOIP" })
    this.dropdownMarketEntryFilter.push({ "id": 4, "itemName": "Market Entry - Wireless" })
  }

  onDeSelectMarketEntry($event) {
    this.selectedItemsMarketEntry = [];
    this.isTypeSelected=false;
    this.listofMarketEntryData=[];
  }

  onDeSelectMarketEntryAll($event) {
    this.selectedItemsMarketEntry = [];
    this.isTypeSelected=false;
    this.listofMarketEntryData=[];
  }

  onSelectMarketEntry($event)
  {
    this.GetMarketEntryByTypeId($event.id);
    this.MarketEntryTypeId=$event.id;
  }

  GetMarketEntryByTypeId(MarketEntryTypeId: number) {

    this.blockDocument();
    this.listofMarketEntryData=[];
    this.PricingQuotesMaster.GetMarketEntryByTypeId(this.tokenKey, MarketEntryTypeId).subscribe(
      result => {
        this.unblockDocument();
        this.marketEntryProvider=MarketEntryTypeId;
        if (result['status'] == 'Success') {
          this.listofMarketEntryData = result['compliance'];

          if (this.listofMarketEntryData.length > 0) {
            this.isTypeSelected=true;
          }
          else {
            this.isTypeSelected=false;
          }
        }

      });
  }

  save(){

    let value = {
      listofMarketEntryData: this.listofMarketEntryData,
      MarketEntryTypeId: this.MarketEntryTypeId
    }
    this.blockDocument();
    this.PricingQuotesMaster.MarketEntryDefaultDataAddUpdate(this.tokenKey, value).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.showMessage('Market entry rates updated successfully','Success', 'success');
          this.router.navigate(['/admin/' + this.SelectedTool + '/dashboard']);
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  cancelMarketEntry(){
    this.router.navigate(['/admin/' + this.SelectedTool + '/dashboard']);
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

}
