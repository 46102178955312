import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../Services/app.config';
import { Router } from '@angular/router';
import { AdminUserType } from '../../../Models/Common';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {
  appModel: any;
  dt: string = '';
  SelectedTool: string;

  firstLastName: string = '';
  adminCompanyName: string = '';
  isUserSystemAdmin: boolean = false;

  constructor(private router: Router) {
    if (sessionStorage.getItem('AdminUserId') != '' && sessionStorage.getItem('AdminUserId') != undefined && sessionStorage.getItem('AdminUserId') != null) {
      this.appModel = AppConfig.settings.ConnectionStrings;
    }
    else
      this.router.navigate(['admin/login']);

    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.firstLastName = sessionStorage.getItem('AdminFirstLastName');
    this.adminCompanyName = sessionStorage.getItem('AdminCompanyName');

    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.isUserSystemAdmin = true;
  }

  ngOnInit() {
    this.dt = Date();
  }

  logout(): void {
    this.clearSession();
    let UserId = sessionStorage.getItem('AdminUserId');
    let LoginSession = sessionStorage.getItem('LoginSession');
    sessionStorage.removeItem('AdminUserId');
    this.setOprationSession('logout');
    sessionStorage.removeItem('tokenValue');
    sessionStorage.removeItem('appStartTime');
    this.router.navigate(['admin/login']);
  }

  setOprationSession(logout: string) {
    sessionStorage.setItem('LoginStatus', logout);
  }

  SetTool(ToolType: string) {
    var AccessibleTools = sessionStorage.getItem('UserAccessibleTools');
    if (AccessibleTools.toLowerCase().indexOf(ToolType.toLowerCase()) !== -1) {
      this.SelectedTool = ToolType;
      sessionStorage.setItem('UserLoginTool', ToolType);
      if (ToolType == 'actionitem')
        this.router.navigate(['admin/trac-it/dashboard']);
      else if (ToolType == 'view 47')
        this.router.navigate(['admin/view47/dashboard']);
      else
        this.router.navigate(['admin/' + this.SelectedTool + '/dashboard']);
    }
    else {
      sessionStorage.setItem('AccessDeniedTool', ToolType);
      let redirectToTool = ToolType == 'actionitem' ? 'trac-it' : ToolType;
      if (ToolType == 'view 47') {
        let redirectToTool = ToolType == 'view 47' ? 'view47' : ToolType;
        this.router.navigate(['admin/' + redirectToTool + '/access-denied']);
      }
      else {
      this.router.navigate(['admin/' + redirectToTool + '/access-denied']);
      }
    }
  }

  clearSession() {
    sessionStorage.removeItem('saveFilterPageNo');
    sessionStorage.removeItem('saveFilterOrderBy');
    sessionStorage.removeItem('saveFilterWhereCondition');
    sessionStorage.removeItem('saveFilterStateID');
    sessionStorage.removeItem('saveFilterCarrierCategories');

    sessionStorage.removeItem('saveFilterUpDownDeadlineDateCSS');
    sessionStorage.removeItem('saveFilterUpDownSubjectCSS');
    sessionStorage.removeItem('saveFilterUpDownTypeNameCSS');
    sessionStorage.removeItem('saveFilterUpDownCompanyNameCSS');
    sessionStorage.removeItem('saveFilterUpDownStateNameCSS');
    sessionStorage.removeItem('saveFilterUpDownCarrierNameCSS');
    sessionStorage.removeItem('saveFilterUpDownActionChargesCSS');
    sessionStorage.removeItem('saveFilterUpDownIsActionCSS');
    sessionStorage.removeItem('saveFilterUpDownIsInformationOnlyCSS');
    sessionStorage.removeItem('saveFilterIsAscending');
    sessionStorage.removeItem('savePreviousUrl');
    sessionStorage.removeItem('saveFilterOprator');
    sessionStorage.removeItem('saveFilterFromDate');
    sessionStorage.removeItem('saveFilterToDate');
    sessionStorage.removeItem('saveFilterListOfType');
    sessionStorage.removeItem('saveFilterIsActionRequired');
  }

}

