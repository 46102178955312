import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { InteserraResourcesModel } from 'src/Models/InteserraResourcesModel';
import { InteserraResourcesService } from 'src/Services/inteserra-resources.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-manage-inteserra-resources',
  templateUrl: './manage-inteserra-resources.component.html',
  styleUrls: ['./manage-inteserra-resources.component.css']
})
export class ManageInteserraResourcesComponent implements OnInit {

  InteserraResourceModel = new InteserraResourcesModel();
  loginuserID: number = 0;
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;

  resourceId: number = 0;
  isMultipleClick: boolean = false;
  file: any;
  @ViewChild('fileUpload', { static: true }) fileUpload;
  isShowprogressSpinner = false;
  blockedDocument = false;
  @ViewChild('FreeModelButton', { static: true }) FreeModelButton: ElementRef;
  popupmsg: string = '';
  issubmitted: boolean = false;
  isEmbed: boolean = true;

  dropdownOptionSettings = {}
  dropdownOptionItems = [];
  listOfOptionSelected = [];
  isSelectExternalURL: boolean = false;
  isSelectFileUpload: boolean = false;
  isErrorOnUploadDoc: boolean = false;
  selectedFiles: File;
  selectedOptionItems = [];

  constructor(private activedRoute: ActivatedRoute, private _fb: FormBuilder, private router: Router, private checkAuth: CheckAuth, private titleService: Title,
    private inteserraResourcesService: InteserraResourcesService,
    private toastr: ToastrService) { 
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Inteserra Resources');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');

    this.dropdownOptionSettings = {
      singleSelection: true,
      text: 'Select',
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 3,
      noDataLabel: 'No record available',
      showCheckbox: false
    };
    }

    tokenExpireCalculation(startDate: string, tokenExpiry: string) {
      this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
    }

  ngOnInit() {
    this.FillselectoptionDropdown();
    this.InteserraResourceModel.isEmbed = this.isEmbed;
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {

          this.resourceId = params['id'];
          this.GetInteserraResourcesData();
        }
      }
      else {
        this.InteserraResourceModel.isActive = true;
      }
    });
  }

  
  GetInteserraResourcesData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.inteserraResourcesService.i360InteserraResourcesFindbyResourcesId(this.tokenKey, this.resourceId).subscribe(
      data => {
        if (data != null) {
          this.InteserraResourceModel = data["carrierData"];

          if (this.InteserraResourceModel.linktype != 0) {
            this.selectedOptionItems = this.dropdownOptionItems.filter(x => x.id == this.InteserraResourceModel.linktype);
            this.listOfOptionSelected.push(this.InteserraResourceModel.linktype);
            if (this.InteserraResourceModel.linktype == 1) {
              document.getElementById('inputfile').style.display = 'none'
              document.getElementById('inputUrl').style.display = 'block'
            }
            else {
              document.getElementById('inputfile').style.display = 'block'
              document.getElementById('inputUrl').style.display = 'none'
            }
          }
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  saveInteserraResources(InteserraResourcesForm) {
    this.issubmitted = true;

    if (this.listOfOptionSelected.length == 0) {
      return;
    }

    if (this.listOfOptionSelected[0] == 2) {
      if (this.fileUpload.nativeElement.files.length == 0 && !this.InteserraResourceModel.displayDocName) {
        this.showMessage('Please select file', 'File Required', 'error');
        return;
      }
    }else{
    if (InteserraResourcesForm.form.invalid) {
      return false;
    }
  }

    event.preventDefault();

    if (this.InteserraResourceModel.title == '' || typeof (this.InteserraResourceModel.title) == 'undefined' || this.InteserraResourceModel.title == null) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }

    if (this.InteserraResourceModel.sortOrder == '' || typeof (this.InteserraResourceModel.sortOrder) == 'undefined' || this.InteserraResourceModel.sortOrder == null) {
      return;
    }

    this.InteserraResourceModel.createdBy = this.loginuserID;
    this.InteserraResourceModel.modifiedBy = this.loginuserID;
    let formData = new FormData();
    formData.append('resourcesId', this.InteserraResourceModel.resourcesId);
    formData.append('title', this.InteserraResourceModel.title);
    if(this.InteserraResourceModel.externalURL!=null && this.InteserraResourceModel.externalURL!='null')
    {
      formData.append('externalURL', this.InteserraResourceModel.externalURL);
    }
    formData.append('displayDocName', this.InteserraResourceModel.displayDocName);
    formData.append('docPath', this.InteserraResourceModel.docPath);
    formData.append('fileUpload', this.fileUpload.nativeElement.files[0]);
    formData.append('createdBy', this.InteserraResourceModel.createdBy);
    formData.append('modifiedBy', this.InteserraResourceModel.modifiedBy);
    formData.append('isActive', this.InteserraResourceModel.isActive);
    formData.append('isAllowallAccess', this.InteserraResourceModel.isAllowallAccess);
    formData.append('isEmbed', this.InteserraResourceModel.isEmbed);
    formData.append('linktype', this.listOfOptionSelected[0]);
    formData.append('sortOrder', this.InteserraResourceModel.sortOrder);

    if (this.isMultipleClick) {
      return;
    }
    this.isMultipleClick = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.inteserraResourcesService.i360InteserraResourcesAddUpdate(this.tokenKey, formData).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        if (data['resourceId'] != 0) {
          if (data['resourceId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.resourceId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.resourceId = 0;
            this.cancelInteserraResources()
          }
        }
      },
      error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        this.issubmitted = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelInteserraResources() {
    this.router.navigate(['admin/inteserra360/InteserraResources']);
  }

  IfFreeProduct(event) {
    if (this.resourceId > 0) {
      this.FreeModelButton.nativeElement.click();
      this.popupmsg = event ? 'Are you sure you want to allow access to this item to all companies and subscribers having access to Inteserra Resources?' : 'Are you sure you want to remove access to this item from all companies and subscribers having access to this Inteserra Resources?';
      console.log(this.resourceId);
      console.log(event);
    }
  }

  Cancelpopup() {
    this.GetInteserraResourcesData();
  }


  FillselectoptionDropdown() {
    this.dropdownOptionItems.push({ "id": '1', "itemName": "External URL" });
    this.dropdownOptionItems.push({ "id": '2', "itemName": "File Upload" });
  }

  onSelectOption($event) {
    this.listOfOptionSelected = [];
    this.listOfOptionSelected.push($event.id);
    if ($event.id == 1) {
      document.getElementById('inputfile').style.display = 'none'
      document.getElementById('inputUrl').style.display = 'block'
    } else if ($event.id == 2) {
      document.getElementById('inputfile').style.display = 'block'
      document.getElementById('inputUrl').style.display = 'none'
    } else {
      document.getElementById('inputfile').style.display = 'none'
      document.getElementById('inputUrl').style.display = 'none'
    }
  }

  onDeselectOption() {
    document.getElementById('inputfile').style.display = 'none'
    document.getElementById('inputUrl').style.display = 'none'
    this.listOfOptionSelected = [];
  }

  onDeselectAllOption() {
    document.getElementById('inputfile').style.display = 'none'
    document.getElementById('inputUrl').style.display = 'none'
    this.listOfOptionSelected = [];
  }

  fileEvent(event): any {
    var file = event.target.files[0];
    let size = Math.round(file.size / 1024);
    if (size <= 21000) {
      var allowedExtensions = ["doc", "DOC", "docx", "DOCX", "pdf", "PDF"];
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF, DOC, DOCX.', 'Error', 'error');
        this.isErrorOnUploadDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isErrorOnUploadDoc = false;
        this.selectedFiles = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnUploadDoc = true;
      window.scrollTo(0, 0);
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }


}
