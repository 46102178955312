export class CRRASubCategorysModel {
    subCategoryId: number;
    subCategoryName: string;
    order: number;   
    categoryId: number;
    totalRecordCount: number;
}

export class FilterSubCategotyModel {
    pageNumber?: number;
    pageSize?: number;
    filterText?:string;
    orderBy?: string;    
    categoryId?: string | number;    
  }