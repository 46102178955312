import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { FilterModel } from 'src/Models/filterModel';
import { IPRScheduleLogHistoryModel } from 'src/Models/IPRScheduleLogHistoryModel';
import { IPRScheduleLogModel } from 'src/Models/IPRScheduleLogModel';
import { IprUpdatesService } from 'src/Services/iprupdatesService';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-iprlog',
  templateUrl: './iprlog.component.html',
  styleUrls: ['./iprlog.component.css']
})
export class IPRLogComponent implements OnInit {
  filterModel = new FilterModel();
  logFilterModel = new FilterModel();
  upDownCreatedDate_onCSS: string = '';
  upDownRegisterCountCSS: string = '';
  upDownModifiedCountCSS: string = '';
  currentPage: number = 1;
  logcurrentPage: number = 1;
  totalRecordCount: number = -1;
  logtotalRecordCount: number = -1;
  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false
  isloading: boolean;
  isHistoryloading: boolean;
  logs = new Array<IPRScheduleLogModel>();
  logHistory = new Array<IPRScheduleLogHistoryModel>();
  status: number;
  logId: number;
  constructor(private iprUpdatesService: IprUpdatesService, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('IPR Updates');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'CreatedDate DESC';
    this.upDownCreatedDate_onCSS = 'fa fa-arrow-up';
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.movetotop();
    this.GetRMDUpdatesFilter();
  }

  GetRMDUpdatesFilter() {
    this.logs = [];
    this.isloading = true;
    this.iprUpdatesService.GetScheduleLogFilterByPage(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.logs = data['updates'];
          if (this.logs != null) {
            if (this.logs.length > 0) {
              this.totalRecordCount = this.logs[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }
  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetRMDUpdatesFilter();
  }

  cleanCssClass() {
    this.upDownRegisterCountCSS = '';
    this.upDownModifiedCountCSS = '';
    this.upDownCreatedDate_onCSS = '';
  }

  sort(sortBy: any) {
    this.cleanCssClass();
    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case "createdDate": this.upDownCreatedDate_onCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "modifiedCount": this.upDownModifiedCountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      case "registerCount": this.upDownRegisterCountCSS = this.isAscending ? 'fa fa-arrow-down' : 'fa fa-arrow-up'; break;
      default: break;
    }
    this.GetRMDUpdatesFilter();
  }

  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }

  showLogHistory(logId, status) {
    this.logHistory = [];
    this.logFilterModel.status = status;
    this.logFilterModel.logId = logId;
    this.logFilterModel.pageNumber = '1';
    this.logFilterModel.pageSize = '10';
    this.logFilterModel.orderBy = 'CarrierId';
    this.GetScheduleLogHistory();
  }

  GetScheduleLogHistory() {
    this.isHistoryloading = true;
    this.iprUpdatesService.GetScheduleLogHistory(this.tokenKey, this.logFilterModel).subscribe(
      data => {
        this.isHistoryloading = false;
        if (data != null) {
          this.logHistory = data['updates'];
          if (this.logHistory != null) {
            if (this.logHistory.length > 0) {
              this.logtotalRecordCount = this.logHistory[0].totalRecordCount;
            }
            else {
              this.logtotalRecordCount = 0;
            }
          }
          else {
            this.logtotalRecordCount = 0;
          }

        }
      },
      error => {
        this.isHistoryloading = false;
        console.log(error);
      }
    );
  }

  logpageChanged($event: any) {
    this.logcurrentPage = $event.page;
    this.logFilterModel.pageNumber = parseInt($event.page).toString();
    this.GetScheduleLogHistory();
  }

}
