import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SeminarService {

  AppUrl: string = "";
  constructor(private _http: Http, private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
      this.setAPIUrl();
  }

  setAPIUrl() {
      if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
          this.AppUrl = sessionStorage.getItem("BaseURL") || '';
      }
  }

i360SeminarsAddUpdate(TokenKey: string, SeminarsModel: any): Observable<any> {
  this.setAPIUrl();
  let data = 'api/Seminars/i360SeminarsAddUpdate';
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.post<any>(this.AppUrl + data, SeminarsModel, httpOptions)
    .pipe(catchError(this.handleError));
}

i360SeminarsGet(TokenKey: string, filterModel: any): Observable<any> {
  this.setAPIUrl();
  let data = 'api/Seminars/i360SeminarsGet';
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
    .pipe(catchError(this.handleError));
}

i360SeminarsFindbySeminarId(TokenKey: string, SeminarsId: any): Observable<any> {
  this.setAPIUrl();
  let data = 'api/Seminars/i360SeminarsFindbySeminarId';
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.post<any>(this.AppUrl + data, SeminarsId, httpOptions)
    .pipe(catchError(this.handleError));
}

i360SeminarsDelete(TokenKey: string, SeminarsId: any): Observable<any> {
  this.setAPIUrl();
  let data = 'api/Seminars/i360SeminarsDelete';
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.post<any>(this.AppUrl + data, SeminarsId, httpOptions)
    .pipe(catchError(this.handleError));
}

i360SeminarsGetFront(TokenKey: string): Observable<any> {
  this.setAPIUrl();
  let data = 'api/Seminars/i360SeminarsGetFront';
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
    .pipe(catchError(this.handleError));
}
private handleError(error: Response | any) {
  console.error(error.message || error);
  return observableThrowError(error.status)
}

i360SeminarsSectionListWithIds(TokenKey: string): Observable<any> {
  this.setAPIUrl();
  let data = 'api/Seminars/i360SeminarsSectionListWithIds';

  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.get<any>(this.AppUrl + data, httpOptions).pipe(catchError(this.handleError));
}

i360SeminarReportUpdate(TokenKey: string, currentIndexid: string, previousIndexid: string): Observable<any[]> {
  this.setAPIUrl();

  let data = 'api/Seminars/i360SeminarDetailUpdate/' + currentIndexid + '/' + previousIndexid;
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
}
}


