import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/Services/app.config';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrfaFundTypeMasterService } from 'src/Services/trfa-fund-type--master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-fund-type-add-update',
  templateUrl: './fund-type-add-update.component.html',
  styleUrls: ['./fund-type-add-update.component.css']
})
export class FundTypeAddUpdateComponent implements OnInit {

  fundTypeMasterForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit:boolean = false;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private trfaFundTypeMaster: TrfaFundTypeMasterService, private toastr: ToastrService) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    // this.titleService.setTitle('FundType Configurations');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.isSubmit = false;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.fundTypeMasterForm = this._fb.group({
      fundTypeId: 0,
      fundTypeName: ['', Validators.required],
      sortOrder: ['', Validators.required],
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetFundTypeById(params['id']);
        }
      }
    });
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  cancelFundTypeMaster() {
    let navigation = '/admin/' + this.SelectedTool + '/fundType';
    this.router.navigate([navigation]);
  }

  GetFundTypeById(fundTypeId: number) {
    this.trfaFundTypeMaster.GetFundTypeById(this.tokenKey, parseInt(fundTypeId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.fundTypeMasterForm.patchValue({
            fundTypeId: result['fundType'].fundTypeID,
            fundTypeName: result['fundType'].fundTypeName,
            sortOrder: result['fundType'].sortOrder,
          });
        }
      });
  }

  save() {
    this.isSubmit = true;
    let data = this.fundTypeMasterForm.value;
    if (!this.fundTypeMasterForm.valid||!data.fundTypeName.trim())
      return
    let value={
      fundTypeId:data.fundTypeId,
      fundTypeName:data.fundTypeName.trim(),
      sortOrder: data.sortOrder
    }
    this.trfaFundTypeMaster.FundTypeAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if(result['message'] !=""){
            this.showMessage(result['message'], 'Warning', 'warning')
          }else{

            if (data.fundTypeId > 0)
              this.showMessage('FundType updated successfully', 'Success', 'success');
            else
              this.showMessage('FundType saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/'+this.SelectedTool+'/fundType']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
}
