export class ActionItemTypeModel{
  actionItemTypeId: number ;
  typeName: string;
  colorCode: string;
  isDefaultChecked: boolean;
  isHoliday: boolean;
  isPersonal: boolean;
  createdDate: string;
  createdBy: number;
  modifiedDate: string;
  modifiedBy: number;
  totalRecordCount: number;
  isEdit: boolean = false;
  createdUser: string;
  modifiedUser: string;
  isUserCanDelete?: boolean;
  sortOrder:number;
}

