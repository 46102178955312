import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { I360Service } from 'src/Services/i360Service';
import { StateCommissionModel } from 'src/Models/StateCommissionModel';


@Component({
  selector: 'app-state-commission',
  templateUrl: './state-commission.component.html',
  styleUrls: ['./state-commission.component.css']
})
export class StateCommissionComponent implements OnInit {
  filterModel = new FilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfStateCommission: StateCommissionModel[];

  isUserSystemAdmin: boolean = true;
  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false
  upDowTitleCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowURLCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';

  productId: number = 1;
  deletedId: number = 0;
  isloading: boolean;
  openMenuIndex: number = -1;

  constructor(private i360service: I360Service, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('State Commissions');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'ISNULL(ModifiedTime,CreatedTime) DESC';
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.GetStateCommissionsData();
  }

  GetStateCommissionsData() {
    this.isloading = true;
    this.listOfStateCommission = [];
    this.i360service.i360StateCommissionWebSitesGet(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfStateCommission = data['stateCommissionData'];
          if (this.listOfStateCommission != null) {
            if (this.listOfStateCommission.length > 0) {
              this.totalRecordCount = this.listOfStateCommission[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filterModel.whereCondition = $event.target.value;
    }
    else {
      this.filterModel.whereCondition = '';
    }
    this.GetStateCommissionsData();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetStateCommissionsData();
  }

  sort(sortBy: any) {
    this.upDowTitleCSS = '';
    this.upDowStateNameCSS = '';
    this.upDowURLCSS = '';
    this.upDowIsActiveCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowModifiedDateCSS = '';

    if (!this.isAscending) {
      this.isAscending = true;
      this.filterModel.orderBy = sortBy + ' DESC';

      if (sortBy === "title") {
        this.upDowTitleCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "stateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "url") {
        this.upDowURLCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "isActive") {
        this.upDowIsActiveCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "createdTime") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "modifiedTime") {
        this.upDowModifiedDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.filterModel.orderBy = sortBy;

      if (sortBy === "title") {
        this.upDowTitleCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "stateName") {
        this.upDowStateNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "url") {
        this.upDowURLCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "isActive") {
        this.upDowIsActiveCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "createdTime") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "modifiedTime") {
        this.upDowModifiedDateCSS = 'fa fa-arrow-up';
      }
    }
    this.GetStateCommissionsData();
  }

  addStateCommission() {
    this.router.navigate(['admin/inteserra360/statecommission/manage']);
  }

  editData(ids) {
    this.router.navigate(['admin/inteserra360/statecommission/manage/' + ids])
  }

  setDeleteData(ids) {
    this.deletedId = ids;
  }

  deleteRecord(deletedId) {
    this.i360service.i360StateCommissionWebSitesDelete(this.tokenKey, deletedId).subscribe(
      data => {
        this.deletedId = 0;
        if (data != null) {
          if (data['deletedId'] > 0) {
            this.GetStateCommissionsData();
            this.toastr.success('Record deleted successfully.', 'Delete')
          }
        }
      },
      error => {

      }
    );
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
