import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { escapeSelector } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TRFAUpdateGetModel } from 'src/Models/TRFAUpdateModel';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { TrfaUpdateMasterService } from 'src/Services/trfa-update--master.service';
import { CheckAuth } from 'src/Services/utility.service';
@Component({
  selector: 'app-update-add-update',
  templateUrl: './update-add-update.component.html',
  styleUrls: ['./update-add-update.component.css']
})
export class UpdateAddUpdateComponent implements OnInit {

  updateMasterForm: FormGroup;
  userID: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  stateList: StateMasterModel[] = [];
  selectedState = [];
  fundList: TRFAUpdateGetModel[] = [];
  selectedFund = [];
  isEdit: boolean = false;
  getStateId:string='';

  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};
  dropdownSettingsDateFundFilter = {};

  dropdownDateFundFilter = [];
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  module="TRFA";
  //State List
dropdownListState = [];
selectedItemsState = [];
dropdownSettingsState = {};
listOfStateIds = [];
stateId: string = ' ';
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private stateService: StateMasterService,
    private datePipe: DatePipe,
    private toastr: ToastrService, private updateService: TrfaUpdateMasterService,private fundService:TrfaFundMasterService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          //this.GetUpdateById(params['id']);
        }
      }
    });

    this.getFund();
    this.getState();
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.getStateId='';
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsDateFundFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: false
    };
    this.updateMasterForm = this._fb.group({
      updateID: 0,
      note: [''],
      updateDate: ['',Validators.required],
      fundID: [''],
    })

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetUpdateById(params['id']);
        }
      }
    });
  }

  GetUpdateById(updateID) {
    this.updateService.GetUpdateById(this.tokenKey, parseInt(updateID.toString())).subscribe(
      result => {
        
        if (result['status'] == 'Success') {
          this.updateMasterForm.patchValue({
            updateID: result['update'].updateID,
            rateId: result['update'].rateId,
            note: result['update'].note,
            updateDate: result['update'].updateDate,
            fundID: result['update'].fundID
          });

          if (this.stateList.length > 0) {
            let data = this.stateList.filter(x => x.stateId == result['update'].stateID)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
          else
          {
            this.getStateId=result['update'].stateID;
          }
                    
          if (this.fundList.length > 0) {
            let data = this.fundList.filter(x => x.fundID == result['update'].fundID)[0];
            // this.selectedFund.push({ "id": data.fundID, "itemName": data.fundName })
            if(data.fundID!=null)
            {
            this.selectedFund.push(data)
            }
          }
          else
            setTimeout(() => {
              let data = this.fundList.filter(x => x.fundID == result['update'].fundID)[0];
              // this.selectedFund.push({ "id": data.fundID, "itemName": data.fundName })
              if(data.fundID!=null)
              {
              this.selectedFund.push(data)
              }
            }, 1500);
            
        }
      });
  }
  getFund() {
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',');
      this.selectedFund=[];
    }
    this.fundService.GetFund(this.tokenKey,this.stateId,true,true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.fundList = result['fund'];
          this.dropdownDateFundFilter=result['fund'];
          // this.fundList.forEach(x => {
          //   this.dropdownDateFundFilter.push({ "id": x.fundID, "itemName": x.fundName })
          // });
          
        }
      },
      error => { } 
    );
  }
  cancelUpdate() {
    let navigation = '/admin/' + this.SelectedTool + '/update';
    this.router.navigate([navigation]);
  }

  save() {
    this.isSubmit = true;
    let data = this.updateMasterForm.value;
    // || !data.note.trim()
    if (!this.updateMasterForm.valid ||  this.selectedFund.length == 0 )
      return
    data["createdBy"] = this.userID;
    let value={
      updateID:data.updateID,
      fundID:data.fundID,
      updateDate:this.datePipe.transform(data.updateDate, 'MM-dd-yyyy hh:mm:ss'),
      note:data.note.trim(),
    }
    this.updateService.UpdateAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.updateID > 0)
              this.showMessage('Update updated successfully', 'Success', 'success');
            else
              this.showMessage('Update saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/update']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

 
  onDeSelectDateFilterAll($event) {
    this.selectedState = [];
    this.dropdownDateFundFilter = [];
  }

  onDeSelectDateFilterFundAll($event) {
    this.selectedFund = [];
  }

  onChangeFund(event) {
    this.updateMasterForm.patchValue({
      fundID: event.id
    });
  }
  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey,this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
          if(this.getStateId!='')
          {
          let data = this.stateList.filter(x => x.stateId == this.getStateId)[0];
          this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
        }
      },
      error => { }
    );
  }
  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.getFund();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.getFund();
  }
}
