import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { CRRASubCategorysModel } from 'src/Models/CRRASubCategoryModel';
import { CrraSubCategoryMasterService } from 'src/Services/crra-sub-category-master.service';
import { CrraCategoryMasterService } from 'src/Services/crra-category-master.service';

@Component({
  selector: 'app-crra-sub-category',
  templateUrl: './crra-sub-category.component.html',
  styleUrls: ['./crra-sub-category.component.css']
})
export class CrraSubCategoryComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofCategory: CRRASubCategorysModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  subcategoryName: string;


  upDowCategoryNameCSS: string = '';
  upDowCategorySortCSS: string = '';
  upDowActiveCSS: string = '';
  upDowSubCategoryNameCSS :string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  isState911: boolean = false;
  subCategoryId: number = 0;

  categoryListMain: any[] = [];
  dropdownDateCategoryFilterMain: any[] = [];
  dropdownSettingsDateCategoryFilterMain = {};
  selectedItemsCategoryMain = [];
  listOfCategoryIDsMain = [];
  categoryId: string = '';
  openMenuIndex: number = -1;

  constructor(private router: Router, private crraSubCategoryMaster: CrraSubCategoryMasterService, private crraCategoryMaster:CrraCategoryMasterService,
     private checkAuth: CheckAuth , private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofCategory = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'SortOrder';
    this.subcategoryName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Sub Categories');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.dropdownSettingsDateCategoryFilterMain = {
      singleSelection: false,
      text: "Filter by Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.getCategory();
    this.GetSubCategory();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetSubCategory();
  }

  GetSubCategory() {
    this.categoryId = ' ';
    if (this.listOfCategoryIDsMain.length > 0) {
      this.categoryId = this.listOfCategoryIDsMain.join(',')
    }
    if (this.subcategoryName == "null") this.subcategoryName = " ";
    this.crraSubCategoryMaster.GetAllSubCategory(this.tokenKey, this.PageNumber, this.PageSize, this.subcategoryName,this.categoryId, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofCategory = data['crrasubcategory'];
        if (this.listofCategory != null) {
          if (this.listofCategory.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofCategory[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );

  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.subcategoryName = $event.target.value;
    else
      this.subcategoryName = 'null';

    this.GetSubCategory();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "SubcategoryName") {
        this.upDowSubCategoryNameCSS = 'fa fa-arrow-down';
        this.upDowCategoryNameCSS = '';
        this.upDowCategorySortCSS = '';
      }
      else if (sortBy === "categoryName") {
        this.upDowSubCategoryNameCSS = '';
        this.upDowCategoryNameCSS = 'fa fa-arrow-down';
        this.upDowCategorySortCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowSubCategoryNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowCategorySortCSS = 'fa fa-arrow-down';
      }
      
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "SubcategoryName") {
        this.upDowSubCategoryNameCSS = 'fa fa-arrow-up';
        this.upDowCategoryNameCSS = '';
        this.upDowCategorySortCSS = '';
      }
      else if (sortBy === "categoryName") {
        this.upDowSubCategoryNameCSS = '';
        this.upDowCategoryNameCSS = 'fa fa-arrow-up';
        this.upDowCategorySortCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowSubCategoryNameCSS = '';
        this.upDowCategoryNameCSS = '';
        this.upDowCategorySortCSS = 'fa fa-arrow-up';
      }
      
    }
    this.GetSubCategory();
  }

  addCategory() {
    let navigation = '/admin/' + this.SelectedTool + '/subCategory/manage';
    this.router.navigate([navigation]);
  }

  updateCategory(subCategoryId) {
    let navigation = '/admin/' + this.SelectedTool + '/subCategory/manage/' + subCategoryId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.subCategoryId = id;
  }

  deleteCategory(subCategoryId) {
    this.isloading = true;
    this.crraSubCategoryMaster.DeleteSubCategory(this.tokenKey, subCategoryId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
          this.showMessage("Sub Category Deleted successfully", 'Success', 'success')
        }
        this.GetSubCategory();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  getCategory() {
    this.crraCategoryMaster.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {

          this.categoryListMain = result['crracategory'];
          this.dropdownDateCategoryFilterMain = [];
          this.categoryListMain.forEach(x => {
            this.dropdownDateCategoryFilterMain.push({ "id": x.categoryId, "itemName": x.categoryName })
          });

          if (this.listOfCategoryIDsMain != null && this.listOfCategoryIDsMain != undefined) {
            for (let iCounter = 0; iCounter < this.listOfCategoryIDsMain.length; iCounter++) {
              if (this.listOfCategoryIDsMain[iCounter] != '') {
                let obj = result["crracategory"].filter(m => m.categoryId == this.listOfCategoryIDsMain[iCounter]);
                if (obj != null) {
                  this.selectedItemsCategoryMain.push({ "id": obj[0].categoryId, "itemName": obj[0].categoryName });
                }
              }
            }
          }

        }
      },
      error => { }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectCategoryMain($event) {
    this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event.id)
    this.listOfCategoryIDsMain.push($event.id);
    this.resetPageNoandSize();
    this.GetSubCategory();
  }

  onDeSelectCategoryMain($event) {
    if (this.listOfCategoryIDsMain != null) {
      if (this.listOfCategoryIDsMain.length > 0) {
        this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event.id)
      }
    }
    this.resetPageNoandSize();
    this.GetSubCategory();
  }

  onSelectAllCategoryMain($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetSubCategory();
  }

  onDeSelectAllCategoryMain($event) {

    this.listOfCategoryIDsMain = [];
    this.resetPageNoandSize();
    this.GetSubCategory();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  onFilterSelectAll($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDsMain.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetSubCategory();
  }

  onFilterDeSelectAll($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDsMain != null) {
        if (this.listOfCategoryIDsMain.length > 0) {
          this.listOfCategoryIDsMain = this.removeElemetninArray(this.listOfCategoryIDsMain, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetSubCategory();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
