import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TRFACategoryModel, TRFACategorysModel } from 'src/Models/TRFACategoryModel';
import { TrfaCategoryMasterService } from 'src/Services/trfa-category-master.service';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  stateCode: any;
  tokenKey: string;
  listofCategory: TRFACategorysModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  CategoryName: string;


  upDowCategoryNameCSS: string = '';
  upDowCategorySortCSS: string = '';
  upDowActiveCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  isState911: boolean = false;
  categoryId: number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private trfaCategoryMaster: TrfaCategoryMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofCategory = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'CategoryName';
    this.CategoryName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Categories');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetCategory();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetCategory();
  }

  GetCategory() {

    if (this.CategoryName == "null") this.CategoryName = " ";
    this.trfaCategoryMaster.GetAllCategory(this.tokenKey, this.PageNumber, this.PageSize, this.CategoryName, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofCategory = data['category'];
        if (this.listofCategory != null) {
          if (this.listofCategory.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofCategory[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );

  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.CategoryName = $event.target.value;
    else
      this.CategoryName = 'null';

    this.GetCategory();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "CategoryName") {
        this.upDowCategoryNameCSS = 'fa fa-arrow-down';
        this.upDowCategorySortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "CategorySort") {
        this.upDowCategoryNameCSS = '';
        this.upDowCategorySortCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowCategoryNameCSS = '';
        this.upDowCategorySortCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "CategoryName") {
        this.upDowCategoryNameCSS = 'fa fa-arrow-up';
        this.upDowCategorySortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "CategorySort") {
        this.upDowCategoryNameCSS = '';
        this.upDowCategorySortCSS = 'fa fa-arrow-up';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowCategoryNameCSS = '';
        this.upDowCategorySortCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-up';
      }
    }
    this.GetCategory();
  }

  addCategory() {
    let navigation = '/admin/' + this.SelectedTool + '/category/manage';
    this.router.navigate([navigation]);
  }

  updateCategory(categoryId) {
    let navigation = '/admin/' + this.SelectedTool + '/category/manage/' + categoryId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.categoryId = id;
  }

  deleteCategory(categoryId) {
    this.isloading = true;
    this.trfaCategoryMaster.DeleteCategory(this.tokenKey, categoryId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
          this.showMessage("Category Deleted successfully", 'Success', 'success')
        }
        this.GetCategory();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
