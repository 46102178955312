import { Component, Inject, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CRRAContentGetModel, CRRAGetUpdateViewModel } from 'src/Models/CRRAContentModel';
import { CrraContentMasterService } from 'src/Services/crra-content-master.service';
import { CrraCarrierMasterService } from 'src/Services/crra-carrier-master.service';
import { CrraCategoryMasterService } from 'src/Services/crra-category-master.service';
import { CrraSubCategoryMasterService } from 'src/Services/crra-sub-category-master.service';

@Component({
  selector: 'app-crra-compare-view',
  templateUrl: './crra-compare-view.component.html',
  styleUrls: ['./crra-compare-view.component.css']
})
export class CrraCompareViewComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofContent: CRRAContentGetModel[]
  
  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;

  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];

  //Carrier

  dropdownListCarrier = [];
  selectedItemsCarrier = [];
  dropdownSettingsCarrier = {};
  listOfCarrierIDs = [];
  carrierList: any[] = [];
  carrierId: string = ' ';

  //Category
  categoryList: any[] = [];
  dropdownDateCategoryFilter = [];
  selectedItemsCategory = [];
  listOfCategoryIDs = [];
  dropdownSettingsDateCategoryFilter = {};
  categoryId: string = ' ';

  // Sub Category
  subCategoryList: any[] = [];
  dropdownDateSubCategoryFilter = [];
  selectedItemsSubCategory = [];
  listOfSubCategoryIDs = [];
  dropdownSettingsDateSubCategoryFilter = {};
  subCategoryId: string = ' ';

  stateLinkData: CRRAGetUpdateViewModel;
  isupdatesloading: boolean = false;
  baseUrl: string;
  module = "CRRA";

  constructor(private router: Router, private crraContentMaster: CrraContentMasterService, private crraCarrierMasterService: CrraCarrierMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private modalService: NgbModal, private crraCategoryMasterService: CrraCategoryMasterService,
    private toastr: ToastrService, private stateService: StateMasterService, private crraSubCategoryMasterService: CrraSubCategoryMasterService,
    @Inject('BASE_URL') baseUrl: string) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofContent = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Compare View');
    this.baseUrl = baseUrl;
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdictions",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsCarrier = {
      singleSelection: false,
      text: "Filter by Carrier",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Carrier(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateCategoryFilter = {
      singleSelection: false,
      text: "Filter by Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateSubCategoryFilter = {
      singleSelection: false,
      text: "Filter by Sub Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Sub Category(s) available',
      showCheckbox: true
    };

    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.getState();
    this.getCarrier();
    this.getCategory();
    this.GetContent();
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.GetContent();
  }


  getCarrier() {
    this.crraCarrierMasterService.GetCarrier(this.tokenKey).subscribe(
      data => {
        this.dropdownListCarrier = []
        this.carrierList = data['crracarrier'];
        if (this.carrierList.length > 0) {
          this.carrierList.forEach(x => {
            this.dropdownListCarrier.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCarrier($event) {
    this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
    this.listOfCarrierIDs.push($event.id);
    this.GetContent();
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIDs != null) {
      if (this.listOfCarrierIDs.length > 0) {
        this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
      }
    }
    if (this.listOfCarrierIDs.length > 0) {
      this.carrierId = this.listOfCarrierIDs.join(',')
    }
    this.GetContent();
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIDs = [];
    this.GetContent();
  }

  getCategory() {
    this.crraCategoryMasterService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryList = result['crracategory'];
          this.dropdownDateCategoryFilter = [];
          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilter.push({ "id": x.categoryId, "itemName": x.categoryName })
          });
        }
      },
      error => { }
    );
  }

  GetSubCategoryByCategoryId(categoryId) {

    this.crraSubCategoryMasterService.GetSubCategoryByCategoryId(this.tokenKey, categoryId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.subCategoryList = result['crrasubcategory'];
          this.dropdownDateSubCategoryFilter = [];
          if (this.subCategoryList.length > 0) {
            let b = [];
            this.selectedItemsSubCategory = [];
            this.subCategoryList.forEach(x => {
              let a = this.listOfSubCategoryIDs.filter(e => e == x.subCategoryId);
              if (a.length > 0) {
                b.push(x.subCategoryId);
                this.selectedItemsSubCategory.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
              }
              this.dropdownDateSubCategoryFilter.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
            });
            this.listOfSubCategoryIDs = b;
            if (this.listOfSubCategoryIDs.length > 0) {
              this.subCategoryId = this.listOfSubCategoryIDs.join(',')
              this.GetContent();
            }
          }
          else {
            this.selectedItemsSubCategory = [];
            this.listOfSubCategoryIDs = [];
          }
        }
      }
    );
  }

  onSelectCategory($event) {
    this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
    this.listOfCategoryIDs.push($event.id);
    this.categoryId = ' ';
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onDeSelectCategory($event) {
    this.categoryId = ' ';
    if (this.listOfCategoryIDs != null) {
      if (this.listOfCategoryIDs.length > 0) {
        this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
      }
    }
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    if (this.listOfCategoryIDs.length == 0) {
      this.dropdownDateSubCategoryFilter = [];
      this.selectedItemsSubCategory = [];
      this.listOfSubCategoryIDs = [];
    }
    else
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onSelectAllCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onDeSelectAllCategory($event) {
    this.categoryId = ' ';
    this.listOfCategoryIDs = [];
    this.listOfSubCategoryIDs = [];
    this.selectedItemsSubCategory = [];
    this.dropdownDateSubCategoryFilter = [];
    this.selectedItemsCategory = []
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

 
  onSelectSubCategory($event) {
    this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event.id)
    this.listOfSubCategoryIDs.push($event.id);
    this.GetContent();
  }

  onDeSelectSubCategory($event) {
    if (this.listOfSubCategoryIDs != null) {
      if (this.listOfSubCategoryIDs.length > 0) {
        this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event.id)
      }
    }
    this.GetContent();
  }

  onSelectAllSubCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onDeSelectAllSubCategory($event) {
    this.listOfSubCategoryIDs = [];
    this.GetContent();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetContent();
  }


  GetContent() {

    if (this.listOfStateIds.length == 0 && this.listOfCarrierIDs.length == 0 && this.listOfCategoryIDs.length == 0 && this.listOfSubCategoryIDs.length == 0) {
      this.listofContent=[];
      return;
    }

    this.isloading = true;

    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    
    this.carrierId = ' ';
    if (this.listOfCarrierIDs.length > 0) {
      this.carrierId = this.listOfCarrierIDs.join(',')
    }

    this.categoryId = ' ';
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }

    this.subCategoryId = ' ';
    if (this.listOfSubCategoryIDs.length > 0) {
      this.subCategoryId = this.listOfSubCategoryIDs.join(',')
    }
       
    this.crraContentMaster.GetAllCompareView(this.tokenKey, this.stateId, this.carrierId, this.categoryId,this.subCategoryId).subscribe(
      data => {
        this.isloading = true;
        this.listofContent = data['crracontent'];
        if (this.listofContent != null) {
          if (this.listofContent.length == 0) {
            this.isRecord = true;
            this.isloading = false;
          }
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
            this.isloading = false;
          }
        }
        else
          this.isRecord = true;
        this.isloading = false;
      },
      error => { this.stateCode = error }
    );
  }


  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  replaceCommaLines(data) {
    let str = '';
    let dataToArray = data.split(',').map(item => item.trim());

    dataToArray.forEach((element, index) => {

      if ((dataToArray.length - 1) == index) {
        str = str + element;
      }
      else {
        if (((index + 1) % 3) == 0) {
          str = str + element + "<br />";
        }
        else { str = str + element + ','; }
      }
    });

    return str
  }


  openModel(content, item) {
    this.stateLinkData = null;
    this.isupdatesloading = true;
    this.stateLinkData = item;
    this.modalService.open(content, { centered: true });
    setTimeout(() => {
      this.isupdatesloading = false;
    }, 2000);
  }

  citationUrl(item) {
    if (item.url) {
      window.open(item.url, '_blank')
    }
  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onFilterSelectAllCarrier($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onFilterDeSelectAllCarrier($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
        }
      }
    }
    this.GetContent();
  }

  onFilterSelectAllCategory($event){
    this.categoryId = ' ';
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onFilterDeSelectAllCategory($event){
    this.categoryId = ' ';
    if($event.length>0)
    {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
        }
      }
    }
  }else{
    this.dropdownDateSubCategoryFilter=[];
    this.listOfSubCategoryIDs = [];
    this.selectedItemsSubCategory=[];
    this.selectedItemsCategory = [];
    this.listOfCategoryIDs=[];
}

    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
      this.GetSubCategoryByCategoryId(this.categoryId);
    }
    else
    {
      this.dropdownDateSubCategoryFilter=[];
      this.listOfSubCategoryIDs = [];
      this.selectedItemsSubCategory = [];
    }
    this.GetContent();
  }

  onFilterSelectAllSubCategory($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onFilterDeSelectAllSubCategory($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
        }
      }
    }
    this.GetContent();
  }
}