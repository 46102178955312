import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActionItemService } from 'src/Services/actionitem.service';
import { CheckAuth } from 'src/Services/utility.service';
import { ToolEnum, AdminUserType } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { ToastrService } from 'ngx-toastr';
import { AdminActionItemSearchResult } from 'src/Models/AdminActionItemSearchResult';
import { Router } from '@angular/router';
import { StateMasterService } from 'src/Services/state-master.service';
import { CarrierCategoryModel } from 'src/Models/carrierCategoryModel';
import { ActionStateModel } from 'src/Models/ActionStateModel';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-action-item-list',
  templateUrl: './action-item-list.component.html',
  styleUrls: ['./action-item-list.component.css']
})
export class ActionItemListComponent implements OnInit {
  tokenKey: string = '';
  userID: number = 0;
  SelectedTool: string;
  productId: number = 1;

  filteModel = new FilterModel()
  actionItemList: AdminActionItemSearchResult[] = [];
  totalRecordCount: number = -1;

  upDownDeadlineDateCSS: string = '';
  upDownSubjectCSS: string = '';
  upDownTypeNameCSS: string = '';
  upDownCompanyNameCSS: string = '';
  upDownStateNameCSS: string = '';
  upDownCarrierNameCSS: string = '';
  upDownIsActionCSS: string = '';
  upDownActionChargesCSS: string = '';
  upDownIsInformationOnlyCSS: string = '';

  isAscending: boolean = false;

  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateId: number[] = [];

  dropdownListCategory = [];
  selectedItemsCategory = [];
  dropdownSettingsCategory = {};
  listOfCategoryId: number[] = [];

  isloading: boolean = false;
  carrierCategoryList: CarrierCategoryModel[];
  actionStateList: ActionStateModel[];
  deleteActionId: number = 0;

  keywordValue: string = '';
  IsActionRequiredValues: boolean = false;
  currentPage: number = 1;

  dropdownDateFilter = [];
  selectedItemsDateFilter = [];
  dropdownSettingsDateFilter = {};
  listOfDateFilterId: number[] = [];
  isDateRangShow: boolean = false;
  bsRangeValue: Date[];
  singleDate: string = '';

  dropdownListTypes = [];
  selectedItemsTypes = [];
  dropdownSettingsTypes = {};
  listOfTypes = [];
  systemUserCondition: string = '';
  loginUserId: number = 0;

  openMenuIndex: number = -1;
  isShowprogressSpinner = false;
  blockedDocument = false;
  listofTracItEntriesExport:any = [];

  constructor(private stateMasterService: StateMasterService, private router: Router, private toastr: ToastrService,
    private titleService: Title, private actionItemServices: ActionItemService,
    private checkAuth: CheckAuth) {
    this.listOfStateId = [];
    this.listOfCategoryId = [];
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Trac-It Entries');
    sessionStorage.removeItem('ActionItemClone');

    this.loginUserId = +sessionStorage.getItem('AdminUserId');
    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.systemUserCondition = 'null'
    else
      this.systemUserCondition = '  (ISNULL(CompanyId,0) = 0 Or CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.loginUserId + '))';

  }

  ngOnInit() {
    this.pupulateDateFilterItem();
    this.setSaveFilter();
    this.AdminActionItemFindByPages();
    this.GetCarrierCategories();
    this.GetStateData();
    this.ActionTypes();
  }

  pupulateDateFilterItem() {
    this.dropdownDateFilter.push({ "id": 1, "itemName": 'After' });
    this.dropdownDateFilter.push({ "id": 2, "itemName": 'Before' });
    this.dropdownDateFilter.push({ "id": 3, "itemName": 'Between' });
    this.dropdownDateFilter.push({ "id": 4, "itemName": 'Equal' });

    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No filter(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false,
    };
  }


  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  AdminActionItemFindByPages() {
    this.isloading = true;
    this.actionItemList = [];
    this.actionItemServices.AdminActionItemFindByPages(this.tokenKey, this.filteModel).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.actionItemList = data['actionList'];
          if (this.actionItemList != null) {
            if (this.actionItemList.length > 0) {
              this.totalRecordCount = +this.actionItemList[0].totalRowCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filteModel.pageNumber = parseInt($event.page).toString();
    this.AdminActionItemFindByPages();
  }

  sort(sortBy: any) {
    this.upDownDeadlineDateCSS = '';
    this.upDownSubjectCSS = '';
    this.upDownTypeNameCSS = '';
    this.upDownCompanyNameCSS = '';
    this.upDownStateNameCSS = '';
    this.upDownCarrierNameCSS = '';
    this.upDownActionChargesCSS = '';
    this.upDownIsActionCSS = '';
    this.upDownIsInformationOnlyCSS = '';

    if (!this.isAscending) {
      this.isAscending = true;
      this.filteModel.orderBy = sortBy + ' DESC';

      if (sortBy === "deadlineDate") {
        this.upDownDeadlineDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "subject") {
        this.upDownSubjectCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "typeName") {
        this.upDownTypeNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "companyName") {
        this.upDownCompanyNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "stateName") {
        this.upDownStateNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "carrierName") {
        this.upDownCarrierNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "ActionCharges") {
        this.upDownActionChargesCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "IsAction") {
        this.upDownIsActionCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "IsInformationOnly") {
        this.upDownIsInformationOnlyCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.filteModel.orderBy = sortBy;

      if (sortBy === "deadlineDate") {
        this.upDownDeadlineDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "subject") {
        this.upDownSubjectCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "typeName") {
        this.upDownTypeNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "companyName") {
        this.upDownCompanyNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "stateName") {
        this.upDownStateNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "carrierName") {
        this.upDownCarrierNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "ActionCharges") {
        this.upDownActionChargesCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "IsAction") {
        this.upDownIsActionCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "IsInformationOnly") {
        this.upDownIsInformationOnlyCSS = 'fa fa-arrow-up';
      }
    }
    this.AdminActionItemFindByPages();
  }

  IsActionRequired($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    this.filteModel.isActionRequired = $event.target.checked;
    this.AdminActionItemFindByPages();
  }

  redirectToActionAdd() {

    this.saveSession();
    this.router.navigate(['/admin/trac-it/manage']);
  }

  redirectToActionEdit(actionId: any) {

    this.saveSession();
    this.router.navigate(['/admin/trac-it/manage/' + actionId]);
  }

  redirectToActionClone(actionId: any) {

    this.saveSession();
    sessionStorage.setItem('ActionItemClone', actionId);
    this.router.navigate(['/admin/trac-it/manage/' + actionId]);
  }

  setDropDownSetting() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Select State(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No state available',
      showCheckbox: true
    };

    this.dropdownSettingsCategory = {
      singleSelection: false,
      text: "Select Carrier Type(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No carrier types available',
      showCheckbox: true
    };
  }

  GetCarrierCategories() {
    this.actionItemServices.GetCarrierCategories(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success') {
          let carrierCategories = data['companyModel'];
          if (carrierCategories != null) {
            if (carrierCategories.length > 0) {
              for (let iCounter = 0; iCounter < carrierCategories.length; iCounter++) {
                this.dropdownListCategory.push({ "id": parseInt(carrierCategories[iCounter].carrierCatId), "itemName": carrierCategories[iCounter].carrierCatName });
              }
            }


            let CarrierCategories = this.filteModel.carrierCategories;
            if (CarrierCategories != '' && CarrierCategories != null) {
              let tmpCarrierCategories = CarrierCategories.split(',');
              if (tmpCarrierCategories != null) {
                if (tmpCarrierCategories.length > 0) {
                  for (let iCounter = 0; iCounter < tmpCarrierCategories.length; iCounter++) {

                    if (tmpCarrierCategories[iCounter] != '') {
                      let obj = data["companyModel"].filter(m => m.carrierCatId == tmpCarrierCategories[iCounter]);
                      if (obj != null) {
                        if (obj[0].carrierCatId == tmpCarrierCategories[iCounter]) {
                          this.selectedItemsCategory.push({ "id": parseInt(carrierCategories[iCounter].carrierCatId), "itemName": carrierCategories[iCounter].carrierCatName });
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        this.dropdownSettingsCategory = {
          singleSelection: false,
          text: "Select Carrier Type(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 2,
          noDataLabel: 'No carrier types available',
          showCheckbox: true
        };
      });
  }

  GetStateData(): void {
    this.stateMasterService.getState(this.tokenKey, this.productId).subscribe(
      data => {
        if (data["state"] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data["state"].length; iCounter++) {
            this.dropdownListState.push({ "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name });
          }

          let StateID = this.filteModel.stateID;
          if (StateID != '' && StateID != null) {
            let tmpstateID = StateID.split(',');
            if (tmpstateID != null) {
              if (tmpstateID.length > 0) {
                for (let iCounter = 0; iCounter < tmpstateID.length; iCounter++) {

                  if (tmpstateID[iCounter] != '') {
                    let obj = data["state"].filter(m => m.stateId == tmpstateID[iCounter]);
                    if (obj != null) {
                      if (obj[0].stateId == tmpstateID[iCounter]) {
                        this.selectedItemsState.push({ "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name });
                      }
                    }
                  }
                }
              }
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');

        }
        this.dropdownSettingsState = {
          singleSelection: false,
          text: "Select Jurisdiction(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 2,
          noDataLabel: 'No Jurisdiction available',
          showCheckbox: true
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  onSelectedState($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    if (this.listOfStateId != null) {
      if (this.listOfStateId.length > 0) {
        this.listOfStateId = this.removeElemetninArray(this.listOfStateId, $event.id)
        this.listOfStateId.push($event.id);
      }
      else {
        this.listOfStateId.push($event.id);
      }
    }
    this.setStateId();
    this.AdminActionItemFindByPages();
  }

  onDeSelectedState($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    if (this.listOfStateId != null) {
      if (this.listOfStateId.length > 0) {
        this.listOfStateId = this.removeElemetninArray(this.listOfStateId, $event.id)
      }
    }
    this.setStateId();
    this.AdminActionItemFindByPages();
  }

  onSelectStateAll($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateId != null) {
        if (this.listOfStateId.length > 0) {
          this.listOfStateId = this.removeElemetninArray(this.listOfStateId, $event[iCounter].id)
          this.listOfStateId.push($event[iCounter].id);
        }
        else {
          this.listOfStateId.push($event[iCounter].id);
        }
      }
    }

    this.setStateId();
    this.AdminActionItemFindByPages();
  }

  onDeSelectStateAll($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    this.listOfStateId = [];
    this.setStateId();
    this.AdminActionItemFindByPages();
  }

  setStateId() {
    let seprator = '';
    this.filteModel.stateID = '';
    if (this.listOfStateId != null) {
      if (this.listOfStateId.length > 0) {
        for (let iCounter = 0; iCounter < this.listOfStateId.length; iCounter++) {
          this.filteModel.stateID += seprator + this.listOfStateId[iCounter];
          seprator = ',';
        }
      }
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectedCategory($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    if (this.listOfCategoryId != null) {
      if (this.listOfCategoryId.length > 0) {
        this.listOfCategoryId = this.removeElemetninArray(this.listOfCategoryId, $event.id)
        this.listOfCategoryId.push($event.id);
      }
      else {
        this.listOfCategoryId.push($event.id);
      }
    }
    this.setCategoryId();
    this.AdminActionItemFindByPages();
  }

  onDeSelectedCategory($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    if (this.listOfCategoryId != null) {
      if (this.listOfCategoryId.length > 0) {
        this.listOfCategoryId = this.removeElemetninArray(this.listOfCategoryId, $event.id)
      }
    }
    this.setCategoryId();
    this.AdminActionItemFindByPages();
  }

  onSelectCategoryAll($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryId != null) {
        if (this.listOfCategoryId.length > 0) {
          this.listOfCategoryId = this.removeElemetninArray(this.listOfCategoryId, $event[iCounter].id)
          this.listOfCategoryId.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryId.push($event[iCounter].id);
        }
      }
    }
    this.setCategoryId();
    this.AdminActionItemFindByPages();
  }

  onDeSelectCategoryAll($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    this.listOfCategoryId = [];
    this.setCategoryId();
    this.AdminActionItemFindByPages();
  }

  setCategoryId() {
    let seprator = '';
    this.filteModel.carrierCategories = '';
    if (this.listOfCategoryId != null) {
      if (this.listOfCategoryId.length > 0) {
        for (let iCounter = 0; iCounter < this.listOfCategoryId.length; iCounter++) {
          this.filteModel.carrierCategories += seprator + this.listOfCategoryId[iCounter];
          seprator = ',';
        }
      }
    }
  }

  keywordFilters($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      sessionStorage.setItem('saveFilterKeywordValue', $event.target.value);
      this.filteModel.whereCondition = " subject like '%" + $event.target.value + "%'";
    }
    else {
      this.filteModel.whereCondition = '';
      sessionStorage.setItem('saveFilterKeywordValue', '');
    }

    this.AdminActionItemFindByPages();
  }

  GetModelActionState(actionId: any) {
    this.actionStateList = [];
    this.actionItemServices.GetActionStateByActionId(this.tokenKey, actionId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.actionStateList = data['actionState'];
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  GetModelCarrierCategories(actionId: any) {
    this.carrierCategoryList = [];
    this.actionItemServices.GetActionCarrierByActionId(this.tokenKey, actionId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.carrierCategoryList = data['actionCarrier'];
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  SetDeleteId(actionId: any) {
    this.deleteActionId = actionId;
  }

  DeleteActionItemAdmin() {
    this.actionItemServices.DeleteActionItemAdmin(this.tokenKey, this.deleteActionId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.showMessage('Record deleted successfully.', 'Success', 'success');
          this.AdminActionItemFindByPages();
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }


  saveSession() {

    sessionStorage.setItem('saveFilterPageNo', this.filteModel.pageNumber);
    sessionStorage.setItem('saveFilterOrderBy', this.filteModel.orderBy);
    sessionStorage.setItem('saveFilterWhereCondition', this.filteModel.whereCondition);
    sessionStorage.setItem('saveFilterStateID', JSON.stringify(this.listOfStateId));
    sessionStorage.setItem('saveFilterCarrierCategories', JSON.stringify(this.listOfCategoryId));
    sessionStorage.setItem('saveFilterKeywordValue', this.keywordValue);

    let upDownDeadlineDateCSS = this.upDownDeadlineDateCSS;
    sessionStorage.setItem('saveFilterUpDownDeadlineDateCSS', upDownDeadlineDateCSS);
    let upDownSubjectCSS = this.upDownSubjectCSS
    sessionStorage.setItem('saveFilterUpDownSubjectCSS', upDownSubjectCSS);
    let upDownTypeNameCSS = this.upDownTypeNameCSS
    sessionStorage.setItem('saveFilterUpDownTypeNameCSS', upDownTypeNameCSS);
    let upDownCompanyNameCSS = this.upDownCompanyNameCSS;
    sessionStorage.setItem('saveFilterUpDownCompanyNameCSS', upDownCompanyNameCSS);
    let upDownStateNameCSS = this.upDownStateNameCSS;
    sessionStorage.setItem('saveFilterUpDownStateNameCSS', upDownStateNameCSS);
    let upDownCarrierNameCSS = this.upDownCarrierNameCSS;
    sessionStorage.setItem('saveFilterUpDownCarrierNameCSS', upDownCarrierNameCSS);
    let upDownActionChargesCSS = this.upDownActionChargesCSS;
    sessionStorage.setItem('saveFilterUpDownActionChargesCSS', upDownActionChargesCSS);
    let upDownIsActionCSS = this.upDownIsActionCSS;
    sessionStorage.setItem('saveFilterUpDownIsActionCSS', upDownIsActionCSS);
    let upDownIsInformationOnlyCSS = this.upDownIsInformationOnlyCSS;
    sessionStorage.setItem('saveFilterUpDownIsInformationOnlyCSS', upDownIsInformationOnlyCSS);

    let isAscending = '';
    if (this.isAscending)
      isAscending = 'true';
    else
      isAscending = 'false';
    sessionStorage.setItem('saveFilterIsAscending', isAscending);

    let isActionRequired = '';
    if (this.filteModel.isActionRequired)
      isActionRequired = 'true';
    else
      isActionRequired = 'false';

    sessionStorage.setItem('saveFilterIsActionRequired', isActionRequired);

    sessionStorage.setItem('saveFilterOprator', this.filteModel.oprator);
    sessionStorage.setItem('saveFilterFromDate', this.filteModel.fromDate);
    sessionStorage.setItem('saveFilterToDate', this.filteModel.toDate);
    sessionStorage.setItem('saveFilterListOfType', this.filteModel.listOfType);

    sessionStorage.setItem('savePreviousUrl', '/admin/admin/trac-it/manage');
  }

  clearSession() {

    sessionStorage.removeItem('saveFilterPageNo');
    sessionStorage.removeItem('saveFilterOrderBy');
    sessionStorage.removeItem('saveFilterWhereCondition');
    sessionStorage.removeItem('saveFilterStateID');
    sessionStorage.removeItem('saveFilterCarrierCategories');

    sessionStorage.removeItem('saveFilterUpDownDeadlineDateCSS');
    sessionStorage.removeItem('saveFilterUpDownSubjectCSS');
    sessionStorage.removeItem('saveFilterUpDownTypeNameCSS');
    sessionStorage.removeItem('saveFilterUpDownCompanyNameCSS');
    sessionStorage.removeItem('saveFilterUpDownStateNameCSS');
    sessionStorage.removeItem('saveFilterUpDownCarrierNameCSS');
    sessionStorage.removeItem('saveFilterUpDownActionChargesCSS');
    sessionStorage.removeItem('saveFilterUpDownIsActionCSS');
    sessionStorage.removeItem('saveFilterIsAscending');
    sessionStorage.removeItem('saveFilterIsActionRequired');
    sessionStorage.removeItem('saveFilterUpDownIsInformationOnlyCSS');

    sessionStorage.removeItem('saveFilterOprator');
    sessionStorage.removeItem('saveFilterFromDate');
    sessionStorage.removeItem('saveFilterToDate');
    sessionStorage.removeItem('saveFilterListOfType');
    sessionStorage.removeItem('savePreviousUrl');
  }

  setSaveFilter() {
    this.filteModel.pageSize = '10';
    this.filteModel.systemUserCondition = this.systemUserCondition;

    if (sessionStorage.getItem('savePreviousUrl') != '/admin/admin/trac-it/manage') {
      this.clearSession();
    }

    if (sessionStorage.getItem('saveFilterPageNo') != null && sessionStorage.getItem('saveFilterPageNo') != '' && sessionStorage.getItem('saveFilterPageNo') != 'undefined') {
      this.filteModel.pageNumber = sessionStorage.getItem('saveFilterPageNo');
      this.currentPage = +sessionStorage.getItem('saveFilterPageNo');
      sessionStorage.removeItem('saveFilterPageNo');
    }
    else
      this.filteModel.pageNumber = '1';


    if (sessionStorage.getItem('saveFilterOrderBy') != null && sessionStorage.getItem('saveFilterOrderBy') != '' && sessionStorage.getItem('saveFilterOrderBy') != 'undefined') {
      this.filteModel.orderBy = sessionStorage.getItem('saveFilterOrderBy');
      sessionStorage.removeItem('saveFilterOrderBy');
    }
    else
      this.filteModel.orderBy = 'ISNULL(ModifiedDate,CreatedDate) desc';


    if (sessionStorage.getItem('saveFilterWhereCondition') != null && sessionStorage.getItem('saveFilterWhereCondition') != '' && sessionStorage.getItem('saveFilterWhereCondition') != 'undefined') {
      this.filteModel.whereCondition = sessionStorage.getItem('saveFilterWhereCondition');
      this.keywordValue = sessionStorage.getItem('saveFilterKeywordValue');
      sessionStorage.removeItem('saveFilterKeywordValue');
      sessionStorage.removeItem('saveFilterWhereCondition');
    }
    else {
      this.filteModel.whereCondition = '';
      this.keywordValue = '';
    }


    if (sessionStorage.getItem('saveFilterStateID') != null && sessionStorage.getItem('saveFilterStateID') != '' && sessionStorage.getItem('saveFilterStateID') != 'undefined') {

      this.listOfStateId = JSON.parse(sessionStorage.getItem('saveFilterStateID'));
      this.filteModel.stateID = this.listOfStateId.join(',');
      sessionStorage.removeItem('saveFilterStateID');
    }
    else
      this.filteModel.stateID = '';


    if (sessionStorage.getItem('saveFilterCarrierCategories') != null && sessionStorage.getItem('saveFilterCarrierCategories') != '' && sessionStorage.getItem('saveFilterCarrierCategories') != 'undefined') {
      this.listOfCategoryId = JSON.parse(sessionStorage.getItem('saveFilterCarrierCategories'));
      this.filteModel.carrierCategories = this.listOfCategoryId.join(',');
      sessionStorage.removeItem('saveFilterCarrierCategories');
    }
    else
      this.filteModel.carrierCategories = '';

    if (sessionStorage.getItem('saveFilterUpDownDeadlineDateCSS') != null && sessionStorage.getItem('saveFilterUpDownDeadlineDateCSS') != '' && sessionStorage.getItem('saveFilterUpDownDeadlineDateCSS') != 'undefined') {
      this.upDownDeadlineDateCSS = sessionStorage.getItem('saveFilterUpDownDeadlineDateCSS');
      sessionStorage.removeItem('saveFilterUpDownDeadlineDateCSS');
    }
    else
      this.upDownDeadlineDateCSS = '';

    if (sessionStorage.getItem('saveFilterUpDownSubjectCSS') != null && sessionStorage.getItem('saveFilterUpDownSubjectCSS') != '' && sessionStorage.getItem('saveFilterUpDownSubjectCSS') != 'undefined') {
      this.upDownSubjectCSS = sessionStorage.getItem('saveFilterUpDownSubjectCSS');
      sessionStorage.removeItem('saveFilterUpDownSubjectCSS');
    }
    else
      this.upDownSubjectCSS = '';

    if (sessionStorage.getItem('saveFilterUpDownTypeNameCSS') != null && sessionStorage.getItem('saveFilterUpDownTypeNameCSS') != '' && sessionStorage.getItem('saveFilterUpDownTypeNameCSS') != 'undefined') {
      this.upDownTypeNameCSS = sessionStorage.getItem('saveFilterUpDownTypeNameCSS');
      sessionStorage.removeItem('saveFilterUpDownTypeNameCSS');
    }
    else
      this.upDownTypeNameCSS = '';


    if (sessionStorage.getItem('saveFilterUpDownCompanyNameCSS') != null && sessionStorage.getItem('saveFilterUpDownCompanyNameCSS') != '' && sessionStorage.getItem('saveFilterUpDownCompanyNameCSS') != 'undefined') {
      this.upDownCompanyNameCSS = sessionStorage.getItem('saveFilterUpDownCompanyNameCSS');
      sessionStorage.removeItem('saveFilterUpDownCompanyNameCSS');
    }
    else
      this.upDownCompanyNameCSS = '';

    if (sessionStorage.getItem('saveFilterUpDownStateNameCSS') != null && sessionStorage.getItem('saveFilterUpDownStateNameCSS') != '' && sessionStorage.getItem('saveFilterUpDownStateNameCSS') != 'undefined') {
      this.upDownStateNameCSS = sessionStorage.getItem('saveFilterUpDownStateNameCSS');
      sessionStorage.removeItem('saveFilterUpDownStateNameCSS');
    }
    else
      this.upDownStateNameCSS = '';

    if (sessionStorage.getItem('saveFilterUpDownCarrierNameCSS') != null && sessionStorage.getItem('saveFilterUpDownCarrierNameCSS') != '' && sessionStorage.getItem('saveFilterUpDownCarrierNameCSS') != 'undefined') {
      this.upDownCarrierNameCSS = sessionStorage.getItem('saveFilterUpDownCarrierNameCSS');
      sessionStorage.removeItem('saveFilterUpDownCarrierNameCSS');
    }
    else
      this.upDownCarrierNameCSS = '';

    if (sessionStorage.getItem('saveFilterUpDownActionChargesCSS') != null && sessionStorage.getItem('saveFilterUpDownActionChargesCSS') != '' && sessionStorage.getItem('saveFilterUpDownActionChargesCSS') != 'undefined') {
      this.upDownActionChargesCSS = sessionStorage.getItem('saveFilterUpDownActionChargesCSS');
      sessionStorage.removeItem('saveFilterUpDownActionChargesCSS');
    }
    else
      this.upDownActionChargesCSS = '';

    if (sessionStorage.getItem('saveFilterUpDownIsActionCSS') != null && sessionStorage.getItem('saveFilterUpDownIsActionCSS') != '' && sessionStorage.getItem('saveFilterUpDownIsActionCSS') != 'undefined') {
      this.upDownIsActionCSS = sessionStorage.getItem('saveFilterUpDownIsActionCSS');
      sessionStorage.removeItem('saveFilterUpDownIsActionCSS');
    }
    else
      this.upDownIsActionCSS = '';

    if (sessionStorage.getItem('saveFilterUpDownIsInformationOnlyCSS') != null && sessionStorage.getItem('saveFilterUpDownIsInformationOnlyCSS') != '' && sessionStorage.getItem('saveFilterUpDownIsInformationOnlyCSS') != 'undefined') {
      this.upDownIsInformationOnlyCSS = sessionStorage.getItem('saveFilterUpDownIsInformationOnlyCSS');
      sessionStorage.removeItem('saveFilterUpDownIsInformationOnlyCSS');
    }
    else
      this.upDownIsInformationOnlyCSS = '';

    if (sessionStorage.getItem('saveFilterIsAscending') != null && sessionStorage.getItem('saveFilterIsAscending') != '' && sessionStorage.getItem('saveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('saveFilterIsAscending') == 'false' ? false : true;
      sessionStorage.removeItem('saveFilterIsAscending');
    }
    else
      this.isAscending = false;

    if (sessionStorage.getItem('saveFilterIsActionRequired') != null && sessionStorage.getItem('saveFilterIsActionRequired') != '' && sessionStorage.getItem('saveFilterIsActionRequired') != 'undefined') {
      this.filteModel.isActionRequired = sessionStorage.getItem('saveFilterIsActionRequired') == 'false' ? false : true;
      this.IsActionRequiredValues = this.filteModel.isActionRequired;
      sessionStorage.removeItem('saveFilterIsActionRequired');
    }
    else {
      this.filteModel.isActionRequired = false;
      this.IsActionRequiredValues = this.filteModel.isActionRequired;
    }


    if (sessionStorage.getItem('saveFilterOprator') != null && sessionStorage.getItem('saveFilterOprator') != '' && sessionStorage.getItem('saveFilterOprator') != 'undefined') {
      this.filteModel.oprator = sessionStorage.getItem('saveFilterOprator');
      for (let iCounter = 0; iCounter < this.dropdownDateFilter.length; iCounter++) {
        if (this.dropdownDateFilter[iCounter].itemName == this.filteModel.oprator) {
          this.selectedItemsDateFilter.push({ "id": this.dropdownDateFilter[iCounter].id, "itemName": this.dropdownDateFilter[iCounter].itemName })
        }
      }
      sessionStorage.removeItem('saveFilterOprator');
    }
    else
      this.filteModel.oprator = '';

    if (sessionStorage.getItem('saveFilterFromDate') != null && sessionStorage.getItem('saveFilterFromDate') != '' && sessionStorage.getItem('saveFilterFromDate') != 'undefined') {
      this.filteModel.fromDate = sessionStorage.getItem('saveFilterFromDate');
      this.singleDate = this.filteModel.fromDate;
      sessionStorage.removeItem('saveFilterFromDate');
    }
    else
      this.filteModel.fromDate = '';

    if (sessionStorage.getItem('saveFilterToDate') != null && sessionStorage.getItem('saveFilterToDate') != '' && sessionStorage.getItem('saveFilterToDate') != 'undefined') {
      this.filteModel.toDate = sessionStorage.getItem('saveFilterToDate');
      sessionStorage.removeItem('saveFilterToDate');
    }
    else
      this.filteModel.toDate = '';


    if (this.filteModel.fromDate != '' && this.filteModel.toDate != '') {
      const s = new Date(this.filteModel.fromDate);
      const e = new Date(this.filteModel.toDate);
      this.isDateRangShow = true;
      this.bsRangeValue = [s, e]
    }

    if (sessionStorage.getItem('saveFilterListOfType') != null && sessionStorage.getItem('saveFilterListOfType') != '' && sessionStorage.getItem('saveFilterListOfType') != 'undefined') {
      this.filteModel.listOfType = sessionStorage.getItem('saveFilterListOfType');
      sessionStorage.removeItem('saveFilterListOfType');
    }
    else
      this.filteModel.listOfType = '';
  }

  isDeadlineDate(dateArray: any) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    if (dateArray != null) {
      let dd1 = dateArray.getDate().toString();
      if (parseInt(dd1.toString()) < 10)
        dd1 = '0' + dd1.toString();

      let mm1 = (dateArray.getMonth() + 1).toString();
      if (parseInt(mm1.toString()) < 10)
        mm1 = '0' + mm1.toString();

      this.filteModel.fromDate = mm1 + '-' + dd1 + '-' + dateArray.getFullYear();
      this.singleDate = mm1 + '-' + dd1 + '-' + dateArray.getFullYear();
    }
    else
      this.filteModel.fromDate = '';

    if (this.filteModel.oprator != '')
      this.AdminActionItemFindByPages();
  }

  onValueChange(value: Date): void {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    const d = new Date(value[0]);
    const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

    const e = new Date(value[1]);
    const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');
    this.filteModel.fromDate = a;
    this.filteModel.toDate = f;
    if (this.filteModel.oprator != '')
      this.AdminActionItemFindByPages();
  }

  pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  onSelectedDateFilter($event) {

    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    this.listOfDateFilterId = [];
    if (this.listOfDateFilterId != null) {
      if (this.listOfDateFilterId.length > 0) {
        this.listOfDateFilterId = this.removeElemetninArray(this.listOfDateFilterId, $event.id)
        this.listOfDateFilterId.push($event.id);
      }
      else {
        this.listOfDateFilterId.push($event.id);

        if ($event.id == 3) {
          this.isDateRangShow = true;
        }
        else {
          this.isDateRangShow = false;
          this.filteModel.toDate = '';
        }
      }
      this.filteModel.oprator = $event.itemName;
    }
    if (this.filteModel.fromDate != '')
      this.AdminActionItemFindByPages();
  }

  onDeSelectedDateFilter($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    if (this.listOfDateFilterId != null) {
      if (this.listOfDateFilterId.length > 0) {
        this.listOfDateFilterId = this.removeElemetninArray(this.listOfDateFilterId, $event.id)
      }
    }
    if ($event.id == 3) {
      this.isDateRangShow = true;
    }
    else {
      this.isDateRangShow = false;
      this.filteModel.toDate = '';
    }
    this.filteModel.oprator = $event.itemName;

    if (this.filteModel.fromDate != '')
      this.AdminActionItemFindByPages();
  }

  onDeSelectDateFilterAll($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    this.listOfDateFilterId = [];
    this.isDateRangShow = false;
    this.filteModel.oprator = '';
    this.filteModel.fromDate = '';
    this.filteModel.toDate = '';
    this.singleDate = '';
    this.AdminActionItemFindByPages();
  }

  ActionTypes(): void {
    this.dropdownListTypes = [];
    this.actionItemServices.GetActionItemsType(this.tokenKey).subscribe(
      data => {
        if (data["actionType"] != null && data["status"] != "Failure") {

          for (let iCounter = 0; iCounter < data["actionType"].length; iCounter++) {
            // Remove Company Specific
            if (data["actionType"][iCounter].actionItemTypeId != 5) {
              this.dropdownListTypes.push(
                { "id": data["actionType"][iCounter].actionItemTypeId, "itemName": data["actionType"][iCounter].typeName }
              );
            }
          }


          if (this.filteModel.listOfType != '') {
            let arry = this.filteModel.listOfType.split(',');
            for (let iCounter = 0; iCounter < arry.length; iCounter++) {
              let obj = data["actionType"].filter(m => m.actionItemTypeId == arry[iCounter]);
              if (obj != null) {
                if (obj.length > 0) {
                  this.selectedItemsTypes.push({ "id": obj[0].actionItemTypeId, "itemName": obj[0].typeName })
                  this.listOfTypes.push(arry[iCounter]);
                }
              }
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
        this.dropdownSettingsTypes = {
          singleSelection: false,
          text: "Select Category(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No  category(s) Available',
          showCheckbox: true,
          enableFilterSelectAll: false,
          enableCheckAll: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  // Action Type
  onSelectedActionType($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    if (this.listOfTypes != null) {
      if (this.listOfTypes.length > 0) {
        this.listOfTypes = this.removeElemetninArray(this.listOfTypes, $event.id)
        this.listOfTypes.push($event.id);
      }
      else {
        this.listOfTypes.push($event.id);
      }
    }
    this.filteModel.listOfType = this.listOfTypes.join(',');
    this.AdminActionItemFindByPages();
  }

  onSelectAllCategory($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfTypes != null) {
        if (this.listOfTypes.length > 0) {
          this.listOfTypes = this.removeElemetninArray(this.listOfTypes, $event[iCounter].id)
          this.listOfTypes.push($event[iCounter].id);
        }
        else {
          this.listOfTypes.push($event[iCounter].id);
        }
      }
    }
    this.filteModel.listOfType = this.listOfTypes.join(',');
    this.AdminActionItemFindByPages();
  }

  onDeSelectedActionType($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    if (this.listOfTypes != null) {
      if (this.listOfTypes.length > 0) {
        this.listOfTypes = this.removeElemetninArray(this.listOfTypes, $event.id)
      }
    }
    this.filteModel.listOfType = this.listOfTypes.join(',');
    this.AdminActionItemFindByPages();
  }

  onDeSelectAllActionType($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    this.listOfTypes = [];
    this.filteModel.listOfType = this.listOfTypes.join(',');
    this.AdminActionItemFindByPages();
  }

  onFilterSelectAllState($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateId != null) {
        if (this.listOfStateId.length > 0) {
          this.listOfStateId = this.removeElemetninArray(this.listOfStateId, $event[iCounter].id)
          this.listOfStateId.push($event[iCounter].id);
        }
        else {
          this.listOfStateId.push($event[iCounter].id);
        }
      }
    }

    this.setStateId();
    this.AdminActionItemFindByPages();
  }

  onFilterDeSelectAllState($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateId != null) {
        if (this.listOfStateId.length > 0) {
          this.listOfStateId = this.removeElemetninArray(this.listOfStateId, $event[iCounter].id)
        }
      }
    }

    this.setStateId();
    this.AdminActionItemFindByPages();
  }

  onFilterSelectAllCarrier($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryId != null) {
        if (this.listOfCategoryId.length > 0) {
          this.listOfCategoryId = this.removeElemetninArray(this.listOfCategoryId, $event[iCounter].id)
          this.listOfCategoryId.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryId.push($event[iCounter].id);
        }
      }
    }
    this.setCategoryId();
    this.AdminActionItemFindByPages();
  }

  onFilterDeSelectAllCarrier($event) {
    this.filteModel.pageNumber = '1';
    this.currentPage = 1;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryId != null) {
        if (this.listOfCategoryId.length > 0) {
          this.listOfCategoryId = this.removeElemetninArray(this.listOfCategoryId, $event[iCounter].id)
        }
      }
    }
    this.setCategoryId();
    this.AdminActionItemFindByPages();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

  ExportTracItEntries() {
    this.isloading = true;
    this.blockedDocument = true;
    this.isShowprogressSpinner = true;
    this.actionItemServices.ExportTracItEntries(this.tokenKey, this.filteModel).subscribe(
      data => {
        this.isloading = false;
        this.listofTracItEntriesExport = [];
        if (data['status'] == 'Success') {
          this.listofTracItEntriesExport = data['data'];
          setTimeout(() => {
            this.exportToExcel();
          }, 1000);
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  exportToExcel() {
    this.isloading = false;
    let tableData = document.getElementById("listofTracItEntriesExportList").innerHTML;

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";
    let name = 'TracItEntries';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "TracItEntries.xls");
  }


}
