
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterModel } from 'src/Models/filterModel';

@Injectable({
  providedIn: 'root'
})
export class UpdatesService {
  AppUrl: string = "";
  filterModel = new FilterModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }
  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  GetUpdatesFilterByPage(TokenKey: string, PageNumber: string, PageSize: string, WhereCondition: string, OrderBy: string, keywordFilter: string, productId: number, isActionRequired: boolean, systemUserCondition: string): Observable<any> {
    this.setAPIUrl();
    this.filterModel.pageNumber = PageNumber;
    this.filterModel.pageSize = PageSize;
    this.filterModel.whereCondition = WhereCondition;
    this.filterModel.orderBy = OrderBy;
    this.filterModel.keywordFilter = keywordFilter;
    this.filterModel.productId = productId;
    this.filterModel.isActionRequired = isActionRequired;
    this.filterModel.systemUserCondition = systemUserCondition;

    let fullAPIPath = this.AppUrl + 'api/Updates/GetUpdatesFilterByPage';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, this.filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  deleteupdates(TokenKey: string, updateId: number): Observable<any[]> {
    let data = '/' + updateId;
    let fullAPIPath = this.AppUrl + 'api/Updates/DeleteUpdate' + data;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any[]>(fullAPIPath, httpOptions)
      .pipe(catchError(this.handleError));
  }
  activeinactiveupdates(TokenKey: string, updateId: string, isactive: string): Observable<any[]> {
    let data = '/' + updateId;
    let fullAPIPath = this.AppUrl + 'api/Updates/ActiveInActiveUpdate' + data + '/' + isactive;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any[]>(fullAPIPath, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetMatrixFundbyStateId(TokenKey: string, StateId: string, productId: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetMatrixFundByStateId/' + StateId + "/" + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions)
      .pipe(catchError(this.handleError));
  }
  getFundFields(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Updates/GetFundFieldAll';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  getUpdateCategory(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Updates/GetUpdateCategoryGetAll';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  addupdateupdates(user, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/Updates/AddUpdateUpdates', user, httpOptions)
      .pipe(catchError(this.handleError));
  }
  getupdatesfindByID(TokenKey: string, UpdateId: number): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetUpdatesFindByID/' + UpdateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(fullAPIPath, httpOptions).pipe(catchError(this.handleError));
  }
  
  getupdatesfindByUserID(TokenKey: string, parameter): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetUpdatesFindByUserID';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, parameter, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getupdatesfindByUserIDByCount(TokenKey: string, parameter): Observable<any> {
    this.setAPIUrl();
    let fullAPIPath = this.AppUrl + 'api/Updates/GetUpdatesFindByUserIDByCount';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, parameter, httpOptions)
      .pipe(catchError(this.handleError));
  }
  addNotification(updates, TokenKey: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + 'api/Updates/AddNotification', updates, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetClientCompany(TokenKey: string, productId: any, whereCondition: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + 'api/Updates/GetClientCompany/' + productId + '/' + whereCondition, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetClientCompanyByProductId(TokenKey: string, data: FilterModel): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/Updates/GetClientCompanyByProductId';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetRecordsCountForAllPage(TokenKey: string, productId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Updates/GetRecordsCountForAllPage/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
