import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuditFieldService { 
  public Fields:Fields[] = [
    { id: 1, name: "Fund" },
    { id: 2, name: "Carrier" },
    { id: 3, name: "Category"},
    { id: 4, name: "Subcategory" },
    { id: 5, name: "Rule Text" },
    { id: 6, name: "Internal Note" },
    { id: 7, name: "Rule Citation" },
    { id: 8, name: "Effective Date" },
    { id: 9, name: "Audit Month" },
    { id: 10, name: "Last Audit" },   
    { id: 11, name: "Source" } 
  ];

  getFields() {
    return this.Fields;
  }
}

class Fields {
  id: number;
  name: string;
}