export class MenuMasterModel {
    menuId: number;
    menuName: string;
    menuGroupId: number;
    productId: number;
    productName: string;
    displayOrder: number;
    iconName: string;
    displayName: string;
    title: string;
    shortDiscription: string;
    icon: string;
    externalURL: string;
    isActive: boolean;
    isAdminVisible: boolean;
    isClientVisible: boolean;
    isLocked: boolean;
    isFreeProduct: boolean;
    modifiedBy: number;
    externalProductName:string;
    isTop:boolean
}