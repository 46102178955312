import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CRRALinkTypeModel } from 'src/Models/CRRALinkModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { AppConfig } from 'src/Services/app.config';
import { CrraLinkMasterService } from 'src/Services/crra-link-master.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';


@Component({
  selector: 'app-crra-link-add-update',
  templateUrl: './crra-link-add-update.component.html',
  styleUrls: ['./crra-link-add-update.component.css']
})
export class CrraLinkAddUpdateComponent implements OnInit {

  linkMasterForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit:boolean = false;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/

  stateList: StateMasterModel[] = [];
  selectedState = [];
  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};
  dropdownSettingsDateLinkTypeFilter = {};
  module="CRRA";
  selectedLinkType = [];
  dropdownDateLinkTypeFilter = [];
  linkTypeList: CRRALinkTypeModel[] = [];

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private stateService: StateMasterService,
    private linkTypeService: CrraLinkMasterService,
    private router: Router, private crraLinkMaster: CrraLinkMasterService, private toastr: ToastrService) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.getLinkType();
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.isSubmit = false;
   
    this.linkMasterForm = this._fb.group({
      linkId: 0,
      citation: ['', Validators.required],
      description: [''],
      uRL: ['', Validators.required],
      linkTypeId: [0],
      stateId: [0],
    })
    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsDateLinkTypeFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No LinkType(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetlinkById(params['id']);
        }
      }
    });
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey,this.module).subscribe(
      data => {
        this.stateList = data['state'];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownDateFilter.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }
  getLinkType() {
    this.linkTypeService.GetLinkType(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.linkTypeList = result['crralink'];
          this.linkTypeList.forEach(x => {
            this.dropdownDateLinkTypeFilter.push({ "id": x.linkTypeId, "itemName": x.linkTypeName })
          });
        }
      },
      error => { }
    );
  }
  cancellinkMaster() {
    let navigation = '/admin/' + this.SelectedTool + '/link';
    this.router.navigate([navigation]);
  }

  GetlinkById(linkTypeId: number) {
    this.crraLinkMaster.GetLinkById(this.tokenKey, parseInt(linkTypeId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.linkMasterForm.patchValue({
            linkId: result['crralink'].linkId,
            citation:result['crralink'].citation,
            description: result['crralink'].description,
            uRL: result['crralink'].url,
            linkTypeId:result['crralink'].linkTypeId,
            stateId: result['crralink'].stateId,
          });
          if (this.stateList.length > 0) {
            let data = this.stateList.filter(x => x.stateId == result['crralink'].stateId)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
          else
            setTimeout(() => {
              let data = this.stateList.filter(x => x.stateId == result['crralink'].stateId)[0];
              this.selectedState.push({ "id": data.stateId, "itemName": data.name })
            }, 500);
          if (this.linkTypeList.length > 0) {
            let data = this.linkTypeList.filter(x => x.linkTypeId == result['crralink'].linkTypeId)[0];
            this.selectedLinkType.push({ "id": data.linkTypeId, "itemName": data.linkTypeName })
          }
          else
            setTimeout(() => {
              let data = this.linkTypeList.filter(x => x.linkTypeId == result['crralink'].linkTypeId)[0];
              this.selectedLinkType.push({ "id": data.linkTypeId, "itemName": data.linkTypeName })
            }, 800);
        }
      });
  }

  save() {
    this.isSubmit = true;
    let data = this.linkMasterForm.value;
    if (!this.linkMasterForm.valid||!data.citation.trim()|| !data.uRL.trim()||this.selectedState.length == 0 || this.selectedLinkType.length == 0 )
      return
    let value={
      linkID:data.linkId,
      citation:data.citation !=null?data.citation.trim():data.citation,
      description:data.description !=null?data.description.trim():data.description,
      uRL:data.uRL,
      linkTypeID:data.linkTypeId,
      stateID:data.stateId,
    }
    this.crraLinkMaster.LinkAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if(result['message'] !=""){
            this.showMessage(result['message'], 'Warning', 'warning')
          }else{

            if (data.linkId > 0)
              this.showMessage('Link updated successfully', 'Success', 'success');
            else
              this.showMessage('Link saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/'+this.SelectedTool+'/link']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
  onChangeState(event) {
    this.linkMasterForm.patchValue({
      stateId: event.id
    });
  }

  onDeSelectDateFilterAll($event) {
    this.selectedState = [];
    this.dropdownDateLinkTypeFilter = [];
  }

  onDeSelectDateFilterLinkTypeAll($event) {
    this.selectedLinkType = [];
  }

  onChangeLinkType(event) {
    this.linkMasterForm.patchValue({
      linkTypeId: event.id
    });
  }
}
