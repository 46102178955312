import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { CRRAContentGetModel } from 'src/Models/CRRAContentModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { CompanyService } from 'src/Services/company.service';
import { CrraCarrierMasterService } from 'src/Services/crra-carrier-master.service';
import { CrraCategoryMasterService } from 'src/Services/crra-category-master.service';
import { CrraContentMasterService } from 'src/Services/crra-content-master.service';
import { CrraSubCategoryMasterService } from 'src/Services/crra-sub-category-master.service';
import { FavoriteService } from 'src/Services/favorite.service';
import { HtmlToPdf } from 'src/Services/htmlToPdf';
import { StateMasterService } from 'src/Services/state-master.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';
import * as FileSaver from 'file-saver';
import { TRFAGetUpdateViewModel } from 'src/Models/TRFAContentModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DraggableComponent } from 'ng2-dnd';
import { FavoriteFilterModel } from 'src/Models/FavoriteFilterModel';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.css']
})
export class RulesComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;

  listofContent: CRRAContentGetModel[]

  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  stateLinkData: TRFAGetUpdateViewModel;
  isupdatesloading: boolean = false;
  displayFavorite:number=0;

//State List
dropdownListState = [];
selectedItemsState = [];
dropdownSettingsState = {};
listOfStateIds = [];
stateId: string = ' ';
stateList: StateMasterModel[] = [];
stateSubscription: any;

//Carrier

dropdownListCarrier = [];
selectedItemsCarrier = [];
dropdownSettingsCarrier = {};
listOfCarrierIDs = [];
carrierList: any[] = [];
carrierId: string = ' ';

//Category
categoryList: any[] = [];
dropdownDateCategoryFilter = [];
selectedItemsCategory = [];
listOfCategoryIDs = [];
dropdownSettingsDateCategoryFilter = {};
categoryId: string = ' ';

// Sub Category
subCategoryList: any[] = [];
dropdownDateSubCategoryFilter = [];
selectedItemsSubCategory = [];
listOfSubCategoryIDs = [];
dropdownSettingsDateSubCategoryFilter = {};
subCategoryId: string = ' ';

dropdownSettingsDateselectedColumnFilter = {};
dropdownSettingsDatePageSizeFilter = {};

selectedPageSize = [];
pageSizeOptions: any[] = [];
SearchItem: string='';
Note: string;
statefilterData: any;
canDownload: boolean = false;

module = "CRRA";
_userID: string = "";
userId: number
ProductId:number = 0;

showhidecollpase: boolean = false;
colexpandText: string = "Expand All";
isCollapsed: boolean = true;
fundCount: number = 0;

SelectedStateFav: number = 0;
favouriteStateModel: FavoriteFilterModel[] = [];

isShowprogressSpinner: boolean = false;
blockedDocument: boolean = false;
@ViewChild('deletecloseBtn', { static: false }) deletecloseBtn: ElementRef;
baseUrl: string;

CRRAexportCitiationList: any[] = [];

  constructor(private appActivity: ApplicationActivityService,
    private router: Router,
    private checkAuth: CheckAuth,
    private titleService: Title,
    private toastr: ToastrService,
    private htmlToPdf: HtmlToPdf,
    private activedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private userService: UsersService,   
    private favorite: FavoriteService,
    private stateService: StateMasterService,
    private crraCarrierMasterService: CrraCarrierMasterService,
    private crraCategoryMasterService: CrraCategoryMasterService,
    private crraSubCategoryMasterService: CrraSubCategoryMasterService,
    private crraContentMaster: CrraContentMasterService,
    private modalService: NgbModal,
    @Inject('BASE_URL') baseUrl: string
    ) { 
      this.tokenExpireCalculation(
        sessionStorage.getItem("appStartTime"),
        sessionStorage.getItem("tokenExpiryValue")
      );

      this._userID = sessionStorage.getItem('UserId');
    this.ProductId = ToolEnum.crra;
    this.baseUrl = baseUrl;
    this.titleService.setTitle('Rules');
    }

    tokenExpireCalculation(startDate: string, tokenExpiry: string) {
      this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
    }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Jurisdiction(s) available",
      showCheckbox: true,
    };
    this.dropdownSettingsCarrier = {
      singleSelection: false,
      text: "Filter by Carrier",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Carrier(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateCategoryFilter = {
      singleSelection: false,
      text: "Filter by Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateSubCategoryFilter = {
      singleSelection: false,
      text: "Filter by Sub Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Sub Category(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateselectedColumnFilter = {
      singleSelection: false,
      text: "Show Column",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Column(s) available",
      showCheckbox: true,
    };
    this.dropdownSettingsDatePageSizeFilter = {
      singleSelection: true,
      text: "Filter by Page",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Page(s) available",
      showCheckbox: false,
      escapeToClose: false,
    };
    
    this.pageSizeOptions = [
      { id: 1, itemName: 10 },
      { id: 2, itemName: 50 },
      { id: 3, itemName: 100 },
      { id: 4, itemName: 200 },
    ];
    this.selectedPageSize.push({ id: 1, itemName: 10 });
    this.tokenKey = sessionStorage.getItem("tokenValue");

    // this.getState();
    this.getSubscriptionState();
    this.getCarrier();
    this.getCategory();
    this.GetContent();
    this.getCanDownload();
    this.CRRAExportCitiationSubscriptionGet();

    $(function () {
    $("#content").css("fontSize", "32px");
  });
  }

  CRRAExportCitiationSubscriptionGet() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.userService.ExportCitiationSubscriptionGetByUser(this.tokenKey, parseInt(this._userID),this.ProductId).subscribe(
      data => {

        this.CRRAexportCitiationList = data['exportCitiation'];

      },
      error => { }
    );
  }

  getSubscriptionState() {
    this.stateSubscription = null;
    this.stateService.i360ManageSubscriptionClientId(this.tokenKey, this._userID, 0, true).subscribe(
      data => {
        this.stateSubscription = {
          wirelessStates: data.wirelessStates,
          wirelessStatesCount: data.wirelessStates ? data.wirelessStates.length : 0,
          iprStates: data.iprStates,
          iprStatesCount: data.iprStates ? data.iprStates.length : 0,
          voIPStates: data.voIPStates,
          voIPStatesCount: data.voIPStates ? data.voIPStates.length : 0,
          actionStates: data.actionStates,
          actionStatesCount: data.actionStates ? data.actionStates.length : 0,
          productAccess: data.productAccess,
          briefingStates: data.briefingStates,
          briefingStatesCount: data.briefingStates ? data.briefingStates.length : 0,
          state911: data.state911,
          state911Count: data.state911 ? data.state911.length : 0,
          carrierCategory: data.carrierCategory,
          carrierCategoryCount: data.carrierCategory ? data.carrierCategory.filter(m => m.isSelected == true).length : 0,
          trfa: data.trfa,
          crra:data.crra
        };
        this.stateList = data.crra;
          if (this.stateList.length > 0) {
            this.statefilterData = this.stateList.filter(a => a.name == 'FCC');
            if (this.statefilterData.length > 0) {
              this.stateId = " ";
              this.statefilterData.forEach(x => {
                this.listOfStateIds.push(x.stateId);
                this.selectedItemsState.push({ "id": x.stateId, "itemName": x.name })
              });
              this.stateId = this.listOfStateIds.join(',')
              this.GetContent();
            }
          }
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { console.log(error); }
    );
  }

  
  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.GetContent();
  }


  getCarrier() {
    this.crraCarrierMasterService.GetCarrier(this.tokenKey).subscribe(
      data => {
        this.dropdownListCarrier = []
        this.carrierList = data['crracarrier'];
        if (this.carrierList.length > 0) {
          this.carrierList.forEach(x => {
            this.dropdownListCarrier.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCarrier($event) {
    this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
    this.listOfCarrierIDs.push($event.id);
    this.GetContent();
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIDs != null) {
      if (this.listOfCarrierIDs.length > 0) {
        this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
      }
    }
    if (this.listOfCarrierIDs.length > 0) {
      this.carrierId = this.listOfCarrierIDs.join(',')
    }
    this.GetContent();
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIDs = [];
    this.GetContent();
  }

  getCategory() {
    this.crraCategoryMasterService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryList = result['crracategory'];
          this.dropdownDateCategoryFilter = [];
          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilter.push({ "id": x.categoryId, "itemName": x.categoryName })
          });
        }
      },
      error => { }
    );
  }

  GetSubCategoryByCategoryId(categoryId) {

    this.crraSubCategoryMasterService.GetSubCategoryByCategoryId(this.tokenKey, categoryId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.subCategoryList = result['crrasubcategory'];
          this.dropdownDateSubCategoryFilter = [];
          if (this.subCategoryList.length > 0) {
            let b = [];
            this.selectedItemsSubCategory = [];
            this.subCategoryList.forEach(x => {
              let a = this.listOfSubCategoryIDs.filter(e => e == x.subCategoryId);
              if (a.length > 0) {
                b.push(x.subCategoryId);
                this.selectedItemsSubCategory.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
              }
              this.dropdownDateSubCategoryFilter.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
            });
            this.listOfSubCategoryIDs = b;
            if (this.listOfSubCategoryIDs.length > 0) {
              this.subCategoryId = this.listOfSubCategoryIDs.join(',')
              this.GetContent();
            }
          }
          else {
            this.selectedItemsSubCategory = [];
            this.listOfSubCategoryIDs = [];
          }
        }
      }
    );
  }

  onSelectCategory($event) {
    this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
    this.listOfCategoryIDs.push($event.id);
    this.categoryId = ' ';
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onDeSelectCategory($event) {
    if (this.listOfCategoryIDs != null) {
      if (this.listOfCategoryIDs.length > 0) {
        this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
      }
    }
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    if (this.listOfCategoryIDs.length == 0) {
      this.dropdownDateSubCategoryFilter = [];
      this.selectedItemsSubCategory = [];
      this.listOfSubCategoryIDs = [];
    }
    else
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onSelectAllCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onDeSelectAllCategory($event) {
    this.listOfCategoryIDs = [];
    this.listOfSubCategoryIDs = [];
    this.selectedItemsSubCategory = [];
    this.dropdownDateSubCategoryFilter = [];
    this.selectedItemsCategory = []
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

 
  onSelectSubCategory($event) {
    this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event.id)
    this.listOfSubCategoryIDs.push($event.id);
    this.GetContent();
  }

  onDeSelectSubCategory($event) {
    if (this.listOfSubCategoryIDs != null) {
      if (this.listOfSubCategoryIDs.length > 0) {
        this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event.id)
      }
    }
    this.GetContent();
  }

  onSelectAllSubCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onDeSelectAllSubCategory($event) {
    this.listOfSubCategoryIDs = [];
    this.GetContent();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetContent();
  }


  GetContent() {
    this.colexpandText="Expand All";
    if (this.listOfStateIds.length == 0 && this.listOfCarrierIDs.length == 0 && this.listOfCategoryIDs.length == 0 && this.listOfSubCategoryIDs.length == 0) {
      this.listofContent=[];
      return;
    }

    this.isloading = true;

    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    
    this.carrierId = ' ';
    if (this.listOfCarrierIDs.length > 0) {
      this.carrierId = this.listOfCarrierIDs.join(',')
    }

    this.categoryId = ' ';
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }

    this.subCategoryId = ' ';
    if (this.listOfSubCategoryIDs.length > 0) {
      this.subCategoryId = this.listOfSubCategoryIDs.join(',')
    }
    
    this.crraContentMaster.GetAllCompareViewByUser(this.tokenKey, this.stateId, this.carrierId, this.categoryId,this.subCategoryId,parseInt(this._userID),this.SearchItem,this.displayFavorite).subscribe(
      data => {
        this.isloading = true;
        this.listofContent = data['crracontent'];
        if (this.listofContent != null) {
          if (this.listofContent.length == 0) {
            this.isRecord = true;
            this.isloading = false;
          }
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
            this.isloading = false;
          }
        }
        else
          this.isRecord = true;
        this.isloading = false;
      },
      error => { this.stateCode = error }
    );
  }


  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else this.Note = "null";

    this.GetContent();
  }

  clear() {

    this.stateId = '';
    this.carrierId = '';
    this.categoryId = '';
    this.subCategoryId='';

    this.selectedItemsState = [];
    this.selectedItemsCarrier = [];
    this.selectedItemsCategory = [];
    this.selectedItemsSubCategory=[];
    this.listOfSubCategoryIDs = [];
    this.listOfStateIds = [];
    this.listOfCarrierIDs = [];
    this.listOfCategoryIDs = [];
    this.Note = "";
    this.SearchItem = "";

    if (this.stateList.length > 0) {
      this.statefilterData = this.stateList;
      if (this.statefilterData.length > 0) {
        this.stateId = " ";
        this.statefilterData.forEach(x => {
          this.listOfStateIds.push(x.stateId);
          this.selectedItemsState.push({ "id": x.stateId, "itemName": x.name })
        });
        this.stateId = this.listOfStateIds.join(',')
        this.GetContent();
      }
    }
  }

  validExport(id) {
    if (this.CRRAexportCitiationList.length > 0) {
      let data = this.CRRAexportCitiationList.find((x) => x.exportCitiationId == id);
      if (data != null) return true;
      else return false
    }
    else return false
    // return true;
  }

  getCanDownload() {
    this.userId = Number(this._userID);
    this.companyService.GetCompanybyUserId(this.tokenKey, this.userId).subscribe(
      data => {
        if (data != null) {
          if (data['result'] > 0) {
            this.canDownload = true;
          }
          else
            this.canDownload = false;
        }
      },
      error => { }
    );
  }

  createMyPdf() {
    this.showcollapse();
    this.blockDocument();

    $(".collapse").removeClass("hide")
      $(".collapse").addClass("show");
      this.colexpandText="Collapse All";
      this.showhidecollpase = true;
      if(this.listofContent.length>0)
      {
        for (let iCounter = 0; iCounter < this.listofContent.length; iCounter++) {
          var id='sign-'+iCounter;
      document.getElementById(id).setAttribute('aria-expanded', 'true');
        }
      }
      
    let mainURL = this.baseUrl;
    let url = '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> '
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';
    
    var html = url + document.getElementById('divPrint').innerHTML;
    this.htmlToPdf.CreatePDF(this.tokenKey, html, '0',ToolEnum.crra).
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.downloadFile('RulesDetails.pdf', data['url'], data['fileName']);
          this.deletecloseBtn.nativeElement.click();
        }
      },
        error => {
          this.unblockDocument();
        }
      );

  }
  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        ApplicationActivityStore('Download', 'CRRA File download ' + filename, this.appActivity, ToolEnum.crra, ActionActivity.CRRA, '/View/rulesView');
        sessionStorage.setItem('fileloading', 'false');
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  
  ShowHideCollapse() {
    this.fundCount = 0;
    if (this.listofContent.length > 0) {
      this.fundCount = this.fundCount + this.listofContent[this.listofContent.length - 1].fundCount;
    }
    if (this.showhidecollpase) {
      $(".collapse").removeClass("show")
      $(".collapse").addClass("hide")
      this.showhidecollpase = false;
      this.colexpandText = "Expand All";
      this.isCollapsed = true;
      if (this.listofContent.length > 0) {
        for (let iCounter = 0; iCounter < this.listofContent.length; iCounter++) {
          var statesignid = 'sign-' + iCounter;
          document.getElementById(statesignid).setAttribute('aria-expanded', 'false');
          for (let cnt = 0; cnt < this.listofContent[iCounter].fundCount; cnt++) {
            var fundsignid = 'sign1-' + iCounter + '-' + cnt;
            if (document.getElementById(fundsignid) != null)
              document.getElementById(fundsignid).setAttribute('aria-expanded', 'false');
          }
        }
      }
    }
    else {
      $(".collapse").removeClass("hide")
      $(".collapse").addClass("show");
      this.colexpandText = "Collapse All";
      this.showhidecollpase = true;
      this.isCollapsed = false;
      if (this.listofContent.length > 0) {
        for (let iCounter = 0; iCounter < this.listofContent.length; iCounter++) {
          var statesignid = 'sign-' + iCounter;
          document.getElementById(statesignid).setAttribute('aria-expanded', 'true');
          for (let cnt = 0; cnt < this.listofContent[iCounter].fundCount; cnt++) {
            var fundsignid = 'sign1-' + iCounter + '-' + cnt;
            if (document.getElementById(fundsignid) != null)
              document.getElementById(fundsignid).setAttribute('aria-expanded', 'true');
          }
          
        }
        
      }
    }
  }

  showcollapse() {
    $(".collapse").removeClass("hide")
    $(".collapse").addClass("show");
    this.colexpandText = "Collapse All";
    this.showhidecollpase = true;
    this.isCollapsed = false;
    if (this.listofContent.length > 0) {
      for (let iCounter = 0; iCounter < this.listofContent.length; iCounter++) {
        var statesignid = 'sign-' + iCounter;
        document.getElementById(statesignid).setAttribute('aria-expanded', 'true');
        for (let cnt = 0; cnt < this.listofContent[iCounter].fundCount; cnt++) {
          var fundsignid = 'sign1-' + iCounter + '-' + cnt;
          if (document.getElementById(fundsignid) != null)
            document.getElementById(fundsignid).setAttribute('aria-expanded', 'true');
        }
      }
    }
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  openModel(content, item) {
    this.stateLinkData = null;
    this.isupdatesloading = false;
    this.stateLinkData = item;
    this.unblockDocument();
    var elem = document.getElementById("validationModal");
    elem.style.display='none'
    this.modalService.open(content, { centered: true });
    setTimeout(() => {
      this.isupdatesloading = false;
    }, 2000);
  }
  citationUrl(item) {
    if (item.url) {
      window.open(item.url, '_blank')
    }
  }

  
  AddToFavourite(item, IsFavourite: number) {
    let stateId = item.stateID;
    if (IsFavourite == 0) {
      this.SelectedStateFav = stateId;
      this.favouriteStateModel = item;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavoriteState(stateId, IsFavourite, item);
    }
  }

  SaveFavoriteState(stateId: string, IsFavourite: number, item)
  {
    this.blockDocument();
    //document.getElementById("btnClose").click();
    this.favorite.ClientStateFavouriteAdd(this.tokenKey, parseInt(this._userID), stateId, IsFavourite, ToolEnum.crra).subscribe(data => 
    {
      if (data["status"] == "Success") 
      {
        this.GetContent();
        if (IsFavourite == 1) {
          this.showMessage('Selected jurisdiction added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          this.showMessage('Selected jurisdiction removed successfully from favorite list.', 'Favorite', 'success');
        }        
      }
      else 
      {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
      }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
  }

  GetFavourite(displayFav:number)
  {
    this.displayFavorite=displayFav;
    this.GetContent();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == "success") {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    } else if (msgType == "error") {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    } else if (msgType == "warning") {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  replaceCommaLines(data) {
    let str = '';
    let dataToArray = data.split(',').map(item => item.trim());
    // let list = data.split(",").join("<br />")
    dataToArray.forEach((element, index) => {

      if ((dataToArray.length - 1) == index) {
        str = str + element;
      }
      else {
        if (((index + 1) % 3) == 0) {
          str = str + element + "<br />";
        }
        else { str = str + element + ','; }
      }
    });

    return str
  }
  print() {
    this.showcollapse();
    ApplicationActivityStore('Download', 'CRRA Rules Print', this.appActivity, ToolEnum.crra, ActionActivity.CRRA, '/View/rulesView');
    let mainURL = this.baseUrl;
    let url = '<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';
    let printContents, popupWin;
    printContents = url + document.getElementById('divPrint').innerHTML;
    popupWin = window.open('', 'PrintWindow', 'width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>        
      </head>
      <body onload="window.print();window.close()">${printContents}</body>
    </html>`
    );
    popupWin.document.close();

  }

  onFilterSelectAll($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onFilterDeSelectAll($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetContent();
  }

  onFilterSelectAllCarrier($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onFilterDeSelectAllCarrier($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
        }
      }
    }
    this.GetContent();
  }

  onFilterSelectAllCategory($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onFilterDeSelectAllCategory($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
        }
      }
    }
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    this.GetSubCategoryByCategoryId(this.categoryId);
    this.GetContent();
  }

  onFilterSelectAllSubCategory($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onFilterDeSelectAllSubCategory($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
        }
      }
    }
    this.GetContent();
  }

  print1() {
    this.showcollapse();
    this.blockDocument();

    $(".collapse").removeClass("hide")
      $(".collapse").addClass("show");
      this.colexpandText="Collapse All";
      this.showhidecollpase = true;
      if(this.listofContent.length>0)
      {
        for (let iCounter = 0; iCounter < this.listofContent.length; iCounter++) {
          var id='sign-'+iCounter;
      document.getElementById(id).setAttribute('aria-expanded', 'true');
        }
      }
      
    let mainURL = this.baseUrl;
    let url = '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> '
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';
    
    var html = url + document.getElementById('divPrint').innerHTML;
    this.htmlToPdf.CreatePDF(this.tokenKey, html, '0',ToolEnum.crra).
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.PrintPreviewPDF(data['url'],data['fileName']);
          this.deletecloseBtn.nativeElement.click();
        }
      },
        error => {
          this.unblockDocument();
        }
      );

  }

  PrintPreviewPDF(PDFPath:string,orignalFileName:any)
  {
    this.htmlToPdf.downloadPdf(PDFPath).subscribe(
      (res) => {
        this.blockDocument();
        // FileSaver.saveAs(res, filename);
        const blobUrl = window.URL.createObjectURL((res));
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
    this.unblockDocument();
  }
}
