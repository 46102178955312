export class PriceCalculationModel {
    PriceQuoteId?:number;
    clientInformationId?: number;
    totalComplianceReportingRateQuote?:number=0;
    complianceReportingRateQuote?:number=0;
    annualComplianceAndSOSQuote?:number=0;
    totalPerusalPricingWithTax?:number=0;
    transactionalTaxPricing?:number=0;
    otherRegulatoryPricing?:number=0;
    solutionsPricing?:number=0;
    applicableDiscount?:number=0;
    totalSOSReportingRateQuote?:number=0;
    companyDiscount?:number=0;
    monthlyComplianceAndSOSQuote?:number=0;
    monthlyComplianceSosTaxSolutionsAndRegulatoryQuote?:number=0;
    isCompanyDiscount?:boolean=false;
    isRegulatoryReporting?:boolean;
}

export class MarketEntryDataModel{
    marketEntryId?:number
    stateName:string
    stateId?:number
    soS_Service_Fees?:number
    dbA_Service_Fees?:number
    soS_Corp_Filling_Fees?:number
    soS_LLC_Filing_Fees?:number
    dbA_Filing_Fees?:number
    registered_Agent_Fees?:number
    puC_Service_Fees?:number
    psC_FilingsAndPublication_Fees?:number
    local_Counsel_Fees?:number
    // dbA_Inteserra_Fee?:number
    // dbaFillingFees?:number
    dbA_RA_Fees?:number
    total?:number
    bond?:number
    puC_Filing_Fees?: number
    notes:string
    soS_Service_Fees_isSelected: boolean
    dbA_Service_Fees_isSelected: boolean
    soS_Corp_Filling_Fees_isSelected: boolean
    soS_LLC_Filing_Fees_isSelected: boolean
    dbA_Filing_Fees_isSelected: boolean
    registered_Agent_Fees_isSelected: boolean
    puC_Service_Fees_isSelected: boolean
    psC_FilingsAndPublication_Fees_isSelected: boolean
    local_Counsel_Fees_isSelected: boolean
    dbA_Inteserra_Fee_isSelected: boolean
    dbAFillingFees_isSelected: boolean
    dbA_RA_Fees_isSelected: boolean
    bond_isSelected: boolean
    puC_Filing_Fees_isSelected: boolean
    soS_Service_Fees_NAisSelected: boolean
    dbA_Service_Fees_NAisSelected: boolean
    soS_Corp_Filling_Fees_NAisSelected: boolean
    soS_LLC_Filing_Fees_NAisSelected: boolean
    dbA_Filing_Fees_NAisSelected: boolean
    registered_Agent_Fees_NAisSelected: boolean
    puC_Service_Fees_NAisSelected: boolean
    psC_FilingsAndPublication_Fees_NAisSelected: boolean
    local_Counsel_Fees_NAisSelected: boolean
    dbA_Inteserra_Fee_NAisSelected: boolean
    dbAFillingFees_NAisSelected: boolean
    dbA_RA_Fees_NAisSelected: boolean
    bond_NAisSelected: boolean
    puC_Filing_Fees_NAisSelected: boolean
    soS_Service_Fees_Changed?: boolean
    dbA_Service_Fees_Changed?: boolean
    soS_Corp_Filling_Fees_Changed?: boolean
    soS_LLC_Filing_Fees_Changed?: boolean
    dbA_Filing_Fees_Changed?: boolean
    registered_Agent_Fees_Changed?: boolean
    registered_Agent_DBA_Fees_Changed?: boolean
    puC_Service_Fees_Changed?: boolean
    puC_Filing_Fees_Changed?: boolean
    publicationFees_Changed?: boolean
    local_Counsel_Fees_Changed?: boolean
    bond_Changed?: boolean
    createdBy?:number
    userName?:string
    createdDate?:Date
}