import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';
import { FavoriteService } from 'src/Services/favorite.service';
import { CheckAuth } from 'src/Services/utility.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import * as FileSaver from 'file-saver';
import { I360Service } from 'src/Services/i360Service';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css']
})
export class ChecklistComponent implements OnInit {

  stateCode: any;
  _userID: string = "";
  ProductId: number = 0;

  //Carrier List
  dropdownListCarrier = [];
  selectedItemsCarrier = [];
  dropdownSettingsCarrier = {};
  listOfCarrierIds = [];
  carrierId: string = ' ';
  carrierTypeList: any[] = [];
  listOfCarrierName = [];

  //CategoryType
  dropdownSettingsCategoryFilter = {};
  dropdownCategoryFilter = [];
  selectedCategoryItems = [];
  listOfCategoryTypeIDs = [];
  categoryTypeList: any[] = [];
  listOfCategoryTypeName = [];
  categoryId: string = ' ';

  tokenKey: string;
  SearchItem: string = '';
  fromDate: string = '';
  toDate: string = '';

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;

  rangeOfupdatesDates: any;

  upDowCarrierNameCSS: string = '';
  upDowDueDateCSS: string = '';
  upDowTMICSS: string = '';
  upDowCFRDefinitionCSS: string = '';
  upDowCFRCSS: string = '';

  isAscending: boolean = true;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  OrderByName: string = '';


  dropdownDateSelectedColumnFilter = [];
  selectedColumn = [];
  listOfColumnId = [];
  dropdownSettingsDateselectedColumnFilter = {};

  blockedDocument = false;

  isShowprogressSpinner = false;
  listofObligation: any=[]
  listofObligationExport: any=[]

  isColumnExist: boolean = true;
  listofMain911PrintData: any[] = [];
  quaryValues: string = "";
  upDowtitleCSS: string = '';
  upDowcfrCSS:string='';

  @ViewChild("ObligationModel", { static: false }) ObligationModel: ElementRef;
  obligationTitle:string='';
  obligationDetails:string='';
  pendingactivity:string='';
  applicableto:string='';
  keyterms:string='';
  timeframe:string='';
  internalnotes:string='';
  knownrelatedforfeitures:string='';
  cfrdetails:string='';
  cfrurl:string='';
  cfrsection:string='';
  ch:string='';
  chSub:string='';
  part:string='';
  subPart:string='';


  IsInitial:boolean=false;
  IsMaintenance:boolean=false;
  IsRecurring:boolean=false;
  IsFee:boolean=false;
  IsOperational:boolean=false;
  Isnottracked:boolean=false;
  displayFavorite:number=0;
  INTERNALINTESERRAUSERS:boolean=true;
  carriertype:string="";
  oblicationtype:string="";

  InitialHoverText:string='';
  MaintenanceHoverText:string='';
  NottrackedHoverText:string='';
  OperationalHoverText:string='';
  RecurringHoverText:string='';
  FeeHoverText:string='';

  constructor(private appActivity: ApplicationActivityService, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private activedRoute: ActivatedRoute, private toastr: ToastrService,
    private favorite: FavoriteService, private carrierObligationService: CarrierObligationService,private i360service: I360Service) {

    let Inteseraemailid= localStorage.getItem('useremail');

    if (Inteseraemailid != '' && Inteseraemailid != null) {
      if (Inteseraemailid.indexOf('@inteserra.com') !== -1) {
        this.INTERNALINTESERRAUSERS = true;
      }
      else {
        this.INTERNALINTESERRAUSERS = false;

        this.router.navigate(['/View/View47']);
      }
    }
    else {
      this.INTERNALINTESERRAUSERS = false;
      this.router.navigate(['/View/View47']);
    }

    this.tokenExpireCalculation(
      sessionStorage.getItem("appStartTime"),
      sessionStorage.getItem("tokenExpiryValue")
    );

    this._userID = sessionStorage.getItem('UserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    this.OrderByName = 'title,cfr';

    if (this.SelectedTool == 'view 47') {
      this.SelectedTool = 'view47'
    }

    sessionStorage.setItem('tokenValue', localStorage.getItem('tokenValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.ProductId = ToolEnum.View47;
    this.titleService.setTitle('Checklist');
    this.tokenKey = sessionStorage.getItem("tokenValue");
    this.OrderBy = 'cfr';
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.dropdownSettingsCarrier = {
      singleSelection: false,
      text: "Filter by Carrier(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Carrier Type(s) available",
      showCheckbox: true,
    };

    this.dropdownSettingsCategoryFilter = {
      singleSelection: false,
      text: "Filter by Obligation Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Category Type(s) available',
      showCheckbox: true
    };

    this.getCarrier();
    this.getCategoryType();
    this.GetObligation();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else this.Note = null;

    this.GetObligation();
  }

  getCarrier() {
    this.carrierObligationService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierTypeList = result['carrier'];
          this.dropdownListCarrier = [];
          this.carrierTypeList.forEach(x => {
            this.dropdownListCarrier.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCarrier($event) {
    this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event.id)
    this.listOfCarrierIds.push($event.id);
    this.carrierId = ' ';
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetObligation();
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIds != null) {
      if (this.listOfCarrierIds.length > 0) {
        this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event.id)
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetObligation();
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
          this.listOfCarrierIds.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetObligation();
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIds = [];
    this.resetPageNoandSize();
    this.GetObligation();
  }

  onFilterSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
          this.listOfCarrierIds.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetObligation();
  }

  onFilterDeSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetObligation();
  }

  getCategoryType() {
    this.carrierObligationService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryTypeList = result['category'];

          if(this.categoryTypeList.length>0){
            for(let i=0;i<this.categoryTypeList.length;i++){
              if (this.categoryTypeList[i].categoryName == "Initial") {
                this.InitialHoverText = this.categoryTypeList[i].mouseoverText;
              }
              else if (this.categoryTypeList[i].categoryName == "Maintenance") {
                this.MaintenanceHoverText = this.categoryTypeList[i].mouseoverText;
              }
              else if (this.categoryTypeList[i].categoryName == "Not tracked") {
                this.NottrackedHoverText = this.categoryTypeList[i].mouseoverText;
              }
              else if (this.categoryTypeList[i].categoryName == "Operational") {
                this.OperationalHoverText = this.categoryTypeList[i].mouseoverText;
              }
              else if (this.categoryTypeList[i].categoryName == "Recurring") {
                this.RecurringHoverText = this.categoryTypeList[i].mouseoverText;
              }
              else if (this.categoryTypeList[i].categoryName == "Fee/Contribution") {
                this.FeeHoverText = this.categoryTypeList[i].mouseoverText;
              }
            }
          }

          this.dropdownCategoryFilter = [];
          this.categoryTypeList.forEach(x => {
            this.dropdownCategoryFilter.push({ "id": x.categoryTypeId, "itemName": x.categoryName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCategory($event) {
    this.listOfCategoryTypeIDs = this.removeElemetninArray(this.listOfCategoryTypeIDs, $event.id)
    this.listOfCategoryTypeName = this.removeElemetninArray(this.listOfCategoryTypeName, $event.itemName)
    this.listOfCategoryTypeIDs.push($event.id);
    this.listOfCategoryTypeName.push($event.itemName);

    this.GetObligation();
  }

  onDeSelectCategory($event) {

    if (this.listOfCategoryTypeIDs != null) {
      if (this.listOfCategoryTypeIDs.length > 0) {
        this.listOfCategoryTypeIDs = this.removeElemetninArray(this.listOfCategoryTypeIDs, $event.id)
      }
    }

    if (this.listOfCategoryTypeName != null) {
      if (this.listOfCategoryTypeName.length > 0) {
        this.listOfCategoryTypeName = this.removeElemetninArray(this.listOfCategoryTypeName, $event.itemName)
      }
    }

    this.GetObligation();
  }

  onSelectAllCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryTypeIDs != null) {
        if (this.listOfCategoryTypeIDs.length > 0) {
          this.listOfCategoryTypeIDs = this.removeElemetninArray(this.listOfCategoryTypeIDs, $event[iCounter].id)
          this.listOfCategoryTypeIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryTypeIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCategoryTypeName != null) {
        if (this.listOfCategoryTypeName.length > 0) {
          this.listOfCategoryTypeName = this.removeElemetninArray(this.listOfCategoryTypeName, $event[iCounter].itemName)
          this.listOfCategoryTypeName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCategoryTypeName.push($event[iCounter].itemName);
        }
      }
    }

    this.GetObligation();
  }

  onDeSelectAllCategory($event) {
    this.listOfCategoryTypeIDs = [];
    this.listOfCategoryTypeName = [];
    this.GetObligation();
  }

  GetObligation() {
    this.isloading = true;
    this.categoryId = ' ';
    if (this.listOfCategoryTypeIDs.length > 0) {
      this.categoryId = this.listOfCategoryTypeIDs.join(',')
    }

    this.carrierId = ' ';
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }

    if (this.Note == "null" || this.Note == "undefined") this.Note = null;
    ApplicationActivityStore('View', 'View 47 Checklist', this.appActivity, ToolEnum.View47, ActionActivity['View 47'], '/View/checklist');

    this.carrierObligationService.GetChecklistInternalUser(this.tokenKey, this.PageNumber, this.PageSize, this.OrderBy, parseInt(this._userID),this.categoryId, this.Note,this.carrierId,this.displayFavorite,false).subscribe(
      data => {
        this.isloading = false;
        this.listofObligation = [];
        data['obligation'].map(val => {

          let category = val.categoryTypeName.split(',');
          let Initial = category.find(e => e === 'Initial') == undefined ? false : true;
          let Maintenance = category.find(e => e === 'Maintenance') == undefined ? false : true;
          let Recurring = category.find(e => e === 'Recurring') == undefined ? false : true;
          let Fee = category.find(e => e === 'Fee/Contribution') == undefined ? false : true;
          let Operational = category.find(e => e === 'Operational') == undefined ? false : true;
          let Nottracked = category.find(e => e === 'Not tracked') == undefined ? false : true;

          let Obligation: any = {
            carrierName: val.carrierTypeName,
            carrierTypeId: val.carrierTypeId,
            subCarriertype: val.subCarriertype,
            categoryName: category,
            categotyTypeId: val.categotyTypeId,
            title: val.title,
            details: val.details,
            pendingactivity: val.pendingactivity,
            dueDate: val.dueDate,
            initial: Initial,
            maintenance: Maintenance,
            recurring: Recurring,
            fee: Fee,
            operational: Operational,
            nottracked: Nottracked,
            internalnotes: val.internalnotes,
            keyterms: val.keyterms,
            relatedforfeitures: val.relatedforfeitures,
            cfr: val.cfr,
            url:val.url,
            ch:val.ch,
            subch:val.subCh,
            part:val.part,
            subpart:val.subPart,
            cfrsection:val.cfrSection,
            publishdate:val.publishdate,
            obligationId: val.obligationId,
            totalRecordCount: val.totalRecordCount,
          }

          this.listofObligation.push(Obligation);

        });
        if (this.listofObligation != null) {
          if (this.listofObligation.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofObligation[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  replaceCommaLines(data) {
    let str = '';
    let dataToArray = data.split(',').map(item => item.trim());
    dataToArray.forEach((element, index) => {
      str = str + element + "<br />";
    });

    return str
  }

  viewObligation(item){
    
    ApplicationActivityStore('View', 'View 47 Checklist', this.appActivity, ToolEnum.View47, ActionActivity['View 47'], item.title);

    this.ObligationModel.nativeElement.click();
    this.obligationTitle=item.title;
    this.obligationDetails=item.details;
    this.cfrdetails=item.cfr;
    this.cfrurl=item.url;
    this.pendingactivity=item.pendingactivity;
    if(item.carrierName!='' && item.carrierName!=null){
      this.applicableto=item.carrierName.split(',').join(', ')
    }
    else{
      this.applicableto=item.carrierName;
    }
    
    this.keyterms=item.keyterms;
    this.timeframe=item.dueDate;
    this.internalnotes=item.internalnotes;
    this.knownrelatedforfeitures=item.relatedforfeitures;
    this.cfrsection=item.cfrsection;
    this.ch=item.ch;
    this.chSub=item.subch;
    this.part=item.part;
    this.subPart=item.subpart;

    if (item.initial) {
      this.IsInitial = true;
    }
    else {
      this.IsInitial = false;
    }

    if (item.maintenance) {
      this.IsMaintenance = true;
    }
    else {
      this.IsMaintenance = false;
    }

    if (item.recurring) {
      this.IsRecurring = true;
    }
    else {
      this.IsRecurring = false;
    }

    if (item.fee) {
      this.IsFee = true;
    }
    else {
      this.IsFee = false;
    }

    if (item.operational) {
      this.IsOperational = true;
    }
    else {
      this.IsOperational = false;
    }

    if (item.convenienceonly) {
      this.Isnottracked = true;
    }
    else {
      this.Isnottracked = false;
    }
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDowtitleCSS='';

     
      if (sortBy === "title") {
        this.upDowtitleCSS = 'active';
        this.upDowcfrCSS='';
      }
      else if (sortBy === "cfr") {
        this.upDowtitleCSS = '';
        this.upDowcfrCSS='active';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "title") {
        this.upDowtitleCSS = 'active-up';
        this.upDowcfrCSS = '';
      }
      else if (sortBy === "cfr") {
        this.upDowtitleCSS = '';
        this.upDowcfrCSS = 'active-up';
      }
    }

    this.OrderByName = sortBy;

    this.resetPageNoandSize();
    this.GetObligation();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  getPageLength() {
    return Math.min((this.PageNumber * this.PageSize),this.totalRecordCount);
  }


  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    ApplicationActivityStore('View', 'View 47 Checklist', this.appActivity, ToolEnum.View47, ActionActivity['View 47'], '/View/checklist');
    this.GetObligation();
  }

  clear() {
    this.resetPageNoandSize();
    this.selectedCategoryItems = [];
    this.listOfCategoryTypeIDs=[];

    this.selectedItemsCarrier = [];
    this.listOfCarrierIds=[];

    this.Note=undefined;
    this.SearchItem="";
    this.GetObligation();
  }

  GetFavourite(displayFav:number){
    this.displayFavorite=displayFav;
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetObligation();
  }


  exportChecklist(){
    this.isloading = true;
    this.blockedDocument = true;
    this.categoryId = ' ';
    if (this.listOfCategoryTypeIDs.length > 0) {
      this.categoryId = this.listOfCategoryTypeIDs.join(',');

      this.oblicationtype='';

      for (let s = 0; s < this.listOfCategoryTypeIDs.length; s++) {
        if (this.dropdownCategoryFilter.length > 0) {
          for (let i = 0; i < this.dropdownCategoryFilter.length; i++) {
            if(this.listOfCategoryTypeIDs[s]==this.dropdownCategoryFilter[i].id){
              this.oblicationtype+=this.dropdownCategoryFilter[i].itemName+", ";
            }
          }
        }
      }

      if (this.oblicationtype != '') {
        this.oblicationtype = this.oblicationtype.slice(0, -2);
      }
    }

    if(this.listOfCategoryTypeIDs.length==0){
      this.oblicationtype='';
    }

    this.carrierId = ' ';
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',');

      this.carriertype='';

      for (let s = 0; s < this.listOfCarrierIds.length; s++) {
        if (this.dropdownListCarrier.length > 0) {
          for (let i = 0; i < this.dropdownListCarrier.length; i++) {
            if(this.listOfCarrierIds[s]==this.dropdownListCarrier[i].id){
              this.carriertype+=this.dropdownListCarrier[i].itemName+", ";
            }
          }
        }
      }
      if (this.carriertype != '') {
        this.carriertype = this.carriertype.slice(0, -2);
      }
    }

    if(this.listOfCarrierIds.length==0){
      this.carriertype='';
    }

    if (this.Note == "null" || this.Note == "undefined") this.Note = null;
    ApplicationActivityStore('Export', 'View 47 Checklist', this.appActivity, ToolEnum.View47, ActionActivity['View 47'], '/View/checklist');

    this.carrierObligationService.GetChecklistInternalUser(this.tokenKey, this.PageNumber, this.PageSize, this.OrderBy, parseInt(this._userID),this.categoryId, this.Note,this.carrierId,this.displayFavorite,true).subscribe(
      data => {
        this.isloading = false;
        this.listofObligationExport = [];
        data['obligation'].map(val => {

          let category = val.categoryTypeName.split(',');
          let Initial = category.find(e => e === 'Initial') == undefined ? false : true;
          let Maintenance = category.find(e => e === 'Maintenance') == undefined ? false : true;
          let Recurring = category.find(e => e === 'Recurring') == undefined ? false : true;
          let Fee = category.find(e => e === 'Fee/Contribution') == undefined ? false : true;
          let Operational = category.find(e => e === 'Operational') == undefined ? false : true;
          let Nottracked = category.find(e => e === 'Not tracked') == undefined ? false : true;

          let Obligation: any = {
            carrierName: val.carrierTypeName,
            carrierTypeId: val.carrierTypeId,
            subCarriertype: val.subCarriertype,
            categoryName: category,
            categotyTypeId: val.categotyTypeId,
            title: val.title,
            details: val.details,
            pendingactivity: val.pendingactivity,
            dueDate: val.dueDate,
            initial: Initial,
            maintenance: Maintenance,
            recurring: Recurring,
            fee: Fee,
            operational: Operational,
            nottracked: Nottracked,
            internalnotes: val.internalnotes,
            keyterms: val.keyterms,
            relatedforfeitures: val.relatedforfeitures,
            cfr: val.cfr,
            url:val.url,
            ch:val.ch,
            subch:val.subCh,
            part:val.part,
            subpart:val.subPart,
            cfrsection:val.cfrSection,
            publishdate:val.publishdate,
            obligationId: val.obligationId,
            totalRecordCount: val.totalRecordCount,
          }

          this.listofObligationExport.push(Obligation);

        });
       
        this.exportToExcel();

      },
      error => { this.stateCode = error }
    );
  }


  exportToExcel() {
    let tableData = document.getElementById("checklistexport").innerHTML;

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }    
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";    
    let name = 'CheckList';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "CheckList.xls");
  }

  cfrLinkView(linkurl){
    ApplicationActivityStore('Click Link', 'View 47 Checklist', this.appActivity, ToolEnum.View47, ActionActivity['View 47'], linkurl);
  }
}
