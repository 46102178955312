export class LibraryReminderModel {
    libraryReminderId?: any;
    userId?: any;
    libraryId?: any;
    reminderDate?: any;
    isEmailSent?: any;
    recurEvery?: any;
    reminderType?: any;
    createdBy?: any;
    createdTime?: any;
    modifiedBy?: any;
    modifiedTime?: any;
    name?: any;
}