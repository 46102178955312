import { CarrierCategoryAdminModel } from "./CarrierCategoryAdminModel";
import { ExportCitiationAdminModel, StateMasterModel } from "./stateMasterModel";
import { SpecialReportModel } from "./SpecialReportModel";
import { TrainingModel } from "./TrainingModel";
import { WebinarLibraryModel } from "./WebinarLibraryModel";
import { WhitePapersModel } from "./WhitePapersModel";
import { TaxExemptionFormModel } from "./TaxExemptionFormsModel";
import { InteserraResourcesModel } from "./InteserraResourcesModel";

export class UserModel {
    userId: string;
    userName: string;
    password?: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName?: string;
    city?: string;
    state?: string;
    phone: string;
    userTypeId?: string;
    isActive: string;
    lastLogin?: string;
    createdDate?: string;
    createdBy?: string;
    modifiedDate?: string;
    modifiedBy?: string;
    wirelessStates?: StateMasterModel[];
    voIPStates?: StateMasterModel[];
    iprStates?: StateMasterModel[];
    actionStates?: StateMasterModel[];
    subscriptionStateName?: string;
    subscriptionCount?: string;
    token?: string;
    companyId?: string;
    isICalSubscription?: string;
    encyUserId?: string;
    deadlineDateFromAction?: string;
    deadlineDateFromUpdate?: string;
    adminTypeId?: number;
    adminUserName?: string;
    adminUserId?: number;
    briefingStates?: StateMasterModel[];
    carrierCategory?: CarrierCategoryAdminModel[];
    userLoginUrl?: string;
    userLoginUrli360?: string;
    createPassword?: boolean;
    isWirelessPrimaryContact?: boolean = false;
    isIPRPrimaryContact?: boolean = false;
    isVoIPPrimaryContact?: boolean = false;
    isTractItPrimaryContact?: boolean = false;
    isBriefingPrimaryContact?: boolean = false;
    isTrainingPrimaryContact?: boolean = false;
    isSpecialReportPrimaryContact?: boolean = false;
    training?: TrainingModel[];
    state911?: StateMasterModel[];
    exportCitiation?: ExportCitiationAdminModel[];
    TRFAexportCitiation?: ExportCitiationAdminModel[];
    CRRAexportCitiation?: ExportCitiationAdminModel[];
    specialReport?: SpecialReportModel[];
    webinars?: WebinarLibraryModel[];
    inteserraResources?: InteserraResourcesModel[];
    whitePapers?: WhitePapersModel[];
    stateTRFA?: StateMasterModel[];
    stateCRRA?: StateMasterModel[];
    taxPortalPassword?:string;
    taxPortalUsername?:string;
    isSendEmail?: string;
    title?:string;
    dba?:string;
    interestProduct?:string;
    taxExemptionForm?:TaxExemptionFormModel[]
    actualUserName?:string;
}
