import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebinarLibraryService {
  AppUrl: string = "";
  constructor(private _http: Http, private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  i360WebinarLibraryAddUpdate(TokenKey: string, webinarLibraryModel: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/WebinarLibrary/i360WebinarLibraryAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, webinarLibraryModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360WebinarLibraryGet(TokenKey: string, filterModel: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/WebinarLibrary/i360WebinarLibraryGet';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360WebinarLibraryFindbywebinarLibraryId(TokenKey: string, webinarLibraryId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/WebinarLibrary/i360WebinarLibraryFindbywebinarLibraryId';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, webinarLibraryId, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360WebinarLibraryDelete(TokenKey: string, webinarLibraryId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/WebinarLibrary/i360WebinarLibraryDelete';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, webinarLibraryId, httpOptions)
      .pipe(catchError(this.handleError));
  }
  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  i360WebinarSectionListWithIds(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/WebinarLibrary/i360WebinarSectionListWithIds';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.get<any>(this.AppUrl + data, httpOptions).pipe(catchError(this.handleError));
  }

  i360WebinarUpdate(TokenKey: string, currentIndexid: string, previousIndexid: string): Observable<any[]> {
    this.setAPIUrl();

    let data = 'api/WebinarLibrary/i360WebinarDetailUpdate/' + currentIndexid + '/' + previousIndexid;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
