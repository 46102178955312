import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseRateType } from 'src/Models/Common';
import { PriceCalculationModel } from 'src/Models/PriceCalculationModel';
import { StateCountModel } from 'src/Models/StateCountModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompleted, getIncompletedStep } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';



@Component({
  selector: 'app-price-quotes-calc',
  templateUrl: './price-quotes-calc.component.html',
  styleUrls: ['./price-quotes-calc.component.css']
})
export class PriceQuotesCalcComponent implements OnInit {
  stateCode: any;
  tokenKey: string;
  listofStateCount: StateCountModel[];
  priceCalculation: PriceCalculationModel;
  WhereCondition: string;
  OrderBy: string;

  upDownClientNameCSS: string = '';
  upDownProposelDateCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  clientInformationId: number = 0;
  userID: any;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  applicableDiscount: number = 0;
  companyDiscount: number = 0;
  totalPerusalPriceWithTax: number = 0;
  monthlyComplianceSOSTaxSolutionRegulatoryQuote: number = 0;

  isSubmit: boolean = false;

  isRateDetailsCompleted: boolean;
  isQuoteDetailsCompleted: boolean;
  isAddOnServicesCompleted: boolean;
  isComplianceDetailsCompleted: boolean;
  isProposalDetailsCompleted: boolean;
  currentActiveTab: number = 1;

  url: string;
  tabId: number = 0;

  mainUrl: string;
  subUrl: string;
  priceQuoteId: number = 0;
  companyName: string;
  isCount: boolean = false;

  constructor(private router: Router, private PricingQuotesMaster: PricingQuotesService, private checkAuth: CheckAuth
    , private titleService: Title, private activedRoute: ActivatedRoute, private toastr: ToastrService, private globle: Globle) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.WhereCondition = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    // this.titleService.setTitle('Price Quote');
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.priceCalculation = new PriceCalculationModel;
    this.priceCalculation.isCompanyDiscount = false;
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Price Quote - ' + this.companyName);

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          // this.clientInformationId = params['id'];
          this.priceQuoteId = params['id'];
        }
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.priceQuoteId != null) {
      this.GetProgressStepDetailsById(this.priceQuoteId);
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isRateDetailsCompleted = null
      this.globle.cApp.statusModel.isQuoteDetailsCompleted = null
      this.globle.cApp.statusModel.isAddOnServicesCompleted = null
      this.globle.cApp.statusModel.isComplianceDetailsCompleted = null
      this.globle.cApp.statusModel.isProposalDetailsCompleted = null
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }

  }

  ngOnInit() {
    this.isloading = true;

    this.GetSelectedStateCount();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  pageChanged($event: any) {
    this.GetSelectedStateCount();
  }

  GetSelectedStateCount() {
    this.blockDocument();
    this.PricingQuotesMaster.GetSelectedStateCount(this.tokenKey, this.priceQuoteId, BaseRateType.BaseRate).subscribe(
      data => {
        if (data['status'] = 'Success') {
          this.unblockDocument();
          this.isloading = false;
          this.listofStateCount = data['stateCount'];
          if (this.listofStateCount != null) {
            if (this.listofStateCount.length == 0)
              this.isRecord = true;
            else {
              for (let index = 0; index < this.listofStateCount.length; index++) {
                if (this.listofStateCount[index].stateCount > 0) {
                  this.isCount = true;
                }
              }
              this.isRecord = false;
            }
          }
          this.priceCalculation = data['priceCalculation'];
          this.clientInformationId = this.priceCalculation.clientInformationId;
          this.companyDiscount = this.priceCalculation.companyDiscount;
          this.applicableDiscount = this.priceCalculation.applicableDiscount;
        }
        else {
          this.unblockDocument();
          this.isRecord = false;
        }
      },
      error => { 
      this.unblockDocument();
        this.stateCode = error 
      }

    );

  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  previousStep() {
    let navigation = '/admin/' + this.SelectedTool + '/manage-baserate/' + this.priceQuoteId;
    this.router.navigate([navigation]);
  }

  nextStep() {
    // let navigation = '/admin/' + this.SelectedTool + '/manage-baserate/' + this.clientInformationId;
    // this.router.navigate([navigation]);
    var data = this.priceCalculation;
  }

  save() {
    this.isSubmit = true;
    let data = this.priceCalculation;
    // this.globle.cApp.statusModel.isQuoteDetailsCompleted = false;
    // let value = {
    //   baseRateId: data.baseRateId,
    //   baseRate: data.baseRate.trim(),
    //   rateType: data.rateType,
    //   monthlyRate: data.monthlyRate,
    //   annualRate: data.annualRate,
    //   createdBy: this.userID
    // }
    this.blockDocument();
    this.priceCalculation.clientInformationId = data.clientInformationId;
    this.priceCalculation.PriceQuoteId = this.priceQuoteId;
    this.PricingQuotesMaster.PriceQuoteAddUpdate(this.priceCalculation, this.tokenKey).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isQuoteDetailsCompleted = true;
          //this.showMessage('Price Quote saved successfully', 'Success', 'success');
          this.router.navigate(['/admin/' + this.SelectedTool + '/add-on-services/' + this.priceQuoteId]);
        }
        else {
        this.unblockDocument();
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  ChangeDiscount(newValue) {
    if (newValue > 10) {
      this.showMessage("Please Enter Value less then or equal to 10", "error", "error");
      this.companyDiscount = 0;
      this.applicableDiscount = 0;
    }
    else {
      if (this.priceCalculation != undefined) {
        this.companyDiscount = (this.priceCalculation.totalPerusalPricingWithTax * newValue) / 100;
        this.monthlyComplianceSOSTaxSolutionRegulatoryQuote = ((this.priceCalculation.totalPerusalPricingWithTax + this.priceCalculation.solutionsPricing + this.priceCalculation.otherRegulatoryPricing) - this.companyDiscount) / 12;
        this.priceCalculation.companyDiscount = this.companyDiscount;
        this.priceCalculation.applicableDiscount = newValue;
        this.priceCalculation.monthlyComplianceSosTaxSolutionsAndRegulatoryQuote = Math.round(this.monthlyComplianceSOSTaxSolutionRegulatoryQuote);
      }
    }
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  activeTab(url: string, tabId: number) {

    if (this.priceQuoteId != null) {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsById(this.tokenKey, priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
          this.activeTab('pricing-quotes', 6);
        }
      });
  }
}
