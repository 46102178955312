
export class ClientInformationAttachmentModel {
    clientInfoAttachmentId?: any;
    clientInformationId?: any;
    productCatalogDisplayName: any;
    productCatalogDocName: any;
    taxLiabilityDisplayName: any;
    taxLiabilityDocName: any;
    billedRevenueDisplayName: any;
    billedRevenueDocName: any;
    taxPermitDisplayName: any;
    taxPermitDocName: any;
    createdBy: any;
    createdDate: any;
    updatedBy: any;
    updatedDate: any;
    productCatalogDocPath:any;
    taxLiabilityDocPath:any;
    billedRevenueDocPath :any;
    taxPermitDocPath:any;
    isFront?:any;
    priceQuoteId?:any;
    isProductCatalogRequired?:any;
    isTaxLiabilityRequired?:any;
    isBilledRevenueRequired?:any;
    isTaxPermitRequired?:any;
}