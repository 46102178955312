import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BriefingSubTypeModel } from 'src/Models/BriefingMasterModel';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-briefing-sub-type',
  templateUrl: './briefing-sub-type.component.html',
  styleUrls: ['./briefing-sub-type.component.css']
})
export class BriefingSubTypeComponent implements OnInit {

  tokenKey: string;
  isloading: boolean = false;
  PageNumber: number = 1;
  listofSubTypes: BriefingSubTypeModel[];
  SelectedTool: string;
  PageSize: number;
  isRecord: boolean = false;
  totalRecordCount: number = 0;
  FilterText:string;
  OrderBy: string;
  WhereCondition: string;
  isAscending: boolean = false;
  upDowcarrierCatNameCSS: string = '';
  upDowSortOrderCSS: string = '';
  upDowShortNameCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowIsArchiveCSS: string = '';
  BulletinSubTypeId:number = 0;
  openMenuIndex: number = -1;

  constructor(private i360service: I360Service, private router: Router, private checkAuth: CheckAuth, private toastr: ToastrService, private titleService: Title) { 
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'SortOrder';
    this.FilterText = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Briefing Sub Type');
  }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetAllSubTypes();
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.FilterText = $event.target.value;
      else
      this.FilterText = 'null';

      this.GetAllSubTypes();
  }

   GetAllSubTypes() {
    if (this.FilterText == "null") this.FilterText = " ";
    this.i360service.i360BulletinSubType(this.tokenKey,this.PageNumber, this.PageSize, this.FilterText, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofSubTypes = data['listOfBulletinSubType'];
        if (this.listofSubTypes != null) {
          if (this.listofSubTypes.length == 0)
            this.isRecord = true;
          else {  
            this.isRecord = false;
            this.totalRecordCount = this.listofSubTypes[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => {  }
    );
   }

  addBriefSubType() {
    let navigation = '/admin/' + this.SelectedTool + '/briefingSubType/manage';
    this.router.navigate([navigation]);
  }

  updateBriefSubType(BulletinSubTypeId) {
    let navigation = '/admin/' + this.SelectedTool + '/briefingSubType/manage/' + BulletinSubTypeId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.BulletinSubTypeId = id;
  }

  deleteBrifSubType(BulletinSubTypeId) {
    this.isloading = true;
    this.i360service.DeleteBriefingSubType(this.tokenKey, BulletinSubTypeId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
          this.showMessage("Deleted successfully", 'Success', 'success')
        }
        this.GetAllSubTypes();
      },
      error => { }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetAllSubTypes();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "BulletinSubType") {
        this.upDowcarrierCatNameCSS = 'fa fa-arrow-down';
        this.upDowShortNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = '';
      }
      else if (sortBy === "ShortName") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowShortNameCSS = 'fa fa-arrow-down';
        this.upDowSortOrderCSS = '';
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-down';
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowIsActiveCSS = 'fa fa-arrow-down';
        this.upDowIsArchiveCSS = '';
      }
      else if (sortBy === "IsArchive") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "BulletinSubType") {
        this.upDowcarrierCatNameCSS = 'fa fa-arrow-up';
        this.upDowShortNameCSS = '';
        this.upDowSortOrderCSS = '';      
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = '';
      }
      else if (sortBy === "ShortName") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowShortNameCSS = 'fa fa-arrow-up';
        this.upDowSortOrderCSS = '';
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-up';       
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = ''; 
      }
      else if (sortBy === "IsActive") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortOrderCSS = '';       
        this.upDowIsActiveCSS = 'fa fa-arrow-up';
        this.upDowIsArchiveCSS = ''; 
      }
      else if (sortBy === "IsArchive") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortOrderCSS = '';       
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = 'fa fa-arrow-up'; 
      }
    }
    this.GetAllSubTypes();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
