import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/Services/app.config';
import { CheckAuth } from 'src/Services/utility.service';
import { CategoryObligationService } from 'src/Services/category-obligation.service';

@Component({
  selector: 'app-category-type-cfo-add-update',
  templateUrl: './category-type-cfo-add-update.component.html',
  styleUrls: ['./category-type-cfo-add-update.component.css']
})
export class CategoryTypeCfoAddUpdateComponent implements OnInit {

  categoryMasterForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private categoryObligationService: CategoryObligationService, private toastr: ToastrService) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    if(this.SelectedTool == 'view 47'){
      this.SelectedTool = 'view47';
    }
    this.isSubmit = false;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.categoryMasterForm = this._fb.group({
      categoryTypeId: 0,
      categoryName: ['', Validators.required],
      mouseovertext: ['', Validators.required],
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetCategoryById(params['id']);
        }
      }
    });
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  cancelCategoryMaster() {
    let navigation = '/admin/' + this.SelectedTool + '/category';
    this.router.navigate([navigation]);
  }

  GetCategoryById(categoryTypeId: number) {
    this.categoryObligationService.GetCategoryById(this.tokenKey, parseInt(categoryTypeId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryMasterForm.patchValue({
            categoryTypeId: result['obligationCategory'].categoryTypeId,
            categoryName: result['obligationCategory'].categoryName,
            mouseovertext: result['obligationCategory'].mouseoverText
          });
        }
      });
  }

  save() {
    this.isSubmit = true;
    let data = this.categoryMasterForm.value;
    if (!this.categoryMasterForm.valid || !data.categoryName.trim())
      return
    let value = {
      categoryTypeId: data.categoryTypeId,
      categoryName: data.categoryName.trim(),
      mouseovertext: data.mouseovertext.trim()
    }
    this.categoryObligationService.CategoryAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {

            if (data.categoryTypeId > 0)
              this.showMessage('category updated successfully', 'Success', 'success');
            else
              this.showMessage('category saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/category']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
}
