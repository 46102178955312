import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TabHeadingDirective } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MarketEntryDataModel } from 'src/Models/PriceCalculationModel';
import { StatusModel } from 'src/Models/StepMasterModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { UserModel } from 'src/Models/usersModel';
import { AppConfig } from 'src/Services/app.config';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompleted, getIncompletedStep } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-admin-price-quote-notes',
  templateUrl: './admin-price-quote-notes.component.html',
  styleUrls: ['./admin-price-quote-notes.component.css']
})
export class AdminPriceQuoteNotesComponent implements OnInit {

  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;

  mainUrl: string;
  subUrl: string;

  priceQuoteId: number = 0;
  isSubmit: boolean = false;
  isEdit: boolean = false;
  currentActiveTab: number = 1;
  notes: string;
  clientInformationId: number = 0;
  companyName: string;

  listofMarketEntryData: MarketEntryDataModel[];
  listofMarketEntryData1: MarketEntryDataModel;
  listofMarketEntryData2: MarketEntryDataModel;

  sosServiceFees?: number = 0.00;
  sosCorpFeelingFees?: number = 0.00;
  sosLLCFeelingFees?: number = 0.00;
  registeredAgentFees?: number = 0.00;
  pucServiceFees?: number = 0.00;
  pscFeelingFees?: number = 0.00;
  localCounselFees?: number = 0.00;
  inteserraTotal?: number = 0.00;
  companyTotal?: number = 0.00;
  dbaFillinfFees?: number = 0.00;
  dbaComSpecFillingFees?: number = 0.00;
  Total?: number = 0.00;
  dbaServiceFees?: number = 0.00;
  dbaComSpecServiceFees?: number = 0.00;
  dbA_Inteserra_Fee?: number = 0.00;
  dbAFillingsFees?: number = 0.00;
  dbA_RA_Fees?: number = 0.00;
  bond?: number = 0.00;
  puC_Filing_Fees?:number=0.00;

  soS_Service_Fees_SelectedAll: boolean = false;
  dbA_Service_Fees_SelectedAll: boolean = false;
  soS_Corp_Filling_Fees_SelectedAll: boolean = false;
  soS_LLC_Filing_Fees_SelectedAll: boolean = false;
  dbA_Filing_Fees_SelectedAll: boolean = false;
  registered_Agent_Fees_SelectedAll: boolean = false;
  puC_Service_Fees_SelectedAll: boolean = false;
  puC_Filing_Fees_SelectedAll: boolean = false;
  psC_FilingsAndPublication_Fees_SelectedAll: boolean = false;
  local_Counsel_Fees_SelectedAll: boolean = false;
  registered_Agent_DBA_Fees_SelectedAll: boolean = false;
  bond_SelectedAll: boolean = false;

  soS_Service_Fees_NAisSelected: boolean = false;
  dbA_Service_Fees_NAisSelected: boolean = false;
  soS_Corp_Filling_Fees_NAisSelected: boolean = false;
  soS_LLC_Filing_Fees_NAisSelected: boolean = false;
  dbA_Filing_Fees_NAisSelected: boolean = false;
  registered_Agent_Fees_NAisSelected: boolean = false;
  puC_Service_Fees_NAisSelected: boolean = false;
  psC_FilingsAndPublication_Fees_NAisSelected: boolean = false;
  local_Counsel_Fees_NAisSelected: boolean = false;
  registered_Agent_DBA_Fees_NAisSelected: boolean = false;
  bond_NAisSelected: boolean = false;
  puC_Filing_Fees_NAisSelected: boolean = false;
  isMarketEntry: boolean = false;
  marketEntryName: string;
  marketEntryProvider: number = 0;
  userName:string;
  createdDate:Date;
  isEditedFromFront:boolean=false;
  previousTotal:number=0;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute, private datePipe: DatePipe, private globle: Globle,
    private router: Router, private PricingQuotesMaster: PricingQuotesService, private toastr: ToastrService,
    private titleService: Title, private userService: UsersService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Company Profile - ' + this.companyName);

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.priceQuoteId = params['id'];
        }
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.priceQuoteId != null) {
      this.GetProgressStepDetailsById(this.priceQuoteId)
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isCompanyProfileDetailsCompleted = null;
      this.globle.cApp.statusModel.isAddCompQueCompleted = null;
      this.globle.cApp.statusModel.isStateSelectionCompleted = null;
      this.globle.cApp.statusModel.isAttechedDetailsCompleted = null;
      this.globle.cApp.statusModel.isNotes = null
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
  }

  ngOnInit() {

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetComplianceByClientInformationId(this.priceQuoteId);
          this.GetClientInformationById(params['id']);
          this.GetMarketEntryDataById(this.priceQuoteId);
        }
      }
    });

  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  GetClientInformationById(priceQuoteId: number) {
    this.blockDocument();

    this.PricingQuotesMaster.GetClientInformationById(this.tokenKey, priceQuoteId, 0, false).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.notes = result['clientInformation'].notes;
          this.clientInformationId = result['clientInformation'].clientInformationId;
        }
        if (result['clientInformation'].marketEntryProvider == 1) {
          this.marketEntryName = " - CLEC";
        }
        else if (result['clientInformation'].marketEntryProvider == 2) {
          this.marketEntryName = " - IXC";
        }
        else if (result['clientInformation'].marketEntryProvider == 3) {
          this.marketEntryName = " - VOIP";
        }
        else if (result['clientInformation'].marketEntryProvider == 4) {
          this.marketEntryProvider = 4;
          this.marketEntryName = " - Wireless";
        }
      });
  }

  save(clientForm: any) {

    this.isSubmit = true;

    if (this.isMarketEntry) {
      this.blockDocument();
      for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {
        this.listofMarketEntryData[iCounter].soS_Service_Fees_NAisSelected = this.soS_Service_Fees_NAisSelected;
        this.listofMarketEntryData[iCounter].dbA_Service_Fees_NAisSelected = this.dbA_Service_Fees_NAisSelected;
        this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_NAisSelected = this.soS_Corp_Filling_Fees_NAisSelected;
        this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_NAisSelected = this.soS_LLC_Filing_Fees_NAisSelected;
        this.listofMarketEntryData[iCounter].dbA_Filing_Fees_NAisSelected = this.dbA_Filing_Fees_NAisSelected;
        this.listofMarketEntryData[iCounter].registered_Agent_Fees_NAisSelected = this.registered_Agent_Fees_NAisSelected;
        this.listofMarketEntryData[iCounter].puC_Service_Fees_NAisSelected = this.puC_Service_Fees_NAisSelected;
        this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_NAisSelected = this.psC_FilingsAndPublication_Fees_NAisSelected;
        this.listofMarketEntryData[iCounter].local_Counsel_Fees_NAisSelected = this.local_Counsel_Fees_NAisSelected;
        this.listofMarketEntryData[iCounter].dbA_RA_Fees_NAisSelected = this.registered_Agent_DBA_Fees_NAisSelected;
        this.listofMarketEntryData[iCounter].bond_NAisSelected = this.bond_NAisSelected;
        this.listofMarketEntryData[iCounter].puC_Filing_Fees_NAisSelected = this.puC_Filing_Fees_NAisSelected;
      }
      let value = {
        listofMarketEntryData: this.listofMarketEntryData,
        priceQuoteId: this.priceQuoteId,
        IsProposalDetailsCompleted: true,
        isAdmin:true,
        previousTotal:this.previousStep,
        inteserraTotal:this.inteserraTotal
      }

      this.PricingQuotesMaster.MarketEntryDataAddUpdate(this.tokenKey, value).subscribe(
        result => {
          this.unblockDocument();
          if (result['status'] == 'Success') {
          
          }
          else {
            this.showMessage(result['status'], 'Error', 'error');
          }
        },
        error => {
          this.unblockDocument();
          this.showMessage(error, 'Error', 'error');
        }
      );
    }

    let value = {
      clientInformationId: this.clientInformationId,
      priceQuoteId: this.priceQuoteId,
      notes: this.notes,
      createdBy: this.userID,
      IsVendor:false
    }

    this.blockDocument();
    this.PricingQuotesMaster.NotesAddUpdate(this.tokenKey, value).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isNotes = true;
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {

            if (result.priceQuoteId > 0)
              this.showMessage('Company profile saved successfully', 'Success', 'success');

            let navigation = '/admin/' + this.SelectedTool + '/manage-baserate/' + this.priceQuoteId;
            this.router.navigate([navigation]);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  previousStep() {
    let navigation = '/admin/' + this.SelectedTool + '/state-selection/' + this.priceQuoteId;
    this.router.navigate([navigation]);
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  activeTab(url: string, tabId: number) {

    if (this.priceQuoteId != null) {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsById(this.tokenKey, this.priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
          this.activeTab('notes', 13);
        }
      });
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  GetComplianceByClientInformationId(priceQuoteId) {
    this.blockDocument();
    this.PricingQuotesMaster.GetProposelByClientInformationId(this.tokenKey, priceQuoteId).subscribe(
      data => {
        if (data['status'] = 'Success') {
          this.unblockDocument();

          if (data['miscelleneous'] != null && data['miscelleneous'].length > 0) {
          }
        }
        else {
          this.unblockDocument();
        }
      }, error => { }
    );
  }

  GetMarketEntryDataById(priceQuoteId: number) {

    this.blockDocument();
    this.PricingQuotesMaster.GetMarketEntryDataById(this.tokenKey, priceQuoteId).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.listofMarketEntryData = result['compliance'];

          this.userName=this.listofMarketEntryData[0].userName;
          this.createdDate=this.listofMarketEntryData[0].createdDate;

          if(this.userName!=null && this.userName!='' && this.createdDate!=null)
              this.isEditedFromFront=true;

          if (this.listofMarketEntryData.length > 0) {
            this.isMarketEntry = true;
            this.sosServiceFees = 0;
            this.sosCorpFeelingFees = 0;
            this.pucServiceFees = 0;
            this.pscFeelingFees = 0;
            this.registeredAgentFees = 0;
            this.localCounselFees = 0;
            this.dbaFillinfFees = 0;
            this.dbaServiceFees = 0;
            this.sosLLCFeelingFees = 0;
            this.dbA_Inteserra_Fee = 0.00;
            this.dbAFillingsFees = 0.00;
            this.dbA_RA_Fees = 0.00;
            this.bond = 0.00;
            this.puC_Filing_Fees=0.00;

            let totallistofmarketentrydata = this.listofMarketEntryData.filter(x => x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;

            var SOSServiceData = this.listofMarketEntryData.filter(x => x.soS_Service_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (SOSServiceData != null) {
              if (SOSServiceData == totallistofmarketentrydata)
                this.soS_Service_Fees_SelectedAll = true;
              else
                this.soS_Service_Fees_SelectedAll = false;
            }

            var DBAServiceData = this.listofMarketEntryData.filter(x => x.dbA_Service_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (DBAServiceData != null) {
              if (DBAServiceData == totallistofmarketentrydata)
                this.dbA_Service_Fees_SelectedAll = true;
              else
                this.dbA_Service_Fees_SelectedAll = false;
            }

            var SOSCorpData = this.listofMarketEntryData.filter(x => x.soS_Corp_Filling_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (SOSCorpData != null) {
              if (SOSCorpData == totallistofmarketentrydata)
                this.soS_Corp_Filling_Fees_SelectedAll = true;
              else
                this.soS_Corp_Filling_Fees_SelectedAll = false;
            }

            var SOSLLCData = this.listofMarketEntryData.filter(x => x.soS_LLC_Filing_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (SOSLLCData != null) {
              if (SOSLLCData == totallistofmarketentrydata)
                this.soS_LLC_Filing_Fees_SelectedAll = true;
              else
                this.soS_LLC_Filing_Fees_SelectedAll = false;
            }

            var DBAFillingFeesData = this.listofMarketEntryData.filter(x => x.dbA_Filing_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (DBAFillingFeesData != null) {
              if (DBAFillingFeesData == totallistofmarketentrydata)
                this.dbA_Filing_Fees_SelectedAll = true;
              else
                this.dbA_Filing_Fees_SelectedAll = false;
            }

            var RegisterAgentData = this.listofMarketEntryData.filter(x => x.registered_Agent_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (RegisterAgentData != null) {
              if (RegisterAgentData == totallistofmarketentrydata)
                this.registered_Agent_Fees_SelectedAll = true;
              else
                this.registered_Agent_Fees_SelectedAll = false;
            }

            var PUCServicesData = this.listofMarketEntryData.filter(x => x.puC_Service_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (PUCServicesData != null) {
              if (PUCServicesData == totallistofmarketentrydata)
                this.puC_Service_Fees_SelectedAll = true;
              else
                this.puC_Service_Fees_SelectedAll = false;
            }

            var PSCFilingsAndPublicationData = this.listofMarketEntryData.filter(x => x.psC_FilingsAndPublication_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (PSCFilingsAndPublicationData != null) {
              if (PSCFilingsAndPublicationData == totallistofmarketentrydata)
                this.psC_FilingsAndPublication_Fees_SelectedAll = true;
              else
                this.psC_FilingsAndPublication_Fees_SelectedAll = false;
            }

            var LocalCounselData = this.listofMarketEntryData.filter(x => x.local_Counsel_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (LocalCounselData != null) {
              if (LocalCounselData == totallistofmarketentrydata)
                this.local_Counsel_Fees_SelectedAll = true;
              else
                this.local_Counsel_Fees_SelectedAll = false;
            }

            var DBARAData = this.listofMarketEntryData.filter(x => x.dbA_RA_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (DBARAData != null) {
              if (DBARAData == totallistofmarketentrydata)
                this.registered_Agent_DBA_Fees_SelectedAll = true;
              else
                this.registered_Agent_DBA_Fees_SelectedAll = false;
            }

            var PUCFilingData = this.listofMarketEntryData.filter(x => x.puC_Filing_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
            if (PUCFilingData != null) {
              if (PUCFilingData == totallistofmarketentrydata)
                this.puC_Filing_Fees_SelectedAll = true;
              else
                this.puC_Filing_Fees_SelectedAll = false;
            }


            if (this.marketEntryProvider != 4) {
              var BondData = this.listofMarketEntryData.filter(x => x.bond_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
              if (BondData != null) {
                if (BondData == totallistofmarketentrydata)
                  this.bond_SelectedAll = true;
                else
                  this.bond_SelectedAll = false;
              }
            }

            var SOSServiceNAData = this.listofMarketEntryData.filter(x => x.soS_Service_Fees_NAisSelected == true).length;
            if (SOSServiceNAData != null) {
              if (SOSServiceNAData > 0)
                this.soS_Service_Fees_NAisSelected = true;
              else
                this.soS_Service_Fees_NAisSelected = false;
            }

            var DBAServiceNAData = this.listofMarketEntryData.filter(x => x.dbA_Service_Fees_NAisSelected == true).length;
            if (DBAServiceNAData != null) {
              if (DBAServiceNAData > 0)
                this.dbA_Service_Fees_NAisSelected = true;
              else
                this.dbA_Service_Fees_NAisSelected = false;
            }

            var SOSCorpNAData = this.listofMarketEntryData.filter(x => x.soS_Corp_Filling_Fees_NAisSelected == true).length;
            if (SOSCorpNAData != null) {
              if (SOSCorpNAData > 0)
                this.soS_Corp_Filling_Fees_NAisSelected = true;
              else
                this.soS_Corp_Filling_Fees_NAisSelected = false;
            }

            var SOSLLCNAData = this.listofMarketEntryData.filter(x => x.soS_LLC_Filing_Fees_NAisSelected == true).length;
            if (SOSLLCNAData != null) {
              if (SOSLLCNAData > 0)
                this.soS_LLC_Filing_Fees_NAisSelected = true;
              else
                this.soS_LLC_Filing_Fees_NAisSelected = false;
            }

            var DBAFillingFeesNAData = this.listofMarketEntryData.filter(x => x.dbA_Filing_Fees_NAisSelected == true).length;
            if (DBAFillingFeesNAData != null) {
              if (DBAFillingFeesNAData > 0)
                this.dbA_Filing_Fees_NAisSelected = true;
              else
                this.dbA_Filing_Fees_NAisSelected = false;
            }

            var RegisterAgentNAData = this.listofMarketEntryData.filter(x => x.registered_Agent_Fees_NAisSelected == true).length;
            if (RegisterAgentNAData != null) {
              if (RegisterAgentNAData > 0)
                this.registered_Agent_Fees_NAisSelected = true;
              else
                this.registered_Agent_Fees_NAisSelected = false;
            }

            var PUCServicesNAData = this.listofMarketEntryData.filter(x => x.puC_Service_Fees_NAisSelected == true).length;
            if (PUCServicesNAData != null) {
              if (PUCServicesNAData > 0)
                this.puC_Service_Fees_NAisSelected = true;
              else
                this.puC_Service_Fees_NAisSelected = false;
            }

            var PSCFilingsAndPublicationNAData = this.listofMarketEntryData.filter(x => x.psC_FilingsAndPublication_Fees_NAisSelected == true).length;
            if (PSCFilingsAndPublicationNAData != null) {
              if (PSCFilingsAndPublicationNAData > 0)
                this.psC_FilingsAndPublication_Fees_NAisSelected = true;
              else
                this.psC_FilingsAndPublication_Fees_NAisSelected = false;
            }

            var LocalCounselNAData = this.listofMarketEntryData.filter(x => x.local_Counsel_Fees_NAisSelected == true).length;
            if (LocalCounselNAData != null) {
              if (LocalCounselNAData > 0)
                this.local_Counsel_Fees_NAisSelected = true;
              else
                this.local_Counsel_Fees_NAisSelected = false;
            }

            var RADBANAData = this.listofMarketEntryData.filter(x => x.dbA_RA_Fees_NAisSelected == true).length;
            if (RADBANAData != null) {
              if (RADBANAData > 0)
                this.registered_Agent_DBA_Fees_NAisSelected = true;
              else
                this.registered_Agent_DBA_Fees_NAisSelected = false;
            }

            if (this.marketEntryProvider != 4) {
              var BondNAData = this.listofMarketEntryData.filter(x => x.bond_NAisSelected == true).length;
              if (BondNAData != null) {
                if (BondNAData > 0)
                  this.bond_NAisSelected = true;
                else
                  this.bond_NAisSelected = false;
              }
            }

            var PUCFilingata = this.listofMarketEntryData.filter(x => x.puC_Filing_Fees_NAisSelected == true).length;
            if (PUCFilingata != null) {
              if (PUCFilingata > 0)
                this.puC_Filing_Fees_NAisSelected = true;
              else
                this.puC_Filing_Fees_NAisSelected = false;
            }

            for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {

              this.Total = 0.00;

              if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

              if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

              if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

              if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

              if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;

              if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

              if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

              if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Service_Fees

              if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

              if (this.listofMarketEntryData[iCounter].dbA_RA_Fees_isSelected && !this.registered_Agent_DBA_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_RA_Fees;

              if (this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected && !this.puC_Filing_Fees_NAisSelected)
                this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Filing_Fees;


              if (this.marketEntryProvider != 4) {
                if (this.listofMarketEntryData[iCounter].bond_isSelected && !this.bond_NAisSelected)
                  this.Total = +this.Total + +this.listofMarketEntryData[iCounter].bond;
              }

              this.listofMarketEntryData[iCounter].total = +this.Total;

              if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
                this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

              if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
                this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

              if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
                this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

              if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
                this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

              if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
                this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;

              if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
                this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

              if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
                this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

              if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
                this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

              if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
                this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
              
              if (this.listofMarketEntryData[iCounter].dbA_RA_Fees_isSelected && !this.registered_Agent_DBA_Fees_NAisSelected)
                this.dbA_RA_Fees = this.dbA_RA_Fees + +this.listofMarketEntryData[iCounter].dbA_RA_Fees;

              if (this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected && !this.puC_Filing_Fees_NAisSelected)
                this.puC_Filing_Fees = this.puC_Filing_Fees + +this.listofMarketEntryData[iCounter].puC_Filing_Fees;

              if (this.marketEntryProvider != 4) {
                if (this.listofMarketEntryData[iCounter].bond_isSelected && !this.bond_NAisSelected)
                  this.bond = this.bond + +this.listofMarketEntryData[iCounter].bond;
              }

            }
            this.dbaComSpecFillingFees = 0;
            this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
            this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
            this.inteserraTotal = 0; this.companyTotal = 0;
            this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees + this.dbA_RA_Fees + this.bond+this.puC_Filing_Fees;
            this.previousTotal= this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees + this.dbA_RA_Fees + this.bond+this.puC_Filing_Fees;
            this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
          }
          else {

          }
        }

      });
  }

  SelectedEntryCheck(detail) {
    this.sosServiceFees = 0;
    this.sosCorpFeelingFees = 0;
    this.pucServiceFees = 0;
    this.pscFeelingFees = 0;
    this.registeredAgentFees = 0;
    this.localCounselFees = 0;
    this.dbaFillinfFees = 0;
    this.sosLLCFeelingFees = 0;
    this.dbaServiceFees = 0;
    this.dbA_Inteserra_Fee = 0;
    this.dbAFillingsFees = 0;
    this.dbA_RA_Fees = 0;
    this.bond = 0;
    this.puC_Filing_Fees=0;

    for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {

      this.Total = 0.00;

      if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

      if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_RA_Fees_isSelected && !this.registered_Agent_DBA_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_RA_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected && !this.puC_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Filing_Fees;

      if (this.marketEntryProvider != 4) {
        if (this.listofMarketEntryData[iCounter].bond_isSelected && !this.bond_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].bond;
      }
    
      if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

      if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Service_Fees

      if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

      this.listofMarketEntryData[iCounter].total = +this.Total;

      if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
        this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
        this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
        this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

      if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
        this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
        this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_RA_Fees_isSelected && !this.registered_Agent_DBA_Fees_NAisSelected)
        this.dbA_RA_Fees = this.dbA_RA_Fees + +this.listofMarketEntryData[iCounter].dbA_RA_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected && !this.puC_Filing_Fees_NAisSelected)
        this.puC_Filing_Fees = this.puC_Filing_Fees + +this.listofMarketEntryData[iCounter].puC_Filing_Fees;

      if (this.marketEntryProvider != 4) {
        if (this.listofMarketEntryData[iCounter].bond_isSelected && !this.bond_NAisSelected)
          this.bond = this.bond + +this.listofMarketEntryData[iCounter].bond;
      }

      if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
        this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

      if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
        this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

      if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
        this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

      if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
        this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
    }

    let totallistofmarketentrydata = this.listofMarketEntryData.filter(x => x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;

    var SOSServiceData = this.listofMarketEntryData.filter(x => x.soS_Service_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (SOSServiceData != null) {
      if (SOSServiceData == totallistofmarketentrydata)
        this.soS_Service_Fees_SelectedAll = true;
      else
        this.soS_Service_Fees_SelectedAll = false;
    }

    var DBAServiceData = this.listofMarketEntryData.filter(x => x.dbA_Service_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (DBAServiceData != null) {
      if (DBAServiceData == totallistofmarketentrydata)
        this.dbA_Service_Fees_SelectedAll = true;
      else
        this.dbA_Service_Fees_SelectedAll = false;
    }

    var SOSCorpData = this.listofMarketEntryData.filter(x => x.soS_Corp_Filling_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (SOSCorpData != null) {
      if (SOSCorpData == totallistofmarketentrydata)
        this.soS_Corp_Filling_Fees_SelectedAll = true;
      else
        this.soS_Corp_Filling_Fees_SelectedAll = false;
    }

    var SOSLLCData = this.listofMarketEntryData.filter(x => x.soS_LLC_Filing_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (SOSLLCData != null) {
      if (SOSLLCData == totallistofmarketentrydata)
        this.soS_LLC_Filing_Fees_SelectedAll = true;
      else
        this.soS_LLC_Filing_Fees_SelectedAll = false;
    }

    var DBAFillingFeesData = this.listofMarketEntryData.filter(x => x.dbA_Filing_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (DBAFillingFeesData != null) {
      if (DBAFillingFeesData == totallistofmarketentrydata)
        this.dbA_Filing_Fees_SelectedAll = true;
      else
        this.dbA_Filing_Fees_SelectedAll = false;
    }

    var RegisterAgentData = this.listofMarketEntryData.filter(x => x.registered_Agent_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (RegisterAgentData != null) {
      if (RegisterAgentData == totallistofmarketentrydata)
        this.registered_Agent_Fees_SelectedAll = true;
      else
        this.registered_Agent_Fees_SelectedAll = false;
    }

    var PUCServicesData = this.listofMarketEntryData.filter(x => x.puC_Service_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (PUCServicesData != null) {
      if (PUCServicesData == totallistofmarketentrydata)
        this.puC_Service_Fees_SelectedAll = true;
      else
        this.puC_Service_Fees_SelectedAll = false;
    }

    var PSCFilingsAndPublicationData = this.listofMarketEntryData.filter(x => x.psC_FilingsAndPublication_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (PSCFilingsAndPublicationData != null) {
      if (PSCFilingsAndPublicationData == totallistofmarketentrydata)
        this.psC_FilingsAndPublication_Fees_SelectedAll = true;
      else
        this.psC_FilingsAndPublication_Fees_SelectedAll = false;
    }

    var LocalCounselData = this.listofMarketEntryData.filter(x => x.local_Counsel_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (LocalCounselData != null) {
      if (LocalCounselData == totallistofmarketentrydata)
        this.local_Counsel_Fees_SelectedAll = true;
      else
        this.local_Counsel_Fees_SelectedAll = false;
    }

    var RegisterDBAData = this.listofMarketEntryData.filter(x => x.dbA_RA_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (RegisterDBAData != null) {
      if (RegisterDBAData == totallistofmarketentrydata)
        this.registered_Agent_DBA_Fees_SelectedAll = true;
      else
        this.registered_Agent_DBA_Fees_SelectedAll = false;
    }

    if (this.marketEntryProvider != 4) {
      var BondData = this.listofMarketEntryData.filter(x => x.bond_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
      if (BondData != null) {
        if (BondData == totallistofmarketentrydata)
          this.bond_SelectedAll = true;
        else
          this.bond_SelectedAll = false;
      }
    }

    var PUCFilingData = this.listofMarketEntryData.filter(x => x.puC_Filing_Fees_isSelected == true && x.stateName != 'Puerto Rico' && x.stateName != 'Canada').length;
    if (PUCFilingData != null) {
      if (PUCFilingData == totallistofmarketentrydata)
        this.puC_Filing_Fees_SelectedAll = true;
      else
        this.puC_Filing_Fees_SelectedAll = false;
    }

    this.dbaComSpecFillingFees = 0;
    this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
    this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
    this.inteserraTotal = 0; this.companyTotal = 0;
    this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees + this.dbA_RA_Fees + this.bond+this.puC_Filing_Fees;
    this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
  }

  selectedState_soS_Service_Fees(value, columnName) {
    this.sosServiceFees = 0;
    this.sosCorpFeelingFees = 0;
    this.pucServiceFees = 0;
    this.pscFeelingFees = 0;
    this.registeredAgentFees = 0;
    this.localCounselFees = 0;
    this.dbaFillinfFees = 0;
    this.sosLLCFeelingFees = 0;
    this.dbaServiceFees = 0;
    this.dbA_Inteserra_Fee = 0;
    this.dbAFillingsFees = 0;
    this.dbA_RA_Fees = 0;
    this.bond = 0;
    this.puC_Filing_Fees=0;

    for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {
      if (this.listofMarketEntryData[iCounter].stateName != 'Puerto Rico' && this.listofMarketEntryData[iCounter].stateName != 'Canada') {

        this.Total = 0.00;
        if (columnName == 'SOS_Service_Fees')
          this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected = value;
        if (columnName == 'DBA_Service_Fees')
          this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected = value;
        if (columnName == 'SOS_Corp_Fees')
          this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected = value;
        if (columnName == 'SOS_LLC_Fees')
          this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected = value;
        if (columnName == 'DBA_Filing_Fees')
          this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected = value;
        if (columnName == 'Registered_Agent_Fees')
          this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected = value;
        if (columnName == 'PUC_Service_Fees')
          this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected = value;
        if (columnName == 'PSC_FilingsAndPublication_Fees')
          this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected = value;
        if (columnName == 'Local_Counsel_Fees')
          this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected = value;
        if (columnName == 'bond')
          this.listofMarketEntryData[iCounter].bond_isSelected = value;
        if (columnName == 'Registered_Agent_DBA_Fees')
          this.listofMarketEntryData[iCounter].dbA_RA_Fees_isSelected = value;
        if (columnName == 'PUC_Filing_Fees')
          this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected = value;

        if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

        if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

        if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;

        if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

        if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

        if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Service_Fees

        if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

        if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_DBA_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_RA_Fees;

        if (this.marketEntryProvider != 4) {
          if (this.listofMarketEntryData[iCounter].bond_isSelected && !this.bond_NAisSelected)
            this.Total = +this.Total + +this.listofMarketEntryData[iCounter].bond;
        }
       
        if (this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected && !this.puC_Filing_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Filing_Fees;
  
        this.listofMarketEntryData[iCounter].total = +this.Total;

        if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
          this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
          this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
          this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

        if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
          this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

        if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
          this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;

        if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
          this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

        if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
          this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

        if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
          this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

        if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
          this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_RA_Fees_isSelected && !this.registered_Agent_DBA_Fees_NAisSelected)
          this.dbA_RA_Fees = (this.dbA_RA_Fees) + +this.listofMarketEntryData[iCounter].dbA_RA_Fees;

        if (this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected && !this.puC_Filing_Fees_NAisSelected)
          this.puC_Filing_Fees = this.puC_Filing_Fees + +this.listofMarketEntryData[iCounter].puC_Filing_Fees;

        if (this.marketEntryProvider != 4) {
          if (this.listofMarketEntryData[iCounter].bond_isSelected && !this.bond_NAisSelected)
            this.bond = (this.bond) + +this.listofMarketEntryData[iCounter].bond;
        }
      }
    }
    this.dbaComSpecFillingFees = 0;
    this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
    this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
    this.inteserraTotal = 0; this.companyTotal = 0;
    this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees + this.bond + this.dbA_RA_Fees+this.puC_Filing_Fees;
    this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
  }

  selectedState_NA_Fees(value, columnName) {
    this.sosServiceFees = 0;
    this.sosCorpFeelingFees = 0;
    this.pucServiceFees = 0;
    this.pscFeelingFees = 0;
    this.registeredAgentFees = 0;
    this.localCounselFees = 0;
    this.dbaFillinfFees = 0;
    this.sosLLCFeelingFees = 0;
    this.dbaServiceFees = 0;
    this.dbA_Inteserra_Fee = 0;
    this.dbAFillingsFees = 0;
    this.dbA_RA_Fees = 0;
    this.bond = 0;
    this.puC_Filing_Fees=0;

    for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {

      this.Total = 0.00;
      if (columnName == 'SOS_Service_Fees')
        this.soS_Service_Fees_NAisSelected = value;
      if (columnName == 'DBA_Service_Fees')
        this.dbA_Service_Fees_NAisSelected = value;
      if (columnName == 'SOS_Corp_Fees')
        this.soS_Corp_Filling_Fees_NAisSelected = value;
      if (columnName == 'SOS_LLC_Fees')
        this.soS_LLC_Filing_Fees_NAisSelected = value;
      if (columnName == 'DBA_Filing_Fees')
        this.dbA_Filing_Fees_NAisSelected = value;
      if (columnName == 'Registered_Agent_Fees')
        this.registered_Agent_Fees_NAisSelected = value;
      if (columnName == 'PUC_Service_Fees')
        this.puC_Service_Fees_NAisSelected = value;
      if (columnName == 'PSC_FilingsAndPublication_Fees')
        this.psC_FilingsAndPublication_Fees_NAisSelected = value;
      if (columnName == 'Local_Counsel_Fees')
        this.local_Counsel_Fees_NAisSelected = value;
      if (columnName == 'bond')
        this.bond_NAisSelected = value;
      if (columnName == 'Registered_Agent_DBA_Fees')
        this.registered_Agent_DBA_Fees_NAisSelected = value;
      if (columnName == 'PUC_Filing_Fees')
        this.puC_Filing_Fees_NAisSelected = value;

      if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

      if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

      if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Service_Fees

      if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_RA_Fees_isSelected && !this.registered_Agent_DBA_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_RA_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected && !this.puC_Filing_Fees_NAisSelected)
        this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Filing_Fees;

      if (this.marketEntryProvider != 4) {
        if (this.listofMarketEntryData[iCounter].bond_isSelected && !this.bond_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].bond;
      }

      this.listofMarketEntryData[iCounter].total = +this.Total;

      if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
        this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
        this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
        this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

      if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
        this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

      if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
        this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;

      if (this.listofMarketEntryData[iCounter].dbA_RA_Fees_isSelected && !this.registered_Agent_DBA_Fees_NAisSelected)
        this.dbA_RA_Fees = this.dbA_RA_Fees + +this.listofMarketEntryData[iCounter].dbA_RA_Fees;

      if (this.marketEntryProvider != 4) {
        if (this.listofMarketEntryData[iCounter].bond_isSelected && !this.bond_NAisSelected)
          this.bond = this.bond + +this.listofMarketEntryData[iCounter].bond;
      }

      if (this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected && !this.puC_Filing_Fees_NAisSelected)
        this.puC_Filing_Fees = this.puC_Filing_Fees + +this.listofMarketEntryData[iCounter].puC_Filing_Fees;

      if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
        this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

      if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
        this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

      if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
        this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

      if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
        this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
    }
    this.dbaComSpecFillingFees = 0;
    this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
    this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
    this.inteserraTotal = 0; this.companyTotal = 0;
    this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees + this.dbA_RA_Fees + this.bond+this.puC_Filing_Fees;
    this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
  }

  changevalue(event: any, item, fieldName: string) {
    if (this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId).length > 0) {
      if (fieldName == 'soS_Service_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].soS_Service_Fees = +event;
      }
      else if (fieldName == 'dbA_Service_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].dbA_Service_Fees = +event;
      }
      else if (fieldName == 'soS_Corp_Filling_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].soS_Corp_Filling_Fees = +event;
      }
      else if (fieldName == 'soS_LLC_Filing_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].soS_LLC_Filing_Fees = +event;
      }
      else if (fieldName == 'dbA_Filing_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].dbA_Filing_Fees = +event;
      }
      else if (fieldName == 'registered_Agent_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].registered_Agent_Fees = +event;
      }
      else if (fieldName == 'puC_Service_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].puC_Service_Fees = +event;
      }
      else if (fieldName == 'psC_FilingsAndPublication_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].psC_FilingsAndPublication_Fees = +event;
      }
      else if (fieldName == 'local_Counsel_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].local_Counsel_Fees = +event;
      }
      else if (fieldName == 'registered_Agent_DBA_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].dbA_RA_Fees = +event;
      }
      else if (fieldName == 'bond') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].bond = +event;
      }
      else if (fieldName == 'puC_Filing_Fees') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].puC_Filing_Fees = +event;
      }
      else if (fieldName == 'Notes') {
        this.listofMarketEntryData.filter(x => x.marketEntryId == item.marketEntryId)[0].notes = event;
      }

      this.sosServiceFees = 0;
      this.sosCorpFeelingFees = 0;
      this.pucServiceFees = 0;
      this.pscFeelingFees = 0;
      this.registeredAgentFees = 0;
      this.localCounselFees = 0;
      this.dbaFillinfFees = 0;
      this.sosLLCFeelingFees = 0;
      this.dbaServiceFees = 0;
      this.dbA_Inteserra_Fee = 0;
      this.dbAFillingsFees = 0;
      this.dbA_RA_Fees = 0;
      this.bond = 0;
      this.puC_Filing_Fees=0;

      for (let iCounter = 0; iCounter < this.listofMarketEntryData.length; iCounter++) {

        this.Total = 0.00;

        if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

        if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

        if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;

        if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

        if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

        if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Service_Fees

        if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_RA_Fees_isSelected && !this.registered_Agent_DBA_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].dbA_RA_Fees

        if (this.marketEntryProvider != 4) {
          if (this.listofMarketEntryData[iCounter].bond_isSelected && !this.bond_NAisSelected)
            this.Total = +this.Total + +this.listofMarketEntryData[iCounter].bond;
        }
       
        if (this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected && !this.puC_Filing_Fees_NAisSelected)
          this.Total = +this.Total + +this.listofMarketEntryData[iCounter].puC_Filing_Fees;

        this.listofMarketEntryData[iCounter].total = +this.Total;

        if (this.listofMarketEntryData[iCounter].soS_Service_Fees_isSelected && !this.soS_Service_Fees_NAisSelected)
          this.sosServiceFees = (this.sosServiceFees) + +this.listofMarketEntryData[iCounter].soS_Service_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_Filing_Fees_isSelected && !this.dbA_Filing_Fees_NAisSelected)
          this.dbaFillinfFees = this.dbaFillinfFees + +this.listofMarketEntryData[iCounter].dbA_Filing_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_Service_Fees_isSelected && !this.dbA_Service_Fees_NAisSelected)
          this.dbaServiceFees = this.dbaServiceFees + +this.listofMarketEntryData[iCounter].dbA_Service_Fees;

        if (this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees_isSelected && !this.soS_Corp_Filling_Fees_NAisSelected)
          this.sosCorpFeelingFees = this.sosCorpFeelingFees + +this.listofMarketEntryData[iCounter].soS_Corp_Filling_Fees;

        if (this.listofMarketEntryData[iCounter].puC_Service_Fees_isSelected && !this.puC_Service_Fees_NAisSelected)
          this.pucServiceFees = this.pucServiceFees + +this.listofMarketEntryData[iCounter].puC_Service_Fees;

        if (this.listofMarketEntryData[iCounter].dbA_RA_Fees_isSelected && !this.registered_Agent_DBA_Fees_NAisSelected)
          this.dbA_RA_Fees = this.dbA_RA_Fees + +this.listofMarketEntryData[iCounter].dbA_RA_Fees;

        if (this.marketEntryProvider != 4) {
          if (this.listofMarketEntryData[iCounter].bond_isSelected && !this.bond_NAisSelected)
            this.bond = this.bond + +this.listofMarketEntryData[iCounter].bond;
        }

        if (this.listofMarketEntryData[iCounter].puC_Filing_Fees_isSelected && !this.puC_Filing_Fees_NAisSelected)
          this.puC_Filing_Fees = this.puC_Filing_Fees + +this.listofMarketEntryData[iCounter].puC_Filing_Fees;

        if (this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees_isSelected && !this.psC_FilingsAndPublication_Fees_NAisSelected)
          this.pscFeelingFees = this.pscFeelingFees + +this.listofMarketEntryData[iCounter].psC_FilingsAndPublication_Fees;

        if (this.listofMarketEntryData[iCounter].registered_Agent_Fees_isSelected && !this.registered_Agent_Fees_NAisSelected)
          this.registeredAgentFees = this.registeredAgentFees + +this.listofMarketEntryData[iCounter].registered_Agent_Fees;

        if (this.listofMarketEntryData[iCounter].local_Counsel_Fees_isSelected && !this.local_Counsel_Fees_NAisSelected)
          this.localCounselFees = this.localCounselFees + +this.listofMarketEntryData[iCounter].local_Counsel_Fees;

        if (this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees_isSelected && !this.soS_LLC_Filing_Fees_NAisSelected)
          this.sosLLCFeelingFees = this.sosLLCFeelingFees + +this.listofMarketEntryData[iCounter].soS_LLC_Filing_Fees;
      }
      this.dbaComSpecFillingFees = 0;
      this.dbaComSpecFillingFees = this.dbaFillinfFees * 2;
      this.dbaComSpecServiceFees = this.dbaServiceFees * 2;
      this.inteserraTotal = 0; this.companyTotal = 0;
      this.inteserraTotal = this.sosServiceFees + this.dbaServiceFees + this.sosCorpFeelingFees + this.sosLLCFeelingFees + this.dbaFillinfFees + this.registeredAgentFees + this.pucServiceFees + this.pscFeelingFees + this.localCounselFees+this.dbA_RA_Fees+this.bond+this.puC_Filing_Fees;
      this.companyTotal = this.registeredAgentFees + this.localCounselFees + this.dbaComSpecFillingFees;
    }
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
}
