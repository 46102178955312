import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CRRAGetLinkModel, CRRALinkTypeModel } from 'src/Models/CRRALinkModel';
import { CrraLinkMasterService } from 'src/Services/crra-link-master.service';

@Component({
  selector: 'app-crra-link',
  templateUrl: './crra-link.component.html',
  styleUrls: ['./crra-link.component.css']
})
export class CrraLinkComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofLink: CRRAGetLinkModel[]

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  LinkName: string;


  upDowCitationCSS: string = '';
  upDowDescriptionCSS: string = '';
  upDowLinkTypeNameCSS: string = '';
  upDowStateNameCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];

  //LinkType
  LinkTypeId: string = ' ';
  dropdownListLinkType = [];
  selectedItemsLinkType = [];
  dropdownSettingsLinkType = {};
  listOfLinkTypeIds = [];
  LinkTypeList: CRRALinkTypeModel[] = [];

  module = "CRRA";
  LinkId: number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private linkTypeService: CrraLinkMasterService, private crraLinkMaster: CrraLinkMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService, private stateService: StateMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofLink = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'LinkTypeName DESC';
    this.LinkName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Links');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsLinkType = {
      singleSelection: false,
      text: "Filter by Link Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Link Type(s) available',
      showCheckbox: true
    };
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.getLinkType();
    this.isloading = true;
    this.GetLink();
  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }
  getLinkType() {
    this.linkTypeService.GetLinkType(this.tokenKey).subscribe(
      data => {
        this.LinkTypeList = data['crralink'];
        this.dropdownListLinkType = [];
        if (this.LinkTypeList.length > 0) {
          this.LinkTypeList.forEach(x => {
            this.dropdownListLinkType.push({ "id": x.linkTypeId, "itemName": x.linkTypeName })
          });
        }
      },
      error => { }
    );
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetLink();
  }


  GetLink() {

    this.isloading = true;
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.LinkTypeId = ' ';
    if (this.listOfLinkTypeIds.length > 0) {
      this.LinkTypeId = this.listOfLinkTypeIds.join(',')
    }
    if (this.LinkName == "null") this.LinkName = " ";
    this.linkTypeService.GetAllLink(this.tokenKey, this.PageNumber, this.PageSize, this.LinkTypeId, this.stateId, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofLink = data['crralink'];
        if (this.listofLink != null) {
          if (this.listofLink.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofLink[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.LinkName = $event.target.value;
    else
      this.LinkName = 'null';
    this.resetPageNoandSize();
    this.GetLink();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowDescriptionCSS = '';
      if (sortBy === "Citation") {
        this.upDowCitationCSS = 'fa fa-arrow-down';
        this.upDowLinkTypeNameCSS = '';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "linkTypeName") {
        this.upDowCitationCSS = '';
        this.upDowLinkTypeNameCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "StateName") {
        this.upDowCitationCSS = '';
        this.upDowLinkTypeNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "Description") {
        this.upDowCitationCSS = '';
        this.upDowLinkTypeNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowDescriptionCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "Citation") {
        this.upDowCitationCSS = 'fa fa-arrow-up';
        this.upDowLinkTypeNameCSS = '';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "linkTypeName") {
        this.upDowCitationCSS = '';
        this.upDowLinkTypeNameCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "StateName") {
        this.upDowCitationCSS = '';
        this.upDowLinkTypeNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "Description") {
        this.upDowCitationCSS = '';
        this.upDowLinkTypeNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowDescriptionCSS = 'fa fa-arrow-up';
      }
    }
    this.resetPageNoandSize();
    this.GetLink();
  }

  addLink() {
    let navigation = '/admin/' + this.SelectedTool + '/link/manage';
    this.router.navigate([navigation]);
  }

  updateLink(LinkId) {
    let navigation = '/admin/' + this.SelectedTool + '/link/manage/' + LinkId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.LinkId = id;
  }

  deleteLink(LinkId) {
    this.isloading = true;
    this.crraLinkMaster.DeleteLink(this.tokenKey, LinkId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.showMessage("Link Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.GetLink();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetLink();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetLink();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetLink();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.resetPageNoandSize();
    this.GetLink();
  }


  onSelectLinkType($event) {
    this.listOfLinkTypeIds = this.removeElemetninArray(this.listOfLinkTypeIds, $event.id)
    this.listOfLinkTypeIds.push($event.id);
    
    this.resetPageNoandSize();
    this.GetLink();
  }

  onDeSelectLinkType($event) {
    if (this.listOfLinkTypeIds != null) {
      if (this.listOfLinkTypeIds.length > 0) {
        this.listOfLinkTypeIds = this.removeElemetninArray(this.listOfLinkTypeIds, $event.id)
      }
    }
    if (this.listOfLinkTypeIds.length > 0) {
      this.stateId = this.listOfLinkTypeIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetLink();
  }

  onSelectAllLinkType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfLinkTypeIds != null) {
        if (this.listOfLinkTypeIds.length > 0) {
          this.listOfLinkTypeIds = this.removeElemetninArray(this.listOfLinkTypeIds, $event[iCounter].id)
          this.listOfLinkTypeIds.push($event[iCounter].id);
        }
        else {
          this.listOfLinkTypeIds.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetLink();
  }

  onDeSelectAllLinkType($event) {
    this.listOfLinkTypeIds = [];
    this.resetPageNoandSize();
    this.GetLink();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetLink();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetLink();
  }

  onFilterSelectAllLinkType($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfLinkTypeIds != null) {
        if (this.listOfLinkTypeIds.length > 0) {
          this.listOfLinkTypeIds = this.removeElemetninArray(this.listOfLinkTypeIds, $event[iCounter].id)
          this.listOfLinkTypeIds.push($event[iCounter].id);
        }
        else {
          this.listOfLinkTypeIds.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetLink();
  }

  onFilterDeSelectAllLinkType($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfLinkTypeIds != null) {
        if (this.listOfLinkTypeIds.length > 0) {
          this.listOfLinkTypeIds = this.removeElemetninArray(this.listOfLinkTypeIds, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetLink();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}

