export class TRSNoticeModel {
    trsId: any;
    stateId: any;
    directoryDocName: any;
    directoryDocPath: any;
    billInsertDocName: any;
    billInsertDocPath: any;
    url: string;
    createdBy: any;
    createdDate: any;
    modifiedBy: any;
    modifiedDate: any;
    totalRecordCount: any;
    isFavorite?:any;
    billInsertLabel:any;
    directoryPageLabel:any;
}

export class FilterTRSModel{
    pageNumber: number;
    pageSize: number;
    orderBy?: string;
    stateID?: string | number;
    userID?:number;
    isFavorite?:number;
}