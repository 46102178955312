import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { Alerts911Model } from 'src/Models/TrfaAlertsModel';
import { TrfaAlertService } from 'src/Services/alert.service';

@Component({
  selector: 'app-alerts911',
  templateUrl: './alerts911.component.html',
  styleUrls: ['./alerts911.component.css']
})
export class Alerts911Component implements OnInit {

  tokenKey: string = '';
  isloading: boolean = false;
  filteModel = new FilterModel();
  Alerts911Model: Alerts911Model[];
  totalRecordCount: number = -1;
  isAscending: boolean;
  updateId: number = 0;

  upDownAlertDateCSS: string = '';
  upDownNoteCSS: string = '';
  upDownFundCSS: string ='';
  upDownStatusCSS: string = '';
  upDownCreatedDateCSS: string = '';
  upDownModifiedDateCSS: string = '';
  Id: number = 0;
  IsSendEmail: boolean
  productId:number;
  openMenuIndex: number = -1;

  constructor(private toastr: ToastrService, private router: Router,private trfaAlertServices:TrfaAlertService,private titleService:Title) { 
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.productId=ToolEnum.state911;
    this.titleService.setTitle('911 Alerts');
  }

  ngOnInit() {
    this.filteModel.pageNumber = '1';
    this.filteModel.pageSize = '10';
    this.filteModel.keywordFilter = '';
    this.filteModel.orderBy = 'UpdateId';
    this.filteModel.updateId=0;
    this.Get911AlertsFindByPages();
  }

  Get911AlertsFindByPages() {
    this.isloading = true;
    if (this.filteModel.keywordFilter == "null") this.filteModel.keywordFilter = " ";
    this.trfaAlertServices.Get911AlertsFindByPages(this.tokenKey, this.filteModel).subscribe(data => {
      if (data['status'] == 'Success') {
        this.isloading = false;
        this.Alerts911Model = data['alert911List'];
        if (this.Alerts911Model != null) {
          if (this.Alerts911Model.length > 0) {
            this.totalRecordCount = +this.Alerts911Model[0].totalRowCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      }
      else {
        this.showMessage(data['status'], 'Error', 'error');
      }

    },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }


  keywordFilters($event) {
    this.filteModel.keywordFilter = $event.target.value;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filteModel.keywordFilter = $event.target.value;
    }
    else { this.filteModel.keywordFilter = ''; }

    this.Get911AlertsFindByPages();
  }

  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.filteModel.orderBy = sortBy + ' DESC';
      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.filteModel.orderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.Get911AlertsFindByPages();
  }

  setDescCSS(sortBy) {
    if (sortBy === "updateDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-down';
      this.upDownNoteCSS = '';
      this.upDownFundCSS = '';
    }
    else if (sortBy === "note") {
      this.upDownAlertDateCSS = '';
      this.upDownNoteCSS = 'fa fa-arrow-down';
      this.upDownFundCSS = '';
    }
    else if (sortBy === "fundName") {
      this.upDownAlertDateCSS = '';
      this.upDownNoteCSS = '';
      this.upDownFundCSS = 'fa fa-arrow-down';
    }

  }

  setAscCSS(sortBy) {
    if (sortBy === "updateDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-up';
      this.upDownNoteCSS = '';
      this.upDownFundCSS = '';
    }
    else if (sortBy === "note") {
      this.upDownAlertDateCSS = '';
      this.upDownNoteCSS = 'fa fa-arrow-up';
      this.upDownFundCSS = '';
    }
    else if (sortBy === "fundName") {
      this.upDownAlertDateCSS = '';
      this.upDownNoteCSS = '';
      this.upDownFundCSS = 'fa fa-arrow-up';
    }
  }

  pageChanged($event) {
    this.filteModel.pageNumber = parseInt($event.page).toString();
    this.Get911AlertsFindByPages();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  redirectToEditView(updateId: any) {
    this.router.navigate(['/admin/911/911-Alerts/user/' + updateId]);
  }

  SetCancelMailId(updateId: any) {
    this.Id = updateId;
  }

  CancelMail()
  {
    this.trfaAlertServices.notsentAlertmail(this.tokenKey, this.Id,this.productId).subscribe(
      data => {
      if (data['status'] == 'Success') {
        this.showMessage('', 'Success', 'success');
        this.Get911AlertsFindByPages();
      }
      else
        this.showMessage(data['status'], 'Error', 'error');
    },
    error => {
      this.showMessage(error, 'Error', 'error');
    }
  );
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
