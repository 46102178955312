
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterModel } from 'src/Models/filterModel';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  AppUrl: string = "";
  filterModel = new FilterModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }
  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  GetIPRHistory(TokenKey: string, PageNumber: string, PageSize: string, WhereCondition: string, OrderBy: string, keywordFilter: string): Observable<any> {
    this.setAPIUrl();
    this.filterModel.pageNumber = PageNumber;
    this.filterModel.pageSize = PageSize;
    this.filterModel.whereCondition = WhereCondition;
    this.filterModel.orderBy = OrderBy;
    this.filterModel.keywordFilter = keywordFilter;

    let fullAPIPath = this.AppUrl + 'api/IPRUpdates/GetIPRHistoryFilter';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(fullAPIPath, this.filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
  

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
