import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseRateModel } from 'src/Models/BaseRateModel';
import { StateSelectionModel } from 'src/Models/StateSelectionModel';
import { StatusModel } from 'src/Models/StepMasterModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompleted, checkStepIsCompletedVendor, getIncompletedStepVendor } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';


@Component({
  selector: 'app-client-quote-state-selection',
  templateUrl: './client-quote-state-selection.component.html',
  styleUrls: ['./client-quote-state-selection.component.css']
})
export class ClientQuoteStateSelectionComponent implements OnInit {

  SelectedTool: string;
  tokenKey: any;
  isEdit: boolean = false;
  clientInformationId: number = 0;
  userID: any;

  baseRateList: BaseRateModel[];
  stateSelectionList: any[];

  isShowprogressSpinner: boolean;
  blockedDocument: boolean;

  selectedStateData: StateSelectionModel =
    {
      clientInformationId: 0,
      stateSelectionListDatas: [],
      createdBy: 0,
      priceQuoteId:0,
      isFront:false
    };

  isCompanyProfileDetailsCompleted: boolean;
  isAddCompQueCompleted: boolean;
  isStateSelectionCompleted: boolean;
  isAddOnServicesCompleted: boolean;
  isComplianceDetailsCompleted: boolean;
  isAttechedDetailsCompleted: boolean;
  currentActiveTab: number = 1;
  url: string;
  tabId: number = 0;

  mainUrl: string;
  subUrl: string;
  clientcompanyName: string;
  dbaComapnyName:string;
  isRecordAvailable:boolean=false;
  priceQuoteId:number=0;
  isStateList: boolean = true;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute, private globle: Globle,
    private router: Router, private PricingQuotesMaster: PricingQuotesService, private toastr: ToastrService,
    private titleService: Title) {
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.baseRateList = [];
    this.stateSelectionList = [];
    this.userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.clientcompanyName = sessionStorage.getItem('ClientCompanyName');
    this.dbaComapnyName = sessionStorage.getItem('DbaCompanyName')=='null'?null:sessionStorage.getItem('DbaCompanyName');
    this.titleService.setTitle('Quote Request');

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          // this.clientInformationId = params['id'];
          this.priceQuoteId = params['id'];
        }
      }
    });

    this.mainUrl = '/View/';
    if (this.priceQuoteId != null) {
      this.GetProgressStepDetailsById(this.priceQuoteId)
      this.subUrl = getIncompletedStepVendor(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isCompanyProfileDetailsCompleted = null;
      this.globle.cApp.statusModel.isAddCompQueCompleted = null;
      this.globle.cApp.statusModel.isStateSelectionCompleted = null;
      this.globle.cApp.statusModel.isAddOnServicesCompleted = null;
      this.globle.cApp.statusModel.isComplianceDetailsCompleted = null;
      this.globle.cApp.statusModel.isNotes=null;
      this.subUrl = getIncompletedStepVendor(this.globle.cApp.statusModel);
    }
  }

  ngOnInit() {

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetClientInformationById(params['id']);
        }
      }
    });

  }

  GetClientInformationById(priceQuoteId: number) {
    this.blockDocument();
    this.PricingQuotesMaster.GetStateSelection(this.tokenKey, priceQuoteId, false).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.stateSelectionList = result['statelist'];
          this.baseRateList = result['baseRate'];
          if (result['statelist'].length == 0) {
            this.isStateList = false;
          } else {
            this.isStateList = true;
          }
          if (this.baseRateList.length > 0) {
            this.isRecordAvailable = true;
          }
        }
      });
  }


  SelectBaserateForAllState(item) {
    var baseRateId = item.baseRateId;
    for (let iCounter = 0; iCounter < this.stateSelectionList.length; iCounter++) {
      if (this.stateSelectionList[iCounter].stateName != 'Puerto Rico' && this.stateSelectionList[iCounter].stateName != 'Canada') {
        if (this.stateSelectionList[iCounter].stateSelectionListDatas.filter(x => x.baseRateId == baseRateId).length > 0) {
          if (item.isSelected == true)
            this.stateSelectionList[iCounter].stateSelectionListDatas.filter(x => x.baseRateId == baseRateId).filter(y => y.isSelected = true)
          else
            this.stateSelectionList[iCounter].stateSelectionListDatas.filter(x => x.baseRateId == baseRateId).filter(y => y.isSelected = false)
        }
      }
    }
  }

  SelectStatesCheckAll(item) {
    if (this.stateSelectionList.filter(x => x.stateId == item.stateId).length > 0) {
      var statedataLength = this.stateSelectionList.filter(x => x.stateId == item.stateId)[0].stateSelectionListDatas.length;
      if (statedataLength > 0) {

        for (let iCounter = 0; iCounter < statedataLength; iCounter++) {
          if (item.isSelected == true)
            this.stateSelectionList.filter(x => x.stateId == item.stateId)[0].stateSelectionListDatas[iCounter].isSelected = true;
          else
            this.stateSelectionList.filter(x => x.stateId == item.stateId)[0].stateSelectionListDatas[iCounter].isSelected = false;
        }
      }
    }
  }

  SelectedStatesCheck(detail) {
    var data = detail;
  }

  
  ActiveSelectBaserateForAllState(item) {
    var baseRateId = item.baseRateId;
    for (let iCounter = 0; iCounter < this.stateSelectionList.length; iCounter++) {
      if (this.stateSelectionList[iCounter].stateName != 'Puerto Rico' && this.stateSelectionList[iCounter].stateName != 'Canada') {
        if (this.stateSelectionList[iCounter].stateSelectionListDatas.filter(x => x.baseRateId == baseRateId).length > 0) {
          if (item.isActiveSelected == true)
            this.stateSelectionList[iCounter].stateSelectionListDatas.filter(x => x.baseRateId == baseRateId).filter(y => y.isActiveSelected = true)
          else
            this.stateSelectionList[iCounter].stateSelectionListDatas.filter(x => x.baseRateId == baseRateId).filter(y => y.isActiveSelected = false)
        }
      }
    }
  }

  previousStep() {
    let navigation = '/View/client-quote-add-comp-que/' + this.priceQuoteId;
    this.router.navigate([navigation]);
  }

  save(clientForm: any) {
    let data = clientForm.value;

    this.selectedStateData.clientInformationId = this.clientInformationId;
    this.selectedStateData.stateSelectionListDatas = this.stateSelectionList;
    this.selectedStateData.priceQuoteId = this.priceQuoteId;
    this.selectedStateData.createdBy = this.userID;
    this.selectedStateData.isFront = false;

    let isStateSelected = false;

    if (this.stateSelectionList.length > 0) {
      for (let iCounter = 0; iCounter < this.stateSelectionList.length; iCounter++) {
        if (isStateSelected == false) {
          if (this.stateSelectionList[iCounter].stateSelectionListDatas.filter(x => x.isSelected == true).length > 0) {
            isStateSelected = true;
          }
        }
      }
    }
    if (isStateSelected == false) {
      if (this.isStateList) {
        this.showMessage('Please Select State', 'Error', 'error');
        this.unblockDocument();
        return;
      }
    }
    if (isStateSelected == false) {
      let navigation = '/View/client-quote-add-on-services/' + this.priceQuoteId;
        this.router.navigate([navigation]);
    }else{

    this.blockDocument();
    this.PricingQuotesMaster.addUpdateSelectedStateData(this.selectedStateData, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        this.globle.cApp.statusModel.isStateSelectionCompleted = true;
        let navigation = '/View/client-quote-add-on-services/' + data['clientInformationId'];
        this.router.navigate([navigation]);
      }
      else {
        this.showMessage(data.status, 'Error', 'error');
        this.unblockDocument();
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  activeTab(url: string, tabId: number) {

    if (this.priceQuoteId != null) {
      if (checkStepIsCompletedVendor(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepVendor(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompletedVendor(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepVendor(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsById(this.tokenKey, this.priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStepVendor(this.globle.cApp.statusModel);
          this.activeTab('client-quote-state-selection', 3);
        }
      });
  }

}