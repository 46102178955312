import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CountyMasterService {
  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  GetAllCounty(TokenKey: string, PageNumber: number, PageSize: number, filterText: string, stateId: string,orderBy:string): Observable<any> {
    this.setAPIUrl();
    
    let data = 'api/countyMaster/GetAllCounty/' + filterText + '/' + PageNumber + '/' + PageSize + '/' + stateId+'/'+orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CreateOrUpdateCounty(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/countyMaster/CreateOrUpdateCounty';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCountyById(TokenKey: string, countyId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/countyMaster/GetCountyById/' + countyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteCounty(TokenKey: string, countyId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/countyMaster/DeleteCounty/' + countyId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
  GetCountyByStateId(TokenKey: string, stateId: string,IsAdmin : boolean): Observable<any> {
    this.setAPIUrl();
    let data = 'api/countyMaster/GetCountyByStateId/' + stateId+ '/' +IsAdmin;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

}
