import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CheckAuth } from "src/Services/utility.service";
import { Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { TRFAContentGetModel,TRFAGetUpdateViewModel } from "src/Models/TRFAContentModel";
import { StateMasterService } from "src/Services/state-master.service";
import { StateMasterModel } from "src/Models/stateMasterModel";
import { TRFAFundModel } from "src/Models/TRFAFundModel";
import { TrfaFundMasterService } from "src/Services/trfa-fund-master.service";
import { TrfaContentMasterService } from "src/Services/trfa-content-master.service";
import { HtmlToPdf } from "src/Services/htmlToPdf";
import * as FileSaver from 'file-saver';
import { TRFAFundTypeModel } from "src/Models/TRFAFundTypeModel";
import { TrfaCategoryMasterService } from "src/Services/trfa-category-master.service";
import { TrfaFundTypeMasterService } from "src/Services/trfa-fund-type--master.service";
import { TrfaCarrierMasterService } from "src/Services/trfa-carrier--master.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CompanyService } from "src/Services/company.service";
import { ApplicationActivityStore } from "src/common/commonFunction";
import { ActionActivity, EnumFavorites, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { UsersService } from "src/Services/users.service";
import { FavoriteFilterModel } from "src/Models/FavoriteFilterModel";
import { FavoriteService } from "src/Services/favorite.service";
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: "app-trfa-content-view",
  templateUrl: "./trfa-content-view.component.html",
  styleUrls: ["./trfa-content-view.component.css"],
})
export class TrfaContentViewComponent implements OnInit {
  stateCode: any;
  isGetDetailbyId: boolean = false;
  tokenKey: string;
  listofContent: TRFAContentGetModel[];
  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = " ";
  stateList: StateMasterModel[] = [];
  //Fund
  fundID: string = " ";
  dropdownListFund = [];
  selectedItemsFund = [];
  dropdownSettingsFund = {};
  listOffundIDs = [];
  fundList: TRFAFundModel[] = [];
  module = "TRFA";
  stateIds: string = " ";
  OrderByName: string = "";
  dropdownDateSelectedColumnFilter = [];
  selectedColumn = [];
  listOfColumnId = [];
  dropdownSettingsDateselectedColumnFilter = {};
  upDowCarrierCSS: string = "";
  upDowCategoryCSS: string = "";
  upDowRuleCitationCSS: string = "";
  upDowSubcategoryCSS: string = "";
  upDowRuleTextCSS: string = "";
  upDowStateNameCSS: string = "";
  upDowEffectiveDateNameCSS: string = "";
  selectedPageSize = [];
  pageSizeOptions: any[] = [];
  dropdownSettingsDatePageSizeFilter = {};
  listofMain911PrintData: any[] = [];
  baseUrl: string;
  @ViewChild('deletecloseBtn', { static: false }) deletecloseBtn: ElementRef;
  isShowprogressSpinner: boolean = false;
  blockedDocument: boolean = false;
  dropdownListCarrier = [];
  selectedItemsCarrier = [];
  dropdownSettingsCarrier = {};
  listOfCarrierIDs = [];
  carrierList: any[] = [];
  FundTypeId: string = ' ';
  dropdownListFundType = [];
  selectedItemsFundType = [];
  dropdownSettingsFundType = {};
  listOfFundTypeIds = [];
  fundTypeList: TRFAFundTypeModel[] = [];
  carrierId: string = ' ';
  categoryList: any[] = [];
  dropdownDateCategoryFilter = [];
  selectedItemsCategory = [];
  listOfCategoryIDs = [];
  dropdownSettingsDateCategoryFilter = {};
  categoryId: string = ' ';
  stateLinkData: TRFAGetUpdateViewModel;
  isupdatesloading: boolean = false;
  stateSubscription: any;
  _userID: string = "";
  updateFundId = "";
  statefilterData: any;
  SearchItem: string;
  // canDownload: boolean = false;
  userId: number
  showhidecollpase: boolean = false;
  colexpandText: string = "Expand All";
  isCollapsed: boolean = true;
  TRFAexportCitiationList: any[] = [];
  fundCount: number = 0;
  ProductId:number = 0;

  SelectedStateFav: number = 0;
  favouriteStateModel: FavoriteFilterModel[] = [];
  displayFavorite:number=0;

  src = '';
  isPdfLoaded = false;
  private pdf: PDFDocumentProxy;
  PDFDisplaying:boolean=false


  onLoaded(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.isPdfLoaded = true;
  }

  constructor(
    private appActivity: ApplicationActivityService,
    private router: Router,
    private trfaContentMaster: TrfaContentMasterService,
    private checkAuth: CheckAuth,
    private titleService: Title,
    private toastr: ToastrService,
    private stateService: StateMasterService,
    private trfaFundMaster: TrfaFundMasterService,
    private htmlToPdf: HtmlToPdf,
    private trfaFundTypeMaster: TrfaFundTypeMasterService, private modalService: NgbModal, private trfaCarrierMasterService: TrfaCarrierMasterService, private categoryMasterService: TrfaCategoryMasterService,
    private activedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private userService: UsersService,   
    private favorite: FavoriteService,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.tokenExpireCalculation(
      sessionStorage.getItem("appStartTime"),
      sessionStorage.getItem("tokenExpiryValue")
    );
    this.listofContent = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = "null";
    this.OrderBy = "RuleText DESC";
    this.Note = "null";
    this.SelectedTool = sessionStorage.getItem("UserLoginTool");
    this.titleService.setTitle("States");
    this.getColumn();
    this.baseUrl = baseUrl;
    this._userID = sessionStorage.getItem('UserId');
    this.ProductId = ToolEnum.trfa;
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.src = "http://localhost:37022/pdf/RulesDetails.pdf";
    

    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Jurisdiction(s) available",
      showCheckbox: true,
    };
    this.dropdownSettingsFund = {
      singleSelection: false,
      text: "Filter by Fund",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Fund(s) available",
      showCheckbox: true,
    };
    this.dropdownSettingsDateselectedColumnFilter = {
      singleSelection: false,
      text: "Show Column",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Column(s) available",
      showCheckbox: true,
    };
    this.dropdownSettingsDatePageSizeFilter = {
      singleSelection: true,
      text: "Filter by Page",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Page(s) available",
      showCheckbox: false,
      escapeToClose: false,
    };
    this.dropdownSettingsCarrier = {
      singleSelection: false,
      text: "Filter by Carrier",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Carrier(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsFundType = {
      singleSelection: false,
      text: "Filter by Fund Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No FundType(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsDateCategoryFilter = {
      singleSelection: false,
      text: "Filter by Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Category(s) available',
      showCheckbox: true
    };
    this.pageSizeOptions = [
      { id: 1, itemName: 10 },
      { id: 2, itemName: 50 },
      { id: 3, itemName: 100 },
      { id: 4, itemName: 200 },
    ];
    this.selectedPageSize.push({ id: 1, itemName: 10 });
    this.tokenKey = sessionStorage.getItem("tokenValue");
    this.getSubscriptionState();
    //this.getState();
    // this.getFund();
    this.getCarrier();
    this.getFundType();
    this.getCategory();
    // this.getCanDownload();
    this.isloading = true;
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          let updateFundName = sessionStorage.getItem('updateFundName')!=null?sessionStorage.getItem('updateFundName').toString():null;
          if(updateFundName!=null)
          {
            this.GetfundByName(updateFundName);
          }
          else
          {
          this.GetContentById(params['id']);
          this.updateFundId = params['id']
          this.isGetDetailbyId = true;
          }
          // this.isEdit = true;
          // this.GetContentById(params['id']);
        }
        else { this.updateFundId = ""; }
      }
    });
    this.TRFAExportCitiationSubscriptionGet();

    $(function () {
      $("#content").css("fontSize", "32px");
    });
    
    this.GetContent();
  }
  getFundType() {
    this.trfaFundTypeMaster.GetFundType(this.tokenKey).subscribe(
      data => {
        this.fundTypeList = data['fundType'];
        this.dropdownListFundType = [];
        if (this.fundTypeList.length > 0) {
          this.fundTypeList.forEach(x => {
            this.dropdownListFundType.push({ "id": x.fundTypeID, "itemName": x.fundTypeName })
          });
        }
      },
      error => { }
    );
  }
  getCarrier() {
    this.trfaCarrierMasterService.GetCarrier(this.tokenKey).subscribe(
      data => {
        this.dropdownListCarrier = []
        this.carrierList = data['carrier'];
        if (this.carrierList.length > 0) {
          this.carrierList.forEach(x => {
            this.dropdownListCarrier.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }
  getFund() {
    this.stateId = " ";
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(",");
    }
    this.trfaFundMaster.GetFund(this.tokenKey, this.stateId, false, false).subscribe(
      (data) => {
        this.dropdownListFund = [];
        this.fundList = data["fund"];
        this.dropdownListFund = data["fund"];
        this.selectedItemsFund = [];
        if (this.fundList.length > 0) {
          let b = [];
          this.selectedItemsFund = [];
          this.fundList.map((x) => {
            let a = this.listOffundIDs.filter((e) => e == x.fundID);
            if (a.length > 0) {
              b.push(x.fundID);
              this.selectedItemsFund.push(x);
            }
          });
          this.listOffundIDs = b;
        } else {
          this.selectedItemsFund = [];
          this.listOffundIDs = [];
        }
      },
      (error) => { }
    );
  }
  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    ApplicationActivityStore('View', 'TRFA Funds' + $event.page, this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/contentView');
    this.GetContent();
  }

  GetContent() {
    this.showhidecollpase = false;
    this.colexpandText="Expand All";
    this.isloading = true;
    this.fundID = ' ';
    if (this.listOfFundTypeIds.length == 0 && this.listOfStateIds.length == 0 && this.listOfCarrierIDs.length == 0 && this.listOfCategoryIDs.length == 0 && this.updateFundId == "") {
      this.isloading = false;
      this.listofContent = [];
      return;
    }
    if (this.listOfFundTypeIds.length > 0) {
      this.fundID = this.listOfFundTypeIds.join(',')
    }
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.carrierId = ' ';
    if (this.listOfCarrierIDs.length > 0) {
      this.carrierId = this.listOfCarrierIDs.join(',')
    }
    this.categoryId = ' ';
    if (this.listOfCategoryIDs.length > 0) {
      this.categoryId = this.listOfCategoryIDs.join(',')
    }
    if (this.Note == "null") this.Note = " ";    
    let listofstatename = "";
      this.listOfStateIds.forEach((value, y) => {
        const statemodal = this.stateList.filter(x => x.stateId == value);
        if(this.listOfStateIds.length-1==y)
        {         
         listofstatename = listofstatename + statemodal[0].name + '<br />';
        }
        else{
          listofstatename = listofstatename + statemodal[0].name + ',' + '<br />';
        }
      });

      if (listofstatename != "") {
        listofstatename = listofstatename.trim();
        ApplicationActivityStore('View', 'TRFA Fund-' ,this.appActivity, ToolEnum.state911, ActionActivity.State911, '/View/rateLookup',listofstatename);
      }

      this.trfaContentMaster.GetAllContentByUser(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, this.fundID, this.updateFundId, this.carrierId, this.categoryId, this.OrderBy, this.Note, this.baseUrl,parseInt(this._userID),this.displayFavorite).subscribe(
      data => {
        this.isloading = false;
        this.listofContent = data['content'];
        if (this.listofContent != null) {
          if (this.listofContent.length == 0) this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
          }
        } else this.isRecord = true;
      },
      (error) => {
        this.stateCode = error;
      }
    );
  }
  callDivclose() {
    var data = "";
  }
  GetContentById(fundId: string) {
    this.isloading = true;
    // this.fundID = ' ';
    // if (this.listOfFundTypeIds.length == 0 && this.listOfStateIds.length ==0 && this.listOfCarrierIDs.length==0 && this.listOfCategoryIDs.length==0)
    // {
    //   this.isloading = false;
    // return;
    // }
    // if (this.listOfFundTypeIds.length > 0) {
    //   this.fundID = this.listOfFundTypeIds.join(',')
    // }
    // this.stateId = ' ';
    // if (this.listOfStateIds.length > 0) {
    //   this.stateId = this.listOfStateIds.join(',')
    // }
    // this.carrierId = ' ';
    // if (this.listOfCarrierIDs.length > 0) {
    //   this.carrierId = this.listOfCarrierIDs.join(',')
    // }
    // this.categoryId = ' ';
    // if (this.listOfCategoryIDs.length > 0) {
    //   this.categoryId = this.listOfCategoryIDs.join(',')
    // }
    // if (this.Note == "null") this.Note = " ";
    this.trfaContentMaster.GetAllContentByUser(this.tokenKey, 1, 10, "", "", fundId, "", "", "RuleText DESC", "", this.baseUrl,parseInt(this._userID),this.displayFavorite).subscribe(
      (data) => {
        this.isloading = false;
        this.listofContent = data['content'];
        if (this.listofContent != null) {
          if (this.listofContent.length == 0) this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
          }
        } else this.isRecord = true;
      },
      (error) => {
        this.stateCode = error;
      }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else this.Note = "null";

    this.GetContent();
  }

  sort(sortBy: string) {
    this.upDowCarrierCSS = "";
    this.upDowCategoryCSS = "";
    this.upDowRuleCitationCSS = "";
    this.upDowSubcategoryCSS = "";
    this.upDowRuleTextCSS = "";
    this.upDowEffectiveDateNameCSS = "";
    this.upDowStateNameCSS = "";

    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + " DESC";

      if (sortBy === "Carrier") {
        this.upDowCarrierCSS = "active-up";
      } else if (sortBy === "Category") {
        this.upDowCategoryCSS = "active-up";
      } else if (sortBy === "RuleCitation") {
        this.upDowRuleCitationCSS = "active-up";
      } else if (sortBy === "Subcategory") {
        this.upDowSubcategoryCSS = "active-up";
      } else if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = "active-up";
      } else if (sortBy === "EffectiveDate") {
        this.upDowEffectiveDateNameCSS = "active-up";
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = "active-up";
      }
    } else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "Carrier") {
        this.upDowCarrierCSS = "active";
      } else if (sortBy === "Category") {
        this.upDowCategoryCSS = "active";
      } else if (sortBy === "RuleCitation") {
        this.upDowRuleCitationCSS = "active";
      } else if (sortBy === "Subcategory") {
        this.upDowSubcategoryCSS = "active";
      } else if (sortBy === "RuleText") {
        this.upDowRuleTextCSS = "active";
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowEffectiveDateNameCSS = "active";
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = "active";
      }
    }
    this.OrderByName = sortBy;
    this.GetContent();
  }

  ContentContent(ContentId) {
    let navigation =
      "/admin/" + this.SelectedTool + "/content/manage/" + ContentId;
    this.router.navigate([navigation]);
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == "success") {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    } else if (msgType == "error") {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    } else if (msgType == "warning") {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter((e) => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(
      this.listOfStateIds,
      $event.id
    );
    this.listOfStateIds.push($event.id);
    this.stateId = " ";
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(",");
    }
    this.getFund();
    this.GetContent();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(
          this.listOfStateIds,
          $event.id
        );
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(",");
    }
    this.getFund();
    this.GetContent();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(
            this.listOfStateIds,
            $event[iCounter].id
          );
          this.listOfStateIds.push($event[iCounter].id);
        } else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(",");
    }
    this.getFund();
    this.GetContent();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.getFund();
    this.GetContent();
  }

  onSelectFund($event) {
    this.listOffundIDs = this.removeElemetninArray(
      this.listOffundIDs,
      $event.id
    );
    this.listOffundIDs.push($event.id);
    this.GetContent();
  }

  onDeSelectFund($event) {
    if (this.listOffundIDs != null) {
      if (this.listOffundIDs.length > 0) {
        this.listOffundIDs = this.removeElemetninArray(
          this.listOffundIDs,
          $event.id
        );
      }
    }
    if (this.listOffundIDs.length > 0) {
      this.stateId = this.listOffundIDs.join(",");
    }
    this.GetContent();
  }

  onSelectAllFund($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOffundIDs != null) {
        if (this.listOffundIDs.length > 0) {
          this.listOffundIDs = this.removeElemetninArray(
            this.listOffundIDs,
            $event[iCounter].id
          );
          this.listOffundIDs.push($event[iCounter].id);
        } else {
          this.listOffundIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onDeSelectAllFund($event) {
    this.listOffundIDs = [];
    this.GetContent();
  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      (data) => {
        this.stateList = data["state"];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach((x) => {
            this.dropdownListState.push({ id: x.stateId, itemName: x.name });
          });
        }
      },
      (error) => { }
    );
  }
  getColumn() {
    this.upDowCarrierCSS = "";
    this.upDowCategoryCSS = "";
    this.upDowRuleCitationCSS = "";
    this.upDowSubcategoryCSS = "";
    this.upDowRuleTextCSS = "";
    this.upDowEffectiveDateNameCSS = "";
    this.dropdownDateSelectedColumnFilter = [
      { id: 1, itemName: "Carrier" },
      { id: 2, itemName: "Category" },
      { id: 3, itemName: "Rule Citation" },
      { id: 4, itemName: "Subcategory" },
      { id: 5, itemName: "Rule Text" },
      { id: 6, itemName: "Effective Date" },
    ];
    this.selectedColumn = [
      { id: 1, itemName: "Carrier" },
      { id: 2, itemName: "Category" },
      { id: 3, itemName: "Rule Citation" },
      { id: 4, itemName: "Subcategory" },
      { id: 5, itemName: "Rule Text" },
      { id: 6, itemName: "Effective Date" },
    ];
  }
  isCheckColum(id) {
    let data = this.selectedColumn.filter((x) => x.id == id)[0];
    if (data != null) return true;
    else return false;
  }
  onSelectColumn($event) {
    this.listOfColumnId = this.removeElemetninArray(
      this.listOfColumnId,
      $event.id
    );
    this.listOfColumnId.push($event.id);
    if (this.listOfColumnId.length > 0) {
    }
  }

  onDeSelectColumn($event) {
    if (this.listOfColumnId != null) {
      if (this.listOfColumnId.length > 0) {
        this.listOfColumnId = this.removeElemetninArray(
          this.listOfColumnId,
          $event.id
        );
      }
    }
    if (this.listOfColumnId.length > 0) {
    }
  }

  onSelectAllColumn($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfColumnId != null) {
        if (this.listOfColumnId.length > 0) {
          this.listOfColumnId = this.removeElemetninArray(
            this.listOfColumnId,
            $event[iCounter].id
          );
          this.listOfColumnId.push($event[iCounter].id);
        } else {
          this.listOfColumnId.push($event[iCounter].id);
        }
      }
    }
  }
  onDeSelectAllColumn($event) {
    this.selectedColumn = [];
    this.listOfColumnId = [];
  }
  TRFAExportCitiationSubscriptionGet() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.userService.ExportCitiationSubscriptionGetByUser(this.tokenKey, parseInt(this._userID),this.ProductId).subscribe(
      data => {

        this.TRFAexportCitiationList = data['exportCitiation'];

      },
      error => { }
    );
  }

  validExport(id) {
    if (this.TRFAexportCitiationList.length > 0) {
      let data = this.TRFAexportCitiationList.find((x) => x.exportCitiationId == id);
      if (data != null) return true;
      else return false
    }
    else return false
    // return true;
  }
  getPageLength() {
    if (this.PageSize < this.totalRecordCount) return this.PageSize;
    else return this.totalRecordCount;
  }
  onChangePageSize(event) {
    if (this.selectedPageSize.length > 0)
      this.PageSize = this.selectedPageSize[0].itemName;
    else {
      this.selectedPageSize = [];
      this.PageSize = 10;
      this.selectedPageSize.push({ id: 1, itemName: 10 });
    }
    this.GetContent();
  }

  onDeSelectDateFilterPageSizeAll($event) {
    this.GetContent();
  }

  print() {
    this.showcollapse();
    ApplicationActivityStore('Download', 'TRFA Fund Print', this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/contentView');
    let mainURL = this.baseUrl;
    let url = '<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';
    let printContents, popupWin;
    printContents = url + document.getElementById('divPrint').innerHTML;
    popupWin = window.open('', 'PrintWindow', 'width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>        
      </head>
      <body onload="window.print();window.close()">${printContents}</body>
    </html>`
    );
    popupWin.document.close();

  }
  contentGetAllPrintData(isPDf: boolean) {

    this.isloading = true;
    this.fundID = ' ';
    if (this.listOffundIDs.length > 0) {
      this.fundID = this.listOffundIDs.join(',')
    }
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.Note == "null") this.Note = " ";
    this.stateId = this.listOfStateIds.join(',')
    this.trfaContentMaster.GetAllContentByUser(this.tokenKey, 1, 1000000000, this.stateId, this.fundID, " ", " ", " ", this.OrderBy, "", this.baseUrl,parseInt(this._userID),this.displayFavorite).subscribe(
      data => {
        this.isloading = false;
        this.listofMain911PrintData = data['content'];
        if (this.listofMain911PrintData != null) {
          if (this.listofMain911PrintData.length == 0) {
            this.isRecord = true;
          }
          else {
            this.isRecord = false;
          }
        }
        else {
          this.isRecord = true;
        }
        setTimeout(() => {
          if (isPDf) this.createMyPdf();
          else this.print();
        }, 2000);
      },
      error => { }
    );
  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  onSelectCarrier($event) {
    this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
    this.listOfCarrierIDs.push($event.id);
    this.GetContent();
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIDs != null) {
      if (this.listOfCarrierIDs.length > 0) {
        this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
      }
    }
    if (this.listOfCarrierIDs.length > 0) {
      this.carrierId = this.listOfCarrierIDs.join(',')
    }
    this.GetContent();
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIDs = [];
    this.GetContent();
  }

  replaceCommaLine(data) {
    let list = data.split(",").join("<br />")
    return list
  }

  onSelectFundType($event) {
    this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event.id)
    this.listOfFundTypeIds.push($event.id);
    this.GetContent();
  }

  onDeSelectFundType($event) {
    if (this.listOfFundTypeIds != null) {
      if (this.listOfFundTypeIds.length > 0) {
        this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event.id)
      }
    }
    if (this.listOfFundTypeIds.length > 0) {
      this.fundID = this.listOfFundTypeIds.join(',')
    }
    this.GetContent();
  }

  onSelectAllFundType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfFundTypeIds != null) {
        if (this.listOfFundTypeIds.length > 0) {
          this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event[iCounter].id)
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
        else {
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onDeSelectAllFundType($event) {
    this.listOfFundTypeIds = [];
    this.GetContent();
  }
  getCategory() {
    this.categoryMasterService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryList = result['category'];
          this.dropdownDateCategoryFilter = [];
          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilter.push({ "id": x.categoryID, "itemName": x.categoryName })
          });
        }
      },
      error => { }
    );
  }
  onSelectCategory($event) {
    this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
    this.listOfCategoryIDs.push($event.id);
    this.GetContent();
  }

  onDeSelectCategory($event) {
    if (this.listOfCategoryIDs != null) {
      if (this.listOfCategoryIDs.length > 0) {
        this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event.id)
      }
    }
    this.GetContent();
  }

  onSelectAllCategory($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }
  replaceCommaLines(data) {
    let str = '';
    let dataToArray = data.split(',').map(item => item.trim());
    // let list = data.split(",").join("<br />")
    dataToArray.forEach((element, index) => {

      if ((dataToArray.length - 1) == index) {
        str = str + element;
      }
      else {
        if (((index + 1) % 3) == 0) {
          str = str + element + "<br />";
        }
        else { str = str + element + ','; }
      }
    });

    return str
  }
  onDeSelectAllCategory($event) {
    this.listOfCategoryIDs = [];
    this.GetContent();
  }
  openModel(content, item) {
    this.stateLinkData = null;
    this.isupdatesloading = true;
    this.stateLinkData = item;
    var elem = document.getElementById("validationModal");
    elem.style.display='none'
    this.modalService.open(content, { centered: true });
    setTimeout(() => {
      this.isupdatesloading = false;
    }, 2000);
  }
  citationUrl(item) {
    if (item.url) {
      window.open(item.url, '_blank')
    }
  }

  createMyPdf() {
    this.showcollapse();
    this.blockDocument();

    $(".collapse").removeClass("hide")
      $(".collapse").addClass("show");
      this.colexpandText="Collapse All";
      this.showhidecollpase = true;
      if(this.listofContent.length>0)
      {
        for (let iCounter = 0; iCounter < this.listofContent.length; iCounter++) {
          var id='sign-'+iCounter;
           document.getElementById(id).setAttribute('aria-expanded', 'true');
        

        for (let cnt = 0; cnt < this.listofContent[iCounter].fundCount; cnt++) {
          var fundsignid = 'sign1-'+iCounter+'-'+ cnt;
          if (document.getElementById(fundsignid) != null)
            document.getElementById(fundsignid).setAttribute('aria-expanded', 'true');
        }
      }
      }
      
    let mainURL = this.baseUrl;
    let url = '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> '
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';
    
    var html = url + document.getElementById('divPrint').innerHTML;
    this.htmlToPdf.CreatePDF(this.tokenKey, html, '0',ToolEnum.trfa).
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.downloadFile('FundsDetails.pdf', data['url'], data['fileName']);
          this.deletecloseBtn.nativeElement.click();
        }
      },
        error => {
          this.unblockDocument();
        }
      );

  }
  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.htmlToPdf.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        ApplicationActivityStore('Download', 'TRFA File download ' + filename, this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, '/View/contentView');
        sessionStorage.setItem('fileloading', 'false');
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
  }

  getSubscriptionState() {
    this.stateSubscription = null;
    this.stateService.i360ManageSubscriptionClientId(this.tokenKey, this._userID, 0, true).subscribe(
      data => {
        this.stateSubscription = {
          wirelessStates: data.wirelessStates,
          wirelessStatesCount: data.wirelessStates ? data.wirelessStates.length : 0,
          iprStates: data.iprStates,
          iprStatesCount: data.iprStates ? data.iprStates.length : 0,
          voIPStates: data.voIPStates,
          voIPStatesCount: data.voIPStates ? data.voIPStates.length : 0,
          actionStates: data.actionStates,
          actionStatesCount: data.actionStates ? data.actionStates.length : 0,
          productAccess: data.productAccess,
          briefingStates: data.briefingStates,
          briefingStatesCount: data.briefingStates ? data.briefingStates.length : 0,
          state911: data.state911,
          state911Count: data.state911 ? data.state911.length : 0,
          carrierCategory: data.carrierCategory,
          carrierCategoryCount: data.carrierCategory ? data.carrierCategory.filter(m => m.isSelected == true).length : 0,
          trfa: data.trfa
        };
        this.stateList = data.trfa;
        if (!this.isGetDetailbyId) {
          if (this.stateList.length > 0) {
            this.statefilterData = this.stateList.filter(a => a.name == 'FCC');
            //this.statefilterData = this.stateList;
            if (this.statefilterData.length > 0) {
              this.stateId = " ";
              //this.listOfStateIds.push(this.statefilterData[0].stateId);
              //this.stateId = this.statefilterData[0].stateId;
              this.statefilterData.forEach(x => {
                this.listOfStateIds.push(x.stateId);
                this.selectedItemsState.push({ "id": x.stateId, "itemName": x.name })
              });
              this.stateId = this.listOfStateIds.join(',')
              this.GetContent();
            }
          }
        }
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { console.log(error); }
    );
  }

  clear() {

    this.stateId = '';
    this.FundTypeId = '';
    this.carrierId = '';
    this.categoryId = '';

    this.selectedItemsState = [];
    this.selectedItemsFundType = [];
    this.selectedItemsCarrier = [];
    this.selectedItemsCategory = [];
    this.listOfFundTypeIds = [];
    this.listOfStateIds = [];
    this.listOfCarrierIDs = [];
    this.listOfCategoryIDs = [];
    this.updateFundId = "";
    this.Note = "";
    this.SearchItem = "";

    if (this.stateList.length > 0) {
      this.statefilterData = this.stateList;
      if (this.statefilterData.length > 0) {
        this.stateId = " ";
        //this.listOfStateIds.push(this.statefilterData[0].stateId);
        //this.stateId = this.statefilterData[0].stateId;
        this.statefilterData.forEach(x => {
          this.listOfStateIds.push(x.stateId);
          this.selectedItemsState.push({ "id": x.stateId, "itemName": x.name })
        });
        this.stateId = this.listOfStateIds.join(',')
        this.GetContent();
      }
    }
    // this.listofContent=[];
    //this.resetPageNoandSize();
    //this.GetUpdate();
  }

  // getCanDownload() {
  //   this.userId = Number(this._userID);
  //   this.companyService.GetCompanybyUserId(this.tokenKey, this.userId).subscribe(
  //     data => {
  //       if (data != null) {
  //         if (data['result'] > 0) {
  //           this.canDownload = true;
  //         }
  //         else
  //           this.canDownload = false;
  //       }
  //     },
  //     error => { }
  //   );
  // }

  ShowHideCollapse() {
    this.fundCount = 0;
    if (this.listofContent.length > 0) {
      this.fundCount = this.fundCount + this.listofContent[this.listofContent.length - 1].fundCount;
    }
    if (this.showhidecollpase) {
      $(".collapse").removeClass("show")
      $(".collapse").addClass("hide")
      this.showhidecollpase = false;
      this.colexpandText = "Expand All";
      this.isCollapsed = true;
      if (this.listofContent.length > 0) {
        for (let iCounter = 0; iCounter < this.listofContent.length; iCounter++) {
          var statesignid = 'sign-' + iCounter;
          document.getElementById(statesignid).setAttribute('aria-expanded', 'false');
          for (let cnt = 0; cnt < this.listofContent[iCounter].fundCount; cnt++) {
            var fundsignid = 'sign1-' + iCounter + '-' + cnt;
            if (document.getElementById(fundsignid) != null)
              document.getElementById(fundsignid).setAttribute('aria-expanded', 'false');
          }
        }
      }
    }
    else {
      $(".collapse").removeClass("hide")
      $(".collapse").addClass("show");
      this.colexpandText = "Collapse All";
      this.showhidecollpase = true;
      this.isCollapsed = false;
      if (this.listofContent.length > 0) {
        for (let iCounter = 0; iCounter < this.listofContent.length; iCounter++) {
          var statesignid = 'sign-' + iCounter;
          document.getElementById(statesignid).setAttribute('aria-expanded', 'true');
          for (let cnt = 0; cnt < this.listofContent[iCounter].fundCount; cnt++) {
            var fundsignid = 'sign1-' + iCounter + '-' + cnt;
            if (document.getElementById(fundsignid) != null)
              document.getElementById(fundsignid).setAttribute('aria-expanded', 'true');
          }
          
        }
        
      }
    }
  }

  showcollapse() {
    $(".collapse").removeClass("hide")
    $(".collapse").addClass("show");
    this.colexpandText = "Collapse All";
    this.showhidecollpase = true;
    this.isCollapsed = false;
    if (this.listofContent.length > 0) {
      for (let iCounter = 0; iCounter < this.listofContent.length; iCounter++) {
        var statesignid = 'sign-' + iCounter;
        document.getElementById(statesignid).setAttribute('aria-expanded', 'true');
        for (let cnt = 0; cnt < this.listofContent[iCounter].fundCount; cnt++) {
          var fundsignid = 'sign1-' + iCounter + '-' + cnt;
          if (document.getElementById(fundsignid) != null)
            document.getElementById(fundsignid).setAttribute('aria-expanded', 'true');
        }
      }
    }
  }

  
  AddToFavourite(item, IsFavourite: number) {
    let stateId = item.stateID;
    if (IsFavourite == 0) {
      this.SelectedStateFav = stateId;
      this.favouriteStateModel = item;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavoriteState(stateId, IsFavourite, item);
    }
  }

  SaveFavoriteState(stateId: string, IsFavourite: number, item)
  {
    this.blockDocument();
    //document.getElementById("btnClose").click();
    this.favorite.ClientStateFavouriteAdd(this.tokenKey, parseInt(this._userID), stateId, IsFavourite, ToolEnum.trfa).subscribe(data => 
    {
      if (data["status"] == "Success") 
      {
        this.GetContent();
        if (IsFavourite == 1) {
          this.showMessage('Selected jurisdiction added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          this.showMessage('Selected jurisdiction removed successfully from favorite list.', 'Favorite', 'success');
        }        
      }
      else 
      {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
      }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
  }

  GetFavourite(displayFav:number)
  {
    this.displayFavorite=displayFav;
    this.GetContent();
  }

  GetfundByName(fundName: string) {
    this.isloading = true;
    this.trfaFundMaster.GetFundByName(this.tokenKey, fundName).subscribe(
      (data) => {
        this.isloading = false;
        if(data.fund!=null)
        {
          this.GetContentById(data.fund);
          this.updateFundId = data.fund;
          this.isGetDetailbyId = true;
        }
        else
        {
          this.GetContent();
        }
      },
      (error) => {
        this.stateCode = error;
      }
    );
  }

  onFilterSelectAll($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(
            this.listOfStateIds,
            $event[iCounter].id
          );
          this.listOfStateIds.push($event[iCounter].id);
        } else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(",");
    }
    this.getFund();
    this.GetContent();
  }

  onFilterDeSelectAll($event){
    
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(
            this.listOfStateIds,
            $event[iCounter].id
          );
        } 
      }
    }
    this.getFund();
    this.GetContent();
  }

  onFilterSelectAllFundType($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfFundTypeIds != null) {
        if (this.listOfFundTypeIds.length > 0) {
          this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event[iCounter].id)
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
        else {
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onFilterDeSelectAllFundType($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfFundTypeIds != null) {
        if (this.listOfFundTypeIds.length > 0) {
          this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event[iCounter].id)
        }
      }
    }
    this.GetContent();
  }

  onFilterSelectAllCarrier($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onFilterDeSelectAllCarrier($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
        }
      }
    }
    this.GetContent();
  }

  onFilterSelectAllCategory($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryIDs.push($event[iCounter].id);
        }
      }
    }
    this.GetContent();
  }

  onFilterDeSelectAllCategory($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryIDs != null) {
        if (this.listOfCategoryIDs.length > 0) {
          this.listOfCategoryIDs = this.removeElemetninArray(this.listOfCategoryIDs, $event[iCounter].id)
        }
      }
    }
    this.GetContent();
  }

  print1() {
    this.showcollapse();
    this.blockDocument();

    $(".collapse").removeClass("hide")
      $(".collapse").addClass("show");
      this.colexpandText="Collapse All";
      this.showhidecollpase = true;
      if(this.listofContent.length>0)
      {
        for (let iCounter = 0; iCounter < this.listofContent.length; iCounter++) {
          var id='sign-'+iCounter;
           document.getElementById(id).setAttribute('aria-expanded', 'true');
        

        for (let cnt = 0; cnt < this.listofContent[iCounter].fundCount; cnt++) {
          var fundsignid = 'sign1-'+iCounter+'-'+ cnt;
          if (document.getElementById(fundsignid) != null)
            document.getElementById(fundsignid).setAttribute('aria-expanded', 'true');
        }
      }
      }
      
    let mainURL = this.baseUrl;
    let url = '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> '
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.22/pdfmake.min.js"></script> ';
    url += '  <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.min.js"></script> ';
    url += '  <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.5.6/angular.min.js"></script>   ';
    url += ' <link rel="stylesheet" href="' + mainURL + '/assets/css/bootstrap.min.css" asp-append-version="true" /> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/AdminLTE.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ionicons.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/jquery-jvectormap.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all-skins.min.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/_all.css"> ';
    url += '<link id="adminstyle" rel="stylesheet" href="' + mainURL + '/assets/css/Admin/ContentView.css"> ';
    
    var html = url + document.getElementById('divPrint').innerHTML;
    this.htmlToPdf.CreatePDF(this.tokenKey, html, '0',ToolEnum.trfa).
      subscribe(data => {
        if (data['status'] == 'Success') {
          this.PrintPreviewPDF(data['url'],data['fileName']);
          this.deletecloseBtn.nativeElement.click();
        }
      },
        error => {
          this.unblockDocument();
        }
      );

    
  }

  PrintPreviewPDF(PDFPath:string,orignalFileName:any)
  {
    this.htmlToPdf.downloadPdf(PDFPath).subscribe(
      (res) => {
        this.blockDocument();
        // FileSaver.saveAs(res, filename);
        const blobUrl = window.URL.createObjectURL((res));
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        this.htmlToPdf.DeletePDF(this.tokenKey, orignalFileName).subscribe(
          data => {
            this.unblockDocument();
          },
        );
      }
    );
    this.unblockDocument();
  }
  
}

$(document).on("click", ".pagerlink", function() {
  if ($(this).find('a')[1].attributes[2].value.includes('22px')) {
    $(this).find('a').css({ "font-size": "20px" });
  }
  else {
    $(this).find('a').css({ "font-size": "22px" });
  }
}); 