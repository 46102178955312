export class HomePageBannerModel {
    bannerId?: any;
    title: string;
    leftDescription?: any;
    rightDescription?: any;
    isActive?: any;
    createdBy?: any;
    createdDate?: any;
    modifiedBy?: any;
    modifiedDate?: any;
    totalRecordCount:number;
}