import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TRFAFundGetModel, TRFAFundModel } from 'src/Models/TRFAFundModel';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { TrfaFundTypeMasterService } from 'src/Services/trfa-fund-type--master.service';
import { TRFAFundTypeGetModel, TRFAFundTypeModel } from 'src/Models/TRFAFundTypeModel';

@Component({
  selector: 'app-fund',
  templateUrl: './fund.component.html',
  styleUrls: ['./fund.component.css']
})
export class FundComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofFund: TRFAFundGetModel[]

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  FundName: string;


  upDowFundNameCSS: string = '';
  upDowFundTypeNameCSS: string = '';
  upDowStateNameCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];
  //FundType
  FundTypeId: string = ' ';
  dropdownListFundType = [];
  selectedItemsFundType = [];
  dropdownSettingsFundType = {};
  listOfFundTypeIds = [];
  fundTypeList: TRFAFundTypeModel[] = [];
  module = "TRFA";
  moduleList: any[] = [{ itemName: 'Active', id: 'Active' }, { itemName: 'Archive', id: 'Archive' },{ itemName: 'Draft', id: 'Draft' }];
  selectedItemsStatus = [];
  listOfStatus = [];
  statusId: string = ' ';
  dropdownSettingsStatus = {};
  FundId: number = 0;
  upDowSortOrderCSS: string = '';
  statefilterData: any;
  openMenuIndex: number = -1;

  constructor(private router: Router, private trfaFundMaster: TrfaFundMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService, private stateService: StateMasterService, private trfaFundTypeMaster: TrfaFundTypeMasterService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofFund = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'StateName,FundName,SortOrder';
    this.FundName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Funds');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsFundType = {
      singleSelection: false,
      text: "Filter by Fund Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Fund Type(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      text: "Filter by Status",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Status available',
      showCheckbox: true
    };
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.getFundType();
    this.isloading = true;
    this.GetFund();
  }
  getState() {
    
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }
  getFundType() {
    this.trfaFundTypeMaster.GetFundType(this.tokenKey, true).subscribe(
      data => {
        this.fundTypeList = data['fundType'];
        this.dropdownListFundType = [];
        if (this.fundTypeList.length > 0) {
          this.fundTypeList.forEach(x => {
            this.dropdownListFundType.push({ "id": x.fundTypeID, "itemName": x.fundTypeName })
          });
        }
      },
      error => { }
    );
  }
  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetFund();
  }


  GetFund() {
    this.isloading = true;
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.FundTypeId = ' ';
    if (this.listOfFundTypeIds.length > 0) {
      this.FundTypeId = this.listOfFundTypeIds.join(',')
    }
    if (this.FundName == "null") this.FundName = " ";
    this.trfaFundMaster.GetAllFund(this.tokenKey, this.PageNumber, this.PageSize, this.FundName, this.stateId, this.FundTypeId, this.OrderBy, this.statusId).subscribe(
      data => {
        this.isloading = false;
        this.listofFund = data['fund'];
        if (this.listofFund != null) {
          if (this.listofFund.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofFund[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.FundName = $event.target.value;
    else
      this.FundName = 'null';

    this.resetPageNoandSize();
    this.GetFund();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "FundName") {
        this.upDowFundNameCSS = 'fa fa-arrow-down';
        this.upDowFundTypeNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowSortOrderCSS = '';
      }
      else if (sortBy === "FundTypeName") {
        this.upDowFundNameCSS = '';
        this.upDowFundTypeNameCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
        this.upDowSortOrderCSS = '';
      }
      else if (sortBy === "stateName") {
        this.upDowFundNameCSS = '';
        this.upDowFundTypeNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowSortOrderCSS = '';
      }
      else if (sortBy === "sortOrder") {
        this.upDowFundNameCSS = '';
        this.upDowFundTypeNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "FundName") {
        this.upDowFundNameCSS = 'fa fa-arrow-up';
        this.upDowFundTypeNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowSortOrderCSS = '';
      }
      else if (sortBy === "FundTypeName") {
        this.upDowFundNameCSS = '';
        this.upDowFundTypeNameCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
        this.upDowSortOrderCSS = '';
      }
      else if (sortBy === "stateName") {
        this.upDowFundNameCSS = '';
        this.upDowFundTypeNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-up';
        this.upDowSortOrderCSS = '';
      }
      else if (sortBy === "sortOrder") {
        this.upDowFundNameCSS = '';
        this.upDowFundTypeNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-up';
      }
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  addFund() {
    let navigation = '/admin/' + this.SelectedTool + '/fund/manage';
    this.router.navigate([navigation]);
  }

  updateFund(FundId) {
    let navigation = '/admin/' + this.SelectedTool + '/fund/manage/' + FundId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.FundId = id;
  }

  deleteFund(FundId) {
    this.isloading = true;
    this.trfaFundMaster.DeleteFund(this.tokenKey, FundId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
            this.showMessage("Fund Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.GetFund();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.resetPageNoandSize();
    this.GetFund();
  }


  onSelectFundType($event) {
    this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event.id)
    this.listOfFundTypeIds.push($event.id);
    // this.stateId = ' ';
    // if (this.listOfFundTypeIds.length > 0) {
    //   this.stateId = this.listOfFundTypeIds.join(',')
    // }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onDeSelectFundType($event) {
    if (this.listOfFundTypeIds != null) {
      if (this.listOfFundTypeIds.length > 0) {
        this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event.id)
      }
    }
    if (this.listOfFundTypeIds.length > 0) {
      this.stateId = this.listOfFundTypeIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onSelectAllFundType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfFundTypeIds != null) {
        if (this.listOfFundTypeIds.length > 0) {
          this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event[iCounter].id)
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
        else {
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onDeSelectAllFundType($event) {
    this.listOfFundTypeIds = [];
    this.resetPageNoandSize();
    this.GetFund();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  onSelectStatus($event) {
    this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
    this.listOfStatus.push($event.id);
    this.statusId = ' ';
    if (this.listOfStatus.length > 0) {
      this.statusId = this.listOfStatus.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onDeSelectStatus($event) {
    if (this.listOfStatus != null) {
      if (this.listOfStatus.length > 0) {
        this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
      }
    }
    this.statusId='';
    if (this.listOfStatus.length > 0) {
      this.statusId = this.listOfStatus.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onSelectAllStatus($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStatus != null) {
        if (this.listOfStatus.length > 0) {
          this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event[iCounter].id)
          this.listOfStatus.push($event[iCounter].id);
        }
        else {
          this.listOfStatus.push($event[iCounter].id);
        }
      }
    }
    this.statusId='';
    if (this.listOfStatus.length > 0) {
      this.statusId = this.listOfStatus.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onDeSelectAllStatus($event) {
    this.statusId='';
    this.listOfStatus = [];
    this.resetPageNoandSize();
    this.GetFund();
  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onFilterSelectAllFundType($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfFundTypeIds != null) {
        if (this.listOfFundTypeIds.length > 0) {
          this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event[iCounter].id)
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
        else {
          this.listOfFundTypeIds.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onFilterDeSelectAllFundType($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfFundTypeIds != null) {
        if (this.listOfFundTypeIds.length > 0) {
          this.listOfFundTypeIds = this.removeElemetninArray(this.listOfFundTypeIds, $event[iCounter].id)
        }
      }
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onFilterSelectAllStatus($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStatus != null) {
        if (this.listOfStatus.length > 0) {
          this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event[iCounter].id)
          this.listOfStatus.push($event[iCounter].id);
        }
        else {
          this.listOfStatus.push($event[iCounter].id);
        }
      }
    }
    this.statusId='';
    if (this.listOfStatus.length > 0) {
      this.statusId = this.listOfStatus.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  onFilterDeSelectAllStatus($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStatus != null) {
        if (this.listOfStatus.length > 0) {
          this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event[iCounter].id)
        }
      }
    }
    this.statusId='';
    if (this.listOfStatus.length > 0) {
      this.statusId = this.listOfStatus.join(',')
    }
    this.resetPageNoandSize();
    this.GetFund();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
