import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity } from 'src/Models/Common';
import { ToolEnum } from 'src/Models/Common';
import { FavoriteFilterModel } from 'src/Models/FavoriteFilterModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { GetRate, Update911GetModel } from 'src/Models/Update911Model';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { FavoriteService } from 'src/Services/favorite.service';
import { Main911Service } from 'src/Services/main911.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { Updates911Service } from 'src/Services/updates911.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-update-view',
  templateUrl: './update-view.component.html',
  styleUrls: ['./update-view.component.css']
})
export class UpdateViewComponent implements OnInit {

  tokenKey: string;
  dropdownSettingsDateFilter = {};
  pageSizeOptions:any[]=[];
  selectedPageSize = [];
  SelectedTool: string;
  ProductId:number = 0;
  _userID:any;
  upDowNameCSS: string = '';
  isAscending: boolean = false;
  OrderByName: string = '';

  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateList: StateMasterModel[] = [];

  stateId: string = ' ';
  isActive: number;
  scopeId: string = '';
  RateList: GetRate[] = [];
  dropdownListRate = [];
  dropdownListRatetemp = [];
  dropdownSettingsRate = {};
  listOfRateIDs = [];

  PageNumber: number = 1;
  PageSize: number = 30;
  OrderBy: string;

  blockedDocument = false;
  isShowprogressSpinner = false;
  isloading: boolean = false;
  rateId: string = ' ';
  Note: string;
  isRecord: boolean = false;
  totalRecordCount: number = 0;
  stateCode: any;

  listofUpdate: Update911GetModel[];
  selectedColumn = [];
  isColumnExist:boolean =true;
  listOfColumnId = [];
  dropdownDateSelectedColumnFilter = [];

  upDowNoteCSS: string = '';
  upDowfundNameCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDowRateCSS: string = '';
  upDowFeeCSS: string = '';

  quaryValues:string='';
  selectedState = [];
  selectedRate = [];

  SelectedStateFav: number = 0;
  favouriteStateModel: FavoriteFilterModel[] = [];
  displayFavorite:number=0;

  constructor(private checkAuth: CheckAuth,private stateService: StateMasterService,
    private updates911: Updates911Service, private toastr: ToastrService,
    private appActivity: ApplicationActivityService,
    private favorite: FavoriteService,private activedRoute: ActivatedRoute,private main911Service: Main911Service
    ,private router: Router) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.ProductId = ToolEnum.state911;
    this._userID = sessionStorage.getItem('UserId');
    this.upDowNameCSS = 'active'
    this.isAscending = false;
    this.OrderBy = 'updateDate Desc';
    this.getColumn();
   }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsDateFilter = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsRate = {
      singleSelection: false,
      text: "Filter by Fund",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: true
    };

    this.pageSizeOptions=[
      { "id":1, "itemName": 10},
      { "id":2, "itemName": 50},
      { "id":3, "itemName": 100},
      { "id":4, "itemName": 200},
    ];
    this.selectedPageSize.push({ "id":1, "itemName": 10})
    sessionStorage.setItem('tokenValue', localStorage.getItem('tokenValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    
    this.getSubscriptionState();

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.quaryValues = params['id'];
          this.Get911NotificationHistory();
        }
        else {
          this.GetUpdate();
        }
      }
      else
      {this.GetUpdate();}
    });
  }

  
  Get911NotificationHistory()
  {

    this.blockDocument();
    this.main911Service.Get911NotificationHistory(this.quaryValues).subscribe(
      data => {
        this.isloading = false;
        this.listofUpdate = data['update'];
        if(data.status == 'Failure' && this.listofUpdate.length>0)
        {
          this.showMessage('No any update found', 'Warning', 'warning');
          this.router.navigate(['/Access/Denied/View Updates']);
        }
        else
        {
          if(this.listofUpdate.length>0)
          {
          this.blockedDocument = false;
          sessionStorage.setItem('trfa911UpdatehistoryUId', this.listofUpdate[0].userId.toString());
          if (data.status == 'Success' && (typeof (localStorage.getItem('UserName')) != 'undefined' && localStorage.getItem('UserName') != null && localStorage.getItem('UserName') != '')) {
            if(this.listofUpdate[0].userId.toString()===localStorage.getItem('UserId'))
            {
              setTimeout(() => {
                    this.isloading = false;
                    
                      if (this.listofUpdate.length == 0)
                        this.isRecord = true;
                      else {
                        this.isRecord = false;
                        this.totalRecordCount = this.listofUpdate.length;
                        data['update'].map((x) => {
                          let stateData=this.stateList.filter(y=>y.stateId==x.stateId)[0]
                          this.selectedItemsState.push({ "id": stateData.stateId, "itemName": stateData.name});
                          this.listOfStateIds.push(stateData.stateId);
                          this.selectedState.push({ "id": stateData.stateId, "itemName": stateData.name});
                          // let data = this.RateList.filter(y => y.rateId == x.FundId)[0];
                           this.listOfRateIDs.push(x.fundID);
                          this.stateId = ' ';
                          if (this.listOfStateIds.length > 0) {
                            this.stateId = this.listOfStateIds.join(',')
                          }
                        })
                        this.GetRateByStateIdandRateId();
                      }
              }, 2000);
              this.unblockDocument();
            }
            else
            this.router.navigate(['/Access/Denied/View Updates']);
          }
          else if (data.status == 'Success')
            this.router.navigate(['login/' + this.quaryValues]);
          else
            this.router.navigate(['login']);
        }
        else{
          this.blockedDocument = false;
          this.showMessage('Data Not Found', 'Warning', 'warning');
          this.router.navigate(['/Access/Denied/View Updates']);
          this.unblockDocument();
        }
        }
      },
      error => {
       this.blockedDocument = false;
        console.log(error);
        this.router.navigate(['login']);
        this.unblockDocument();
      }
     );
  }


  getSubscriptionState() {
    
    this.stateService.i360ManageSubscriptionClientId(this.tokenKey, this._userID, 0,false).subscribe(
      data => {
        this.stateList = data.state911;
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name ,"shortName":x.shortName})
          });
        }
      },
      error => { console.log(error); }
    );
  }

  getColumn() {

    this.dropdownDateSelectedColumnFilter = [
      { "id": 1, "itemName": 'Jurisdiction' },
      { "id": 2, "itemName": 'Fund' },
      { "id": 3, "itemName": 'Rate' },
      { "id": 4, "itemName": 'Fee' },
      { "id": 5, "itemName": 'Note' },
      { "id": 6, "itemName": 'Update Date' },
    ]
    this.selectedColumn = [{ "id": 1, "itemName": 'Jurisdiction' },
    { "id": 2, "itemName": 'Fund' },
    { "id": 3, "itemName": 'Rate' },
    { "id": 4, "itemName": 'Fee' },
    { "id": 5, "itemName": 'Note' },
    { "id": 6, "itemName": 'Update Date' },
    ]
  }

  isCheckColum(id) {
    let data = this.selectedColumn.filter((x => x.id == id))[0];
    if (data != null)
      return true
    else return false
  }
  onSelectColumn($event) {
    this.isColumnExist=true
    this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event.id)
    this.listOfColumnId.push($event.id);
    if (this.listOfColumnId.length > 0) {
    }
  }

  onSelectState($event) {
    this.resetPageNoandSize();
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetRateByStateId();
      this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectState($event) {
    this.resetPageNoandSize();
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.listOfStateIds.length == 0) {

    }
    else
      this.GetRateByStateId();
      this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAll($event) {
    this.resetPageNoandSize();
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }

    this.GetRateByStateId();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  GetUpdate() {
    this.isloading = true;
    this.rateId = ' ';
    if (this.listOfRateIDs.length > 0) {
      this.rateId = this.listOfRateIDs.join(',')
    }
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.Note == "null") this.Note = " ";
    ApplicationActivityStore('View', '911 Updates', this.appActivity, ToolEnum.state911, ActionActivity.State911, '/View/update',null);
    this.updates911.GetAllUpdateFront(this.tokenKey, this.PageNumber, this.PageSize, this.rateId, this.stateId, this.OrderBy,this._userID,this.displayFavorite).subscribe(
      data => {
        this.isloading = false;
        this.listofUpdate = data['update'];
        if (this.listofUpdate != null) {
          if (this.listofUpdate.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofUpdate[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }


  onDeSelectAllState($event) {
    this.resetPageNoandSize();
    this.listOfStateIds = [];
    this.dropdownListRatetemp =[];
    this.dropdownListRate = [];
    this.RateList=[];
    //this.GetRateByStateId();
    this.resetPageNoandSize();
    this.GetUpdate();

  }

  GetRateByStateId() {
    this.updates911.GetRateByStateId(this.tokenKey, this.stateId, this.isActive, this.scopeId).subscribe(
      data => {

        if (data['status'] == 'Success') {
          this.RateList = data['rate'];
          this.dropdownListRatetemp = data['rate'];

          this.dropdownListRate = [];

          data['rate'].forEach(x => {
            this.dropdownListRate.push(
              {
                "id": x.rateId,
                "itemName": x.taxName,
              })
          });
          
        }
      },
      error => { }
    );
  }

  GetRateByStateIdandRateId() {
    this.updates911.GetRateByStateId(this.tokenKey, this.stateId, this.isActive, this.scopeId).subscribe(
      data => {

        if (data['status'] == 'Success') {
          this.RateList = data['rate'];
          this.dropdownListRatetemp = data['rate'];

          this.dropdownListRate = [];

          data['rate'].forEach(x => {
            this.dropdownListRate.push(
              {
                "id": x.rateId,
                "itemName": x.taxName,
              })
              for (let iCounter = 0; iCounter < this.listOfRateIDs.length; iCounter++) {

                if(this.listOfRateIDs[iCounter]==x.rateId)
                {
                this.selectedRate.push({"id": x.rateId,"itemName": x.taxName});
                }
              }
          });
          
        }
      },
      error => { }
    );
  }

  resetPageNoandSize()
  {
    this.PageNumber = 1;
    this.PageSize = 30;
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  getPageLength() {
    // if (this.PageSize < this.totalRecordCount) return this.PageSize
    // else return this.totalRecordCount
    return Math.min((this.PageNumber * this.PageSize),this.totalRecordCount);
  }

  
  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowNoteCSS = '';
      this.upDowfundNameCSS = '';
      this.upDowStateNameCSS = '';
      this.upDowFeeCSS = '';
      this.upDowRateCSS = '';
      this.upDowUpdateDateCSS = '';
      if (sortBy === "Note") {
        this.upDowNoteCSS = 'active-up';
      }
      else if (sortBy === "fundName") {
        this.upDowfundNameCSS = 'active-up';
      }
      else if (sortBy === "Fee") {
        this.upDowFeeCSS = 'active-up';
      }
      else if (sortBy === "Rate") {
        this.upDowRateCSS = 'active-up';
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active-up';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowUpdateDateCSS = 'active-up';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;
      this.upDowNoteCSS = '';
      this.upDowfundNameCSS = '';
      this.upDowStateNameCSS = '';
      this.upDowFeeCSS = '';
      this.upDowRateCSS='';
      this.upDowUpdateDateCSS = '';

      if (sortBy === "Note") {
        this.upDowNoteCSS = 'active';
      }
      else if (sortBy === "fundName") {
        this.upDowfundNameCSS = 'active';
      }
      else if (sortBy === "Fee") {
        this.upDowFeeCSS = 'active';
      }
      else if (sortBy === "Rate") {
        this.upDowRateCSS = 'active';
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowUpdateDateCSS = 'active';
      }
    }
    this.OrderByName = sortBy;
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectRate($event) {
    this.listOfRateIDs = this.removeElemetninArray(this.listOfRateIDs, $event.id)
    this.listOfRateIDs.push($event.id);
    // this.stateId = ' ';
    // if (this.listOffundIDs.length > 0) {
    //   this.stateId = this.listOffundIDs.join(',')
    // }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectRate($event) {
    if (this.listOfRateIDs != null) {
      if (this.listOfRateIDs.length > 0) {
        this.listOfRateIDs = this.removeElemetninArray(this.listOfRateIDs, $event.id)
      }
    }
    if (this.listOfRateIDs.length > 0) {
      this.rateId = this.listOfRateIDs.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAllRate($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfRateIDs != null) {
        if (this.listOfRateIDs.length > 0) {
          this.listOfRateIDs = this.removeElemetninArray(this.listOfRateIDs, $event[iCounter].id)
          this.listOfRateIDs.push($event[iCounter].id);
        }
        else {
          this.listOfRateIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectAllRate($event) {
    this.listOfRateIDs = [];
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  AddToFavourite(item, IsFavourite: number) {
    let stateId = item.stateId;
    if (IsFavourite == 0) {
      this.SelectedStateFav = stateId;
      this.favouriteStateModel = item;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavourite(stateId, IsFavourite, item);
    }
  }

  SaveFavourite(stateId: string, IsFavourite: number, item)
  {
    this.blockDocument();
    let stateName = item.stateName;
    document.getElementById("btnClose").click();
    this.favorite.ClientStateFavouriteAdd(this.tokenKey, this._userID, stateId, IsFavourite, ToolEnum.state911).subscribe(data => 
    {
      if (data["status"] == "Success") 
      {
        this.GetUpdate();
        if (IsFavourite == 1) {
          ApplicationActivityStore('Mark Favorite', '911 Updates', this.appActivity, ToolEnum.state911, ActionActivity.State911, '/View/update', stateName);
          this.showMessage('Selected Jurisdiction added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          ApplicationActivityStore('Remove Favorite', '911 Updates', this.appActivity, ToolEnum.state911, ActionActivity.State911, '/View/update', stateName);
          this.showMessage('Selected Jurisdiction removed successfully from favorite list.', 'Favorite', 'success');
        }        
      }
      else 
      {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
      }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  
  GetFavourite(displayFav:number)
  {
    this.displayFavorite=displayFav;
    this.GetUpdate();
  }

  redirectToRateLookup(rateId:string)
  {
    sessionStorage.setItem('911updatetoRateLookup', rateId);
    ApplicationActivityStore('View', '911 Updates - View Rate/Fee History', this.appActivity, ToolEnum.state911, ActionActivity.State911, '/View/rateLookup');
    this.router.navigate(['/View/rateLookup/' + rateId]);
  }

  redirectToFundDetails(fundName:string)
  {
    sessionStorage.setItem('updateFundName', fundName);
    ApplicationActivityStore('View', '911 Updates - View Fund History', this.appActivity, ToolEnum.state911, ActionActivity.State911, '/View/contentView', fundName);
    this.router.navigate(['/View/contentView/' + fundName]);
  }

  onFilterSelectAll($event){
    this.resetPageNoandSize();
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }

    this.GetRateByStateId();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAll($event){
    this.resetPageNoandSize();
    this.listOfStateIds = [];
    this.dropdownListRatetemp =[];
    this.dropdownListRate = [];
    this.RateList=[];
    this.selectedState=[];
    //this.GetRateByStateId();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterSelectAllRate($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfRateIDs != null) {
        if (this.listOfRateIDs.length > 0) {
          this.listOfRateIDs = this.removeElemetninArray(this.listOfRateIDs, $event[iCounter].id)
          this.listOfRateIDs.push($event[iCounter].id);
        }
        else {
          this.listOfRateIDs.push($event[iCounter].id);
        }
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAllRate($event){
    this.listOfRateIDs = [];
    this.selectedRate=[];
    this.resetPageNoandSize();
    this.GetUpdate();
  }

}
