import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CountyMasterModel } from 'src/Models/CountyMasterModel';
import { StatusModel } from 'src/Models/StepMasterModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { UserModel } from 'src/Models/usersModel';
import { AppConfig } from 'src/Services/app.config';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompleted, getIncompletedStep } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-admin-compnay-profile-information',
  templateUrl: './admin-compnay-profile-information.component.html',
  styleUrls: ['./admin-compnay-profile-information.component.css']
})
export class AdminCompnayProfileInformationComponent implements OnInit {

  CompanyProfileInformationForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/

  listOfResetPasswordIDs = [];
  ResetPasswordList: any[] = [];
  selectedItemsResetPassword = [];
  dropdownResetPasswordFilter = [];
  dropdownSettingsResetPasswordFilter = {};
  isEnableCurrentlyResetingPassword: boolean = false

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateList: StateMasterModel[] = [];
  stateId: string = ' ';

  dropdownListMailingState = [];
  selectedItemsMailingState = [];
  dropdownSettingsMailingState = {};
  listOfMailingStateIds = [];
  mailingStateList: StateMasterModel[] = [];
  mailingStateId: string = ' ';

  dropdownListMailingCounty = [];
  selectedItemsMailingCounty = [];
  dropdownSettingsMailingCounty = {};
  listOfMailingCountyIds = [];
  mailingCountyList: CountyMasterModel[] = [];
  mailingCountyId: string = ' ';

  dropdownListUser = [];
  selectedItemsUser = [];
  dropdownSettingsUser = {};
  listOfUserIds = [];
  userList: UserModel[] = [];
  userId: string = ' ';

  isCompanyProfileDetailsCompleted: boolean;
  isAddCompQueCompleted: boolean;
  isStateSelectionCompleted: boolean;
  isAttechedDetailsCompleted: boolean;
  isNotes: boolean;
  currentActiveTab: number = 1;
  url: string;
  tabId: number = 0;

  mainUrl: string;
  subUrl: string;

  clientInformationId: number;
  companyName: string;
  dbacompanyName: string;
  ClientCompanyId: number;
  priceQuoteId: number;
  isRequireBasicDetails: boolean = true;

  pointListuser: any = [];

  Username:string="";
  fullname:string="";
  companyname:string="";
  email:string="";
  phone:string="";
  useractive:string="";
  createddate: string = "";
  modifieddate: string = "";
  lstproduct:any=[];
  devideRowCount = 0;
  sDisplay:boolean=false;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute, private datePipe: DatePipe, private globle: Globle,
    private router: Router, private PricingQuotesMaster: PricingQuotesService, private toastr: ToastrService,
    private titleService: Title, private userService: UsersService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
   
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Company Profile - '+this.companyName);
    this.dbacompanyName = sessionStorage.getItem('DbaCompanyName') == 'null' ? null : sessionStorage.getItem('DbaCompanyName');
    this.ClientCompanyId = +sessionStorage.getItem('ClientCompanyId');

    this.CompanyProfileInformationForm = this._fb.group({
      clientInformationId: 0,
      companyName: ['', Validators.required],
      physicalMailingAddressWithCounty: [''],
      generalCompanyPhoneNumber: [''],
      generalCompanyEmail: [''],
      persontoReceiveInvoice: [''],
      website: [''],
      filerIdNumber: [''],
      fEIN: [''],
      needAssistWithResetPassword: 0,
      stateOfFormation: [''],
      dateOfFormation: [''],
      quoteName: ['', Validators.required],
      quoteExpiryDate: ['', Validators.required],
      quotePreparedBy: ['', Validators.required],
      priceQuoteId: 0,
      dba: [''],
      mailingCity: [''],
      mailingState: 0,
      mailingZipCode: [''],
      firstName: [''],
      lastName: [''],
      email: [''],
      title: [''],
      affiliates:[''],
      isCompanyProfile:false,
      userName: [''],
      exhibit:['', Validators.required],
      mailingCounty: 0,
    })

    this.dropdownSettingsState = {
      singleSelection: true,
      text: "Filter by State",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No State(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsMailingState = {
      singleSelection: true,
      text: "Filter by State",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No State(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsUser = {
      singleSelection: false,
      text: "Filter by user",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No user(s) available',
      showCheckbox: true,
      enableFilterSelectAll: true
    };

    this.dropdownSettingsMailingCounty = {
      singleSelection: true,
      text: "Filter by County",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No County(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.getUser();
    this.getState();

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null && params['id'] != "null") {
          this.isEdit = true;
          this.priceQuoteId = params['id'];
          if (this.priceQuoteId == 0) {
            this.GetClientInformationByCompanyId(this.ClientCompanyId);
          }
          else
            this.GetClientInformationById(params['id']);

        }
        else {
          this.CompanyProfileInformationForm.patchValue({
            companyName: this.companyName,
            dba: this.dbacompanyName
          });
        }
      }
      else {
        this.CompanyProfileInformationForm.patchValue({
          companyName: this.companyName,
          dba: this.dbacompanyName
        });
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.priceQuoteId != null) {
      this.GetProgressStepDetailsById(this.priceQuoteId);
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isCompanyProfileDetailsCompleted = null;
      this.globle.cApp.statusModel.isAddCompQueCompleted = null;
      this.globle.cApp.statusModel.isStateSelectionCompleted = null;
      this.globle.cApp.statusModel.isAttechedDetailsCompleted = null;
      this.globle.cApp.statusModel.isNotes = null;
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
  }

  ngOnInit() {
    this.isSubmit = false;

    this.dropdownSettingsResetPasswordFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  getState() {
    this.PricingQuotesMaster.GetStateMaster(this.tokenKey).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }

        this.mailingStateList = data['state'];
        this.dropdownListMailingState = [];
        if (this.mailingStateList.length > 0) {
          this.mailingStateList.forEach(x => {
            this.dropdownListMailingState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  getCounty(stateid, countyid) {
    this.PricingQuotesMaster.GetCountyMaster(this.tokenKey, stateid).subscribe(
      data => {
        this.mailingCountyList = data['county'];
        this.dropdownListMailingCounty = [];
        this.selectedItemsMailingCounty = [];
        this.listOfMailingCountyIds = [];
        this.mailingCountyId = '';

        if (this.mailingCountyList.length > 0) {
          this.mailingCountyList.forEach(x => {
            this.dropdownListMailingCounty.push({ "id": x.countyId, "itemName": x.countyName })
          });
        }

        if (countyid != 0 && countyid!=null) {
          if (this.dropdownListMailingCounty.length > 0) {
            let countyData = this.dropdownListMailingCounty.filter(x => x.id == countyid);
            if (countyData.length > 0) {
              this.listOfMailingCountyIds = [];
              this.listOfMailingCountyIds.push(countyData[0].id);
              this.mailingCountyId = countyData[0].id;
              this.selectedItemsMailingCounty.push({ "id": countyData[0].id, "itemName": countyData[0].itemName })
            }
          }
        }
      },
      error => { }
    );
  }

  getUser() {
    this.userService.GetUsersByCompanyId(this.tokenKey, this.ClientCompanyId).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          if (result['companyUsers'] != null) {
            this.userList = result['companyUsers'];
            this.dropdownListUser = [];
            if (this.userList.length > 0) {
              this.userList.forEach(x => {
                this.dropdownListUser.push({ "id": x.userId, "itemName": x.userName })
              });
            }
          }
        }
      })
  }

  GetClientInformationById(priceQuoteId: number) {
    this.blockDocument();
    if (priceQuoteId != null) {
      this.PricingQuotesMaster.GetClientInformationById(this.tokenKey, priceQuoteId,this.clientInformationId, false).subscribe(
        result => {
          if (result['status'] == 'Success') {
            this.getCounty(result['clientInformation'].mailingState, result['clientInformation'].mailingCounty);
            this.CompanyProfileInformationForm.patchValue({
              clientInformationId: result['clientInformation'].clientInformationId,
              companyName: result['clientInformation'].companyName,
              physicalMailingAddressWithCounty: result['clientInformation'].physicalMailingAddressWithCounty,
              generalCompanyPhoneNumber: result['clientInformation'].generalCompanyPhoneNumber,
              generalCompanyEmail: result['clientInformation'].generalCompanyEmail,
              persontoReceiveInvoice: result['clientInformation'].persontoReceiveInvoice,
              website: result['clientInformation'].website,
              filerIdNumber: result['clientInformation'].filerIdNumber,
              fEIN: result['clientInformation'].fein,
              stateOfFormation: result['clientInformation'].stateOfFormation,
              dateOfFormation: result['clientInformation'].dateOfFormation,
              quoteName: result['clientInformation'].quoteName,
              quoteExpiryDate: result['clientInformation'].quoteExpiryDate,
              quotePreparedBy: result['clientInformation'].quotePreparedBy,
              priceQuoteId: result['clientInformation'].priceQuoteId,
              dba: result['clientInformation'].dba,
              mailingCity: result['clientInformation'].mailingCity,
              mailingZipCode: result['clientInformation'].mailingZipCode,
              affiliates:result['clientInformation'].affiliates,
              isCompanyProfile:result['clientInformation'].isCompanyProfile,
              exhibit:result['clientInformation'].exhibit,

            });

            if (result['clientInformation'].companyName == null || result['clientInformation'].companyName == '' || result['clientInformation'].companyName == undefined) {
              this.CompanyProfileInformationForm.patchValue({
                companyName: this.companyName
              });
            }

            if (result['clientInformation'].quoteExpiryDate == null || result['clientInformation'].quoteExpiryDate == '' || result['clientInformation'].quoteExpiryDate == undefined) {
              const currentDate = new Date();
              const newDate = new Date(currentDate);
              newDate.setDate(currentDate.getDate() + 30);

              // Use the DatePipe to format the new date as needed
              const formattedNewDate = this.datePipe.transform(newDate, 'MM dd yyyy');
              this.CompanyProfileInformationForm.patchValue({
                quoteExpiryDate: formattedNewDate
              });
            }

            if (result['clientInformation'].dba == null || result['clientInformation'].dba == '' || result['clientInformation'].dba == undefined) {
              this.CompanyProfileInformationForm.patchValue({
                dba: this.dbacompanyName
              });
            }

            if (result['clientInformation'].pointOfContact != null && result['clientInformation'].pointOfContact != '' && result['clientInformation'].pointOfContact != undefined) {
              setTimeout(() => {
                if (this.userList.length > 0) {
                  let dataToArray = result['clientInformation'].pointOfContact.split(',').map(item => item.trim());
                  this.selectedItemsUser = [];
                  this.listOfUserIds = [];
                  if (dataToArray.length > 0) {
                    for (let iCounter = 0; iCounter < dataToArray.length; iCounter++) {
                      let data = this.userList.filter(x => x.userId == dataToArray[iCounter])[0];
                      if (data != undefined) {
                        this.selectedItemsUser.push({ "id": data.userId, "itemName": data.userName });
                        this.listOfUserIds.push(data.userId);
                        let userlst = this.findUserById(data.userId);

                        this.addUserpoint(userlst.userName,userlst.email,Number(userlst.userId),userlst.actualUserName);
                      }
                    }
                  }
                }
              }, 2000);
              this.isRequireBasicDetails = false;
            }
            else {
              this.isRequireBasicDetails = true;
            }
            if (this.stateList.length > 0) {
              this.selectedItemsState = [];
              this.listOfStateIds = [];
              let data = this.stateList.filter(x => x.stateId == result['clientInformation'].stateOfFormation)[0];
              if (data != undefined) {
                this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name });
                this.listOfStateIds.push(data.stateId);
                this.stateId = result['clientInformation'].stateOfFormation;
              }

              this.selectedItemsMailingState = [];
              this.listOfMailingStateIds = [];
              let data1 = this.mailingStateList.filter(x => x.stateId == result['clientInformation'].mailingState)[0];
              if (data1 != undefined) {
                this.selectedItemsMailingState.push({ "id": data1.stateId, "itemName": data1.name });
                this.listOfMailingStateIds.push(data1.stateId);
                this.mailingStateId = result['clientInformation'].mailingState;
              }
            }
            else {
              setTimeout(() => {
                this.selectedItemsState = [];
                this.listOfStateIds = [];
                let data = this.stateList.filter(x => x.stateId == result['clientInformation'].stateOfFormation)[0];
                if (data != undefined) {
                  this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name });
                  this.listOfStateIds.push(data.stateId);
                  this.stateId = result['clientInformation'].stateOfFormation;
                }

                this.selectedItemsMailingState = [];
                this.listOfMailingStateIds = [];
                let data1 = this.mailingStateList.filter(x => x.stateId == result['clientInformation'].mailingState)[0];
                if (data1 != undefined) {
                  this.selectedItemsMailingState.push({ "id": data1.stateId, "itemName": data1.name });
                  this.listOfMailingStateIds.push(data1.stateId);
                  this.mailingStateId = result['clientInformation'].mailingState;
                }

              }, 1200);
            }
            this.unblockDocument();
          }
          else {
            this.unblockDocument();
          }
        });
    }
    else {
      this.unblockDocument();
      this.CompanyProfileInformationForm.patchValue({
        companyName: this.companyName,
        dba: this.dbacompanyName
      });

      const currentDate = new Date();
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + 30);

      // Use the DatePipe to format the new date as needed
      const formattedNewDate = this.datePipe.transform(newDate, 'MM dd yyyy');
      this.CompanyProfileInformationForm.patchValue({
        quoteExpiryDate: formattedNewDate
      });
    }
  }

  GetClientInformationByCompanyId(companyId: number) {
    this.blockDocument();
    if (companyId != null) {
      this.PricingQuotesMaster.GetClientInformationByCompanyId(this.tokenKey, companyId).subscribe(
        result => {
          this.getCounty(result['clientInformation'].mailingState, result['clientInformation'].mailingCounty);
          if (result['status'] == 'Success' && result['clientInformation'] != null) {
            this.CompanyProfileInformationForm.patchValue({
              clientInformationId: result['clientInformation'].clientInformationId,
              companyName: result['clientInformation'].companyName,
              physicalMailingAddressWithCounty: result['clientInformation'].physicalMailingAddressWithCounty,
              generalCompanyPhoneNumber: result['clientInformation'].generalCompanyPhoneNumber,
              generalCompanyEmail: result['clientInformation'].generalCompanyEmail,
              persontoReceiveInvoice: result['clientInformation'].persontoReceiveInvoice,
              website: result['clientInformation'].website,
              filerIdNumber: result['clientInformation'].filerIdNumber,
              fEIN: result['clientInformation'].fein,
              stateOfFormation: result['clientInformation'].stateOfFormation,
              dateOfFormation: result['clientInformation'].dateOfFormation,
              quoteName: result['clientInformation'].quoteName,
              quoteExpiryDate: result['clientInformation'].quoteExpiryDate,
              quotePreparedBy: result['clientInformation'].quotePreparedBy,
              priceQuoteId: result['clientInformation'].priceQuoteId,
              dba: result['clientInformation'].dba,
              mailingCity: result['clientInformation'].mailingCity,
              mailingZipCode: result['clientInformation'].mailingZipCode,
              affiliates:result['clientInformation'].affiliates,

            });

            if (result['clientInformation'].companyName == null || result['clientInformation'].companyName == '' || result['clientInformation'].companyName == undefined) {
              this.CompanyProfileInformationForm.patchValue({
                companyName: this.companyName
              });
            }

            if (result['clientInformation'].quoteExpiryDate == null || result['clientInformation'].quoteExpiryDate == '' || result['clientInformation'].quoteExpiryDate == undefined) {
              const currentDate = new Date();
              const newDate = new Date(currentDate);
              newDate.setDate(currentDate.getDate() + 30);

              // Use the DatePipe to format the new date as needed
              const formattedNewDate = this.datePipe.transform(newDate, 'MM dd yyyy');
              this.CompanyProfileInformationForm.patchValue({
                quoteExpiryDate: formattedNewDate
              });
            }


            if (result['clientInformation'].dba == null || result['clientInformation'].dba == '' || result['clientInformation'].dba == undefined) {
              this.CompanyProfileInformationForm.patchValue({
                dba: this.dbacompanyName
              });
            }

            if (this.userList.length > 0) {
              if (result['clientInformation'].pointOfContact != null && result['clientInformation'].pointOfContact != '' && result['clientInformation'].pointOfContact != undefined) {
                setTimeout(() => {

                  let dataToArray = result['clientInformation'].pointOfContact.split(',').map(item => item.trim());
                  this.selectedItemsUser = [];
                  this.listOfUserIds = [];
                  if (dataToArray.length > 0) {
                    for (let iCounter = 0; iCounter < dataToArray.length; iCounter++) {
                      let data = this.userList.filter(x => x.userId == dataToArray[iCounter])[0];
                      if (data != undefined) {
                        this.selectedItemsUser.push({ "id": data.userId, "itemName": data.userName });
                        this.listOfUserIds.push(data.userId);
                      }
                    }
                  }
                }
                  , 2000);
                this.isRequireBasicDetails = false;
              } else {
                this.isRequireBasicDetails = true;
              }
            } else {
              if (result['clientInformation'].pointOfContact != null && result['clientInformation'].pointOfContact != '' && result['clientInformation'].pointOfContact != undefined) {
                setTimeout(() => {
                  let dataToArray = result['clientInformation'].pointOfContact.split(',').map(item => item.trim());
                  this.selectedItemsUser = [];
                  this.listOfUserIds = [];
                  if (dataToArray.length > 0) {
                    for (let iCounter = 0; iCounter < dataToArray.length; iCounter++) {
                      let data = this.userList.filter(x => x.userId == dataToArray[iCounter])[0];
                      if (data != undefined) {
                        this.selectedItemsUser.push({ "id": data.userId, "itemName": data.userName });
                        this.listOfUserIds.push(data.userId);
                      }
                    }
                  }
                }
                  , 2000);
                this.isRequireBasicDetails = false;
              } else {
                this.isRequireBasicDetails = true;
              }
            }


            if (this.stateList.length > 0) {
              this.selectedItemsState = [];
              this.listOfStateIds = [];
              let data = this.stateList.filter(x => x.stateId == result['clientInformation'].stateOfFormation)[0];
              if (data != undefined) {
                this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name });
                this.listOfStateIds.push(data.stateId);
                this.stateId = result['clientInformation'].stateOfFormation;
              }

              this.selectedItemsMailingState = [];
              this.listOfMailingStateIds = [];
              let data1 = this.mailingStateList.filter(x => x.stateId == result['clientInformation'].mailingState)[0];
              if (data1 != undefined) {
                this.selectedItemsMailingState.push({ "id": data1.stateId, "itemName": data1.name });
                this.listOfMailingStateIds.push(data1.stateId);
                this.mailingStateId = result['clientInformation'].mailingState;
              }
            }
            else {
              setTimeout(() => {
                this.selectedItemsState = [];
                this.listOfStateIds = [];
                let data = this.stateList.filter(x => x.stateId == result['clientInformation'].stateOfFormation)[0];
                if (data != undefined) {
                  this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name });
                  this.listOfStateIds.push(data.stateId);
                  this.stateId = result['clientInformation'].stateOfFormation;
                }

                this.selectedItemsMailingState = [];
                this.listOfMailingStateIds = [];
                let data1 = this.mailingStateList.filter(x => x.stateId == result['clientInformation'].mailingState)[0];
                if (data1 != undefined) {
                  this.selectedItemsMailingState.push({ "id": data1.stateId, "itemName": data1.name });
                  this.listOfMailingStateIds.push(data1.stateId);
                  this.mailingStateId = result['clientInformation'].mailingState;
                }

              }, 1200);
            }
          }
          else {
            this.CompanyProfileInformationForm.patchValue({
              companyName: this.companyName,
              dba: this.dbacompanyName
            });

            const currentDate = new Date();
            const newDate = new Date(currentDate);
            newDate.setDate(currentDate.getDate() + 30);

            // Use the DatePipe to format the new date as needed
            const formattedNewDate = this.datePipe.transform(newDate, 'MM dd yyyy');
            this.CompanyProfileInformationForm.patchValue({
              quoteExpiryDate: formattedNewDate
            });
          }

          this.unblockDocument();

        });
    }
    else {
      this.unblockDocument();
      this.CompanyProfileInformationForm.patchValue({
        companyName: this.companyName,
        dba: this.dbacompanyName
      });
    }
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  save() {
    this.isSubmit = true;
    if (this.CompanyProfileInformationForm.value.needAssistWithResetPassword == "")
      this.CompanyProfileInformationForm.value.needAssistWithResetPassword = false;
    let data = this.CompanyProfileInformationForm.value;

    if (!this.CompanyProfileInformationForm.valid || !data.companyName.trim()
      || !data.quoteName.trim() || !data.quotePreparedBy.trim())
      return

    if (this.CompanyProfileInformationForm.invalid) {
      this.globle.cApp.statusModel.isCompanyProfileDetailsCompleted = false;
    }

    if (this.isRequireBasicDetails) {
      if (!data.userName.trim() || !data.firstName.trim() || !data.lastName.trim() || !data.email.trim() || !data.title.trim())
        return;
    }

    if (this.listOfUserIds.length == 0 && !this.isRequireBasicDetails) {
      this.isRequireBasicDetails = true;
      return;
    }
    else {
      if (this.listOfUserIds.length != 0) {
        this.userId = this.listOfUserIds.join(',')
        this.isRequireBasicDetails = false;
      }
    }

    let value = {
      clientInformationId: data.clientInformationId,
      companyName: data.companyName != null ? data.companyName.trim() : data.companyName,
      physicalMailingAddressWithCounty: data.physicalMailingAddressWithCounty != null ? data.physicalMailingAddressWithCounty.trim() : data.physicalMailingAddressWithCounty,
      generalCompanyPhoneNumber: data.generalCompanyPhoneNumber != null ? data.generalCompanyPhoneNumber.trim() : data.generalCompanyPhoneNumber,
      generalCompanyEmail: data.generalCompanyEmail != null ? data.generalCompanyEmail.trim() : data.generalCompanyEmail,
      persontoReceiveInvoice: data.persontoReceiveInvoice != null ? data.persontoReceiveInvoice.trim() : data.persontoReceiveInvoice,
      website: data.website,
      filerIdNumber: data.filerIdNumber,
      fEIN: data.fEIN != null ? data.fEIN.trim() : data.fEIN,
      stateOfFormation: this.stateId,
      dateOfFormation: data.dateOfFormation,
      createdBy: this.userID,
      quoteName: data.quoteName != null ? data.quoteName.trim() : data.quoteName,
      quotePreparedBy: data.quotePreparedBy != null ? data.quotePreparedBy.trim() : data.quotePreparedBy,
      quoteExpiryDate: data.quoteExpiryDate,
      isClient: false,
      isFront: false,
      pointOfContact: this.userId,
      clientCompanyId: this.ClientCompanyId,
      priceQuoteId: data.priceQuoteId,
      isCompProfileInfoCompleted: true,
      dba: data.dba != null ? data.dba.trim() : data.dba,
      mailingState: this.mailingStateId,
      mailingCity:data.mailingCity,
      mailingZipCode:data.mailingZipCode,
      firstName: data.firstName,
      userName: data.userName,
      lastName: data.lastName,
      userEmail: data.email,
      title: data.title,
      affiliates:data.affiliates,
      isQuote:true,
      isCompanyProfile:data.isCompanyProfile!=null?data.isCompanyProfile:false,
      exhibit:data.exhibit,
      mailingCounty: this.mailingCountyId
    }

    this.blockDocument();
    this.PricingQuotesMaster.CompanyProfileInformationAddUpdate(this.tokenKey, value).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isCompanyProfileDetailsCompleted = true;
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (result['clientInformationId'] != 0) {
              let navigation = '/admin/' + this.SelectedTool + '/add-comp-que/' + result['clientInformationId'];
              this.router.navigate([navigation]);
            }
          }
        }
        else {
          this.showMessage(result['message'], 'Error', 'error');
        }
      },
      error => {
        this.blockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  cancelClinetInfo() {
    sessionStorage.removeItem('ClientCompanyName');
    sessionStorage.removeItem('DbaCompanyName');
    if (this.priceQuoteId != null && this.priceQuoteId != undefined) {
      let navigation = '/admin/' + this.SelectedTool + '/Company-Selection/' + this.priceQuoteId;
      this.router.navigate([navigation]);
    } else {
      let navigation = '/admin/' + this.SelectedTool + '/Company-Selection';
      this.router.navigate([navigation]);
    }
  }

  onSelectTaxCompliance($event) {
    if ($event.id == 1) {
      this.isEnableCurrentlyResetingPassword = true;
      this.CompanyProfileInformationForm.patchValue({
        needAssistWithResetPassword: true
      });
    }
    else {
      this.isEnableCurrentlyResetingPassword = false;
      this.CompanyProfileInformationForm.patchValue({
        needAssistWithResetPassword: false
      });
    }
  }

  onDeSelectTaxComplianceAll($event) {
    this.selectedItemsResetPassword = [];
    this.isEnableCurrentlyResetingPassword = false;
    this.CompanyProfileInformationForm.patchValue({
      needAssistWithResetPassword: false
    });
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = $event.id;
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.stateId = '';
  }

  activeTab(url: string, tabId: number) {

    if (this.priceQuoteId != null) {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsById(this.tokenKey, this.priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];          
          this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
        }
      });
  }

  onSelectUser($event) {
    this.listOfUserIds = this.removeElemetninArray(this.listOfUserIds, $event.id)
    this.listOfUserIds.push($event.id);
    this.userId = '';
    if (this.listOfUserIds.length > 0) {
      this.userId = this.listOfUserIds.join(',')
      this.isRequireBasicDetails = false;
    }
    else {
      this.isRequireBasicDetails = true;
    }
    let userlst = this.findUserById($event.id);

    this.addUserpoint(userlst.userName,userlst.email,Number(userlst.userId),userlst.actualUserName);
  }

  onDeSelectUser($event) {
    if (this.listOfUserIds != null) {
      if (this.listOfUserIds.length > 0) {
        this.listOfUserIds = this.removeElemetninArray(this.listOfUserIds, $event.id)
      }
    }
    if (this.listOfUserIds.length > 0) {
      this.userId = this.listOfUserIds.join(',')
      this.isRequireBasicDetails = false;
    }
    else {
      this.isRequireBasicDetails = true;
    }

    this.deleteRecordByUserid($event.id);
  }

  onSelectAllUser($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfUserIds != null) {
        if (this.listOfUserIds.length > 0) {
          this.listOfUserIds = this.removeElemetninArray(this.listOfUserIds, $event[iCounter].id)
          this.listOfUserIds.push($event[iCounter].id);


          let userlst = this.findUserById($event[iCounter].id);

          this.addUserpoint(userlst.userName,userlst.email,Number(userlst.userId),userlst.actualUserName);
        }
        else {
          this.listOfUserIds.push($event[iCounter].id);

          let userlst = this.findUserById($event[iCounter].id);

          this.addUserpoint(userlst.userName,userlst.email,Number(userlst.userId),userlst.actualUserName);
        }
      }
    }
    if (this.listOfUserIds.length > 0) {
      this.userId = this.listOfUserIds.join(',')
      this.isRequireBasicDetails = false;
    }
    else {
      this.isRequireBasicDetails = true;
    }
  }

  onDeSelectAllUser($event) {
    this.listOfUserIds = [];
    this.selectedItemsUser = [];
    this.userId = '';
    this.isRequireBasicDetails = true;
    this.pointListuser = [];
  }

  onFilterSelectAllUser($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfUserIds != null) {
        if (this.listOfUserIds.length > 0) {
          this.listOfUserIds = this.removeElemetninArray(this.listOfUserIds, $event[iCounter].id)
          this.listOfUserIds.push($event[iCounter].id);
        }
        else {
          this.listOfUserIds.push($event[iCounter].id);
        }
      }
    }
  }

  onFilterDeSelectAllUser($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfUserIds != null) {
        if (this.listOfUserIds.length > 0) {
          this.listOfUserIds = this.removeElemetninArray(this.listOfUserIds, $event[iCounter].id)
        }
      }
    }
  }

  onSelectMailingState($event) {
    this.listOfMailingStateIds = this.removeElemetninArray(this.listOfMailingStateIds, $event.id)
    this.listOfMailingStateIds.push($event.id);
    this.mailingStateId = $event.id;
    this.getCounty(this.mailingStateId,0);
  }

  onDeSelectMailingState($event) {
    if (this.listOfMailingStateIds != null) {
      if (this.listOfMailingStateIds.length > 0) {
        this.listOfMailingStateIds = this.removeElemetninArray(this.listOfMailingStateIds, $event.id)
      }
    }
    if (this.listOfMailingStateIds.length > 0) {
      this.mailingStateId = this.listOfMailingStateIds.join(',')
    }
    this.listOfMailingCountyIds = null;
    this.mailingCountyList = [];
    this.mailingCountyId = '';
    this.selectedItemsMailingCounty = null;
  }

  onDeSelectAllMailingState($event) {
    this.listOfMailingStateIds = [];
    this.mailingStateId = '';

    this.listOfMailingCountyIds = null;
    this.mailingCountyList = [];
    this.mailingCountyId = '';
    this.selectedItemsMailingCounty = null;
  }

  onSelectMailingCounty($event) {
    this.listOfMailingCountyIds = this.removeElemetninArray(this.listOfMailingCountyIds, $event.id)
    this.listOfMailingCountyIds.push($event.id);
    this.mailingCountyId = $event.id;
  }

  onDeSelectMailingCounty($event) {
    if (this.listOfMailingCountyIds != null) {
      if (this.listOfMailingCountyIds.length > 0) {
        this.listOfMailingCountyIds = this.removeElemetninArray(this.listOfMailingCountyIds, $event.id)
      }
    }
    if (this.listOfMailingCountyIds.length > 0) {
      this.mailingCountyId = this.listOfMailingCountyIds.join(',')
    }
  }

  onDeSelectAllMailingCounty($event) {
    this.listOfMailingCountyIds = [];
    this.mailingCountyId = '';
  }
  
  addUserpoint(name:string,email:string,userid:number,userName:string){
    this.pointListuser.push(
      {
        fullname: name,  
        email:email,
        userid:userid,
        userName:userName      
      }
    )
  }

  deleteRecordByUserid(userid: number) {
    this.pointListuser = this.pointListuser.filter(record => record.userid !== userid);
  }

  
  findUserById(id: string) {
    return this.userList.find(user => user.userId == id);
  }


  getuserInformation(_userID:number): void {
    this.sDisplay=false;
    this.userService.GetUserFindByUserID(this.tokenKey, _userID).subscribe(
      data => {
        let usrinfo = data['userMaster'];
        if(usrinfo.length>0){
          this.Username=usrinfo[0].userName;
          this.fullname = usrinfo[0].firstName + " " + usrinfo[0].lastName;
          this.companyname=usrinfo[0].companyName;
          this.email=usrinfo[0].email;
          this.phone=usrinfo[0].phone;
          this.useractive=usrinfo[0].isActive;
          this.createddate=usrinfo[0].createdDate;
          this.modifieddate=usrinfo[0].modifiedDate;

          this.lstproduct = data['userMaster'][0].listOfProducts;
          this.devideRowCount = this.lstproduct.length / 2;
          this.devideRowCount = this.devideRowCount.toString().indexOf(".") != -1 ? this.devideRowCount + 1 : this.devideRowCount;

          this.sortProducts();
        }
        else{
          this.Username="";
          this.fullname="";
          this.companyname="";
          this.email="";
          this.phone="";
          this.useractive="";
          this.createddate="";
          this.modifieddate="";
          this.lstproduct=[];
        }
        this.sDisplay=true;
       
      },
      error => {
        this.Username = "";
        this.fullname = "";
        this.companyname = "";
        this.email = "";
        this.phone = "";
        this.useractive = "";
        this.createddate = "";
        this.modifieddate = "";
        this.lstproduct = [];
        this.sDisplay = false;
      }
    );
  }

  sortProducts(): void {
    this.lstproduct.sort((a, b) => {
      if (a.displayName < b.displayName) {
        return -1;
      }
      if (a.displayName > b.displayName) {
        return 1;
      }
      return 0;
    });
  }


}
