export class SpecialReportModel {
    specialReportId?: any
    externalURL?: any
    displayDocName: string;
    docPath: string
    title?: any
    isActive?: any
    createdDate?: any
    createdBy?: any
    modifiedDate?: any
    modifiedBy?: any
    totalRecordCount?: any
    isSelected?: any
    productId?: any
    isLocked?: any
    isEmbed?: any
    isAllowallAccess?: any
    linktype?:any
    sortOrder?:any
    sectionId?: any
    sectionName?:any
    specialReportSectionDetails?:any

}

export class SpecialReportSectionsModel{
    
       sectionId?: any
       sectionName?: any
       sortOrder?: any 
       isActive?: any 
       createdDate?: any 
       createdBy?: any 
       updatedDate?: any
       updatedBy?: any 
       totalRecordCount?: any

}