import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConfig } from 'src/Services/app.config';
import { UsersService } from '../../../Services/users.service';
import { CheckAuth } from '../../../Services/utility.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from '../../../Models/usersModel';
import { Title } from '@angular/platform-browser';
import { I360Service } from 'src/Services/i360Service';
import { MenuGroupModel,CustomMenuGroupModel } from 'src/Models/MenuGroupModel';
import { SiteLayoutComponent } from 'src/app/Layout/site-layout/site-layout.component';
import { ToolEnum } from 'src/Models/Common';

@Component({
  selector: 'app-client-login',
  templateUrl: './client-login.component.html',
  styleUrls: ['./client-login.component.css']
})
export class ClientLoginComponent implements OnInit {

  userModel = new UserModel();
  appModel: any;
  tokenKey: string = '';
  loginForm: FormGroup;
  menuModel = new MenuGroupModel();
  custommenuModel= new CustomMenuGroupModel();
  quaryValues: string = '';

  constructor(private siteLaout: SiteLayoutComponent, private i360Service: I360Service, private titleService: Title, private toastr: ToastrService, private _fb: FormBuilder, private UserService: UsersService, private checkAuth: CheckAuth, private router: Router
    , private activedRoute: ActivatedRoute) {
    sessionStorage.removeItem('tokenValue');
    sessionStorage.removeItem('appStartTime');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    sessionStorage.removeItem('isAdminOpen');
    sessionStorage.removeItem('AdminUserId');
    sessionStorage.removeItem('StateSetupManageRedirection');
    this.titleService.setTitle('Login | Inteserra - i360');
  }

  ngOnInit() {
    if (sessionStorage.getItem('byPassError') != null && sessionStorage.getItem('byPassError') != '') {
      let byPassError = sessionStorage.getItem('byPassError');
      sessionStorage.removeItem('byPassError');
      this.showMessage(byPassError, 'Error', 'error');
    }
    let queryParams = this.activedRoute.snapshot.queryParams['data'];
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.loginForm = this._fb.group({
      userid: 0,
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    })

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null)
          this.quaryValues = params['id'];
        else
          this.quaryValues = '';
      }
      else
        this.quaryValues = '';
    });

    // if (sessionStorage.getItem('Username') != '' && sessionStorage.getItem('Username') != 'null'
    //   && sessionStorage.getItem('Hash') != '' && sessionStorage.getItem('Hash') != 'null') {
    //   this.CheckLoginbyusernamewithhash();
    // }
  }

  CheckLogin() {

    if (this.loginForm.value["username"] == "") {
      this.showMessage('Please enter username', 'Error', 'error');
      return;
    }
    if (this.loginForm.value["password"] == "") {
      this.showMessage('Please enter password', 'Error', 'error');
      return;
    }
    if (!this.loginForm.valid) {
      return;
    }

    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.UserService.i360CheckUserLogin(this.tokenKey, this.loginForm.value.username, this.loginForm.value.password).subscribe(
      data => {
        if (data["status"] == "Success") {          
          this.userModel = data["usersModel"];
          if (this.userModel != null) {

            if (data["usersModel"].listOfProducts.length > 0) {
              sessionStorage.setItem('UserId', this.userModel.userId);
              localStorage.setItem('UserId', this.userModel.userId);
              if (this.userModel.createPassword == true) {
                this.router.navigate(['CreatePassword']);
              }
              else {
                sessionStorage.setItem('UserName', this.userModel.userName);
                localStorage.setItem('UserName', this.userModel.userName);
                sessionStorage.setItem('LastLogin', this.userModel.lastLogin);
                localStorage.setItem('LastLogin', this.userModel.lastLogin);
                sessionStorage.setItem('clientTypeId', this.userModel.userTypeId);
                localStorage.setItem('clientTypeId', this.userModel.userTypeId);
                sessionStorage.setItem('isUserByPass', 'Login');
                localStorage.setItem('isUserByPass', 'Login');
                let FirstLastName = this.userModel.firstName + ' ' + this.userModel.lastName;
                if (FirstLastName.trim() != '') {
                  sessionStorage.setItem('FirstLastName', FirstLastName);
                  localStorage.setItem('FirstLastName', FirstLastName);
                }
                else {
                  sessionStorage.setItem('FirstLastName', this.userModel.userName);
                  localStorage.setItem('FirstLastName', this.userModel.userName);
                }

                let Tool: string = data["usersModel"].listOfProducts[0].productName.toLowerCase();

                sessionStorage.setItem('userHasTracIt', 'false');
                localStorage.setItem('userHasTracIt', 'false');
                //sessionStorage.setItem('ClientLoginTool', Tool);
                let UserAccessibleTools: string = ''

                for (let iCounter = 0; iCounter < data["usersModel"].listOfProducts.length; iCounter++) {
                  UserAccessibleTools += data["usersModel"].listOfProducts[iCounter].productName + ',';

                  if (ToolEnum.actionitem == data["usersModel"].listOfProducts[iCounter].productId) {
                    sessionStorage.setItem('userHasTracIt', 'true');
                    localStorage.setItem('userHasTracIt', 'true');
                  }
                }
                sessionStorage.setItem('ClientAccessibleTools', UserAccessibleTools.substring(0, UserAccessibleTools.length - 1).toLowerCase());
                localStorage.setItem('ClientAccessibleTools', UserAccessibleTools.substring(0, UserAccessibleTools.length - 1).toLowerCase());
                sessionStorage.setItem('CompanyId', this.userModel.companyId);
                localStorage.setItem('CompanyId', this.userModel.companyId);
                sessionStorage.setItem('IsICalSubscription', this.userModel.isICalSubscription);
                localStorage.setItem('IsICalSubscription', this.userModel.isICalSubscription);
                sessionStorage.setItem('EncyUserId', this.userModel.encyUserId);
                localStorage.setItem('EncyUserId', this.userModel.encyUserId);
                sessionStorage.setItem('CompanyName', this.userModel.companyName);
                localStorage.setItem('CompanyName', this.userModel.companyName);
                sessionStorage.setItem('isLogin', 'true');
                localStorage.setItem('isLogin', 'true');
                this.siteLaout.userName = sessionStorage.getItem('FirstLastName');
                this.siteLaout.lastLogin = sessionStorage.getItem('LastLogin');
                this.siteLaout.companyName=sessionStorage.getItem('CompanyName');
                this.siteLaout.newUserName = sessionStorage.getItem('UserName');

                sessionStorage.setItem('DbaCompanyName', this.userModel.dba);
                localStorage.setItem('DbaCompanyName', this.userModel.dba);

                sessionStorage.setItem('useremail', this.userModel.email);
                localStorage.setItem('useremail', this.userModel.email);
                //this.router.navigate(['Home']);
                //this.router.navigate([Tool + '/Home']);
                this.i360Service.i360MenuGroupGet(this.tokenKey, this.userModel.userId).subscribe(
                  menudata => {
                    if (!this.userModel.email || this.userModel.email.indexOf('@inteserra.com') == -1) {
                      menudata['listOfMenu'].forEach(element => {
                        if (element.menuGroupId == 7) {
                          //element.childMenu = element.childMenu.filter(x => x.menuId != 44);
                          //  element.childMenu = element.childMenu.filter(x => x.menuId != 44 && x.menuId != 58);
                          element.childMenu = element.childMenu.filter(x =>x.productName!='Inteserra Internal - Subscriber Login' && x.productName != 'Inteserra Internal Use Only' && x.productName !='Inteserra Employee - Customer Login Access');
                        }
                      });
                    }
                    this.siteLaout.menuModel = menudata['listOfMenu'];
                    this.menuModel = menudata['listOfMenu'];
                    this.siteLaout.userID = +this.userModel.userId;
                    sessionStorage.setItem('UserManu', JSON.stringify(this.menuModel));
                    localStorage.setItem('UserManu', JSON.stringify(this.menuModel));
           
                    if (this.quaryValues != '' && this.quaryValues != null) {
                      let uId = sessionStorage.getItem('emailBriefingUId')!=null?sessionStorage.getItem('emailBriefingUId').toString():null;
                      let userId = sessionStorage.getItem('emailPriceQuoteUId')!=null?sessionStorage.getItem('emailPriceQuoteUId').toString():null;
                      let onboardinguserId = sessionStorage.getItem('emailOnboardingQuoteUId')!=null?sessionStorage.getItem('emailOnboardingQuoteUId').toString():null;

                      let trfa911uId = sessionStorage.getItem('trfa911FileDownloadUId')!=null?sessionStorage.getItem('trfa911FileDownloadUId').toString():null;
                      let trfauId = sessionStorage.getItem('trfaFileDownloadUId')!=null?sessionStorage.getItem('trfaFileDownloadUId').toString():null;
                      let trfa911updateuId = sessionStorage.getItem('trfa911UpdatehistoryUId')!=null?sessionStorage.getItem('trfa911UpdatehistoryUId').toString():null;
                      let crraupdateuId = sessionStorage.getItem('crraUpdatehistoryUId')!=null?sessionStorage.getItem('crraUpdatehistoryUId').toString():null;
                      
                      if (uId === this.userModel.userId.toString())
                      this.router.navigate(['/View/BriefingDetails/' + this.quaryValues]);
                      else if (userId === this.userModel.userId.toString())
                      this.router.navigate(['/View/download-quotes-and-proposal/' + this.quaryValues]);
                      else if(userId!=null)
                      this.router.navigate(['/Access/Denied/PriceQuote']);
                      // else if (onboardinguserId === this.userModel.userId.toString())
                      else if (onboardinguserId!="" && onboardinguserId!=null)
                        this.router.navigate(['/View/download-quotes/'+ this.quaryValues]);
                      else if(trfa911uId===this.userModel.userId.toString())
                      this.router.navigate(['/View/downloadfiles/' + this.quaryValues]);
                      else if(trfa911uId!=null)
                      this.router.navigate(['/Access/Denied/download files']);
                      else if(trfauId===this.userModel.userId.toString())
                      this.router.navigate(['/View/downloadtrfafiles/' + this.quaryValues]);
                      else if(trfauId!=null)
                      this.router.navigate(['/Access/Denied/download files']);
                      else if(trfa911updateuId===this.userModel.userId.toString())
                      this.router.navigate(['/View/updateView/' + this.quaryValues]);
                      else if(trfa911updateuId!=null)
                      this.router.navigate(['/Access/Denied/View Updates']);
                      else if(crraupdateuId===this.userModel.userId.toString())
                      this.router.navigate(['/View/updates/' + this.quaryValues]);
                      else if(crraupdateuId!=null)
                      this.router.navigate(['/Access/Denied/View Updates']);
                      else
                        this.router.navigate(['/Access/Denied/BriefingEmailServices']);
                    }
                    else
                      this.router.navigate(['Dashboard']);
                  },
                  error => {
                    console.log(error);
                  }
                );

                this.i360Service.i360CustomProductGet(this.tokenKey, this.userModel.userId).subscribe(
                  data => {
                    
                    this.siteLaout.custommenuModel = data['listOfCustomProduct']; 
                    this.custommenuModel = data['listOfCustomProduct'];                     
                    sessionStorage.setItem('UsercustomManu', JSON.stringify(this.custommenuModel));
                    localStorage.setItem('UsercustomManu', JSON.stringify(this.custommenuModel));
                    
                  },
                  error => {
                    console.log(error);
                  }
                );


              }
            }
            else {
              this.showMessage("You don't have access to any Tool. Please contact administrator.", 'Error', 'error');
            }
          }
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }



      },
      errorCode => {
        this.showMessage(errorCode, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  CheckLoginbyusernamewithhash() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.UserService.checkUserbyusernamewithhash(this.tokenKey,
      sessionStorage.getItem('Username'), sessionStorage.getItem('Hash')).subscribe(
        data => {
          if (data["status"] == "Success") {
            this.userModel = data["usersModel"];
            if (this.userModel != null) {
              sessionStorage.setItem('UserId', this.userModel.userId);
              sessionStorage.setItem('LastLogin', this.userModel.lastLogin);
              this.router.navigate(['Home']);
            }
          }
          else {
            this.showMessage(data["status"], 'Error', 'error');
          }
        },
        errorCode => {
          this.showMessage(errorCode, 'Error', 'error');
        }
      );
  }
}
