import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BriefingTypeModel } from 'src/Models/BriefingMasterModel';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-briefing-type',
  templateUrl: './briefing-type.component.html',
  styleUrls: ['./briefing-type.component.css']
})
export class BriefingTypeComponent implements OnInit {

  tokenKey: string;
  isloading: boolean = false;
  PageNumber: number = 1;
  listofTypes: BriefingTypeModel[];
  SelectedTool: string;
  PageSize: number;
  isRecord: boolean = false;
  totalRecordCount: number = 0;
  carrierCatName:string;
  OrderBy: string;
  WhereCondition: string;
  isAscending: boolean = false;
  upDowcarrierCatNameCSS: string = '';
  upDowSortOrderCSS: string = '';
  upDowIsActiveCSS: string = '';
  upDowIsArchiveCSS: string = '';
  carrierCatId:number = 0;
  openMenuIndex: number = -1;

  constructor(private i360service: I360Service, private router: Router, private checkAuth: CheckAuth, private toastr: ToastrService, private titleService: Title) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'SortOrder';
    this.carrierCatName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Briefing Type');
  }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetAllTypes();
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.carrierCatName = $event.target.value;
      else
      this.carrierCatName = 'null';

      this.GetAllTypes();
  }

  GetAllTypes() {
    if (this.carrierCatName == "null") this.carrierCatName = " ";
    this.i360service.i360BulletinType(this.tokenKey,this.PageNumber, this.PageSize, this.carrierCatName, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofTypes = data['listOfBulletinType'];
        if (this.listofTypes != null) {
          if (this.listofTypes.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofTypes[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => {
        console.log(error);
      }
    );
  }

  addBriefType() {
    let navigation = '/admin/' + this.SelectedTool + '/briefingType/manage';
    this.router.navigate([navigation]);
  }

  updateBriefType(carrierCatId) {
    let navigation = '/admin/' + this.SelectedTool + '/briefingType/manage/' + carrierCatId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.carrierCatId = id;
  }

  deleteBrifType(carrierCatId) {
    this.isloading = true;
    this.i360service.DeleteBriefingType(this.tokenKey, carrierCatId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
          this.showMessage("Deleted successfully", 'Success', 'success')
        }
        this.GetAllTypes();
      },
      error => { }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetAllTypes();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "CarrierCatName") {
        this.upDowcarrierCatNameCSS = 'fa fa-arrow-down';
        this.upDowSortOrderCSS = '';
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-down';
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowIsActiveCSS = 'fa fa-arrow-down';
        this.upDowIsArchiveCSS = '';
      }
      else if (sortBy === "IsArchive") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "CarrierCatName") {
        this.upDowcarrierCatNameCSS = 'fa fa-arrow-up';
        this.upDowSortOrderCSS = '';      
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-up';      
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = '';  
      }
      else if (sortBy === "IsActive") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowSortOrderCSS = '';      
        this.upDowIsActiveCSS = 'fa fa-arrow-up';
        this.upDowIsArchiveCSS = '';  
      }
      else if (sortBy === "IsArchive") {
        this.upDowcarrierCatNameCSS = '';
        this.upDowSortOrderCSS = '';      
        this.upDowIsActiveCSS = '';
        this.upDowIsArchiveCSS = 'fa fa-arrow-up';  
      }
    }
    this.GetAllTypes();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}








