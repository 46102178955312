import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CrraContentFieldService { 
  public Fields:Fields[] = [
    { id: 1, name: "Jurisdiction" },
    { id: 2, name: "Carrier" },
    { id: 3, name: "Category"},
    { id: 4, name: "Sub Category" },
    { id: 5, name: "Rule Heading" },
    { id: 6, name: "Rule Cite" },
    { id: 7, name: "Rule Text" },
    { id: 8, name: "Draft Revisions" },
    { id: 9, name: "Audit Month" },
    { id: 10, name: "Last Audit" },   
    { id: 11, name: "Effective Date" } 
  ];

  getFields() {
    return this.Fields;
  }
}

class Fields {
  id: number;
  name: string;
}