import { Component, OnInit } from '@angular/core';
import { EBBClaimCountReportsModel } from 'src/Models/EBBClaimCountReportsModel';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { NLADClaimService } from 'src/Services/nladClaim.service';
import { InrollProductsCompanyModel } from 'src/Models/InrollProductsCompanyModel';
import { ClaimStateModel } from 'src/Models/ClaimStateModel';
import { Router } from '@angular/router';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';

@Component({
  selector: 'app-claim-reports',
  templateUrl: './claim-reports.component.html',
  styleUrls: ['./claim-reports.component.css']
})
export class ClaimReportsComponent implements OnInit {
  filterModel = new FilterModel();
  listOfData: EBBClaimCountReportsModel[];
  tokenKey: string = '';
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false

  upDowCompanyNameCSS: string = '';
  upDowYearsCSS: string = '';
  upDowMonthsCSS: string = '';
  upDowTotalCountCSS: string = '';
  upDowTotalAmountCSS: string = '';
  upDowDeviceCountCSS: string = '';
  upDowDeviceAmountCSS: string = '';
  upDowStateCSS: string = '';

  dropdownMonths = [];
  selectedItemsMonths = [];
  dropdownSettingMonths = {};
  listOfMonths = [];

  dropdownYears = [];
  selectedItemsYears = [];
  dropdownSettingYears = {};
  listOfYears = [];
  monthText = [];

  listOfInrollProductCompany: InrollProductsCompanyModel[];
  dropdownCompanies = [];
  selectedItemsCompanies = [];
  dropdownSettingCompanies = {};
  listOfCompany = [];

  listOfStateData: ClaimStateModel[];
  dropdownStates = [];
  selectedItemsStates = [];
  dropdownSettingStates = {};
  listOfStates = [];

  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  isloading: boolean = false;

  constructor(private appActivity: ApplicationActivityService,private nladClaimService: NLADClaimService, private checkAuth: CheckAuth, private titleService: Title, private router: Router) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Monthly Claims- ACP | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'CompanyName';
    this.filterModel.userID = this._userID;
    this.filterModel.productId = 0;
    this.filterModel.months = '0';
    this.filterModel.years = '0';
    this.filterModel.state = '0';
    if (sessionStorage.getItem('CompanyId') != null && sessionStorage.getItem('CompanyId') != '')
      this.filterModel.providerId = sessionStorage.getItem('CompanyId');
    //this.EBBClaimCountReports();
    this.GetMonths();
    this.GetYears();
    this.EBBClaimCountReports();
    this.GetClaimState();

    this.monthText.push({ 'id': 1, 'itemName': 'January' });
    this.monthText.push({ 'id': 2, 'itemName': 'February' });
    this.monthText.push({ 'id': 3, 'itemName': 'March' });
    this.monthText.push({ 'id': 4, 'itemName': 'April' });
    this.monthText.push({ 'id': 5, 'itemName': 'May' });
    this.monthText.push({ 'id': 6, 'itemName': 'June' });
    this.monthText.push({ 'id': 7, 'itemName': 'July' });
    this.monthText.push({ 'id': 8, 'itemName': 'August' });
    this.monthText.push({ 'id': 9, 'itemName': 'September' });
    this.monthText.push({ 'id': 10, 'itemName': 'October' });
    this.monthText.push({ 'id': 11, 'itemName': 'November' });
    this.monthText.push({ 'id': 12, 'itemName': 'December' });
  }

  EBBClaimCountReports() {  
    this.blockDocument()
    this.listOfData = [];
    this.isloading = true;
    this.nladClaimService.EBBClaimCountReports(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        this.unblockDocument();
        if (data != null) {
          this.listOfData = data['listOfData'];
          if (this.listOfData != null) {
            if (this.listOfData.length > 0) {
              this.totalRecordCount = this.listOfData[0].totalRecords;
            }
            else
              this.totalRecordCount = 0;
          }
          else
            this.totalRecordCount = 0;
        }
      },
      error => {
        this.isloading = false;
        this.unblockDocument();
        console.log(error);
      }
    );
  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.EBBClaimCountReports();
  }

  sort(sortBy: any) {
    this.upDowCompanyNameCSS = '';
    this.upDowYearsCSS = '';
    this.upDowMonthsCSS = '';
    this.upDowTotalCountCSS = '';
    this.upDowTotalAmountCSS = '';
    this.upDowDeviceCountCSS = '';
    this.upDowDeviceAmountCSS = '';
    this.upDowStateCSS = '';

    this.isAscending = !this.isAscending;
    this.filterModel.orderBy = this.isAscending ? sortBy : sortBy + ' DESC';

    switch (sortBy) {
      case 'companyName': this.upDowCompanyNameCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'years': this.upDowYearsCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'months': this.upDowMonthsCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'totalCount': this.upDowTotalCountCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'totalAmount': this.upDowTotalAmountCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'deviceCount': this.upDowDeviceCountCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'deviceAmount': this.upDowDeviceAmountCSS = this.isAscending ? 'active' : 'active-up'; break;
      case 'state': this.upDowStateCSS = this.isAscending ? 'active' : 'active-up'; break;
      default: break;
    }
    this.OrderBy = sortBy;
    this.EBBClaimCountReports();
  }

  GetMonths() {
    this.dropdownSettingMonths = {
      singleSelection: true,
      text: 'Filter by Month',
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No Month',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownMonths.push({ 'id': 1, 'itemName': 'January' });
    this.dropdownMonths.push({ 'id': 2, 'itemName': 'February' });
    this.dropdownMonths.push({ 'id': 3, 'itemName': 'March' });
    this.dropdownMonths.push({ 'id': 4, 'itemName': 'April' });
    this.dropdownMonths.push({ 'id': 5, 'itemName': 'May' });
    this.dropdownMonths.push({ 'id': 6, 'itemName': 'June' });
    this.dropdownMonths.push({ 'id': 7, 'itemName': 'July' });
    this.dropdownMonths.push({ 'id': 8, 'itemName': 'August' });
    this.dropdownMonths.push({ 'id': 9, 'itemName': 'September' });
    this.dropdownMonths.push({ 'id': 10, 'itemName': 'October' });
    this.dropdownMonths.push({ 'id': 11, 'itemName': 'November' });
    this.dropdownMonths.push({ 'id': 12, 'itemName': 'December' });
  }

  removeElemetninArrayById(array, element) {
    return array.filter(e => e.id !== element);
  }

  onSelectedMonths($event) {
    this.listOfMonths = []
    this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event.id)
    this.listOfMonths.push($event.id);
    this.filterModel.pageNumber = '1'
    this.filterModel.months = this.listOfMonths.join(',')
    this.EBBClaimCountReports();
  }

  onDeSelectedMonths($event) {
    this.listOfMonths = []
    if (this.listOfMonths != null) {
      if (this.listOfMonths.length > 0) {
        this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event.id)
      }
    }
    this.filterModel.pageNumber = '1'
    this.filterModel.months = this.listOfMonths.join(',')
    this.EBBClaimCountReports();
  }

  onSelectAllMonths($event) {
    this.listOfMonths = []
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonths != null) {
        if (this.listOfMonths.length > 0) {
          this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event[iCounter].id)
          this.listOfMonths.push($event[iCounter].id);
        }
        else {
          this.listOfMonths.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.pageNumber = '1'
    this.filterModel.months = this.listOfMonths.join(',')
    this.EBBClaimCountReports();
  }

  onDeSelectAllMonths($event) {
    this.listOfMonths = [];
    this.filterModel.pageNumber = '1'
    this.filterModel.months = '0'
    this.EBBClaimCountReports();
  }


  GetYears() {
    this.nladClaimService.GetClaimYears(this.tokenKey, this.filterModel).subscribe(
      data => {
        if (data != null) {
          let dropdownYears = data['listOfData'];
          if (dropdownYears != null) {
            if (dropdownYears.length > 0) {
              
              for (let iCounter = 0; iCounter < dropdownYears.length; iCounter++) {
                this.dropdownYears.push({ 'id': dropdownYears[iCounter], 'itemName': dropdownYears[iCounter] });
              }
            }
          }
          this.dropdownSettingYears = {
            singleSelection: true,
            text: 'Filter by Year',
            enableSearchFilter: true,
            classes: 'borderLessTextBoxGreen',
            badgeShowLimit: 1,
            noDataLabel: 'No Year',
            showCheckbox: false,
            enableFilterSelectAll: false
          };
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedYears($event) {
    if (this.listOfYears.length > 0) {
      this.listOfYears = this.listOfYears.filter(m => m != $event.id);
    }
    this.listOfYears.push($event.id);
    this.filterModel.pageNumber = '1'
    if (this.listOfYears.length > 0)
      this.filterModel.years = this.listOfYears.join(',')
    else
      this.filterModel.years = '0';
      this.EBBClaimCountReports();
  }

  onDeSelectedYears($event) {
    if (this.listOfYears != null) {
      if (this.listOfYears.length > 0) {
        this.listOfYears = this.listOfYears.filter(m => m != $event.id);
      }
    }
    this.filterModel.pageNumber = '1'
    if (this.listOfYears.length > 0)
      this.filterModel.years = this.listOfYears.join(',')
    else
      this.filterModel.years = '0';
      this.EBBClaimCountReports();
  }

  onSelectAllYears($event) {
    this.listOfYears = []
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfYears != null) {
        if (this.listOfYears.length > 0) {
          this.listOfYears = this.listOfYears.filter(m => m != $event[iCounter].id);
          this.listOfYears.push($event[iCounter].id);
        }
        else {
          this.listOfYears.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.pageNumber = '1'
    if (this.listOfYears.length > 0)
      this.filterModel.years = this.listOfYears.join(',')
    else
      this.filterModel.years = '0';
      this.EBBClaimCountReports();
  }

  onDeSelectAllYears($event) {
    this.listOfYears = [];
    this.filterModel.pageNumber = '1'
    this.filterModel.years = '0'
    this.EBBClaimCountReports();
  }


  // GetYears() {
  //   this.nladClaimService.GetClaimYears(this.tokenKey, this.filterModel).subscribe(
  //     data => {
  //       if (data != null) {
  //         let listOfYears = data['listOfData'];
  //         if (listOfYears != null) {
  //           if (listOfYears.length > 0) {

  //             for (let iCounter = 0; iCounter < listOfYears.length; iCounter++) {
  //               this.listOfYears.push({ 'id': listOfYears[iCounter], 'itemName': listOfYears[iCounter] });
  //             }
  //           }
  //         }
  //         this.dropdownSettingYears = {
  //           singleSelection: true,
  //           text: 'Filter by Year',
  //           enableSearchFilter: true,
  //           classes: 'borderLessTextBoxGreen',
  //           badgeShowLimit: 1,
  //           noDataLabel: 'No Year',
  //           showCheckbox: false,
  //           enableFilterSelectAll: false
  //         };
  //       }
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  // onSelectedYears($event) {

  //   this.listOfYears = []
  //   this.listOfYears = this.removeElemetninArrayById(this.listOfYears, $event.id)
  //   this.listOfYears.push($event.id);
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.years = this.listOfYears.join(',')
  //   this.EBBClaimCountReports();
  // }

  // onDeSelectedYears($event) {

  //   this.listOfYears = []
  //   if (this.listOfYears != null) {
  //     if (this.listOfYears.length > 0) {
  //       this.listOfYears = this.removeElemetninArrayById(this.listOfYears, $event.id)
  //     }
  //   }
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.years = this.listOfYears.join(',')
  //   this.EBBClaimCountReports();
  // }

  // onSelectAllYears($event) {

  //   this.listOfYears = []
  //   for (let iCounter = 0; iCounter < $event.length; iCounter++) {
  //     if (this.listOfYears != null) {
  //       if (this.listOfYears.length > 0) {
  //         this.listOfYears = this.removeElemetninArrayById(this.listOfYears, $event[iCounter].id)
  //         this.listOfYears.push($event[iCounter].id);
  //       }
  //       else {
  //         this.listOfYears.push($event[iCounter].id);
  //       }
  //     }
  //   }
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.years = this.listOfYears.join(',')
  //   this.EBBClaimCountReports();
  // }

  // onDeSelectAllYears($event) {

  //   this.listOfYears = [];
  //   this.filterModel.pageNumber = '1'
  //   this.filterModel.years = '0'
  //   this.EBBClaimCountReports();
  // }

  setMonthsText(id) {
    return this.monthText.filter(m => m.id == id)[0].itemName;
  }

  GetClaimState() {
    this.nladClaimService.GetClaimState(this.tokenKey).subscribe(
      data => {
        if (data != null) {
          this.listOfStateData = data['listOfData'];
          if (this.listOfStateData != null) {
            if (this.listOfStateData.length > 0) {

              for (let iCounter = 0; iCounter < this.listOfStateData.length; iCounter++) {
                this.dropdownStates.push({ 'id': this.listOfStateData[iCounter].shortName, 'itemName': this.listOfStateData[iCounter].name });
              }
            }
          }
          this.dropdownSettingStates = {
            singleSelection: true,
            text: 'Filter by State',
            enableSearchFilter: true,
            classes: 'borderLessTextBoxGreen',
            badgeShowLimit: 1,
            noDataLabel: 'No State',
            showCheckbox: false,
            enableFilterSelectAll: false
          };
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  onSelectedStates($event) {

    this.listOfStates = this.removeElemetninArrayById(this.listOfStates, $event.id)
    this.listOfStates.push($event.id);
    this.filterModel.pageNumber = '1';
    this.filterModel.state = $event.id;
    this.EBBClaimCountReports();
  }

  onDeSelectedStates($event) {
    if (this.listOfStates != null) {
      if (this.listOfStates.length > 0) {
        this.listOfStates = this.removeElemetninArrayById(this.listOfStates, $event.id)
      }
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.state = $event.id;
    this.EBBClaimCountReports();
  }

  onSelectAllStates($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStates != null) {
        if (this.listOfStates.length > 0) {
          this.listOfStates = this.removeElemetninArrayById(this.listOfStates, $event[iCounter].id)
          this.listOfStates.push($event[iCounter].id);
        }
        else {
          this.listOfStates.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.pageNumber = '1';
    this.filterModel.state = $event[0].id;
    this.EBBClaimCountReports();
  }

  onDeSelectAllStates($event) {
    this.listOfStates = [];
    this.filterModel.pageNumber = '1';
    this.filterModel.state = '0';
    this.EBBClaimCountReports();
  }

  redirectToDetailsPage(months: any,years: any,state: any,ProviderId: any, companyName: any) {
    state=state==null?"0":state;
    months=months==null?"0":months;
    years=years==null?"0":years;

    sessionStorage.setItem('FrontDetailReportsYear',  years);
    sessionStorage.setItem('FrontDetailReportsMonths', months);
    sessionStorage.setItem('FrontDetailReportsProviderId', ProviderId);
    sessionStorage.setItem('FrontDetailReportsCompanyName', companyName);
    sessionStorage.setItem('FrontDetailReportsState', state);
    sessionStorage.setItem('FrontReportFlag', 'Monthly');
    ApplicationActivityStore('View', 'Detail Monthly Report-ACP',this.appActivity,0, ActionActivity['Inroll ACP'],'/View/Details');
    this.router.navigate(['/View/Details'])
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  redirectToListPage() {
    this.router.navigate(['/Claim/Reports/List']);
  }
}
