import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseRateType } from 'src/Models/Common';
import { UpdatedBaseRateModel, AddOnServicesModel } from 'src/Models/UpdatedBaseRateModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompleted, getIncompletedStep } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-add-on-services',
  templateUrl: './add-on-services.component.html',
  styleUrls: ['./add-on-services.component.css']
})
export class AddOnServicesComponent implements OnInit {

  SelectedTool: string;
  userID: any;
  clientInformationId: number = 0;
  priceQuoteId: number = 0;
  tokenKey: string;
  isloading: boolean = false;

  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  listofUpdatedAddOnServices: UpdatedBaseRateModel[];
  listofUpdatedAddOnOtherRegulatory: UpdatedBaseRateModel[];
  listofConsultingProjects: UpdatedBaseRateModel[];
  isRecord: boolean = false;
  isRecord1: boolean = false;
  isConsultingProjects: boolean = false;
  stateCode: any;
  solutionTotal: number = 0;
  regulatoryTotal: number = 0;
  consultingTotal: number = 0;
  VoiceOnlyPrice: number = 0
  VoiceWithBroadband: number = 0;

  addOnServices: AddOnServicesModel =
    {
      AddOnInternalPlatformOffering: [],
      AddOnOtherRegulatoryRequirement: [],
      ConsultingProjects:[]
    };

  isRateDetailsCompleted: boolean;
  isQuoteDetailsCompleted: boolean;
  isAddOnServicesCompleted: boolean;
  isComplianceDetailsCompleted: boolean;
  isProposalDetailsCompleted: boolean;
  currentActiveTab: number = 1;

  url: string;
  tabId: number = 0;

  mainUrl: string;
  subUrl: string;
  companyName: string;

  constructor(private router: Router, private PricingQuotesMaster: PricingQuotesService, private checkAuth: CheckAuth
    , private titleService: Title, private activedRoute: ActivatedRoute, private toastr: ToastrService, private globle: Globle) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Price Quote - ' + this.companyName);

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.priceQuoteId = params['id'];
        }
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.priceQuoteId != null) {
      this.GetProgressStepDetailsById(this.priceQuoteId);
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isRateDetailsCompleted = null
      this.globle.cApp.statusModel.isQuoteDetailsCompleted = null
      this.globle.cApp.statusModel.isAddOnServicesCompleted = null
      this.globle.cApp.statusModel.isComplianceDetailsCompleted = null
      this.globle.cApp.statusModel.isProposalDetailsCompleted = null
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
  }

  ngOnInit() {
    this.isloading = true;


    this.GetAddOnServices();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  previousStep() {
    this.router.navigate(['/admin/' + this.SelectedTool + '/pricing-quotes/' + this.priceQuoteId]);
  }

  GetAddOnServices() {
    this.blockDocument();
    this.PricingQuotesMaster.GetAddOnServices(this.tokenKey, this.priceQuoteId, BaseRateType['AddOn(InteserraPlatformOffering)']).subscribe(
      data => {
        this.unblockDocument();
        this.isloading = false;
        this.solutionTotal = 0;
        // this.regulatoryTotal = data['regulatoryTotal'];
        this.listofUpdatedAddOnServices = data['updatedAddOnServices'];

        if (this.listofUpdatedAddOnServices != null) {
          for (let iCounter = 0; iCounter < this.listofUpdatedAddOnServices.length; iCounter++) {
            if (this.listofUpdatedAddOnServices[iCounter].isSelected) {
              if (this.listofUpdatedAddOnServices[iCounter].noOfUsers == null) {
                this.listofUpdatedAddOnServices[iCounter].noOfUsers = 1;
                this.listofUpdatedAddOnServices[iCounter].totalAmount = this.listofUpdatedAddOnServices[iCounter].monthlyRate;
              }
              this.solutionTotal = this.solutionTotal + this.listofUpdatedAddOnServices[iCounter].totalAmount;
            }
          }
          if (this.listofUpdatedAddOnServices.length == 0) {
            this.isRecord = true;
          }
          else {
            this.isRecord = false;
          }
        }
        else {
          this.isRecord = true;
        }

        this.listofUpdatedAddOnOtherRegulatory = data['updatedAddOnOtherRegulatory'];
        if (this.listofUpdatedAddOnOtherRegulatory != null) {
          for (let iCounter = 0; iCounter < this.listofUpdatedAddOnOtherRegulatory.length; iCounter++) {
            if (this.listofUpdatedAddOnOtherRegulatory[iCounter].isSelected)
              this.regulatoryTotal = this.regulatoryTotal + this.listofUpdatedAddOnOtherRegulatory[iCounter].monthlyRate;
          }
          if (this.listofUpdatedAddOnOtherRegulatory.length == 0) {
            this.isRecord1 = true;
          }
          else {
            this.isRecord1 = false;
          }
        }
        else {
          this.isRecord1 = true;
        }

        this.listofConsultingProjects= data['updatedConsultingProjects'];
        if (this.listofConsultingProjects != null) {
          for (let iCounter = 0; iCounter < this.listofConsultingProjects.length; iCounter++) {
            if (this.listofConsultingProjects[iCounter].isSelected)
              this.consultingTotal = this.consultingTotal + this.listofConsultingProjects[iCounter].monthlyRate;
          }
          if (this.listofConsultingProjects.length == 0) {
            this.isConsultingProjects = true;
          }
          else {
            this.isConsultingProjects = false;
          }
        }
        else {
          this.isConsultingProjects = true;
        }
      },
      error => {
        this.unblockDocument();
        this.stateCode = error
      }
    );
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  SelectedBaseRateCheck(item) {

    let data = this.listofUpdatedAddOnServices.filter(y => y.baseRateId == item.baseRateId)[0];
    if (data != null) {
      if (data.noOfUsers == null || (+data.noOfUsers == 0 && data.isSelected == true)) {
        data.noOfUsers = 1;
        data.totalAmount = + item.monthlyRate;
      }
    }

    this.solutionTotal = 0;
    this.listofUpdatedAddOnServices.forEach(x => {
      if (x.isSelected) {
        this.solutionTotal = + (this.solutionTotal + (+x.totalAmount));
      }
    });
  }

  save(clientForm: any) {

    for (let iCounter = 0; iCounter < this.listofUpdatedAddOnServices.length; iCounter++) {
      this.listofUpdatedAddOnServices[iCounter].createdBy = this.userID;
      this.listofUpdatedAddOnServices[iCounter].clientInformationId = this.clientInformationId;
      this.listofUpdatedAddOnServices[iCounter].priceQuoteId = this.priceQuoteId;
    }

    for (let iCounter = 0; iCounter < this.listofUpdatedAddOnOtherRegulatory.length; iCounter++) {
      this.listofUpdatedAddOnOtherRegulatory[iCounter].createdBy = this.userID;
      this.listofUpdatedAddOnOtherRegulatory[iCounter].clientInformationId = this.clientInformationId;
      this.listofUpdatedAddOnOtherRegulatory[iCounter].priceQuoteId = this.priceQuoteId;
    }

    for (let iCounter = 0; iCounter < this.listofConsultingProjects.length; iCounter++) {
      this.listofConsultingProjects[iCounter].createdBy = this.userID;
      this.listofConsultingProjects[iCounter].clientInformationId = this.clientInformationId;
      this.listofConsultingProjects[iCounter].priceQuoteId = this.priceQuoteId;
    }

    this.addOnServices.AddOnInternalPlatformOffering = this.listofUpdatedAddOnServices;
    this.addOnServices.AddOnOtherRegulatoryRequirement = this.listofUpdatedAddOnOtherRegulatory;
    this.addOnServices.ConsultingProjects = this.listofConsultingProjects;

    // if (!clientForm.invalid) {
    //   this.globle.cApp.statusModel.isAddOnServicesCompleted = false;
    // }
    this.blockDocument()
    this.PricingQuotesMaster.UpdatedAddOnServicesAddUpdate(this.addOnServices, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        this.unblockDocument();
        this.globle.cApp.statusModel.isAddOnServicesCompleted = true;
        //this.showMessage('Record Saved Successfully.', 'success', 'success');
        this.router.navigate(['/admin/' + this.SelectedTool + '/compliance/' + this.priceQuoteId]);
      }
      else {
        this.showMessage(data.status, 'Error', 'error');
        this.unblockDocument();
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }

  changenoOfUsers(event: number, item) {
    let data = this.listofUpdatedAddOnServices.filter(y => y.baseRateId == item.baseRateId)[0];
    if (data != null) {
      data.noOfUsers = +event;
      data.totalAmount = +(event * data.monthlyRate);
    }

    this.solutionTotal = 0;
    this.listofUpdatedAddOnServices.forEach(x => {
      if (x.isSelected) {
        this.solutionTotal = +(this.solutionTotal + (+x.totalAmount));
      }
    });
  }

  changeTotalAmount(event: number, item) {
    let data = this.listofUpdatedAddOnServices.filter(y => y.baseRateId == item.baseRateId)[0];
    if (data != null) {
      data.totalAmount = +event;
    }

    this.solutionTotal = 0;
    this.listofUpdatedAddOnServices.forEach(x => {
      if (x.isSelected) {
        this.solutionTotal = + (this.solutionTotal + (+x.totalAmount));
      }
    });
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  changeMonthlyRate(event: number, item) {
    // if (item.serviceId == 10) {

    //   if (event > 0) {
    //     this.VoiceOnlyPrice = + event;
    //   }
    //   else {
    //     let data = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.serviceId == 7)[0];
    //     if (data != null) {
    //       if (data.isSelected == true)
    //         this.VoiceOnlyPrice = + AddOnFixPrice.VoiceOnly * 2;
    //       else
    //         this.VoiceOnlyPrice = 0;
    //     }
    //     else {
    //       this.VoiceOnlyPrice = 0;
    //     }
    //   }

    //   let VWB = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.serviceId == 8)[0];
    //   if (VWB != null) {
    //     if (VWB.isSelected == true) {
    //       this.VoiceWithBroadband = + AddOnFixPrice.VoiceWithBroadBandh * 2;
    //     }
    //     else {
    //       this.VoiceWithBroadband = 0;
    //     }
    //   }

    //   let data = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.serviceId == 1)[0];
    //   if (data != null) {
    //     data.annualRate = +this.VoiceOnlyPrice + this.VoiceWithBroadband;
    //   }
    //  }

    // let data = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.baseRateId == item.baseRateId)[0];
    // if (data != null) {
    //   data.monthlyRate = +event;
    // }

    // this.regulatoryTotal = 0;
    // this.listofUpdatedAddOnOtherRegulatory.forEach(x => {
    //   if (x.isSelected) {
    //     this.regulatoryTotal = +(this.regulatoryTotal + (+x.monthlyRate));
    //     this.regulatoryTotal = +(this.regulatoryTotal + (+x.annualRate));
    //   }
    // });
  }

  changeAnnualRate(event: number, item) {
    let data = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.baseRateId == item.baseRateId)[0];
    if (data != null) {
      data.annualRate = +event;
    }

    this.regulatoryTotal = 0;
    this.listofUpdatedAddOnOtherRegulatory.forEach(x => {
      if (x.isSelected) {
        this.regulatoryTotal = +(this.regulatoryTotal + (+x.monthlyRate));
        this.regulatoryTotal = +(this.regulatoryTotal + (+x.annualRate));
      }
    });
  }

  changeAddOnMonthlyRate(event: number, item) {
    let data = this.listofUpdatedAddOnServices.filter(y => y.baseRateId == item.baseRateId)[0];
    if (data != null) {
      data.monthlyRate = +event;
      data.totalAmount = +(event * data.noOfUsers);
    }

    this.solutionTotal = 0;
    this.listofUpdatedAddOnServices.forEach(x => {
      if (x.isSelected) {
        this.solutionTotal = +(this.solutionTotal + (+x.totalAmount));
      }
    });
  }

  SelectedBaseRateCheck1(item) {
    // let Vop = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.serviceId == 7)[0];
    // if (Vop != null) {
    //   if (Vop.isSelected == true) {
    //     let VOOdata = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.serviceId == 10)[0];
    //     if (VOOdata != null) {
    //       if (VOOdata.monthlyRate > 0) {
    //         this.VoiceOnlyPrice = 0;
    //         this.VoiceOnlyPrice = +VOOdata.monthlyRate;
    //       } else {
    //         this.VoiceOnlyPrice = +AddOnFixPrice.VoiceOnly * 2;
    //       }
    //     }
    //   }
    //   else {
    //     let VOOdata = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.serviceId == 10)[0];
    //     if (VOOdata != null) {
    //       if (VOOdata.monthlyRate > 0)
    //         this.VoiceOnlyPrice = 0;
    //       this.VoiceOnlyPrice = +VOOdata.monthlyRate;
    //     }
    //     else
    //       this.VoiceOnlyPrice = 0;
    //   }
    // }


    // let VWBdata = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.serviceId == 8)[0];
    // if (VWBdata != null) {
    //   if (VWBdata.isSelected == true)
    //     this.VoiceWithBroadband = + AddOnFixPrice.VoiceWithBroadBandh * 2;
    //   else
    //     this.VoiceWithBroadband = 0;
    // } else {
    //   this.VoiceWithBroadband = 0;
    // }

    // let FF477 = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.serviceId == 1)[0];
    // if (FF477 != null) {
    //   FF477.annualRate = + this.VoiceOnlyPrice + this.VoiceWithBroadband;
    // }

    // let data = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.serviceId == 1)[0];

    this.regulatoryTotal = 0;
    this.listofUpdatedAddOnOtherRegulatory.forEach(x => {
      if (x.isSelected) {
        this.regulatoryTotal = + (this.regulatoryTotal + (+x.monthlyRate));
      }
    });
  }

  SelectedBaseRateCheckConsProj(item) {
    this.consultingTotal = 0;
    this.listofConsultingProjects.forEach(x => {
      if (x.isSelected) {
        this.consultingTotal = + (this.consultingTotal + (+x.monthlyRate));
      }
    });
  }

  ChangeConsultingCharger(event:number,item)
  {
    let data = this.listofConsultingProjects.filter(y => y.baseRateId == item.baseRateId)[0];
    if (data != null) {
      data.monthlyRate = +event;
    }

    this.consultingTotal = 0;
    this.listofConsultingProjects.forEach(x => {
      if (x.isSelected) {
        this.consultingTotal = + (this.consultingTotal + (+x.monthlyRate));
      }
    });
  }

  ChangeRegulatoryRequirement(event:number,item)
  {
    let data = this.listofUpdatedAddOnOtherRegulatory.filter(y => y.baseRateId == item.baseRateId)[0];
    if (data != null) {
      data.monthlyRate = +event;
    }

    this.regulatoryTotal = 0;
    this.listofUpdatedAddOnOtherRegulatory.forEach(x => {
      if (x.isSelected) {
        this.regulatoryTotal = + (this.regulatoryTotal + (+x.monthlyRate));
      }
    });
  }

  activeTab(url: string, tabId: number) {

    if (this.priceQuoteId != null) {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsById(this.tokenKey, priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
          this.activeTab('add-on-services', 7);
        }
      });
  }
}
