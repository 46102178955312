import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CheckAuth } from '../../../Services/utility.service';
import { UserModel } from '../../../Models/usersModel';
import { UsersService } from '../../../Services/users.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ProductsService } from '../../../Services/products.service';
import { ToolEnum } from 'src/Models/Common';
import { TabsetComponent } from 'ngx-bootstrap';
import { I360Service } from 'src/Services/i360Service';
import { CompanyService } from 'src/Services/company.service';
@Component({
  selector: 'app-manageuser',
  templateUrl: './manageuser.component.html',
  styleUrls: ['./manageuser.component.css']
})
export class ManageuserComponent implements OnInit {
  @ViewChild('statesTabs', { static: false }) statesTabs: TabsetComponent;
  userForm: FormGroup;
  loginuserID: number = 0;

  allstate: boolean = false;
  //clearstate: boolean = false;
  tokenKey: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  statusCode: number = 0;
  _userID: number = 0;
  userModel: UserModel = {
    userId: '',
    userName: '',
    companyId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isActive: '',
    createdDate: null,
    createdBy: null,
    modifiedDate: null,
    modifiedBy: null,
    isICalSubscription: '',
    password: '',
    taxPortalPassword: '',
    taxPortalUsername: '',
    isSendEmail: '',
    title: ''
  };
  // Product List---------------
  dropdownListProduct = [];
  selectedItemsProduct = [];
  dropdownSettingsProduct = {};
  productFilter: string = '';
  listOfProductID = [];
  // Product List---------------
  SelectedTool: string;

  // Company List
  company: any[];
  filteredCompanySingle: any[];

  selectedAllWireless: boolean;
  selectedAllVoIP: boolean;
  selectedAlliPR: boolean;
  selectedAllAction: boolean;
  selectedAllBriefing: boolean;
  selectedAllBriefingCarriers: boolean;
  selectedAllExportCitiation: boolean;
  TRFAselectedAllExportCitiation: boolean;
  CRRAselectedAllExportCitiation: boolean;
  selectedAllTraining: boolean;
  selectedAllspecialReport: boolean;
  selectedAllTaxExemptionForm: boolean;

  selectedAllWebinar: boolean;
  selectedAllInteserraResources: boolean;
  selectedAllWhitepapers: boolean;

  selectedWirelessCount = 0;
  selectedVoIPCount = 0;
  selectedIPRCount = 0;
  selectedActionCount = 0;
  selectedBriefingCount = 0;
  selectedBriefingCarriersCount = 0;
  selectedExportCitiationCount = 0;
  TRFAselectedExportCitiationCount = 0;
  CRRAselectedExportCitiationCount = 0;
  selectedTrainingCount = 0;
  selectedState911Count = 0;
  selectedSpecialReportCount = 0;
  selectedTaxExemptionFormCount = 0;

  selectedWebinarCount = 0;
  selectedInteserraResourcesCount = 0;
  selectedWhitepapersCount = 0;
  selectedStateTRFACount = 0;
  selectedStateCRRACount = 0;

  selectedProducts = [];
  isloadingstates: boolean = false;
  isShowICalCheckBox: boolean = false;
  isMultipleClick: boolean = false;

  dropdownSettingsCompany = {};
  dropdownListCompany = [];
  selectedItemsCompany = [];
  //carrierCategory: CarrierCategoryAdminModel[];

  isWirelessPrimaryContact: boolean = false;
  isIPRPrimaryContact: boolean = false;
  isVoIPPrimaryContact: boolean = false;
  isTractItPrimaryContact: boolean = false;
  isBriefingPrimaryContact: boolean = false;
  isTrainingPrimaryContact: boolean = false;
  is911PrimaryContact: boolean = false;
  selectedAll911State: boolean;
  exportCitiationList: any[] = [];
  TRFAexportCitiationList: any[] = [];
  CRRAexportCitiationList: any[] = [];
  isSpecialReportPrimaryContact: boolean = false;
  isTaxExemptionFormPrimaryContact: boolean = false;

  isWebinarPrimaryContact: boolean = false;
  isInteserraResourcesPrimaryContact: boolean = false;
  isWhitepapersPrimaryContact: boolean = false;
  public toolEnum = ToolEnum;
  selectedAllTRFAState: boolean;
  isTRFAPrimaryContact: boolean = false;
  selectedAllCRRAState: boolean;
  isCRRAPrimaryContact: boolean = false;
  fieldTextType: boolean;
  fieldTextType1: boolean;
  selectedProductItemName: boolean = false;
  // isClientPrimaryContact: boolean = false

  //Department List
  dropdownDepartmentList = [];
  selectedDepartmentItems = [];
  dropdownSettingsDepartment = {};
  listOfDepartmentID = [];
  isTaxportal: boolean = false;
  isREMS: boolean = false;
  isDuplicate: boolean = false;

  isInterestProduct: boolean = false;
  intrestedProductList = [];
  taxPortalCredentialList: any[] = [];
  isRowEdited: boolean = false;
  selectedItemsGridCompany = [];
  taxPortalId: number = 0;
  taxPortalTempId: number = 0;
  deleteTaxPortalTempId: number = 0;

  TempTaxPortalUserName:string;
  TempTaxPortalPassword:string;
  TempTaxPortalNotes:string;
  TempCompanyId:number=0;
  TempTaxPortalId:number=0;
  TempTempId:number=0;


  constructor(private i360Service: I360Service, private _fb: FormBuilder, private checkAuth: CheckAuth, private UserService: UsersService
    , private activedRoute: ActivatedRoute, private toastr: ToastrService, private companyService: CompanyService
    , private router: Router, private titleService: Title, private productsService: ProductsService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.userForm = this._fb.group({
      userid: 0,
      username: [''],
      companyId: [''],
      firstname: [''],
      lastname: [''],
      email: [''],
      phone: [''],
      userTypeId: 3,
      isactive: 1,
      createdBy: this.loginuserID,
      modifiedBy: this.loginuserID,
      wirelessStates: [],
      voIPStates: [],
      iprStates: [],
      actionStates: [],
      ListOfProducts: [''],
      isiCalSubscription: 0,
      briefingStates: [],
      password: [''],
      carrierCategory: [],
      training: [],
      state911: [],
      stateTRFA: [],
      stateCRRA: [],
      exportCitiation: [],
      specialReport: [],
      taxExemptionForm: [],
      webinars: [],
      inteserraResources: [],
      whitePapers: [],
      TRFAexportCitiation: [],
      CRRAexportCitiation: [],
      taxPortalPassword: [""],
      taxPortalUsername: [""],
      isSendEmail: 0,
      dropdownDepartmentList: [''],
      title: ['']
    });

    if (sessionStorage.getItem('isDuplicate') != null) {
      this.isDuplicate = sessionStorage.getItem('isDuplicate') == 'true' ? true : false;
      sessionStorage.removeItem('isDuplicate');
    }


    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Manage Subscriber');
    this.ExportCitiationSubscriptionGetAll();
    this.TRFAExportCitiationSubscriptionGetAll();
    this.CRRAExportCitiationSubscriptionGetAll();

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.movetotop();
    this.dropdownSettingsProduct = {
      singleSelection: false,
      text: 'Select',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No product available',
      showCheckbox: true,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsCompany = {
      singleSelection: true,
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No company available',
    };

    this.dropdownSettingsDepartment = {
      singleSelection: false,
      text: 'Select',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No department available',
      showCheckbox: true,
      enableFilterSelectAll: false
    };

    this.getAllCompany();
    this.getAllDepartment();
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this._userID = params['id'];
          this.Getuser();
        }
      }
      else {
        this._userID = 0;
        this.Getstates();
      }
      this.GetUserPortalCredentialDetailsByUserId(this._userID)
    });
  }

  selectAll(productType) {

    switch (productType) {
      case ToolEnum.wireless:
        for (let i = 0; i < this.userModel.wirelessStates.length; i++) {
          this.userModel.wirelessStates[i].isSelected = this.selectedAllWireless;
        }
        break;
      case ToolEnum.ipr:
        for (let i = 0; i < this.userModel.iprStates.length; i++) {
          this.userModel.iprStates[i].isSelected = this.selectedAlliPR;
        }
        break;
      case ToolEnum.voip:
        for (let i = 0; i < this.userModel.voIPStates.length; i++) {
          this.userModel.voIPStates[i].isSelected = this.selectedAllVoIP;
        }
      case ToolEnum.actionitem:
        for (let i = 0; i < this.userModel.actionStates.length; i++) {
          this.userModel.actionStates[i].isSelected = this.selectedAllAction;
        }
        break;
      case ToolEnum.briefingServices:
        for (let i = 0; i < this.userModel.briefingStates.length; i++) {
          this.userModel.briefingStates[i].isSelected = this.selectedAllBriefing;
        }
        break;
      case ToolEnum.carrierTariffs:
        for (let i = 0; i < this.userModel.carrierCategory.length; i++) {
          this.userModel.carrierCategory[i].isSelected = this.selectedAllBriefingCarriers;
        }
        break;
      case ToolEnum.training:
        for (let i = 0; i < this.userModel.training.length; i++) {
          this.userModel.training[i].isSelected = this.selectedAllTraining;
        }
        break;
      case ToolEnum.state911:
        for (let i = 0; i < this.userModel.state911.length; i++) {
          this.userModel.state911[i].isSelected = this.selectedAll911State;
        }
        break;
      case ToolEnum.trfa:
        for (let i = 0; i < this.userModel.stateTRFA.length; i++) {
          this.userModel.stateTRFA[i].isSelected = this.selectedAllTRFAState;
        }
        break;
      case ToolEnum.crra:
        for (let i = 0; i < this.userModel.stateCRRA.length; i++) {
          this.userModel.stateCRRA[i].isSelected = this.selectedAllCRRAState;
        }
        break;
      case ToolEnum.exportCitiation:

        for (let i = 0; i < this.userModel.exportCitiation.length; i++) {
          this.userModel.exportCitiation[i].isSelected = this.selectedAllExportCitiation;
        }
        break;
      case ToolEnum.specialReport:
        for (let i = 0; i < this.userModel.specialReport.length; i++) {
          this.userModel.specialReport[i].isSelected = this.selectedAllspecialReport;
        }
        break;
      case ToolEnum.TaxExemptionForms:
        for (let i = 0; i < this.userModel.taxExemptionForm.length; i++) {
          this.userModel.taxExemptionForm[i].isSelected = this.selectedAllTaxExemptionForm;
        }
        break;
      case ToolEnum.webinar:
        for (let i = 0; i < this.userModel.webinars.length; i++) {
          this.userModel.webinars[i].isSelected = this.selectedAllWebinar;
        }
        break;
      case ToolEnum.inteserraResources:
        for (let i = 0; i < this.userModel.inteserraResources.length; i++) {
          this.userModel.inteserraResources[i].isSelected = this.selectedAllInteserraResources;
        }
        break;
      case ToolEnum.whitepapers:
        for (let i = 0; i < this.userModel.whitePapers.length; i++) {
          this.userModel.whitePapers[i].isSelected = this.selectedAllWhitepapers;
        }
        break;
    }
    this.calculateCheckedCount();
  }

  checkIfAllSelected(productType) {
    switch (productType) {
      case ToolEnum.wireless:
        this.selectedAllWireless = this.userModel.wirelessStates.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.ipr:
        this.selectedAlliPR = this.userModel.iprStates.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.voip:
        this.selectedAllVoIP = this.userModel.voIPStates.every(function (item: any) {
          return item.isSelected == true;
        });
      case ToolEnum.actionitem:
        this.selectedAllAction = this.userModel.actionStates.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.briefingServices:
        this.selectedAllBriefing = this.userModel.briefingStates.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.carrierTariffs:
        this.selectedAllBriefingCarriers = this.userModel.carrierCategory.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.training:
        this.selectedAllTraining = this.userModel.training.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.state911:
        this.selectedAll911State = this.userModel.state911.every(function (item: any) {
          return item.isSelected == true;
        });

        break;
      case ToolEnum.trfa:
        this.selectedAllTRFAState = this.userModel.stateTRFA.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.crra:
        this.selectedAllCRRAState = this.userModel.stateCRRA.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.exportCitiation:
        this.selectedAllExportCitiation = this.userModel.exportCitiation.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.specialReport:
        this.selectedAllspecialReport = this.userModel.specialReport.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.TaxExemptionForms:
        this.selectedAllTaxExemptionForm = this.userModel.taxExemptionForm.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.webinar:
        this.selectedAllWebinar = this.userModel.webinars.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.inteserraResources:
        this.selectedAllInteserraResources = this.userModel.inteserraResources.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
      case ToolEnum.whitepapers:
        this.selectedAllWhitepapers = this.userModel.whitePapers.every(function (item: any) {
          return item.isSelected == true;
        });
        break;
    }
    this.calculateCheckedCount();
  }

  calculateCheckedCount() {
    this.selectedWirelessCount = this.userModel.wirelessStates.filter((item) => item.isSelected).length;
    this.selectedIPRCount = this.userModel.iprStates.filter((item) => item.isSelected).length;
    this.selectedVoIPCount = this.userModel.voIPStates.filter((item) => item.isSelected).length;
    this.selectedActionCount = this.userModel.actionStates.filter((item) => item.isSelected).length;
    this.selectedBriefingCount = this.userModel.briefingStates.filter((item) => item.isSelected).length;
    this.selectedBriefingCarriersCount = this.userModel.carrierCategory.filter((item) => item.isSelected).length;
    this.selectedTrainingCount = this.userModel.training.filter((item) => item.isSelected).length;
    this.selectedState911Count = this.userModel.state911.filter((item) => item.isSelected).length;
    this.selectedExportCitiationCount = this.userModel.exportCitiation.filter((item) => item.isSelected).length;
    this.selectedSpecialReportCount = this.userModel.specialReport.filter((item) => item.isSelected).length;
    this.selectedTaxExemptionFormCount = this.userModel.taxExemptionForm.filter((item) => item.isSelected).length;

    this.selectedWebinarCount = this.userModel.webinars.filter((item) => item.isSelected).length;
    this.selectedInteserraResourcesCount = this.userModel.inteserraResources.filter((item) => item.isSelected).length;
    this.selectedWhitepapersCount = this.userModel.whitePapers.filter((item) => item.isSelected).length;
    this.selectedStateTRFACount = this.userModel.stateTRFA.filter((item) => item.isSelected).length;
    this.TRFAselectedExportCitiationCount = this.TRFAexportCitiationList.filter((item) => item.isSelected).length;
    this.selectedStateCRRACount = this.userModel.stateCRRA.filter((item) => item.isSelected).length;
    this.CRRAselectedExportCitiationCount = this.CRRAexportCitiationList.filter((item) => item.isSelected).length;
  }

  Getuser(): void {
    this.isloadingstates = true;
    this.UserService.GetUserFindByUserID(this.tokenKey, this._userID).subscribe(
      data => {

        this.userModel = data['userMaster'];
        if (this.isDuplicate && this.userModel != null) {
          this.userModel[0].userName = '';
          this.userModel[0].email = '';
          this.userModel[0].password = '';
        }

        if (this.userModel[0].interestProduct != "" && this.userModel[0].interestProduct != null) {
          this.isInterestProduct = true;
          this.intrestedProductList = this.userModel[0].interestProduct.split(',');
        }

        if (this.userModel[0].exportCitiation.length > 0) {
          this.userModel[0].exportCitiation.map((x) => {
            this.exportCitiationList.map((y) => {
              if (y.exportCitiationId == x.exportCitiationId) y.isSelected = true;
            })
          })
        }
        if (this.userModel[0].trfaExportCitiation.length > 0) {
          this.userModel[0].trfaExportCitiation.map((x) => {
            this.TRFAexportCitiationList.map((y) => {
              if (y.exportCitiationId == x.exportCitiationId) y.isSelected = true;
            })
          })
        }

        if (this.userModel[0].crraExportCitiation.length > 0) {
          this.userModel[0].crraExportCitiation.map((x) => {
            this.CRRAexportCitiationList.map((y) => {
              if (y.exportCitiationId == x.exportCitiationId) y.isSelected = true;
            })
          })
        }

        this.userForm.setValue({
          'userid': this.userModel[0].userId,
          'username': this.userModel[0].userName,
          'companyId': this.userModel[0].companyId,
          'firstname': this.userModel[0].firstName,
          'lastname': this.userModel[0].lastName,
          'email': this.userModel[0].email,
          'phone': this.userModel[0].phone,
          'userTypeId': 3,
          'isactive': this.userModel[0].isActive,
          'createdBy': this.loginuserID,
          'modifiedBy': this.loginuserID,
          'wirelessStates': this.userModel[0].wirelessStates,
          'voIPStates': this.userModel[0].voIPStates,
          'iprStates': this.userModel[0].iprStates,
          'actionStates': this.userModel[0].actionStates,
          'ListOfProducts': '',
          'isiCalSubscription': this.userModel[0].isICalSubscription,
          'briefingStates': this.userModel[0].briefingStates,
          'password': this.userModel[0].password,
          'carrierCategory': this.userModel[0].carrierCategory,
          'exportCitiation': this.exportCitiationList,
          'training': this.userModel[0].training,
          'state911': this.userModel[0].state911,
          'stateTRFA': this.userModel[0].stateTRFA,
          'stateCRRA': this.userModel[0].stateCRRA,
          'specialReport': this.userModel[0].specialReport,
          'taxExemptionForm': this.userModel[0].taxExemptionForm,
          'webinars': this.userModel[0].webinars,
          'inteserraResources': this.userModel[0].inteserraResources,
          'whitePapers': this.userModel[0].whitePapers,
          'TRFAexportCitiation': this.TRFAexportCitiationList,
          'CRRAexportCitiation': this.CRRAexportCitiationList,
          'taxPortalPassword': this.userModel[0].taxPortalPassword,
          'taxPortalUsername': this.userModel[0].taxPortalUsername,
          'isSendEmail': this.userModel[0].isSendEmail,
          'title': this.userModel[0].title,
          'dropdownDepartmentList': ''
        });
        this.selectedItemsCompany = [];
        this.selectedItemsCompany.push({ id: this.userModel[0].companyId, itemName: this.userModel[0].companyName });
        this.userModel.wirelessStates = data['userMaster'][0].wirelessStates;
        this.userModel.voIPStates = data['userMaster'][0].voIPStates;
        this.userModel.iprStates = data['userMaster'][0].iprStates;
        this.userModel.actionStates = data['userMaster'][0].actionStates;
        this.userModel.briefingStates = data['userMaster'][0].briefingStates;
        this.userModel.carrierCategory = data['userMaster'][0].carrierCategory;
        this.userModel.training = data['userMaster'][0].training;
        this.userModel.state911 = data['userMaster'][0].state911;
        this.userModel.exportCitiation = this.exportCitiationList;/////exportCitiation
        this.userModel.TRFAexportCitiation = this.TRFAexportCitiationList;
        this.userModel.CRRAexportCitiation = this.CRRAexportCitiationList;
        this.userModel.specialReport = data['userMaster'][0].specialReport;
        this.userModel.taxExemptionForm = data['userMaster'][0].taxExemptionForm;

        this.userModel.webinars = data['userMaster'][0].webinars;
        this.userModel.inteserraResources = data['userMaster'][0].inteserraResources;
        this.userModel.whitePapers = data['userMaster'][0].whitePapers;
        this.userModel.stateTRFA = data['userMaster'][0].stateTRFA;
        this.userModel.stateCRRA = data['userMaster'][0].stateCRRA;

        this.selectedItemsProduct = [];
        for (let iCounter = 0; iCounter < data['userMaster'][0].listOfProducts.length; iCounter++) {
          this.selectedItemsProduct.push({ 'id': data['userMaster'][0].listOfProducts[iCounter].productId, 'itemName': data['userMaster'][0].listOfProducts[iCounter].displayName });
          this.listOfProductID.push(data['userMaster'][0].listOfProducts[iCounter].productId);

          if (data['userMaster'][0].listOfProducts[iCounter].displayName == "Tax Portal")
            this.selectedProductItemName = true
        }
        this.addProductInList();

        this.selectedDepartmentItems = [];
        if (data['userMaster'][0].dropdownDepartmentList != null) {
          for (let iCounter = 0; iCounter < data['userMaster'][0].dropdownDepartmentList.length; iCounter++) {
            this.selectedDepartmentItems.push({ 'id': data['userMaster'][0].dropdownDepartmentList[iCounter].departmentId, 'itemName': data['userMaster'][0].dropdownDepartmentList[iCounter].departmentName });
            this.listOfDepartmentID.push(data['userMaster'][0].dropdownDepartmentList[iCounter].departmentId);
          }
        }

        this.unblockDocument();
        const selectedWirelessStates = this.userModel.wirelessStates.filter((item) => item.isSelected);
        if (selectedWirelessStates.length == this.userModel.wirelessStates.length) {
          this.selectedAllWireless = true;
        }

        const selectediprStates = this.userModel.iprStates.filter((item) => item.isSelected);
        if (selectediprStates.length == this.userModel.iprStates.length) {
          this.selectedAlliPR = true;
        }

        const selectedVoIPStates = this.userModel.voIPStates.filter((item) => item.isSelected);
        if (selectedVoIPStates.length == this.userModel.voIPStates.length) {
          this.selectedAllVoIP = true;
        }

        const selectedActionStates = this.userModel.actionStates.filter((item) => item.isSelected);
        if (selectedActionStates.length == this.userModel.actionStates.length) {
          this.selectedAllAction = true;
        }

        const selectedAllBriefingStates = this.userModel.briefingStates.filter((item) => item.isSelected);
        if (selectedAllBriefingStates.length == this.userModel.briefingStates.length) {
          this.selectedAllBriefing = true;
        }

        const selectedAllBriefingCarriers = this.userModel.carrierCategory.filter((item) => item.isSelected);
        if (selectedAllBriefingCarriers.length == this.userModel.carrierCategory.length) {
          this.selectedAllBriefingCarriers = true;
        }
        const selectedAllExportCitiation = this.userModel.exportCitiation.filter((item) => item.isSelected);
        if (selectedAllExportCitiation.length == this.userModel.exportCitiation.length) {
          this.selectedAllExportCitiation = true;
        }

        const selectedAllTraining = this.userModel.training.filter((item) => item.isSelected);
        if (selectedAllTraining.length == this.userModel.training.length) {
          this.selectedAllTraining = true;
        }


        const selectedAll911State = this.userModel.state911.filter((item) => item.isSelected);
        if (selectedAll911State.length == this.userModel.state911.length) {
          this.selectedAll911State = true;
        }
        const selectedAllTRFAState = this.userModel.stateTRFA.filter((item) => item.isSelected);
        if (selectedAllTRFAState.length == this.userModel.stateTRFA.length) {
          this.selectedAllTRFAState = true;
        }

        const selectedAllCRRAState = this.userModel.stateCRRA.filter((item) => item.isSelected);
        if (selectedAllCRRAState.length == this.userModel.stateCRRA.length) {
          this.selectedAllCRRAState = true;
        }

        const selectedAllspecialReport = this.userModel.specialReport.filter((item) => item.isSelected);
        if (selectedAllspecialReport.length == this.userModel.specialReport.length) {
          this.selectedAllspecialReport = true;
        }

        const selectedAllTaxExemptionForm = this.userModel.taxExemptionForm.filter((item) => item.isSelected);
        if (selectedAllTaxExemptionForm.length == this.userModel.taxExemptionForm.length) {
          this.selectedAllTaxExemptionForm = true;
        }

        const selectedAllWebinar = this.userModel.webinars.filter((item) => item.isSelected);
        if (selectedAllWebinar.length == this.userModel.webinars.length) {
          this.selectedAllWebinar = true;
        }

        const selectedAllInteserraResources = this.userModel.inteserraResources.filter((item) => item.isSelected);
        if (selectedAllInteserraResources.length == this.userModel.inteserraResources.length) {
          this.selectedAllInteserraResources = true;
        }

        const selectedAllWhitepapers = this.userModel.whitePapers.filter((item) => item.isSelected);
        if (selectedAllWhitepapers.length == this.userModel.whitePapers.length) {
          this.selectedAllWhitepapers = true;
        }

        this.calculateCheckedCount();
        this.GetProductData(this.userModel[0].companyId);

        this.isloadingstates = false;

        this.isWirelessPrimaryContact = this.userModel[0].isWirelessPrimaryContact;
        this.isIPRPrimaryContact = this.userModel[0].isIPRPrimaryContact;
        this.isVoIPPrimaryContact = this.userModel[0].isVoIPPrimaryContact;
        this.isTractItPrimaryContact = this.userModel[0].isTractItPrimaryContact;
        this.isBriefingPrimaryContact = this.userModel[0].isBriefingPrimaryContact;
        this.isTrainingPrimaryContact = this.userModel[0].isTrainingPrimaryContact;
        this.is911PrimaryContact = this.userModel[0].is911PrimaryContact;
        this.isSpecialReportPrimaryContact = this.userModel[0].isSpecialReportPrimaryContact;
        this.isTaxExemptionFormPrimaryContact = this.userModel[0].isTaxExemptionFormPrimaryContact;

        this.isWebinarPrimaryContact = this.userModel[0].isWebinarPrimaryContact;
        this.isInteserraResourcesPrimaryContact = this.userModel[0].isInteserraResourcesPrimaryContact;
        this.isWhitepapersPrimaryContact = this.userModel[0].isWhitepapersPrimaryContact;
        this.isTRFAPrimaryContact = this.userModel[0].isTRFAPrimaryContact;
        this.isCRRAPrimaryContact = this.userModel[0].isCRRAPrimaryContact;
        // this.isClientPrimaryContact = this.userModel[0].isClientPrimaryContact;
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
        this.isloadingstates = false;
      }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  addProductInList() {

    this.selectedProducts = this.selectedItemsProduct.map(x => x.id);
    let isActionIdSelected = this.selectedProducts.filter((item) => item === ToolEnum.actionitem);
    if (isActionIdSelected.length > 0) {
      this.isShowICalCheckBox = true;
    }
    else {
      this.isShowICalCheckBox = false;
    }


  }

  onSelectedProduct($event) {
    if (this.selectedProducts != null) {
      if (this.selectedProducts.length > 0) {
        this.selectedProducts = this.removeElemetninArray(this.selectedProducts, $event.id)

        this.selectedProducts.push($event.id);
      }
      else {
        this.selectedProducts.push($event.id);
      }
    }
    if ($event.itemName == "Tax Portal") {
      this.selectedProductItemName = true
      this.isTaxportal = true;
    }
    if ($event.itemName == "REMS") {
      this.isREMS = true;
    }
    this.onSelectREMSTaxPortal();
    this.addProductInList();
    this.assignCompanyState();
  }

  onSelectAllProduct($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.selectedProducts != null) {
        if (this.selectedProducts.length > 0) {
          this.selectedProducts = this.removeElemetninArray(this.selectedProducts, $event[iCounter].id)
          this.selectedProducts.push($event[iCounter].id);
          if ($event.itemName == "Tax Portal") {
            this.selectedProductItemName = true
          }
        }
        else {
          this.selectedProducts.push($event[iCounter].id);
        }
      }
    }
    this.addProductInList();
    this.assignCompanyState();
  }

  onDeSelectAllProduct($event) {
    this.selectedProducts = [];
    this.selectedProductItemName = false;
    //this.addProductInList();    
  }

  onDeSelectedProduct($event) {
    if (this.selectedProducts != null) {
      if (this.selectedProducts.length > 0) {
        this.selectedProducts = this.removeElemetninArray(this.selectedProducts, $event.id)
      }
      if ($event.itemName == "Tax Portal") {
        this.selectedProductItemName = false;
        this.isTaxportal = false;
      }
      if ($event.itemName == "REMS") {
        this.isREMS = false;
      }
    }
    this.addProductInList();
  }


  productOnClose() {
    if (this.statesTabs.tabs.length > 0) { this.statesTabs.tabs[0].active = true; }
  }

  Getstates(): void {
    this.isloadingstates = true;
    this.UserService.GetStateFindByUserID(this.tokenKey, this._userID).subscribe(
      data => {

        this.userModel.wirelessStates = data.wirelessStates;
        this.userModel.iprStates = data.iprStates;
        this.userModel.voIPStates = data.voIPStates;
        this.userModel.actionStates = data.actionStates;
        this.userModel.briefingStates = data.briefingStates;
        this.userModel.carrierCategory = data.carrierCategory;
        this.userModel.training = data.training;
        this.userModel.state911 = data.state911;
        this.userModel.specialReport = data.specialReport;
        this.userModel.taxExemptionForm = data.taxExemptionForm;

        this.userModel.webinars = data.webinars;
        this.userModel.inteserraResources = data.inteserraResources;
        this.userModel.whitePapers = data.whitePapers;
        this.userModel.stateTRFA = data.trfa;
        this.userModel.stateCRRA = data.crra;

        this.unblockDocument();
        this.isloadingstates = false;
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
        this.isloadingstates = false;
      }
    );
  }

  saveuser(userForm: any) {
    event.preventDefault();
    this._userID = this.isDuplicate ? 0 : this._userID;
    if ((userForm.value['username'] == null || userForm.value['username'] == undefined ||
      userForm.value['username'].trim() == '')) {
      this.showMessage('Please enter username.', 'Required', 'error');
      return;
    }

    if ((userForm.value['password'] == null || userForm.value['password'] == undefined ||
      userForm.value['password'].trim() == '')) {
      this.showMessage('Please enter password.', 'Required', 'error');
      return;
    }

    if (this.selectedItemsCompany == null || this.selectedItemsCompany.length == 0) {
      this.showMessage('Please select company.', 'Required', 'error');
      return;
    }
    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.wireless) && this.selectedWirelessCount == 0) {
      this.showMessage('Please select atleast one Jurisdiction of Wireless tool.', 'Required', 'error');
      return;
    }
    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.ipr) && this.selectedIPRCount == 0) {
      this.showMessage('Please select atleast one Jurisdiction of IPR tool.', 'Required', 'error');
      return;
    }
    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.voip) && this.selectedVoIPCount == 0) {
      this.showMessage('Please select atleast one Jurisdiction of VoIP tool.', 'Required', 'error');
      return;
    }
    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.actionitem) && this.selectedActionCount == 0) {
      this.showMessage('Please select atleast one Jurisdiction of Trac-It tool.', 'Required', 'error');
      return;
    }

    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.briefingServices) && this.selectedBriefingCount == 0) {
      this.showMessage('Please select atleast one Jurisdiction of Briefing Services tool.', 'Required', 'error');
      return;
    }

    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.briefingServices) && this.selectedBriefingCarriersCount == 0) {
      this.showMessage('Please select atleast one briefing type of Briefing Services tool.', 'Required', 'error');
      return;
    }

    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.training) && this.selectedTrainingCount == 0) {
      this.showMessage('Please select atleast one item of Training tool.', 'Required', 'error');
      return;
    }
    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.state911) && this.selectedState911Count == 0) {
      this.showMessage('Please select atleast one item of 911 tool.', 'Required', 'error');
      return;
    }
    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.trfa) && this.selectedStateTRFACount == 0) {
      this.showMessage('Please select atleast one item of TRFA tool.', 'Required', 'error');
      return;
    }

    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.crra) && this.selectedStateCRRACount == 0) {
      this.showMessage('Please select atleast one item of CRRA tool.', 'Required', 'error');
      return;
    }

    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.specialReport) && this.selectedSpecialReportCount == 0) {
      this.showMessage('Please select atleast one item of Special Report tool.', 'Required', 'error');
      return;
    }
    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.TaxExemptionForms) && this.selectedTaxExemptionFormCount == 0) {
      this.showMessage('Please select atleast one item of Tax Exemption Forms tool.', 'Required', 'error');
      return;
    }
    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.webinar) && this.selectedWebinarCount == 0) {
      this.showMessage('Please select atleast one item of Webinar tool.', 'Required', 'error');
      return;
    }
    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.inteserraResources) && this.selectedInteserraResourcesCount == 0) {
      this.showMessage('Please select atleast one item of Inteserra Resources tool.', 'Required', 'error');
      return;
    }
    if (this.selectedItemsProduct.find(x => x.id === ToolEnum.whitepapers) && this.selectedWhitepapersCount == 0) {
      this.showMessage('Please select atleast one item of White papers tool.', 'Required', 'error');
      return;
    }

    if (userForm.value['email'].trim() != '') {
      let result = this.isValidMailFormat(userForm.controls.email);
      if (result != null) {
        this.showMessage('Please provide a valid email', 'Invalid Email', 'error');
        return;
      }
    }

    if (this.selectedProducts.length == 0) {
      this.showMessage('Please select atleast one tool(s)', 'Required', 'error');
      return;
    }

    var SelectedProducts = [];
    if (this.selectedItemsProduct.length > 0) {
      for (let iCounter = 0; iCounter < this.selectedItemsProduct.length; iCounter++) {
        SelectedProducts.push({ 'ProductId': this.selectedItemsProduct[iCounter].id });
      }

      userForm.value['ListOfProducts'] = SelectedProducts;
    }
    else {
      this.showMessage('Please select tool(s)', 'Tools', 'error');
      return;
    }

    var selectedDepartmentItems = [];
    if (this.selectedDepartmentItems.length > 0) {
      for (let iCounter = 0; iCounter < this.selectedDepartmentItems.length; iCounter++) {
        selectedDepartmentItems.push({ 'DepartmentId': this.selectedDepartmentItems[iCounter].id });
      }
      userForm.value['dropdownDepartmentList'] = selectedDepartmentItems;
    }

    // if (this.selectedProducts.indexOf(34) > -1) {
    //   if ((userForm.value['taxPortalPassword'] == null || userForm.value['taxPortalPassword'] == undefined ||
    //     userForm.value['taxPortalPassword'].trim() == '')) {
    //     this.showMessage('Please enter tax portal password.', 'Required', 'error');
    //     return;
    //   }
    // }

    this.blockDocument();
    userForm.value['userid'] = this._userID;

    for (let iCounter = 0; iCounter < this.userModel.wirelessStates.length; iCounter++) {
      this.userModel.wirelessStates[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.wirelessStates[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.wirelessStates[iCounter].productId = ToolEnum.wireless;
    }
    for (let iCounter = 0; iCounter < this.userModel.iprStates.length; iCounter++) {
      this.userModel.iprStates[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.iprStates[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.iprStates[iCounter].productId = ToolEnum.ipr;
    }
    for (let iCounter = 0; iCounter < this.userModel.voIPStates.length; iCounter++) {
      this.userModel.voIPStates[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.voIPStates[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.voIPStates[iCounter].productId = ToolEnum.voip;
    }
    for (let iCounter = 0; iCounter < this.userModel.actionStates.length; iCounter++) {
      this.userModel.actionStates[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.actionStates[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.actionStates[iCounter].productId = ToolEnum.actionitem;
    }

    for (let iCounter = 0; iCounter < this.userModel.briefingStates.length; iCounter++) {
      this.userModel.briefingStates[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.briefingStates[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.briefingStates[iCounter].productId = ToolEnum.briefingServices;
    }

    for (let iCounter = 0; iCounter < this.userModel.carrierCategory.length; iCounter++) {
      this.userModel.carrierCategory[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.carrierCategory[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.carrierCategory[iCounter].productId = ToolEnum.briefingServices;
    }

    for (let iCounter = 0; iCounter < this.userModel.training.length; iCounter++) {
      this.userModel.training[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.training[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.training[iCounter].productId = ToolEnum.training;
    }
    for (let iCounter = 0; iCounter < this.userModel.state911.length; iCounter++) {
      this.userModel.state911[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.state911[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.state911[iCounter].productId = ToolEnum.state911;
    }
    for (let iCounter = 0; iCounter < this.userModel.stateTRFA.length; iCounter++) {
      this.userModel.stateTRFA[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.stateTRFA[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.stateTRFA[iCounter].productId = ToolEnum.trfa;
    }
    for (let iCounter = 0; iCounter < this.userModel.stateCRRA.length; iCounter++) {
      this.userModel.stateCRRA[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.stateCRRA[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.stateCRRA[iCounter].productId = ToolEnum.crra;
    }
    for (let iCounter = 0; iCounter < this.userModel.exportCitiation.length; iCounter++) {
      this.userModel.exportCitiation[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.exportCitiation[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.exportCitiation[iCounter].productId = ToolEnum.state911;
    }
    for (let iCounter = 0; iCounter < this.userModel.TRFAexportCitiation.length; iCounter++) {
      this.userModel.TRFAexportCitiation[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.TRFAexportCitiation[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.TRFAexportCitiation[iCounter].productId = ToolEnum.trfa;
    }
    for (let iCounter = 0; iCounter < this.userModel.CRRAexportCitiation.length; iCounter++) {
      this.userModel.CRRAexportCitiation[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.CRRAexportCitiation[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.CRRAexportCitiation[iCounter].productId = ToolEnum.crra;
    }

    for (let iCounter = 0; iCounter < this.userModel.specialReport.length; iCounter++) {
      this.userModel.specialReport[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.specialReport[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.specialReport[iCounter].productId = ToolEnum.specialReport;
    }

    for (let iCounter = 0; iCounter < this.userModel.taxExemptionForm.length; iCounter++) {
      this.userModel.taxExemptionForm[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.taxExemptionForm[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.taxExemptionForm[iCounter].productId = ToolEnum.TaxExemptionForms;
    }

    for (let iCounter = 0; iCounter < this.userModel.webinars.length; iCounter++) {
      this.userModel.webinars[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.webinars[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.webinars[iCounter].productId = ToolEnum.webinar;
    }

    for (let iCounter = 0; iCounter < this.userModel.inteserraResources.length; iCounter++) {
      this.userModel.inteserraResources[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.inteserraResources[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.inteserraResources[iCounter].productId = ToolEnum.inteserraResources;
    }

    for (let iCounter = 0; iCounter < this.userModel.whitePapers.length; iCounter++) {
      this.userModel.whitePapers[iCounter].createdBy = this.loginuserID.toString();
      this.userModel.whitePapers[iCounter].modifiedBy = this.loginuserID.toString();
      this.userModel.whitePapers[iCounter].productId = ToolEnum.whitepapers;
    }

    userForm.value['wirelessStates'] = this.userModel.wirelessStates;
    userForm.value['voIPStates'] = this.userModel.voIPStates;
    userForm.value['iprStates'] = this.userModel.iprStates;
    userForm.value['actionStates'] = this.userModel.actionStates;
    userForm.value['briefingStates'] = this.userModel.briefingStates;
    userForm.value['carrierCategory'] = this.userModel.carrierCategory;
    userForm.value['exportCitiation'] = this.userModel.exportCitiation;
    userForm.value['training'] = this.userModel.training;
    userForm.value['specialReport'] = this.userModel.specialReport;
    userForm.value['taxExemptionForm'] = this.userModel.taxExemptionForm;

    userForm.value['webinars'] = this.userModel.webinars;
    userForm.value['inteserraResources'] = this.userModel.inteserraResources;
    userForm.value['whitePapers'] = this.userModel.whitePapers;
    userForm.value['state911'] = this.userModel.state911;
    userForm.value['userTypeId'] = 3;
    userForm.value['createdBy'] = this.loginuserID;
    userForm.value['modifiedBy'] = this.loginuserID;

    userForm.value['isWirelessPrimaryContact'] = this.isWirelessPrimaryContact;
    userForm.value['isIPRPrimaryContact'] = this.isIPRPrimaryContact;
    userForm.value['isVoIPPrimaryContact'] = this.isVoIPPrimaryContact;
    userForm.value['isTractItPrimaryContact'] = this.isTractItPrimaryContact;
    userForm.value['isBriefingPrimaryContact'] = this.isBriefingPrimaryContact;
    userForm.value['isTrainingPrimaryContact'] = this.isTrainingPrimaryContact;
    userForm.value['is911PrimaryContact'] = this.is911PrimaryContact;
    userForm.value['isSpecialReportPrimaryContact'] = this.isSpecialReportPrimaryContact;
    userForm.value['isTaxExemptionFormPrimaryContact'] = this.isTaxExemptionFormPrimaryContact;

    userForm.value['isWebinarPrimaryContact'] = this.isWebinarPrimaryContact;
    userForm.value['isInteserraResourcesPrimaryContact'] = this.isInteserraResourcesPrimaryContact;
    userForm.value['isWhitepapersPrimaryContact'] = this.isWhitepapersPrimaryContact;
    userForm.value['stateTRFA'] = this.userModel.stateTRFA;
    userForm.value['isTRFAPrimaryContact'] = this.isTRFAPrimaryContact;
    userForm.value['TRFAexportCitiation'] = this.userModel.TRFAexportCitiation;
    userForm.value['stateCRRA'] = this.userModel.stateCRRA;
    userForm.value['isCRRAPrimaryContact'] = this.isCRRAPrimaryContact;
    userForm.value['CRRAexportCitiation'] = this.userModel.CRRAexportCitiation;
    userForm.value['TaxPortalUserDetails'] = this.taxPortalCredentialList;
    // userForm.value['isClientPrimaryContact'] = this.isClientPrimaryContact;

    let msg = '';
    if (this._userID == 0) {
      msg = 'added';
    }
    else {
      msg = 'updated';
    }

    this.userForm.value['companyId'] = this.selectedItemsCompany[0].id;
    if (this.isMultipleClick) {
      return;
    }
    this.isMultipleClick = true;

    this.UserService.addupdateuser(this.userForm.value, this.tokenKey).subscribe(data => {
      this.isMultipleClick = false;
      if (data.status == 'Success') {
        this.showMessage('Subscriber ' + msg + ' successfully', 'Subscriber ' + msg, 'success');
        this.unblockDocument();
        let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
        this.router.navigate(['/admin/' + toolName + '/subscribers']);
      }
      else {
        if (data.message == 'duplicate') {
          this.showMessage('Duplicate client not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else if (data.message == 'duplicate email') {
          this.showMessage('Duplicate email id not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else {
          this.showMessage(data.message, 'Error', 'error');
          this.unblockDocument();
        }
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
      this.isMultipleClick = false;
    });

  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  isValidMailFormat(control: FormControl) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value != '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { 'Please provide a valid email': true };
    }

    return null;
  }
  canceluser() {
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['/admin/' + toolName + '/subscribers']);
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }


  // Product List---------------
  GetProductData(companyId): void {
    this.companyService.GetCompanyProduct(this.tokenKey, companyId).subscribe(
      data => {
        if (data.companyProducts != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data.companyProducts.length; iCounter++) {
            this.dropdownListProduct.push(
              { 'id': data.companyProducts[iCounter].productId, 'itemName': data.companyProducts[iCounter].displayName }
            );
          }
        }
        else {
          this.showMessage(data['message'], 'Error', 'error');
          this.unblockDocument();
        }

      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }
  // Product List---------------

  // Company List---------------

  getAllCompany() {
    this.UserService.GetCompanyListWithIds(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        for (let iCounter = 0; iCounter < data['companyModel'].length; iCounter++) {
          this.dropdownListCompany.push(
            { 'id': data['companyModel'][iCounter].companyId, 'itemName': data['companyModel'][iCounter].companyName }
          );
        }
      }
    });
  }

  onCompanySelect(company) {
    this.dropdownListProduct = [];
    this.selectedItemsProduct = [];
    this.selectedProducts = [];
    if (this.selectedItemsCompany.length > 0 && company.length != 0) {
      this.getSubscriptionState(this.selectedItemsCompany[0].id);
    }
  }

  getSubscriptionState(companyId: any): any {
    this.companyService.getCompanyFindByCompanyId(this.tokenKey, companyId, this.loginuserID).subscribe(
      data => {
        this.assignCompanyStateToUserState(data);
      },
      error => { this.statusCode = error }
    );
  }

  assignCompanyStateToUserState(data) {
    this.userModel.wirelessStates = data.companyModel.wirelessStates.filter((item) => item.isSelected);
    this.userModel.iprStates = data.companyModel.iprStates.filter((item) => item.isSelected);
    this.userModel.voIPStates = data.companyModel.voIPStates.filter((item) => item.isSelected);
    this.userModel.actionStates = data.companyModel.actionStates.filter((item) => item.isSelected);
    this.userModel.briefingStates = data.companyModel.briefingStates.filter((item) => item.isSelected);
    this.userModel.carrierCategory = data.companyModel.carrierCategory.filter((item) => item.isSelected);
    // this.userModel.exportCitiation = data.companyModel.exportCitiation.filter((item) => item.isSelected);
    this.userModel.training = data.companyModel.training.filter((item) => item.isSelected);
    this.userModel.state911 = data.companyModel.state911.filter((item) => item.isSelected);
    this.userModel.specialReport = data.companyModel.specialReport.filter((item) => item.isSelected);
    this.userModel.taxExemptionForm = data.companyModel.taxExemptionForm.filter((item) => item.isSelected);

    this.userModel.webinars = data.companyModel.webinars.filter((item) => item.isSelected);
    this.userModel.inteserraResources = data.companyModel.inteserraResources.filter((item) => item.isSelected);
    this.userModel.whitePapers = data.companyModel.whitePapers.filter((item) => item.isSelected);
    this.userModel.stateTRFA = data.companyModel.trfa.filter((item) => item.isSelected);
    this.userModel.stateCRRA = data.companyModel.crra.filter((item) => item.isSelected);

    for (let iCounter = 0; iCounter < data.companyModel.listOfProducts.length; iCounter++) {
      this.dropdownListProduct.push(
        { 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName }
      );
      if (this._userID == 0) {

        if (data.companyModel.listOfProducts[iCounter].productId == 5) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
        }
        else if (data.companyModel.listOfProducts[iCounter].productId == 23) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push(data.companyModel.listOfProducts[iCounter].productId);
          this.selectedAllTraining = true;
        }
        else if (data.companyModel.listOfProducts[iCounter].productId == 27) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push(data.companyModel.listOfProducts[iCounter].productId);
          this.selectedAllspecialReport = true;
        }
        else if (data.companyModel.listOfProducts[iCounter].productId == 40) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push(data.companyModel.listOfProducts[iCounter].productId);
          this.selectedAllTaxExemptionForm = true;
        }
        else if (data.companyModel.listOfProducts[iCounter].productId == 28) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push(data.companyModel.listOfProducts[iCounter].productId);
          this.selectedAllWebinar = true;
        }
        else if (data.companyModel.listOfProducts[iCounter].productId == 35) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push(data.companyModel.listOfProducts[iCounter].productId);
          this.selectedAllInteserraResources = true;
        }
        else if (data.companyModel.listOfProducts[iCounter].productId == 29) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push(data.companyModel.listOfProducts[iCounter].productId);
          this.selectedAllWhitepapers = true;
        }
        else if (data.companyModel.listOfProducts[iCounter].productId == 37) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push(data.companyModel.listOfProducts[iCounter].productId);
          // this.isClientPrimaryContact = true;
        }
        else if (data.companyModel.listOfProducts[iCounter].productId == 38) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push(data.companyModel.listOfProducts[iCounter].productId);
          // this.selectedAllWhitepapers = true;
        }
        else if (data.companyModel.listOfProducts[iCounter].productId == 39) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push(data.companyModel.listOfProducts[iCounter].productId);
          // this.selectedAllWhitepapers = true;
        }
        else if (data.companyModel.listOfProducts[iCounter].productId == 2) {
          this.selectedItemsProduct.push({ 'id': data.companyModel.listOfProducts[iCounter].productId, 'itemName': data.companyModel.listOfProducts[iCounter].displayName });
          this.selectedProducts.push(data.companyModel.listOfProducts[iCounter].productId);
          this.selectedAlliPR = true;
        }

      }
    }
    const selectedWirelessStates = this.userModel.wirelessStates.filter((item) => item.isSelected);
    if (selectedWirelessStates.length == this.userModel.wirelessStates.length) {
      this.selectedAllWireless = true;
    }
    const selectediprStates = this.userModel.iprStates.filter((item) => item.isSelected);
    if (selectediprStates.length == this.userModel.iprStates.length) {
      this.selectedAlliPR = true;
    }

    const selectedVoIPStates = this.userModel.voIPStates.filter((item) => item.isSelected);
    if (selectedVoIPStates.length == this.userModel.voIPStates.length) {
      this.selectedAllVoIP = true;
    }

    const selectedActionStates = this.userModel.actionStates.filter((item) => item.isSelected);
    if (selectedActionStates.length == this.userModel.actionStates.length) {
      this.selectedAllAction = true;
    }

    const selectedAllBriefingStates = this.userModel.briefingStates.filter((item) => item.isSelected);
    if (selectedAllBriefingStates.length == this.userModel.briefingStates.length) {
      this.selectedAllBriefing = true;
    }
    const selectedAllBriefingCarriers = this.userModel.carrierCategory.filter((item) => item.isSelected);
    if (selectedAllBriefingCarriers.length == this.userModel.carrierCategory.length) {
      this.selectedAllBriefingCarriers = true;
    }
    const selectedAllExportCitiation = this.userModel.exportCitiation.filter((item) => item.isSelected);
    if (selectedAllExportCitiation.length == this.userModel.exportCitiation.length) {
      this.selectedAllExportCitiation = true;
    }

    const selectedAllTraining = this.userModel.training.filter((item) => item.isSelected);
    if (selectedAllTraining.length == this.userModel.training.length) {
      this.selectedAllTraining = true;
    }
    const selectedAll911 = this.userModel.state911.filter((item) => item.isSelected);
    if (selectedAll911.length == this.userModel.state911.length) {
      this.selectedAll911State = true;
    }
    const selectedAllTRFA = this.userModel.stateTRFA.filter((item) => item.isSelected);
    if (selectedAllTRFA.length == this.userModel.stateTRFA.length) {
      this.selectedAllTRFAState = true;
    }
    const selectedAllCRRA = this.userModel.stateCRRA.filter((item) => item.isSelected);
    if (selectedAllCRRA.length == this.userModel.stateCRRA.length) {
      this.selectedAllCRRAState = true;
    }
    const selectedAllspecialReport = this.userModel.specialReport.filter((item) => item.isSelected);
    if (selectedAllspecialReport.length == this.userModel.specialReport.length) {
      this.selectedAllspecialReport = true;
    }
    const selectedAllTaxExemptionForm = this.userModel.taxExemptionForm.filter((item) => item.isSelected);
    if (selectedAllTaxExemptionForm.length == this.userModel.taxExemptionForm.length) {
      this.selectedAllTaxExemptionForm = true;
    }
    const selectedAllWebinar = this.userModel.webinars.filter((item) => item.isSelected);
    if (selectedAllWebinar.length == this.userModel.webinars.length) {
      this.selectedAllWebinar = true;
    }
    const selectedAllInteserraResources = this.userModel.inteserraResources.filter((item) => item.isSelected);
    if (selectedAllInteserraResources.length == this.userModel.inteserraResources.length) {
      this.selectedAllInteserraResources = true;
    }
    const selectedAllWhitepapers = this.userModel.whitePapers.filter((item) => item.isSelected);
    if (selectedAllWhitepapers.length == this.userModel.whitePapers.length) {
      this.selectedAllWhitepapers = true;
    }

    this.calculateCheckedCount();
  }
  // Company List 

  assignCompanyState() {
    this.companyService.getCompanyFindByCompanyId(this.tokenKey, this.selectedItemsCompany[0].id, this.loginuserID).subscribe(
      data => {
        if (this.selectedProducts.indexOf(1) > -1 && this.selectedWirelessCount == 0) {
          this.userModel.wirelessStates = data.companyModel.wirelessStates.filter((item) => item.isSelected);
        }
        else if (this.selectedProducts.indexOf(2) > -1 && this.selectedIPRCount == 0) {
          this.userModel.iprStates = data.companyModel.iprStates.filter((item) => item.isSelected);
        }
        else if (this.selectedProducts.indexOf(3) > -1 && this.selectedVoIPCount == 0) {
          this.userModel.voIPStates = data.companyModel.voIPStates.filter((item) => item.isSelected);
        }
        else if (this.selectedProducts.indexOf(4) > -1 && this.selectedActionCount == 0) {
          this.userModel.actionStates = data.companyModel.actionStates.filter((item) => item.isSelected);
        }
        else if (this.selectedProducts.indexOf(7) > -1) {
          if (this.selectedBriefingCount == 0)
            this.userModel.briefingStates = data.companyModel.briefingStates.filter((item) => item.isSelected);
          if (this.selectedBriefingCarriersCount == 0)
            this.userModel.carrierCategory = data.companyModel.carrierCategory.filter((item) => item.isSelected);
        }
        // else if (this.selectedProducts.indexOf(23) > -1) {
        //   this.userModel.training = data.companyModel.training.filter((item) => item.isSelected);
        // }
        // else if (this.selectedProducts.indexOf(27) > -1) {
        //   this.userModel.specialReport = data.companyModel.specialReport.filter((item) => item.isSelected);
        // }
        // else if (this.selectedProducts.indexOf(28) > -1) {
        //   this.userModel.webinars = data.companyModel.webinars.filter((item) => item.isSelected);
        // }
        // else if (this.selectedProducts.indexOf(29) > -1) {
        //   this.userModel.whitePapers = data.companyModel.whitepapers.filter((item) => item.isSelected);
        // }

        if (this.selectedProducts.indexOf(23) > -1) {
          if (this._userID > 0) {
            for (let xindex = 0; xindex < data.companyModel.training.length; xindex++) {
              const xelement = data.companyModel.training[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }
            this.userModel.training = data.companyModel.training.filter((item) => item.isSelected);
          }
          else {
            this.userModel.training = data.companyModel.training.filter((item) => item.isSelected);
          }
        }

        if (this.selectedProducts.indexOf(27) > -1) {
          if (this._userID > 0) {
            for (let xindex = 0; xindex < data.companyModel.specialReport.length; xindex++) {
              const xelement = data.companyModel.specialReport[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }
            this.userModel.specialReport = data.companyModel.specialReport.filter((item) => item.isSelected);
          }
          else {
            this.userModel.specialReport = data.companyModel.specialReport.filter((item) => item.isSelected);
          }
        }

        if (this.selectedProducts.indexOf(40) > -1) {
          if (this._userID > 0) {
            for (let xindex = 0; xindex < data.companyModel.taxExemptionForm.length; xindex++) {
              const xelement = data.companyModel.taxExemptionForm[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }
            this.userModel.taxExemptionForm = data.companyModel.taxExemptionForm.filter((item) => item.isSelected);
          }
          else {
            this.userModel.taxExemptionForm = data.companyModel.taxExemptionForm.filter((item) => item.isSelected);
          }
        }

        if (this.selectedProducts.indexOf(28) > -1) {
          if (this._userID > 0) {
            for (let xindex = 0; xindex < data.companyModel.webinars.length; xindex++) {
              const xelement = data.companyModel.webinars[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }
            this.userModel.webinars = data.companyModel.webinars.filter((item) => item.isSelected);
          }
          else {
            this.userModel.webinars = data.companyModel.webinars.filter((item) => item.isSelected);
          }
        }

        if (this.selectedProducts.indexOf(35) > -1) {
          if (this._userID > 0) {
            for (let xindex = 0; xindex < data.companyModel.inteserraResources.length; xindex++) {
              const xelement = data.companyModel.inteserraResources[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }
            this.userModel.inteserraResources = data.companyModel.inteserraResources.filter((item) => item.isSelected);
          }
          else {
            this.userModel.inteserraResources = data.companyModel.inteserraResources.filter((item) => item.isSelected);
          }
        }

        if (this.selectedProducts.indexOf(29) > -1) {
          if (this._userID > 0) {
            for (let xindex = 0; xindex < data.companyModel.whitePapers.length; xindex++) {
              const xelement = data.companyModel.whitePapers[xindex];
              if (xelement.isAllowallAccess) {
                xelement.isSelected = true;
              }
            }
            this.userModel.whitePapers = data.companyModel.whitePapers.filter((item) => item.isSelected);
          }
          else {
            this.userModel.whitePapers = data.companyModel.whitePapers.filter((item) => item.isSelected);
          }
        }

        this.calculateCheckedCount();
        this.selectedAllWireless = this.userModel.wirelessStates.every(function (item: any) { return item.isSelected == true; });
        this.selectedAlliPR = this.userModel.iprStates.every(function (item: any) { return item.isSelected == true; });
        this.selectedAllVoIP = this.userModel.voIPStates.every(function (item: any) { return item.isSelected == true; });
        this.selectedAllAction = this.userModel.actionStates.every(function (item: any) { return item.isSelected == true; });
        this.selectedAllBriefing = this.userModel.briefingStates.every(function (item: any) { return item.isSelected == true; });
        this.selectedAllBriefingCarriers = this.userModel.carrierCategory.every(function (item: any) { return item.isSelected == true; });
        this.selectedAllExportCitiation = this.userModel.exportCitiation.every(function (item: any) { return item.isSelected == true; });
        this.selectedAllTraining = this.userModel.training.every(function (item: any) { return item.isSelected == true; });
        this.selectedAllspecialReport = this.userModel.specialReport.every(function (item: any) { return item.isSelected == true; });
        this.selectedAllTaxExemptionForm = this.userModel.taxExemptionForm.every(function (item: any) { return item.isSelected == true; });

        this.selectedAllWebinar = this.userModel.webinars.every(function (item: any) { return item.isSelected == true; });
        this.selectedAllInteserraResources = this.userModel.inteserraResources.every(function (item: any) { return item.isSelected == true; });
        this.selectedAllWhitepapers = this.userModel.whitePapers.every(function (item: any) { return item.isSelected == true; });
      },
      error => { this.statusCode = error }
    );

  }
  ExportCitiationSubscriptionGetAll() {
    this.UserService.ExportCitiationSubscriptionGetAll(this.tokenKey).subscribe(
      data => {
        this.exportCitiationList = data['exportCitiation'];
        this.userModel.exportCitiation = this.exportCitiationList;
      },
      error => { }
    );
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }
  TRFAExportCitiationSubscriptionGetAll() {
    this.UserService.TRFAExportCitiationSubscriptionGetAll(this.tokenKey).subscribe(
      data => {
        this.TRFAexportCitiationList = data['trfaexportCitiation'];
        this.userModel.TRFAexportCitiation = this.TRFAexportCitiationList;
      },
      error => { }
    );
  }

  CRRAExportCitiationSubscriptionGetAll() {
    this.UserService.CRRAExportCitiationSubscriptionGetAll(this.tokenKey).subscribe(
      data => {
        this.CRRAexportCitiationList = data['trfaexportCitiation'];
        this.userModel.CRRAexportCitiation = this.CRRAexportCitiationList;
      },
      error => { }
    );
  }

  getAllDepartment() {
    this.UserService.GetAllDepartment(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        for (let iCounter = 0; iCounter < data['department'].length; iCounter++) {
          this.dropdownDepartmentList.push(
            { 'id': data['department'][iCounter].departmentId, 'itemName': data['department'][iCounter].departmentName }
          );
        }
      }
    });
  }

  onSelectREMSTaxPortal() {
    if (this.isREMS == true) {
      var data = this.dropdownDepartmentList.filter(a => a.itemName == 'Regulatory/Compliance')
      if (data != null) {
        if (this.selectedDepartmentItems.filter(b => b.id == data[0].id).length == 0) {
          this.selectedDepartmentItems.push({ 'id': data[0].id, 'itemName': data[0].itemName });
          this.listOfDepartmentID.push(data[0].id);
        }
      }
    }

    if (this.isTaxportal == true) {
      var data = this.dropdownDepartmentList.filter(a => a.itemName == 'Tax')
      if (data != null) {
        if (this.selectedDepartmentItems.filter(b => b.id == data[0].id).length == 0) {
          this.selectedDepartmentItems.push({ 'id': data[0].id, 'itemName': data[0].itemName });
          this.listOfDepartmentID.push(data[0].id);
        }
      }
    }
  }

  onSelectedDepartment($event) {
    this.listOfDepartmentID = this.removeElemetninArray(this.listOfDepartmentID, $event.id)
    this.listOfDepartmentID.push($event.id);
  }
  onDeSelectedDepartment($event) {
    if (this.listOfDepartmentID != null) {
      if (this.listOfDepartmentID.length > 0) {
        this.listOfDepartmentID = this.removeElemetninArray(this.listOfDepartmentID, $event.id)
      }
    }
  }
  onSelectAllDepartment($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfDepartmentID != null) {
        if (this.listOfDepartmentID.length > 0) {
          this.listOfDepartmentID = this.removeElemetninArray(this.listOfDepartmentID, $event[iCounter].id)
          this.listOfDepartmentID.push($event[iCounter].id);
        }
        else {
          this.listOfDepartmentID.push($event[iCounter].id);
        }
      }
    }
  }
  onDeSelectAllDepartment($event) {
    this.listOfDepartmentID = [];
    this.selectedDepartmentItems = [];
  }

  GetUserPortalCredentialDetailsByUserId(userid: number) {
    if (userid == 0) {

    }
    else {
      this.UserService.GetUserPortalCredentialDetailsByUserId(this.tokenKey, userid).subscribe(
        data => {
          this.taxPortalCredentialList = data['userCredentialList'];
        },
        error => { }
      );
    }
  }

  UpdateUserById(UserData) {
    this.taxPortalCredentialList.forEach(element => {
      element['isEdit'] = false;
    });
    // this.templistofContent = JSON.parse(JSON.stringify(this.taxPortalCredentialList));

    UserData.isEdit = true;

    this.TempTaxPortalUserName=UserData.taxPortalUserName;
    this.TempTaxPortalPassword=UserData.taxPortalPassword;
    this.TempTaxPortalNotes=UserData.notes;
    // this.TempTaxPortalId=UserData.taxPortalId;
    // this.TempTempId=UserData.tempId;


    this.selectedItemsGridCompany = [];
    let data = this.dropdownListCompany.filter(x => x.id == UserData.companyId)[0];
    if (data != null) {
      this.selectedItemsGridCompany.push({ id: data.id, itemName: data.itemName });
    }
  }

  cancelEdit(data) {
    data.isEdit = false;
    this.isRowEdited = false;
    data.taxPortalUserName=this.TempTaxPortalUserName;
    data.taxPortalPassword=this.TempTaxPortalPassword;
    data.notes=this.TempTaxPortalNotes;

    this.selectedItemsGridCompany = [];
    let data1 = this.dropdownListCompany.filter(x => x.id == this.TempCompanyId)[0];
    if (data1 != null) {
      this.selectedItemsGridCompany.push({ id: data1.id, itemName: data1.itemName });
      data.companyId = data1.id;
      data.companyName = data1.itemName;
    }
    // data.taxPortalUserName=this.TempTaxPortalUserName;
    // data.taxPortalUserName=this.TempTaxPortalUserName;
    // data.taxPortalUserName=this.TempTaxPortalUserName;
  }

  setDeleteData(taxPortalId, tempId) {
    this.taxPortalId = taxPortalId;
    this.deleteTaxPortalTempId = tempId;
  }

  deletePortalCredential(taxPortalId, deleteTaxPortalTempId) {
    if (taxPortalId == 0) {
      const index:number = this.taxPortalCredentialList.findIndex(a => a.tempId == deleteTaxPortalTempId); 
      if (index !== -1) {
        this.taxPortalCredentialList.splice(index,1);
      }
    }
  else
  {
    const index:number = this.taxPortalCredentialList.findIndex(a => a.taxPortalId == taxPortalId); 
    if (index !== -1) {
      this.taxPortalCredentialList.splice(index,1);
    }
  }
    // this.UserService.DeleteTaxPortalCredential(this.tokenKey, taxPortalId).subscribe(
    //   data => {
    //     if (data['status'] == 'Success') {
    //       this.showMessage("Deleted successfully", 'Success', 'success')
    //     }
    //     this.GetUserPortalCredentialDetailsByUserId(this._userID);
    //     this.unblockDocument();
    //   },
    //   error => { this.unblockDocument(); }
    // );
  }

AddTexPortalDetails() {
  var EditDataAvailable = this.taxPortalCredentialList.filter(x => x.isEdit == true);
  if (EditDataAvailable.length == 0) {
    let data = this.dropdownListCompany.filter(x => x.id == this.selectedItemsCompany[0].id)[0];
    if (data != null) {
      this.taxPortalTempId = this.taxPortalTempId + 1;
      this.selectedItemsGridCompany = [];
      this.selectedItemsGridCompany.push({ id: data.id, itemName: data.itemName });
      this.taxPortalCredentialList.push({ 'taxPortalId': 0, 'companyId': data.id, 'companyName': data.itemName, 'isEdit': true, 'taxPortalPassword': '', 'taxPortalUserName': '', 'userId': this._userID, 'tempId': this.taxPortalTempId,'notes':'' });
    }
  }
}

UpdateTaxPortalData(item) {
  this.taxPortalCredentialList.forEach(element => {
    element['isEdit'] = false;
  });
}

onGridCompanySelect(company, item) {
  this.TempCompanyId=item.companyId;
  item.companyId = company.id;
  item.companyName = company.itemName;
  
  // this.selectedItemsProduct = [];
  // this.selectedProducts = [];
  // if (this.selectedItemsCompany.length > 0 && company.length != 0) {
  //   this.getSubscriptionState(this.selectedItemsCompany[0].id);
  // }
}
}
