import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ObligationCarriersModel } from 'src/Models/ObligationGetLinkModel';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-carrier-type-cfo',
  templateUrl: './carrier-type-cfo.component.html',
  styleUrls: ['./carrier-type-cfo.component.css']
})
export class CarrierTypeCfoComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofCarrier: ObligationCarriersModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  CarrierName: string;


  upDowCarrierNameCSS: string = '';
  upDowSortOrderCSS: string = '';
  upDowActiveCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  carrierTypeId: number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private checkAuth: CheckAuth, private titleService: Title, private toastr: ToastrService,
    private carrierObligationService: CarrierObligationService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofCarrier = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'SortOrder';
    this.CarrierName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Carriers');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.tokenKey = sessionStorage.getItem('tokenValue');
    if(this.SelectedTool == 'view 47'){
      this.SelectedTool = 'view47';
    }
    this.isloading = true;
    this.GetCarrier();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetCarrier();
  }

  GetCarrier() {

    if (this.CarrierName == "null") this.CarrierName = " ";
    this.carrierObligationService.GetAllCarrier(this.tokenKey, this.PageNumber, this.PageSize, this.CarrierName, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofCarrier = data['obligationcarrier'];
        if (this.listofCarrier != null) {
          if (this.listofCarrier.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofCarrier[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );

  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.CarrierName = $event.target.value;
    else
      this.CarrierName = 'null';

    this.GetCarrier();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "CarrierName") {
        this.upDowCarrierNameCSS = 'fa fa-arrow-down';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowCarrierNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowCarrierNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "CarrierName") {
        this.upDowCarrierNameCSS = 'fa fa-arrow-up';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowCarrierNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-up';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowCarrierNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-up';
      }
    }
    this.GetCarrier();
  }

  addCarrier() {
    let navigation = '/admin/' + this.SelectedTool + '/carrier/manage';
    this.router.navigate([navigation]);
  }

  updateCarrier(carrierTypeId) {
    let navigation = '/admin/' + this.SelectedTool + '/carrier/manage/' + carrierTypeId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.carrierTypeId = id;
  }


  deleteCarrier(carrierTypeId) {
    this.isloading = true;
    this.carrierObligationService.DeleteCarrier(this.tokenKey, carrierTypeId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
            this.showMessage("Carrier Deleted successfully", 'Success', 'success')
        }
        this.GetCarrier();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
