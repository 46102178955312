export class UserLibraryModel {
    libraryId?: any;
    userId?: any;
    companyId?: any;
    name?: string;
    fileName?: string;
    fileType?: string;
    level?: any;
    parentId?: any;
    isFolder?: any;
    productId?: any;
    createdBy?: any;
    createdTime?: any;
    modifiedBy?: any;
    modifiedTime?: any;
    createdByName?: any;
    modifiedByName?: any;
    totalRecordCount?: any;
    fileSize?: any;
    folderSize?: any;
    isUserSetReminder?: any;
    libraryReminderId?: any;
    stateListData?:any;

}

export class UserFolderModel{
    libraryId?: any;
    userId?: any;
    companyId?: any;
    name?: string;
    level?: any;
    parentId?: any;
    isFolder?: any;
    productId?: any;
    createdBy?: any;
    createdTime?: any;
    modifiedBy?: any;
    modifiedTime?: any;
    createdByName?: any;
    modifiedByName?: any;
    totalRecordCount?: any;
    stateListData?:any;
}
