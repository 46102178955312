import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { CompanyModel } from 'src/Models/CompanyModel';
import { CompanyService } from 'src/Services/company.service';
import { ProductsService } from 'src/Services/products.service';
import { RouterExtService } from 'src/Services/RouterExtService';
import { StateMasterService } from 'src/Services/state-master.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-assign-user',
  templateUrl: './assign-user.component.html',
  styleUrls: ['./assign-user.component.css']
})
export class AssignUserComponent implements OnInit {
  @ViewChild('statesTabs', { static: false }) statesTabs: TabsetComponent;
  stateSubscription: any;
  isGeneralAlertSubscription: boolean = false;
  tokenKey: string = '';
  devideRowCount = 0;
  statusCode: any;
  companyId = 0;
  SelectedTool: string;
  loginuserID = 0;
  compnayModel: CompanyModel;
  isShowprogressSpinner = false;
  blockedDocument = false;

  dropdownListProduct = [];
  selectedItemsProduct = [];
  dropdownSettingsProduct = {};
  userList = [];
  selectedAll = false;

  isshowdropTraining: boolean = false;
  dropdownListTraining = [];
  selectedItemsTraining = [];
  dropdownSettingsTraining = {};

  isshowdropSpecialReport: boolean = false;
  dropdownListSpecialReport = [];
  selectedItemsSpecialReport = [];
  dropdownSettingsSpecialReport = {};

  isshowdropWebinar: boolean = false;
  dropdownListWebinar = [];
  selectedItemsWebinar = [];
  dropdownSettingsWebinar = {};

  isshowdropWhitepapers: boolean = false;
  dropdownListWhitepapers = [];
  selectedItemsWhitepapers = [];
  dropdownSettingsWhitepapers = {};
  freeproductId:number=0;

  constructor(private router: Router, private checkAuth: CheckAuth, private companyService: CompanyService
    , private titleService: Title, private stateServices: StateMasterService, private activedRoute: ActivatedRoute
    , private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.titleService.setTitle('Assign User');
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.companyId = params['id'];
          this.getSubscriptionState();
          this.getCompany();
        }
      }
    });
  }

  cancelcompany() {
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['/admin/' + toolName + '/companies']);
  }
  assignUser() {
    if (this.selectedItemsProduct.length == 0 || !this.selectedItemsProduct[0].id) {
      this.showMessage('Please select at least one product(s)', 'Required', 'error');
      return;
    }
    if (this.userList.length == 0 || this.userList.filter(x => x.isSelected).length == 0) {
      this.showMessage('Please select at least one user', 'Required', 'error');
      return;
    }
    if (this.userList.filter(x => x.isSelected).length > (this.stateSubscription.productAccess.find(x => x.productId == this.selectedItemsProduct[0].id).licenceCount)) {
      this.showMessage('You can not select user(s) more then licence', 'Required', 'error');
      return;
    }
    const data = { userId: this.loginuserID, 
      companyId: this.companyId, 
      productId: this.selectedItemsProduct[0].id, 
      userIds: this.userList.filter(x => x.isSelected).map(y => y.userId).join(","),
      id:this.freeproductId,
     }

    this.companyService.CompanyAssignUserSave(this.tokenKey, data).subscribe(
      data => {
        if (data.status == 'Success') {
          this.showMessage('User assign successfully', 'Assign user', 'success');
          this.unblockDocument();
          let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
          this.router.navigate(['/admin/' + toolName + '/companies']);
        }
        else {
          if (data.message == 'userlicence') {
            this.showMessage('You can not select user(s) more then licence', 'Required', 'error');
            this.unblockDocument();
          }
          else {
            this.showMessage(data.status, 'Error', 'error');
            this.unblockDocument();
          }
        }
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
      });


  }

  getCompany() {
    this.blockDocument();
    this.companyService.getCompanyFindByCompanyId(this.tokenKey, this.companyId, this.loginuserID, true).subscribe(
      data => {
        this.compnayModel = data.companyModel;
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
      });
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  getSubscriptionState() {
    this.blockDocument();
    this.stateSubscription = null;
    this.stateServices.GetStateMasterFindByCompanyId(this.tokenKey, this.companyId).subscribe(
      data => {
        this.unblockDocument();
        this.stateSubscription = {
          wirelessStates: data.wirelessStates,
          wirelessStatesCount: data.wirelessStates ? data.wirelessStates.length : 0,
          iprStates: data.iprStates,
          iprStatesCount: data.iprStates ? data.iprStates.length : 0,
          voIPStates: data.voIPStates,
          voIPStatesCount: data.voIPStates ? data.voIPStates.length : 0,
          actionStates: data.actionStates,
          actionStatesCount: data.actionStates ? data.actionStates.length : 0,
          productAccess: data.productAccess,
          briefingStates: data.briefingStates,
          briefingStatesCount: data.briefingStates ? data.briefingStates.length : 0,
          carrierCategory: data.carrierCategory,
          carrierCategoryCount: data.carrierCategory ? data.carrierCategory.filter(m => m.isSelected == true).length : 0,
          training: data.training,
          trainingCount: data.training ? data.training.length : 0,
          specialReport: data.specialReport,
          specialReportCount: data.specialReport ? data.specialReport.length : 0,
          webinars: data.webinars,
          webinarsCount: data.webinars ? data.webinars.length : 0,
          whitePapers: data.whitePapers,
          whitePapersCount: data.whitePapers ? data.whitePapers.length : 0,
        };

        if (data.productAccess.length > 0) {
          let sub = data.productAccess.filter(m => m.productId !== ToolEnum.actionitem && m.productId !== ToolEnum.ipr && m.productId !== ToolEnum.voip && m.productId !== ToolEnum.wireless)
          if (sub.length > 0) {
            this.isGeneralAlertSubscription = true;
          }
          else {
            if (this.statesTabs != null && typeof (this.statesTabs) != 'undefined') {
              this.statesTabs.tabs[0].active = true;
            }
            this.isGeneralAlertSubscription = false;
          }
          this.devideRowCount = (data.productAccess.length / 2);
          this.devideRowCount = this.devideRowCount.toString().indexOf(".") != -1 ? this.devideRowCount + 1 : this.devideRowCount;
        }
        this.GetProductData();
      },
      error => { this.statusCode = error }
    );
  }

  GetProductData(): void {
    const products = this.stateSubscription.productAccess;
    for (let iCounter = 0; iCounter < products.length; iCounter++) {
      this.dropdownListProduct.push(
        { 'id': products[iCounter].productId, 'itemName': products[iCounter].displayName }
      );
    }
    this.dropdownSettingsProduct = {
      singleSelection: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No product available',
    };
  }

  onProductSelect(product) {
    this.userList = [];
    this.isshowdropTraining = false;
    this.isshowdropSpecialReport = false;
    this.isshowdropWebinar = false;
    this.isshowdropWhitepapers = false;
    this.freeproductId=0;

    if (product.id == 23 || product.id == 27 || product.id == 28 || product.id == 29) {

      if (product.id == 23)
      {
        this.isshowdropTraining = true;
        this.isshowdropSpecialReport = false;
        this.isshowdropWebinar = false;
        this.isshowdropWhitepapers = false;
        console.log(this.stateSubscription.training);

        for (let iCounter = 0; iCounter < this.stateSubscription.training.length; iCounter++) {
          this.dropdownListTraining.push(
            { 'id': this.stateSubscription.training[iCounter].trainingId, 'itemName': this.stateSubscription.training[iCounter].title }
          );
        }

        this.dropdownSettingsTraining = {
          singleSelection: true,
          classes: 'borderLessTextBoxGreen',
          noDataLabel: 'No Training available',
        };

      }
      else if (product.id == 27) 
      {
        this.isshowdropTraining = false;
        this.isshowdropSpecialReport = true;
        this.isshowdropWebinar = false;
        this.isshowdropWhitepapers = false;

        console.log(this.stateSubscription.specialReport);

        for (let iCounter = 0; iCounter < this.stateSubscription.specialReport.length; iCounter++) {
          this.dropdownListSpecialReport.push(
            { 'id': this.stateSubscription.specialReport[iCounter].specialReportId, 'itemName': this.stateSubscription.specialReport[iCounter].title }
          );
        }

        this.dropdownSettingsSpecialReport = {
          singleSelection: true,
          classes: 'borderLessTextBoxGreen',
          noDataLabel: 'No Special Report available',
        };

      }
      else if (product.id == 28) 
      {
        this.isshowdropTraining = false;
        this.isshowdropSpecialReport = false;
        this.isshowdropWebinar = true;
        this.isshowdropWhitepapers = false;
        console.log(this.stateSubscription.webinars);

        for (let iCounter = 0; iCounter < this.stateSubscription.webinars.length; iCounter++) {
          this.dropdownListWebinar.push(
            { 'id': this.stateSubscription.webinars[iCounter].webinarLibraryId, 'itemName': this.stateSubscription.webinars[iCounter].title }
          );
        }

        this.dropdownSettingsWebinar = {
          singleSelection: true,
          classes: 'borderLessTextBoxGreen',
          noDataLabel: 'No Webinar available',
        };

      }
      else if (product.id == 29) 
      {
        this.isshowdropTraining = false;
        this.isshowdropSpecialReport = false;
        this.isshowdropWebinar = false;
        this.isshowdropWhitepapers = true;
        console.log(this.stateSubscription.whitePapers);

        for (let iCounter = 0; iCounter < this.stateSubscription.whitePapers.length; iCounter++) {
          this.dropdownListWhitepapers.push(
            { 'id': this.stateSubscription.whitePapers[iCounter].whitePaperId, 'itemName': this.stateSubscription.whitePapers[iCounter].title }
          );
        }

        this.dropdownSettingsWhitepapers = {
          singleSelection: true,
          classes: 'borderLessTextBoxGreen',
          noDataLabel: 'No White papers available',
        };
      }

    }
    else {

      if (this.selectedItemsProduct.length > 0 && product.length != 0) {
        this.companyService.GetCompanyAssignedUser(this.tokenKey, this.companyId, this.selectedItemsProduct[0].id).subscribe(data => {
          if (data) {
            this.userList = data.companyUsers;
            this.checkIfAllSelected();
          }
        })
      }
    }

  }

  onTrainingSelect(training) {
    this.userList = [];
    console.log(training);
    if (this.selectedItemsProduct.length > 0) {
      const data={
        companyId:this.companyId,
        productId:this.selectedItemsProduct[0].id,
        freeProductid:training.id,
        type:'training'
      }

      this.freeproductId=training.id;

      this.companyService.GetCompanyAssignedUserFreeProduct(this.tokenKey, data).subscribe(data => {
        if (data) {
          this.userList = data.companyUsers;
          this.checkIfAllSelected();
        }
      })
    }
  }

  onSpecialReportSelect(specialReport) {
    this.userList = [];
    console.log(specialReport);
    if (this.selectedItemsProduct.length > 0) {
      const data={
        companyId:this.companyId,
        productId:this.selectedItemsProduct[0].id,
        freeProductid:specialReport.id,
        type:'specialReport'
      }

      this.freeproductId=specialReport.id;
      this.companyService.GetCompanyAssignedUserFreeProduct(this.tokenKey,data).subscribe(data => {
        if (data) {
          this.userList = data.companyUsers;
          this.checkIfAllSelected();
        }
      })
    }
    
  }

  onWebinarSelect(Webinar) {
    this.userList = [];
    console.log(Webinar);
    if (this.selectedItemsProduct.length > 0) {
      const data={
        companyId:this.companyId,
        productId:this.selectedItemsProduct[0].id,
        freeProductid:Webinar.id,
        type:'Webinar'
      }
      this.freeproductId=Webinar.id;
      this.companyService.GetCompanyAssignedUserFreeProduct(this.tokenKey, data).subscribe(data => {
        if (data) {
          this.userList = data.companyUsers;
          this.checkIfAllSelected();
        }
      })
    }
  }

  onWhitepaperSelect(Whitepaper) {
    this.userList = [];
    console.log(Whitepaper);
    if (this.selectedItemsProduct.length > 0) {
      const data={
        companyId:this.companyId,
        productId:this.selectedItemsProduct[0].id,
        freeProductid:Whitepaper.id,
        type:'Whitepaper'
      }
      this.freeproductId=Whitepaper.id;
      this.companyService.GetCompanyAssignedUserFreeProduct(this.tokenKey,data).subscribe(data => {
        if (data) {
          this.userList = data.companyUsers;
          this.checkIfAllSelected();
        }
      })
    }
  }


  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  selectAllUser() {
    for (let i = 0; i < this.userList.length; i++) {
      this.userList[i].isSelected = this.selectedAll;
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.userList.every(function (item: any) {
      return item.isSelected == true;
    });
  }



}
