import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ContactsRate911Model } from 'src/Models/ContactsRate911Model';
import { CountyMaster911Model } from 'src/Models/CountyMaster911Model';
import { CountyMasterService } from 'src/Services/county-master.service';
import { Main911Service } from 'src/Services/main911.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-contact-rate',
  templateUrl: './contact-rate.component.html',
  styleUrls: ['./contact-rate.component.css']
})
export class ContactRateComponent implements OnInit {

  rateId: string = "";
  tokenKey: any;
  isloading: boolean = false;
  contactsRateList: ContactsRate911Model[] = [];
  PageNumber: number = 1;
  totalRecordCount: number = 0;
  PageSize: number = 10;
  OrderBy: string;  
  upDowCountyCSS: string = '';
  upDowCityCSS: string = '';
  isAscending: boolean = false;
  dropdownDateCountyFilter = [];
  selectedItemsCounty = [];
  countyList: CountyMaster911Model[] = [];
  dropdownSettingsDateCountyFilter = {};
  countyId: string = ' ';
  listOfCountyIds = [];
  filterText: string = ' ';
  SelectedTool: string;
  upDowStateCSS: string = '';
  upDowCityNameCSS = '';
  upDowFirstNameCSS = '';
  upDowLastNameCSS = '';
  upDowLastDateCSS = '';
  upDowConfiDateCSS = '';
  upDowEmailCSS = '';
  isRecord: boolean = false;
  openMenuIndex: number = -1;

  constructor(private activedRoute: ActivatedRoute, private main911Service: Main911Service,
    private countyService: CountyMasterService,private router: Router,private checkAuth: CheckAuth) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.OrderBy = 'StateName';
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsDateCountyFilter = {
      singleSelection: false,
      text: "Filter by County",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No County(s) available',
      showCheckbox: true,
      lazyLoading: true,
    };

    $(function () {
      $('.wrapper1').on('scroll', function (e) {
        $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft());
      });
      $('.wrapper2').on('scroll', function (e) {
        $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft());
      });
      $('.div1').width($('table').width());
      $('.div2').width($('table').width());
    });
    
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.rateId = params['id']
          this.getAllContactByRateId();
        }
      }
    });
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.getAllContactByRateId();
  }

  getAllContactByRateId() {
    this.isloading = true;
    this.main911Service.GetAllContactByRateId(this.tokenKey, this.PageNumber, this.PageSize, parseInt(this.rateId), this.OrderBy, this.countyId, this.filterText).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.contactsRateList = [];
          this.contactsRateList = result['contactByRate']
          if (this.contactsRateList != null) {
            if (this.contactsRateList.length == 0) {
              this.isRecord = true;
            }
            else {
              this.isRecord = false;
              this.totalRecordCount = this.contactsRateList[0].totalRecordCount;
              this.GetCountyByStateId(this.contactsRateList[0].state);
            }
          }
          else
          this.isRecord = true;
        }
      });
    this.isloading = false;
  }

  sort(sortBy: string) {
      if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      
      if (sortBy === "cityName") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = 'fa fa-arrow-down';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactFirstName") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = 'fa fa-arrow-down';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactLastName") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = 'fa fa-arrow-down';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "LastContactDate") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = 'fa fa-arrow-down';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ConfirmationDate") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = 'fa fa-arrow-down';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactEmail") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "cityName") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = 'fa fa-arrow-up';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactFirstName") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = 'fa fa-arrow-up';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactLastName") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = 'fa fa-arrow-up';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "LastContactDate") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = 'fa fa-arrow-up';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ConfirmationDate") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = 'fa fa-arrow-up';
        this.upDowEmailCSS = '';
      }
      else if (sortBy === "ContactEmail") {
        this.upDowStateCSS = '';
        this.upDowCityNameCSS = '';
        this.upDowFirstNameCSS = '';
        this.upDowLastNameCSS = '';
        this.upDowLastDateCSS = '';
        this.upDowConfiDateCSS = '';
        this.upDowEmailCSS = 'fa fa-arrow-down';
      }
    }
    this.getAllContactByRateId();
  }

  GetCountyByStateId(stateId) {
    this.countyService.GetCountyByStateId(this.tokenKey, stateId, true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyList = result['county'];
          this.dropdownDateCountyFilter = [];
          if (this.countyList.length > 0) {
            let b = [];
            this.selectedItemsCounty = [];
            this.countyList.forEach(x => {
              let a = this.listOfCountyIds.filter(e => e == x.countyId);
              if (a.length > 0) {
                b.push(x.countyId);
                this.selectedItemsCounty.push({ "id": x.countyId, "itemName": x.countyName })
              }
              this.dropdownDateCountyFilter.push({ "id": x.countyId, "itemName": x.countyName })
            });
            this.listOfCountyIds = b;
            if (this.listOfCountyIds.length > 0) {
              this.countyId = this.listOfCountyIds.join(',')
            }
          }
          else {
            this.selectedItemsCounty = [];
            this.listOfCountyIds = [];
          }
        }
      });
  }

  onSelectCounty($event) {
    this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
    this.listOfCountyIds.push($event.id);
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.getAllContactByRateId();
  }

  onDeSelectCounty($event) {
    if (this.listOfCountyIds != null) {
      if (this.listOfCountyIds.length > 0) {
        this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.getAllContactByRateId();
  }

  onSelectAllCounty($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.getAllContactByRateId();
  }

  onDeSelectAllCounty($event) {
    this.listOfCountyIds = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.countyId = ' ';
    this.getAllContactByRateId();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  keywordFilters($event) {
    if ($event.target.value != "" && $event.target.value != null)
      this.filterText = $event.target.value;
    else
      this.filterText = ' ';
    this.getAllContactByRateId();
  }

  updateContact(id) {
    if (id != undefined && id > 0) {
      let navigation = '/admin/' + this.SelectedTool + '/contacts/manage/' + id;
      this.router.navigate([navigation]);
    }
  }

  backtorate(){
    let navigation = '/admin/' + this.SelectedTool + '/rates';
      this.router.navigate([navigation]);
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
