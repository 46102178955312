import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TRFACarriersModel } from 'src/Models/TRFACarrierModel';
import { TrfaCarrierMasterService } from 'src/Services/trfa-carrier--master.service';

@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.css']
})
export class CarriersComponent implements OnInit {
  stateCode: any;
  tokenKey: string;
  listofCarrier: TRFACarriersModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  CarrierName: string;


  upDowCarrierNameCSS: string = '';
  upDowSortOrderCSS: string = '';
  upDowActiveCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  carrierTypeId:number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private trfaCarrierMaster: TrfaCarrierMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofCarrier = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'SortOrder';
    this.CarrierName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Carriers');
    
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetCarrier();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetCarrier();
  }

  GetCarrier() { 
     
      if (this.CarrierName == "null") this.CarrierName = " ";
      this.trfaCarrierMaster.GetAllCarrier(this.tokenKey, this.PageNumber, this.PageSize, this.CarrierName, this.OrderBy).subscribe(
        data => {
          this.isloading = false;
          this.listofCarrier = data['carrier'];
          if (this.listofCarrier != null) {
            if (this.listofCarrier.length == 0)
              this.isRecord = true;
            else {
              this.isRecord = false;
              this.totalRecordCount = this.listofCarrier[0].totalRecordCount;
            }
          }
          else
            this.isRecord = true;
        },
        error => { this.stateCode = error }
      );
    
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.CarrierName = $event.target.value;
    else
      this.CarrierName = 'null';

    this.GetCarrier();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "CarrierName") {
        this.upDowCarrierNameCSS = 'fa fa-arrow-down';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowCarrierNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowCarrierNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "CarrierName") {
        this.upDowCarrierNameCSS = 'fa fa-arrow-up';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "SortOrder") {
        this.upDowCarrierNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-up';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowCarrierNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-up';
      }
    }
    this.GetCarrier();
  }

  addCarrier() {
    let navigation = '/admin/' + this.SelectedTool + '/carrier/manage';
    this.router.navigate([navigation]);
  }

  updateCarrier(carrierTypeId) {
    let navigation = '/admin/' + this.SelectedTool + '/carrier/manage/' + carrierTypeId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.carrierTypeId = id;
  }


  deleteCarrier(carrierTypeId) {
    this.isloading = true;
    this.trfaCarrierMaster.DeleteCarrier(this.tokenKey, carrierTypeId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
            this.showMessage("Carrier Deleted successfully", 'Success', 'success')
        }
        this.GetCarrier();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }


}
