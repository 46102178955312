
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterModel } from 'src/Models/filterModel';

@Injectable({
  providedIn: 'root'
})
export class MatrixService {

  AppUrl: string = "";
  filterModel = new FilterModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  getMatrix(TokenKey: string, StateID: string, UserID: any, ColumnIndex: any, OrderBy: any, WhereCondition: any, productId: number): Observable<any> {
    this.setAPIUrl();    
    this.filterModel.stateID = StateID;
    this.filterModel.userID = UserID;
    this.filterModel.columnIndex = ColumnIndex;
    this.filterModel.orderBy = OrderBy;
    this.filterModel.whereCondition = WhereCondition;
    this.filterModel.productId = productId;

    let URL = this.AppUrl + 'api/Matrix/GetMatrix';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getState(TokenKey: string, userId: number, productId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Matrix/GetState/' + userId + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  SaveUserPrefrences(TokenKey: string, ListofID: any, UserID: any, productId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Matrix/SaveUserPrefrences/' + ListofID + '/' + UserID + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetfundRefrence(TokenKey: string, StateID: string, AssementCatId: any, LoginUser: any, productId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/Matrix/GetfundRefrence/' + StateID + '/' + AssementCatId + '/' + LoginUser + '/' + productId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
