import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CrraCategoryMasterService {

  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }


  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllCategory(TokenKey: string, PageNumber: number, PageSize: number, filterText: string,orderBy:string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRACategoryMaster/GetAllCategory/' + filterText + '/' + PageNumber + '/' + PageSize+'/'+orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CategoryAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRACategoryMaster/CategoryAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCategoryById(TokenKey: string,categoryId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRACategoryMaster/GetCategoryById/' + categoryId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteCategory(TokenKey: string,categoryId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRACategoryMaster/DeleteCategory/' + categoryId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetCategory(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRACategoryMaster/GetCategory';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

}
