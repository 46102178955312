import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CheckAuth } from '../../../Services/utility.service';
import { AppConfig } from '../../../Services/app.config';
import { StateMasterModel } from '../../../Models/stateMasterModel';
import { UserModel } from '../../../Models/usersModel';
import { UsersService } from '../../../Services/users.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  changepasswordForm: FormGroup;
  loginuserID: number = 0;
  appModel: any;
  tokenKey: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  statusCode: number = 0;
  userModel: UserModel = {
    userId: '',
    userName: '',
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isActive: '',
    createdDate: null,
    createdBy: null,
    modifiedDate: null,
    modifiedBy: null
  };

  showoldeye: boolean = false;
  showold: boolean = false;
  showneweye: boolean = false;
  shownew: boolean = false;
  showconfirmeye: boolean = false;
  showconfirm: boolean = false;
  SelectedTool: string;
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private UserService: UsersService
    , private activedRoute: ActivatedRoute, private toastr: ToastrService
    , private router: Router, private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.changepasswordForm = this._fb.group({
      userid: 0,
      oldpassword: '',
      newpassword: '',
      confirmpassword: '',
      modifiedBy: this.loginuserID
    })
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('My Profile');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {    
    this.movetotop();
    this.Getuser();
  }

  Getuser(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.UserService.GetUserFindByUserID(this.tokenKey, this.loginuserID).subscribe(
      data => {        
        this.userModel = data["userMaster"][0];
        //this.userModel.userName = data["userMaster"][0].userName;
        //this.userModel.companyName = data["userMaster"][0].companyName;
        //this.userModel.firstName = data["userMaster"][0].firstName;
        //this.userModel.lastName = data["userMaster"][0].lastName;
        //this.userModel.email = data["userMaster"][0].email;
        //this.userModel.phone = data["userMaster"][0].phone;
        //this.userModel.listOfstate = data["userMaster"][0].listOfstate;
        this.unblockDocument();
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
      }
    );
  }
  savepassword(changepasswordForm: any) {    
    if ((changepasswordForm.value["oldpassword"] == null || changepasswordForm.value["oldpassword"] == undefined ||
      changepasswordForm.value["oldpassword"].trim() == "")) {
      this.showMessage('Please enter old password.', 'Required', 'error');
      return;
    }
    else {
      if (changepasswordForm.value["oldpassword"].trim() !=
        this.userModel.password.trim()) {
        this.showMessage('Incorrect old password.', 'Invalid Password', 'error');
        return;
      }
    }
    if ((changepasswordForm.value["newpassword"] == null || changepasswordForm.value["newpassword"] == undefined ||
      changepasswordForm.value["newpassword"].trim() == "")) {
      this.showMessage('Please enter new password.', 'Required', 'error');
      return;
    }
    if ((changepasswordForm.value["confirmpassword"] == null || changepasswordForm.value["confirmpassword"] == undefined ||
      changepasswordForm.value["confirmpassword"].trim() == "")) {
      this.showMessage('Please enter confirm password.', 'Required', 'error');
      return;
    }
    if (changepasswordForm.value["confirmpassword"].trim() != changepasswordForm.value["newpassword"].trim()) {
      this.showMessage('Password and Confirm password not matched', 'Invalid Password', 'error');
      return;
    }
    if (changepasswordForm.value["newpassword"].length <= 5) {
      this.showMessage('Password must contain at least 6 character', 'Password To Short', 'error');
      return;
    }
    if (this.isPasswordFormat(changepasswordForm.value["newpassword"]) == null) {
      this.showMessage('password should contain at least 1 uppercase, lowercase, digit and special character', 'Password Pattern', 'error');
      return;
    }
    this.blockDocument();
    changepasswordForm.value["userid"] = this.loginuserID;    
    this.userModel.userId = changepasswordForm.value["userid"];
    this.userModel.password = changepasswordForm.value["newpassword"];
    this.userModel.modifiedBy = this.loginuserID.toString();
    this.UserService.updatepassword(this.userModel, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        changepasswordForm.reset();
        this.showMessage('Password changed successfully', 'Password changed', 'success');
        this.unblockDocument();
      }
      else {
        this.showMessage(data.status, 'Error', 'error');
        this.unblockDocument();
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }

  isPasswordFormat(control: any) {
    let EMAIL_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    if (control != "" && (control.length > 5 && EMAIL_REGEXP.test(control))) {
      return { "Password pattern are valid": true };
    }

    return null;
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  isValidMailFormat(control: FormControl) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value != "" && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { "Please provide a valid email": true };
    }

    return null;
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }
  changeoldpass($event) {
    if ($event.target.value != "" && $event.target.value != null) {
      this.showoldeye = true;
    }
    else {
      this.showoldeye = false;
    }
  }
  hideshowoldpassword() { this.showold = !this.showold; }
  changenewpass($event) {
    if ($event.target.value != "" && $event.target.value != null) {
      this.showneweye = true;
    }
    else {
      this.showneweye = false;
    }
  }
  hideshownewpassword() { this.shownew = !this.shownew; }
  changeconfirmpass($event) {
    if ($event.target.value != "" && $event.target.value != null) {
      this.showconfirmeye = true;
    }
    else {
      this.showconfirmeye = false;
    }
  }
  hideshowconfirmpassword() { this.showconfirm = !this.showconfirm; }
}
