import { StatusModel } from "src/Models/StepMasterModel";

export function checkStepIsCompleted(appStage: string, page: StatusModel) {

    if (appStage == 'company-info' && page.isCompanyProfileDetailsCompleted) {
        return true;
    }
    else if (appStage == 'add-comp-que' && page.isAddCompQueCompleted) {
        return true;
    }
    else if (appStage == 'state-selection' && page.isStateSelectionCompleted) {
        return true;
    }
    else if (appStage == 'notes' && page.isNotes) {
        return true;
    }
    else if (appStage == 'attach-to-determine-services' && page.isAttechedDetailsCompleted) {
        return true;
    }
    else if (appStage == 'manage-baserate' && page.isRateDetailsCompleted) {
        return true;
    }
    else if (appStage == 'pricing-quotes' && page.isQuoteDetailsCompleted) {
        return true;
    }
    else if (appStage == 'add-on-services' && page.isAddOnServicesCompleted) {
        return true;
    }
    else if (appStage == 'compliance' && page.isComplianceDetailsCompleted) {
        return true;
    }
    else if (appStage == 'proposal' && page.isProposalDetailsCompleted) {
        return true;
    }
    else  if (appStage == 'add-company-info' && page.isAdditionalCompanyInformation) {
        return true;
    }
    else if (appStage == 'sensitive-info' && page.isSensitiveInformation) {
        return true;
    }
    else if (appStage == 'portal-credential' && page.isInteserraPortalCredential) {
        return true;
    }
    
    

    return false;
}

export function checkStepIsCompletedNew(appStage: string, page: StatusModel) {

    if (appStage == 'company-info' && page.isCompanyProfileDetailsCompleted) {
        return true;
    }
    else if (appStage == 'add-comp-que' && page.isAddCompQueCompleted) {
        return true;
    }
    else if (appStage == 'state-selection' && page.isStateSelectionCompleted) {
        return true;
    }
    else if (appStage == 'attachments' && page.isAttechedDetailsCompleted) {
        return true;
    }
    return false;
}

export function getIncompletedStep(page: StatusModel) {

    if (!page.isCompanyProfileDetailsCompleted) {
        return 'company-info';
    }
    else if (!page.isAddCompQueCompleted) {
        return 'add-comp-que';
    }
    else if (!page.isStateSelectionCompleted) {
        return 'state-selection';
    }
    else if (!page.isNotes) {
        return 'notes';
    }
    else if (!page.isAttechedDetailsCompleted) {
        return 'attach-to-determine-services';
    }
    else if (!page.isRateDetailsCompleted) {
        return 'manage-baserate';
    }
    else if (!page.isQuoteDetailsCompleted) {
        return 'pricing-quotes';
    }
    else if (!page.isAddOnServicesCompleted) {
        return 'add-on-services';
    }
    else if (!page.isComplianceDetailsCompleted) {
        return 'compliance';
    }
    else if (!page.isProposalDetailsCompleted) {
        return 'proposal';
    }
    
}
export function getIncompletedStepNew(page: StatusModel) {

    if (!page.isCompanyProfileDetailsCompleted) {
        return 'company-info';
    }
    else if (!page.isAddCompQueCompleted) {
        return 'add-comp-que';
    }
    else if (!page.isStateSelectionCompleted) {
        return 'state-selection';
    }
    else if (!page.isAttechedDetailsCompleted) {
        return 'attachments';
    }
}

export function checkStepIsCompletedPhase3(appStage: string, page: StatusModel) {

    if (appStage == 'add-company-info' && page.isAdditionalCompanyInformation) {
        return true;
    }
    else if (appStage == 'sensitive-info' && page.isSensitiveInformation) {
        return true;
    }
    else if (appStage == 'portal-credential' && page.isInteserraPortalCredential) {
        return true;
    }
    else if (appStage == 'attach-to-determine-services' && page.isAttechedDetailsCompleted) {
        return true;
    }
    else if (appStage == 'onboarding-state-selection' && page.isStateSelectionCompleted) {
        return true;
    }
 
    return false;
}

export function getIncompletedStepPhase3(page: StatusModel) {

    if (!page.isAdditionalCompanyInformation) {
        return 'add-company-info';
    }
    else if (!page.isSensitiveInformation) {
        return 'sensitive-info';
    }
    else if (!page.isInteserraPortalCredential) {
        return 'portal-credential';
    }
    else if (!page.isAttechedDetailsCompleted) {
        return 'attach-to-determine-services';
    }
    else if (!page.isStateSelectionCompleted) {
        return 'onboarding-state-selection'
    }
}

export function checkStepIsCompletedVendor(appStage: string, page: StatusModel) {

    if (appStage == 'client-quote-company-info' && page.isCompanyProfileDetailsCompleted) {
        return true;
    }
    else if (appStage == 'client-quote-add-comp-que' && page.isAddCompQueCompleted) {
        return true;
    }
    else if (appStage == 'client-quote-state-selection' && page.isStateSelectionCompleted) {
        return true;
    }
    else if (appStage == 'client-quote-add-on-services' && page.isAddOnServicesCompleted) {
        return true;
    }
    else if (appStage == 'client-quote-compliance' && page.isComplianceDetailsCompleted) {
        return true;
    }
    else if (appStage == 'client-notes' && page.isNotes) {
        return true;
    }
    else if (appStage == 'client-quote-attach-determine-services' && page.isAttechedDetailsCompleted) {
        return true;
    }

    return false;
}

export function getIncompletedStepVendor(page: StatusModel) {

    if (!page.isCompanyProfileDetailsCompleted) {
        return 'client-quote-company-info';
    }
    else if (!page.isAddCompQueCompleted) {
        return 'client-quote-add-comp-que';
    }
    else if (!page.isStateSelectionCompleted) {
        return 'client-quote-state-selection';
    }
    else if (!page.isAddOnServicesCompleted) {
        return 'client-quote-add-on-services';
    }
    else if (!page.isComplianceDetailsCompleted) {
        return 'client-quote-compliance';
    }
    else if (!page.isNotes) {
        return 'client-notes';
    }
    else if (!page.isAttechedDetailsCompleted) {
        return 'client-quote-attach-determine-services';
    }
}