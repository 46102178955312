import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CrraLinkMasterService {

  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }


  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllLink(TokenKey: string, PageNumber: number, PageSize: number, linkId: string,stateId:string,orderBy:string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRALinkMaster/GetAllLink/' + linkId + '/'+ stateId + '/' + PageNumber + '/' + PageSize+'/'+orderBy;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  LinkAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRALinkMaster/LinkAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetLinkById(TokenKey: string,linkId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRALinkMaster/GetLinkById/' + linkId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteLink(TokenKey: string,linkId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRALinkMaster/DeleteLink/' + linkId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetLinkType(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRALinkMaster/GetLinkType';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}