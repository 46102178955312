import { Component, HostListener, OnInit } from '@angular/core';
import { ActionItemTypeModel } from 'src/Models/ActionItemTypeModel';
import { ActionItemService } from 'src/Services/actionitem.service';
import { CheckAuth } from 'src/Services/utility.service';
import { FilterModel } from 'src/Models/filterModel';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-action-item-type',
  templateUrl: './action-item-type.component.html',
  styleUrls: ['./action-item-type.component.css']
})
export class ActionItemTypeComponent implements OnInit {
  tokenKey: string = '';
  loginuserID: number;

  actionTypeList: ActionItemTypeModel[];
  filterParameter = new FilterModel();
  totalRecordCount: number = -1;

  upDowNameCSS: string = '';
  upDowSortCSS: string = '';
  upDowColorCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedDateCSS: string = '';
  isAscending: boolean;
  SelectedTool: string;

  isloading: boolean = false;
  deleteId: number = 0;
  openMenuIndex: number = -1;

  constructor(private toastr: ToastrService, private titleService: Title, private router: Router, private actionItemService: ActionItemService, private checkAuth: CheckAuth) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    this.filterParameter.pageNumber = '1';
    this.filterParameter.pageSize = '10';
    this.filterParameter.whereCondition = '';
    this.filterParameter.orderBy = 'SortOrder';
    this.titleService.setTitle('Categories');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.GetActionTypesByPages();
  }

  GetActionTypesByPages() {
    this.isloading = true;
    this.actionItemService.GetActionTypesByPages(this.tokenKey, this.filterParameter).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.actionTypeList = data['actionTypesList'];
          if (this.actionTypeList != null) {
            if (this.actionTypeList.length > 0) {
              this.totalRecordCount = data['actionTypesList'][0]['totalRecordCount'];
            }
            else {
              this.totalRecordCount = 0;
            }
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  pageChanged($event: any) {
    this.filterParameter.pageNumber = parseInt($event.page).toString();
    this.GetActionTypesByPages();
  }

  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.filterParameter.orderBy = sortBy + ' DESC';

      if (sortBy === "typeName") {
        this.upDowNameCSS = 'fa fa-arrow-down';
        this.upDowColorCSS = '';
        this.upDowSortCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "colorCode") {
        this.upDowNameCSS = '';
        this.upDowColorCSS = 'fa fa-arrow-down';
        this.upDowSortCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "createdDate") {
        this.upDowNameCSS = '';
        this.upDowColorCSS = '';
        this.upDowSortCSS = '';
        this.upDowCreatedDateCSS = 'fa fa-arrow-down';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "modifiedDate") {
        this.upDowNameCSS = '';
        this.upDowColorCSS = '';
        this.upDowSortCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.filterParameter.orderBy = sortBy;

      if (sortBy === "typeName") {
        this.upDowNameCSS = 'fa fa-arrow-up';
        this.upDowColorCSS = '';
        this.upDowSortCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "colorCode") {
        this.upDowNameCSS = '';
        this.upDowColorCSS = 'fa fa-arrow-up';
        this.upDowSortCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "createdDate") {
        this.upDowNameCSS = '';
        this.upDowColorCSS = '';
        this.upDowSortCSS = '';
        this.upDowCreatedDateCSS = 'fa fa-arrow-up';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "modifiedDate") {
        this.upDowNameCSS = '';
        this.upDowColorCSS = '';
        this.upDowSortCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedDateCSS = 'fa fa-arrow-up';
      }
    }
    this.GetActionTypesByPages();
  }

  editActionItemType(actionItemTypeId: any) {
    this.router.navigate(['admin/trac-it/category/manage/' + actionItemTypeId]);
  }

  setDeletActionItem(actionItemTypeId: any) {
    this.deleteId = actionItemTypeId;
  }

  deletActionItemType(actionItemTypeId: any) {
    this.actionItemService.DeleteActionTypes(this.tokenKey, actionItemTypeId).subscribe(
      data => {        
        if (data['status'] == 'Success') {
          this.showMessage('Record deleted successfully', 'Sucess', 'success');
          this.GetActionTypesByPages();
        }
        else
          this.showMessage(data['status'] + '-' + data['message'], 'Error', 'error');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
