import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CrraCarrierMasterService } from 'src/Services/crra-carrier-master.service';
import { CrraCategoryMasterService } from 'src/Services/crra-category-master.service';
import { CrraContentMasterService } from 'src/Services/crra-content-master.service';
import { CrraSubCategoryMasterService } from 'src/Services/crra-sub-category-master.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrfaMonthsService } from 'src/Services/trfa-months.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-crra-contents-add-update',
  templateUrl: './crra-contents-add-update.component.html',
  styleUrls: ['./crra-contents-add-update.component.css']
})
export class CrraContentsAddUpdateComponent implements OnInit {

  ContentMasterForm: FormGroup;
  userId: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  ckeConfig: any;
  isEdit: boolean = false;

  //State
  dropdownSettingsDateStateFilter = {};
  stateList: StateMasterModel[] = [];
  selectedItemsState = [];
  dropdownDateStateFilter = [];
  listOfStateIDs = [];

  //Category  
  dropdownSettingsDateCategoryFilter = {};
  selectedItemsCategory = [];
  listOfCategoryIDs = [];
  dropdownDateCategoryFilter = [];
  categoryList: any[] = [];
  listOfCategoryName = [];

  //Carrier
  dropdownSettingsDateCarrierFilter = {};
  dropdownDateCarrierFilter = [];
  selectedItemsCarrier = [];
  listOfCarrierIDs = [];
  carrierList: any[] = [];
  listOfCarrierName = [];

  //Sub Category  
  dropdownSettingsDateSubCategoryFilter = {};
  selectedItemsSubCategory = [];
  listOfSubCategoryIDs = [];
  dropdownDateSubCategoryFilter = [];
  subCategoryList: any[] = [];
  listOfSubCategoryName = [];

  dropdownSettingsMonthFilter = {};
  dropdownMonthFilter = [];
  listOfMonth = [];
  auditMonth: any;
  MonthList: any[] = [];
  selectedItemsMonth = [];
  auditDate: any;

  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  module = "CRRA";
  contentData: any;
  stateId: string = ' ';
  subCategoryId: number = 0;
  categoryId: string = ' ';

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute, private toastr: ToastrService,
    private router: Router, private stateService: StateMasterService,
    private datePipe: DatePipe,
    private crracategoryMasterService: CrraCategoryMasterService,
    private crracarrierMasterService: CrraCarrierMasterService,
    private crraContentMasterService: CrraContentMasterService,
    private crrasubCategoryMasterService: CrraSubCategoryMasterService,
    private trfaMonthsService: TrfaMonthsService) {
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.ckeConfig = {
      versionCheck:false,
      toolbar: [
        {
          items:
            [
              'Undo', 'Redo', 'Link', 'Unlink', 'Anchor',
              'Image', 'Table', 'SpecialChar', 'Maximize', 'Source', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat',
              'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Styles', 'Format', 'Font'
            ]
        }],
    }

    this.stateId = ' ';
    this.dropdownSettingsDateStateFilter = {
      singleSelection: true,
      text: "Select Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsDateCategoryFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Category(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsDateCarrierFilter = {
      singleSelection: false,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Carrier(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsDateSubCategoryFilter = {
      singleSelection: false,
      text: "Select Sub Category",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Sub Category(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsMonthFilter = {
      singleSelection: false,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Month(s) available',
      showCheckbox: true
    };

    this.ContentMasterForm = this._fb.group({
      contentId: 0,
      stateId: [0],
      carrierTypeId: [''],
      categoryId: [0],
      subCategoryId: [''],
      ruleNote: [''],
      name: [''],
      notes: ['', Validators.required],
      tmiNotes: [''],
      effectiveDate: ['', Validators.required],
      auditDate: [''],
      auditMonth: [''],
      stateName: [''],
    })
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.getCarrier();
    this.getCategory();
    this.getMonths();

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetContentById(params['id']);
        }
      }
    });
  }

  getMonths() {
    this.MonthList = this.trfaMonthsService.getMonths();
    this.dropdownMonthFilter = [];
    this.MonthList.forEach(x => {
      this.dropdownMonthFilter.push({ "id": x.id, "itemName": x.name })
    });
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownDateStateFilter = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownDateStateFilter.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  onChangeState(event) {
    this.ContentMasterForm.patchValue({
      stateId: event.id,
      stateName: event.itemName
    });
  }

  onDeSelectDateFilterStateAll($event) {
    this.selectedItemsState = [];
  }

  getCarrier() {
    this.crracarrierMasterService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierList = result['crracarrier'];
          this.dropdownDateCarrierFilter = [];
          this.carrierList.forEach(x => {
            this.dropdownDateCarrierFilter.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCarrier($event) {
    this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
    this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event.itemName)
    this.listOfCarrierIDs.push($event.id);
    this.listOfCarrierName.push($event.itemName);
  }

  onDeSelectCarrier($event) {

    if (this.listOfCarrierIDs != null) {
      if (this.listOfCarrierIDs.length > 0) {
        this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event.id)
      }
    }

    if (this.listOfCarrierName != null) {
      if (this.listOfCarrierName.length > 0) {
        this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event.itemName)
      }
    }
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIDs != null) {
        if (this.listOfCarrierIDs.length > 0) {
          this.listOfCarrierIDs = this.removeElemetninArray(this.listOfCarrierIDs, $event[iCounter].id)
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIDs.push($event[iCounter].id);
        }
      }
      if (this.listOfCarrierName != null) {
        if (this.listOfCarrierName.length > 0) {
          this.listOfCarrierName = this.removeElemetninArray(this.listOfCarrierName, $event[iCounter].itemName)
          this.listOfCarrierName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCarrierName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllCarrier($event) {

    this.listOfCarrierIDs = [];
    this.listOfCarrierName = [];
  }



  getCategory() {
    this.crracategoryMasterService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryList = result['crracategory'];
          this.dropdownDateCategoryFilter = [];
          this.categoryList.forEach(x => {
            this.dropdownDateCategoryFilter.push({ "id": x.categoryId, "itemName": x.categoryName })
          });
        }
      },
      error => { }
    );
  }

  onChangeCategory(event) {
    this.ContentMasterForm.patchValue({
      categoryId: event.id,
      categoryTypeName: event.itemName
    });
    this.listOfCategoryIDs = [];
    this.listOfCategoryName = [];
    this.listOfSubCategoryIDs = [];
    this.listOfSubCategoryName=[];

    this.listOfCategoryIDs.push(event.id);
    this.listOfCategoryName.push(event.itemName);
    this.GetSubCategoryByCategoryId(event.id);
  }

  onDeselectCategory($event)
  {
    this.listOfCategoryIDs = [];
    this.listOfCategoryName = [];
    this.listOfSubCategoryIDs = [];
    this.listOfSubCategoryName=[];
    this.selectedItemsSubCategory=[];
    this.dropdownDateSubCategoryFilter=[];
  }

  onDeSelectDateFilterChangeAll($event) {
    this.selectedItemsCategory = [];
    this.listOfSubCategoryIDs = [];
    this.listOfSubCategoryName=[];
    this.selectedItemsSubCategory=[];
    this.dropdownDateSubCategoryFilter=[];
  }

  GetSubCategoryByCategoryId(categoryId) {
    this.crrasubCategoryMasterService.GetSubCategoryByCategoryId(this.tokenKey, categoryId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.subCategoryList = result['crrasubcategory'];
          this.dropdownDateSubCategoryFilter = [];
          this.selectedItemsSubCategory = [];
          this.subCategoryList.forEach(x => {
            this.dropdownDateSubCategoryFilter.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
          });
          if (!this.isEdit && this.subCategoryId != 0) {
            if (this.subCategoryList.length > 0) {
              let data = this.subCategoryList.filter(x => x.subCategoryId == this.subCategoryId)[0];
              if (data) {
                this.selectedItemsSubCategory.push({ "id": data.subCategoryId, "itemName": data.subCategoryName })
                this.ContentMasterForm.patchValue({
                  subCategoryId: data.subCategoryId
                });
              }
            }
          }
        }
      }
    );
  }



  onSelectSubCategory($event) {
    this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event.id)
    this.listOfSubCategoryIDs.push($event.id);

    this.listOfSubCategoryName = this.removeElemetninArray(this.listOfSubCategoryName, $event.itemName)
    this.listOfSubCategoryName.push($event.itemName);
  }

  onDeSelectSubCategory($event) {
    if (this.listOfSubCategoryIDs != null) {
      if (this.listOfSubCategoryIDs.length > 0) {
        this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event.id)
      }

    }

    if (this.listOfSubCategoryName != null) {
      if (this.listOfSubCategoryName.length > 0) {
        this.listOfSubCategoryName = this.removeElemetninArray(this.listOfSubCategoryName, $event.itemName)
      }
    }
  }

  onSelectAllSubCategory($event) {
    this.listOfSubCategoryIDs = [];
    this.listOfSubCategoryName = [];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfSubCategoryIDs != null) {
        if (this.listOfSubCategoryIDs.length > 0) {
          this.listOfSubCategoryIDs = this.removeElemetninArray(this.listOfSubCategoryIDs, $event[iCounter].id)
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
        else {
          this.listOfSubCategoryIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfSubCategoryName != null) {
        if (this.listOfSubCategoryName.length > 0) {
          this.listOfSubCategoryName = this.removeElemetninArray(this.listOfSubCategoryName, $event[iCounter].itemName)
          this.listOfSubCategoryName.push($event[iCounter].itemName);
        }
        else {
          this.listOfSubCategoryName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllSubCategory($event) {
    this.listOfSubCategoryIDs = [];
    this.listOfSubCategoryName = [];
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  GetContentById(ContentId) {
    this.crraContentMasterService.GetContentById(this.tokenKey, parseInt(ContentId.toString())).subscribe(
      result => {

        if (result['status'] == 'Success') {
          let categoryId = this.listOfCategoryIDs.join(',');
          this.subCategoryList = result['crracontent'].subcategoryData;
          this.dropdownDateSubCategoryFilter = [];
          this.selectedItemsSubCategory = [];

          this.subCategoryList.forEach(x => {
            this.dropdownDateSubCategoryFilter.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
          });


          let subCategory = this.dropdownDateSubCategoryFilter.filter(x => x.id == this.subCategoryId);
          if (subCategory.length > 0) {
            this.selectedItemsSubCategory.push({ "id": subCategory[0].id, "itemName": subCategory[0].itemName });
          }

          this.auditDate = '';
          this.auditMonth = [];

          if (result['crracontent'].lastAudit != null) { this.auditDate = new Date(result['crracontent'].lastAudit) }
          if (result['crracontent'].auditMonth != null) { this.auditMonth = new Date(result['crracontent'].auditMonth) }

          this.contentData = result['crracontent'];

          this.ClearContentForm();

          this.ContentMasterForm.patchValue({
            contentId: result['crracontent'].contentId,
            stateId: result['crracontent'].stateId,
            carrierTypeId: result['crracontent'].carrierTypeId,
            categoryId: result['crracontent'].categoryId,
            subcategoryId: result['crracontent'].subCategoryId,
            ruleNote: result['crracontent'].ruleNote,
            notes: result['crracontent'].notes,
            name: result['crracontent'].name,
            tmiNotes: result['crracontent'].tmiNotes,
            effectiveDate: result['crracontent'].effectiveDate,
            auditDate: this.auditDate,
            auditMonth: this.auditMonth,
            stateName: result['crracontent'].stateName,
          });

          if (this.stateList.length > 0) {
            this.selectedItemsState = [];
            let data = this.stateList.filter(x => x.stateId == result['crracontent'].stateId)[0];
            this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name })
          }
          else {
            setTimeout(() => {
              this.selectedItemsState = [];
              let data = this.stateList.filter(x => x.stateId == result['crracontent'].stateId)[0];
              this.selectedItemsState.push({ "id": data.stateId, "itemName": data.name })
              this.stateId = result['crracontent'].stateId;
            }, 2000);
          }

          if (this.categoryList.length > 0) {
            this.selectedItemsCategory = [];
            this.listOfCategoryIDs = [];
            this.listOfCategoryName = [];
            result['crracontent'].categoryId.map((x) => {
              let data = this.categoryList.filter(y => y.categoryId == x.categoryId)[0];
              this.selectedItemsCategory.push({ "id": data.categoryId, "itemName": data.categoryName })
              this.listOfCategoryIDs.push(data.categoryId)
              this.listOfCategoryName.push(data.categoryName)
            })
          }
          else
            setTimeout(() => {
              this.selectedItemsCategory = [];
              this.listOfCategoryIDs = [];
              this.listOfCategoryName = [];
              result['crracontent'].categoryId.map((x) => {
                let data = this.categoryList.filter(y => y.categoryId == x.categoryId)[0];
                this.selectedItemsCategory.push({ "id": data.categoryId, "itemName": data.categoryName });
                this.listOfCategoryIDs.push(data.categoryId)
                this.listOfCategoryName.push(data.categoryName);
              })
            }, 2000);


          if (this.carrierList.length > 0) {
            this.selectedItemsCarrier = []
            this.listOfCarrierIDs = []
            this.listOfCarrierName = []
            for (let iCounter = 0; iCounter < result['crracontent'].carrierTypeId.length; iCounter++) {

              let data = this.carrierList.filter(x => x.carrierTypeId == result['crracontent'].carrierTypeId[iCounter].carrierTypeId)[0];
              this.selectedItemsCarrier.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
              this.listOfCarrierIDs.push(data.carrierTypeId)
              this.listOfCarrierName.push(data.carrierName)
            }
          }
          else
            setTimeout(() => {
              if (result['crracontent'].carrierTypeId.length > 0) {
                this.selectedItemsCarrier = []
                this.listOfCarrierIDs = []
                this.listOfCarrierName = [];
                for (let iCounter = 0; iCounter < result['crracontent'].carrierTypeId.length; iCounter++) {

                  let data = this.carrierList.filter(x => x.carrierTypeId == result['crracontent'].carrierTypeId[iCounter].carrierTypeId)[0];
                  this.selectedItemsCarrier.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
                  this.listOfCarrierIDs.push(data.carrierTypeId)
                  this.listOfCarrierName.push(data.carrierName)
                }
              }
            }, 1000);

          if (this.subCategoryList.length > 0) {
            this.selectedItemsSubCategory = [];
            this.listOfSubCategoryIDs = [];
            this.listOfSubCategoryName = [];
            result['crracontent'].subCategoryId.map((x) => {
              let data = this.subCategoryList.filter(y => y.subCategoryId == x.subCategoryId)[0];
              this.selectedItemsSubCategory.push({ "id": data.subCategoryId, "itemName": data.subCategoryName })
              this.listOfSubCategoryIDs.push(data.subCategoryId)
              this.listOfSubCategoryName.push(data.subCategoryName)
            })
          }
          else
            setTimeout(() => {
              this.selectedItemsSubCategory = [];
              this.listOfSubCategoryIDs = [];
              this.listOfSubCategoryName = [];
              result['crracontent'].subCategoryId.map((x) => {
                let data = this.subCategoryList.filter(y => y.subCategoryId == x.subCategoryId)[0];
                this.selectedItemsSubCategory.push({ "id": data.subCategoryId, "itemName": data.subCategoryName });
                this.listOfSubCategoryIDs.push(data.subCategoryId)
                this.listOfSubCategoryName.push(data.subCategoryName)
              })
            }, 2000);

          if (this.MonthList.length > 0) {
            let auditMonthList = [];
            if (result['crracontent'].auditMonth != null) {
              if (result['crracontent'].auditMonth.includes(',')) {
                auditMonthList = result['crracontent'].auditMonth.split(',');

                this.listOfMonth = [];
                this.selectedItemsMonth = [];

                for (let iCounter = 0; iCounter < auditMonthList.length; iCounter++) {
                  let data = this.MonthList.filter(x => x.name == auditMonthList[iCounter])[0];
                  if (data != undefined) {
                    this.selectedItemsMonth.push({ "id": data.id, "itemName": data.name })
                    this.listOfMonth.push(data.name)
                  }
                }
              }
              else if (result['crracontent'].auditMonth !== "") {
                auditMonthList = result['crracontent'].auditMonth;

                this.listOfMonth = [];
                this.selectedItemsMonth = [];
                let data = this.MonthList.filter(x => x.name == auditMonthList)[0];
                if (data != undefined) {
                  this.selectedItemsMonth.push({ "id": data.id, "itemName": data.name })
                  this.listOfMonth.push(data.name)
                }
              }
            }
          }
        }
      });
  }

  ClearContentForm() {
    this.ContentMasterForm = this._fb.group({
      contentId: 0,
      stateId: [0],
      carrierTypeId: [''],
      categoryId: [0],
      subCategoryId: [''],
      ruleNote: [''],
      name: [''],
      notes: ['', Validators.required],
      tmiNotes: [''],
      effectiveDate: ['', Validators.required],
      auditDate: [''],
      auditMonth: [''],
      stateName: [''],
    })
  }

  GetSubCateByCategoryIdEdit(categoryId) {
    this.crrasubCategoryMasterService.GetSubCategoryByCategoryId(this.tokenKey, categoryId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.subCategoryList = result['crrasubcategory'];
          this.dropdownDateSubCategoryFilter = [];
          this.selectedItemsSubCategory = [];

          this.subCategoryList.forEach(x => {
            this.dropdownDateSubCategoryFilter.push({ "id": x.subCategoryId, "itemName": x.subCategoryName })
          });


          let subCategory = this.dropdownDateSubCategoryFilter.filter(x => x.id == this.subCategoryId);
          if (subCategory.length > 0) {
            this.selectedItemsSubCategory.push({ "id": subCategory[0].id, "itemName": subCategory[0].itemName });
          }
        }
      });
  }

  save() {
    this.isSubmit = true;
    let data = this.ContentMasterForm.value;
    if (!this.ContentMasterForm.valid || this.selectedItemsState.length == 0 || this.selectedItemsCarrier.length == 0 || this.selectedItemsCategory.length == 0 || this.selectedItemsSubCategory.length == 0)
      return
    data["createdBy"] = this.userId;
    let userId = this.userId;
    let carrierId = this.listOfCarrierIDs.join(',');
    let carrierName = this.listOfCarrierName.join(',');
    let categoryId = this.listOfCategoryIDs.join(',');
    let CategoryName = this.listOfCategoryName.join(',');
    let subCategoryId = this.listOfSubCategoryIDs.join(',');
    let subCategoryName = this.listOfSubCategoryName.join(',');
    let MonthList = this.listOfMonth.join(',');
    
    let value = {
      contentId: data.contentId,
      stateId: data.stateId,
      carrierTypeId: carrierId,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      ruleNote: data.ruleNote != null ? data.ruleNote.trim() : data.ruleNote,
      name: data.name != null ? data.name.trim() : data.name,
      notes: data.notes != null ? data.notes.trim() : data.notes,
      tmiNotes: data.tmiNotes,
      effectiveDate: this.datePipe.transform(data.effectiveDate, 'yyyy-MM-dd hh:mm:ss'),
      lastAudit: this.datePipe.transform(data.auditDate, 'yyyy-MM-dd hh:mm:ss'),
      auditMonth: MonthList==''?null:MonthList,
      userId: userId,
      carrierTypeName: carrierName,
      CategoryTypeName: CategoryName,
      subCategoryTypeName: subCategoryName,
      stateName: data.stateName
    }

    this.crraContentMasterService.ContentAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.contentId > 0)
              this.showMessage('Content Update successfully', 'Success', 'success');
            else
              this.showMessage('Content saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/contents']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  cancelContent() {
    let navigation = '/admin/' + this.SelectedTool + '/contents';
    this.router.navigate([navigation]);
  }

  onDeSelectMonthAll($event) {
    this.selectedItemsMonth = [];
    this.listOfMonth = [];
  }

  onSelectMonth($event) {
    this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event.itemName)
    this.listOfMonth.push($event.itemName);
  }

  onDeSelectMonth($event) {
    if (this.listOfMonth != null) {
      if (this.listOfMonth.length > 0) {
        this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event.itemName)
      }
    }
  }

  onSelectAllMonth($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonth != null) {
        if (this.listOfMonth.length > 0) {
          this.listOfMonth = this.removeElemetninArray(this.listOfMonth, $event[iCounter].itemName)
          this.listOfMonth.push($event[iCounter].itemName);
        }
        else {
          this.listOfMonth.push($event[iCounter].itemName);
        }
      }
    }
  }
}


