import { MenuMasterModel } from 'src/Models/MenuMasterModel';

export class MenuGroupModel {
    menuGroupId: number;
    menuGroupName: string;
    displayOrder: number;
    isActive: boolean;
    isChildMenu: boolean;
    childMenu: MenuMasterModel[];
}

export class CustomMenuGroupModel {
    customProductId: number;
    companyId: number;
    title: string;
    description: string;   
}