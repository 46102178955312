import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TRFAFundTypeGetModel, TRFAFundTypeModel } from 'src/Models/TRFAFundTypeModel';
import { TrfaFundTypeMasterService } from 'src/Services/trfa-fund-type--master.service';

@Component({
  selector: 'app-fund-type',
  templateUrl: './fund-type.component.html',
  styleUrls: ['./fund-type.component.css']
})
export class FundTypeComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofFundType: TRFAFundTypeGetModel[]

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string = 'FundTypeName';
  FundTypeName: string;


  upDowFundTypeNameCSS: string = '';
  upDowSortOrderCSS: string = '';
  upDowActiveCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  fundTypeId:number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private trfaFundTypeMaster: TrfaFundTypeMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofFundType = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'SortOrder';
    this.FundTypeName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Fund Types');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetFundType();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetFundType();
  }

  GetFundType() {
    if (this.FundTypeName == "null") this.FundTypeName = " ";
    this.trfaFundTypeMaster.GetAllFundType(this.tokenKey, this.PageNumber, this.PageSize, this.FundTypeName, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofFundType = data['fundType'];
        if (this.listofFundType != null) {
          if (this.listofFundType.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofFundType[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );

  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.FundTypeName = $event.target.value;
    else
      this.FundTypeName = 'null';

    this.GetFundType();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "FundTypeName") {
        this.upDowFundTypeNameCSS = 'fa fa-arrow-down';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "sortOrder") {
        this.upDowFundTypeNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowFundTypeNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "FundTypeName") {
        this.upDowFundTypeNameCSS = 'fa fa-arrow-up';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "sortOrder") {
        this.upDowFundTypeNameCSS = '';
        this.upDowSortOrderCSS = 'fa fa-arrow-up';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowFundTypeNameCSS = '';
        this.upDowSortOrderCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-up';
      }
    }
    this.GetFundType();
  }

  addFundType() {
    let navigation = '/admin/' + this.SelectedTool + '/fundType/manage';
    this.router.navigate([navigation]);
  }

  updateFundType(fundTypeId) {
    let navigation = '/admin/' + this.SelectedTool + '/fundType/manage/' + fundTypeId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.fundTypeId = id;
  }

  deleteFundType(fundTypeId) {
    this.isloading = true;
    this.trfaFundTypeMaster.DeleteFundType(this.tokenKey, fundTypeId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
            this.showMessage("Fundtype Deleted successfully", 'Success', 'success')
        }
        this.GetFundType();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
