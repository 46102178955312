import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Filter911Model } from 'src/Models/filterModel';
import { ContactsGet } from 'src/Models/Main911Model';
import { FilterContactsRate911Model } from 'src/Models/ContactsRate911Model';

@Injectable({
  providedIn: 'root'
})
export class Main911Service {
  AppUrl: string = "";
  filter911Model = new Filter911Model();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  MainGetAll(TokenKey: string, PageNumber: number, PageSize: number, stateId: string, countyId: string, cityId: string, orderBy: string, statusid: string): Observable<any> {
    this.filter911Model = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      stateID: stateId,
      cityID: cityId,
      countyID: countyId,
      orderBy: orderBy,
      isFutureChanges: false,
      statusid: statusid
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/MainGetAll';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filter911Model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CreateOrUpdateMain911(TokenKey: string, addNewActionModel: any): Observable<any> {

    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/MainAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetMainById(TokenKey: string, rateId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/GetMainById/' + rateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  Delete911Main(TokenKey: string, rateID: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/Delete911Main/' + rateID;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  GetAllScope(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/GetAllScope';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
  Export911Main(TokenKey: string, stateId: string, countyId: string, cityId: string, orderBy: string,statusid: string): Observable<any> {
    this.filter911Model = {
      pageNumber: 1,
      pageSize: 1000000000,
      stateID: stateId,
      cityID: cityId,
      countyID: countyId,
      orderBy: orderBy,
      isFutureChanges: false,
      isActive: true,
      statusid: statusid
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/Export911Main';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filter911Model, httpOptions)
      .pipe(catchError(this.handleError));
  }
  Export911RateLookup(TokenKey: string, stateId: string, countyId: string, cityId: string, orderBy: string, isFutureChanges: boolean,filterText:string): Observable<any> {
    this.filter911Model = {
      pageNumber: 1,
      pageSize: 1000000000,
      stateID: stateId,
      cityID: cityId,
      countyID: countyId,
      orderBy: orderBy,
      isFutureChanges: isFutureChanges,
      isActive: true,
      filterText:filterText
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/Export911RateLookup';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filter911Model, httpOptions)
      .pipe(catchError(this.handleError));
  }
  RateLookupGetAll(TokenKey: string, PageNumber: number, PageSize: number,userId:number ,stateId: string, countyId: string, cityId: string, orderBy: string, isFutureChanges: boolean,isFavorite:number,filterText:string): Observable<any> {
    this.filter911Model = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      userID:userId,
      stateID: stateId,
      cityID: cityId,
      countyID: countyId,
      orderBy: orderBy,
      isFutureChanges: isFutureChanges,
      isActive: true,
      isFavorite:isFavorite,
      filterText:filterText
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/RateLookupGetAll';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filter911Model, httpOptions)
      .pipe(catchError(this.handleError));
  }
  Get911FutureChanges(TokenKey: string, PageNumber: number, PageSize: number,userId:number, stateId: string, countyId: string, cityId: string, orderBy: string,displayFavorite :number): Observable<any> {
    this.filter911Model = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      userID:userId,
      stateID: stateId,
      cityID: cityId,
      countyID: countyId,
      orderBy: orderBy,
      isFutureChanges: false,
      isActive: true,
      isFavorite:displayFavorite
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/Get911FutureChanges';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filter911Model, httpOptions)
      .pipe(catchError(this.handleError));
  }
  ContactsGetAll(TokenKey: string, PageNumber: number, PageSize: number, stateId: string,countyId: string,cityId:string,scopeId:string, orderBy: string, filterText: string ,selectedItem:string,fromDate: string, toDate: string,fromDate1: string, toDate1: string): Observable<any> {
    this.filter911Model = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      stateID: stateId,
      countyID: countyId,
      cityID: cityId,
      scopeID: scopeId,
      orderBy: orderBy,
      isFutureChanges: false,
      isActive: true,
      filterText: filterText,
      selectedItem:selectedItem,
      fromDate: fromDate,
      toDate: toDate,
      fromDate1: fromDate1,
      toDate1: toDate1,
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/ContactsGetAll';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<ContactsGet[]>(URL, this.filter911Model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetContactsTable(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/GetContactsTable';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  ContactsStateGetAll(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/ContactsStateGetAll';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey, }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  UploadExcel(TokenKey: string, addNewActionModel: FormData) {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/UploadExcel';
    const req = new HttpRequest('POST', URL, addNewActionModel, {
      reportProgress: true, headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey })
    });    
    return this._http.request(req)
      .pipe(catchError(this.handleError));
  }

  GetAllContactByRateId(TokenKey: string, pageNumber: number, pageSize: number, rateId: number, orderBy: string, countyId: string, filterText: string): Observable<any> {
    this.setAPIUrl();
    let input = new FilterContactsRate911Model();
    input.rateId = rateId;
    input.pageNumber = pageNumber;
    input.pageSize = pageSize;
    input.orderBy = orderBy;
    input.countyId = countyId;
    input.filterText = filterText;
    let data = 'api/main911/GetAllContactByRateId';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, input, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CreateOrUpdateContact(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/ContactAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllStateScope(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/GetAllStateScope';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetContactById(TokenKey: string, Id: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/GetContactById/' + Id;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  DeleteContact(TokenKey: string, id: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/DeleteContact/' + id;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
  UploadDataonFTP(TokenKey: string,userId:string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/Export911MainScheduler/'+userId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetTRFA911FTPUploadHistory(userlink: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFA_911_DownloadHistory/GetTRFA911FTPUploadHistory/'+userlink;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DownloadS3Files(fileName: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFA_911_DownloadHistory/DownloadS3Files/'+fileName;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteZipFile(FileName: string): Observable<any> {        
    this.setAPIUrl();        
    this.AppUrl = this.AppUrl + 'api/TRFA_911_DownloadHistory/DeleteZipFile';
    let input: FormData = new FormData();
    input.append('FileName', FileName);        
    return this._http.post<any>(this.AppUrl, input)
        .pipe(catchError(this.handleError));
  }

  GetSubscriberUserList(TokenKey: string,pageNumber: number, pageSize: number,orderBy: string,module:string): Observable<any>
  {
    this.setAPIUrl();
    let input = new FilterContactsRate911Model();
    input.pageNumber = pageNumber;
    input.pageSize = pageSize;
    input.orderBy = orderBy;
    input.module = module;

    let data = 'api/main911/GetSubscriberUserList';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, input, httpOptions)
    .pipe(catchError(this.handleError));
  }

  GetTRFA911NotificationHistory(userlink: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFA_911_DownloadHistory/GetTRFA911NotificationHistory/'+userlink;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  Get911NotificationHistory(userlink: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRFA_911_DownloadHistory/Get911NotificationHistory/'+userlink;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllContactColumn(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/GetAllContactColumn';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
