import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { CRRAUpdateGetModel } from 'src/Models/CRRAUpdateModel';
import { FavoriteFilterModel } from 'src/Models/FavoriteFilterModel';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { CrraUpdatesMasterService } from 'src/Services/crra-updates-master.service';
import { FavoriteService } from 'src/Services/favorite.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-crra-frontupdates',
  templateUrl: './crra-frontupdates.component.html',
  styleUrls: ['./crra-frontupdates.component.css']
})
export class CrraFrontupdatesComponent implements OnInit {

  stateCode: any;
  _userID: string = "";
  ProductId: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];
  stateSubscription: any;
  statefilterData: any;

  tokenKey: string;
  SearchItem: string = '';
  fromDate: string = '';
  toDate: string = '';

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;

  rangeOfupdatesDates: any;

  upDowNoteCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  isAscending: boolean = true;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  OrderByName: string = 'StateName';


  dropdownDateSelectedColumnFilter = [];
  selectedColumn = [];
  listOfColumnId = [];
  dropdownSettingsDateselectedColumnFilter = {};

  SelectedStateFav: number = 0;
  favouriteStateModel: FavoriteFilterModel[] = [];
  displayFavorite:number=0;
  blockedDocument = false;

  isShowprogressSpinner = false;
  listofUpdate: CRRAUpdateGetModel[]

  isColumnExist:boolean =true;
  listofMain911PrintData: any[] = [];
  quaryValues:string="";

  constructor(private appActivity: ApplicationActivityService,
    private router: Router,
    private checkAuth: CheckAuth,
    private titleService: Title,
    private activedRoute: ActivatedRoute,
    private stateService: StateMasterService, private toastr: ToastrService,private favorite: FavoriteService,
    private crraUpdateMaster:CrraUpdatesMasterService) {
    this.tokenExpireCalculation(
      sessionStorage.getItem("appStartTime"),
      sessionStorage.getItem("tokenExpiryValue")
    );
    this._userID = sessionStorage.getItem('UserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    sessionStorage.setItem('tokenValue', localStorage.getItem('tokenValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.ProductId = ToolEnum.crra;
    this.titleService.setTitle('Updates');
    this.tokenKey = sessionStorage.getItem("tokenValue");
    this.OrderBy = 'UpdateDate desc';
    this.upDowUpdateDateCSS = 'active-up';
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: "No Jurisdiction(s) available",
      showCheckbox: true,
    };

    this.dropdownSettingsDateselectedColumnFilter = {
      singleSelection: false,
      text: "Show Column",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Column(s) available',
      showCheckbox: true
    };
    this.getColumn();
    this.getSubscriptionState();
    this.OrderByName="";

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {

        if (params['id'] != null) {

          this.quaryValues = params['id'];
          
          this.GetCRRANotificationHistory();
        }
        else {
          this.GetUpdate();
        }
      }
      else
      {this.GetUpdate();}
    });
    //this.GetUpdate();
  }

  GetCRRANotificationHistory()
  {

    this.blockDocument();
    this.crraUpdateMaster.GetCRRANotificationHistory(this.quaryValues).subscribe(
      data => {
        this.isloading = false;
        this.listofUpdate = data['crraupdate'];
        if(data.status == 'Failure' && this.listofUpdate.length>0)
        {
          this.showMessage('No any update found', 'Warning', 'warning');
          this.router.navigate(['/Access/Denied/View Updates']);
        }
        else
        {
          if(this.listofUpdate.length>0)
          {
          this.blockedDocument = false;
          sessionStorage.setItem('crraUpdatehistoryUId', this.listofUpdate[0].userId.toString());
          if (data.status == 'Success' && (typeof (localStorage.getItem('UserName')) != 'undefined' && localStorage.getItem('UserName') != null && localStorage.getItem('UserName') != '')) {
            if(this.listofUpdate[0].userId.toString()===localStorage.getItem('UserId'))
            {
              setTimeout(() => {
                    this.isloading = false;
                    
                      if (this.listofUpdate.length == 0)
                        this.isRecord = true;
                      else {
                        this.isRecord = false;
                        this.totalRecordCount = this.listofUpdate.length;
                        data['crraupdate'].map((x) => {
                          let stateData=this.stateList.filter(y=>y.stateId==x.stateId)[0]
                          this.selectedItemsState.push({ "id": stateData.stateId, "itemName": stateData.name});
                          this.listOfStateIds.push(stateData.stateId)
                        })
                      }
              }, 2000);
              this.unblockDocument();
            }
            else
            this.router.navigate(['/Access/Denied/View Updates']);
          }
          else if (data.status == 'Success')
            this.router.navigate(['login/' + this.quaryValues]);
          else
            this.router.navigate(['login']);
        }
        else{
          this.blockedDocument = false;
          this.showMessage('Data Not Found', 'Warning', 'warning');
          this.router.navigate(['/Access/Denied/View Updates']);
          this.unblockDocument();
        }
        }
      },
      error => {
       this.blockedDocument = false;
        console.log(error);
        this.router.navigate(['login']);
        this.unblockDocument();
      }
     );
  }

  getSubscriptionState() {
    this.stateSubscription = null;
    this.stateService.i360ManageSubscriptionClientId(this.tokenKey, this._userID, 0, false).subscribe(
      data => {
        this.stateSubscription = {
          wirelessStates: data.wirelessStates,
          wirelessStatesCount: data.wirelessStates ? data.wirelessStates.length : 0,
          iprStates: data.iprStates,
          iprStatesCount: data.iprStates ? data.iprStates.length : 0,
          voIPStates: data.voIPStates,
          voIPStatesCount: data.voIPStates ? data.voIPStates.length : 0,
          actionStates: data.actionStates,
          actionStatesCount: data.actionStates ? data.actionStates.length : 0,
          productAccess: data.productAccess,
          briefingStates: data.briefingStates,
          briefingStatesCount: data.briefingStates ? data.briefingStates.length : 0,
          state911: data.state911,
          state911Count: data.state911 ? data.state911.length : 0,
          carrierCategory: data.carrierCategory,
          carrierCategoryCount: data.carrierCategory ? data.carrierCategory.filter(m => m.isSelected == true).length : 0,
          trfa: data.trfa,
          crra: data.crra
        };
        this.stateList = data.crra;
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { console.log(error); }
    );
  }

  isUpdatesDatesFilter(value: any) {
    if (value != null) {
      if (value.length == 2) {
        const d = new Date(value[0]);
        const a = [this.pad(d.getMonth() + 1), this.pad(d.getDate()), d.getFullYear()].join('/');

        const e = new Date(value[1]);
        const f = [this.pad(e.getMonth() + 1), this.pad(e.getDate()), e.getFullYear()].join('/');

        this.fromDate = a;
        this.toDate = f;
        this.resetPageNoandSize();
        this.GetUpdate();
      }
    }
  }
  pad(s) {
    return (s < 10) ? '0' + s : s;
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }
  
  GetUpdate() {
    this.isloading = true;

    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.Note == "null") this.Note = " ";
    
    let listofstatename = "";
      this.listOfStateIds.forEach((value, y) => {
        const statemodal = this.stateList.filter(x => x.stateId == value);
        if(this.listOfStateIds.length-1==y)
        {         
         listofstatename = listofstatename + statemodal[0].name + '<br />';
        }
        else{
          listofstatename = listofstatename + statemodal[0].name + ',' + '<br />';
        }
      });

      if (listofstatename != "") {
        listofstatename = listofstatename.trim();
        ApplicationActivityStore('View', 'CRRA Updates-', this.appActivity, ToolEnum.crra, ActionActivity.CRRA, '/View/updates',listofstatename);
      }
      else {
        ApplicationActivityStore('View', 'CRRA Updates', this.appActivity, ToolEnum.crra, ActionActivity.CRRA, '/View/updates', null);
      }


      this.crraUpdateMaster.GetAllUpdateByUser(this.tokenKey, this.PageNumber, this.PageSize,this.stateId, this.fromDate, this.toDate, this.OrderBy,parseInt(this._userID),this.displayFavorite).subscribe(
      data => {
        this.isloading = false;
        this.listofUpdate = data['update'];
        if (this.listofUpdate != null) {
          if (this.listofUpdate.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofUpdate[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  clearUpdateDate() {
    this.rangeOfupdatesDates = [];
    this.fromDate = '';
    this.toDate = '';
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowNoteCSS = '';
      this.upDowStateNameCSS = '';
      this.upDowUpdateDateCSS = '';

      if (sortBy === "UpdateNote") {
        this.upDowNoteCSS = 'active-up';
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active-up';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowUpdateDateCSS = 'active-up';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;
      this.upDowNoteCSS = '';
      this.upDowStateNameCSS = '';
      this.upDowUpdateDateCSS = '';
      if (sortBy === "UpdateNote") {
        this.upDowNoteCSS = 'active';
      }
      else if (sortBy === "StateName") {
        this.upDowStateNameCSS = 'active';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowUpdateDateCSS = 'active';
      }
    }
    this.OrderByName = sortBy;
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  getColumn() {

    this.dropdownDateSelectedColumnFilter = [
      { "id": 1, "itemName": 'Jurisdiction' },
      { "id": 2, "itemName": 'UpdateNote' },
      { "id": 3, "itemName": 'Update Date' },
    ]
    this.selectedColumn = [{ "id": 1, "itemName": 'Jurisdiction' },
    { "id": 2, "itemName": 'UpdateNote' },
    { "id": 3, "itemName": 'Update Date' },
    ]
  }

  isCheckColum(id) {
    let data = this.selectedColumn.filter((x => x.id == id))[0];
    if (data != null)
      return true
    else return false
  }

  AddToFavourite(item, IsFavourite: number) {
    let stateId = item.stateId;
    if (IsFavourite == 0) {
      this.SelectedStateFav = stateId;
      this.favouriteStateModel = item;
      var elem = document.getElementById("lnkFavouriteValidation");
      elem.click();
    }
    else {
      this.SaveFavourite(stateId, IsFavourite, item);
    }
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  SaveFavourite(stateId: string, IsFavourite: number, item)
  {
    this.blockDocument();
    let stateName = item.stateName;
    //document.getElementById("btnClose").click();
    this.favorite.ClientStateFavouriteAdd(this.tokenKey, parseInt(this._userID), stateId, IsFavourite, ToolEnum.crra).subscribe(data => 
    {
      if (data["status"] == "Success") 
      {
        this.GetUpdate();
        if (IsFavourite == 1) {
          ApplicationActivityStore('Mark Favorite', 'CRRA Updates', this.appActivity, ToolEnum.crra, ActionActivity.CRRA, '/View/updates', stateName);
          this.showMessage('Selected jurisdiction added successfully to favorite list.', 'Favorite', 'success');
        }
        else {
          ApplicationActivityStore('Remove Favorite', 'CRRA Updates', this.appActivity, ToolEnum.crra, ActionActivity.CRRA, '/View/updates', stateName);
          this.showMessage('Selected jurisdiction removed successfully from favorite list.', 'Favorite', 'success');
        }        
      }
      else 
      {
        this.showMessage(data["status"], 'Error', 'error');
      }
      this.unblockDocument();
      }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    })
  }

  GetFavourite(displayFav:number)
  {
    this.displayFavorite=displayFav;
    this.GetUpdate();
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  pageChanged($event: any) {    
    this.PageNumber = parseInt($event.page);
    ApplicationActivityStore('View', 'CRRA Updates'+$event.page,this.appActivity,ToolEnum.crra, ActionActivity.CRRA,'/View/updates');
    this.GetUpdate();
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else
      this.Note = 'null';
      this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectColumn($event) {
    this.isColumnExist=true
    this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event.id)
    this.listOfColumnId.push($event.id);
    if (this.listOfColumnId.length > 0) {
    }
  }

  onDeSelectColumn($event) {

    if (this.listOfColumnId != null) {
      if (this.listOfColumnId.length > 0) {
        this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event.id)
      }
    }
    if (this.selectedColumn.length > 0) {
      this.isColumnExist=true
    }
    else{
      this.isColumnExist=false
    }
  }

  onSelectAllColumn($event) {
    this.isColumnExist=true
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfColumnId != null) {
        if (this.listOfColumnId.length > 0) {
          this.listOfColumnId = this.removeElemetninArray(this.listOfColumnId, $event[iCounter].id)
          this.listOfColumnId.push($event[iCounter].id);
        }
        else {
          this.listOfColumnId.push($event[iCounter].id);
        }
      }
    }
  }
  onDeSelectAllColumn($event) {
    this.isColumnExist=false
    this.selectedColumn = [];
    this.listOfColumnId = [];
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  validExport(id) {
    // if(this.exportCitiationList.length>0){
    //  let data= this.exportCitiationList.find((x)=>x.exportCitiationId==id);
    //  if(data !=null)return true;
    //  else return false
    // }
    //else return false
    return false
  }

  updateGetAllPrintData() {
    this.isloading = true;
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.Note == "null") this.Note = " ";
    this.stateId = this.listOfStateIds.join(',')
    this.crraUpdateMaster.GetAllUpdateByUser(this.tokenKey, 1, 1000000000, this.stateId, this.fromDate, this.toDate, this.OrderBy,parseInt(this._userID),0).subscribe(
      data => {
        this.isloading = false;
        this.listofMain911PrintData = data['update'];
        if (this.listofMain911PrintData != null) {
          if (this.listofMain911PrintData.length == 0) {
            this.isRecord = true;
          }
          else {
            this.isRecord = false;
          }
        }
        else {
          this.isRecord = true;
        }
        setTimeout(() => {
          this.print();
        }, 1000);
      },
      error => { }
    );
  }

  print() {
    ApplicationActivityStore('Download', 'CRRA Updates Print',this.appActivity,ToolEnum.crra, ActionActivity.CRRA,'/View/updates');
    let printContents, popupWin;
    printContents = document.getElementById('printareaDiv').innerHTML;
    popupWin = window.open('', 'PrintWindow', 'width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
    popupWin.document.open();
    popupWin.document.write(`
  <html>
    <head>
      <style>
      body{  width: 99%;}
        label { font-weight: 400;
                font-size: 13px;
                padding: 2px;
                margin-bottom: 5px;
              }
        table, td, th {
               border: 1px solid silver;
                }
                table td {
               font-size: 13px;
               word-wrap:break-word;
                }

                 table th {
               font-size: 13px;
                }
          table {
                border-collapse: collapse;
                width: 98%;
                table-layout:fixed;
                }
            th {
                height: 26px;
                }
      </style>
    </head>
<body onload="window.print();window.close()">${printContents}</body>
  </html>`
    );
    popupWin.document.close();

  }

  getPageLength() {
    // if (this.PageSize < this.totalRecordCount) return this.PageSize
    // else return this.totalRecordCount
    return Math.min((this.PageNumber * this.PageSize),this.totalRecordCount);
  }

  onFilterSelectAll($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAll($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }
}

