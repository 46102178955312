import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EmailLoginService } from 'src/Services/emaillogin.service';
import { FilterModel } from 'src/Models/filterModel';

@Component({
  selector: 'app-briefing-email-varification',
  templateUrl: './briefing-email-varification.component.html',
  styleUrls: ['./briefing-email-varification.component.css']
})
export class BriefingEmailVarificationComponent implements OnInit {
  quaryValues: string = '';
  filterModel = new FilterModel();
  blockedDocument: boolean = false;

  constructor(private activedRoute: ActivatedRoute,
    private router: Router,
    private emailLoginService: EmailLoginService) {

  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {

        if (params['id'] != null) {

          this.quaryValues = params['id'];
          this.filterModel.emailKey = params['id'];
          this.IsValidBriefingEmailKey();
        }
        else {
          this.router.navigate(['login/' + this.quaryValues]);
        }
      }
      else {
        this.router.navigate(['login/' + this.quaryValues]);
      }
    });
  }

  IsValidBriefingEmailKey() {
    this.blockedDocument = true;
    this.emailLoginService.isValidBriefingEmailKey(this.filterModel).subscribe(
      data => {
        this.blockedDocument = false;
        sessionStorage.setItem('emailBriefingUId', data.userId);
        if (data.status == 'Success' && (typeof (localStorage.getItem('UserName')) != 'undefined' && localStorage.getItem('UserName') != null && localStorage.getItem('UserName') != '')) {
          this.router.navigate(['/View/BriefingDetails/' + this.quaryValues]);
        }
        else if (data.status == 'Success')
          this.router.navigate(['login/' + this.quaryValues]);
        else
          this.router.navigate(['login']);
      },
      error => {
        this.blockedDocument = false;
        console.log(error);
        this.router.navigate(['login']);
      }
    );
  }

}
