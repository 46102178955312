import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/Services/app.config';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompletedPhase3, getIncompletedStepPhase3 } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-admin-onboarding-state-selection',
  templateUrl: './admin-onboarding-state-selection.component.html',
  styleUrls: ['./admin-onboarding-state-selection.component.css']
})
export class AdminOnboardingStateSelectionComponent implements OnInit {

  CompanyProfileInformationForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;

  ClientInformationId: number;

  mainUrl: string;
  subUrl: string;
  currentActiveTab: number = 1;


  file: any;
  @ViewChild('articlesInfo', { static: true }) articlesInfo;


  PriceQuoteStateList:any[];
  isPriceQuoteStateList:boolean=false;
  companyName:string;
  priceQuoteId:number=0;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute,
    private globle: Globle, private router: Router, private PricingQuotesMaster: PricingQuotesService, private toastr: ToastrService,
    private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = sessionStorage.getItem('UserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Onboarding Information - '+this.companyName);

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.ClientInformationId = params['id'];
        }
      }

      if (params['priceQuoteId']) {
        if (params['priceQuoteId'] != null) {
          this.priceQuoteId = params['priceQuoteId'];
          this.GetPriceQuoteSubscriptionState(this.priceQuoteId);
        }
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.ClientInformationId != null) {
      this.GetProgressStepDetailsById(this.ClientInformationId);
      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isAdditionalCompanyInformation = null;
      this.globle.cApp.statusModel.isSensitiveInformation = null;
      this.globle.cApp.statusModel.isInteserraPortalCredential = null;
      this.globle.cApp.statusModel.isStateSelectionCompleted = null;
      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
    }

  }

  ngOnInit() {

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  GetProgressStepDetailsById(clientInformationId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsByClientInformationId(this.tokenKey, clientInformationId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
          this.activeTab('onboarding-state-selection', 3);
        }
      }
    );
  }


  activeTab(url: string, tabId: number) {

    if (this.ClientInformationId != null) {
      if (checkStepIsCompletedPhase3(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.ClientInformationId+'/'+this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.ClientInformationId+'/'+this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompletedPhase3(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetPriceQuoteSubscriptionState(ClientInformationId) {
    this.blockDocument();
    this.PricingQuotesMaster.GetPriceQuoteSubscriptionStateByClientInformationId(this.tokenKey, ClientInformationId).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          if (result['stateList'] != null) {
            this.PriceQuoteStateList = result['stateList'];
            if (this.PriceQuoteStateList.length > 0) {
              this.isPriceQuoteStateList = true;
            }
          }
        }
        else{
          this.unblockDocument();
        }
      })
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  previousStep() {
    let navigation = '/admin/' + this.SelectedTool + '/attach-to-determine-services/' + this.ClientInformationId+'/'+this.priceQuoteId;
    this.router.navigate([navigation]);
  }

  save(){
    let navigation = '/admin/' + this.SelectedTool + '/pricing-quotes';
    this.router.navigate([navigation]);
  }

}
