import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaxExemptionFormsServiceService {

  AppUrl: string = "";
  constructor(private _http: Http, private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  i360TaxExemptionFormsAddUpdate(TokenKey: string, trainingModel: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TaxExemptionForm/i360TaxExemptionFormAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, trainingModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360TaxExemptionFormsSectionListWithIds(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TaxExemptionForm/i360TaxExemptionFormSectionListWithIds';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.get<any>(this.AppUrl + data, httpOptions).pipe(catchError(this.handleError));
  }

  i360TaxExemptionFormsGet(TokenKey: string, filterModel: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TaxExemptionForm/i360TaxExemptionFormGet';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360TaxExemptionFormsFindbyTaxExemptionFormId(TokenKey: string, specialReportId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TaxExemptionForm/i360TaxExemptionFormFindbyTaxExemptionFormId';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, specialReportId, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360TaxExemptionFormDelete(TokenKey: string, specialReportId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TaxExemptionForm/i360TaxExemptionFormDelete';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, specialReportId, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360TaxExemptionFormUpdate(TokenKey: string, currentIndexid: string, previousIndexid: string): Observable<any[]> {
    this.setAPIUrl();

    let data = 'api/TaxExemptionForm/i360TaxExemptionFormDetailUpdate/' + currentIndexid + '/' + previousIndexid;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  i360TaxExemptionFormFindbyTaxExemptionFormId(TokenKey: string, specialReportId: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TaxExemptionForm/i360TaxExemptionFormFindbyTaxExemptionFormId';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._httpClient.post<any>(this.AppUrl + data, specialReportId, httpOptions)
      .pipe(catchError(this.handleError));
  }
}