import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AppConfig } from '../../../Services/app.config';
import { CheckAuth } from '../../../Services/utility.service';
import { StateMasterService } from '../../../Services/state-master.service';
import { ToastrService } from 'ngx-toastr';
import { UpdatesService } from '../../../Services/updatesService';
import { FieldModel } from '../../../Models/fieldModel';
import { updateCategoryModel } from '../../../Models/updateCategoryModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatrixfundModel } from '../../../Models/matrixfundModel';
import { ToolEnum, AdminUserType } from '../../../Models/Common';
import { ClientCompanyModel } from 'src/Models/ClientCompanyModel';
import { UsersService } from 'src/Services/users.service';
import { UserModel } from 'src/Models/usersModel';
import { UpdatesModel } from 'src/Models/updatesModel';

@Component({
  selector: 'app-manageupdate',
  templateUrl: './manageupdate.component.html',
  styleUrls: ['./manageupdate.component.css']
})
export class ManageupdateComponent implements OnInit {
  updateForm: FormGroup;
  userID: number = 0;
  appModel: any;
  tokenKey: string = '';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  statusCode: number = 0;
  myDateValue: Date;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  stateFilter: string = '';


  //Fund List
  dropdownListFund = [];
  selectedItemsFund = [];
  dropdownSettingsFund = {};
  updatesofFilter: string = '';


  fieldModel: FieldModel[] = [];
  updateCategoryModel: updateCategoryModel[] = [];
  updateid: number = 0;
  isloading: boolean = false;
  listFund: MatrixfundModel[] = [];
  isfieldenable: boolean = false;
  SelectedTool: string;
  productId: number = 1;

  clientCompanyList: ClientCompanyModel[];
  dropdownListclientCompany = [];
  selectedItemsclientCompany = [];
  dropdownSettingclientCompany = {};
  listOfCompany = [];

  userModel: UserModel[];
  dropdownListclientCompanyUser = [];
  selectedItemsclientCompanyUser = [];
  dropdownSettingclientCompanyUser = {};
  listOfUsers = [];

  isEnableDisable: boolean = false;
  isThisUpdateRequiresActionToPerform: boolean = false;

  isUpdateRequiredValues: boolean = false;
  actionNotesValues: any = '';
  actionChargesValues: any = 0;

  isSendEmailNotificationChecked: boolean = true;
  systemUserCondition: string = '';

  constructor(private userServices: UsersService, private _fb: FormBuilder, private checkAuth: CheckAuth, private stateMasterService: StateMasterService
    , private toastr: ToastrService, private updatesService: UpdatesService
    , private router: Router, private titleService: Title
    , private activedRoute: ActivatedRoute) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.updateForm = this._fb.group({
      updateId: 0,
      updateCategoryId: 0,
      updateType: [''],
      matrixFundId: 0,
      fundFieldId: 0,
      stateId: 0,
      updateDate: new Date(),
      description: [''],
      isactive: 1,
      createdBy: this.userID,
      modifiedBy: this.userID,
      productId: 1,
      clientCompanyId: [{}],
      isUpdateRequired: 0,
      actionCharges: 0,
      actionNotes: '',
      actionTypeId: 4,
      companyUserId: [{}],
      isPushActionItem: false,
      isSendEmailNotification: 0
    })
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.dropdownSettingsFund = {
      singleSelection: true,
      text: "Select Fund Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No data available for selected state',
      showCheckbox: false
    };
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Update');

    this.dropdownSettingclientCompanyUser = {
      singleSelection: false,
      text: "Select User",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No data available for selected user',
      showCheckbox: true
    };

    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.systemUserCondition = 'null'
    else
      this.systemUserCondition = '  (CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.userID + '))';
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.movetotop();
    this.GetStateData();
    this.GetFieldsData();
    this.GetUpdateCategoryData();
    this.GetClientCompany();
    this.updateForm.controls['fundFieldId'].disable();
  }
  GetStateData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.stateMasterService.getState(this.tokenKey, this.productId).subscribe(
      data => {
        if (data["state"] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data["state"].length; iCounter++) {
            this.dropdownListState.push(
              { "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name }
            );
          }
          this.activedRoute.params.subscribe((params: Params) => {
            if (params['id']) {
              if (params['id'] != null) {
                this.updateid = params['id'];
                this.Getupdates();
              }
            }
          });
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
          this.unblockDocument();
        }
        this.dropdownSettingsState = {
          singleSelection: true,
          text: "Select State Type",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No state available',
          showCheckbox: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }
  GetFundbyState(stateid, updatesform): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.updatesService.GetMatrixFundbyStateId(this.tokenKey, stateid, this.productId).subscribe(
      data => {
        if (data["status"] == "Success") {
          this.listFund = data["funds"];
          // Set All Item
          this.dropdownListFund = [];
          for (let iCounter = 0; iCounter < data["funds"].length; iCounter++) {
            this.dropdownListFund.push(
              { "id": data["funds"][iCounter].matrixFundId, "itemName": data["funds"][iCounter].title }
            );
          }
          this.activedRoute.params.subscribe((params: Params) => {
            if (params['id']) {
              if (params['id'] != null) {
                this.updateid = params['id'];
                this.fillform(updatesform);
              }
            }
          });
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }
  FilterSelecteventState($event) {
    this.selectedItemsFund = [];
    this.stateFilter = "";
    if ($event.id != "" && $event.id != null) {
      if (this.stateFilter != "null") {
        this.stateFilter = this.stateFilter + "," + $event.id;
      }
      else {
        this.stateFilter = "," + $event.id;
      }
    }
    if (this.stateFilter.charAt(0) == ",") {
      this.stateFilter = this.stateFilter.slice(1)
    }

    if (this.stateFilter.split(',').length == 1) {
      this.GetFundbyState(+this.stateFilter.split(',')[0], null);
    }
    else {
      this.GetFundbyState(0, null);
    }
  }

  FilterDeSelectseventState($event) {
    this.selectedItemsFund = [];
    if ($event.id != "" && $event.id != null) {
      this.stateFilter = this.stateFilter.replace($event.id, "");
    }
    this.stateFilter = this.stateFilter.replace(/,\s*$/, "");
    this.stateFilter = this.stateFilter.replace(",,", ",");
    if (this.stateFilter == "") { this.stateFilter = "null" }

    if (this.stateFilter.charAt(0) == ",") {
      this.stateFilter = this.stateFilter.slice(1)
    }

    if (this.stateFilter.split(',').length == 1) {
      this.GetFundbyState(+this.stateFilter.split(',')[0], null);
    }
    else {
      this.GetFundbyState(0, null);
    }
  }

  onDeSelectAllState($event) {
    this.stateFilter = '';
  }

  GetFieldsData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.updatesService.getFundFields(this.tokenKey).subscribe(
      data => {
        if (data["fundField"] != null) {
          this.fieldModel = data["fundField"];
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
          this.unblockDocument();
        }

      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }

  GetUpdateCategoryData(): void {
    this.isloading = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.updatesService.getUpdateCategory(this.tokenKey).subscribe(
      data => {
        this.isloading = false;
        if (data["updateCategory"] != null) {
          this.updateCategoryModel = data["updateCategory"];
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
          this.unblockDocument();
        }

      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }

  dateFormat(dt: Date) {
    let dd = dt.getDate().toString();
    if (parseInt(dd.toString()) < 10)
      dd = '0' + dd.toString();

    let mm = (dt.getMonth() + 1).toString();
    if (parseInt(mm.toString()) < 10)
      mm = '0' + mm.toString();

    return mm + '/' + dd + '/' + dt.getFullYear() + ' 00:00:00';

  }

  saveupdates(updateForm: any) {

    let dt = new Date(updateForm.value['updateDate'])
    updateForm.value['updateDate'] = this.dateFormat(dt);



    if (updateForm.value["updateCategoryId"] == null ||
      updateForm.value["updateCategoryId"] == undefined ||
      updateForm.value["updateCategoryId"] == "") {
      this.showMessage('Please select update category.', 'Required', 'error');
      return;
    }
    if ((updateForm.value["updateType"] == null ||
      updateForm.value["updateType"] == undefined ||
      updateForm.value["updateType"] == "")) {
      this.showMessage('Please enter update type.', 'Required', 'error');
      return;
    }
    if (this.stateFilter == 'null' ||
      this.stateFilter == undefined ||
      this.stateFilter == "") {
      this.showMessage('Please select state.', 'Required', 'error');
      return;
    }

    // this.updatesModel.updateId = this.updateid;
    // this.updatesModel.stateId = this.stateFilter == '' ? 0 : parseInt(this.stateFilter);

    updateForm.value["updateId"] = this.updateid;
    updateForm.value["stateId"] = this.stateFilter;

    // if (updateForm.value["matrixFundId"].length > 0) {
    //   updateForm.value["matrixFundId"] = updateForm.value["matrixFundId"][0].id;
    // }
    // else {
    //   updateForm.value["matrixFundId"] = 0;
    // }

    updateForm.value["matrixFundId"] = this.updatesofFilter == '' ? '0' : this.updatesofFilter;

    updateForm.value["productId"] = this.productId;


    if (this.listOfCompany.length == 0) {
      updateForm.value["clientCompanyId"] = 0;
    }
    else {
      updateForm.value["clientCompanyId"] = this.listOfCompany.join(',');
    }



    if (this.listOfCompany.length == 0 && this.listOfUsers.length > 0) {
      this.showMessage('Please select company.', 'Required', 'error');
      return;
    }

    // if ((updateForm.value["clientCompanyId"] > 0) && (updateForm.value["companyUserId"].length == 0 || updateForm.value["companyUserId"] == 'undefined')) {
    //   this.showMessage('Please select user.', 'Required', 'error');
    //   return;
    // }

    if (this.listOfUsers.length == 0) {
      updateForm.value["companyUserId"] = 0;
    }
    else {
      updateForm.value["companyUserId"] = this.listOfUsers.join(',');
    }

    let isDecimal = this.isDecimalValue(updateForm.value["actionCharges"]);
    if (!isDecimal) {
      this.showMessage('Please enter valid action charges.', 'Required', 'error');
      return;
    }

    if (updateForm.value["isUpdateRequired"] == false || typeof (updateForm.value["isUpdateRequired"]) == 'undefined') {
      updateForm.value["actionCharges"] = 0;
      updateForm.value["actionNotes"] = null;
    }

    let msg = '';
    if (this.updateid == 0) {
      msg = 'Added';
    }
    else {
      msg = 'Updated';
    }

    // if (this.updateid == 0)    
    //   updateForm.value["isSendEmailNotification"] = true;

    updateForm.value["isSendEmailNotification"] = this.isSendEmailNotificationChecked;

    this.blockDocument();
    this.updatesService.addupdateupdates(this.updateForm.value, this.tokenKey).subscribe(data => {

      if (data.status == 'Success') {
        this.showMessage(msg + ' successfully', msg, 'success');
        this.unblockDocument();
        this.router.navigate(['/admin/' + this.SelectedTool + '/updates']);
      }
      else {
        //if (data.message == 'duplicate') {
        //  this.showMessage('Duplicate not allowed.', 'Duplicate', 'error');
        //  this.unblockDocument();
        //}
        //else {
        this.showMessage(data.status, 'Error', 'error');
        this.unblockDocument();
        //}
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }

  isDecimalValue(actionCharges: any) {
    var str = actionCharges.toString();
    var re = /^(0|[1-9]\d*)(\.\d+)?$/;
    var found = str.match(re);
    return found == null ? false : true;
  }

  Getupdates(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.updatesService.getupdatesfindByID(this.tokenKey, this.updateid).subscribe(
      data => {

        if (data["updates"] != null) {
          this.GetFundbyState(data["updates"][0].stateId, data);
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
          this.unblockDocument();
        }

        this.unblockDocument();
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
      }
    );
  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }
  fillform(data) {
    this.updateForm.setValue({
      'updateId': data["updates"][0].updateId,
      'updateCategoryId': data["updates"][0].updateCategoryId,
      'updateType': data["updates"][0].updateType,
      'matrixFundId': data["updates"][0].matrixFundId,
      'fundFieldId': data["updates"][0].fundFieldId,
      'stateId': data["updates"][0].stateId,
      'updateDate': new Date(data["updates"][0].updateDate),
      'description': data["updates"][0].description,
      'isactive': 1,
      'createdBy': this.userID,
      'modifiedBy': this.userID,
      'productId': this.productId,
      'clientCompanyId': [{}],
      'isUpdateRequired': data['updates'][0].isUpdateRequired,
      'actionCharges': data['updates'][0].actionCharges,
      'actionNotes': data['updates'][0].actionNotes,
      'actionTypeId': data['updates'][0].actionTypeId,
      'companyUserId': [{}],
      'isPushActionItem': data['updates'][0].isPushActionItem,
      'isSendEmailNotification': this.isSendEmailNotificationChecked,
    });
    if (data['updates'][0].isPushActionItem) {
      this.isThisUpdateRequiresActionToPerform = true;
    }

    if (data['updates'][0].isUpdateRequired) {
      this.isEnableDisable = true;
    }
    this.selectedItemsState = [{
      "id": parseInt(data["updates"][0].stateId),
      "itemName": data["updates"][0].stateName
    }];
    this.stateFilter = data["updates"][0].stateId;

    this.selectedItemsclientCompany = [];
    this.listOfCompany = [];
    if (data["updates"][0].clientCompanyId != null && data["updates"][0].clientCompanyId != '') {
      this.selectedItemsclientCompany.push({ "id": parseInt(data["updates"][0].clientCompanyId), "itemName": data["updates"][0].companyName });
      this.listOfCompany.push(parseInt(data["updates"][0].clientCompanyId));
      this.GetCompanyUser(parseInt(data["updates"][0].clientCompanyId));
    }

    this.selectedItemsclientCompanyUser = [];
    this.listOfUsers = [];
    if (data["updates"][0].userUpdatelist != null) {
      if (data["updates"][0].userUpdatelist.length > 0) {
        for (let iCounter = 0; iCounter < data["updates"][0].userUpdatelist.length; iCounter++) {
          if (parseInt(data["updates"][0].userUpdatelist[iCounter].userId) != 0) {
            this.selectedItemsclientCompanyUser.push({ "id": parseInt(data["updates"][0].userUpdatelist[iCounter].userId), "itemName": data["updates"][0].userUpdatelist[iCounter].userName });
            this.listOfUsers.push(parseInt(data["updates"][0].userUpdatelist[iCounter].userId));
          }
        }
      }
    }

    if (this.dropdownListFund.length > 0) {
      this.selectedItemsFund = [{
        "id": parseInt(data["updates"][0].matrixFundId),
        "itemName": data["updates"][0].matrixFundTitle
      }];

      this.updatesofFilter = data["updates"][0].matrixFundId;
      const fundItem = this.listFund.filter((item) => item.matrixFundId == data["updates"][0].matrixFundId);
      if (fundItem && fundItem.length > 0) {
        const assementCatId = fundItem[0].assementCatId;
        if (assementCatId != 7 && assementCatId != 9 && assementCatId != 15 && assementCatId != 16
          && assementCatId != 17) {
          this.updateForm.controls['fundFieldId'].enable();
        }
      }
    } else {
      this.selectedItemsFund = [];
    }
  }
  cancelupdates() {
    this.router.navigate(['/admin/' + this.SelectedTool + '/updates']);
  }

  FilterSelecteventupdatesof($event) {
    this.updatesofFilter = "";
    if ($event.id != "" && $event.id != null) {
      if (this.updatesofFilter != "null") {
        this.updatesofFilter = this.updatesofFilter + "," + $event.id;
      }
      else {
        this.updatesofFilter = "," + $event.id;
      }
    }
    if (this.updatesofFilter.charAt(0) == ",") {
      this.updatesofFilter = this.updatesofFilter.slice(1)
    }
    if (this.updatesofFilter.split(',').length == 1) {
      let currentfund = this.listFund.filter((item) => item.matrixFundId == +this.updatesofFilter);
      if (currentfund[0].assementCatId == 7 || currentfund[0].assementCatId == 1) {
        this.updateForm.controls['fundFieldId'].setValue(0);
        this.updateForm.controls['fundFieldId'].disable();
      }
      else {
        this.updateForm.controls['fundFieldId'].enable();
      }
    }
    else {
      this.updateForm.controls['fundFieldId'].setValue(0);
      this.updateForm.controls['fundFieldId'].disable();
    }
  }
  FilterDeSelectseventupdatesof($event) {
    this.updatesofFilter = "";
    if ($event.id != "" && $event.id != null) {
      this.updatesofFilter = this.updatesofFilter.replace($event.id, "");
    }
    this.updatesofFilter = this.updatesofFilter.replace(/,\s*$/, "");
    this.updatesofFilter = this.updatesofFilter.replace(",,", ",");
    if (this.updatesofFilter == "") { this.updatesofFilter = "null" }

    if (this.updatesofFilter.charAt(0) == ",") {
      this.updatesofFilter = this.updatesofFilter.slice(1)
    }
    if (this.updatesofFilter.split(',').length == 1) {
      let currentfund = this.listFund.filter((item) => item.matrixFundId == +this.updatesofFilter);
      if (currentfund[0].assementCatId == 7 || currentfund[0].assementCatId == 1) {
        this.updateForm.controls['fundFieldId'].setValue(0);
        this.updateForm.controls['fundFieldId'].disable();
      }
      else {
        this.updateForm.controls['fundFieldId'].enable();
      }
    }
    else {
      this.updateForm.controls['fundFieldId'].setValue(0);
      this.updateForm.controls['fundFieldId'].disable();
    }
  }

  FilterDeSelectAllseventupdatesof($event) {
    this.updatesofFilter = '';
  }

  GetClientCompany() {
    this.clientCompanyList = [];
    this.updatesService.GetClientCompany(this.tokenKey, this.productId, this.systemUserCondition).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.clientCompanyList = data['clientCompanay'];

          if (this.clientCompanyList != null) {
            if (this.clientCompanyList.length > 0) {
              for (let iCounter = 0; iCounter < this.clientCompanyList.length; iCounter++) {
                this.dropdownListclientCompany.push(
                  { "id": this.clientCompanyList[iCounter].companyId, "itemName": this.clientCompanyList[iCounter].companyName }
                );
              }
            }
          }

          this.dropdownSettingclientCompany = {
            singleSelection: true,
            text: "Select Company",
            enableSearchFilter: true,
            classes: "borderLessTextBoxGreen",
            badgeShowLimit: 2,
            noDataLabel: 'No data available for selected company',
            showCheckbox: false
          };
        }
      },
      error => {
        this.statusCode = error;
      }
    );
  }

  isNumberKey(evt) {

    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  setEnableDisable($event) {
    this.isEnableDisable = $event;
  }

  // Company   
  onSelectedCompany($event) {
    this.listOfCompany = [];
    let companyId = $event.id;
    this.selectedItemsclientCompanyUser = [];
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
        this.listOfCompany.push($event.id);
      }
      else {
        this.listOfCompany.push($event.id);
      }
    }
    this.GetCompanyUser(companyId);
  }

  onDeSelectedCompany($event) {
    if (this.listOfCompany != null) {
      if (this.listOfCompany.length > 0) {
        this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event.id)
      }
    }
  }

  onSelectAllCompany($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCompany != null) {
        if (this.listOfCompany.length > 0) {
          this.listOfCompany = this.removeElemetninArray(this.listOfCompany, $event[iCounter].id)
          this.listOfCompany.push($event[iCounter].id);
        }
        else {
          this.listOfCompany.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllCompany($event) {
    this.dropdownListclientCompanyUser = [];
    this.selectedItemsclientCompanyUser = [];
    this.listOfUsers = [];
    this.listOfCompany = [];
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  // Users
  // Users 
  onSelectedUsers($event) {

    if (this.listOfUsers != null) {
      if (this.listOfUsers.length > 0) {
        this.listOfUsers = this.removeElemetninArray(this.listOfUsers, $event.id)
        this.listOfUsers.push($event.id);
      }
      else {
        this.listOfUsers.push($event.id);
      }
    }
  }

  onDeSelectedUsers($event) {
    if (this.listOfUsers != null) {
      if (this.listOfUsers.length > 0) {
        this.listOfUsers = this.removeElemetninArray(this.listOfUsers, $event.id)
      }
    }
  }

  onSelectAllUsers($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfUsers != null) {
        if (this.listOfUsers.length > 0) {
          this.listOfUsers = this.removeElemetninArray(this.listOfUsers, $event[iCounter].id)
          this.listOfUsers.push($event[iCounter].id);
        }
        else {
          this.listOfUsers.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllUsers($event) {
    this.listOfUsers = [];
  }

  GetCompanyUser(companyId: any) {
    this.dropdownListclientCompanyUser = [];
    this.userServices.GetUsersFindByCompanyId(this.tokenKey, companyId, this.productId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          if (data['companyUsers'] != null) {
            if (data['companyUsers'].length > 0) {
              this.userModel = data['companyUsers'];

              for (let iCounter = 0; iCounter < this.userModel.length; iCounter++) {
                if(this.userModel[iCounter].adminUserId != -1) {
                  this.dropdownListclientCompanyUser.push(
                    { "id": this.userModel[iCounter].userId, "itemName": this.userModel[iCounter].userName }
                  );
                }
              }

              this.dropdownSettingclientCompanyUser = {
                singleSelection: false,
                text: "Select User",
                enableSearchFilter: true,
                classes: "borderLessTextBoxGreen",
                badgeShowLimit: 2,
                noDataLabel: 'No data available for selected user',
                showCheckbox: true
              };

            }
          }
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  DisplayThisUpdateonActionItems($event) {
    this.isThisUpdateRequiresActionToPerform = $event.target.checked;
    if (!$event.target.checked) {
      this.isUpdateRequiredValues = $event.target.checked;
      this.isEnableDisable = $event.target.checked;
    }
    this.actionChargesValues = 0;
    this.actionNotesValues = '';
  }

  setSendEmailNotificationChecked($event) {
    this.isSendEmailNotificationChecked = $event.target.checked;
  }

}
