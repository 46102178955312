import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SiteLayoutComponent } from 'src/app/Layout/site-layout/site-layout.component';
import { ChangePasswordModel } from 'src/Models/ChangePasswordModel';
import { FilterModel } from 'src/Models/filterModel';
import { UserModel } from 'src/Models/usersModel';
import { AdminByPassClientLoginService } from 'src/Services/adminByPassClientLogin.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.css']
})
export class CreatePasswordComponent implements OnInit {

  userModel = new UserModel();
  appModel: any;
  tokenKey: string = '';
  loginForm: FormGroup;
  filterModel = new FilterModel();
  userId: number = 0;
  changePasswordModel = new ChangePasswordModel();

  constructor(
    private router: Router,
    private checkAuth: CheckAuth,
    private _fb: FormBuilder,
    private toastr: ToastrService,
    private usersService: UsersService
    , private titleService: Title,
    private siteLayoutComponent: SiteLayoutComponent
  ) {
    sessionStorage.removeItem('tokenValue');
    sessionStorage.removeItem('appStartTime');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    sessionStorage.removeItem('isAdminOpen');
    sessionStorage.removeItem('AdminUserId');
    sessionStorage.removeItem('StateSetupManageRedirection');
    this.titleService.setTitle('Create Password | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.loginForm = this._fb.group({
      userid: 0,
      newpassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    })

    this.userId = +sessionStorage.getItem('UserId');
    if (!this.userId) {
      this.router.navigate(['/Login']);
    }
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  updatePassword(loginForm) {

    this.changePasswordModel = loginForm.value;
    if (loginForm.value["newpassword"] == "") {
      this.showMessage('Please enter new password', 'Error', 'error');
      return;
    }
    if (loginForm.value["confirmPassword"] == "") {
      this.showMessage('Please enter confirm password', 'Error', 'error');
      return;
    }

    if (loginForm.value["confirmPassword"] != loginForm.value["newpassword"]) {
      this.showMessage('Confirm password not match', 'Error', 'error');
      return;
    }

    this.usersService.ChangePassword('null', this.changePasswordModel).subscribe(
      data => {
        if (data['status'] == 'Success') {
          if (data['id'] > 0) {
            this.showMessage('Password created successfully', 'Success', 'success');
            this.siteLayoutComponent.Logout();
            this.router.navigate(['/login']);
          }
        }
      },
      error => {

      }
    );
  }
}

