import { Component, OnInit, ViewChild, ViewChildren, ElementRef, HostListener } from '@angular/core';
import { IPRUpdatesModel } from '../../../../Models/IPRUpdatesModel';
import { HistoryModel } from '../../../../Models/historyModel';
import { IprUpdatesService } from '../../../../Services/iprupdatesService';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { AppConfig } from '../../../../Services/app.config';
import { CheckAuth } from '../../../../Services/utility.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.css']
})
export class IPRUpdatesComponent implements OnInit {  
  userID: number = 0;
  totalPageSize: number = 0;
  pageSize: number = 0;
  pageIndex: number = 0;
  orderBy: string = 'null';
  keywordFilter: string = '';
  whereCondition: string = 'null';
  isAscending: boolean;
  statusCode: number = 0;
  appModel: any;
  upDowUpdateCategoryNameCSS: string = '';
  upDowStateCSS: string = '';
  upDowUpdateTypeCSS: string = '';
  upDowCreatedByCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedByCSS: string = '';
  upDowModifiedDateCSS: string = '';
  upDowCarrierLastUpdateDate: string = '';
  isloading: boolean = false;
  ishistoryloading: boolean = false;  
  tokenKey: string = '';
  iprUpdatesModel: IPRUpdatesModel[] = [];
  mergeSuggestionModel: IPRUpdatesModel[] = [];
  mergeCarrierModel: IPRUpdatesModel[] = [];
  historyModel: HistoryModel[] = [];
  isRecords: boolean = false;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  totalRecordsCount: number = -1;
  totalHistoryCount: number = -1;
  deleteupdateId: number = 0;
  SelectedTool: string;
  lastModifiedDetails: string;
  lastSchedularModifiedDetails :string;
  MergeSuggestionCount: number = 0;
  selectedMergeCarrierName: string = '';
  selectedMergeNewCarrierName: string = '';
  selectedMergeCarrierID: number = 0;
  selectedNewCarrierID: number = 0;
  openMenuIndex: number = -1;

  @ViewChild('deletecloseBtn', {static: false}) deletecloseBtn: ElementRef;

  constructor(private iprupdatesService: IprUpdatesService, private toastr: ToastrService
    , private titleService: Title, private checkAuth: CheckAuth
    , private router: Router) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('IPR Updates');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.movetotop();
    this.pageSize = 10;
    this.totalPageSize = 1;
    this.pageIndex = 1;
    this.keywordFilter = 'null';
    this.whereCondition = 'null';    
    this.orderBy = 'CarrierLastUpdateDate DESC';
    this.GetIPRUpdates();
    this.GetCarrierMergeSuggestionList();
  }
  ImportIPRUpdates() {
    this.router.navigate(['/admin/' + this.SelectedTool + '/import-updates']);
  }
  GetIPRUpdates(): void {
    this.isloading = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.iprupdatesService.GetUpdatesFilterByPage(this.tokenKey, this.pageIndex.toString(), this.pageSize.toString(), this.whereCondition, this.orderBy, this.keywordFilter).subscribe(
      data => {
        this.isloading = false;        
        if (data["status"] == "Success") {
          this.iprUpdatesModel = data["updates"];
          if (this.iprUpdatesModel.length > 0) {
            this.isRecords = true;
            this.totalPageSize = parseInt(this.iprUpdatesModel[0].totalRecordCount);
            this.totalRecordsCount = this.iprUpdatesModel.length;
            this.lastSchedularModifiedDetails = 'Last Updated at ' + this.iprUpdatesModel[0].lastModifiedDate +' By ' + this.iprUpdatesModel[0].lastModifiedBy
            this.lastModifiedDetails = 'Last Imported at ' + this.iprUpdatesModel[0].lastImportModifiedDate +' By ' + this.iprUpdatesModel[0].lastImportModifiedBy
            
          }
          else {
            this.totalRecordsCount = 0;
            this.isRecords = false;
          }
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }
  editupdate(carrierId) {
    this.router.navigate(['/admin/' + this.SelectedTool + '/updates/manage/' + carrierId]);
  }
  ActiveInactiveUpdates(updateId: any, isactive: any) {
    this.blockDocument();
    this.iprupdatesService.ActiveInactiveIPRUpdates(this.tokenKey, updateId, isactive)
      .subscribe(data => {
        if (data["status"] == "Success") {
          if (isactive) {
            this.showMessage('Activated successfully', 'Active', 'success');
          }
          else {
            this.showMessage('Inactivated successfully', 'Inactive', 'success');
          }
          this.GetIPRUpdates();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
        this.unblockDocument();
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }
  setDeleteupdateId(carrierId: any) {
    this.deleteupdateId = carrierId;
  }
  getCarrrierUpdateHistory(carrierId: any) {
    this.ishistoryloading = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.iprupdatesService.GetIPRUpdateHistoryByCarrierID(this.tokenKey, carrierId).subscribe(
      data => {        
        if (data["status"] == "Success") {
          this.historyModel = data["updateHistory"];
          if (this.historyModel.length > 0) {           
            this.totalHistoryCount = this.historyModel.length;           
          }
          else {
            this.totalHistoryCount = 0;
          }
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
        this.ishistoryloading = false;
      },
      error => {
        this.statusCode = error;
        this.ishistoryloading = false;
      },
    );
  }
  GetCarrierMergeSuggestionList(): void {    
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.iprupdatesService.GetCarrierMergeSuggestionList(this.tokenKey).subscribe(
      data => {       
        if (data["status"] == "Success") {
          this.mergeSuggestionModel = data["updates"];
          if (this.mergeSuggestionModel.length > 0) {
            this.MergeSuggestionCount = this.mergeSuggestionModel.length;
          }
          else {
            this.MergeSuggestionCount = 0;
          }
        }        
      },
      error => {       
      },
    );
  }
  RemoveUpdateHistory() {
    this.historyModel = [];
  }
  GetCarriersForMerge(carrierID: number, businessName): void {    
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.selectedMergeCarrierName = businessName;
    this.selectedMergeCarrierID = carrierID;
    this.iprupdatesService.GetAllCarriersForMerge(this.tokenKey, carrierID).subscribe(
      data => {        
        if (data["status"] == "Success") {
          this.mergeCarrierModel = data["update"];         
        }        
      },
      error => {        
      },
    );
  }
  assignMergeCarrierChange(event) {  
    this.selectedNewCarrierID = event.target.value;
    this.selectedMergeNewCarrierName = event.target.selectedOptions[0].text;

  }
  MergeCarrierFromSuggetionPopup(oldCarrierId: number, newCarrierId, businessName: string, newBusinessName: string) {    
    this.selectedMergeCarrierID = oldCarrierId;
    this.selectedNewCarrierID = newCarrierId;
    this.selectedMergeCarrierName = businessName;
    this.selectedMergeNewCarrierName = newBusinessName;
    this.ValidateMerge();
  }
  DiscardCarrierFromSuggetionPopup(oldCarrierId: number, newCarrierId, businessName: string) {
    this.selectedMergeCarrierID = oldCarrierId;
    this.selectedNewCarrierID = newCarrierId;
    this.selectedMergeCarrierName = businessName;
    this.ClosePopup('lnkDiscard');    
  }
  DiscardCarrier() {
    this.ClosePopup('btnDiscardModelClose');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.iprupdatesService.DiscardCarrierMerge( this.selectedMergeCarrierID, this.selectedNewCarrierID, this.tokenKey).subscribe(
      data => {
        if (data["status"] == "Success") {
          this.showMessage('Provider discarded successfully', 'Discard', 'success');
          this.GetCarrierMergeSuggestionList();        
        }
        else {
          this.showMessage('There is an issue while discarding provider. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }
  ValidateMerge() {
    if (this.selectedNewCarrierID == 0) {
      this.showMessage('Please select provider', 'Replace', 'error');
    }
    else {
      this.ClosePopup('lnkMerge');
    }
  }
  MergeCarrier() {    
    this.ClosePopup('btnMergeModelClose');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.iprupdatesService.MergeCarrier(this.userID, this.selectedMergeCarrierID, this.selectedNewCarrierID, this.tokenKey).subscribe(
      data => {
        if (data["status"] == "Success") {
          this.showMessage('Provider replaced successfully', 'Replace', 'success');
          this.GetIPRUpdates();
          this.GetCarrierMergeSuggestionList();
          this.ClosePopup('btnClose');
        }
        else {
          this.showMessage('There is an issue while replacing provider. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }
  ClosePopup(controlName: string) {
    let element: HTMLElement = document.getElementById(controlName) as HTMLElement;
    element.click();
  }
  sort(sortBy: string) {
    if (!this.isAscending)
    {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';

      if (sortBy === "BusinessName") {
        this.upDowUpdateCategoryNameCSS = 'fa fa-arrow-down';
        this.upDowStateCSS = '';
        this.upDowUpdateTypeCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
        this.upDowCarrierLastUpdateDate = '';
      }
      else if (sortBy === "CarrierLastUpdateDate") {
        this.upDowUpdateCategoryNameCSS = '';
        this.upDowStateCSS = '';
        this.upDowUpdateTypeCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
        this.upDowCarrierLastUpdateDate = 'fa fa-arrow-down';
      }   
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      if (sortBy === "BusinessName") {
        this.upDowUpdateCategoryNameCSS = 'fa fa-arrow-up';
        this.upDowStateCSS = '';
        this.upDowUpdateTypeCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
        this.upDowCarrierLastUpdateDate = '';
      }
      else if (sortBy === "CarrierLastUpdateDate") {
        this.upDowUpdateCategoryNameCSS = '';
        this.upDowStateCSS = '';
        this.upDowUpdateTypeCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
        this.upDowCarrierLastUpdateDate = 'fa fa-arrow-up';
      }
    }
    this.GetIPRUpdates();
  }
  pageChanged($event) {
    this.pageIndex = parseInt($event.page);
    this.GetIPRUpdates();
  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }
  keywordFilters($event) {
    this.pageIndex = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.keywordFilter = $event.target.value;
    else
      this.keywordFilter = 'null';

    this.GetIPRUpdates();
  }  

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
