export class ActionCalendarSearch {
    userId: number;
    dateFrom: string;
    toDate: string;
    isNeworChanged: boolean = false;
    actionTypeIds: string;
    carrierCatIds: string;
    stateIds: string;
    statusIds: string;
    emailActionId: string;
    emailUpdateId: string;
    emailDeadlineDate: string;
    listOfActionID: string;
    listOfUpdateID: string;
    isFavorite?: boolean = false;
    isMyTracIt?: boolean = false;
}
