import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/Services/app.config';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  templateUrl: './state-master-add-edit.component.html',
  selector: 'app-state-master-add-edit',
  styleUrls: ['./state-master-add-edit.component.css']
})
export class StateMasterAddEditComponent implements OnInit {
  @ViewChild("stateChangeModelPrompt", { static: true }) stateChangeModelPrompt: ElementRef;
  stateMasterForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit:boolean = false;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  moduleList: any[] = [{name: 'All', key: 'Both'}, {name: '911', key: '911'}, {name: 'TRFA', key: 'TRFA'}, {name: 'CRRA', key: 'CRRA'}, {name: 'None', key: 'None'}];
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private state: StateMasterService, private toastr: ToastrService,private modalService: NgbModal) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.isSubmit = false;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.stateMasterForm = this._fb.group({
      stateId: 0,
      shortName: ['', Validators.required],
      selectedModule: ['', Validators.required],
      name: ['',Validators.compose([Validators.required, Validators.pattern(this.pattern)])],
      isActive:[false],
      sortOrder: ['', Validators.required],
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetStateMasterById(params['id']);
        }
      }
    });
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  cancelStateMaster() {
    let navigation = '/admin/' + this.SelectedTool + '/states';
    this.router.navigate([navigation]);
  }

  GetStateMasterById(stateId: number) {
    this.state.GetStateMasterById(this.tokenKey, parseInt(stateId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.stateMasterForm.patchValue({
            stateId: result['state'].stateId,
            shortName: result['state'].shortName,
            name: result['state'].name,
            selectedModule:result['state'].module,
            isActive: result['state'].isActive,
            sortOrder: result['state'].sortOrder,
          });
        }
      });
  }

  save() {
    this.isSubmit = true;
    let data = this.stateMasterForm.value;
    if (!this.stateMasterForm.valid||!data.name.trim())
      return
    let value={
      stateId:data.stateId,
      shortName:data.shortName.trim(),
      name:data.name.trim(),
      createdBy:this.userID,
      module:data.selectedModule,
      isActive:data.isActive,
      note:null,
      sortOrder:data.sortOrder
    }
    data["createdBy"] = this.userID;
    this.state.AddUpdateNewStateMaster(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if(result['message'] !=""){
            if(result['message']=='NotUpdate') document.getElementById("stateChangeModelPrompt").click();
            else this.showMessage(result['message'], 'Warning', 'warning')
          }else{

            if (data.stateId > 0)
              this.showMessage('Jurisdictions updated successfully', 'Success', 'success');
            else
              this.showMessage('Jurisdictions saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/'+this.SelectedTool+'/states']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }
  
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
  updateStateModual(){

  }
  openModel(content){
    this.modalService.open(content, { centered: true });
  }
  UpdateStateWithModule() {
    this.isSubmit = true;
    let data = this.stateMasterForm.value;
    if (!this.stateMasterForm.valid||!data.name.trim())
      return
    let value={
      stateId:data.stateId,
      shortName:data.shortName.trim(),
      name:data.name.trim(),
      createdBy:this.userID,
      module:data.selectedModule,
      isActive:data.isActive,
      sortOrder:data.sortOrder
    }
    data["createdBy"] = this.userID;
    this.state.UpdateStateWithModule(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if(result['message'] !=""){
            this.modalService.dismissAll();
             this.showMessage(result['message'], 'Warning', 'warning')
          }else{
            this.modalService.dismissAll();
            if (data.stateId > 0)
              this.showMessage('Jurisdictions updated successfully', 'Success', 'success');
            else
              this.showMessage('Jurisdictions saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/'+this.SelectedTool+'/states']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
        this.modalService.dismissAll();
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    
    );
  }
}
