import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { TRFAContentHistoryGetModel } from 'src/Models/TRFAContentModel';
import { AuditFieldService } from 'src/Services/audit-field.service';
import { TrfaContentMasterService } from 'src/Services/trfa-content-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-content-history',
  templateUrl: './content-history.component.html',
  styleUrls: ['./content-history.component.css']
})
export class ContentHistoryComponent implements OnInit {

  tokenKey: string = '';
  stateCode: any;
  isloading: boolean = false;
  totalRecordCount: number = -1;
  isAscending: boolean;

  upDownCreatedByCSS: string = '';
  upDownDateCSS: string = '';
  upDownFieldCSS: string = '';
  upDownOldCSS: string = '';
  upDownNewCSS: string = '';

  productId: number;
  OrderBy: string;
  PageNumber: number;
  PageSize: number;
  listofContent: TRFAContentHistoryGetModel[]
  isRecord: boolean = false;
  contentId: number;

  FieldList: any[] = [];
  selectedField = [];
  dropdownSettingsField = {};
  dropdownFieldData = [];
  listOfField = [];
  FieldName: any;
  SelectedTool: string;
  FieldId:any;

  constructor(private toastr: ToastrService, private checkAuth: CheckAuth, private router: Router, private activedRoute: ActivatedRoute, 
    public trfaContentMaster: TrfaContentMasterService, private AuditFieldService: AuditFieldService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.productId = ToolEnum.trfa;
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.PageNumber = 1;
    this.PageSize = 10;
    this.OrderBy = 'createdDate desc';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.dropdownSettingsField = {
      singleSelection: false,
      text: "Filter by Field",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Filed(s) available',
      showCheckbox: true
    };

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.contentId = params['id'];
          this.getField();
          this.GetContenthistoryById();
        }
      }
    });
  }

  getField() {
    this.FieldList = this.AuditFieldService.getFields();
    this.dropdownFieldData = [];
    this.selectedField = [];
    this.FieldList.forEach(x => {
      this.dropdownFieldData.push({ "id": x.id, "itemName": x.name })
    });
  }

  onSelectField($event) {
    this.listOfField = this.removeElemetninArray(this.listOfField, $event.id)
    this.listOfField.push($event.id);
    this.GetContenthistoryById();
  }

  onDeSelectField($event) {
    if (this.listOfField != null) {
      if (this.listOfField.length > 0) {
        this.listOfField = this.removeElemetninArray(this.listOfField, $event.id)
      }
    }
    this.GetContenthistoryById();
  }

  onSelectAllField($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfField != null) {
        if (this.listOfField.length > 0) {
          this.listOfField = this.removeElemetninArray(this.listOfField, $event[iCounter].id)
          this.listOfField.push($event[iCounter].id);
        }
        else {
          this.listOfField.push($event[iCounter].id);
        }
      }
    }
    this.GetContenthistoryById();

  }

  onDeSelectAllField($event) {
    this.listOfField = [];
    this.GetContenthistoryById();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  GetContenthistoryById() {
    this.isloading = true;

    this.FieldId = '';
    if (this.listOfField.length > 0) {
      this.FieldId = this.listOfField.join(',');
    }
    else{
      this.FieldId=null;
    }

    this.trfaContentMaster.GetContenthistoryById(this.tokenKey, this.PageNumber, this.PageSize, this.contentId, this.OrderBy,this.FieldId).subscribe(
      data => {
        this.isloading = false;
        this.listofContent = data['contenthistory'];
        if (this.listofContent != null) {
          if (this.listofContent.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofContent[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }


  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "createdBy") {
        this.upDownCreatedByCSS = 'fa fa-arrow-down';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "createdDate") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = 'fa fa-arrow-down';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "fieldName") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = 'fa fa-arrow-down';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "oldValue") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = 'fa fa-arrow-down';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "newValue") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = 'fa fa-arrow-down';
      }

    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy + ' ASC';

      if (sortBy === "createdBy") {
        this.upDownCreatedByCSS = 'fa fa-arrow-up';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "createdDate") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = 'fa fa-arrow-up';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "fieldName") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = 'fa fa-arrow-up';
        this.upDownOldCSS = '';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "oldValue") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = 'fa fa-arrow-up';
        this.upDownNewCSS = '';
      }
      else if (sortBy === "newValue") {
        this.upDownCreatedByCSS = '';
        this.upDownDateCSS = '';
        this.upDownFieldCSS = '';
        this.upDownOldCSS = '';
        this.upDownNewCSS = 'fa fa-arrow-up';
      }
    }
    this.GetContenthistoryById();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetContenthistoryById();
  }

  BackToContent(){
    let navigation = '/admin/' + this.SelectedTool + '/content';
    this.router.navigate([navigation]);
  }
}
