import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { StateCommissionModel } from 'src/Models/StateCommissionModel';

@Component({
  selector: 'app-front-state-commission',
  templateUrl: './front-state-commission.component.html',
  styleUrls: ['./front-state-commission.component.css']
})
export class FrontStateCommissionComponent implements OnInit {
  tokenKey: string = '';
  loginUser: number = 0;
  listOfState: StateCommissionModel[];

  constructor(private i360Service: I360Service, private titleService: Title, private checkAuth: CheckAuth
    , private appActivity: ApplicationActivityService) { }

  ngOnInit() {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.loginUser = +sessionStorage.getItem('UserId');
    this.titleService.setTitle('State Commission | Inteserra - i360');
    this.GetStateCommissionWebSites();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  GetStateCommissionWebSites() {
    this.i360Service.i360StateCommissionWebSitesForFront(this.tokenKey).subscribe(
      data => {
        if (data != null) {
          this.listOfState = data['listOfData'];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  applicationActivity(actionName, pageName, datatype = 0, actionUrl = '', dataId = 0) {
    ApplicationActivityStore(actionName, pageName, this.appActivity, dataId, datatype, actionUrl)
  }

}
