import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CheckAuth } from '../../../Services/utility.service';
import { UserModel } from '../../../Models/usersModel';
import { UsersService } from '../../../Services/users.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { StateMasterService } from 'src/Services/state-master.service';
import { ToolEnum } from 'src/Models/Common';
import { TabsetComponent } from 'ngx-bootstrap';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  userForm: FormGroup;
  loginuserID: number = 0;
  allstate: boolean = false;
  tokenKey: string = '';
  companyName:string ='';
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  statusCode: number = 0;
  isSubmittedUserDetails = false;
  isBroadband: boolean= false;
  isVoIP: boolean= false;
  isWireless: boolean= false;
  isRegulatory: boolean= false;
  isTax: boolean= false;
  isComplianceUpdates: boolean= false;
  
  _userID: number = 0;
  userModel: UserModel = {
    userId: '',
    userName: '',
    companyId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isActive: '',
    title: '',
    interestProduct: ''
  };
  SelectedTool: string;
  //Department List
  dropdownDepartmentList = [];
  dropdownListTOI=[];
  selectedDepartmentItems = [];
  selectedTOIItems = [];
  dropdownSettingsDepartment = {};
  dropdownSettingsTOI = {};
  listOfDepartmentID = [];
  listOfTOIID = [];

  selectedWirelessCount = 0;
  selectedVoIPCount = 0;
  selectedIPRCount = 0;
  selectedActionCount = 0;
  selectedBriefingCount = 0;

  selectedProducts = [];
  stateSubscription: any;
  isGeneralAlertSubscription: boolean = false;
  @ViewChild('statesTabs', { static: false }) statesTabs: TabsetComponent;
  lockedProduct: any;
  unLockedProduct: any;
  companyId:number;
  PriceQuoteStateList:any[]
isPriceQuoteStateList:boolean=false;
ProductList = '';
listOfProducts:any[];
listOfProducts1:any[];

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private UserService: UsersService,private stateServices: StateMasterService
    , private activedRoute: ActivatedRoute, private toastr: ToastrService, private router: Router, private titleService: Title, private PricingQuotesMaster: PricingQuotesService) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.loginuserID = +sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyId= + sessionStorage.getItem('CompanyId');
    this.companyName =  sessionStorage.getItem('CompanyName');
    this.userForm = this._fb.group({
      userId: 0,
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      dropdownDepartmentList: ['', Validators.required],
      dropdownListTOI: [''],
      title: ['', Validators.required],
      VoIP: false,
      Broadband: false,
      Wireless: false,
      Regulatory: false,
      Tax: false,
      ComplianceUpdates: false,
      strListOfProducts: ['']
    });
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('My Profile');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.dropdownListTOI = [
      { id: 'VoIP', itemName: 'VoIP' },
      { id: 'Broadband', itemName: 'Broadband' },
      { id: 'Tax', itemName: 'Tax' },
      { id: 'Wireless', itemName: 'Wireless' },
      { id: 'Regulatory', itemName: 'Regulatory' },
      { id: 'Compliance Updates & Alerts', itemName: 'Compliance Updates & Alerts' }

    ];
    this.dropdownSettingsTOI = {
      singleSelection: false,
      text: 'Select',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No Topics Of Interest available',
      showCheckbox: true,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsDepartment = {
      singleSelection: false,
      text: 'Select',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No department available',
      showCheckbox: true,
      enableFilterSelectAll: false
    };
    // this.GetPriceQuoteSubscriptionState(this.companyId);
    this.getAllDepartment();
    this.Getuser();
    this.getSubscriptionState();
  }

  get fad() { return this.userForm.controls; }

  Getuser(): void {
    this.blockDocument();
    this.UserService.GetUserFindByUserIDMyProfile(this.tokenKey, this.loginuserID).subscribe(
      data => {
        this.unblockDocument();
        this.userModel = data['userMaster'];
        this.selectedTOIItems=[];
        if (this.userModel[0].interestProduct != null && this.userModel[0].interestProduct != '') {
          var myArray = this.userModel[0].interestProduct.split(',')
        }

        this.userForm.setValue({
          'userId': this.userModel[0].userId,
          'firstname': this.userModel[0].firstName,
          'lastname': this.userModel[0].lastName,
          'email': this.userModel[0].email,
          'phone': this.userModel[0].phone,
          'title': this.userModel[0].title,
          'dropdownDepartmentList': '',
          'dropdownListTOI':'',
          'strListOfProducts': [''],
          'VoIP': this.isVoIP,
          'Broadband': this.isBroadband,
          'Wireless': this.isWireless,
          'Regulatory': this.isRegulatory,
          'Tax': this.isTax,
          'ComplianceUpdates': this.isComplianceUpdates
        });

        this.selectedDepartmentItems = [];
        for (let iCounter = 0; iCounter < data['userMaster'][0].dropdownDepartmentList.length; iCounter++) {
          this.selectedDepartmentItems.push({ 'id': data['userMaster'][0].dropdownDepartmentList[iCounter].departmentId, 'itemName': data['userMaster'][0].dropdownDepartmentList[iCounter].departmentName });
          this.listOfDepartmentID.push(data['userMaster'][0].dropdownDepartmentList[iCounter].departmentId);
        }

        this.selectedTOIItems = [];
        for (let iCounter = 0; iCounter < myArray.length; iCounter++) {
          this.selectedTOIItems.push({ 'id': myArray[iCounter], 'itemName': myArray[iCounter] });
        }

        this.unblockDocument();
      },
      error => {
        this.statusCode = error;
        this.unblockDocument();
      }
    );
  }
  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }
  saveuser(userForm: any) {
    this.isSubmittedUserDetails = true;

    if (this.userForm.invalid || this.selectedDepartmentItems.length == 0) {
      return;
    }
    event.preventDefault();
    if (userForm.value['email'].trim() != '') {
      let result = this.isValidMailFormat(userForm.controls.email);
      if (result != null) {
        this.showMessage('Please provide a valid email', 'Invalid Email', 'error');
        return;
      }
    }
    var selectedDepartmentItems = [];
    if (this.selectedDepartmentItems.length > 0) {
      for (let iCounter = 0; iCounter < this.selectedDepartmentItems.length; iCounter++) {
        selectedDepartmentItems.push({ 'DepartmentId': this.selectedDepartmentItems[iCounter].id,'DepartmentName':this.selectedDepartmentItems[iCounter].itemName});
      }
      userForm.value['dropdownDepartmentList'] = selectedDepartmentItems;
    }
    if (this.selectedTOIItems.length > 0) {
      for (let iCounter = 0; iCounter < this.selectedTOIItems.length; iCounter++) {
        //selectedTOIItems.push({ 'TOIId': this.selectedTOIItems[iCounter].id });
        if(iCounter==0)
        {
          this.ProductList=this.selectedTOIItems[iCounter].id;
        }
        else
        {
        this.ProductList=this.ProductList+","+this.selectedTOIItems[iCounter].id;
        }
      }

    }
    this.blockDocument();
    userForm.value['createdBy'] = this.loginuserID;
    userForm.value['userid'] = this.loginuserID;
    userForm.value['strListOfProducts'] = this.ProductList;

    let msg = '';
    if (this.loginuserID == 0) {
      msg = 'added';
    }
    else {
      msg = 'updated';
    }
    this.UserService.addupdateuserFromFront(this.userForm.value, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        this.showMessage('Profile ' + msg + ' successfully','', 'success');
        this.unblockDocument();
      }
      else {
        this.showMessage(data.message, 'Error', 'error');
        this.unblockDocument();
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  isValidMailFormat(control: FormControl) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (control.value != '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { 'Please provide a valid email': true };
    }
    return null;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  getAllDepartment() {
    this.UserService.GetAllDepartment(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        for (let iCounter = 0; iCounter < data['department'].length; iCounter++) {
          this.dropdownDepartmentList.push(
            { 'id': data['department'][iCounter].departmentId, 'itemName': data['department'][iCounter].departmentName }
          );
        }
      }
    });
  }

  //TOI list
  onSelectedTOI($event) {
    this.listOfTOIID = this.removeElemetninArray(this.listOfTOIID, $event.id)
    this.listOfTOIID.push($event.id);   
  }
  onDeSelectedTOI($event) {
    if (this.listOfTOIID != null) {
      if (this.listOfTOIID.length > 0) {
        this.listOfTOIID = this.removeElemetninArray(this.listOfTOIID, $event.id)
      }
    }  
  }

  onSelectAllTOI($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfTOIID != null) {
        if (this.listOfTOIID.length > 0) {
          this.listOfTOIID = this.removeElemetninArray(this.listOfTOIID, $event[iCounter].id)
          this.listOfTOIID.push($event[iCounter].id);
        }
        else {
          this.listOfTOIID.push($event[iCounter].id);
        }
      }
    }  
  }
  onDeSelectAllTOI($event) {
    this.listOfTOIID = [];
    this.selectedTOIItems = [];  
  }

  //Department List
  onSelectedDepartment($event) {
    this.listOfDepartmentID = this.removeElemetninArray(this.listOfDepartmentID, $event.id)
    this.listOfDepartmentID.push($event.id);
  }
  onDeSelectedDepartment($event) {
    if (this.listOfDepartmentID != null) {
      if (this.listOfDepartmentID.length > 0) {
        this.listOfDepartmentID = this.removeElemetninArray(this.listOfDepartmentID, $event.id)
      }
    }
  }
  onSelectAllDepartment($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfDepartmentID != null) {
        if (this.listOfDepartmentID.length > 0) {
          this.listOfDepartmentID = this.removeElemetninArray(this.listOfDepartmentID, $event[iCounter].id)
          this.listOfDepartmentID.push($event[iCounter].id);
        }
        else {
          this.listOfDepartmentID.push($event[iCounter].id);
        }
      }
    }
  }
  onDeSelectAllDepartment($event) {
    this.listOfDepartmentID = [];
    this.selectedDepartmentItems = [];
  }

  getSubscriptionState() {

    this.stateSubscription = null;
    this.stateServices.i360ManageSubscriptionClientId(this.tokenKey, this.loginuserID, 0, false).subscribe(
      data => {
        this.stateSubscription = {
          wirelessStates: data.wirelessStates,
          wirelessStatesCount: data.wirelessStates ? data.wirelessStates.length : 0,
          iprStates: data.iprStates,
          iprStatesCount: data.iprStates ? data.iprStates.length : 0,
          voIPStates: data.voIPStates,
          voIPStatesCount: data.voIPStates ? data.voIPStates.length : 0,
          actionStates: data.actionStates,
          actionStatesCount: data.actionStates ? data.actionStates.length : 0,
          productAccess: data.productAccess,
          briefingStates: data.briefingStates,
          briefingStatesCount: data.briefingStates ? data.briefingStates.length : 0,
          state911: data.state911,
          state911Count: data.state911 ? data.state911.length : 0,
          carrierCategory: data.carrierCategory,
          carrierCategoryCount: data.carrierCategory ? data.carrierCategory.filter(m => m.isSelected == true).length : 0,
        };


        if (data.productAccess.length > 0) {
          this.lockedProduct = data.productAccess.filter(m => m.isLocked == true && m.productId != 11 && m.productId != 12);
          this.unLockedProduct = data.productAccess.filter(m => m.isLocked == false);
          this.listOfProducts =  this.unLockedProduct.concat(this.lockedProduct);

          for (let iCounter = 0; iCounter < this.listOfProducts.length; iCounter++) {
            if(this.companyName != "Inteserra")
            {
              if(this.listOfProducts[iCounter].productName == "Inteserra Internal - Subscriber Login")
              this.listOfProducts = this.removeElemetninArray(this.listOfProducts,this.listOfProducts[iCounter]) 
              if(this.listOfProducts[iCounter].productName == "Inteserra Resources")
              this.listOfProducts = this.removeElemetninArray(this.listOfProducts,this.listOfProducts[iCounter])  
              if(this.listOfProducts[iCounter].productName == "Inteserra Employee - Customer Login Access")
              this.listOfProducts = this.removeElemetninArray(this.listOfProducts,this.listOfProducts[iCounter])  
                         
            }
          }

          this.listOfProducts.sort((a, b) => {
            var typeA = a.displayName.toLowerCase(),
              typeB = b.displayName.toLowerCase();
            if (typeA < typeB)
              //sort string ascending
              return -1;
            if (typeA > typeB) return 1;
            return 0; //default return value (no sorting)
          });

          let sub = data.productAccess.filter(m => m.productId !== ToolEnum.actionitem && m.productId !== ToolEnum.ipr && m.productId !== ToolEnum.voip && m.productId !== ToolEnum.wireless)
          if (sub.length > 0) {
            this.isGeneralAlertSubscription = true;
          }
          else {
            if (this.statesTabs != null && typeof (this.statesTabs) != 'undefined') {
              this.statesTabs.tabs[0].active = true;
            }
            this.isGeneralAlertSubscription = false;
          }
        }
      },
      error => { console.log(error); }
    );
  }

  GetPriceQuoteSubscriptionState(CompanyId){
    this.PricingQuotesMaster.GetPriceQuoteSubscriptionState(this.tokenKey, CompanyId).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          if (result['stateList'] != null) {
             this.PriceQuoteStateList = result['stateList'];
             if(this.PriceQuoteStateList.length>0)
             {
              this.isPriceQuoteStateList=true;
             }
          }
        }
      })
  }
}