import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CountyMasterService } from 'src/Services/county-master.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-createoredit-county',
  templateUrl: './createoredit-county.component.html',
  styleUrls: ['./createoredit-county.component.css']
})
export class CreateoreditCountyComponent implements OnInit {

  countyMasterForm: FormGroup;
  userID: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  stateList: StateMasterModel[] = [];
  selectedState = [];
  isEdit: boolean = false;

  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};
  listOfDateFilterId: number[] = [];
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  module='911';
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private countyService: CountyMasterService, private stateService: StateMasterService,
    private toastr: ToastrService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    
    this.countyMasterForm = this._fb.group({
      countyId: 0,
      countyName: ['',Validators.compose([Validators.required, Validators.pattern(this.pattern)])],
      stateId: ['', Validators.required],
      isActive:[false],
      sortOrder: ['', Validators.required],
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetCountyById(params['id']);
        }
      }
    });
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey,this.module).subscribe(
      data => {
        this.stateList = data['state'];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownDateFilter.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  GetCountyById(countyId) {
    this.countyService.GetCountyById(this.tokenKey, parseInt(countyId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyMasterForm.patchValue({
            countyId: result['county'].countyId,
            countyName: result['county'].countyName,
            stateId: result['county'].stateId,
            isActive:result['county'].isActive,
            sortOrder:result['county'].sortOrder
          });
          if (this.stateList.length > 0) {
            let data = this.stateList.filter(x => x.stateId == result['county'].stateId)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
          else
            setTimeout(() => {
              let data = this.stateList.filter(x => x.stateId == result['county'].stateId)[0];
              this.selectedState.push({ "id": data.stateId, "itemName": data.name })
            }, 500);
        }
      });
  }

  cancelCounty() {
    let navigation = '/admin/' + this.SelectedTool + '/county';
    this.router.navigate([navigation]);
  }

  save() {
    this.isSubmit = true;
    let data = this.countyMasterForm.value;
    if (!this.countyMasterForm.valid || this.selectedState.length == 0||!data.countyName.trim())
      return
    data["createdBy"] = this.userID;
    let value={
      countyId:data.countyId,
      stateId:data.stateId,
      countyName:data.countyName.trim(),
      createdBy:this.userID,
      isActive:data.isActive,
      sortOrder:data.sortOrder
    }
    this.countyService.CreateOrUpdateCounty(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.countyId > 0)
              this.showMessage('County updated successfully', 'Success', 'success');
            else
              this.showMessage('County saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/county']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  onChangeState(event) {
    this.countyMasterForm.patchValue({
      stateId: event.id
    });
  }

  onDeSelectDateFilterAll($event) {
    this.selectedState= [];
  }

}
