import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CheckAuth } from 'src/Services/utility.service';
import { CountyMasterService } from 'src/Services/county-master.service';
import { CountyMaster911Model } from 'src/Models/CountyMaster911Model';
import { Router } from '@angular/router';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CityMasterService } from 'src/Services/city-master.service';
import { DropDown911Model } from 'src/Models/CityMaster911Model';
import { Main911Service } from 'src/Services/main911.service';
import { Main911Model } from 'src/Models/Main911Model';
import { DatePipe } from '@angular/common';
import { I360Service } from 'src/Services/i360Service';
import { Filter911Model, FilterModel } from 'src/Models/filterModel';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddLocation911Model } from 'src/Models/Location911Model';
import { Location911Service } from 'src/Services/location911.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-rate-main911',
  templateUrl: './rate-main911.component.html',
  styleUrls: ['./rate-main911.component.css']
})
export class RateMain911Component implements OnInit {
  tokenKey: string;
  listofMain911: Main911Model[];
  PageNumber: number = 1;
  PageSize: number = 10;
  isRecord: boolean = false;
  isloading: boolean = false;
  totalRecordCount: number = 0;
  stateId: string = ' ';
  filterText: string = ' ';
  SelectedTool: string;
  stateList: StateMasterModel[] = [];
  countyId: string = ' ';
  countyList: CountyMaster911Model[] = [];
  selectedCounty = [];
  orderBy: string = 'EndDate Desc';
  isAscending: boolean = false;
  upDowNameCSS: string = '';
  upDowScopeNameCSS: string = '';
  upDowRateNameCSS: string = '';
  upDowFeeCSS: string = '';
  upDowEffectiveDateCSS: string = '';
  upDowEndDateCSS: string = '';
  upDowAuditDateCSS: string = '';
  upDowAuditMonthCSS: string = '';
  upDowWhatTaxedCSS: string = '';
  upDowNotesCSS: string = '';
  upDowActiveCSS: string = '';

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  //county
  selectedItemsConuty = [];
  selectedItemsCounty = [];
  dropdownSettingsDateFilter = {};
  listOfCountyIds = [];
  cityId: string = ' ';
  selectedState = [];
  dropdownDateCountyFilter = [];
  selectedCity = [];
  cityList: DropDown911Model[] = [];
  dropdownDateCityFilter = [];
  todayDate: any;
  filterModel = new FilterModel();
  blockedDocument = false;
  isShowprogressSpinner = false;
  dropdownSettingsDateCountyFilter = {};
  dropdownSettingsDateCityFilter = {};
  stateIds: string = ' ';
  countyIds: string = ' ';
  cityIds: string = ' ';
  listOfCityIds = [];
  listofMain911PrintData: any[] = [];
  filter911Model = new Filter911Model();
  module = '911';
  isInActiveRate: boolean;
  dropdownSettingsCountyFilter = {};
  dropdownSettingsCityFilter = {};
  dropdownSettingsEditStateFilter = {};
  isSubmit: boolean = false;
  rateId: number = 0;
  @ViewChild('fileInput', { static: true }) fileInput;
  exportDoc: any;
  duplicateRateId: number = 0;
  deleteRateId = 0;
  openMenuIndex: number = -1;
  dropdownSettingsStatus = {};
  selectedItemsStatus = [];
  listOfStatus = [];
  statusId: string = '';
  moduleList: any[] = [{ itemName: 'Yes', id: '1' }, { itemName: 'No', id: '0' }];

  constructor(private countyService: CountyMasterService, private locationService: Location911Service, private stateService: StateMasterService, private router: Router, private checkAuth: CheckAuth, private cityService: CityMasterService, private main911Service: Main911Service, private datePipe: DatePipe, private i360service: I360Service, private toastr: ToastrService, private modalService: NgbModal,private titleService:Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('911 Rates');
    if (this.router.url == '/admin/911/inactiveRates') this.isInActiveRate = true;
    else this.isInActiveRate = false;

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.todayDate = this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    this.dropdownSettingsDateFilter = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsDateCountyFilter = {
      singleSelection: false,
      text: "Filter by County",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No County(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsDateCityFilter = {
      singleSelection: false,
      text: "Filter by City",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No City(s) available',
      showCheckbox: true
    };
    this.dropdownSettingsEditStateFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsCountyFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No County(s) available',
      showCheckbox: false,
      disabled: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsCityFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No City(s) available',
      showCheckbox: false,
      disabled: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsStatus = {
      singleSelection: true,
      text: "Filter by Active",
      enableSearchFilter: false,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No record available',
      showCheckbox: false
    };
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.MainGetAll();
  }

  keywordFilters($event) {
    if ($event.target.value != "" && $event.target.value != null)
      this.filterText = $event.target.value;
    else
      this.filterText = ' ';
    this.MainGetAll();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.MainGetAll();
  }

  MainGetAll() {
    this.isloading = true;
    this.stateId = " ";
    this.countyId = " ";
    this.countyId = " ";
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.cityId = " ";
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }
    if ((this.selectedState.length > 0 && !this.isInActiveRate) || this.isInActiveRate) {
      this.stateId = this.listOfStateIds.join(',')
      // let isActive = this.isInActiveRate ? false : true;
      
      if (this.isInActiveRate) {
        this.stateId = " ";
        this.countyId = " ";
        this.cityId = " ";
        this.dropdownDateCountyFilter = [];
        this.selectedItemsCounty = [];
        this.listOfCountyIds = [];
        this.selectedState = [];
        this.dropdownDateCityFilter = [];
        this.listOfCityIds = [];
        this.selectedCity = [];
        this.selectedItemsCounty = [];
        this.dropdownDateCityFilter = [];
      }
      this.main911Service.MainGetAll(this.tokenKey, this.PageNumber, this.PageSize, this.stateId, this.countyId, this.cityId, this.orderBy, this.statusId).subscribe(
        data => {
          this.isloading = false;
          this.listofMain911 = data['main'];
          if (this.listofMain911 != null) {
            if (this.listofMain911.length == 0)
              this.isRecord = true;
            else {
              this.isRecord = false;
              this.totalRecordCount = this.listofMain911[0].totalRecordCount;
              this.listofMain911.forEach((x) => {
                if (new Date(this.datePipe.transform(new Date(x.endDate), 'MM-dd-yyyy')) >= new Date(this.todayDate)) x['isActive'] = true;
                else x['isActive'] = false;
              })
            }
          }
          else
            this.isRecord = true;
        },
        error => { }
      );
    }
  }

  setDeleteData(id) {
    this.rateId = id;
  }

  delete911Main(deletedId) {
    this.isloading = true;
    this.main911Service.Delete911Main(this.tokenKey, deletedId).subscribe(
      data => {
        this.MainGetAll();
        this.toastr.success('Rate deleted successfully.', 'Delete')
      },
      error => { }
    );
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  sort(sortBy: string) {
    this.upDowNameCSS = '';
    this.upDowScopeNameCSS = '';
    this.upDowActiveCSS = '';
    this.upDowFeeCSS = '';
    this.upDowEndDateCSS = '';
    this.upDowEffectiveDateCSS = '';
    this.upDowWhatTaxedCSS = '';
    this.upDowNotesCSS = '';
    this.upDowRateNameCSS = '';
    this.upDowAuditDateCSS = '';
    this.upDowAuditMonthCSS = '';
    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';

      if (sortBy === "TaxName") {
        this.upDowNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "ScopeID") {
        this.upDowScopeNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "Rate") {
        this.upDowRateNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "Fee") {
        this.upDowFeeCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "EndDate") {
        this.upDowActiveCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowEffectiveDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "EndDate") {
        this.upDowEndDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "WhatTaxed") {
        this.upDowWhatTaxedCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "Notes") {
        this.upDowNotesCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "AuditDate") {
        this.upDowAuditDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "AuditMonth") {
        this.upDowAuditMonthCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      if (sortBy === "TaxName") {
        this.upDowNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "ScopeID") {
        this.upDowScopeNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "Rate") {
        this.upDowRateNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "Fee") {
        this.upDowFeeCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "EndDate") {
        this.upDowActiveCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "EffectiveDate") {
        this.upDowEffectiveDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "EndDate") {
        this.upDowEndDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "WhatTaxed") {
        this.upDowWhatTaxedCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "Notes") {
        this.upDowNotesCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "AuditDate") {
        this.upDowAuditDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "AuditMonth") {
        this.upDowAuditMonthCSS = 'fa fa-arrow-up';
      }
    }
    this.MainGetAll();
  }

  addRate() {
    let navigation = '/admin/' + this.SelectedTool + '/rates/manage';
    this.router.navigate([navigation]);
  }

  updateRate(rateID) {
    window.open('/#/admin/' + this.SelectedTool + '/rates/manage/' + rateID,"_blank");
  }

  Export911Main() {
    this.isloading = true;
    this.blockedDocument = true;
    this.isShowprogressSpinner = true;
    this.stateId = ' ';
    this.countyId = ' ';
    if (this.selectedState.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.cityId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }
    this.main911Service.Export911Main(this.tokenKey, this.stateId, this.countyId, this.cityId, this.orderBy,this.statusId).subscribe(
      data => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.isloading = false;
        if (data != null) {

          if (data.status == 'Success') {
            this.filterModel.docPath = data.responseNewFilename;
            this.filterModel.docType = '911Main Exports';
            this.i360service.DownloadDocument(this.tokenKey, this.filterModel).subscribe(
              fileData => {

                if (fileData != null) {
                  FileSaver.saveAs(fileData, '911MainExports.csv');
                }
              }, error => {
                console.log(error);
              }
            );
          }
          else {
            this.toastr.error(data.status, 'Error');
          }
        }
      },
      error => {
        this.blockedDocument = false;
        this.isShowprogressSpinner = false;
        this.isloading = false;
      }
    );
  }




  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  onSelectState($event) {

    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.stateId = ' ';
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetCountyByStateId(this.stateId);
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onDeSelectState($event) {

    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    if (this.listOfStateIds.length == 0) {
      this.dropdownDateCountyFilter = [];
      this.selectedItemsCounty = [];
      this.listOfCountyIds = [];
      this.selectedCity = [];
      this.dropdownDateCityFilter = [];
      this.listOfCityIds = [];
      this.selectedCity = [];
      this.dropdownDateCityFilter = [];
    }
    else
      this.GetCountyByStateId(this.stateId);
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetCountyByStateId(this.stateId);
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.listOfCountyIds = [];
    this.selectedItemsCounty = [];
    this.dropdownDateCountyFilter = [];
    this.listOfCityIds = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.selectedState = []
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onSelectCounty($event) {

    this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
    this.listOfCountyIds.push($event.id);
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onDeSelectCounty($event) {

    if (this.listOfCountyIds != null) {
      if (this.listOfCountyIds.length > 0) {
        this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    if (this.listOfCountyIds.length == 0) {
      this.listOfCityIds = [];
      this.selectedCity = [];
      this.dropdownDateCityFilter = [];
    }
    else
      this.GetCityByCountyId(this.countyId);
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onSelectAllCounty($event) {

    this.isShowprogressSpinner = true;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onDeSelectAllCounty($event) {
    this.isShowprogressSpinner = true;
    this.listOfCountyIds = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
    else this.selectedItemsConuty = [];
    setTimeout(() => {
      this.isShowprogressSpinner = false;
    }, 2000);
  }


  onSelectCity($event) {
    this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
    this.listOfCityIds.push($event.id);
    this.cityId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onDeSelectCity($event) {

    if (this.listOfCityIds != null) {
      if (this.listOfCityIds.length > 0) {
        this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
      }
    }
    if (this.listOfCityIds.length > 0) {
      this.cityId = this.listOfCityIds.join(',')
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
    else this.selectedCity = [];
  }

  onSelectAllCity($event) {

    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
          this.listOfCityIds.push($event[iCounter].id);
        }
        else {
          this.listOfCityIds.push($event[iCounter].id);
        }
      }
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onDeSelectAllCity($event) {
    this.listOfCityIds = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
    else this.selectedCity = [];
  }

  onChangeState(event) {
    this.dropdownDateCityFilter = [];
    this.dropdownDateCountyFilter = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
    if (event.id) this.GetCountyByStateId(event.id)
  }

  onDeSelectDateFilterAll($event) {
    this.selectedState = [];
    this.selectedCounty = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.dropdownDateCountyFilter = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onDeSelectDateFilterCountyAll($event) {
    this.selectedCounty = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onChangeCounty(event) {
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
    if (event.id) this.GetCityByCountyId(event.id)
  }
  onChangeCity(event) {
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }
  onDeSelectDateFilterCityAll($event) {
    this.PageNumber = 1;
    this.PageSize = 10;
    this.selectedCity = [];
    if (!this.isInActiveRate) this.MainGetAll();
  }
  GetCountyByStateId(stateId) {

    this.countyService.GetCountyByStateId(this.tokenKey, stateId,true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyList = result['county'];
          this.dropdownDateCountyFilter = [];
          if (this.countyList.length > 0) {
            let b = [];
            this.selectedItemsCounty = [];
            this.countyList.forEach(x => {
              let a = this.listOfCountyIds.filter(e => e == x.countyId);
              if (a.length > 0) {
                b.push(x.countyId);
                this.selectedItemsCounty.push({ "id": x.countyId, "itemName": x.countyName })
              }
              this.dropdownDateCountyFilter.push({ "id": x.countyId, "itemName": x.countyName })
            });
            this.listOfCountyIds = b;
            if (this.listOfCountyIds.length > 0) {
              this.countyId = this.listOfCountyIds.join(',')
              this.GetCityByCountyId(this.countyId)
            } else {
              this.listOfCityIds = [];
              this.selectedCity = [];
              this.dropdownDateCityFilter = [];
            }
          }
          else {
            this.selectedItemsCounty = [];
            this.listOfCountyIds = [];
          }
        }
      });
  }
  GetCityByCountyId(countyId) {
    this.cityService.GetCityByCountyIdMultiple(this.tokenKey, countyId,true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.cityList = result['city'];
          this.dropdownDateCityFilter = [];
          this.dropdownDateCityFilter = this.cityList
          this.selectedCity = [];
          if (this.cityList.length > 0) {
            let b = [];
            this.selectedCity = [];
            this.listOfCityIds.forEach(x => {
              let a = this.cityList.filter(e => e.id == x);
              if (a.length > 0) {
                b.push(x);
                this.selectedCity.push(a[0])
              }
            });
            this.listOfCityIds = b;
          }
          else {
            this.selectedCity = [];
            this.listOfCityIds = [];
          }
        }
        this.isShowprogressSpinner = false;
        if (this.listOfCountyIds.length == 0) {
          this.listOfCountyIds = [];
          this.selectedCity = [];
          this.dropdownDateCityFilter = [];
        }
      });
  }



  openModel(content, rateID) {
    this.rateId = rateID;
    this.modalService.open(content, { centered: true });
  }




  saveLocation() {
    this.isSubmit = true;
    if (this.selectedState.length == 0 || this.selectedItemsCounty.length == 0 || this.selectedCity.length == 0 || this.rateId == 0)
      return
    let location: AddLocation911Model = {
      rateID: this.rateId,
      stateId: this.selectedState[0].id,
      countyId: this.selectedItemsCounty[0].id,
      cityId: this.selectedCity[0].id,
    }
    this.locationService.CreateLocationByRateId(this.tokenKey, location).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            if (result['message'] == "Invalid") this.showMessage('Invalid Data', 'Error', 'error')
            else
              this.showMessage(result['message'], 'Warning', 'warning');
          }
          else {
            this.showMessage('Location Saved Successfully', 'Success', 'success');
            this.modalService.dismissAll();
            this.isSubmit = false;
            this.MainGetAll();
            this.selectedCounty = [];
            this.selectedItemsConuty = [];
            this.selectedState = [];
            this.selectedCity = [];
            this.dropdownDateCountyFilter = [];
            this.dropdownDateCityFilter = [];
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }

      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );

    this.isSubmit = false;

  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  openModelExcel(content) {
    this.modalService.open(content, { centered: true });
  }
  uploadFile() {
    let formData = new FormData();
    formData.append('uploadFile', this.exportDoc)
    this.main911Service.UploadExcel(this.tokenKey, formData).subscribe(result => {
    })
  }
  fileEvent(event): any {
    this.exportDoc = null;
    //*-- this function gets content of uploaded file and validation -- * /    
    var file = event.target.files[0];
    this.exportDoc = file;
  }

  contactRate(rateID) {
    window.open('/#/admin/' + this.SelectedTool + '/rates/contact/' + rateID,"_blank");
  }

  setDuplicateId(duplicateRateId) {
    this.duplicateRateId = duplicateRateId;
  }

  duplicateRateData(rateID) {
    sessionStorage.setItem('isDuplicate', 'true');
    window.open('/#/admin/' + this.SelectedTool + '/rates/manage/' + rateID,"_blank");
  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
    }
    this.GetCountyByStateId(this.stateId);
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfStateIds.length > 0) {
      this.stateId = this.listOfStateIds.join(',')
      this.GetCountyByStateId(this.stateId);
    }
    else{
      this.dropdownDateCountyFilter=[];
      this.dropdownDateCityFilter=[];
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onFilterSelectAllCounty($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countyId);
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onFilterDeSelectAllCounty($event){

    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
        }
      }
    }
    this.countyId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countyId = this.listOfCountyIds.join(',');
      this.GetCityByCountyId(this.countyId);
    }
    else{
      this.dropdownDateCityFilter=[];
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onFilterSelectAllCity($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
          this.listOfCityIds.push($event[iCounter].id);
        }
        else {
          this.listOfCityIds.push($event[iCounter].id);
        }
      }
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  onFilterDeSelectAllCity($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
        }
      }
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    if (!this.isInActiveRate) this.MainGetAll();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

  onSelectStatus($event) {
    this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
    this.listOfStatus.push($event.id);
    this.statusId = '';
    if (this.listOfStatus.length > 0) {
      this.statusId = $event.id;
    }
    this.MainGetAll();
    this.PageNumber = 1;
  }

  onDeSelectStatus($event) {
    if (this.listOfStatus != null) {
      if (this.listOfStatus.length > 0) {
        this.listOfStatus = this.removeElemetninArray(this.listOfStatus, $event.id)
      }
    }
    this.statusId='';
    if (this.listOfStatus.length > 0) {
      this.statusId = $event.id;
    }
    this.MainGetAll();
    this.PageNumber = 1;
  }

 onDeSelectAllStatus($event) {
    this.statusId='';
    this.listOfStatus = [];
    this.MainGetAll();
    this.PageNumber = 1;
  }

}
