export class WebinarLibraryModel {
    webinarLibraryId?: any
    externalURL?: any
    displayDocName: string;
    docPath: string
    title?: any
    isActive?: any
    createdDate?: any
    createdBy?: any
    modifiedDate?: any
    modifiedBy?: any
    totalRecordCount?: any
    isAllowallAccess?: any
    isSelected?: any
    productId?: any
    isLocked?: any
    isEmbed?: any
    linktype?:any
    sortOrder?:any;
    sectionName?:any
    sectionId?: any
    webinarSectionDetails?:any

}

export class WebinarSectionsModel{
    
    sectionId?: any
    sectionName?: any
    sortOrder?: any 
    isActive?: any 
    createdDate?: any 
    createdBy?: any 
    updatedDate?: any
    updatedBy?: any 
    totalRecordCount?: any

}