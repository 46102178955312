import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterTRSModel } from 'src/Models/TRSNoticeModel';
import { ManageTRSModel } from 'src/Models/ManageTRSModel';

@Injectable({
  providedIn: 'root'
})
export class TrsMasterService {

  AppUrl: string = "";
  filterTRSModel = new FilterTRSModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  TRSNoticeAddUpdate(TokenKey: string, TRSNoticeModel: any): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRSNoticeController/TRSNoticeAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, TRSNoticeModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteTRSNotice(TokenKey: string, TRSId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRSNoticeController/DeleteTRSNotice/' + TRSId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllTRSNotice(TokenKey: string, PageNumber: number, PageSize: number, stateId: string, orderBy: string): Observable<any> {
    this.filterTRSModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      stateID: stateId,
      orderBy: orderBy      
    }
    this.setAPIUrl();
    let data = 'api/TRSNoticeController/GetAllTRSNotice';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data,this.filterTRSModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllTRSNoticeFront(TokenKey: string, PageNumber: number, PageSize: number, stateId: string, orderBy: string,userId:number,displayFavorite :number): Observable<any> {
    this.filterTRSModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      stateID: stateId,
      orderBy: orderBy,
      userID:userId,
      isFavorite:displayFavorite      
    }
    this.setAPIUrl();
    let data = 'api/TRSNoticeController/GetAllTRSNoticeFront';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data,this.filterTRSModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetTRSNoticeById(TokenKey: string, TRSId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/TRSNoticeController/GetTRSNoticeById/' + TRSId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetManageTRSDetails(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRSNoticeController/GetManageTRSDetails';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(URL, httpOptions)
      .pipe(catchError(this.handleError));
  }

  SaveManageTRSDetails(TokenKey: string, postModel:ManageTRSModel): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/TRSNoticeController/SaveManageTRSDetails';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, postModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
