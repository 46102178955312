import { Component, HostListener, OnInit } from '@angular/core';
import { CheckAuth } from 'src/Services/utility.service';
import { CountyMasterService } from 'src/Services/county-master.service';
import { CountyMaster911Model } from 'src/Models/CountyMaster911Model';
import { Router } from '@angular/router';
import { StateMasterService } from 'src/Services/state-master.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-county',
  templateUrl: './county.component.html',
  styleUrls: ['./county.component.css']
})
export class CountyComponent implements OnInit {

  tokenKey: string;
  listofCounty: CountyMaster911Model[];
  PageNumber: number = 1;
  PageSize: number = 10;
  isRecord: boolean = false;
  isloading: boolean = false;
  totalRecordCount: number = 0;
  stateId: string = ' ';
  filterText: string = ' ';
  SelectedTool: string;
  stateList: StateMasterModel[] = [];
  orderBy: string = 'SortOrder';
  isAscending: boolean = false;
  upDowNameCSS: string = '';
  upDowShortNameCSS: string = '';
  upDowActiveCSS: string = '';
  upDowSortCSS: string = '';

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  module = '911';
  countyId: number = 0;
  openMenuIndex: number = -1;

  constructor(private countyService: CountyMasterService, private stateService: StateMasterService,
    private router: Router, private checkAuth: CheckAuth, private toastr: ToastrService,private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('County');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.GetAllCounty();
    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };
  }

  keywordFilters($event) {
    if ($event.target.value != "" && $event.target.value != null)
      this.filterText = $event.target.value;
    else
      this.filterText = ' ';
    this.GetAllCounty();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetAllCounty();
  }

  GetAllCounty() {
    this.isloading = true;
    let state = ' ';
    if (this.listOfStateIds.length > 0) {
      state = this.listOfStateIds.join(',')
    }
    this.countyService.GetAllCounty(this.tokenKey, this.PageNumber, this.PageSize, this.filterText, state, this.orderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofCounty = data['county'];
        if (this.listofCounty != null) {
          if (this.listofCounty.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofCounty[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { }
    );
  }

  addCounty() {
    let navigation = '/admin/' + this.SelectedTool + '/county/manage';
    this.router.navigate([navigation]);
  }

  updateCounty(countyId) {
    let navigation = '/admin/' + this.SelectedTool + '/county/manage/' + countyId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.countyId = id;
  }

  deleteCounty(countyId) {
    this.isloading = true;
    this.countyService.DeleteCounty(this.tokenKey, countyId).subscribe(
      data => {
        this.GetAllCounty();
        if (data['message'] != "")
          this.showMessage(data['message'], 'Warning', 'warning')
        else
          this.toastr.success('County deleted successfully.', 'Delete')
      },
      error => { }
    );
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCounty();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCounty();
  }

  onSelectAll($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCounty();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCounty();
  }

  clear() {
    this.stateId = ' ';
    this.filterText = " ";
    this.listOfStateIds = [];
    this.selectedItemsState = [];
    this.GetAllCounty();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';

      if (sortBy === "CountyName") {
        this.upDowNameCSS = 'fa fa-arrow-down';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "StateId") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = 'fa fa-arrow-down';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "sortOrder") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      if (sortBy === "Name") {
        this.upDowNameCSS = 'fa fa-arrow-up';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "ShortName") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = 'fa fa-arrow-up';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "sortOrder") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS = 'fa fa-arrow-up';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-up';
      }
    }
    this.GetAllCounty();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  onFilterSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCounty();
  }

  onFilterDeSelectAllState($event){
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    this.PageNumber = 1;
    this.PageSize = 10;
    this.GetAllCounty();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
