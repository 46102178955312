
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EmailLoginService {

    AppUrl: string = "";
    constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.setAPIUrl();
    }

    setAPIUrl() {
        if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
            this.AppUrl = sessionStorage.getItem("BaseURL") || '';
        }
    }

    DecryptEmailKey(filterModel: any): Observable<any> {
        this.setAPIUrl();
        let url = this.AppUrl + 'api/EmailLogin/DecryptEmailKey';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this._http.post<any>(url, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    isValidBriefingEmailKey(filterModel: any): Observable<any> {
        this.setAPIUrl();
        let url = this.AppUrl + 'api/EmailLogin/IsValidBriefingEmailKey';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this._http.post<any>(url, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    private handleError(error: Response | any) {
        console.error(error.message || error);
        return observableThrowError(error.status)
    }

    isValidPriceQuoteEmailKey(filterModel: any): Observable<any> {
        this.setAPIUrl();
        let url = this.AppUrl + 'api/EmailLogin/IsValidPriceQuoteEmailKey';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this._http.post<any>(url, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    isValidOnboardingQuoteEmailKey(filterModel: any): Observable<any> {
        this.setAPIUrl();
        let url = this.AppUrl + 'api/EmailLogin/IsValidOnboardingQuoteEmailKey';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this._http.post<any>(url, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }
}
