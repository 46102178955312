import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Trfa911FTPUploadHistoryModel } from 'src/Models/TRFA_911_FTPUploadHistoryModel';
import { Main911Service } from 'src/Services/main911.service';
import * as FileSaver from 'file-saver';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-downloadfiles',
  templateUrl: './downloadfiles.component.html',
  styleUrls: ['./downloadfiles.component.css']
})
export class DownloadfilesComponent implements OnInit {

  quaryValues:string='';
  ListHistoryModel:Trfa911FTPUploadHistoryModel[];
  isloading:boolean=true;
  isShowprogressSpinner: boolean = false;
  blockedDocument: boolean = false;
  DownloadLink:string;

  constructor(private activedRoute: ActivatedRoute,
    private router: Router,
    private main911Service: Main911Service,
    private appActivity: ApplicationActivityService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {

        if (params['id'] != null) {

          this.quaryValues = params['id'];
          
          this.GetTRFA911FTPUploadHistory();
        }
        else {
          this.router.navigate(['login/' + this.quaryValues]);
        }
      }
      else {
        this.router.navigate(['login/' + this.quaryValues]);
      }
    });
  }

  GetTRFA911FTPUploadHistory()
  {
    this.blockDocument();
    this.main911Service.GetTRFA911FTPUploadHistory(this.quaryValues).subscribe(
      data => {
        this.isloading = false;
        this.ListHistoryModel = data['main'];
        if(data.status == 'Failure' && this.ListHistoryModel.length>0)
        {
          this.showMessage('Link was expired', 'Warning', 'warning');
          this.router.navigate(['/Access/Denied/download files']);
        }
        else
        {
          if(this.ListHistoryModel.length>0)
          {
          this.blockedDocument = false;
          sessionStorage.setItem('trfa911FileDownloadUId', this.ListHistoryModel[0].userId.toString());
          if (data.status == 'Success' && (typeof (localStorage.getItem('UserName')) != 'undefined' && localStorage.getItem('UserName') != null && localStorage.getItem('UserName') != '')) {
            if(this.ListHistoryModel[0].userId.toString()===localStorage.getItem('UserId'))
            this.downloadFiles(this.ListHistoryModel[0].link);
            else
            this.router.navigate(['/Access/Denied/download files']);
          }
          else if (data.status == 'Success')
            this.router.navigate(['login/' + this.quaryValues]);
          else
            this.router.navigate(['login']);
        }
        else{
          this.blockedDocument = false;
          this.showMessage('Data Not Found', 'Warning', 'warning');
          this.router.navigate(['/Access/Denied/download files']);
          this.unblockDocument();
        }
        }
      },
      error => {
       this.blockedDocument = false;
        console.log(error);
        this.router.navigate(['login']);
        this.unblockDocument();
      }
     );
  }

  buttonclick()
  {
    this.blockDocument();
    this.downloadFiles(this.quaryValues+".zip");
  }

  downloadFiles(filename)
  {
    this.isloading = true;
    this.main911Service.DownloadS3Files(filename).subscribe(
      (res) => {
    FileSaver.saveAs(res.url, filename);
    ApplicationActivityStore('Download', '911 Rates File download ' + filename, this.appActivity, ToolEnum.state911, ActionActivity.State911, '/View/downloadfiles');
    sessionStorage.setItem('fileloading', 'false');
    this.main911Service.DeleteZipFile(filename).subscribe(
      data => {
        this.unblockDocument();
        this.showMessage('File Download Successfully', 'Success', 'success');
      },
    );
  });
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }
}
