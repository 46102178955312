import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseRateType } from 'src/Models/Common';
import { UpdatedBaseRateModel } from 'src/Models/UpdatedBaseRateModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompleted, getIncompletedStep } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-admin-manage-baserate',
  templateUrl: './admin-manage-baserate.component.html',
  styleUrls: ['./admin-manage-baserate.component.css']
})
export class AdminManageBaserateComponent implements OnInit {
  stateCode: any;
  tokenKey: string;
  listofUpdatedBaseRate: UpdatedBaseRateModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  companyName: string;


  upDownClientNameCSS: string = '';
  upDownProposelDateCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  clientInformationId: number = 0;
  priceQuoteId:number=0;
  userID: any;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;

  isRateDetailsCompleted: boolean;
  isQuoteDetailsCompleted: boolean;
  isAddOnServicesCompleted: boolean;
  isComplianceDetailsCompleted: boolean;
  isProposalDetailsCompleted: boolean;
  currentActiveTab: number = 1;

  url: string;
  tabId: number = 0;

  mainUrl: string;
  subUrl: string;
  quoteRejectReason:string;

  constructor(private router: Router, private PricingQuotesMaster: PricingQuotesService, private checkAuth: CheckAuth
    , private titleService: Title, private activedRoute: ActivatedRoute, private toastr: ToastrService, private globle: Globle) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofUpdatedBaseRate = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'baserateId desc';
    // this.CompanyName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    // this.titleService.setTitle('Price Quote');
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Price Quote - '+this.companyName);

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          // this.clientInformationId = params['id'];
          this.priceQuoteId= params['id'];
          this.GetClientInformationById(this.priceQuoteId);
        }
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.priceQuoteId != null) {
      this.GetProgressStepDetailsById(this.priceQuoteId);
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isRateDetailsCompleted = null
      this.globle.cApp.statusModel.isQuoteDetailsCompleted = null
      this.globle.cApp.statusModel.isAddOnServicesCompleted = null
      this.globle.cApp.statusModel.isComplianceDetailsCompleted = null
      this.globle.cApp.statusModel.isProposalDetailsCompleted = null
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
    
  }

  ngOnInit() {
    this.isloading = true;
    this.GetUpdatedBaseRate();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetUpdatedBaseRate();
  }

  GetUpdatedBaseRate() {
    this.blockDocument();
    this.PricingQuotesMaster.GetUpdatedBaseRate(this.tokenKey, this.priceQuoteId, BaseRateType.BaseRate, this.OrderBy).subscribe(
      data => {
        this.unblockDocument();
        this.isloading = false;
        this.listofUpdatedBaseRate = data['updatedBaseRate'];
        if (this.listofUpdatedBaseRate != null) {
          if (this.listofUpdatedBaseRate.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
          }
        }
        else
          this.isRecord = true;
      },
      error => {
        this.unblockDocument();
        this.stateCode = error
      }
    );

  }

  GetClientInformationById(priceQuoteId: number) {
    this.blockDocument();

    this.PricingQuotesMaster.GetClientInformationById(this.tokenKey, priceQuoteId,0, false).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.quoteRejectReason=result['clientInformation'].quoteRejectReason;
        }
      });
  }


  save(clientForm: any) {
    this.blockDocument();

    let data = clientForm.value;
    if (!clientForm.invalid) {
      this.globle.cApp.statusModel.isRateDetailsCompleted = false;
      // sessionStorage.setItem('isAddCompQueCompleted', 'false');
    }

    for (let iCounter = 0; iCounter < this.listofUpdatedBaseRate.length; iCounter++) {
      this.listofUpdatedBaseRate[iCounter].createdBy = this.userID;
      this.listofUpdatedBaseRate[iCounter].priceQuoteId = this.priceQuoteId;
    }

    this.PricingQuotesMaster.UpdatedBaseRateAddUpdate(this.listofUpdatedBaseRate, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        this.unblockDocument();
        this.globle.cApp.statusModel.isRateDetailsCompleted = true;
        this.router.navigate(['/admin/' + this.SelectedTool + '/pricing-quotes/' + this.priceQuoteId]);
      }
      else {
        this.showMessage(data.status, 'Error', 'error');
        this.unblockDocument();
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }

  previousStep() {
    let navigation = '/admin/' + this.SelectedTool + '/notes/' + this.priceQuoteId
    this.router.navigate([navigation]);
  }

  changeMonthlyRate(item) {
    var data = item;
  }

  changeAnnualRate(item) {
    var data = item;
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelInfo() {
    let navigation = '/admin/' + this.SelectedTool + '/pricing-quotes';
    this.router.navigate([navigation]);
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  activeTab(url: string, tabId: number) {

    if (this.priceQuoteId != null) {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsById(this.tokenKey, priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
          this.activeTab('manage-baserate', 5);
        }
      });
  }
}
