import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UsersService } from '../../../Services/users.service';
import { CheckAuth } from '../../../Services/utility.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from '../../../Models/usersModel';
import { Title } from '@angular/platform-browser';
import { I360Service } from 'src/Services/i360Service';
import { MenuGroupModel } from 'src/Models/MenuGroupModel';
import { ProductsService } from 'src/Services/products.service';

@Component({
  selector: 'app-registraton',
  templateUrl: './registraton.component.html',
  styleUrls: ['./registraton.component.css']
})
export class RegistratonComponent implements OnInit {
  appModel: any;
  tokenKey: string = '';
  registrationForm: FormGroup;
  menuModel = new MenuGroupModel();

  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  statusCode: number = 0;
  SelectedTool: string = '';

  _userID: number = 0;
  userModel: UserModel = {
    userId: '',
    userName: '',
    password: '',
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isActive: '',
    createdDate: null,
    createdBy: null,
    modifiedDate: null,
    modifiedBy: null,
    title:'',
  };

  // Product List---------------
  dropdownListProduct = [];
  selectedItemsProduct = [];
  dropdownSettingsProduct = {};
  productFilter: string = '';
  listOfProductID = [];

  selectedProducts = [];
  isloadingstates: boolean = false;

  // Company List
  company: any[];
  filteredCompanySingle: any[];

  dropdownDepartmentList = [];
  selectedDepartmentItems = [];
  dropdownSettingsDepartment = {};
  listOfDepartmentID = [];

  loginuserID: number = 0;
  userName: string = '';
  dropdownListTOI=[];
  selectedTOIItems = [];
  dropdownSettingsTOI = {};
  listOfTOIID = [];
  ProductList = '';
  constructor(private UserService: UsersService, private _fb: FormBuilder, private checkAuth: CheckAuth, private i360Service: I360Service
    , private activedRoute: ActivatedRoute, private toastr: ToastrService
    , private router: Router, private titleService: Title, private productsService: ProductsService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));

    this.titleService.setTitle('Registration | Inteserra - i360');

    
    this.getAllDepartment();
  }


  ngOnInit(): void {
    this.dropdownListTOI = [
      { id: 'VoIP', itemName: 'VoIP' },
      { id: 'Broadband', itemName: 'Broadband' },
      { id: 'Tax', itemName: 'Tax' },
      { id: 'Wireless', itemName: 'Wireless' },
      { id: 'Regulatory', itemName: 'Regulatory' },
      { id: 'Compliance Updates & Alerts', itemName: 'Compliance Updates & Alerts' }

    ];
    this.dropdownSettingsTOI = {
      singleSelection: false,
      text: 'Select Topics Of Interest',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No Topics Of Interest available',
      showCheckbox: true,
      enableFilterSelectAll: false
    };
    this.registrationForm = this._fb.group({
      userid: 0,
      username: [''],
      companyName: [''],
      firstname: [''],
      lastname: [''],
      email: [''],
      phone: [''],
      userTypeId: 3,
      isactive: 1,
      createdBy: 0,
      modifiedBy: 0,
      strListOfProducts: [''],
      dropdownListTOI: [''],
      password: [''],
      confirmPassword: [''],
      VoIP: false,
      Broadband: false,
      Wireless: false,
      Regulatory: false,
      title:[''],
      Tax:false,
      ComplianceUpdates:false
    });

    this.movetotop();

    //this.GetProductData();
    this.dropdownSettingsDepartment = {
      singleSelection: false,
      text: 'Select Departments (all that apply)',
      enableSearchFilter: false,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No department available',
      showCheckbox: true,
      enableFilterSelectAll: false,
      innerHeight:105,
      outerHeight:105,
    };
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

    //TOI list
    onSelectedTOI($event) {
      this.listOfTOIID = this.removeElemetninArray(this.listOfTOIID, $event.id)
      this.listOfTOIID.push($event.id);   
    }
    onDeSelectedTOI($event) {
      if (this.listOfTOIID != null) {
        if (this.listOfTOIID.length > 0) {
          this.listOfTOIID = this.removeElemetninArray(this.listOfTOIID, $event.id)
        }
      }  
    }
  
    onSelectAllTOI($event) {
      for (let iCounter = 0; iCounter < $event.length; iCounter++) {
        if (this.listOfTOIID != null) {
          if (this.listOfTOIID.length > 0) {
            this.listOfTOIID = this.removeElemetninArray(this.listOfTOIID, $event[iCounter].id)
            this.listOfTOIID.push($event[iCounter].id);
          }
          else {
            this.listOfTOIID.push($event[iCounter].id);
          }
        }
      }  
    }
    onDeSelectAllTOI($event) {
      this.listOfTOIID = [];
      this.selectedTOIItems = [];  
    }

  addProductInList() {
    this.selectedProducts = this.selectedItemsProduct.map(x => x.id);
  }

  onSelectedProduct($event) {
    if (this.selectedProducts != null) {
      if (this.selectedProducts.length > 0) {
        this.selectedProducts = this.removeElemetninArray(this.selectedProducts, $event.id)
        this.selectedProducts.push($event.id);
      }
      else {
        this.selectedProducts.push($event.id);
      }
    }
    this.addProductInList();
  }

  onSelectAllProduct($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.selectedProducts != null) {
        if (this.selectedProducts.length > 0) {
          this.selectedProducts = this.removeElemetninArray(this.selectedProducts, $event[iCounter].id)
          this.selectedProducts.push($event[iCounter].id);
        }
        else {
          this.selectedProducts.push($event[iCounter].id);
        }
      }
    }
    this.addProductInList();
  }

  onDeSelectAllProduct($event) {
    this.selectedProducts = [];
    //this.addProductInList();    
  }

  onDeSelectedProduct($event) {
    if (this.selectedProducts != null) {
      if (this.selectedProducts.length > 0) {
        this.selectedProducts = this.removeElemetninArray(this.selectedProducts, $event.id)
      }
    }
    this.addProductInList();
  }

  registerUser(userForm: any) {
    if ((userForm.value['username'] == null || userForm.value['username'] == undefined ||
      userForm.value['username'].trim() == '')) {
      this.showMessage('Please enter username.', 'Required', 'error');
      return;
    }

    if (userForm.value['username'].trim() != '') {
      if (userForm.value['username'].trim().toString().length <= 3) {
        this.showMessage('Please enter username more then 3 charactor.', 'Required', 'error');
        return;
      }
    }

    if ((userForm.value['email'] == null || userForm.value['email'] == undefined ||
      userForm.value['email'].trim() == '')) {
      this.showMessage('Please enter email.', 'Required', 'error');
      return;
    }

    if (userForm.value['email'].trim() != '') {
      let result = this.isValidMailFormat(userForm.controls.email);
      if (result != null) {
        this.showMessage('Please provide a valid email', 'Invalid Email', 'error');
        return;
      }
    }

    if ((userForm.value['password'] == null || userForm.value['password'] == undefined ||
      userForm.value['password'].trim() == '')) {
      this.showMessage('Please enter password.', 'Required', 'error');
      return;
    }

    if ((userForm.value['confirmPassword'] == null || userForm.value['confirmPassword'] == undefined ||
      userForm.value['confirmPassword'].trim() == '')) {
      this.showMessage('Please enter confirm Password.', 'Required', 'error');
      return;
    }

    if ((userForm.value['confirmPassword'] != userForm.value['password'])) {
      this.showMessage('Confirm password not match.', 'Required', 'error');
      return;
    }

    if ((userForm.value['firstname'] == null || userForm.value['firstname'] == undefined ||
      userForm.value['firstname'].trim() == '')) {
      this.showMessage('Please enter first name.', 'Required', 'error');
      return;
    }

    if ((userForm.value['lastname'] == null || userForm.value['lastname'] == undefined ||
      userForm.value['lastname'].trim() == '')) {
      this.showMessage('Please enter last name.', 'Required', 'error');
      return;
    }

    if ((userForm.value['companyName'] == null || userForm.value['companyName'] == undefined ||
      userForm.value['companyName'].trim() == '')) {
      this.showMessage('Please enter company name.', 'Required', 'error');
      return;
    }

    if ((userForm.value['phone'] == null || userForm.value['phone'] == undefined ||
      userForm.value['phone'].trim() == '')) {
      this.showMessage('Please enter phone number.', 'Required', 'error');
      return;
    }
    ;
    if (this.selectedTOIItems.length == 0) {
      this.showMessage('Please select at least one topics of interest', 'Invalid Topics', 'error');
      return;
    }

    if (this.selectedTOIItems.length > 0) {
      for (let iCounter = 0; iCounter < this.selectedTOIItems.length; iCounter++) {
        //selectedTOIItems.push({ 'TOIId': this.selectedTOIItems[iCounter].id });
        if(iCounter==0)
        {
          this.ProductList=this.selectedTOIItems[iCounter].id;
        }
        else
        {
        this.ProductList=this.ProductList+","+this.selectedTOIItems[iCounter].id;
        }
      }

    }


    this.blockDocument();
    userForm.value['userid'] = this._userID;
    // userForm.value['wirelessStates'] = this.userModel.wirelessStates;
    // userForm.value['voIPStates'] = this.userModel.voIPStates;
    // userForm.value['iprStates'] = this.userModel.iprStates;
    // userForm.value['actionStates'] = this.userModel.actionStates;
    // userForm.value['briefingStates'] = this.userModel.briefingStates;
    userForm.value['userTypeId'] = 3;
    userForm.value['createdBy'] = this.loginuserID;
    userForm.value['modifiedBy'] = this.loginuserID;
    userForm.value['strListOfProducts'] = this.ProductList;

    var selectedDepartmentItems = [];
    if (this.selectedDepartmentItems.length > 0) {
      for (let iCounter = 0; iCounter < this.selectedDepartmentItems.length; iCounter++) {
        selectedDepartmentItems.push({ 'DepartmentId': this.selectedDepartmentItems[iCounter].id });
      }
      userForm.value['dropdownDepartmentList'] = selectedDepartmentItems;
    }

  

    let msg = '';

    msg = 'register';

    this.i360Service.i360OnlineUserRegistration(this.registrationForm.value, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        this.showMessage('Subscriber ' + msg + ' successfully. Please check your inbox for a confirmation email', 'Subscriber ' + msg, 'success');
        this.unblockDocument();
        this.router.navigate(['/login']);
      }
      else {
        if (data.message == 'duplicate') {
          this.showMessage('Duplicate username not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else if (data.message == 'duplicate email') {
          this.showMessage('Duplicate email id not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else {
          this.showMessage(data.status, 'Error', 'error');
          this.unblockDocument();
        }
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  isValidMailFormat(control: FormControl) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value != '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { 'Please provide a valid email': true };
    }

    return null;
  }
  canceluser() {
    let toolName = this.SelectedTool == 'actionitem' ? 'trac-it' : this.SelectedTool;
    this.router.navigate(['/admin/' + toolName + '/subscribers']);
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }


  // Product List---------------
  GetProductData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.productsService.GetProductList(this.tokenKey, this.loginuserID, false, true).subscribe(
      data => {
        if (data['product'] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data['product'].length; iCounter++) {
            this.dropdownListProduct.push(
              { 'id': data['product'][iCounter].productId, 'itemName': data['product'][iCounter].displayName }
            );
          }
        }
        else {
          this.showMessage(data['message'], 'Error', 'error');
          this.unblockDocument();
        }
        this.dropdownSettingsProduct = {
          singleSelection: false,
          text: 'Select Product(s)',
          enableSearchFilter: false,
          classes: 'borderLessTextBoxGreen',
          badgeShowLimit: 1,
          noDataLabel: 'No product available',
          showCheckbox: true,
          enableFilterSelectAll: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }
  // Product List---------------

  filterCompanySingle(event) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    let query = event.query;
    this.UserService.GetCompanyList(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {

        this.filteredCompanySingle = this.filterCompany(query, data['companyModel']);
      }
    });
  }

  filterCompany(query, companies: any[]): any[] {
    let filtered: any[] = [];
    for (let i = 0; i < companies.length; i++) {
      let companyname = companies[i];
      if (companyname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(companyname);
      }
    }
    return filtered;
  }

  getAllDepartment() {
    this.UserService.GetAllDepartment(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        for (let iCounter = 0; iCounter < data['department'].length; iCounter++) {
          this.dropdownDepartmentList.push(
            { 'id': data['department'][iCounter].departmentId, 'itemName': data['department'][iCounter].departmentName }
          );
        }
      }
    });
  }

  onSelectedDepartment($event) {
    this.listOfDepartmentID = this.removeElemetninArray(this.listOfDepartmentID, $event.id)
    this.listOfDepartmentID.push($event.id);
  }
  onDeSelectedDepartment($event) {
    if (this.listOfDepartmentID != null) {
      if (this.listOfDepartmentID.length > 0) {
        this.listOfDepartmentID = this.removeElemetninArray(this.listOfDepartmentID, $event.id)
      }
    }
  }
  onSelectAllDepartment($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfDepartmentID != null) {
        if (this.listOfDepartmentID.length > 0) {
          this.listOfDepartmentID = this.removeElemetninArray(this.listOfDepartmentID, $event[iCounter].id)
          this.listOfDepartmentID.push($event[iCounter].id);
        }
        else {
          this.listOfDepartmentID.push($event[iCounter].id);
        }
      }
    }
  }
  onDeSelectAllDepartment($event) {
    this.listOfDepartmentID = [];
    this.selectedDepartmentItems = [];
  }

}
