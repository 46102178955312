import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-carrier-obligation-updates-add-update',
  templateUrl: './carrier-obligation-updates-add-update.component.html',
  styleUrls: ['./carrier-obligation-updates-add-update.component.css']
})
export class CarrierObligationUpdatesAddUpdateComponent implements OnInit {

  updateMasterForm: FormGroup;
  userId: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;
  getCarrierId: string = '';
  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};

  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/

  //CarrierType
  dropdownSettingsCarrierFilter = {};
  dropdownCarrierFilter = [];
  selectedCarrierItems = [];
  listOfCarrierTypeIDs = [];
  carrierTypeList: any = [];
  listOfCarrierTypeName = [];

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute, private router: Router, private datePipe: DatePipe,
    private toastr: ToastrService, private carrierObligationService: CarrierObligationService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetUpdateById(params['id']);
        }
      }
    });

    this.getCarrierType();
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    if (this.SelectedTool == 'view 47') {
      this.SelectedTool = 'view47';
    }

    this.getCarrierId = '';
    this.dropdownSettingsCarrierFilter = {
      singleSelection: false,
      text: "Select Carrier Type(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 5,
      noDataLabel: 'No Carrier Type(s) available',
      showCheckbox: true
    };

    this.updateMasterForm = this._fb.group({
      updateId: 0,
      updateNote: [''],
      updateDate: ['', Validators.required],
      cfr: ['', Validators.required],
      carrierId: [''],
    })

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetUpdateById(params['id']);
        }
      }
    });
  }

  getCarrierType() {
    this.carrierObligationService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierTypeList = result['carrier'];
          this.dropdownCarrierFilter = [];
          this.carrierTypeList.forEach(x => {
            this.dropdownCarrierFilter.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  onChangeCarrier(event) {
    this.updateMasterForm.patchValue({
      carrierId: event.id
    });
  }

  onSelectCarrierType($event) {
    this.listOfCarrierTypeIDs = this.removeElemetninArray(this.listOfCarrierTypeIDs, $event.id)
    this.listOfCarrierTypeName = this.removeElemetninArray(this.listOfCarrierTypeName, $event.itemName)
    this.listOfCarrierTypeIDs.push($event.id);
    this.listOfCarrierTypeName.push($event.itemName);
  }

  onDeSelectCarrierType($event) {

    if (this.listOfCarrierTypeIDs != null) {
      if (this.listOfCarrierTypeIDs.length > 0) {
        this.listOfCarrierTypeIDs = this.removeElemetninArray(this.listOfCarrierTypeIDs, $event.id)
      }
    }

    if (this.listOfCarrierTypeName != null) {
      if (this.listOfCarrierTypeName.length > 0) {
        this.listOfCarrierTypeName = this.removeElemetninArray(this.listOfCarrierTypeName, $event.itemName)
      }
    }
  }

  onSelectAllCarrierType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierTypeIDs != null) {
        if (this.listOfCarrierTypeIDs.length > 0) {
          this.listOfCarrierTypeIDs = this.removeElemetninArray(this.listOfCarrierTypeIDs, $event[iCounter].id)
          this.listOfCarrierTypeIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierTypeIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCarrierTypeName != null) {
        if (this.listOfCarrierTypeName.length > 0) {
          this.listOfCarrierTypeName = this.removeElemetninArray(this.listOfCarrierTypeName, $event[iCounter].itemName)
          this.listOfCarrierTypeName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCarrierTypeName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllCarrierType($event) {
    this.listOfCarrierTypeIDs = [];
    this.listOfCarrierTypeName = [];
  }


  GetUpdateById(updateId) {
    this.carrierObligationService.GetUpdateById(this.tokenKey, parseInt(updateId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.updateMasterForm.patchValue({
            updateId: result['obligationupdate'].updateId,
            carrierId: result['obligationupdate'].carrierId,
            updateNote: result['obligationupdate'].updateNote,
            updateDate: result['obligationupdate'].updateDate,
            cfr: result['obligationupdate'].cfr
          });

          if (this.carrierTypeList.length > 0) {
            this.selectedCarrierItems = [];
            this.listOfCarrierTypeIDs = []
            this.listOfCarrierTypeName = []

            for (let iCounter = 0; iCounter < result['obligationupdate'].carrierTypeId.length; iCounter++) {
              let data = this.carrierTypeList.filter(x => x.carrierTypeId == result['obligationupdate'].carrierTypeId[iCounter].carrierTypeId)[0];
              this.selectedCarrierItems.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
              this.listOfCarrierTypeIDs.push(data.carrierTypeId)
              this.listOfCarrierTypeName.push(data.carrierName)
            }
          }
          else {
            setTimeout(() => {
              if (result['obligationupdate'].carrierTypeId.length > 0) {
                this.selectedCarrierItems = []
                this.listOfCarrierTypeIDs = []
                this.listOfCarrierTypeName = [];
                for (let iCounter = 0; iCounter < result['obligationupdate'].carrierTypeId.length; iCounter++) {
                  let data = this.carrierTypeList.filter(x => x.carrierTypeId == result['obligationupdate'].carrierTypeId[iCounter].carrierTypeId)[0];
                  this.selectedCarrierItems.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
                  this.listOfCarrierTypeIDs.push(data.carrierTypeId)
                  this.listOfCarrierTypeName.push(data.carrierName)
                }
              }
            }, 500);
          }
        }
      });
  }

  cancelUpdate() {
    let navigation = '/admin/' + this.SelectedTool + '/update';
    this.router.navigate([navigation]);
  }

  save() {
    this.isSubmit = true;
    let data = this.updateMasterForm.value;
    if (!this.updateMasterForm.valid)
      return
    data["createdBy"] = this.userId;

    let carrierId = this.listOfCarrierTypeIDs.join(',');
    
    let value = {
      updateId: data.updateId,
      updateDate: this.datePipe.transform(data.updateDate, 'MM-dd-yyyy hh:mm:ss'),
      UpdateNote: data.updateNote.trim(),
      cfr: data.cfr.trim(),
      carrierId: carrierId
    }
    this.carrierObligationService.UpdateAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.updateId > 0)
              this.showMessage('Update updated successfully', 'Success', 'success');
            else
              this.showMessage('Update saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/update']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }
}
