import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SpecialReportService {
    AppUrl: string = "";
    constructor(private _http: Http, private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.setAPIUrl();
    }

    setAPIUrl() {
        if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
            this.AppUrl = sessionStorage.getItem("BaseURL") || '';
        }
    }

    i360SpecialReportAddUpdate(TokenKey: string, trainingModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/SpecialReports/i360SpecialReportsAddUpdate';
        const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, trainingModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360SpecialReportGet(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/SpecialReports/i360SpecialReportsGet';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360SpecialReportFindbySpecialReportId(TokenKey: string, specialReportId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/SpecialReports/i360SpecialReportFindbySpecialReportId';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, specialReportId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360SpecialReportDelete(TokenKey: string, specialReportId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/SpecialReports/i360SpecialReportsDelete';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, specialReportId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360SpecialReportSectionsAddUpdate(TokenKey: string, trainingModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/SpecialReports/AddUpdateSpecialReportsSections';
        const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, trainingModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360SpecialReportSectionGet(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/SpecialReports/i360SpecialReportsSectionGet';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360SpecialReportFindbySpecialReportSectionId(TokenKey: string, sectionId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/SpecialReports/i360SpecialReportFindbySpecialReportSectionId';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, sectionId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360SpecialReportSectionDelete(TokenKey: string, sectionId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/SpecialReports/i360SpecialReportsSectionDelete';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, sectionId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360SpecialReportSectionListWithIds(TokenKey: string): Observable<any> {
        this.setAPIUrl();
        let data = 'api/SpecialReports/i360SpecialReportsSectionListWithIds';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions).pipe(catchError(this.handleError));
    }

    private handleError(error: Response | any) {
        console.error(error.message || error);
        return observableThrowError(error.status)
    }

    i360SRSectionUpdate(TokenKey: string, currentIndexid: string, previousIndexid: string): Observable<any[]> {
        this.setAPIUrl();

        let data = 'api/SpecialReports/i360SRSectionDetailUpdate/' + currentIndexid + '/' + previousIndexid;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360SpecialReportUpdate(TokenKey: string, currentIndexid: string, previousIndexid: string): Observable<any[]> {
        this.setAPIUrl();

        let data = 'api/SpecialReports/i360SpecialReportDetailUpdate/' + currentIndexid + '/' + previousIndexid;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }
}
