export class TRFAFundModel {
    fundID: number;
    Id: number;
    fundTypeID: number;
    stateId: number;
    fundName: string;
    itemName: string;
}
export class TRFAFundGetModel {
    fundID: number;
    fundName: string;
    stateName: string;
    fundTypeName: string;
    fundTypeID: number;
    stateID: number;
    totalRecordCount: number;
    sortOrder: number;
}

export class FilterFundModel {
    pageNumber?: number;
    pageSize?: number;
    orderBy?: string;
    stateID?: string | number;
    fundTypeID?: string | number;
    filterText?: string;
    isAdmin?: boolean;
    statusId?: string;
    isEdit?:boolean;
}
export class TrfaGetFundModel {
    fundID: number;
    fundName: string;
    shortName: string;
    fundTypeName: string;
}