import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { FilterDepositeModel } from 'src/Models/CRRADepositeModel';

@Injectable({
  providedIn: 'root'
})
export class CrraDepositeMasterService {

  AppUrl: string = "";
  FilterDepositeModel=new FilterDepositeModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }

  GetAllDeposite(TokenKey: string, PageNumber: number, PageSize: number, stateId:string,filterText:string,orderBy:string): Observable<any> {
    this.FilterDepositeModel={
      pageNumber:PageNumber,
      pageSize:PageSize,   
      filterText:filterText,   
      stateId:stateId,
      orderBy:orderBy
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRADepositeMaster/GetAllDeposite';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL,this.FilterDepositeModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  AddUpdateDeposite(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/CRRADepositeMaster/AddUpdateDeposite';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetDepositeById(TokenKey: string,payRateId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRADepositeMaster/GetDepositeById/' + payRateId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteDeposite(TokenKey: string,payRateId:number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRADepositeMaster/DeleteDeposite/' + payRateId ;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetDeposite(TokenKey: string): Observable<any> {
    this.setAPIUrl();
    let data = 'api/CRRADepositeMaster/GetDeposite';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllDepositByUser(TokenKey:string,PageNumber:number,PageSize:number,stateId:string,filterText:string,userId:number,OrderBy:string,isFavorite:number): Observable<any>{
    this.FilterDepositeModel={
      pageNumber:PageNumber,
      pageSize:PageSize,   
      filterText:filterText,   
      stateId:stateId,
      orderBy:OrderBy,
      userId:userId,
      isFavorite:isFavorite 
    }
    this.setAPIUrl();
    let data = this.AppUrl + 'api/CRRADepositeMaster/GetAllDepositByUser';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(data,this.FilterDepositeModel, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
