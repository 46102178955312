import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CheckAuth } from '../Services/utility.service';
import { Gtag } from 'angular-gtag';
import { Title } from '@angular/platform-browser';
import { I360Service } from 'src/Services/i360Service';
import { IpService } from 'src/Services/ip.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  user_Id = -1;

  constructor(private i360Service: I360Service, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute,
    private router: Router, private gtag: Gtag, private titleService: Title, private ipService: IpService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    sessionStorage.setItem('Username', document.querySelector('app-root').getAttribute('[Username]'));
    sessionStorage.setItem('Hash', document.querySelector('app-root').getAttribute('[Hash]'));
    let Productname = document.querySelector('app-root').getAttribute('[Productname]');
    // in database we have used actionitem but letter on productname tracit 
    if (Productname == 'tracit')
      sessionStorage.setItem('Productname', 'actionitem');
    else
      sessionStorage.setItem('Productname', document.querySelector('app-root').getAttribute('[Productname]'));

    const username = document.querySelector('app-root').getAttribute('[Username]');
    this.user_Id = +document.querySelector('app-root').getAttribute('[User_Id]');
    const haskey = document.querySelector('app-root').getAttribute('[Hash]');
    if (username != '' && username != null && haskey != null && haskey != '') {
      this.router.navigate(['autologin']);
    }
    this.checkIpAddress();
  }

  ngOnInit() {
    //sessionStorage.setItem('UserId', '0');
    if (this.user_Id != -1) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.gtag.set({ 'user_id': this.user_Id });
          this.gtag.pageview({
            page_title: this.getTitle(),
            page_path: this.router.url,
            page_location: window.location.href
          });
        }
      });
    }
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  getTitle(): string {
    let title = this.titleService.getTitle();
    const clientLoginTool = sessionStorage.getItem('ClientLoginTool');
    switch (clientLoginTool) {
      case 'voip': title = 'VOIP - ' + title; break;
      case 'ipr': title = 'IPR - ' + title; break;
      case 'wireless': title = 'Wireless - ' + title; break;
      case 'actionitem': title = 'Action Item - ' + title; break;
      default:
        break;
    }
    return title;
  }
  checkIpAddress() {
    let url = 'i360-site-maintenance.html';
    this.ipService.getIPAddress().subscribe((res: any) => {
      if (res) {        
        if (res._body != null) {
          let obj = JSON.parse(res._body)
          if (obj != null) {
            // if (!CheckIpAddresAllow(obj.ip)) {
            //   window.location.href = window.location.origin + "/assets/" + url;
            // }
            sessionStorage.setItem('ipAddress', obj.ip);
          }
        }
      }
    },
      error => {
        //window.location.href = window.location.origin + "/assets/" + url;
      });
  }

}
