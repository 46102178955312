import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomProductModel } from 'src/Models/CustomProductModel';
import { CheckAuth } from 'src/Services/utility.service';
import { CustomCompanyProductService } from 'src/Services/custom-company-product.services';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-manage-custom-company-product',
  templateUrl: './manage-custom-company-product.component.html',
  styleUrls: ['./manage-custom-company-product.component.css']
})
export class ManageCustomCompanyProductComponent implements OnInit {

  customProductModel = new CustomProductModel();
  customProductDetails: any = [];

  loginuserID: number = 0;
  tokenKey: string = '';
  subtitle: string;
  externalURL: string;
  section: string;
  AddButtontitle: string;
  AddUpdateButtontitle: string;
  fullPath: string;
  customProductDetailsId: string;
  docPath: string;
  sortOrder: string;

  companyId: number = 0;
  customProductId: number = 0;
  isMultipleClick: boolean = false;
  file: any;
  @ViewChild('fileUpload', { static: true }) fileUpload;
  isShowprogressSpinner = false;
  blockedDocument = false;
  deletedId: number = 0;
  deletedIndex: number = 0;
  editedIndex: number = 0;

  dropdownOptionSettings = {}
  dropdownOptionItems = [];
  listOfOptionSelected = [];
  isSelectExternalURL: boolean = false;
  isSelectFileUpload: boolean = false;
  isErrorOnUploadDoc: boolean = false;
  selectedFiles: File;
  selectedOptionItems = [];
  openMenuIndex: number = -1;

  constructor(private activedRoute: ActivatedRoute, private _fb: FormBuilder, private router: Router, private checkAuth: CheckAuth, private titleService: Title,
    private customCompanyProductService: CustomCompanyProductService,
    private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Manage Custom Company Product');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');

    this.dropdownOptionSettings = {
      singleSelection: true,
      text: 'Select',
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 3,
      noDataLabel: 'No record available',
      showCheckbox: false
    };
  }

  // Getter for easy access
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.FillselectoptionDropdown();
    this.subtitle = '';
    this.externalURL = '';
    this.AddButtontitle = "Add";
    this.AddUpdateButtontitle = "Save";
    this.activedRoute.params.subscribe((params: Params) => {
      this.companyId = params['cid'];

      if (params['id']) {
        if (params['id'] != null) {

          this.customProductId = params['id'];
          this.AddUpdateButtontitle = "Update";

          this.GetCustomCompanyProductData();
        }
      }
      else {
        this.customProductModel.isActive = true;
      }
    });

  }

  GetCustomCompanyProductData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.customCompanyProductService.i360CustomCompanyProductFindbycustomProductId(this.tokenKey, this.customProductId).subscribe(
      data => {
        if (data != null) {
          this.customProductModel = data["customProductData"];
          this.customProductDetails = data["customProductData"].customProductDetails;
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  savecustomcompanyproduct() {

    event.preventDefault();

    if (this.customProductModel.title == '' || typeof (this.customProductModel.title) == 'undefined' || this.customProductModel.title == null) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }

    if (this.customProductModel.description == '' || typeof (this.customProductModel.description) == 'undefined' || this.customProductModel.description == null) {
      this.showMessage('Please enter description', 'Description Required', 'error');
      return;
    }

    this.customProductModel.companyId = this.companyId;
    this.customProductModel.customProductId = this.customProductId;
    this.customProductModel.createdBy = this.loginuserID;
    this.customProductModel.modifiedBy = this.loginuserID;
    this.customProductModel.customProductDetails = this.customProductDetails;

    if (this.isMultipleClick) {
      return;
    }
    this.isMultipleClick = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.customCompanyProductService.i360CustomCompanyProductAddUpdateCustomData(this.tokenKey, this.customProductModel).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        if (data['customProductId'] != 0) {
          if (data['customProductId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.customProductId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.customProductId = 0;
            this.cancelcustomcompanyproduct();
          }
        }
      },
      error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelcustomcompanyproduct() {
    this.router.navigate(['admin/inteserra360/custom-company-product/' + this.companyId]);
  }

  editData(item, index) {
    this.editedIndex = index;
    this.subtitle = item.title;
    this.externalURL = item.externalURL;
    this.section = item.section;
    this.sortOrder = item.sortOrder;
    this.fullPath = item.fullPath;
    this.customProductDetailsId = item.customProductDetailsId;
    this.docPath = item.docPath;

    if (item.linkType != 0) {
      if (item.linkType != undefined) {
        this.selectedOptionItems = this.dropdownOptionItems.filter(x => x.id == item.linkType);
        this.listOfOptionSelected.push(item.linkType);
      }

      if (item.linkType == 1) {
        document.getElementById('inputfile').style.display = 'none'
        document.getElementById('inputUrl').style.display = 'block'
      }
      else if (item.linkType == 2) {
        document.getElementById('inputfile').style.display = 'block'
        document.getElementById('inputUrl').style.display = 'none'
      }
      else {
        this.listOfOptionSelected = [];
        this.dropdownOptionItems = [];
        this.selectedOptionItems = [];
        document.getElementById('inputfile').style.display = 'none'
        document.getElementById('inputUrl').style.display = 'none'
        this.FillselectoptionDropdown();
      }
    }
    else {
      this.listOfOptionSelected = [];
      this.dropdownOptionItems = [];
      this.selectedOptionItems = [];
      document.getElementById('inputfile').style.display = 'none'
      document.getElementById('inputUrl').style.display = 'none'
      this.FillselectoptionDropdown();
    }
    this.AddButtontitle = "Update";
  }

  AddUpdateData() {

    if (this.subtitle == "" || this.subtitle == null) {
      return false;
    }

    if (this.listOfOptionSelected.length == 0) {
      this.showMessage('Please Link Type', 'Link Type Required', 'error');
      return;
    }

    if (this.customProductModel.title == '' || typeof (this.customProductModel.title) == 'undefined' || this.customProductModel.title == null) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }

    let formData = new FormData();
    if (this.AddButtontitle == "Update") {
      formData.append('customProductDetailsId', this.customProductDetailsId);
      formData.append('docPath', this.docPath);
    }
    else {
      formData.append('customProductDetailsId', '0');
      formData.append('docPath', "");
    }

    formData.append('customProductId', this.customProductId.toString());
    formData.append('subtitle', this.subtitle)
    formData.append('externalURL', this.externalURL);
    formData.append('section', this.section);
    formData.append('sortOrder', this.sortOrder);
    formData.append('isActive', '1');
    formData.append('createdBy', '0');
    formData.append('modifiedBy', '0');
    formData.append('fileUpload', this.fileUpload.nativeElement.files[0]);
    formData.append('linktype', this.selectedOptionItems[0].id);
    formData.append('fullPath', '');
    formData.append('companyId', this.companyId.toString());
    formData.append('createdBy', this.loginuserID.toString());
    formData.append('modifiedBy', this.loginuserID.toString());

    if (this.isMultipleClick) {
      return;
    }
    this.isMultipleClick = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.customCompanyProductService.i360CustomCompanyProductAddUpdate(this.tokenKey, formData).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        if (data['customProductId'] != 0) {
          if (data['customProductId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.AddButtontitle == "Update")
              this.showMessage('Record updated successfully', 'Update', 'success');
            else
              this.showMessage('Record saved successfully', 'Save', 'success');

            this.ResetControl();
            this.GetCustomCompanyProductData();

          }
        }
      },
      error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        console.log(error)
      }
    );
  }
  ResetControl() {
    this.fileUpload.nativeElement.value = "";
    this.AddButtontitle = "Add";
    this.section = "";
    this.sortOrder = "";
    this.subtitle = "";
    this.externalURL = "";
    this.listOfOptionSelected = [];
    this.dropdownOptionItems = [];
    this.selectedOptionItems = [];
    this.fullPath = null;
    document.getElementById('inputfile').style.display = 'none'
    document.getElementById('inputUrl').style.display = 'none'
    this.FillselectoptionDropdown();
    this.docPath = "";
  }
  setDeleteData(customProductDetailsId, index) {

    console.log(customProductDetailsId);
    this.deletedId = customProductDetailsId;
    this.deletedIndex = index;
  }

  deleteRecord(deletedId) {
    if (deletedId > 0) {
      this.customCompanyProductService.i360CustomProductitemDelete(this.tokenKey, deletedId).subscribe(
        data => {
          this.deletedId = 0;
          if (data != null) {
            if (data['deletedId'] > 0) {
              this.GetCustomCompanyProductData();
              this.toastr.success('Record deleted successfully.', 'Delete');
            }
          }
        },
        error => {
        }
      );
    }
    else {
      this.customProductDetails.splice(this.deletedIndex, 1);
      this.toastr.success('Record deleted successfully.', 'Delete');
    }

    this.deletedIndex = 0;
    this.deletedId = 0;

  }

  FillselectoptionDropdown() {
    this.dropdownOptionItems.push({ "id": '1', "itemName": "External URL" });
    this.dropdownOptionItems.push({ "id": '2', "itemName": "File Upload" });
  }

  onSelectOption($event) {
    this.listOfOptionSelected = [];
    this.listOfOptionSelected.push($event.id);
    if ($event.id == 1) {
      document.getElementById('inputfile').style.display = 'none'
      document.getElementById('inputUrl').style.display = 'block'
    } else if ($event.id == 2) {
      document.getElementById('inputfile').style.display = 'block'
      document.getElementById('inputUrl').style.display = 'none'
    } else {
      document.getElementById('inputfile').style.display = 'none'
      document.getElementById('inputUrl').style.display = 'none'
    }
  }

  onDeselectOption() {
    document.getElementById('inputfile').style.display = 'none'
    document.getElementById('inputUrl').style.display = 'none'
    this.listOfOptionSelected = [];
  }

  onDeselectAllOption() {
    document.getElementById('inputfile').style.display = 'none'
    document.getElementById('inputUrl').style.display = 'none'
    this.listOfOptionSelected = [];
  }

  fileEvent(event): any {
    var file = event.target.files[0];
    let size = Math.round(file.size / 1024);
    if (size <= 21000) {
      var allowedExtensions = ["doc", "DOC", "docx", "DOCX", "pdf", "PDF"];
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF, DOC, DOCX.', 'Error', 'error');
        this.isErrorOnUploadDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isErrorOnUploadDoc = false;
        this.selectedFiles = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnUploadDoc = true;
      window.scrollTo(0, 0);
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  drop(event: CdkDragDrop<string[]>): void {
    var CurrentShortOrder, PreviousShortOrder, currentcustomProductDetailsId, previouscustomProductDetailsId
    PreviousShortOrder = this.customProductDetails[event.previousIndex]['sortOrder']
    previouscustomProductDetailsId = this.customProductDetails[event.previousIndex]['customProductDetailsId']
    CurrentShortOrder = this.customProductDetails[event.currentIndex]['sortOrder']
    currentcustomProductDetailsId = this.customProductDetails[event.currentIndex]['customProductDetailsId']
    this.customCompanyProductService.i360CustomProductDetailUpdate(this.tokenKey, previouscustomProductDetailsId, currentcustomProductDetailsId).subscribe(
      data => {
        this.deletedId = 0;
        if (data != null) {
          this.customCompanyProductService.i360CustomCompanyProductFindbycustomProductId(this.tokenKey, this.customProductId).subscribe(
            data => {
              if (data != null) {
                this.customProductModel = data["customProductData"];
                this.customProductDetails = data["customProductData"].customProductDetails;
              }
            }, error => {
              this.showMessage(error, 'Error', 'error');
            })
        }
      },
      error => {
      }
    );
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
