import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddUpdateOngoingComplianceModel, MiscellaneousFeeModel, OngoingComplianceModel } from 'src/Models/OngoingComplianceModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompleted, getIncompletedStep } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';

@Component({
  selector: 'app-price-quotes-compliance',
  templateUrl: './price-quotes-compliance.component.html',
  styleUrls: ['./price-quotes-compliance.component.css']
})
export class PriceQuotesComplianceComponent implements OnInit {

  SelectedTool: string;
  userID: any;
  clientInformationId: number = 0;
  priceQuoteId: number = 0;
  tokenKey: string;
  isloading: boolean = false;

  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  stateCode: any;
  isRecord: boolean = false;
  listofCompliance: OngoingComplianceModel[];
  listofMiscellaneousFees: MiscellaneousFeeModel[];
  listofAdditionalServices: any = [];
  complianceTotal: number = 0;
  companyName: string;

  addUpdateOngoingCompliance: AddUpdateOngoingComplianceModel =
    {
      OngoingCompliance: [],
      MiscellaneousFee: [],
      AdditionalServicesFee: [],
      priceQuoteId: 0
    };

  isRateDetailsCompleted: boolean;
  isQuoteDetailsCompleted: boolean;
  isAddOnServicesCompleted: boolean;
  isComplianceDetailsCompleted: boolean;
  isProposalDetailsCompleted: boolean;
  currentActiveTab: number = 1;

  url: string;
  tabId: number = 0;

  additionalServicesComplianeIdTemp: number = 1000000;

  mainUrl: string;
  subUrl: string;
  send_date = new Date();
  formattedDate: any;
  isSubmit: boolean = false;

  constructor(private router: Router, private PricingQuotesMaster: PricingQuotesService, private checkAuth: CheckAuth
    , private titleService: Title, private activedRoute: ActivatedRoute, private toastr: ToastrService, private globle: Globle) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    // this.titleService.setTitle('Price Quote');
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Price Quote - ' + this.companyName);


    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          //this.clientInformationId = params['id'];
          this.priceQuoteId = params['id'];
          this.GetComplianceByClientInformationId(this.priceQuoteId);
        }
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.priceQuoteId != null) {
      this.GetProgressStepDetailsById(this.priceQuoteId);
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isRateDetailsCompleted = null
      this.globle.cApp.statusModel.isQuoteDetailsCompleted = null
      this.globle.cApp.statusModel.isAddOnServicesCompleted = null
      this.globle.cApp.statusModel.isComplianceDetailsCompleted = null
      this.globle.cApp.statusModel.isProposalDetailsCompleted = null
      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
    }


  }

  ngOnInit() {
    this.isloading = true;

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  previousStep() {
    this.router.navigate(['/admin/' + this.SelectedTool + '/add-on-services/' + this.priceQuoteId]);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  GetComplianceByClientInformationId(priceQuoteId) {
    this.blockDocument();
    this.PricingQuotesMaster.GetComplianceByClientInformationId(this.tokenKey, priceQuoteId).subscribe(
      data => {
        if (data['status'] = 'Success') {
          this.unblockDocument();
          this.isloading = false;
          this.listofCompliance = data['compliance'];
          if (this.listofCompliance != null) {
            if (this.listofCompliance.length == 0) {
              this.isRecord = true;
            }
            else {
              for (let iCounter = 0; iCounter < this.listofCompliance.length; iCounter++) {
                this.complianceTotal = this.complianceTotal + this.listofCompliance[iCounter].totalMonthlyCost;
              }
            }
          }
          else {
            this.isRecord = false;
          }

          this.listofMiscellaneousFees = data['miscellaneous'];
          if (this.listofMiscellaneousFees != null) {
            if (this.listofMiscellaneousFees.length == 0) {
              this.isRecord = true;
            }
          }
          else {
            this.isRecord = false;
          }
          this.listofAdditionalServices = data['additionalServices'];
          if (this.listofAdditionalServices != null) {
            if (this.listofAdditionalServices.length == 0) {
              this.isRecord = true;
            }
          }
          else {
            this.isRecord = false;
          }
        }

        else {
          this.unblockDocument();
          this.isRecord = false;
        }
      }, error => { 
        this.unblockDocument();
        this.stateCode = error 
      }
    );
  }

  addRow() {
    this.additionalServicesComplianeIdTemp = this.additionalServicesComplianeIdTemp + 1;

    this.send_date.setMonth(this.send_date.getMonth());
    this.formattedDate = this.send_date.toISOString().slice(0, 10);

    this.listofAdditionalServices.push({
      additionalServicesComplianeId: this.additionalServicesComplianeIdTemp, additionalServicesTitle: '',
      additionalServicesNote: '', additionalServicesAmount: '', clientInformationId: this.userID,
      createdBy: 0, createdDate: this.formattedDate, updatedBy: 0, updatedDate: this.formattedDate, priceQuoteId: this.priceQuoteId, isSelected: false
    });
  }
  deleteRow(index) {
    this.listofAdditionalServices.splice(index, 1);
  }

  save() {
    this.blockDocument();
    this.isSubmit = true;
    let data = this.listofCompliance;
    this.globle.cApp.statusModel.isComplianceDetailsCompleted = false;
    for (let iCounter = 0; iCounter < this.listofCompliance.length; iCounter++) {
      this.listofCompliance[iCounter].createdBy = this.userID;
      this.listofCompliance[iCounter].clientInformationId = this.clientInformationId;
      this.listofCompliance[iCounter].priceQuoteId = this.priceQuoteId;
    }

      for (let iCounter = 0; iCounter < this.listofMiscellaneousFees.length; iCounter++) {
        this.listofMiscellaneousFees[iCounter].createdBy = this.userID;
        this.listofMiscellaneousFees[iCounter].clientInformationId = this.clientInformationId;
        this.listofMiscellaneousFees[iCounter].priceQuoteId = this.priceQuoteId;
  
        if(this.listofMiscellaneousFees[iCounter].miscellaneousTitle == "Notes"){
          if(this.listofMiscellaneousFees[iCounter].miscellaneousNote==null || this.listofMiscellaneousFees[iCounter].miscellaneousNote=="" || this.listofMiscellaneousFees[iCounter].miscellaneousNote==undefined)
          {
            if(this.listofMiscellaneousFees[iCounter].miscellaneousAmount!=null || this.listofMiscellaneousFees[iCounter].miscellaneousAmount!=undefined || this.listofMiscellaneousFees[iCounter].miscellaneousAmount==0){
            this.listofMiscellaneousFees[iCounter].isSelected = false;
            }
            this.listofMiscellaneousFees[iCounter].isSelected = false;
          }
  
          else{
            if(this.listofMiscellaneousFees[iCounter].miscellaneousNote!=null || this.listofMiscellaneousFees[iCounter].miscellaneousNote!=undefined || this.listofMiscellaneousFees[iCounter].miscellaneousNote!="")
            {
              if(this.listofMiscellaneousFees[iCounter].miscellaneousAmount==0 ){
              this.listofMiscellaneousFees[iCounter].isSelected = true;
              }
              else if(this.listofMiscellaneousFees[iCounter].miscellaneousAmount!=null || this.listofMiscellaneousFees[iCounter].miscellaneousAmount!=undefined)
              this.listofMiscellaneousFees[iCounter].isSelected = true;
              else if (this.listofMiscellaneousFees[iCounter].miscellaneousAmount==null || this.listofMiscellaneousFees[iCounter].miscellaneousAmount==undefined ||  this.listofMiscellaneousFees[iCounter].miscellaneousAmount.toString()!="") 
              this.listofMiscellaneousFees[iCounter].isSelected = true;
            }
            // this.listofMiscellaneousFees[iCounter].isSelected = true;
            else
             this.listofMiscellaneousFees[iCounter].isSelected = false;
          }
         
        }
  
      }
      
    for (let iCounter = 0; iCounter < this.listofAdditionalServices.length; iCounter++) {
      if (this.listofAdditionalServices[iCounter].additionalServicesTitle == '')
      return;
     
      if (this.listofAdditionalServices[iCounter].additionalServicesNote == '')
      return;
      this.listofAdditionalServices[iCounter].createdBy = this.userID;
      this.listofAdditionalServices[iCounter].clientInformationId = this.clientInformationId;
      this.listofAdditionalServices[iCounter].priceQuoteId = this.priceQuoteId;
    }

    this.addUpdateOngoingCompliance.OngoingCompliance = this.listofCompliance;
    this.addUpdateOngoingCompliance.MiscellaneousFee = this.listofMiscellaneousFees;
    this.addUpdateOngoingCompliance.AdditionalServicesFee = this.listofAdditionalServices;
    this.addUpdateOngoingCompliance.priceQuoteId = this.priceQuoteId;


    this.PricingQuotesMaster.ComlianceAddUpdate(this.addUpdateOngoingCompliance, this.tokenKey).subscribe(
      result => {
        this.unblockDocument();
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isComplianceDetailsCompleted = true;
          this.router.navigate(['/admin/' + this.SelectedTool + '/proposal/' + this.priceQuoteId]);
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  activeTab(url: string, tabId: number) {

    if (this.priceQuoteId != null) {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompleted(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(priceQuoteId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsById(this.tokenKey, priceQuoteId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStep(this.globle.cApp.statusModel);
          this.activeTab('compliance', 8);
        }
      });
  }

  SelectMiscellaneousItem(item) {

  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
}
