import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ObligationUpdateGetModel } from 'src/Models/ObligationUpdatesModel';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';


@Component({
  selector: 'app-carrier-obligation-updates',
  templateUrl: './carrier-obligation-updates.component.html',
  styleUrls: ['./carrier-obligation-updates.component.css']
})
export class CarrierObligationUpdatesComponent implements OnInit {

  stateCode: any;
  tokenKey: string;
  listofUpdate: ObligationUpdateGetModel[]

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  UpdateNote: string;


  upDowNoteCSS: string = '';
  upDowCarrierNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDowCfrCSS: string = '';

  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //Carrier List
  dropdownListCarrier = [];
  selectedItemsCarrier = [];
  dropdownSettingsCarrier = {};
  listOfCarrierIds = [];
  carrierId: string = ' ';
  carrierTypeList: any[] = [];
  UpdateId: number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private checkAuth: CheckAuth, private carrierObligationService: CarrierObligationService
    , private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofUpdate = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'UpdateDate desc';
    this.UpdateNote = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Updates');

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    if(this.SelectedTool == 'view 47'){
      this.SelectedTool = 'view47'
    }
    this.dropdownSettingsCarrier = {
      singleSelection: false,
      text: "Filter by Carrier Type(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 5,
      noDataLabel: 'No Carrier Type(s) available',
      showCheckbox: true
    };

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getCarrierType();
    this.isloading = true;
    this.GetUpdate();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetUpdate();
  }

  getCarrierType() {
    this.carrierObligationService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierTypeList = result['carrier'];
          this.dropdownListCarrier = [];
          this.carrierTypeList.forEach(x => {
            this.dropdownListCarrier.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCarrier($event) {
    this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event.id)
    this.listOfCarrierIds.push($event.id);
    this.carrierId = ' ';
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIds != null) {
      if (this.listOfCarrierIds.length > 0) {
        this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event.id)
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
          this.listOfCarrierIds.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIds = [];
    this.resetPageNoandSize();
    this.GetUpdate();
  }


  GetUpdate() {
    this.isloading = true;
    this.carrierId = ' ';
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    if (this.UpdateNote == "null") this.UpdateNote = " ";
    this.carrierObligationService.GetAllUpdate(this.tokenKey, this.PageNumber, this.PageSize, this.carrierId, this.OrderBy).subscribe(
      data => {
        this.isloading = false;
        this.listofUpdate = data['obligationupdate'];
        if (this.listofUpdate != null) {
          if (this.listofUpdate.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofUpdate[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.UpdateNote = $event.target.value;
    else
      this.UpdateNote = 'null';

    this.resetPageNoandSize();
    this.GetUpdate();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowUpdateDateCSS = '';
      if (sortBy === "UpdateNote") {
        this.upDowNoteCSS = 'fa fa-arrow-down';
        this.upDowCarrierNameCSS = '';
        this.upDowUpdateDateCSS = '';
        this.upDowCfrCSS='';
      }
      else if (sortBy === "carrierName") {
        this.upDowNoteCSS = '';
        this.upDowCarrierNameCSS = 'fa fa-arrow-down';
        this.upDowUpdateDateCSS = '';
        this.upDowCfrCSS='';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowNoteCSS = '';
        this.upDowCarrierNameCSS = '';
        this.upDowUpdateDateCSS = 'fa fa-arrow-down';
        this.upDowCfrCSS='';
      }
      else if (sortBy === "cfr") {
        this.upDowNoteCSS = '';
        this.upDowCarrierNameCSS = '';
        this.upDowUpdateDateCSS = '';
        this.upDowCfrCSS='fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "UpdateNote") {
        this.upDowNoteCSS = 'fa fa-arrow-up';
        this.upDowCarrierNameCSS = '';
        this.upDowUpdateDateCSS = '';
        this.upDowCfrCSS='';
      }
      else if (sortBy === "carrierName") {
        this.upDowNoteCSS = '';
        this.upDowCarrierNameCSS = 'fa fa-arrow-up';
        this.upDowUpdateDateCSS = '';
        this.upDowCfrCSS='';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowNoteCSS = '';
        this.upDowCarrierNameCSS = '';
        this.upDowUpdateDateCSS = 'fa fa-arrow-up';
        this.upDowCfrCSS='';
      }
      else if (sortBy === "cfr") {
        this.upDowNoteCSS = '';
        this.upDowCarrierNameCSS = '';
        this.upDowUpdateDateCSS = '';
        this.upDowCfrCSS='fa fa-arrow-up';
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  addUpdate() {
    let navigation = '/admin/' + this.SelectedTool + '/update/manage';
    this.router.navigate([navigation]);
  }

  updateUpdate(UpdateId) {
    let navigation = '/admin/' + this.SelectedTool + '/update/manage/' + UpdateId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.UpdateId = id;
  }

  deleteUpdate(UpdateId) {
    this.isloading = true;
    this.carrierObligationService.DeleteUpdate(this.tokenKey, UpdateId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.showMessage("Update Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.GetUpdate();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }
 

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  onFilterSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
          this.listOfCarrierIds.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  replaceCommaLine(data) {
    if (data != null) {
      if (data.includes(',')) {
        let dataToArray = data.split(',').map(item => item.trim());
        let list = data.split(",").join("<br />")
        return list
      }
      else {
        return data;
      }
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
