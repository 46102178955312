import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/Services/app.config';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';
import { checkStepIsCompletedPhase3, getIncompletedStepPhase3 } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';


@Component({
  selector: 'app-admin-additional-company-information',
  templateUrl: './admin-additional-company-information.component.html',
  styleUrls: ['./admin-additional-company-information.component.css']
})
export class AdminAdditionalCompanyInformationComponent implements OnInit {

  CompanyProfileInformationForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit: boolean = false;

  ClientInformationId: number;

  mainUrl: string;
  subUrl: string;
  currentActiveTab: number = 1;

  listOfTradedCompanyIDs = [];
  TradedCompanyList: any[] = [];
  selectedTradedCompanyItem = [];
  dropdownTradedCompanyFilter = [];
  dropdownSettingsTradedCompanyFilter = {};
  isEnableTradedCompnay: boolean = false

  listOfCompanyTypeIDs = [];
  CompanyTypeList: any[] = [];
  selectedCompanyType = [];
  dropdownCompanyTypeFilter = [];
  dropdownSettingsCompanyTypeFilter = {};

  listOfLLCIDs = [];
  LLCTypeList: any[] = [];
  selectedLLCType = [];
  dropdownLLCTypeFilter = [];
  dropdownSettingsLLCTypeFilter = {};

  listOfCorporationIDs = [];
  CorporationTypeList: any[] = [];
  selectedCorporationType = [];
  dropdownCorporationTypeFilter = [];
  dropdownSettingsCorporationTypeFilter = {};

  listOfUSIdentificationIDs = [];
  USIdentificationList: any[] = [];
  selectedUSIdentificationItem = [];
  dropdownUSIdentificationFilter = [];
  dropdownSettingsUSIdentificationFilter = {};

  listOfReferralRegisteredAgentIDs = [];
  ReferralRegisteredAgentList: any[] = [];
  selectedReferralRegisteredAgentItem = [];
  dropdownReferralRegisteredAgentFilter = [];
  dropdownSettingsReferralRegisteredAgentFilter = {};

  dropdownMonths = [];
  selectedItemsMonths = [];
  dropdownSettingMonths = {};
  listOfMonths = [];
  years: any = '';

  CorporationType: number = 0;
  LLCType: number = 0;

  IsLLCSelected: boolean = false;
  IsCorporationSelected: boolean = false;
  IsOther: boolean = false;

  allowedExtensions: any = ["pdf", "PDF", "doc", "docx", "DOC", "DOCX", "txt"];

  filePath1: string = null;

  file: any;
  // @ViewChild('articlesInfo', { static: true }) articlesInfo;

  selectedItemsResetPassword = [];
  dropdownResetPasswordFilter = [];
  isEnableCurrentlyResetingPassword: boolean = false

  listOfTaxPermitIDs = [];
  TaxPermitList: any[] = [];
  selectedItemsTaxPermit = [];
  dropdownTaxPermitFilter = [];
  dropdownSettingsTaxPermitFilter = {};
  dropdownSettingsResetPasswordFilter = {};
  companyName: string;
  priceQuoteId: number;
  isUsIdentificationSocialSecurityNumber: boolean = false;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute,
    private globle: Globle, private router: Router, private PricingQuotesMaster: PricingQuotesService, private toastr: ToastrService,
    private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = sessionStorage.getItem('UserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.companyName = sessionStorage.getItem('ClientCompanyName');
    this.titleService.setTitle('Onboarding Information - ' + this.companyName);

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.ClientInformationId = params['id'];
        }
      }

      if (params['priceQuoteId']) {
        if (params['priceQuoteId'] != null) {
          this.priceQuoteId = params['priceQuoteId'];
        }
      }
    });

    this.mainUrl = '/admin/PricingQuote/';
    if (this.ClientInformationId != null) {
      this.GetProgressStepDetailsById(this.ClientInformationId);
      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
    }
    else {
      this.globle.cApp.statusModel.isAdditionalCompanyInformation = null;
      this.globle.cApp.statusModel.isSensitiveInformation = null;
      this.globle.cApp.statusModel.isInteserraPortalCredential = null;
      this.globle.cApp.statusModel.isStateSelectionCompleted = null;
      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
    }

  }

  ngOnInit() {

    this.dropdownSettingsTradedCompanyFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsCompanyTypeFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsLLCTypeFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsCorporationTypeFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsTaxPermitFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsResetPasswordFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsUSIdentificationFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingsReferralRegisteredAgentFilter = {
      singleSelection: true,
      text: "Select",
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Data available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.dropdownSettingMonths = {
      singleSelection: true,
      text: "Select Month",
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 1,
      noDataLabel: 'No Month',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.BindDropdown();

    this.CompanyProfileInformationForm = this._fb.group({
      clientInformationId: this.ClientInformationId,
      additionalCompanyInformationId: 0,
      tollFreeNo: ['', Validators.required],
      faxNo: ['', Validators.required],
      accountingYearEnd: ['', Validators.required],
      underlyingCarriers: ['', Validators.required],
      copyOfTheirArticlesOfIncorporation: ['', Validators.required],
      copyOfTheirArticlesOfIncorporationPath: [''],
      copyOfTheirArticlesOfIncorporationDocName: [''],
      customerServiceAndComplainContact: ['', Validators.required],
      emergencyContact: ['', Validators.required],
      llcmanagementMemberOwnership: ['', Validators.required],
      corporationOfficeOwnership: [''],
      stockInformation: [''],
      companyContactPerson: ['', Validators.required],
      personToReceiveEscrowFundingRequests: ['', Validators.required],
      inteserraRegulatoryContact: ['', Validators.required],
      governmentRegulatoryContact: ['', Validators.required],
      taxContactForInteserraPointOfContact: ['', Validators.required],
      serviceOfProcessContactEmails: ['', Validators.required],
      ifOther: [''],
      copyOfTheirArticlesOfIncorporationFullPath: [''],
      fRNPassword: [''],
      needYouTaxPermits: [false, Validators.required],
      years: [''],
      nameTheFirmThatIsCurrentlyHandlingYourCompliance:['']
    })

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.ClientInformationId = params['id'];
          this.GetAdditionalInformation(this.ClientInformationId);
        }
      }
    });

  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  BindDropdown() {

    this.dropdownTradedCompanyFilter = [];
    this.dropdownTradedCompanyFilter.push({ "id": 1, "itemName": "Yes" })
    this.dropdownTradedCompanyFilter.push({ "id": 2, "itemName": "No" })

    this.dropdownCompanyTypeFilter = [];
    this.dropdownCompanyTypeFilter.push({ "id": 1, "itemName": "Limited Liability Company" })
    this.dropdownCompanyTypeFilter.push({ "id": 2, "itemName": "Corporation" })
    this.dropdownCompanyTypeFilter.push({ "id": 3, "itemName": "Other" })

    this.dropdownLLCTypeFilter = [];
    this.dropdownLLCTypeFilter.push({ "id": 1, "itemName": "Member Managed" })
    this.dropdownLLCTypeFilter.push({ "id": 2, "itemName": "Manager Managed" })

    this.dropdownCorporationTypeFilter = [];
    this.dropdownCorporationTypeFilter.push({ "id": 1, "itemName": "S Corp" })
    this.dropdownCorporationTypeFilter.push({ "id": 2, "itemName": "C Corp" })

    this.dropdownResetPasswordFilter = [];
    this.dropdownResetPasswordFilter.push({ "id": 1, "itemName": "Yes" })
    this.dropdownResetPasswordFilter.push({ "id": 2, "itemName": "No" })

    this.dropdownTaxPermitFilter = [];
    this.dropdownTaxPermitFilter.push({ "id": 1, "itemName": "Yes" })
    this.dropdownTaxPermitFilter.push({ "id": 2, "itemName": "No" })
    this.dropdownTaxPermitFilter.push({ "id": 3, "itemName": "Not Sure" })

    this.dropdownUSIdentificationFilter = [];
    this.dropdownUSIdentificationFilter.push({ "id": 1, "itemName": "Yes" })
    this.dropdownUSIdentificationFilter.push({ "id": 2, "itemName": "No" })

    this.dropdownReferralRegisteredAgentFilter = [];
    this.dropdownReferralRegisteredAgentFilter.push({ "id": 1, "itemName": "Yes" })
    this.dropdownReferralRegisteredAgentFilter.push({ "id": 2, "itemName": "No" })

    this.dropdownMonths = [];
    this.dropdownMonths.push({ 'id': 1, 'itemName': 'January' });
    this.dropdownMonths.push({ 'id': 2, 'itemName': 'February' });
    this.dropdownMonths.push({ 'id': 3, 'itemName': 'March' });
    this.dropdownMonths.push({ 'id': 4, 'itemName': 'April' });
    this.dropdownMonths.push({ 'id': 5, 'itemName': 'May' });
    this.dropdownMonths.push({ 'id': 6, 'itemName': 'June' });
    this.dropdownMonths.push({ 'id': 7, 'itemName': 'July' });
    this.dropdownMonths.push({ 'id': 8, 'itemName': 'August' });
    this.dropdownMonths.push({ 'id': 9, 'itemName': 'September' });
    this.dropdownMonths.push({ 'id': 10, 'itemName': 'October' });
    this.dropdownMonths.push({ 'id': 11, 'itemName': 'November' });
    this.dropdownMonths.push({ 'id': 12, 'itemName': 'December' });
  }

  onSelectCompanyType($event) {
    if ($event.id == 1) {
      this.IsLLCSelected = true;
      this.IsCorporationSelected = false;
      this.IsOther = false;
    }
    else if ($event.id == 2) {
      this.IsLLCSelected = false;
      this.IsCorporationSelected = true;
      this.IsOther = false;
    }
    else {
      this.IsLLCSelected = false;
      this.IsCorporationSelected = false;
      this.IsOther = true;
    }
  }

  onDeSelectCompanyType($event) {
    this.IsLLCSelected = false;
    this.IsCorporationSelected = false;
    this.IsOther = false;
  }


  onDeSelectCompanyTypeAll($event) {
    this.selectedCompanyType = [];
    this.IsLLCSelected = false;
    this.IsCorporationSelected = false;
    this.IsOther = false;
  }

  onDeSelectTradedCompany($event) {
    this.IsLLCSelected = false;
    this.IsCorporationSelected = false;
    this.selectedTradedCompanyItem = [];
  }

  onSelectTradedCompany($event) {
    if ($event.id == 1) {
      this.isEnableTradedCompnay = true;
      this.CompanyProfileInformationForm.patchValue({
        needAssistWithTradedCompany: true
      });
    }
    else {
      this.isEnableTradedCompnay = false;
      this.CompanyProfileInformationForm.patchValue({
        needAssistWithTradedCompany: false
      });
    }
  }

  onDeSelectTradedCompanyAll($event) {
    this.selectedTradedCompanyItem = [];
    this.isEnableTradedCompnay = false;
    this.CompanyProfileInformationForm.patchValue({
      needAssistWithTradedCompany: false
    });
  }

  onSelectUSIdentification($event) {
    if ($event.id == 1) {
      this.isUsIdentificationSocialSecurityNumber = true;
    }
    else {
      this.isUsIdentificationSocialSecurityNumber = false;
    }
  }

  onDeSelectUSIdentification($event) {
    this.selectedUSIdentificationItem=[];
    this.isUsIdentificationSocialSecurityNumber = false;
  }

  onDeSelectUSIdentificationAll($event) {
    this.selectedUSIdentificationItem=[];
    this.isUsIdentificationSocialSecurityNumber = false;
  }

  onSelectReferralRegisteredAgent($event) {
  }

  onDeSelectReferralRegisteredAgent($event) {
    this.selectedReferralRegisteredAgentItem = [];
  }

  onDeSelectReferralRegisteredAgentAll($event) {
    this.selectedReferralRegisteredAgentItem = [];
  }

  onSelectedMonths($event) {
    this.listOfMonths = [];
    this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event.id)
    this.listOfMonths.push($event.id);
  }

  onDeSelectedMonths($event) {
    this.listOfMonths = [];
    if (this.listOfMonths != null) {
      if (this.listOfMonths.length > 0) {
        this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event.id)
      }
    }
  }

  onSelectAllMonths($event) {
    this.listOfMonths = [];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfMonths != null) {
        if (this.listOfMonths.length > 0) {
          this.listOfMonths = this.removeElemetninArrayById(this.listOfMonths, $event[iCounter].id)
          this.listOfMonths.push($event[iCounter].id);
        }
        else {
          this.listOfMonths.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllMonths($event) {
    this.listOfMonths = [];
  }

  onSelectLLCType($event) {

  }

  onDeSelectLLCType($event) {
    this.selectedLLCType = [];
  }

  onDeSelectLLCTypeAll($event) {
    this.selectedLLCType = [];
  }

  onSelectCorporationType($event) {

  }

  onDeSelectCorporationType($event) {
    this.selectedCorporationType = [];
  }

  onDeSelectCorporationTypeAll($event) {
    this.selectedCorporationType = [];
  }

  activeTab(url: string, tabId: number) {

    if (this.ClientInformationId != null) {
      if (checkStepIsCompletedPhase3(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url + '/' + this.ClientInformationId + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl + '/' + this.ClientInformationId + '/' + this.priceQuoteId;
        this.router.navigate([navigate]);
      }
    }
    else {
      if (checkStepIsCompletedPhase3(url, this.globle.cApp.statusModel)) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + url;
        this.router.navigate([navigate]);
      }

      this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
      if (url == this.subUrl) {
        this.currentActiveTab = tabId;
        let navigate = this.mainUrl + this.subUrl;
        this.router.navigate([navigate]);
      }
    }
  }

  GetProgressStepDetailsById(clientInformationId: number) {
    this.PricingQuotesMaster.GetProgressStepDetailsByClientInformationId(this.tokenKey, clientInformationId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel = result['progress'];
          this.subUrl = getIncompletedStepPhase3(this.globle.cApp.statusModel);
          this.activeTab('add-company-info', 10);
        }
      }
    );
  }


  onSelectTaxPermit($event) {
    if ($event.id == 1) {
      this.CompanyProfileInformationForm.patchValue({
        needYouTaxPermits: true
      });
    }
    else {
      this.CompanyProfileInformationForm.patchValue({
        needYouTaxPermits: false
      });
    }
  }

  onDeSelectTaxPermitAll($event) {
    this.selectedItemsTaxPermit = [];
    this.CompanyProfileInformationForm.patchValue({
      needYouTaxPermits: false
    });
  }

  save(clientForm: any) {

    var isResetPassword = null;
    var isbillingprovider = false, istaxRatingProvider = false, isTaxandCompliance = false;

    this.isSubmit = true;
    let data = clientForm.value;
    if (this.selectedCompanyType.length > 0) {
      if (this.selectedCompanyType[0]['id'] == 1) {
        if (this.selectedLLCType.length > 0) {
          this.LLCType = this.selectedLLCType[0]['id'];
          this.selectedCorporationType = [];
          data.corporationOfficeOwnership = '';
          data.stockInformation = '';
        }
      }
      else if (this.selectedCompanyType[0]['id'] == 2) {
        if (data.corporationOfficeOwnership == null || data.corporationOfficeOwnership == '' || data.corporationOfficeOwnership == undefined) {
          this.CompanyProfileInformationForm.patchValue({
            corporationOfficeOwnership: ""
          });
          if (data.stockInformation == null) {
            this.CompanyProfileInformationForm.patchValue({
              stockInformation: ""
            });
          }
        }
        if (data.stockInformation == null || data.stockInformation == '' || data.stockInformation == undefined) {
          this.CompanyProfileInformationForm.patchValue({
            stockInformation: ""
          });
        }

        if (this.selectedCorporationType.length > 0) {
          this.CorporationType = this.selectedCorporationType[0]['id'];
          this.LLCType = 0;
          this.selectedLLCType = [];
          data.llcmanagementMe4mberOwnership = '';
        }
      }
    }
    else {
      this.selectedLLCType = [];
      this.selectedCorporationType = [];
      data.llcmanagementMemberOwnership = '';
      data.corporationOfficeOwnership = '';
      data.stockInformation = '';
    }

    var PubliclyTradedCompany, CompanyType, UsIdentificationId, referalRegistrationId, MonthId

    if (this.selectedCompanyType.length > 0) {
      CompanyType = this.selectedCompanyType[0]['id'];
    }

    let formData = new FormData();
    formData.append('AdditionalCompanyInformationId', data.additionalCompanyInformationId || 0);
    formData.append('ClientInformationId', data.clientInformationId || 0);
    formData.append('TollFreeNo', data.tollFreeNo || '');
    formData.append('FaxNo', data.faxNo || '');
    formData.append('AccountingYearEnd', data.accountingYearEnd || '');
    formData.append('UnderlyingCarriers', data.underlyingCarriers || '');

    if (this.selectedTradedCompanyItem.length > 0) {
      formData.append('PubliclyTradedCompany', this.selectedTradedCompanyItem[0]['id'] || 0);
    }
    else {
      formData.append('PubliclyTradedCompany', null);
    }

    formData.append('CompanyType', CompanyType || '');
    formData.append('LLCManaged', this.LLCType.toString() || '');
    formData.append('LLCManagementMemberOwnership', data.llcmanagementMemberOwnership || '');
    formData.append('TypeOfCorporation', this.CorporationType.toString() || '');
    formData.append('CorporationOfficeOwnership', data.corporationOfficeOwnership || '');
    formData.append('StockInformation', data.stockInformation || '');
    formData.append('CustomerServiceAndComplainContact', data.customerServiceAndComplainContact || '');
    formData.append('EmergencyContact', data.emergencyContact || '');
    formData.append('CompanyContactPerson', data.companyContactPerson || '');
    formData.append('PersonToReceiveEscrowFundingRequests', data.personToReceiveEscrowFundingRequests || '');
    formData.append('InteserraRegulatoryContact', data.inteserraRegulatoryContact || '');
    formData.append('GovernmentRegulatoryContact', data.governmentRegulatoryContact || '');
    formData.append('TaxContactForInteserraPointOfContact', data.taxContactForInteserraPointOfContact || '');
    formData.append('ServiceOfProcessContactEmails', data.serviceOfProcessContactEmails || '');
    formData.append('CreatedBy', this.userID || 0);
    formData.append('IfOther', data.ifOther || '');
    formData.append('Year', data.years);
    formData.append('nameTheFirmThatIsCurrentlyHandlingYourCompliance',data.nameTheFirmThatIsCurrentlyHandlingYourCompliance);
    // formData.append('articlesInfo', this.articlesInfo.nativeElement.files[0] || '');
    // formData.append('copyOfTheirArticlesOfIncorporationPath', data.copyOfTheirArticlesOfIncorporationPath || '');
    // formData.append('copyOfTheirArticlesOfIncorporationDocName', data.copyOfTheirArticlesOfIncorporationDocName || '');

    if (this.selectedUSIdentificationItem.length > 0) {
      UsIdentificationId = this.selectedUSIdentificationItem[0]['id'];
    }

    formData.append('IsUSIdentficationNumber', UsIdentificationId || '');

    if (this.selectedReferralRegisteredAgentItem.length > 0) {
      referalRegistrationId = this.selectedReferralRegisteredAgentItem[0]['id'];
    }

    formData.append('IsReferralRegisteredAgentItem', referalRegistrationId || '');

    if (this.selectedItemsMonths.length > 0) {
      MonthId = this.selectedItemsMonths[0]['id'];
    }

    formData.append('Month', MonthId || '');

    if (this.selectedItemsResetPassword.length > 0) {
      formData.append('needAssistWithResetPassword', this.selectedItemsResetPassword[0]['id'] || 0);
    }
    else {
      formData.append('needAssistWithResetPassword', null);
    }

    formData.append('fRNPassword', data.fRNPassword || '');
    if (this.selectedItemsTaxPermit.length > 0)
      formData.append('needYouTaxPermits', this.selectedItemsTaxPermit[0]['id'] || 0);
    else
      formData.append('needYouTaxPermits', null);
    this.blockDocument();
    this.PricingQuotesMaster.AdditionalCompanyInformationAddUpdate(this.tokenKey, formData).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.globle.cApp.statusModel.isAdditionalCompanyInformation = true;
          this.unblockDocument();
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (result['clientInformationId'] != 0) {
              let navigation = '/admin/' + this.SelectedTool + '/sensitive-info/' + result['clientInformationId'] + "/" + this.priceQuoteId;
              this.router.navigate([navigation]);
            }
          }
        }
        else {
          this.unblockDocument();
          this.showMessage(result['status'], result['message'], 'error');
        }
      },
      error => {
        this.unblockDocument();
        this.showMessage(error, 'Error', 'error');
      }
    );
  }


  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }


  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }


  GetAdditionalInformation(clientInformationId: number) {
    this.blockDocument()
    this.PricingQuotesMaster.GetAdditionalInformationById(this.tokenKey, parseInt(clientInformationId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.unblockDocument();
          if (result['clientInformation'] != null) {
            this.CompanyProfileInformationForm.patchValue({
              clientInformationId: this.ClientInformationId,
              additionalCompanyInformationId: result['clientInformation'].additionalCompanyInformationId,
              tollFreeNo: result['clientInformation'].tollFreeNo,
              faxNo: result['clientInformation'].faxNo,
              accountingYearEnd: result['clientInformation'].accountingYearEnd,
              underlyingCarriers: result['clientInformation'].underlyingCarriers,
              copyOfTheirArticlesOfIncorporation: result['clientInformation'].copyOfTheirArticlesOfIncorporation,
              copyOfTheirArticlesOfIncorporationPath: result['clientInformation'].copyOfTheirArticlesOfIncorporationPath,
              copyOfTheirArticlesOfIncorporationDocName: result['clientInformation'].copyOfTheirArticlesOfIncorporationDocName,
              customerServiceAndComplainContact: result['clientInformation'].customerServiceAndComplainContact,
              emergencyContact: result['clientInformation'].emergencyContact,
              llcmanagementMemberOwnership: result['clientInformation'].llcManagementMemberOwnership,
              corporationOfficeOwnership: result['clientInformation'].corporationOfficeOwnership,
              stockInformation: result['clientInformation'].stockInformation,
              companyContactPerson: result['clientInformation'].companyContactPerson,
              personToReceiveEscrowFundingRequests: result['clientInformation'].personToReceiveEscrowFundingRequests,
              inteserraRegulatoryContact: result['clientInformation'].inteserraRegulatoryContact,
              governmentRegulatoryContact: result['clientInformation'].governmentRegulatoryContact,
              taxContactForInteserraPointOfContact: result['clientInformation'].taxContactForInteserraPointOfContact,
              serviceOfProcessContactEmails: result['clientInformation'].serviceOfProcessContactEmails,
              ifOther: result['clientInformation'].ifOther,
              copyOfTheirArticlesOfIncorporationFullPath: result['clientInformation'].copyOfTheirArticlesOfIncorporationFullPath,
              fRNPassword: result['clientInformation'].frnPassword,
              years: result['clientInformation'].year,
              nameTheFirmThatIsCurrentlyHandlingYourCompliance:result['clientInformation'].nameTheFirmThatIsCurrentlyHandlingYourCompliance
            });

            this.selectedItemsTaxPermit = [];
            if (result['clientInformation'].needYouTaxPermits != null) {
              let data = this.dropdownTaxPermitFilter.filter(x => x.id == result['clientInformation'].needYouTaxPermits)[0];
              if (data != null) {
                this.selectedItemsTaxPermit.push({ "id": data.id, "itemName": data.itemName })
              }
            }

            this.selectedItemsResetPassword = [];
            if (result['clientInformation'].needAssistWithResetPassword != null) {
              this.isEnableCurrentlyResetingPassword = result['clientInformation'].needAssistWithResetPassword;
              if (result['clientInformation'].needAssistWithResetPassword == true) {
                this.selectedItemsResetPassword.push({ "id": 1, "itemName": "Yes" })
              }
              else { this.selectedItemsResetPassword.push({ "id": 2, "itemName": "No" }) }
            }

            this.selectedUSIdentificationItem = [];
            if (result['clientInformation'].isUSIdentficationNumber != null) {
              if (result['clientInformation'].isUSIdentficationNumber == true) {
                this.selectedUSIdentificationItem.push({ "id": 1, "itemName": "Yes" })
                this.isUsIdentificationSocialSecurityNumber = true;
              }
              else {
                this.selectedUSIdentificationItem.push({ "id": 2, "itemName": "No" })
                this.isUsIdentificationSocialSecurityNumber = false;
              }
            }
            else{
              this.isUsIdentificationSocialSecurityNumber=false;
            }



            this.selectedReferralRegisteredAgentItem = [];
            if (result['clientInformation'].isReferralRegisteredAgentItem != null) {
              if (result['clientInformation'].isReferralRegisteredAgentItem == true) {
                this.selectedReferralRegisteredAgentItem.push({ "id": 1, "itemName": "Yes" })
              }
              else { this.selectedReferralRegisteredAgentItem.push({ "id": 2, "itemName": "No" }) }
            }

            this.selectedItemsMonths = [];
            if (result['clientInformation'].month != null) {
              let data = this.dropdownMonths.filter(x => x.id == result['clientInformation'].month)[0];
              if (data != undefined) {
                this.selectedItemsMonths.push({ "id": data.id, "itemName": data.itemName });
              }
            }

            this.filePath1 = result['clientInformation'].copyOfTheirArticlesOfIncorporationFullPath;

            this.selectedTradedCompanyItem = [];
            if (result['clientInformation'].publiclyTradedCompany != null) {
              if (result['clientInformation'].publiclyTradedCompany == true) {
                this.selectedTradedCompanyItem.push({ "id": 1, "itemName": "Yes" })
              }
              else { this.selectedTradedCompanyItem.push({ "id": 2, "itemName": "No" }) }
            }

            if (this.dropdownCompanyTypeFilter.length > 0) {
              this.selectedCompanyType = [];
              let data = this.dropdownCompanyTypeFilter.filter(x => x.id == result['clientInformation'].companyType)[0];
              if (data != undefined) {
                this.selectedCompanyType.push({ "id": data.id, "itemName": data.itemName });
              }
            }

            if (result['clientInformation'].companyType == 1) {
              this.IsLLCSelected = true;
              this.IsCorporationSelected = false;
              this.IsOther = false;

              if (this.dropdownLLCTypeFilter.length > 0) {
                this.selectedLLCType = [];
                let data = this.dropdownLLCTypeFilter.filter(x => x.id == result['clientInformation'].llcManaged)[0];
                if (data != undefined) {
                  this.selectedLLCType.push({ "id": data.id, "itemName": data.itemName });
                }
              }
            }
            else if (result['clientInformation'].companyType == 2) {
              this.IsLLCSelected = false;
              this.IsCorporationSelected = true;
              this.IsOther = false;

              if (this.dropdownCorporationTypeFilter.length > 0) {
                this.selectedCorporationType = [];
                let data = this.dropdownCorporationTypeFilter.filter(x => x.id == result['clientInformation'].typeOfCorporation)[0];
                if (data != undefined) {
                  this.selectedCorporationType.push({ "id": data.id, "itemName": data.itemName });
                }
              }
            }
            else {
              this.IsLLCSelected = false;
              this.IsCorporationSelected = false;
              this.IsOther = true;
            }
          }
        }
      });
  }

  onSelectResetPassword($event) {

  }

  onDeSelectResetPasswordAll($event) {
    this.selectedItemsResetPassword = [];
  }

  backtoquoteList() {
    let navigation = '/admin/' + this.SelectedTool + '/pricing-quotes'
    this.router.navigate([navigation]);
  }

  removeElemetninArrayById(array, element) {
    return array.filter(e => e.id !== element);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}