import { Component, OnInit, ElementRef } from '@angular/core';
import { HistoryModel } from '../../../../Models/historyModel';
import { HistoryService } from '../../../../Services/history.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { AppConfig } from '../../../../Services/app.config';
import { CheckAuth } from '../../../../Services/utility.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  userID: number = 0;
  totalPageSize: number = 0;
  pageSize: number = 0;
  pageIndex: number = 0;
  orderBy: string = 'null';
  keywordFilter: string = '';
  whereCondition: string = 'null';
  isAscending: boolean;  
  appModel: any;
  statusCode: number = 0;
  upDowUpdateFileNameCSS: string = '';
  isloading: boolean = false;
  tokenKey: string = '';
  historyModel: HistoryModel[] = [];
  isRecords: boolean = false;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  totalRecordsCount: number = -1;  
  SelectedTool: string;
  constructor(private historyService: HistoryService, private toastr: ToastrService
    , private titleService: Title, private checkAuth: CheckAuth
    , private router: Router) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('IPR History');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    this.movetotop();
    this.pageSize =10;
    this.totalPageSize = 1;
    this.pageIndex = 1;
    this.keywordFilter = 'null';
    this.whereCondition = 'null';
    this.orderBy = 'IL.CreatedDate DESC';
    this.GetIPRHistory();
  }
  GetIPRHistory(): void {
    this.isloading = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.historyService.GetIPRHistory(this.tokenKey, this.pageIndex.toString(), this.pageSize.toString(), this.whereCondition, this.orderBy, this.keywordFilter).subscribe(
      data => {
        this.isloading = false;
        if (data["status"] == "Success") {
          this.historyModel = data["history"];
          if (this.historyModel.length > 0) {
            this.isRecords = true;
            this.totalPageSize = parseInt(this.historyModel[0].totalRecordCount);
            this.totalRecordsCount = this.historyModel.length;            
          }
          else {
            this.totalRecordsCount = 0;
            this.isRecords = false;
          }
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }
  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';

      if (sortBy === "ImportFileName") {
        this.upDowUpdateFileNameCSS = 'fa fa-arrow-down';        
      }
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      if (sortBy === "ImportFileName") {
        this.upDowUpdateFileNameCSS = 'fa fa-arrow-up';        
      }

    }
    this.GetIPRHistory();
  }
  pageChanged($event) {
    this.pageIndex = parseInt($event.page);
    this.GetIPRHistory();
  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }
  keywordFilters($event) {
    this.pageIndex = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.keywordFilter = $event.target.value;
    else
      this.keywordFilter = 'null';

    this.GetIPRHistory();
  }  
}
