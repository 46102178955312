import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AddLocation911Model, AddNewLocation911Model } from 'src/Models/Location911Model';

@Injectable({
  providedIn: 'root'
})
export class Location911Service {

  AppUrl: string = "";
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }

  GetAllLocationByRateId(TokenKey: string, rateId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/location/GetAllLocationByRateId/' + rateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  CreateLocationByRateId(TokenKey: string, addNewActionModel: AddLocation911Model): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/location/AddLocationsByRateId';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteLocation(TokenKey: string, locationId: number[]): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/location/DeleteLocation';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, locationId, httpOptions)
      .pipe(catchError(this.handleError));
  }

  addNewLocation(TokenKey: string, addNewActionModel: AddNewLocation911Model): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/location/AddNewLocation';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}
