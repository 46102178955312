import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseRateTypeModel } from 'src/Models/BaseRateTypeModel';
import { PricingQuotingBaserateService } from 'src/Services/pricing-quoting-baserate.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-base-rate-type',
  templateUrl: './base-rate-type.component.html',
  styleUrls: ['./base-rate-type.component.css']
})
export class BaseRateTypeComponent implements OnInit {

 
  stateCode: any;
  tokenKey: string;
  listofBaseRate: BaseRateTypeModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  BaseRate: string;


  upDowBaseRateCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  baseRateTypeId:number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private BaseRateMaster: PricingQuotingBaserateService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService) {
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofBaseRate = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'BaseRateTypeName';
    this.BaseRate = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Base Rate Type');
     }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetBaseRateType();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  
  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetBaseRateType();
  }

  GetBaseRateType() { 
      if (this.BaseRate == "null") this.BaseRate = " ";
      this.BaseRateMaster.GetAllBaseRateType(this.tokenKey, this.PageNumber, this.PageSize, this.BaseRate, this.OrderBy).subscribe(
        data => {
          this.isloading = false;
          this.listofBaseRate = data['baseRateType'];
          if (this.listofBaseRate != null) {
            if (this.listofBaseRate.length == 0)
              this.isRecord = true;
            else {
              this.isRecord = false;
              this.totalRecordCount = this.listofBaseRate[0].totalRecordCount;
            }
          }
          else
            this.isRecord = true;
        },
        error => { this.stateCode = error }
      );
    
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.BaseRate = $event.target.value;
    else
      this.BaseRate = 'null';

    this.GetBaseRateType();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "BaseRateTypeName") {
        this.upDowBaseRateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "BaseRateTypeName") {
        this.upDowBaseRateCSS = 'fa fa-arrow-up';
      }
    }
    this.GetBaseRateType();
  }

  addBaseRateType() {
    let navigation = '/admin/' + this.SelectedTool + '/baseRateType/manage';
    this.router.navigate([navigation]);
  }

  updateBaseRateType(baseRateTypeId) {
    let navigation = '/admin/' + this.SelectedTool + '/baseRateType/manage/' + baseRateTypeId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.baseRateTypeId = id;
  }


  deleteBaseRateType(baseRateTypeId) {
    this.isloading = true;
    this.BaseRateMaster.DeleteBaseRateType(this.tokenKey, baseRateTypeId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
            this.showMessage("Base Rate Type Deleted successfully", 'Success', 'success')
        }
        this.GetBaseRateType();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }


}
