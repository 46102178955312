import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from 'src/Models/Common';
import { StateCommissionModel } from 'src/Models/StateCommissionModel';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { I360Service } from 'src/Services/i360Service';
import { StateMasterService } from 'src/Services/state-master.service';
import { Params, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage-state-commission',
  templateUrl: './manage-state-commission.component.html',
  styleUrls: ['./manage-state-commission.component.css']
})
export class ManageStateCommissionComponent implements OnInit {
  stateCommissionModel = new StateCommissionModel();


  stateCommissionForm: FormGroup;
  loginuserID: number = 0;

  StateCommissionModel: StateCommissionModel = {
    stateCommissionId: 0,
    stateId: '',
    title: '',
    url: '',
    isActive: ''
  };
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  tempSelectedStateId: number = 0;
  stateCommissionId: number = 0;
  isMultipleClick: boolean = false;
  constructor(private activedRoute: ActivatedRoute, private _fb: FormBuilder, private router: Router, private checkAuth: CheckAuth, private titleService: Title,
    private i360service: I360Service,
    private stateMasterService: StateMasterService,
    private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage State Commissions');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    this.stateCommissionForm = this._fb.group({
      stateCommissionId: 0,
      stateId: '',
      title: '',
      url: ['', [Validators.required, Validators.pattern(reg)]],
      isActive: true
    });
  }

  // Getter for easy access
  get url() { return this.stateCommissionForm.get('url') };

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
         
          this.stateCommissionId = params['id'];
          this.GetStateCommissionData();
        }
        else {
          this.GetStateData();
        }
      }
      else {
        this.GetStateData();
      }
    });

  }

  GetStateCommissionData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.i360service.i360StateCommissionWebSitesFindbyStateCommissionId(this.tokenKey, this.stateCommissionId).subscribe(
      data => {
        if (data != null) {
          this.stateCommissionModel = data["stateCommissionData"];
          if (this.StateCommissionModel != null) {
            this.stateCommissionForm.setValue({
              stateCommissionId: this.stateCommissionModel.stateCommissionId,
              stateId: this.stateCommissionModel.stateId,
              title: this.stateCommissionModel.title,
              url: this.stateCommissionModel.url,
              isActive: this.stateCommissionModel.isActive
            });
            this.listOfStateIds = [];
            this.listOfStateIds.push(this.stateCommissionModel.stateId);
            this.tempSelectedStateId = this.stateCommissionModel.stateId;
          }
          this.GetStateData();
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  GetStateData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.stateMasterService.getState(this.tokenKey, this.productId).subscribe(
      data => {
        if (data["state"] != null) {
          // Set All Item
          for (let iCounter = 0; iCounter < data["state"].length; iCounter++) {
            this.dropdownListState.push(
              { "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name }
            );
          }

          this.selectedItemsState = [];
          if (this.tempSelectedStateId > 0) {
            let record = data['state'].filter(m => m.stateId == this.tempSelectedStateId)
            if (record != null) {
              this.selectedItemsState.push(
                { "id": record[0].stateId, "itemName": record[0].name }
              );
            }
          }
        }
        else {
          this.showMessage(data["message"], 'Error', 'error');
        }
        this.dropdownSettingsState = {
          singleSelection: true,
          text: "Select State Type",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No state available',
          showCheckbox: false
        };
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.listOfStateIds = [];
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
  }

  onSelectAll($event) {
    this.listOfStateIds = [];
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
  }

  saveStateCommission(stateCommissionForm) {
   
    event.preventDefault();

    this.stateCommissionModel = stateCommissionForm.value;

    if (this.stateCommissionModel.title == '' || typeof (this.stateCommissionModel.title) == 'undefined' || this.stateCommissionModel.title == null) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }

    // if (this.listOfStateIds.length == 0) {
    //   this.showMessage('Please select state', 'State Required', 'error');
    //   return;
    // }

    if (this.stateCommissionModel.url == '' || typeof (this.stateCommissionModel.url) == 'undefined' || this.stateCommissionModel.url == null) {
      this.showMessage('Please enter url', 'URL Required', 'error');
      return;
    }

    this.stateCommissionModel.createdBy = this.loginuserID;
    this.stateCommissionModel.modifiedBy = this.loginuserID;
    this.stateCommissionModel.stateId = 0; //this.listOfStateIds.join(',');

    if (!this.stateCommissionForm.valid) {
    
      return;
    }

    if (this.isMultipleClick) {      
      return;
    }
    this.isMultipleClick = true;

    this.i360service.i360StateCommissionWebSitesAddUpdate(this.tokenKey, this.stateCommissionModel).subscribe(
      data => {
       
        this.isMultipleClick = false;
        if (data['stateCommissionId'] != 0) {
          if (data['stateCommissionId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.stateCommissionId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.stateCommissionId = 0;
            this.cancelStateCommission()
          }

        }
      },
      error => {
        this.isMultipleClick = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  
  cancelStateCommission() {
    this.router.navigate(['admin/inteserra360/statecommissions']);
  }
}
