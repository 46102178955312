import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';

@Component({
  selector: 'app-list-of-lifeline-claim-reports',
  templateUrl: './list-of-lifeline-claim-reports.component.html',
  styleUrls: ['./list-of-lifeline-claim-reports.component.css']
})
export class ListOfLifelineClaimReportsComponent implements OnInit {

  constructor( private appActivity: ApplicationActivityService,private router: Router,private titleService: Title) {
    this.titleService.setTitle('Lifeline Claims Reports | Inteserra - i360');
   }

  ngOnInit() {
    
  }

  redirectToImportLifelineReports() {
    ApplicationActivityStore('View', 'Claims Imported-Lifeline',this.appActivity,0, ActionActivity['Inroll Lifeline'],'/Claim/Lifeline-Import');
    this.router.navigate(['Claim/Lifeline-Import']);
  }

  redirectToMonthlyLifelineReports() {
    ApplicationActivityStore('View', 'Monthly Claims-Lifeline',this.appActivity,0, ActionActivity['Inroll Lifeline'],'/Claim/Lifeline-Reports');
    this.router.navigate(['Claim/Lifeline-Reports']);
  }

  redirectToYearlyLifelineReports() {
    ApplicationActivityStore('View', 'Yearly Claims-Lifeline',this.appActivity,0, ActionActivity['Inroll Lifeline'],'/Claim/Lifeline-yearly-Reports');
    this.router.navigate(['Claim/Lifeline-yearly-Reports']);
  }

  redirectToSummaryLifelineReports() {
    ApplicationActivityStore('View', 'Claims Summary-Lifeline',this.appActivity,0, ActionActivity['Inroll Lifeline'],'/Claim/Lifeline-summary-Reports');
    this.router.navigate(['Claim/Lifeline-summary-Reports']);
  }


}
