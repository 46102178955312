import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/Services/users.service';
import { ToastrService } from 'ngx-toastr';
import { MenuGroupModel, CustomMenuGroupModel } from 'src/Models/MenuGroupModel';
import { RedirectService } from 'src/Services/RedirectService';
import { I360Service } from 'src/Services/i360Service';
import { UserModel } from '../../../Models/usersModel';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { IpService } from 'src/Services/ip.service';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import * as $ from 'jquery';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css']
})
export class SiteLayoutComponent implements OnInit {
  appModel: any;
  userID: any;
  isClickedOni360: boolean;
  tokenKey: string = '';
  baseURL: string = '';
  loginName: string = ''
  companyName: string = ''
  dt: string = '';
  menuModel: MenuGroupModel[];
  userModel = new UserModel();
  lastLogin: any;
  userName: string = '';
  newUserName: string = '';
  dynamicURLForActiveMenu: string = '';
  custommenuModel: CustomMenuGroupModel[];
  @ViewChild('search', { static: false }) searchElement: ElementRef;

  constructor(private i360Service: I360Service, private redirectService: RedirectService, private toastr: ToastrService,
    private userService: UsersService, private ipService: IpService,
    private router: Router, @Inject('BASE_URL') baseUrl: string, private appActivity: ApplicationActivityService) {
    this.baseURL = baseUrl;
    this.loginName = sessionStorage.getItem('FirstLastName');
    this.companyName = sessionStorage.getItem('CompanyName');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.dt = Date();
    this.lastLogin = sessionStorage.getItem('LastLogin');
    this.userName = sessionStorage.getItem('FirstLastName');
    this.newUserName = sessionStorage.getItem('UserName');
    if (localStorage.getItem('EncyUserId') != null) {
      this.setLocalStorage();
    }
    if (sessionStorage.getItem('UserManu') != null && sessionStorage.getItem('isLogin') == 'true') {
      this.i360GetUserId(sessionStorage.getItem('EncyUserId'));
      this.menuModel = JSON.parse(sessionStorage.getItem('UserManu'));
      this.custommenuModel = JSON.parse(sessionStorage.getItem('UsercustomManu'));
    }
    else
      this.loadMenu();
  }

  ngOnInit(): void {
    // if (sessionStorage.getItem('UserId') != '' && sessionStorage.getItem('UserId') != undefined && sessionStorage.getItem('UserId') != null) {
    //   this.appModel = AppConfig.settings.ConnectionStrings;
    //   this.userID = +sessionStorage.getItem('UserId');
    // }
    // else {
    //   this.router.navigate(['/index']);
    // }
    if (!sessionStorage.getItem('ipAddress'))
      this.getIP();
  }
  ngAfterViewInit(): void {
    $(".c-hamburger").click(function () {
      $('#cssmenu01').slideToggle('fast');
      if ($(this).hasClass('is-active')) {
        $(this).attr('class', 'c-hamburger c-hamburger--htx');
      } else {
        $(this).attr('class', 'c-hamburger c-hamburger--htx is-active');
      }
      $('.submenutrigger').unbind().click(function () {
        $(this).next('.submenu-wrapper').toggle();
        if ($(this).hasClass('active')) {
          $(this).attr('class', 'submenutrigger');
        } else {
          $(this).attr('class', 'submenutrigger active');
        }
      });
    });
  }

  getIP() {
    let ipAddress = '127.0.0.1';
    this.ipService.getIPAddress().subscribe((res: any) => {
      if (res) {
        if (res._body != null) {
          let obj = JSON.parse(res._body)
          if (obj != null) {
            ipAddress = obj.ip;
          }
        }
      }
      sessionStorage.setItem('ipAddress', ipAddress);
    },
      error => {
        sessionStorage.setItem('ipAddress', ipAddress);
      });
  }

  Logout() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.userService.UserLogout(this.tokenKey, this.userID).subscribe(
      data => {
        sessionStorage.removeItem('UserId');
        //sessionStorage.removeItem('tokenValue');
        sessionStorage.removeItem('appStartTime');
        let isUserByPass = sessionStorage.getItem('isUserByPass');
        sessionStorage.removeItem('isUserByPass');
        sessionStorage.removeItem('ClientLoginTool');
        sessionStorage.removeItem('ClientAccessibleTools');
        this.lastLogin = null;
        sessionStorage.removeItem('LastLogin');
        sessionStorage.removeItem('dashboardSettingFromDate');
        sessionStorage.removeItem('dashboardSettingToDate');
        sessionStorage.removeItem('dashboardSettingIsFavorite');
        sessionStorage.removeItem('dashboardSettingIsMyTracIt');
        sessionStorage.removeItem('userHasTracIt');
        sessionStorage.removeItem('UserManu');
        sessionStorage.removeItem('EncyUserId');
        sessionStorage.removeItem('isLogin')
        localStorage.removeItem('tabEncyUserId');
        localStorage.removeItem('UserId')
        localStorage.removeItem('UserName')
        localStorage.removeItem('LastLogin')
        localStorage.removeItem('clientTypeId')
        localStorage.removeItem('isUserByPass')
        localStorage.removeItem('FirstLastName')
        localStorage.removeItem('userHasTracIt')
        localStorage.removeItem('ClientAccessibleTools')
        localStorage.removeItem('CompanyId')
        localStorage.removeItem('IsICalSubscription')
        localStorage.removeItem('EncyUserId')
        localStorage.removeItem('CompanyName')
        localStorage.removeItem('isLogin')
        localStorage.removeItem('UserManu')
        this.userID = 0;
        this.loadMenu();
        if (isUserByPass == 'AutoLogin') {
          window.location.href = this.baseURL + 'Logout.aspx';
        }
        else {
          ApplicationActivityStore('Add', 'Logout', this.appActivity, 0, ActionActivity.Logout, '');
          this.router.navigate(['/login']);
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  redirectToURL(url, isLocked, displayName, externalProductName, productId) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    sessionStorage.setItem('isLocked', isLocked);
    if (isLocked || url == null) {
      //var ints = displayName.split('').map(this.ascii);
      if (displayName.indexOf('Tariffs.') > -1) {
        displayName = 'TariffsNet';
      }
      let para = encodeURI(displayName.replace(/\s+/g, "-"));
      if (sessionStorage.getItem('isLogin') == 'true') {
        url = '/Access/Denied/' + para;
      }
      else
        url = '/login';
    }

    //this.applicationActivity('View', displayName, productId, url);

    if (url != null) {
      if (url.indexOf('.') > -1) {
        //window.location.href = url;
        if (url.indexOf('/services') > -1 || url.indexOf('/compliance') > -1 || url.indexOf('/client') > -1) {
          this.applicationActivity(displayName, url);
          window.open(url);
        }
        else {
          this.i360Service.GenerateByPassAuthenticationKey(this.tokenKey).subscribe(
            data => {

              if (data != null) {
                if (data['status'] == 'Success') {
                  let encyHasKey = data['encyHasKey'];
                  if (data['encyHasKey'] != null) {
                    let userName = sessionStorage.getItem('UserName');
                    if (externalProductName == 'Trac It') {
                      externalProductName = 'ActionItem'
                    }
                    // let userName = 'DemoVID';// sessionStorage.getItem('UserName');
                    let param = { username: userName, password: encyHasKey, productname: externalProductName };
                    this.applicationActivity(displayName, url);
                    this.redirectService.post(param, url);
                  }
                }
              }
            },
            error => {
              console.log(error);
            }
          );
        }
      }
      else {
        this.dynamicURLForActiveMenu = url;
        sessionStorage.setItem('displayName', displayName);
        sessionStorage.setItem('productId', productId);
        this.applicationActivity(displayName, url);
        this.router.navigate([url]);
      }
    }
  }

  applicationActivity(displayName, url) {
    if (displayName == 'Transactional Tax Login') {
      ApplicationActivityStore('View', 'Transactional Tax Login', this.appActivity, 0, ActionActivity['Transactional Tax Login'], url);
    }
    else if (displayName == 'Robocall Mitigation Database') {
      ApplicationActivityStore('View', 'Robocall Mitigation Database', this.appActivity, 0, ActionActivity['Robocall Mitigation Database'], url);
    }
    else if (displayName == 'Wireless PRO') {
      ApplicationActivityStore('View', 'Wireless PRO', this.appActivity, ToolEnum.wireless, ActionActivity.Wireless, url);
    }
    else if (displayName == 'Rates Management System') {
      ApplicationActivityStore('View', 'Rates Management System', this.appActivity, ToolEnum.ratesManagementSystem, ActionActivity['Rates Management System'], url);
    }
    else if (displayName == 'VoIP PRO') {
      ApplicationActivityStore('View', 'VoIP PRO', this.appActivity, ToolEnum.voip, ActionActivity.VoIP, url);
    }
    else if (displayName == 'CRRA') {
      ApplicationActivityStore('View', 'CRRA', this.appActivity, ToolEnum.crra, ActionActivity.CRRA, url);
    }
    else if (displayName == 'Trac It') {
      ApplicationActivityStore('View', 'Trac It', this.appActivity, 0, ActionActivity['Trac It'], url);
    }
    else if (displayName == 'Local Rate Information') {
      ApplicationActivityStore('View', 'Local Rate Information', this.appActivity, 0, ActionActivity['Local Rate Information'], url);
    }
    else if (displayName == 'IPR') {
      ApplicationActivityStore('View', 'IPR', this.appActivity, ToolEnum.ipr, ActionActivity.IPR, url);
    }
    else if (displayName == 'News') {
      ApplicationActivityStore('View', 'News', this.appActivity, 0, ActionActivity.News, url);
    }
    else if (displayName == 'Commission Websites') {
      ApplicationActivityStore('View', 'Commission Websites', this.appActivity, ToolEnum.commissionWebsites, ActionActivity['Commission Websites'], url);
    }
    else if (displayName == 'Carrier Tariffs') {
      ApplicationActivityStore('View', 'Carrier Tariffs', this.appActivity, ToolEnum.carrierTariffs, ActionActivity['Carrier Tariffs'], url);
    }
    else if (displayName == 'Special Reports') {
      ApplicationActivityStore('View', 'Special Report', this.appActivity, ToolEnum.specialReport, ActionActivity['Special Report'], url);
    }
    else if (displayName == 'Tax Exemption Forms') {
      ApplicationActivityStore('View', 'Tax Exemption Forms', this.appActivity, ToolEnum.TaxExemptionForms, ActionActivity['Tax Exemption Forms'], url);
    }
    else if (displayName == 'Webinar Library') {
      ApplicationActivityStore('View', 'Webinar Library', this.appActivity, ToolEnum.webinar, ActionActivity['Webinar Libary'], url);
    }
    else if (displayName == 'White Papers') {
      ApplicationActivityStore('View', 'White Papers', this.appActivity, ToolEnum.whitepapers, ActionActivity['White Papers'], url);
    }
    else if (displayName == 'Seminars') {
      ApplicationActivityStore('View', 'Seminars', this.appActivity, 0, ActionActivity.Seminars, url);
    }
    else if (displayName == 'Training') {
      ApplicationActivityStore('View', 'Training', this.appActivity, ToolEnum.training, ActionActivity.Training, url);
    }
    else if (displayName == 'My Library') {
      ApplicationActivityStore('View', 'My Library', this.appActivity, 0, ActionActivity['My Library'], url);
    }
    else if (displayName == 'Company Directory') {
      ApplicationActivityStore('View', 'Company Directory', this.appActivity, 0, ActionActivity['Company Directory'], url);
    }
    else if (displayName == 'Change Password') {
      ApplicationActivityStore('View', 'Change Password', this.appActivity, 0, ActionActivity['Change Password'], url);
    }
    else if (displayName == 'Briefing Subscription Services') {
      ApplicationActivityStore('View', 'Briefing Subscription Services', this.appActivity, ToolEnum.briefingServices, ActionActivity['Briefing Services'], url);
    }
    else if (displayName == 'Telecom Regulatory Fees - 911') {
      ApplicationActivityStore('View', 'Telecom Regulatory Fees - 911', this.appActivity, ToolEnum.state911, ActionActivity.State911, url);
    }
    else if (displayName == 'Exemption Certificates') {
      ApplicationActivityStore('View', 'Exemption Certificates', this.appActivity, ToolEnum.exemptionCertificates, ActionActivity['Exemption Certificates'], url);
    }
    else if (displayName == 'CPCN Certificates') {
      ApplicationActivityStore('View', 'CPCN Certificates', this.appActivity, ToolEnum.cpcnCertificates, ActionActivity['CPCN Certificates'], url);
    }
    else if (displayName == 'Secretary of States Authorization') {
      ApplicationActivityStore('View', 'Secretary of States Authorization', this.appActivity, ToolEnum.secretaryofStatesAuthorization, ActionActivity['Secretary of States Authorization'], url);
    }
    else if (displayName == 'Tariffs.Net') {
      ApplicationActivityStore('View', 'Tariffs.Net', this.appActivity, ToolEnum.tarrifsnet, ActionActivity['Tariffs.net'], url);
    }
    else if (displayName == 'My Files') {
      ApplicationActivityStore('View', 'My Files', this.appActivity, 0, ActionActivity['My Files'], url);
    }
    else if (displayName == 'Your Subscriptions') {
      ApplicationActivityStore('View', 'Your Subscriptions', this.appActivity, 0, 0, url);
    }
    else if (displayName == 'Regulatory Management System (REMS) Login') {
      ApplicationActivityStore('View', 'Regulatory Management System (REMS) Login', this.appActivity, 0, ActionActivity.REMS, url);
    }
    else if (displayName == 'Inroll Claims ACP') {
      ApplicationActivityStore('View', 'ACP Claims Reports', this.appActivity, ToolEnum.inrollACP, ActionActivity['Inroll ACP'], url);
    }
    else if (displayName == 'Inroll Claims Lifeline') {
      ApplicationActivityStore('View', 'Lifeline Claims Reports', this.appActivity, ToolEnum.inrollACP, ActionActivity['Inroll Lifeline'], url);
    }
    else if (displayName == 'TRFA') {
      ApplicationActivityStore('View', 'TRFA', this.appActivity, ToolEnum.trfa, ActionActivity.TRFA, url);
    }
    else if (displayName == 'Inteserra Internal Use Only') {
      ApplicationActivityStore('View', 'Inteserra Internal Use Only', this.appActivity, 0, ActionActivity['Inteserra Internal Use Only'], url);
    }
    else if (displayName == 'Tax Documents') {
      ApplicationActivityStore('View', 'Tax Documents', this.appActivity, ToolEnum['Tax Documents'], ActionActivity['Tax Documents'], url);
    }
    else if (displayName == 'TRS Customer Notice') {
      ApplicationActivityStore('View', 'TRS Customer Notice', this.appActivity, ToolEnum['TRSCustomerNotice'], ActionActivity['TRS Customer Notice'], url);
    }
    else if (displayName == 'View 47') {
      ApplicationActivityStore('View', 'View 47', this.appActivity, ToolEnum.View47, ActionActivity['View 47'], url);
    }
  }

  ascii(a) { return a.charCodeAt(0); }

  loadMenu() {
    this.i360Service.i360MenuGroupGet('', this.userID).subscribe(
      data => {
        this.menuModel = data['listOfMenu'];
        data['listOfMenu'].forEach(element => {
          if (element.menuGroupId == 7) {
            element.childMenu = element.childMenu.filter(x => x.menuId != 45);
          }
        });
        sessionStorage.setItem('UserManu', JSON.stringify(this.menuModel));
        //this.router.navigate(['Home']);
      },
      error => {
        console.log(error);
      }
    );
  }

  i360GetUserId(EncyUserId) {
    this.i360Service.i360GetUserId('', EncyUserId).subscribe(
      data => {

        if (sessionStorage.getItem('isLogin') == 'true') {
          this.userID = data['userId'];
          sessionStorage.setItem('UserId', this.userID);
          //this.router.navigate(['Home']);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  movetoTop(event) {
    window.scroll(0, 0);
  }

  rediretTonews(url) {
    this.router.navigate([url]);
    this.applicationActivity('News', '/News');
  }

  // applicationActivity(actionName, pageName, datatype = 0, actionUrl = '') {
  //   ApplicationActivityStore(actionName, pageName, this.appActivity, 0, datatype, actionUrl)
  // }

  setLocalStorage() {
    if (typeof (localStorage.getItem('UserId')) != 'undefined' && localStorage.getItem('UserId') != null && localStorage.getItem('UserId') != '')
      sessionStorage.setItem('UserId', localStorage.getItem('UserId'));

    if (typeof (localStorage.getItem('UserName')) != 'undefined' && localStorage.getItem('UserName') != null && localStorage.getItem('UserName') != '')
      sessionStorage.setItem('UserName', localStorage.getItem('UserName'));

    if (typeof (localStorage.getItem('LastLogin')) != 'undefined' && localStorage.getItem('LastLogin') != null && localStorage.getItem('LastLogin') != '') {
      sessionStorage.setItem('LastLogin', localStorage.getItem('LastLogin'));
      this.lastLogin = sessionStorage.getItem('LastLogin');
    }

    if (typeof (localStorage.getItem('clientTypeId')) != 'undefined' && localStorage.getItem('clientTypeId') != null && localStorage.getItem('clientTypeId') != '')
      sessionStorage.setItem('clientTypeId', localStorage.getItem('clientTypeId'));

    if (typeof (localStorage.getItem('isUserByPass')) != 'undefined' && localStorage.getItem('isUserByPass') != null && localStorage.getItem('isUserByPass') != '')
      sessionStorage.setItem('isUserByPass', localStorage.getItem('isUserByPass'));

    if (typeof (localStorage.getItem('FirstLastName')) != 'undefined' && localStorage.getItem('FirstLastName') != null && localStorage.getItem('FirstLastName') != '') {
      sessionStorage.setItem('FirstLastName', localStorage.getItem('FirstLastName'));
      this.userName = sessionStorage.getItem('FirstLastName');
    }

    if (typeof (localStorage.getItem('userHasTracIt')) != 'undefined' && localStorage.getItem('userHasTracIt') != null && localStorage.getItem('userHasTracIt') != '')
      sessionStorage.setItem('userHasTracIt', localStorage.getItem('userHasTracIt'));

    if (typeof (localStorage.getItem('ClientAccessibleTools')) != 'undefined' && localStorage.getItem('ClientAccessibleTools') != null && localStorage.getItem('ClientAccessibleTools') != '')
      sessionStorage.setItem('ClientAccessibleTools', localStorage.getItem('ClientAccessibleTools'));

    if (typeof (localStorage.getItem('CompanyId')) != 'undefined' && localStorage.getItem('CompanyId') != null && localStorage.getItem('CompanyId') != '')
      sessionStorage.setItem('CompanyId', localStorage.getItem('CompanyId'));

    if (typeof (localStorage.getItem('DbaCompanyName')) != 'undefined' && localStorage.getItem('DbaCompanyName') != null && localStorage.getItem('DbaCompanyName') != '')
      sessionStorage.setItem('DbaCompanyName', localStorage.getItem('DbaCompanyName'));

    if (typeof (localStorage.getItem('IsICalSubscription')) != 'undefined' && localStorage.getItem('IsICalSubscription') != null && localStorage.getItem('IsICalSubscription') != '')
      sessionStorage.setItem('IsICalSubscription', localStorage.getItem('IsICalSubscription'));

    if (typeof (localStorage.getItem('EncyUserId')) != 'undefined' && localStorage.getItem('EncyUserId') != null && localStorage.getItem('EncyUserId') != '')
      sessionStorage.setItem('EncyUserId', localStorage.getItem('EncyUserId'));

    if (typeof (localStorage.getItem('CompanyName')) != 'undefined' && localStorage.getItem('CompanyName') != null && localStorage.getItem('CompanyName') != '')
    {
      sessionStorage.setItem('CompanyName', localStorage.getItem('CompanyName'));
      this.companyName =sessionStorage.getItem('CompanyName');
    }

    if (typeof (localStorage.getItem('isLogin')) != 'undefined' && localStorage.getItem('isLogin') != null && localStorage.getItem('isLogin') != '')
      sessionStorage.setItem('isLogin', localStorage.getItem('isLogin'));

    if (typeof (localStorage.getItem('UserManu')) != 'undefined' && localStorage.getItem('UserManu') != null && localStorage.getItem('UserManu') != '')
      sessionStorage.setItem('UserManu', localStorage.getItem('UserManu'));

    if (typeof (localStorage.getItem('UsercustomManu')) != 'undefined' && localStorage.getItem('UsercustomManu') != null && localStorage.getItem('UsercustomManu') != '')
      sessionStorage.setItem('UsercustomManu', localStorage.getItem('UsercustomManu'));
  }

  @HostListener('click')
  clickout() {

    //this.searchElement.nativeElement.close();
  }

  redirectTocustomURL(custommenu) {
    this.router.navigate(['/Custom-Product/' + custommenu.customProductId]);
  }

  openCompanyPage() {
    this.router.navigate(['/View/user-login']);
  }
}
