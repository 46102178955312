import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CountyMaster911Model } from 'src/Models/CountyMaster911Model';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CityMasterService } from 'src/Services/city-master.service';
import { CountyMasterService } from 'src/Services/county-master.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-createoredit-city',
  templateUrl: './createoredit-city.component.html',
  styleUrls: ['./createoredit-city.component.css']
})
export class CreateoreditCityComponent implements OnInit {
  cityMasterForm: FormGroup;
  userID: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  stateList: StateMasterModel[] = [];
  selectedState = [];
  countyList: CountyMaster911Model[] = [];
  selectedCounty = [];
  isEdit: boolean = false;

  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};
  dropdownSettingsDateCountyFilter = {};

  dropdownDateCountyFilter = [];
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  module = '911';
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private countyService: CountyMasterService, private stateService: StateMasterService,
    private toastr: ToastrService, private cityService: CityMasterService) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsDateCountyFilter = {
      singleSelection: true,
      text: "Select",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No County(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.cityMasterForm = this._fb.group({
      cityId: 0,
      cityName: ['', Validators.compose([Validators.required, Validators.pattern(this.pattern)])],
      stateId: [''],
      countyId: [''],
      isActive: [false],
      sortOrder: ['', Validators.required]
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetCityById(params['id']);
        }
      }
    });
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownDateFilter.push({ id: x.stateId, itemName: x.name });
          });
        }
      },
      error => { }
    );
  }

  GetCityById(cityId) {
    this.cityService.GetCityById(this.tokenKey, parseInt(cityId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.cityMasterForm.patchValue({
            cityId: result['city'].cityId,
            countyId: result['city'].countyId,
            cityName: result['city'].cityName,
            stateId: result['city'].stateId,
            isActive: result['city'].isActive,
            sortOrder: result['city'].sortOrder
          });
          this.GetCountyByStateId(result['city'].stateId)
          if (this.stateList.length > 0) {
            let data = this.stateList.filter(x => x.stateId == result['city'].stateId)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
          else
            setTimeout(() => {
              let data = this.stateList.filter(x => x.stateId == result['city'].stateId)[0];
              this.selectedState.push({ "id": data.stateId, "itemName": data.name })
            }, 500);
          if (this.countyList.length > 0) {
            let data = this.countyList.filter(x => x.countyId == result['city'].countyId)[0];
            this.selectedCounty.push({ "id": data.countyId, "itemName": data.countyName })
          }
          else
            setTimeout(() => {
              let data = this.countyList.filter(x => x.countyId == result['city'].countyId)[0];
              this.selectedCounty.push({ "id": data.countyId, "itemName": data.countyName })
            }, 800);
        }
      });
  }
  GetCountyByStateId(stateId) {
    this.countyService.GetCountyByStateId(this.tokenKey, stateId, true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyList = result['county'];
          this.countyList.forEach(x => {
            this.dropdownDateCountyFilter.push({ "id": x.countyId, "itemName": x.countyName })
          });
        }
      });
  }

  cancelCity() {
    let navigation = '/admin/' + this.SelectedTool + '/city';
    this.router.navigate([navigation]);
  }

  save() {
    this.isSubmit = true;
    let data = this.cityMasterForm.value;
    if (!this.cityMasterForm.valid || this.selectedState.length == 0 || this.selectedCounty.length == 0 || !data.cityName.trim())
      return
    data["createdBy"] = this.userID;

    let value = {
      cityId: data.cityId,
      countyId: data.countyId,
      stateId: data.stateId,
      cityName: data.cityName.trim(),
      createdBy: this.userID,
      isActive: data.isActive,
      sortOrder: data.sortOrder
    }

    this.cityService.CreateOrUpdateCity(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.cityId > 0)
              this.showMessage('City updated successfully', 'Success', 'success');
            else
              this.showMessage('City saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/city']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  onChangeState(event) {
    this.cityMasterForm.patchValue({
      stateId: event.id
    });
    this.dropdownDateCountyFilter=[];
    this.selectedCounty = [];
    this.GetCountyByStateId(event.id);
  }

  onDeSelectDateFilterAll($event) {
    this.selectedState = [];
    this.selectedCounty = [];
    this.dropdownDateCountyFilter = [];
  }

  onDeSelectDateFilterCountyAll($event) {
    this.selectedCounty = [];
  }

  onChangeCounty(event) {
    this.cityMasterForm.patchValue({
      countyId: event.id
    });
  }


}
