export class CustomProductModel {
    customProductId?: any
    companyId?: any
    title?: any
    description?: any
    isActive?: any  
    createdDate?: any
    createdBy?: any
    modifiedDate?: any
    modifiedBy?: any
    totalRecordCount?: any
    customProductDetailsId?:any
    subTitle?:any
    externalURL?:any
    section?:any
    displayDocName?:any
    docPath?:any
    linkType?:any
    fileUpload?:any
    fullPath?:any
    customProductDetails: any
}


export class CustomProductDetailsModel {
    customProductDetailsId?: any
    customProductId?: any
    title?: any
    externalURL?: any
    isActive?: any  
    createdDate?: any
    createdBy?: any
    modifiedDate?: any
    modifiedBy?: any
    section?:any
    sordOrder?:any
}