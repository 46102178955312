import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TrainingService {
    AppUrl: string = "";
    constructor(private _http: Http, private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.setAPIUrl();
    }

    setAPIUrl() {
        if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
            this.AppUrl = sessionStorage.getItem("BaseURL") || '';
        }
    }

    i360TrainingAddUpdate(TokenKey: string, trainingModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Training/i360TrainingAddUpdate';
        const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, trainingModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360TrainingGet(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Training/i360TrainingGet';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360TrainingFindbyTrainingId(TokenKey: string, trainingId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Training/i360TrainingFindbyTrainingId';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, trainingId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360TrainingDelete(TokenKey: string, trainingId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Training/i360TrainingDelete';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, trainingId, httpOptions)
            .pipe(catchError(this.handleError));
    }
    private handleError(error: Response | any) {
        console.error(error.message || error);
        return observableThrowError(error.status)
    }

    i360TrainingSectionListWithIds(TokenKey: string): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Training/i360TrainingSectionListWithIds';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions).pipe(catchError(this.handleError));
    }

    i360TrainingUpdate(TokenKey: string, currentIndexid: string, previousIndexid: string): Observable<any[]> {
        this.setAPIUrl();
        let data = 'api/Training/i360TrainingDetailUpdate/' + currentIndexid + '/' + previousIndexid;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }
}
