import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { GetRate, Update911FundModel, Update911GetModel } from 'src/Models/Update911Model';
import { StateMasterService } from 'src/Services/state-master.service';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { TrfaUpdateMasterService } from 'src/Services/trfa-update--master.service';
import { Updates911Service } from 'src/Services/updates911.service';
import { CheckAuth } from 'src/Services/utility.service';



@Component({
  selector: 'app-update911',
  templateUrl: './update911.component.html',
  styleUrls: ['./update911.component.css']
})
export class Update911Component implements OnInit {
  stateCode: any;
  tokenKey: string;
  listofUpdate: Update911GetModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;


  upDowNoteCSS: string = '';
  upDowfundNameCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowUpdateDateCSS: string = '';
  upDowFundTypeCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;

  //State List
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];
  stateId: string = ' ';
  stateList: StateMasterModel[] = [];
  //Fund
  fundID: string = ' ';
  dropdownListFund = [];
  selectedItemsFund = [];
  dropdownSettingsFund = {};
  listOffundIDs = [];
  fundList: Update911FundModel[] = [];
  module = "911";
  stateIds: string = ' ';
  rateId: string = ' ';
  isActive: number;
  scopeId: string = '';
  RateList: GetRate[] = [];
  dropdownListRate = [];
  dropdownListRatetemp = [];
  dropdownSettingsRate = {};
  listOfRateIDs = [];
  selectedRate = [];
  loginUserId: number = 0;
  UpdateId: number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService, private stateService: StateMasterService, private updates911: Updates911Service) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofUpdate = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'UpdateDate desc';
    this.Note = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Updates');
    this.loginUserId = +sessionStorage.getItem('AdminUserId');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.dropdownSettingsState = {
      singleSelection: false,
      text: "Filter by Jurisdiction",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsRate = {
      singleSelection: false,
      text: "Filter by Fund",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Fund(s) available',
      showCheckbox: true
    };

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.isloading = true;
    this.GetUpdate();
  }

  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownListState = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownListState.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }

  GetRateByStateId() {
    this.stateId = ' ';
    if (this.selectedItemsState.length > 0) {
    this.stateId = this.selectedItemsState.map(State => State.id).join(',');}
    else{
      this.dropdownListRate=[];
      this.selectedRate=[];
      return;
    }
    this.updates911.GetRateByStateId(this.tokenKey, this.stateId, this.isActive, this.scopeId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.RateList = data['rate'];
          this.dropdownListRatetemp = data['rate'];
          this.dropdownListRate = [];
          data['rate'].forEach(x => {
            this.dropdownListRate.push({ "id": x.rateId, "itemName": x.taxName, });
          });
        }
      },
      error => { }
    );
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetUpdate();
  }

  GetUpdate() {
    this.isloading = true;
    this.rateId = this.selectedRate.map(Rate => Rate.id).join(',');
    this.stateId = this.selectedItemsState.map(State => State.id).join(',');
    this.updates911.GetAllUpdate(this.tokenKey, this.PageNumber, this.PageSize, this.rateId, this.stateId, this.OrderBy, this.loginUserId,0).subscribe(
      data => {
        this.isloading = false; 
        if (this.selectedRate.length > 0) {
          this.selectedRate.forEach((x, y) => {           
            const ifexist = this.dropdownListRate.filter(ratei => ratei.id == x.id);
            if (ifexist.length == 0) {
              this.selectedRate.splice(y, 1);
            }       
          });
        }   

        this.listofUpdate = data['update'];
        if (this.listofUpdate != null) {
          if (this.listofUpdate.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofUpdate[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.Note = $event.target.value;
    else
      this.Note = 'null';
    this.GetUpdate();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';
      this.upDowUpdateDateCSS = '';
      this.upDowFundTypeCSS = '';
      if (sortBy === "Note") {
        this.upDowNoteCSS = 'fa fa-arrow-down';
        this.upDowfundNameCSS = '';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "FundName") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "FundTypeName") {
        this.upDowNoteCSS = '';
        this.upDowFundTypeCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "StateName") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowUpdateDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "Note") {
        this.upDowNoteCSS = 'fa fa-arrow-up';
        this.upDowfundNameCSS = '';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "FundName") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "FundTypeName") {
        this.upDowNoteCSS = '';
        this.upDowFundTypeCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
      }
      else if (sortBy === "StateName") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "UpdateDate") {
        this.upDowNoteCSS = '';
        this.upDowfundNameCSS = '';
        this.upDowUpdateDateCSS = 'fa fa-arrow-up';
      }
    }
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  addUpdate() {
    let navigation = '/admin/' + this.SelectedTool + '/update/manage';
    this.router.navigate([navigation]);
  }

  updateUpdate(UpdateId) {
    let navigation = '/admin/' + this.SelectedTool + '/update/manage/' + UpdateId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.UpdateId = id;
  }

  deleteUpdate(UpdateId) {
    this.isloading = true;
    this.updates911.DeleteUpdate(this.tokenKey, UpdateId).subscribe(
      data => {
        this.resetPageNoandSize();
        this.GetUpdate();
        this.toastr.success('Update deleted successfully.', 'Delete')
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onSelectState($event) {
    this.GetRateByStateId();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectState($event) {    
    this.GetRateByStateId();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectAll($event) {
    this.GetRateByStateId();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.listOfRateIDs = [];
    this.selectedItemsState = [];
    this.selectedRate = [];
    this.GetRateByStateId();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onSelectRate($event) {
    this.GetUpdate();
  }

  onDeSelectRate($event) {
    this.GetUpdate();
  }

  onSelectAllRate($event) {
    this.GetUpdate();
  }

  onDeSelectAllRate($event) {
    this.GetUpdate();
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  onFilterSelectAllState($event){
    this.GetRateByStateId();
    this.resetPageNoandSize();
    this.GetUpdate();
  }

  onFilterDeSelectAllState($event){
    this.GetRateByStateId();
    if(this.selectedItemsState.length==0)
    {
      this.RateList=[];
    }    
    this.resetPageNoandSize();
    this.GetUpdate();
    
  }

  onFilterSelectAllRate($event){
    this.GetUpdate();
  }

  onFilterDeSelectAllRate($event){
    this.GetUpdate();
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}