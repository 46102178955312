import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ObligationGetLinkModel } from 'src/Models/ObligationGetLinkModel';
import { RouterExtService } from 'src/Services/RouterExtService';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';
import { CheckAuth } from 'src/Services/utility.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-carrier-obligation',
  templateUrl: './carrier-obligation.component.html',
  styleUrls: ['./carrier-obligation.component.css']
})
export class CarrierObligationComponent implements OnInit {

  ckeConfig: any;
  stateCode: any;
  userId: any;
  tokenKey: string;
  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  obligationId: number;

  listofObligation: ObligationGetLinkModel[]
  upDowsubCarriertypeCSS: string = '';
  upDowtitleCSS: string = '';
  upDowDueDateCSS: string = '';
  upDowCFRCSS: string = '';
  upDowChCSS: string = '';
  upDowSubChCSS: string = '';
  upDowPartCSS: string = '';
  upDowSubPartCSS: string = '';
  upDowCFRSectionCSS: string = '';
  upDowpublishdate:string='';
  upDowlastauditdate:string='';
  listofObligation1 = []
  listofObligationExport: any=[]

  companyId:number;
  isInteserraUser: boolean = false;

  //Carrier List
  dropdownListCarrier = [];
  selectedItemsCarrier = [];
  dropdownSettingsCarrier = {};
  listOfCarrierIds = [];
  carrierId: string = ' ';
  carrierTypeList: any[] = [];

  keywordValue:string='';

  constructor(private router: Router, private checkAuth: CheckAuth, private carrierObligationService: CarrierObligationService
    , private titleService: Title, private toastr: ToastrService, private datePipe: DatePipe,
    private routerService: RouterExtService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.titleService.setTitle('View 47');
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.listofObligation = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'cfr';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.dropdownSettingsCarrier = {
      singleSelection: false,
      text: "Filter by Carrier Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Carrier Type(s) available',
      showCheckbox: true
    };

    $(function () {

      $('.wrapper1').on('scroll', function (e) {
        $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft());
      });
      $('.wrapper2').on('scroll', function (e) {
        $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft());
      });
      $('.div1').width($('table').width());
      $('.div2').width($('table').width());
    });

    if(this.SelectedTool == 'view 47'){
      this.SelectedTool = 'view47';
    }

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getcompanyId();
    this.getCarrierType();
   
    if (this.routerService.getPreviousUrl().includes('admin/view47') || this.routerService.getPreviousUrl().includes('view47/history')) {
      this.setSaveFilter();
    }
    else {
      this.clearSession();
      this.getCarrierObligation();
    }

  }

  getCarrierType() {
    this.carrierObligationService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierTypeList = result['carrier'];
          this.dropdownListCarrier = [];
          this.carrierTypeList.forEach(x => {
            this.dropdownListCarrier.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCarrier($event) {
    this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event.id)
    this.listOfCarrierIds.push($event.id);
    this.carrierId = ' ';
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIds != null) {
      if (this.listOfCarrierIds.length > 0) {
        this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event.id)
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
          this.listOfCarrierIds.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIds = [];
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  onFilterSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
          this.listOfCarrierIds.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  onFilterDeSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  getcompanyId(){
    this.carrierObligationService.getCompanyIdByUserId(this.tokenKey,this.userId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.companyId = result['companyId'];          
          if(this.companyId == 19){
            this.isInteserraUser=true;
          }
          else{
            this.isInteserraUser=false;
          }
        }
      },
      error => { }
    );
  }

  getCarrierObligation() {
    this.isloading = true;
    if (this.OrderBy == "null" || this.OrderBy == "" || this.OrderBy == "undefined")
      this.OrderBy = " ";

      this.carrierId = null;
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }

    if (this.keywordValue == "null" || this.keywordValue == "" || this.keywordValue == "undefined") this.keywordValue = " ";
    this.carrierObligationService.GetAllobligation(this.tokenKey, this.PageNumber, this.PageSize, this.OrderBy,this.keywordValue,this.carrierId,false).subscribe(
      data => {
        this.isloading = false;
        this.listofObligation=[];
        data['obligation'].map(val => {
          let category;
          let Initial = false;
          let Maintenance = false;
          let Recurring = false;
          let Fee = false;
          let Operational = false;
          let Nottracked=false;

          if (val.categoryTypeName != null) {
           category = val.categoryTypeName.split(',');
           Initial = category.find(e => e === 'Initial')==undefined?false:true;
           Maintenance=category.find(e => e === 'Maintenance')==undefined?false:true;
           Recurring =category.find(e => e === 'Recurring')==undefined?false:true;
           Fee =category.find(e => e === 'Fee/Contribution')==undefined?false:true;
           Operational=category.find(e => e === 'Operational')==undefined?false:true;
           Nottracked=category.find(e => e === 'Not tracked')==undefined?false:true;
          }

          let Obligation: any = {
            carrierName: val.carrierTypeName,
            carrierTypeId: val.carrierTypeId,
            subCarriertype: val.subCarriertype,
            categoryName: category,
            categotyTypeId: val.categotyTypeId,
            title: val.title,
            details: val.details,
            pendingactivity: val.pendingactivity,
            dueDate: val.dueDate,

            initial: Initial,
            maintenance: Maintenance,
            recurring: Recurring,
            fee: Fee,
            operational: Operational,
            nottracked: Nottracked,

            internalnotes: val.internalnotes,
            keyterms: val.keyterms,
            relatedforfeitures: val.relatedforfeitures,
            cfr: val.cfr,
            url:val.url,

            ch:val.ch,
            subch:val.subCh,
            part:val.part,
            subpart:val.subPart,
            cfrsection:val.cfrSection,
            sortorder:val.sortOrder,
            checklist:val.checklist,

            publishdate:val.publishdate,
            lastAudit:val.lastAudit,
            obligationId: val.obligationId,
            totalRecordCount: val.totalRecordCount,
          }

          this.listofObligation.push(Obligation);
        });

        if (this.listofObligation != null) {
          if (this.listofObligation.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofObligation[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  addObligation() {
    let navigation = '/admin/' + this.SelectedTool + '/manage';
    this.router.navigate([navigation]);
  }

  updateObligation(obligationId) {
    this.saveSession();
    window.open('/#/admin/' + this.SelectedTool + '/manage/' + obligationId,"_blank");
  }

  setDeleteData(id) {
    this.obligationId = id;
  }

  deleteObligation(obligationId) {
    this.isloading = true;
    this.carrierObligationService.Deleteobligation(this.tokenKey, obligationId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.showMessage("Obligation Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.getCarrierObligation();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.getCarrierObligation();
  }

  replaceCommaLine(data) {
    if (data != null) {
      if (data.includes(',')) {
        let dataToArray = data.split(',').map(item => item.trim());
        let list = data.split(",").join("<br />")
        return list
      }
      else {
        return data;
      }
    }
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDowsubCarriertypeCSS = '';
      this.upDowtitleCSS='';
      this.upDowDueDateCSS = '';     
      this.upDowCFRCSS = '';
      this.upDowChCSS = '';
      this.upDowSubChCSS = '';
      this.upDowPartCSS = '';
      this.upDowSubPartCSS = '';
      this.upDowCFRSectionCSS = '';
      this.upDowpublishdate='';
      this.upDowlastauditdate='';

      if (sortBy === "subCarriertype") {
        this.upDowsubCarriertypeCSS = 'fa fa-arrow-down';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "title") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS = 'fa fa-arrow-down';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "dueDate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = 'fa fa-arrow-down';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }     
      else if (sortBy === "cfr") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = 'fa fa-arrow-down';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "publishdate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='fa fa-arrow-down';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "ch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='fa fa-arrow-down';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='fa fa-arrow-down';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "part") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='fa fa-arrow-down';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subpart") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='fa fa-arrow-down';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "cfrsection") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='fa fa-arrow-down';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "lastaudit") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='fa fa-arrow-down';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "subCarriertype") {
        this.upDowsubCarriertypeCSS = 'fa fa-arrow-up';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "title") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS = 'fa fa-arrow-up';
        this.upDowDueDateCSS = '';      
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "dueDate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = 'fa fa-arrow-up';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }      
      else if (sortBy === "cfr") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = 'fa fa-arrow-up';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "publishdate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='fa fa-arrow-up';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "ch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='fa fa-arrow-up';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='fa fa-arrow-up';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "part") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='fa fa-arrow-up';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subpart") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='fa fa-arrow-up';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "cfrsection") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='fa fa-arrow-up';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "lastaudit") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='fa fa-arrow-up';
      }
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  ViewHistory(obligationId) {
    this.saveSession();
    let navigation = '/admin/' + this.SelectedTool + '/history/' + obligationId;
    this.router.navigate([navigation]);
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.keywordValue = $event.target.value.trim();
    else
      this.keywordValue = 'null';

    this.resetPageNoandSize();
    this.getCarrierObligation();
  }


  clear(){
    this.PageNumber = 1;
    this.keywordValue = 'null';
    this.selectedItemsCarrier = [];
    this.listOfCarrierIds = [];

    this.resetPageNoandSize();
    this.getCarrierObligation();
  }


  saveSession() {
    sessionStorage.setItem('SaveFilterPageNo', this.PageNumber.toString());
    sessionStorage.setItem('SaveFilterOrderBy', this.OrderBy);
    sessionStorage.setItem('SaveFilterIsAscending', this.isAscending ? 'true' : 'false');
    sessionStorage.setItem('SaveFilterKeyword', this.keywordValue);
    sessionStorage.setItem('SaveFilterlistOfCarrierIds', JSON.stringify(this.listOfCarrierIds));
    sessionStorage.setItem('SaveFilterselectedItemsCarrier', JSON.stringify(this.selectedItemsCarrier));
  }


  clearSession() {
    sessionStorage.removeItem('SaveFilterPageNo');
    sessionStorage.removeItem('SaveFilterOrderBy');
    sessionStorage.removeItem('SaveFilterIsAscending');
    sessionStorage.removeItem('SaveFilterKeyword');
    sessionStorage.removeItem('SaveFilterlistOfCarrierIds');
    sessionStorage.removeItem('SaveFilterselectedItemsCarrier');
  }

  setSaveFilter() {
    if (sessionStorage.getItem('SaveFilterPageNo') != null && sessionStorage.getItem('SaveFilterPageNo') != '' && sessionStorage.getItem('SaveFilterPageNo') != 'undefined') {
      this.PageNumber = parseInt(sessionStorage.getItem('SaveFilterPageNo'));
    }
    if (sessionStorage.getItem('SaveFilterOrderBy') != null && sessionStorage.getItem('SaveFilterOrderBy') != '' && sessionStorage.getItem('SaveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('SaveFilterOrderBy');
    }
    if (sessionStorage.getItem('SaveFilterIsAscending') != null && sessionStorage.getItem('SaveFilterIsAscending') != '' && sessionStorage.getItem('SaveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('SaveFilterIsAscending') == 'false' ? false : true;
      if (this.isAscending) {
        this.CarrierObligationsort(this.OrderBy);
      } else {
        this.CarrierObligationsort(this.OrderBy);
      }
    }
    if (sessionStorage.getItem('SaveFilterOrderBy') != null && sessionStorage.getItem('SaveFilterOrderBy') != '' && sessionStorage.getItem('SaveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('SaveFilterOrderBy');
    }
    if (sessionStorage.getItem('SaveFilterKeyword') != null && sessionStorage.getItem('SaveFilterKeyword') != '' && sessionStorage.getItem('SaveFilterKeyword') != 'undefined') {
      this.keywordValue = sessionStorage.getItem('SaveFilterKeyword');
    }
    if (sessionStorage.getItem('SaveFilterlistOfCarrierIds') != null && sessionStorage.getItem('SaveFilterlistOfCarrierIds') != '' && sessionStorage.getItem('SaveFilterlistOfCarrierIds') != 'undefined') {
      this.listOfCarrierIds = JSON.parse(sessionStorage.getItem('SaveFilterlistOfCarrierIds'));
    }
    if (sessionStorage.getItem('SaveFilterselectedItemsCarrier') != null && sessionStorage.getItem('SaveFilterselectedItemsCarrier') != '' && sessionStorage.getItem('SaveFilterselectedItemsCarrier') != 'undefined') {
      this.selectedItemsCarrier = JSON.parse(sessionStorage.getItem('SaveFilterselectedItemsCarrier'));
    }
    this.getCarrierObligation();
    this.clearSession();
  }

  CarrierObligationsort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDowsubCarriertypeCSS = '';
      this.upDowtitleCSS='';
      this.upDowDueDateCSS = '';     
      this.upDowCFRCSS = '';
      this.upDowChCSS = '';
      this.upDowSubChCSS = '';
      this.upDowPartCSS = '';
      this.upDowSubPartCSS = '';
      this.upDowCFRSectionCSS = '';
      this.upDowpublishdate='';
      this.upDowlastauditdate='';

      if (sortBy === "subCarriertype") {
        this.upDowsubCarriertypeCSS = 'fa fa-arrow-down';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "title") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS = 'fa fa-arrow-down';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "dueDate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = 'fa fa-arrow-down';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }     
      else if (sortBy === "cfr") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = 'fa fa-arrow-down';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "publishdate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='fa fa-arrow-down';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "ch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='fa fa-arrow-down';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='fa fa-arrow-down';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "part") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='fa fa-arrow-down';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subpart") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='fa fa-arrow-down';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "cfrsection") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='fa fa-arrow-down';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "lastaudit") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='fa fa-arrow-down';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "subCarriertype") {
        this.upDowsubCarriertypeCSS = 'fa fa-arrow-up';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "title") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS = 'fa fa-arrow-up';
        this.upDowDueDateCSS = '';      
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "dueDate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = 'fa fa-arrow-up';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }      
      else if (sortBy === "cfr") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = 'fa fa-arrow-up';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "publishdate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='fa fa-arrow-up';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "ch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='fa fa-arrow-up';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='fa fa-arrow-up';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "part") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='fa fa-arrow-up';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subpart") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='fa fa-arrow-up';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "cfrsection") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='fa fa-arrow-up';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "lastaudit") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='fa fa-arrow-up';
      }
    }
  }

  exportCarrierObligation() {
    this.isloading = true;
    if (this.OrderBy == "null" || this.OrderBy == "" || this.OrderBy == "undefined")
      this.OrderBy = " ";

      this.carrierId = null;
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }

    if (this.keywordValue == "null" || this.keywordValue == "" || this.keywordValue == "undefined") this.keywordValue = " ";
    this.carrierObligationService.GetAllobligation(this.tokenKey, this.PageNumber, this.PageSize, this.OrderBy,this.keywordValue,this.carrierId,true).subscribe(
      data => {
        
        this.listofObligationExport = [];
        data['obligation'].map(val => {
          let category;
          let Initial = false;
          let Maintenance = false;
          let Recurring = false;
          let Fee = false;
          let Operational = false;
          let Nottracked=false;

          if (val.categoryTypeName != null) {
           category = val.categoryTypeName.split(',');
           Initial = category.find(e => e === 'Initial')==undefined?false:true;
           Maintenance=category.find(e => e === 'Maintenance')==undefined?false:true;
           Recurring =category.find(e => e === 'Recurring')==undefined?false:true;
           Fee =category.find(e => e === 'Fee/Contribution')==undefined?false:true;
           Operational=category.find(e => e === 'Operational')==undefined?false:true;
           Nottracked=category.find(e => e === 'Not tracked')==undefined?false:true;
          }

          let Obligation: any = {
            carrierName: val.carrierTypeName,
            carrierTypeId: val.carrierTypeId,
            subCarriertype: val.subCarriertype,
            categoryName: category,
            categotyTypeId: val.categotyTypeId,
            title: val.title,
            details: val.details,
            pendingactivity: val.pendingactivity,
            dueDate: val.dueDate,

            initial: Initial,
            maintenance: Maintenance,
            recurring: Recurring,
            fee: Fee,
            operational: Operational,
            nottracked: Nottracked,

            internalnotes: val.internalnotes,
            keyterms: val.keyterms,
            relatedforfeitures: val.relatedforfeitures,
            cfr: val.cfr,
            url:val.url,

            ch:val.ch,
            subch:val.subCh,
            part:val.part,
            subpart:val.subPart,
            cfrsection:val.cfrSection,
            sortorder:val.sortOrder,
            checklist:val.checklist,

            publishdate:val.publishdate,
            lastAudit:val.lastAudit,
            obligationId: val.obligationId,
            totalRecordCount: val.totalRecordCount,
          }

          this.listofObligationExport.push(Obligation);
        });

        setTimeout(() => {
          this.exportToExcel();
        }, 1000);
        
        
      },
      error => { 
        this.isloading = false;
        this.stateCode = error 
      }
    );
  }

  exportToExcel() {
    this.isloading = false;
    let tableData = document.getElementById("exportobligationlist").innerHTML;

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }    
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";    
    let name = 'view47';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "view47.xls");
  }
}


