import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AdminUserType } from 'src/Models/Common';
import { ReportService } from 'src/Services/report.service';
import { CheckAuth } from 'src/Services/utility.service';
import { UpdatesService } from 'src/Services/updatesService';
import * as FileSaver from 'file-saver';
import { FormGroup, FormControl } from '@angular/forms';
import { I360Service } from 'src/Services/i360Service';
import { StateMasterService } from 'src/Services/state-master.service';
import { AllActiveSubscribersFilterModel } from 'src/Models/ReportModel';
import { AllActiveSubscibersModel } from 'src/Models/AllActiveSubscibersModel';
import { ClientCompanyModel } from 'src/Models/ClientCompanyModel';

@Component({
  selector: 'app-all-active-subscribers-reports',
  templateUrl: './all-active-subscribers-reports.component.html',
  styleUrls: ['./all-active-subscribers-reports.component.css']
})
export class AllActiveSubscribersReportsComponent implements OnInit {
  tokenKey: string = '';
  filterModel = new AllActiveSubscribersFilterModel();

  // Company Dropdown
  clientCompanyList = new Array<ClientCompanyModel>();
  selectedCompany: any;
  dropdownSettingsCompany = {};

  // States
  dropdownListState = [];
  selectedItemsState = [];
  dropdownSettingsState = {};
  listOfStateIds = [];

  loginuserID: any;
  isloading = false;
  SelectedTool = '';
  systemUserCondition: string = '';

  rangeDates: Date[] = [];
  fromDateFilter: string = '';
  toDateFilter: string = '';

  documentForm = new FormGroup({
    rangeDates: new FormControl(),
  });

  listOfData: AllActiveSubscibersModel[];

  currentPage: number = 1;
  productId: number = 3;

  constructor(private checkAuth: CheckAuth, private titleService: Title,
    private reportService: ReportService, private updatesService: UpdatesService,
    private toastrService: ToastrService,
    private stateMasterService: StateMasterService,
  ) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('All Active Subscribers Report');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.systemUserCondition = 'null'
    else
      this.systemUserCondition = '  (CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.loginuserID + '))';

      this.filterModel.productId = this.productId;
  }

  ngOnInit() {
    this.loadDropdownSettings();
    this.movetotop();
    this.GetClientCompany();
    this.getAllActiveSubscibersReports();
    this.GetStateData();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  getAllActiveSubscibersReports() {
    this.listOfData = [];
    this.isloading = true;
    this.reportService.AllActiveSubscibersReports(this.tokenKey, this.filterModel).subscribe(data => {
      this.isloading = false;
      if (data) {
        debugger
        if (data.status == "Success") {
          this.listOfData = data.subsciberReports;
        }
        else {
          this.toastrService.error("Error", "error");
        }
      }
    }, error => {
      this.isloading = false;
    })
  }

  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }

  GetClientCompany() {
    this.clientCompanyList = [];
    this.updatesService.GetClientCompany(this.tokenKey, 3, this.systemUserCondition).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.clientCompanyList = data['clientCompanay'];
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  clearBriefingReport() {
    this.selectedCompany = undefined;
    this.listOfData = [];
    this.rangeDates = [];
    this.fromDateFilter = null;
    this.toDateFilter = null;
  }

  exportToExcel() {
    if (this.listOfData.length > 0) {
      var uri = 'data:application/vnd.ms-excel;base64,'
        , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
        , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
        , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
      let html = '<table>';
      if (this.filterModel.companyId && this.selectedCompany) {
        html += "</tr><tr class='mainhead'>    <td colspan='3'> Company: " + this.selectedCompany[0].companyName + "</td>  </tr>";
      }
      html += document.getElementById("reportHeader").innerHTML + " " + document.getElementById("reportBody").innerHTML;
      html += "</table>";
      var ctx = { worksheet: 'Worksheet', table: html }
      let dt = this.transformDate(new Date());
      FileSaver.saveAs((uri + base64(format(template, ctx))), "AllActiveSubscribersReport" + dt + ".xls");
    }
    else {
      this.toastrService.error("No record found", "error");
    }
  }

  transformDate(array) {
    if (array != null) {

      let dd = array.getDate().toString();
      if (parseInt(dd.toString()) < 10)
        dd = '0' + dd.toString();

      let mm = (array.getMonth() + 1).toString();
      if (parseInt(mm.toString()) < 10)
        mm = '0' + mm.toString();

      let hrs = array.getHours();
      if (parseInt(hrs.toString()) < 10)
        hrs = '0' + hrs.toString();

      let min = array.getMinutes()
      if (parseInt(min.toString()) < 10)
        min = '0' + min.toString();

      let sec = array.getSeconds()
      if (parseInt(sec.toString()) < 10)
        sec = '0' + sec.toString();

      return array.getFullYear() + '_' + mm + '_' + dd + '_' + hrs + '_' + min + '_' + sec;
    }
  }

  isDateFilter(dateArray: any) {

    this.rangeDates = dateArray;

    if (this.rangeDates != null) {
      if (this.rangeDates.length > 1) {
        let dd1 = this.rangeDates[0].getDate().toString();
        if (parseInt(dd1.toString()) < 10)
          dd1 = '0' + dd1.toString();

        let mm1 = (this.rangeDates[0].getMonth() + 1).toString();
        if (parseInt(mm1.toString()) < 10)
          mm1 = '0' + mm1.toString();

        let dd2 = this.rangeDates[1].getDate().toString();
        if (parseInt(dd2.toString()) < 10)
          dd2 = '0' + dd2.toString();

        let mm2 = (this.rangeDates[1].getMonth() + 1).toString();
        if (parseInt(mm2.toString()) < 10)
          mm2 = '0' + mm2.toString();

        this.fromDateFilter = this.rangeDates[0].getFullYear() + mm1 + dd1;
        this.toDateFilter = this.rangeDates[1].getFullYear() + mm2 + dd2;
      }
      if (this.fromDateFilter === null || this.fromDateFilter === '') {
        this.fromDateFilter = "null";
        this.toDateFilter = "null";
      }
    }
    else {
      this.fromDateFilter = "null";
      this.toDateFilter = "null";
    }
    if (this.fromDateFilter != 'null' && this.toDateFilter != 'null') {
      this.getAllActiveSubscibersReports();
    }

  }

  dateFilterClear() {
    this.rangeDates = [];
    this.fromDateFilter = null;
    this.toDateFilter = null;
    this.getAllActiveSubscibersReports();
  }

  loadDropdownSettings() {
    this.dropdownSettingsCompany = {
      singleSelection: true,
      text: "Select Company",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No data available for selected company',
      showCheckbox: false,
      primaryKey: 'companyId',
      labelKey: 'companyName',
      enableCheckAll: false,
      enableFilterSelectAll: false,
    };
  }

  onSelectState($event) {
    this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
    this.listOfStateIds.push($event.id);
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.getAllActiveSubscibersReports();
  }

  onDeSelectState($event) {
    if (this.listOfStateIds != null) {
      if (this.listOfStateIds.length > 0) {
        this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event.id)
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.getAllActiveSubscibersReports();
  }

  onSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.getAllActiveSubscibersReports();
  }

  onDeSelectAllState($event) {
    this.listOfStateIds = [];
    this.filterModel.stateID = null;
    this.getAllActiveSubscibersReports();
  }

  GetStateData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.stateMasterService.getState(this.tokenKey, this.productId).subscribe(
      data => {
        if (data["state"] != null) {
          // Set All Item          
          for (let iCounter = 0; iCounter < data["state"].length; iCounter++) {
            this.dropdownListState.push(
              { "id": data["state"][iCounter].stateId, "itemName": data["state"][iCounter].name }
            );
          }
        }
        this.dropdownSettingsState = {
          singleSelection: false,
          text: "Select Jurisdiction(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 1,
          noDataLabel: 'No Jurisdiction available',
          showCheckbox: true
        };
      }, error => {
        console.log(error);
      })
  }
  
  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  onFilterSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
          this.listOfStateIds.push($event[iCounter].id);
        }
        else {
          this.listOfStateIds.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.getAllActiveSubscibersReports();
  }

  onFilterDeSelectAllState($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStateIds != null) {
        if (this.listOfStateIds.length > 0) {
          this.listOfStateIds = this.removeElemetninArray(this.listOfStateIds, $event[iCounter].id)
        }
      }
    }
    this.filterModel.stateID = this.listOfStateIds.join(',');
    this.getAllActiveSubscibersReports();
  }

  onCompanySelect(selectedCompany) {
    this.filterModel.companyId = 0;
    if (this.selectedCompany.length > 0 && selectedCompany.length != 0) {
      this.filterModel.companyId = this.selectedCompany[0].companyId;
    }
    this.getAllActiveSubscibersReports();
  }

}
