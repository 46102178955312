export class SeminarsModel {
    seminarId?: any;
    title?: any;
    description?: any = '';
    isActive?: any;
    createdDate?: any;
    createdBy?: any;
    modifiedDate?: any;
    modifiedBy?: any;
    totalRecordCount?: any;
    sortOrder?:any;
    sectionId?: any;
    sectionName?:any;
    seminarSectionDetails?:any;
}

export class SeminarsSectionsModel{    
    sectionId?: any
    sectionName?: any
    sortOrder?: any 
    isActive?: any 
    createdDate?: any 
    createdBy?: any 
    updatedDate?: any
    updatedBy?: any 
    totalRecordCount?: any

}