import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from 'src/Services/app.config';
import { PricingQuotingBaserateService } from 'src/Services/pricing-quoting-baserate.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-add-update-base-rate-type',
  templateUrl: './add-update-base-rate-type.component.html',
  styleUrls: ['./add-update-base-rate-type.component.css']
})
export class AddUpdateBaseRateTypeComponent implements OnInit {

  baseRateMasterForm: FormGroup;
  userID: any;
  appModel: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit:boolean = false;
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  
  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private baseRateMaster: PricingQuotingBaserateService, private toastr: ToastrService) {
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.appModel = AppConfig.settings.ConnectionStrings;
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
     }

  ngOnInit() {
    this.isSubmit = false;
    this.tokenKey = sessionStorage.getItem('tokenValue');

    this.baseRateMasterForm = this._fb.group({
      baseRateTypeId: 0,
      baseRateTypeName: ['', Validators.required]
    })

    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetBaseRateByTypeId(params['id']);
        }
      }
    });
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  cancelBaseRateType() {
    let navigation = '/admin/' + this.SelectedTool + '/baserateType';
    this.router.navigate([navigation]);
  }

  GetBaseRateByTypeId(baseRateId: number) {
    this.baseRateMaster.GetBaseRateTypeById(this.tokenKey, parseInt(baseRateId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.baseRateMasterForm.patchValue({
            baseRateTypeId: result['baseRateType'].baseRateTypeId,
            baseRateTypeName: result['baseRateType'].baseRateTypeName,
          });
        }
      });
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }


  save() {
    this.isSubmit = true;
    let data = this.baseRateMasterForm.value;
    if (!this.baseRateMasterForm.valid||!data.baseRateTypeName.trim())
      return

    let value={
      baseRateTypeId:data.baseRateTypeId,
      baseRateTypeName:data.baseRateTypeName.trim(),
    }

    this.baseRateMaster.BaseRateTypeAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if(result['message'] !=""){
            this.showMessage(result['message'], 'Warning', 'warning')
          }else{

            if (data.baseRateTypeId > 0)
              this.showMessage('Base rate type updated successfully', 'Success', 'success');
            else
              this.showMessage('Base rate type saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/'+this.SelectedTool+'/baserateType']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

}
