import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { I360Service } from 'src/Services/i360Service';
import { FilterModel } from 'src/Models/filterModel';
import { CheckAuth } from 'src/Services/utility.service';
import { Title } from '@angular/platform-browser';
import { TaxExemptionFormModel,TaxExemptionFormSectionsModel } from 'src/Models/TaxExemptionFormsModel';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { ApplicationActivityStore } from 'src/common/commonFunction';
import { ApplicationActivityService } from 'src/Services/application-activity.service';
import { ActionActivity, ToolEnum } from 'src/Models/Common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TaxExemptionFormsServiceService } from 'src/Services/tax-exemption-forms-service.service';

@Component({
  selector: 'app-front-tax-exemption-forms',
  templateUrl: './front-tax-exemption-forms.component.html',
  styleUrls: ['./front-tax-exemption-forms.component.css']
})
export class FrontTaxExemptionFormsComponent implements OnInit {

  listOfTaxExemptionForm: TaxExemptionFormModel[];
  filterModel = new FilterModel();
  tokenKey: string = '';
  taxExemptionFormSectionDetails: any = [];
  _userID: any;

  currentPage: number = 1;
  totalRecordCount: number = -1;
  keywordFilter: string = '';
  OrderBy: string = '';
  isAscending: boolean = false
  isShowprogressSpinner: boolean;
  blockedDocument: boolean;
  videoURL: any;
  videoType: string;
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  @ViewChild("videoModel", { static: false }) videoModel: ElementRef;
  topicTitle: string = '';
  isEmbed: boolean = false;
  listOfTaxExemptionFormSection: TaxExemptionFormSectionsModel[];
  groupArr: any[];


  constructor(private tefservice: TaxExemptionFormsServiceService,private i360Service: I360Service, private checkAuth: CheckAuth, private titleService: Title,
    private toastr: ToastrService, private appActivity: ApplicationActivityService, private sanitizer: DomSanitizer) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this._userID = sessionStorage.getItem('UserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '500';
    this.filterModel.orderBy = 'SectionSortOrder,SortOrder';
    this.filterModel.userID = this._userID;
    this.filterModel.productId = ToolEnum.TaxExemptionForms;
    this.titleService.setTitle('Tax Exemption Forms | Inteserra - i360');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.Geti360TaxExemptionForm();
    // this.getAllSections();
  }

  Geti360TaxExemptionForm() {

    this.i360Service.i360TaxExemptionFormGetFront(this.tokenKey, this.filterModel).subscribe(
      data => {
        //this.listOfSpecialReport = data['listOfSpecialReport'];
        this.taxExemptionFormSectionDetails = data['listOfTaxExemptionForm'];

        this.groupArr = this.taxExemptionFormSectionDetails.reduce((r, { sectionName }) => {
          if (!r.some(o => o.sectionName == sectionName)) {
            r.push({ sectionName, groupItem: this.taxExemptionFormSectionDetails.filter(v => v.sectionName == sectionName) });
          }
          return r;
        }, []);

        if (this.taxExemptionFormSectionDetails != null) {
          if (this.taxExemptionFormSectionDetails.length > 0) {
            this.totalRecordCount = this.taxExemptionFormSectionDetails[0].totalRecordCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
        else {
          this.totalRecordCount = 0;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  donwloadFile(data: TaxExemptionFormModel) {
    this.topicTitle = '';
    this.isEmbed = data.isEmbed;
    if ((!data.docPath || data.isLocked) && !data.isEmbed)
      return;
      
    if (data.isLocked)
      return;

    this.topicTitle = data.title;
    if (data.linktype == 1 || data.linktype == 0) {
      if (data.isEmbed) {
        ApplicationActivityStore('View', 'Tax Exemption Form', this.appActivity, data.taxExemptionFormId, ActionActivity['Tax Exemption Form'], data.title);
        if (data.externalURL.includes('youtube')) {
          this.videoURL = data.externalURL;
          this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.externalURL);
          this.videoModel.nativeElement.click();
        }
        else {
          window.open(data.externalURL, '_blank');
        }
      }
    }

    else if (data.linktype == 2) {
      const extension = data.docPath.split(".").pop();
      if (extension == 'ogg' || extension == 'ogv' || extension == 'mp4' || extension == 'webm') {
        ApplicationActivityStore('View', 'Tax Exemption Form', this.appActivity, data.taxExemptionFormId, ActionActivity['Tax Exemption Form'], data.title);
        this.videoType = 'video/' + extension;
        this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.docPath);
        this.videoModel.nativeElement.click();
        this.videoplayer.nativeElement.load();
        this.videoplayer.nativeElement.play();
      }
      else {
        ApplicationActivityStore('Download', 'Tax Exemption Form', this.appActivity, data.taxExemptionFormId, ActionActivity['Tax Exemption Form'], data.displayDocName)
        this.isShowprogressSpinner = true;
        this.blockedDocument = true;
        this.i360Service.DownloadDocument(this.tokenKey, { DocType: 'taxexemptionform', DocPath: data.docPath }).subscribe(
          fileData => {
            this.isShowprogressSpinner = false;
            this.blockedDocument = false;
            if (fileData != null) {
              FileSaver.saveAs(fileData, data.displayDocName);
              this.toastr.success('File downloaded successfully', 'File Downloaded')
            }
          }, error => {
            this.isShowprogressSpinner = false;
            this.blockedDocument = false;
            console.log(error);
          });
      }
    }
  }
  closeVideoPlayer(isEmbed) {
    if (!isEmbed) {
      this.videoplayer.nativeElement.pause();
      this.videoplayer.nativeElement.currentTime = 0;
    }
  }

  toggleVideo(event: any) {
    var myVideo: any = this.videoplayer.nativeElement
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

  pageChanged($event: any) {
    this.filterModel.pageNumber = $event.page;
    this.currentPage = $event.page;
    this.Geti360TaxExemptionForm();
  }

  // Disable Mouse Right Click
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    if (event != undefined)
      event.preventDefault();
  }

  // Disable Keyboard
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.returnValue = false;
    event.preventDefault();
  }
}
