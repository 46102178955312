import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InteserraResourcesService {
  AppUrl: string = "";
  constructor(private _http: Http, private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
      this.setAPIUrl();
  }

  setAPIUrl() {
      if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
          this.AppUrl = sessionStorage.getItem("BaseURL") || '';
      }
  }

  i360InteserraResourcesAddUpdate(TokenKey: string, InteserraResourcesModel: any): Observable<any> {
  this.setAPIUrl();
  let data = 'api/InteserraResources/i360InteserraResourcesAddUpdate';
  const httpOptions = { headers: new HttpHeaders({ 'Accept': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.post<any>(this.AppUrl + data, InteserraResourcesModel, httpOptions)
    .pipe(catchError(this.handleError));
}

i360InteserraResourcesGet(TokenKey: string, filterModel: any): Observable<any> {
  this.setAPIUrl();
  let data = 'api/InteserraResources/i360InteserraResourcesGet';
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
    .pipe(catchError(this.handleError));
}

i360InteserraResourcesFindbyResourcesId(TokenKey: string, InteserraResourcesId: any): Observable<any> {
  this.setAPIUrl();
  let data = 'api/InteserraResources/i360InteserraResourcesFindbyResourcesId';
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.post<any>(this.AppUrl + data, InteserraResourcesId, httpOptions)
    .pipe(catchError(this.handleError));
}

i360InteserraResourcesDelete(TokenKey: string, InteserraResourcesId: any): Observable<any> {
  this.setAPIUrl();
  let data = 'api/InteserraResources/i360InteserraResourcesDelete';
  const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
  return this._httpClient.post<any>(this.AppUrl + data, InteserraResourcesId, httpOptions)
    .pipe(catchError(this.handleError));
}
private handleError(error: Response | any) {
  console.error(error.message || error);
  return observableThrowError(error.status)
} 
}
