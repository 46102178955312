import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { TrainingModel, TrainingSectionsModel } from 'src/Models/TrainingModel';
import { CheckAuth } from 'src/Services/utility.service';
import { TrainingService } from 'src/Services/training.services';

@Component({
  selector: 'app-manage-training',
  templateUrl: './manage-training.component.html',
  styleUrls: ['./manage-training.component.css']
})
export class ManageTrainingComponent implements OnInit {
  trainingModel = new TrainingModel();
  loginuserID: number = 0;
  productId: number = 1;
  tokenKey: string = '';
  SelectedTool: string;

  trainingId: number = 0;
  isMultipleClick: boolean = false;
  file: any;
  @ViewChild('fileUpload', { static: true }) fileUpload;
  isShowprogressSpinner = false;
  blockedDocument = false;

  @ViewChild('FreeModelButton', { static: true }) FreeModelButton: ElementRef;
  popupmsg: string = '';
  issubmitted: boolean = false;
  isEmbed: boolean = true;
  dropdownOptionSettings = {}
  dropdownOptionItems = [];
  listOfOptionSelected = [];
  isSelectExternalURL: boolean = false;
  isSelectFileUpload: boolean = false;
  isErrorOnUploadDoc: boolean = false;
  selectedFiles: File;
  selectedOptionItems = [];
  dropdownListSection = [];
  selectedItemsSections = [];
  dropdownSettingsSections = {};
  selectedSectionItems = [];
  listOfGetSpecialReportSectionData: TrainingSectionsModel[];



  constructor(private activedRoute: ActivatedRoute, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title,
    private trainingService: TrainingService,
    private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Manage Training');
    this.loginuserID = +sessionStorage.getItem('AdminUserId');

    this.dropdownOptionSettings = {
      singleSelection: true,
      text: 'Select',
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      badgeShowLimit: 3,
      noDataLabel: 'No record available',
      showCheckbox: false
    };
    this.getAllSections();

  }

  // Getter for easy access


  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.FillselectoptionDropdown();
    this.trainingModel.isEmbed = this.isEmbed;
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {

          this.trainingId = params['id'];
          this.GetTrainingData();
        }
      }
      else {
        this.trainingModel.isActive = true;
      }
    });
    this.dropdownSettingsSections = {
      singleSelection: true,
      enableSearchFilter: true,
      classes: 'borderLessTextBoxGreen',
      noDataLabel: 'No Section available',
    };

  }

  GetTrainingData(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.trainingService.i360TrainingFindbyTrainingId(this.tokenKey, this.trainingId).subscribe(
      data => {
        if (data != null) {
          this.trainingModel = data["carrierData"];

          if (this.trainingModel.linktype != 0) {
            this.selectedOptionItems = this.dropdownOptionItems.filter(x => x.id == this.trainingModel.linktype);
            this.listOfOptionSelected.push(this.trainingModel.linktype);
            if (this.trainingModel.linktype == 1) {
              document.getElementById('inputfile').style.display = 'none'
              document.getElementById('inputUrl').style.display = 'block'
            }
            else {
              document.getElementById('inputfile').style.display = 'block'
              document.getElementById('inputUrl').style.display = 'none'
            }
          }
          if(this.trainingModel.sectionId!=null && this.trainingModel.sectionId!=0)
          {
            if(this.dropdownListSection!=null && this.dropdownListSection.length>0)
            {
              this.selectedItemsSections = this.dropdownListSection.filter(x => x.id == this.trainingModel.sectionId); 
            }
            else{
            setTimeout(() => {
          this.selectedItemsSections = this.dropdownListSection.filter(x => x.id == this.trainingModel.sectionId); 
          }, 800);
         }
          }
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
      })
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  getAllSections() {
    this.trainingService.i360TrainingSectionListWithIds(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        for (let iCounter = 0; iCounter < data['sectionList'].length; iCounter++) {
          this.dropdownListSection.push(
            { 'id': data['sectionList'][iCounter].sectionId, 'itemName': data['sectionList'][iCounter].sectionName }
          );
        }
      }
    });
  }

  saveTraining(trainingForm) {
    this.issubmitted = true;
    // if(trainingForm.form.invalid)
    // {
    //  return false;
    // }   

    if (this.listOfOptionSelected.length == 0) {
      //this.showMessage('Please Link Type', 'Link Type Required', 'error');
      return;
    }

    if (this.listOfOptionSelected[0] == 2) {
      if (this.fileUpload.nativeElement.files.length == 0 && !this.trainingModel.displayDocName) {
        this.showMessage('Please select file', 'File Required', 'error');
        return;
      }
    } else {
      if (trainingForm.form.invalid) {
        return false;
      }
    }

    event.preventDefault();

    if (this.trainingModel.title == '' || typeof (this.trainingModel.title) == 'undefined' || this.trainingModel.title == null) {
      this.showMessage('Please enter title', 'Title Required', 'error');
      return;
    }
    if (this.selectedItemsSections == null || this.selectedItemsSections.length == 0){
      this.showMessage('Please enter Section', 'Section Required', 'error');
      return;
      }

    if (this.trainingModel.sortOrder == '' || typeof (this.trainingModel.sortOrder) == 'undefined' || this.trainingModel.sortOrder == null) {
      if(this.trainingModel.sortOrder != 0)
      {
        return;
      }
    }

    this.trainingModel.createdBy = this.loginuserID;
    this.trainingModel.modifiedBy = this.loginuserID;

    let formData = new FormData();
    formData.append('trainingId', this.trainingModel.trainingId);
    formData.append('title', this.trainingModel.title);
    if(this.trainingModel.externalURL!=null && this.trainingModel.externalURL!='null')
    {
    formData.append('externalURL', this.trainingModel.externalURL)
    }
    formData.append('displayDocName', this.trainingModel.displayDocName);
    formData.append('docPath', this.trainingModel.docPath);
    formData.append('fileUpload', this.fileUpload.nativeElement.files[0]);
    formData.append('createdBy', this.trainingModel.createdBy);
    formData.append('modifiedBy', this.trainingModel.modifiedBy);
    formData.append('isActive', this.trainingModel.isActive);
    formData.append('isAllowallAccess', this.trainingModel.isAllowallAccess);
    formData.append('isEmbed', this.trainingModel.isEmbed);
    formData.append('linktype', this.listOfOptionSelected[0]);
    formData.append('sortOrder', this.trainingModel.sortOrder);
    formData.append('sectionId', this.selectedItemsSections[0].id);


    if (this.isMultipleClick) {
      return;
    }
    this.isMultipleClick = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.trainingService.i360TrainingAddUpdate(this.tokenKey, formData).subscribe(
      data => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        if (data['trainingId'] != 0) {
          if (data['trainingId'] == -1) {
            this.showMessage('Duplicate record found.', 'Duplicate', 'error');
          }
          else {
            if (this.trainingId == 0)
              this.showMessage('Record saved successfully', 'Save', 'success');
            else
              this.showMessage('Record updated successfully', 'Update', 'success');
            this.trainingId = 0;
            this.cancelTraining()
          }
        }
      },
      error => {
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        this.isMultipleClick = false;
        this.issubmitted = false;
        console.log(error)
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  cancelTraining() {
    this.router.navigate(['admin/inteserra360/Training']);
  }


  IfFreeProduct(event) {
    if (this.trainingId > 0) {
      this.FreeModelButton.nativeElement.click();
      this.popupmsg = event ? 'Are you sure you want to allow access to this item to all companies and subscribers having access to Training?' : 'Are you sure you want to remove access to this item from all companies and subscribers having access to this Training?';
      console.log(this.trainingId);
      console.log(event);
    }
  }

  Cancelpopup() {
    this.GetTrainingData();
  }

  FillselectoptionDropdown() {
    this.dropdownOptionItems.push({ "id": '1', "itemName": "External URL" });
    this.dropdownOptionItems.push({ "id": '2', "itemName": "File Upload" });
  }

  onSelectOption($event) {
    this.listOfOptionSelected = [];
    this.listOfOptionSelected.push($event.id);
    if ($event.id == 1) {
      document.getElementById('inputfile').style.display = 'none'
      document.getElementById('inputUrl').style.display = 'block'
    } else if ($event.id == 2) {
      document.getElementById('inputfile').style.display = 'block'
      document.getElementById('inputUrl').style.display = 'none'
    } else {
      document.getElementById('inputfile').style.display = 'none'
      document.getElementById('inputUrl').style.display = 'none'
    }
  }

  onDeselectOption() {
    document.getElementById('inputfile').style.display = 'none'
    document.getElementById('inputUrl').style.display = 'none'
    this.listOfOptionSelected = [];
  }

  onDeselectAllOption() {
    document.getElementById('inputfile').style.display = 'none'
    document.getElementById('inputUrl').style.display = 'none'
    this.listOfOptionSelected = [];
  }

  fileEvent(event): any {
    var file = event.target.files[0];
    let size = Math.round(file.size / 1024);
    if (size <= 21000) {
      var allowedExtensions = ["doc", "DOC", "docx", "DOCX", "pdf", "PDF"];
      let fileType = file.name.split('.').pop();

      if (!this.isInArray(allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF, DOC, DOCX.', 'Error', 'error');
        this.isErrorOnUploadDoc = true;
        window.scrollTo(0, 0);
      }
      else {
        this.isErrorOnUploadDoc = false;
        this.selectedFiles = event.target.files;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      this.isErrorOnUploadDoc = true;
      window.scrollTo(0, 0);
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }
  onSelectedSection($event) {
    // this.selectedItemsSections = this.removeElemetninArray(this.selectedItemsSections, $event.id)
    // this.selectedItemsSections.push($event.id);
  }
  onDeSelectedSection($event) {
    if (this.selectedItemsSections != null) {
      if (this.selectedItemsSections.length > 0) {
        this.selectedItemsSections = this.removeElemetninArray(this.selectedItemsSections, $event.id)
      }
    }
  }
}


